export const SIMPLE_TEMPLATE = `import * as React from "react";
import {
  Body,
  Container,
  Head,
  Html,
  Preview,
  Section,
  Text,
} from "@react-email/components";

// A minimal template with just the essentials
export const EmailTemplate = () => (
  <Html>
    <Head />
    <Preview>{"{{subject}}"}</Preview>
    <Body style={{
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#ffffff',
      margin: '0',
      padding: '0',
    }}>
      <Container style={{
        maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
      }}>
        <Section>
          <Text style={{
            fontSize: '16px',
            lineHeight: '1.5',
            color: '#333333',
          }}>
            {"Hello {{name}},"}
          </Text>
          
          <Text style={{
            fontSize: '16px',
            lineHeight: '1.5',
            color: '#333333',
          }}>
            {"{{message}}"}
          </Text>
          
          <Text style={{
            fontSize: '14px',
            color: '#666666',
            marginTop: '20px',
          }}>
            Regards,<br />
            FreeTech Team
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

/* Available Handlebars variables for this template:
 * {{subject}} - Email subject line
 * {{name}} - Recipient's name
 * {{message}} - Main email message
 */

export default EmailTemplate;`; 