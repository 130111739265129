import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { Referral } from "@freetech/models/referrals";

/**
 * Gets all referrals made by a specific user
 * @param userId The ID of the user whose referrals to fetch
 * @returns An array of referrals made by the user
 */
export const getUserReferrals = async (userId: string): Promise<Referral[]> => {
  try {
    const referralCollection = collection(db, "referrals");
    const referralQuery = query(
      referralCollection,
      where("referrerId", "==", userId),
    //   orderBy("createdAt", "desc")
    );

    const querySnapshot = await getDocs(referralQuery);
    const referrals: Referral[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      referrals.push({
        id: doc.id,
        ...data,
      } as Referral);
    });

    return referrals;
  } catch (error) {
    console.error("Error getting user referrals:", error);
    throw error;
  }
};

/**
 * Gets all referrals in the system (for admin use)
 * @returns An array of all referrals
 */
export const getAllReferrals = async (): Promise<Referral[]> => {
  try {
    const referralCollection = collection(db, "referrals");
    const referralQuery = query(
      referralCollection,
      orderBy("createdAt", "desc")
    );

    const querySnapshot = await getDocs(referralQuery);
    const referrals: Referral[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      referrals.push({
        id: doc.id,
        ...data,
      } as Referral);
    });

    return referrals;
  } catch (error) {
    console.error("Error getting all referrals:", error);
    throw error;
  }
};
