import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  DocumentReference,
  DocumentData,
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { adminController } from "controllers/adminController";
import { Client } from "@freetech/models/projects";
import { useAdminClients } from "./useAdminClients";
import {
  addDocument,
  db,
  deleteDocument,
  updateDocument,
} from "core/firestore";
import { Project } from "@freetech/models";
import { updateBoard } from "core/freelo/board/updateBoard";
import { getBoard } from "core/freelo/board/getBoard";
import { useAdminStakeholders } from "./useAdminStakeholders";
import { stakeholderConverter } from "@freetech/models/converters";
import { useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ProjectPlan, ProjectIdeaData, ConsultingServicesAgreement } from "@freetech/models/projects";
import { createAndUploadCSA } from "core/csa/createAndUploadCSA";

// Define the context type for the mutation
interface MutationContext {
  previousClient: Client | null;
}

export const useAdminClient = (clientId: string) => {
  const queryClient = useQueryClient();
  const { clients } = useAdminClients();

  // Load a single client (GET)
  const {
    data: client,
    isLoading,
    isError,
  }: UseQueryResult<Client | null, Error> = useQuery<Client | null, Error>({
    queryKey: ["client", clientId],
    queryFn: () => clients?.find((client) => client.id === clientId) || null,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!clientId && !!clients,
  });

  // Set up snapshot listener for projects
  useEffect(() => {
    if (!clientId) return;

    let unsubscribe: Unsubscribe;

    const setupProjectsListener = async () => {
      const projectsRef = collection(db, `clients/${clientId}/projects`);

      unsubscribe = onSnapshot(projectsRef, (snapshot) => {
        // Get the current client data from the cache
        const currentClient = queryClient.getQueryData<Client | null>([
          "client",
          clientId,
        ]);

        if (!currentClient) return;

        // Map the projects from the snapshot
        const updatedProjects = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Project[];

        // Update the client data with the new projects
        queryClient.setQueryData(["client", clientId], {
          ...currentClient,
          projects: updatedProjects,
        });
      });
    };

    setupProjectsListener();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [clientId, queryClient]);

  // Query for project plans for this client
  const {
    data: projectPlans,
    isLoading: isLoadingProjectPlans,
    isError: isErrorProjectPlans,
    refetch: refetchProjectPlans,
  } = useQuery<ProjectPlan[], Error>({
    queryKey: ["client", clientId, "projectPlans"],
    queryFn: async () => {
      if (!clientId) return [];

      try {
        const projectPlansRef = collection(
          db,
          `clients/${clientId}/projectPlans`
        );
        const snapshot = await getDocs(projectPlansRef);

        return snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as ProjectPlan[];
      } catch (error) {
        console.error("Error fetching project plans:", error);
        throw error;
      }
    },
    enabled: !!clientId,
  });

  // Query for project ideas for this client
  const {
    data: projectIdeas,
    isLoading: isLoadingProjectIdeas,
    isError: isErrorProjectIdeas,
    refetch: refetchProjectIdeas,
  } = useQuery<ProjectIdeaData[], Error>({
    queryKey: ["client", clientId, "projectIdeas"],
    queryFn: async () => {
      if (!clientId) return [];

      try {
        const projectIdeasRef = collection(
          db,
          `clients/${clientId}/projectIdeas`
        );
        const snapshot = await getDocs(projectIdeasRef);

        return snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as ProjectIdeaData[];
      } catch (error) {
        console.error("Error fetching project ideas:", error);
        throw error;
      }
    },
    enabled: !!clientId,
  });

  // Update a client (PUT)
  const updateClientMutation = useMutation<void, Error, Partial<Client>>({
    mutationFn: (updatedClient: Partial<Client>) =>
      adminController.clients.updateClient(updatedClient),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Associate stakeholder with client
  const associateStakeholderMutation = useMutation<void, Error, string>({
    mutationFn: async (stakeholderId: string) => {
      if (!client) throw new Error("Client not found");

      const currentStakeholders = client.associatedStakeholderIds || [];
      if (currentStakeholders.includes(stakeholderId)) {
        throw new Error("Stakeholder already associated with this client");
      }

      return adminController.clients.updateClient({
        id: clientId,
        associatedStakeholderIds: [...currentStakeholders, stakeholderId],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Dissociate stakeholder from client
  const dissociateStakeholderMutation = useMutation<void, Error, string>({
    mutationFn: async (stakeholderId: string) => {
      if (!client) throw new Error("Client not found");

      const currentStakeholders = client.associatedStakeholderIds || [];
      return adminController.clients.updateClient({
        id: clientId,
        associatedStakeholderIds: currentStakeholders.filter(
          (id) => id !== stakeholderId
        ),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Associate freelancer with client
  const associateFreelancerMutation = useMutation<void, Error, string>({
    mutationFn: async (freelancerId: string) => {
      if (!client) throw new Error("Client not found");

      const currentFreelancers = client.associatedFreelancerIds || [];
      if (currentFreelancers.includes(freelancerId)) {
        throw new Error("Freelancer already associated with this client");
      }

      return adminController.clients.updateClient({
        id: clientId,
        associatedFreelancerIds: [...currentFreelancers, freelancerId],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Dissociate freelancer from client
  const dissociateFreelancerMutation = useMutation<void, Error, string>({
    mutationFn: async (freelancerId: string) => {
      if (!client) throw new Error("Client not found");

      const currentFreelancers = client.associatedFreelancerIds || [];
      return adminController.clients.updateClient({
        id: clientId,
        associatedFreelancerIds: currentFreelancers.filter(
          (id) => id !== freelancerId
        ),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Set legal owner for client
  const setLegalOwnerMutation = useMutation<void, Error, string>({
    mutationFn: async (stakeholderId: string) => {
      if (!client) throw new Error("Client not found");

      return adminController.clients.updateClient({
        id: clientId,
        legalOwnerFirebaseId: stakeholderId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Delete a client (DELETE)
  const deleteClientMutation = useMutation<void, Error, void>({
    mutationFn: () => adminController.clients.deleteClient(clientId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  const addProjectMutation = useMutation<
    void,
    Error,
    Omit<Project, "id">,
    MutationContext
  >({
    mutationFn: async (project: Omit<Project, "id">) => {
      await addDocument(`clients/${clientId}/projects`, project);
    },
    // Optimistic update
    onMutate: async (newProject) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["client", clientId] });

      // Snapshot the previous value
      const previousClient = queryClient.getQueryData<Client | null>([
        "client",
        clientId,
      ]);

      if (previousClient) {
        // Create a temporary ID for the optimistic update
        const tempId = `temp_${Date.now()}`;

        // Create the optimistic project
        const optimisticProject = {
          ...newProject,
          id: tempId,
          createdAt: new Date().toISOString(),
        } as Project;

        // Update the client with the new project
        const updatedClient = {
          ...previousClient,
          projects: [...(previousClient.projects || []), optimisticProject],
        };

        // Update the cache with the optimistic value
        queryClient.setQueryData(["client", clientId], updatedClient);
      }

      // Return the previous client for rollback
      return { previousClient } as MutationContext;
    },
    onError: (err, newProject, context) => {
      // If there was an error, roll back to the previous value
      if (context?.previousClient) {
        queryClient.setQueryData(["client", clientId], context.previousClient);
      }
    },
    onSuccess: () => {
      // We don't need to invalidate the query here because the snapshot listener will update the data
      // This allows the optimistic update to persist until the real data comes in via the snapshot
    },
  });

  // Get a specific project idea
  const getProjectIdea = async (
    projectIdeaId: string
  ): Promise<ProjectIdeaData | null> => {
    if (!clientId || !projectIdeaId) return null;

    try {
      const projectIdeaRef = doc(
        db,
        `clients/${clientId}/projectIdeas/${projectIdeaId}`
      );
      const docSnap = await getDoc(projectIdeaRef);

      if (docSnap.exists()) {
        return {
          id: docSnap.id,
          ...docSnap.data(),
        } as ProjectIdeaData;
      }

      return null;
    } catch (error) {
      console.error("Error fetching project idea:", error);
      throw error;
    }
  };

  // Get a specific project plan
  const getProjectPlan = async (
    projectPlanId: string
  ): Promise<ProjectPlan | null> => {
    if (!projectPlanId) return null;

    try {
      const projectPlanRef = doc(db, `projectPlans/${projectPlanId}`);
      const docSnap = await getDoc(projectPlanRef);

      if (docSnap.exists()) {
        return {
          id: docSnap.id,
          ...docSnap.data(),
        } as ProjectPlan;
      }

      return null;
    } catch (error) {
      console.error("Error fetching project plan:", error);
      throw error;
    }
  };

  const updateProjectMutation = useMutation<void, Error, Partial<Project>>({
    mutationFn: async (project: Partial<Project>) => {
      const filteredProject = Object.fromEntries(
        Object.entries(project).filter(([_, value]) => value !== undefined)
      );
      await updateDocument(
        `clients/${clientId}/projects/${project.id}`,
        filteredProject
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  const deleteProjectMutation = useMutation<void, Error, string>({
    mutationFn: async (projectId: string) => {
      await deleteDocument(`clients/${clientId}/projects/${projectId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Delete a project idea
  const deleteProjectIdeaMutation = useMutation<void, Error, string>({
    mutationFn: async (projectIdeaId: string) => {
      await deleteDocument(`clients/${clientId}/projectIdeas/${projectIdeaId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["client", clientId, "projectIdeas"],
      });
    },
  });

  // Delete a project plan
  const deleteProjectPlanMutation = useMutation<void, Error, string>({
    mutationFn: async (projectPlanId: string) => {
      await deleteDocument(`projectPlans/${projectPlanId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["client", clientId, "projectPlans"],
      });
    },
  });

  const assignProjectToBoardMutation = useMutation({
    mutationFn: async (params: {
      boardId: string;
      projectId: string;
      clientId: string;
    }) => {
      try {
        const board = await getBoard(params.boardId);
        if (board) {
          console.log("board", board);

          const clientRef = doc(
            db,
            "clients",
            params.clientId,
            "projects",
            params.projectId
          );
          await updateDoc(clientRef, {
            freeloBoardId: board.id,
          });
          board.projectId = params.projectId;
          board.clientId = params.clientId;
          return updateBoard({
            boardId: board.id,
            data: board,
          });
        }
        return null;
      } catch (error) {
        console.error("Error assigning project to board:", error);
        throw error;
      }
    },

    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const getStakeholdersForClientId = useMutation({
    mutationFn: async (clientId: string) => {
      const clientRef = doc(db, "clients", clientId);
      const clientDoc = await getDoc(clientRef);
      const clientData = clientDoc.data() as Client;

      const associatedStakeholderIds =
        clientData.associatedStakeholderIds || [];

      const stakeholders = await collection(db, "users").withConverter(
        stakeholderConverter
      );
      const stakeholderDocs = await getDocs(
        query(stakeholders, where("id", "in", associatedStakeholderIds))
      );
      return stakeholderDocs.docs.map((doc) => doc.data());
    },
  });

  // Upload client logo
  const uploadClientLogoMutation = useMutation<string, Error, File>({
    mutationFn: async (file: File) => {
      if (!clientId) throw new Error("Client ID is required");

      const storage = getStorage();
      const storageRef = ref(storage, `clients/${clientId}/logo`);

      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Update client with logo URL
      await updateDocument(`clients/${clientId}`, {
        logoUrl: downloadURL,
      });

      return downloadURL;
    },
    onSuccess: (logoUrl) => {
      // Update the client in the cache with the new logo URL
      const currentClient = queryClient.getQueryData<Client | null>([
        "client",
        clientId,
      ]);
      if (currentClient) {
        queryClient.setQueryData(["client", clientId], {
          ...currentClient,
          logoUrl,
        });
      }

      // Invalidate the clients list query to reflect the changes
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
    onError: (error) => {
      console.error("Error uploading client logo:", error);
    },
  });

  // Upload CSA for a project
  const manualUploadCSAMutation = useMutation<
    ConsultingServicesAgreement,
    Error,
    {
      projectId: string;
      csaData: Omit<ConsultingServicesAgreement, "id" | "fileUrl">;
      file: File;
    }
  >({
    mutationFn: async ({ projectId, csaData, file }) => {
      if (!clientId) throw new Error("Client ID is required");
      return createAndUploadCSA(clientId, projectId, csaData, file);
    },
    onSuccess: () => {
      // Invalidate client data to refresh the CSAs
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
    onError: (error) => {
      console.error("Error uploading CSA:", error);
    },
  });

  return {
    client,
    isLoading,
    isError,
    // Project plans data
    projectPlans,
    isLoadingProjectPlans,
    isErrorProjectPlans,
    refetchProjectPlans,
    getProjectPlan,
    deleteProjectPlan: deleteProjectPlanMutation.mutate,
    isDeletingProjectPlan: deleteProjectPlanMutation.isPending,
    // Project ideas data
    projectIdeas,
    isLoadingProjectIdeas,
    isErrorProjectIdeas,
    refetchProjectIdeas,
    getProjectIdea,
    deleteProjectIdea: deleteProjectIdeaMutation.mutate,
    isDeletingProjectIdea: deleteProjectIdeaMutation.isPending,
    // CSA operations
    uploadCSA: manualUploadCSAMutation.mutate,
    isUploadingCSA: manualUploadCSAMutation.isPending,
    // Client data operations
    updateClient: updateClientMutation.mutate,
    isUpdating: updateClientMutation.isPending,
    updateError: updateClientMutation.error,
    associateStakeholder: associateStakeholderMutation,
    dissociateStakeholder: dissociateStakeholderMutation,
    associateFreelancer: associateFreelancerMutation,
    dissociateFreelancer: dissociateFreelancerMutation,
    setLegalOwner: setLegalOwnerMutation,
    deleteClient: deleteClientMutation.mutate,
    isDeleting: deleteClientMutation.isPending,
    deleteError: deleteClientMutation.error,
    addProject: addProjectMutation.mutate,
    isAddingProject: addProjectMutation.isPending,
    addProjectError: addProjectMutation.error,
    updateProject: updateProjectMutation,
    deleteProject: deleteProjectMutation,
    assignProjectToBoard: assignProjectToBoardMutation,
    getStakeholdersForClientId: getStakeholdersForClientId,
    uploadClientLogo: uploadClientLogoMutation,
  };
};
