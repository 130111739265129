import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getIndependentEngineerAgreement } from "../../core/independentEngineerAgreements/getIndependentEngineerAgreement";
import { Freelancer, UserDocument } from "@freetech/models/user";
import {
  fetchFreelancerDocuments,
  fetchMyFreelancerProfile,
  updateFreelancerProfile as updateProfile,
} from "core/freelancer";
import { uploadDocument } from "core/freelancer";
import { useAuth } from "hooks/auth/useAuth";

export const useFreelancerProfile = () => {
  const queryClient = useQueryClient();

  const { currentUser } = useAuth();

  const freelancerId = currentUser?.uid;

  if (!freelancerId) {
    throw new Error("No authenticated user");
  }

  const {
    data: independentEngineerAgreement,
    isLoading: isLoadingIndependentEngineerAgreement,
  } = useQuery({
    queryKey: ["freelancerAgreement", freelancerId],
    queryFn: () => getIndependentEngineerAgreement(freelancerId),
  });

  const { data: freelancerProfile, isLoading: isLoadingFreelancerProfile } =
    useQuery({
      queryKey: ["freelancerProfile", freelancerId],
      queryFn: async () => await fetchMyFreelancerProfile(),
    });

  const { data: freelancerDocuments, isLoading: isLoadingFreelancerDocuments } =
    useQuery({
      queryKey: ["freelancerDocuments", freelancerId],
      queryFn: () => fetchFreelancerDocuments(freelancerId),
    });

  const { mutate: updateFreelancerProfile } = useMutation({
    mutationFn: (updatedFreelancer: Partial<Freelancer>) =>
      updateProfile(freelancerId, updatedFreelancer),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["freelancerProfile", freelancerId],
      });
    },
  });

  const { mutate: uploadFreelancerDocument } = useMutation({
    mutationFn: (data: { file: File; document: UserDocument }) =>
      uploadDocument(freelancerId, data.file, data.document),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["freelancerDocuments", freelancerId],
      });
    },
  });

  return {
    independentEngineerAgreement,
    isLoadingIndependentEngineerAgreement,
    freelancerProfile,
    isLoadingFreelancerProfile,
    freelancerDocuments,
    isLoadingFreelancerDocuments,
    updateFreelancerProfile,
    uploadFreelancerDocument,
  };
};
