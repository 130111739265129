import React, { useRef, useState } from 'react';
import { 
  Box, Typography, Avatar, TextField, Button, Badge, IconButton,
  Snackbar, Alert
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { uploadProfilePicture, updateUser } from "core/auth";
import { useProfilePicture } from "hooks/user/useProfilePicture";
import { Freelancer } from "@freetech/models/user";
import { PrivateUserInfoType } from "@freetech/models/user";

interface ProfileInfoProps {
  freelancer: Freelancer;
  onUpdate: (updatedFreelancer: Freelancer) => void;
  userInfo?: PrivateUserInfoType;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

const formFieldStyles = {
  "& .MuiInputBase-root": {
    bgcolor: "white",
    borderRadius: 1,
  },
  "& .MuiInputBase-input": {
    color: "grey.900",
  },
  "& .MuiInputLabel-root": {
    color: "grey.700",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "grey.300",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "grey.400",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.main",
  }
};

const ProfileInfo: React.FC<ProfileInfoProps> = ({ 
  freelancer, 
  onUpdate, 
  userInfo,
  isEditing,
  setIsEditing 
}) => {
  const [editedFreelancer, setEditedFreelancer] = React.useState(freelancer);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { url: profilePictureUrl, refetch: refetchProfilePicture } = useProfilePicture(userInfo?.id || '');
  const [snackbar, setSnackbar] = useState({ 
    open: false, 
    message: "", 
    severity: "success" as "error" | "success" 
  });

  const handleCancel = () => {
    setEditedFreelancer(freelancer);
    setIsEditing(false);
  };

  const handleSave = () => {
    onUpdate(editedFreelancer);
    setIsEditing(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedFreelancer(prev => ({ ...prev, [name]: value }));
  };

  const handlePhotoClick = () => {
    fileInputRef.current?.click();
  };

  const handlePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !userInfo?.id) return;

    try {
      const photoURL = await uploadProfilePicture(userInfo.id, file);
      await updateUser(userInfo.id, { profilePicture: photoURL });
      
      // Update local state
      setEditedFreelancer(prev => ({ ...prev, profilePicture: photoURL }));
      onUpdate({ ...freelancer, profilePicture: photoURL });
      
      // Refresh the profile picture
      await refetchProfilePicture();
      
      setSnackbar({
        open: true,
        message: "Profile picture updated successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      setSnackbar({
        open: true,
        message: "Error uploading profile picture",
        severity: "error"
      });
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <IconButton
              onClick={handlePhotoClick}
              sx={{
                bgcolor: 'primary.main',
                '&:hover': { bgcolor: 'primary.dark' },
                width: 32,
                height: 32
              }}
            >
              <PhotoCameraIcon sx={{ color: 'white', fontSize: 18 }} />
            </IconButton>
          }
        >
          <Avatar
            src={profilePictureUrl || freelancer.profilePicture}
            alt={`${freelancer.firstName} ${freelancer.lastName}`}
            sx={{ width: 100, height: 100 }}
          />
        </Badge>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handlePhotoUpload}
          accept="image/*"
          style={{ display: 'none' }}
        />
        <Box ml={3}>
          {isEditing ? (
            <>
              <TextField
                name="firstName"
                value={editedFreelancer.firstName}
                onChange={handleChange}
                variant="outlined"
                sx={{ mb: 1, ...formFieldStyles }}
              />
              <TextField
                name="lastName"
                value={editedFreelancer.lastName}
                onChange={handleChange}
                variant="outlined"
                sx={formFieldStyles}
              />
            </>
          ) : (
            <>
              <Typography variant="h5">{`${editedFreelancer.firstName} ${editedFreelancer.lastName}`}</Typography>
              <Typography color="textSecondary">{editedFreelancer.email}</Typography>
            </>
          )}
        </Box>
      </Box>

      {isEditing ? (
        <TextField
          fullWidth
          name="phone"
          label="Phone"
          value={editedFreelancer.phone}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          sx={formFieldStyles}
        />
      ) : (
        <>
          <Typography variant="body1"><strong>Phone:</strong> {editedFreelancer.phone}</Typography>
          <Typography variant="body1"><strong>User Type:</strong> {editedFreelancer.role}</Typography>
        </>
      )}

      {isEditing ? (
        <Box mt={2}>
          <Button startIcon={<SaveIcon />} onClick={handleSave} sx={{ mr: 1 }}>
            Save
          </Button>
          <Button startIcon={<CancelIcon />} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      ) : null}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfileInfo;
