import { PDFDocument, StandardFonts } from "pdf-lib";
import { ClientInvoiceData } from "@freetech/models/bank";
import { getDownloadURL } from "firebase/storage";
import { ref } from "firebase/storage";
import { getStorage } from "firebase/storage";
import { uploadBytes } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";

export const createAndSavePDF = async (
  invoiceData: Omit<ClientInvoiceData, "id">,
  clientId?: string,
  invoiceId?: string
) => {
  try {
    console.log("Creating PDF for invoice:", invoiceData);
    
    // Validate required parameters
    if (!clientId || !invoiceId) {
      console.error("Missing required parameters for PDF generation:", { clientId, invoiceId });
      throw new Error("Client ID and Invoice ID are required for PDF generation");
    }
    
    // Ensure all required data is present
    const safeInvoiceData = {
      ...invoiceData,
      // Ensure billing and shipping info are set
      billTo: {
        name: invoiceData.billTo?.name || "Client",
        company: invoiceData.billTo?.company || "Company"
      },
      shipTo: {
        name: invoiceData.shipTo?.name || "",
        company: invoiceData.shipTo?.company || ""
      },
      // Ensure dates are set
      invoiceDate: invoiceData.invoiceDate || new Date().toISOString().split("T")[0],
      dueDate: invoiceData.dueDate || "",
      // Ensure invoice number is set
      invoiceNumber: invoiceData.invoiceNumber || "INV-" + Date.now(),
      // Ensure terms are set
      terms: invoiceData.terms || "Net 30",
      // Ensure items are set
      items: invoiceData.items?.length > 0 ? invoiceData.items : [{ description: "Service", quantity: 1, rate: invoiceData.totalDollarAmountDue || 0 }],
      // Ensure total amount is set
      totalDollarAmountDue: invoiceData.totalDollarAmountDue || 0,
      // Ensure discount is set
      discount: invoiceData.discount || 0
    };
    
    console.log("Using safe invoice data for PDF generation:", safeInvoiceData);
    
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    // Company details
    page.drawText("FreeTech", { x: 50, y: 750, size: 24, font: boldFont });
    page.drawText("104 Fox Court", { x: 50, y: 730, size: 10, font });
    page.drawText("Winchester, VA 22603", { x: 50, y: 715, size: 10, font });
    page.drawText("admin@freetech.co", { x: 50, y: 700, size: 10, font });
    page.drawText("+1 (571)-291-1193", { x: 50, y: 685, size: 10, font });
    page.drawText("freetech.co", { x: 50, y: 670, size: 10, font });

    // Invoice title
    page.drawText("INVOICE", { x: 450, y: 750, size: 24, font: boldFont });

    // Bill To
    page.drawText("Bill to", { x: 50, y: 600, size: 12, font: boldFont });
    page.drawText(safeInvoiceData.billTo.name, { x: 50, y: 585, size: 10, font });
    page.drawText(safeInvoiceData.billTo.company, {
      x: 50,
      y: 570,
      size: 10,
      font,
    });

    // Ship To
    page.drawText("Ship to", { x: 300, y: 600, size: 12, font: boldFont });
    page.drawText(safeInvoiceData.shipTo.name, { x: 300, y: 585, size: 10, font });
    page.drawText(safeInvoiceData.shipTo.company, {
      x: 300,
      y: 570,
      size: 10,
      font,
    });

    // Invoice details
    page.drawText("Invoice details", {
      x: 50,
      y: 520,
      size: 12,
      font: boldFont,
    });
    page.drawText(`Invoice no.: ${safeInvoiceData.invoiceNumber}`, {
      x: 50,
      y: 505,
      size: 10,
      font,
    });
    page.drawText(`Terms: ${safeInvoiceData.terms}`, {
      x: 50,
      y: 490,
      size: 10,
      font,
    });
    page.drawText(`Invoice date: ${safeInvoiceData.invoiceDate}`, {
      x: 50,
      y: 475,
      size: 10,
      font,
    });
    page.drawText(`Due date: ${safeInvoiceData.dueDate}`, {
      x: 50,
      y: 460,
      size: 10,
      font,
    });

    // If this invoice has previous versions, add a note
    if (invoiceData.oldVersions && invoiceData.oldVersions.length > 0) {
      page.drawText(`This invoice has been revised ${invoiceData.oldVersions.length} time(s). Last update: ${new Date().toLocaleDateString()}`, {
        x: 50,
        y: 445,
        size: 10,
        font,
      });
    }

    // Table header
    page.drawText("#", { x: 50, y: 420, size: 10, font: boldFont });
    page.drawText("Product or Service", {
      x: 80,
      y: 420,
      size: 10,
      font: boldFont,
    });
    page.drawText("Qty", { x: 300, y: 420, size: 10, font: boldFont });
    page.drawText("Rate", { x: 350, y: 420, size: 10, font: boldFont });
    page.drawText("Amount", { x: 500, y: 420, size: 10, font: boldFont });

    // Table content
    let yOffset = 400;
    let subtotal = 0;
    
    safeInvoiceData.items.forEach((item, index) => {
      const amount = (item.quantity || 0) * (item.rate || 0);
      subtotal += amount;
      page.drawText((index + 1).toString(), {
        x: 50,
        y: yOffset,
        size: 10,
        font,
      });
      page.drawText(item.description || "Service", { x: 80, y: yOffset, size: 10, font });
      page.drawText((item.quantity || 0).toString(), {
        x: 300,
        y: yOffset,
        size: 10,
        font,
      });
      page.drawText(`$${(item.rate || 0).toFixed(2)}`, {
        x: 350,
        y: yOffset,
        size: 10,
        font,
      });
      page.drawText(`$${amount.toFixed(2)}`, {
        x: 500,
        y: yOffset,
        size: 10,
        font,
      });
      yOffset -= 20;
    });

    // Subtotal, Discount, and Total
    const discount = subtotal * ((safeInvoiceData.discount || 0) / 100);
    
    // Use the provided totalDollarAmountDue if available, otherwise calculate it
    const total = safeInvoiceData.totalDollarAmountDue || (subtotal - discount);

    page.drawText("Subtotal", {
      x: 400,
      y: yOffset - 20,
      size: 10,
      font: boldFont,
    });
    page.drawText(`$${subtotal.toFixed(2)}`, {
      x: 500,
      y: yOffset - 20,
      size: 10,
      font,
    });
    page.drawText(`Discount (${safeInvoiceData.discount || 0}%)`, {
      x: 400,
      y: yOffset - 40,
      size: 10,
      font: boldFont,
    });
    page.drawText(`$${discount.toFixed(2)}`, {
      x: 500,
      y: yOffset - 40,
      size: 10,
      font,
    });
    page.drawText("Total", {
      x: 400,
      y: yOffset - 60,
      size: 12,
      font: boldFont,
    });
    page.drawText(`$${total.toFixed(2)}`, {
      x: 500,
      y: yOffset - 60,
      size: 12,
      font: boldFont,
    });

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const storage = getStorage();

    // Use provided clientId and invoiceId if available, otherwise use from invoiceData
    const finalClientId = clientId || safeInvoiceData.clientId;
    const finalInvoiceId = invoiceId; 

    if (!finalClientId) {
      throw new Error("Client ID is required");
    }
    if (!finalInvoiceId) {
      throw new Error("Invoice ID is required");
    }

    // Determine the storage path - use a folder structure
    let storagePath = `clients/${finalClientId}/invoices/${finalInvoiceId}/`;
    
    // Add a version suffix to the filename
    if (invoiceData.oldVersions && invoiceData.oldVersions.length > 0) {
      // For updated invoices, use a version suffix
      // The current version is always "current.pdf"
      storagePath += `current.pdf`;
    } else {
      // For new invoices, use original.pdf
      storagePath += 'original.pdf';
    }
    
    console.log("Saving PDF to storage path:", storagePath);
    
    // Upload the PDF to Firebase Storage, overwriting any existing file
    const storageRef = ref(storage, storagePath);
    const metadata = {
      contentType: 'application/pdf',
      // Add a timestamp to force cache invalidation
      customMetadata: {
        updatedAt: new Date().toISOString()
      }
    };
    
    const snapshot = await uploadBytes(storageRef, pdfBlob, metadata);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log("PDF created and saved with URL:", downloadURL);
    
    return downloadURL;
  } catch (error) {
    console.error("Error creating PDF Invoice:", error);
    throw error;
  }
};
