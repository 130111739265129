export const REACT_EMAIL_BASE_TEMPLATE = `import * as React from "react";
import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Preview,
  Section,
  Text,
  Link,
  Img,
} from "@react-email/components";

export const EmailTemplate = () => (
  <Html>
    <Head />
    <Preview>FreeTech Email Notification</Preview>
    <Body style={{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      backgroundColor: "#f5f5f5",
      margin: "0",
      padding: "0",
    }}>
      <Container style={{
        maxWidth: "600px",
        margin: "0 auto",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        marginTop: "40px",
        marginBottom: "40px",
      }}>
        <Section style={{
          padding: "32px",
          textAlign: "center",
          borderBottom: "1px solid #e0e0e0",
        }}>
          <Img
            src="https://firebasestorage.googleapis.com/v0/b/freetech-production.appspot.com/o/public%2Flogo-no-background.png?alt=media&token=14917ef1-04eb-4370-9b07-110de636adc9"
            alt="FreeTech Logo"
            style={{
              maxWidth: "200px",
              height: "auto",
              margin: "0 auto",
            }}
          />
        </Section>
        <Section style={{ padding: "32px" }}>
          <Heading style={{
            fontSize: "24px",
            fontWeight: "500",
            color: "#1976d2",
            marginBottom: "24px",
            textAlign: "center",
          }}>
            {"Hello, {{name}}"}
          </Heading>
          <Text style={{
            fontSize: "16px",
            color: "#333333",
            lineHeight: "1.6",
            marginBottom: "24px",
          }}>
            {"{{message}}"}
          </Text>
          
          <Section style={{ 
            textAlign: "center",
            margin: "32px 0"
          }}>
            <table
              role="presentation"
              cellPadding="0"
              cellSpacing="0"
              style={{
                margin: "0 auto"
              }}
            >
              <tr>
                <td
                  style={{
                    backgroundColor: "#1976d2",
                    borderRadius: "4px",
                    padding: "0"
                  }}
                >
                  <a
                    href="{{buttonUrl}}"
                    target="_blank"
                    style={{
                      color: "#ffffff",
                      fontSize: "16px",
                      fontWeight: "500",
                      textDecoration: "none",
                      textAlign: "center",
                      padding: "12px 24px",
                      display: "block"
                    }}
                  >
                    {"{{buttonText}}"}
                  </a>
                </td>
              </tr>
            </table>
          </Section>
          
          <Text style={{
            fontSize: "16px",
            color: "#666666",
            lineHeight: "1.6",
            borderTop: "1px solid #e0e0e0",
            paddingTop: "24px",
          }}>
            Best regards,
            <br />
            The FreeTech Team
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

/* Available Handlebars variables:
 * {{name}} - Recipient's name
 * {{message}} - Main email content
 * {{buttonText}} - Text for the button
 * {{buttonUrl}} - URL for the button link
 */

export default EmailTemplate;
`;
