export enum BlogCategory {
  Engineering = "Engineering",
  Technology = "Technology",
  Development = "Development",
  AI = "AI",
  MachineLearning = "Machine Learning",
  Cloud = "Cloud",
  Architecture = "Architecture",
  DevOps = "DevOps",
  Security = "Security",
  Announcement = "Announcement",
}
