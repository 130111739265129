import React from "react";
import { Chip, Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFreelo } from "hooks/freelo/useFreelo";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useFreeloBoard } from "hooks/freelo/useFreeloBoard";

interface FreeloBoardChipProps {
  boardId: string;
}

export const FreeloBoardChip: React.FC<FreeloBoardChipProps> = ({
  boardId,
}) => {
  const navigate = useNavigate();
  const { data: board, isLoading } = useFreeloBoard(boardId);

  if (isLoading || !board) {
    return (
      <Chip
        icon={<DashboardIcon />}
        label="Loading..."
        sx={{ cursor: "wait" }}
      />
    );
  }

  return (
    <Tooltip title="Go to board" arrow>
      <Chip
        icon={<DashboardIcon />}
        label="Freelo Board"
        onClick={() => navigate(`/freelo/${boardId}`)}
        sx={{
          cursor: "pointer",
          backgroundColor: "info.main",
          color: "white",
          "&:hover": {
            backgroundColor: "info.dark",
          },
        }}
      />
    </Tooltip>
  );
};
