import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { 
  getStudentSubscription, 
  cancelStudentSubscription, 
  reactivateStudentSubscription,
  createStudentCustomerPortalSession,
  createStudentSubscription
} from 'core/functions/stripeFunctions';
import { StudentSubscription } from 'types/student';

interface UseStudentManageSubscriptionReturn {
  // Data
  subscription: StudentSubscription | null;
  isLoading: boolean;
  error: Error | null;
  
  // Fetch subscription
  refetchSubscription: () => Promise<void>;
  
  // Create subscription
  createSubscription: (priceId: string, couponCode?: string) => Promise<{ checkoutUrl: string; sessionId: string }>;
  isCreating: boolean;
  
  // Cancel subscription
  cancelSubscription: (atPeriodEnd: boolean) => Promise<{ success: boolean; message: string }>;
  isCancelling: boolean;
  
  // Reactivate subscription
  reactivateSubscription: () => Promise<{ success: boolean; message: string; subscription?: StudentSubscription }>;
  isReactivating: boolean;
  
  // Customer portal
  openCustomerPortal: (returnUrl?: string) => Promise<void>;
  isOpeningPortal: boolean;
}

/**
 * Hook for managing student subscriptions
 * 
 * Provides methods to fetch, create, cancel, and reactivate subscriptions,
 * as well as open the Stripe Customer Portal for payment management.
 */
export const useStudentManageSubscription = (): UseStudentManageSubscriptionReturn => {
  const queryClient = useQueryClient();
  const [isOpeningPortal, setIsOpeningPortal] = useState(false);
  
  // Fetch the current subscription
  const { 
    data: subscription, 
    isLoading, 
    error,
    refetch
  } = useQuery({
    queryKey: ['student', 'subscription'],
    queryFn: async () => {
      const data = await getStudentSubscription();
      return data;
    },
  });
  
  // Create subscription mutation
  const createSubscriptionMutation = useMutation({
    mutationFn: (params: { priceId: string; couponCode?: string }) => {
      const { priceId, couponCode } = params;
      return createStudentSubscription(priceId, couponCode);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['student', 'subscription'] });
    },
  });
  
  // Cancel subscription mutation
  const cancelSubscriptionMutation = useMutation({
    mutationFn: (atPeriodEnd: boolean) => {
      return cancelStudentSubscription(atPeriodEnd);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['student', 'subscription'] });
    },
  });
  
  // Reactivate subscription mutation
  const reactivateSubscriptionMutation = useMutation({
    mutationFn: () => {
      return reactivateStudentSubscription();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['student', 'subscription'] });
    },
  });
  
  // Refetch subscription data
  const refetchSubscription = async (): Promise<void> => {
    await refetch();
  };
  
  // Create a new subscription
  const createSubscription = async (priceId: string, couponCode?: string) => {
    return createSubscriptionMutation.mutateAsync({ priceId, couponCode });
  };
  
  // Cancel an existing subscription
  const cancelSubscription = async (atPeriodEnd: boolean = true) => {
    return cancelSubscriptionMutation.mutateAsync(atPeriodEnd);
  };
  
  // Reactivate a canceled subscription
  const reactivateSubscription = async () => {
    return reactivateSubscriptionMutation.mutateAsync();
  };
  
  // Open customer portal for payment management
  const openCustomerPortal = async (returnUrl?: string): Promise<void> => {
    try {
      setIsOpeningPortal(true);
      const portalUrl = await createStudentCustomerPortalSession(returnUrl);
      window.location.href = portalUrl;
    } catch (err) {
      console.error('Error opening customer portal:', err);
      throw err;
    } finally {
      setIsOpeningPortal(false);
    }
  };
  
  return {
    // Data
    subscription: subscription || null,
    isLoading,
    error: error as Error | null,
    
    // Methods
    refetchSubscription,
    createSubscription,
    cancelSubscription,
    reactivateSubscription,
    openCustomerPortal,
    
    // Loading states
    isCreating: createSubscriptionMutation.isPending,
    isCancelling: cancelSubscriptionMutation.isPending,
    isReactivating: reactivateSubscriptionMutation.isPending,
    isOpeningPortal,
  };
}; 