import { addDocument, db } from "core/firestore";
import { Project } from "@freetech/models/projects";
import { Stakeholder } from "@freetech/models/user";
import { projectConverter } from "@freetech/models/converters";
import { collection, doc } from "firebase/firestore";
import { ProjectIdeaForm } from "types/projects/projectIdea";
import { ProjectPlan } from "@freetech/models/projects";

/**
 * Submits a new project idea with an AI-generated project plan
 *
 * @param stakeholder The stakeholder submitting the project idea
 * @param ideaData The project idea form data
 * @param aiProjectPlan The AI-generated project plan
 * @returns A promise that resolves to the created project
 */
export const submitProjectIdea = async (
  stakeholder: Stakeholder,
  ideaData: ProjectIdeaForm,
  aiProjectPlan?: ProjectPlan
): Promise<Project> => {
  // Check if we have a client ID from the form data
  const clientId =
    ideaData.clientId ||
    (stakeholder.stakeholderClientIds?.length
      ? stakeholder.stakeholderClientIds[0]
      : null);

  if (!clientId) {
    throw new Error("No client associated with this project");
  }

  const newProject = {
    name: ideaData.name,
    description: ideaData.description,
    status: "idea",
    ideaDate: new Date().toISOString(),
    startDate: null,
    endDate: null,
    clientId: clientId,
    createdByUserId: stakeholder.id,
    projectType: ideaData.projectType,
    budget: ideaData.budget?.toString(),
    duration: ideaData.expectedDuration,
    // Store additional fields
    techStack: ideaData.techStack,
    teamSize: ideaData.teamSize?.toString(),
    additionalNotes: ideaData.additionalNotes,
    companyContext: ideaData.companyContext,
    projectDetails: ideaData.projectDetails,
    projectTypeDetails: ideaData.projectTypeDetails,
    aiGeneratedPlan: aiProjectPlan ? JSON.stringify(aiProjectPlan) : undefined,
  };

  const projectRef = doc(
    collection(db, `clients/${clientId}/projects`)
  ).withConverter(projectConverter);

  const addedProject = await addDocument(projectRef.path, newProject);

  return {
    ...newProject,
    id: addedProject.id,
  } as Project;
};
