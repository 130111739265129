import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Avatar, 
  Chip,
  CircularProgress,
  useTheme,
  Button,
  Divider,
  alpha
} from '@mui/material';
import { ChatMessage as ChatMessageType } from 'types/projects/projectIdea';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion';
import { Components } from 'react-markdown';

interface ChatMessageProps {
  message: ChatMessageType;
  onSuggestionClick?: (suggestion: string) => void;
  isLatest?: boolean;
}

/**
 * Component for rendering a chat message in the AI conversation
 */
export const ChatMessage: React.FC<ChatMessageProps> = ({ 
  message, 
  onSuggestionClick,
  isLatest
}) => {
  const isAI = message.type === 'ai';
  const theme = useTheme();
  
  // Check if this message has client suggestions
  const hasClientSuggestions = isAI && 
    message.suggestions !== undefined && 
    message.suggestions.length > 0 && 
    message.suggestions.some((s: string) => s.includes(':'));
  
  // Define markdown components with proper typing
  const markdownComponents: Components = {
    p: ({ children }) => (
      <Typography component="p" variant="body1" gutterBottom>
        {children}
      </Typography>
    ),
    h1: ({ children }) => (
      <Typography component="h1" variant="h5" gutterBottom>
        {children}
      </Typography>
    ),
    h2: ({ children }) => (
      <Typography component="h2" variant="h6" gutterBottom>
        {children}
      </Typography>
    ),
    h3: ({ children }) => (
      <Typography component="h3" variant="subtitle1" gutterBottom>
        {children}
      </Typography>
    ),
    ul: ({ children }) => (
      <Box component="ul" sx={{ pl: 2 }}>
        {children}
      </Box>
    ),
    ol: ({ children }) => (
      <Box component="ol" sx={{ pl: 2 }}>
        {children}
      </Box>
    ),
    li: ({ children }) => (
      <Typography component="li" variant="body1">
        {children}
      </Typography>
    ),
    strong: ({ children }) => (
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
    ),
  };
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          mb: 2.5,
          alignItems: 'flex-start',
          flexDirection: isAI ? 'row' : 'row-reverse'
        }}
      >
        <Avatar 
          src={isAI ? "/jane.jpg" : undefined}
          sx={{ 
            bgcolor: isAI ? theme.palette.primary.main : theme.palette.secondary.main,
            mr: isAI ? 1.5 : 0,
            ml: isAI ? 0 : 1.5,
            width: 38,
            height: 38,
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            ...(isAI && {
              border: '2px solid rgba(255, 255, 255, 0.3)',
            }),
            ...(!isAI && {
              background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
              color: 'white',
              fontWeight: 'bold'
            })
          }}
        >
          {!isAI && <PersonIcon fontSize="small" />}
        </Avatar>
        
        <Box sx={{ maxWidth: '80%' }}>
          {isAI && (
            <Typography 
              variant="caption" 
              sx={{ 
                ml: 2, 
                mb: 0.5, 
                display: 'block', 
                color: 'text.secondary',
                fontWeight: 500
              }}
            >
              Jane, FreeTech AI
            </Typography>
          )}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2, 
              bgcolor: isAI ? 'white' : theme.palette.primary.main,
              color: isAI ? 'text.primary' : 'white',
              borderRadius: 2.5,
              borderTopLeftRadius: isAI ? 0 : 2.5,
              borderTopRightRadius: isAI ? 2.5 : 0,
              boxShadow: isAI 
                ? '0 2px 12px rgba(0, 0, 0, 0.06)' 
                : '0 2px 8px rgba(0, 0, 0, 0.15)',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              border: isAI ? '1px solid rgba(0, 0, 0, 0.08)' : 'none',
              ...(isAI && {
                '& a': {
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 500,
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }
              })
            }}
          >
            {isAI && isLatest && message.content === '' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5 }}>
                <CircularProgress size={20} sx={{ mr: 1.5, color: theme.palette.primary.main }} />
                <Typography variant="body1">Thinking...</Typography>
              </Box>
            ) : (
              <>
                <ReactMarkdown components={markdownComponents}>
                  {message.content}
                </ReactMarkdown>
                
                {/* Client suggestions as buttons inside the chat bubble */}
                {hasClientSuggestions && (
                  <Box sx={{ mt: 2 }}>
                    <Divider sx={{ my: 1.5 }} />
                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1.5 }}>
                      Select a client:
                    </Typography>
                    {message.suggestions?.map((suggestion: string, index: number) => {
                      if (suggestion.includes(':')) {
                        // This is a client suggestion
                        const [clientName, clientDetails] = suggestion.split(':');
                        const displayName = clientName.trim();
                        const isRecommended = clientDetails.toLowerCase().includes('recommended');
                        
                        return (
                          <Button
                            key={index}
                            variant={isRecommended ? "contained" : "outlined"}
                            color={isRecommended ? "secondary" : "primary"}
                            size="medium"
                            startIcon={<BusinessIcon />}
                            onClick={() => {
                              console.log('Client suggestion clicked:', suggestion);
                              onSuggestionClick?.(suggestion);
                            }}
                            fullWidth
                            sx={{ 
                              justifyContent: 'flex-start',
                              textAlign: 'left',
                              py: 1.5,
                              borderRadius: 2,
                              mb: 0.5,
                              fontWeight: isRecommended ? 'bold' : 'normal',
                              position: 'relative',
                              pl: isRecommended ? 3 : undefined,
                              '&::before': isRecommended ? {
                                content: '""',
                                position: 'absolute',
                                left: 8,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                backgroundColor: 'white',
                              } : undefined
                            }}
                          >
                            {displayName}
                          </Button>
                        );
                      }
                      return null;
                    })}
                  </Box>
                )}
                
                {/* Other suggestion buttons below the message */}
                {isAI && isLatest && message.suggestions && !hasClientSuggestions && (
                  <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {message.suggestions.map((suggestion, index) => (
                      <Chip
                        key={index}
                        label={suggestion}
                        color="primary"
                        variant="outlined"
                        onClick={() => onSuggestionClick?.(suggestion)}
                        sx={{ 
                          borderRadius: 4,
                          px: 1,
                          py: 2.5,
                          borderColor: 'rgba(0, 0, 0, 0.12)',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                            borderColor: theme.palette.primary.main,
                          }
                        }}
                      />
                    ))}
                  </Box>
                )}
              </>
            )}
          </Paper>
          
          {!isAI && (
            <Typography 
              variant="caption" 
              sx={{ 
                mr: 2, 
                mb: 0.5, 
                display: 'block', 
                color: 'text.secondary',
                fontWeight: 500,
                textAlign: 'right',
                mt: 0.5
              }}
            >
              You
            </Typography>
          )}
        </Box>
      </Box>
    </motion.div>
  );
}; 