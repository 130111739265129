import { db } from "config/firebase";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { IBugReport } from "@freetech/models/bugReport";
import { bugReportConverter } from"@freetech/models/converters";
import { loadClientsNonAdmin } from "core/clients";
import { UserInfoType } from "@freetech/models/user";

export const getBugReportsCurrentUser = async (
  userInfo: UserInfoType
): Promise<IBugReport[]> => {
  if (!userInfo) {
    throw new Error("User not found");
  }
  try {
    const role = userInfo?.role;
    if (role === "admin") {
      const q = query(
        collection(db, "bugReports"),
        orderBy("createdAt", "desc")
      ).withConverter(bugReportConverter);
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => doc.data());
    } else if (role === "freelancer") {
      const clients = await loadClientsNonAdmin(userInfo);
      const clientIds = clients.map((client) => client.id);
      const q = query(
        collection(db, "bugReports"),
        orderBy("createdAt", "desc"),
        where("clientId", "in", clientIds)
      ).withConverter(bugReportConverter);
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => doc.data());
    } else if (role === "stakeholder") {
      const clients = await loadClientsNonAdmin(userInfo);
      const clientIds = clients.map((client) => client.id);
      const q = query(
        collection(db, "bugReports"),
        orderBy("createdAt", "desc"),
        where("clientId", "in", clientIds)
      ).withConverter(bugReportConverter);

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
        };
      });
    } else {
      throw new Error("User role not found");
    }
  } catch (error) {
    console.error("Error fetching bug reports:", error);
    throw error;
  }
};
