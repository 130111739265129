import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { adminController } from "controllers/adminController";
import { db } from "config/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  addDoc,
  serverTimestamp,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import {
  Student,
  PrivateUserInfoType,
  CombinedUserData,
  StudentStatus,
} from "@freetech/models/user";
import { useAuth } from "hooks/auth/useAuth";

interface Cohort {
  id: string;
  name: string;
  description?: string;
  mentorId: string;
  createdAt: Timestamp;
  startDate?: string;
  endDate?: string;
  status: "active" | "inactive" | "completed";
  studentIds: string[];
}

interface CreateCohortParams {
  name: string;
  description?: string;
  mentorId: string;
  startDate?: string;
  endDate?: string;
  studentIds?: string[];
}

interface UpdateCohortParams {
  id: string;
  name?: string;
  description?: string;
  mentorId?: string;
  startDate?: string;
  endDate?: string;
  status?: "active" | "inactive" | "completed";
  studentIds?: string[];
}

interface AssignMentorParams {
  studentId: string;
  mentorId: string;
}

interface AssignStudentToCohortParams {
  studentId: string;
  cohortId: string;
}

interface UpdateStudentStatusParams {
  studentId: string;
  status: StudentStatus;
}

export const useAdminStudents = () => {
  const queryClient = useQueryClient();
  const { userInfo: user } = useAuth();

  // Get all students
  const { data: students = [], isLoading: isStudentsLoading } = useQuery({
    queryKey: ["admin", "students"],
    queryFn: async (): Promise<Student[]> => {
      const studentsQuery = query(
        collection(db, "users"),
        where("role", "==", "student")
      );

      const snapshot = await getDocs(studentsQuery);
      return snapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          }) as Student
      );
    },
    enabled: !!user && user.role === "admin",
  });

  // Get all potential mentors (admins and freelancers)
  const { data: potentialMentors = [], isLoading: isMentorsLoading } = useQuery(
    {
      queryKey: ["admin", "potential-mentors"],
      queryFn: async (): Promise<PrivateUserInfoType[]> => {
        // Get all users who can be mentors (admins and freelancers)
        const usersQuery = query(
          collection(db, "users"),
          where("role", "in", ["admin", "freelancer"])
        );

        const snapshot = await getDocs(usersQuery);
        return snapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
            }) as PrivateUserInfoType
        );
      },
      enabled: !!user && user.role === "admin",
    }
  );

  // Get all cohorts
  const { data: cohorts = [], isLoading: isCohortsLoading } = useQuery({
    queryKey: ["admin", "cohorts"],
    queryFn: async (): Promise<Cohort[]> => {
      const cohortsQuery = query(collection(db, "cohorts"));

      const snapshot = await getDocs(cohortsQuery);
      return snapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          }) as Cohort
      );
    },
    enabled: !!user && user.role === "admin",
  });

  // Create a new cohort
  const createCohortMutation = useMutation({
    mutationFn: async (params: CreateCohortParams): Promise<string> => {
      // Create the cohort document
      const cohortRef = await addDoc(collection(db, "cohorts"), {
        ...params,
        createdAt: serverTimestamp(),
        status: "active",
        studentIds: params.studentIds || [],
      });

      // If students are provided, update their cohortId
      if (params.studentIds && params.studentIds.length > 0) {
        for (const studentId of params.studentIds) {
          await updateDoc(doc(db, "users", studentId), {
            cohortId: cohortRef.id,
            updatedAt: serverTimestamp(),
          });
        }
      }

      return cohortRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "cohorts"] });
      queryClient.invalidateQueries({ queryKey: ["admin", "students"] });
    },
  });

  // Update an existing cohort
  const updateCohortMutation = useMutation({
    mutationFn: async (params: UpdateCohortParams): Promise<void> => {
      const { id, ...updateData } = params;

      // Get current cohort data to compare student IDs
      const cohortDoc = await getDoc(doc(db, "cohorts", id));
      if (!cohortDoc.exists()) {
        throw new Error("Cohort not found");
      }

      const currentCohort = cohortDoc.data() as Cohort;
      const currentStudentIds = currentCohort.studentIds || [];
      const newStudentIds = params.studentIds || currentStudentIds;

      // Update the cohort document
      await updateDoc(doc(db, "cohorts", id), {
        ...updateData,
        updatedAt: serverTimestamp(),
      });

      // Find students who have been removed from the cohort
      const removedStudentIds = currentStudentIds.filter(
        (id) => !newStudentIds.includes(id)
      );

      // Find students who have been added to the cohort
      const addedStudentIds = newStudentIds.filter(
        (id) => !currentStudentIds.includes(id)
      );

      // Update removed students
      for (const studentId of removedStudentIds) {
        await updateDoc(doc(db, "users", studentId), {
          cohortId: null,
          updatedAt: serverTimestamp(),
        });
      }

      // Update added students
      for (const studentId of addedStudentIds) {
        await updateDoc(doc(db, "users", studentId), {
          cohortId: id,
          updatedAt: serverTimestamp(),
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "cohorts"] });
      queryClient.invalidateQueries({ queryKey: ["admin", "students"] });
    },
  });

  // Delete a cohort
  const deleteCohortMutation = useMutation({
    mutationFn: async (cohortId: string): Promise<void> => {
      // Get the cohort to find its students
      const cohortDoc = await getDoc(doc(db, "cohorts", cohortId));
      if (!cohortDoc.exists()) {
        throw new Error("Cohort not found");
      }

      const cohort = cohortDoc.data() as Cohort;

      // Remove cohort references from all students in the cohort
      if (cohort.studentIds && cohort.studentIds.length > 0) {
        for (const studentId of cohort.studentIds) {
          await updateDoc(doc(db, "users", studentId), {
            cohortId: null,
            updatedAt: serverTimestamp(),
          });
        }
      }

      // Delete the cohort document
      await deleteDoc(doc(db, "cohorts", cohortId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "cohorts"] });
      queryClient.invalidateQueries({ queryKey: ["admin", "students"] });
    },
  });

  // Assign a mentor to a student
  const assignMentorMutation = useMutation({
    mutationFn: async (params: AssignMentorParams): Promise<void> => {
      const { studentId, mentorId } = params;

      await updateDoc(doc(db, "users", studentId), {
        assignedMentorId: mentorId,
        updatedAt: new Date().toISOString(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "students"] });
    },
  });

  // Assign a student to a cohort
  const assignStudentToCohortMutation = useMutation({
    mutationFn: async (params: AssignStudentToCohortParams): Promise<void> => {
      const { studentId, cohortId } = params;

      // Update student with new cohort ID
      await updateDoc(doc(db, "users", studentId), {
        cohortId,
        updatedAt: serverTimestamp(),
      });

      // Update cohort with new student ID
      const cohortDoc = await getDoc(doc(db, "cohorts", cohortId));
      if (!cohortDoc.exists()) {
        throw new Error("Cohort not found");
      }

      const cohort = cohortDoc.data() as Cohort;
      const studentIds = cohort.studentIds || [];

      if (!studentIds.includes(studentId)) {
        await updateDoc(doc(db, "cohorts", cohortId), {
          studentIds: [...studentIds, studentId],
          updatedAt: serverTimestamp(),
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "cohorts"] });
      queryClient.invalidateQueries({ queryKey: ["admin", "students"] });
    },
  });

  // Update student status
  const updateStudentStatusMutation = useMutation({
    mutationFn: async (params: UpdateStudentStatusParams): Promise<void> => {
      const { studentId, status } = params;

      await updateDoc(doc(db, "users", studentId), {
        status,
        updatedAt: serverTimestamp(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "students"] });
    },
  });

  return {
    students,
    potentialMentors,
    cohorts,
    isStudentsLoading,
    isMentorsLoading,
    isCohortsLoading,
    isLoading: isStudentsLoading || isMentorsLoading || isCohortsLoading,
    createCohort: createCohortMutation.mutateAsync,
    updateCohort: updateCohortMutation.mutateAsync,
    deleteCohort: deleteCohortMutation.mutateAsync,
    assignMentor: assignMentorMutation.mutateAsync,
    assignStudentToCohort: assignStudentToCohortMutation.mutateAsync,
    updateStudentStatus: updateStudentStatusMutation.mutateAsync,
    isCreatingCohort: createCohortMutation.isPending,
    isUpdatingCohort: updateCohortMutation.isPending,
    isDeletingCohort: deleteCohortMutation.isPending,
    isAssigningMentor: assignMentorMutation.isPending,
    isAssigningStudentToCohort: assignStudentToCohortMutation.isPending,
    isUpdatingStudentStatus: updateStudentStatusMutation.isPending,
  };
};
