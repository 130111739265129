import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  updateDoc,
  getFirestore,
} from "firebase/firestore";
import {
  Typography,
  Box,
  CircularProgress,
  Paper,
  Grid,
  Avatar,
  Chip,
  Card,
  CardContent,
  CardActions,
  Button,
  Snackbar,
  Alert,
  TextField,
  Autocomplete,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  MenuItem,
  Link,
  Select,
  FormControl,
  Menu,
  ListItemIcon,
  ListItemText,
  Tooltip,
  LinearProgress,
  Badge,
} from "@mui/material";
import { DateTime } from 'luxon';
import ViewFileDialog from "components/ViewFileDialog";
import { getProjectName } from "core/projects";
import { useDropzone } from "react-dropzone";
import { useAdminTimesheet } from "hooks/admin/useAdminTimesheet";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PendingIcon from "@mui/icons-material/Pending";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import { getProjectAssignmentAgreement } from "core/projectAssignments";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { Freelancer, IndependentEngineerAgreement } from "@freetech/models/user";
import { ProjectAssignment } from "@freetech/models/projects";
import { UserDocument } from "@freetech/models/user";
import { 
  ProjectAssignmentStatus, 
  EverSignDocumentStatus 
} from "@freetech/models/projects";

const db = getFirestore();

// Add this array of predefined skills
const predefinedSkills = [
  "JavaScript",
  "React",
  "Node.js",
  "Python",
  "Java",
  "C#",
  "Ruby",
  "PHP",
  "Swift",
  "Kotlin",
  "Go",
  "Rust",
  "TypeScript",
  "Angular",
  "Vue.js",
  "Docker",
  "Kubernetes",
  "AWS",
  "Azure",
  "GCP",
  "MongoDB",
  "PostgreSQL",
  "MySQL",
  "Redis",
  "GraphQL",
  "REST API",
  "Machine Learning",
  "Data Science",
  "DevOps",
  "CI/CD",
];

// Add this function to generate a color based on the skill name
const getSkillColor = (skill: string) => {
  let hash = 0;
  for (let i = 0; i < skill.length; i++) {
    hash = skill.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 80%)`;
};

// Add this array of predefined document types
const predefinedDocumentTypes = [
  "Independent Engineer Agreement",
  "Driver's License",
  "Passport",
  "Birth Certificate",
  "Legacy Project Assignment",
  "Legal",
  "Independent Contractor Agreement",
  "Headshot",
  "Certificate",
  "Other",
];

export const FreelancerOverview = () => {
  const { freelancerId } = useParams<{ freelancerId: string }>();
  const [freelancer, setFreelancer] = useState<Freelancer | null>(null);
  const [editedFreelancer, setEditedFreelancer] = useState<Freelancer | null>(
    null
  );
  const [projectAssignments, setProjectAssignments] = useState<
    ProjectAssignment[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [openViewFileDialog, setOpenViewFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error" as "error" | "success",
  });
  const [skills, setSkills] = useState<string[]>([]);
  const [newSkill, setNewSkill] = useState<string>("");
  const [editing, setEditing] = useState(false);
  const [documents, setDocuments] = useState<UserDocument[]>([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [newDocument, setNewDocument] = useState<Partial<UserDocument>>({
    name: "",
    documentType: "",
    documentDate: "",
  });
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [editingDocument, setEditingDocument] = useState<string | null>(null);
  const [editedDocument, setEditedDocument] = useState<UserDocument | null>(
    null
  );
  const [documentSearchTerm, setDocumentSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState<UserDocument[]>(
    []
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    assignment: ProjectAssignment | null;
  }>({
    open: false,
    assignment: null,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAssignment, setSelectedAssignment] = useState<ProjectAssignment | null>(null);
  const [everSignStatusDialogOpen, setEverSignStatusDialogOpen] = useState(false);
  const [selectedEverSignStatus, setSelectedEverSignStatus] = useState<EverSignDocumentStatus | undefined>(undefined);
  const [timesheetStartDate, setTimesheetStartDate] = useState<DateTime>(
    DateTime.now().minus({ months: 3 }).startOf("month")
  );
  const [timesheetEndDate, setTimesheetEndDate] = useState<DateTime>(
    DateTime.now().endOf("month")
  );
  const [timesheetStats, setTimesheetStats] = useState<{
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    clientHours: Record<string, number>;
    projectHours: Record<string, number>;
    averageHoursPerDay: number;
    averageHoursPerWeek: number;
    submittedTimesheets: number;
    pendingTimesheets: number;
  }>({
    totalHours: 0,
    billableHours: 0,
    nonBillableHours: 0,
    clientHours: {},
    projectHours: {},
    averageHoursPerDay: 0,
    averageHoursPerWeek: 0,
    submittedTimesheets: 0,
    pendingTimesheets: 0,
  });
  const [isUploadingProfilePicture, setIsUploadingProfilePicture] = useState(false);

  const {
    freelancers: adminFreelancers,
    isLoading: isLoadingFreelancers,
    deleteProjectAssignment,
    isDeletingAssignment,
    uploadDocument,
    updateDocument,
    deleteDocument,
    getAgreement,
    isLoadingAgreement: isLoadingEngineerAgreement,
    uploadFreelancerProfilePicture,
  } = useAdminFreelancers();

  const { getAllTimesheets } = useAdminTimesheet();
  const { data: allTimesheets, isLoading: isLoadingTimesheets } = getAllTimesheets();

  useEffect(() => {
    const initializeFreelancerData = async () => {
      if (freelancerId && adminFreelancers) {
        const freelancerWithAssignments = adminFreelancers.find(
          (f) => f.id === freelancerId
        );

        if (freelancerWithAssignments) {
          setFreelancer(freelancerWithAssignments);
          setEditedFreelancer(freelancerWithAssignments);
          setSkills(freelancerWithAssignments.skills || []);
          setProjectAssignments(freelancerWithAssignments.projectAssignments || []);
          setDocuments(freelancerWithAssignments.documents || []);
          setFilteredDocuments(freelancerWithAssignments.documents || []);

          // Fetch the engineer agreement
          getAgreement.mutate(freelancerId);
        }

        setLoading(false);
      }
    };

    initializeFreelancerData();
  }, [freelancerId, adminFreelancers]);

  useEffect(() => {
    const filtered = documents.filter((doc) =>
      Object.values(doc).some(
        (value) =>
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(documentSearchTerm.toLowerCase())
      )
    );
    setFilteredDocuments(filtered);
  }, [documentSearchTerm, documents]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditedFreelancer(freelancer);
    setEditing(false);
  };

  const handleSave = async () => {
    if (editedFreelancer && freelancerId) {
      try {
        const updatedFields = {
          firstName: editedFreelancer.firstName,
          lastName: editedFreelancer.lastName,
          phone: editedFreelancer.phone,
          skills: editedFreelancer.skills,
          // Add any other fields that can be edited
        };

        await updateDoc(doc(db, "users", freelancerId), updatedFields);
        setFreelancer(editedFreelancer);
        setEditing(false);
        setSnackbar({
          open: true,
          message: "Freelancer updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating freelancer:", error);
        setSnackbar({
          open: true,
          message: "Error updating freelancer",
          severity: "error",
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedFreelancer((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleAddSkill = (
    event: React.KeyboardEvent<HTMLDivElement>,
    value: string | null
  ) => {
    if (event.key === "Enter" && value && !skills.includes(value)) {
      const updatedSkills = [...skills, value];
      setSkills(updatedSkills);
      updateFreelancerSkills(updatedSkills);
      setNewSkill("");
    }
  };

  const handleDeleteSkill = (skillToDelete: string) => {
    const updatedSkills = skills.filter((skill) => skill !== skillToDelete);
    setSkills(updatedSkills);
    updateFreelancerSkills(updatedSkills);
  };

  const updateFreelancerSkills = async (updatedSkills: string[]) => {
    if (freelancerId) {
      const freelancerRef = doc(db, "users", freelancerId);
      try {
        await updateDoc(freelancerRef, { skills: updatedSkills });
        setSnackbar({
          open: true,
          message: "Skills updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating skills:", error);
        setSnackbar({
          open: true,
          message: "Error updating skills",
          severity: "error",
        });
      }
    }
  };

  const handleViewAgreement = async (assignment: ProjectAssignment) => {
    if (!freelancerId) {
      throw new Error("Freelancer ID is missing");
    }

    try {
      const fileUrl = await getProjectAssignmentAgreement(
        assignment.clientId,
        assignment.id
      );
      if (fileUrl) {
        setSelectedFile({
          url: fileUrl,
          name: `Agreement for ${assignment.projectName}`,
        });
        setOpenViewFileDialog(true);
      } else {
        setSnackbar({
          open: true,
          message: "Agreement file not found",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching agreement file:", error);
      setSnackbar({
        open: true,
        message: "Error fetching agreement file",
        severity: "error",
      });
    }
  };

  const handleUploadDocument = async () => {
    if (
      !freelancerId ||
      !uploadFile ||
      !newDocument.name ||
      !newDocument.documentDate ||
      !newDocument.documentType
    ) {
      return;
    }

    try {
      await uploadDocument.mutateAsync({
        freelancerId,
        file: uploadFile,
        documentData: {
          name: newDocument.name,
          documentType: newDocument.documentType,
          documentDate: newDocument.documentDate,
        },
      });

      setOpenUploadDialog(false);
      setNewDocument({
        name: "",
        documentType: "",
        documentDate: "",
      });
      setUploadFile(null);
      setSnackbar({
        open: true,
        message: "Document uploaded successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error uploading document:", error);
      setSnackbar({
        open: true,
        message: "Error uploading document",
        severity: "error",
      });
    }
  };

  const handleEditDocument = (doc: UserDocument) => {
    setEditingDocument(doc.id);
    setEditedDocument(doc);
  };

  const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editedDocument) {
      setEditedDocument({ ...editedDocument, [e.target.name]: e.target.value });
    }
  };

  const handleUpdateDocument = async () => {
    if (editedDocument && freelancerId) {
      try {
        await updateDocument.mutateAsync({
          freelancerId,
          documentId: editedDocument.id,
          updates: {
            name: editedDocument.name,
            documentType: editedDocument.documentType,
            documentDate: editedDocument.documentDate,
          },
        });

        setEditingDocument(null);
        setEditedDocument(null);
        setSnackbar({
          open: true,
          message: "Document updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating document:", error);
        setSnackbar({
          open: true,
          message: "Error updating document",
          severity: "error",
        });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleUpdateDocument();
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDeleteAssignmentClick = (assignment: ProjectAssignment) => {
    setDeleteConfirmation({
      open: true,
      assignment,
    });
  };

  const handleDeleteAssignment = async () => {
    if (
      !deleteConfirmation.open ||
      !deleteConfirmation.assignment ||
      !freelancerId
    )
      return;

    try {
      await deleteProjectAssignment.mutateAsync({
        clientId: deleteConfirmation.assignment.clientId,
        engineerId: freelancerId,
        assignmentId: deleteConfirmation.assignment.id,
      });

      setProjectAssignments((prev) =>
        prev.filter((a) => a.id !== deleteConfirmation.assignment!.id)
      );

      setSnackbar({
        open: true,
        message: "Project assignment deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting project assignment:", error);
      setSnackbar({
        open: true,
        message: "Error deleting project assignment",
        severity: "error",
      });
    } finally {
      setDeleteConfirmation({ open: false, assignment: null });
    }
  };

  const handleViewEngineerAgreement = (e: React.MouseEvent) => {
    e.preventDefault();
    const agreement = getAgreement.data;
    if (agreement?.firebaseStorageUrl) {
      setSelectedFile({
        url: agreement.firebaseStorageUrl,
        name: "Independent Engineer Agreement"
      });
      setOpenViewFileDialog(true);
    }
  };

  // Add function to handle opening the assignment menu
  const handleAssignmentMenuClick = (event: React.MouseEvent<HTMLElement>, assignment: ProjectAssignment) => {
    setAnchorEl(event.currentTarget);
    setSelectedAssignment(assignment);
  };

  // Add function to handle closing the assignment menu
  const handleAssignmentMenuClose = () => {
    setAnchorEl(null);
  };

  // Add function to handle updating the assignment status
  const handleUpdateAssignmentStatus = async (newStatus: ProjectAssignmentStatus) => {
    if (selectedAssignment && freelancerId) {
      try {
        await updateDoc(
          doc(
            getFirestore(),
            `clients/${selectedAssignment.clientId}/projectAssignments/${selectedAssignment.id}`
          ),
          { status: newStatus }
        );

        // Update local state
        setProjectAssignments(
          projectAssignments.map((assignment) =>
            assignment.id === selectedAssignment.id
              ? { ...assignment, status: newStatus }
              : assignment
          )
        );

        setSnackbar({
          open: true,
          message: "Assignment status updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating assignment status:", error);
        setSnackbar({
          open: true,
          message: "Error updating assignment status",
          severity: "error",
        });
      }
    }
    handleAssignmentMenuClose();
  };

  // Add function to open EverSign status dialog
  const handleOpenEverSignDialog = () => {
    if (selectedAssignment) {
      setSelectedEverSignStatus(selectedAssignment.everSignDocumentStatus);
      setEverSignStatusDialogOpen(true);
    }
    handleAssignmentMenuClose();
  };

  // Add function to close EverSign status dialog
  const handleCloseEverSignDialog = () => {
    setEverSignStatusDialogOpen(false);
  };

  // Add function to update EverSign status
  const handleUpdateEverSignStatus = async () => {
    if (selectedAssignment && selectedEverSignStatus) {
      try {
        await updateDoc(
          doc(
            getFirestore(),
            `clients/${selectedAssignment.clientId}/projectAssignments/${selectedAssignment.id}`
          ),
          { everSignDocumentStatus: selectedEverSignStatus }
        );

        // Update local state
        setProjectAssignments(
          projectAssignments.map((assignment) =>
            assignment.id === selectedAssignment.id
              ? { ...assignment, everSignDocumentStatus: selectedEverSignStatus }
              : assignment
          )
        );

        setSnackbar({
          open: true,
          message: "EverSign status updated successfully",
          severity: "success",
        });
        setEverSignStatusDialogOpen(false);
      } catch (error) {
        console.error("Error updating EverSign status:", error);
        setSnackbar({
          open: true,
          message: "Error updating EverSign status",
          severity: "error",
        });
      }
    }
  };

  // Add function to get status color
  const getStatusColor = (status?: ProjectAssignmentStatus) => {
    switch (status) {
      case "interviewing":
        return "#FFA726"; // Orange
      case "ready":
        return "#66BB6A"; // Green
      case "signing":
        return "#42A5F5"; // Blue
      case "in_progress":
        return "#AB47BC"; // Purple
      case "complete":
        return "#26A69A"; // Teal
      case "cancelled":
        return "#EF5350"; // Red
      default:
        return "#9E9E9E"; // Grey
    }
  };

  // Add function to get status label
  const getStatusLabel = (status?: ProjectAssignmentStatus) => {
    switch (status) {
      case "interviewing":
        return "Interviewing";
      case "ready":
        return "Ready";
      case "signing":
        return "Signing";
      case "in_progress":
        return "In Progress";
      case "complete":
        return "Complete";
      case "cancelled":
        return "Cancelled";
      default:
        return "Unknown";
    }
  };

  // Add function to get EverSign status icon
  const getEverSignStatusIcon = (status?: EverSignDocumentStatus) => {
    switch (status) {
      case "awaiting_admin_signature":
        return <HourglassEmptyIcon color="warning" />;
      case "awaiting_engineer_signature":
        return <PendingIcon color="info" />;
      case "signed":
        return <CheckCircleIcon color="success" />;
      case "declined":
        return <CancelOutlinedIcon color="error" />;
      case "cancelled":
        return <CancelOutlinedIcon color="error" />;
      default:
        return null;
    }
  };

  // Add function to get EverSign status label
  const getEverSignStatusLabel = (status?: EverSignDocumentStatus) => {
    switch (status) {
      case "awaiting_admin_signature":
        return "Awaiting Admin Signature";
      case "awaiting_engineer_signature":
        return "Awaiting Engineer Signature";
      case "signed":
        return "Signed";
      case "declined":
        return "Declined";
      case "cancelled":
        return "Cancelled";
      default:
        return "Not Started";
    }
  };

  // Add a function to calculate timesheet statistics
  const calculateTimesheetStats = useCallback(() => {
    if (!allTimesheets || !freelancerId) return;

    // Filter timesheets for this freelancer and date range
    const freelancerTimesheets = allTimesheets.filter(
      (timesheet) => 
        timesheet.userId === freelancerId &&
        DateTime.fromISO(timesheet.date) >= timesheetStartDate &&
        DateTime.fromISO(timesheet.date) <= timesheetEndDate
    );

    if (freelancerTimesheets.length === 0) return;

    // Calculate statistics
    const totalHours = freelancerTimesheets.reduce(
      (sum, timesheet) => sum + timesheet.duration / 3600, // Convert seconds to hours
      0
    );

    const billableHours = freelancerTimesheets
      .filter((timesheet) => timesheet.billable)
      .reduce((sum, timesheet) => sum + timesheet.duration / 3600, 0);

    const nonBillableHours = totalHours - billableHours;

    // Calculate hours by client
    const clientHours: Record<string, number> = {};
    freelancerTimesheets.forEach((timesheet) => {
      const clientId = timesheet.clientId;
      const clientName = timesheet.clientName || clientId;
      const hours = timesheet.duration / 3600;
      
      if (!clientHours[clientName]) {
        clientHours[clientName] = 0;
      }
      clientHours[clientName] += hours;
    });

    // Calculate hours by project
    const projectHours: Record<string, number> = {};
    freelancerTimesheets.forEach((timesheet) => {
      const projectId = timesheet.projectId;
      const projectName = timesheet.projectName || projectId;
      const hours = timesheet.duration / 3600;
      
      if (!projectHours[projectName]) {
        projectHours[projectName] = 0;
      }
      projectHours[projectName] += hours;
    });

    // Calculate average hours per day and week
    const uniqueDays = new Set(
      freelancerTimesheets.map((timesheet) => timesheet.date.split('T')[0])
    );
    const daysWithEntries = uniqueDays.size;
    const weeksWithEntries = daysWithEntries / 7;
    
    const averageHoursPerDay = daysWithEntries > 0 
      ? totalHours / daysWithEntries 
      : 0;
    
    const averageHoursPerWeek = weeksWithEntries > 0 
      ? totalHours / weeksWithEntries 
      : 0;

    // Count submitted and pending timesheets
    const submittedTimesheets = freelancerTimesheets.filter(
      (timesheet) => timesheet.submitted
    ).length;
    
    const pendingTimesheets = freelancerTimesheets.length - submittedTimesheets;

    setTimesheetStats({
      totalHours,
      billableHours,
      nonBillableHours,
      clientHours,
      projectHours,
      averageHoursPerDay,
      averageHoursPerWeek,
      submittedTimesheets,
      pendingTimesheets,
    });
  }, [allTimesheets, freelancerId, timesheetStartDate, timesheetEndDate]);

  // Call calculateTimesheetStats when dependencies change
  useEffect(() => {
    calculateTimesheetStats();
  }, [calculateTimesheetStats]);

  // Add function to get status priority for sorting
  const getStatusPriority = (status?: ProjectAssignmentStatus): number => {
    switch (status) {
      case "interviewing":
        return 1;
      case "ready":
        return 2;
      case "signing":
        return 3;
      case "in_progress":
        return 4;
      case "complete":
        return 5;
      case "cancelled":
        return 6;
      default:
        return 7;
    }
  };

  // Add function to sort project assignments by status
  const sortedProjectAssignments = useMemo(() => {
    return [...projectAssignments].sort((a, b) => {
      return getStatusPriority(a.status) - getStatusPriority(b.status);
    });
  }, [projectAssignments]);

  // Add function to handle profile picture upload
  const handleProfilePictureChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !freelancerId) return;

    // Check file size (limit to 5MB)
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      setSnackbar({
        open: true,
        message: "File size exceeds 5MB limit. Please choose a smaller image.",
        severity: "error",
      });
      return;
    }

    try {
      setIsUploadingProfilePicture(true);
      await uploadFreelancerProfilePicture.mutateAsync({
        freelancerId,
        file,
      });
      setSnackbar({
        open: true,
        message: "Profile picture updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating profile picture:", error);
      let errorMessage = "Error updating profile picture";
      
      // Provide more specific error messages
      if (error instanceof Error) {
        if (error.message.includes("No document to update")) {
          errorMessage = "User document not found. Please refresh the page and try again.";
        } else if (error.message.includes("storage/unauthorized")) {
          errorMessage = "Unauthorized to upload files. Please check your permissions.";
        } else if (error.message.includes("storage/quota-exceeded")) {
          errorMessage = "Storage quota exceeded. Please contact the administrator.";
        } else if (error.message.includes("network")) {
          errorMessage = "Network error. Please check your connection and try again.";
        }
      }
      
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    } finally {
      setIsUploadingProfilePicture(false);
    }
  };

  if (loading || isLoadingFreelancers) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!freelancer || !editedFreelancer) {
    return (
      <>
        <Typography>No freelancer found</Typography>
      </>
    );
  }

  return (
    <>
      <Box py={4}>
        <Paper elevation={3} sx={{ mb: 4 }}>
          <Box p={4} position="relative">
            {!editing ? (
              <IconButton
                onClick={handleEdit}
                sx={{ position: "absolute", top: 16, right: 16 }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Box sx={{ position: "absolute", top: 16, right: 16 }}>
                <IconButton onClick={handleSave} sx={{ mr: 1 }}>
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCancel}>
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Box sx={{ position: "relative" }}>
                    <Avatar
                      src={freelancer.profilePicture}
                      alt={`${freelancer.firstName} ${freelancer.lastName}`}
                      sx={{ width: 80, height: 80, mr: 2 }}
                    />
                    
                    {/* Add profile picture upload button */}
                    <IconButton
                      component="label"
                      size="small"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 8,
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        '&:hover': {
                          bgcolor: 'background.paper',
                        },
                      }}
                      disabled={isUploadingProfilePicture}
                    >
                      {isUploadingProfilePicture ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleProfilePictureChange}
                          />
                          <PhotoCameraIcon fontSize="small" />
                        </>
                      )}
                    </IconButton>
                  </Box>
                  <Box>
                    {editing ? (
                      <>
                        <TextField
                          name="firstName"
                          value={editedFreelancer.firstName}
                          onChange={handleChange}
                          variant="standard"
                          sx={{ mb: 1 }}
                        />
                        <TextField
                          name="lastName"
                          value={editedFreelancer.lastName}
                          onChange={handleChange}
                          variant="standard"
                        />
                      </>
                    ) : (
                      <Typography variant="h4">{`${freelancer.firstName} ${freelancer.lastName}`}</Typography>
                    )}
                    <Typography variant="body1" color="textSecondary">
                      {freelancer.email}
                    </Typography>
                  </Box>
                </Box>
                {editing ? (
                  <TextField
                    fullWidth
                    name="phone"
                    label="Phone"
                    value={editedFreelancer.phone}
                    onChange={handleChange}
                    margin="normal"
                  />
                ) : (
                  <>
                    <Typography variant="body1">
                      <strong>Phone:</strong> {freelancer.phone}
                    </Typography>
                    <Typography variant="body1">
                      <strong>User Type:</strong> {freelancer.role}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Skills
                </Typography>
                <Autocomplete
                  freeSolo
                  options={predefinedSkills}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Add a skill"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "200px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  )}
                  value={newSkill}
                  onChange={(event, value) => setNewSkill(value || "")}
                  onKeyDown={(event) => handleAddSkill(event, newSkill)}
                  sx={{ mb: 2 }}
                />
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      onDelete={() => handleDeleteSkill(skill)}
                      sx={{
                        backgroundColor: getSkillColor(skill),
                        "&:hover": {
                          backgroundColor: getSkillColor(skill),
                          filter: "brightness(0.9)",
                        },
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ mb: 4 }}>
          <Box p={4}>
            <Typography variant="h6" gutterBottom>
              Independent Engineer Agreement
            </Typography>
            
            {isLoadingEngineerAgreement ? (
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
              </Box>
            ) : getAgreement.data ? (
              <Box>
                <Typography variant="body1" gutterBottom>
                  Current Agreement:
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <Link 
                    href={getAgreement.data.firebaseStorageUrl} 
                    onClick={handleViewEngineerAgreement}
                    sx={{ cursor: 'pointer' }}
                  >
                    Independent Engineer Agreement
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                    {getAgreement.data.sentDate && 
                      `Signed on ${DateTime.fromISO(getAgreement.data.sentDate).toFormat('LLL dd, yyyy')}`
                    }
                  </Typography>
                  {getAgreement.data.status === "signing" && (
                    <Typography variant="body2" color="warning.main">
                      (Pending Signatures)
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <Alert severity="info">
                No agreement has been uploaded yet.
              </Alert>
            )}
          </Box>
        </Paper>

        {/* Documents Section - Combined header and content */}
        <Paper
          elevation={3}
          sx={{ p: 3, mb: 3, mt: 3 }}
        >
          <Box display="flex" alignItems="center" mb={3} justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <DescriptionIcon sx={{ fontSize: 40, mr: 2 }} />
              <Typography variant="h5" component="h2">
                Documents
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                placeholder="Search documents"
                variant="outlined"
                size="small"
                value={documentSearchTerm}
                onChange={(e) => setDocumentSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                startIcon={<UploadFileIcon />}
                onClick={() => setOpenUploadDialog(true)}
              >
                Upload Document
              </Button>
            </Box>
          </Box>

          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Document Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Upload Date</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Document Date</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Document Type</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDocuments.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableCell>
                      {editingDocument === doc.id ? (
                        <TextField
                          name="name"
                          value={editedDocument?.name || ""}
                          onChange={handleDocumentChange}
                          onKeyDown={handleKeyDown}
                          autoFocus
                          size="small"
                          fullWidth
                        />
                      ) : (
                        doc.name
                      )}
                    </TableCell>
                    <TableCell>
                      {new Date(doc.uploadDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {editingDocument === doc.id ? (
                        <TextField
                          name="documentDate"
                          value={editedDocument?.documentDate || ""}
                          onChange={handleDocumentChange}
                          onKeyDown={handleKeyDown}
                          size="small"
                          fullWidth
                        />
                      ) : doc.documentDate ? (
                        new Date(doc.documentDate).toLocaleDateString()
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {editingDocument === doc.id ? (
                        <TextField
                          name="documentType"
                          value={editedDocument?.documentType || ""}
                          onChange={handleDocumentChange}
                          onKeyDown={handleKeyDown}
                          size="small"
                          fullWidth
                        />
                      ) : (
                        doc.documentType
                      )}
                    </TableCell>
                    <TableCell>
                      {editingDocument === doc.id ? (
                        <>
                          <Button size="small" onClick={handleUpdateDocument}>
                            Save
                          </Button>
                          <Button
                            size="small"
                            onClick={() => setEditingDocument(null)}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setSelectedFile({ url: doc.fileUrl, name: doc.name });
                            setOpenViewFileDialog(true);
                          }}
                        >
                          View
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Timesheet Overview Section */}
        <Paper
          elevation={3}
          sx={{ p: 3, mb: 3, mt: 3 }}
        >
          <Box display="flex" alignItems="center" mb={3}>
            <AccessTimeIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography variant="h5" component="h2">
              Timesheet Overview
            </Typography>
          </Box>

          {isLoadingTimesheets ? (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress />
            </Box>
          ) : (
            <>
              {/* Date Range Selector */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  gap: 2, 
                  mb: 3,
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'stretch', sm: 'center' }
                }}
              >
                <Typography variant="body1">Date Range:</Typography>
                <DatePicker
                  label="Start Date"
                  value={timesheetStartDate}
                  onChange={(newValue: DateTime | null) => newValue && setTimesheetStartDate(newValue)}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <Typography variant="body1">to</Typography>
                <DatePicker
                  label="End Date"
                  value={timesheetEndDate}
                  onChange={(newValue: DateTime | null) => newValue && setTimesheetEndDate(newValue)}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </Box>

              {/* Summary Statistics */}
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Total Hours
                      </Typography>
                      <Typography variant="h4">
                        {timesheetStats.totalHours.toFixed(1)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Billable Hours
                      </Typography>
                      <Typography variant="h4">
                        {timesheetStats.billableHours.toFixed(1)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {timesheetStats.totalHours > 0 
                          ? `${((timesheetStats.billableHours / timesheetStats.totalHours) * 100).toFixed(0)}% of total`
                          : '0% of total'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Avg. Hours/Week
                      </Typography>
                      <Typography variant="h4">
                        {timesheetStats.averageHoursPerWeek.toFixed(1)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Card>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Timesheets
                      </Typography>
                      <Typography variant="h4">
                        {timesheetStats.submittedTimesheets + timesheetStats.pendingTimesheets}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {timesheetStats.pendingTimesheets > 0 && 
                          `${timesheetStats.pendingTimesheets} pending`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* Hours by Client */}
              <Typography variant="h6" gutterBottom>
                Hours by Client
              </Typography>
              <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Client</TableCell>
                      <TableCell align="right">Hours</TableCell>
                      <TableCell align="right">% of Total</TableCell>
                      <TableCell>Distribution</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(timesheetStats.clientHours)
                      .sort(([, hoursA], [, hoursB]) => hoursB - hoursA)
                      .map(([clientName, hours]) => (
                        <TableRow key={clientName}>
                          <TableCell>{clientName}</TableCell>
                          <TableCell align="right">{hours.toFixed(1)}</TableCell>
                          <TableCell align="right">
                            {timesheetStats.totalHours > 0 
                              ? `${((hours / timesheetStats.totalHours) * 100).toFixed(0)}%`
                              : '0%'}
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>
                            <LinearProgress 
                              variant="determinate" 
                              value={timesheetStats.totalHours > 0 ? (hours / timesheetStats.totalHours) * 100 : 0}
                              sx={{ height: 10, borderRadius: 5 }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Hours by Project */}
              <Typography variant="h6" gutterBottom>
                Hours by Project
              </Typography>
              <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Project</TableCell>
                      <TableCell align="right">Hours</TableCell>
                      <TableCell align="right">% of Total</TableCell>
                      <TableCell>Distribution</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(timesheetStats.projectHours)
                      .sort(([, hoursA], [, hoursB]) => hoursB - hoursA)
                      .map(([projectName, hours]) => (
                        <TableRow key={projectName}>
                          <TableCell>{projectName}</TableCell>
                          <TableCell align="right">{hours.toFixed(1)}</TableCell>
                          <TableCell align="right">
                            {timesheetStats.totalHours > 0 
                              ? `${((hours / timesheetStats.totalHours) * 100).toFixed(0)}%`
                              : '0%'}
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>
                            <LinearProgress 
                              variant="determinate" 
                              value={timesheetStats.totalHours > 0 ? (hours / timesheetStats.totalHours) * 100 : 0}
                              sx={{ height: 10, borderRadius: 5 }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>

        {/* Project Assignments Section - Combined header and content */}
        <Paper
          elevation={3}
          sx={{ p: 3, mb: 3, mt: 3 }}
        >
          <Box display="flex" alignItems="center" mb={3}>
            <AssignmentIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography variant="h5" component="h2">
              Project Assignments
            </Typography>
          </Box>

          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>EverSign Status</TableCell>
                  <TableCell>Dates</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProjectAssignments.map((assignment) => (
                  <TableRow key={assignment.id}>
                    <TableCell>{assignment.projectName}</TableCell>
                    <TableCell>{assignment.clientName || assignment.clientId}</TableCell>
                    <TableCell>
                      <Chip
                        label={getStatusLabel(assignment.status)}
                        sx={{
                          bgcolor: getStatusColor(assignment.status),
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {getEverSignStatusIcon(assignment.everSignDocumentStatus)}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {getEverSignStatusLabel(assignment.everSignDocumentStatus)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {assignment.startDate
                          ? new Date(assignment.startDate).toLocaleDateString()
                          : "N/A"}
                        {" - "}
                        {assignment.endDate
                          ? new Date(assignment.endDate).toLocaleDateString()
                          : "Ongoing"}
                      </Typography>
                    </TableCell>
                    <TableCell>${assignment.billableRate}/hr</TableCell>
                    <TableCell>
                      <Box display="flex">
                        <IconButton 
                          size="small" 
                          onClick={() => handleViewAgreement(assignment)}
                          title="View Agreement"
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          onClick={(e) => handleAssignmentMenuClick(e, assignment)}
                          title="More Actions"
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Assignment Actions Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleAssignmentMenuClose}
        >
          <MenuItem onClick={() => handleUpdateAssignmentStatus("interviewing")}>
            <ListItemIcon>
              <Chip
                size="small"
                label="Interviewing"
                sx={{ bgcolor: getStatusColor("interviewing"), color: 'white' }}
              />
            </ListItemIcon>
            <ListItemText>Set to Interviewing</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleUpdateAssignmentStatus("ready")}>
            <ListItemIcon>
              <Chip
                size="small"
                label="Ready"
                sx={{ bgcolor: getStatusColor("ready"), color: 'white' }}
              />
            </ListItemIcon>
            <ListItemText>Set to Ready</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleUpdateAssignmentStatus("signing")}>
            <ListItemIcon>
              <Chip
                size="small"
                label="Signing"
                sx={{ bgcolor: getStatusColor("signing"), color: 'white' }}
              />
            </ListItemIcon>
            <ListItemText>Set to Signing</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleUpdateAssignmentStatus("in_progress")}>
            <ListItemIcon>
              <Chip
                size="small"
                label="In Progress"
                sx={{ bgcolor: getStatusColor("in_progress"), color: 'white' }}
              />
            </ListItemIcon>
            <ListItemText>Set to In Progress</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleUpdateAssignmentStatus("complete")}>
            <ListItemIcon>
              <Chip
                size="small"
                label="Complete"
                sx={{ bgcolor: getStatusColor("complete"), color: 'white' }}
              />
            </ListItemIcon>
            <ListItemText>Set to Complete</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleUpdateAssignmentStatus("cancelled")}>
            <ListItemIcon>
              <Chip
                size="small"
                label="Cancelled"
                sx={{ bgcolor: getStatusColor("cancelled"), color: 'white' }}
              />
            </ListItemIcon>
            <ListItemText>Set to Cancelled</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleOpenEverSignDialog}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Update EverSign Status</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleDeleteAssignmentClick(selectedAssignment!)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Delete Assignment</ListItemText>
          </MenuItem>
        </Menu>

        {/* EverSign Status Dialog */}
        <Dialog open={everSignStatusDialogOpen} onClose={handleCloseEverSignDialog}>
          <DialogTitle>Update EverSign Document Status</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Select
                value={selectedEverSignStatus || ""}
                onChange={(e) => setSelectedEverSignStatus(e.target.value as EverSignDocumentStatus)}
              >
                <MenuItem value="awaiting_admin_signature">Awaiting Admin Signature</MenuItem>
                <MenuItem value="awaiting_engineer_signature">Awaiting Engineer Signature</MenuItem>
                <MenuItem value="signed">Signed</MenuItem>
                <MenuItem value="declined">Declined</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEverSignDialog}>Cancel</Button>
            <Button onClick={handleUpdateEverSignStatus} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {selectedFile && (
        <ViewFileDialog
          open={openViewFileDialog}
          onClose={() => setOpenViewFileDialog(false)}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle>Upload Engineer Document</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Document Name"
            value={newDocument.name || ""}
            onChange={(e) =>
              setNewDocument({ ...newDocument, name: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="normal"
            label="Document Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={newDocument.documentDate || ""}
            onChange={(e) =>
              setNewDocument({ ...newDocument, documentDate: e.target.value })
            }
          />
          <TextField
            select
            fullWidth
            margin="normal"
            label="Document Type"
            value={newDocument.documentType || ""}
            onChange={(e) =>
              setNewDocument({ ...newDocument, documentType: e.target.value })
            }
          >
            {predefinedDocumentTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #cccccc",
              borderRadius: "4px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              mt: 2,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the PDF file here ...</Typography>
            ) : (
              <Typography>
                Drag and drop a PDF file here, or click to select a file
              </Typography>
            )}
          </Box>
          {uploadFile && (
            <Typography sx={{ mt: 2 }}>{uploadFile.name}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button
            onClick={handleUploadDocument}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, assignment: null })}
      >
        <DialogTitle sx={{ color: "error.main" }}>
          ⚠️ Warning: Destructive Action
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            You are about to delete the project assignment for:
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            {deleteConfirmation.assignment?.projectName}
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            This action cannot be undone. All associated data will be
            permanently deleted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDeleteConfirmation({ open: false, assignment: null })
            }
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAssignment}
            color="error"
            variant="contained"
            disabled={isDeletingAssignment}
            startIcon={
              isDeletingAssignment ? (
                <CircularProgress size={20} />
              ) : (
                <DeleteIcon />
              )
            }
          >
            {isDeletingAssignment ? "Deleting..." : "Delete Assignment"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FreelancerOverview;
