import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
  Alert,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Add as AddIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { useAdminCSAs, CreateCSARequest } from "hooks/admin/useAdminCSAs";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { CSAStatus, CSAType, BillableRate } from "@freetech/models/projects";
import { toast } from "react-hot-toast";
import { DateTime } from "luxon";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { PublicUserInfoType } from "@freetech/models/user";

// Empty CSA draft template
const emptyCSADraft = (): CreateCSARequest => ({
  clientId: "",
  projectId: "",
  name: "",
  effectiveDate: DateTime.now().toISODate(),
  endDate: null,
  csaType: "monthly",
  status: "draft",
  monthlyBudget: 0,
  fixedPriceBudget: 0,
  billableRates: [{ role: "", rate: 0 }],
  file: null,
  scopeOfWork: [],
  stakeholderId: "",
  stakeholderName: "",
  stakeholderEmail: "",
});

const CSAGenerator: React.FC = () => {
  const theme = useTheme();
  const { createCSA, generateCSAPdf } = useAdminCSAs();
  const { clients, isLoading: isLoadingClients } = useAdminClients();
  const { data: publicUsers = [], isLoading: isLoadingUsers } = usePublicUserList();
  const pdfPreviewRef = useRef<HTMLIFrameElement>(null);

  const [csaDraft, setCSADraft] = useState<CreateCSARequest>(emptyCSADraft());
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [hasLegalOwner, setHasLegalOwner] = useState(false);

  // Determine if form is disabled (during generation or submission)
  const isFormDisabled = isGenerating || isSubmitting;

  // Ensure scopeOfWork is always an array
  useEffect(() => {
    if (!Array.isArray(csaDraft.scopeOfWork)) {
      updateCSADraft({ 
        scopeOfWork: csaDraft.scopeOfWork ? 
          (typeof csaDraft.scopeOfWork === 'string' ? 
            (csaDraft.scopeOfWork as string).split('\n').filter(line => line.trim() !== '') : 
            [(csaDraft.scopeOfWork as any).toString()]) : 
          [] 
      });
    }
  }, []);

  // Check if the selected client has a legal owner
  useEffect(() => {
    if (csaDraft.clientId) {
      const selectedClient = clients?.find(client => client.id === csaDraft.clientId);
      const legalOwnerId = selectedClient?.legalOwnerFirebaseId;
      const legalOwnerExists = !!legalOwnerId && publicUsers.some(user => user.id === legalOwnerId);
      setHasLegalOwner(legalOwnerExists);
    } else {
      setHasLegalOwner(false);
    }
  }, [csaDraft.clientId, clients, publicUsers]);

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate the draft
    if (!isFormValid()) {
      if (!hasLegalOwner) {
        toast.error("A legal owner must be assigned to the client before generating a CSA");
      } else {
        toast.error("Please fill in all required fields");
      }
      return;
    }

    // Generate preview
    await generatePreview();
  };

  // Validate the form
  const isFormValid = () => {
    return (
      csaDraft.clientId &&
      csaDraft.projectId &&
      csaDraft.effectiveDate &&
      (csaDraft.csaType === "monthly"
        ? csaDraft.monthlyBudget && csaDraft.monthlyBudget > 0
        : true) &&
      (csaDraft.csaType === "fixed_price"
        ? csaDraft.fixedPriceBudget && csaDraft.fixedPriceBudget > 0
        : true) &&
      csaDraft.billableRates.every((rate) => rate.role && rate.rate > 0) &&
      hasLegalOwner // Require a legal owner
    );
  };

  // Generate PDF preview
  const generatePreview = async () => {
    setIsGenerating(true);

    try {
      // Get client data
      const clientData = clients?.find(client => client.id === csaDraft.clientId);
      const clientName = clientData?.companyName || "";
      
      // Get project data
      const projectId = csaDraft.projectId;
      const project = clientData?.projects?.find(p => p.id === projectId);
      const projectName = project?.name || "";
      
      // Ensure CSA name is set
      if (!csaDraft.name && clientName && projectName) {
        const name = generateCSAName(clientName, projectName);
        updateCSADraft({ name });
      }
      
      // Get legal owner information from public users list
      let stakeholderName = csaDraft.stakeholderName || "";
      let stakeholderEmail = csaDraft.stakeholderEmail || "";
      
      if (clientData?.legalOwnerFirebaseId && (!stakeholderName || !stakeholderEmail)) {
        const legalOwner = publicUsers.find(
          user => user.id === clientData.legalOwnerFirebaseId
        );
        
        if (legalOwner) {
          stakeholderName = legalOwner.displayName;
          stakeholderEmail = legalOwner.email;
        }
      }
      
      // Convert scope of work to array if needed
      const scopeOfWork = Array.isArray(csaDraft.scopeOfWork) 
        ? csaDraft.scopeOfWork 
        : (csaDraft.scopeOfWork ? csaDraft.scopeOfWork.split('\n') : []);
      
      // Generate PDF from template
      const blob = await generateCSAPdf.mutateAsync({
        clientName,
        projectName,
        effectiveDate: csaDraft.effectiveDate,
        endDate: csaDraft.endDate || undefined,
        monthlyBudget: csaDraft.monthlyBudget?.toString() || "0",
        scopeOfWork,
        billableRates: csaDraft.billableRates,
        stakeholderName,
        stakeholderEmail,
      });
      
      // Store the blob but don't create a URL yet
      setPdfBlob(blob);
      
      // Show preview dialog - URL will be created when the dialog is shown
      setShowPreviewDialog(true);
    } catch (error) {
      console.error("Error generating preview:", error);
      toast.error("Failed to generate preview");
    } finally {
      setIsGenerating(false);
    }
  };

  // Create URL for preview when dialog is shown
  useEffect(() => {
    if (showPreviewDialog && pdfBlob && !previewUrl) {
      // Create a URL for the blob only when the dialog is shown
      const url = URL.createObjectURL(pdfBlob);
      setPreviewUrl(url);
    }
  }, [showPreviewDialog, pdfBlob, previewUrl]);

  // Clean up URL when dialog is closed
  useEffect(() => {
    if (!showPreviewDialog && previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  }, [showPreviewDialog, previewUrl]);

  // Close preview dialog
  const handleClosePreview = () => {
    setShowPreviewDialog(false);
  };

  // Handle CSA generation directly from preview
  const handleCreateCSAFromPreview = async () => {
    setShowPreviewDialog(false);
    setIsSubmitting(true);

    try {
      if (!pdfBlob) {
        throw new Error("PDF not generated");
      }
      
      // Create a File object from the Blob
      const clientData = clients?.find(client => client.id === csaDraft.clientId);
      const clientName = clientData?.companyName || "";
      const file = new File([pdfBlob], `${clientName}_CSA_${csaDraft.effectiveDate}.pdf`, { type: 'application/pdf' });
      
      // Ensure stakeholder information is included
      if (clientData?.legalOwnerFirebaseId && (!csaDraft.stakeholderId || !csaDraft.stakeholderName || !csaDraft.stakeholderEmail)) {
        const legalOwner = publicUsers.find(
          user => user.id === clientData.legalOwnerFirebaseId
        );
        
        if (legalOwner) {
          csaDraft.stakeholderId = legalOwner.id;
          csaDraft.stakeholderName = legalOwner.displayName;
          csaDraft.stakeholderEmail = legalOwner.email;
        }
      }
      
      // Create the CSA with the generated PDF
      const result = await createCSA.mutateAsync({
        ...csaDraft,
        file,
      });

      // Reset form after successful creation
      setCSADraft(emptyCSADraft());
      toast.success(
        <div>
          <p>CSA draft created successfully!</p>
          <p>The draft has been saved to Firestore and Storage.</p>
          <p>Next steps: Use the draft to generate a signing document with eversign.</p>
        </div>,
        { duration: 5000 }
      );
      
      // Clean up
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
      }
      setPdfBlob(null);
    } catch (error) {
      console.error("Error creating CSA:", error);
      toast.error("Failed to create CSA");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update the CSA draft
  const updateCSADraft = (updates: Partial<CreateCSARequest>) => {
    setCSADraft({ ...csaDraft, ...updates });
  };

  // Handle client change
  const handleClientChange = async (clientId: string) => {
    const selectedClient = clients?.find(client => client.id === clientId);
    
    // Get legal owner information if available
    let stakeholderId = "";
    let stakeholderName = "";
    let stakeholderEmail = "";
    let hasOwner = false;
    
    if (selectedClient?.legalOwnerFirebaseId) {
      const legalOwner = publicUsers.find(
        user => user.id === selectedClient.legalOwnerFirebaseId
      );
      
      if (legalOwner) {
        stakeholderId = legalOwner.id;
        stakeholderName = legalOwner.displayName;
        stakeholderEmail = legalOwner.email;
        hasOwner = true;
      }
    }
    
    setHasLegalOwner(hasOwner);
    
    // Generate CSA name if project is selected
    let name = "";
    if (selectedClient) {
      const projectId = csaDraft.projectId;
      if (projectId) {
        const project = selectedClient.projects?.find(p => p.id === projectId);
        if (project) {
          name = generateCSAName(selectedClient.companyName, project.name);
        }
      }
    }
    
    updateCSADraft({ 
      clientId, 
      projectId: "",
      stakeholderId,
      stakeholderName,
      stakeholderEmail,
      name
    });
  };

  // Handle project change
  const handleProjectChange = (projectId: string) => {
    // Generate CSA name when project is selected
    let name = "";
    const selectedClient = clients?.find(client => client.id === csaDraft.clientId);
    if (selectedClient) {
      const project = selectedClient.projects?.find(p => p.id === projectId);
      if (project) {
        name = generateCSAName(selectedClient.companyName, project.name);
      }
    }
    
    updateCSADraft({ 
      projectId,
      name
    });
  };

  // Generate CSA name based on client, project, and date
  const generateCSAName = (clientName: string, projectName: string): string => {
    const dateStr = DateTime.now().toFormat("yyyy-MM-dd");
    return `${clientName} - ${projectName} - ${dateStr}`;
  };

  // Handle CSA type change
  const handleCSATypeChange = (csaType: CSAType) => {
    updateCSADraft({
      csaType,
      // Reset the budget fields based on type
      monthlyBudget:
        csaType === "monthly" ? csaDraft.monthlyBudget || 0 : 0,
      fixedPriceBudget:
        csaType === "fixed_price" ? csaDraft.fixedPriceBudget || 0 : 0,
    });
  };

  // Handle scope of work change
  const handleScopeOfWorkChange = (scopeOfWork: string) => {
    updateCSADraft({ scopeOfWork });
  };

  // Add a new scope item
  const addScopeItem = () => {
    const currentScope = Array.isArray(csaDraft.scopeOfWork) 
      ? [...csaDraft.scopeOfWork, ""] 
      : [""];
    
    updateCSADraft({ scopeOfWork: currentScope });
  };

  // Handle change to a specific scope item
  const handleScopeItemChange = (index: number, value: string) => {
    if (!Array.isArray(csaDraft.scopeOfWork)) {
      return;
    }
    
    const currentScope = [...csaDraft.scopeOfWork];
    currentScope[index] = value;
    updateCSADraft({ scopeOfWork: currentScope });
  };

  // Remove a scope item
  const removeScopeItem = (index: number) => {
    if (!Array.isArray(csaDraft.scopeOfWork)) {
      return;
    }
    
    const currentScope = [...csaDraft.scopeOfWork].filter((_, i) => i !== index);
    updateCSADraft({ scopeOfWork: currentScope });
  };

  // Handle billable rate changes
  const handleRoleChange = (
    rateIndex: number,
    role: string
  ) => {
    const updatedRates = [...csaDraft.billableRates];
    updatedRates[rateIndex].role = role;
    updateCSADraft({ billableRates: updatedRates });
  };

  const handleRateChange = (
    rateIndex: number,
    rate: number
  ) => {
    const updatedRates = [...csaDraft.billableRates];
    updatedRates[rateIndex].rate = rate;
    updateCSADraft({ billableRates: updatedRates });
  };

  const addBillableRate = () => {
    const updatedRates = [...csaDraft.billableRates, { role: "", rate: 0 }];
    updateCSADraft({ billableRates: updatedRates });
  };

  const removeBillableRate = (rateIndex: number) => {
    const updatedRates = csaDraft.billableRates.filter((_, i) => i !== rateIndex);
    updateCSADraft({ billableRates: updatedRates });
  };

  // Clean up when component unmounts
  React.useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 4,
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography variant="h5" gutterBottom>
          CSA Generator
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Generate a Consulting Services Agreement using the master template.
        </Typography>
      </Paper>

      <form onSubmit={handleSubmit}>
        <Card
          elevation={2}
          sx={{
            mb: 3,
            borderRadius: theme.shape.borderRadius,
            position: "relative",
            opacity: isFormDisabled ? 0.7 : 1,
            pointerEvents: isFormDisabled ? "none" : "auto",
          }}
        >
          {isFormDisabled && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                zIndex: 10,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">CSA Details</Typography>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Grid container spacing={3}>
              {/* Client Selection */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Client</InputLabel>
                  <Select
                    value={csaDraft.clientId}
                    label="Client"
                    onChange={(e) =>
                      handleClientChange(e.target.value as string)
                    }
                  >
                    {clients?.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Project Selection */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required disabled={!csaDraft.clientId}>
                  <InputLabel>Project</InputLabel>
                  <Select
                    value={csaDraft.projectId}
                    label="Project"
                    onChange={(e) =>
                      handleProjectChange(e.target.value as string)
                    }
                  >
                    {clients
                      ?.find((client) => client.id === csaDraft.clientId)
                      ?.projects?.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Effective Date */}
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Effective Date"
                  value={csaDraft.effectiveDate ? DateTime.fromISO(csaDraft.effectiveDate) : null}
                  onChange={(newValue) => {
                    updateCSADraft({
                      effectiveDate: newValue?.toISODate() || DateTime.now().toISODate(),
                    });
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      required: true,
                    },
                  }}
                />
              </Grid>

              {/* End Date (optional) */}
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="End Date (Optional)"
                  value={csaDraft.endDate ? DateTime.fromISO(csaDraft.endDate as string) : null}
                  onChange={(newValue) => {
                    updateCSADraft({
                      endDate: newValue?.toISODate() || null,
                    });
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>

              {/* CSA Type */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>CSA Type</InputLabel>
                  <Select
                    value={csaDraft.csaType}
                    label="CSA Type"
                    onChange={(e) =>
                      handleCSATypeChange(e.target.value as CSAType)
                    }
                  >
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="fixed_price">Fixed Price</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Monthly Budget (for monthly CSAs) */}
              {csaDraft.csaType === "monthly" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    label="Monthly Budget"
                    value={csaDraft.monthlyBudget}
                    onChange={(e) =>
                      updateCSADraft({
                        monthlyBudget: parseFloat(e.target.value) || 0,
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {/* Fixed Price Budget (for fixed price CSAs) */}
              {csaDraft.csaType === "fixed_price" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    label="Fixed Price Budget"
                    value={csaDraft.fixedPriceBudget}
                    onChange={(e) =>
                      updateCSADraft({
                        fixedPriceBudget: parseFloat(e.target.value) || 0,
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {/* Scope of Work */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                  Scope of Work
                </Typography>
                <Divider sx={{ mb: 3 }} />
                
                {/* Initialize as array if not already */}
                {!Array.isArray(csaDraft.scopeOfWork) && (
                  <Box sx={{ display: 'none' }}>
                    <Button 
                      sx={{ display: 'none' }}
                      onClick={() => updateCSADraft({ scopeOfWork: [] })}
                    >
                      Hidden
                    </Button>
                  </Box>
                )}
                
                {/* Display scope items as individual text fields */}
                {Array.isArray(csaDraft.scopeOfWork) && csaDraft.scopeOfWork.map((item, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      mb: 2,
                      gap: 2 
                    }}
                  >
                    <Typography sx={{ minWidth: '30px' }}>
                      {index + 1}.
                    </Typography>
                    <TextField
                      fullWidth
                      value={item}
                      placeholder={`Scope item ${index + 1}`}
                      onChange={(e) => handleScopeItemChange(index, e.target.value)}
                    />
                    <IconButton 
                      color="error" 
                      onClick={() => removeScopeItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                
                <Button
                  startIcon={<AddIcon />}
                  onClick={addScopeItem}
                  sx={{ mt: 1 }}
                >
                  Add Scope Item
                </Button>
              </Grid>
            </Grid>

            {/* Billable Rates */}
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
              Billable Rates
            </Typography>
            <Divider sx={{ mb: 3 }} />

            {csaDraft.billableRates.map((rate, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  gap: 2,
                }}
              >
                <TextField
                  label="Role"
                  value={rate.role}
                  onChange={(e) => handleRoleChange(index, e.target.value)}
                  required
                  sx={{ flex: 2 }}
                />
                <TextField
                  label="Rate ($/hr)"
                  type="number"
                  value={rate.rate}
                  onChange={(e) =>
                    handleRateChange(index, parseFloat(e.target.value) || 0)
                  }
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{ flex: 1 }}
                />
                {csaDraft.billableRates.length > 1 && (
                  <IconButton
                    color="error"
                    onClick={() => removeBillableRate(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}

            <Button
              startIcon={<AddIcon />}
              onClick={addBillableRate}
              sx={{ mt: 1 }}
            >
              Add Billable Rate
            </Button>
          </CardContent>
        </Card>

        {/* Legal Owner Information */}
        <Card
          elevation={2}
          sx={{
            mb: 3,
            borderRadius: theme.shape.borderRadius,
            position: "relative",
            opacity: isFormDisabled ? 0.7 : 1,
            pointerEvents: isFormDisabled ? "none" : "auto",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">Legal Owner Information</Typography>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Typography variant="body1" sx={{ mb: 2 }}>
              This document will be prefilled with the legal owner information for this company. The legal owner will be responsible for signing the agreement.
            </Typography>

            {isLoadingUsers ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : csaDraft.clientId ? (
              <Box sx={{ mt: 2 }}>
                {clients?.find(client => client.id === csaDraft.clientId)?.legalOwnerFirebaseId ? (
                  <Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Legal Owner:
                    </Typography>
                    {(() => {
                      const clientData = clients?.find(client => client.id === csaDraft.clientId);
                      const legalOwnerId = clientData?.legalOwnerFirebaseId;
                      const legalOwner = publicUsers.find(user => user.id === legalOwnerId);
                      
                      return legalOwner ? (
                        <>
                          <Typography variant="body1">
                            {legalOwner.displayName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {legalOwner.email}
                          </Typography>
                        </>
                      ) : (
                        <Alert severity="warning" sx={{ mt: 2 }}>
                          User with ID {legalOwnerId} not found in the system.
                        </Alert>
                      );
                    })()}
                  </Box>
                ) : (
                  <Alert severity="warning" sx={{ mt: 2 }}>
                    No legal owner has been assigned to this client. Please assign a legal owner in the Client Management section.
                  </Alert>
                )}
              </Box>
            ) : (
              <Alert severity="info" sx={{ mt: 2 }}>
                Select a client to view legal owner information.
              </Alert>
            )}
          </CardContent>
        </Card>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={isFormDisabled || !isFormValid()}
            sx={{ minWidth: 200 }}
          >
            {isGenerating ? (
              <>
                <CircularProgress size={24} sx={{ mr: 1 }} />
                Generating Preview...
              </>
            ) : (
              "Generate Preview"
            )}
          </Button>
        </Box>
      </form>

      {/* Preview Dialog */}
      <Dialog
        open={showPreviewDialog}
        onClose={handleClosePreview}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>CSA Preview</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Review the generated CSA document before finalizing.
          </DialogContentText>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            <strong>CSA Name:</strong> {csaDraft.name}
          </Typography>
          <Box sx={{ width: "100%", height: "70vh" }}>
            {previewUrl && (
              <iframe
                ref={pdfPreviewRef}
                src={previewUrl}
                style={{ width: "100%", height: "100%", border: "none" }}
                title="CSA Preview"
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPreviewDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateCSAFromPreview} 
            color="primary" 
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={24} sx={{ mr: 1 }} />
                Creating CSA...
              </>
            ) : (
              "Create CSA"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CSAGenerator;
