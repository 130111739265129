import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Grid,
  Divider,
  Chip,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Stack,
  useTheme,
  Container,
  Avatar,
  Fade,
  Zoom
} from '@mui/material';
import {
  CreditCard as CreditCardIcon,
  CheckCircleOutline as CheckCircleIcon,
  ErrorOutline as ErrorIcon,
  CalendarToday as CalendarIcon,
  Cancel as CancelIcon,
  Autorenew as AutorenewIcon,
  Payment as PaymentIcon,
  History as HistoryIcon
} from '@mui/icons-material';
import { useAuth } from 'hooks/auth';
import { DateTime } from 'luxon';
import { useStudentManageSubscription } from 'hooks/student/useStudentManageSubscription';
import { useLocation, useNavigate } from 'react-router-dom';
import { StudentSubscription } from 'types/student';
import SubscriptionProducts from './SubscriptionProducts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Define subscription plan options
const SUBSCRIPTION_PLANS = [
  {
    id: 'monthly_plan',
    name: 'Monthly',
    description: 'Get access to all FreeTech student resources with monthly billing',
    price: 49.99,
    interval: 'month',
    stripePriceId: 'price_1OcXwSGAWWmzn7hCVeKz8K66', // This would be your actual Stripe price ID
  },
  {
    id: 'annual_plan',
    name: 'Annual',
    description: 'Save 17% with annual billing',
    price: 499.99,
    interval: 'year',
    stripePriceId: 'price_1OcXwSGAWWmzn7hCVeKz8K67', // This would be your actual Stripe price ID
    popular: true,
  }
];

// Format a date using Luxon
const formatDate = (dateString: string) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED);
};

// Map status to display text and color
const getStatusInfo = (status: string) => {
  switch (status) {
    case 'active':
      return { label: 'Active', color: 'success', icon: <CheckCircleIcon /> };
    case 'trialing':
      return { label: 'Trial', color: 'info', icon: <AutorenewIcon /> };
    case 'past_due':
      return { label: 'Past Due', color: 'warning', icon: <ErrorIcon /> };
    case 'canceled':
      return { label: 'Canceled', color: 'error', icon: <CancelIcon /> };
    case 'unpaid':
      return { label: 'Unpaid', color: 'error', icon: <ErrorIcon /> };
    default:
      return { label: status, color: 'default', icon: <ErrorIcon /> };
  }
};

export const StudentManageSubscription: React.FC = () => {
  const theme = useTheme();
  const { userInfo } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  // Subscription management hook
  const {
    subscription,
    isLoading,
    error: subscriptionError,
    cancelSubscription,
    reactivateSubscription,
    openCustomerPortal,
    isReactivating,
    isCancelling
  } = useStudentManageSubscription();
  
  // Local UI state
  const [selectedPlan, setSelectedPlan] = useState<typeof SUBSCRIPTION_PLANS[0] | null>(null);
  const [showPurchaseConfirmDialog, setShowPurchaseConfirmDialog] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [reactivateSuccess, setReactivateSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Check URL for status messages
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const subscriptionStatus = params.get('subscription');
    
    if (subscriptionStatus === 'success') {
      // Clear the URL parameter
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('subscription');
      window.history.replaceState({}, '', newUrl);
    }
  }, [location]);
  
  // Set error from subscription hook
  useEffect(() => {
    if (subscriptionError) {
      setError('Failed to load subscription information. Please try again later.');
    }
  }, [subscriptionError]);

  const handleSubscribe = (plan: typeof SUBSCRIPTION_PLANS[0]) => {
    setSelectedPlan(plan);
    setShowPurchaseConfirmDialog(true);
  };

  const handleConfirmSubscribe = async () => {
    if (selectedPlan) {
      try {
        await openCustomerPortal();
        // The openCustomerPortal function will redirect automatically
      } catch (err) {
        console.error("Error creating subscription:", err);
        setError("Failed to create subscription. Please try again later.");
      }
    }
    setShowPurchaseConfirmDialog(false);
  };

  const handleCancelSubscription = async () => {
    if (!cancelConfirm) {
      setCancelConfirm(true);
      return;
    }

    try {
      await cancelSubscription(true);
      setCancelSuccess(true);
      setCancelConfirm(false);
    } catch (err) {
      console.error('Error cancelling subscription:', err);
      setError('Failed to cancel subscription. Please try again later.');
    }
  };

  const handleReactivateSubscription = async () => {
    try {
      const result = await reactivateSubscription();
      
      if (result.success) {
        setReactivateSuccess(true);
      }
    } catch (err) {
      console.error('Error reactivating subscription:', err);
      setError('Failed to reactivate subscription. Please try again later.');
    }
  };

  const handleOpenCustomerPortal = async () => {
    try {
      await openCustomerPortal();
    } catch (err) {
      console.error('Error opening customer portal:', err);
      setError('Failed to open payment management portal. Please try again later.');
    }
  };

  const hasActiveSubscription = 
    subscription !== null && 
    subscription.status === 'active';

  const isCanceled = 
    subscription !== null && 
    (subscription.status === 'canceled' ||
     subscription.cancelAtPeriodEnd);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <Fade in={true} timeout={800}>
          <CircularProgress size={60} thickness={4} />
        </Fade>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" p={4} sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
        <ErrorIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
        <Typography color="error" variant="h5" gutterBottom>
          Oops! Something went wrong
        </Typography>
        <Typography color="text.secondary" variant="body1" gutterBottom sx={{ mb: 3 }}>
          {error}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => window.location.reload()}
          sx={{ mt: 2 }}
          size="large"
        >
          Try Again
        </Button>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Zoom in={true} timeout={500}>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          mb={4} 
          fontWeight="bold"
          sx={{ 
            textAlign: { xs: 'center', md: 'left' },
            borderBottom: `4px solid ${theme.palette.primary.main}`,
            display: 'inline-block',
            pb: 1
          }}
        >
          Subscription Management
        </Typography>
      </Zoom>

      {location.search.includes('subscription=success') && (
        <Fade in={true} timeout={1000}>
          <Alert 
            severity="success" 
            variant="filled"
            sx={{ 
              mb: 4, 
              borderRadius: 2,
              boxShadow: 2
            }}
            icon={<CheckCircleIcon fontSize="inherit" />}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Your subscription has been successfully activated! You now have full access to all student resources.
            </Typography>
          </Alert>
        </Fade>
      )}

      {cancelSuccess && (
        <Fade in={true} timeout={1000}>
          <Alert 
            severity="info" 
            variant="filled" 
            sx={{ 
              mb: 4, 
              borderRadius: 2,
              boxShadow: 2
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Your subscription has been cancelled and will end at the end of your current billing period.
            </Typography>
          </Alert>
        </Fade>
      )}

      {reactivateSuccess && (
        <Fade in={true} timeout={1000}>
          <Alert 
            severity="success" 
            variant="filled"
            sx={{ 
              mb: 4, 
              borderRadius: 2,
              boxShadow: 2
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Your subscription has been successfully reactivated!
            </Typography>
          </Alert>
        </Fade>
      )}

      {subscription ? (
        <Fade in={true}>
          <Box>
            <Paper 
              elevation={3} 
              sx={{ 
                mb: 5, 
                overflow: 'hidden',
                borderRadius: 2,
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'translateY(-4px)'
                }
              }}
            >
              <Box 
                bgcolor="primary.main" 
                color="primary.contrastText" 
                py={2.5} 
                px={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5
                }}
              >
                <CreditCardIcon sx={{ fontSize: 28 }} />
                <Typography variant="h6" fontWeight="bold">
                  Subscription Details
                </Typography>
              </Box>
              
              <CardContent sx={{ p: 4 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        SUBSCRIPTION PLAN
                      </Typography>
                      <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'medium' }}>
                        {subscription.planName || 'FreeTech Student Subscription'}
                      </Typography>
                      
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          mt: 1, 
                          mb: 2 
                        }}
                      >
                        {getStatusInfo(subscription.status).icon}
                        <Chip 
                          label={getStatusInfo(subscription.status).label}
                          color={getStatusInfo(subscription.status).color as any}
                          size="small"
                          sx={{ ml: 1, fontWeight: 'medium' }}
                        />
                        
                        {subscription.cancelAtPeriodEnd && (
                          <Chip 
                            label="Cancels at period end" 
                            color="warning"
                            size="small"
                            sx={{ ml: 1, fontWeight: 'medium' }}
                          />
                        )}
                      </Box>
                      
                      <Divider sx={{ my: 2.5 }} />
                      
                      <Stack spacing={2.5}>
                        <Box display="flex" alignItems="center">
                          <Avatar 
                            sx={{ 
                              bgcolor: theme.palette.grey[100], 
                              color: theme.palette.grey[700],
                              mr: 2,
                              width: 36,
                              height: 36
                            }}
                          >
                            <PaymentIcon />
                          </Avatar>
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Price
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                              ${subscription.amount.toFixed(2)}/month
                            </Typography>
                          </Box>
                        </Box>
                        
                        <Box display="flex" alignItems="center">
                          <Avatar 
                            sx={{ 
                              bgcolor: theme.palette.grey[100], 
                              color: theme.palette.grey[700],
                              mr: 2,
                              width: 36,
                              height: 36
                            }}
                          >
                            <CalendarIcon />
                          </Avatar>
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Current Period
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                              {formatDate(subscription.currentPeriodStart)} - {formatDate(subscription.currentPeriodEnd)}
                            </Typography>
                          </Box>
                        </Box>
                        
                        <Box display="flex" alignItems="center">
                          <Avatar 
                            sx={{ 
                              bgcolor: theme.palette.grey[100], 
                              color: theme.palette.grey[700],
                              mr: 2,
                              width: 36,
                              height: 36
                            }}
                          >
                            <HistoryIcon />
                          </Avatar>
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Started On
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                              {formatDate(subscription.createdAt)}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Box sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          PAYMENT MANAGEMENT
                        </Typography>
                        <Typography variant="body1" paragraph>
                          Manage your payment methods, view invoices, and update billing information through our secure payment portal.
                        </Typography>
                        
                        <Divider sx={{ my: 2.5 }} />
                      </Box>
                      
                      <Box sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CreditCardIcon />}
                            onClick={handleOpenCustomerPortal}
                            fullWidth
                            size="large"
                            sx={{ py: 1.5 }}
                          >
                            Manage Payment Methods
                          </Button>
                          
                          {!isCanceled ? (
                            <Button
                              variant="outlined"
                              color="error"
                              startIcon={<CancelIcon />}
                              onClick={handleCancelSubscription}
                              fullWidth
                              disabled={isCancelling}
                              size="large"
                              sx={{ py: 1.5 }}
                            >
                              {isCancelling ? (
                                <CircularProgress size={24} />
                              ) : cancelConfirm ? (
                                "Confirm Cancellation"
                              ) : (
                                "Cancel Subscription"
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<AutorenewIcon />}
                              onClick={handleReactivateSubscription}
                              fullWidth
                              disabled={isReactivating}
                              size="large"
                              sx={{ py: 1.5 }}
                            >
                              {isReactivating ? (
                                <CircularProgress size={24} />
                              ) : (
                                "Reactivate Subscription"
                              )}
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Paper>
          </Box>
        </Fade>
      ) : (
        <Fade in={true}>
          <Box>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 4, 
                mb: 4, 
                borderRadius: 2,
                boxShadow: 3
              }}
            >
              <Typography variant="h6" gutterBottom fontWeight="bold">
                No Active Subscription
              </Typography>
              <Typography variant="body1" paragraph color="text.secondary">
                You currently don't have an active subscription. Subscribe to get full access to FreeTech student resources.
              </Typography>
              <SubscriptionProducts />
            </Paper>
          </Box>
        </Fade>
      )}

      {/* Dialogs */}
      <Dialog
        open={showPurchaseConfirmDialog}
        onClose={() => setShowPurchaseConfirmDialog(false)}
        aria-labelledby="purchase-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1
          }
        }}
      >
        <DialogTitle id="purchase-dialog-title" sx={{ pb: 1 }}>
          Confirm Subscription
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to subscribe to the {selectedPlan?.name} plan for ${(selectedPlan?.price || 0).toFixed(2)}/{selectedPlan?.interval}. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button 
            onClick={() => setShowPurchaseConfirmDialog(false)} 
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmSubscribe} 
            autoFocus 
            variant="contained"
            color="primary"
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}; 