// Days of the week
export const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;

// User roles
export const USER_ROLES = {
  ADMIN: 'admin',
  FREELANCER: 'freelancer',
  STAKEHOLDER: 'stakeholder',
  STUDENT: 'student',
  SUPER_USER: 'superuser',
} as const;

// Status options
export const STATUS_OPTIONS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  COMPLETED: 'completed',
} as const; 