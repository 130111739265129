import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { logoNoBackground, authBackground } from "assets";

declare module "@mui/material/styles" {
  interface Theme {
    gradients: {
      dark: string;
      darkCard: string;
      referralCard: string;
    };
    customComponents: {
      card: {
        white: object;
        dark: object;
        static: object;
        referral: object;
        darkSection: object;
      };
      container: {
        main: object;
        dark: object;
      };
    };
  }
  interface ThemeOptions {
    gradients?: {
      dark?: string;
      darkCard?: string;
      referralCard?: string;
    };
    customComponents?: {
      card?: {
        white?: object;
        dark?: object;
        static?: object;
        referral?: object;
        darkSection?: object;
      };
      container?: {
        main?: object;
        dark?: object;
      };
    };
  }
}

// Basic Theme
export const theme = responsiveFontSizes(
  createTheme({
    spacing: 8, // default spacing
    shape: {
      borderRadius: 4, // default border radius
    },
    gradients: {
      dark: "linear-gradient(145deg, #3a3a3a 0%, #2d2d2d 100%)",
      darkCard: "linear-gradient(145deg, #2d2d2d 0%, #1f1f1f 100%)",
      referralCard: "linear-gradient(225deg, rgba(13,71,161,0.1) 0%, rgba(0,0,0,0) 100%)",
    },
    palette: {
      mode: "light",
      primary: {
        main: "#000000",
        light: "#424242",
        dark: "#212121",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#424242",
        light: "#6d6d6d",
        dark: "#1b1b1b",
        contrastText: "#ffffff",
      },
      error: {
        main: "#d32f2f",
        light: "#ef5350",
        dark: "#c62828",
      },
      warning: {
        main: "#ed6c02",
        light: "#ff9800",
        dark: "#e65100",
      },
      info: {
        main: "#0288d1",
        light: "#03a9f4",
        dark: "#01579b",
      },
      success: {
        main: "#2e7d32",
        light: "#4caf50",
        dark: "#1b5e20",
      },
      grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
        A100: "#f5f5f5",
        A200: "#eeeeee",
        A400: "#bdbdbd",
        A700: "#616161",
      },
      background: {
        default: "#f5f5f5",
        paper: "#ffffff",
      },
      text: {
        primary: "#212121",
        secondary: "#757575",
        disabled: "#9e9e9e",
      },
      divider: "rgba(0, 0, 0, 0.12)",
    },
    typography: {
      fontFamily: "Outfit, sans-serif",
      fontSize: 14,
      h1: {
        fontSize: "2.5rem",
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: "-0.01562em",
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 700,
        lineHeight: 1.3,
        letterSpacing: "-0.00833em",
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 600,
        lineHeight: 1.35,
        letterSpacing: "0em",
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 600,
        lineHeight: 1.4,
        letterSpacing: "0.00735em",
      },
      h5: {
        fontSize: "1.25rem",
        fontWeight: 600,
        lineHeight: 1.45,
        letterSpacing: "0em",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 600,
        lineHeight: 1.6,
        letterSpacing: "0.0075em",
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1.75,
        letterSpacing: "0.00938em",
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: 1.57,
        letterSpacing: "0.00714em",
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
      },
      button: {
        fontSize: "0.875rem",
        fontWeight: 600,
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: "none",
      },
      caption: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: "0.03333em",
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 500,
        lineHeight: 2.66,
        letterSpacing: "0.08333em",
        textTransform: "uppercase",
      },
    },
    shadows: [
      "none",
      "0px 2px 1px -1px rgba(0,0,0,0.08),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)",
      "0px 3px 1px -2px rgba(0,0,0,0.08),0px 2px 2px 0px rgba(0,0,0,0.07),0px 1px 5px 0px rgba(0,0,0,0.06)",
      "0px 3px 3px -2px rgba(0,0,0,0.08),0px 3px 4px 0px rgba(0,0,0,0.07),0px 1px 8px 0px rgba(0,0,0,0.06)",
      "0px 2px 4px -1px rgba(0,0,0,0.08),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.06)",
      "0px 3px 5px -1px rgba(0,0,0,0.08),0px 5px 8px 0px rgba(0,0,0,0.07),0px 1px 14px 0px rgba(0,0,0,0.06)",
      "0px 3px 5px -1px rgba(0,0,0,0.08),0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.06)",
      "0px 4px 5px -2px rgba(0,0,0,0.08),0px 7px 10px 1px rgba(0,0,0,0.07),0px 2px 16px 1px rgba(0,0,0,0.06)",
      "0px 5px 5px -3px rgba(0,0,0,0.08),0px 8px 10px 1px rgba(0,0,0,0.07),0px 3px 14px 2px rgba(0,0,0,0.06)",
      "0px 5px 6px -3px rgba(0,0,0,0.08),0px 9px 12px 1px rgba(0,0,0,0.07),0px 3px 16px 2px rgba(0,0,0,0.06)",
      "0px 6px 6px -3px rgba(0,0,0,0.08),0px 10px 14px 1px rgba(0,0,0,0.07),0px 4px 18px 3px rgba(0,0,0,0.06)",
      "0px 6px 7px -4px rgba(0,0,0,0.08),0px 11px 15px 1px rgba(0,0,0,0.07),0px 4px 20px 3px rgba(0,0,0,0.06)",
      "0px 7px 8px -4px rgba(0,0,0,0.08),0px 12px 17px 2px rgba(0,0,0,0.07),0px 5px 22px 4px rgba(0,0,0,0.06)",
      "0px 7px 8px -4px rgba(0,0,0,0.08),0px 13px 19px 2px rgba(0,0,0,0.07),0px 5px 24px 4px rgba(0,0,0,0.06)",
      "0px 7px 9px -4px rgba(0,0,0,0.08),0px 14px 21px 2px rgba(0,0,0,0.07),0px 5px 26px 4px rgba(0,0,0,0.06)",
      "0px 8px 9px -5px rgba(0,0,0,0.08),0px 15px 22px 2px rgba(0,0,0,0.07),0px 6px 28px 5px rgba(0,0,0,0.06)",
      "0px 8px 10px -5px rgba(0,0,0,0.08),0px 16px 24px 2px rgba(0,0,0,0.07),0px 6px 30px 5px rgba(0,0,0,0.06)",
      "0px 8px 11px -5px rgba(0,0,0,0.08),0px 17px 26px 2px rgba(0,0,0,0.07),0px 6px 32px 5px rgba(0,0,0,0.06)",
      "0px 9px 11px -5px rgba(0,0,0,0.08),0px 18px 28px 2px rgba(0,0,0,0.07),0px 7px 34px 6px rgba(0,0,0,0.06)",
      "0px 9px 12px -6px rgba(0,0,0,0.08),0px 19px 29px 2px rgba(0,0,0,0.07),0px 7px 36px 6px rgba(0,0,0,0.06)",
      "0px 10px 13px -6px rgba(0,0,0,0.08),0px 20px 31px 3px rgba(0,0,0,0.07),0px 8px 38px 7px rgba(0,0,0,0.06)",
      "0px 10px 13px -6px rgba(0,0,0,0.08),0px 21px 33px 3px rgba(0,0,0,0.07),0px 8px 40px 7px rgba(0,0,0,0.06)",
      "0px 10px 14px -6px rgba(0,0,0,0.08),0px 22px 35px 3px rgba(0,0,0,0.07),0px 8px 42px 7px rgba(0,0,0,0.06)",
      "0px 11px 14px -7px rgba(0,0,0,0.08),0px 23px 36px 3px rgba(0,0,0,0.07),0px 9px 44px 8px rgba(0,0,0,0.06)",
      "0px 11px 15px -7px rgba(0,0,0,0.08),0px 24px 38px 3px rgba(0,0,0,0.07),0px 9px 46px 8px rgba(0,0,0,0.06)",
    ],
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            // background: "linear-gradient(135deg, #0062ff 0%, #00a6ff 100%)",
            // background: "linear-gradient(145deg, #3a3a3a 0%, #2d2d2d 100%)",
            // background: "linear-gradient(145deg, #262626 0%,  #00a6ff 100%)",
            // background: "linear-gradient(145deg, #262626 20%, #262626 30%, rgba(0, 166, 255, 0.3) 42%, rgba(0, 166, 255, 0.3) 52%, #262626 60%, #262626 100%)",

            // background: "linear-gradient(145deg, #1a1a1a 0%, #121212 100%)",
            background: "linear-gradient(145deg, #2d2d2d 0%, #1f1f1f 100%)",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderRadius: 8,
            fontWeight: 600,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
          contained: {
            "&:hover": {
              boxShadow: "none",
            },
          },
          outlined: {
            borderWidth: 2,
            "&:hover": {
              borderWidth: 2,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&[aria-label="open drawer"], &[aria-label="close drawer"]': {
              color: "#ffffff",
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              border: "2px solid rgba(0, 0, 0, 0.87)",
              borderRadius: "4px",
              color: "#455573",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
          elevation1: {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
          },
          elevation2: {
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
          },
        },
      },
    },
    customComponents: {
      card: {
        white: {
          bgcolor: "#ffffff",
          borderRadius: 2,
          border: "1px solid",
          borderColor: "grey.200",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
          p: 2.5,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
        dark: {
          background: "linear-gradient(145deg, #2d2d2d 0%, #1f1f1f 100%)",
          border: "1px solid",
          borderColor: "grey.800",
          boxShadow: "0px 12px 32px rgba(0, 0, 0, 0.18)",
          borderRadius: 3,
          p: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          "& .MuiTypography-root": {
            color: "grey.100",
          },
          "& .MuiTypography-secondary": {
            color: "grey.400",
          }
        },
        static: {
          bgcolor: "#ffffff",
          borderRadius: 2,
          border: "1px solid",
          borderColor: "grey.200",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
          p: 2.5,
          height: "100%",
        },
        referral: {
          bgcolor: "#1a1a1a",
          borderRadius: 2,
          p: 2.5,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(255, 255, 255, 0.05)",
          backdropFilter: "blur(8px)",
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "linear-gradient(225deg, rgba(13,71,161,0.15) 0%, rgba(0,0,0,0) 100%)",
            zIndex: 0,
          },
          "&:after": {
            content: '""',
            position: "absolute",
            top: -100,
            left: -100,
            right: -100,
            bottom: -100,
            background: "radial-gradient(circle at center, rgba(25,118,210,0.15) 0%, rgba(0,0,0,0) 70%)",
            animation: "pulse 3s ease-in-out infinite",
          },
          "& > *": {
            position: "relative",
            zIndex: 1,
          },
        },
        darkSection: {
          background: "linear-gradient(145deg, #1a1a1a 0%, #121212 100%)",
          borderRadius: 2,
          p: 3,
          height: "100%",
          border: "1px solid",
          borderColor: "grey.900",
          boxShadow: "0px 12px 32px rgba(0, 0, 0, 0.25)",
          "& .MuiTypography-root": {
            color: "grey.100",
          },
          "& .MuiTypography-secondary": {
            color: "grey.400",
          },
          "& .section-content": {
            bgcolor: "white",
            borderRadius: 2,
            p: 3,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
            border: "1px solid",
            borderColor: "grey.200",
          }
        },
      },
      container: {
        main: {
          bgcolor: "grey.100",
          p: { xs: 2, md: 4 },
        },
        dark: {
          bgcolor: "#121212",
          p: { xs: 2, md: 4 },
        }
      }
    }
  })
);

// Custom Styles
export const authTextFieldStyle = {
  borderRadius: theme.shape.borderRadius,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
      borderWidth: 2,
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(0, 0, 0, 0.6)",
    "&.Mui-focused": {
      color: "black",
    },
  },
  "& .MuiInputBase-input": {
    color: "black",
  },
};
