import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Chip,
  IconButton,
  CircularProgress,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Work as WorkIcon,
} from "@mui/icons-material";
import { useAdminCareers } from "hooks/careers/useAdminCareers";
import {
  JobCategory,
  JobLocation,
  JobStatus,
  JobType,
  JobListingCreate,
  JobListing,
} from "@freetech/models/careers";
import { useConfirm } from "material-ui-confirm";
import { useAuth } from "hooks/auth/useAuth";

const JOB_CATEGORIES = Object.values(JobCategory);
const JOB_TYPES = Object.values(JobType);
const JOB_LOCATIONS = Object.values(JobLocation);

const JobStatusChip = ({ status }: { status: JobStatus }) => {
  const getChipProps = (status: JobStatus) => {
    switch (status) {
      case JobStatus.Draft:
        return { color: "default" as const };
      case JobStatus.Published:
        return { color: "success" as const };
      case JobStatus.Closed:
        return { color: "error" as const };
      case JobStatus.Hidden:
        return { color: "warning" as const };
    }
  };

  return (
    <Chip
      label={status.replace("_", " ").toUpperCase()}
      size="small"
      {...getChipProps(status)}
    />
  );
};

const CreateJobDialog = ({
  open,
  onClose,
  onCreate,
}: {
  open: boolean;
  onClose: () => void;
  onCreate: (job: JobListingCreate) => Promise<void>;
}) => {
  const [formData, setFormData] = useState({
    title: "",
    shortDescription: "",
    description: "",
    category: JobCategory.Engineering,
    jobType: JobType.FullTime,
    location: JobLocation.Remote,
    requirements: [""],
    responsibilities: [""],
    salary: {
      min: 0,
      max: 0,
    },
    hourlyRate: {
      min: 0,
      max: 0,
    },
  });
  const [compensationType, setCompensationType] = useState<"salary" | "hourly">(
    "salary"
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleArrayChange = (
    field: "requirements" | "responsibilities",
    index: number,
    value: string
  ) => {
    const newArray = [...formData[field]];
    newArray[index] = value;
    setFormData({ ...formData, [field]: newArray });
  };

  const handleAddArrayItem = (field: "requirements" | "responsibilities") => {
    setFormData({
      ...formData,
      [field]: [...formData[field], ""],
    });
  };

  const handleRemoveArrayItem = (
    field: "requirements" | "responsibilities",
    index: number
  ) => {
    const newArray = [...formData[field]];
    newArray.splice(index, 1);
    setFormData({ ...formData, [field]: newArray });
  };

  const handleSalaryChange = (field: "min" | "max", value: any) => {
    setFormData({
      ...formData,
      salary: {
        ...formData.salary,
        [field]: Number(value),
      },
    });
  };

  const handleHourlyRateChange = (field: "min" | "max", value: any) => {
    setFormData({
      ...formData,
      hourlyRate: {
        ...(formData.hourlyRate || { min: 0, max: 0 }),
        [field]: Number(value),
      },
    });
  };

  const handleCompensationTypeChange = (type: "salary" | "hourly") => {
    setCompensationType(type);
    // When switching compensation type, ensure the appropriate field is set
    if (type === "salary" && !formData.salary) {
      setFormData({
        ...formData,
        salary: { min: 0, max: 0 },
        hourlyRate: { min: 0, max: 0 },
      });
    } else if (type === "hourly" && !formData.hourlyRate) {
      setFormData({
        ...formData,
        hourlyRate: { min: 0, max: 0 },
        salary: { min: 0, max: 0 },
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const now = new Date().toISOString();

      // Prepare job data based on compensation type
      const jobData: any = {
        ...formData,
        status: JobStatus.Draft,
        createdAt: now,
        updatedAt: now,
        requirements: formData.requirements.filter(Boolean),
        responsibilities: formData.responsibilities.filter(Boolean),
      };

      // Ensure only the selected compensation type is included
      if (compensationType === "salary") {
        delete jobData.hourlyRate;
      } else {
        delete jobData.salary;
      }

      await onCreate(jobData);
      onClose();
    } catch (error) {
      console.error("Failed to create job:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Job Listing</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Job Title"
            fullWidth
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
            required
          />

          <TextField
            label="Short Description"
            fullWidth
            value={formData.shortDescription}
            onChange={(e) =>
              setFormData({ ...formData, shortDescription: e.target.value })
            }
            required
            placeholder="A brief summary of the job position"
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Job Type"
                fullWidth
                value={formData.jobType}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    jobType: e.target.value as JobType,
                  })
                }
                required
              >
                {JOB_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Location"
                fullWidth
                value={formData.location}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    location: e.target.value as JobLocation,
                  })
                }
                required
              >
                {JOB_LOCATIONS.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Category"
                fullWidth
                value={formData.category}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    category: e.target.value as JobCategory,
                  })
                }
                required
              >
                {JOB_CATEGORIES.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <TextField
            label="Job Description"
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            required
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">Compensation Type</FormLabel>
            <RadioGroup
              row
              value={compensationType}
              onChange={(e) =>
                handleCompensationTypeChange(
                  e.target.value as "salary" | "hourly"
                )
              }
            >
              <FormControlLabel
                value="salary"
                control={<Radio />}
                label="Salary"
              />
              <FormControlLabel
                value="hourly"
                control={<Radio />}
                label="Hourly Rate"
              />
            </RadioGroup>
          </FormControl>

          {compensationType === "salary" ? (
            <>
              <Typography variant="subtitle1">
                Salary Range (Optional)
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Minimum"
                    type="number"
                    fullWidth
                    value={formData.salary?.min || 0}
                    onChange={(e) => handleSalaryChange("min", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Maximum"
                    type="number"
                    fullWidth
                    value={formData.salary?.max || 0}
                    onChange={(e) => handleSalaryChange("max", e.target.value)}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">
                Hourly Rate Range (Optional)
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Minimum"
                    type="number"
                    fullWidth
                    value={formData.hourlyRate?.min || 0}
                    onChange={(e) =>
                      handleHourlyRateChange("min", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Maximum"
                    type="number"
                    fullWidth
                    value={formData.hourlyRate?.max || 0}
                    onChange={(e) =>
                      handleHourlyRateChange("max", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Divider sx={{ my: 1 }} />

          <Typography variant="subtitle1">Requirements</Typography>
          {formData.requirements.map((req, index) => (
            <Box key={index} sx={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                value={req}
                onChange={(e) =>
                  handleArrayChange("requirements", index, e.target.value)
                }
                placeholder={`Requirement ${index + 1}`}
              />
              <IconButton
                color="error"
                onClick={() => handleRemoveArrayItem("requirements", index)}
                disabled={formData.requirements.length <= 1}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() => handleAddArrayItem("requirements")}
          >
            Add Requirement
          </Button>

          <Divider sx={{ my: 1 }} />

          <Typography variant="subtitle1">Responsibilities</Typography>
          {formData.responsibilities.map((resp, index) => (
            <Box key={index} sx={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                value={resp}
                onChange={(e) =>
                  handleArrayChange("responsibilities", index, e.target.value)
                }
                placeholder={`Responsibility ${index + 1}`}
              />
              <IconButton
                color="error"
                onClick={() => handleRemoveArrayItem("responsibilities", index)}
                disabled={formData.responsibilities.length <= 1}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() => handleAddArrayItem("responsibilities")}
          >
            Add Responsibility
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            isSubmitting ||
            !formData.title ||
            !formData.shortDescription ||
            !formData.description ||
            !formData.requirements.some(Boolean) ||
            !formData.responsibilities.some(Boolean)
          }
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Create Job"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const JobCard = ({
  job,
  onDelete,
  onStatusChange,
}: {
  job: JobListing;
  onDelete: (id: string) => Promise<void>;
  onStatusChange: (id: string, status: JobStatus) => Promise<void>;
}) => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const confirm = useConfirm();

  const handleEdit = () => {
    navigate(`/job-poster/${job.id}`);
  };

  const handleDelete = async () => {
    try {
      await confirm({
        title: "Delete Job Listing",
        description: `Are you sure you want to delete "${job.title}"? This action cannot be undone.`,
        confirmationText: "Delete",
        confirmationButtonProps: { color: "error" },
      });
      await onDelete(job.id);
    } catch (error) {
      // User canceled the deletion
    }
  };

  const handleStatusChange = async (newStatus: JobStatus) => {
    await onStatusChange(job.id, newStatus);
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" noWrap sx={{ maxWidth: "70%" }}>
            {job.title}
          </Typography>
          <JobStatusChip status={job.status} />
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 2,
            height: "3em",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {job.shortDescription}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          <Chip label={job.category} size="small" />
          <Chip label={job.jobType} size="small" />
          <Chip label={job.location} size="small" />
        </Box>
      </CardContent>
      <CardActions>
        <IconButton size="small" title="Edit" onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton size="small" title="Preview">
          <VisibilityIcon />
        </IconButton>
        <IconButton size="small" title="Delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
        {job.status === JobStatus.Draft && (
          <Button
            size="small"
            onClick={() => handleStatusChange(JobStatus.Published)}
          >
            Publish
          </Button>
        )}
        {job.status === JobStatus.Published && (
          <Button
            size="small"
            color="error"
            onClick={() => handleStatusChange(JobStatus.Closed)}
          >
            Close
          </Button>
        )}
        {job.status === JobStatus.Published && (
          <Button
            size="small"
            color="warning"
            onClick={() => handleStatusChange(JobStatus.Hidden)}
          >
            Hide
          </Button>
        )}
        {(job.status === JobStatus.Hidden ||
          job.status === JobStatus.Closed) && (
          <Button
            size="small"
            color="success"
            onClick={() => handleStatusChange(JobStatus.Published)}
          >
            Republish
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const JobPoster = () => {
  const { jobs, isLoadingJobs, createJob, deleteJob, updateJobStatus } =
    useAdminCareers();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const handleCreateJob = async (job: JobListingCreate) => {
    try {
      await createJob(job);
    } catch (error) {
      console.error("Failed to create job:", error);
      // TODO: Add error handling
    }
  };

  const handleDeleteJob = async (jobId: string) => {
    try {
      await deleteJob(jobId);
    } catch (error) {
      console.error("Failed to delete job:", error);
      // TODO: Add error handling
    }
  };

  const handleStatusChange = async (jobId: string, status: JobStatus) => {
    try {
      await updateJobStatus({ jobId, status });
    } catch (error) {
      console.error("Failed to update job status:", error);
      // TODO: Add error handling
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">Job Poster</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Create Job Listing
        </Button>
      </Box>

      {isLoadingJobs ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {jobs.length === 0 ? (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 4,
                  textAlign: "center",
                }}
              >
                <WorkIcon
                  sx={{ fontSize: 60, color: "text.secondary", mb: 2 }}
                />
                <Typography variant="h6" color="text.secondary">
                  No job listings yet
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  Create your first job listing to display on your website
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setIsCreateDialogOpen(true)}
                >
                  Create Job Listing
                </Button>
              </Box>
            </Grid>
          ) : (
            jobs.map((job) => (
              <Grid item xs={12} sm={6} md={4} key={job.id}>
                <JobCard
                  job={job}
                  onDelete={handleDeleteJob}
                  onStatusChange={handleStatusChange}
                />
              </Grid>
            ))
          )}
        </Grid>
      )}

      <CreateJobDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onCreate={handleCreateJob}
      />
    </Box>
  );
};

export default JobPoster;
