import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, CircularProgress } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
// Import highlight.js core
import hljs from "highlight.js/lib/core";
// Import languages
import javascript from "highlight.js/lib/languages/javascript";
import typescript from "highlight.js/lib/languages/typescript";
import xml from "highlight.js/lib/languages/xml";
import css from "highlight.js/lib/languages/css";
import json from "highlight.js/lib/languages/json";
import bash from "highlight.js/lib/languages/bash";
// Add TSX support
import tsx from "highlight.js/lib/languages/typescript";
import "highlight.js/styles/github.css"; // Import a style
import { motion, AnimatePresence } from "framer-motion";

// Register languages
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("typescript", typescript);
hljs.registerLanguage("xml", xml);
hljs.registerLanguage("html", xml);
hljs.registerLanguage("css", css);
hljs.registerLanguage("json", json);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("tsx", tsx);
hljs.registerLanguage("jsx", tsx);

interface AIMessageBubbleProps {
  content: string;
  timestamp: number;
  isCurrentUser: boolean;
  userName: string;
  userAvatar?: string;
  isLoading?: boolean;
}

const loadingMessages = {
  extended: [
    "Hmm, this is an interesting one...",
    "Gathering all the relevant details...",
    "Wow! That's a complex question...",
    "Processing lots of information...",
  ],
  long: [
    "Still working on it...",
    "Almost there...",
    "This requires deep thinking...",
    "Analyzing all possibilities...",
  ],
};

export const AIMessageBubble: React.FC<AIMessageBubbleProps> = ({
  content,
  timestamp,
  isCurrentUser,
  userName,
  userAvatar,
  isLoading,
}) => {
  const [loadingPhase, setLoadingPhase] = useState<
    "initial" | "extended" | "long"
  >("initial");
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    if (isLoading) {
      const extendedTimer = setTimeout(() => {
        setLoadingPhase("extended");
      }, 20000);

      const longTimer = setTimeout(() => {
        setLoadingPhase("long");
      }, 40000);

      const messageRotation = setInterval(() => {
        if (loadingPhase !== "initial") {
          setMessageIndex((prev) => (prev + 1) % 4);
        }
      }, 10000);

      return () => {
        clearTimeout(extendedTimer);
        clearTimeout(longTimer);
        clearInterval(messageRotation);
      };
    } else {
      setLoadingPhase("initial");
      setMessageIndex(0);
    }
  }, [isLoading, loadingPhase]);

  const getLoadingContent = () => {
    switch (loadingPhase) {
      case "extended":
        return loadingMessages.extended[messageIndex];
      case "long":
        return loadingMessages.long[messageIndex];
      default:
        return "";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isCurrentUser ? "row-reverse" : "row",
        gap: 1,
        opacity: isLoading ? 0.7 : 1,
      }}
    >
      <Avatar
        src={userAvatar}
        sx={{
          width: 32,
          height: 32,
          bgcolor: isCurrentUser ? "primary.main" : "grey.500",
        }}
      >
        {userName[0]}
      </Avatar>
      <Box sx={{ maxWidth: "80%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 0.5,
            justifyContent: isCurrentUser ? "flex-end" : "flex-start",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: "text.secondary",
              fontWeight: 500,
            }}
          >
            {userName}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "text.secondary",
            }}
          >
            {new Date(timestamp).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            bgcolor: isCurrentUser ? "primary.main" : "grey.100",
            color: isCurrentUser ? "white" : "text.primary",
            p: 2,
            borderRadius: 2,
            borderTopLeftRadius: !isCurrentUser ? 0 : 2,
            borderTopRightRadius: isCurrentUser ? 0 : 2,
            "& .markdown-content": {
              "& > *:first-of-type": {
                mt: 0,
              },
              "& > *:last-child": {
                mb: 0,
              },
              "& h1, & h2, & h3": {
                mt: 2,
                mb: 1,
                fontWeight: 600,
                lineHeight: 1.2,
              },
              "& h1": { fontSize: "1.5em" },
              "& h2": { fontSize: "1.3em" },
              "& h3": { fontSize: "1.1em" },
              "& ul, & ol": {
                pl: 2,
                my: 1,
              },
              "& li": {
                my: 0.5,
              },
              "& p": {
                my: 1,
              },
              "& code": {
                bgcolor: isCurrentUser ? "primary.dark" : "grey.200",
                p: "2px 4px",
                borderRadius: 1,
                fontFamily: "monospace",
                fontSize: "0.875em",
              },
              "& pre": {
                my: 1.5,
                p: 0,
                "& > div": {
                  borderRadius: 1,
                  margin: "0 !important",
                },
              },
              "& blockquote": {
                borderLeft: "4px solid",
                borderColor: isCurrentUser ? "primary.light" : "grey.400",
                pl: 2,
                ml: 0,
                my: 1,
                color: isCurrentUser ? "primary.light" : "text.secondary",
              },
              "& a": {
                color: isCurrentUser ? "primary.light" : "primary.main",
                textDecoration: "underline",
                "&:hover": {
                  color: isCurrentUser ? "primary.lighter" : "primary.dark",
                },
              },
              "& strong": {
                fontWeight: 600,
              },
              "& em": {
                fontStyle: "italic",
              },
            },
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <motion.div
                animate={{
                  rotate: [0, 360],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "linear",
                }}
              >
                <CircularProgress
                  size={24}
                  color={isCurrentUser ? "inherit" : "primary"}
                  sx={{ opacity: 0.8 }}
                />
              </motion.div>
              <AnimatePresence mode="wait">
                {loadingPhase !== "initial" && (
                  <motion.div
                    key={getLoadingContent()} // Force re-render on content change
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -5 }}
                    transition={{
                      duration: 0.4,
                      ease: "easeOut",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        color: isCurrentUser ? "inherit" : "text.secondary",
                        fontStyle: "italic",
                      }}
                    >
                      {getLoadingContent()}
                    </Typography>
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>
          ) : (
            <ReactMarkdown
              className="markdown-content"
              remarkPlugins={[remarkGfm]}
              components={
                {
                  // code({ node, inline, className, children, ...props }) {
                  //   const match = /language-(\w+)/.exec(className || "");
                  //   return !inline && match ? (
                  //     <SyntaxHighlighter
                  //       style={materialLight}
                  //       language={match[1]}
                  //       PreTag="div"
                  //       {...props}
                  //     >
                  //       {String(children).replace(/\n$/, "")}
                  //     </SyntaxHighlighter>
                  //   ) : (
                  //     <code className={className} {...props}>
                  //       {children}
                  //     </code>
                  //   );
                  // },
                }
              }
            >
              {content}
            </ReactMarkdown>
          )}
        </Box>
      </Box>
    </Box>
  );
};
