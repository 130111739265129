import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  CardActionArea,
  Divider,
  Stack,
  useTheme,
  Avatar,
} from "@mui/material";
import { Project, ProjectStatus, Client } from "@freetech/models/projects";
import { DateTime } from "luxon";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TimerIcon from "@mui/icons-material/Timer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import { useStakeholderProjects } from "hooks/stakeholder/useStakeholderProjects";

interface StakeholderProjectCardProps {
  project: Project;
  onClick?: () => void;
}

const getStatusColor = (status: ProjectStatus) => {
  switch (status) {
    case "active":
      return "success";
    case "idea":
      return "warning";
    case "completed":
      return "info";
    case "on_hold":
      return "warning";
    case "cancelled":
      return "error";
    default:
      return "default";
  }
};

const formatDate = (date: string | null) => {
  if (!date) return "Not set";
  return DateTime.fromISO(date).toFormat("MMM d, yyyy");
};

const getBudgetAmount = (project: Project) => {
  if (!project.consultingServicesAgreements?.length) return null;
  const latestCSA = [...project.consultingServicesAgreements].sort(
    (a, b) =>
      new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime()
  )[0];
  return {
    amount: latestCSA.monthlyBudget || latestCSA.fixedPriceBudget || 0,
    type: latestCSA.monthlyBudget ? "monthly" : "fixed",
  };
};

const StakeholderProjectCard: React.FC<StakeholderProjectCardProps> = ({
  project,
  onClick,
}) => {
  const theme = useTheme();
  const budget = getBudgetAmount(project);

  const { clients } = useStakeholderProjects();

  const client = clients?.find((c) => c.id === project.clientId);

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: onClick ? "pointer" : "default",
        "&:hover": onClick
          ? {
              transform: "translateY(-4px)",
              boxShadow: 4,
              transition: "all 0.2s ease-in-out",
            }
          : {},
      }}
    >
      <CardActionArea onClick={onClick} disabled={!onClick}>
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Box
            sx={{
              position: "relative",
              mb: 2
            }}
          >
            {/* Project title and client info */}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  mb: { xs: 0.5, sm: 1 },
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: { xs: "1.1rem", sm: "1.25rem" },
                    fontWeight: 600,
                    pr: 8, // Make room for the chip
                  }}
                >
                  {project.name}
                </Typography>

                {/* Status chip - positioned absolutely to stay in top right */}
                <Chip
                  label={project.status}
                  color={getStatusColor(project.status)}
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    height: { xs: 24, sm: 24 },
                    fontSize: { xs: "0.75rem", sm: "0.75rem" },
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                />
              </Box>

              {/* Client info with avatar */}
              {client?.companyName && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: { xs: 1, sm: 1.5 },
                  }}
                >
                  <Avatar
                    src={client.logoUrl}
                    alt={client.companyName}
                    sx={{
                      width: { xs: 24, sm: 28 },
                      height: { xs: 24, sm: 28 },
                      bgcolor: "primary.light",
                    }}
                  >
                    {!client.logoUrl && client.companyName.charAt(0)}
                  </Avatar>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "0.875rem" },
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    {client.companyName}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              mb: 2,
              fontSize: { xs: "0.875rem", sm: "0.875rem" },
              lineHeight: 1.5,
            }}
          >
            {project.description}
          </Typography>

          <Divider sx={{ my: { xs: 1.5, sm: 2 } }} />

          <Stack spacing={{ xs: 1, sm: 1.5 }}>
            {project.projectType && (
              <Box display="flex" alignItems="center" gap={1}>
                <AccountBalanceIcon
                  fontSize="small"
                  color="action"
                  sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}
                >
                  Type:{" "}
                  <Chip
                    label={project.projectType}
                    size="small"
                    sx={{
                      height: { xs: 20, sm: 24 },
                      fontSize: { xs: "0.7rem", sm: "0.75rem" },
                    }}
                  />
                </Typography>
              </Box>
            )}

            {project.duration && (
              <Box display="flex" alignItems="center" gap={1}>
                <TimerIcon
                  fontSize="small"
                  color="action"
                  sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}
                >
                  Duration: {project.duration}
                </Typography>
              </Box>
            )}

            {budget && (
              <Box display="flex" alignItems="center" gap={1}>
                <AttachMoneyIcon
                  fontSize="small"
                  color="action"
                  sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}
                >
                  Budget: ${budget.amount.toLocaleString()} ({budget.type})
                </Typography>
              </Box>
            )}

            <Box display="flex" alignItems="center" gap={1}>
              <CalendarTodayIcon
                fontSize="small"
                color="action"
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              />
              <Box>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}
                >
                  Start: {formatDate(project.startDate)}
                </Typography>
                {project.endDate && (
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}
                  >
                    End: {formatDate(project.endDate)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Stack>

          {project.consultingServicesAgreements?.length ? (
            <>
              <Divider sx={{ my: { xs: 1.5, sm: 2 } }} />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}
              >
                {project.consultingServicesAgreements.length} Active Agreement
                {project.consultingServicesAgreements.length > 1 ? "s" : ""}
              </Typography>
            </>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StakeholderProjectCard;
