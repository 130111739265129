import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import { CoreAppToolbar } from "./CoreAppToolbar";
import { Typography, Tooltip } from "@mui/material";
// Import package.json data
import packageJson from "../../../../../package.json";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: "100%",
}));

interface CoreAppBarProps {
  open: boolean;
  onDrawerToggle: () => void;
}

export const CoreAppBar: React.FC<CoreAppBarProps> = ({
  open,
  onDrawerToggle,
}) => {
  // Version and update date from package.json
  const version = packageJson.version;
  const updatedDate = packageJson.updated;

  // Fix date formatting by parsing the date parts directly
  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${months[month - 1]} ${day}, ${year}`;
  };

  const formattedDate = formatDate(updatedDate);

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <IconButton
          color="inherit"
          aria-label={open ? "close drawer" : "open drawer"}
          onClick={onDrawerToggle}
          edge="start"
        >
          {open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <Box
          component="img"
          src="https://raw.githubusercontent.com/the-freetech-company/freetech-image-content/refs/heads/main/freeteh-cropped-larger.png"
          alt="FreeTech"
          sx={{
            maxHeight: "40px",
            width: "auto",
            objectFit: "contain",
            borderRadius: "10px",
          }}
        />
        <Tooltip
          title={`Beta Release: ${version}\ - Updated: ${formattedDate}`}
          arrow
          placement="bottom"
        >
          <InfoIcon fontSize="small" sx={{ cursor: "pointer" }} />
        </Tooltip>

        <Box sx={{ flexGrow: 1 }} />
        <CoreAppToolbar />
      </Toolbar>
    </AppBar>
  );
};
