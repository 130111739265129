import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Paper, 
  Typography,
  Box,
  Divider,
  IconButton,
  Tooltip,
  Tab,
  Tabs,
  TextField,
  Alert
} from '@mui/material';
import { Close as CloseIcon, ContentCopy as CopyIcon, Code as CodeIcon, DataObject as JsonIcon, MarkEmailRead as TrackingIcon } from '@mui/icons-material';
import { MailDelivery } from 'types/admin/emailTemplate';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`email-preview-tabpanel-${index}`}
      aria-labelledby={`email-preview-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface EmailPreviewDialogProps {
  open: boolean;
  onClose: () => void;
  delivery: MailDelivery | null;
}

export const EmailPreviewDialog: React.FC<EmailPreviewDialogProps> = ({
  open,
  onClose,
  delivery
}) => {
  const [activeTab, setActiveTab] = useState(0);
  
  if (!delivery) return null;

  // Extract content based on the document structure
  const recipientEmail = delivery.to || 
    (delivery.message?.to) || 
    (delivery.toUids && delivery.toUids.length > 0 ? delivery.toUids[0] : 'N/A');
  
  // Try to get the subject from multiple possible locations
  const subject = delivery.template?.subject || 
                 delivery.message?.subject || 
                 'No Subject';
  
  // Try to get HTML content from multiple possible locations
  const htmlContent = delivery.template?.html || 
                     delivery.message?.html || 
                     '';
  
  // Get the template name for display
  const templateName = delivery.template?.name || 'Unknown Template';
  
  // Get data for template variables
  const templateData = delivery.template?.data || {};

  // Format any timestamp to string
  const formatTimestamp = (timestamp: any): string => {
    if (!timestamp) return 'N/A';
    if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }
    return String(timestamp);
  };
  

  // Get status, timestamps, etc. with proper formatting
  const status = delivery.delivery?.state || 'Unknown';
  const startTime = formatTimestamp(delivery.delivery?.startTime);
  const messageId = delivery.delivery?.info?.messageId || 'N/A';

  const handleCopyHtml = () => {
    if (htmlContent) {
      navigator.clipboard.writeText(htmlContent);
    }
  };

  const handleCopyJson = () => {
    navigator.clipboard.writeText(JSON.stringify(delivery, null, 2));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { 
          height: '80vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6">Email Preview</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {subject}
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <Divider />
      
      <Box sx={{ px: 3, py: 2, bgcolor: '#f5f5f5' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Box>
            <Typography variant="body2">
              <strong>To:</strong> {recipientEmail}
            </Typography>
            <Typography variant="body2">
              <strong>Subject:</strong> {subject}
            </Typography>
            <Typography variant="body2">
              <strong>Template:</strong> {templateName}
            </Typography>
          </Box>
          <Box>
            <Tooltip title="Copy HTML">
              <IconButton onClick={handleCopyHtml} size="small" sx={{ mr: 1 }}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy JSON">
              <IconButton onClick={handleCopyJson} size="small">
                <JsonIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Typography variant="caption" display="block" gutterBottom>
          <strong>Status:</strong> {status} | 
          <strong> Sent:</strong> {startTime} | 
          <strong> Message ID:</strong> {messageId}
        </Typography>
      </Box>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Preview" />
          <Tab label="Template Data" icon={<CodeIcon />} />
          <Tab label="Raw HTML" />
          <Tab label="Raw JSON" icon={<JsonIcon />} />
        </Tabs>
      </Box>
      
      <DialogContent sx={{ flex: 1, overflow: 'auto', p: 0 }}>
        <Box sx={{ p: 2 }}>
          <Alert 
            icon={<TrackingIcon />} 
            severity="info"
            sx={{ mb: 2 }}
          >
            When sent to recipients, this email will include open tracking that lets you know when it's been viewed.
          </Alert>
        </Box>
        
        <TabPanel value={activeTab} index={0}>
          <Paper 
            elevation={0} 
            sx={{ 
              height: '100%', 
              overflow: 'auto',
              bgcolor: 'white',
              borderRadius: 0
            }}
          >
            <Box 
              sx={{ 
                p: 2,
                maxWidth: '600px',
                mx: 'auto',
                border: '1px solid #e0e0e0',
                borderRadius: 1,
                my: 2,
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
              }}
            >
              <div 
                dangerouslySetInnerHTML={{ 
                  __html: htmlContent || '<p>No HTML content</p>' 
                }} 
              />
            </Box>
          </Paper>
        </TabPanel>
        
        <TabPanel value={activeTab} index={1}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Template Variables</Typography>
            {Object.keys(templateData).length > 0 ? (
              <Box sx={{ mt: 2 }}>
                {Object.entries(templateData).map(([key, value]) => {
                  // Format timestamps for display
                  const displayValue = (value && typeof value === 'object' && 'seconds' in value) 
                    ? formatTimestamp(value)
                    : typeof value === 'object' 
                      ? JSON.stringify(value, null, 2) 
                      : String(value);
                      
                  return (
                    <Box key={key} sx={{ mb: 2 }}>
                      <Typography variant="subtitle2" color="primary">{key}</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={displayValue}
                        InputProps={{
                          readOnly: true,
                        }}
                        multiline={typeof value === 'object' && !('seconds' in value)}
                        maxRows={4}
                        size="small"
                      />
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No template variables were used for this email.
              </Typography>
            )}
          </Box>
        </TabPanel>
        
        <TabPanel value={activeTab} index={2}>
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={20}
              value={htmlContent}
              InputProps={{
                readOnly: true,
                sx: { fontFamily: 'monospace', fontSize: '0.875rem' }
              }}
            />
          </Box>
        </TabPanel>
        
        <TabPanel value={activeTab} index={3}>
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom color="text.secondary">
              Raw Mail Document JSON
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={20}
              value={JSON.stringify(delivery, null, 2)}
              InputProps={{
                readOnly: true,
                sx: { fontFamily: 'monospace', fontSize: '0.875rem' }
              }}
            />
          </Box>
        </TabPanel>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailPreviewDialog; 