import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import { db } from "config/firebase";
import { formatProjectAssignmentDisplayName } from "core/freelancer/utils/formatProjectAssignmentDisplayName";
import { projectAssignmentConverter } from "@freetech/models/converters";
import { Client } from "@freetech/models/projects";
import { ProjectAssignment } from "@freetech/models/projects";
import { storage } from "config/firebase";
import { getDownloadURL } from "firebase/storage";
import { ref } from "firebase/storage";

export const fetchAllFreelancerProjectAssignments = async (
  freelancerId: string,
  clients: Client[]
): Promise<ProjectAssignment[]> => {
  try {
    // Do a single collectionGroup query instead of querying per client
    const assignmentsQuery = query(
      collectionGroup(db, "projectAssignments"),
      where("engineerId", "==", freelancerId)
    ).withConverter(projectAssignmentConverter);

    const assignmentsSnapshot = await getDocs(assignmentsQuery);

    // Process all assignments at once
    const assignments = await Promise.all(
      assignmentsSnapshot.docs.map(async (doc) => {
        try {
          const data = doc.data();
          // Get the client reference path from the document
          const clientId = doc.ref.parent.parent?.id;

          if (!clientId) {
            console.error(`No client ID found for assignment ${doc.id}`);
            return null;
          }

          // Find the matching client
          const client = clients.find((c) => c.id === clientId);
          if (!client) {
            console.error(`No matching client found for ID ${clientId}`);
            return null;
          }

          // Find the matching project
          const project = client.projects?.find((p) => p.id === data.projectId);
          if (!project) {
            console.error(`No matching project found for ID ${data.projectId}`);
            return null;
          }

          const assignment: ProjectAssignment = {
            ...data,
            fileUrl: data.fileUrl || undefined,
            id: doc.id,
            clientId,
            clientName: client.companyName,
            projectName: project.name,
            engineerId: freelancerId,
            displayName: formatProjectAssignmentDisplayName(
              project.name,
              data.startDate,
              data.endDate,
              doc.id
            ),
          };

          return assignment;
        } catch (error) {
          console.error(`Error processing assignment ${doc.id}:`, error);
          return null;
        }
      })
    );

    // Filter out null values and return
    return assignments.filter(
      (assignment): assignment is NonNullable<typeof assignment> =>
        assignment !== null
    );
  } catch (error) {
    console.error("Error fetching project assignments:", error);
    return [];
  }
};
