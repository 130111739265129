import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Avatar,
  IconButton,
  TablePagination,
  TextField,
  InputAdornment,
  Skeleton,
  useTheme,
} from '@mui/material';
import {
  Search as SearchIcon,
  Person as PersonIcon,
  VerifiedUser as VerifiedUserIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import { useAdminFreelancers } from 'hooks/admin/useAdminFreelancers';
import { Freelancer } from '@freetech/models/user';
import { Link } from 'react-router-dom';

const FreelancersTab: React.FC = () => {
  const theme = useTheme();
  const { freelancers, isLoading } = useAdminFreelancers();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Filter freelancers based on search query
  const filteredFreelancers = freelancers?.filter((freelancer) => {
    const searchText = searchQuery.toLowerCase();
    const name = `${freelancer.firstName} ${freelancer.lastName}`.toLowerCase();
    const email = freelancer.email?.toLowerCase() || '';
    const skills = freelancer.skills?.join(' ').toLowerCase() || '';
    
    return (
      name.includes(searchText) ||
      email.includes(searchText) ||
      skills.includes(searchText)
    );
  }) || [];

  // Calculate displayed freelancers based on pagination
  const displayedFreelancers = filteredFreelancers
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Get counts for different freelancer statuses
  const activeFreelancers = freelancers?.filter(f => f.status === 'active').length || 0;
  const inactiveFreelancers = freelancers?.filter(f => f.status === 'inactive').length || 0;
  const readyFreelancers = freelancers?.filter(f => f.status === 'ready').length || 0;

  // Get skill distribution
  const getSkillDistribution = () => {
    const skillMap: Record<string, number> = {};
    
    freelancers?.forEach(freelancer => {
      freelancer.skills?.forEach(skill => {
        if (skill) {
          skillMap[skill] = (skillMap[skill] || 0) + 1;
        }
      });
    });
    
    return Object.entries(skillMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);
  };

  const topSkills = getSkillDistribution();

  // Loading placeholders
  const StatCardLoader = () => (
    <Card>
      <CardContent>
        <Skeleton variant="text" width={120} height={28} />
        <Skeleton variant="text" width={80} height={40} sx={{ my: 1 }} />
        <Skeleton variant="text" width={100} />
      </CardContent>
    </Card>
  );

  const TableLoader = () => (
    <Box>
      <Skeleton variant="rectangular" height={52} sx={{ mb: 1 }} />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={53} sx={{ mb: 1 }} />
      ))}
    </Box>
  );

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Freelancer Stats */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Freelancer Overview</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Total Freelancers
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1 }}>
                      {freelancers?.length || 0}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Registered in the system
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Active Freelancers
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.success.main }}>
                      {activeFreelancers}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Currently working
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Inactive Freelancers
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.error.main }}>
                      {inactiveFreelancers}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Not currently available
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Pending Approval
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.warning.main }}>
                      {readyFreelancers}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Ready to work
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Top Skills */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" sx={{ mb: 2 }}>Top Skills</Typography>
          {isLoading ? (
            <Skeleton variant="rectangular" height={300} />
          ) : (
            <Paper sx={{ p: 2, height: '100%' }}>
              <TableContainer sx={{ maxHeight: 340 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Skill</TableCell>
                      <TableCell align="right">Freelancers</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topSkills.map(([skill, count]) => (
                      <TableRow key={skill} hover>
                        <TableCell>{skill}</TableCell>
                        <TableCell align="right">{count}</TableCell>
                      </TableRow>
                    ))}
                    {topSkills.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          No skills data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Grid>

        {/* Freelancer Search */}
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Freelancer Search</Typography>
            <TextField
              variant="outlined"
              placeholder="Search freelancers..."
              size="small"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              sx={{ width: { xs: '100%', sm: 250 } }}
            />
          </Box>
          
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {isLoading ? (
              <Box sx={{ p: 2 }}>
                <TableLoader />
              </Box>
            ) : (
              <>
                <TableContainer sx={{ maxHeight: 340 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Freelancer</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Skills</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedFreelancers.map((freelancer) => (
                        <TableRow hover key={freelancer.id}>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {freelancer.profilePicture ? (
                                <Avatar 
                                  src={freelancer.profilePicture} 
                                  alt={`${freelancer.firstName} ${freelancer.lastName}`} 
                                  sx={{ mr: 2, width: 32, height: 32 }}
                                />
                              ) : (
                                <Avatar 
                                  sx={{ mr: 2, width: 32, height: 32, bgcolor: theme.palette.primary.main }}
                                >
                                  <PersonIcon fontSize="small" />
                                </Avatar>
                              )}
                              <Typography variant="body2">
                                {freelancer.firstName} {freelancer.lastName}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{freelancer.email}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {freelancer.skills?.slice(0, 2).map((skill, index) => (
                                <Chip 
                                  key={index} 
                                  label={skill} 
                                  size="small" 
                                  variant="outlined" 
                                />
                              ))}
                              {(freelancer.skills?.length || 0) > 2 && (
                                <Chip 
                                  label={`+${(freelancer.skills?.length || 0) - 2}`} 
                                  size="small" 
                                  variant="outlined" 
                                />
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={freelancer.status || 'unknown'}
                              size="small"
                              color={
                                freelancer.status === 'active' 
                                  ? 'success' 
                                  : freelancer.status === 'inactive'
                                    ? 'error'
                                    : 'warning'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              component={Link}
                              to={`/admin/freelancer-management/${freelancer.id}`}
                              size="small"
                              color="primary"
                            >
                              <OpenInNewIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {displayedFreelancers.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            No freelancers found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredFreelancers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FreelancersTab; 