// import { ReactScan } from "./components/ReactScan";
// ReactScan();
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { theme } from "./core/theme";
import { muiXLicenseKey } from "./config";
import HyperDX from "@hyperdx/browser";
import { LicenseInfo } from "@mui/x-license";
import { ErrorBoundary } from "react-error-boundary";
import "@fontsource/inter";

if (muiXLicenseKey) {
  LicenseInfo.setLicenseKey(muiXLicenseKey);
} else {
  console.error("MUI X License Key is not set: ", muiXLicenseKey);
}

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

if (window.location.hostname === "portal.freetech.co") {
  HyperDX.init({
    apiKey: "010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2",
    service: "freetech-portal",
    consoleCapture: true,
    advancedNetworkCapture: false,
  });
}
fetch("https://api.ipify.org?format=json")
  .then((response) => response.json())
  .then((data) => {
    HyperDX.setGlobalAttributes({ userIp: data.ip });
  })
  .catch((error) => {
    console.error("Error fetching IP:", error);
  });

HyperDX.attachToReactErrorBoundary(ErrorBoundary);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>
);
