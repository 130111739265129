import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  CircularProgress,
  Alert,
} from "@mui/material";
import { CreateUserType } from "@freetech/models/user";
import { useManageUsers } from "hooks/admin/useManageUsers";
import { useSnackbar } from "notistack";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { getFirestore } from "firebase/firestore";

const db = getFirestore();

// Extend CreateUserType with assignedMentorId property
interface ExtendedCreateUserType extends CreateUserType {
  assignedMentorId?: string;
}

export const CreateUser: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { createUserMutation } = useManageUsers();
  const { clients } = useAdminClients();
  const [formError, setFormError] = useState<string | null>(null);
  const [userData, setUserData] = useState<ExtendedCreateUserType>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "freelancer",
    permissions: [],
    emailVerified: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    needsOnboarding: true,
    disabled: false,
    documents: [],
    associatedClientId: "",
  });
  const [open, setOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isUserTypeSelected, setIsUserTypeSelected] = useState(true);

  const isStakeholderRole =
    userData.role === "stakeholder" || userData.role === "babyStakeholder";

  const handleOpen = () => {
    setOpen(true);
    setFormError(null);
  };

  const handleClose = () => {
    setIsEmailValid(true);
    setFormError(null);
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(null);
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormError(null);
    if (event.target.name === "role") {
      setUserData({
        ...userData,
        role: event.target.value as CreateUserType["role"],
      });
    } else {
      setUserData({ ...userData, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError(null);

    // Validate required fields
    if (
      !userData.role ||
      !userData.firstName ||
      !userData.lastName ||
      !userData.email ||
      (isStakeholderRole && !userData.associatedClientId)
    ) {
      setFormError(
        `Missing required fields: ${[
          !userData.role && "role",
          !userData.firstName && "firstName",
          !userData.lastName && "lastName",
          !userData.email && "email",
          isStakeholderRole &&
            !userData.associatedClientId &&
            "associatedClientId",
        ]
          .filter(Boolean)
          .join(", ")}`
      );
      return;
    }

    // Create a clean copy of userData for submission
    const userDataToSubmit: CreateUserType = {
      email: userData.email,
      password: userData.password,
      firstName: userData.firstName,
      lastName: userData.lastName,
      role: userData.role,
      permissions: userData.permissions,
      emailVerified: userData.emailVerified,
      createdAt: userData.createdAt,
      updatedAt: userData.updatedAt,
      needsOnboarding: userData.needsOnboarding,
      disabled: userData.disabled,
      documents: userData.documents,
      associatedClientId: userData.associatedClientId,
    };

    createUserMutation.mutate(userDataToSubmit, {
      onSuccess: () => {
        enqueueSnackbar("User created successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        setUserData({
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          role: "freelancer",
          permissions: [],
          emailVerified: false,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          needsOnboarding: true,
          disabled: false,
          documents: [],
          associatedClientId: "",
        });
        handleClose();
      },
      onError: (error) => {
        console.error("Error creating user", error);
        setFormError(
          error instanceof Error ? error.message : "Error creating user"
        );
      },
    });
  };

  return (
    <>
      <Box sx={{ marginBottom: 4 }}>
        <Button variant="outlined" onClick={handleOpen}>
          Create User
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={userData.email}
                  onChange={handleChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? "Invalid email address" : ""}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  value={userData.firstName}
                  onChange={handleChange}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={userData.lastName}
                  onChange={handleChange}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Role</InputLabel>
                  <Select
                    label="Role"
                    name="role"
                    value={userData.role}
                    onChange={handleSelectChange}
                    disabled={createUserMutation.isPending}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="executiveAssistant">
                      Executive Assistant
                    </MenuItem>
                    <MenuItem value="freelancer">Freelancer</MenuItem>
                    <MenuItem value="stakeholder">Stakeholder</MenuItem>
                    <MenuItem value="babyStakeholder">
                      Baby Stakeholder
                    </MenuItem>
                    <MenuItem value="student">Student</MenuItem>
                    <MenuItem value="non-admin">Non-Admin</MenuItem>
                  </Select>
                  {!isUserTypeSelected && (
                    <Typography color="error" variant="caption">
                      Please select a role.
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              {isStakeholderRole && (
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Client</InputLabel>
                    <Select
                      label="Client"
                      name="associatedClientId"
                      value={userData.associatedClientId}
                      onChange={handleSelectChange}
                      disabled={createUserMutation.isPending}
                    >
                      {clients?.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {formError && (
                <Grid item xs={12}>
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {formError}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={createUserMutation.isPending}
                >
                  {createUserMutation.isPending ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Create User"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
