import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getPublicUserProfiles } from "core/users/getPublicUserProfile";
import { PublicUserInfoType } from "@freetech/models/user";

export const usePublicUserList = (): UseQueryResult<PublicUserInfoType[]> => {
  return useQuery({
    queryKey: ["users", "public-info"],
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryFn: getPublicUserProfiles,
  });
};
