import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import {
  ClientInvoiceData,
  IncreaseExternalAccountDetails,
  StakeholderPayment,
} from "@freetech/models/bank";
import { loadMyStakeholderInvoices } from "core/stakeholder/loadMyStakeholderInvoices";
import { collection, QuerySnapshot, onSnapshot } from "firebase/firestore";
import { db } from "config/firebase";
import { stakeholderPaymentConverter } from "@freetech/models/converters";
import { getDocs } from "firebase/firestore";
import { bankFunctions } from "core/functions/bankFunctions";
import { stripeFunctions } from "core/functions/stripeFunctions";

export const useStakeholderPayments = () => {
  const { currentUser, userInfo } = useAuth();
  const queryClient = useQueryClient();

  const invalidatePaymentProfileQueries = async () => {
    if (!currentUser?.uid) return;

    await Promise.all([
      // Invalidate and refetch stakeholder profile
      queryClient.invalidateQueries({
        queryKey: ["stakeholder", currentUser.uid],
      }),
      queryClient.refetchQueries({
        queryKey: ["stakeholder", currentUser.uid],
      }),
      // Invalidate and refetch payment profile
      queryClient.invalidateQueries({
        queryKey: ["stakeholderPaymentProfile", currentUser.uid],
      }),
      queryClient.refetchQueries({
        queryKey: ["stakeholderPaymentProfile", currentUser.uid],
      }),
    ]);
  };

  const fetchStakeholderPayments = (): Promise<StakeholderPayment[]> => {
    return new Promise((resolve, reject) => {
      if (!currentUser?.uid) {
        reject(new Error("No authenticated user"));
        return;
      }
      const paymentsRef = collection(
        db,
        "users",
        currentUser.uid,
        "payments"
      ).withConverter(stakeholderPaymentConverter);

      const unsubscribe = onSnapshot(
        paymentsRef,
        (snapshot: QuerySnapshot<StakeholderPayment>) => {
          const data = snapshot.docs.map((doc) => {
            const docData = doc.data();
            return docData;
          });

          console.log("stakeholder payments", data);
          resolve(data);
        },
        (error) => {
          console.error("Error fetching stakeholder payments:", error);
          reject(error);
        }
      );

      // Cleanup function to unsubscribe from the snapshot listener
      return () => unsubscribe();
    });
  };

  const {
    data: stakeholderPayments,
    error: stakeholderPaymentsError,
    isLoading: stakeholderPaymentsLoading,
  } = useQuery({
    queryKey: ["stakeholderPayments", currentUser?.uid],
    queryFn: fetchStakeholderPayments,
    enabled: !!currentUser?.uid,
  });

  const {
    data: paymentProfile,
    error: paymentProfileError,
    isLoading: paymentProfileLoading,
    refetch: refetchPaymentProfile,
  } = useQuery({
    queryKey: ["stakeholderPaymentProfile", currentUser?.uid],
    queryFn: async () => {
      if (!currentUser?.uid) {
        throw new Error("No authenticated user");
      }
      return bankFunctions.stakeholder.getMyStakeholderPaymentProfile();
    },
    enabled: !!currentUser?.uid,
  });

  const {
    data: clientInvoices,
    error: clientInvoicesError,
    isLoading: clientInvoicesLoading,
  } = useQuery({
    queryKey: ["clientInvoices", currentUser?.uid],
    queryFn: () => {
      if (!userInfo) {
        throw new Error("No authenticated user");
      }
      return loadMyStakeholderInvoices(userInfo);
    },
    enabled: !!currentUser?.uid,
  });

  const createMyStakeholderPaymentProfile = async (
    data: Partial<IncreaseExternalAccountDetails> & { nickname: string }
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    await bankFunctions.stakeholder.createStakeholderPaymentProfile(
      currentUser.uid,
      data
    );

    await invalidatePaymentProfileQueries();
  };

  const updateMyStakeholderPaymentProfile = async (
    data: Partial<IncreaseExternalAccountDetails> & { nickname?: string }
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    await bankFunctions.stakeholder.updateStakeholderPaymentProfile(
      currentUser.uid,
      data
    );

    await invalidatePaymentProfileQueries();
  };

  const payMyStakeholderInvoice = async (
    clientId: string,
    invoiceId: string,
    amount?: number
  ): Promise<void> => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    await bankFunctions.stakeholder.payMyStakeholderInvoice(
      clientId,
      invoiceId,
      amount
    );

    await queryClient.invalidateQueries({
      queryKey: ["clientInvoices", currentUser.uid],
    });
  };

  /**
   * Creates a Stripe checkout session for an invoice payment
   *
   * @param invoice The invoice to pay
   * @param successUrl The URL to redirect to after successful payment
   * @param cancelUrl The URL to redirect to if payment is cancelled
   * @param partialAmount Optional amount for a partial payment
   * @returns The checkout URL, session ID, and fee information
   */
  const createStripeCheckoutSession = async (
    invoice: ClientInvoiceData,
    successUrl: string,
    cancelUrl: string,
    partialAmount?: number
  ): Promise<{
    checkoutUrl: string;
    sessionId: string;
    originalAmount: number;
    processingFee: number;
    totalAmount: number;
  }> => {
    if (!invoice.clientId || !invoice.id) {
      throw new Error("Invalid invoice data");
    }

    // Calculate the remaining amount
    const totalAmount = invoice.totalDollarAmountDue || 0;
    const alreadyPaid = invoice.partialPaymentAmount || 0;
    const remainingAmount = totalAmount - alreadyPaid;

    // Use provided partial amount or default to remaining amount
    const paymentAmount = partialAmount !== undefined ? partialAmount : remainingAmount;

    if (paymentAmount <= 0) {
      throw new Error("Payment amount must be greater than zero");
    }

    if (paymentAmount > remainingAmount) {
      throw new Error(`Partial amount cannot exceed the remaining amount due ($${remainingAmount.toFixed(2)})`);
    }

    try {
      return stripeFunctions.stakeholder.createCheckoutSession(
        invoice.id,
        invoice.clientId,
        paymentAmount,
        invoice.invoiceNumber || "Unknown",
        successUrl,
        cancelUrl
      );
    } catch (error) {
      console.error("Stripe checkout error:", error);
      if (error instanceof Error) {
        // Check for specific error messages
        if (error.message.includes("already paid")) {
          throw new Error("This invoice has already been paid in full");
        }
        
        // Handle minimum amount error
        if (error.message.includes("minimum") || error.message.includes("$0.50")) {
          throw new Error("Stripe requires a minimum payment of $0.50. Please use bank transfer for smaller amounts.");
        }
      }
      throw error;
    }
  };

  return {
    paymentProfile,
    paymentProfileError,
    paymentProfileLoading,
    refetchPaymentProfile,
    createMyStakeholderPaymentProfile,
    updateMyStakeholderPaymentProfile,
    clientInvoices,
    clientInvoicesError,
    clientInvoicesLoading,
    payMyStakeholderInvoice,
    stakeholderPayments,
    stakeholderPaymentsError,
    stakeholderPaymentsLoading,
    createStripeCheckoutSession,
  };
};
