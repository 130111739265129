import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Chip,
  Tooltip,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  AccessTime as AccessTimeIcon,
  CheckCircle as CheckCircleIcon,
  AltRoute as AltRouteIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { DateTime } from "luxon";
import { ProjectIdeaData } from "@freetech/models/projects";

interface InProgressIdeasProps {
  ideas: ProjectIdeaData[];
  onContinueIdea: (ideaId: string) => void;
  onDeleteIdea?: (clientId: string, ideaId: string) => Promise<void>;
  isDeleting?: boolean;
}

export const InProgressIdeas: React.FC<InProgressIdeasProps> = ({
  ideas,
  onContinueIdea,
  onDeleteIdea,
  isDeleting = false,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [ideaToDelete, setIdeaToDelete] = useState<{ clientId: string; ideaId: string } | null>(null);

  if (!ideas.length) return null;

  const formatRelativeTime = (date: Date) => {
    return DateTime.fromJSDate(date).toRelative();
  };

  const handleOpenDeleteDialog = (clientId: string, ideaId: string) => {
    setIdeaToDelete({ clientId, ideaId });
    setOpenConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = async () => {
    if (ideaToDelete && onDeleteIdea) {
      await onDeleteIdea(ideaToDelete.clientId, ideaToDelete.ideaId);
      setOpenConfirmDialog(false);
      setIdeaToDelete(null);
    }
  };

  ideas = ideas.filter((idea) => idea.status === "in_progress");

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Continue In-Progress Project Ideas
      </Typography>
      <Grid container spacing={2}>
        {ideas.map((idea) => {
          const isReadyForPlan = idea.readyForPlan || (idea as any).conversationComplete;
          
          return (
            <Grid item xs={12} md={6} lg={4} key={idea.id}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    "&:hover": {
                      boxShadow: (theme) => theme.shadows[4],
                    },
                    border: isReadyForPlan ? (theme) => `1px solid ${theme.palette.success.main}` : undefined,
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        mb: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "medium" }}
                      >
                        {idea.clientName}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        {isReadyForPlan && (
                          <Chip
                            size="small"
                            icon={<CheckCircleIcon />}
                            label="Ready for Plan"
                            color="success"
                            variant="outlined"
                          />
                        )}
                        <Chip
                          size="small"
                          icon={<AccessTimeIcon />}
                          label={formatRelativeTime(new Date(idea.lastUpdated))}
                          color="primary"
                          variant="outlined"
                        />
                      </Stack>
                    </Box>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        mb: 2,
                      }}
                    >
                      {idea.projectDescription ||
                        "Project description in progress..."}
                    </Typography>

                    <Box sx={{ 
                      display: "flex", 
                      justifyContent: "space-between",
                      alignItems: "center" 
                    }}>
                      {onDeleteIdea && (
                        <Tooltip title="Delete project idea">
                          <IconButton 
                            size="small" 
                            color="error"
                            onClick={() => handleOpenDeleteDialog(idea.clientId, idea.id || "")}
                            disabled={isDeleting}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      
                      <Tooltip title={isReadyForPlan ? "Generate a project plan from this idea" : "Continue working on this idea"}>
                        <Button
                          variant="contained"
                          size="small"
                          color={isReadyForPlan ? "success" : "primary"}
                          startIcon={isReadyForPlan ? <AltRouteIcon /> : <PlayArrowIcon />}
                          onClick={() => onContinueIdea(idea.id || "")}
                        >
                          {isReadyForPlan ? "Generate Plan" : "Continue"}
                        </Button>
                      </Tooltip>
                    </Box>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          );
        })}
      </Grid>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete project idea?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this project idea? This action cannot be undone, and all your progress will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            color="error" 
            variant="contained" 
            disabled={isDeleting}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
