import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FeatureRequestAttachment } from "@freetech/models";

/**
 * Uploads attachments to a feature request and updates the document
 * @param clientId The ID of the client
 * @param featureRequestId The ID of the feature request
 * @param files The files to upload
 */
export const updateFeatureRequestAttachments = async (
  clientId: string,
  featureRequestId: string,
  files: File[]
): Promise<void> => {
  try {
    const featureRequestRef = doc(db, "clients", clientId, "featureRequests", featureRequestId);

    // Get current attachments
    const featureRequestDoc = await getDoc(featureRequestRef);
    if (!featureRequestDoc.exists()) {
      throw new Error("Feature request not found");
    }
    
    const currentAttachments = featureRequestDoc.data()?.attachments || [];

    // Upload new files and get attachments
    const uploadPromises = files.map(async (file) => {
      const storageRef = ref(storage, `featureRequests/${clientId}/${featureRequestId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      return {
        id: crypto.randomUUID(),
        name: file.name,
        url,
        uploadedAt: new Date().toISOString(),
      } as FeatureRequestAttachment;
    });

    const newAttachments = await Promise.all(uploadPromises);

    // Combine existing and new attachments
    const allAttachments = [...currentAttachments, ...newAttachments];

    // Update Firestore document
    await updateDoc(featureRequestRef, {
      attachments: allAttachments,
      updatedAt: new Date().toISOString(),
    });
    
    console.log(`${newAttachments.length} attachments added to feature request ${featureRequestId}`);
  } catch (error) {
    console.error("Error updating attachments:", error);
    throw error;
  }
}; 