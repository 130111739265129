import { Box, Typography, Tab, Tabs, Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from '@mui/material';
import { useState } from 'react';
import { ContractorHeader } from './ContractorHeader';
import { ContractorDetails } from './ContractorDetails';
import { ContractorPayments } from './ContractorPayments';
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useParams } from 'react-router-dom';
import React from 'react';
import { PaymentDialog } from './PaymentDialog';
import { ExternalPaymentDialog } from './ExternalPaymentDialog';

export const ContractorDetail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [externalPaymentDialogOpen, setExternalPaymentDialogOpen] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const { contractorId } = useParams();
  const { freelancers: contractors } = useAdminFreelancers();
  
  const contractor = contractors?.find(c => c.id === contractorId);

  if (!contractor || !contractorId) return null;

  const handleCreatePaymentClick = () => {
    if (!contractor.taxFormCompleted && contractor.payrollProfileCompleted) {
      setWarningDialogOpen(true);
    } else {
      setPaymentDialogOpen(true);
    }
  };

  const handleWarningConfirm = () => {
    setWarningDialogOpen(false);
    setPaymentDialogOpen(true);
  };

  const getPaymentButtonColor = () => {
    if (!contractor.payrollProfileCompleted) return "primary";
    if (!contractor.taxFormCompleted) return "warning";
    return "primary";
  };

  const getPaymentButtonTooltip = () => {
    if (!contractor.payrollProfileCompleted) return "Contractor not ready for payroll";
    if (!contractor.taxFormCompleted) return "Tax form not completed";
    return "";
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <ContractorHeader contractor={contractor} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setExternalPaymentDialogOpen(true)}
          >
            Add External Payment
          </Button>
          <Tooltip 
            title={getPaymentButtonTooltip()}
            arrow
          >
            <span>
              <Button
                variant="contained"
                onClick={handleCreatePaymentClick}
                disabled={!contractor.payrollProfileCompleted}
                color={getPaymentButtonColor()}
              >
                Create Payment
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={(_, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Details" />
          <Tab label="Payments" />
        </Tabs>
      </Box>

      {activeTab === 0 && <ContractorDetails contractor={contractor} />}
      {activeTab === 1 && <ContractorPayments contractor={contractor} />}

      <PaymentDialog
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        contractor={contractor}
        contractorId={contractorId}
      />

      <ExternalPaymentDialog
        open={externalPaymentDialogOpen}
        onClose={() => setExternalPaymentDialogOpen(false)}
        contractor={contractor}
      />

      {/* Warning Dialog for Missing Tax Form */}
      <Dialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Warning: Missing Tax Form</DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mt: 2 }}>
            This contractor has not submitted their tax form. Creating a payment without a tax form may cause issues with tax reporting and compliance.
          </Alert>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to proceed with creating a payment?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleWarningConfirm} color="warning" variant="contained">
            Proceed Anyway
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 