import { useEffect, useCallback, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Chip,
  Divider,
  TextField,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useScrollTrigger,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
  Publish as PublishIcon,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  Code,
  Link as LinkIcon,
  Image as ImageIcon,
  AutoAwesome as AutoAwesomeIcon,
  History as HistoryIcon,
  Restore as RestoreIcon,
  Compare as CompareIcon,
  Title as TitleIcon,
  LooksOne,
  LooksTwo,
  Looks3,
  Looks4,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from "@mui/icons-material";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { createLowlight } from "lowlight";
import { useAdminBlogs } from "hooks/blog/useAdminBlogs";
import { BlogCategory, BlogState, BlogVersion } from "@freetech/models/blog";
import { ImageUpload } from "../components/ImageUpload";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "config/firebase";
import { useProfilePicture } from "hooks/auth/useProfilePicture";
import { AICommands, AICommand, defaultAICommands } from '../components/AICommandsExtension';
import { CommandSuggestionPlugin } from '../components/AICommandSuggestion';
import { AIGenerationDialog } from '../components/AIGenerationDialog';
import { diffWords } from 'diff';
import { useAuth } from "hooks/auth";
import { BlogPostUpdate } from "@freetech/models/blog";

// Import highlight.js core
import hljs from "highlight.js/lib/core";
// Import languages
import javascript from "highlight.js/lib/languages/javascript";
import typescript from "highlight.js/lib/languages/typescript";
import xml from "highlight.js/lib/languages/xml";
import css from "highlight.js/lib/languages/css";
import json from "highlight.js/lib/languages/json";
import bash from "highlight.js/lib/languages/bash";
import { Delete as DeleteIcon } from "@mui/icons-material";
// Add TSX support
import tsx from "highlight.js/lib/languages/typescript";

// Import Atom One Dark theme
import "highlight.js/styles/atom-one-dark.css";

// Register languages
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("typescript", typescript);
hljs.registerLanguage("html", xml);
hljs.registerLanguage("css", css);
hljs.registerLanguage("json", json);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("tsx", tsx);

// Create lowlight instance with registered languages
const lowlight = createLowlight({
  javascript,
  typescript,
  tsx,
  html: xml,
  css,
  json,
  bash,
});

const MenuBar = ({ editor, onAIButtonClick }: { editor: any; onAIButtonClick?: () => void }) => {
  if (!editor) {
    return null;
  }

  // Add state tracking for formatting
  const [, forceUpdate] = useState({});

  // Subscribe to editor changes
  useEffect(() => {
    if (!editor) return;

    // Update when selection changes
    const unsubscribe = editor.on('selectionUpdate', () => {
      forceUpdate({});
    });

    return () => {
      unsubscribe();
    };
  }, [editor]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      // Upload to Firebase Storage
      const storageRef = ref(
        storage,
        `public/blog-content/${Date.now()}-${file.name}`
      );
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);

      // Insert into editor
      editor.chain().focus().setImage({ src: url }).run();
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  const handleCodeBlock = () => {
    // If already in a code block, exit it
    if (editor.isActive("codeBlock")) {
      editor.chain().focus().toggleCodeBlock().run();
      return;
    }

    // Languages we've registered
    const supportedLanguages = [
      "typescript",
      "tsx",
      "javascript",
      "html",
      "css",
      "json",
      "bash",
    ];

    // Provide language options with TypeScript as default
    const language = window.prompt(
      `Enter language (${supportedLanguages.join(", ")}):`,
      "typescript"
    );

    if (!language) return;

    // Validate the language
    const validLanguage = supportedLanguages.includes(language.toLowerCase())
      ? language.toLowerCase()
      : "typescript";

    // Clear any existing formatting before applying code block
    editor.chain()
      .focus()
      .clearNodes()
      .toggleCodeBlock({ language: validLanguage })
      .run();
      
    // Manually trigger highlighting with a delay to ensure the DOM has updated
    setTimeout(() => {
      const codeBlocks = document.querySelectorAll('pre code');
      codeBlocks.forEach((block) => {
        // Set the language class
        block.classList.add(`language-${validLanguage}`);
        // Apply highlighting
        hljs.highlightElement(block as HTMLElement);
      });
    }, 100);
  };

  return (
    <Box sx={{ mb: 2 }}>
      {/* Single row of formatting options */}
      <ToggleButtonGroup 
        size="small" 
        aria-label="Text formatting"
      >
        {/* Headers */}
        <ToggleButton
          value="h1"
          selected={editor.isActive('heading', { level: 1 })}
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 1 }).run();
            forceUpdate({});
          }}
          title="Heading 1"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px 8px',
            backgroundColor: editor.isActive('heading', { level: 1 }) ? 'black' : 'transparent',
            color: editor.isActive('heading', { level: 1 }) ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('heading', { level: 1 }) ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          H1
        </ToggleButton>
        <ToggleButton
          value="h2"
          selected={editor.isActive('heading', { level: 2 })}
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 2 }).run();
            forceUpdate({});
          }}
          title="Heading 2"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px 8px',
            backgroundColor: editor.isActive('heading', { level: 2 }) ? 'black' : 'transparent',
            color: editor.isActive('heading', { level: 2 }) ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('heading', { level: 2 }) ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          H2
        </ToggleButton>
        <ToggleButton
          value="h3"
          selected={editor.isActive('heading', { level: 3 })}
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 3 }).run();
            forceUpdate({});
          }}
          title="Heading 3"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px 8px',
            backgroundColor: editor.isActive('heading', { level: 3 }) ? 'black' : 'transparent',
            color: editor.isActive('heading', { level: 3 }) ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('heading', { level: 3 }) ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          H3
        </ToggleButton>
        <ToggleButton
          value="h4"
          selected={editor.isActive('heading', { level: 4 })}
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 4 }).run();
            forceUpdate({});
          }}
          title="Heading 4"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px 8px',
            backgroundColor: editor.isActive('heading', { level: 4 }) ? 'black' : 'transparent',
            color: editor.isActive('heading', { level: 4 }) ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('heading', { level: 4 }) ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          H4
        </ToggleButton>
        <ToggleButton
          value="bold"
          selected={editor.isActive('bold')}
          onClick={() => {
            editor.chain().focus().toggleBold().run();
            forceUpdate({});
          }}
          title="Bold"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px',
            backgroundColor: editor.isActive('bold') ? 'black' : 'transparent',
            color: editor.isActive('bold') ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('bold') ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          <FormatBold sx={{ fontSize: '1.2rem' }} />
        </ToggleButton>
        <ToggleButton
          value="italic"
          selected={editor.isActive('italic')}
          onClick={() => {
            editor.chain().focus().toggleItalic().run();
            forceUpdate({});
          }}
          title="Italic"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px',
            backgroundColor: editor.isActive('italic') ? 'black' : 'transparent',
            color: editor.isActive('italic') ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('italic') ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          <FormatItalic sx={{ fontSize: '1.2rem' }} />
        </ToggleButton>
        <ToggleButton
          value="bulletList"
          selected={editor.isActive('bulletList')}
          onClick={() => {
            editor.chain().focus().toggleBulletList().run();
            forceUpdate({});
          }}
          title="Bullet List"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px',
            backgroundColor: editor.isActive('bulletList') ? 'black' : 'transparent',
            color: editor.isActive('bulletList') ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('bulletList') ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          <FormatListBulleted sx={{ fontSize: '1.2rem' }} />
        </ToggleButton>
        <ToggleButton
          value="orderedList"
          selected={editor.isActive('orderedList')}
          onClick={() => {
            editor.chain().focus().toggleOrderedList().run();
            forceUpdate({});
          }}
          title="Numbered List"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px',
            backgroundColor: editor.isActive('orderedList') ? 'black' : 'transparent',
            color: editor.isActive('orderedList') ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('orderedList') ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          <FormatListNumbered sx={{ fontSize: '1.2rem' }} />
        </ToggleButton>
        <ToggleButton
          value="code"
          selected={editor.isActive('codeBlock')}
          onClick={() => {
            handleCodeBlock();
            forceUpdate({});
          }}
          title="Code Block"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px',
            backgroundColor: editor.isActive('codeBlock') ? 'black' : 'transparent',
            color: editor.isActive('codeBlock') ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('codeBlock') ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          <Code sx={{ fontSize: '1.2rem' }} />
        </ToggleButton>
        <ToggleButton
          value="link"
          selected={editor.isActive('link')}
          onClick={() => {
            const url = window.prompt("Enter URL");
            if (url) {
              editor.chain().focus().setLink({ href: url }).run();
              forceUpdate({});
            }
          }}
          title="Insert Link"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px',
            backgroundColor: editor.isActive('link') ? 'black' : 'transparent',
            color: editor.isActive('link') ? 'white' : 'inherit',
            '&:hover': {
              backgroundColor: editor.isActive('link') ? 'black' : 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
              },
            },
          }}
        >
          <LinkIcon sx={{ fontSize: '1.2rem' }} />
        </ToggleButton>
        <ToggleButton 
          value="image" 
          component="label"
          title="Insert Image"
          sx={{
            minWidth: '32px',
            height: '32px',
            padding: '4px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageUpload}
          />
          <ImageIcon sx={{ fontSize: '1.2rem' }} />
        </ToggleButton>
        
        {/* Add AI button only if onAIButtonClick is provided */}
        {onAIButtonClick && (
          <ToggleButton
            value="ai"
            onClick={onAIButtonClick}
            title="AI Assistance"
            sx={{
              minWidth: '32px',
              height: '32px',
              padding: '4px',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <AutoAwesomeIcon sx={{ fontSize: '1.2rem' }} />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Box>
  );
};

// Add new component for version preview
const VersionPreview = ({ version, onClose }: { version: BlogVersion; onClose: () => void }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: version.content,
    editable: false,
  });

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Version Preview</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">{version.title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {version.description}
          </Typography>
        </Box>
        <EditorContent editor={editor} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

// Add new component for changes preview
const VersionChanges = ({ version, onClose }: { version: BlogVersion; onClose: () => void }) => {
  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Changes in Version {version.versionNumber}</DialogTitle>
      <DialogContent>
        {version.changes?.modified?.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" color="warning.main">Modified Fields</Typography>
            <List>
              {version.changes.modified.map((field: string) => (
                <ListItem key={field}>
                  <ListItemText primary={field} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        {version.changes?.added?.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" color="success.main">Added Content</Typography>
            <Paper sx={{ p: 2, bgcolor: 'success.light' }}>
              {version.changes.added.map((text: string, i: number) => (
                <Typography key={i} component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
                  {text}
                </Typography>
              ))}
            </Paper>
          </Box>
        )}
        {version.changes?.removed?.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" color="error.main">Removed Content</Typography>
            <Paper sx={{ p: 2, bgcolor: 'error.light' }}>
              {version.changes.removed.map((text: string, i: number) => (
                <Typography key={i} component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
                  {text}
                </Typography>
              ))}
            </Paper>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const BlogPostEditor = () => {
  const { blogId } = useParams<{ blogId: string }>();
  const navigate = useNavigate();
  const { 
    selectedBlog, 
    updateBlog, 
    isLoadingBlog,
    blogVersions,
    isLoadingVersions,
    restoreVersion,
    deleteVersion
  } = useAdminBlogs(blogId);
  const { currentPhotoURL } = useProfilePicture();
  const { currentUser } = useAuth();
  
  // Add state for staged changes
  const [stagedChanges, setStagedChanges] = useState<{
    title?: string;
    description?: string;
    category?: BlogCategory;
    content?: string;
    date?: string;
  }>({});
  
  // Add state for last saved time
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  
  // Add state for AI command dialog
  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState<AICommand | null>(null);
  
  // Add state for versions dialog
  const [versionsDialogOpen, setVersionsDialogOpen] = useState(false);
  
  // Add state for version preview
  const [previewVersion, setPreviewVersion] = useState<BlogVersion | null>(null);
  
  // Add state for changes preview
  const [changesVersion, setChangesVersion] = useState<BlogVersion | null>(null);
  
  // Update scroll trigger configuration
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 300
  });
  
  // Add state for full screen mode
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Add effect to handle URL hash for fullscreen
  useEffect(() => {
    const handleHashChange = () => {
      setIsFullScreen(window.location.hash === '#fullscreen');
    };

    // Set initial state based on hash
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  // Update URL hash when fullscreen state changes
  const toggleFullScreen = () => {
    const newState = !isFullScreen;
    setIsFullScreen(newState);
    if (newState) {
      window.history.pushState(null, '', '#fullscreen');
    } else {
      window.history.pushState(null, '', window.location.pathname);
    }
  };
  
  // Define handleCommandSelect before it's used in editor configuration
  const handleCommandSelect = useCallback((command: AICommand) => {
    console.log('BlogPostEditor handleCommandSelect called with command:', command);
    setSelectedCommand(command);
    setAiDialogOpen(true);
  }, []);
  
  // Now define the editor with access to handleCommandSelect
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        codeBlock: false,
        history: {
          depth: 100,
          newGroupDelay: 500
        },
        heading: {
          levels: [1, 2, 3, 4]
        }
      }),
      Placeholder.configure({
        placeholder: "Write your blog post here...",
      }),
      Link.configure({
        openOnClick: false,
      }),
      Image.configure({
        inline: true,
      }),
      CodeBlockLowlight.configure({
        lowlight,
        defaultLanguage: "tsx",
        HTMLAttributes: {
          class: "hljs",
          spellcheck: "false",
          "data-language": "tsx",
        },
      }),
      // Add the AI Commands extension
      AICommands.configure({
        suggestion: {
          char: '/',
          items: ({ query }) => {
            console.log('BlogPostEditor items called with query:', query);
            return defaultAICommands
              .filter(item => 
                item.command.toLowerCase().startsWith('/' + query.toLowerCase()) || 
                item.command.toLowerCase().startsWith(query.toLowerCase()))
              .slice(0, 10);
          },
          command: ({ editor, range, props }) => {
            console.log('BlogPostEditor command called with props:', props);
            // Delete the slash command input
            editor.chain().focus().deleteRange(range).run();
            
            // Open the AI dialog with the selected command
            if (props) {
              handleCommandSelect(props);
            }
          },
          render: () => {
            console.log('BlogPostEditor render called');
            return CommandSuggestionPlugin().render();
          },
        },
      }),
    ],
    content: selectedBlog?.content || "",
    onUpdate: ({ editor }) => {
      console.log('Editor content updated');
      setStagedChanges((prev) => ({
        ...prev,
        content: editor.getHTML(),
      }));
    },
  });
  
  // Define these callbacks after editor is defined
  const handleInsertAIContent = useCallback((content: string) => {
    if (editor) {
      // Insert the generated content at the current cursor position
      editor.chain().focus().insertContent(content).run();
    }
  }, [editor]);
  
  // Get surrounding context for AI
  const getEditorContext = useCallback(() => {
    if (!editor) return '';
    
    // Get the current selection
    const { from, to } = editor.state.selection;
    
    // Get some context before and after the selection
    const doc = editor.state.doc;
    const start = Math.max(0, from - 500);
    const end = Math.min(doc.content.size, to + 500);
    
    // Extract the text
    let context = '';
    doc.nodesBetween(start, end, (node) => {
      if (node.isText) {
        context += node.text;
      }
      return true;
    });
    
    return context;
  }, [editor]);
  
  // Check if there are unsaved changes by comparing with original values
  const hasUnsavedChanges = useMemo(() => {
    if (!selectedBlog) return false;
    
    // Check if any staged changes exist and are different from original values
    if (stagedChanges.title !== undefined && stagedChanges.title !== selectedBlog.title) {
      return true;
    }
    
    if (stagedChanges.description !== undefined && stagedChanges.description !== selectedBlog.description) {
      return true;
    }
    
    if (stagedChanges.category !== undefined && stagedChanges.category !== selectedBlog.category) {
      return true;
    }
    
    if (stagedChanges.content !== undefined && stagedChanges.content !== selectedBlog.content) {
      return true;
    }
    
    if (stagedChanges.date !== undefined && stagedChanges.date !== selectedBlog.date) {
      return true;
    }
    
    return false;
  }, [selectedBlog, stagedChanges]);

  // This effect handles initial content loading and updates
  useEffect(() => {
    if (selectedBlog?.content && editor && !editor.isDestroyed) {
      // Only set content if it's different from current editor content
      // to avoid unnecessary re-renders
      const currentContent = editor.getHTML();
      if (currentContent !== selectedBlog.content) {
        editor.commands.setContent(selectedBlog.content);
      }
    }
    
    // Set last saved time from the blog's lastModified field
    if (selectedBlog?.lastModified) {
      setLastSaved(new Date(selectedBlog.lastModified));
    }
  }, [selectedBlog, editor]);

  // This effect handles editor content changes
  useEffect(() => {
    const handleEditorUpdate = () => {
      if (editor && !editor.isDestroyed && selectedBlog) {
        const newContent = editor.getHTML();
        // Only update staged changes if content actually changed
        if (newContent !== selectedBlog.content) {
          setStagedChanges(prev => ({
            ...prev,
            content: newContent
          }));
        }
      }
    };

    if (editor && !editor.isDestroyed) {
      editor.on('update', handleEditorUpdate);
      
      return () => {
        editor.off('update', handleEditorUpdate);
      };
    }
  }, [editor, selectedBlog]);

  // Add this effect to manually highlight code blocks
  useEffect(() => {
    if (editor) {
      const highlightCodeBlocks = () => {
        // Find all code blocks in the editor
        const codeBlocks = document.querySelectorAll('pre code');
        
        // Apply highlight.js to each code block
        codeBlocks.forEach((block) => {
          // Make sure the language class is set based on the data-language attribute
          const pre = block.closest('pre');
          const language = pre?.getAttribute('data-language');
          
          if (language) {
            // Remove any existing language classes
            block.className = block.className.replace(/language-\w+/g, '');
            
            // Add the appropriate language class
            block.classList.add(`language-${language}`);
            
            // Special handling for TSX/JSX content
            if (language === 'typescript' && block.textContent?.includes('JSX')) {
              block.classList.add('language-tsx');
            }
          }
          
          // Apply highlighting
          hljs.highlightElement(block as HTMLElement);
        });
      };

      // Run once on mount
      setTimeout(highlightCodeBlocks, 100);

      // Add event listener for content changes
      editor.on('update', () => {
        setTimeout(highlightCodeBlocks, 100);
      });
      
      return () => {
        editor.off('update');
      };
    }
  }, [editor]);

  // Handle AI button click
  const handleAIButtonClick = useCallback(() => {
    // Open dialog with paragraph command by default
    const paragraphCommand = defaultAICommands.find(cmd => cmd.command === '/paragraph');
    if (paragraphCommand) {
      setSelectedCommand(paragraphCommand);
      setAiDialogOpen(true);
    }
  }, []);

  const handleSave = async (newState?: BlogState) => {
    if (!selectedBlog || !currentUser) return;

    try {
      const updateData: BlogPostUpdate = {
        id: selectedBlog.id,
        lastModified: new Date().toISOString(),
        author: {
          ...selectedBlog.author,
          avatar: currentPhotoURL || selectedBlog.author.avatar,
        },
        ...(stagedChanges.title !== undefined && { title: stagedChanges.title }),
        ...(stagedChanges.description !== undefined && { description: stagedChanges.description }),
        ...(stagedChanges.category !== undefined && { category: stagedChanges.category }),
        ...(stagedChanges.content !== undefined && { content: stagedChanges.content }),
        ...(stagedChanges.date !== undefined && { date: stagedChanges.date }),
        ...(newState !== undefined && { 
          state: newState,
          isPublished: newState === "live"
        }),
      };

      await updateBlog(updateData);
      
      setStagedChanges({});
      setLastSaved(new Date());
    } catch (error) {
      console.error("Failed to save blog:", error);
    }
  };

  const handleHeaderImageChange = async (url: string) => {
    if (!selectedBlog) return;

    try {
      await updateBlog({
        id: selectedBlog.id,
        headerImage: url,
        lastModified: new Date().toISOString(),
      });
      
      // Update last saved time after header image change
      setLastSaved(new Date());
      
      // Clear any staged changes for header image
      setStagedChanges(prev => {
        const newChanges = { ...prev };
        if ('headerImage' in newChanges) {
          delete newChanges.headerImage;
        }
        return newChanges;
      });
    } catch (error) {
      console.error("Failed to update header image:", error);
    }
  };

  // Update the VersionsDialog component
  const VersionsDialog = () => {
    // Add confirmation state
    const [deleteConfirmation, setDeleteConfirmation] = useState<BlogVersion | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    if (!blogVersions || isLoadingVersions) {
      return (
        <Dialog open={versionsDialogOpen} onClose={() => setVersionsDialogOpen(false)}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      );
    }

    const handleDeleteVersion = async (version: BlogVersion) => {
      console.log('handleDeleteVersion called with version:', version);
      if (!version.id || !blogId) {
        console.error('Missing required IDs:', { versionId: version.id, blogId });
        return;
      }
      
      setIsDeleting(true);
      try {
        console.log('Attempting to delete version:', version.id);
        await deleteVersion(version.id);
        console.log('Version deleted successfully');
        setDeleteConfirmation(null);
        // Close the versions dialog if there are no more versions
        if (blogVersions.length <= 1) {
          setVersionsDialogOpen(false);
        }
      } catch (error) {
        console.error('Error deleting version:', error);
      } finally {
        setIsDeleting(false);
      }
    };

    return (
      <>
        <Dialog 
          open={versionsDialogOpen} 
          onClose={() => setVersionsDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Version History</DialogTitle>
          <DialogContent>
            <List>
              {blogVersions.map((version) => (
                <ListItem 
                  key={version.id}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    py: 2
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        Version {version.versionNumber}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        Created by {version.createdByName} on{' '}
                        {new Date(version.createdAt).toLocaleString()}
                      </Typography>
                    }
                  />
                  <Stack direction="row" spacing={1}>

                    <Button
                      size="small"
                      startIcon={<CompareIcon />}
                      onClick={() => setChangesVersion(version)}
                    >
                      Changes
                    </Button>
                    <Button
                      size="small"
                      startIcon={<RestoreIcon />}
                      onClick={async () => {
                        await restoreVersion(version);
                        setVersionsDialogOpen(false);
                      }}
                    >
                      Restore
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => setDeleteConfirmation(version)}
                      disabled={blogVersions.length <= 1}
                    >
                      Delete
                    </Button>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={!!deleteConfirmation}
          onClose={() => setDeleteConfirmation(null)}
        >
          <DialogTitle>Delete Version {deleteConfirmation?.versionNumber}</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete version {deleteConfirmation?.versionNumber}? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setDeleteConfirmation(null)}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => deleteConfirmation && handleDeleteVersion(deleteConfirmation)}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                  Deleting...
                </>
              ) : (
                'Delete'
              )}
            </Button>
          </DialogActions>
        </Dialog>

        {previewVersion && (
          <VersionPreview 
            version={previewVersion} 
            onClose={() => setPreviewVersion(null)} 
          />
        )}

        {changesVersion && (
          <VersionChanges 
            version={changesVersion} 
            onClose={() => setChangesVersion(null)} 
          />
        )}
      </>
    );
  };

  // Create array of formatting actions
  const formatActions = [
    { icon: <TitleIcon />, name: 'H1', onClick: () => editor?.chain().focus().toggleHeading({ level: 1 }).run() },
    { icon: <TitleIcon />, name: 'H2', onClick: () => editor?.chain().focus().toggleHeading({ level: 2 }).run() },
    { icon: <TitleIcon />, name: 'H3', onClick: () => editor?.chain().focus().toggleHeading({ level: 3 }).run() },
    { icon: <TitleIcon />, name: 'H4', onClick: () => editor?.chain().focus().toggleHeading({ level: 4 }).run() },
    { icon: <FormatBold />, name: 'Bold', onClick: () => editor?.chain().focus().toggleBold().run() },
    { icon: <FormatItalic />, name: 'Italic', onClick: () => editor?.chain().focus().toggleItalic().run() },
    { icon: <FormatListBulleted />, name: 'Bullet List', onClick: () => editor?.chain().focus().toggleBulletList().run() },
    { icon: <FormatListNumbered />, name: 'Numbered List', onClick: () => editor?.chain().focus().toggleOrderedList().run() },
    { icon: <Code />, name: 'Code Block', onClick: () => editor?.chain().focus().toggleCodeBlock().run() },
    { icon: <LinkIcon />, name: 'Link', onClick: () => {
      const url = window.prompt("Enter URL");
      if (url) editor?.chain().focus().setLink({ href: url }).run();
    }},
    { icon: <ImageIcon />, name: 'Image', onClick: () => {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.onchange = async (e) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          try {
            const response = await fetch('/api/upload', {
              method: 'POST',
              body: formData,
            });
            const data = await response.json();
            if (data.url) {
              editor?.chain().focus().setImage({ src: data.url }).run();
            }
          } catch (error) {
            console.error('Error uploading image:', error);
          }
        }
      };
      fileInput.click();
    }},
    { icon: <AutoAwesomeIcon />, name: 'AI', onClick: () => setAiDialogOpen(true) },
  ];

  if (isLoadingBlog || !selectedBlog) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Image Upload */}
      <Box sx={{ mb: 3, position: "relative" }}>
        {selectedBlog.headerImage ? (
          <Box
            sx={{
              position: "relative",
              height: "300px",
              borderRadius: 1,
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)",
                zIndex: 1,
              },
            }}
          >
            <img
              src={selectedBlog.headerImage}
              alt="Blog header"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                zIndex: 2,
                display: "flex",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                component="label"
                sx={{ bgcolor: "background.paper", color: "text.primary" }}
              >
                Change Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const storageRef = ref(
                        storage,
                        `public/blog-headers/${blogId}/${file.name}`
                      );
                      uploadBytes(storageRef, file)
                        .then((snapshot) => getDownloadURL(snapshot.ref))
                        .then((url) => handleHeaderImageChange(url))
                        .catch((error) =>
                          console.error("Failed to upload header image:", error)
                        );
                    }
                  }}
                />
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleHeaderImageChange("")}
                sx={{ bgcolor: "background.paper", color: "error.main" }}
              >
                Remove
              </Button>
            </Box>
          </Box>
        ) : (
          <Button
            component="label"
            variant="outlined"
            fullWidth
            sx={{
              height: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              borderStyle: "dashed",
              borderWidth: 2,
              "&:hover": {
                borderStyle: "dashed",
                borderWidth: 2,
              },
            }}
          >
            <ImageIcon sx={{ fontSize: 48, opacity: 0.5 }} />
            <Typography variant="h6" color="text.secondary">
              Upload Header Image
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Recommended size: 1200x630px
            </Typography>
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const storageRef = ref(
                    storage,
                    `public/blog-headers/${blogId}/${file.name}`
                  );
                  uploadBytes(storageRef, file)
                    .then((snapshot) => getDownloadURL(snapshot.ref))
                    .then((url) => handleHeaderImageChange(url))
                    .catch((error) =>
                      console.error("Failed to upload header image:", error)
                    );
                }
              }}
            />
          </Button>
        )}
      </Box>

      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 3, gap: 2 }}>
        <IconButton
          onClick={() => navigate("/admin/beta-features/blog-writer")}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ flex: 1 }}>
          Edit Blog Post
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          {lastSaved && !hasUnsavedChanges && (
            <Typography variant="body2" color="text.secondary">
              Last saved: {lastSaved.toLocaleString(undefined, {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}
            </Typography>
          )}
          <Button
            variant="outlined"
            startIcon={<SaveIcon />}
            onClick={() => handleSave(selectedBlog.state)}
            disabled={!hasUnsavedChanges}
          >
            {hasUnsavedChanges ? "Save Changes" : "No Changes"}
          </Button>
          {selectedBlog.state === "live" ? (
            <Button
              variant="contained"
              color="warning"
              startIcon={<SaveIcon />}
              onClick={() => handleSave("draft")}
            >
              Unpublish to Draft
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={() => handleSave("draft")}
              >
                Save Draft
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<PublishIcon />}
                onClick={() => handleSave("live")}
              >
                Make Live
              </Button>
            </>
          )}
          <Button
            variant="outlined"
            startIcon={<HistoryIcon />}
            onClick={() => setVersionsDialogOpen(true)}
          >
            Versions
          </Button>
        </Stack>
      </Box>

      {/* Metadata Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Title"
            fullWidth
            value={stagedChanges.title !== undefined ? stagedChanges.title : selectedBlog.title}
            onChange={(e) =>
              setStagedChanges({
                ...stagedChanges,
                title: e.target.value
              })
            }
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={stagedChanges.description !== undefined ? stagedChanges.description : selectedBlog.description}
            onChange={(e) =>
              setStagedChanges({
                ...stagedChanges,
                description: e.target.value
              })
            }
          />
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <TextField
              select
              label="Category"
              value={stagedChanges.category !== undefined ? stagedChanges.category : selectedBlog.category}
              onChange={(e) =>
                setStagedChanges({
                  ...stagedChanges,
                  category: e.target.value as BlogCategory
                })
              }
              sx={{ width: 200 }}
            >
              {Object.values(BlogCategory).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="date"
              label="Post Date"
              value={stagedChanges.date !== undefined ? stagedChanges.date : selectedBlog.date || new Date().toISOString().split('T')[0]}
              onChange={(e) =>
                setStagedChanges({
                  ...stagedChanges,
                  date: e.target.value
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: 200 }}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml: 'auto' }}>
              <Typography variant="body2">Status:</Typography>
              <Chip
                label={selectedBlog.state.replace("_", " ").toUpperCase()}
                color={
                  selectedBlog.state === "live"
                    ? "success"
                    : selectedBlog.state === "draft"
                      ? "default"
                      : "warning"
                }
              />
            </Box>
          </Box>
        </Box>
      </Paper>

      {/* Editor */}
      <Paper sx={{ 
        p: 3, 
        mb: 3,
        boxShadow: 'none',
        position: isFullScreen ? 'fixed' : 'relative',
        top: isFullScreen ? 0 : 'auto',
        left: isFullScreen ? 0 : 'auto',
        right: isFullScreen ? 0 : 'auto',
        bottom: isFullScreen ? 0 : 'auto',
        width: isFullScreen ? '100vw' : 'auto',
        height: isFullScreen ? '100vh' : 'auto',
        zIndex: isFullScreen ? 1300 : 'auto',
        bgcolor: 'background.paper',
        overflow: isFullScreen ? 'auto' : 'visible',
        display: 'flex',
        flexDirection: 'column',
        '& .ProseMirror': { 
          flex: 1,
          minHeight: isFullScreen ? 'calc(100vh - 200px)' : '500px',
          outline: 'none',
          '& pre': { 
            margin: '1em 0',
            borderRadius: '4px',
            overflow: 'auto',
          },
          '& pre code': {
            fontFamily: 'monospace',
            fontSize: '0.9rem',
            lineHeight: 1.5
          },
          '& p': {
            margin: '0.5em 0'
          },
          '& > *:first-child': {
            marginTop: 0
          }
        } 
      }}>
        {isFullScreen ? (
          // Full-screen header
          <Box sx={{ 
            position: 'sticky',
            top: 0,
            bgcolor: 'background.paper',
            borderBottom: 1,
            borderColor: 'divider',
            zIndex: 1,
            px: 3,
            py: 2,
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton onClick={toggleFullScreen}>
                <FullscreenExitIcon />
              </IconButton>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                {stagedChanges.title !== undefined ? stagedChanges.title : selectedBlog.title}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={() => handleSave(selectedBlog.state)}
                disabled={!hasUnsavedChanges}
              >
                {hasUnsavedChanges ? "Save Changes" : "Saved"}
              </Button>
            </Box>
          </Box>
        ) : (
          // Regular header
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="h6">Content</Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Use the toolbar to format your content or type / for AI assistance
              </Typography>
            </Box>
            <Button
              variant="outlined"
              onClick={toggleFullScreen}
              startIcon={<FullscreenIcon />}
              size="small"
            >
              Edit in Fullscreen Mode
            </Button>
          </Box>
        )}
        
        <MenuBar editor={editor} onAIButtonClick={handleAIButtonClick} />
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <EditorContent editor={editor} />
        </Box>
      </Paper>
      
      {/* AI Generation Dialog */}
      <AIGenerationDialog
        open={aiDialogOpen}
        onClose={() => setAiDialogOpen(false)}
        onInsert={handleInsertAIContent}
        command={selectedCommand}
        context={getEditorContext()}
        category={selectedBlog?.category}
      />
      <VersionsDialog />

      {/* Quick Actions SpeedDial - Show in non-full-screen mode when scrolled */}
      {!isFullScreen && trigger && (
        <SpeedDial
          ariaLabel="Formatting options"
          sx={{
            position: 'fixed',
            top: { xs: 64, sm: 76 },
            right: { xs: 16, sm: 24 },
            zIndex: 1000,
            '& .MuiFab-primary': { 
              width: 48,
              height: 48,
              minHeight: 48,
              bgcolor: 'background.paper',
              color: 'text.primary',
              boxShadow: 4,
              '&:hover': {
                bgcolor: 'background.paper',
              }
            }
          }}
          icon={<SpeedDialIcon />}
          direction="left"
          FabProps={{
            sx: {
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
              }
            }
          }}
          open={true}
        >
          {formatActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
              FabProps={{
                sx: {
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                  }
                }
              }}
            />
          ))}
        </SpeedDial>
      )}
    </Box>
  );
};

export default BlogPostEditor;

