import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Collapse,
  Chip,
  Snackbar,
  CircularProgress,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import AddIcon from "@mui/icons-material/Add";
import { useLocalStorageBoolState } from "hooks/useLocalStorage";
import { Client } from "@freetech/models/projects";
import { IFeatureRequest, FeatureRequestStatus, ICreateFeatureRequest } from "@freetech/models";
import { useAdminFeatureRequest } from "hooks/admin/useAdminFeatureRequest";
import { useFreelo } from "hooks/freelo/useFreelo";
import { CreateTicketDialog, FeatureRequestCard, CreateFeatureRequestDialog } from "./components";
import { useStakeholderFeatureRequest } from "hooks/stakeholder/useStakeholderFeatureRequest";
import { useFreeloBoard } from "hooks/freelo/useFreeloBoard";

interface AssociatedFeatureRequestsProps {
  client: Client;
  clientId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`feature-request-tabpanel-${index}`}
      aria-labelledby={`feature-request-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const AssociatedFeatureRequests: React.FC<AssociatedFeatureRequestsProps> = ({
  client,
  clientId,
}) => {
  const [isVisible, setIsVisible] = useLocalStorageBoolState(
    "isAssociatedFeatureRequestsVisible",
    false
  );
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [createTicketDialog, setCreateTicketDialog] = useState<{
    open: boolean;
    featureRequest: IFeatureRequest | null;
  }>({
    open: false,
    featureRequest: null,
  });
  const [createDialog, setCreateDialog] = useState(false);

  const {
    featureRequests,
    isLoading: isLoadingRequests,
    approveFeatureRequest,
    rejectFeatureRequest,
    updateFeatureRequest,
  } = useAdminFeatureRequest(clientId);
  const project = client.projects?.[0];
  const { data: board } = useFreeloBoard(project?.freeloBoardId || "");

  const { submitFeatureRequest, isSubmitting: isCreating } = useStakeholderFeatureRequest({
    clientId,
  });

  const getLinkedTickets = (request: IFeatureRequest) => {
    if (!board?.lists || !request.freeloTicketIds) return [];
    return board.lists
      .flatMap((list) => list.cards || [])
      .filter((card) => request.freeloTicketIds?.includes(card.id));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getRequestsCountByStatus = (status: FeatureRequestStatus) => {
    return featureRequests?.filter((fr) => fr.status === status).length || 0;
  };

  const handleOpenCreateTicketDialog = (request: IFeatureRequest) => {
    setCreateTicketDialog({
      open: true,
      featureRequest: request,
    });
  };

  const handleCloseCreateTicketDialog = () => {
    setCreateTicketDialog({
      open: false,
      featureRequest: null,
    });
  };

  const handleCreateFeatureRequest = async (data: ICreateFeatureRequest) => {
    try {
      await submitFeatureRequest(data);
      setError("Feature request created successfully!");
      setCreateDialog(false);
    } catch (error) {
      setError("Failed to create feature request. Please try again.");
      console.error("Error creating feature request:", error);
    }
  };

  return (
    <>
      <Card
        elevation={2}
        sx={{
          width: "100%",
          mb: 3,
          background: "linear-gradient(45deg, #1a237e 30%, #283593 90%)",
        }}
      >
        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <Box display="flex" alignItems="center">
              <LightbulbIcon sx={{ fontSize: 28, mr: 2 }} />
              <Typography variant="h6" component="h2">
                Feature Requests
                {getRequestsCountByStatus("pending_approval") > 0 && (
                  <Chip
                    label={getRequestsCountByStatus("pending_approval")}
                    color="error"
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Typography>
              <IconButton
                onClick={() => setIsVisible(!isVisible)}
                sx={{ color: "white", ml: 2 }}
                size="small"
              >
                {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => setCreateDialog(true)}
            >
              Create Feature Request
            </Button>
          </Box>

          <Collapse in={isVisible}>
            <Box sx={{ mt: 2, bgcolor: "background.paper", borderRadius: 1 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="feature request tabs"
                sx={{ borderBottom: 1, borderColor: "divider" }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Pending
                      {getRequestsCountByStatus("pending_approval") > 0 && (
                        <Chip
                          label={getRequestsCountByStatus("pending_approval")}
                          color="error"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Approved
                      {getRequestsCountByStatus("approved") > 0 && (
                        <Chip
                          label={getRequestsCountByStatus("approved")}
                          color="success"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      In Progress
                      {getRequestsCountByStatus("in_progress") > 0 && (
                        <Chip
                          label={getRequestsCountByStatus("in_progress")}
                          color="warning"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Completed
                      {getRequestsCountByStatus("completed") > 0 && (
                        <Chip
                          label={getRequestsCountByStatus("completed")}
                          color="success"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Rejected
                      {getRequestsCountByStatus("rejected") > 0 && (
                        <Chip
                          label={getRequestsCountByStatus("rejected")}
                          color="error"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Cancelled
                      {getRequestsCountByStatus("cancelled") > 0 && (
                        <Chip
                          label={getRequestsCountByStatus("cancelled")}
                          color="default"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
              </Tabs>

              {isLoadingRequests ? (
                <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <TabPanel value={tabValue} index={0}>
                    {featureRequests
                      ?.filter((fr) => fr.status === "pending_approval")
                      .map((request) => (
                        <FeatureRequestCard
                          key={request.id}
                          request={request}
                          linkedTickets={getLinkedTickets(request)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    {featureRequests
                      ?.filter((fr) => fr.status === "approved")
                      .map((request) => (
                        <FeatureRequestCard
                          key={request.id}
                          request={request}
                          linkedTickets={getLinkedTickets(request)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    {featureRequests
                      ?.filter((fr) => fr.status === "in_progress")
                      .map((request) => (
                        <FeatureRequestCard
                          key={request.id}
                          request={request}
                          linkedTickets={getLinkedTickets(request)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    {featureRequests
                      ?.filter((fr) => fr.status === "completed")
                      .map((request) => (
                        <FeatureRequestCard
                          key={request.id}
                          request={request}
                          linkedTickets={getLinkedTickets(request)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    {featureRequests
                      ?.filter((fr) => fr.status === "rejected")
                      .map((request) => (
                        <FeatureRequestCard
                          key={request.id}
                          request={request}
                          linkedTickets={getLinkedTickets(request)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    {featureRequests
                      ?.filter((fr) => fr.status === "cancelled")
                      .map((request) => (
                        <FeatureRequestCard
                          key={request.id}
                          request={request}
                          linkedTickets={getLinkedTickets(request)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                </>
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>

      {createTicketDialog.featureRequest && (
        <CreateTicketDialog
          open={createTicketDialog.open}
          onClose={handleCloseCreateTicketDialog}
          featureRequest={createTicketDialog.featureRequest}
          boardId={board?.id || ""}
          listId={board?.lists?.[0]?.id || ""}
          onSuccess={() => setError("Freelo ticket created successfully!")}
          onError={setError}
        />
      )}

      <CreateFeatureRequestDialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
        onSubmit={handleCreateFeatureRequest}
        clientId={clientId}
        projectId={project?.id || ""}
        isSubmitting={isCreating}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </>
  );
};

export default AssociatedFeatureRequests;
