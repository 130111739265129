import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { BugReportAttachment } from "@freetech/models/bugReport";

export const updateBugReportAttachments = async (
  bugReportId: string,
  files: File[]
): Promise<void> => {
  try {
    const bugReportRef = doc(db, "bugReports", bugReportId);

    // Get current attachments
    const bugReportDoc = await getDoc(bugReportRef);
    const currentAttachments = bugReportDoc.data()?.attachments || [];

    // Upload new files and get attachments
    const uploadPromises = files.map(async (file) => {
      const storageRef = ref(storage, `bugReports/${bugReportId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      return {
        id: `${bugReportId}_${file.name}`,
        name: file.name,
        url,
        uploadedAt: new Date().toISOString(),
      } as BugReportAttachment;
    });

    const newAttachments = await Promise.all(uploadPromises);

    // Combine existing and new attachments
    const allAttachments = [...currentAttachments, ...newAttachments];

    console.log("allAttachments", allAttachments);
    // Update Firestore document
    await updateDoc(bugReportRef, {
      attachments: allAttachments,
      updatedAt: new Date(),
    });
  } catch (error) {
    console.error("Error updating attachments:", error);
  }
};
