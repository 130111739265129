import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Paper,
  useTheme,
  alpha,
  IconButton,
  Tooltip,
  Button,
  Stack,
} from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatter,
  GridToolbarContainer,
  GridToolbarProps,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SendIcon from "@mui/icons-material/Send";
import { Timesheet } from "@freetech/models/timesheet";

interface ProjectTotal {
  projectName: string;
  totalHours: number;
}

interface SelectedProjectTotal {
  projectName: string;
  totalHours: number;
}

interface TimesheetApprovalsGridProps {
  userEntries: Timesheet[];
  userName: string;
  handleApproveTimesheet?: (id: string) => Promise<void>;
  handleRejectTimesheet?: (id: string, reason?: string) => Promise<void>;
  handleSubmitForUser?: (id: string) => Promise<void>;
  handleRevokeApproval?: (id: string) => Promise<void>;
  showActions: boolean;
  isUnsubmittedTab: boolean;
  isArchiveTab: boolean;
  handleBulkApprove?: (ids: string[]) => Promise<void>;
  handleBulkReject?: (ids: string[], reason?: string) => Promise<void>;
  handleBulkSubmit?: (ids: string[]) => Promise<void>;
  handleBulkRevoke?: (ids: string[]) => Promise<void>;
}

const BulkActionsToolbar = React.forwardRef<
  HTMLDivElement,
  GridToolbarProps & {
    selectedIds: string[];
    isUnsubmittedTab: boolean;
    isArchiveTab: boolean;
    onBulkApprove?: (ids: string[]) => Promise<void>;
    onBulkReject?: (ids: string[], reason?: string) => Promise<void>;
    onBulkSubmit?: (ids: string[]) => Promise<void>;
    onBulkRevoke?: (ids: string[]) => Promise<void>;
    rows: Timesheet[];
    onSelectionModelChange: (newSelection: string[]) => void;
    gridApi?: any;
  }
>((props, ref) => {
  const {
    selectedIds,
    isUnsubmittedTab,
    isArchiveTab,
    onBulkApprove,
    onBulkReject,
    onBulkSubmit,
    onBulkRevoke,
    rows,
    onSelectionModelChange,
    gridApi,
    ...toolbarProps
  } = props;

  const theme = useTheme();

  const selectedTotals = useMemo(() => {
    const selectedRows = rows.filter((row: Timesheet) => selectedIds.includes(row.id));
    return selectedRows.reduce(
      (acc: Record<string, SelectedProjectTotal>, entry: Timesheet) => {
        const projectKey = entry.projectId;
        if (!acc[projectKey]) {
          acc[projectKey] = {
            projectName: entry.projectName || "Unknown Project",
            totalHours: 0,
          };
        }
        acc[projectKey].totalHours += (entry.duration || 0) / 3600;
        return acc;
      },
      {} as Record<string, SelectedProjectTotal>
    );
  }, [selectedIds, rows]);

  const handleDeselectProject = (projectId: string) => {
    const projectRows = rows.filter((row: Timesheet) => row.projectId === projectId);
    const projectRowIds = projectRows.map((row: Timesheet) => row.id);
    
    const newSelection = selectedIds.filter((id: string) => !projectRowIds.includes(id));
    
    onSelectionModelChange(newSelection);

    if (gridApi) {
      projectRowIds.forEach((id: string) => {
        gridApi.current.setRowSelectionModel(newSelection);
      });
    }
  };

  const handleBulkAction = async (
    action: (ids: string[]) => Promise<void>,
    ids: string[]
  ) => {
    try {
      await action(ids);
      // Clear selection after successful action
      onSelectionModelChange([]);
      if (gridApi) {
        gridApi.current.setRowSelectionModel([]);
      }
    } catch (error) {
      // Error handling is done in the parent component
      console.error('Bulk action failed:', error);
    }
  };

  if (selectedIds.length === 0) {
    return (
      <GridToolbarContainer ref={ref}>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const totalSelectedHours = (Object.values(selectedTotals) as SelectedProjectTotal[]).reduce(
    (sum: number, project: SelectedProjectTotal) => sum + project.totalHours,
    0
  );

  return (
    <GridToolbarContainer ref={ref}>
      <Stack 
        direction="column" 
        spacing={2} 
        sx={{ 
          p: 1, 
          width: '100%' 
        }}
      >
        {/* Top row with selection count and action buttons */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          width: '100%' 
        }}>
          <Typography variant="subtitle1">
            {selectedIds.length} items selected ({totalSelectedHours.toFixed(2)}h total)
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {isUnsubmittedTab ? (
              <Button
                startIcon={<SendIcon />}
                variant="contained"
                onClick={() => handleBulkAction(onBulkSubmit!, selectedIds)}
              >
                Submit Selected
              </Button>
            ) : isArchiveTab ? (
              <Button
                startIcon={<CancelOutlinedIcon />}
                variant="contained"
                color="warning"
                onClick={() => handleBulkAction(onBulkRevoke!, selectedIds)}
              >
                Revoke Selected
              </Button>
            ) : (
              <>
                <Button
                  startIcon={<CheckCircleOutlineIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() => handleBulkAction(onBulkApprove!, selectedIds)}
                >
                  Approve Selected
                </Button>
                <Button
                  startIcon={<CancelOutlinedIcon />}
                  variant="contained"
                  color="error"
                  onClick={() => handleBulkAction(onBulkReject!, selectedIds)}
                >
                  Reject Selected
                </Button>
              </>
            )}
          </Box>
        </Box>

        {/* Updated project totals grid */}
        <Box 
          sx={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: 2,
            width: '100%',
            bgcolor: 'background.paper',
            p: 1,
            borderRadius: 1,
            border: 1,
            borderColor: 'divider'
          }}
        >
          {(Object.entries(selectedTotals) as [string, SelectedProjectTotal & { projectId: string }][]).map(([projectId, project]) => (
            <Box 
              key={projectId}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1,
                borderRadius: 1,
                bgcolor: alpha(theme.palette.primary.main, 0.04),
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {project.projectName}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  {project.totalHours.toFixed(2)}h
                </Typography>
              </Box>
              <IconButton
                size="small"
                onClick={() => handleDeselectProject(projectId)}
                sx={{ ml: 1 }}
                color="primary"
              >
                <Tooltip title="Deselect Project">
                  <CancelOutlinedIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          ))}
        </Box>
      </Stack>
    </GridToolbarContainer>
  );
});

BulkActionsToolbar.displayName = 'BulkActionsToolbar';

const TimesheetApprovalsGrid: React.FC<TimesheetApprovalsGridProps> = ({
  userEntries,
  userName,
  handleApproveTimesheet,
  handleRejectTimesheet,
  handleSubmitForUser,
  handleRevokeApproval,
  showActions,
  isUnsubmittedTab,
  isArchiveTab,
  handleBulkApprove,
  handleBulkReject,
  handleBulkSubmit,
  handleBulkRevoke,
}) => {
  const theme = useTheme();

  const projectTotals = useMemo(() => {
    return userEntries.reduce(
      (acc, entry) => {
        const projectKey = entry.projectId;
        if (!acc[projectKey]) {
          acc[projectKey] = {
            projectName: entry.projectName || "Unknown Project",
            totalHours: 0,
          };
        }
        acc[projectKey].totalHours += (entry.duration || 0) / 3600; // Convert to hours
        return acc;
      },
      {} as Record<string, ProjectTotal>
    );
  }, [userEntries]);

  const dateRange = useMemo(() => {
    if (userEntries.length === 0) return "";
    const dates = userEntries.map((entry) => DateTime.fromISO(entry.date));
    const minDate = DateTime.min(...dates);
    const maxDate = DateTime.max(...dates);
    return `(${minDate.toFormat("MMM d")} - ${maxDate.toFormat("MMM d, yyyy")})`;
  }, [userEntries]);

  const columns: GridColDef[] = [
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 120,
      //   valueFormatter: (params: GridValueFormatter) => DateTime.fromISO(params.value).toFormat("yyyy-MM-dd"),
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      minWidth: 120,
      valueGetter: (value, row, column, apiRef) => {
        const startTime = DateTime.fromISO(row.startTime).toFormat("HH:mm");
        const endTime = DateTime.fromISO(row.endTime).toFormat("HH:mm");
        return `${startTime} - ${endTime}`;
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value: number) => {
        const totalSeconds = value; // Convert ms to seconds
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },
  ];

  if (isArchiveTab) {
    columns.push(
      {
        field: "approvedAt",
        headerName: "Approval Date",
        flex: 1,
        minWidth: 150,
        type: "date",
        valueGetter: (params: any) => DateTime.fromISO(params.value).toJSDate(),
        // valueFormatter: (params) =>
        //   DateTime.fromJSDate(params.value as Date).toFormat("yyyy-MM-dd HH:mm"),
        // valueSetter: (params) => {
        //   const newDate = DateTime.fromJSDate(params.value as Date);
        //   return { ...params.row, date: newDate.toISODate() };
        // },
      },
      {
        field: "approvedByEmail",
        headerName: "Approved By",
        flex: 1,
        minWidth: 150,
      }
    );
  }

  if (showActions) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {isUnsubmittedTab ? (
            <Tooltip title="Submit for User">
              <IconButton
                onClick={() =>
                  handleSubmitForUser && handleSubmitForUser(params.row.id)
                }
                color="primary"
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          ) : isArchiveTab ? (
            <Tooltip title="Revoke Approval">
              <IconButton
                onClick={() =>
                  handleRevokeApproval && handleRevokeApproval(params.row.id)
                }
                color="warning"
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Approve">
                <IconButton
                  onClick={() =>
                    handleApproveTimesheet &&
                    handleApproveTimesheet(params.row.id)
                  }
                  color="primary"
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton
                  onClick={() =>
                    handleRejectTimesheet &&
                    handleRejectTimesheet(params.row.id)
                  }
                  color="error"
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    });
  }

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const gridApiRef = React.useRef<any>(null);

  return (
    <Box sx={{ mb: 4 }}>
      <Paper
        elevation={3}
        sx={{ p: 2, borderRadius: theme.shape.borderRadius }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">
            {userName} {dateRange}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            {(Object.entries(projectTotals) as [string, ProjectTotal][]).map(
              ([projectId, project]) => (
                <Typography key={projectId} variant="subtitle2" color="primary">
                  {project.projectName}: {project.totalHours.toFixed(2)}h
                </Typography>
              )
            )}
          </Box>
        </Box>
        <DataGridPro
          rows={userEntries}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          hideFooter
          checkboxSelection
          apiRef={gridApiRef}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedIds(newSelectionModel as string[]);
          }}
          slots={{
            toolbar: BulkActionsToolbar,
          }}
          slotProps={{
            toolbar: {
              selectedIds,
              isUnsubmittedTab,
              isArchiveTab,
              onBulkApprove: handleBulkApprove,
              onBulkReject: handleBulkReject,
              onBulkSubmit: handleBulkSubmit,
              onBulkRevoke: handleBulkRevoke,
              rows: userEntries,
              onSelectionModelChange: setSelectedIds,
              gridApi: gridApiRef,
            },
          }}
          sx={{
            border: "none",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default TimesheetApprovalsGrid;
