import { ClientInvoiceData, StakeholderPayment } from "../bank";
// import { Invoice } from "types/invoice";
import { IBugReport } from "../bugReport";
import { FreelancerStatus, Stakeholder } from "../user";
import { Freelancer } from "../user";
import { IFeatureRequest } from "../bugReport";

type ClientStatus = "opportunity" | "active" | "inactive";

type CSAStatus =
  | "draft"
  | "admin_signing"
  | "client_signing"
  | "active"
  | "completed"
  | "cancelled";

type CSAType = "fixed_price" | "monthly";

type ProjectAssignmentStatus =
  | "interviewing"
  | "ready"
  | "signing"
  | "in_progress"
  | "complete"
  | "cancelled";

type EverSignDocumentStatus =
  | "awaiting_admin_signature"
  | "awaiting_engineer_signature"
  | "awaiting_signature"
  | "signed"
  | "declined"
  | "cancelled";

type ProjectStatus = "idea" | "active" | "completed" | "on_hold" | "cancelled";

interface Client {
  readonly id: string;
  companyName: string;
  logoUrl?: string;
  legalOwnerFirebaseId?: string;
  pointOfContactName?: string;
  email?: string;
  phone?: string;
  location: string;
  size: string;
  status: ClientStatus;
  introductionDate: string;
  // subcollections
  projects?: Project[];
  invoices?: ClientInvoiceData[];
  payments?: StakeholderPayment[];
  projectAssignments?: ProjectAssignment[];
  associatedFreelancerIds?: string[];
  associatedStakeholderIds?: string[];
  companyPhysicalAddress: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };

  // Local-only fields (not saved to Firestore client document, these are subcollections)
  consultingServicesAgreements?: ConsultingServicesAgreement[];
  freelancers?: Freelancer[];
  stakeholders?: Stakeholder[];
  createdByUserId: string;
  featureRequests?: IFeatureRequest[];
}

interface ICreateClient
  extends Omit<
    Client,
    | "id"
    | "projects"
    | "invoices"
    | "payments"
    | "projectAssignments"
    | "featureRequests"
    | "consultingServicesAgreements"
    | "freelancers"
    | "stakeholders"
  > {
  createdByUserId: string;
}

interface ClientStakeholder extends Stakeholder {
  associationDate: string;
  client?: Client;
}

interface ClientFreelancer extends Freelancer {
  associationDate?: string;
  status?: FreelancerStatus;
}

interface Project {
  readonly id: string;
  name: string;
  description: string;
  status: ProjectStatus;
  ideaDate: string | null;
  startDate: string | null;
  endDate: string | null;
  clientId: string;
  projectType?: string;
  duration?: string;
  budget?: string | number;
  activeCSAId?: string;
  // associatedStakeholderIds?: string[];
  // associatedFreelancerIds?: string[];
  createdByUserId: string;
  // root collections
  bugReports?: IBugReport[];
  consultingServicesAgreements?: ConsultingServicesAgreement[];
  freeloBoardId?: string;
  expectedStartDate?: string;
  expectedDuration?: string;
  techStack?: string;
  teamSize?: number;
  additionalNotes?: string;
  companyContext?: string;
  projectDetails?: string;
  projectTypeDetails?: string;
  projectPlanId?: string;
  projectIdeaId?: string;
}

export interface ProjectIdeaMessage {
  id: string;
  role: "user" | "assistant";
  content: string;
  timestamp: number;
}

export interface ProjectIdeaData {
  id?: string;
  status:
    | "in_progress"
    | "awaiting_admin_approval"
    | "awaiting_stakeholder_approval"
    | "approved"
    | "rejected";
  clientId: string;
  clientName: string;
  projectDescription: string;
  businessChallenges: string[];
  currentWorkflow: string;
  desiredOutcomes: string[];
  successMetrics: string[];
  budget?: number;
  timeline?: string;
  teamSize?: number;
  technicalRequirements?: string[];
  name?: string;
  description?: string;
  projectType?: string;
  duration?: string;
  expectedStartDate?: string;
  expectedDuration?: string;
  techStack?: string;
  additionalNotes?: string;
  companyContext?: string;
  projectDetails?: string;
  projectTypeDetails?: string;
  aiGeneratedPlan?: string;
  messages: ProjectIdeaMessage[];
  lastUpdated: string;
  readyForPlan?: boolean;
  projectPlanId?: string;
  createdAt: string;
  createdByUserId: string;
  updatedAt: string;
}

export interface ChatButton {
  label: string;
  action: () => void;
}

export interface AIMessage {
  id: string;
  type: "ai";
  content: string;
  timestamp: number;
  suggestions?: string[];
  buttons?: ChatButton[];
}

export interface UserMessage {
  id: string;
  type: "user";
  content: string;
  timestamp: number;
}

export type ChatMessage = AIMessage | UserMessage;

export interface ConversationState {
  currentStep:
    | "objective"
    | "selectCompany"
    | "companyContext"
    | "suggestName"
    | "name"
    | "description"
    | "projectType"
    | "projectDetails"
    | "timeline"
    | "technical"
    | "review"
    | "complete";
  messages: ChatMessage[];
  projectData: Partial<ProjectIdeaForm>;
}

export interface ProjectIdeaForm extends ProjectIdeaData {
  // name: string;
  // description: string;
  // projectType: string;
  // expectedStartDate: string;
  // expectedDuration: string;
  // budget?: number;
  // techStack: string;
  // teamSize?: number;
  // additionalNotes?: string;
  // companyContext?: string;
  // projectDetails?: string;
  // projectTypeDetails?: string;
  // clientId: string;
  // clientName?: string;
  // projectIdeaId?: string;
}

export const PROJECT_TYPES = [
  { value: "operational_efficiency", label: "Operational Efficiency Solution" },
  { value: "saas_solution", label: "SaaS Solution" },
  { value: "ai_integration", label: "AI Integration" },
  { value: "data_modernization", label: "Data Modernization" },
  { value: "data_engineering", label: "Data Engineering Pipeline" },
  { value: "staff_augmentation", label: "Staff Augmentation" },
] as const;

export type ProjectType = (typeof PROJECT_TYPES)[number]["value"];

interface ProjectPlan {
  readonly id: string;
  overview: string;
  projectId?: string;
  clientId: string;
  status: "in_stakeholder_review" | "in_admin_review" | "approved" | "rejected";
  timeline: {
    phases: Array<{
      name: string;
      description: string;
      duration: string;
      deliverables: string[];
    }>;
  };
  resources: {
    teamComposition: Array<{
      role: string;
      responsibilities: string[];
      count: number;
    }>;
    technologies: string[];
    estimatedCost: {
      min: number;
      max: number;
    };
  };
  risks: Array<{
    description: string;
    impact: "low" | "medium" | "high";
    mitigation: string;
  }>;
}

interface ConsultingServicesAgreement {
  readonly id: string;
  clientId: string;
  projectId: string;
  name: string;
  effectiveDate: string;
  endDate?: string | null;
  csaType: CSAType;
  status: CSAStatus;
  monthlyBudget?: number;
  fixedPriceBudget?: number;
  billableRates: BillableRate[];
  fileUrl?: string;
  draftStoragePath?: string;
  finalStoragePath?: string;
  createdAt?: any; // Timestamp
  createdBy?: string;
  updatedAt?: any; // Timestamp
  updatedBy?: string;
  scopeOfWork?: string[];
  stakeholderId?: string;
  stakeholderName?: string;
  stakeholderEmail?: string;
  needsApproval?: boolean;
  sentToClient?: boolean;
  eversignDocumentHash?: string;
  eversignUserDocumentEmbedUrl?: string;
  eversignAdminDocumentEmbedUrl?: string;
  eversignDocumentJson?: string;
  everSignDocumentStatus?: EverSignDocumentStatus;
  signedByAdmin?: boolean;
  signedByAdminAt?: string;
  signedByStakeholder?: boolean;
  signedByStakeholderAt?: string;
  documentSigningCompletedAt?: string;
}

interface ProjectAssignment {
  readonly id: string;
  engineerId: string;
  freelancerName?: string | null;
  clientId: string;
  clientName?: string | null;
  projectId: string;
  projectName?: string | null;
  csaId: string | null;
  csaName?: string | null;
  billableRate: number | null;
  budgetedHours?: number | null;
  budgetedDollarAmount?: number | null;
  utilizedHours?: number | null;
  amountPaid?: number | null;
  paymentDate?: string | null;
  paymentMethod?: string | null;
  name?: string;
  status: ProjectAssignmentStatus;
  startDate: string | null;
  endDate: string | null;
  fileUrl?: string | null;
  isUploadedToStorage?: boolean;
  role?: string | null;
  performanceReviews?: PerformanceReview[];
  eversignDocumentHash?: string;
  eversignUserDocumentEmbedUrl?: string;
  eversignAdminDocumentEmbedUrl?: string;
  everSignDocumentStatus?: EverSignDocumentStatus;
  eversignDocumentJson?: string;
  displayName?: string;
  scopeOfWork?: string;
  sentDate?: string;
  sentByAdminUserId?: string;
  dateSignedByAdmin?: string;
  dateSignedByEngineer?: string;
}

interface BillableRate {
  role: string;
  rate: number;
}

interface PerformanceReview {
  rating: number;
  comment: string;
  reviewDate: string;
  reviewedBy: {
    id: string;
    email: string;
  };
}

// interface FreelancerClientReferral {
//   readonly id: string;
//   freelancerId: string;
//   clientId: string;
//   referralDate: string;
//   referralSource: string;
// }

export type {
  Project,
  Client,
  ConsultingServicesAgreement,
  ProjectAssignment,
  PerformanceReview,
  BillableRate,
  ProjectStatus,
  CSAStatus,
  CSAType,
  ProjectAssignmentStatus,
  EverSignDocumentStatus,
  ClientFreelancer,
  ClientStakeholder,
  ICreateClient,
  ProjectPlan,
};
