import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useFreelancerRoleClients } from "hooks/freelancer/useFreelancerRoleClients";
import ClientCard from "./components/ClientCard";
import ClientOverviewHeader from "./components/ClientOverviewHeader";

const MyClients: React.FC = () => {
  const { clients, isLoading, isError, error } = useFreelancerRoleClients();

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">
          Error loading clients:{" "}
          {error instanceof Error ? error.message : "Unknown error"}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, md: 3 } }}>
      <ClientOverviewHeader clientCount={clients.length} />

      {clients.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: "center" }}>
          <Typography variant="body1">
            You don't have any clients assigned yet.
          </Typography>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          {clients.map((client) => (
            <Grid item xs={12} md={6} lg={4} key={client.id}>
              <ClientCard client={client} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default MyClients;
