import { useAuth } from "hooks/auth/useAuth";
import { IncreaseExternalAccountDetails } from "@freetech/models/bank";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, getDocs, doc, orderBy, query } from "firebase/firestore";
import { db } from "core/firestore";
import { updateDoc } from "firebase/firestore";
import { encryptPayload } from "core/security/encryptPayload";
import { FreelancerPayment } from "@freetech/models/bank";
import { bankFunctions } from "core/functions/bankFunctions";
import { portalFunctions } from "core/functions/portalFunctions";

export const useFreelancerPayroll = () => {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const storage = getStorage();

  const fetchPaymentProfile =
    async (): Promise<IncreaseExternalAccountDetails | null> => {
      if (!currentUser?.uid) {
        throw new Error("No authenticated user");
      }

      try {
        const response =
          await bankFunctions.freelancer.getMyFreelancerPaymentProfile();
        return response;
      } catch (error) {
        console.error("Failed to fetch payment profile:", error);
        return null; // Return null instead of throwing to handle 404 gracefully
      }
    };

  const { data: paymentProfile, error: paymentProfileError } = useQuery({
    queryKey: ["freelancerPaymentProfile", currentUser?.uid],
    queryFn: fetchPaymentProfile,
    enabled: !!currentUser?.uid,
    retry: false, // Don't retry if the profile doesn't exist
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const createMyFreelancerPayrollProfile = async (
    data: Partial<IncreaseExternalAccountDetails> & { nickname: string }
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }
    try {
      const response =
        await bankFunctions.freelancer.createFreelancerPaymentProfile(
          currentUser.uid,
          data
        );

      console.log("response", response);
      if (!response.success) {
        throw new Error(response.error);
      }

      // Wait for 10 seconds to allow Increase to process the profile creation
      await new Promise((resolve) => setTimeout(resolve, 10000));

      // Force a refetch of the payment profile
      await queryClient.invalidateQueries({
        queryKey: ["freelancerPaymentProfile", currentUser.uid],
      });

      // Wait for another second to ensure we have the latest data
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Fetch the latest profile data
      const updatedProfile = await fetchPaymentProfile();
      if (!updatedProfile) {
        throw new Error(
          "Payment profile creation is still in progress. Please refresh the page in a few moments."
        );
      }

      return response;
    } catch (error: any) {
      console.log("error message", error.message);
      throw new Error(error.message);
    }
  };

  const updateMyFreelancerPayrollProfile = async (
    data: Partial<IncreaseExternalAccountDetails> & { nickname?: string }
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    const response =
      await bankFunctions.freelancer.updateFreelancerPaymentProfile(
        currentUser.uid,
        data
      );

    if (!response.ok) {
      return response.json();
    }

    // Invalidate the payment profile query after successful update
    queryClient.invalidateQueries({
      queryKey: ["freelancerPaymentProfile", currentUser.uid],
    });

    const responseData = await response.json();
    return responseData;
  };

  const generateFreelancerW9Form = async (): Promise<{
    embedUrl: string;
    documentHash: string;
  }> => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    const response = await portalFunctions.eversign.generateFreelancerW9Form();

    if (!response.ok) {
      throw new Error("Failed to generate W9 form");
    }

    const data = await response.json();

    return data;
  };

  const generateFreelancerW8BenForm = async (): Promise<{
    embedUrl: string;
    documentHash: string;
  }> => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    const response =
      await portalFunctions.eversign.generateFreelancerW8BenForm();

    if (!response.ok) {
      throw new Error("Failed to generate W-8BEN form");
    }

    const data = await response.json();

    return data;
  };

  const saveOrUpdateTaxForm = async (
    documentHash: string,
    formType: "w9" | "w8ben"
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    try {
      const response = await portalFunctions.eversign.processSignedTaxForm({
        userId: currentUser.uid,
        documentHash,
        formType,
      });

      if (!response.ok) {
        throw new Error("Failed to process tax form");
      }

      // Invalidate relevant queries to refresh the UI
      queryClient.invalidateQueries({
        queryKey: ["freelancerProfile", currentUser.uid],
      });

      return { success: true };
    } catch (error) {
      console.error("Error saving tax form:", error);
      throw error;
    }
  };

  const getTaxFormStorageUrl = async () => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    try {
      const storageRef = ref(storage, `users/${currentUser.uid}/taxform.pdf`);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error("Error getting tax form URL:", error);
      throw error;
    }
  };

  const completeFreelancerPayrollOnboarding = async () => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    const userRef = doc(db, "users", currentUser.uid);
    const now = new Date().toISOString();

    await updateDoc(userRef, {
      paymentAndTaxProfileVerifiedByUser: true,
      paymentAndTaxProfileVerifiedByUserAt: now,
      updatedAt: now,
    });

    // Invalidate relevant queries to refresh the UI
    queryClient.invalidateQueries({
      queryKey: ["freelancerProfile", currentUser.uid],
    });

    return { success: true };
  };

  const getMyFreelancerPayments = async () => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }
    const collectionRef = collection(db, "users", currentUser.uid, "payments");
    const q = query(collectionRef, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const payments = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as FreelancerPayment[];
    return payments;
  };

  const {
    data: payments,
    isLoading: isPaymentsLoading,
    error: paymentsError,
  } = useQuery({
    queryKey: ["freelancerPayments", currentUser?.uid],
    queryFn: getMyFreelancerPayments,
    enabled: !!currentUser?.uid,
  });

  const startNewTaxForm = async (formType: "w9" | "w8ben") => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    try {
      // Call the new API endpoint to generate a new tax form
      const response =
        await portalFunctions.eversign.generateReplacementTaxForm({
          formType,
        });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to generate new tax form");
      }

      const data = await response.json();

      // Invalidate relevant queries to refresh the UI
      queryClient.invalidateQueries({
        queryKey: ["freelancerProfile", currentUser.uid],
      });

      return {
        success: true,
        embedUrl: data.embedUrl,
        documentHash: data.documentHash,
        formType: data.formType,
      };
    } catch (error) {
      console.error("Error starting new tax form:", error);
      throw error;
    }
  };

  return {
    paymentProfile,
    paymentProfileError,
    createMyFreelancerPayrollProfile,
    updateMyFreelancerPayrollProfile,
    generateFreelancerW9Form,
    generateFreelancerW8BenForm,
    saveOrUpdateTaxForm,
    getTaxFormStorageUrl,
    completeFreelancerPayrollOnboarding,
    payments,
    isPaymentsLoading,
    paymentsError,
    startNewTaxForm,
  };
};
