import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, Button, IconButton, Tooltip, Tabs, Tab, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, ListItemButton, Badge } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridColDef, GridRowParams, GridToolbar } from '@mui/x-data-grid';
import { 
  Add as AddIcon, 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  ArrowBack as ArrowBackIcon, 
  Send as SendIcon,
  Email as EmailIcon,
  History as HistoryIcon,
  Visibility as VisibilityIcon,
  Notifications as NotificationsIcon,
  Campaign as CampaignIcon,
  Article as ArticleIcon,
  Help as HelpIcon,
  Group as GroupIcon,
  PersonAdd as PersonAddIcon,
  Person as PersonIcon,
  MarkEmailRead as MarkEmailReadIcon,
  MarkEmailUnread as MarkEmailUnreadIcon,
} from '@mui/icons-material';
import { useAdminEmailManagement } from 'hooks/admin/useAdminEmailManagement';
import { usePublicUserList } from 'hooks/user/usePublicUserList';
import { EmailTemplate, SendEmailRequest, MailDelivery, ContactList, Contact } from 'types/admin/emailTemplate';
import { PublicUserInfoType } from '@freetech/models/user';
import TemplateEditor from './TemplateEditor';
import SendEmailDialog from './SendEmailDialog';
import EmailPreviewDialog from './EmailPreviewDialog';
import { useQueryClient } from '@tanstack/react-query';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`email-management-tabpanel-${index}`}
      aria-labelledby={`email-management-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const EmailManagement: React.FC = () => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);
  const [templateToSend, setTemplateToSend] = useState<EmailTemplate | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState<MailDelivery | null>(null);
  const [templateFilter, setTemplateFilter] = useState<string>('all');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
  
  // Contact List states
  const [contactListDialogOpen, setContactListDialogOpen] = useState(false);
  const [editingContactList, setEditingContactList] = useState<ContactList | null>(null);
  const [contactListName, setContactListName] = useState('');
  const [contactListDescription, setContactListDescription] = useState('');
  const [selectedContactList, setSelectedContactList] = useState<ContactList | null>(null);
  const [newContactEmail, setNewContactEmail] = useState('');
  const [newContactName, setNewContactName] = useState('');
  const [deleteContactListDialogOpen, setDeleteContactListDialogOpen] = useState(false);
  const [contactListToDelete, setContactListToDelete] = useState<string | null>(null);
  const [contactListTags, setContactListTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState('');

  const queryClient = useQueryClient();

  // Helper function to format timestamps
  const formatTimestamp = (timestamp: any): string => {
    if (!timestamp) return 'N/A';
    if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }
    return String(timestamp);
  };

  const {
    templates,
    mailDeliveries,
    contactLists,
    deleteTemplate,
    createTemplate,
    updateTemplate,
    previewTemplate,
    sendEmail,
    createContactList,
    updateContactList,
    deleteContactList,
    isLoading: isEmailManagementLoading,
    isMailDeliveriesLoading,
    isContactListsLoading,
  } = useAdminEmailManagement();

  const { data: publicUsers = [], isLoading: isPublicUsersLoading } = usePublicUserList();

  // Handle hash-based navigation
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash === '#create') {
        setIsCreating(true);
        setSelectedTemplateId(null);
      } else if (hash.startsWith('#edit?id=')) {
        const id = hash.replace('#edit?id=', '');
        setSelectedTemplateId(id);
        setIsCreating(false);
      } else if (hash === '#deliveries') {
        setActiveTab(1);
        setSelectedTemplateId(null);
        setIsCreating(false);
      } else if (hash === '#templates' || hash === '') {
        setActiveTab(0);
        setSelectedTemplateId(null);
        setIsCreating(false);
      } else if (hash === '#contact-lists') {
        setActiveTab(2);
        setSelectedTemplateId(null);
        setIsCreating(false);
      } else {
        setSelectedTemplateId(null);
        setIsCreating(false);
      }
    };

    // Handle initial hash
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      window.location.hash = 'templates';
    } else if (newValue === 1) {
      window.location.hash = 'deliveries';
    } else if (newValue === 2) {
      window.location.hash = 'contact-lists';
    }
  };

  const handlePreviewClick = (delivery: MailDelivery) => {
    // Create a new object with properly formatted timestamp values
    const processedDelivery = {
      ...delivery,
      // Ensure delivery is never undefined by providing a default value
      delivery: delivery.delivery ? {
        ...delivery.delivery,
        startTime: formatTimestamp(delivery.delivery.startTime),
        endTime: formatTimestamp(delivery.delivery.endTime),
        leaseExpireTime: delivery.delivery.leaseExpireTime ? 
          formatTimestamp(delivery.delivery.leaseExpireTime) : null
      } : {
        // Provide default values if delivery is undefined
        startTime: 'N/A',
        endTime: 'N/A',
        state: 'UNKNOWN',
        attempts: 0,
        error: null,
        leaseExpireTime: null,
        info: {
          accepted: [],
          rejected: [],
          pending: [],
          messageId: '',
          response: ''
        }
      },
      // Format createdAt if it exists
      createdAt: delivery.createdAt ? formatTimestamp(delivery.createdAt) : 'N/A'
    };
    
    setSelectedDelivery(processedDelivery as MailDelivery);
    setPreviewDialogOpen(true);
  };

  // Get icon and color for template type
  const getTemplateTypeDisplay = (type: string | undefined) => {
    switch(type) {
      case 'portal_notification':
        return {
          icon: <NotificationsIcon />,
          label: 'Notification',
          color: 'primary'
        };
      case 'marketing_campaign':
        return {
          icon: <CampaignIcon />,
          label: 'Marketing',
          color: 'success'
        };
      case 'newsletter':
        return {
          icon: <ArticleIcon />,
          label: 'Newsletter',
          color: 'info'
        };
      case 'misc':
        return {
          icon: <HelpIcon />,
          label: 'Misc',
          color: 'warning'
        };
      default:
        return {
          icon: <EmailIcon />,
          label: 'General',
          color: 'default'
        };
    }
  };

  // Template columns
  const templateColumns: GridColDef<EmailTemplate>[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'nickname', headerName: 'Template Name', flex: 1.5 },
    { field: 'subject', headerName: 'Subject', flex: 2 },
    { 
      field: 'description', 
      headerName: 'Description', 
      flex: 2 
    },
    { field: 'lastModified', headerName: 'Last Modified', flex: 1 },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderCell: (params: GridRenderCellParams<EmailTemplate>) => {
        const { icon, label, color } = getTemplateTypeDisplay(params.value);
        return (
          <Chip 
            icon={icon} 
            label={label} 
            color={color as any} 
            size="small" 
            variant="outlined"
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams<EmailTemplate>) => {
        const id = params.row.id;
        return (
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="Edit Template">
              <IconButton 
                onClick={() => id && handleEdit(id)} 
                color="primary" 
                size="small"
                disabled={!id}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send Email">
              <IconButton 
                onClick={() => handleSendClick(params.row)} 
                color="primary" 
                size="small"
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Template">
              <IconButton 
                onClick={() => id && handleDelete(id)} 
                color="error" 
                size="small"
                disabled={!id}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  // Handle template filter change
  const handleTemplateFilterChange = (event: SelectChangeEvent) => {
    setTemplateFilter(event.target.value);
  };

  // Filter templates based on selected filter
  const filteredTemplates = React.useMemo(() => {
    if (templateFilter !== 'all') {
      return templates.filter(template => template.type === templateFilter);
    } else {
      return templates;
    }
  }, [templates, templateFilter]);

  // Mail delivery columns
  const deliveryColumns: GridColDef<MailDelivery>[] = [
    { 
      field: 'preview', 
      headerName: 'Preview', 
      width: 80,
      sortable: false,
      renderCell: (params: GridRenderCellParams<MailDelivery>) => (
        <Tooltip title="Preview Email">
          <IconButton
            onClick={() => handlePreviewClick(params.row)}
            size="small"
            color="primary"
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      )
    },
    { field: 'id', headerName: 'ID', width: 100 },
    { 
      field: 'to', 
      headerName: 'Recipient', 
      width: 200,
      valueGetter: (value: any, row: any) => {
        return row.to || (row.message?.to) || (row.toUids && row.toUids.length > 0 ? row.toUids[0] : '');
      }
    },
    { 
      field: 'subject', 
      headerName: 'Subject', 
      width: 250,
      valueGetter: (value: any, row: any) => {
        return row.template?.subject || row.message?.subject || '';
      }
    },
    { 
      field: 'templateName', 
      headerName: 'Template', 
      width: 150,
      valueGetter: (value: any, row: any) => {
        return row.template?.name || 'Unknown';
      }
    },
    { 
      field: 'readStatus', 
      headerName: 'Read Status', 
      width: 120,
      valueGetter: (value: any, row: any) => row.readStatus || 'sent',
      renderCell: (params: GridRenderCellParams<MailDelivery>) => {
        const status = params.value || 'sent';
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {status === 'opened' ? (
              <MarkEmailReadIcon color="success" fontSize="small" />
            ) : (
              <MarkEmailUnreadIcon color="disabled" fontSize="small" />
            )}
            <Typography variant="body2">
              {status === 'opened' ? 'Opened' : 'Not opened'}
            </Typography>
          </Box>
        );
      }
    },
    { 
      field: 'lastOpened', 
      headerName: 'Last Opened', 
      width: 180,
      valueGetter: (value: any, row: any) => {
        if (row.readAt) {
          return row.readAt;
        }
        return 'N/A';
      }
    },
    { 
      field: 'status', 
      headerName: 'Delivery Status', 
      width: 120,
      valueGetter: (value: any, row: any) => row.delivery?.state || 'PENDING',
      renderCell: (params: GridRenderCellParams<MailDelivery>) => (
        <Box 
          sx={{ 
            p: 1, 
            borderRadius: 1, 
            backgroundColor: 
              params.value === 'SUCCESS' ? 'success.light' : 
              params.value === 'ERROR' ? 'error.light' : 
              params.value === 'PROCESSING' ? 'warning.light' : 'grey.300',
            color: 'white',
            fontWeight: 'bold'
          }}
        >
          {params.value}
        </Box>
      )
    },
    { 
      field: 'startTime', 
      headerName: 'Sent At', 
      width: 180,
      valueGetter: (value: any, row: any) => {
        return formatTimestamp(row.delivery?.startTime);
      }
    },
    { 
      field: 'endTime', 
      headerName: 'Delivery Time', 
      width: 180,
      valueGetter: (value: any, row: any) => {
        return formatTimestamp(row.delivery?.endTime);
      }
    },
    { 
      field: 'messageId', 
      headerName: 'Message ID', 
      width: 300,
      valueGetter: (value: any, row: any) => {
        return row.delivery?.info?.messageId || '';
      }
    },
    { 
      field: 'error', 
      headerName: 'Error', 
      width: 250,
      valueGetter: (value: any, row: any) => row.delivery?.error || null,
      renderCell: (params: GridRenderCellParams<MailDelivery>) => params.value ? (
        <Tooltip title={params.value}>
          <Typography 
            color="error" 
            sx={{ 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              maxWidth: 240 
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ) : null
    },
  ];

  const handleSendClick = (template: EmailTemplate) => {
    setTemplateToSend(template);
    setShowSendDialog(true);
  };

  const handleSendEmail = async (request: SendEmailRequest) => {
    await sendEmail(request);
  };

  const handleDelete = async (templateId: string) => {
    setTemplateToDelete(templateId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (templateToDelete) {
      await deleteTemplate(templateToDelete);
      if (selectedTemplateId === templateToDelete) {
        handleBack();
      }
      setDeleteDialogOpen(false);
      setTemplateToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setTemplateToDelete(null);
  };

  const handleEdit = (templateId: string) => {
    window.location.hash = `edit?id=${templateId}`;
  };

  const handleCreateNew = () => {
    window.location.hash = 'create';
  };

  const handleBack = () => {
    window.location.hash = activeTab === 0 ? 'templates' : activeTab === 1 ? 'deliveries' : 'contact-lists';
  };

  const handleCopyTemplate = () => {
    // Switch to create mode but keep the template data
    setIsCreating(true);
    setSelectedTemplateId(null);
    window.location.hash = 'create';
  };

  const handleSave = async (template: any) => {
    try {
      if (template.id && !selectedTemplateId) {
        // This is a create with custom ID
        await createTemplate({
          ...template,
          id: template.id
        });
      } else if (selectedTemplateId) {
        // This is an update
        await updateTemplate({
          ...template,
          id: selectedTemplateId
        });
      } else {
        // This is a create with auto ID
        await createTemplate(template);
      }
      handleBack();
    } catch (error) {
      console.error('Failed to save template:', error);
      alert(`Error saving template: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const selectedTemplate = templates.find(t => t.id === selectedTemplateId);

  // Contact List columns
  const contactListColumns: GridColDef<ContactList>[] = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'name', headerName: 'List Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 2 },
    { 
      field: 'contacts', 
      headerName: 'Contacts', 
      flex: 0.5,
      valueGetter: (params: any) => {
        // Safely access contacts property with nullish coalescing
        return params.row?.contacts?.length || 0;
      },
    },
    { field: 'lastModified', headerName: 'Last Modified', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ContactList>) => {
        const row = params.row;
        return (
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="Add Contacts">
              <IconButton 
                onClick={() => handleAddContacts(row)} 
                color="primary" 
                size="small"
              >
                <PersonAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit List">
              <IconButton 
                onClick={() => handleEditContactList(row)} 
                color="primary" 
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete List">
              <IconButton 
                onClick={() => row.id && handleDeleteContactList(row.id)} 
                color="error" 
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  // Contact columns for the nested data grid
  const contactColumns: GridColDef<Contact>[] = [
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<Contact>) => {
        const email = params.row.email;
        return email ? (
          <Tooltip title="Remove Contact">
            <IconButton 
              onClick={() => handleRemoveContact(email)} 
              color="error" 
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null;
      },
    },
  ];

  // Handler for bulk deleting selected contacts
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  
  const handleBulkDeleteContacts = async () => {
    if (!selectedContactList || selectedContacts.length === 0) return;
    
    try {
      const existingContacts = selectedContactList.contacts || [];
      const updatedContacts = existingContacts.filter(
        contact => !selectedContacts.includes(contact.email)
      );
      
      // Optimistically update the UI
      const optimisticContactList = {
        ...selectedContactList,
        contacts: updatedContacts,
      };
      
      // Update selected contact list in state immediately
      setSelectedContactList(optimisticContactList);
      
      // Create an optimistic version of all contact lists
      const updatedContactLists = contactLists.map(list => 
        list.id === selectedContactList.id ? optimisticContactList : list
      );
      
      // Optimistically update the queryClient cache
      queryClient.setQueryData(['contactLists'], updatedContactLists);
      
      // Clear selected contacts
      setSelectedContacts([]);
      
      // Perform the actual update
      await updateContactList({
        ...selectedContactList,
        contacts: updatedContacts,
      });
      
    } catch (error) {
      console.error('Error removing contacts:', error);
      alert(`Error removing contacts: ${error instanceof Error ? error.message : 'Unknown error'}`);
      
      // Revert optimistic update on error by refetching
      queryClient.invalidateQueries({ queryKey: ['contactLists'] });
    }
  };

  // Handler for opening the contact list dialog
  const handleCreateContactList = () => {
    setEditingContactList(null);
    setContactListName('');
    setContactListDescription('');
    setContactListDialogOpen(true);
  };

  // Handler for editing a contact list
  const handleEditContactList = (contactList: ContactList) => {
    setEditingContactList(contactList);
    setContactListName(contactList.name || '');
    setContactListDescription(contactList.description || '');
    setContactListTags(contactList.tags || []);
    setContactListDialogOpen(true);
  };

  // Handler for saving a contact list
  const handleSaveContactList = async () => {
    if (!contactListName.trim()) return;

    try {
      if (editingContactList) {
        // Update existing list
        const updatedList = {
          ...editingContactList,
          name: contactListName,
          description: contactListDescription,
          tags: contactListTags,
          lastModified: new Date().toISOString(), // Optimistically update timestamp
        };
        
        // Optimistically update the selected list if it's the one being edited
        if (selectedContactList?.id === editingContactList.id) {
          setSelectedContactList(updatedList);
        }
        
        // Create an optimistic version of all contact lists
        const updatedContactLists = contactLists.map(list => 
          list.id === editingContactList.id ? updatedList : list
        );
        
        // Optimistically update the queryClient cache
        queryClient.setQueryData(['contactLists'], updatedContactLists);
        
        // Close the dialog
        setContactListDialogOpen(false);
        
        // Perform the actual update
        await updateContactList(updatedList);
        
      } else {
        // Create new list with a temporary ID
        const tempId = `temp-${Date.now()}`;
        const newList = {
          id: tempId,
          name: contactListName,
          description: contactListDescription,
          tags: contactListTags,
          contacts: [],
          createdAt: new Date().toISOString(),
          lastModified: new Date().toISOString(),
        };
        
        // Optimistically add to the contact lists in UI
        const updatedContactLists = [...contactLists, newList];
        
        // Optimistically update the queryClient cache
        queryClient.setQueryData(['contactLists'], updatedContactLists);
        
        // Close the dialog
        setContactListDialogOpen(false);
        
        // Perform the actual create
        const newId = await createContactList({
          name: contactListName,
          description: contactListDescription,
          tags: contactListTags,
          contacts: [],
        });
        
        // Update the ID in the cache after creation
        if (newId) {
          // Get the current cache
          const currentCache = queryClient.getQueryData(['contactLists']) as ContactList[];
          if (currentCache) {
            // Replace the temporary ID with the real one
            const finalCache = currentCache.map(list => 
              list.id === tempId ? {...list, id: newId} : list
            );
            queryClient.setQueryData(['contactLists'], finalCache);
          }
        }
      }
    } catch (error) {
      console.error('Error saving contact list:', error);
      alert(`Error saving contact list: ${error instanceof Error ? error.message : 'Unknown error'}`);
      
      // Revert optimistic update on error by refetching
      queryClient.invalidateQueries({ queryKey: ['contactLists'] });
    }
  };

  // Handler for adding a tag
  const handleAddTag = () => {
    if (newTag.trim() && !contactListTags.includes(newTag.trim())) {
      setContactListTags([...contactListTags, newTag.trim()]);
      setNewTag('');
    }
  };

  // Handler for deleting a tag
  const handleDeleteTag = (tagToDelete: string) => {
    setContactListTags(contactListTags.filter(tag => tag !== tagToDelete));
  };

  // Simplified handler for showing contacts (no dialog now)
  const handleAddContacts = (contactList: ContactList) => {
    setSelectedContactList(contactList);
    setNewContactEmail('');
    setNewContactName('');
  };

  // Handler for adding a contact
  const handleAddContact = async () => {
    if (!selectedContactList || !newContactEmail.trim()) return;

    try {
      // Ensure contacts is an array even if it was undefined
      const existingContacts = selectedContactList.contacts || [];
      
      const newContact = {
        email: newContactEmail.trim(),
        name: newContactName.trim() || undefined,
      };
      
      const updatedContacts = [...existingContacts, newContact];
      
      // Optimistically update the UI
      const optimisticContactList = {
        ...selectedContactList,
        contacts: updatedContacts,
      };
      
      // Update selected contact list in state immediately
      setSelectedContactList(optimisticContactList);
      
      // Create an optimistic version of all contact lists
      const updatedContactLists = contactLists.map(list => 
        list.id === selectedContactList.id ? optimisticContactList : list
      );
      
      // Optimistically update the queryClient cache
      queryClient.setQueryData(['contactLists'], updatedContactLists);
      
      // Clear the form fields
      setNewContactEmail('');
      setNewContactName('');
      
      // Perform the actual update
      await updateContactList({
        ...selectedContactList,
        contacts: updatedContacts,
      });
      
    } catch (error) {
      console.error('Error adding contact:', error);
      alert(`Error adding contact: ${error instanceof Error ? error.message : 'Unknown error'}`);
      
      // Revert optimistic update on error by refetching
      queryClient.invalidateQueries({ queryKey: ['contactLists'] });
    }
  };

  // Handler for removing a contact
  const handleRemoveContact = async (email: string) => {
    if (!selectedContactList) return;

    try {
      // Ensure contacts is an array even if it was undefined
      const existingContacts = selectedContactList.contacts || [];
      
      const updatedContacts = existingContacts.filter(
        (contact) => contact.email !== email
      );
      
      // Optimistically update the UI
      const optimisticContactList = {
        ...selectedContactList,
        contacts: updatedContacts,
      };
      
      // Update selected contact list in state immediately
      setSelectedContactList(optimisticContactList);
      
      // Create an optimistic version of all contact lists
      const updatedContactLists = contactLists.map(list => 
        list.id === selectedContactList.id ? optimisticContactList : list
      );
      
      // Optimistically update the queryClient cache
      queryClient.setQueryData(['contactLists'], updatedContactLists);

      // Perform the actual update
      await updateContactList({
        ...selectedContactList,
        contacts: updatedContacts,
      });
      
    } catch (error) {
      console.error('Error removing contact:', error);
      alert(`Error removing contact: ${error instanceof Error ? error.message : 'Unknown error'}`);
      
      // Revert optimistic update on error by refetching
      queryClient.invalidateQueries({ queryKey: ['contactLists'] });
    }
  };

  // Handler for deleting a contact list
  const handleDeleteContactList = (id: string | undefined) => {
    if (!id) return;
    
    setContactListToDelete(id);
    setDeleteContactListDialogOpen(true);
  };

  // Handler for confirming contact list deletion
  const handleConfirmDeleteContactList = async () => {
    if (!contactListToDelete) return;

    try {
      // If this is the selected list, clear the selection
      if (selectedContactList?.id === contactListToDelete) {
        setSelectedContactList(null);
      }
      
      // Optimistically remove from the UI
      const updatedContactLists = contactLists.filter(list => list.id !== contactListToDelete);
      
      // Optimistically update the queryClient cache
      queryClient.setQueryData(['contactLists'], updatedContactLists);
      
      // Close the dialog
      setDeleteContactListDialogOpen(false);
      setContactListToDelete(null);
      
      // Perform the actual delete
      await deleteContactList(contactListToDelete);
      
    } catch (error) {
      console.error('Error deleting contact list:', error);
      alert(`Error deleting contact list: ${error instanceof Error ? error.message : 'Unknown error'}`);
      
      // Revert optimistic update on error by refetching
      queryClient.invalidateQueries({ queryKey: ['contactLists'] });
    }
  };

  // Get creator name from user ID
  const getCreatorName = (userId: string | undefined) => {
    if (!userId) return 'Unknown';
    const user = publicUsers.find(u => u.id === userId);
    return user ? `${user.firstName} ${user.lastName}` : userId;
  };

  if (isCreating || selectedTemplateId) {
    return (
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{ mr: 2 }}
          >
            Back to Templates
          </Button>
          <Typography variant="h5">
            {selectedTemplateId ? 'Edit Email Template' : 'Create New Email Template'}
          </Typography>
        </Box>

        <TemplateEditor
          selectedTemplateId={selectedTemplateId}
          onSave={handleSave}
          previewTemplate={previewTemplate}
          initialTemplate={selectedTemplate}
          onCreateNew={handleCopyTemplate}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          aria-label="email management tabs"
        >
          <Tab icon={<EmailIcon />} label="Email Templates" />
          <Tab icon={<HistoryIcon />} label="Mail Deliveries" />
          <Tab icon={<GroupIcon />} label="Contact Lists" />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">Email Templates</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateNew}
          >
            Create Template
          </Button>
        </Box>

        <Box sx={{ mb: 2 }}>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="template-filter-label">Filter Templates</InputLabel>
            <Select
              labelId="template-filter-label"
              id="template-filter"
              value={templateFilter}
              label="Filter Templates"
              onChange={handleTemplateFilterChange}
            >
              <MenuItem value="all">All Templates</MenuItem>
              <MenuItem value="general">General</MenuItem>
              <MenuItem value="portal_notification">Notifications</MenuItem>
              <MenuItem value="marketing_campaign">Marketing</MenuItem>
              <MenuItem value="newsletter">Newsletter</MenuItem>
              <MenuItem value="misc">Miscellaneous</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Paper sx={{ height: 'calc(100vh - 290px)' }}>
          <DataGrid
            rows={filteredTemplates}
            columns={templateColumns}
            loading={isEmailManagementLoading}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
            autoPageSize
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Paper>
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">Mail Deliveries</Typography>
          <Typography variant="subtitle1">
            Showing recent 100 mail deliveries (real-time updates)
          </Typography>
        </Box>

        <Paper sx={{ height: 'calc(100vh - 240px)' }}>
          <DataGrid
            rows={mailDeliveries}
            columns={deliveryColumns}
            loading={isMailDeliveriesLoading}
            pageSizeOptions={[10, 25, 50, 100]}
            disableRowSelectionOnClick
            autoPageSize
            getRowClassName={(params: GridRowParams<MailDelivery>) => {
              if (params.row.delivery?.state === 'ERROR') return 'error-row';
              if (params.row.delivery?.state === 'SUCCESS') return 'success-row';
              return '';
            }}
            sx={{
              '& .error-row': {
                bgcolor: 'rgba(255, 0, 0, 0.05)',
              },
              '& .success-row': {
                bgcolor: 'rgba(0, 255, 0, 0.05)',
              }
            }}
          />
        </Paper>
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">Contact Lists</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateContactList}
          >
            Create Contact List
          </Button>
        </Box>

        <Grid container spacing={2}>
          {/* List of contact lists on the left */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper sx={{ height: 'calc(100vh - 290px)', overflow: 'auto' }}>
              <List>
                {contactLists.map((list) => (
                  <React.Fragment key={list.id}>
                    <ListItemButton
                      onClick={() => setSelectedContactList(list)}
                      selected={selectedContactList?.id === list.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary={list.name} 
                        secondary={`${list.contacts?.length || 0} contacts`} 
                      />
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                ))}
                {contactLists.length === 0 && (
                  <ListItem>
                    <ListItemText primary="No contact lists found" />
                  </ListItem>
                )}
              </List>
            </Paper>
          </Grid>
          
          {/* Contact list details on the right */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper sx={{ height: 'calc(100vh - 290px)', p: 2, display: 'flex', flexDirection: 'column' }}>
              {selectedContactList ? (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h6">{selectedContactList.name}</Typography>
                    <Box>
                      <Tooltip title="Edit List">
                        <IconButton 
                          onClick={() => handleEditContactList(selectedContactList)} 
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete List">
                        <IconButton 
                          onClick={() => selectedContactList.id && handleDeleteContactList(selectedContactList.id)} 
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      {selectedContactList.description || 'No description'}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {(selectedContactList.tags || []).map((tag) => (
                      <Chip 
                        key={tag} 
                        label={tag} 
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                    {(selectedContactList.tags || []).length === 0 && (
                      <Typography variant="body2" color="text.secondary">
                        No tags
                      </Typography>
                    )}
                  </Box>
                  
                  <Box sx={{ mb: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="text.secondary">
                          Created by: {getCreatorName(selectedContactList.createdBy)}
                        </Typography>
                        {selectedContactList.createdAt && (
                          <Typography variant="body2" color="text.secondary">
                            Created: {formatTimestamp(selectedContactList.createdAt)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {selectedContactList.updatedBy && (
                          <Typography variant="body2" color="text.secondary">
                            Updated by: {getCreatorName(selectedContactList.updatedBy)}
                          </Typography>
                        )}
                        {selectedContactList.lastModified && (
                          <Typography variant="body2" color="text.secondary">
                            Last modified: {selectedContactList.lastModified}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  
                  <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="subtitle1">
                        Contacts ({selectedContactList.contacts?.length || 0})
                      </Typography>
                      {selectedContacts.length > 0 && (
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={handleBulkDeleteContacts}
                        >
                          Delete Selected ({selectedContacts.length})
                        </Button>
                      )}
                    </Box>
                    
                    {/* Add contact form inline at the top */}
                    <Paper sx={{ p: 2, mb: 2, border: '1px solid', borderColor: 'primary.main' }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={5}>
                          <TextField
                            label="Email"
                            fullWidth
                            size="small"
                            value={newContactEmail}
                            onChange={(e) => setNewContactEmail(e.target.value)}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <TextField
                            label="Name (Optional)"
                            fullWidth
                            size="small"
                            value={newContactName}
                            onChange={(e) => setNewContactName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Button 
                            variant="contained" 
                            onClick={handleAddContact}
                            disabled={!newContactEmail.trim()}
                            fullWidth
                          >
                            Add Contact
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                    
                    <DataGrid
                      rows={selectedContactList.contacts || []}
                      columns={contactColumns}
                      disableRowSelectionOnClick={false}
                      checkboxSelection
                      autoPageSize
                      getRowId={(row) => row.email || Math.random().toString()}
                      initialState={{
                        pagination: {
                          paginationModel: { pageSize: 10 }
                        }
                      }}
                      sx={{
                        '& .MuiDataGrid-row:hover': {
                          backgroundColor: 'action.hover',
                        }
                      }}
                      onRowSelectionModelChange={(newSelection) => {
                        setSelectedContacts(newSelection as string[]);
                      }}
                    />
                  </Box>
                </>
              ) : (
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  height: '100%'
                }}>
                  <Typography variant="h6" color="text.secondary">
                    Select a contact list to view details
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>

      {templateToSend && (
        <SendEmailDialog
          open={showSendDialog}
          onClose={() => setShowSendDialog(false)}
          template={templateToSend}
          contactLists={contactLists || []}
          publicContacts={publicUsers || []}
          onSend={handleSendEmail}
        />
      )}
      
      <EmailPreviewDialog
        open={previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        delivery={selectedDelivery}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this email template? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Contact List Dialog */}
      <Dialog 
        open={contactListDialogOpen} 
        onClose={() => setContactListDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingContactList ? 'Edit Contact List' : 'Create Contact List'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="List Name"
            fullWidth
            value={contactListName}
            onChange={(e) => setContactListName(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={contactListDescription}
            onChange={(e) => setContactListDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Tags
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
              {contactListTags.map((tag) => (
                <Chip 
                  key={tag} 
                  label={tag} 
                  onDelete={() => handleDeleteTag(tag)}
                  size="small"
                  color="primary"
                />
              ))}
              {contactListTags.length === 0 && (
                <Typography variant="body2" color="text.secondary">
                  No tags added yet
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                size="small"
                label="Add tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddTag();
                    e.preventDefault();
                  }
                }}
              />
              <Button 
                variant="outlined" 
                onClick={handleAddTag}
                disabled={!newTag.trim() || contactListTags.includes(newTag.trim())}
              >
                Add
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContactListDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleSaveContactList} 
            variant="contained"
            disabled={!contactListName.trim()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Contact List Confirmation Dialog */}
      <Dialog
        open={deleteContactListDialogOpen}
        onClose={() => setDeleteContactListDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this contact list? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteContactListDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmDeleteContactList} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmailManagement;