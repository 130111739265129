import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { Board } from "@freetech/models/freelo";
export const updateBoardOrder = async (boards: Board[]): Promise<void> => {
  const updates = boards.map((board, index) => {
    const boardRef = doc(db, "boards", board.id);
    return updateDoc(boardRef, { 
      order: index + 1,
      updatedAt: DateTime.now().toISO()
    });
  });
  await Promise.all(updates);
}; 