import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { IBugReport } from "../bugReport";

export const bugReportConverter: FirestoreDataConverter<IBugReport> = {
  toFirestore(bugReport: WithFieldValue<IBugReport>): DocumentData {
    return {
      id: bugReport.id,
      title: bugReport.title,
      description: bugReport.description,
      priority: bugReport.priority,
      status: bugReport.status,
      type: bugReport.type,
      projectId: bugReport.projectId,
      reportedById: bugReport.reportedById,
      createdAt: bugReport.createdAt,
      updatedAt: bugReport.updatedAt,
      resolvedAt: bugReport.resolvedAt,
      clientId: bugReport.clientId,
      attachments: bugReport.attachments,
      stepsToReproduce: bugReport.stepsToReproduce,
      expectedBehavior: bugReport.expectedBehavior,
      actualBehavior: bugReport.actualBehavior,
      browserInfo: bugReport.browserInfo,
      ownerId: bugReport.ownerId,
      assignedToId: bugReport.assignedToId,
      comments: bugReport.comments,
      freeloTicketIds: bugReport.freeloTicketIds,
    };
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): IBugReport {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data?.title,
      description: data?.description,
      priority: data?.priority,
      status: data?.status,
      type: data?.type,
      projectId: data?.projectId,
      reportedById: data?.reportedById,
      createdAt: data?.createdAt,
      updatedAt: data?.updatedAt,
      resolvedAt: data?.resolvedAt,
      clientId: data?.clientId,
      attachments: data?.attachments,
      stepsToReproduce: data?.stepsToReproduce,
      expectedBehavior: data?.expectedBehavior,
      actualBehavior: data?.actualBehavior,
      browserInfo: data?.browserInfo,
      ownerId: data?.ownerId,
      assignedToId: data?.assignedToId,
      comments: data?.comments,
      freeloTicketIds: data?.freeloTicketIds,
    };
  },
};
