import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  Divider,
} from "@mui/material";
import {
  AccountBalance as BankIcon,
  CreditCard as StripeIcon,
} from "@mui/icons-material";
import { alpha, useTheme } from "@mui/material/styles";

interface AccountBalanceWidgetProps {
  title: string;
  amount: number;
  availableAmount?: number;
  source: "increase" | "stripe";
}

const AccountBalanceWidget: React.FC<AccountBalanceWidgetProps> = ({
  title,
  amount,
  availableAmount,
  source,
}) => {
  const theme = useTheme();
  
  // Calculate pending amount and percentage
  const pendingAmount = availableAmount !== undefined ? amount - availableAmount : amount * 0.2;
  const availablePercentage = availableAmount !== undefined 
    ? Math.min(100, Math.max(0, (availableAmount / amount) * 100)) 
    : 80;
  const pendingPercentage = availableAmount !== undefined 
    ? Math.min(100, Math.max(0, (pendingAmount / amount) * 100)) 
    : 20;

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(value);
  };

  const getIconColor = () => {
    return source === "increase"
      ? theme.palette.primary.main
      : theme.palette.secondary.main;
  };

  const getBackgroundColor = () => {
    return source === "increase"
      ? alpha(theme.palette.primary.main, 0.08)
      : alpha(theme.palette.secondary.main, 0.08);
  };

  const getIcon = () => {
    return source === "increase" ? (
      <BankIcon sx={{ fontSize: 40, color: getIconColor() }} />
    ) : (
      <StripeIcon sx={{ fontSize: 40, color: getIconColor() }} />
    );
  };

  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: 2,
        boxShadow: 3,
        transition: "0.3s",
        "&:hover": {
          boxShadow: 6,
        },
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: getBackgroundColor(),
              borderRadius: "50%",
              width: 56,
              height: 56,
            }}
          >
            {getIcon()}
          </Box>
        </Box>

        <Typography variant="h4" component="div" sx={{ fontWeight: 700, mb: 1 }}>
          {formatCurrency(amount)}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
            <Typography variant="body2" color="text.secondary">
              Available
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {formatCurrency(availableAmount !== undefined ? availableAmount : amount * 0.8)}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={availablePercentage}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              "& .MuiLinearProgress-bar": {
                backgroundColor: getIconColor(),
              },
              mb: 2,
            }}
          />
        </Box>

        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
            <Typography variant="body2" color="text.secondary">
              Pending
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {formatCurrency(pendingAmount)}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={pendingPercentage}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              "& .MuiLinearProgress-bar": {
                backgroundColor: alpha(getIconColor(), 0.6),
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountBalanceWidget; 