import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "core/firestore";
import { IBugReport, BugReportStatus } from "@freetech/models";
import { updateBugReportAttachments } from "core/bugReport";

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "text/plain",
];

export const useAdminBugReport = (clientId: string) => {
  const queryClient = useQueryClient();

  const {
    data: bugReports,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["bugReports", clientId],
    queryFn: async () => {
      const q = query(
        collection(db, "bugReports"),
        where("clientId", "==", clientId)
      );
      const reports = await getDocs(q);
      return reports.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as IBugReport[];
    },
    enabled: !!clientId,
  });

  const updateBugReport = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<IBugReport> }) => {
      const docRef = doc(db, "bugReports", id);
      await updateDoc(docRef, {
        ...data,
        updatedAt: new Date().toISOString(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports", clientId] });
    },
  });

  const updateAttachments = useMutation<
    void,
    Error,
    { bugReportId: string; files: File[] }
  >({
    mutationFn: ({ bugReportId, files }) =>
      updateBugReportAttachments(bugReportId, files),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports", clientId] });
    },
  });

  const approveBugReport = async (bugReport: IBugReport, engineerId: string) => {
    if (!engineerId) {
      throw new Error("Engineer ID is required for approval");
    }

    return updateBugReport.mutateAsync({
      id: bugReport.id,
      data: {
        status: "in_progress" as BugReportStatus,
        assignedToId: engineerId,
      },
    });
  };

  const rejectBugReport = async (bugReport: IBugReport) => {
    return updateBugReport.mutateAsync({
      id: bugReport.id,
      data: {
        status: "closed" as BugReportStatus,
      },
    });
  };

  return {
    bugReports,
    isLoading,
    error,
    approveBugReport,
    rejectBugReport,
    updateBugReport,
    updateAttachments,
    isUpdatingAttachments: updateAttachments.isPending,
  };
}; 