import { ref, getDownloadURL, getStorage } from 'firebase/storage';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export const getInvoicePDFUrl = async (clientId: string, invoiceId: string): Promise<string> => {
  try {
    const storage = getStorage();
    const db = getFirestore();
    
    // First try to get the invoice document to check if it has a pdfUrl
    const invoiceRef = doc(db, "clients", clientId, "invoices", invoiceId);
    const invoiceDoc = await getDoc(invoiceRef);
    
    if (invoiceDoc.exists() && invoiceDoc.data().pdfUrl) {
      // If the invoice has a pdfUrl, return it
      return invoiceDoc.data().pdfUrl;
    }
    
    // Otherwise, try to get the PDF from the new folder structure
    const newStoragePath = `clients/${clientId}/invoices/${invoiceId}/original.pdf`;
    try {
      const newStorageRef = ref(storage, newStoragePath);
      return await getDownloadURL(newStorageRef);
    } catch (newPathError) {
      console.log("PDF not found in new path, trying legacy path");
      
      // If that fails, try the legacy path
      const legacyStoragePath = `clients/${clientId}/invoices/${invoiceId}.pdf`;
      const legacyStorageRef = ref(storage, legacyStoragePath);
      return await getDownloadURL(legacyStorageRef);
    }
  } catch (error) {
    console.error("Error getting invoice PDF URL:", error);
    throw error;
  }
};
