import React, { useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Stack,
  Grid,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import PayInvoiceDialog from "./PayInvoiceDialog";
import ViewInvoiceDialog from "./ViewInvoiceDialog";
import { ClientInvoiceData } from "types/bank";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PaymentIcon from "@mui/icons-material/Payment";
import BusinessIcon from "@mui/icons-material/Business";

const getStatusColor = (status: string) => {
  switch (status) {
    case "paid":
      return "success";
    case "unpaid":
      return "error";
    case "partial":
      return "warning";
    case "pending":
      return "info";
    default:
      return "default";
  }
};

export const ClientInvoices: React.FC = () => {
  const { currentUser } = useAuth();
  const { clients } = useStakeholder(currentUser?.uid);
  const { clientInvoices, clientInvoicesLoading } = useStakeholderPayments();
  const [selectedInvoice, setSelectedInvoice] = useState<ClientInvoiceData | null>(
    null
  );
  const [isPayDialogOpen, setIsPayDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);

  const handleViewInvoice = (invoice: ClientInvoiceData) => {
    if (!invoice.clientId) {
      const client = clients?.find((c) => c.id === invoice.clientId);
      if (client) {
        setSelectedInvoice({
          ...invoice,
          clientId: client.id
        });
      } else {
        setSelectedInvoice(invoice);
      }
    } else {
      setSelectedInvoice(invoice);
    }
    setIsViewDialogOpen(true);
  };

  const handlePayInvoice = (invoice: ClientInvoiceData) => {
    setSelectedInvoice(invoice);
    setIsPayDialogOpen(true);
  };

  if (clientInvoicesLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!clientInvoices || clientInvoices.length === 0) {
    return (
      <ListItem sx={{ px: 3, py: 2 }}>
        <ListItemText
          primary={
            <Typography variant="body1" color="text.secondary">
              No invoice history
            </Typography>
          }
        />
      </ListItem>
    );
  }

  return (
    <Box>
      {clientInvoices.map((invoice) => {
        const client = clients?.find((c) => c.id === invoice.clientId);
        const partialAmount = invoice.partialPaymentAmount || 0;
        const remainingAmount = invoice.totalDollarAmountDue - partialAmount;
        return (
          <Accordion key={invoice.id} elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                px: 3,
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <BusinessIcon color="action" />
                    <Typography>{client?.companyName}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography color="text.secondary">
                    Invoice #{invoice.invoiceNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>
                    ${invoice.totalDollarAmountDue.toLocaleString()}
                    {invoice.status === "partial" && (
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: "block" }}
                      >
                        Paid: ${partialAmount.toLocaleString()} • Remaining: ${remainingAmount.toLocaleString()}
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Chip
                    label={(invoice.status || 'unknown').toUpperCase()}
                    color={getStatusColor(invoice.status || 'unknown')}
                    size="small"
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 3, pb: 3 }}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Invoice Details
                  </Typography>
                  <Typography variant="body2">
                    Due Date:{" "}
                    {new Date(invoice.dueDate).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">
                    Created:{" "}
                    {new Date(invoice.invoiceDate).toLocaleDateString()}
                  </Typography>
                  {invoice.status === "partial" && (
                    <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                      Partial payment of ${partialAmount.toLocaleString()} received.
                      Remaining amount: ${remainingAmount.toLocaleString()}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<PictureAsPdfIcon />}
                    onClick={() => handleViewInvoice(invoice)}
                  >
                    View Invoice
                  </Button>
                  {(invoice.status === "unpaid" || invoice.status === "partial") && (
                    <Button
                      variant="contained"
                      startIcon={<PaymentIcon />}
                      onClick={() => handlePayInvoice(invoice)}
                    >
                      {invoice.status === "partial" ? "Pay Remaining" : "Pay Now"}
                    </Button>
                  )}
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}

      {selectedInvoice && (
        <>
          <PayInvoiceDialog
            open={isPayDialogOpen}
            onClose={() => setIsPayDialogOpen(false)}
            invoice={selectedInvoice}
            accountId="sandbox_account_wsja3ql2aqobwsegmehy"
          />
          <ViewInvoiceDialog
            open={isViewDialogOpen}
            onClose={() => setIsViewDialogOpen(false)}
            invoice={selectedInvoice}
          />
        </>
      )}
    </Box>
  );
};
