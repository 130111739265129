import { collection, query, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { UserDocument } from "@freetech/models/user";

export const getUserDocuments = async (
  userId: string
): Promise<UserDocument[]> => {
  try {
    const documentsQuery = query(collection(db, "users", userId, "documents"));
    const documentsSnapshot = await getDocs(documentsQuery);
    return documentsSnapshot.docs.map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        }) as UserDocument
    );
  } catch (error) {
    return [];
  }
};
