import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Divider,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CodeIcon from "@mui/icons-material/Code";
import SendIcon from "@mui/icons-material/Send";
import { DateTime } from "luxon";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { BugReportComment } from "@freetech/models/bugReport";
import { useAuth } from "hooks/auth/useAuth";
import { Timestamp } from "firebase/firestore";

interface BugReportCommentsProps {
  comments: BugReportComment[];
  onAddComment: (content: string) => Promise<any>;
  isSubmitting?: boolean;
}

const MenuBar: React.FC<{ editor: any }> = ({ editor }) => {
  if (!editor) return null;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        mb: 1,
        borderBottom: 1,
        borderColor: "divider",
        pb: 1,
      }}
    >
      <Tooltip title="Bold">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBold().run()}
          color={editor.isActive("bold") ? "primary" : "default"}
        >
          <FormatBoldIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Italic">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          color={editor.isActive("italic") ? "primary" : "default"}
        >
          <FormatItalicIcon />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title="Bullet List">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          color={editor.isActive("bulletList") ? "primary" : "default"}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Numbered List">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          color={editor.isActive("orderedList") ? "primary" : "default"}
        >
          <FormatListNumberedIcon />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title="Code">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          color={editor.isActive("codeBlock") ? "primary" : "default"}
        >
          <CodeIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const StakeholderReportOrRequestComments: React.FC<
  BugReportCommentsProps
> = ({ comments, onAddComment, isSubmitting = false }) => {
  const { data: users } = usePublicUserList();
  const { userInfo } = useAuth();
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: "Write a comment...",
      }),
    ],
    onUpdate: ({ editor }) => {
      setIsEditorEmpty(editor.isEmpty);
    },
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none",
      },
    },
  });

  const handleSubmit = async () => {
    if (!editor || isEditorEmpty || isSubmitting) return;

    const content = editor.getHTML();
    await onAddComment(content);
    editor.commands.clearContent();
  };

  const getUserById = (userId: string) => {
    return users?.find((user) => user.id === userId);
  };



  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Comments
      </Typography>

      {/* Comment List */}
      <Box sx={{ mb: 3 }}>
        {comments.map((comment) => {
          const user = getUserById(comment.userId);
          return (
            <Paper key={comment.id} variant="outlined" sx={{ p: 2, mb: 2 }}>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
              >
                <Avatar
                  src={user?.profilePicture || ""}
                  alt={user ? `${user.firstName} ${user.lastName}` : "Unknown"}
                  sx={{ width: 32, height: 32 }}
                >
                  {user?.firstName?.[0]}
                </Avatar>
                <Box>
                  <Typography variant="subtitle2">
                    {user
                      ? `${user.firstName} ${user.lastName}`
                      : "Unknown User"}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {comment.createdAt}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{ pl: 5 }}
                dangerouslySetInnerHTML={{ __html: comment.content }}
              />
            </Paper>
          );
        })}
      </Box>

      {/* Comment Editor */}
      {userInfo && (
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
            <Avatar
              src={userInfo.profilePicture || ""}
              alt={`${userInfo.firstName} ${userInfo.lastName}`}
              sx={{ width: 32, height: 32 }}
            >
              {userInfo.firstName[0]}
            </Avatar>
            <Typography variant="subtitle2">
              {userInfo.firstName} {userInfo.lastName}
            </Typography>
          </Box>

          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
              p: 2,
              mb: 2,
              "& .ProseMirror": {
                minHeight: "100px",
                outline: "none",
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          >
            <MenuBar editor={editor} />
            <EditorContent editor={editor} />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              disabled={isEditorEmpty || isSubmitting}
              onClick={handleSubmit}
              endIcon={<SendIcon />}
            >
              {isSubmitting ? "Sending..." : "Send"}
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  );
};
