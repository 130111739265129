import { getHeaders } from "core/getHeaders";
import { CreateUserType } from "@freetech/models/user";
import {
  EversignTemplate,
  EversignDocumentBody,
  EversignDocumentRequestBody,
  ProjectPlan,
} from "@freetech/models";
import { GenerateProjectAssignmentTemplateRequestBody } from "@freetech/models/eversign";
import { functionsBaseUrl } from "config/functionsBaseUrl";
import { sharedFunctions } from "./sharedFunctions";
import { BlogContentPrompt } from "hooks/blog/useBlogAI";
import { NotificationPreferences } from "@freetech/models/notifications";
import { submitProjectIdea } from "core/stakeholder/submitProjectIdea";
import { Stakeholder } from "@freetech/models/user";
import { ProjectIdeaForm } from "types/projects/projectIdea";

interface ProcessSignedTaxFormRequestBody {
  userId: string;
  documentHash: string;
  formType: "w9" | "w8ben";
}

interface SignUpStakeholderBody {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone?: string;
  companyName: string;
  companyWebsite?: string;
  message?: string;
  isStudent?: boolean;
  isFreelancer?: boolean;
}

interface GenerateCSADocumentRequestBody {
  csaId: string;
  clientId: string;
  adminUserId: string;
  projectId?: string;
  stakeholderId?: string;
}

interface ProcessSignedCSARequestBody {
  documentHash: string;
  clientId: string;
  csaId: string;
}

// Same type definition can be used for all signup types
type SignUpStudentBody = SignUpStakeholderBody;
type SignUpFreelancerBody = SignUpStakeholderBody;

export const portalFunctions = {
  ai: {
    sendMessage: async (
      prompt: string,
      context?: { boardId?: string; taskId?: string }
    ) => {
      const url = `${functionsBaseUrl}/portal/shared/ai/message`;
      const method = "POST";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({ prompt, context }),
      });

      console.log("AI response:", response);

      if (!response.ok) {
        throw new Error("Failed to send AI message");
      }

      return response.json();
    },

    generateBlogContent: async (
      prompt: BlogContentPrompt,
      options: {
        onChunk?: (chunk: string) => void;
        onComplete?: (fullContent: string) => void;
        onError?: (error: Error) => void;
        signal?: AbortSignal;
      } = {}
    ): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/shared/ai/blog-content`;
      const headers = await getHeaders();

      console.log(
        "Generating blog content with prompt:",
        JSON.stringify(prompt)
      );

      try {
        // Make the API call
        console.log("Sending request to:", url);
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify({ prompt }),
          signal: options.signal,
        });

        console.log(
          "Blog content response status:",
          response.status,
          response.statusText
        );
        console.log(
          "Blog content response headers:",
          Object.fromEntries([...response.headers.entries()])
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response body:", errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Set up event source for streaming
        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error("Response body is null");
        }

        console.log("Starting to read response stream");
        const decoder = new TextDecoder();
        let buffer = "";
        let fullContent = "";
        let chunkCount = 0;

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            console.log(
              "Stream reading complete, processed chunks:",
              chunkCount
            );
            break;
          }

          // Decode the chunk and add to buffer
          const chunk = decoder.decode(value, { stream: true });
          chunkCount++;

          console.log(`Raw chunk ${chunkCount}:`, chunk);
          buffer += chunk;

          // Process complete SSE messages from the buffer
          let startPos = 0;
          let endPos;
          let processedEvents = 0;

          while ((endPos = buffer.indexOf("\n\n", startPos)) !== -1) {
            const eventStr = buffer.substring(startPos, endPos);
            startPos = endPos + 2;
            processedEvents++;

            console.log(`Processing SSE event ${processedEvents}:`, eventStr);

            // Process each line in the event
            const lines = eventStr.split("\n");
            for (const line of lines) {
              console.log(`Processing line:`, line);

              // Parse the SSE data
              if (line.startsWith("data: ")) {
                try {
                  const dataStr = line.substring(6);
                  console.log("Extracted data string:", dataStr);

                  const eventData = JSON.parse(dataStr);
                  console.log("Parsed event data:", eventData);

                  switch (eventData.type) {
                    case "chunk":
                      if (eventData.content) {
                        console.log(
                          "Received content chunk:",
                          eventData.content
                        );
                        fullContent += eventData.content;
                        options.onChunk?.(eventData.content);
                      } else {
                        console.warn("Received chunk event with no content");
                      }
                      break;

                    case "error":
                      console.error("Received error event:", eventData.message);
                      throw new Error(eventData.message || "Unknown error");

                    case "end":
                      console.log(
                        "Received end event, total content length:",
                        fullContent.length
                      );
                      // Don't call onComplete here, we'll do it after the stream is done
                      break;

                    case "start":
                      console.log("Received start event");
                      break;

                    default:
                      console.warn("Unknown event type:", eventData.type);
                  }
                } catch (e) {
                  console.error("Error parsing SSE data:", e, "Line:", line);
                }
              } else {
                console.log("Line doesn't start with 'data: '");
              }
            }
          }

          // Keep the remainder for the next iteration
          buffer = buffer.substring(startPos);
          console.log("Remaining buffer:", buffer);
        }

        // If we haven't accumulated any text but have processed chunks, use a fallback
        if (fullContent.length === 0 && chunkCount > 0) {
          console.warn(
            "No content accumulated but chunks were processed. Using fallback content."
          );
          fullContent =
            "I couldn't generate specific content based on your request. Please try again with more details or a different topic.";
          options.onChunk?.(fullContent);
        }

        // If we haven't called onComplete yet, do it now
        if (!options.signal?.aborted) {
          console.log(
            "Stream complete, calling onComplete with content length:",
            fullContent.length
          );
          options.onComplete?.(fullContent);
        }
      } catch (err) {
        console.error("Error in generateBlogContent:", err);
        // Only set error if it's not an abort error
        if (err instanceof Error && err.name !== "AbortError") {
          options.onError?.(err);
        }
        throw err;
      }
    },
  },
  admin: {
    getAllUsers: async () => {
      const url = `${functionsBaseUrl}/portal/admin/getAuthUsers`;
      const method = "GET";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
      });
      return response;
    },
    createFirebaseUser: async (userToCreate: CreateUserType) => {
      const url = `${functionsBaseUrl}/portal/admin/createFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify(userToCreate),
      });
      return response;
    },
    deleteFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/deleteFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
    disableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/disableFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
    enableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/enableFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
    resendVerificationEmail: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/resendVerificationEmail`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
    sendInvoiceReminders: async (clientId: string, invoiceId: string, stakeholderIds: string[]) => {
      const url = `${functionsBaseUrl}/portal/admin/sendInvoiceReminders`;
      const method = "POST";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({ clientId, invoiceId, stakeholderIds }),
      });

      if (!response.ok) {
        throw new Error("Failed to send invoice reminders");
      }

      return response.json();
    },
  },
  eversign: {
    generateProjectAssignmentTemplate: async (
      input: GenerateProjectAssignmentTemplateRequestBody
    ) => {
      return fetch(
        `${functionsBaseUrl}/portal/eversign/generateProjectAssignmentTemplate`,
        {
          method: "POST",
          headers: await getHeaders(),
          body: JSON.stringify(input),
        }
      );
    },
    processSignedTaxForm: async (input: ProcessSignedTaxFormRequestBody) => {
      return fetch(`${functionsBaseUrl}/portal/eversign/processSignedTaxForm`, {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify(input),
      });
    },
    fetchEversignTemplates: async (): Promise<EversignTemplate[]> => {
      const url = `${functionsBaseUrl}/portal/eversign/fetchEversignTemplates`;
      const method = "GET";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method: method,
        headers,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error("Failed to fetch Eversign templates");
      }

      return data;
    },
    viewTemplate: async (templateHash: string) => {
      const url = `${functionsBaseUrl}/portal/eversign/viewTemplate`;
      const method = "GET";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ template_hash: templateHash }),
      });
      return response.json();
    },
    useEversignTemplate: async ({
      documentData,
      sandbox,
    }: {
      documentData: EversignDocumentRequestBody;
      sandbox: number;
    }) => {
      const url = `${functionsBaseUrl}/portal/eversign/useEversignTemplate`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({
          document_data: documentData,
          sandbox: sandbox,
        }),
      });
      return response;
    },
    generateCSADocument: async (input: GenerateCSADocumentRequestBody) => {
      return fetch(`${functionsBaseUrl}/portal/eversign/generateCSADocument`, {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify(input),
      });
    },
    processSignedCSA: async (input: ProcessSignedCSARequestBody) => {
      return fetch(`${functionsBaseUrl}/portal/eversign/processSignedCSA`, {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify(input),
      });
    },
    processStakeholderSignedCSA: async (input: ProcessSignedCSARequestBody) => {
      return fetch(
        `${functionsBaseUrl}/portal/eversign/processStakeholderSignedCSA`,
        {
          method: "POST",
          headers: await getHeaders(),
          body: JSON.stringify(input),
        }
      );
    },
    cancelDocument: async (documentHash: string) => {
      const url = `${functionsBaseUrl}/portal/eversign/cancelDocument`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ document_hash: documentHash }),
      });
      return response;
    },
    generateFreelancerW9Form: async () => {
      const url = `${functionsBaseUrl}/portal/eversign/generateFreelancerW9Form`;
      const method = "GET";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
      });
      return response;
    },
    generateFreelancerW8BenForm: async () => {
      const url = `${functionsBaseUrl}/portal/eversign/generateFreelancerW8BenForm`;
      const method = "GET";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
      });
      return response;
    },
    generateReplacementTaxForm: async ({
      formType,
    }: {
      formType: "w9" | "w8ben";
    }) => {
      const url = `${functionsBaseUrl}/portal/eversign/generateReplacementTaxForm`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ formType }),
      });
      return response;
    },
    processSignedProjectAssignment: async (
      clientId: string,
      projectAssignmentId: string
    ) => {
      const url = `${functionsBaseUrl}/portal/eversign/processSignedProjectAssignment`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({
          clientId,
          projectAssignmentId,
        }),
      });
      return response;
    },
  },
  public: {
    generatePasswordReset: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generatePasswordReset`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response);

      if (response.status === 429) {
        throw new Error("Too many requests");
      }

      if (!response.ok) {
        throw new Error("Failed to send password reset email");
      }
    },
    resendVerificationEmail: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/resendVerificationEmail`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    generateSignInLink: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generateSignInLink`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: { "Content-Type": "application/json" },
      });
    },
    signUpStakeholder: async (
      data: SignUpStakeholderBody
    ): Promise<Response> => {
      const url = `${functionsBaseUrl}/portal/public/signUpStakeholder`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to create stakeholder account");
      }

      return response;
    },
    signUpStudent: async (
      data: SignUpStudentBody
    ): Promise<Response> => {
      const url = `${functionsBaseUrl}/portal/public/signUpStudent`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to create student account");
      }

      return response;
    },
    signUpFreelancer: async (
      data: SignUpFreelancerBody
    ): Promise<Response> => {
      const url = `${functionsBaseUrl}/portal/public/signUpFreelancer`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to create freelancer account");
      }

      return response;
    },
  },
  shared: {
    setInitialPassword: async (password: string) => {
      const url = `${functionsBaseUrl}/portal/shared/setInitialPassword`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ password }),
      });
      return response;
    },

    updateNotificationPreferences: async (
      userId: string,
      preferences: NotificationPreferences
    ) => {
      const url = `${functionsBaseUrl}/portal/shared/users/${userId}/notification-preferences`;
      const method = "PUT";
      const headers = await getHeaders();

      try {
        const response = await fetch(url, {
          method,
          headers,
          body: JSON.stringify({ preferences }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.error || "Failed to update notification preferences"
          );
        }

        return response.json();
      } catch (error) {
        console.error("Error updating notification preferences:", error);
        throw error;
      }
    },

    getNotificationPreferences: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/shared/users/${userId}/notification-preferences`;
      const method = "GET";
      const headers = await getHeaders();

      try {
        const response = await fetch(url, {
          method,
          headers,
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.error || "Failed to retrieve notification preferences"
          );
        }

        const data = await response.json();
        return data.preferences as NotificationPreferences;
      } catch (error) {
        console.error("Error retrieving notification preferences:", error);
        throw error;
      }
    },
  },
  stakeholder: {
    suggestProjectType: async (description: string) => {
      const url = `${functionsBaseUrl}/portal/stakeholder/projectIdea/suggestProjectType`;
      const method = "POST";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({ description }),
      });

      if (!response.ok) {
        throw new Error("Failed to suggest project type");
      }

      return response.json();
    },
    suggestRelevantClient: async (projectDescription: string) => {
      const url = `${functionsBaseUrl}/portal/stakeholder/projectIdea/suggestRelevantClient`;
      const method = "POST";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({ projectDescription }),
      });

      if (!response.ok) {
        throw new Error("Failed to suggest relevant client");
      }

      // Parse the response which now may include the suggestNewClient flag
      const recommendations = await response.json();
      return recommendations;
    },
    generateResponse: async (
      messages: Array<{ role: "user" | "assistant"; content: string }>,
      currentStep: string,
      options: {
        onChunk?: (chunk: string) => void;
        onComplete?: (fullContent: string) => void;
        onError?: (error: Error) => void;
        signal?: AbortSignal;
      } = {}
    ): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/stakeholder/projectIdea/generateResponse`;
      const headers = await getHeaders();

      try {
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify({ messages, currentStep }),
          signal: options.signal,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response body:", errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Set up event source for streaming
        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error("Response body is null");
        }

        console.log("Starting to read response stream");
        const decoder = new TextDecoder();
        let buffer = "";
        let fullContent = "";
        let chunkCount = 0;

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            console.log(
              "Stream reading complete, processed chunks:",
              chunkCount
            );
            break;
          }

          // Decode the chunk and add to buffer
          const chunk = decoder.decode(value, { stream: true });
          chunkCount++;

          buffer += chunk;

          // Process complete SSE messages from the buffer
          let startPos = 0;
          let endPos;
          let processedEvents = 0;

          while ((endPos = buffer.indexOf("\n\n", startPos)) !== -1) {
            const eventStr = buffer.substring(startPos, endPos);
            startPos = endPos + 2;
            processedEvents++;

            // Process each line in the event
            const lines = eventStr.split("\n");
            for (const line of lines) {
              // Parse the SSE data
              if (line.startsWith("data: ")) {
                try {
                  const dataStr = line.substring(6);
                  const eventData = JSON.parse(dataStr);

                  switch (eventData.type) {
                    case "chunk":
                      if (eventData.content) {
                        fullContent += eventData.content;
                        options.onChunk?.(eventData.content);
                      }
                      break;

                    case "error":
                      throw new Error(eventData.message || "Unknown error");

                    case "end":
                      // Don't call onComplete here, we'll do it after the stream is done
                      break;

                    case "start":
                      // Stream started
                      break;

                    default:
                      console.warn("Unknown event type:", eventData.type);
                  }
                } catch (e) {
                  console.error("Error parsing SSE data:", e);
                }
              }
            }
          }

          // Keep the remainder for the next iteration
          buffer = buffer.substring(startPos);
        }

        // If we haven't called onComplete yet, do it now
        if (!options.signal?.aborted) {
          options.onComplete?.(fullContent);
        }
      } catch (err) {
        console.error("Error in generateResponse:", err);
        // Only set error if it's not an abort error
        if (err instanceof Error && err.name !== "AbortError") {
          options.onError?.(err);
        }
        throw err;
      }
    },
    generateProjectPlan: async ({
      clientId,
      projectIdeaId,
    }: {
      clientId: string;
      projectIdeaId: string;
    }): Promise<{ success: boolean; plan: ProjectPlan }> => {
      const url = `${functionsBaseUrl}/portal/stakeholder/projectIdea/generateProjectPlan`;
      const method = "POST";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({ clientId, projectIdeaId }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate project plan");
      }

      return response.json();
    },
    submitProjectIdea: async (
      stakeholder: Stakeholder,
      ideaData: ProjectIdeaForm,
      aiProjectPlan: ProjectPlan
    ): Promise<any> => {
      return submitProjectIdea(stakeholder, ideaData, aiProjectPlan);
    },
    handleConversation: async (
      messages: { role: string; content: string }[],
      currentStep: string,
      clientId: string,
      projectIdeaId?: string,
      options: {
        onChunk?: (chunk: string) => void;
        onComplete?: (fullContent: string) => void;
        onError?: (error: Error) => void;
        signal?: AbortSignal;
      } = {}
    ): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/stakeholder/projectIdea/handleConversation`;
      const headers = await getHeaders();

      try {
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify({
            messages,
            currentStep,
            clientId,
            projectIdeaId,
          }),
          signal: options.signal,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response body:", errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error("Response body is null");
        }

        const decoder = new TextDecoder();
        let buffer = "";
        let fullContent = "";

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;

          let startPos = 0;
          let endPos;

          while ((endPos = buffer.indexOf("\n\n", startPos)) !== -1) {
            const eventStr = buffer.substring(startPos, endPos);
            startPos = endPos + 2;

            const lines = eventStr.split("\n");
            for (const line of lines) {
              if (line.startsWith("data: ")) {
                try {
                  const dataStr = line.substring(6);
                  const eventData = JSON.parse(dataStr);

                  switch (eventData.type) {
                    case "chunk":
                      if (eventData.content) {
                        fullContent += eventData.content;
                        options.onChunk?.(eventData.content);
                      }
                      break;

                    case "error":
                      throw new Error(eventData.message || "Unknown error");

                    case "end":
                      break;

                    case "start":
                      break;

                    default:
                      console.warn("Unknown event type:", eventData.type);
                  }
                } catch (e) {
                  console.error("Error parsing SSE data:", e);
                }
              }
            }
          }

          buffer = buffer.substring(startPos);
        }

        if (!options.signal?.aborted) {
          options.onComplete?.(fullContent);
        }
      } catch (err) {
        console.error("Error in handleConversation:", err);
        if (err instanceof Error && err.name !== "AbortError") {
          options.onError?.(err);
        }
        throw err;
      }
    },
    submitStakeholderProjectIdea: async ({
      clientId,
      projectIdeaId,
      projectPlanId,
    }: {
      clientId: string;
      projectIdeaId: string;
      projectPlanId: string;
    }): Promise<{ success: boolean; projectId: string }> => {
      const url = `${functionsBaseUrl}/portal/stakeholder/projectIdea/submitStakeholderProjectIdea`;
      const method = "POST";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({ clientId, projectIdeaId, projectPlanId }),
      });

      if (!response.ok) {
        throw new Error("Failed to submit project idea");
      }

      return response.json();
    },
    initializeProjectIdea: async (data: { 
      clientId: string, 
      projectName: string, 
      projectType: string,
      projectDescription: string,
      budget?: number,
      timeline?: string,
      expectedStartDate?: string
    }): Promise<{ success: boolean; projectIdeaId: string; projectData: any }> => {
      const url = `${functionsBaseUrl}/portal/stakeholder/projectIdea/initializeProjectIdea`;
      const method = "POST";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to initialize project idea");
      }

      return response.json();
    },
  },
};
