import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { PrivateUserInfoType } from "@freetech/models/user";
import { privateUserConverter } from"@freetech/models/converters";

const db = getFirestore();

interface GetPrivateUserProfilesParams {
  userIds?: string[];
  filters?: {
    role?: string;
  };
}

export const getPrivateUserProfiles = async ({
  userIds,
  filters,
}: GetPrivateUserProfilesParams = {}): Promise<PrivateUserInfoType[]> => {
  const usersRef = collection(db, "users").withConverter(privateUserConverter);
  let q = query(usersRef);

  if (userIds?.length) {
    q = query(usersRef, where("id", "in", userIds));
  }

  if (filters?.role) {
    q = query(usersRef, where("role", "==", filters.role));
  }

  const querySnapshot = await getDocs(q);
  const users: PrivateUserInfoType[] = [];

  querySnapshot.forEach((doc) => {
    users.push(doc.data());
  });

  return users;
};
