import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  IconButton,
  Grid,
  Tooltip,
  Avatar
} from "@mui/material";
import {
  ArrowForwardIos as NextIcon,
  ArrowBackIos as PrevIcon,
} from "@mui/icons-material";
import StripeIcon from '@mui/icons-material/Payment';
import BankIcon from '@mui/icons-material/AccountBalance';
import { DateTime } from "luxon";
import { alpha, useTheme } from "@mui/material/styles";

interface PaymentEvent {
  date: DateTime;
  amount: number;
  description: string;
  type: "incoming" | "outgoing";
  status?: string;
  source?: "increase" | "stripe";
}

interface PaymentsCalendarProps {
  events: PaymentEvent[];
}

const PaymentsCalendar: React.FC<PaymentsCalendarProps> = ({ events }) => {
  const [currentMonth, setCurrentMonth] = useState(DateTime.now().startOf("month"));
  const [filteredEvents, setFilteredEvents] = useState<PaymentEvent[]>([]);
  const theme = useTheme();
  
  // Filter events whenever the month changes or events change
  useEffect(() => {
    const filtered = events.filter(event => {
      return event.date.month === currentMonth.month && 
             event.date.year === currentMonth.year;
    });
    setFilteredEvents(filtered);
  }, [events, currentMonth]);
  
  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.minus({ months: 1 }));
  };
  
  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.plus({ months: 1 }));
  };
  
  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };
  
  // Helper to get color for event status
  const getStatusColor = (event: PaymentEvent) => {
    // First determine color by transaction type
    const baseColor = event.type === "incoming" 
      ? theme.palette.success.main 
      : theme.palette.error.main;
      
    // Then adjust based on status
    switch(event.status) {
      case "pending":
        return alpha(baseColor, 0.6);
      case "failed":
        return theme.palette.grey[500];
      case "complete":
      default:
        return baseColor;
    }
  };
  
  // Get source icon for event
  const getSourceIcon = (event: PaymentEvent) => {
    switch(event.source) {
      case "stripe":
        return <StripeIcon fontSize="small" />;
      case "increase":
      default:
        return <BankIcon fontSize="small" />;
    }
  };
  
  // Get days in the current month view
  const getDaysInMonth = () => {
    const daysInMonth = currentMonth.daysInMonth || 30;
    const firstDayOfMonth = currentMonth.startOf("month").weekday;
    
    // Create array of day numbers, with empty slots for days from previous month
    const daysArray = [];
    
    // Add empty spaces for days from the previous month (1-based, Monday = 1)
    for (let i = 1; i < firstDayOfMonth; i++) {
      daysArray.push(null);
    }
    
    // Add days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    
    return daysArray;
  };
  
  // Group events by day
  const eventsByDay = filteredEvents.reduce((acc, event) => {
    const day = event.date.day;
    
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(event);
    
    return acc;
  }, {} as Record<number, PaymentEvent[]>);
  
  const days = getDaysInMonth();
  
  const totalEvents = filteredEvents.length;
  const pendingEvents = filteredEvents.filter(e => e.status === 'pending').length;
  const upcomingEvents = filteredEvents.filter(e => 
    e.date >= DateTime.now().startOf('day') && e.status !== 'failed'
  ).length;
  
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="subtitle1">
          {currentMonth.toFormat("MMMM yyyy")}
        </Typography>
        <Box>
          <IconButton onClick={handlePrevMonth} size="small">
            <PrevIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={handleNextMonth} size="small">
            <NextIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      
      <Grid container spacing={0.5} sx={{ mb: 2 }}>
        {/* Weekday headers */}
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
          <Grid item xs={12/7} key={day}>
            <Box sx={{ textAlign: "center", p: 0.5 }}>
              <Typography variant="caption" color="text.secondary">
                {day}
              </Typography>
            </Box>
          </Grid>
        ))}
        
        {/* Calendar days */}
        {days.map((day, index) => (
          <Grid item xs={12/7} key={index}>
            <Tooltip 
              title={
                eventsByDay[day as number]?.length 
                  ? `${eventsByDay[day as number].length} transactions` 
                  : ""
              } 
              arrow
              disableHoverListener={!eventsByDay[day as number]?.length}
            >
              <Box 
                sx={{ 
                  textAlign: "center", 
                  p: 0.5, 
                  height: 35, 
                  border: day ? "1px solid" : "none", 
                  borderColor: "divider",
                  borderRadius: 1,
                  bgcolor: eventsByDay[day as number]?.length 
                    ? alpha(theme.palette.primary.main, 0.1) 
                    : "transparent",
                  opacity: day ? 1 : 0,
                  position: "relative",
                  cursor: eventsByDay[day as number]?.length ? "pointer" : "default",
                  "&:hover": {
                    bgcolor: eventsByDay[day as number]?.length 
                      ? alpha(theme.palette.primary.main, 0.2) 
                      : "transparent",
                  }
                }}
              >
                {day && (
                  <>
                    <Typography variant="body2" sx={{ 
                      fontWeight: eventsByDay[day]?.length ? "bold" : "normal",
                    }}>
                      {day}
                    </Typography>
                    
                    {/* Display dots for each type of event on this day */}
                    {eventsByDay[day as number]?.length > 0 && (
                      <Box sx={{ 
                        display: "flex", 
                        justifyContent: "center", 
                        position: "absolute",
                        bottom: 2,
                        left: 0,
                        right: 0
                      }}>
                        {eventsByDay[day as number].slice(0, 3).map((event, i) => (
                          <Box 
                            key={i}
                            sx={{ 
                              width: 5, 
                              height: 5, 
                              borderRadius: "50%", 
                              bgcolor: getStatusColor(event),
                              mx: 0.2
                            }} 
                          />
                        ))}
                        {eventsByDay[day as number].length > 3 && (
                          <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.5rem', ml: 0.2 }}>
                            +{eventsByDay[day as number].length - 3}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
      
      <Divider sx={{ my: 2 }} />
      
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Typography variant="subtitle2">
          {currentMonth.toFormat("MMMM")} Transactions ({totalEvents})
        </Typography>
        <Box>
          <Chip 
            size="small" 
            label={`${pendingEvents} Pending`} 
            color="warning" 
            sx={{ mr: 1, height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.7rem' } }} 
          />
          <Chip 
            size="small" 
            label={`${upcomingEvents} Upcoming`} 
            color="info" 
            sx={{ height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.7rem' } }} 
          />
        </Box>
      </Box>
      
      <List dense disablePadding sx={{ maxHeight: 150, overflow: "auto" }}>
        {filteredEvents.length > 0 ? (
          filteredEvents
            .sort((a, b) => {
              // Sort by date, with future dates first
              return b.date.toMillis() - a.date.toMillis();
            })
            .map((event, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider component="li" />}
                <ListItem 
                  sx={{ 
                    py: 0.5,
                    opacity: event.status === "failed" ? 0.6 : 1
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            sx={{
                              width: 20,
                              height: 20,
                              mr: 1,
                              bgcolor: getStatusColor(event)
                            }}
                          >
                            {getSourceIcon(event)}
                          </Avatar>
                          <Typography variant="body2" noWrap sx={{ maxWidth: 120 }}>
                            {event.description}
                          </Typography>
                        </Box>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: event.type === "incoming" ? "success.main" : "error.main",
                            fontWeight: "bold"
                          }}
                        >
                          {event.type === "incoming" ? "+" : "-"}
                          {formatCurrency(event.amount)}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="caption" color="text.secondary">
                          {event.date.toFormat("LLL dd, yyyy")}
                        </Typography>
                        {event.status && (
                          <Chip
                            label={event.status}
                            size="small"
                            color={
                              event.status === "complete" 
                                ? "success" 
                                : event.status === "pending" 
                                  ? "warning" 
                                  : "error"
                            }
                            sx={{ 
                              height: 16, 
                              '& .MuiChip-label': { px: 1, fontSize: '0.6rem' } 
                            }}
                          />
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))
        ) : (
          <ListItem>
            <ListItemText 
              primary={
                <Typography variant="body2" color="text.secondary" align="center">
                  No transactions for {currentMonth.toFormat("MMMM")}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default PaymentsCalendar; 