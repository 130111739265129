import { addDocument, db } from "core/firestore";
import { Project } from "@freetech/models/projects";
import { Stakeholder } from "@freetech/models/user";
import { ProjectIdeaData } from "@freetech/models/projects";
import { projectConverter } from "@freetech/models/converters";
import { collection } from "firebase/firestore";
import { doc } from "firebase/firestore";

export const submitNewProjectIdea = async (
  stakeholder: Stakeholder,
  ideaData: ProjectIdeaData
): Promise<Project> => {
  if (!stakeholder.stakeholderClientIds?.length) {
    throw new Error("No client associated with this stakeholder");
  }

  const newProject: Partial<Project> = {
    name: ideaData.name,
    description: ideaData.description,
    status: "idea",
    ideaDate: new Date().toISOString(),
    startDate: null,
    endDate: null,
    clientId: stakeholder.stakeholderClientIds[0],
    createdByUserId: stakeholder.id,
  };

  const projectRef = doc(
    collection(db, `clients/${stakeholder.stakeholderClientIds[0]}/projects`)
  ).withConverter(projectConverter);

  const addedProject = await addDocument(projectRef.path, newProject);

  return {
    ...newProject,
    id: addedProject.id,
  } as Project;
};
