import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { timesheetConverter } from "@freetech/models/converters";
import { Timesheet } from "@freetech/models/timesheet";

export const revokeTimesheetApproval = async (id: string) => {
  const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
  
  const updateData: Partial<Timesheet> = {
    approved: false,
    approvedAt: null,
    approvedBy: null,
    approvedByEmail: null,
  };

  await updateDoc(timesheetRef, updateData);
}; 