import React, { useMemo } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import { motion, useSpring, useMotionValue, useTransform } from "framer-motion";
import { Timesheet } from "@freetech/models/timesheet";
import { useFreelancerProjectAssignments } from "hooks/freelancer";
import { useEffect } from "react";

interface TimesheetStatsProps {
  timesheets: Timesheet[];
}

const CountingNumber: React.FC<{
  value: number;
  formatter: (value: number) => string;
}> = ({ value, formatter }) => {
  const motionValue = useSpring(0, {
    stiffness: 80,
    damping: 20,
    mass: 1,
  });

  const roundedValue = useTransform(motionValue, (latest) => {
    if (latest === value) return formatter(latest);
    // Use different precision for different ranges
    const precision = latest > 1000 ? 0 : latest > 100 ? 1 : 2;
    return formatter(Number(latest.toFixed(precision)));
  });

  useEffect(() => {
    motionValue.set(value);
  }, [value, motionValue]);

  return <motion.span>{roundedValue}</motion.span>;
};

const StatCard: React.FC<{
  value: string | number;
  label: string;
  highlight?: boolean;
}> = ({ value, label, highlight }) => {
  const theme = useTheme();
  const numericValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.]/g, '')) : value;

  const formatter = (val: number) => {
    if (label.toLowerCase().includes('earnings') || label.toLowerCase().includes('rate')) {
      return `$${val.toFixed(2)}${label.toLowerCase().includes('rate') ? '/hr' : ''}`;
    }
    return `${val.toFixed(1)}h`;
  };

  return (
    <Paper
      elevation={4}
      sx={{
        p: 3,
        textAlign: "center",
        background: highlight
          ? `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
          : theme.palette.background.paper,
        color: highlight ? "white" : "inherit",
        borderRadius: 4,
        boxShadow: highlight 
          ? `0 8px 32px ${theme.palette.primary.main}40`
          : `0 8px 32px ${theme.palette.grey[300]}40`,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          // transform: "translateY(-4px)",
          boxShadow: highlight 
            ? `0 12px 48px ${theme.palette.primary.main}60`
            : `0 12px 48px ${theme.palette.grey[300]}60`,
        },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: highlight ? "white" : theme.palette.primary.main,
          mb: 1,
          height: "3.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFeatureSettings: "'tnum' on, 'lnum' on",
          fontFamily: "monospace",
        }}
      >
        <CountingNumber value={numericValue} formatter={formatter} />
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: highlight ? "rgba(255,255,255,0.9)" : theme.palette.text.secondary,
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          fontWeight: "medium",
        }}
      >
        {label}
      </Typography>
    </Paper>
  );
};

const TimesheetStats: React.FC<TimesheetStatsProps> = ({ timesheets }) => {
  const { projectAssignments } = useFreelancerProjectAssignments();

  const stats = useMemo(() => {
    const totalHours = timesheets.reduce(
      (sum, timesheet) => sum + timesheet.duration / 3600,
      0
    );
    const totalEarnings = timesheets.reduce((sum, timesheet) => {
      const projectAssignment = projectAssignments?.find(
        (assignment) => assignment.id === timesheet.projectAssignmentId
      );
      return (
        sum +
        (timesheet.duration / 3600) * (projectAssignment?.billableRate ?? 0)
      );
    }, 0);
    const averageRate = totalHours > 0 ? totalEarnings / totalHours : 0;

    return {
      totalHours,
      totalEarnings,
      averageRate,
    };
  }, [timesheets, projectAssignments]);

  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            value={stats.totalEarnings}
            label="Total Earnings"
            highlight
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            value={stats.totalHours}
            label="Total Hours"
            highlight
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            value={stats.averageRate}
            label="Average Rate"
            highlight
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimesheetStats;
