import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
  User,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { getAuthUserProfile } from "./getAuthUserProfile";
import { PrivateUserInfoType } from "@freetech/models/user";
import { privateUserConverter } from "@freetech/models/converters";
import { serverTimestamp } from "firebase/firestore";
import { FirebaseError } from "firebase/app";
import { MultiFactorError } from "@firebase/auth";
// import { detectIncognitoMode } from "./detectIncognitoMode";

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const handleLoginWithGoogle = async (
  setUserInfo: React.Dispatch<React.SetStateAction<PrivateUserInfoType>>
): Promise<MultiFactorError | FirebaseError | void> => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  let lastError: any;

  // Check for incognito mode
  // const isIncognito = await detectIncognitoMode();
  // if (isIncognito) {
  //   throw new FirebaseError(
  //     "auth/browser-not-supported",
  //     "FreeTech does not support incognito mode or private browsing."
  //   );
  // }
  try {
    const { user } = await signInWithPopup(
      auth,
      provider,
      browserPopupRedirectResolver
    );

  let userProfile = await getAuthUserProfile(user);

  if (!userProfile && user.email) {
    // Create new user profile if it doesn't exist
    const newUserData: PrivateUserInfoType = {
      email: user.email,
      firstName: user.displayName?.split(" ")[0] || "",
      lastName: user.displayName?.split(" ").slice(1).join(" ") || "",
      role: "non-admin",
      disabled: false,
      emailVerified: user.emailVerified,
      permissions: [],
      documents: [],
      id: user.uid,
      displayName: user.displayName || "",
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      lastLoginAt: serverTimestamp(),
      profilePicture: user.photoURL || "",
    };

    await setDoc(
      doc(db, `users/${user.uid}`).withConverter(privateUserConverter),
      newUserData
    );
    userProfile = await getAuthUserProfile(user);
  }

  if (!userProfile) {
    throw new Error("Failed to get or create user profile");
  }

    setUserInfo(userProfile);
  } catch (error: any) {
    lastError = error;

    // Don't retry for these specific errors
    if (
      error?.code === "auth/multi-factor-auth-required" ||
      error?.code === "auth/popup-closed-by-user" ||
      error?.code === "auth/cancelled-popup-request" ||
      error?.code === "auth/user-disabled" ||
    error?.code === "auth/account-exists-with-different-credential" ||
    error?.code === "auth/browser-not-supported" ||
    (error?.message &&
      (error.message.includes("ReCAPTCHA") ||
        error.message.includes("AppCheck")))
  ) {
    throw error;
  }

  // Only retry on internal errors or network-related issues
  if (
    error?.code !== "auth/internal-error" &&
    !error?.message?.toLowerCase().includes("network") &&
      !error?.message?.toLowerCase().includes("deadline exceeded")
    ) {
      throw error;
    }
  }
};
