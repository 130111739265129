import React, { useMemo } from "react";
import { Box, Paper, Typography, useTheme, Grid } from "@mui/material";
import { LineChart, BarChart } from "@mui/x-charts";
import { SeriesValueFormatter } from "@mui/x-charts/models/seriesType/common";
import { DateTime } from "luxon";
import { Timesheet } from "@freetech/models/timesheet";
import { formatDuration } from "core/timesheet/utils";
import { useFreelancerRoleClients } from "hooks/freelancer";
interface TimesheetTrendsProps {
  timesheets: Timesheet[];
}

interface DataPoint {
  week?: string;
  date?: string;
  hours?: number;
  total?: number;
  duration: string;
}

const ProjectCounter: React.FC<{
  name: string;
  hours: number;
  totalHours: number;
}> = ({ name, hours, totalHours }) => {
  const theme = useTheme();
  const percentage = (hours / totalHours) * 100;

  return (
    <Box sx={{ mb: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
        <Typography variant="body2" sx={{ fontWeight: "medium" }}>
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {hours.toFixed(1)}h ({percentage.toFixed(1)}%)
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: 6,
          bgcolor: theme.palette.grey[200],
          borderRadius: 3,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: `${percentage}%`,
            height: "100%",
            bgcolor: theme.palette.primary.main,
            transition: "width 0.5s ease-in-out",
          }}
        />
      </Box>
    </Box>
  );
};

export const TimesheetTrends: React.FC<TimesheetTrendsProps> = ({
  timesheets,
}) => {
  const theme = useTheme();

  const { clients } = useFreelancerRoleClients();

  // Convert seconds to hours with 2 decimal places
  const secondsToHours = (seconds: number) =>
    Number((seconds / 3600).toFixed(2));

  // Format hours for display
  const formatHours = (hours: number) => `${hours}h`;

  // Format tooltip for charts
  const formatTooltip: SeriesValueFormatter<number | null> = (value) => {
    if (value === null) return "0h";
    return formatHours(value);
  };

  // Calculate weekly hours trend
  const weeklyTrend = useMemo(() => {
    const weeklyHours = timesheets.reduce(
      (acc, timesheet) => {
        const weekStart = DateTime.fromISO(timesheet.date)
          .startOf("week")
          .toFormat("MM/dd");
        acc[weekStart] =
          (acc[weekStart] || 0) + secondsToHours(timesheet.duration || 0);
        return acc;
      },
      {} as Record<string, number>
    );

    return [...Object.entries(weeklyHours)]
      .map(([week, hours]) => ({
        week,
        hours,
        duration: formatDuration(hours * 3600),
      }))
      .sort(
        (a, b) =>
          DateTime.fromFormat(a.week, "MM/dd").toMillis() -
          DateTime.fromFormat(b.week, "MM/dd").toMillis()
      );
  }, [timesheets]);

  // Calculate cumulative hours
  const cumulativeHours = useMemo(() => {
    let total = 0;
    return [...timesheets]
      .sort(
        (a, b) =>
          DateTime.fromISO(a.date).toMillis() -
          DateTime.fromISO(b.date).toMillis()
      )
      .map((timesheet) => {
        total += secondsToHours(timesheet.duration || 0);
        return {
          date: DateTime.fromISO(timesheet.date).toFormat("MM/dd"),
          total: Number(total.toFixed(2)),
          duration: formatDuration(total * 3600),
        };
      });
  }, [timesheets]);

  // Prepare data for hours by day graph
  const hoursByDay = useMemo(() => {
    // First group by date and client
    const dailyClientHours = timesheets.reduce(
      (acc, timesheet) => {
        const date = DateTime.fromISO(timesheet.date).toFormat("MM/dd");
        const clientName = clients.find((client) => client.id === timesheet.clientId)?.companyName || "Unknown Client";
        
        if (!acc[date]) acc[date] = {};
        acc[date][clientName] = (acc[date][clientName] || 0) + secondsToHours(timesheet.duration || 0);
        return acc;
      },
      {} as Record<string, Record<string, number>>
    );

    // Get unique clients
    const uniqueClients = new Set<string>();
    Object.values(dailyClientHours).forEach(dayData => {
      Object.keys(dayData).forEach(client => uniqueClients.add(client));
    });
    const clientsList = Array.from(uniqueClients);

    // Transform data for chart
    return {
      data: Object.entries(dailyClientHours)
        .map(([date, clientData]) => ({
          date,
          ...Object.fromEntries(clientsList.map(client => [client, clientData[client] || 0])),
        }))
        .sort((a, b) => DateTime.fromFormat(a.date, "MM/dd").toMillis() - DateTime.fromFormat(b.date, "MM/dd").toMillis()),
      clients: clientsList,
    };
  }, [timesheets, clients]);

  // Prepare data for hours by project graph
  const hoursByProject = useMemo(() => {
    const projectHours = timesheets.reduce(
      (acc, timesheet: Timesheet) => {
        const projectName =
          clients
            .find((client) => client.id === timesheet.clientId)
            ?.projects?.find((project) => project.id === timesheet.projectId)
            ?.name || "Unnamed Project";
        const clientName = clients.find((client) => client.id === timesheet.clientId)?.companyName || "Unknown Client";
        const key = `${projectName} (${clientName})`;
        acc[key] = (acc[key] || 0) + secondsToHours(timesheet.duration || 0);
        return acc;
      },
      {} as Record<string, number>
    );

    return Object.entries(projectHours)
      .map(([name, hours]) => ({
        name,
        hours,
        duration: formatDuration(hours * 3600),
      }))
      .sort((a, b) => b.hours - a.hours);
  }, [timesheets, clients]);

  // Calculate weekly hours by project trend
  const weeklyProjectTrend = useMemo(() => {
    const weeklyHours = timesheets.reduce((acc, timesheet) => {
      const weekStart = DateTime.fromISO(timesheet.date).startOf('week').toFormat('MM/dd');
      const projectName =
        clients
          .find((client) => client.id === timesheet.clientId)
          ?.projects?.find((project) => project.id === timesheet.projectId)
          ?.name || "Unnamed Project";
      const clientName = clients.find((client) => client.id === timesheet.clientId)?.companyName || "Unknown Client";
      const key = `${projectName} (${clientName})`;
      
      if (!acc[weekStart]) acc[weekStart] = {};
      acc[weekStart][key] = (acc[weekStart][key] || 0) + secondsToHours(timesheet.duration || 0);
      return acc;
    }, {} as Record<string, Record<string, number>>);

    const projects = new Set<string>();
    Object.values(weeklyHours).forEach(weekData => {
      Object.keys(weekData).forEach(project => projects.add(project));
    });

    return {
      weeks: Object.keys(weeklyHours).sort((a, b) => 
        DateTime.fromFormat(a, 'MM/dd').toMillis() - DateTime.fromFormat(b, 'MM/dd').toMillis()
      ),
      projects: Array.from(projects),
      data: Object.entries(weeklyHours).map(([week, data]) => ({
        week,
        ...Object.fromEntries(Array.from(projects).map(project => [project, data[project] || 0]))
      }))
    };
  }, [timesheets, clients]);

  // Calculate cumulative hours by project
  const cumulativeProjectHours = useMemo(() => {
    const projectTotals: Record<string, number> = {};
    
    return [...timesheets]
      .sort((a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis())
      .map((timesheet) => {
        const projectName =
          clients
            .find((client) => client.id === timesheet.clientId)
            ?.projects?.find((project) => project.id === timesheet.projectId)
            ?.name || "Unnamed Project";
        const clientName = clients.find((client) => client.id === timesheet.clientId)?.companyName || "Unknown Client";
        const key = `${projectName} (${clientName})`;
        
        projectTotals[key] = (projectTotals[key] || 0) + secondsToHours(timesheet.duration || 0);
        
        return {
          date: DateTime.fromISO(timesheet.date).toFormat('MM/dd'),
          ...projectTotals
        };
      });
  }, [timesheets, clients]);

  return (
    <Grid container spacing={2}>
      {/* Hours by Day Chart */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            height: "100%",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Hours by Day
          </Typography>
          <Box sx={{ width: "100%", height: 350 }}>
            <BarChart
              dataset={hoursByDay.data}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "date",
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: "start",
                    fontSize: 12,
                  },
                },
              ]}
              series={hoursByDay.clients.map((client) => ({
                dataKey: client,
                label: client,
                valueFormatter: formatTooltip,
                stackStrategy: "stack",
              }))}
              slotProps={{
                legend: {
                  hidden: false,
                  direction: 'row',
                  position: { vertical: 'top', horizontal: 'middle' },
                  padding: 0,
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>

      {/* Hours by Project Counter */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Hours by Project
          </Typography>
          <Box sx={{ mt: 2, flex: 1, overflow: "auto" }}>
            {hoursByProject.map((project) => (
              <ProjectCounter
                key={project.name}
                name={project.name}
                hours={project.hours}
                totalHours={hoursByProject.reduce((sum, p) => sum + p.hours, 0)}
              />
            ))}
          </Box>
        </Paper>
      </Grid>

      {/* Weekly Hours by Project Trend */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            height: "100%",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Weekly Hours by Project
          </Typography>
          <Box sx={{ width: "100%", height: 350 }}>
            <LineChart
              dataset={weeklyProjectTrend.data}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "week",
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: "start",
                    fontSize: 12,
                  },
                },
              ]}
              series={weeklyProjectTrend.projects.map(project => ({
                dataKey: project,
                label: project,
                valueFormatter: formatTooltip,
                area: true,
              }))}
              slotProps={{
                legend: {
                  hidden: false,
                  direction: 'row',
                  position: { vertical: 'top', horizontal: 'middle' },
                  padding: 0,
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>

      {/* Cumulative Hours by Project */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            height: "100%",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Cumulative Hours by Project
          </Typography>
          <Box sx={{ width: "100%", height: 350 }}>
            <LineChart
              dataset={cumulativeProjectHours}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "date",
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: "start",
                    fontSize: 12,
                  },
                },
              ]}
              series={Object.keys(cumulativeProjectHours[cumulativeProjectHours.length - 1] || {})
                .filter(key => key !== 'date')
                .map(project => ({
                  dataKey: project,
                  label: project,
                  valueFormatter: formatTooltip,
                  area: true,
                  showMark: false,
                }))}
              slotProps={{
                legend: {
                  hidden: false,
                  direction: 'row',
                  position: { vertical: 'top', horizontal: 'middle' },
                  padding: 0,
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
