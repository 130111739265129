import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { AICommand } from './AICommandsExtension';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';

export interface CommandSuggestionProps {
  items: AICommand[];
  command: (props: { command: AICommand }) => void;
}

export interface CommandSuggestionRef {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
}

export const CommandSuggestion = forwardRef<CommandSuggestionRef, CommandSuggestionProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];
    if (item) {
      console.log('Selecting item:', item);
      props.command({ command: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  // Log props for debugging
  useEffect(() => {
    console.log('CommandSuggestion props:', props);
    console.log('CommandSuggestion items:', props.items);
  }, [props]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      console.log('CommandSuggestion onKeyDown:', event.key);
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Paper elevation={3} sx={{ width: 320, maxHeight: 400, overflow: 'auto' }}>
      <List dense>
        {props.items && props.items.length > 0 ? (
          props.items.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                selected={index === selectedIndex}
                onClick={() => selectItem(index)}
                sx={{ 
                  py: 1,
                  '&.Mui-selected': {
                    backgroundColor: 'primary.light',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                  },
                }}
              >
                {item.icon && (
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <Box component="span" sx={{ fontSize: '1.2rem' }}>
                      {item.icon}
                    </Box>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <Typography variant="body1" fontWeight={index === selectedIndex ? 'bold' : 'normal'}>
                      {item.title}
                    </Typography>
                  }
                  secondary={item.description}
                />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No commands found" />
          </ListItem>
        )}
      </List>
    </Paper>
  );
});

export const CommandSuggestionPlugin = () => {
  console.log('CommandSuggestionPlugin created');
  
  return {
    items: ({ query }: { query: string }) => {
      console.log('CommandSuggestionPlugin items called with query:', query);
      // This function is not used because the items are provided by the AICommands extension
      return [];
    },

    render: () => {
      console.log('CommandSuggestionPlugin render called');
      let component: ReactRenderer<CommandSuggestionRef>;
      let popup: any[] = [];

      return {
        onStart: (props: any) => {
          console.log('CommandSuggestionPlugin onStart called with props:', props);
          
          try {
            component = new ReactRenderer(CommandSuggestion, {
              props,
              editor: props.editor,
            });

            if (!props.clientRect) {
              console.warn('CommandSuggestionPlugin: Missing clientRect');
              return;
            }

            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
            
            console.log('CommandSuggestionPlugin: Popup created', popup);
          } catch (error) {
            console.error('CommandSuggestionPlugin: Error in onStart', error);
          }
        },

        onUpdate(props: any) {
          console.log('CommandSuggestionPlugin onUpdate called with props:', props);
          
          try {
            component.updateProps(props);

            if (!props.clientRect) {
              console.warn('CommandSuggestionPlugin: Missing clientRect in onUpdate');
              return;
            }

            if (popup && popup[0]) {
              popup[0].setProps({
                getReferenceClientRect: props.clientRect,
              });
            } else {
              console.warn('CommandSuggestionPlugin: Popup not available in onUpdate');
            }
          } catch (error) {
            console.error('CommandSuggestionPlugin: Error in onUpdate', error);
          }
        },

        onKeyDown(props: any) {
          console.log('CommandSuggestionPlugin onKeyDown called with props:', props);
          
          try {
            if (props.event.key === 'Escape') {
              if (popup && popup[0]) {
                popup[0].hide();
              }
              return true;
            }

            return component.ref?.onKeyDown(props) || false;
          } catch (error) {
            console.error('CommandSuggestionPlugin: Error in onKeyDown', error);
            return false;
          }
        },

        onExit() {
          console.log('CommandSuggestionPlugin onExit called');
          
          try {
            if (popup && popup[0]) {
              popup[0].destroy();
            }
            component.destroy();
          } catch (error) {
            console.error('CommandSuggestionPlugin: Error in onExit', error);
          }
        },
      };
    },
  };
}; 