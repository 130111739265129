import { ProjectAssignment } from "@freetech/models/projects";

export const generateProjectAssignmentDisplayName = (
  assignment: ProjectAssignment
): string => {
  if (!assignment.projectName) {
    return `Assignment ${assignment.id}`;
  }

  //   let displayName = assignment.projectName;

  let displayName = assignment.id;
  // Add dates in parentheses if available
  if (assignment.startDate || assignment.endDate) {
    const dates = [];
    if (assignment.startDate) {
      dates.push(assignment.startDate);
    }
    if (assignment.endDate) {
      dates.push(assignment.endDate);
    }
    displayName += ` (${dates.join(" - ")})`;
  }

  return displayName;
};
