import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
  Stack,
  Paper,
  CircularProgress,
} from "@mui/material";
import { ConsultingServicesAgreement } from "@freetech/models/projects";
import { DateTime } from "luxon";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";

export const ActiveProjectsCard: React.FC = () => {
  const { currentUser } = useAuth();
  const { clients, isLoadingStakeholderProfile } = useStakeholder(currentUser?.uid);

  if (isLoadingStakeholderProfile) {
    return (
      <Paper
        elevation={0}
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
        }}
      >
        <CircularProgress />
      </Paper>
    );
  }

  const activeProjects =
    clients?.flatMap((client) =>
      (client.projects || [])
        .filter((project) => project.status === "active")
        .map((project) => ({
          ...project,
          clientName: client.companyName,
          consultingServicesAgreements: client.consultingServicesAgreements?.filter(
            csa => csa.projectId === project.id
          ) || []
        }))
    ) || [];

  if (!activeProjects.length) {
    return (
      <Paper
        elevation={0}
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          p: 3,
        }}
      >
        <Alert severity="info">
          No active projects found. Active projects and their budgets will appear
          here.
        </Alert>
      </Paper>
    );
  }

  const sortCSAs = (csas: ConsultingServicesAgreement[]) => {
    return [...csas].sort((a, b) => 
      new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime()
    );
  };

  const formatDate = (date: string) => {
    return DateTime.fromISO(date).toFormat("MMM d, yyyy");
  };

  const getBudgetAmount = (csa: ConsultingServicesAgreement) => {
    return csa.monthlyBudget || csa.fixedPriceBudget || 0;
  };

  return (
    <Paper elevation={0} sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', gap: 1 }}>
        <AccountBalanceIcon color="primary" />
        <Typography variant="h6">Active Projects</Typography>
      </Box>

      <List disablePadding>
        {activeProjects.map((project, index) => {
          const currentCSA = sortCSAs(project.consultingServicesAgreements || [])[0];
          return (
            <React.Fragment key={project.id}>
              {index > 0 && <Divider />}
              <ListItem sx={{ py: 2.5, px: 3 }}>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'flex-start',
                        gap: 2,
                        width: '100%'
                      }}>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                            {project.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {project.clientName}
                          </Typography>
                        </Box>
                        {currentCSA && (
                          <Paper 
                            elevation={0}
                            sx={{ 
                              p: 1.5,
                              bgcolor: 'primary.main',
                              borderRadius: 2,
                              flex: '0 0 auto'
                            }}
                          >
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                color: 'primary.contrastText',
                                display: 'block',
                                mb: 0.5,
                                fontWeight: 500
                              }}
                            >
                              Current Budget
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                              <Typography 
                                variant="h6"
                                sx={{ color: 'primary.contrastText', fontWeight: 600 }}
                              >
                                ${getBudgetAmount(currentCSA).toLocaleString()}
                              </Typography>
                              <Typography 
                                variant="caption"
                                sx={{ color: 'primary.contrastText', opacity: 0.9 }}
                              >
                                {currentCSA.monthlyBudget ? 'per month' : 'fixed price'}
                              </Typography>
                            </Box>
                          </Paper>
                        )}
                      </Box>
                      {project.description && (
                        <Typography 
                          variant="body2" 
                          color="text.secondary" 
                          sx={{ 
                            bgcolor: 'grey.50', 
                            p: 1, 
                            borderRadius: 1,
                            border: 1,
                            borderColor: 'divider',
                            wordBreak: 'break-word'
                          }}
                        >
                          {project.description}
                        </Typography>
                      )}
                    </Box>
                  }
                />
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
};
