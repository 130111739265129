import { Box, Typography, Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Freelancer } from "@freetech/models/user";

interface Props {
  contractor: Freelancer;
}

export const ContractorHeader = ({ contractor }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box>


      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Box>
          <Typography variant="h4">
            {contractor.firstName} {contractor.lastName}
          </Typography>
          <Typography variant="body1">
            {contractor.email}
          </Typography>
          
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setAnchorEl(null)}>Active</MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>Inactive</MenuItem>
      </Menu>
    </Box>
  );
}; 