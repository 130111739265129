import {
  deleteClient,
  createClient,
  loadNestedClients,
  updateClient,
} from "core/admin/clients";
import {
  loadFreelancers,
  deleteProjectAssignment,
  uploadDocument,
  updateDocument,
  deleteDocument,
  updateProjectAssignment,
  loadNestedFreelancers,
} from "core/admin/freelancers";
import { userInfo } from "os";
import {
  DeleteProjectAssignmentParams,
  UpdateDocumentParams,
  UpdateProjectAssignmentParams,
  UploadDocumentParams,
  DeleteDocumentParams,
  UploadProfilePictureParams,
} from "types/admin";
import { getAuthUserProfiles } from "core/admin/users/getAuthUserProfiles";
import {
  updateUser,
  uploadProfilePicture,
  disableUser,
  enableUser,
} from "core/admin/users";
import { uploadIndependentEngineerAgreement } from "core/admin/freelancers";
import { getIndependentEngineerAgreement } from "core/freelancer";
import { Client } from "@freetech/models/projects";
import { CombinedUserData } from "@freetech/models/user";
import { UploadIndependentEngineerAgreementParams } from "types/admin";
import { deleteIndependentEngineerAgreement } from "core/admin/freelancers/deleteIndependentEngineerAgreement";
import {
  getInvoices,
  createInvoice,
  createAndSavePDF,
  deleteInvoice,
} from "core/invoices";
import { ClientInvoiceData } from "@freetech/models/bank";
import { getPrivateUserProfiles } from "core/admin/users/getPrivateUserProfile";
import { ICreateClient } from "@freetech/models";
import {
  loadNestedStakeholders,
  updateStakeholder,
} from "core/admin/stakeholders";

export const adminController = {
  users: {
    loadPrivateUsers: async () => {
      const users = await getPrivateUserProfiles({});
      return users;
    },
    loadPrivateUsersByIds: async (userIds: string[]) => {
      const users = await getPrivateUserProfiles({ userIds });
      return users;
    },
    loadPrivateUsersByRole: async (role: CombinedUserData["role"]) => {
      const users = await getPrivateUserProfiles({
        filters: { role },
      });
      return users;
    },
    loadAuthUserProfiles: async () => {
      const users = await getAuthUserProfiles();
      return users;
    },
    updateUser: async (user: CombinedUserData) => {
      const updatedUser = await updateUser(user);
      return updatedUser;
    },

    disableUser: async (userId: string) => {
      const response = await disableUser(userId);
      return response;
    },
    enableUser: async (userId: string) => {
      const response = await enableUser(userId);
      return response;
    },
    uploadProfilePicture: async (params: UploadProfilePictureParams) => {
      const uploadedPicture = await uploadProfilePicture(params);
      return uploadedPicture;
    },
  },
  clients: {
    loadNestedClients: async () => {
      const clients = await loadNestedClients();
      return clients;
    },
    createClient: async (clientToCreate: ICreateClient, currentUser: any) => {
      const newClient = await createClient(clientToCreate, currentUser);
      return newClient;
    },
    updateClient: async (clientToUpdate: Partial<Client>) => {
      const updatedClient = await updateClient(
        clientToUpdate.id!,
        clientToUpdate
      );
      return updatedClient;
    },
    deleteClient: async (clientId: string) => {
      const deletedClient = await deleteClient(clientId);
      return deletedClient;
    },
  },
  freelancers: {
    loadFreelancers: async () => {
      const freelancers = await loadFreelancers();
      return freelancers;
    },
    loadNestedFreelancers: async () => {
      const freelancers = await loadNestedFreelancers();
      return freelancers;
    },
    deleteProjectAssignment: async (params: DeleteProjectAssignmentParams) => {
      const deletedAssignment = await deleteProjectAssignment(params);
      return deletedAssignment;
    },
    updateProjectAssignment: async (params: UpdateProjectAssignmentParams) => {
      const updatedAssignment = await updateProjectAssignment(params);
      return updatedAssignment;
    },
    uploadDocument: async (params: UploadDocumentParams) => {
      const uploadedDocument = await uploadDocument(params);
      return uploadedDocument;
    },
    updateDocument: async (params: UpdateDocumentParams) => {
      const updatedDocument = await updateDocument(params);
      return updatedDocument;
    },
    deleteDocument: async (params: DeleteDocumentParams) => {
      const deletedDocument = await deleteDocument(params);
      return deletedDocument;
    },
    uploadIndependentEngineerAgreement: async (
      params: UploadIndependentEngineerAgreementParams
    ) => {
      const uploadedAgreement =
        await uploadIndependentEngineerAgreement(params);
      return uploadedAgreement;
    },
    getIndependentEngineerAgreement: async (freelancerId: string) => {
      const agreement = await getIndependentEngineerAgreement(freelancerId);
      return agreement;
    },
    deleteIndependentEngineerAgreement: async (freelancerId: string) => {
      const deletedAgreement =
        await deleteIndependentEngineerAgreement(freelancerId);
      return deletedAgreement;
    },
  },
  invoices: {
    getInvoices,
    createInvoice,
    createAndSavePDF,
    deleteInvoice,
  },
  stakeholders: {
    loadNestedStakeholders,
    updateStakeholder,
  },
  increase: async () => {
    return true;
  },
};
