import React from "react";
import { Route } from "react-router-dom";
import type { Navigation, NavigationPageItem } from "types/navigation";

export const mapPagesToReactRouter = (
  pages: Navigation,
  RouteComponent: React.ComponentType<any>
) => {
  return pages.map((page) => {
    if (page.kind === "header" || page.kind === "divider") {
      return null;
    }

    const typedPage = page as NavigationPageItem;

    if ("children" in typedPage && typedPage.children) {
      return typedPage.children.map((child) => {
        if (child.kind === "header" || child.kind === "divider") {
          return null;
        }

        const typedChild = child as NavigationPageItem;

        if (typedChild.component) {
          const basePath = `${typedPage.segment}/${typedChild.segment}`;
          return (
            <React.Fragment key={basePath}>
              <Route
                path={basePath}
                element={
                  <RouteComponent>{typedChild.component}</RouteComponent>
                }
              />
              {typedChild.slugBabyPattern && typedChild.slugBabyComponent && (
                <React.Fragment>
                  <Route
                    path={`${basePath}/${typedChild.slugBabyPattern}`}
                    element={
                      <RouteComponent>
                        {typedChild.slugBabyComponent}
                      </RouteComponent>
                    }
                  />
                  {typedChild.slugGrandBabyPattern &&
                    typedChild.slugGrandBabyComponent && (
                      <Route
                        path={`${basePath}/${typedChild.slugBabyPattern}/${typedChild.slugGrandBabyPattern}`}
                        element={
                          <RouteComponent>
                            {typedChild.slugGrandBabyComponent}
                          </RouteComponent>
                        }
                      />
                    )}
                  {typedChild.slugGrandBabyBrotherPattern &&
                    typedChild.slugGrandBabyBrotherComponent && (
                      <Route
                        path={`${basePath}/${typedChild.slugBabyPattern}/${typedChild.slugGrandBabyBrotherPattern}`}
                        element={
                          <RouteComponent>
                            {typedChild.slugGrandBabyBrotherComponent}
                          </RouteComponent>
                        }
                      />
                    )}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }
        return null;
      });
    }

    if (typedPage.component) {
      return (
        <React.Fragment key={typedPage.segment}>
          <Route
            path={`/${typedPage.segment}`}
            element={<RouteComponent>{typedPage.component}</RouteComponent>}
          />
          {typedPage.slugBabyPattern && typedPage.slugBabyComponent && (
            <React.Fragment>
              <Route
                path={`/${typedPage.segment}/${typedPage.slugBabyPattern}`}
                element={
                  <RouteComponent>{typedPage.slugBabyComponent}</RouteComponent>
                }
              />
              {typedPage.slugGrandBabyPattern &&
                typedPage.slugGrandBabyComponent && (
                  <Route
                    path={`/${typedPage.segment}/${typedPage.slugBabyPattern}/${typedPage.slugGrandBabyPattern}`}
                    element={
                      <RouteComponent>
                        {typedPage.slugGrandBabyComponent}
                      </RouteComponent>
                    }
                  />
                )}
              {typedPage.slugGrandBabyBrotherPattern &&
                typedPage.slugGrandBabyBrotherComponent && (
                  <Route
                    path={`/${typedPage.segment}/${typedPage.slugBabyPattern}/${typedPage.slugGrandBabyBrotherPattern}`}
                    element={
                      <RouteComponent>
                        {typedPage.slugGrandBabyBrotherComponent}
                      </RouteComponent>
                    }
                  />
                )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }

    return null;
  });
};
