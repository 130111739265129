import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  alpha,
  ToggleButtonGroup,
  ToggleButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { DatePicker } from "@mui/x-date-pickers-pro";
import AddIcon from "@mui/icons-material/Add";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import TimerIcon from "@mui/icons-material/Timer";
import EditIcon from "@mui/icons-material/EditCalendar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useAuth } from "hooks/auth/useAuth";
import { DateTime } from "luxon";
import { calculateDuration, formatDuration } from "core/timesheet/utils";
import { useLocalStorageStringState } from "hooks/useLocalStorage";
import { useTheme } from "@mui/material/styles";
import TipTapEditor from "components/TipTap/TipTapEditor";
import {
  formatTime,
  isValidDuration,
  updateDateTimeFields,
  getCurrentTimeEntry,
} from "core/timesheet/utils/timeEntryUtils";
import { prepareTimeEntryData } from "core/timesheet/utils/prepareTimeEntryData";
import { validateTimeEntry } from "core/timesheet/utils/validateTimeEntry";
import { Timesheet } from "@freetech/models/timesheet";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { useTimesheet } from "hooks/timesheet/useTimesheet";

interface MinimumTimeDialogProps {
  open: boolean;
  onClose: () => void;
  duration: string;
}

const MinimumTimeDialog: React.FC<MinimumTimeDialogProps> = ({
  open,
  onClose,
  duration,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: "error.main",
        }}
      >
        <WarningAmberIcon color="error" />
        Invalid Time Duration
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          The time entry duration ({duration}) is less than one minute.
        </Typography>
        <Typography variant="body2">
          Please ensure that time entries are at least one minute long.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AdminTimeTrackerInput: React.FC = () => {
  const { userInfo } = useAuth();
  const theme = useTheme();

  if (!userInfo?.id) {
    throw new Error("User must be logged in to use time tracker");
  }

  const { clients, isLoading: isClientsLoading } = useAdminClients();
  const {
    timesheet,
    createTimesheet,
    startTimer,
    stopTimer,
    timerValue,
    isTimerActive,
    isSaving,
    updateTimesheet,
  } = useTimesheet(userInfo.id);

  const [selectedClient, setSelectedClient] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [view, setView] = useLocalStorageStringState(
    "adminTimeTrackingMethod",
    "manual"
  );
  const [newEntry, setNewEntry] = useState<Partial<Timesheet>>({
    description: "",
    longDescription: "",
    date: DateTime.now().toISODate(),
    startTime: DateTime.now().toISO(),
    endTime: DateTime.now().toISO(),
    billable: true,
    submitted: false,
    isAdmin: true,
  });
  const [isTimedEntry, setIsTimedEntry] = useState(view === "timer");
  const [expandedNotes, setExpandedNotes] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);
  const [manualDuration, setManualDuration] = useState<string>("00:00:00");
  const [showMinTimeDialog, setShowMinTimeDialog] = useState(false);

  const updateManualDuration = useCallback(() => {
    if (newEntry.startTime && newEntry.endTime) {
      const durationInSeconds = calculateDuration(
        newEntry.startTime,
        newEntry.endTime
      );
      setManualDuration(formatDuration(durationInSeconds));
    }
  }, [newEntry.startTime, newEntry.endTime]);

  useEffect(() => {
    if (!isTimedEntry) {
      updateManualDuration();
    }
  }, [isTimedEntry, updateManualDuration]);

  const handleInputChange = (field: keyof Timesheet, value: any) => {
    setNewEntry((prev) => {
      const updatedEntry = { ...prev, [field]: value };

      if (field === "date") {
        const { startTime, endTime } = updateDateTimeFields(
          value,
          prev.startTime || "",
          prev.endTime || ""
        );
        updatedEntry.startTime = startTime;
        updatedEntry.endTime = endTime;
      }

      if (
        (field === "description" || field === "longDescription") &&
        timesheet?.id
      ) {
        updateTimesheet.mutateAsync({
          id: timesheet.id,
          description: field === "description" ? value : prev.description,
          longDescription:
            field === "longDescription" ? value : prev.longDescription,
        });
      }

      return updatedEntry;
    });

    if (field === "startTime" || field === "endTime" || field === "date") {
      updateManualDuration();
    }
  };

  const resetEntryToCurrentTime = useCallback(() => {
    const { date, startTime, endTime } = getCurrentTimeEntry();
    setNewEntry((prev) => ({
      ...prev,
      description: "",
      longDescription: "",
      date,
      startTime,
      endTime,
      billable: true,
      submitted: false,
      isAdmin: true,
      clientId: undefined,
      projectId: undefined,
    }));
    setManualDuration("00:00:00");
  }, []);

  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    setSelectedProject("");
    setNewEntry((prev) => ({
      ...prev,
      clientId: clientId,
      projectId: "",
    }));
  };

  const handleProjectChange = (event: SelectChangeEvent<string>) => {
    const projectId = event.target.value;
    setSelectedProject(projectId);
    setNewEntry((prev) => ({
      ...prev,
      projectId: projectId,
    }));
  };

  const clearError = () => {
    setError(null);
    setShowError(false);
  };

  const handleAddEntry = async () => {
    const validation = validateTimeEntry(newEntry);

    if (!validation.isValid) {
      if (validation.isBelowMinDuration) {
        setShowMinTimeDialog(true);
        return;
      }

      setError(validation.error || "Invalid entry");
      setShowError(true);
      return;
    }

    if (!userInfo?.id) {
      throw new Error("User must be logged in to use time tracker");
    }

    console.log("newEntry", newEntry);

    try {
      // Create a clean entry object with only the fields we need
      const cleanEntry: Partial<Timesheet> = {
        description: newEntry.description,
        longDescription: newEntry.longDescription,
        date: newEntry.date,
        startTime: newEntry.startTime,
        endTime: newEntry.endTime,
        isAdmin: true, // Explicitly set isAdmin for admin entries
        clientId: selectedClient,
        projectId: selectedProject,
        billable: newEntry.billable,
        submitted: false,
      };

      const timeEntryData = prepareTimeEntryData({
        entry: cleanEntry,
        userId: userInfo.id,
        selectedClient,
        selectedProject,
      });

      await createTimesheet.mutateAsync(timeEntryData);
      resetEntryToCurrentTime();
      clearError();
    } catch (error) {
      console.error("Failed to add timesheet entry:", error);
      setError("Failed to add timesheet entry. Please try again.");
      setShowError(true);
    }
  };

  const handleTimerOrManualEntryChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null && newView !== view) {
      setIsTimedEntry(newView === "timer");
      if (isTimerActive) {
        handleStopTimer();
      }
      setView(newView);
    }
  };

  const handleStartTimer = async () => {
    if (!selectedClient || !selectedProject) {
      setError("Please select both a client and project");
      setShowError(true);
      return;
    }

    if (!newEntry.date || !newEntry.startTime || !newEntry.endTime) {
      setError("Date and time fields are required");
      setShowError(true);
      return;
    }

    try {
      const entryWithClient = {
        description: newEntry.description || "",
        longDescription: newEntry.longDescription || "",
        date: newEntry.date,
        startTime: newEntry.startTime,
        endTime: newEntry.endTime,
        duration: newEntry.duration || 0,
        isAdmin: true,
        clientId: selectedClient,
        projectId: selectedProject,
        billable: newEntry.billable ?? true,
        submitted: newEntry.submitted ?? false,
      } as Omit<Timesheet, "id">;

      await startTimer(entryWithClient);
      clearError();
    } catch (error) {
      console.error("Error starting timer:", error);
      setError("Failed to start timer. Please try again.");
      setShowError(true);
    }
  };

  const handleStopTimer = async () => {
    try {
      if (!timesheet?.id) {
        throw new Error("No active timesheet");
      }

      const result = await stopTimer();
      if (result) {
        await updateTimesheet.mutateAsync({
          id: timesheet.id,
          description: newEntry.description,
        });
      }
    } catch (error) {
      console.error("Error stopping timer:", error);
      setError("Failed to stop timer");
      setShowError(true);
    }
  };

  const inputHeight = 56;

  const filteredProjects = useMemo(() => {
    if (!clients) return [];
    return (
      clients.filter((client) => client.id === selectedClient)[0]?.projects ||
      []
    );
  }, [clients, selectedClient]);

  const isActionButtonDisabled = useMemo(() => {
    if (isSaving) return true;
    if (!selectedClient || !selectedProject) return true;
    return false;
  }, [isSaving, selectedClient, selectedProject]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 1, sm: 2 },
          mb: 2,
          position: "sticky",
          top: { xs: 0, sm: 80 },
          zIndex: 1000,
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          {/* Selection Controls Row */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              width: "100%",
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Client</InputLabel>
              <Select
                value={selectedClient}
                onChange={handleClientChange}
                label="Client"
                disabled={isTimerActive || isClientsLoading}
              >
                {clients?.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Project</InputLabel>
              <Select
                value={selectedProject}
                onChange={handleProjectChange}
                label="Project"
                disabled={isTimerActive || !selectedClient}
              >
                {filteredProjects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Time Entry Row */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              width: "100%",
            }}
          >
            {!isTimedEntry ? (
              <>
                {/* Manual Entry Mode */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 2,
                    flex: 1,
                  }}
                >
                  <TextField
                    label="Description"
                    value={newEntry.description || ""}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    multiline
                    rows={2}
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-root": {
                        height: inputHeight,
                        alignItems: "flex-start",
                        padding: "8px 14px",
                        "& textarea": {
                          height: "100% !important",
                          overflow: "auto !important",
                        },
                      },
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexShrink: 0,
                      minWidth: { sm: "400px" },
                    }}
                  >
                    <DatePicker
                      label="Date"
                      value={
                        newEntry.date ? DateTime.fromISO(newEntry.date) : null
                      }
                      onChange={(newValue) => {
                        if (newValue) {
                          const date = newValue as DateTime;
                          handleInputChange("date", date.toISODate());
                        }
                      }}
                      sx={{
                        width: "150px",
                        "& .MuiInputBase-root": { height: inputHeight },
                      }}
                    />

                    <MultiInputTimeRangeField
                      value={[
                        newEntry.startTime
                          ? DateTime.fromISO(newEntry.startTime)
                          : null,
                        newEntry.endTime
                          ? DateTime.fromISO(newEntry.endTime)
                          : null,
                      ]}
                      onChange={(newValue) => {
                        const [start, end] = newValue;
                        if (start && end) {
                          handleInputChange("startTime", start.toISO());
                          handleInputChange("endTime", end.toISO());
                        }
                      }}
                      sx={{
                        width: "230px",
                        "& .MuiInputBase-root": { height: inputHeight },
                      }}
                      ampm={false}
                      format="HH:mm"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              // Timer Mode
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flex: 1,
                }}
              >
                <TextField
                  label="Description"
                  value={newEntry.description || ""}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                  sx={{
                    flex: 1,
                    "& .MuiInputBase-root": { height: inputHeight },
                  }}
                />
              </Box>
            )}

            {/* Action Controls */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                flexShrink: 0,
              }}
            >
              <IconButton
                onClick={() =>
                  handleInputChange("billable", !newEntry.billable)
                }
                sx={{
                  width: inputHeight,
                  height: inputHeight,
                  border: "1px solid",
                  borderColor: "divider",
                  color: newEntry.billable
                    ? theme.palette.success.main
                    : theme.palette.text.secondary,
                }}
              >
                {newEntry.billable ? <AttachMoneyIcon /> : <MoneyOffIcon />}
              </IconButton>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                  p: 1,
                  width: "110px",
                  height: inputHeight,
                }}
              >
                <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                  {isTimedEntry ? "Timer" : "Duration"}
                </Typography>
                <Typography variant="h6">
                  {isTimedEntry ? timerValue : manualDuration}
                </Typography>
              </Box>

              <Button
                variant="contained"
                startIcon={
                  isTimedEntry ? (
                    isTimerActive ? (
                      isSaving ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <StopIcon />
                      )
                    ) : (
                      <PlayArrowIcon />
                    )
                  ) : (
                    <AddIcon />
                  )
                }
                onClick={() => {
                  if (isTimedEntry) {
                    isTimerActive ? handleStopTimer() : handleStartTimer();
                  } else {
                    handleAddEntry();
                  }
                }}
                color={isTimedEntry && isTimerActive ? "secondary" : "primary"}
                disabled={isActionButtonDisabled}
                sx={{
                  width: "110px",
                  height: inputHeight,
                }}
              >
                {isTimedEntry
                  ? isTimerActive
                    ? isSaving
                      ? "Saving..."
                      : "Stop"
                    : "Start"
                  : "Add"}
              </Button>

              <ToggleButtonGroup
                orientation="vertical"
                value={view}
                exclusive
                onChange={handleTimerOrManualEntryChange}
                sx={{
                  height: inputHeight,
                  "& .MuiToggleButton-root": {
                    padding: "4px",
                  },
                }}
              >
                <ToggleButton value="manual" aria-label="manual" size="small">
                  <EditIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="timer" aria-label="timer" size="small">
                  <TimerIcon fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Accordion
        expanded={expandedNotes}
        onChange={() => setExpandedNotes(!expandedNotes)}
        sx={{
          mb: 2,
          boxShadow: "none",
          "&:before": { display: "none" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography>Long Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              overflow: "hidden",
              opacity: isTimerActive ? 0.5 : 1,
              pointerEvents: isTimerActive ? "none" : "auto",
            }}
          >
            <TipTapEditor
              content={newEntry.longDescription || ""}
              onChange={(value) => handleInputChange("longDescription", value)}
              placeholder="Add additional details here..."
              minHeight={200}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <MinimumTimeDialog
        open={showMinTimeDialog}
        onClose={() => setShowMinTimeDialog(false)}
        duration={isTimedEntry ? timerValue : manualDuration}
      />

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => setShowError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};
