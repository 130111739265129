import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Chip,
  IconButton,
  Collapse,
  Grid,
  Alert,
  TablePagination,
  Skeleton,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Card,
  Divider,
  alpha,
  Button,
  Menu,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FilterListIcon from '@mui/icons-material/FilterList';
import RestoreIcon from '@mui/icons-material/Restore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UndoIcon from '@mui/icons-material/Undo';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import { useAdminAuditLog, AuditLogEntry } from 'hooks/admin/useAdminAuditLog';
import { DateTime } from 'luxon';
import { collection, getFirestore, query, where, orderBy, getDocs, onSnapshot } from 'firebase/firestore';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const db = getFirestore();

interface HistoryEntry {
  id: string;
  documentId: string;
  timestamp: any;
  alphas?: Record<string, any>;
  deltas?: Record<string, any>;
  fullData?: Record<string, any>;
  modifiedBy: string;
  collection: string;
  type: 'update' | 'create' | 'delete';
  identifier: string;
  docId: string;
}

const AuditLog: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const {
    auditLogs,
    isLoading,
    isError,
    searchTerm,
    setSearchTerm,
    filterCollection,
    setFilterCollection,
    filterType,
    setFilterType,
    uniqueCollections,
    refetch,
    revertChange
  } = useAdminAuditLog();
  
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  // Context menu state
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    logId: string;
  } | null>(null);
  
  // Action menu state
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLogId, setSelectedLogId] = useState<string | null>(null);
  
  // Dialog states
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState<AuditLogEntry | null>(null);
  
  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  // Handle context menu open
  const handleContextMenu = (event: React.MouseEvent, logId: string) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            logId
          }
        : null,
    );
  };

  // Handle context menu close
  const handleContextMenuClose = () => {
    setContextMenu(null);
  };
  
  // Handle action menu open
  const handleActionMenuOpen = (event: React.MouseEvent<HTMLElement>, logId: string) => {
    event.stopPropagation();
    setActionMenuAnchorEl(event.currentTarget);
    setSelectedLogId(logId);
  };
  
  // Handle action menu close
  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
    setSelectedLogId(null);
  };
  
  // Find log by ID
  const getLogById = (id: string): AuditLogEntry | undefined => {
    return auditLogs.find(log => log.id === id);
  };
  
  // Handle revert action
  const handleRevert = (logId: string) => {
    const log = getLogById(logId);
    if (log) {
      setSelectedLog(log);
      setConfirmDialogOpen(true);
    }
    handleContextMenuClose();
    handleActionMenuClose();
  };
  
  // Confirm dialog actions
  const handleConfirmAction = async () => {
    if (!selectedLog) return;
    
    try {
      await revertChange(selectedLog);
      setSnackbarMessage(`Successfully reverted changes to ${selectedLog.collection} document`);
      setSnackbarOpen(true);
      refetch();
    } catch (error) {
      setSnackbarMessage(`Error: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
      setSnackbarOpen(true);
    }
    
    setConfirmDialogOpen(false);
    setSelectedLog(null);
  };
  
  // Handle cancel dialog
  const handleCancelDialog = () => {
    setConfirmDialogOpen(false);
    setSelectedLog(null);
  };
  
  // Handle pagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setExpandedRows(new Set());
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setExpandedRows(new Set());
  };

  // Reset all filters
  const handleResetFilters = () => {
    setSearchTerm('');
    setFilterCollection(null);
    setFilterType(null);
    setPage(0);
  };

  const handleToggleRow = (id: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const getOperationColor = (type: string) => {
    switch (type) {
      case 'create': return 'success';
      case 'update': return 'primary';
      case 'delete': return 'error';
      default: return 'default';
    }
  };

  // Format the timestamp using Luxon
  const formatTimestamp = (timestamp: any) => {
    if (!timestamp) return 'N/A';
    
    try {
      // Convert Firebase timestamp to JavaScript Date
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      // Format with Luxon
      return DateTime.fromJSDate(date).toFormat('MMM dd, yyyy - HH:mm');
    } catch (error) {
      return 'Invalid date';
    }
  };
  
  // Get relative time for display in tooltips
  const getRelativeTime = (timestamp: any): string => {
    if (!timestamp) return '';
    
    try {
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      return DateTime.fromJSDate(date).toRelative() || '';
    } catch (error) {
      return '';
    }
  };

  // Paginated and filtered logs
  const displayedLogs = useMemo(() => {
    return auditLogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [auditLogs, page, rowsPerPage]);

  // Loading skeleton for table
  const renderSkeleton = () => (
    <TableBody>
      {Array.from(new Array(5)).map((_, index) => (
        <TableRow key={index}>
          <TableCell width="5%"><Skeleton variant="circular" width={24} height={24} /></TableCell>
          <TableCell><Skeleton variant="rounded" width={80} height={24} /></TableCell>
          <TableCell><Skeleton variant="text" width="80%" /></TableCell>
          <TableCell><Skeleton variant="text" width="60%" /></TableCell>
          <TableCell><Skeleton variant="text" width="70%" /></TableCell>
          <TableCell><Skeleton variant="text" width="90%" /></TableCell>
          <TableCell width="5%"><Skeleton variant="circular" width={24} height={24} /></TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const hasFilters = Boolean(searchTerm || filterCollection || filterType);

  return (
    <Box sx={{ padding: { xs: 1, md: 3 } }}>
      <Card sx={{ mb: 3, overflow: 'visible' }}>
        <Box sx={{ p: 2, pb: 1 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>Audit Log</Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            View and track all changes made to documents in the system
          </Typography>
        </Box>
        
        <Divider />
        
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Search"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setPage(0);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" color="action" />
                    </InputAdornment>
                  )
                }}
                placeholder="Search by identifier, collection, or ID"
                size="small"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Collection</InputLabel>
                <Select
                  value={filterCollection || ''}
                  label="Collection"
                  onChange={(e) => {
                    setFilterCollection(e.target.value === '' ? null : e.target.value);
                    setPage(0);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon fontSize="small" color="action" />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="">All Collections</MenuItem>
                  {uniqueCollections.map((collection: string) => (
                    <MenuItem key={collection} value={collection}>
                      {collection}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Operation Type</InputLabel>
                <Select
                  value={filterType || ''}
                  label="Operation Type"
                  onChange={(e) => {
                    setFilterType(e.target.value === '' ? null : e.target.value as 'update' | 'create' | 'delete' | null);
                    setPage(0);
                  }}
                >
                  <MenuItem value="">All Operations</MenuItem>
                  <MenuItem value="create">Create</MenuItem>
                  <MenuItem value="update">Update</MenuItem>
                  <MenuItem value="delete">Delete</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={2}>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
                <IconButton 
                  onClick={handleResetFilters} 
                  disabled={!hasFilters}
                  size="small" 
                  color="primary" 
                  sx={{ mr: 1 }}
                  title="Reset filters"
                >
                  <RestoreIcon />
                </IconButton>
                <IconButton 
                  onClick={() => refetch()} 
                  size="small" 
                  color="primary"
                  title="Refresh data"
                >
                  <RestoreIcon sx={{ transform: 'scaleX(-1)' }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        
        {isError ? (
          <Alert severity="error" sx={{ mx: 2, mb: 2 }}>Error loading audit logs. Please try refreshing the page.</Alert>
        ) : null}
        
        {!isLoading && auditLogs.length === 0 ? (
          <Alert severity="info" sx={{ mx: 2, mb: 2 }}>
            {hasFilters 
              ? 'No audit logs found matching your filters.' 
              : 'No audit logs found in the system.'}
          </Alert>
        ) : null}
        
        <TableContainer sx={{ 
          maxHeight: '600px',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: alpha(theme.palette.text.primary, 0.05),
          }
        }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="5%" sx={{ bgcolor: theme.palette.background.paper }}></TableCell>
                <TableCell sx={{ bgcolor: theme.palette.background.paper }}>Operation</TableCell>
                <TableCell sx={{ bgcolor: theme.palette.background.paper }}>Collection</TableCell>
                {!isMobile && <TableCell sx={{ bgcolor: theme.palette.background.paper }}>Identifier</TableCell>}
                <TableCell sx={{ bgcolor: theme.palette.background.paper }}>{isMobile ? 'By' : 'Modified By'}</TableCell>
                <TableCell sx={{ bgcolor: theme.palette.background.paper }}>{isMobile ? 'When' : 'Timestamp'}</TableCell>
                <TableCell width="5%" sx={{ bgcolor: theme.palette.background.paper }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? renderSkeleton() : (
              <TableBody>
                {displayedLogs.map((log: AuditLogEntry) => (
                  <React.Fragment key={log.id}>
                    <TableRow 
                      hover 
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.05),
                        },
                        ...(expandedRows.has(log.id) && {
                          backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          }
                        }),
                        ...(log.reverted && {
                          backgroundColor: alpha(theme.palette.warning.light, 0.15),
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.warning.light, 0.2),
                          }
                        })
                      }}
                      onClick={() => handleToggleRow(log.id)}
                      onContextMenu={(e) => handleContextMenu(e, log.id)}
                    >
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleRow(log.id);
                          }}
                        >
                          {expandedRows.has(log.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Chip 
                            label={log.type.toUpperCase()} 
                            color={getOperationColor(log.type)} 
                            size="small"
                            variant="outlined" 
                            sx={{
                              fontWeight: 'bold',
                              minWidth: '70px'
                            }}
                          />
                          {log.reverted && (
                            <Tooltip title="This change was reverted">
                              <Chip
                                icon={<HistoryIcon fontSize="small" />}
                                label="Reverted"
                                size="small"
                                variant="outlined"
                                color="warning"
                                sx={{ ml: 1, fontWeight: 'medium' }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'monospace' }}>{log.collection}</TableCell>
                      {!isMobile && (
                        <TableCell>
                          <Typography noWrap title={log.identifier || log.docId || 'N/A'} sx={{ maxWidth: 200 }}>
                            {log.identifier || log.docId || 'N/A'}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>{log.modifiedBy || 'Unknown'}</TableCell>
                      <TableCell title={getRelativeTime(log.timestamp)}>
                        {formatTimestamp(log.timestamp)}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Actions">
                          <IconButton
                            size="small"
                            onClick={(e) => handleActionMenuOpen(e, log.id)}
                            sx={{ ml: 1 }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={isMobile ? 6 : 7} style={{ padding: 0 }}>
                        <Collapse in={expandedRows.has(log.id)} timeout="auto" unmountOnExit>
                          <Box p={2} bgcolor={alpha(theme.palette.primary.main, 0.03)}>
                            <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                              <Box>
                                {!isMobile ? null : (
                                  <Box mb={2}>
                                    <Typography variant="subtitle2">Identifier:</Typography>
                                    <Typography variant="body2" fontFamily="monospace">
                                      {log.identifier || log.docId || 'N/A'}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                              <Box>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  startIcon={<UndoIcon />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRevert(log.id);
                                  }}
                                  disabled={log.reverted}
                                >
                                  {log.reverted ? 'Already Reverted' : 'Revert Changes'}
                                </Button>
                              </Box>
                            </Box>
                            
                            <Grid container spacing={2}>
                              {log.type === 'update' && (
                                <>
                                  <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                      Previous Values (Before Change):
                                    </Typography>
                                    <pre style={{ 
                                      overflow: 'auto', 
                                      maxHeight: '200px',
                                      fontSize: '0.75rem',
                                      padding: '12px',
                                      margin: 0,
                                      background: alpha(theme.palette.background.default, 0.8),
                                      borderRadius: '4px',
                                      border: `1px solid ${alpha(theme.palette.divider, 0.5)}`
                                    }}>
                                      {JSON.stringify(log.reverted ? log.alphas : log.deltas, null, 2) || 'No previous values available'}
                                    </pre>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                      New Values (After Change):
                                    </Typography>
                                    <pre style={{ 
                                      overflow: 'auto', 
                                      maxHeight: '200px',
                                      fontSize: '0.75rem',
                                      padding: '12px',
                                      margin: 0,
                                      background: alpha(theme.palette.background.default, 0.8),
                                      borderRadius: '4px',
                                      border: `1px solid ${alpha(theme.palette.divider, 0.5)}`
                                    }}>
                                      {JSON.stringify(log.reverted ? log.deltas : log.alphas, null, 2) || 'No changes available'}
                                    </pre>
                                  </Grid>
                                </>
                              )}
                              {(log.type === 'create' || log.type === 'delete') && (
                                <Grid item xs={12}>
                                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    {log.type === 'create' ? 'Created Document:' : 'Deleted Document:'}
                                  </Typography>
                                  <pre style={{ 
                                    overflow: 'auto', 
                                    maxHeight: '250px',
                                    fontSize: '0.75rem',
                                    padding: '12px',
                                    margin: 0,
                                    background: alpha(theme.palette.background.default, 0.8),
                                    borderRadius: '4px',
                                    border: `1px solid ${alpha(theme.palette.divider, 0.5)}`
                                  }}>
                                    {JSON.stringify(log.fullData || (log.type === 'delete' ? log.deltas : log.alphas), null, 2) || 'No document data available'}
                                  </pre>
                                </Grid>
                              )}
                            </Grid>
                            <Box mt={2}>
                              <Typography variant="caption" color="text.secondary" display="block">
                                Document ID: {log.docId}
                              </Typography>
                              <Typography variant="caption" color="text.secondary" display="block">
                                Log ID: {log.id}
                              </Typography>
                              {log.reverted && (
                                <Typography 
                                  variant="caption" 
                                  color="warning.dark" 
                                  display="block"
                                  sx={{ 
                                    mt: 1, 
                                    fontWeight: 'medium',
                                    bgcolor: alpha(theme.palette.warning.light, 0.15),
                                    p: 0.5,
                                    borderRadius: 0.5,
                                    display: 'inline-block'
                                  }}
                                >
                                  ⚠️ This change was reverted on {formatTimestamp(log.revertedAt)}
                                  {log.revertedBy && ` by ${log.revertedBy}`}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={auditLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      
      {/* Context Menu */}
      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem 
          onClick={() => contextMenu && handleRevert(contextMenu.logId)}
          dense
          disabled={!!(contextMenu && getLogById(contextMenu.logId)?.reverted)}
        >
          <UndoIcon fontSize="small" sx={{ mr: 1 }} />
          Revert Change
        </MenuItem>
      </Menu>
      
      {/* Action Menu */}
      <Menu
        anchorEl={actionMenuAnchorEl}
        open={Boolean(actionMenuAnchorEl)}
        onClose={handleActionMenuClose}
      >
        <MenuItem 
          onClick={() => selectedLogId && handleRevert(selectedLogId)}
          dense
          disabled={!!(selectedLogId && getLogById(selectedLogId)?.reverted)}
        >
          <UndoIcon fontSize="small" sx={{ mr: 1 }} />
          Revert Change
        </MenuItem>
      </Menu>
      
      {/* Confirm Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Revert
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to revert the changes to this {selectedLog?.collection} document?
            {selectedLog?.reverted && (
              <Box sx={{ mt: 2, p: 1, bgcolor: alpha(theme.palette.warning.light, 0.15), borderRadius: 1 }}>
                <Typography variant="body2" color="warning.dark" fontWeight="medium">
                  Note: This change has already been reverted.
                </Typography>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default AuditLog;
