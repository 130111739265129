import { useState, useEffect } from 'react';
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "config/firebase";
import { CardLabel } from "@freetech/models/freelo";

export const useLabels = (boardId: string) => {
  const [labels, setLabels] = useState<CardLabel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const labelsRef = collection(db, 'boards', boardId, 'labels');
    const labelsQuery = query(labelsRef);

    const unsubscribe = onSnapshot(
      labelsQuery,
      (snapshot) => {
        const labelsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as CardLabel[];
        setLabels(labelsList);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching labels:', err);
        setError(err as Error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [boardId]);

  return { labels, loading, error };
};
