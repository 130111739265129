import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useAuth } from "hooks/auth/useAuth";
import { PersonalInfo } from "./types";

interface CompleteProfileProps {
  onNext: (data: PersonalInfo) => void;
  initialData?: PersonalInfo;
}

export const CompleteProfile: React.FC<CompleteProfileProps> = ({
  onNext,
  initialData,
}) => {
  const { userInfo } = useAuth();
  const [formData, setFormData] = useState<PersonalInfo>({
    firstName: initialData?.firstName || userInfo?.firstName || "",
    lastName: initialData?.lastName || userInfo?.lastName || "",
    phone: initialData?.phone || userInfo?.phone || "",
    email: initialData?.email || userInfo?.email || "",
    displayName: initialData?.displayName || userInfo?.displayName || "",
    disabled: initialData?.disabled || userInfo?.disabled || false,
    role: initialData?.role || userInfo?.role || "stakeholder",
    permissions: initialData?.permissions || userInfo?.permissions || [],
    emailVerified: initialData?.emailVerified || userInfo?.emailVerified || false,
    notes: initialData?.notes || userInfo?.notes || "",
    needsPasswordReset: initialData?.needsPasswordReset || userInfo?.needsPasswordReset || false,
    needsOnboarding: initialData?.needsOnboarding || userInfo?.needsOnboarding || true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onNext(formData);
    } catch (error) {
      console.error("Error submitting profile:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Personal Information
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Please provide your contact information.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            disabled={isSubmitting}
            placeholder="+1 (555) 555-5555"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            type="email"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            disabled={true}
            InputProps={{
              endAdornment: (
                <Tooltip title="Email cannot be changed at this time." arrow>
                  <InfoIcon color="action" sx={{ ml: 1 }} />
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={20} />}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompleteProfile;
