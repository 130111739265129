import React, { useState, useEffect, useMemo, MouseEvent } from "react";
import {
  collection,
  query,
  getFirestore,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  Button,
  Typography,
  Box,
  Paper,
  Stack,
  Avatar,
  CircularProgress,
  Chip,
  Tooltip,
  alpha,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  Add as AddIcon,
  Business as BusinessIcon,
  Search as SearchIcon,
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Freelancer } from "@freetech/models/user";
import { useAuth } from "hooks/auth/useAuth";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";

const db = getFirestore();

interface FreelancerWithDetails extends Freelancer {
  fullName: string;
  projectCount: number;
  assignedClients: string[];
}

type FreelancerStatus = "active" | "ready" | "inactive";

const FreelancerManagement: React.FC = () => {
  const { userInfo } = useAuth();
  const [freelancers, setFreelancers] = useState<FreelancerWithDetails[]>([]);
  const [filteredFreelancers, setFilteredFreelancers] = useState<
    FreelancerWithDetails[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState<FreelancerStatus | "all">(
    "active"
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const { clients, isLoading: isLoadingClients } = useAdminClients();
  const {
    freelancers: adminFreelancers,
    isLoading: isLoadingFreelancers,
    updateFreelancerStatus: updateFreelancerStatusMutation,
  } = useAdminFreelancers();

  // Context menu state
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    freelancerId: string;
    currentStatus: FreelancerStatus;
  } | null>(null);

  // Snackbar state
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  // Create a map of client IDs to client names for quick lookup
  const clientMap = useMemo(() => {
    if (!clients) return new Map<string, string>();

    const map = new Map<string, string>();
    clients.forEach((client) => {
      if (client.id) {
        map.set(client.id, client.companyName);
      }
    });
    return map;
  }, [clients]);

  // Function to get client name from ID
  const getClientName = (clientId: string): string => {
    return clientMap.get(clientId) || clientId;
  };

  // Function to get color for status chip
  const getStatusColor = (status: FreelancerStatus | undefined) => {
    if (!status) return "default";

    switch (status) {
      case "active":
        return "success";
      case "ready":
        return "info";
      case "inactive":
        return "error";
      default:
        return "default";
    }
  };

  // Process freelancers data when adminFreelancers changes
  useEffect(() => {
    if (!adminFreelancers || isLoadingFreelancers) return;

    const freelancersWithDetails = adminFreelancers.map((freelancer) => ({
      ...freelancer,
      fullName:
        `${freelancer.firstName || ""} ${freelancer.lastName || ""}`.trim(),
      projectCount: freelancer.projectAssignments?.length || 0,
      assignedClients:
        freelancer.projectAssignments?.map((pa) => pa.clientId) || [],
    }));

    setFreelancers(freelancersWithDetails);
    setFilteredFreelancers(freelancersWithDetails);
  }, [adminFreelancers, isLoadingFreelancers]);

  // Filter freelancers based on search query and status filter
  useEffect(() => {
    let filtered = [...freelancers];

    // Apply status filter first
    if (statusFilter !== "all") {
      filtered = filtered.filter(
        (freelancer) => freelancer.status === statusFilter
      );
    }

    // Then apply search filter
    if (searchQuery.trim() !== "") {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (freelancer) =>
          (freelancer.fullName &&
            freelancer.fullName.toLowerCase().includes(lowercasedQuery)) ||
          (freelancer.email &&
            freelancer.email.toLowerCase().includes(lowercasedQuery)) ||
          freelancer.assignedClients.some((clientId) => {
            const clientName = getClientName(clientId);
            return (
              clientName && clientName.toLowerCase().includes(lowercasedQuery)
            );
          })
      );
    }

    setFilteredFreelancers(filtered);
  }, [searchQuery, statusFilter, freelancers, clientMap]);

  const handleManageFreelancer = (freelancerId: string) => {
    navigate(`/${userInfo?.role}/freelancer-management/${freelancerId}`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: FreelancerStatus | "all"
  ) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
    }
  };

  // Handle right-click on freelancer card
  const handleContextMenu = (
    event: MouseEvent,
    freelancerId: string,
    status: FreelancerStatus
  ) => {
    if (!status) {
      status = "inactive";
    }
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      freelancerId,
      currentStatus: status,
    });
  };

  // Close context menu
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Mark freelancer as inactive
  const handleMarkInactive = async () => {
    if (!contextMenu) return;

    try {
      const freelancerId = contextMenu.freelancerId;

      // Apply optimistic update
      const updatedFreelancers = freelancers.map((freelancer) =>
        freelancer.id === freelancerId
          ? { ...freelancer, status: "inactive" as FreelancerStatus }
          : freelancer
      );
      setFreelancers(updatedFreelancers);
      setFilteredFreelancers((prevFiltered) =>
        prevFiltered.map((freelancer) =>
          freelancer.id === freelancerId
            ? { ...freelancer, status: "inactive" as FreelancerStatus }
            : freelancer
        )
      );

      // Use the mutation from useAdminFreelancers
      await updateFreelancerStatusMutation.mutateAsync({
        freelancerId,
        status: "inactive",
      });

      // Show success message
      setSnackbar({
        open: true,
        message: "Freelancer marked as inactive",
        severity: "success",
      });

      // Close the context menu
      handleCloseContextMenu();
    } catch (error) {
      console.error("Error marking freelancer as inactive:", error);

      // Revert optimistic update on error
      const originalFreelancer = freelancers.find(
        (f) => f.id === contextMenu.freelancerId
      );
      if (originalFreelancer) {
        const revertedFreelancers = freelancers.map((freelancer) =>
          freelancer.id === contextMenu.freelancerId
            ? { ...freelancer, status: contextMenu.currentStatus }
            : freelancer
        );
        setFreelancers(revertedFreelancers);
        setFilteredFreelancers((prevFiltered) =>
          prevFiltered.map((freelancer) =>
            freelancer.id === contextMenu.freelancerId
              ? { ...freelancer, status: contextMenu.currentStatus }
              : freelancer
          )
        );
      }

      setSnackbar({
        open: true,
        message: "Failed to mark freelancer as inactive",
        severity: "error",
      });
    }
  };

  // Mark freelancer as active
  const handleMarkActive = async () => {
    if (!contextMenu) return;

    try {
      const freelancerId = contextMenu.freelancerId;

      // Apply optimistic update
      const updatedFreelancers = freelancers.map((freelancer) =>
        freelancer.id === freelancerId
          ? { ...freelancer, status: "active" as FreelancerStatus }
          : freelancer
      );
      setFreelancers(updatedFreelancers);
      setFilteredFreelancers((prevFiltered) =>
        prevFiltered.map((freelancer) =>
          freelancer.id === freelancerId
            ? { ...freelancer, status: "active" as FreelancerStatus }
            : freelancer
        )
      );

      // Use the mutation from useAdminFreelancers
      await updateFreelancerStatusMutation.mutateAsync({
        freelancerId,
        status: "active",
      });

      // Show success message
      setSnackbar({
        open: true,
        message: "Freelancer marked as active",
        severity: "success",
      });

      // Close the context menu
      handleCloseContextMenu();
    } catch (error) {
      console.error("Error marking freelancer as active:", error);

      // Revert optimistic update on error
      const originalFreelancer = freelancers.find(
        (f) => f.id === contextMenu.freelancerId
      );
      if (originalFreelancer) {
        const revertedFreelancers = freelancers.map((freelancer) =>
          freelancer.id === contextMenu.freelancerId
            ? { ...freelancer, status: contextMenu.currentStatus }
            : freelancer
        );
        setFreelancers(revertedFreelancers);
        setFilteredFreelancers((prevFiltered) =>
          prevFiltered.map((freelancer) =>
            freelancer.id === contextMenu.freelancerId
              ? { ...freelancer, status: contextMenu.currentStatus }
              : freelancer
          )
        );
      }

      setSnackbar({
        open: true,
        message: "Failed to mark freelancer as active",
        severity: "error",
      });
    }
  };

  // Handle snackbar close
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (isLoadingClients || isLoadingFreelancers) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: "24px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {/* Left: Status Filter */}
        <Box
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <FilterListIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="medium">
              Filter by Status:
            </Typography>
          </Box>
          <ToggleButtonGroup
            value={statusFilter}
            exclusive
            onChange={handleStatusFilterChange}
            aria-label="freelancer status filter"
            size="small"
          >
            <ToggleButton value="all" aria-label="all freelancers">
              All
            </ToggleButton>
            <ToggleButton
              value="active"
              aria-label="active freelancers"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: alpha(theme.palette.success.main, 0.1),
                  color: theme.palette.success.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.success.main, 0.2),
                  },
                },
              }}
            >
              Active
            </ToggleButton>
            <ToggleButton
              value="ready"
              aria-label="ready freelancers"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: alpha(theme.palette.info.main, 0.1),
                  color: theme.palette.info.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.info.main, 0.2),
                  },
                },
              }}
            >
              Ready
            </ToggleButton>
            <ToggleButton
              value="inactive"
              aria-label="inactive freelancers"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: alpha(theme.palette.error.main, 0.1),
                  color: theme.palette.error.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.error.main, 0.2),
                  },
                },
              }}
            >
              Inactive
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Right: Search Field */}
        <Box sx={{ flex: "0 0 auto" }}>
          <TextField
            placeholder="Search freelancers..."
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ minWidth: 250 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={3}>
        {filteredFreelancers.map((freelancer) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={freelancer.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "all 0.3s",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: theme.shadows[10],
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  },
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                }}
                onClick={() => handleManageFreelancer(freelancer.id)}
                onContextMenu={(e) =>
                  handleContextMenu(
                    e,
                    freelancer.id,
                    freelancer.status || "inactive"
                  )
                }
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Avatar
                        src={freelancer.profilePicture}
                        alt={freelancer.fullName}
                        sx={{
                          width: 60,
                          height: 60,
                          bgcolor: alpha(theme.palette.secondary.main, 0.7),
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          border: "1px solid",
                          borderColor: alpha(theme.palette.secondary.main, 0.2),
                        }}
                      >
                        {freelancer.firstName?.charAt(0) || ""}
                        {freelancer.lastName?.charAt(0) || ""}
                      </Avatar>
                    </Box>
                    <Chip
                      label={freelancer.status}
                      size="small"
                      color={getStatusColor(freelancer.status)}
                      sx={{ textTransform: "capitalize" }}
                    />
                  </Box>

                  <Box>
                    <Typography variant="h6" color="primary">
                      {freelancer.fullName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 1.5 }}
                    >
                      {freelancer.email}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1.5 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        Projects:
                      </Typography>
                      <Chip
                        label={freelancer.projectCount}
                        size="small"
                        color={
                          freelancer.projectCount > 0 ? "primary" : "default"
                        }
                      />
                    </Box>

                    <Typography variant="body2" fontWeight="bold" gutterBottom>
                      {freelancer.assignedClients.length > 0
                        ? "Assigned Clients:"
                        : "No Assigned Clients"}
                    </Typography>

                    {freelancer.assignedClients.length > 0 && (
                      <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        useFlexGap
                      >
                        {freelancer.assignedClients
                          .slice(0, 3)
                          .map((clientId, index) => (
                            <Chip
                              key={index}
                              label={getClientName(clientId)}
                              size="small"
                              variant="outlined"
                              sx={{ marginBottom: 0.5 }}
                            />
                          ))}
                        {freelancer.assignedClients.length > 3 && (
                          <Tooltip
                            title={freelancer.assignedClients
                              .slice(3)
                              .map(getClientName)
                              .join(", ")}
                            arrow
                          >
                            <Chip
                              label={`+${freelancer.assignedClients.length - 3} more`}
                              size="small"
                              color="primary"
                              variant="outlined"
                              sx={{ marginBottom: 0.5 }}
                            />
                          </Tooltip>
                        )}
                      </Stack>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Context Menu */}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenu?.currentStatus !== "active" && (
          <MenuItem onClick={handleMarkActive}>
            <ListItemIcon>
              <CheckCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark Active</ListItemText>
          </MenuItem>
        )}
        {contextMenu?.currentStatus !== "inactive" && (
          <MenuItem onClick={handleMarkInactive}>
            <ListItemIcon>
              <BlockIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark Inactive</ListItemText>
          </MenuItem>
        )}
      </Menu>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {filteredFreelancers.length === 0 && (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography variant="h6" color="text.secondary">
            No freelancers found matching your search.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FreelancerManagement;
