import React, { createContext, useState, useEffect, ReactNode } from "react";
import {
  User,
  onAuthStateChanged,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  applyActionCode,
  signInWithEmailLink,
} from "@firebase/auth";
import { auth } from "config/firebase";
import { handleLoginWithGoogle } from "core/auth/handleLoginWithGoogle";
import { handleLoginWithEmail } from "core/auth/handleLoginWithEmail";
import HyperDX from "@hyperdx/browser";
import { getAuthUserProfile } from "core/auth/getAuthUserProfile";
import {
  PrivateUserInfoType,
  Freelancer,
  Stakeholder,
  UserInfoType,
} from "@freetech/models/user";
import { LoginCredentials } from "types/user";
import { MultiFactorError } from "@firebase/auth";
import { portalFunctions } from "core/functions/portalFunctions";
import { FirebaseError } from "@firebase/util";
import { EXPIRATION_TIME_MINUTES } from "config/constants";

type IAuthContext = {
  // User state
  currentUser?: User | null;
  userInfo?: UserInfoType | Stakeholder | Freelancer;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  refreshUserInfo: (currentUser: User) => Promise<void>;

  // Auth methods
  loginWithEmail: (
    credentials: LoginCredentials
  ) => Promise<MultiFactorError | FirebaseError | void>;
  loginWithGoogle: () => Promise<MultiFactorError | FirebaseError | void>;
  logout: () => Promise<void>;

  // Email verification
  handleResendVerificationEmail: (email: string) => Promise<boolean>;
  handleVerifyActionCode: (code: string) => Promise<boolean>;
  handleSignInWithEmailLink: (code: string) => Promise<boolean>;

  // Password reset
  handleSendPasswordResetEmail: (email: string) => Promise<void>;
  handleVerifyPasswordResetCode: (code: string) => Promise<string>;
  handleConfirmPasswordReset: (
    code: string,
    newPassword: string
  ) => Promise<boolean>;
  remainingSessionTime: number | null;
};

type IAuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [remainingSessionTime, setRemainingSessionTime] = useState<
    number | null
  >(null);

  const loginWithEmail = async (credentials: LoginCredentials) => {
    await handleLoginWithEmail(credentials, setUserInfo);
  };

  const loginWithGoogle = async () => {
    await handleLoginWithGoogle(setUserInfo);
  };

  const logout = async () => {
    return await signOut(auth);
  };

  const handleResendVerificationEmail = async (email: string) => {
    try {
      await portalFunctions.public.resendVerificationEmail(email);
      return true;
    } catch (error) {
      console.error("Error resending verification email:", error);
      return false;
    }
  };

  const handleVerifyActionCode = async (code: string) => {
    console.log("[AuthContext] Attempting to verify action code:", code);
    try {
      await applyActionCode(auth, code);
      return true;
    } catch (error) {
      console.error("[AuthContext] Error verifying action code:", error);
      return false;
    }
  };

  const handleVerifyPasswordResetCode = async (code: string) => {
    console.log("[AuthContext] Attempting to verify password reset code");
    try {
      const userEmail = await verifyPasswordResetCode(auth, code);
      return userEmail;
    } catch (error) {
      console.error(
        "[AuthContext] Error verifying password reset code:",
        error
      );
      throw error;
    }
  };

  const handleSendPasswordResetEmail = async (email: string): Promise<void> => {
    const actionCodeSettings = {
      url: "http://localhost:3000",
      handleCodeInApp: false,
    };

    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
    } catch (error) {
      console.error("Error sending password reset email:", error);
    }
  };

  const handleConfirmPasswordReset = async (
    code: string,
    newPassword: string
  ) => {
    try {
      await confirmPasswordReset(auth, code, newPassword);
      return true;
    } catch (error) {
      console.error("Error confirming password reset:", error);
      return false;
    }
  };

  const handleSignInWithEmailLink = async (code: string) => {
    try {
      // Get email from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      console.log("urlParams", urlParams);
      const email = urlParams.get("email");

      if (!email) {
        return false;
      }

      let signInLink = window.location.href.split("&email")[0];

      signInLink = signInLink.replace(
        "http://localhost:3000",
        "https://stg-portal.freetech.co"
      );

      await signInWithEmailLink(auth, email, signInLink);

      return true;
    } catch (error) {
      return false;
    }
  };

  const checkExpired = () => {
    const user = auth.currentUser;
    if (!user) return;

    user.getIdTokenResult(true).then((tokenResult) => {
      const issuedAt = tokenResult.claims.auth_time;
      if (!issuedAt) {
        console.error("No issuedAt found");
        return;
      }

      const issuedAtInt = parseInt(issuedAt) * 1000; // Convert seconds to milliseconds
      const twoHoursFromIssuedTime =
        issuedAtInt + EXPIRATION_TIME_MINUTES * 60 * 1000; // Add 2 hours to issued time
      const currentTime = Date.now();

      console.log({
        issuedAtInt,
        twoHoursFromIssuedTime,
        currentTime,
        hasExpired: currentTime > twoHoursFromIssuedTime,
      });

      if (currentTime > twoHoursFromIssuedTime) {
        logout();
        window.location.href = "/session-expired";
      } else {
        setRemainingSessionTime(twoHoursFromIssuedTime - currentTime);
      }
    });
  };

  const refreshUserInfo = async (currentUser: User) => {
    checkExpired();

    await currentUser.reload();

    const docSnapData = await getAuthUserProfile(currentUser);

    console.log("docSnapData", docSnapData);
    const verified = currentUser.emailVerified;
    const data = { ...docSnapData, email_verified: verified };

    if (docSnapData) {
      setUserInfo((prev: PrivateUserInfoType | Freelancer | Stakeholder) => ({
        ...prev,
        ...data,
      }));
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      try {
        if (user && auth.currentUser) {
          if (user?.email) {
            HyperDX.setGlobalAttributes({
              userId: user.uid,
              userEmail: user.email,
            });
          }
          if (auth.currentUser) {
            await refreshUserInfo(auth.currentUser);
          }
        } else {
          setUserInfo(null);
        }
      } catch (error) {
        console.error("Auth state change error:", error);
        setUserInfo(null);
      } finally {
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser: auth.currentUser,
        userInfo,
        refreshUserInfo,
        isLoading,
        setIsLoading,
        loginWithEmail,
        loginWithGoogle,
        logout,
        handleResendVerificationEmail,
        handleSendPasswordResetEmail,
        handleVerifyPasswordResetCode,
        handleConfirmPasswordReset,
        handleVerifyActionCode,
        handleSignInWithEmailLink,
        remainingSessionTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
