import React from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  Button,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "hooks/auth/useAuth";
import { Link } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const ExecutiveAssistantHome: React.FC = () => {
  const theme = useTheme();
  const { userInfo } = useAuth();

  const quickAccessItems = [
    {
      title: "Client Management",
      icon: <BusinessIcon fontSize="large" color="primary" />,
      description: "View and manage client information",
      link: "/executiveAssistant/client-management",
    },
    {
      title: "Freelancer Management",
      icon: <PeopleIcon fontSize="large" color="primary" />,
      description: "View and manage freelancer profiles",
      link: "/executiveAssistant/freelancer-management",
    },
    {
      title: "Freelo Boards",
      icon: <ViewKanbanIcon fontSize="large" color="primary" />,
      description: "Access Kanban boards for project management",
      link: "/executiveAssistant/freelo",
    },
    {
      title: "Time Tracking",
      icon: <AccessTimeIcon fontSize="large" color="primary" />,
      description: "Track and manage time entries",
      link: "/executiveAssistant/tracker",
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 2, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Executive Assistant Dashboard
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ marginBottom: 4, color: "#555" }}>
          Welcome, {userInfo?.firstName}! Here's an overview of your operations.
        </Typography>

        <Alert severity="info" sx={{ mb: 4 }}>
          More dashboard information and analytics are coming soon from the development team.
        </Alert>

        {/* Quick Access Cards */}
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Quick Access
        </Typography>
        <Grid container spacing={3}>
          {quickAccessItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                  }
                }}
              >
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {item.icon}
                    <Typography variant="h6" sx={{ ml: 1 }}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {item.description}
                  </Typography>
                  <Box sx={{ mt: 'auto' }}>
                    <Button 
                      component={Link} 
                      to={item.link} 
                      variant="contained" 
                      color="primary"
                      fullWidth
                    >
                      Access
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ExecutiveAssistantHome; 