import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  Avatar, 
  Divider, 
  Button, 
  TextField, 
  Paper, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Skeleton,
  Chip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useStudentMentorship } from 'hooks/student/useStudentMentorship';
import { BlurredContent } from 'components/BlurredContent';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateTime } from 'luxon';

const StudentMentorship: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { 
    mentorInfo, 
    cohortInfo, 
    isLoading, 
    requestMentorAssistance,
    isRequestingAssistance
  } = useStudentMentorship();
  
  const [assistanceDialogOpen, setAssistanceDialogOpen] = useState(false);
  const [assistanceMessage, setAssistanceMessage] = useState('');
  
  const handleRequestAssistance = async () => {
    if (!assistanceMessage.trim()) return;
    
    try {
      await requestMentorAssistance(assistanceMessage);
      setAssistanceDialogOpen(false);
      setAssistanceMessage('');
    } catch (error) {
      console.error('Error requesting assistance:', error);
    }
  };

  // Format date using Luxon
  const formatDate = (dateString: string) => {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED);
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 3 }}>
        Your Mentorship Program
      </Typography>
      
      <Typography variant="body1" paragraph sx={{ mb: 4 }}>
        As a student at FreeTech, you are assigned to a mentor and a cohort of fellow students. 
        Your mentor will guide you through your learning journey, while your cohort provides a collaborative environment 
        for growth and shared learning experiences.
      </Typography>
      
      <Grid container spacing={4}>
        {/* Mentor Information */}
        <Grid item xs={12} md={6}>
          <BlurredContent title="Mentor Information">
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PersonIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="h5" component="h2">
                    Your Mentor
                  </Typography>
                </Box>
                
                <Divider sx={{ mb: 3 }} />
                
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton variant="circular" width={80} height={80} sx={{ mr: 2 }} />
                    <Box>
                      <Skeleton variant="text" width={200} height={30} />
                      <Skeleton variant="text" width={150} height={20} />
                    </Box>
                  </Box>
                ) : mentorInfo ? (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <Avatar 
                        src={mentorInfo.profilePicture} 
                        alt={mentorInfo.displayName}
                        sx={{ width: 80, height: 80, mr: 2 }}
                      >
                        {mentorInfo.firstName[0]}{mentorInfo.lastName[0]}
                      </Avatar>
                      <Box>
                        <Typography variant="h6">{mentorInfo.displayName}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          <EmailIcon fontSize="small" sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                          {mentorInfo.email}
                        </Typography>
                      </Box>
                    </Box>
                    
                    <Button 
                      variant="contained" 
                      color="primary" 
                      fullWidth
                      onClick={() => setAssistanceDialogOpen(true)}
                    >
                      Request Assistance
                    </Button>
                  </Box>
                ) : (
                  <Paper sx={{ p: 3, textAlign: 'center', backgroundColor: theme.palette.grey[100] }}>
                    <Typography variant="body1" color="text.secondary">
                      No mentor has been assigned to you yet. Please check back later.
                    </Typography>
                  </Paper>
                )}
              </CardContent>
            </Card>
          </BlurredContent>
        </Grid>
        
        {/* Cohort Information */}
        <Grid item xs={12} md={6}>
          <BlurredContent title="Cohort Information">
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <GroupIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="h5" component="h2">
                    Your Cohort
                  </Typography>
                </Box>
                
                <Divider sx={{ mb: 3 }} />
                
                {isLoading ? (
                  <Box>
                    <Skeleton variant="text" width="60%" height={30} />
                    <Skeleton variant="text" width="40%" height={20} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />
                    <Skeleton variant="text" width="30%" height={20} />
                  </Box>
                ) : cohortInfo ? (
                  <Box>
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="h6">{cohortInfo.name}</Typography>
                      {cohortInfo.description && (
                        <Typography variant="body2" color="text.secondary" paragraph>
                          {cohortInfo.description}
                        </Typography>
                      )}
                      
                      {(cohortInfo.startDate || cohortInfo.endDate) && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <CalendarTodayIcon fontSize="small" sx={{ mr: 1 }} color="action" />
                          <Typography variant="body2" color="text.secondary">
                            {cohortInfo.startDate && (
                              <>Started: {formatDate(cohortInfo.startDate)}</>
                            )}
                            {cohortInfo.startDate && cohortInfo.endDate && ' • '}
                            {cohortInfo.endDate && (
                              <>Ends: {formatDate(cohortInfo.endDate)}</>
                            )}
                          </Typography>
                        </Box>
                      )}
                      
                      <Chip 
                        icon={<SchoolIcon />} 
                        label={`${cohortInfo.members.length} Students`} 
                        color="primary" 
                        size="small"
                        variant="outlined"
                      />
                    </Box>
                    
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      Cohort Members
                    </Typography>
                    
                    <List disablePadding>
                      {cohortInfo.members.map((member) => (
                        <ListItem key={member.id} disableGutters>
                          <ListItemAvatar>
                            <Avatar src={member.profilePicture}>
                              {member.firstName[0]}{member.lastName[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText 
                            primary={member.displayName} 
                            secondary={member.email}
                            primaryTypographyProps={{ variant: 'body2' }}
                            secondaryTypographyProps={{ variant: 'caption' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ) : (
                  <Paper sx={{ p: 3, textAlign: 'center', backgroundColor: theme.palette.grey[100] }}>
                    <Typography variant="body1" color="text.secondary">
                      You are not currently assigned to a cohort. Please check back later.
                    </Typography>
                  </Paper>
                )}
              </CardContent>
            </Card>
          </BlurredContent>
        </Grid>
        
        {/* Program Information */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SchoolIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h5" component="h2">
                  About the Mentorship Program
                </Typography>
              </Box>
              
              <Divider sx={{ mb: 3 }} />
              
              <Typography variant="body1" paragraph>
                The FreeTech Mentorship Program connects you with experienced industry professionals who 
                provide guidance, feedback, and support throughout your learning journey.
              </Typography>
              
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                How It Works:
              </Typography>
              
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={4}>
                  <Paper elevation={1} sx={{ p: 2, height: '100%' }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Small Cohorts
                    </Typography>
                    <Typography variant="body2">
                      You are placed in a cohort with up to 4 other students, all assigned to the same mentor.
                      This creates an intimate learning environment where you can collaborate and grow together.
                    </Typography>
                  </Paper>
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <Paper elevation={1} sx={{ p: 2, height: '100%' }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Regular Check-ins
                    </Typography>
                    <Typography variant="body2">
                      Your mentor will hold regular group sessions to discuss progress, challenges, and 
                      learning opportunities. You can also request one-on-one assistance when needed.
                    </Typography>
                  </Paper>
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <Paper elevation={1} sx={{ p: 2, height: '100%' }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Real-World Experience
                    </Typography>
                    <Typography variant="body2">
                      Mentors provide insights from their professional experience, helping you 
                      understand how classroom concepts apply to real-world scenarios.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                Program Benefits:
              </Typography>
              
              <Typography variant="body2" component="ul">
                <li>Personalized guidance tailored to your learning pace and style</li>
                <li>Access to industry expertise and professional networks</li>
                <li>Collaborative learning with peers at similar skill levels</li>
                <li>Higher completion rates and better learning outcomes</li>
                <li>Smoother transition from learning to professional application</li>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Request Assistance Dialog */}
      <Dialog 
        open={assistanceDialogOpen} 
        onClose={() => setAssistanceDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Request Assistance From Your Mentor</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Describe what you need help with, and your mentor will be notified of your request.
          </DialogContentText>
          <TextField
            autoFocus
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={assistanceMessage}
            onChange={(e) => setAssistanceMessage(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssistanceDialogOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleRequestAssistance} 
            variant="contained"
            color="primary"
            disabled={!assistanceMessage.trim() || isRequestingAssistance}
          >
            {isRequestingAssistance ? 'Sending...' : 'Send Request'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StudentMentorship; 