import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { storage } from "config/firebase";
import { ref, deleteObject } from "firebase/storage";
import { BugReportAttachment } from "@freetech/models/bugReport";

/**
 * Removes an attachment from a bug report and deletes it from storage
 * @param bugReportId The ID of the bug report
 * @param attachmentId The ID of the attachment to remove
 */
export const removeBugReportAttachment = async (
  bugReportId: string,
  attachmentId: string
): Promise<void> => {
  try {
    const bugReportRef = doc(db, "bugReports", bugReportId);

    // Get current attachments
    const bugReportDoc = await getDoc(bugReportRef);
    if (!bugReportDoc.exists()) {
      throw new Error("Bug report not found");
    }
    
    const currentAttachments = bugReportDoc.data()?.attachments || [];
    
    // Find the attachment to remove
    const attachmentToRemove = currentAttachments.find(
      (attachment: BugReportAttachment) => attachment.id === attachmentId
    );
    
    if (!attachmentToRemove) {
      throw new Error("Attachment not found");
    }
    
    // Try to delete the file from storage
    try {
      // Extract the file path from the URL or use the attachment name
      const fileName = attachmentToRemove.name;
      const storageRef = ref(storage, `bugReports/${bugReportId}/${fileName}`);
      await deleteObject(storageRef);
    } catch (error) {
      console.error("Error deleting file from storage:", error);
      // Continue even if storage deletion fails
    }
    
    // Filter out the attachment from the list
    const updatedAttachments = currentAttachments.filter(
      (attachment: BugReportAttachment) => attachment.id !== attachmentId
    );
    
    // Update the bug report document
    await updateDoc(bugReportRef, {
      attachments: updatedAttachments,
      updatedAt: new Date().toISOString(),
    });
    
    console.log(`Attachment ${attachmentId} removed from bug report ${bugReportId}`);
  } catch (error) {
    console.error("Error removing attachment:", error);
    throw error;
  }
}; 