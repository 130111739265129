import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { submitNewProjectIdea } from "core/stakeholder/submitNewProjectIdea";
import { useAuth } from "hooks/auth/useAuth";
import { PublicUserInfoType, Stakeholder } from "@freetech/models/user";
import {
  IFeatureRequest,
  Project,
  ProjectAssignment,
  ProjectIdeaData,
} from "@freetech/models";
import { db } from "core/firestore";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { loadClientsForStakeholder } from "core/stakeholder/loadClientsForStakeholder";
import {
  collectionGroup,
  query,
  getDocs,
  where,
  collection,
  addDoc,
} from "firebase/firestore";

export const useStakeholderProjects = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  const { data: users, isLoading: isUsersLoading } = usePublicUserList();

  const clientsQuery = useQuery({
    queryKey: ["clients"],
    queryFn: async () => {
      if (!userInfo) {
        return [];
      }
      return await loadClientsForStakeholder(userInfo);
    },
    refetchInterval: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    enabled: !!userInfo,
  });

  const projectsQuery = useQuery({
    queryKey: ["projects", clientsQuery.data],
    queryFn: async (): Promise<Project[]> => {
      const projects =
        clientsQuery.data?.flatMap((client) => client.projects || []) || [];
      console.log("All projects:", projects);
      return projects;
    },
    enabled: !!clientsQuery.data,
  });

  const publicFreelancersQuery = useQuery({
    queryKey: ["useStakeholderProjects-freelancers"],
    queryFn: async (): Promise<PublicUserInfoType[]> => {
      // Get all unique freelancer IDs from clients
      const clientFreelancerIds =
        clientsQuery.data?.flatMap(
          (client) => client.associatedFreelancerIds || []
        ) || [];

      const uniqueFreelancerIds = [...new Set(clientFreelancerIds)];
      console.log("All unique freelancerIds:", uniqueFreelancerIds);

      const freelancers =
        users?.filter((user) => uniqueFreelancerIds.includes(user.id)) || [];
      console.log("Filtered freelancers:", freelancers);
      return freelancers;
    },
    enabled: !!clientsQuery.data && !!users,
  });

  const publicStakeholdersQuery = useQuery({
    queryKey: ["useStakeholderProjects-stakeholders"],
    queryFn: async (): Promise<PublicUserInfoType[]> => {
      const clientStakeholderIds =
        clientsQuery.data?.flatMap(
          (client) => client.associatedStakeholderIds || []
        ) || [];

      const uniqueStakeholderIds = [...new Set(clientStakeholderIds)];
      console.log("All unique stakeholderIds:", uniqueStakeholderIds);

      const stakeholders =
        users?.filter((user) => uniqueStakeholderIds.includes(user.id)) || [];
      console.log("Filtered stakeholders:", stakeholders);
      return stakeholders;
    },
    enabled: !!users,
  });

  const featureRequestsQuery = useQuery({
    queryKey: ["useStakeholderProjects-featureRequests"],
    queryFn: async (): Promise<IFeatureRequest[]> => {
      if (!clientsQuery.data) return [];

      const allFeatureRequests: IFeatureRequest[] = [];

      // Fetch feature requests for each client
      for (const client of clientsQuery.data) {
        const featureRequestsRef = collection(
          db,
          "clients",
          client.id,
          "featureRequests"
        );
        const snapshot = await getDocs(featureRequestsRef);
        const clientFeatureRequests = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as IFeatureRequest[];

        allFeatureRequests.push(...clientFeatureRequests);
      }

      return allFeatureRequests;
    },
    enabled: !!clientsQuery.data,
  });

  const submitProjectIdeaMutation = useMutation({
    mutationFn: async (ideaData: ProjectIdeaData) => {
      return await submitNewProjectIdea(userInfo as Stakeholder, ideaData);
    },
  });

  const submitFeatureRequestMutation = useMutation({
    mutationFn: async (
      featureRequestData: Omit<IFeatureRequest, "id" | "createdByUserId">
    ) => {
      const project = projectsQuery.data?.find(
        (p) => p.id === featureRequestData.projectId
      );
      if (!project) {
        throw new Error("Project not found");
      }

      if (!userInfo) {
        throw new Error("User not found");
      }

      const featureRequestRef = collection(
        db,
        "clients",
        project.clientId,
        "featureRequests"
      );
      const docRef = await addDoc(featureRequestRef, {
        ...featureRequestData,
        createdByUserId: userInfo.id,
        createdAt: new Date().toISOString(),
      });

      return {
        ...featureRequestData,
        id: docRef.id,
        createdByUserId: userInfo.id,
      };
    },
    onSuccess: () => {
      // Invalidate both the feature requests and clients queries
      queryClient.invalidateQueries({
        queryKey: ["useStakeholderProjects-featureRequests"],
      });
      queryClient.invalidateQueries({
        queryKey: ["clients"],
      });
    },
  });

  const isLoading = !userInfo || clientsQuery.isLoading;
  const isFreelancersLoading =
    isUsersLoading || publicFreelancersQuery.isLoading || isLoading;

  return {
    clients: clientsQuery.data,
    isClientsLoading: clientsQuery.isLoading,
    clientsError: clientsQuery.error,
    projects: projectsQuery.data,
    isProjectsLoading: projectsQuery.isLoading,
    projectsError: projectsQuery.error,
    freelancers: publicFreelancersQuery.data,
    isFreelancersLoading: publicFreelancersQuery.isLoading,
    freelancersError: publicFreelancersQuery.error,
    stakeholders: publicStakeholdersQuery.data,
    isStakeholdersLoading: publicStakeholdersQuery.isLoading,
    submitProjectIdea: submitProjectIdeaMutation.mutateAsync,
    isSubmitting: submitProjectIdeaMutation.isPending,
    submitError: submitProjectIdeaMutation.error?.message,
    submitFeatureRequest: submitFeatureRequestMutation.mutateAsync,
    isSubmittingFeatureRequest: submitFeatureRequestMutation.isPending,
    submitFeatureRequestError: submitFeatureRequestMutation.error?.message,
    featureRequests: featureRequestsQuery.data,
    isFeatureRequestsLoading: featureRequestsQuery.isLoading,
    featureRequestsError: featureRequestsQuery.error,
  };
};
