import { getDocument } from "core/firestore";
import { ConsultingServicesAgreement } from "@freetech/models/projects";
export const getCSAName = async (
  clientId: string,
  projectId: string,
  csaId: string
): Promise<string | null> => {
  const csaPath = `clients/${clientId}/projects/${projectId}/consultingServicesAgreements/${csaId}`;
  const doc = await getDocument<ConsultingServicesAgreement>(csaPath);
  return doc?.name || null;
};