import React from "react";
import { Box, Typography } from "@mui/material";
import { useAuth } from "hooks/auth/useAuth";
import { EXPIRATION_TIME_MS } from "config/constants";
import { Warning } from "@mui/icons-material";

export const SessionExpiringSoon = () => {
  const { remainingSessionTime, logout } = useAuth();
  const [timeLeft, setTimeLeft] = React.useState(remainingSessionTime);

  const oneHourInMilliseconds = 60 * 60 * 1000;

  React.useEffect(() => {
    if (!timeLeft || timeLeft > EXPIRATION_TIME_MS) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (!prev || prev <= 1000) {
          logout();
          window.location.href = "/session-expired";
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, logout]);

  React.useEffect(() => {
    setTimeLeft(remainingSessionTime);
  }, [remainingSessionTime]);

  if (!timeLeft || timeLeft > oneHourInMilliseconds) {
    return null;
  }

  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeLeft / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);

  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          marginRight: 2,
          "&:hover .warning-text": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Warning
            sx={{
              color: "warning.main",
              display: "flex",
              alignItems: "center",
            }}
          />
          <Typography
            className="warning-text"
            sx={{
              position: "absolute",
              bottom: "-40px",
              right: 0,
              backgroundColor: "warning.main",
              color: "warning.contrastText",
              padding: "8px",
              borderRadius: "4px",
              whiteSpace: "nowrap",
              display: "none",
              zIndex: 1000,
            }}
          >
            Warning: Session expires in {minutes}m {seconds}s. You will be
            logged out.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
