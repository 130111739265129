import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Paper,
  IconButton,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  Card,
  CardContent,
  Collapse,
  Button,
  Avatar,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useClientOverviewStatistics } from "hooks/admin";
import { Client } from "@freetech/models/projects";
import { useAuth } from "hooks/auth/useAuth";

interface ClientStatistics {
  totalEngineers: StatValue;
  activeEngineers: StatValue;
  totalProjects: StatValue;
  activeProjects: StatValue;
  completedProjects: StatValue;
  totalUtilizedHours: StatValue;
  totalEngineerExpenses: StatValue;
  totalRevenue: StatValue;
}

interface StatValue {
  label: string;
  value: number;
}

interface ClientDetailsProps {
  client: Client;
  clientId: string;
}

const STATUS_COLORS = {
  opportunity: "#3498db",
  active: "#2ecc71",
  inactive: "#e74c3c",
  default: "#95a5a6",
} as const;

const STAT_COLORS = {
  totalEngineers: "#1976d2",
  activeEngineers: "#2196f3",
  totalProjects: "#9c27b0",
  activeProjects: "#757575",
  completedProjects: "#2e7d32",
  totalUtilizedHours: "#424242",
  totalEngineerExpenses: "#d32f2f",
  totalRevenue: "#2e7d32",
  default: "#95a5a6",
} as const;

const EDITABLE_FIELDS = [
  "pointOfContactName",
  "email",
  "phone",
  "location",
  "size",
] as const;

const ClientDetails: React.FC<ClientDetailsProps> = ({ client, clientId }) => {
  const [editedClient, setEditedClient] = useState<Client | null>(null);
  const [editing, setEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { updateClient, uploadClientLogo } = useAdminClient(clientId);
  const { data: clientStats } = useClientOverviewStatistics(clientId);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [logoUploading, setLogoUploading] = useState(false);

  useEffect(() => {
    if (client) {
      setEditedClient(client);
    }
  }, [client]);

  const handleEdit = () => {
    setEditing(true);
    setEditedClient((prev) => ({
      ...prev!,
      introductionDate: prev?.introductionDate || new Date().toISOString(),
    }));
  };
  const { currentUser } = useAuth();

  const handleSave = async () => {
    if (editedClient) {
      try {
        // Clean up the client data to remove any undefined or null values
        const cleanClientData: Partial<Client> = {
          id: editedClient.id,
          companyName: editedClient.companyName || '',
          email: editedClient.email || '',
          phone: editedClient.phone || '',
          status: editedClient.status,
          companyPhysicalAddress: editedClient.companyPhysicalAddress || {
            street: '',
            city: '',
            state: '',
            zip: ''
          }
        };

        await updateClient(cleanClientData);
        setEditing(false);
      } catch (error) {
        console.error("Error updating client:", error);
      }
    }
  };

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setEditedClient((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleStatusChange = (event: SelectChangeEvent<Client["status"]>) => {
    const newStatus = event.target.value as Client["status"];
    setEditedClient((prev) => (prev ? { ...prev, status: newStatus } : null));
    if (client) {
      updateClient({ id: client.id, status: newStatus });
    }
  };

  const handleLogoUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      
      try {
        setLogoUploading(true);
        await uploadClientLogo.mutateAsync(file);
      } catch (error) {
        console.error("Error uploading logo:", error);
      } finally {
        setLogoUploading(false);
        // Reset the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  };

  if (!editedClient) return null;

  return (
    <Card elevation={2} sx={{ width: "100%", mb: 4 }}>
      <CardContent>
        {/* Header with Logo, Status, Company Name, and Edit Controls */}
        <Box
          sx={{
            position: "relative",
            mb: 3,
            minHeight: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Client Logo - Left */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ position: 'relative' }}>
              <Avatar 
                src={editedClient.logoUrl} 
                alt={editedClient.companyName}
                sx={{ 
                  width: 80, 
                  height: 80, 
                  border: '1px solid #eee',
                  bgcolor: 'primary.light',
                  color: 'white',
                  fontSize: '1.5rem'
                }}
              >
                {editedClient.companyName?.charAt(0).toUpperCase() || 'C'}
              </Avatar>
              {editing && (
                <Tooltip title="Upload logo">
                  <IconButton 
                    size="small" 
                    sx={{ 
                      position: 'absolute', 
                      bottom: -5, 
                      right: -5,
                      backgroundColor: 'background.paper',
                      border: '1px solid #eee',
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'white'
                      }
                    }}
                    onClick={handleLogoUpload}
                    disabled={logoUploading || uploadClientLogo.isPending}
                  >
                    <PhotoCameraIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Box>

            {/* Status Dropdown */}
            {editing ? (
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <Select
                  value={editedClient.status}
                  onChange={handleStatusChange}
                  name="status"
                  renderValue={(selected) => (
                    <Chip
                      label={selected}
                      size="small"
                      sx={{
                        backgroundColor:
                          STATUS_COLORS[selected as keyof typeof STATUS_COLORS] ||
                          STATUS_COLORS.default,
                        color: "white",
                        fontWeight: "bold",
                      }}
                    />
                  )}
                >
                  <MenuItem value="opportunity">Opportunity</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Chip
                label={editedClient.status}
                size="small"
                sx={{
                  backgroundColor:
                    STATUS_COLORS[
                      editedClient.status as keyof typeof STATUS_COLORS
                    ] || STATUS_COLORS.default,
                  color: "white",
                  fontWeight: "bold",
                }}
              />
            )}
          </Box>

          {/* Centered Company Name */}
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {editing ? (
              <TextField
                name="companyName"
                label="Company Name"
                value={editedClient.companyName}
                onChange={handleChange}
                sx={{ maxWidth: 400 }}
              />
            ) : (
              <Typography variant="h3" align="center">
                {client.companyName}
              </Typography>
            )}
          </Box>

          {/* Edit Controls and Expand - Right */}
          <Stack direction="row" spacing={1}>
            {editing ? (
              <>
                <IconButton
                  onClick={handleSave}
                  disabled={false}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setEditing(false);
                    setEditedClient(client);
                  }}
                  color="error"
                >
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleEdit} color="primary">
                <EditIcon />
              </IconButton>
            )}

          </Stack>
        </Box>

        <Grid container spacing={3}>
          {/* Client Information */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ borderBottom: 1, borderColor: "divider", pb: 1, mb: 2 }}
            >
              Company Information
            </Typography>
            <Stack spacing={1.5}>
              {EDITABLE_FIELDS.map((field) => (
                <Box
                  key={field}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {editing ? (
                    <TextField
                      fullWidth
                      name={field}
                      label={
                        field.charAt(0).toUpperCase() +
                        field
                          .slice(1)
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                      }
                      value={editedClient[field]}
                      onChange={handleChange}
                      size="small"
                    />
                  ) : (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {field.charAt(0).toUpperCase() +
                          field
                            .slice(1)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                      </Typography>
                      <Typography variant="body2">{client[field]}</Typography>
                    </>
                  )}
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {editing ? (
                  <TextField
                    fullWidth
                    name="introductionDate"
                    label="Introduction Date"
                    type="date"
                    value={editedClient?.introductionDate?.split("T")[0] || ""}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                ) : (
                  <>
                    <Typography variant="body2" color="text.secondary">
                      Introduction Date
                    </Typography>
                    <Typography variant="body2">
                      {client.introductionDate
                        ? new Date(client.introductionDate).toLocaleDateString()
                        : "Not set"}
                    </Typography>
                  </>
                )}
              </Box>
            </Stack>
          </Grid>

          {/* Statistics */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ borderBottom: 1, borderColor: "divider", pb: 1, mb: 2 }}
            >
              Statistics
            </Typography>
            {clientStats && (
              <Grid container spacing={1}>
                {Object.entries(clientStats).map(([key, stat]) => (
                  <Grid item xs={6} key={key}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 1,
                        borderRadius: 1,
                        "&:hover": {
                          bgcolor: "action.hover",
                        },
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mr: 1 }}
                      >
                        {stat.label}:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "medium",
                          color:
                            STAT_COLORS[key as keyof typeof STAT_COLORS] ||
                            STAT_COLORS.default,
                        }}
                      >
                        {key === "totalEngineerExpenses" ||
                        key === "totalRevenue"
                          ? `$${stat.value.toLocaleString()}`
                          : stat.value.toLocaleString()}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClientDetails;
