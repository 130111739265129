import { useState, useCallback, useEffect } from "react";

type StorageCodec<T> = {
  parse: (value: string) => T;
  stringify: (value: T) => string;
};

const defaultCodec: StorageCodec<any> = {
  parse: (value: string) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  },
  stringify: (value: any) => {
    try {
      return JSON.stringify(value);
    } catch {
      return String(value);
    }
  },
};

/**
 * Custom hook for managing state in localStorage with type safety
 * @param key The localStorage key
 * @param initialValue The initial value if no value exists in localStorage
 * @param options Optional configuration including custom codec for parsing/stringifying
 * @returns [value, setValue, removeValue] tuple
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options?: {
    codec?: StorageCodec<T>;
  }
): [T, (value: T | ((prev: T) => T)) => void, () => void] {
  const codec = options?.codec || defaultCodec;

  // Initialize state with existing localStorage value or initial value
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? codec.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  // Return memoized version of the setValue function
  const setValue = useCallback(
    (value: T | ((prev: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, codec.stringify(valueToStore));
      } catch (error) {
        console.error(`Error setting localStorage key "${key}":`, error);
      }
    },
    [key, storedValue, codec]
  );

  // Remove value from localStorage
  const removeValue = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
      setStoredValue(initialValue);
    } catch (error) {
      console.error(`Error removing localStorage key "${key}":`, error);
    }
  }, [key, initialValue]);

  // Sync state with other tabs/windows
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key && e.newValue !== null) {
        try {
          setStoredValue(codec.parse(e.newValue));
        } catch (error) {
          console.error(`Error parsing storage event for key "${key}":`, error);
        }
      } else if (e.key === key && e.newValue === null) {
        setStoredValue(initialValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [key, codec, initialValue]);

  return [storedValue, setValue, removeValue];
}

// Boolean-specific helper (for backward compatibility)
export function useLocalStorageBoolState(key: string, defaultValue: boolean) {
  const booleanCodec: StorageCodec<boolean> = {
    parse: (value: string) => value === "true",
    stringify: (value: boolean) => value.toString(),
  };

  return useLocalStorage(key, defaultValue, { codec: booleanCodec });
}

export function useLocalStorageStringState(key: string, defaultValue: string) {
  const stringCodec: StorageCodec<string> = {
    parse: (value: string) => value,
    stringify: (value: string) => value,
  };

  return useLocalStorage(key, defaultValue, { codec: stringCodec });
}
