import React, { useEffect, useState, useMemo } from "react";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Tabs,
  Tab,
  Avatar,
  Chip,
  Link,
  Card,
  CardContent,
  CardActionArea,
  Tooltip,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion, useAnimationFrame } from "framer-motion";
import { SelectChangeEvent } from "@mui/material/Select";
import { useAuth } from "hooks/auth/useAuth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useFreelancerHomeStatistics } from "hooks/freelancer/useFreelancerHomeStatistics";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import TimeTrackingCharts from "./components/TimeTrackingCharts";
import { getCountsOfProjectAssignmentStatuses } from "core/projectAssignments/utils";
import { IndependentEngineerAgreement } from "@freetech/models/user";
import {
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  Description as DescriptionIcon,
  Business as BusinessIcon,
  Campaign as CampaignIcon,
} from "@mui/icons-material";
import { Theme, SxProps } from "@mui/material/styles";
import { ManageSearch as ManageSearchIcon } from "@mui/icons-material";
import { DateTime } from "luxon";

import { useFreelancerSignAgreements } from "hooks/freelancer";
import { useHoverAnimation } from "hooks/animation/useHoverAnimation";
import { FreelancerStatusTimeline } from "./components/FreelancerStatusTimeline";
import { useNavigation } from "hooks/useNavigation";
import { FreeTechLogoLoading } from "components/FreeTechLogoLoading";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

interface WelcomeMessageState {
  message: React.ReactNode;
  type: "success" | "warning" | "info" | "error";
}

interface ProjectStatusData {
  id: number;
  value: number;
  label: string;
  color: string;
}

interface QuickAction {
  icon: React.ReactNode;
  label: string;
  path: string;
  badgeCount?: number;
  breadcrumb: string;
}

interface TimeFilterOption {
  label: string;
  period:
    | "thisYear"
    | "lastYear"
    | "thisQuarter"
    | "lastQuarter"
    | "thisMonth"
    | "lastMonth";
  startDate: DateTime;
  endDate: DateTime;
}

interface TimeFilter {
  period: TimeFilterOption["period"];
  startDate: DateTime;
  endDate: DateTime;
}

const getProjectStatusChartData = (counts: {
  interviewing: number;
  ready: number;
  signing: number;
  in_progress: number;
  complete: number;
  cancelled: number;
}): ProjectStatusData[] => {
  return [
    {
      id: 0,
      value: counts.in_progress,
      label: "In Progress",
      color: "#1976d2",
    },
    { id: 1, value: counts.complete, label: "Complete", color: "#2e7d32" },
    { id: 2, value: counts.signing, label: "Signing", color: "#ed6c02" },
    {
      id: 3,
      value: counts.interviewing,
      label: "Interviewing",
      color: "#9c27b0",
    },
    { id: 4, value: counts.ready, label: "Ready", color: "#0288d1" },
    { id: 5, value: counts.cancelled, label: "Cancelled", color: "#d32f2f" },
  ].filter((item) => item.value > 0); // Only show statuses with non-zero values
};

const getWelcomeMessage = (
  freelancerProfile: any,
  independentEngineerAgreement: IndependentEngineerAgreement | null,
  projectAssignmentStatusCounts: {
    interviewing: number;
    ready: number;
    signing: number;
    in_progress: number;
    complete: number;
    cancelled: number;
  }
): WelcomeMessageState => {
  // No Independent Engineer Agreement
  if (!freelancerProfile?.currentIndependentEngineerAgreementId) {
    return {
      type: "warning",
      message: (
        <>
          Welcome to FreeTech! To begin working on projects, you'll need to
          complete your Independent Engineer Agreement. You will receive an
          email notification when your agreement is ready for signature. In the
          meantime, please reach out to admin@freetech.co for further
          independent contractor onboarding information.
        </>
      ),
    };
  }

  // Independent Engineer Agreement is Sent but Not Signed by adminn
  if (
    independentEngineerAgreement &&
    independentEngineerAgreement.everSignDocumentStatus ===
      "awaiting_admin_signature"
  ) {
    return {
      type: "info",
      message: (
        <>
          Your Independent Engineer Agreement has been sent to a FreeTech
          representative for signature. You will receive an email notification
          when your agreement is ready for signature.
        </>
      ),
    };
  }

  // Independent Engineer Agreement is Sent but Not Signed by freelancer - is signed by admin
  if (
    independentEngineerAgreement &&
    independentEngineerAgreement.everSignDocumentStatus ===
      "awaiting_engineer_signature"
  ) {
    return {
      type: "info",
      message: (
        <>
          Great news! Your Independent Engineer Agreement is ready for your
          signature. You can find this under{" "}
          <Link component={RouterLink} to="/sign-agreements" color="primary">
            Sign Agreements
          </Link>{" "}
          and you should receive an email with signing instructions shortly.
          Need help? Send us a message at admin@freetech.co.
        </>
      ),
    };
  }

  // Has Agreement but No Project Assignments
  if (!freelancerProfile.projectAssignments) {
    return {
      type: "info",
      message:
        "Congratulations! You've completed your Independent Engineer Agreement. Before you can start working on FreeTech projects, you will need a Project Assignment. Please review your Independent Engineer Agreement and contact admin@freetech.co if you have any questions.",
    };
  }

  // Has Completed Projects but No Active Ones
  if (
    projectAssignmentStatusCounts.in_progress === 0 &&
    projectAssignmentStatusCounts.complete > 0
  ) {
    return {
      type: "info",
      message:
        "You currently have no active project assignments. Contact admin@freetech.co if you're interested in taking on new projects.",
    };
  }

  // Has Projects in Signing Phase
  if (projectAssignmentStatusCounts.signing > 0) {
    return {
      type: "info",
      message: `You have ${projectAssignmentStatusCounts.signing} project assignment${projectAssignmentStatusCounts.signing > 1 ? "s" : ""} pending signature. Please check your email for the signing link or contact admin@freetech.co for assistance.`,
    };
  }

  // Has Active Projects
  if (projectAssignmentStatusCounts.in_progress > 0) {
    return {
      type: "success",
      message: `You have ${projectAssignmentStatusCounts.in_progress} active project assignment${projectAssignmentStatusCounts.in_progress > 1 ? "s" : ""}. Check out your overview and time tracking information below.`,
    };
  }

  // Default State (should rarely hit this)
  return {
    type: "info",
    message:
      "Please contact admin@freetech.co for further information about becoming an independent contractor with FreeTech.",
  };
};

const MotionBox = motion(Box);
const MotionPaper = motion(Paper);

// Style definitions
const darkContainerStyles: SxProps<Theme> = {
  background: "linear-gradient(145deg, #3a3a3a 0%, #2d2d2d 100%)",
  border: "1px solid",
  borderColor: "grey.800",
  boxShadow: "0px 12px 32px rgba(0, 0, 0, 0.18)",
  borderRadius: 3,
} as const;

const whiteCardStyles: SxProps<Theme> = {
  bgcolor: "#ffffff",
  borderRadius: 3,
  border: "1px solid",
  borderColor: "grey.200",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0px 12px 28px rgba(0, 0, 0, 0.12)",
  },
} as const;

const staticWhiteCardStyles: SxProps<Theme> = {
  bgcolor: "#ffffff",
  borderRadius: 3,
  border: "1px solid",
  borderColor: "grey.200",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
} as const;

const filterControlStyles: SxProps<Theme> = {
  minWidth: 120,
  mr: 2,
  bgcolor: "#ffffff",
  borderRadius: 1,
  "& .MuiInputBase-root": {
    color: "grey.900",
    borderColor: "grey.200",
    bgcolor: "#ffffff",
  },
  "& .MuiInputLabel-root": {
    color: "grey.600",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "grey.200",
  },
  "& .MuiSelect-icon": {
    color: "grey.600",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "grey.300",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.main",
  },
} as const;

const referralCardStyles: SxProps<Theme> = {
  bgcolor: "grey.800",
  borderRadius: 2,
  p: 2.5,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.2)",
  border: "1px solid rgba(255, 255, 255, 0.08)",
  backdropFilter: "blur(8px)",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "linear-gradient(225deg, rgba(13,71,161,0.1) 0%, rgba(0,0,0,0) 100%)",
    zIndex: 0,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    top: -100,
    left: -100,
    right: -100,
    bottom: -100,
    background:
      "radial-gradient(circle at center, rgba(25,118,210,0.12) 0%, rgba(0,0,0,0) 70%)",
    animation: "pulse 3s ease-in-out infinite",
  },
  "& > *": {
    position: "relative",
    zIndex: 1,
  },
  "@keyframes pulse": {
    "0%": {
      opacity: 0.8,
      transform: "scale(0.8)",
    },
    "50%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "100%": {
      opacity: 0.8,
      transform: "scale(0.8)",
    },
  },
} as const;

const getTimeFilterOptions = (): TimeFilterOption[] => {
  const now = DateTime.now();
  return [
    {
      label: "This Year",
      period: "thisYear",
      startDate: now.startOf("year"),
      endDate: now.endOf("year"),
    },
    {
      label: "Last Year",
      period: "lastYear",
      startDate: now.minus({ years: 1 }).startOf("year"),
      endDate: now.minus({ years: 1 }).endOf("year"),
    },
    {
      label: "This Quarter",
      period: "thisQuarter",
      startDate: now.startOf("quarter"),
      endDate: now.endOf("quarter"),
    },
    {
      label: "Last Quarter",
      period: "lastQuarter",
      startDate: now.minus({ quarters: 1 }).startOf("quarter"),
      endDate: now.minus({ quarters: 1 }).endOf("quarter"),
    },
    {
      label: "This Month",
      period: "thisMonth",
      startDate: now.startOf("month"),
      endDate: now.endOf("month"),
    },
    {
      label: "Last Month",
      period: "lastMonth",
      startDate: now.minus({ months: 1 }).startOf("month"),
      endDate: now.minus({ months: 1 }).endOf("month"),
    },
  ];
};

const FreelancerHome: React.FC = () => {
  const timeFilterOptions = useMemo(() => getTimeFilterOptions(), []);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>({
    ...timeFilterOptions[0], // Default to "This Year"
  });
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { handleNavigation } = useNavigation();

  const {
    freelancerProfile,
    isLoadingFreelancerProfile,
    independentEngineerAgreement,
  } = useFreelancerProfile();

  const { agreementsToSign, isLoading: isLoadingAgreements } =
    useFreelancerSignAgreements();

  const hoverRef = useHoverAnimation();

  useEffect(() => {
    if (userInfo?.role === "admin") {
      navigate("/admin");
    }
  }, [userInfo, navigate]);

  const handleTimeFilterChange = (event: SelectChangeEvent<string>) => {
    const selectedOption = timeFilterOptions.find(
      (opt) => opt.period === event.target.value
    );
    if (selectedOption) {
      setTimeFilter({
        period: selectedOption.period,
        startDate: selectedOption.startDate,
        endDate: selectedOption.endDate,
      });
    }
  };

  if (isLoadingFreelancerProfile || !freelancerProfile) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <FreeTechLogoLoading />
      </Box>
    );
  }

  const projectAssignmentStatusCounts = getCountsOfProjectAssignmentStatuses(
    freelancerProfile?.projectAssignments || []
  );

  const welcomeMessageState = getWelcomeMessage(
    freelancerProfile,
    independentEngineerAgreement || null,
    projectAssignmentStatusCounts
  );

  const numberOfAgreementsPendingSignature = agreementsToSign?.length || 0;

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        bgcolor: "grey.100",
        p: { xs: 2, md: 4 },
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={2}
            sx={{
              bgcolor: "#ffffff",
              borderRadius: 2,
              p: 2.5,
              height: "100%",
              border: "1px solid",
              borderColor: "grey.200",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: 800,
                mb: 1.5,
                color: "grey.900",
                letterSpacing: "-0.02em",
                fontSize: { xs: "2rem", md: "2.5rem" },
                lineHeight: 1.2,
              }}
            >
              Welcome back, {freelancerProfile?.firstName}{" "}
              {freelancerProfile?.lastName}!
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "grey.600",
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: 1.6,
                maxWidth: "800px",
                fontWeight: 500,
                position: "relative",
                pl: 2,
                "&:before": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  top: 4,
                  bottom: 4,
                  width: 4,
                  borderRadius: 2,
                  bgcolor: (theme) =>
                    theme.palette[welcomeMessageState.type].main,
                },
              }}
            >
              {welcomeMessageState.message}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <motion.div ref={hoverRef} style={{ height: "100%" }}>
            <Paper
              elevation={2}
              sx={{
                bgcolor: "#ffffff",
                borderRadius: 2,
                p: 2.5,
                height: "100%",
                border: "1px solid",
                borderColor: "grey.200",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FreelancerStatusTimeline
                freelancerProfile={freelancerProfile}
                independentEngineerAgreement={
                  independentEngineerAgreement || null
                }
                projectAssignmentStatusCounts={
                  projectAssignmentStatusCounts
                }
              />
            </Paper>
          </motion.div>
        </Grid>
        <Grid item xs={12} md={5}>
          <motion.div ref={hoverRef} style={{ height: "100%" }}>
            <Paper
              elevation={4}
              sx={{
                ...referralCardStyles,
                minHeight: { xs: "auto", md: "100%" },
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CampaignIcon 
                  sx={{ 
                    color: '#ffffff',
                    fontSize: '1.75rem',
                    mr: 1.5,
                    opacity: 0.9
                  }} 
                />
                <Typography
                  variant="h5"
                  sx={{
                    color: "#ffffff",
                    fontWeight: 700,
                  }}
                >
                  Got Leads?
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "grey.300",
                  mb: 2,
                  lineHeight: 1.6,
                }}
              >
                Earn up to 20% commission with our referral program when
                your referred clients begin project work. Register to
                become part of our referral network and start earning
                today.
              </Typography>
              <Typography
                sx={{
                  color: "grey.400",
                  mb: 2,
                  fontSize: "0.9rem",
                }}
              >
                Questions about the program? Reach out to
                admin@freetech.co for more information.
              </Typography>
              <Link
                component={RouterLink}
                to="/freelancer/referral-program"
                sx={{
                  background:
                    "linear-gradient(135deg, #1a1a1a 0%, rgba(0, 98, 255, 0.15) 50%, #1a1a1a 100%)",
                  color: "#ffffff",
                  textDecoration: "none",
                  fontWeight: 700,
                  fontSize: "1.1rem",
                  display: "inline-flex",
                  alignItems: "center",
                  padding: "12px 24px",
                  borderRadius: "8px",
                  transition: "all 0.3s ease-in-out",
                  border: "1px solid rgba(0, 98, 255, 0.2)",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  alignSelf: "flex-start",
                  width: "fit-content",
                  "&:hover": {
                    background:
                      "linear-gradient(135deg, #1a1a1a 0%, rgba(0, 98, 255, 0.25) 50%, #1a1a1a 100%)",
                    transform: "translateY(-1px)",
                    boxShadow: "0 6px 16px rgba(0, 98, 255, 0.15)",
                    borderColor: "rgba(0, 98, 255, 0.4)",
                  },
                }}
              >
                Join the referral program →
              </Link>
            </Paper>
          </motion.div>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <MotionPaper
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              elevation={2}
              sx={{
                p: 3,
                height: "100%",
                ...darkContainerStyles,
              }}
            >
              <Box display="flex" alignItems="center" mb={3}>
                <Avatar
                  src={freelancerProfile?.profilePicture}
                  alt={`${freelancerProfile?.firstName} ${freelancerProfile?.lastName}`}
                  sx={{
                    width: 80,
                    height: 80,
                    mr: 2,
                    border: "4px solid",
                    borderColor: "grey.800",
                  }}
                />
                <Box>
                  <Typography
                    variant="h5"
                    color="#ffffff"
                    sx={{ fontWeight: 600 }}
                  >
                    {freelancerProfile?.firstName} {freelancerProfile?.lastName}
                  </Typography>
                  <Typography color="grey.400">
                    {freelancerProfile?.email}
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="h6"
                gutterBottom
                color="#ffffff"
                sx={{ fontWeight: 600 }}
              >
                Quick Actions
              </Typography>
              <Stack spacing={2}>
                {[
                  {
                    icon: <DescriptionIcon sx={{ fontSize: 24 }} />,
                    badgeCount: numberOfAgreementsPendingSignature,
                    label: "Sign Pending Agreements",
                    path: "/freelancer/sign-agreements",
                    breadcrumb: "Sign Agreements",
                  },
                  {
                    icon: <AccessTimeIcon sx={{ fontSize: 24 }} />,
                    label: "Track Time",
                    path: "/freelancer/timesheet",
                    breadcrumb: "Time Tracking",
                  },
                  {
                    icon: <ManageSearchIcon sx={{ fontSize: 24 }} />,
                    label: "Time & Project Explorer",
                    path: "/freelancer/project-explorer",
                    breadcrumb: "Project Explorer",
                  },
                ].map((action, index) => (
                  <Card
                    key={index}
                    sx={{
                      ...whiteCardStyles,
                      borderRadius: 2,
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleNavigation(action.path)}
                      sx={{
                        "&:hover": {
                          bgcolor: "grey.50",
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 2,
                        }}
                      >
                        <Box
                          sx={{
                            mr: 2,
                            color: "grey.900",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: "grey.100",
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                          }}
                        >
                          {action.badgeCount ? (
                            <Badge
                              badgeContent={action.badgeCount}
                              color="error"
                              sx={{
                                "& .MuiBadge-badge": {
                                  fontSize: "0.75rem",
                                  height: "20px",
                                  minWidth: "20px",
                                  right: -3,
                                  top: 3,
                                },
                              }}
                            >
                              {action.icon}
                            </Badge>
                          ) : (
                            action.icon
                          )}
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "0.95rem",
                            color: "grey.900",
                          }}
                        >
                          {action.label}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Stack>
            </MotionPaper>
          </Grid>

          <Grid item xs={12} md={8}>
            <MotionPaper
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
              elevation={2}
              sx={{
                p: 4,
                height: "100%",
                ...darkContainerStyles,
              }}
            >
              <Box
                sx={{
                  mb: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#ffffff",
                    fontWeight: 800,
                    letterSpacing: "-0.02em",
                    fontSize: { xs: "2rem", md: "2.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  Overview
                </Typography>
                <FormControl sx={filterControlStyles}>
                  <Select
                    labelId="time-filter-label"
                    id="time-filter"
                    value={timeFilter.period}
                    onChange={handleTimeFilterChange}
                  >
                    {timeFilterOptions.map((option) => (
                      <MenuItem key={option.period} value={option.period}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <TimeTrackingCharts timeFilter={timeFilter} />
            </MotionPaper>
          </Grid>
        </Grid>
      </Box>
    </MotionBox>
  );
};

export default FreelancerHome;
