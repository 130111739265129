import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  NotificationPreference,
  NotificationPreferences,
  AllNotificationType,
} from "@freetech/models/notifications";

// Extend NotificationPreferences to allow string indexing
interface ExtendedNotificationPreferences extends NotificationPreferences {
  [key: string]: NotificationPreference;
}

interface NotificationSectionProps {
  title: string;
  expandedSubsections: Set<string>;
  notificationPreferences: ExtendedNotificationPreferences;
  handleSubsectionExpand: (subsection: string) => void;
  notificationTypes: AllNotificationType[];
  onToggle: (type: string, channel: "email" | "sms") => void;
}

const NotificationSection: React.FC<NotificationSectionProps> = ({
  title,
  expandedSubsections,
  notificationPreferences,
  handleSubsectionExpand,
  notificationTypes,
  onToggle,
}) => {
  const formatNotificationType = (type: string): string => {
    // Remove the prefix before the first underscore (e.g., "stakeholder_", "freelo_", "admin_")
    const withoutPrefix = type.includes("_")
      ? type.substring(type.indexOf("_") + 1)
      : type;

    // Convert to title case
    return withoutPrefix
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Function to toggle all notifications of a specific channel (email or SMS)
  const toggleAllNotifications = (
    channel: "email" | "sms",
    enable: boolean
  ) => {
    // Only toggle notifications that don't already match the target state
    notificationTypes.forEach((type) => {
      const typeString = type.toString();
      const preference = notificationPreferences[typeString] || {
        email: false,
        sms: false,
      };

      // Only toggle if the current state doesn't match the target state
      if (preference[channel] !== enable) {
        onToggle(typeString, channel);
      }
    });
  };

  // Calculate if all notifications of a specific channel are enabled
  const areAllEnabled = (channel: "email" | "sms"): boolean => {
    return notificationTypes.every((type) => {
      const typeString = type.toString();
      const preference = notificationPreferences[typeString] || {
        email: false,
        sms: false,
      };
      return preference[channel];
    });
  };

  // Calculate if all notifications of a specific channel are disabled
  const areAllDisabled = (channel: "email" | "sms"): boolean => {
    return notificationTypes.every((type) => {
      const typeString = type.toString();
      const preference = notificationPreferences[typeString] || {
        email: false,
        sms: false,
      };
      return !preference[channel];
    });
  };

  // Get the toggle state for each channel
  const getToggleState = (channel: "email" | "sms"): string => {
    if (areAllEnabled(channel)) return "all";
    if (areAllDisabled(channel)) return "none";
    return "some";
  };

  const emailToggleState = getToggleState("email");
  const smsToggleState = getToggleState("sms");

  return (
    <Accordion
      expanded={expandedSubsections.has(title)}
      onChange={() => handleSubsectionExpand(title)}
      sx={{ borderRadius: 1, overflow: "hidden" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {/* Toggle All Controls */}
          <Box sx={{ mb: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Email
                </Typography>
                <ToggleButtonGroup
                  value={emailToggleState}
                  exclusive
                  onChange={(e, value) => {
                    if (value === null) return; // Prevent deselection
                    toggleAllNotifications("email", value === "all");
                  }}
                  size="small"
                  fullWidth
                >
                  <ToggleButton value="all" color="primary">
                    All
                  </ToggleButton>
                  <ToggleButton value="none" color="primary">
                    None
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  SMS
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ToggleButtonGroup
                    value="none"
                    exclusive
                    disabled
                    size="small"
                    fullWidth
                  >
                    <ToggleButton value="all" color="primary">
                      All
                    </ToggleButton>
                    <ToggleButton value="none" color="primary">
                      None
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Typography 
                    variant="caption" 
                    color="text.secondary" 
                    sx={{ ml: 1, fontStyle: "italic" }}
                  >
                    Coming soon
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>

          <Divider sx={{ my: 1 }} />

          {/* Individual Notification Controls */}
          {notificationTypes.map((type) => {
            const typeString = type.toString();
            // Ensure we have a valid preference object for this type
            const preference = notificationPreferences[typeString] || {
              email: false,
              sms: false,
            };

            return (
              <Box key={typeString}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle1">
                    {formatNotificationType(typeString)}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={preference.email}
                            onChange={() => onToggle(typeString, "email")}
                            color="primary"
                          />
                        }
                        label="Email"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={preference.sms}
                            onChange={() => onToggle(typeString, "sms")}
                            color="primary"
                            disabled
                          />
                        }
                        label="SMS"
                      />
                    </FormGroup>
                  </Box>
                </Box>
                <Divider />
              </Box>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default NotificationSection;
