import React from "react";
import { Box, Paper, TextField, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { Project } from "@freetech/models/projects";

interface TimesheetFiltersProps {
  startDate: DateTime;
  endDate: DateTime;
  searchTerm: string;
  selectedProjects: string[];
  myProjects: Project[];
  onStartDateChange: (date: DateTime | null) => void;
  onEndDateChange: (date: DateTime | null) => void;
  onSearchChange: (value: string) => void;
  onProjectsChange: (projects: string[]) => void;
}

export const TimesheetFilters: React.FC<TimesheetFiltersProps> = ({
  startDate,
  endDate,
  searchTerm,
  selectedProjects,
  myProjects,
  onStartDateChange,
  onEndDateChange,
  onSearchChange,
  onProjectsChange,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: { xs: 4, sm: 2 },
        width: { xs: "90vw", md: "100%" },
        mr: { xs: -2, sm: 0 },
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: { xs: "70vw", md: "100%" },
          maxWidth: { xs: "400px", md: "800px" },
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          boxSizing: "border-box",
          px: { xs: 1, sm: 2 },
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            gap: 1.5,
            width: "100%",
          }}
        >
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={onStartDateChange}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                sx: { maxWidth: "100%" },
              },
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={onEndDateChange}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                sx: { maxWidth: "100%" },
              },
            }}
          />
        </Box>

        <Autocomplete<Project, true>
          multiple
          size="small"
          options={myProjects}
          getOptionLabel={(option) => option.name}
          value={myProjects.filter((p) => selectedProjects.includes(p.id))}
          onChange={(_, newValue) => {
            onProjectsChange(newValue.map((v) => v.id));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Filter by Project" />
          )}
        />

        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          fullWidth
        />
      </Box>
    </Paper>
  );
}; 