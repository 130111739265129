import React from "react";
import {
  Box,
  Typography,
  Card as MUICard,
  CardContent,
  Grid,
  Paper,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Alert,
  Chip,
  Link,
  CircularProgress
} from "@mui/material";
import {
  School as SchoolIcon,
  Help as HelpIcon,
  Group as GroupIcon,
  Assignment as TaskIcon,
  Chat as SlackIcon,
  VideoCall as MeetIcon,
  Send as SendIcon,
  Person as PersonIcon,
  Lock as LockIcon
} from "@mui/icons-material";
import { useAuth } from "hooks/auth";
import { PageTitle } from "components/PageTitle";
import { useStudent } from "hooks/student/useStudent";
import { useStudentManageSubscription } from "hooks/student/useStudentManageSubscription";
import { useStudentMentorship } from "hooks/student/useStudentMentorship";
import { DateTime } from "luxon";
import { CohortMember } from "@freetech/models/student";
import { Card as FreloCard } from "@freetech/models/freelo";
import { BlurredContent } from "components/BlurredContent";

export const StudentHome: React.FC = () => {
  const { userInfo } = useAuth();
  
  // Get subscription info
  const { subscription, isLoading: isSubscriptionLoading } = useStudentManageSubscription();
  const hasActiveSubscription = subscription?.status === 'active';
  
  // Get student data from useStudent hook with individual loading states
  const { 
    cohortInfo, 
    mentorInfo: studentMentorInfo, 
    cohortMembers, 
    freeloBoard, 
    recentTasks,
    pendingAssistanceRequest,
    isLoading: isStudentLoading,
  } = useStudent();

  // Use useStudentMentorship to ensure we get mentor information
  const {
    mentorInfo: mentorshipMentorInfo,
    isLoading: isMentorshipLoading
  } = useStudentMentorship();

  // Combine mentor information from both hooks, preferring studentMentorInfo if available
  const mentorInfo = studentMentorInfo || mentorshipMentorInfo;
  
  // Use separate loading states for each section
  const isCohortLoading = isStudentLoading && !cohortInfo;
  const isMentorLoading = (isStudentLoading || isMentorshipLoading) && !mentorInfo;
  const isTasksLoading = isStudentLoading && !freeloBoard;
  
  // Only show full page loader if subscription is loading (critical data)
  const isPageLoading = isSubscriptionLoading;
  
  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return "N/A";
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED);
  };

  // Format time for display
  const formatTime = (timeString?: string) => {
    if (!timeString) return "N/A";
    try {
      const [hours, minutes] = timeString.split(":");
      return DateTime.now()
        .set({ hour: parseInt(hours, 10), minute: parseInt(minutes, 10) })
        .toFormat("h:mm a");
    } catch (e) {
      return timeString;
    }
  };

  // Show loading spinner while subscription data is being fetched
  if (isPageLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <PageTitle
        title={`Welcome, ${userInfo?.firstName || "Student"}!`}
        subtitle="Your FreeTech Learning Dashboard"
        bulletPoints={[
          "Track your assignments on your cohort's Freelo board",
          "Connect with cohort members through Slack",
          "Attend weekly meetings with your mentor",
          hasActiveSubscription ? "Full access to all resources and features" : "Activate your subscription for full access"
        ]}
      />

      {!hasActiveSubscription && (
        <Alert 
          severity="warning" 
          sx={{ mb: 3 }}
          action={
            <Button 
              color="primary" 
              variant="contained" 
              size="small" 
              component={Link} 
              href="/student/manage-subscription"
            >
              Activate Subscription
            </Button>
          }
        >
          Your subscription is not active. Some content is hidden or limited. Please activate your subscription for full access.
        </Alert>
      )}

      <Grid container spacing={3} sx={{ mt: 1 }}>
        {/* Main content area */}
        <Grid item xs={12} md={8}>
          {pendingAssistanceRequest && hasActiveSubscription && (
            <Alert 
              severity="info" 
              sx={{ mb: 3 }}
              action={
                <Chip 
                  label="Pending" 
                  color="primary" 
                  variant="outlined" 
                  size="small" 
                />
              }
            >
              You have a pending assistance request from {formatDate(pendingAssistanceRequest.requestedAt)}:
              <Typography variant="body2" sx={{ mt: 1, fontStyle: "italic" }}>
                "{pendingAssistanceRequest.message}"
              </Typography>
            </Alert>
          )}

          <MUICard sx={{ mb: 3, boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Program Overview
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body2" paragraph>
                Welcome to FreeTech's student program! As a student, you'll be guided through a structured learning path with hands-on projects and regular mentorship.
              </Typography>
              <Typography variant="body2" paragraph>
                All your tasks and assignments will be tracked on your cohort's Freelo board. Your mentor will assign tasks, provide feedback, and track your progress there.
              </Typography>
              <Typography variant="body2" paragraph>
                Your mentor is here to support your learning journey throughout the program.
              </Typography>
              <Typography variant="body2">
                Make sure to stay connected with your cohort through the designated Slack channel and attend all scheduled meetings.
              </Typography>
            </CardContent>
          </MUICard>

          <MUICard sx={{ boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                Your Cohort
                {!hasActiveSubscription && <LockIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              {isCohortLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <BlurredContent title="Cohort Information">
                  <Box 
                    sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      minHeight: '100px'
                    }}
                  >
                    {!cohortInfo ? (
                      <Alert severity="info">
                        You're not currently assigned to a cohort. Please contact support if this is an error.
                      </Alert>
                    ) : (
                      <>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            bgcolor: "background.default",
                            borderRadius: 2,
                            border: 1,
                            borderColor: "divider",
                            mb: 3
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="subtitle1">
                                {cohortInfo.name}
                              </Typography>
                              {cohortInfo.description && (
                                <Typography variant="body2" color="text.secondary">
                                  {cohortInfo.description}
                                </Typography>
                              )}
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <SchoolIcon fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
                                <Typography variant="body2">
                                  Started: {formatDate(cohortInfo.startDate)}
                                  {cohortInfo.endDate && ` • Ends: ${formatDate(cohortInfo.endDate)}`}
                                </Typography>
                              </Box>
                            </Grid>
                            
                            {cohortInfo.weeklyMeetingDay && cohortInfo.weeklyMeetingTime && (
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <MeetIcon fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
                                  <Typography variant="body2">
                                    Weekly Meeting: {cohortInfo.weeklyMeetingDay}s at {formatTime(cohortInfo.weeklyMeetingTime)}
                                    {cohortInfo.weeklyMeetingDuration && ` (${cohortInfo.weeklyMeetingDuration} min)`}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                            
                            {cohortInfo.weeklyMeetingLink && (
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <Link 
                                    href={cohortInfo.weeklyMeetingLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ display: "flex", alignItems: "center" }}
                                  >
                                    <MeetIcon fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
                                    <Typography variant="body2">
                                      Meeting Link
                                    </Typography>
                                  </Link>
                                </Box>
                              </Grid>
                            )}
                            
                            {cohortInfo.slackChannel && (
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <SlackIcon fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
                                  <Typography variant="body2">
                                    Slack: #{cohortInfo.slackChannel}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                        
                        <Typography variant="subtitle2" gutterBottom>
                          Cohort Members
                        </Typography>
                        
                        {cohortMembers && cohortMembers.length > 0 ? (
                          <List sx={{ bgcolor: "background.paper", borderRadius: 1 }}>
                            {cohortMembers.map((member: CohortMember) => (
                              <ListItem key={member.id} sx={{ px: 2, py: 1 }}>
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                  <PersonIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={member.displayName}
                                  secondary={member.email}
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Alert severity="info">
                            No other members found in your cohort.
                          </Alert>
                        )}
                      </>
                    )}
                  </Box>
                </BlurredContent>
              )}
            </CardContent>
          </MUICard>
        </Grid>

        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <MUICard sx={{ boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  Your Mentor
                  {!hasActiveSubscription && <LockIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                
                {isMentorLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <BlurredContent title="Mentor Information">
                    <Box 
                      sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: '100px'
                      }}
                    >
                      {!mentorInfo ? (
                        <Alert severity="info">
                          You don't have a mentor assigned yet.
                        </Alert>
                      ) : (
                        <>
                          <Box sx={{ display: "flex", mb: 2 }}>
                            <Box
                              sx={{
                                width: 70,
                                height: 70,
                                borderRadius: "50%",
                                bgcolor: "primary.light",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mr: 2,
                              }}
                            >
                              {mentorInfo.profilePicture ? (
                                <img
                                  src={mentorInfo.profilePicture}
                                  alt={mentorInfo.displayName}
                                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                />
                              ) : (
                                <Typography variant="h4" color="white">
                                  {mentorInfo.firstName?.[0] || ""}{mentorInfo.lastName?.[0] || ""}
                                </Typography>
                              )}
                            </Box>
                            <Box>
                              <Typography variant="h6">{mentorInfo.displayName}</Typography>
                              <Typography variant="body2" color="text.secondary">
                                {mentorInfo.email}
                              </Typography>
                            </Box>
                          </Box>
                          
                          {pendingAssistanceRequest && hasActiveSubscription && (
                            <Alert severity="info" sx={{ mt: 1 }}>
                              You have a pending assistance request. Your mentor will respond soon.
                            </Alert>
                          )}
                        </>
                      )}
                    </Box>
                  </BlurredContent>
                )}
              </CardContent>
            </MUICard>

            <MUICard sx={{ boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  Your Freelo Tasks
                  {!hasActiveSubscription && <LockIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                
                {isTasksLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <BlurredContent title="Tasks Information">
                    <Box 
                      sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: '100px'
                      }}
                    >
                      {!freeloBoard ? (
                        <Alert severity="info">
                          Your cohort doesn't have a Freelo board assigned yet. Your mentor will set this up soon.
                        </Alert>
                      ) : (
                        <>
                          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                            <SchoolIcon sx={{ mr: 1, color: "primary.main" }} />
                            <Typography variant="body1">
                              Your tasks are tracked on the "{freeloBoard.name}" Freelo board.
                            </Typography>
                          </Box>
                          
                          <Button 
                            variant="contained" 
                            color="primary"
                            startIcon={<TaskIcon />}
                            sx={{ mb: 3 }}
                            href={`/freelo/board/${freeloBoard.id}`}
                            fullWidth
                          >
                            View My Freelo Board
                          </Button>
                          
                          {recentTasks && recentTasks.length > 0 ? (
                            <>
                              <Typography variant="subtitle2" gutterBottom>
                                Recent Tasks:
                              </Typography>
                              <List>
                                {recentTasks.map((task: FreloCard) => (
                                  <ListItem 
                                    key={task.id}
                                    sx={{ 
                                      px: 2, 
                                      py: 1, 
                                      mb: 1, 
                                      bgcolor: "background.paper", 
                                      borderRadius: 1,
                                      border: 1,
                                      borderColor: "divider" 
                                    }}
                                  >
                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                      <TaskIcon color={task.archived ? "disabled" : "primary"} />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <Link 
                                          href={`/freelo/card/${task.id}`}
                                        >
                                          {task.title}
                                        </Link>
                                      }
                                      secondary={
                                        <>
                                          {task.dueDate && <>Due: {formatDate(task.dueDate)}</>}
                                        </>
                                      }
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </>
                          ) : (
                            <Alert severity="info" sx={{ mt: 2 }}>
                              No recent tasks found. Check your Freelo board for assignments.
                            </Alert>
                          )}
                        </>
                      )}
                    </Box>
                  </BlurredContent>
                )}
              </CardContent>
            </MUICard>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}; 