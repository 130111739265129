import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import { useState, useCallback } from "react";
import {
  IFeatureRequest,
  ICreateFeatureRequest,
  BugReportComment,
  FeatureRequestAttachment,
} from "@freetech/models";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "core/firestore";
import { removeFeatureRequestAttachment, updateFeatureRequestAttachments } from "core/featureRequest";

interface UseStakeholderFeatureRequestProps {
  clientId: string;
}

export const useStakeholderFeatureRequest = ({
  clientId,
}: UseStakeholderFeatureRequestProps) => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  // Get feature requests for the current project
  const { data: featureRequests, isLoading: isLoadingRequests } = useQuery({
    queryKey: ["featureRequests", clientId],
    queryFn: async () => {
      if (!clientId || !userInfo) {
        return [];
      }

      const q = query(
        collection(db, "clients", clientId, "featureRequests"),
        where("clientId", "==", clientId)
      );

      const requests = await getDocs(q);

      return requests.docs.map((doc) => {
        const data = doc.data() as IFeatureRequest;
        return {
          ...data,
          id: doc.id,
        };
      });
    },
    select: (data) => data,
    enabled: !!clientId && !!userInfo,
  });

  // Get a single feature request by ID
  const getFeatureRequest = async (featureRequestId: string) => {
    if (!clientId || !featureRequestId) {
      throw new Error("Client ID and Feature Request ID are required");
    }

    const docRef = doc(
      db,
      "clients",
      clientId,
      "featureRequests",
      featureRequestId
    );
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error("Feature request not found");
    }

    return {
      ...docSnap.data(),
      id: docSnap.id,
    } as IFeatureRequest;
  };

  // Create a new feature request
  const createFeatureRequest = useMutation<
    string,
    Error,
    ICreateFeatureRequest
  >({
    mutationFn: async (data: ICreateFeatureRequest) => {
      if (!userInfo) {
        throw new Error("User must be logged in to create a feature request");
      }

      const featureRequestData: ICreateFeatureRequest = {
        requestedById: userInfo.id,
        requestDate: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: "pending_approval",
        clientId: clientId,
        projectId: data.projectId,
        title: data.title,
        description: data.description,
        priority: data.priority,
        desiredStartDate: data.desiredStartDate,
        desiredCompletionDate: data.desiredCompletionDate,
        clientEstimatedHours: data.clientEstimatedHours,
        clientDollarAmountBudget: data.clientDollarAmountBudget,
      };

      const docRef = await addDoc(
        collection(db, "clients", clientId, "featureRequests"),
        featureRequestData
      );

      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["featureRequests", clientId],
      });
      queryClient.invalidateQueries({
        queryKey: ["useStakeholderProjects-featureRequests"],
      });
    },
  });

  // Update an existing feature request
  const updateFeatureRequest = useMutation<
    void,
    Error,
    { id: string; data: Partial<IFeatureRequest> }
  >({
    mutationFn: async ({ id, data }) => {
      if (!clientId) {
        throw new Error("Client ID is required");
      }

      const updateData = {
        ...data,
        updatedAt: new Date(),
      };

      const docRef = doc(db, "clients", clientId, "featureRequests", id);
      await updateDoc(docRef, updateData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["featureRequests", clientId],
      });
    },
  });

  // Add comment mutation
  const addComment = useMutation({
    mutationFn: async ({
      featureRequestId,
      content,
    }: {
      featureRequestId: string;
      content: string;
    }) => {
      if (!userInfo) throw new Error("User info not found");

      const newComment: BugReportComment = {
        id: crypto.randomUUID(),
        content,
        userId: userInfo.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      const docRef = doc(
        db,
        "clients",
        clientId,
        "featureRequests",
        featureRequestId
      );
      const featureRequest = featureRequests?.find(
        (request) => request.id === featureRequestId
      );

      if (!featureRequest) throw new Error("Feature request not found");

      await updateDoc(docRef, {
        comments: [...(featureRequest.comments || []), newComment],
        updatedAt: new Date().toISOString(),
      });

      return newComment;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["featureRequests", clientId],
      });
    },
  });

  // Add a mutation for removing attachments
  const removeAttachment = useMutation<
    void,
    Error,
    { featureRequestId: string; attachmentId: string }
  >({
    mutationFn: ({ featureRequestId, attachmentId }) =>
      removeFeatureRequestAttachment(clientId, featureRequestId, attachmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["featureRequests", clientId],
      });
    },
  });

  // Add a mutation for uploading attachments
  const uploadAttachment = useMutation<
    void,
    Error,
    { featureRequestId: string; files: File[] }
  >({
    mutationFn: ({ featureRequestId, files }) =>
      updateFeatureRequestAttachments(clientId, featureRequestId, files),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["featureRequests", clientId],
      });
    },
  });

  const submitFeatureRequest = async (data: ICreateFeatureRequest) => {
    try {
      const featureRequestId = await createFeatureRequest.mutateAsync(data);
      return featureRequestId;
    } catch (error) {
      throw error;
    }
  };

  return {
    featureRequests,
    isLoadingRequests,
    submitFeatureRequest,
    getFeatureRequest,
    updateFeatureRequest,
    createFeatureRequest,
    isSubmitting: createFeatureRequest.isPending,
    isUpdating: updateFeatureRequest.isPending,
    // Comment handling
    addComment: addComment.mutateAsync,
    isAddingComment: addComment.isPending,
    // Attachment handling
    removeAttachment: removeAttachment.mutateAsync,
    isRemovingAttachment: removeAttachment.isPending,
    uploadAttachment: uploadAttachment.mutateAsync,
    isUploadingAttachment: uploadAttachment.isPending,
  };
};
