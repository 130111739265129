import { User } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Freelancer, PrivateUserInfoType, Stakeholder } from "@freetech/models/user";
import { privateUserConverter } from"@freetech/models/converters";
import { freelancerConverter } from "@freetech/models/converters";
import { stakeholderConverter } from  "@freetech/models/converters";

const db = getFirestore();

export const getAuthUserProfile = async (
  user: User
): Promise<PrivateUserInfoType | Freelancer | Stakeholder | null> => {
  const userRef = doc(db, `users/${user.uid}`);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) {
    return null;
  }

  const role = docSnap.data()?.role;

  let userData;
  if (role === "freelancer") {
    const freelancerRef = userRef.withConverter(freelancerConverter);
    const freelancerSnap = await getDoc(freelancerRef);
    userData = freelancerSnap.data();
  } else if (role === "stakeholder") {
    const stakeholderRef = userRef.withConverter(stakeholderConverter);
    const stakeholderSnap = await getDoc(stakeholderRef);
    userData = stakeholderSnap.data();
  } else {
    const privateUserRef = userRef.withConverter(privateUserConverter);
    const privateUserSnap = await getDoc(privateUserRef);
    userData = privateUserSnap.data();
  }

  if (!userData) {
    return null;
  }

  return {
    ...userData,
    needsOnboarding: userData.needsOnboarding,
    disabled:
      userData?.disabled || false,
    profilePicture: userData.profilePicture,
  };
};
