import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Mention from '@tiptap/extension-mention';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { createLowlight } from 'lowlight';
import { Extension, Node, Mark } from '@tiptap/core';

// Import highlight.js core
import hljs from 'highlight.js/lib/core';
// Import languages
import javascript from 'highlight.js/lib/languages/javascript';
import typescript from 'highlight.js/lib/languages/typescript';
import xml from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import json from 'highlight.js/lib/languages/json';
import bash from 'highlight.js/lib/languages/bash';
// Add TSX support
import tsx from 'highlight.js/lib/languages/typescript';

// Register languages
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('typescript', typescript);
hljs.registerLanguage('html', xml);
hljs.registerLanguage('css', css);
hljs.registerLanguage('json', json);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('tsx', tsx);

// Create lowlight instance with registered languages
export const lowlight = createLowlight({
  javascript,
  typescript,
  tsx,
  html: xml,
  css,
  json,
  bash,
});

// List of supported languages for code blocks
export const supportedLanguages = [
  'typescript',
  'tsx',
  'javascript',
  'html',
  'css',
  'json',
  'bash',
];

// Default extensions for the editor
export const getDefaultExtensions = (options: {
  placeholder?: string;
  includeCodeBlock?: boolean;
} = {}) => {
  // Define a more generic type for the extensions array
  const extensions: (Extension | Node | Mark)[] = [
    StarterKit.configure({
      codeBlock: options.includeCodeBlock ? false : undefined, // Disable if we're using CodeBlockLowlight
    }),
    Link.configure({
      openOnClick: false,
      HTMLAttributes: {
        class: 'tiptap-link',
      },
    }),
    Image.configure({
      inline: true,
    }),
    Placeholder.configure({
      placeholder: options.placeholder || 'Write something...',
    }),
    TaskList,
    TaskItem.configure({
      nested: true,
    }),
  ];

  if (options.includeCodeBlock) {
    extensions.push(
      CodeBlockLowlight.configure({
        lowlight,
        defaultLanguage: 'tsx',
        HTMLAttributes: {
          class: 'hljs',
          spellcheck: 'false',
          'data-language': 'tsx',
        },
      })
    );
  }

  return extensions;
};

// Configure mention extension
export const getMentionExtension = (
  mentionSuggestions: Array<{ id: string; label: string }> = [],
  onMentionSelect?: (id: string) => void
) => {
  return Mention.configure({
    HTMLAttributes: {
      class: 'mention',
    },
    suggestion: {
      items: ({ query }: { query: string }) => {
        return mentionSuggestions
          .filter((item) =>
            item.label.toLowerCase().includes(query.toLowerCase())
          )
          .slice(0, 5);
      },
      render: () => {
        let popup: any;

        return {
          onStart: (props: any) => {
            popup = document.createElement('div');
            popup.className = 'mention-popup';
            document.body.appendChild(popup);

            popup.style.position = 'absolute';
            popup.style.backgroundColor = 'white';
            popup.style.border = '1px solid #ccc';
            popup.style.borderRadius = '4px';
            popup.style.padding = '4px';
            popup.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';

            const { clientRect } = props;
            if (clientRect) {
              popup.style.left = `${clientRect.left}px`;
              popup.style.top = `${clientRect.top + 24}px`;
            }
          },
          onUpdate: (props: any) => {
            const { items } = props;
            popup.innerHTML = '';
            items.forEach((item: any) => {
              const suggestionItem = document.createElement('div');
              suggestionItem.className = 'mention-popup-item';
              suggestionItem.textContent = item.label;
              suggestionItem.style.padding = '4px 8px';
              suggestionItem.style.cursor = 'pointer';
              suggestionItem.style.borderRadius = '2px';
              suggestionItem.addEventListener('mouseenter', () => {
                suggestionItem.style.backgroundColor = '#f0f0f0';
              });
              suggestionItem.addEventListener('mouseleave', () => {
                suggestionItem.style.backgroundColor = 'transparent';
              });
              suggestionItem.addEventListener('click', () => {
                props.command({ id: item.id, label: item.label });
                if (onMentionSelect) {
                  onMentionSelect(item.id);
                }
              });
              popup.appendChild(suggestionItem);
            });
          },
          onKeyDown: (props: any) => {
            if (props.event.key === 'Escape') {
              popup.remove();
              return true;
            }
            return false;
          },
          onExit: () => {
            popup.remove();
          },
        };
      },
    },
  });
}; 