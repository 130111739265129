import React from "react";
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Chip,
  IconButton,
  CircularProgress,
  Grid,
} from "@mui/material";
import { PhotoCamera as PhotoCameraIcon, Email as EmailIcon } from "@mui/icons-material";
import { useAuth } from "hooks/auth/useAuth";
import { useProfilePicture } from "hooks/auth/useProfilePicture";

const SettingsProfileCard = () => {
  const { userInfo, currentUser } = useAuth();
  const { uploadProfilePicture, isUploading, currentPhotoURL } = useProfilePicture();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      await uploadProfilePicture(file);
    } catch (error) {
      console.error('Failed to update profile picture:', error);
    }
  };

  const fullName = userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : "User";

  return (
    <Paper sx={{ p: 3 }}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "center", sm: "flex-start" }}
        gap={3}
      >
        <Box sx={{ position: "relative" }}>
          <Avatar
            src={currentPhotoURL}
            sx={{
              width: 120,
              height: 120,
              boxShadow: 1,
              border: "4px solid #fff",
            }}
          >
            {userInfo?.firstName?.[0]?.toUpperCase()}
          </Avatar>
          
          {/* Upload Overlay */}
          <IconButton
            component="label"
            sx={{
              position: "absolute",
              bottom: -8,
              right: -8,
              bgcolor: "background.paper",
              boxShadow: 1,
              "&:hover": {
                bgcolor: "background.paper",
              },
            }}
            disabled={isUploading}
          >
            {isUploading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <PhotoCameraIcon />
              </>
            )}
          </IconButton>
        </Box>

        <Box flex={1}>
          <Box mb={3}>
            <Typography variant="h5" gutterBottom>
              {fullName}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
              }}
            >
              <EmailIcon fontSize="small" />
              {currentUser?.email}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                bgcolor: "primary.main",
                color: "primary.contrastText",
                px: 1.5,
                py: 0.5,
                borderRadius: 1,
                textTransform: "capitalize",
              }}
            >
              {userInfo?.role || "N/A"}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Phone
              </Typography>
              <Typography variant="body1">
                {userInfo?.phone || "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Member Since
              </Typography>
              <Typography variant="body1">
                {currentUser?.metadata.creationTime
                  ? new Date(
                      currentUser.metadata.creationTime
                    ).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default SettingsProfileCard;
