import { useRef, useEffect } from 'react';

export const useHoverAnimation = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    
    let rafId: number;
    let startTime = performance.now();
    
    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const y = Math.sin(elapsed / 2000) * 6;
      const x = Math.sin(elapsed / 2500) * 6;
      
      element.style.transform = `translate(${x}px, ${y}px)`;
      rafId = requestAnimationFrame(animate);
    };
    
    rafId = requestAnimationFrame(animate);
    
    return () => {
      if (rafId) cancelAnimationFrame(rafId);
    };
  }, []);
  
  return elementRef;
}; 