import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { Box } from '@mui/material';
import { getDefaultExtensions } from './extensions';
import hljs from 'highlight.js/lib/core';
import './styles/editor.css';
import { Extension, Node, Mark } from '@tiptap/core';

interface ReadOnlyTipTapProps {
  content: string;
  includeCodeBlock?: boolean;
}

const ReadOnlyTipTap: React.FC<ReadOnlyTipTapProps> = ({ 
  content,
  includeCodeBlock = false,
}) => {
  const editor = useEditor({
    extensions: getDefaultExtensions({
      includeCodeBlock,
    }) as (Extension | Node | Mark)[],
    content,
    editable: false,
    editorProps: {
      attributes: {
        class: 'tiptap-editor',
      },
    },
  });

  // Add this effect to manually highlight code blocks if includeCodeBlock is true
  useEffect(() => {
    if (editor && includeCodeBlock) {
      const highlightCodeBlocks = () => {
        // Find all code blocks in the editor
        const codeBlocks = document.querySelectorAll('pre code');
        
        // Apply highlight.js to each code block
        codeBlocks.forEach((block) => {
          // Make sure the language class is set based on the data-language attribute
          const pre = block.closest('pre');
          const language = pre?.getAttribute('data-language');
          
          if (language) {
            // Remove any existing language classes
            block.className = block.className.replace(/language-\w+/g, '');
            
            // Add the appropriate language class
            block.classList.add(`language-${language}`);
            
            // Special handling for TSX/JSX content
            if (language === 'typescript' && block.textContent?.includes('JSX')) {
              block.classList.add('language-tsx');
            }
          }
          
          // Apply highlighting
          hljs.highlightElement(block as HTMLElement);
        });
      };

      // Run once on mount
      setTimeout(highlightCodeBlocks, 100);
    }
  }, [editor, includeCodeBlock]);

  return (
    <Box>
      <EditorContent editor={editor} />
    </Box>
  );
};

export default ReadOnlyTipTap; 