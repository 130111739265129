import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBugReportsCurrentUser,
  getMyBugReports,
  updateBugReportAttachments,
} from "core/bugReport";
import { useAuth } from "hooks/auth/useAuth";
import { useState, useCallback } from "react";
import { getBugReportsForClientIds } from "core/bugReport/getBugReportsForClientIds";

import { IBugReport, ICreateBugReport } from "@freetech/models/bugReport";
import { addDoc } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { db } from "core/firestore";

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "text/plain",
];

export const useBugReport = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const { data: bugReports, isLoading: isLoadingReports } = useQuery({
    queryKey: ["bugReports"],
    queryFn: () => getBugReportsCurrentUser(userInfo!),
    refetchInterval: 1000 * 60 * 60 * 1, // 1 hour
    refetchOnMount: false,
    enabled: !!userInfo,
  });

  const createReport = useMutation<string, Error, ICreateBugReport>({
    mutationFn: async (data) => {
      const docRef = await addDoc(collection(db, "bugReports"), {
        ...data,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: "open",
      });

      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports"] });
      queryClient.invalidateQueries({ queryKey: ["myBugReports"] });
    },
  });

  const updateAttachments = useMutation<
    void,
    Error,
    { bugReportId: string; files: File[] }
  >({
    mutationFn: ({ bugReportId, files }) =>
      updateBugReportAttachments(bugReportId, files),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports"] });
      queryClient.invalidateQueries({ queryKey: ["myBugReports"] });
    },
  });

  const handleFileSelect = useCallback(
    (acceptedFiles: File[], rejectedFiles: any[]) => {
      // Handle rejected files
      if (rejectedFiles.length > 0) {
        const errorMessages = rejectedFiles
          .map(
            ({ file, errors }) =>
              `${file.name}: ${errors.map((e: any) => e.message).join(", ")}`
          )
          .join("; ");
        setUploadError(errorMessages);
        return;
      }

      // Validate accepted files
      const invalidFiles = acceptedFiles.filter(
        (file) =>
          !ALLOWED_FILE_TYPES.includes(file.type) || file.size > MAX_FILE_SIZE
      );

      if (invalidFiles.length > 0) {
        setUploadError(
          `Invalid files: ${invalidFiles.map((f) => f.name).join(", ")}. Files must be under 10MB and of type: ${ALLOWED_FILE_TYPES.join(", ")}`
        );
        return;
      }

      setUploadError(null);
      setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
    },
    []
  );

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    if (selectedFiles.length === 1) {
      setUploadError(null);
    }
  };

  const resetFiles = () => {
    setSelectedFiles([]);
    setUploadError(null);
  };

  return {
    createReport,
    updateAttachments,
    bugReports,
    isLoadingReports,
    // File handling
    selectedFiles,
    uploadError,
    setUploadError,
    handleFileSelect,
    handleRemoveFile,
    resetFiles,
  };
};
