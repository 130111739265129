import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Avatar,
  Skeleton,
  Stack,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
} from "@mui/material";
import { Project, Client } from "@freetech/models/projects";
import { PublicUserInfoType } from "@freetech/models/user";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import GroupIcon from "@mui/icons-material/Group";
import WorkIcon from "@mui/icons-material/Work";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStakeholderProjects } from "hooks/stakeholder/useStakeholderProjects";
import ViewFileDialog from "components/ViewFileDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";
import { FreeloBoardChip } from "components/FreeloBoardChip";

interface ProjectOverviewHeaderProps {
  project: Project;
  client: Client | undefined;
  freelancers: PublicUserInfoType[] | undefined;
  isLoading: boolean;
  isFreelancersLoading: boolean;
}

export const ProjectOverviewHeader: React.FC<ProjectOverviewHeaderProps> = ({
  project,
  client,
  freelancers,
  isLoading,
  isFreelancersLoading,
}) => {
  const { stakeholders, isStakeholdersLoading } = useStakeholderProjects();
  const [isViewFileDialogOpen, setIsViewFileDialogOpen] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState<string>("");

  const handleViewFile = (fileUrl: string | undefined, fileName: string) => {
    if (!fileUrl) {
      console.error("No file URL provided");
      return;
    }
    console.log("Opening file:", fileUrl);
    setSelectedFileUrl(fileUrl);
    setSelectedFileName(fileName);
    setIsViewFileDialogOpen(true);
  };

  const renderTeamMembers = () => {
    if (isLoading || isFreelancersLoading || isStakeholdersLoading) {
      return (
        <Box>
          <Skeleton
            variant="rectangular"
            height={56}
            sx={{ mb: 2, borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            height={200}
            sx={{ borderRadius: 1 }}
          />
        </Box>
      );
    }

    if (!project || !freelancers || !client || !stakeholders) {
      return null;
    }

    const freelancersForProject = freelancers.filter((freelancer) => {
      return client.associatedFreelancerIds?.includes(freelancer.id);
    });

    const stakeholdersForProject = stakeholders.filter((stakeholder) => {
      return client.associatedStakeholderIds?.includes(stakeholder.id);
    });

    return (
      <Accordion
        sx={{
          "&.MuiAccordion-root": {
            backgroundColor: "grey.50",
            "&:before": {
              display: "none",
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <GroupIcon sx={{ color: "primary.main" }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Project Team
            </Typography>
            {/* <Chip
              size="small"
              label={`${stakeholdersForProject.length + freelancersForProject.length} members`}
              sx={{ ml: 1 }}
            /> */}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Stack spacing={4}>
            {/* Stakeholders Section */}
            <Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              >
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Stakeholders
                </Typography>
                <Chip
                  size="small"
                  label={stakeholdersForProject.length}
                  sx={{ height: 20, fontSize: "0.75rem" }}
                />
              </Box>
              {!stakeholdersForProject.length ? (
                <Typography variant="body2" color="text.secondary">
                  No stakeholders assigned
                </Typography>
              ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {stakeholdersForProject.map((stakeholder) => (
                    <Box
                      key={stakeholder.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        bgcolor: "background.paper",
                        p: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Avatar
                        alt={stakeholder.displayName}
                        src={stakeholder.profilePicture || undefined}
                        sx={{ width: 32, height: 32 }}
                      >
                        {stakeholder.displayName
                          ?.split(" ")
                          .map((name) => name[0])
                          .join("")}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2">
                          {stakeholder.displayName}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {stakeholder.email}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            {/* Freelancers Section */}
            <Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              >
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ fontWeight: 600 }}
                >
                  Freelancers
                </Typography>
                <Chip
                  size="small"
                  label={freelancersForProject.length}
                  sx={{ height: 20, fontSize: "0.75rem" }}
                />
              </Box>
              {!freelancersForProject.length ? (
                <Typography variant="body2" color="text.secondary">
                  No freelancers assigned yet
                </Typography>
              ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {freelancersForProject.map((freelancer) => (
                    <Box
                      key={freelancer.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        bgcolor: "background.paper",
                        p: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Avatar
                        alt={freelancer.displayName}
                        src={freelancer.profilePicture || undefined}
                        sx={{ width: 32, height: 32 }}
                      >
                        {freelancer.displayName
                          ?.split(" ")
                          .map((name) => name[0])
                          .join("")}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2">
                          {freelancer.displayName}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {freelancer.email}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderAgreements = () => {
    if (!project || !client) return null;

    const agreements =
      client.consultingServicesAgreements?.filter((agreement) => {
        return project.id === agreement.projectId;
      }) || [];

    if (agreements.length === 0) {
      return (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
        >
          No agreements available
        </Typography>
      );
    }

    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          },
          gap: { xs: 1.5, sm: 2 },
        }}
      >
        {agreements.map((agreement) => (
          <Paper
            key={agreement.id}
            elevation={0}
            sx={{
              p: { xs: 1.5, sm: 2 },
              bgcolor: "background.paper",
              borderRadius: { xs: 1, sm: 1.5 },
              position: "relative",
              overflow: "hidden",
              border: 1,
              borderColor: "divider",
              transition: "all 0.2s ease-in-out",
              height: { xs: "auto", sm: "100%" },
              display: "flex",
              flexDirection: "column",
              "&:hover": {
                bgcolor: "grey.50",
                transform: "translateY(-2px)",
                boxShadow: 1,
              },
            }}
          >
            {/* Status indicator */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: 4,
                height: "100%",
                bgcolor:
                  agreement.status === "active" ? "success.main" : "grey.300",
              }}
            />

            {/* Header with title and status */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                pl: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "0.875rem", sm: "0.95rem" },
                  lineHeight: 1.3,
                  pr: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {agreement.name}
              </Typography>
              {agreement.status === "active" && (
                <Chip
                  label="Active"
                  color="success"
                  size="small"
                  sx={{
                    height: { xs: 20, sm: 20 },
                    fontSize: { xs: "0.65rem", sm: "0.7rem" },
                    fontWeight: 500,
                    minWidth: "unset",
                    "& .MuiChip-label": {
                      px: 1,
                    },
                  }}
                />
              )}
            </Box>

            {/* Agreement details */}
            <Box sx={{ pl: 1, flex: 1 }}>
              <Typography
                variant="caption"
                color="text.secondary"
                display="block"
                sx={{ fontSize: { xs: "0.7rem", sm: "0.75rem" } }}
              >
                Effective:{" "}
                {new Date(agreement.effectiveDate).toLocaleDateString()}
              </Typography>

              {agreement.status !== "active" && (
                <Typography
                  variant="caption"
                  sx={{
                    color: "text.secondary",
                    textTransform: "capitalize",
                    display: "block",
                    fontSize: { xs: "0.7rem", sm: "0.75rem" },
                  }}
                >
                  Status: {agreement.status}
                </Typography>
              )}

              {/* Budget information */}
              <Box sx={{ mt: 1 }}>
                {agreement.monthlyBudget ? (
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "0.8rem", sm: "0.875rem" },
                      color: "primary.main",
                    }}
                  >
                    ${agreement.monthlyBudget.toLocaleString()}/month
                  </Typography>
                ) : agreement.fixedPriceBudget ? (
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "0.8rem", sm: "0.875rem" },
                      color: "primary.main",
                    }}
                  >
                    ${agreement.fixedPriceBudget.toLocaleString()} fixed
                  </Typography>
                ) : null}
              </Box>
            </Box>

            {/* View button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 1.5,
                borderTop: "1px solid",
                borderColor: "divider",
                pt: 1,
              }}
            >
              <Button
                size="small"
                startIcon={
                  <VisibilityIcon
                    sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
                  />
                }
                onClick={() => {
                  if (agreement.fileUrl) {
                    handleViewFile(agreement.fileUrl, agreement.name);
                  } else {
                    console.error("No file URL for agreement:", agreement.name);
                  }
                }}
                sx={{
                  color: "primary.main",
                  fontSize: { xs: "0.7rem", sm: "0.75rem" },
                  textTransform: "none",
                  p: 0.5,
                  minWidth: "unset",
                }}
              >
                View
              </Button>
            </Box>
          </Paper>
        ))}
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Paper
        elevation={2}
        sx={{
          p: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 3 },
          borderRadius: { xs: 1.5, sm: 2 },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="text" width="50%" height={40} sx={{ mb: 1 }} />
            <Skeleton
              variant="rectangular"
              width={100}
              height={32}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={2}
      sx={{
        p: { xs: 2, sm: 3 },
        mb: { xs: 2, sm: 3 },
        borderRadius: { xs: 1.5, sm: 2 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "flex-start" },
          gap: { xs: 2, sm: 0 },
          mb: { xs: 2, sm: 3 },
        }}
      >
        <Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" },
              fontWeight: 700,
              letterSpacing: "-0.01em",
            }}
          >
            {project.name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
            {client?.logoUrl && (
              <Avatar
                src={client.logoUrl}
                alt={client?.companyName || ""}
                sx={{
                  width: { xs: 24, sm: 28 },
                  height: { xs: 24, sm: 28 },
                  bgcolor: "primary.light",
                }}
              >
                {!client.logoUrl && client.companyName?.charAt(0)}
              </Avatar>
            )}
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ fontSize: { xs: "0.95rem", sm: "1rem" } }}
            >
              {client?.companyName || project.clientId}
            </Typography>
          </Box>
        </Box>
        <Stack
          direction={{ xs: "row", sm: "column" }}
          spacing={{ xs: 1, sm: 1 }}
          alignItems={{ xs: "center", sm: "flex-end" }}
          flexWrap="wrap"
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
        >
          <Chip
            label={project.status}
            color={project.status === "active" ? "success" : "default"}
            sx={{
              textTransform: "capitalize",
              height: { xs: 28, sm: 32 },
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
            }}
          />
          {project.freeloBoardId && (
            <FreeloBoardChip boardId={project.freeloBoardId} />
          )}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CalendarTodayIcon
              sx={{
                fontSize: { xs: "0.8rem", sm: "0.9rem" },
                color: "text.secondary",
              }}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}
            >
              {project.startDate
                ? new Date(project.startDate).toLocaleDateString()
                : "Start date not set"}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ mb: { xs: 2, sm: 3 } }}>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, lineHeight: 1.6 }}
        >
          {project.description}
        </Typography>
      </Box>

      <Box>{renderTeamMembers()}</Box>

      <Box sx={{ mt: 2 }}>
        <Accordion
          sx={{
            "&.MuiAccordion-root": {
              backgroundColor: "grey.50",
              "&:before": {
                display: "none",
              },
              borderRadius: { xs: 1, sm: 1.5 },
              overflow: "hidden",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: "background.paper",
              borderBottom: 1,
              borderColor: "divider",
              minHeight: { xs: 48, sm: 56 },
              "& .MuiAccordionSummary-content": {
                margin: { xs: "10px 0", sm: "12px 0" },
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <DescriptionIcon
                sx={{
                  color: "primary.main",
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "0.95rem", sm: "1rem" },
                }}
              >
                Consulting Services Agreements
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: { xs: 2, sm: 3 } }}>
            {renderAgreements()}
          </AccordionDetails>
        </Accordion>
      </Box>

      <ViewFileDialog
        open={isViewFileDialogOpen}
        onClose={() => setIsViewFileDialogOpen(false)}
        fileUrl={selectedFileUrl}
        fileName={selectedFileName}
        isLoading={false}
      />
    </Paper>
  );
};
