import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TextField,
  InputAdornment,
  Skeleton,
  useTheme,
  Button,
  LinearProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Search as SearchIcon,
  OpenInNew as OpenInNewIcon,
  Business as BusinessIcon,
  CalendarToday as CalendarTodayIcon,
  MonetizationOn as MonetizationOnIcon,
  Flag as FlagIcon,
  People as PeopleIcon,
} from '@mui/icons-material';
import { useAdminClients } from 'hooks/admin/useAdminClients';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

const ProjectsTab: React.FC = () => {
  const theme = useTheme();
  const { clients, isLoading } = useAdminClients();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');

  // Extract all projects from clients
  const allProjects = useMemo(() => {
    const projects: any[] = [];
    
    clients?.forEach(client => {
      if (client.projects && client.projects.length > 0) {
        client.projects.forEach(project => {
          projects.push({
            ...project,
            clientName: client.companyName,
            clientId: client.id,
          });
        });
      }
    });
    
    return projects;
  }, [clients]);

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Handle status filter change
  const handleStatusFilterChange = (status: string) => {
    setStatusFilter(status);
    setPage(0);
  };

  // Filter projects based on search query and status filter
  const filteredProjects = allProjects?.filter((project) => {
    // Status filtering
    if (statusFilter !== 'all' && project.status !== statusFilter) {
      return false;
    }

    // Search query filtering
    const searchText = searchQuery.toLowerCase();
    const projectName = project.name?.toLowerCase() || '';
    const clientName = project.clientName?.toLowerCase() || '';
    const description = project.description?.toLowerCase() || '';
    
    return (
      projectName.includes(searchText) ||
      clientName.includes(searchText) ||
      description.includes(searchText)
    );
  }) || [];

  // Calculate displayed projects based on pagination
  const displayedProjects = filteredProjects
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Calculate project statistics
  const totalProjects = allProjects.length;
  const activeProjects = allProjects.filter(p => p.status === 'active').length;
  const completedProjects = allProjects.filter(p => p.status === 'completed').length;
  const pendingProjects = allProjects.filter(p => p.status === 'pending').length;

  // Calculate total budget and remaining budget
  const totalBudget = allProjects.reduce((sum, project) => sum + (project.budget || 0), 0);
  
  // Calculate remaining hours across all projects
  const totalHours = allProjects.reduce((sum, project) => sum + (project.hoursBudgeted || 0), 0);
  const usedHours = allProjects.reduce((sum, project) => sum + (project.hoursUsed || 0), 0);
  const remainingHours = totalHours - usedHours;

  // Format currency
  const formatCurrency = (amount: number | undefined): string => {
    if (amount === undefined) return '$0';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Format date
  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return 'N/A';
    return DateTime.fromISO(dateString).toFormat('LLL dd, yyyy');
  };

  // Loading placeholders
  const StatCardLoader = () => (
    <Card>
      <CardContent>
        <Skeleton variant="text" width={120} height={28} />
        <Skeleton variant="text" width={80} height={40} sx={{ my: 1 }} />
        <Skeleton variant="text" width={100} />
      </CardContent>
    </Card>
  );

  const TableLoader = () => (
    <Box>
      <Skeleton variant="rectangular" height={52} sx={{ mb: 1 }} />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={53} sx={{ mb: 1 }} />
      ))}
    </Box>
  );

  // Get project status color
  const getStatusColor = (status: string): "success" | "error" | "warning" | "info" | "default" => {
    switch (status) {
      case 'active':
        return 'success';
      case 'completed':
        return 'info';
      case 'paused':
        return 'warning';
      case 'cancelled':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  // Calculate progress percentage
  const calculateProgress = (project: any): number => {
    if (!project.hoursUsed || !project.hoursBudgeted || project.hoursBudgeted === 0) {
      return 0;
    }
    
    const percentage = (project.hoursUsed / project.hoursBudgeted) * 100;
    return Math.min(Math.max(percentage, 0), 100); // Clamp between 0-100
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Project Stats */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Project Overview</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Total Projects
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1 }}>
                      {totalProjects}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Across all clients
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Active Projects
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.success.main }}>
                      {activeProjects}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Currently in progress
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Total Budget
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.primary.main }}>
                      {formatCurrency(totalBudget)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      All projects combined
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Remaining Hours
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.info.main }}>
                      {remainingHours.toFixed(0)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {totalHours > 0 
                        ? `${((remainingHours / totalHours) * 100).toFixed(0)}% of total hours`
                        : 'No hours budgeted'}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Project Search and Filters */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap', gap: 2 }}>
            <Typography variant="h6">Project List</Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                variant="outlined"
                placeholder="Search projects..."
                size="small"
                value={searchQuery}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: { xs: '100%', sm: 220 } }}
              />
              
              <Box>
                <Button 
                  variant={statusFilter === 'all' ? "contained" : "outlined"} 
                  size="small"
                  onClick={() => handleStatusFilterChange('all')}
                  sx={{ mx: 0.5 }}
                >
                  All
                </Button>
                <Button 
                  variant={statusFilter === 'active' ? "contained" : "outlined"} 
                  size="small"
                  onClick={() => handleStatusFilterChange('active')}
                  sx={{ mx: 0.5 }}
                >
                  Active
                </Button>
                <Button 
                  variant={statusFilter === 'completed' ? "contained" : "outlined"} 
                  size="small"
                  onClick={() => handleStatusFilterChange('completed')}
                  sx={{ mx: 0.5 }}
                >
                  Completed
                </Button>
              </Box>
            </Box>
          </Box>
          
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {isLoading ? (
              <Box sx={{ p: 2 }}>
                <TableLoader />
              </Box>
            ) : (
              <>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Project Name</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>Budget</TableCell>
                        <TableCell>Progress</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedProjects.map((project) => (
                        <TableRow hover key={project.id}>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FlagIcon 
                                fontSize="small" 
                                sx={{ mr: 1, color: theme.palette.primary.main }} 
                              />
                              <Typography variant="body2">
                                {project.name || 'Unnamed Project'}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <BusinessIcon 
                                fontSize="small" 
                                sx={{ mr: 1, color: theme.palette.text.secondary }} 
                              />
                              <Typography variant="body2">
                                {project.clientName || 'Unknown Client'}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <CalendarTodayIcon 
                                fontSize="small" 
                                sx={{ mr: 1, color: theme.palette.text.secondary }} 
                              />
                              <Typography variant="body2">
                                {formatDate(project.startDate)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <MonetizationOnIcon 
                                fontSize="small" 
                                sx={{ mr: 1, color: theme.palette.success.main }} 
                              />
                              <Typography variant="body2">
                                {formatCurrency(project.budget)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: 100 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="caption" color="text.secondary">
                                  {project.hoursUsed || 0}/{project.hoursBudgeted || 0} hrs
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {calculateProgress(project).toFixed(0)}%
                                </Typography>
                              </Box>
                              <LinearProgress 
                                variant="determinate" 
                                value={calculateProgress(project)} 
                                color={
                                  calculateProgress(project) > 90 
                                    ? 'error' 
                                    : calculateProgress(project) > 75 
                                      ? 'warning' 
                                      : 'primary'
                                }
                              />
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={project.status || 'unknown'}
                              size="small"
                              color={getStatusColor(project.status || 'unknown')}
                            />
                          </TableCell>
                          <TableCell>
                            <Tooltip title="View Details">
                              <IconButton
                                component={Link}
                                to={`/admin/client-management/${project.clientId}`}
                                size="small"
                                color="primary"
                              >
                                <OpenInNewIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                      {displayedProjects.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No projects found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={filteredProjects.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectsTab; 