import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotificationAccordion from "./NotificationAccordion";
import { useAuth } from "hooks/auth/useAuth";
import { useProfilePicture } from "hooks/auth/useProfilePicture";
import {
  ExitToAppSharp,
  Notifications,
  Person,
  Settings,
} from "@mui/icons-material";
import { OnlineUsersDisplay } from "components/OnlineUsersDisplay/OnlineUsersDisplay";
import { SessionExpiringSoon } from "./SessionExpiringSoon";
import { CardMembership } from "@mui/icons-material";

export const CoreAppToolbar = () => {
  const navigate = useNavigate();
  const { userInfo, logout } = useAuth();
  const { currentPhotoURL } = useProfilePicture();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center" sx={{ gap: { xs: 1, sm: 2 } }}>
      <SessionExpiringSoon />
      {window.location.pathname.includes("stg") ||
      window.location.pathname.includes("localhost") ||
      userInfo?.role === "stakeholder" ? null : (
        <OnlineUsersDisplay />
      )}
      <Box sx={{ marginRight: { xs: 0.5, sm: 2 } }}>
        <NotificationAccordion />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography
          variant="body2"
          color="inherit"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          {userInfo?.firstName} {userInfo?.lastName}
          {userInfo?.role === "admin" &&
            `${process.env.VITE_FIREBASE_PROJECT_ID} #${userInfo.id}`}
        </Typography>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{ ml: { xs: 0, sm: 1 } }}
        >
          <Avatar sx={{ width: 32, height: 32 }} src={currentPhotoURL}>
            {userInfo?.firstName?.[0]}
            {userInfo?.lastName?.[0]}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: 200,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {userInfo?.role === "student" ? (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/student/manage-subscription");
            }}
            sx={{
              py: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CardMembership fontSize="small" />
            Membership
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/notifications");
          }}
          sx={{
            py: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Notifications fontSize="small" />
          Notifications
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/account-settings");
          }}
          sx={{
            py: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Settings fontSize="small" />
          Settings
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={async () => {
            handleClose();
            await logout();
          }}
          sx={{
            py: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "error.main",
          }}
        >
          <ExitToAppSharp fontSize="small" />
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};
