import React, { useRef, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Divider,
  useTheme,
  Button,
  Grid,
  Avatar,
  Alert,
} from "@mui/material";
import { ChatMessage as ChatMessageComponent } from "./ChatMessage";
import { ChatInput } from "./ChatInput";
import { ChatMessage } from "types/projects/projectIdea";
import { motion } from "framer-motion";
import { Client } from "@freetech/models/projects";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

interface ChatInterfaceProps {
  messages: ChatMessage[];
  isProcessing: boolean;
  onSendMessage: (message: string, isInitializing?: boolean) => void;
  onClientSelect: (client: Client) => void;
  clients: Client[];
  currentStep: string;
  readyForPlan?: boolean;
  onGeneratePlan?: () => void;
}

/**
 * Component for the chat interface in the AI conversation
 */
export const ChatInterface: React.FC<ChatInterfaceProps> = ({
  messages,
  isProcessing,
  onSendMessage,
  onClientSelect,
  clients,
  currentStep,
  readyForPlan,
  onGeneratePlan,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSuggestionClick = (suggestion: string) => {
    console.log("Suggestion clicked in ChatInterface:", suggestion);
    onSendMessage(suggestion);
  };

  const handleClientClick = (client: Client) => {
    if (onClientSelect) {
      onClientSelect(client);
    }
  };

  // Determine if we should show the early generate plan button
  const shouldShowEarlyGenerateButton = messages.length >= 5 && !readyForPlan && currentStep !== "selectCompany" && onGeneratePlan;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ width: "100%" }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 3,
          overflow: "hidden",
          boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Chat header */}
        <Box
          sx={{
            p: 2,
            bgcolor: theme.palette.primary.main,
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: 1.5,
            background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
          }}
        >
          <Avatar
            src="/jane.jpg"
            alt="Jane"
            sx={{
              width: 40,
              height: 40,
            }}
          />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500, lineHeight: 1.2 }}>
              Project Plan Generation Tool
            </Typography>
            <Typography variant="caption" sx={{ opacity: 0.8 }}>
              Powered by Jane, FreeTech AI
            </Typography>
          </Box>
        </Box>

        {/* Chat messages area */}
        <Box
          sx={{
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            bgcolor: "#f8f9fa",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              p: 2,
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "rgba(0, 0, 0, 0.2)",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            {messages.map((message, index) => (
              <Box key={message.id}>
                <ChatMessageComponent
                  message={message}
                  onSuggestionClick={handleSuggestionClick}
                  isLatest={index === messages.length - 1}
                />
                {message.type === "ai" &&
                  currentStep === "selectCompany" &&
                  index === messages.length - 1 &&
                  clients && (
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      {clients.map((client) => (
                        <Grid item xs={12} sm={6} md={4} key={client.id}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleClientClick(client)}
                            sx={{
                              textTransform: "none",
                              p: 2,
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 500 }}
                            >
                              {client.companyName}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="inherit"
                              sx={{ opacity: 0.8 }}
                            >
                              {client.location} • {client.size}
                            </Typography>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}
              </Box>
            ))}
            <div ref={messagesEndRef} />

            {/* Early generate plan button */}
            {shouldShowEarlyGenerateButton && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={{ mt: 3, mb: 1 }}>
                  <Alert 
                    severity="info" 
                    variant="outlined"
                    sx={{ 
                      mb: 2,
                      backgroundColor: 'rgba(0, 127, 255, 0.05)',
                      border: '1px solid rgba(0, 127, 255, 0.3)',
                    }}
                  >
                    <Typography variant="body2">
                      Jane may need more information to create a complete project plan, but you can proceed with what you've provided so far.
                    </Typography>
                  </Alert>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={onGeneratePlan}
                      startIcon={<AutoAwesomeIcon />}
                      sx={{
                        minWidth: 240,
                        py: 1.5,
                        px: 3,
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover': {
                          backgroundColor: theme.palette.secondary.dark,
                        },
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                      }}
                    >
                      Generate Plan Now
                    </Button>
                  </Box>
                </Box>
              </motion.div>
            )}
          </Box>

          {/* Chat input area */}
          <Box
            sx={{
              p: 2,
              bgcolor: "white",
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <ChatInput
              onSendMessage={onSendMessage}
              isProcessing={isProcessing}
              disabled={currentStep === "selectCompany" || readyForPlan}
              placeholder={
                readyForPlan
                  ? "Conversation complete. Please click 'Generate Plan' to continue..."
                  : currentStep === "selectCompany"
                    ? "Please select a company to continue"
                    : "Type your message..."
              }
            />
            {readyForPlan && (
              <Typography 
                variant="caption" 
                color="primary" 
                sx={{ display: 'block', mt: 1, textAlign: 'center' }}
              >
                Conversation complete. Click "Generate Plan" below to create your project plan.
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </motion.div>
  );
};