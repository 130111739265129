import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  Fab,
  Badge,
  Popover,
  Typography,
  IconButton,
  Avatar,
  Tooltip,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AIMessageBubble } from './AIMessageBubble';
import { AIMessageInput } from './AIMessageInput';
import { useAIChat } from 'hooks/ai/useAIChat';
import { useAuth } from 'hooks/auth/useAuth';

const AI_ASSISTANT_NAME = 'Jane (Beta)';
const AI_ASSISTANT_AVATAR = '/jane.jpg';
const AI_WARNING_MESSAGE = "Jane, FreeTech's AI Assistant is in development and may provide inaccurate or false information. FreeTech does not claim any liability for the information provided by Jane and use of this feature is in accordance with the terms and conditions accepted upon registration";

export const AIChatBubble = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const { userInfo } = useAuth();
  const { messages, sendMessage, isLoading } = useAIChat();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!anchorEl && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.isAI) {
        setUnreadCount(prev => prev + 1);
      }
    }
  }, [messages, anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setUnreadCount(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSendMessage = async (content: string) => {
    await sendMessage(content);
  };

  const displayMessages = useMemo(() => {
    if (!isLoading) return messages;

    // Add a loading message from Jane if we're waiting for a response
    if (messages.length > 0 && messages[messages.length - 1]?.isAI === false) {
      return [
        ...messages,
        {
          id: 'loading',
          content: '',
          timestamp: Date.now(),
          isAI: true,
          userName: AI_ASSISTANT_NAME
        }
      ];
    }
    return messages;
  }, [messages, isLoading]);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000,
      }}
    >
      <Badge 
        badgeContent={unreadCount} 
        color="error"
        sx={{
          '& .MuiBadge-badge': {
            right: 5,
            top: 5,
          }
        }}
      >
        <Fab
          onClick={handleClick}
          color="primary"
          aria-label="ai-chat"
          sx={{
            width: 72,
            height: 72,
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
            '&:hover': {
              transform: 'scale(1.05)',
              transition: 'transform 0.2s ease',
            }
          }}
        >
          <Avatar
            src={AI_ASSISTANT_AVATAR}
            sx={{ 
              width: 40,
              height: 40,
            }}
          >
            <ChatIcon sx={{ fontSize: 32 }} />
          </Avatar>
        </Fab>
      </Badge>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: isExpanded ? 'calc(100vw - 240px)' : { xs: '95vw', sm: 600, md: 800 },
            height: isExpanded ? 'calc(100vh - 184px)' : { xs: '80vh', sm: 700 },
            marginBottom: 2,
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            border: '1px solid rgba(0,0,0,0.08)',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: isExpanded ? 'none' : '95vw',
            maxHeight: isExpanded ? 'none' : '90vh',
            ...(isExpanded ? {
              position: 'fixed',
              top: '84px',
              right: 0,
              left: '240px',
              margin: 0,
              borderRadius: 0,
            } : {}),
            transition: 'all 0.3s ease',
          }
        }}
      >
        <Box sx={{ 
          p: 2,
          borderBottom: '1px solid rgba(0,0,0,0.08)',
          bgcolor: 'primary.main',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={handleToggleExpand}
              sx={{ color: 'white' }}
            >
              {isExpanded ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
            </IconButton>
            <Avatar
              src={AI_ASSISTANT_AVATAR}
              sx={{ 
                width: 32,
                height: 32,
                mr: 1,
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h5">
                {AI_ASSISTANT_NAME}
              </Typography>
              <Tooltip 
                title={AI_WARNING_MESSAGE}
                placement="right"
                sx={{ maxWidth: 300 }}
              >
                <IconButton size="small" sx={{ color: 'white', opacity: 0.8 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <IconButton 
            onClick={handleClose}
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ 
          flex: 1,
          p: 3,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}>
          {displayMessages.map((message) => (
            <AIMessageBubble
              key={message.id}
              content={message.content}
              timestamp={message.timestamp}
              isCurrentUser={!message.isAI}
              userName={message.isAI ? AI_ASSISTANT_NAME : (userInfo?.displayName || userInfo?.email || 'User')}
              userAvatar={message.isAI ? AI_ASSISTANT_AVATAR : undefined}
              isLoading={isLoading && message.id === 'loading'}
            />
          ))}
          <div ref={messagesEndRef} />
        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid rgba(0,0,0,0.08)',
          bgcolor: 'grey.50',
        }}>
          <AIMessageInput 
            onSendMessage={handleSendMessage}
            disabled={isLoading}
            placeholder={`Ask ${AI_ASSISTANT_NAME} anything about FreeTech...`}
          />
        </Box>
      </Popover>
    </Box>
  );
};