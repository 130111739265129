import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { portalFunctions } from "core/functions/portalFunctions";

interface AuthErrorDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  errorMessage: string;
  showResetPassword?: boolean;
  showResendVerification?: boolean;
  resetToken?: string;
  email?: string;
  customButton?: {
    text: string;
    onClick: () => void;
  };
  hideDefaultButton?: boolean;
}

// @ts-ignore
const AuthErrorDialog: React.FC<AuthErrorDialogProps> = ({
  open,
  onClose,
  title,
  errorMessage,
  showResetPassword = false,
  showResendVerification = false,
  resetToken = "",
  email = "",
  customButton,
  hideDefaultButton = false,
}) => {
  const navigate = useNavigate();

  if (!open) return null;

  const handleResendVerificationEmail = async () => {
    if (email) {
      await portalFunctions.public.resendVerificationEmail(email);
      onClose();
    }
  };

  const navigateToResetPassword = () => {
    onClose();
    navigate("/reset-password?token=" + resetToken);
  };

  return ReactDOM.createPortal(
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "400px",
          m: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          pb: 1,
          pt: 3,
          fontSize: "1.5rem",
          fontWeight: 500,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography
          align="center"
          sx={{
            color: "text.secondary",
            mt: 1,
          }}
        >
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          pb: 3,
          px: 3,
          gap: 1,
        }}
      >
        {showResendVerification && (
          <Button
            onClick={handleResendVerificationEmail}
            variant="contained"
            fullWidth
          >
            Resend Verification Email
          </Button>
        )}
        {showResetPassword && (
          <Button
            onClick={navigateToResetPassword}
            variant="contained"
            fullWidth
          >
            Reset Password
          </Button>
        )}
        {customButton && (
          <Button
            onClick={customButton.onClick}
            variant="contained"
            fullWidth
            sx={{
              bgcolor: "black",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.8)",
              },
            }}
          >
            {customButton.text}
          </Button>
        )}
        {!hideDefaultButton && (
          <Button onClick={onClose} variant="outlined" fullWidth>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>,
    document.body
  );
};

export default AuthErrorDialog;
