import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  Container,
  useTheme,
  Stack,
} from "@mui/material";
import StakeholderProjectCard from "../components/StakeholderProjectCard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import FolderIcon from "@mui/icons-material/Folder";
import BugReportIcon from "@mui/icons-material/BugReport";
import GroupIcon from "@mui/icons-material/Group";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { FreeTechLogoLoading } from "components/FreeTechLogoLoading";
import { useNavigation } from "hooks/useNavigation";
import { useAuth } from "hooks/auth/useAuth";

export const StakeholderProjectHub = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { clients, clientsIsLoading, clientsError } = useStakeholder();
  const {
    data: projects,
    isLoading: projectsIsLoading,
    error: projectsError,
  } = useProjectsNonAdmin();


  const handleSubmitNewIdea = () => {
    navigate("/stakeholder/projects/new-idea");
  };

  const handleProjectClick = (projectId: string) => {
    navigate(`/stakeholder/projects/${projectId}`);
  };

  const renderContent = () => {
    if (projectsIsLoading || clientsIsLoading) {
      return <FreeTechLogoLoading />;
    }

    if (projectsError || clientsError) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <Paper
            elevation={3}
            sx={{ p: 4, textAlign: "center", maxWidth: 400 }}
          >
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Error
            </Typography>
            <Typography color="error">
              {projectsError?.message || clientsError?.message}
            </Typography>
          </Paper>
        </Box>
      );
    }

    return (
      <Container maxWidth="xl" sx={{ p: { xs: 2, sm: 3 } }}>
        <Card
          sx={{
            mb: { xs: 3, sm: 4 },
            background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
            color: "white",
            borderRadius: { xs: 1.5, sm: 2 },
            boxShadow: theme.shadows[4],
          }}
        >
          <CardContent sx={{ py: { xs: 2, sm: 3 } }}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent="space-between"
              gap={{ xs: 2, sm: 0 }}
            >
              <Box display="flex" alignItems="center">
                <FolderIcon sx={{ fontSize: { xs: 32, sm: 40 }, mr: 2 }} />
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" },
                    }}
                  >
                    Project Hub
                  </Typography>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 3 }}
                    mt={1}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "0.95rem" },
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      ✨ Active Projects
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "0.95rem" },
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <BugReportIcon
                        sx={{ fontSize: { xs: "1rem", sm: "1.1rem" } }}
                      />{" "}
                      Report Issues
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "0.95rem" },
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <GroupIcon
                        sx={{ fontSize: { xs: "1rem", sm: "1.1rem" } }}
                      />{" "}
                      View Freelancers
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "0.95rem" },
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <TimelineIcon
                        sx={{ fontSize: { xs: "1rem", sm: "1.1rem" } }}
                      />{" "}
                      Check Budget Utilization
                    </Typography>
                  </Stack>
                </Box>
              </Box>
              <Button
                variant="contained"
                color="inherit"
                startIcon={<LightbulbIcon />}
                onClick={handleSubmitNewIdea}
                sx={{
                  borderRadius: { xs: 6, sm: 8 },
                  px: { xs: 2, sm: 3 },
                  py: { xs: 0.75, sm: 1 },
                  textTransform: "none",
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  color: "primary.main",
                  bgcolor: "white",
                  alignSelf: { xs: "stretch", sm: "auto" },
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.9)",
                    transform: "translateY(-1px)",
                  },
                  transition: "all 0.2s ease-in-out",
                }}
              >
                New Project Idea
              </Button>
            </Box>
          </CardContent>
        </Card>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: { xs: 2, sm: 3 },
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" },
          }}
        >
          Your Projects
        </Typography>

        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {!projects?.length ? (
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: { xs: 3, sm: 4 },
                  textAlign: "center",
                  borderRadius: { xs: 1.5, sm: 2 },
                }}
              >
                <Typography
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                  sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }}
                >
                  No projects found
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                >
                  Start by submitting a new project idea
                </Typography>
              </Paper>
            </Grid>
          ) : (
            projects.map((project) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={project.id}>
                  <Card
                    onClick={() => handleProjectClick(project.id)}
                    sx={{
                      height: "100%",
                      cursor: "pointer",
                      borderRadius: { xs: 1.5, sm: 2 },
                      overflow: "hidden",
                      boxShadow: theme.shadows[2],
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        boxShadow: theme.shadows[8],
                        transform: "translateY(-4px)",
                      },
                      "&:active": {
                        transform: "translateY(0)",
                      },
                    }}
                  >
                    <StakeholderProjectCard project={project} />
                  </Card>
                </Grid>
              );
            })
          )}
        </Grid>
      </Container>
    );
  };

  return <>{renderContent()}</>;
};
