import {
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "config/firebase";
import {
  BlogPost,
  BlogPostCreate,
  BlogPostUpdate,
  BlogListItem,
  BlogVersion,
} from "@freetech/models/blog";
import { diffWords, Change } from "diff";

const COLLECTION_NAME = "blogs";

export const createBlog = async (
  blog: BlogPostCreate,
  userId: string
): Promise<string> => {
  const now = new Date().toISOString();

  const blogWithMeta = {
    ...blog,
    createdAt: now,
    updatedAt: now,
    createdBy: userId,
    updatedBy: userId,
  };

  const docRef = await addDoc(collection(db, COLLECTION_NAME), blogWithMeta);
  return docRef.id;
};

export const updateBlog = async (
  blogUpdate: BlogPostUpdate,
  userId: string,
  userName: string
): Promise<void> => {
  const now = new Date().toISOString();

  const updateData = {
    ...blogUpdate,
    updatedAt: now,
    updatedBy: userId,
  };

  // Get the current blog state before updating
  const currentBlog = await getBlog(blogUpdate.id);

  // Create version before updating
  await createBlogVersion(
    { ...currentBlog, ...updateData },
    currentBlog,
    userId,
    userName
  );

  const docRef = doc(db, COLLECTION_NAME, blogUpdate.id);
  await updateDoc(docRef, updateData);
};

export const deleteBlog = async (id: string): Promise<void> => {
  const docRef = doc(db, COLLECTION_NAME, id);
  await deleteDoc(docRef);
};

export const getBlog = async (id: string): Promise<BlogPost> => {
  const docRef = doc(db, COLLECTION_NAME, id);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    throw new Error(`Blog with id ${id} not found`);
  }

  return {
    id: docSnap.id,
    ...docSnap.data(),
  } as BlogPost;
};

export const getBlogs = async (
  publishedOnly = false
): Promise<BlogListItem[]> => {
  let q = query(collection(db, COLLECTION_NAME), orderBy("date", "desc"));

  if (publishedOnly) {
    q = query(q, where("isPublished", "==", true));
  }

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(
    (doc) =>
      ({
        id: doc.id,
        ...doc.data(),
      }) as BlogListItem
  );
};

export const getBlogVersions = async (
  blogId: string
): Promise<BlogVersion[]> => {
  const versionsRef = collection(db, `blogs/${blogId}/versions`);
  const q = query(versionsRef, orderBy("createdAt", "desc"));
  const snapshot = await getDocs(q);

  return snapshot.docs.map(
    (doc) =>
      ({
        ...doc.data(),
        id: doc.id,
      }) as BlogVersion
  );
};

export const createBlogVersion = async (
  currentBlog: BlogPost,
  previousBlog: BlogPost | null,
  userId: string,
  userName: string
): Promise<void> => {
  const versionsRef = collection(db, `blogs/${currentBlog.id}/versions`);

  const q = query(versionsRef, orderBy("createdAt", "desc"));
  const snapshot = await getDocs(q);
  const versionNumber = (snapshot.size + 1).toString();

  const changes = {
    added: [] as string[],
    removed: [] as string[],
    modified: [] as string[],
  };

  if (previousBlog) {
    const contentDiff = diffWords(previousBlog.content, currentBlog.content);
    contentDiff.forEach((part: Change) => {
      if (part.added) changes.added.push(part.value);
      if (part.removed) changes.removed.push(part.value);
    });

    (["title", "description", "category"] as const).forEach((field) => {
      if (currentBlog[field] !== previousBlog[field]) {
        changes.modified.push(field);
      }
    });
  }

  const version: Omit<BlogVersion, "id"> = {
    blogId: currentBlog.id,
    content: currentBlog.content,
    title: currentBlog.title,
    description: currentBlog.description,
    category: currentBlog.category,
    headerImage: currentBlog.headerImage,
    createdAt: new Date().toISOString(),
    createdBy: userId,
    createdByName: userName,
    versionNumber,
    changes,
  };

  await addDoc(versionsRef, version);
};

export const deleteBlogVersion = async (
  blogId: string,
  versionId: string
): Promise<void> => {
  console.log("deleteBlogVersion called with:", { blogId, versionId });
  try {
    const versionRef = doc(db, `blogs/${blogId}/versions/${versionId}`);
    console.log("Deleting version document at path:", versionRef.path);
    await deleteDoc(versionRef);
    console.log("Version document deleted successfully");
  } catch (error) {
    console.error("Error in deleteBlogVersion:", error);
    throw error;
  }
};
