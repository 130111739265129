import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  CircularProgress,
  Alert,
  Button,
  Card,
  CardContent,
  Chip,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ArrowBack,
  Business,
  Email,
  Phone,
  LocationOn,
  Language,
  Assignment,
  Person,
} from "@mui/icons-material";
import { useFreelancerRoleClients } from "hooks/freelancer/useFreelancerRoleClients";
import { useFreelancerProjectAssignments } from "hooks/freelancer/useFreelancerProjectAssignments";
import { Client, ProjectStatus, ProjectAssignmentStatus } from "@freetech/models/projects";
import ProjectAssignmentCard from "components/Freelancer/ProjectAssignmentCard";

// Define additional properties that might be available in the client details
// but are not part of the base Client type
// Combine the Client type with our extended data

interface ClientParams {
  clientId: string;
  [key: string]: string;
}

const FreelancerClient: React.FC = () => {
  const { clientId } = useParams<ClientParams>();
  const navigate = useNavigate();

  const {
    useClientDetails,
    isLoading: isLoadingClients,
    isError: isClientsError,
    error: clientsError,
  } = useFreelancerRoleClients();

  const {
    data: clientData,
    isLoading: isLoadingClient,
    isError: isClientError,
    error: clientError,
  } = useClientDetails(clientId || "");

  // Get project assignments
  const {
    projectAssignments,
    isLoadingProjectAssignments,
    errorProjectAssignments,
    handleViewProjectAssignment,
  } = useFreelancerProjectAssignments();

  // Filter project assignments for this specific client
  const clientProjectAssignments =
    projectAssignments?.filter(
      (assignment) => assignment.clientId === clientId
    ) || [];

  // Cast the client data to our combined type
  // const client = clientData as ClientWithExtendedData | undefined;

  const client = clientData;

  const isLoading =
    isLoadingClients || isLoadingClient || isLoadingProjectAssignments;
  const isError = isClientsError || isClientError || !!errorProjectAssignments;
  const error = clientsError || clientError || errorProjectAssignments;

  const handleBack = () => {
    navigate(-1);
  };

  // Helper function to determine chip color based on project status
  const getStatusColor = (status: string | undefined) => {
    if (!status) return "default";

    switch (status.toLowerCase()) {
      case "active":
        return "success";
      case "completed":
        return "info";
      case "on_hold":
      case "on hold":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  // Helper function to determine assignment status color
  const getAssignmentStatusColor = (status: ProjectAssignmentStatus) => {
    switch (status) {
      case "in_progress":
        return "success";
      case "ready":
      case "signing":
        return "info";
      case "interviewing":
        return "warning";
      case "complete":
        return "primary";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  // Handle view document click
  const handleViewDocument = async (projectAssignmentId: string) => {
    try {
      if (!clientId) return;
      const fileUrl = await handleViewProjectAssignment(
        clientId,
        projectAssignmentId
      );
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error viewing document:", error);
      alert("Error viewing document. Please try again later.");
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">
          Error loading client details:{" "}
          {error instanceof Error ? error.message : "Unknown error"}
        </Alert>
        <Button startIcon={<ArrowBack />} onClick={handleBack} sx={{ mt: 2 }}>
          Back to Clients
        </Button>
      </Box>
    );
  }

  if (!client) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="warning">
          Client not found. The client may have been removed or you don't have
          access to it.
        </Alert>
        <Button startIcon={<ArrowBack />} onClick={handleBack} sx={{ mt: 2 }}>
          Back to Clients
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, md: 3 } }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <Typography variant="h4">Client Details</Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Client Overview Card */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                {client.logoUrl ? (
                  <Avatar
                    src={client.logoUrl}
                    alt={client.companyName}
                    sx={{ width: 64, height: 64, mr: 2 }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: 64,
                      height: 64,
                      mr: 2,
                      bgcolor: "primary.main",
                    }}
                  >
                    {client.companyName.charAt(0).toUpperCase()}
                  </Avatar>
                )}
                <Box>
                  <Typography variant="h5" component="div">
                    {client.companyName}
                  </Typography>
                </Box>
              </Box>

              <Divider sx={{ my: 2 }} />

              <List>
                {client.location && (
                  <ListItem disablePadding sx={{ mb: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <LocationOn fontSize="small" color="action" />
                    </ListItemIcon>
                    <ListItemText
                      primary={client.location}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                  </ListItem>
                )}

                {client.pointOfContactName && (
                  <ListItem disablePadding sx={{ mb: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Person fontSize="small" color="action" />
                    </ListItemIcon>
                    <ListItemText
                      primary={client.pointOfContactName}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                  </ListItem>
                )}

                {client.email && (
                  <ListItem disablePadding sx={{ mb: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Email fontSize="small" color="action" />
                    </ListItemIcon>
                    <ListItemText
                      primary={client.email}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                  </ListItem>
                )}

                {client.phone && (
                  <ListItem disablePadding sx={{ mb: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Phone fontSize="small" color="action" />
                    </ListItemIcon>
                    <ListItemText
                      primary={client.phone}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Projects Card */}
        <Grid item xs={12} md={8}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Projects with {client.companyName}
              </Typography>

              {client.projects && client.projects.length > 0 ? (
                <List>
                  {client.projects.map((project) => (
                    <Paper
                      key={project.id}
                      elevation={0}
                      sx={{
                        p: 2,
                        mb: 2,
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        {project.name}
                      </Typography>
                      {project.description && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          paragraph
                        >
                          {project.description}
                        </Typography>
                      )}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {project.status && (
                          <Chip
                            label={project.status}
                            size="small"
                            color={getStatusColor(project.status)}
                          />
                        )}
                      </Box>
                    </Paper>
                  ))}
                </List>
              ) : (
                <Box sx={{ p: 3, textAlign: "center" }}>
                  <Typography variant="body1" color="text.secondary">
                    No active projects with this client.
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Project Assignments Card */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                My Project Assignments
              </Typography>

              {clientProjectAssignments.length > 0 ? (
                <Grid container spacing={3}>
                  {clientProjectAssignments.map((assignment) => (
                    <Grid item xs={12} md={6} lg={4} key={assignment.id}>
                      <ProjectAssignmentCard
                        assignment={assignment}
                        onViewFile={(assignment) => {
                          if (clientId) {
                            handleViewProjectAssignment(clientId, assignment.id)
                              .then((fileUrl) => window.open(fileUrl, "_blank"))
                              .catch((error) => {
                                console.error("Error viewing document:", error);
                                alert(
                                  "Error viewing document. Please try again later."
                                );
                              });
                          }
                        }}
                        getStatusColor={getAssignmentStatusColor}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box sx={{ p: 3, textAlign: "center" }}>
                  <Typography variant="body1" color="text.secondary">
                    No project assignments with this client.
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Contact Persons Card */}
        {/* <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Contact Persons
              </Typography> */}

        {/* {client.contactPersons && client.contactPersons.length > 0 ? (
                <Grid container spacing={2}>
                  {client.contactPersons.map((contact) => (
                    <Grid item xs={12} sm={6} md={4} key={contact.id}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 2,
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: 1,
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 1 }}
                        >
                          <Avatar sx={{ mr: 1, bgcolor: "secondary.main" }}>
                            <Person />
                          </Avatar>
                          <Typography variant="subtitle1">
                            {contact.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          {contact.position}
                        </Typography>

                        <List disablePadding dense>
                          {contact.email && (
                            <ListItem disablePadding sx={{ mb: 0.5 }}>
                              <ListItemIcon sx={{ minWidth: 30 }}>
                                <Email fontSize="small" color="action" />
                              </ListItemIcon>
                              <ListItemText
                                primary={contact.email}
                                primaryTypographyProps={{ variant: "body2" }}
                              />
                            </ListItem>
                          )}

                          {contact.phone && (
                            <ListItem disablePadding>
                              <ListItemIcon sx={{ minWidth: 30 }}>
                                <Phone fontSize="small" color="action" />
                              </ListItemIcon>
                              <ListItemText
                                primary={contact.phone}
                                primaryTypographyProps={{ variant: "body2" }}
                              />
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box sx={{ p: 3, textAlign: "center" }}>
                  <Typography variant="body1" color="text.secondary">
                    No contact persons available.
                  </Typography>
                </Box>
              )} */}
        {/* </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default FreelancerClient;
