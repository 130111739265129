import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { useBoardActivity } from "hooks/freelo/useBoardActivity";
import { useFreelo } from "hooks/freelo/useFreelo";
import { useAuth } from "hooks/auth/useAuth";
import ShareBoardDialog from "./dialogs/ShareBoardDialog/ShareBoardDialog";
import { ListColorManager } from "views/FreeloBoard/dialogs/ListColorManager/ListColorManager";
import { DeleteListConfirmDialog } from "views/FreeloBoard/dialogs/DeleteListConfirmDialog/DeleteListConfirmDialog";
import { ArchiveList } from "./components/ArchiveList/ArchiveList";
import ArchiveIcon from "@mui/icons-material/Archive";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmActionDialog from "components/ConfirmActionDialog";
import { ActivityList } from "./components/ActivityList/ActivityList";
import { FreeloTicketDialog } from "./dialogs/FreeloTicketDialog";
import { FreeloBoardHeader } from "./components/FreeloBoardHeader";
import { DraggableCardArea } from "./components/DraggableCardArea/DraggableCardArea";
import { BoardSettingsDialog } from "./dialogs/BoardSettingsDialog/BoardSettingsDialog";

import { useQueryClient } from "@tanstack/react-query";
import { Board, List, Card, CardLabel } from "@freetech/models/freelo";
import {
  FreeloBoardFilters,
  BoardFilters,
} from "./components/FreeloBoardFilters";
import { useFreeloBoard } from "hooks/freelo/useFreeloBoard";

const FreeloBoard: React.FC = () => {
  const queryClient = useQueryClient();
  const { boardId } = useParams<{ boardId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: users } = usePublicUserList();
  const { userInfo } = useAuth();
  const isAdmin = userInfo?.role === "admin";
  const [selectedListId, setSelectedListId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [colorAnchorEl, setColorAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditingBoardTitle, setIsEditingBoardTitle] = useState(false);
  const [editBoardTitle, setEditBoardTitle] = useState("");
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  const [deleteListDialog, setDeleteListDialog] = useState<{
    open: boolean;
    listId: string | null;
  }>({
    open: false,
    listId: null,
  });
  const [isArchiveView, setIsArchiveView] = useState(false);
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);
  const [isActivityView, setIsActivityView] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [filters, setFilters] = useState<BoardFilters>({
    members: [],
    labels: [],
    searchText: "",
  });

  const { updateBoard, deleteList, updateCard, updateListColor, archiveList } =
    useFreelo();

  // These hooks should be called even if boardId is null
  const {
    data: board,
    isLoading: boardLoading,
    error: boardError,
  } = useFreeloBoard(boardId || "");
  const {
    activities,
    isLoading: activitiesLoading,
    error: activitiesError,
  } = useBoardActivity(boardId || "");

  // Handle hash changes
  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      // Find card by humanReadableId instead of id
      const cardId = board?.lists?.reduce<string | undefined>((found, list) => {
        if (found) return found;
        const card = list.cards.find((c) => c.humanReadableId === hash);
        return card?.id;
      }, undefined);

      if (cardId) {
        setSelectedCardId(cardId);
      } else {
        setSelectedCardId(null);
      }
    } else {
      setSelectedCardId(null);
    }
  }, [location.hash, board?.lists]);

  // Find the selected card and its list
  const findCardAndList = () => {
    if (!selectedCardId || !board?.lists) return null;

    for (const list of board.lists) {
      const card = list.cards.find((c) => c.id === selectedCardId);
      if (card) {
        return { card, listId: list.id };
      }
    }
    return null;
  };

  const cardAndList = findCardAndList();

  const getFilteredBoard = (): Board | null => {
    if (!board) return null;
    if (filters.members.length === 0 && filters.labels.length === 0 && !filters.searchText) return board;

    return {
      ...board,
      lists: board.lists?.map(list => ({
        ...list,
        cards: list.cards.filter(card => {
          // Member filter
          const memberMatch = filters.members.length === 0 || filters.members.some(memberId => 
            card.assignee === memberId || 
            card.reporter === memberId || 
            card.additionalAssignees?.includes(memberId)
          );

          // Label filter
          const labelMatch = filters.labels.length === 0 || filters.labels.some(labelId => 
            card.labels?.some(label => label.id === labelId)
          );

          // Text search filter
          const searchTextMatch = !filters.searchText || 
            card.title.toLowerCase().includes(filters.searchText.toLowerCase()) ||
            (card.description && card.description.toLowerCase().includes(filters.searchText.toLowerCase())) ||
            (card.comments && card.comments.some(comment => 
              comment.content.toLowerCase().includes(filters.searchText.toLowerCase())
            ));

          return memberMatch && labelMatch && searchTextMatch;
        })
      }))
    };
  };

  if (!boardId || !board) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="grey.100"
      >
        <Typography variant="h6" color="text.secondary">
          {!boardId ? "No board selected" : "Board not found"}
        </Typography>
      </Box>
    );
  }

  if (boardLoading || activitiesLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="grey.100"
        gap={2}
      >
        <CircularProgress size={40} />
        <Typography variant="body1" color="text.secondary">
          Loading board...
        </Typography>
      </Box>
    );
  }

  if (boardError || activitiesError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="grey.100"
        gap={2}
      >
        <Typography variant="h6" color="error">
          Error loading board
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {boardError?.message ||
            activitiesError?.message ||
            "An unexpected error occurred"}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            queryClient.invalidateQueries({ queryKey: ["board", boardId] });
            queryClient.invalidateQueries({
              queryKey: ["boardActivities", boardId],
            });
          }}
        >
          Retry
        </Button>
      </Box>
    );
  }

  const isBoardOwner = userInfo?.id === board.createdBy;
  const canShare = isAdmin || isBoardOwner;

  const handleListMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    listId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedListId(listId);
  };

  const handleListMenuClose = () => {
    setAnchorEl(null);
    setSelectedListId(null);
  };

  const handleConfirmDelete = async (targetListId: string | null) => {
    if (!boardId || !deleteListDialog.listId || !board) return;

    const listToDelete = board.lists?.find(
      (list) => list.id === deleteListDialog.listId
    );
    if (!listToDelete || !board.lists) return;

    try {
      if (targetListId && listToDelete.cards.length > 0) {
        // Get target list
        const targetList = board.lists.find((list) => list.id === targetListId);
        if (!targetList) return;

        // Move cards to target list
        const updatePromises = listToDelete.cards.map((card) =>
          updateCard.mutateAsync({
            boardId: boardId!,
            cardId: card.id,
            updates: { listId: targetListId },
          })
        );

        await Promise.all(updatePromises);
      }

      // Delete the list
      await deleteList.mutateAsync({
        boardId,
        listId: deleteListDialog.listId,
      });

      setDeleteListDialog({ open: false, listId: null });
      handleListMenuClose();

      setSnackbar({
        open: true,
        message: "List deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting list:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete list",
        severity: "error",
      });
    }
  };

  const handleColorSelect = async (listId: string, color: string | null) => {
    if (!board) return;
    if (!color) return;

    try {
      await updateListColor.mutateAsync({
        boardId: board.id,
        listId,
        color,
      });
    } catch (error) {
      console.error("Error updating list color:", error);
    }
    setColorAnchorEl(null);
  };

  const handleBoardTitleSubmit = async () => {
    if (!editBoardTitle.trim() || !board) return;

    try {
      await updateBoard.mutateAsync({
        boardId: board.id,
        data: { name: editBoardTitle.trim() },
      });
      setIsEditingBoardTitle(false);
    } catch (error) {
      console.error("Error updating board title:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleArchiveList = async () => {
    if (!boardId || !selectedListId) return;

    try {
      await archiveList.mutateAsync({
        boardId,
        listId: selectedListId,
      });
      setAnchorEl(null);
      setShowConfirmArchive(false);
    } catch (error) {
      console.error("Error archiving list:", error);
      setSnackbar({
        open: true,
        message: "Failed to archive list",
        severity: "error",
      });
    }
  };

  const FreeloBoardDialogs = () => {
    return (
      <Box>
        {/* Filters Dialog */}
        <FreeloBoardFilters
          open={Boolean(filtersAnchorEl)}
          anchorEl={filtersAnchorEl}
          onClose={() => setFiltersAnchorEl(null)}
          labels={board?.labels || []}
          filters={filters}
          onFiltersChange={setFilters}
          board={board}
        />
        {/* Delete List Confirmation Dialog */}
        <DeleteListConfirmDialog
          open={deleteListDialog.open}
          onClose={() => setDeleteListDialog({ open: false, listId: null })}
          onConfirm={handleConfirmDelete}
          listToDelete={
            board?.lists?.find((list) => list.id === deleteListDialog.listId) ||
            null
          }
          availableLists={
            board?.lists?.filter(
              (list) => list.id !== deleteListDialog.listId
            ) || []
          }
          hasCards={Boolean(
            board?.lists?.find((list) => list.id === deleteListDialog.listId)
              ?.cards.length
          )}
        />
        {/* Add confirmation dialog for archiving */}
        <ConfirmActionDialog
          open={showConfirmArchive}
          title="Archive List"
          message={`Are you sure you want to archive all cards in "${board?.lists?.find((list) => list.id === selectedListId)?.name || ""}"? You can find them later in the archived items.`}
          confirmText="Archive"
          confirmButtonColor="warning"
          onConfirm={handleArchiveList}
          onCancel={() => setShowConfirmArchive(false)}
        />
        {/* Settings Dialog */}
        <BoardSettingsDialog
          open={openSettings}
          onClose={() => setOpenSettings(false)}
          board={board}
          onUpdateBoard={async (updates) => {
            try {
              await updateBoard.mutateAsync({
                boardId: board.id,
                data: updates,
              });
            } catch (error) {
              console.error("Error updating board:", error);
              setSnackbar({
                open: true,
                message: "Failed to update board settings",
                severity: "error",
              });
            }
          }}
        />
        {/* Color Picker Popover */}
        <ListColorManager
          open={Boolean(colorAnchorEl)}
          anchorEl={colorAnchorEl}
          onClose={() => setColorAnchorEl(null)}
          onColorSelect={(color) =>
            selectedListId && handleColorSelect(selectedListId, color)
          }
          currentColor={
            board?.lists?.find((list) => list.id === selectedListId)?.color ||
            null
          }
        />
        {/* Share Dialog */}
        {canShare && (
          <ShareBoardDialog
            open={openShareDialog}
            onClose={() => setOpenShareDialog(false)}
            board={board}
            onUpdate={async (boardId, updates) => {
              try {
                await updateBoard.mutateAsync({
                  boardId,
                  data: updates,
                });
              } catch (error) {
                console.error("Error updating board:", error);
              }
            }}
          />
        )}
        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        // p: 1,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        bgcolor: "grey.100",
        backgroundImage: board?.backgroundUrl
          ? `url(${board.backgroundUrl})`
          : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        "&::before": board?.backgroundUrl
          ? {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 0,
              pointerEvents: "none",
            }
          : {},
      }}
    >
      {/* Header Container */}
      {/* <Box
        sx={{
          width: "100%",
          flexShrink: 0,
          position: "sticky",
          top: 0,
          zIndex: 48,
          pt: {
            xs: "8px",
            sm: "14px",
            md: "14px",
          },
        }}
      > */}
      <FreeloBoardHeader
        board={board}
        users={users}
        currentUser={userInfo}
        isAdmin={isAdmin}
        canShare={canShare}
        isArchiveView={isArchiveView}
        isActivityView={isActivityView}
        editBoardTitle={editBoardTitle}
        isEditingBoardTitle={isEditingBoardTitle}
        filters={filters}
        onEditBoardTitle={setEditBoardTitle}
        onBoardTitleSubmit={handleBoardTitleSubmit}
        onSetIsEditingBoardTitle={setIsEditingBoardTitle}
        onViewChange={(view) => {
          setIsActivityView(view === "activity");
          setIsArchiveView(view === "archive");
        }}
        onOpenShareDialog={() => setOpenShareDialog(true)}
        onOpenSettings={() => setOpenSettings(true)}
        onFiltersChange={setFilters}
      />
      {/* </Box> */}

      {/* Main Content Container */}
      {/* <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "hidden",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            // height: "calc(100vh - 64px)", // Subtract header height
            overflow: "hidden", // Prevent body scroll
          }}
        > */}
      {isArchiveView ? (
        <ArchiveList isArchiveView={isArchiveView} />
      ) : isActivityView ? (
        <ActivityList
          activities={activities || []}
          onClose={() => setIsActivityView(false)}
        />
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            // height: "calc(100vh - 64px)", // Subtract header height
            overflow: "hidden", // Prevent body scroll
          }}
        >
          <Box
            sx={{
              flex: 1,
              // overflowX: "auto",
              // overflowY: "hidden", // Hide vertical scroll on container
              display: "flex",
              alignItems: "flex-start",
              pl: 2,
              // pr: 2,
              // pb: 2,
              // gap: 2,
              minHeight: 0,
              "&::-webkit-scrollbar": {
                height: 8,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 4,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
              },
            }}
          >
            <DraggableCardArea
              board={getFilteredBoard() ?? board}
              boardId={boardId}
              onListMenuOpen={handleListMenuOpen}
            />
          </Box>
        </Box>
      )}
      {/* </Box> */}
      {/* // </Box> */}

      {/* List Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleListMenuClose}
      >
        <MenuItem
          onClick={() => {
            setColorAnchorEl(anchorEl);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ColorLensIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Pick Color</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setShowConfirmArchive(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive List</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setDeleteListDialog({
              open: true,
              listId: selectedListId,
            });
            setAnchorEl(null);
          }}
          sx={{ color: "error.main" }}
        >
          <ListItemIcon sx={{ color: "error.main" }}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete List</ListItemText>
        </MenuItem>
      </Menu>
      <FreeloBoardDialogs />

      {/* Add FreeloTicketDialog */}
      {cardAndList && (
        <FreeloTicketDialog
          cardId={cardAndList.card.id}
          boardId={boardId}
          open={Boolean(selectedCardId)}
          onClose={() => {
            setSelectedCardId(null);
            navigate(`/freelo/${boardId}`, { replace: true });
          }}
          card={cardAndList.card}
          onUpdate={async (cardId, updates) => {
            try {
              await updateCard.mutateAsync({
                boardId,
                cardId,
                updates,
              });
            } catch (error) {
              console.error("Error updating card:", error);
            }
          }}
          onArchive={async () => {
            try {
              await updateCard.mutateAsync({
                boardId,
                cardId: cardAndList.card.id,
                updates: { archived: true },
              });
              setSelectedCardId(null);
              navigate(`/freelo/${boardId}`, { replace: true });
            } catch (error) {
              console.error("Error archiving card:", error);
            }
          }}
        />
      )}
    </Box>
  );
};

export default FreeloBoard;
