import React, { useEffect, useState } from "react";
import { Box, Container, Paper, Button } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { logoNoBackground } from "assets";
import LogoutIcon from "@mui/icons-material/Logout";
import { MainLoading } from "../MainLoading";

const OnboardingLayout: React.FC = () => {
  const { isLoading, currentUser, userInfo, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isRouteReady, setIsRouteReady] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
      return;
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (isLoading || !userInfo || !currentUser) {
      setIsRouteReady(false);
      return;
    }

    // If user needs password reset, ensure they're on the password reset page
    if (
      userInfo.needsInitialPasswordSet &&
      location.pathname !== "/needs-reset-password"
    ) {
      navigate("/needs-reset-password", { replace: true });
      return;
    }

    // If user doesn't need password reset or onboarding, redirect to their role dashboard
    if (!userInfo.needsInitialPasswordSet && !userInfo.needsOnboarding) {
      navigate(`/${userInfo.role}`, { replace: true });
      return;
    }

    // If user needs onboarding but is on password reset page, redirect to appropriate onboarding
    if (
      !userInfo.needsInitialPasswordSet &&
      userInfo.needsOnboarding &&
      location.pathname === "/needs-reset-password"
    ) {
      if (userInfo.role === "executiveAssistant") {
        navigate(`/freelancer-onboarding`, {
          replace: true,
          state: { fromPasswordReset: true },
        });
      } else {
        navigate(`/${userInfo.role.toLowerCase()}-onboarding`, {
          replace: true,
          state: { fromPasswordReset: true },
        });
      }
      return;
    }

    // If we reach here, we're on the correct route
    setIsRouteReady(true);
  }, [isLoading, userInfo, currentUser, location.pathname, navigate]);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  // Show loading until we're sure we're on the correct route
  if (!isRouteReady || isLoading || !userInfo || !currentUser) {
    return <MainLoading />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.grey[100],
        paddingTop: "5vh",
        position: "relative",
      }}
    >
      <Button
        onClick={handleLogout}
        startIcon={<LogoutIcon />}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "black",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        Sign Out
      </Button>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={logoNoBackground}
            alt="FreeTech Logo"
            sx={{
              width: "200px",
              height: "auto",
              marginBottom: 4,
            }}
          />
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "white",
              width: "100%",
              borderRadius: 2,
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            }}
          >
            <Outlet />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default OnboardingLayout;
