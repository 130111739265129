import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileDownload as FileDownloadIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import {
  useAdminCSAs,
  EnrichedCSA,
  UpdateCSARequest,
} from "hooks/admin/useAdminCSAs";
import { useAdminSignCSAs } from "hooks/admin/useAdminSignCSAs";
import { CSAStatus, CSAType, BillableRate } from "@freetech/models/projects";
import { toast } from "react-hot-toast";
import { DateTime } from "luxon";
import { useEversignSDK } from "hooks/eversign/useEversignSDK";
import { useLocation, useNavigate } from "react-router-dom";

// Tab panel component for the detail dialog
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`csa-detail-tabpanel-${index}`}
      aria-labelledby={`csa-detail-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box sx={{ pt: 2, height: "100%" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `csa-detail-tab-${index}`,
    "aria-controls": `csa-detail-tabpanel-${index}`,
  };
}

// Add this function at the top level of the file
const needsSigningCheck = (csa: EnrichedCSA): boolean => {
  // Use a type assertion to handle the string comparison
  const documentStatus = csa.everSignDocumentStatus as unknown as string;
  return (
    csa.status === "admin_signing" &&
    documentStatus === "awaiting_signature" &&
    !!csa.eversignAdminDocumentEmbedUrl
  );
};

const CSAExplorer: React.FC = () => {
  const theme = useTheme();
  const { csas, isLoading, error, refetch, updateCSA, deleteCSA } =
    useAdminCSAs();
  const { generateCSADocument, processSignedCSA, cancelCSADocument } =
    useAdminSignCSAs();
  const { isLoaded: isSdkLoaded, error: sdkError } = useEversignSDK();
  const location = useLocation();
  const navigate = useNavigate();

  // State for filtering and pagination
  const [filteredCSAs, setFilteredCSAs] = useState<EnrichedCSA[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilters, setActiveFilters] = useState<{
    status: (CSAStatus | "needs_approval" | "all")[];
    type: (CSAType | "all")[];
  }>({
    status: ["draft", "admin_signing", "client_signing"],
    type: ["all"],
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // State for CSA detail dialog
  const [selectedCSA, setSelectedCSA] = useState<EnrichedCSA | null>(null);
  const [showDetailDialog, setShowDetailDialog] = useState(false);

  // State for CSA edit dialog
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editFormData, setEditFormData] = useState<UpdateCSARequest | null>(
    null
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // State for delete confirmation dialog
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [csaToDelete, setCsaToDelete] = useState<string | null>(null);

  // State for approval confirmation dialog
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);
  const [csaToApprove, setCsaToApprove] = useState<EnrichedCSA | null>(null);

  // State for iframe loading and error
  const [iframeLoading, setIframeLoading] = useState(true);
  const [iframeError, setIframeError] = useState(false);

  // State for detail dialog tab
  const [detailTabValue, setDetailTabValue] = useState(0);

  // State for generating eversign document
  const [isGeneratingDocument, setIsGeneratingDocument] = useState(false);

  // State for signing
  const [isSigning, setIsSigning] = useState(false);
  const [signingError, setSigningError] = useState<string | null>(null);
  const eversignContainerRef = useRef<HTMLDivElement>(null);

  // Handle URL hash for direct linking to CSA details
  useEffect(() => {
    if (csas && csas.length > 0) {
      const hash = location.hash;
      if (hash && hash.startsWith("#csa-")) {
        const csaId = hash.replace("#csa-", "");
        const csa = csas.find((c) => c.id === csaId);
        if (csa) {
          setSelectedCSA(csa);
          setShowDetailDialog(true);
        }
      }
    }
  }, [csas, location.hash]);

  // Filter CSAs based on search term and filters
  useEffect(() => {
    if (!csas) {
      setFilteredCSAs([]);
      return;
    }

    let filtered = [...csas];

    // Apply search term filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (csa) =>
          csa.name.toLowerCase().includes(term) ||
          csa.clientName?.toLowerCase().includes(term) ||
          csa.projectName?.toLowerCase().includes(term)
      );
    }

    // Apply status filters
    if (!activeFilters.status.includes("all")) {
      filtered = filtered.filter((csa) => {
        if (
          activeFilters.status.includes("needs_approval") &&
          csa.needsApproval
        ) {
          return true;
        }
        return activeFilters.status.some(
          (status) => status !== "needs_approval" && csa.status === status
        );
      });
    }

    // Apply type filters
    if (!activeFilters.type.includes("all")) {
      filtered = filtered.filter((csa) =>
        activeFilters.type.includes(csa.csaType)
      );
    }

    setFilteredCSAs(filtered);
    setPage(0); // Reset to first page when filters change
  }, [csas, searchTerm, activeFilters]);

  // Handle pagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle CSA detail view
  const handleViewCSA = (csa: EnrichedCSA) => {
    setSelectedCSA(csa);
    setShowDetailDialog(true);
    // Update URL hash for direct linking
    navigate(`#csa-${csa.id}`, { replace: true });
  };

  // Handle CSA edit
  const handleEditClick = (csa: EnrichedCSA) => {
    // Only allow editing CSAs in draft state
    if (csa.status !== "draft") {
      toast.error("Only CSAs in draft state can be edited");
      return;
    }

    setEditFormData({
      id: csa.id,
      name: csa.name,
      effectiveDate: csa.effectiveDate,
      endDate: csa.endDate,
      csaType: csa.csaType,
      status: csa.status,
      monthlyBudget: csa.monthlyBudget,
      fixedPriceBudget: csa.fixedPriceBudget,
      billableRates: [...csa.billableRates],
    });
    setShowEditDialog(true);
  };

  // Handle form field changes
  const handleFormChange = (field: string, value: any) => {
    if (!editFormData) return;

    setEditFormData({
      ...editFormData,
      [field]: value,
    });
  };

  // Handle billable rate changes
  const handleRoleChange = (index: number, role: string) => {
    if (!editFormData?.billableRates) return;

    const updatedRates = [...editFormData.billableRates];
    updatedRates[index].role = role;

    setEditFormData({
      ...editFormData,
      billableRates: updatedRates,
    });
  };

  const handleRateChange = (index: number, rate: number) => {
    if (!editFormData?.billableRates) return;

    const updatedRates = [...editFormData.billableRates];
    updatedRates[index].rate = rate;

    setEditFormData({
      ...editFormData,
      billableRates: updatedRates,
    });
  };

  const addBillableRate = () => {
    if (!editFormData?.billableRates) return;

    setEditFormData({
      ...editFormData,
      billableRates: [...editFormData.billableRates, { role: "", rate: 0 }],
    });
  };

  const removeBillableRate = (index: number) => {
    if (!editFormData?.billableRates) return;

    const updatedRates = editFormData.billableRates.filter(
      (_, i) => i !== index
    );

    setEditFormData({
      ...editFormData,
      billableRates: updatedRates,
    });
  };

  // Handle file upload
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      // Check file type (PDF only)
      if (file.type !== "application/pdf") {
        toast.error("Only PDF files are allowed");
        setSelectedFile(null);
        return;
      }

      // Check file size (max 10MB)
      if (file.size > 10 * 1024 * 1024) {
        toast.error("File size should not exceed 10MB");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
    }
  };

  // Handle CSA update
  const handleUpdateCSA = async () => {
    if (!editFormData) return;

    try {
      await updateCSA.mutateAsync({
        ...editFormData,
        file: selectedFile,
      });

      setShowEditDialog(false);
      setEditFormData(null);
      setSelectedFile(null);
    } catch (error) {
      console.error("Error updating CSA:", error);
    }
  };

  // Handle CSA delete
  const handleDeleteClick = (id: string) => {
    setCsaToDelete(id);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (!csaToDelete) return;

    try {
      await deleteCSA.mutateAsync(csaToDelete);
      setShowDeleteDialog(false);
      setCsaToDelete(null);
    } catch (error) {
      console.error("Error deleting CSA:", error);
    }
  };

  // Handle CSA approval
  const handleApproveClick = (csa: EnrichedCSA) => {
    setCsaToApprove(csa);
    setShowApprovalDialog(true);
    // Set URL hash for the CSA being approved
    navigate(`#csa-${csa.id}`, { replace: true });
  };

  const handleConfirmApproval = async () => {
    if (!csaToApprove) return;

    try {
      const updatedCSA = await updateCSA.mutateAsync({
        id: csaToApprove.id,
        needsApproval: false,
      });

      setShowApprovalDialog(false);

      // Find the updated CSA in the list and update the selected CSA if it's the same one
      if (selectedCSA && selectedCSA.id === csaToApprove.id && csas) {
        // Find the updated CSA in the list
        const updatedCSAFromList = csas.find(
          (csa) => csa.id === csaToApprove.id
        );
        if (updatedCSAFromList) {
          setSelectedCSA({
            ...updatedCSAFromList,
            needsApproval: false,
          });
          // Keep the detail dialog open
          setShowDetailDialog(true);
          // Make sure the URL hash is still set
          navigate(`#csa-${csaToApprove.id}`, { replace: true });
        }
      }

      setCsaToApprove(null);
      toast.success("CSA has been approved successfully");
    } catch (error) {
      console.error("Error approving CSA:", error);
      toast.error("Failed to approve CSA");
    }
  };

  // Handle filter changes
  const handleFilterChange = (
    filterType: "status" | "type",
    value: CSAStatus | CSAType | "needs_approval" | "all"
  ) => {
    setActiveFilters((prev) => {
      // If "all" is clicked, clear all other filters of this type
      if (value === "all") {
        return {
          ...prev,
          [filterType]: ["all"],
        };
      }

      // If a specific filter is clicked
      const currentFilters = [...prev[filterType]];

      // Remove 'all' if it's present
      const filteredList = currentFilters.filter((f) => f !== "all");

      // Toggle the selected filter
      if (filteredList.includes(value)) {
        // If this is the last filter and we're removing it, add 'all' back
        if (filteredList.length === 1) {
          return {
            ...prev,
            [filterType]: ["all"],
          };
        }
        // Otherwise just remove the filter
        return {
          ...prev,
          [filterType]: filteredList.filter((f) => f !== value),
        };
      } else {
        // Add the filter
        return {
          ...prev,
          [filterType]: [...filteredList, value],
        };
      }
    });
  };

  // Format currency
  const formatCurrency = (amount: number | undefined) => {
    if (amount === undefined) return "-";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  // Format date
  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return "-";
    return DateTime.fromISO(dateString).toFormat("MMM d, yyyy");
  };

  // Get status chip color
  const getStatusColor = (status: CSAStatus) => {
    switch (status) {
      case "active":
        return "success";
      case "completed":
        return "info";
      case "cancelled":
        return "error";
      case "admin_signing":
        return "warning";
      case "client_signing":
        return "warning";
      default:
        return "default";
    }
  };

  // Get CSA type label
  const getCSATypeLabel = (type: CSAType) => {
    switch (type) {
      case "monthly":
        return "Monthly";
      case "fixed_price":
        return "Fixed Price";
      default:
        return type;
    }
  };

  // Reset iframe state and tab when dialog opens
  useEffect(() => {
    if (showDetailDialog) {
      setIframeLoading(true);
      setIframeError(false);
      setDetailTabValue(0); // Reset to details tab when opening
    }
  }, [showDetailDialog]);

  // Handle tab change in detail dialog
  const handleDetailTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setDetailTabValue(newValue);
  };

  // Handle generating eversign document
  const handleGenerateEversignDocument = async (csa: EnrichedCSA) => {
    if (csa.status !== "draft") {
      toast.error("Only CSAs in draft state can be sent for signing");
      return;
    }

    if (!csa.clientId) {
      toast.error("Client ID is required to generate a document");
      return;
    }

    if (!csa.projectId) {
      toast.error("Project ID is required to generate a document");
      return;
    }

    try {
      setIsGeneratingDocument(true);

      // Generate the CSA document for signing
      await generateCSADocument.mutateAsync({
        csaId: csa.id,
        clientId: csa.clientId,
        projectId: csa.projectId,
        stakeholderId: csa.stakeholderId,
      });

      // Update the CSA status to "admin_signing"
      await updateCSA.mutateAsync({
        id: csa.id,
        status: "admin_signing",
      });

      // Refresh the CSA list
      refetch();

      // Close the detail dialog
      setShowDetailDialog(false);

      toast.success("CSA document has been sent for signing");
    } catch (error) {
      console.error("Error generating CSA document:", error);
      toast.error(
        `Failed to generate CSA document: ${error instanceof Error ? error.message : "Unknown error"}`
      );
    } finally {
      setIsGeneratingDocument(false);
    }
  };

  // Effect to initialize eversign signing when tab changes to signing
  useEffect(() => {
    if (
      detailTabValue === 2 && // Signing tab
      selectedCSA &&
      needsSigningCheck(selectedCSA) &&
      isSdkLoaded &&
      eversignContainerRef.current
    ) {
      try {
        setIsSigning(true);
        setSigningError(null);

        // Short timeout to ensure the container is ready
        setTimeout(() => {
          try {
            // @ts-ignore
            window.eversign.open({
              url: selectedCSA.eversignAdminDocumentEmbedUrl,
              containerID: "eversign-container",
              width: "100%",
              height: "100%",
              events: {
                loaded: () => {
                  // Once the interface is loaded, remove the loading indicator
                  setIsSigning(false);
                },
                signed: () => {
                  toast.success("Document signed successfully");

                  // Process the signed CSA document
                  if (selectedCSA) {
                    processSignedCSA
                      .mutateAsync({
                        documentHash: selectedCSA.eversignDocumentHash || "",
                        clientId: selectedCSA.clientId,
                        csaId: selectedCSA.id,
                      })
                      .then(() => {
                        // Refresh the CSA list after processing
                        refetch();
                      })
                      .catch((error) => {
                        console.error("Error processing signed CSA:", error);
                        toast.error(
                          `Error processing signed CSA: ${error instanceof Error ? error.message : "Unknown error"}`
                        );
                      });
                  }

                  setDetailTabValue(0); // Go back to details tab
                },
                declined: () => {
                  toast.error("Document signing was declined");
                },
                error: (err: any) => {
                  console.error("Eversign error:", err);
                  setSigningError("Error signing document. Please try again.");
                },
              },
            });
          } catch (err) {
            console.error("Error initializing eversign:", err);
            setSigningError(
              "Error preparing signing interface. Please refresh the page."
            );
            setIsSigning(false);
          }
        }, 500);
      } catch (err) {
        console.error("Error in signing effect:", err);
        setSigningError(
          "Error preparing signing interface. Please refresh the page."
        );
        setIsSigning(false);
      }
    }

    // Cleanup function to handle component unmounting
    return () => {
      // If there's an active eversign session, clean it up
      try {
        // @ts-ignore
        if (window.eversign && window.eversign.close) {
          // @ts-ignore
          window.eversign.close();
        }
      } catch (err) {
        console.error("Error cleaning up eversign:", err);
      }
    };
  }, [detailTabValue, selectedCSA, isSdkLoaded]);

  // Render CSA table with highlighted rows for signing
  const renderCSATable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="CSA table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Effective Date</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            ) : filteredCSAs.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No CSAs found
                </TableCell>
              </TableRow>
            ) : (
              filteredCSAs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((csa) => (
                  <TableRow
                    key={csa.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      // Highlight rows that need signing
                      ...(needsSigningCheck(csa) && {
                        backgroundColor: theme.palette.warning.light + "20",
                        "&:hover": {
                          backgroundColor: theme.palette.warning.light + "30",
                        },
                      }),
                      // Highlight draft rows
                      ...(csa.status === "draft" && {
                        backgroundColor: theme.palette.info.light + "20",
                        "&:hover": {
                          backgroundColor: theme.palette.info.light + "30",
                        },
                      }),
                    }}
                  >
                    <TableCell>{csa.name}</TableCell>
                    <TableCell>{csa.clientName || "-"}</TableCell>
                    <TableCell>{csa.projectName || "-"}</TableCell>
                    <TableCell>{getCSATypeLabel(csa.csaType)}</TableCell>
                    <TableCell>
                      <Chip
                        label={csa.status}
                        size="small"
                        color={getStatusColor(csa.status) as any}
                      />
                    </TableCell>
                    <TableCell>{formatDate(csa.effectiveDate)}</TableCell>
                    <TableCell>
                      {csa.csaType === "monthly"
                        ? `${formatCurrency(csa.monthlyBudget)}/mo`
                        : formatCurrency(csa.fixedPriceBudget)}
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                      >
                        <Tooltip title="View Details">
                          <IconButton
                            size="small"
                            onClick={() => handleViewCSA(csa)}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {csa.status === "draft" && (
                          <Tooltip title="Edit">
                            <IconButton
                              size="small"
                              onClick={() => handleEditClick(csa)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleDeleteClick(csa.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCSAs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    );
  };

  // Render CSA detail dialog with signing tab
  const renderDetailDialog = () => {
    if (!selectedCSA) return null;

    const isDraft = selectedCSA.status === "draft";
    const needsSigning = needsSigningCheck(selectedCSA);

    const handleCloseDialog = () => {
      setShowDetailDialog(false);
      // Clear URL hash when closing dialog
      navigate("", { replace: true });
    };

    return (
      <Dialog
        open={showDetailDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            height: { xs: "auto", md: "80vh" },
            maxHeight: "90vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              CSA Details: {selectedCSA.name}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <Box sx={{ borderBottom: 1, borderColor: "divider", px: 3 }}>
          <Tabs
            value={detailTabValue}
            onChange={handleDetailTabChange}
            aria-label="CSA detail tabs"
          >
            <Tab label="Details" {...a11yProps(0)} />
            {selectedCSA.fileUrl && (
              <Tab label="Document Preview" {...a11yProps(1)} />
            )}
            {needsSigning && <Tab label="Sign Document" {...a11yProps(2)} />}
          </Tabs>
        </Box>

        <DialogContent
          dividers
          sx={{
            p: 0,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <TabPanel value={detailTabValue} index={0}>
            <Box sx={{ p: 3, overflow: "auto", height: "100%" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                    <Chip
                      label={selectedCSA.status}
                      size="small"
                      color={getStatusColor(selectedCSA.status) as any}
                    />
                    {selectedCSA.needsApproval && (
                      <Chip
                        label="Needs Approval"
                        size="small"
                        color="warning"
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Client
                  </Typography>
                  <Typography variant="body1">
                    {selectedCSA.clientName || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Project
                  </Typography>
                  <Typography variant="body1">
                    {selectedCSA.projectName || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    CSA Type
                  </Typography>
                  <Typography variant="body1">
                    {getCSATypeLabel(selectedCSA.csaType)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Budget
                  </Typography>
                  <Typography variant="body1">
                    {selectedCSA.csaType === "monthly"
                      ? `${formatCurrency(selectedCSA.monthlyBudget)}/month`
                      : formatCurrency(selectedCSA.fixedPriceBudget)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Effective Date
                  </Typography>
                  <Typography variant="body1">
                    {formatDate(selectedCSA.effectiveDate)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    End Date
                  </Typography>
                  <Typography variant="body1">
                    {formatDate(selectedCSA.endDate)}
                  </Typography>
                </Grid>

                {/* Billable Rates Section */}
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Billable Rates
                  </Typography>
                  <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Role</TableCell>
                          <TableCell align="right">Hourly Rate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedCSA.billableRates.map((rate, index) => (
                          <TableRow key={index}>
                            <TableCell>{rate.role}</TableCell>
                            <TableCell align="right">
                              {formatCurrency(rate.rate)}/hr
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          {selectedCSA.fileUrl && (
            <TabPanel value={detailTabValue} index={1}>
              <Box
                sx={{
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <iframe
                  src={selectedCSA.fileUrl}
                  title="CSA Document Preview"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                  onLoad={() => {
                    setIframeLoading(false);
                  }}
                  onError={() => {
                    setIframeLoading(false);
                    setIframeError(true);
                  }}
                />

                {/* Loading indicator */}
                {iframeLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}

                {/* Error message */}
                {iframeError && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      align="center"
                    >
                      Unable to display the document in the browser.
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                      sx={{ mt: 1 }}
                    >
                      Please contact support if you need to download this
                      document.
                    </Typography>
                  </Box>
                )}

                {/* Fallback message */}
                {!iframeLoading && !iframeError && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                      zIndex: -1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      align="center"
                    >
                      If the document doesn't appear, please try a different
                      browser.
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                      sx={{ mt: 1 }}
                    >
                      Some browsers may block PDF viewing in iframes for
                      security reasons.
                    </Typography>
                  </Box>
                )}
              </Box>
            </TabPanel>
          )}

          {/* Signing Tab */}
          {needsSigning && (
            <TabPanel value={detailTabValue} index={2}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {isSigning && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.5)", // More transparent
                      zIndex: 5, // Lower z-index to allow interaction
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}

                {signingError && (
                  <Box
                    sx={{
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography variant="h6" color="error" gutterBottom>
                      {signingError}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSigningError(null);
                        setDetailTabValue(0); // Go back to details tab
                      }}
                    >
                      Back to Details
                    </Button>
                  </Box>
                )}

                {/* Always render the container, even during loading */}
                <Box
                  ref={eversignContainerRef}
                  id="eversign-container"
                  sx={{
                    flexGrow: 1,
                    border: "1px solid #eee",
                    borderRadius: 1,
                    overflow: "hidden",
                    // Ensure the container is visible and interactive
                    position: "relative",
                    zIndex: 10,
                  }}
                />
              </Box>
            </TabPanel>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          {selectedCSA.needsApproval && (
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                handleCloseDialog();
                handleApproveClick(selectedCSA);
              }}
            >
              Approve
            </Button>
          )}
          {isDraft && !selectedCSA.needsApproval && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCloseDialog();
                  handleEditClick(selectedCSA);
                }}
              >
                Edit
              </Button>
              {/* Only show Generate Eversign Document button if CSA has been approved */}
              {!selectedCSA.needsApproval && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<SendIcon />}
                  onClick={() => handleGenerateEversignDocument(selectedCSA)}
                  disabled={isGeneratingDocument}
                >
                  {isGeneratingDocument
                    ? "Generating..."
                    : "Generate Eversign Document"}
                </Button>
              )}
            </>
          )}
          {needsSigning && detailTabValue !== 2 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDetailTabValue(2)}
            >
              Sign Document
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  // Render CSA edit dialog
  const renderEditDialog = () => {
    if (!editFormData) return null;

    return (
      <Dialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Edit CSA</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setShowEditDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="CSA Name"
                value={editFormData.name || ""}
                disabled
                InputProps={{
                  readOnly: true,
                }}
                helperText="CSA name cannot be edited"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Effective Date"
                value={
                  editFormData.effectiveDate
                    ? DateTime.fromISO(editFormData.effectiveDate)
                    : null
                }
                onChange={(date) =>
                  handleFormChange(
                    "effectiveDate",
                    date ? date.toISODate() : null
                  )
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="End Date (Optional)"
                value={
                  editFormData.endDate
                    ? DateTime.fromISO(editFormData.endDate)
                    : null
                }
                onChange={(date) =>
                  handleFormChange("endDate", date ? date.toISODate() : null)
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>CSA Type</InputLabel>
                <Select
                  value={editFormData.csaType || "monthly"}
                  label="CSA Type"
                  onChange={(e) => handleFormChange("csaType", e.target.value)}
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="fixed_price">Fixed Price</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={editFormData.status || "active"}
                  label="Status"
                  disabled
                  inputProps={{
                    readOnly: true,
                  }}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
                <FormHelperText>Status cannot be edited</FormHelperText>
              </FormControl>
            </Grid>

            {editFormData.csaType === "monthly" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Monthly Budget"
                  value={editFormData.monthlyBudget || 0}
                  onChange={(e) =>
                    handleFormChange(
                      "monthlyBudget",
                      parseFloat(e.target.value) || 0
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            {editFormData.csaType === "fixed_price" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Fixed Price Budget"
                  value={editFormData.fixedPriceBudget || 0}
                  onChange={(e) =>
                    handleFormChange(
                      "fixedPriceBudget",
                      parseFloat(e.target.value) || 0
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Billable Rates
              </Typography>

              {editFormData.billableRates?.map((rate, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Role"
                        value={rate.role}
                        onChange={(e) =>
                          handleRoleChange(index, e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Hourly Rate"
                        value={rate.rate}
                        onChange={(e) =>
                          handleRateChange(
                            index,
                            parseFloat(e.target.value) || 0
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton
                        color="error"
                        onClick={() => removeBillableRate(index)}
                        disabled={
                          (editFormData.billableRates?.length || 0) <= 1
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Button
                startIcon={<AddIcon />}
                onClick={addBillableRate}
                sx={{ mt: 1 }}
              >
                Add Billable Rate
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditDialog(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateCSA}
            disabled={updateCSA.isPending}
          >
            {updateCSA.isPending ? (
              <CircularProgress size={24} />
            ) : (
              "Save Changes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Render delete confirmation dialog
  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Consulting Services Agreement?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            disabled={deleteCSA.isPending}
          >
            {deleteCSA.isPending ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Render approval confirmation dialog
  const renderApprovalDialog = () => {
    return (
      <Dialog
        open={showApprovalDialog}
        onClose={() => setShowApprovalDialog(false)}
      >
        <DialogTitle>Confirm Approval</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to approve this Consulting Services Agreement?
            This will mark it as ready for the next steps in the process.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowApprovalDialog(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmApproval}
            color="success"
            variant="contained"
            disabled={updateCSA.isPending}
          >
            {updateCSA.isPending ? <CircularProgress size={24} /> : "Approve"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 4,
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Consulting Services Agreement Explorer
        </Typography>
        <Typography variant="body1" color="text.secondary">
          View, edit, and manage all Consulting Services Agreements (CSAs).
        </Typography>
      </Paper>

      {/* Search and Filters */}
      <Paper
        elevation={2}
        sx={{
          p: 3,
          mb: 4,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search by name, client, or project"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ mr: 1, alignSelf: "center" }}
              >
                Status:
              </Typography>

              <Chip
                label="All"
                color={
                  activeFilters.status.includes("all") ? "primary" : "default"
                }
                onClick={() => handleFilterChange("status", "all")}
                variant={
                  activeFilters.status.includes("all") ? "filled" : "outlined"
                }
              />

              <Chip
                label="Admin Signing"
                color={
                  activeFilters.status.includes("admin_signing")
                    ? "primary"
                    : "default"
                }
                onClick={() => handleFilterChange("status", "admin_signing")}
                variant={
                  activeFilters.status.includes("admin_signing")
                    ? "filled"
                    : "outlined"
                }
              />

              <Chip
                label="Client Signing"
                color={
                  activeFilters.status.includes("client_signing")
                    ? "primary"
                    : "default"
                }
                onClick={() => handleFilterChange("status", "client_signing")}
                variant={
                  activeFilters.status.includes("client_signing")
                    ? "filled"
                    : "outlined"
                }
              />

              <Chip
                label="Draft"
                color={
                  activeFilters.status.includes("draft") ? "primary" : "default"
                }
                onClick={() => handleFilterChange("status", "draft")}
                variant={
                  activeFilters.status.includes("draft") ? "filled" : "outlined"
                }
              />

              <Chip
                label="Needs Approval"
                color={
                  activeFilters.status.includes("needs_approval")
                    ? "primary"
                    : "default"
                }
                onClick={() => handleFilterChange("status", "needs_approval")}
                variant={
                  activeFilters.status.includes("needs_approval")
                    ? "filled"
                    : "outlined"
                }
              />

              <Chip
                label="Active"
                color={
                  activeFilters.status.includes("active")
                    ? "primary"
                    : "default"
                }
                onClick={() => handleFilterChange("status", "active")}
                variant={
                  activeFilters.status.includes("active")
                    ? "filled"
                    : "outlined"
                }
              />

              <Chip
                label="Completed"
                color={
                  activeFilters.status.includes("completed")
                    ? "primary"
                    : "default"
                }
                onClick={() => handleFilterChange("status", "completed")}
                variant={
                  activeFilters.status.includes("completed")
                    ? "filled"
                    : "outlined"
                }
              />

              <Chip
                label="Cancelled"
                color={
                  activeFilters.status.includes("cancelled")
                    ? "primary"
                    : "default"
                }
                onClick={() => handleFilterChange("status", "cancelled")}
                variant={
                  activeFilters.status.includes("cancelled")
                    ? "filled"
                    : "outlined"
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ mr: 1, alignSelf: "center" }}
              >
                Type:
              </Typography>

              <Chip
                label="All"
                color={
                  activeFilters.type.includes("all") ? "primary" : "default"
                }
                onClick={() => handleFilterChange("type", "all")}
                variant={
                  activeFilters.type.includes("all") ? "filled" : "outlined"
                }
              />

              <Chip
                label="Monthly"
                color={
                  activeFilters.type.includes("monthly") ? "primary" : "default"
                }
                onClick={() => handleFilterChange("type", "monthly")}
                variant={
                  activeFilters.type.includes("monthly") ? "filled" : "outlined"
                }
              />

              <Chip
                label="Fixed Price"
                color={
                  activeFilters.type.includes("fixed_price")
                    ? "primary"
                    : "default"
                }
                onClick={() => handleFilterChange("type", "fixed_price")}
                variant={
                  activeFilters.type.includes("fixed_price")
                    ? "filled"
                    : "outlined"
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* CSA Table */}
      <Paper
        elevation={2}
        sx={{
          borderRadius: theme.shape.borderRadius,
          overflow: "hidden",
        }}
      >
        {error ? (
          <Box p={3} textAlign="center">
            <Typography color="error">
              Error loading CSAs:{" "}
              {error instanceof Error ? error.message : "Unknown error"}
            </Typography>
            <Button variant="outlined" onClick={() => refetch()} sx={{ mt: 2 }}>
              Retry
            </Button>
          </Box>
        ) : (
          renderCSATable()
        )}
      </Paper>

      {/* Dialogs */}
      {renderDetailDialog()}
      {renderEditDialog()}
      {renderDeleteDialog()}
      {renderApprovalDialog()}
    </Box>
  );
};

export default CSAExplorer;
