import React, { useMemo, useCallback } from "react";
import {
  Popover,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
  Divider,
  Button,
  TextField,
  InputAdornment,
  Chip,
  Stack,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import EditIcon from "@mui/icons-material/Edit";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { useAuth } from "hooks/auth/useAuth";
import { Board } from "@freetech/models/freelo";
import { PublicUserInfoType } from "@freetech/models/user";

interface FreeloBoardFiltersProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  labels: { id: string; name: string; color: string }[];
  onFiltersChange: (filters: BoardFilters) => void;
  filters: BoardFilters;
  board: Board;
}

export interface BoardFilters {
  members: string[];
  labels: string[];
  searchText: string;
}

export const FreeloBoardFilters: React.FC<FreeloBoardFiltersProps> = React.memo(({
  open,
  anchorEl,
  onClose,
  labels,
  onFiltersChange,
  filters,
  board,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { data: users } = usePublicUserList();
  const { userInfo: currentUser } = useAuth();

  const handleFilterChange = useCallback(<K extends keyof BoardFilters>(
    key: K,
    value: BoardFilters[K]
  ) => {
    onFiltersChange({
      ...filters,
      [key]: value,
    });
  }, [filters, onFiltersChange]);

  const clearFilters = useCallback(() => {
    onFiltersChange({
      members: [],
      labels: [],
      searchText: "",
    });
  }, [onFiltersChange]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  // Get all users who have write access
  const writeAccessUsers = useMemo(() => {
    if (!users || !board.writeUserIds) return new Set<string>();
    return new Set(board.writeUserIds);
  }, [users, board.writeUserIds]);

  // Filter users based on search term and write access
  const filteredUsers = useMemo(() => {
    if (!users) return [];
    return users
      .filter(user => writeAccessUsers.has(user.id))
      .filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
  }, [users, searchTerm, writeAccessUsers]);

  const activeFiltersCount = useMemo(() => 
    filters.members.length + filters.labels.length + (filters.searchText ? 1 : 0),
  [filters.members.length, filters.labels.length, filters.searchText]);

  const handleMemberToggle = useCallback((userId: string) => {
    const newMembers = filters.members.includes(userId)
      ? filters.members.filter((id) => id !== userId)
      : [...filters.members, userId];
    handleFilterChange("members", newMembers);
  }, [filters.members, handleFilterChange]);

  const handleLabelToggle = useCallback((labelId: string) => {
    const newLabels = filters.labels.includes(labelId)
      ? filters.labels.filter((id) => id !== labelId)
      : [...filters.labels, labelId];
    handleFilterChange("labels", newLabels);
  }, [filters.labels, handleFilterChange]);

  const renderUserLabel = (user: PublicUserInfoType) => {
    const hasWriteAccess = writeAccessUsers.has(user.id);
    const label = (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
        {hasWriteAccess && (
          <Tooltip title="Can edit cards">
            <EditIcon sx={{ fontSize: 16, color: 'primary.main' }} />
          </Tooltip>
        )}
      </Box>
    );

    return label;
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          width: "384px",
          maxWidth: "90vw",
          maxHeight: "80vh",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Filter cards
          </Typography>
          {activeFiltersCount > 0 && (
            <Chip size="small" label={activeFiltersCount} color="primary" />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          {activeFiltersCount > 0 && (
            <Button
              size="small"
              startIcon={<FilterAltOffIcon />}
              onClick={clearFilters}
              color="inherit"
            >
              Clear
            </Button>
          )}
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          height: "100%",
          maxHeight: "calc(80vh - 64px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "action.hover",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey.400",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "grey.500",
            },
          },
        }}
      >
        {/* Members Section */}
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1.5, fontWeight: 500 }}>
            Card Members with Write Access
          </Typography>

          <TextField
            fullWidth
            size="small"
            placeholder="Search members..."
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" color="action" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />

          <FormGroup>
            {currentUser && writeAccessUsers.has(currentUser.id) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.members.includes(currentUser.id)}
                    onChange={() => handleMemberToggle(currentUser.id)}
                  />
                }
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography>{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
                    <Chip
                      label="You"
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{ height: 20 }}
                    />
                    {filters.members.includes(currentUser.id) && (
                      <Chip
                        label="Selected"
                        size="small"
                        color="primary"
                        sx={{ height: 20 }}
                      />
                    )}
                  </Box>
                }
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.members.length === 0}
                  onChange={() => handleFilterChange("members", [])}
                  indeterminate={filters.members.length > 0 && filters.members.length < filteredUsers.length}
                />
              }
              label="All members"
            />

            <Divider sx={{ my: 1 }} />

            {filteredUsers.map(
              (user) =>
                user.id !== currentUser?.id && (
                  <FormControlLabel
                    key={user.id}
                    control={
                      <Checkbox
                        checked={filters.members.includes(user.id)}
                        onChange={() => handleMemberToggle(user.id)}
                      />
                    }
                    label={
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                        {filters.members.includes(user.id) && (
                          <Chip
                            label="Selected"
                            size="small"
                            color="primary"
                            sx={{ height: 20 }}
                          />
                        )}
                      </Box>
                    }
                  />
                )
            )}
          </FormGroup>
        </Box>

        <Divider />

        {/* Labels Section */}
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1.5, fontWeight: 500 }}>
            Labels
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.labels.length === 0}
                  onChange={() => handleLabelToggle(labels[0].id)}
                />
              }
              label="No labels"
            />

            <Divider sx={{ my: 1 }} />

            <Stack spacing={1}>
              {labels.map((label) => (
                <Box
                  key={label.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 1,
                    bgcolor: `${label.color}11`,
                    border: 1,
                    borderColor: filters.labels.includes(label.id)
                      ? label.color
                      : "transparent",
                    transition: "all 0.2s ease",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: `${label.color}22`,
                    },
                  }}
                  onClick={() => handleLabelToggle(label.id)}
                >
                  <Checkbox
                    checked={filters.labels.includes(label.id)}
                    sx={{
                      color: label.color,
                      "&.Mui-checked": {
                        color: label.color,
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: 32,
                        height: 8,
                        borderRadius: 4,
                        bgcolor: label.color,
                      }}
                    />
                    <Typography>{label.name}</Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
          </FormGroup>
        </Box>
      </Box>
    </Popover>
  );
});

FreeloBoardFilters.displayName = 'FreeloBoardFilters';
