import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { MFAWarning } from "components/MFAWarning";
import Footer from "components/Footer";
import { ChatBubble } from "../../../components/ChatBubble/ChatBubble";
import {
  superuserPages,
  getFreelancerPages,
  stakeholderPages,
} from "router/pages";
import { Navigation } from "types/navigation";
import { Outlet } from "react-router-dom";
import { CoreAppBar } from "./components/CoreAppBar";
import { CoreDrawer } from "./components/CoreDrawer";
import { CoreBreadcrumbs } from "./components/CoreBreadcrumbs";
import { MainLoading } from "../MainLoading";
import { useNavigation } from "hooks/useNavigation";
import { AIChatBubble } from "components/AIChatBubble/AIChatBubble";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const DRAWER_WIDTH = 0;
const CLOSED_DRAWER_WIDTH = -240;

export const CoreLayout: React.FC = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo, currentUser, isLoading } = useAuth();
  const { navigationItems } = useNavigation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
      return;
    }
  }, [currentUser, navigate]);

  if (isLoading || !currentUser) {
    return <MainLoading />;
  }

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  if (userInfo?.needsInitialPasswordSet) {
    return <Navigate to="/needs-reset-password" />;
  }

  // Redirect freelancers who need onboarding
  if (
    (userInfo?.role === "freelancer" ||
      userInfo?.role === "executiveAssistant") &&
    userInfo.needsOnboarding === true
  ) {
    return <Navigate to="/freelancer-onboarding" />;
  }

  // Redirect stakeholders who need onboarding
  if (userInfo?.role === "stakeholder" && userInfo.needsOnboarding === true) {
    return <Navigate to="/stakeholder-onboarding" />;
  }

  const isFreeloBoard =
    location.pathname.startsWith("/freelo/") && location.pathname !== "/freelo";
  const isKnowledgeBase = location.pathname.startsWith("/knowledge-base");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CoreAppBar open={open} onDrawerToggle={handleDrawerToggle} />
      <CoreDrawer
        open={open}
        onDrawerClose={handleDrawerToggle}
        navigationItems={navigationItems}
        setOpen={setOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: isMobile 
            ? 0 
            : {
                xs: `${open ? DRAWER_WIDTH : (CLOSED_DRAWER_WIDTH - 60)}px`,
                sm: `${open ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH}px`,
              },
          transition: (theme) =>
            theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),

          marginTop: "64px", // Height of AppBar
          padding: isFreeloBoard || isKnowledgeBase ? 0 : 3,
          minHeight: "calc(100vh - 64px)",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <MFAWarning />
        <Box sx={{ flex: 1 }}>
          <CoreBreadcrumbs
            navigationItems={navigationItems}
            disabled={isFreeloBoard || isKnowledgeBase}
          />
          <Outlet />
        </Box>
        {isFreeloBoard || isKnowledgeBase ? null : <Footer />}
        {/* <ChatBubble /> */}
        <AIChatBubble />
      </Box>
    </Box>
  );
};

export default CoreLayout;
