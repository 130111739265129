import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Stakeholder, UserDocument } from "@freetech/models/user";
import {
  loadSingleStakeholder,
  updateStakeholderProfile as updateProfile,
  fetchStakeholderDocuments,
} from "core/stakeholder";
import { loadClientsNonAdmin } from "core/clients";
import { useAuth } from "../auth/useAuth";
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { db } from "config/firebase";
import { Client, Project, ProjectStatus } from "@freetech/models/projects";

type CreateCompanyParams = Omit<Client, "id">;

type CreateProjectParams = Omit<Project, "id">;

export const useStakeholder = (stakeholderId?: string) => {
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();

  const { data: stakeholderProfile, isLoading: isLoadingStakeholderProfile } =
    useQuery({
      queryKey: ["stakeholderProfile", stakeholderId],
      queryFn: async () => await loadSingleStakeholder(stakeholderId || ""),
      enabled: !!stakeholderId,
    });

  const {
    data: stakeholderDocuments,
    isLoading: isLoadingStakeholderDocuments,
  } = useQuery({
    queryKey: ["stakeholderDocuments", stakeholderId],
    queryFn: () => fetchStakeholderDocuments(stakeholderId || ""),
    enabled: !!stakeholderId,
  });

  const { mutate: updateStakeholderProfile } = useMutation({
    mutationFn: (updatedStakeholder: Partial<Stakeholder>) =>
      updateProfile(stakeholderId || "", updatedStakeholder),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["stakeholderProfile", stakeholderId],
      });
    },
  });

  const createCompany = async (companyData: CreateCompanyParams) => {
    if (!userInfo?.id) {
      throw new Error("No authenticated user");
    }

    const newClient: Partial<Client> = {
      ...companyData,
      status: "active",
      introductionDate: new Date().toISOString(),
      associatedStakeholderIds: [userInfo.id],
      createdByUserId: userInfo.id,
    };

    const clientsRef = collection(db, "clients");
    const docRef = await addDoc(clientsRef, newClient);

    return {
      id: docRef.id,
      ...newClient,
    };
  };

  const {
    mutate: createStakeholderCompany,
    isPending: isCreatingCompany,
    error: mutation,
  } = useMutation({
    mutationFn: createCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stakeholder-clients"] });
    },
  });

  const createProject = async (projectData: CreateProjectParams) => {
    if (!userInfo?.id) {
      throw new Error("No authenticated user");
    }

    const newProject: Partial<Project> = {
      ...projectData,
      ideaDate: new Date().toISOString(),
      startDate: null,
      endDate: null,
      createdByUserId: projectData.createdByUserId || userInfo.id,
    };

    const projectsRef = collection(
      db,
      "clients",
      projectData.clientId,
      "projects"
    );
    const docRef = await addDoc(projectsRef, newProject);

    return {
      id: docRef.id,
      ...newProject,
    };
  };

  const {
    mutate: createStakeholderProject,
    isPending: isCreatingProject,
    error: projectError,
  } = useMutation({
    mutationFn: createProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stakeholder-clients"] });
    },
  });

  const fetchClients = async () => {
    if (!stakeholderId) {
      throw new Error("No authenticated user");
    }

    if (!userInfo) {
      throw new Error("No user info");
    }

    const allClients = await loadClientsNonAdmin(userInfo);
    return allClients;
  };

  const {
    data: clients,
    error: clientsError,
    isLoading: clientsIsLoading,
  } = useQuery({
    queryKey: ["stakeholder-clients", stakeholderId],
    queryFn: fetchClients,
    enabled: !!stakeholderId,
  });

  const {
    data: projects,
    error: projectsError,
    isLoading: projectsIsLoading,
  } = useQuery({
    queryKey: ["stakeholder-projects", stakeholderId],
    queryFn: async () => {
      if (!userInfo) {
        throw new Error("No user info");
      }

      try {
        const allClients = await loadClientsNonAdmin(userInfo);
        console.log("allClients", allClients);
        const q = query(
          collectionGroup(db, "projects"),
          where(
            "clientId",
            "in",
            allClients?.map((client) => client.id)
          )
        );
        
        const projects = await getDocs(q);
        const projectDocs = projects.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          } as Project;
        });
        console.log("projectDocs", projectDocs);
        return projectDocs;
      } catch (error) {
        throw error;
        return [];
      }
    },
  });
  return {
    stakeholderProfile,
    isLoadingStakeholderProfile,
    stakeholderDocuments,
    isLoadingStakeholderDocuments,
    updateStakeholderProfile,
    clients,
    clientsIsLoading,
    clientsError,
    createCompany: createStakeholderCompany,
    isCreatingCompany,
    companyError: mutation,
    createProject: createStakeholderProject,
    isCreatingProject,
    projectError,
    projects,
    projectsIsLoading,
    projectsError,
  };
};
