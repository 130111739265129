import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AddIcon from "@mui/icons-material/Add";
interface NonBillableWarningDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const NonBillableWarningDialog: React.FC<
  NonBillableWarningDialogProps
> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: "warning.main",
        }}
      >
        <WarningAmberIcon color="warning" />
        Non-Billable Time Entry
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You are about to track time without selecting a project assignment.
          Please note:
        </Typography>
        <Box sx={{ pl: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            • These hours will be tracked as non-billable learning/additional
            tasks
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            • They will not be considered billable under any project
          </Typography>
          <Typography variant="body2">
            • This cannot be changed later without administrative approval
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="warning"
          startIcon={<AddIcon />}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
