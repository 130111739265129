import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tabs,
  Tab,
  Button,
  TextField,
} from "@mui/material";
import { useAdminTimesheet } from "hooks/admin/useAdminTimesheet";
import { useAuth } from "hooks/auth/useAuth";
import TimesheetApprovalsGrid from "./components/TimesheetApprovalsGrid";
import { Timesheet } from "@freetech/models/timesheet";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const calculateTotalHours = (entries: Timesheet[]) => {
  const totalSeconds = entries.reduce(
    (total, entry) => total + (entry.duration || 0),
    0
  );
  return (totalSeconds / 3600).toFixed(2); // Convert seconds to hours and format to 2 decimal places
};

const groupEntriesByProject = (entries: Timesheet[]) => {
  return entries.reduce(
    (acc, entry) => {
      const projectKey = entry.projectId;
      if (!acc[projectKey]) {
        acc[projectKey] = {
          projectName: entry.projectName || "Unknown Project",
          entries: [],
          totalHours: 0,
        };
      }
      acc[projectKey].entries.push(entry);
      acc[projectKey].totalHours += (entry.duration || 0) / 3600; // Convert to hours
      return acc;
    },
    {} as Record<
      string,
      { projectName: string; entries: Timesheet[]; totalHours: number }
    >
  );
};

export const TimesheetApprovals: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const { userInfo } = useAuth();
  const {
    timesheets,
    setTimesheets,
    getAllTimesheets,
    getTimesheet,
    updateTimesheet,
    approveTimesheet,
    rejectTimesheet,
    revokeApproval,
    bulkRevokeApproval,
    refetch,
  } = useAdminTimesheet();

  const { data: fetchedTimesheets, isLoading } = getAllTimesheets();

  useEffect(() => {
    if (fetchedTimesheets) {
      setTimesheets(fetchedTimesheets);
    }
  }, [fetchedTimesheets, setTimesheets]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const getCurrentTabEntries = useCallback(() => {
    switch (tabValue) {
      case 0:
        return timesheets.filter((t) => t.submitted && !t.approved);
      case 1:
        return timesheets.filter((t) => !t.submitted && !t.approved);
      case 2:
        return timesheets.filter((t) => t.submitted && t.approved);
      default:
        return [];
    }
  }, [tabValue, timesheets]);

  const groupEntriesByUser = useMemo(() => {
    const entries = getCurrentTabEntries();
    return entries.reduce(
      (acc, entry) => {
        if (!acc[entry.userId]) {
          acc[entry.userId] = [];
        }
        acc[entry.userId].push(entry);
        return acc;
      },
      {} as Record<string, Timesheet[]>
    );
  }, [getCurrentTabEntries]);

  const filteredEntries = useMemo(() => {
    return Object.entries(groupEntriesByUser).filter(([userId, entries]) =>
      entries.some((entry) =>
        Object.values(entry).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    );
  }, [groupEntriesByUser, searchTerm]);

  const paginatedEntries = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredEntries.slice(startIndex, endIndex);
  }, [filteredEntries, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(filteredEntries.length / itemsPerPage);
  const totalItems = filteredEntries.length;
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handleApproveTimesheet = async (id: string) => {
    if (userInfo?.id) {
      await approveTimesheet.mutateAsync({ id, approverId: userInfo.id });
    }
  };

  const handleRejectTimesheet = async (id: string, reason?: string) => {
    if (userInfo?.id) {
      await rejectTimesheet.mutateAsync({
        id,
        rejectorId: userInfo.id,
        reason,
      });
    }
  };

  const handleSubmitForUser = async (id: string) => {
    await updateTimesheet.mutateAsync({ id, submitted: true });
  };

  const handleRemindToSubmit = () => {
    // Implement remind to submit functionality
  };

  const handleRemindToApprove = () => {
    // Implement remind to approve functionality
  };

  const handleApproveAll = () => {
    // Implement approve all functionality
  };

  const handleBulkApprove = async (ids: string[]) => {
    // Store original timesheets state for rollback
    const originalTimesheets = [...timesheets];

    try {
      // Optimistically update UI
      setTimesheets((prev) =>
        prev.map((timesheet) => {
          if (ids.includes(timesheet.id)) {
            return {
              ...timesheet,
              approved: true,
              approvedAt: new Date().toISOString(),
              approvedById: userInfo?.id,
              approvedByEmail: userInfo?.email,
            };
          }
          return timesheet;
        })
      );

      // Perform actual API updates
      await Promise.all(
        ids.map((id) =>
          approveTimesheet.mutateAsync({
            id,
            approverId: userInfo?.id || "",
          })
        )
      );
    } catch (error) {
      // Rollback on error
      setTimesheets(originalTimesheets);
      // Show error notification
      console.error("Failed to approve timesheets:", error);
    }
  };

  const handleBulkReject = async (ids: string[], reason?: string) => {
    const originalTimesheets = [...timesheets];

    try {
      // Optimistically update UI
      setTimesheets((prev) =>
        prev.map((timesheet) => {
          if (ids.includes(timesheet.id)) {
            return {
              ...timesheet,
              approved: false,
              rejected: true,
              rejectedAt: new Date().toISOString(),
              rejectedById: userInfo?.id,
              rejectedByEmail: userInfo?.email,
              rejectionReason: reason,
            };
          }
          return timesheet;
        })
      );

      // Perform actual API updates
      await Promise.all(
        ids.map((id) =>
          rejectTimesheet.mutateAsync({
            id,
            rejectorId: userInfo?.id || "",
            reason,
          })
        )
      );
    } catch (error) {
      // Rollback on error
      setTimesheets(originalTimesheets);
      // Show error notification
      console.error("Failed to reject timesheets:", error);
    }
  };

  const handleBulkSubmit = async (ids: string[]) => {
    const originalTimesheets = [...timesheets];

    try {
      // Optimistically update UI
      setTimesheets((prev) =>
        prev.map((timesheet) => {
          if (ids.includes(timesheet.id)) {
            return {
              ...timesheet,
              submitted: true,
              submittedAt: new Date().toISOString(),
            };
          }
          return timesheet;
        })
      );

      // Perform actual API updates
      await Promise.all(
        ids.map((id) =>
          updateTimesheet.mutateAsync({
            id,
            submitted: true,
          })
        )
      );
    } catch (error) {
      // Rollback on error
      setTimesheets(originalTimesheets);
      // Show error notification
      console.error("Failed to submit timesheets:", error);
    }
  };

  const handleRevokeApproval = async (id: string) => {
    const originalTimesheets = [...timesheets];

    try {
      // Optimistically update UI
      setTimesheets((prev) =>
        prev.map((timesheet) => {
          if (timesheet.id === id) {
            return {
              ...timesheet,
              approved: false,
              approvedAt: null,
              approvedBy: null,
              approvedByEmail: null,
            };
          }
          return timesheet;
        })
      );

      // Perform actual API update
      await revokeApproval.mutateAsync(id);
    } catch (error) {
      // Rollback on error
      setTimesheets(originalTimesheets);
      console.error("Failed to revoke approval:", error);
    }
  };

  const handleBulkRevoke = async (ids: string[]) => {
    const originalTimesheets = [...timesheets];

    try {
      // Optimistically update UI
      setTimesheets((prev) =>
        prev.map((timesheet) => {
          if (ids.includes(timesheet.id)) {
            return {
              ...timesheet,
              approved: false,
              approvedAt: null,
              approvedBy: null,
              approvedByEmail: null,
            };
          }
          return timesheet;
        })
      );

      // Perform actual API updates
      await bulkRevokeApproval.mutateAsync(ids);
    } catch (error) {
      // Rollback on error
      setTimesheets(originalTimesheets);
      console.error("Failed to bulk revoke approvals:", error);
    }
  };

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ height: "100%", width: "100%", p: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Approvals
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="approval tabs"
          >
            <Tab label="PENDING" />
            <Tab label="UNSUBMITTED" />
            <Tab label="ARCHIVE" />
          </Tabs>
        </Box>

        {tabValue === 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Box>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                size="small"
                sx={{ mr: 2 }}
              >
                <MenuItem value={10}>Sort by: Date</MenuItem>
                {/* Add other sort options here */}
              </Select>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                size="small"
              >
                <MenuItem value={10}>Team</MenuItem>
                {/* Add team options here */}
              </Select>
            </Box>
            <Box>
              <Button
                variant="outlined"
                onClick={handleRemindToApprove}
                sx={{ mr: 2 }}
              >
                REMIND TO APPROVE
              </Button>
              <Button variant="contained" onClick={handleApproveAll}>
                APPROVE ALL
              </Button>
            </Box>
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button variant="outlined" onClick={handleRemindToSubmit}>
              REMIND TO SUBMIT
            </Button>
          </Box>
        )}

        {tabValue === 2 && (
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search archives..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
        )}

        <TabPanel value={tabValue} index={0}>
          {paginatedEntries.map(([userId, entries]) => {
            const userName = entries[0].userName || "Unknown User";
            return (
              <TimesheetApprovalsGrid
                key={userId}
                userEntries={entries}
                userName={userName}
                handleApproveTimesheet={handleApproveTimesheet}
                handleRejectTimesheet={handleRejectTimesheet}
                showActions={true}
                isUnsubmittedTab={false}
                isArchiveTab={false}
                handleBulkApprove={handleBulkApprove}
                handleBulkReject={handleBulkReject}
                handleBulkSubmit={handleBulkSubmit}
              />
            );
          })}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {paginatedEntries.map(([userId, entries]) => {
            const userName = entries[0].userName || "Unknown User";
            return (
              <TimesheetApprovalsGrid
                key={userId}
                userEntries={entries}
                userName={userName}
                handleSubmitForUser={handleSubmitForUser}
                showActions={true}
                isUnsubmittedTab={true}
                isArchiveTab={false}
                handleBulkApprove={handleBulkApprove}
                handleBulkReject={handleBulkReject}
                handleBulkSubmit={handleBulkSubmit}
              />
            );
          })}
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {paginatedEntries.map(([userId, entries]) => {
            const userName = entries[0].userName || "Unknown User";
            return (
              <TimesheetApprovalsGrid
                key={userId}
                userEntries={entries}
                userName={userName}
                showActions={true}
                isUnsubmittedTab={false}
                isArchiveTab={true}
                handleRevokeApproval={handleRevokeApproval}
                handleBulkRevoke={handleBulkRevoke}
              />
            );
          })}
        </TabPanel>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {`${startItem}-${endItem} of ${totalItems} items`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              Items per page:
            </Typography>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>
    </>
  );
};
