import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Avatar,
  AvatarGroup,
  Popover,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useWebSocket } from "hooks/websocket/useWebSocket";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { useNavigate } from "react-router-dom";
import { getBoard } from "core/freelo/board/getBoard";
import { useQueryClient } from "@tanstack/react-query";
import { Board } from "@freetech/models/freelo";
import { PublicUserInfoType } from "@freetech/models/user";
import { OnlineUser } from "@freetech/models";

export const OnlineUsersDisplay = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data: users } = usePublicUserList();
  const { onlineUsers } = useWebSocket();
  const queryClient = useQueryClient();
  const [boardNames, setBoardNames] = useState<Record<string, string>>({});
  const [loadingBoardNames, setLoadingBoardNames] = useState<
    Record<string, boolean>
  >({});

  // useEffect(() => {
  //   const fetchBoardNames = async () => {
  //     const onlineUsersList = Object.entries(onlineUsers).filter(
  //       ([_, data]) => data.online && data.currentPage?.includes("/freelo/")
  //     );

  //     for (const [_, userData] of onlineUsersList) {
  //       if (userData.currentPage && !boardNames[userData.currentPage]) {
  //         setLoadingBoardNames((prev) => ({
  //           ...prev,
  //           [userData.currentPage!]: true,
  //         }));
  //         try {
  //           const boardId = userData.currentPage.split("/freelo/")[1];
  //           const board = await queryClient.fetchQuery({
  //             queryKey: ["board", boardId],
  //             queryFn: async () => {
  //               const response = await fetch(`/api/boards/${boardId}`);
  //               return response.json() as Promise<Board>;
  //             },
  //           });
  //           if (board?.name) {
  //             setBoardNames((prev) => ({
  //               ...prev,
  //               [userData.currentPage!]: board.name,
  //             }));
  //           }
  //         } catch (error) {
  //           console.error("Error fetching board name:", error);
  //         } finally {
  //           setLoadingBoardNames((prev) => ({
  //             ...prev,
  //             [userData.currentPage!]: false,
  //           }));
  //         }
  //       }
  //     }
  //   };

  //   fetchBoardNames();
  // }, [onlineUsers, queryClient, boardNames]);

  const open = Boolean(anchorEl);
  const activeUsers = Object.entries(onlineUsers).filter(
    ([_, data]) => data.online
  ) as [string, OnlineUser][];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserName = (userId: string) => {
    const user = users?.find(
      (user) => user.id === userId
    ) as PublicUserInfoType;
    return user?.displayName || user?.email || "Unknown User";
  };

  const handleLocationClick = (path: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent parent click handlers from firing
    navigate(path);
    handleClose(); // Close the popover after navigation
  };

  const formatPagePath = (path: string) => {
    const parts = path.replace(/^\//, "").split("/");

    if (parts[0] === "freelo" && parts[1]) {
      const boardId = parts[1];
      const boardName = boardNames[boardId];

      if (loadingBoardNames[boardId]) {
        return "Freelo → Loading...";
      }

      if (!boardName) {
        getBoard(boardId);
        return "Freelo → Loading...";
      }

      return `Freelo → ${boardName}`;
    }

    return parts
      .map((part) =>
        part
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      )
      .join(" → ");
  };

  const formatTimestamp = (timestamp?: number) => {
    if (!timestamp) return "Unknown";
    const now = Date.now();
    const diff = now - timestamp;

    if (diff < 60000) return "Just now";
    if (diff < 3600000) return `${Math.floor(diff / 60000)}m ago`;
    if (diff < 86400000) return `${Math.floor(diff / 3600000)}h ago`;
    return new Date(timestamp).toLocaleDateString();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <AvatarGroup
        max={7}
        sx={{
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            fontSize: "0.875rem",
            border: "2px solid #fff",
            bgcolor: "success.main",
          },
        }}
      >
        {activeUsers.slice(0, 3).map(([userId, data]) => {
          const user = users?.find((u) => u.id === userId);
          return (
            <Tooltip key={userId} title={getUserName(userId)}>
              <Avatar
                src={user?.profilePicture || undefined}
                alt={user?.displayName || ""}
                sx={{ bgcolor: "success.main" }}
              >
                {user?.displayName?.[0]}
              </Avatar>
            </Tooltip>
          );
        })}
      </AvatarGroup>

      {activeUsers.length > 1 && (
        <>
          <Tooltip title="View all online users">
            <IconButton
              size="small"
              onClick={handleClick}
              sx={{ ml: 0.5, color: "white" }}
            >
              <MoreHoriz />
            </IconButton>
          </Tooltip>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 8,
              sx: {
                width: 360,
                maxHeight: 480,
                overflow: "hidden",
                borderRadius: 3,
                mt: 1.5,
                background: "linear-gradient(to bottom, #ffffff, #f8f9fa)",
                border: "1px solid",
                borderColor: "divider",
              },
            }}
          >
            <Box
              sx={{
                p: 3,
                borderBottom: "1px solid",
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 700, mb: 0.5 }}>
                  Online Users
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {activeUsers.length} active now
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 36,
                  height: 36,
                  borderRadius: "12px",
                  bgcolor: "success.main",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0 4px 12px rgba(76, 175, 80, 0.2)",
                  border: "2px solid",
                  borderColor: "success.light",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                  }}
                >
                  {activeUsers.length}
                </Typography>
              </Box>
            </Box>

            <List
              sx={{
                py: 2,
                px: 1.5,
                maxHeight: 380,
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#c1c1c1",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "#a8a8a8",
                  },
                },
              }}
            >
              {activeUsers.map(([userId, data]) => {
                const user = users?.find((u) => u.id === userId);

                return (
                  <ListItem
                    key={userId}
                    sx={{
                      py: 1.5,
                      px: 2,
                      mb: 1,
                      borderRadius: 2,
                      transition: "all 0.2s ease",
                      cursor: "pointer",
                      "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.02)",
                        transform: "translateX(4px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ position: "relative" }}>
                        <Avatar
                          src={user?.profilePicture || undefined}
                          sx={{
                            width: 40,
                            height: 40,
                            bgcolor: "success.main",
                          }}
                        >
                          {user?.displayName?.[0]}
                        </Avatar>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            bgcolor: "success.main",
                            border: "2px solid #fff",
                          }}
                        />
                      </Box>

                      <Box sx={{ ml: 1, flex: 1, minWidth: 0 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user?.displayName || user?.id}
                        </Typography>

                        {data.currentPage && (
                          <Typography
                            onClick={(e) =>
                              data.currentPage &&
                              handleLocationClick(data.currentPage, e)
                            }
                            variant="caption"
                            sx={{
                              display: "block",
                              color: "text.secondary",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                              "&:hover": {
                                color: "primary.main",
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {formatPagePath(data.currentPage)}
                          </Typography>
                        )}

                        <Typography
                          variant="caption"
                          sx={{
                            color: "success.main",
                            fontWeight: 500,
                          }}
                        >
                          {formatTimestamp(data.lastPing)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Popover>
        </>
      )}
    </Box>
  );
};
