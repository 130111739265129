import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  writeBatch,
  serverTimestamp,
  query,
  where,
  setDoc,
  orderBy,
  limit,
  Timestamp,
  DocumentData,
  onSnapshot,
} from "firebase/firestore";
import { db } from "config/firebase";
import {
  EmailTemplate,
  CreateEmailTemplateInput,
  UpdateEmailTemplateInput,
  PreviewEmailRequest,
  SendEmailRequest,
  ContactList,
  Contact,
  MailDelivery,
} from "types/admin/emailTemplate";
import { compile } from "handlebars";
import { useState, useEffect } from "react";
import { PublicUserInfoType } from "@freetech/models/user";
import { auth } from "config/firebase";
import { firebaseFunctions } from "core/functions";
import { firebaseFunctionsBaseUrl } from "config/functionsBaseUrl";

const MAIL_TEMPLATES_COLLECTION = "mail_templates";
const CONTACT_LISTS_COLLECTION = "contact_lists";
const MAIL_COLLECTION = "mail";

export const useAdminEmailManagement = () => {
  const queryClient = useQueryClient();

  // Fetch all templates
  const { data: templates = [], isLoading: isTemplatesLoading } = useQuery({
    queryKey: ["emailTemplates"],
    queryFn: async (): Promise<EmailTemplate[]> => {
      const querySnapshot = await getDocs(
        collection(db, MAIL_TEMPLATES_COLLECTION)
      );
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as EmailTemplate),
        lastModified: doc.data().createdAt
          ? new Date(doc.data().createdAt.seconds * 1000).toLocaleString()
          : "N/A",
      }));
    },
  });

  // Fetch all contact lists
  const { data: contactLists = [], isLoading: isContactListsLoading } =
    useQuery({
      queryKey: ["contactLists"],
      queryFn: async (): Promise<ContactList[]> => {
        const querySnapshot = await getDocs(
          collection(db, CONTACT_LISTS_COLLECTION)
        );
        return querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as ContactList),
        }));
      },
    });

  // Get mail deliveries with real-time updates using React Query
  const { data: mailDeliveries = [], isLoading: isMailDeliveriesLoading } =
    useQuery({
      queryKey: ["mailDeliveries"],
      queryFn: async (): Promise<MailDelivery[]> => {
        // Get initial data from Firestore to avoid empty table on page load
        const mailQuery = query(
          collection(db, MAIL_COLLECTION),
          orderBy("startTime", "desc"),
          limit(100)
        );
        
        const snapshot = await getDocs(mailQuery);
        
        // Format timestamps for display
        const formatTimestamp = (timestamp: DocumentData | null): string => {
          if (!timestamp) return "N/A";
          return new Date(timestamp.seconds * 1000).toLocaleString();
        };
        
        return snapshot.docs.map((doc) => {
          const data = doc.data();
          
          // Map message data to a consistent interface
          const message = {
            to: data.to || (data.toUids && data.toUids.length > 0 ? data.toUids[0] : "N/A"),
            subject: data.template?.subject || data.subject,
            html: data.template?.html || data.html || "",
          };
          
          return {
            id: doc.id,
            createdAt: data.createdAt,
            to: data.to,
            toUids: data.toUids,
            status: data.status,
            template: data.template || {}, // Use template data from document
            delivery: data.delivery,
            // Add read status tracking
            readStatus: data.readStatus || 'sent',
            readAt: data.readAt ? formatTimestamp(data.readAt) : null,
            // Map to previous structure for compatibility
            attempts: data.delivery?.attempts || 0,
            startTime: formatTimestamp(data.delivery?.startTime),
            endTime: formatTimestamp(data.delivery?.endTime),
            state: data.delivery?.state || "PENDING",
            error: data.delivery?.error || null,
            info: {
              accepted: data.delivery?.info?.accepted || [],
              rejected: data.delivery?.info?.rejected || [],
              pending: data.delivery?.info?.pending || [],
              messageId: data.delivery?.info?.messageId || "",
              response: data.delivery?.info?.response || "",
            },
            message,
            leaseExpireTime: formatTimestamp(data.delivery?.leaseExpireTime),
          } as MailDelivery;
        });
      },
      staleTime: 5 * 60 * 1000, // Data will be fresh for 5 minutes
      gcTime: 30 * 60 * 1000, // Cache will be preserved for 30 minutes (use gcTime instead of cacheTime)
      refetchOnWindowFocus: true, // Refetch when window regains focus
      refetchOnMount: true, // Refetch when component mounts
    });

  // Set up real-time listener for mail deliveries using React Query
  useEffect(() => {
    const mailQuery = query(
      collection(db, MAIL_COLLECTION),
      orderBy("delivery.startTime", "desc"),
      limit(100)
    );

    // Format timestamps for display
    const formatTimestamp = (timestamp: DocumentData | null): string => {
      if (!timestamp) return "N/A";
      return new Date(timestamp.seconds * 1000).toLocaleString();
    };

    // Set up the snapshot listener
    const unsubscribe = onSnapshot(
      mailQuery,
      (snapshot) => {
        console.log("Received snapshot with", snapshot.docChanges().length, "changes");
        
        // Get existing data from cache
        const existingData = queryClient.getQueryData<MailDelivery[]>(["mailDeliveries"]) || [];
        
        // Create a map for easier lookup and updates
        const deliveriesMap = new Map<string, MailDelivery>();
        
        // First add all existing items to the map
        existingData.forEach(item => {
          deliveriesMap.set(item.id, item);
        });
        
        // Process each change in the snapshot
        snapshot.docChanges().forEach((change) => {
          const doc = change.doc;
          const data = doc.data();
          
          if (change.type === "removed") {
            // Remove deleted documents
            deliveriesMap.delete(doc.id);
            return;
          }
          
          // For added or modified documents, process the data
          
          // Find the complete template data from the templates cache if we have a template name/id
          let templateData = data.template || {};
          const templateId = templateData.name || ""; // The 'name' field in the template object is the template ID

          // Try to get the full template content from our cache if it's not in the delivery data
          if (templateId && (!templateData.html || !templateData.subject)) {
            const cachedTemplates =
              queryClient.getQueryData<EmailTemplate[]>(["emailTemplates"]) ||
              [];
            const fullTemplate = cachedTemplates.find(
              (t) => t.id === templateId
            );

            if (fullTemplate) {
              // Merge the template data we found with what came in the delivery
              templateData = {
                ...templateData,
                name: templateId,
                html: fullTemplate.html || "",
                subject: fullTemplate.subject,
              };
            }
          }

          // Map message data to a consistent interface
          const message = {
            to:
              data.to ||
              (data.toUids && data.toUids.length > 0 ? data.toUids[0] : "N/A"),
            subject: templateData.subject || data.subject,
            html: templateData.html || data.html || "",
          };

          // Create the delivery object
          const delivery = {
            id: doc.id,
            createdAt: data.createdAt,
            to: data.to,
            toUids: data.toUids,
            status: data.status,
            template: templateData, // Use our enriched template data
            delivery: data.delivery,
            // Add read status tracking
            readStatus: data.readStatus || 'sent',
            readAt: data.readAt ? formatTimestamp(data.readAt) : null,
            // Map to previous structure for compatibility
            attempts: data.delivery?.attempts || 0,
            startTime: formatTimestamp(data.delivery?.startTime),
            endTime: formatTimestamp(data.delivery?.endTime),
            state: data.delivery?.state || "PENDING",
            error: data.delivery?.error || null,
            info: {
              accepted: data.delivery?.info?.accepted || [],
              rejected: data.delivery?.info?.rejected || [],
              pending: data.delivery?.info?.pending || [],
              messageId: data.delivery?.info?.messageId || "",
              response: data.delivery?.info?.response || "",
            },
            message,
            leaseExpireTime: formatTimestamp(data.delivery?.leaseExpireTime),
          } as MailDelivery;
          
          // Add or update in the map
          deliveriesMap.set(doc.id, delivery);
        });

        // Convert map values back to array for cache update
        const updatedDeliveries = Array.from(deliveriesMap.values());
        
        // Sort by startTime descending to maintain order
        updatedDeliveries.sort((a, b) => {
          if (!a.delivery || !b.delivery) return 0;
          
          // Try to use startTime first
          const aTime = a.delivery.startTime ? new Date(a.delivery.startTime).getTime() : 0;
          const bTime = b.delivery.startTime ? new Date(b.delivery.startTime).getTime() : 0;
          
          if (aTime && bTime) return bTime - aTime;
          
          // Fallback to createdAt if available
          const aCreated = a.createdAt?.seconds ? a.createdAt.seconds : 0;
          const bCreated = b.createdAt?.seconds ? b.createdAt.seconds : 0;
          
          return bCreated - aCreated;
        });

        console.log("Updating mailDeliveries with", updatedDeliveries.length, "items");
        
        // Update query data in the cache
        queryClient.setQueryData(["mailDeliveries"], updatedDeliveries);
      },
      (error) => {
        console.error("Error getting mail delivery updates:", error);
        // Don't clear the cache on error, just log the error
      }
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [queryClient]);

  // Create new template
  const createTemplateMutation = useMutation({
    mutationFn: async (input: CreateEmailTemplateInput & { id?: string }) => {
      const { id, name, ...templateData } = input;

      // Ensure all fields required by Firebase Firestore Send Email extension are present
      // Firebase uses the document ID as the template name, not a name field in the document

      // Add tracking pixel to template data so it can be rendered with Handlebars
      // Use triple braces to prevent HTML escaping
      templateData.html += "<div style='display:none;'>{{{ trackingPixel }}}</div>";

      const templateWithTimestamps = {
        ...templateData,
        // Make sure all required fields are present
        subject: templateData.subject || "",
        html: templateData.html || "",
        text: templateData.text || "", // Plain text fallback
        attachments: templateData.attachments || [], // Ensure attachments exists
        lastModified: new Date().toISOString(),
        createdAt: serverTimestamp(),
      };

      let docId: string;

      // If a custom ID is provided, use setDoc instead of addDoc
      if (id) {
        const docRef = doc(db, MAIL_TEMPLATES_COLLECTION, id);
        await setDoc(docRef, templateWithTimestamps);
        docId = id;
      } else {
        // Otherwise, use addDoc to auto-generate an ID
        const docRef = await addDoc(
          collection(db, MAIL_TEMPLATES_COLLECTION),
          templateWithTimestamps
        );
        docId = docRef.id;
      }

      return docId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["emailTemplates"] });
    },
  });

  // Update template
  const updateTemplateMutation = useMutation({
    mutationFn: async (input: UpdateEmailTemplateInput) => {
      const { id, ...updateData } = input;
      // Add tracking pixel to template data so it can be rendered with Handlebars
      // Use triple braces to prevent HTML escaping
      updateData.html += "<div style='display:none;'>{{{ trackingPixel }}}</div>";
      await updateDoc(doc(db, MAIL_TEMPLATES_COLLECTION, id), {
        ...updateData,
        lastModified: new Date().toISOString(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["emailTemplates"] });
    },
  });

  // Delete template
  const deleteTemplateMutation = useMutation({
    mutationFn: async (templateId: string) => {
      await deleteDoc(doc(db, MAIL_TEMPLATES_COLLECTION, templateId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["emailTemplates"] });
    },
  });

  // Create new contact list
  const createContactListMutation = useMutation({
    mutationFn: async (input: Omit<ContactList, "id">) => {
      const docRef = await addDoc(collection(db, CONTACT_LISTS_COLLECTION), {
        ...input,
        createdAt: serverTimestamp(),
        createdBy: auth.currentUser?.uid || "unknown",
        lastModified: new Date().toISOString(),
      });
      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contactLists"] });
    },
  });

  // Update contact list
  const updateContactListMutation = useMutation({
    mutationFn: async (input: ContactList) => {
      const { id, ...updateData } = input;
      if (!id) throw new Error("Contact list ID is required");
      await updateDoc(doc(db, CONTACT_LISTS_COLLECTION, id), {
        ...updateData,
        updatedBy: auth.currentUser?.uid || "unknown",
        lastModified: new Date().toISOString(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contactLists"] });
    },
  });

  // Delete contact list
  const deleteContactListMutation = useMutation({
    mutationFn: async (listId: string) => {
      await deleteDoc(doc(db, CONTACT_LISTS_COLLECTION, listId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contactLists"] });
    },
  });

  // Send email using a template
  const sendEmailMutation = useMutation({
    mutationFn: async (request: SendEmailRequest) => {
      const {
        templateId,
        contacts = [],
        contactListIds = [],
        variables,
        scheduledFor,
      } = request;

      // Get the template
      const templateDoc = await getDoc(
        doc(db, MAIL_TEMPLATES_COLLECTION, templateId)
      );
      if (!templateDoc.exists()) {
        throw new Error("Template not found");
      }

      const template = templateDoc.data() as EmailTemplate;

      // Check if this is a React Email template - if so, we don't need special handling
      // Firebase will process the Handlebars syntax in the template when it sends the email
      // The variables in the React template should be in Handlebars format ({{varName}})
      const isReactTemplate =
        template.isReactTemplate ||
        (template.html && template.html.includes("@react-email/components"));

      // Get contacts from selected lists if any
      let recipientEmails = [...contacts];
      if (contactListIds && contactListIds.length > 0) {
        for (const listId of contactListIds) {
          const listDoc = await getDoc(
            doc(db, CONTACT_LISTS_COLLECTION, listId)
          );
          if (listDoc.exists()) {
            const list = listDoc.data() as ContactList;
            const listEmails = list.contacts.map((contact) => contact.email);
            recipientEmails = [...recipientEmails, ...listEmails];
          }
        }
      }

      // Remove duplicates
      recipientEmails = Array.from(new Set(recipientEmails));

      if (recipientEmails.length === 0) {
        throw new Error("No recipients selected");
      }

      // Create a batch to add all emails to the queue
      const batch = writeBatch(db);

      for (const email of recipientEmails) {
        // Create a clean variables object with no undefined values
        const templateData: Record<string, any> = {};

        // Only add variables that have values
        if (variables) {
          Object.entries(variables).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== "") {
              templateData[key] = value;
            }
          });
        }

        // Create a document reference with a new ID for this email
        const newMailRef = doc(collection(db, MAIL_COLLECTION));
        const emailId = newMailRef.id;

        // Generate a unique tracking ID
        const trackingId = Math.random().toString(36).substring(2, 15) + Date.now().toString(36);
        
        // Create a more robust tracking implementation with fallbacks
        const trackingUrl = `${firebaseFunctionsBaseUrl}/trackEmailOpen?id=${trackingId}&emailId=${encodeURIComponent(emailId)}&email=${encodeURIComponent(email)}`;
        
        // Create a far more aggressive tracking pixel with multiple methods
        const enhancedTrackingPixel = `
          <!-- Tracking container with multiple methods -->
          <div style="display:block">
            <!-- Primary tracking pixel with cache busting -->
            <img src="${trackingUrl}&nocache=${Date.now()}" alt="" width="1" height="1" border="0" style="height:1px !important;width:1px !important;border-width:0 !important;margin:0 !important;padding:0 !important;" />
            
            <!-- Background image method -->
            <div style="background-image:url('${trackingUrl}&t=${Math.random()}');background-repeat:no-repeat;background-size:1px 1px;height:1px;width:1px;"></div>
            
            <!-- Force loading using eager attribute -->
            <img src="${trackingUrl}&t=${Date.now()}" alt="" width="1" height="1" border="0" loading="eager" fetchpriority="high" />
            
            <!-- Essential image disguised as content -->
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="margin:0">
              <tr>
                <td>
                  <img src="${trackingUrl}&type=logo&t=${Date.now()}" alt="·" width="1" height="1" style="width:1px;height:1px;opacity:0.01;" />
                </td>
              </tr>
            </table>
            
            <!-- Last resort preload hint -->
            <link rel="preload" href="${trackingUrl}&preload=1&t=${Date.now()}" as="image" />
          </div>
        `;
        
        // If we have HTML in the template, add the tracking pixel at the end
        let htmlWithTracking = template.html || "";
        if (htmlWithTracking) {
          // Add enhanced tracking pixel before the closing body tag or at the end if no body tag
          const bodyCloseIndex = htmlWithTracking
            .toLowerCase()
            .lastIndexOf("</body>");
          if (bodyCloseIndex !== -1) {
            htmlWithTracking =
              htmlWithTracking.substring(0, bodyCloseIndex) +
              enhancedTrackingPixel +
              htmlWithTracking.substring(bodyCloseIndex);
          } else {
            htmlWithTracking += enhancedTrackingPixel;
          }
        }
        
        // Add tracking pixel to template data so it can be rendered with Handlebars
        templateData.trackingPixel = enhancedTrackingPixel;

        // Following the format from Firebase docs for Firestore-send-email:
        // https://firebase.google.com/docs/extensions/official/firestore-send-email/templates
        const mailDoc: Record<string, any> = {
          to: email,
          // The template field should have name and data properties
          // The name property should be the document ID of the template in the templates collection
          template: {
            name: templateId, // This should be the document ID, not a name field
            data: templateData,
          },
          createdAt: serverTimestamp(),
          status: "pending",
          // Add tracking information
          tracking: {
            pixelAdded: true,
            trackingId: trackingId,
            readStatus: "sent",
            recipientEmail: email,
          },
        };

        // Always add the HTML with tracking pixel, regardless of template type
        if (htmlWithTracking) {
          mailDoc.html = htmlWithTracking;
        }

        // Only add scheduledFor if it exists
        if (scheduledFor) {
          mailDoc.scheduledFor = new Date(scheduledFor);
        }

        // Add to batch
        batch.set(newMailRef, mailDoc);
      }

      // Commit the batch
      await batch.commit();

      return recipientEmails.length;
    },
    onSuccess: () => {
      // No need to invalidate, the snapshot listener will update the cache
    },
  });

  // Preview a template with variables
  const previewTemplate = async (
    input: PreviewEmailRequest
  ): Promise<string> => {
    try {
      const { subject, html, text, variables = {} } = input;

      // Skip rendering if no HTML content
      if (!html) {
        return `<div>No HTML content to preview</div>`;
      }

      // Check if this is a React Email template
      const isReactTemplate =
        html.includes("@react-email/components") && html.includes("export");

      // Use Handlebars to render the template with variables
      let renderedHtml = "";

      if (isReactTemplate) {
        // For React Email templates, we extract just the HTML content
        // We can't execute React code directly, so we'll show the template with variable placeholders
        try {
          // Show a message indicating this is a React template
          renderedHtml = `
            <div style="padding: 16px; margin-bottom: 16px; background-color: #e3f2fd; border-radius: 4px; border-left: 4px solid #2196f3;">
              <h3 style="margin-top: 0; color: #0d47a1;">React Email Template Preview</h3>
              <p>This is a React Email template. The actual email will be rendered with the React components when sent.</p>
              <p>It will use the following variables:</p>
              <ul>
                ${Object.keys(variables)
                  .map(
                    (key) =>
                      `<li><strong>${key}:</strong> ${variables[key]}</li>`
                  )
                  .join("")}
              </ul>
            </div>
            
            <div style="padding: 16px; border: 1px dashed #ccc; border-radius: 4px;">
              <h4 style="margin-top: 0;">Template Structure:</h4>
              <pre style="background: #f5f5f5; padding: 8px; border-radius: 4px; max-height: 200px; overflow: auto;">${
                html
                  .split("export const EmailTemplate")[1]
                  ?.split("export default")[0]
                  ?.replace(/</g, "&lt;")
                  ?.replace(/>/g, "&gt;")
                  ?.trim() || "Template structure could not be extracted"
              }</pre>
            </div>
          `;
        } catch (error) {
          console.error("Error processing React Email template:", error);
          renderedHtml = `<div style="color: red;">Error processing React Email template: ${error instanceof Error ? error.message : String(error)}</div>`;
        }
      } else {
        // For regular Handlebars templates, use Handlebars to render
        try {
          const htmlTemplate = compile(html);
          renderedHtml = htmlTemplate(variables || {});
        } catch (error) {
          console.error("Error compiling template:", error);
          renderedHtml = `<div style="color: red;">Error compiling template: ${error instanceof Error ? error.message : String(error)}</div>`;
        }
      }

      // Add some basic styling for preview
      return `
        <style>
          body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
            margin: 0;
            padding: 20px;
          }
          img { max-width: 100%; height: auto; }
          a { color: #0066cc; text-decoration: none; }
          a:hover { text-decoration: underline; }
          table { width: 100%; border-collapse: collapse; }
          td { padding: 8px; }
        </style>
        <div style="max-width: 600px; margin: 0 auto;">
          ${renderedHtml}
        </div>
      `;
    } catch (error) {
      console.error("Error previewing template:", error);
      return `<div style="color: red;">Error previewing template: ${error instanceof Error ? error.message : String(error)}</div>`;
    }
  };

  return {
    templates,
    contactLists,
    mailDeliveries,
    isLoading:
      isTemplatesLoading || isContactListsLoading || isMailDeliveriesLoading,
    isTemplatesLoading,
    isContactListsLoading,
    isMailDeliveriesLoading,
    createTemplate: createTemplateMutation.mutateAsync,
    updateTemplate: updateTemplateMutation.mutateAsync,
    deleteTemplate: deleteTemplateMutation.mutateAsync,
    createContactList: createContactListMutation.mutateAsync,
    updateContactList: updateContactListMutation.mutateAsync,
    deleteContactList: deleteContactListMutation.mutateAsync,
    sendEmail: sendEmailMutation.mutateAsync,
    previewTemplate,
    isCreating: createTemplateMutation.isPending,
    isUpdating: updateTemplateMutation.isPending,
    isDeleting: deleteTemplateMutation.isPending,
  };
};
