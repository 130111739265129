import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { ClientStakeholder } from "../projects";

export const stakeholderConverter: FirestoreDataConverter<ClientStakeholder> = {
  toFirestore(stakeholder: WithFieldValue<ClientStakeholder>): DocumentData {
    const { id, ...data } = stakeholder;
    return data;
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): ClientStakeholder {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      role: data?.role,
      permissions: data?.permissions || [],
      documents: data?.documents || [],
      displayName: data?.displayName,
      needsOnboarding: data?.needsOnboarding,
      associationDate: data?.associationDate || new Date().toISOString(),
      disabled: data?.disabled,
      disabledAt: data?.disabledAt,
      increaseExternalAccountProfile: {
        externalAccountId:
          data?.increaseExternalAccountProfile?.externalAccountId,
        externalAccountNickname:
          data?.increaseExternalAccountProfile?.externalAccountNickname,
        accountHolderFullName:
          data?.increaseExternalAccountProfile?.accountHolderFullName,
        accountHolderType:
          data?.increaseExternalAccountProfile?.accountHolderType,
        financialInstitution:
          data?.increaseExternalAccountProfile?.financialInstitution,
        achTransfersSupported:
          data?.increaseExternalAccountProfile?.achTransfersSupported,
        wireTransfersSupported:
          data?.increaseExternalAccountProfile?.wireTransfersSupported,
        realTimePaymentsSupported: data?.increaseRealTimePaymentsSupported,
        testPaymentFirestoreId:
          data?.increaseExternalAccountProfile?.testPaymentFirestoreId,
        testPaymentIncreaseId:
          data?.increaseExternalAccountProfile?.testPaymentIncreaseId,
        testPaymentExternalAccountId:
          data?.increaseExternalAccountProfile?.testPaymentExternalAccountId,
        testPaymentStatus:
          data?.increaseExternalAccountProfile?.testPaymentStatus,
        testPaymentCreatedAt:
          data?.increaseExternalAccountProfile?.testPaymentCreatedAt,
        testPaymentCompletedAt:
          data?.increaseExternalAccountProfile?.testPaymentCompletedAt,
        archivedExternalAccounts:
          data?.increaseExternalAccountProfile?.archivedExternalAccounts,
      },
      lastLoginAt: data?.lastLoginAt,
      lastPasswordResetRequestAt: data?.lastPasswordResetRequestAt,
      needsPasswordReset: data?.needsPasswordReset,
      passwordResetAt: data?.passwordResetAt,
      onboardingCompletedAt: data?.onboardingCompletedAt,
      createdAt: data?.createdAt,
      updatedAt: data?.updatedAt,
      needsInitialPasswordSet: data?.needsInitialPasswordSet,
      initialPasswordSetAt: data?.initialPasswordSetAt,
      lastVerificationEmailRequestAt: data?.lastVerificationEmailRequestAt,
      lastSignInLinkRequestAt: data?.lastSignInLinkRequestAt,
      paymentProfileCompleted: data?.paymentProfileCompleted,
      paymentProfileCompletedAt: data?.paymentProfileCompletedAt,
    };
  },
};
