import React from "react";
import {
  StakeholderPayments,
  StakeholderProjectHub,
  StakeholderHome,
  StakeholderContracts,
} from "views/Stakeholder";
import {
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
  Home as HomeIcon,
  AttachMoney as AttachMoneyIcon,
  ViewKanban as ViewKanbanIcon,
  Description as DescriptionIcon,
  BorderColor as BorderColorIcon,
} from "@mui/icons-material";
import BugReportIcon from "@mui/icons-material/BugReport";
// import { BugReport } from "views/Shared/BugReport";
import { Navigation } from "types/navigation";
import { StakeholderProjectOverview } from "views/Stakeholder/StakeholderProjectHub/StakeholderProjectOverview/StakeholderProjectOverview";
import { StakeholderBugReport } from "views/Stakeholder/StakeholderProjectHub/StakeholderBugReport";
import { StakeholderFeatureRequest } from "views/Stakeholder/StakeholderProjectHub/StakeholderFeatureRequest/StakeholderFeatureRequest";
import { FreeloHome } from "views/FreeloHome";
import { FreeloBoard } from "views/FreeloBoard";
import { StakeholderInvoiceCount } from "components/Icons/StakeholderInvoiceCount";

export const stakeholderPages: Navigation = [

  {
    segment: "stakeholder",
    title: "Home",
    icon: <HomeIcon />,
    component: <StakeholderHome />,
  },
  {
    segment: "stakeholder/projects",
    title: "Project Hub",
    icon: <AssignmentIcon />,
    component: <StakeholderProjectHub />,
    slugBabyPattern: ":projectId",
    slugBabyComponent: <StakeholderProjectOverview />,
    slugGrandBabyPattern: "bug-reports/:bugReportId",
    slugGrandBabyComponent: <StakeholderBugReport />,
    slugGrandBabyBrotherPattern: "feature-requests/:featureRequestId",
    slugGrandBabyBrotherComponent: <StakeholderFeatureRequest />,
  },
  {
    kind: "page",
    title: "Freelo",
    segment: "freelo",
    icon: <ViewKanbanIcon />,
    component: <FreeloHome />,
    slugBabyPattern: ":boardId",
    slugBabyComponent: <FreeloBoard />,
  },
  {
    segment: "stakeholder/contracts",
    title: "Contract Center",
    // icon: <DescriptionIcon />,
    icon: <BorderColorIcon />,
    component: <StakeholderContracts />,
  },
  {
    segment: "stakeholder/bill-pay",
    title: "Bill Pay",
    icon: <StakeholderInvoiceCount />,
    component: <StakeholderPayments />,
  },
];
