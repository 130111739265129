import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Freelancer } from "@freetech/models/user";
import { freelancerConverter } from "@freetech/models/converters";
import { getEngineerAssignments } from "core/projectAssignments/getEngineerAssignments";
import { getCurrentUserId } from "core/auth/getCurrentUserId";

const db = getFirestore();

export const fetchMyFreelancerProfile = async (): Promise<Freelancer> => {
  const userId = getCurrentUserId();
  const docRef = doc(db, "users", userId).withConverter(freelancerConverter);
  const docSnap = await getDoc(docRef);

  const projectAssignments = await getEngineerAssignments(userId);
  if (docSnap.exists()) {
    const freelancer = docSnap.data();

    return {
      ...freelancer,
      projectAssignments,
    };
  }
  throw new Error("Freelancer profile not found");
};
