import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Collapse,
  Grid,
  Chip,
  Stack,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DescriptionIcon from "@mui/icons-material/Description";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useLocalStorageBoolState } from "hooks/useLocalStorage";
import { Client } from "@freetech/models/projects";
import { ClientInvoiceData } from "@freetech/models/bank";
import { useAdminInvoicing } from "hooks/admin/useAdminInvoicing";
import ViewFileDialog from "components/ViewFileDialog";
import GenerateInvoiceDialog from "./components/GenerateInvoiceDialog";
import { ExtendedClientInvoiceData } from "hooks/admin/useAdminInvoicing";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { Stakeholder } from "@freetech/models/user";

interface AssociatedInvoicesProps {
  client: Client;
  clientId: string;
}

const AssociatedInvoices: React.FC<AssociatedInvoicesProps> = ({
  client,
  clientId,
}) => {
  const [isVisible, setIsVisible] = useLocalStorageBoolState(
    "isAssociatedInvoicesVisible",
    false
  );
  const [isGenerateDialogOpen, setIsGenerateDialogOpen] = useState(false);
  const [invoiceToEdit, setInvoiceToEdit] =
    useState<ExtendedClientInvoiceData | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] =
    useState<ClientInvoiceData | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedInvoiceForView, setSelectedInvoiceForView] =
    useState<ExtendedClientInvoiceData | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [versionsDialogOpen, setVersionsDialogOpen] = useState(false);
  const [selectedInvoiceForVersions, setSelectedInvoiceForVersions] =
    useState<ClientInvoiceData | null>(null);
  const [reminderDialogOpen, setReminderDialogOpen] = useState(false);
  const [selectedInvoiceForReminder, setSelectedInvoiceForReminder] = useState<ClientInvoiceData | null>(null);
  const [selectedStakeholders, setSelectedStakeholders] = useState<string[]>([]);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [invoiceToCopy, setInvoiceToCopy] = useState<ClientInvoiceData | null>(null);
  const [newInvoiceNumber, setNewInvoiceNumber] = useState<string>("");
  
  // Get stakeholders for the client
  const { getStakeholdersForClientId } = useAdminClient(clientId);
  const [clientStakeholders, setClientStakeholders] = useState<Stakeholder[]>([]);

  // Use the admin invoicing hook
  const {
    invoices,
    isLoadingInvoices,
    isErrorInvoices,
    createInvoice,
    isCreatingInvoice,
    deleteInvoice,
    isDeletingInvoice,
    updateInvoice,
    isUpdatingInvoice,
    getInvoiceVersions,
    viewInvoicePDF,
    sendInvoiceReminders,
    isSendingReminders,
    copyInvoice,
    isCopyingInvoice,
  } = useAdminInvoicing();

  // Filter invoices for this client
  const clientInvoices = invoices.filter(
    (invoice) => invoice.clientId === clientId
  );

  // Add useEffect to load stakeholders
  useEffect(() => {
    const loadStakeholders = async () => {
      try {
        const stakeholders = await getStakeholdersForClientId.mutateAsync(clientId);
        setClientStakeholders(stakeholders);
      } catch (error) {
        console.error("Error loading stakeholders:", error);
      }
    };
    loadStakeholders();
  }, [clientId, getStakeholdersForClientId]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleOpenGenerateDialog = () => {
    setInvoiceToEdit(null);
    setIsGenerateDialogOpen(true);
  };

  const handleCloseGenerateDialog = () => {
    setIsGenerateDialogOpen(false);
    setInvoiceToEdit(null);
  };

  const handleEditClick = (invoice: ClientInvoiceData) => {
    // Create a deep copy of the invoice to avoid reference issues
    const invoiceCopy = JSON.parse(
      JSON.stringify(invoice)
    ) as ExtendedClientInvoiceData;

    // Ensure client ID is set
    invoiceCopy.clientId = clientId;

    // Set the flag to indicate this is a client-specific invoice
    invoiceCopy.isClientSpecific = true;

    // Set the invoice to edit
    setInvoiceToEdit(invoiceCopy);

    console.log("Setting invoice data for editing:", invoiceCopy);

    // Open the dialog
    setIsGenerateDialogOpen(true);
  };

  const handleSubmitInvoice = async (
    invoiceData: ExtendedClientInvoiceData
  ) => {
    try {
      if (invoiceToEdit) {
        // We're editing an existing invoice
        console.log("Editing invoice:", invoiceToEdit);
        console.log("With updated data:", invoiceData);

        // Update the invoice directly
        await updateInvoice({
          invoiceId: invoiceToEdit.id,
          clientId: clientId,
          data: invoiceData,
        });

        setSnackbar({
          open: true,
          message: "Invoice updated successfully",
          severity: "success",
        });
      } else {
        // Generate a new invoice
        console.log("Creating new invoice with data:", invoiceData);

        await createInvoice(invoiceData);

        setSnackbar({
          open: true,
          message: "Invoice generated successfully",
          severity: "success",
        });
      }

      setIsGenerateDialogOpen(false);
      setInvoiceToEdit(null);
    } catch (error) {
      console.error("Error with invoice:", error);
      setSnackbar({
        open: true,
        message: invoiceToEdit
          ? "Failed to update invoice"
          : "Failed to generate invoice",
        severity: "error",
      });
    }
  };

  const handleDeleteClick = (invoice: ClientInvoiceData) => {
    setInvoiceToDelete(invoice);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (invoiceToDelete) {
      try {
        await deleteInvoice({
          clientId: invoiceToDelete.clientId,
          invoiceId: invoiceToDelete.id,
        });
        setSnackbar({
          open: true,
          message: "Invoice deleted successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error deleting invoice:", error);
        setSnackbar({
          open: true,
          message: "Failed to delete invoice",
          severity: "error",
        });
      } finally {
        setDeleteConfirmOpen(false);
        setInvoiceToDelete(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setInvoiceToDelete(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const handleViewInvoice = async (
    invoice: ClientInvoiceData,
    versionIndex?: number
  ) => {
    try {
      setSelectedInvoiceForView(invoice as ExtendedClientInvoiceData);

      // If a specific version is requested, construct the path to that version
      let url;
      if (versionIndex !== undefined) {
        // For a specific version, construct the path manually
        // Version number is index + 1
        const versionNumber = versionIndex + 1;
        console.log(
          `Viewing version ${versionNumber} of invoice ${invoice.id}`
        );
        const storagePath = `clients/${invoice.clientId}/invoices/${invoice.id}/version_${versionNumber}.pdf`;
        url = await viewInvoicePDF(invoice.clientId, invoice.id, storagePath);
      } else {
        // For the current version, first try using the pdfUrl from the invoice
        if (invoice.pdfUrl) {
          url = invoice.pdfUrl;
          console.log(`Using existing pdfUrl: ${url}`);
        } else {
          // If no pdfUrl, try the current.pdf path
          const currentPath = `clients/${invoice.clientId}/invoices/${invoice.id}/current.pdf`;
          try {
            url = await viewInvoicePDF(
              invoice.clientId,
              invoice.id,
              currentPath
            );
            console.log(`Using current.pdf path: ${currentPath}`);
          } catch (currentError) {
            // If that fails, try the original.pdf path
            const originalPath = `clients/${invoice.clientId}/invoices/${invoice.id}/original.pdf`;
            try {
              url = await viewInvoicePDF(
                invoice.clientId,
                invoice.id,
                originalPath
              );
              console.log(`Using original.pdf path: ${originalPath}`);
            } catch (originalError) {
              // If that fails too, try the legacy path
              url = await viewInvoicePDF(invoice.clientId, invoice.id);
              console.log(`Using legacy path for invoice ${invoice.id}`);
            }
          }
        }
      }

      setPdfUrl(url);
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error viewing invoice:", error);
      setSnackbar({
        open: true,
        message: "Failed to load invoice PDF",
        severity: "error",
      });
    }
  };

  const handleCloseViewDialog = () => {
    setViewDialogOpen(false);
    setSelectedInvoiceForView(null);
    setPdfUrl(null);
  };

  const handleViewVersions = (invoice: ClientInvoiceData) => {
    setSelectedInvoiceForVersions(invoice);
    setVersionsDialogOpen(true);
  };

  const handleCloseVersionsDialog = () => {
    setVersionsDialogOpen(false);
    setSelectedInvoiceForVersions(null);
  };

  const handleOpenReminderDialog = (invoice: ClientInvoiceData) => {
    setSelectedInvoiceForReminder(invoice);
    setSelectedStakeholders([]);
    setReminderDialogOpen(true);
  };

  const handleCloseReminderDialog = () => {
    setReminderDialogOpen(false);
    setSelectedInvoiceForReminder(null);
    setSelectedStakeholders([]);
  };

  const handleStakeholderToggle = (stakeholderId: string) => {
    setSelectedStakeholders(prev => {
      if (prev.includes(stakeholderId)) {
        return prev.filter(id => id !== stakeholderId);
      } else {
        return [...prev, stakeholderId];
      }
    });
  };

  const handleSendReminders = async () => {
    if (!selectedInvoiceForReminder || selectedStakeholders.length === 0) return;

    try {
      await sendInvoiceReminders({
        clientId,
        invoiceId: selectedInvoiceForReminder.id,
        stakeholderIds: selectedStakeholders,
      });

      setSnackbar({
        open: true,
        message: `Reminders sent to ${selectedStakeholders.length} stakeholder${selectedStakeholders.length > 1 ? 's' : ''}`,
        severity: "success",
      });
      handleCloseReminderDialog();
    } catch (error) {
      console.error("Error sending reminders:", error);
      setSnackbar({
        open: true,
        message: "Failed to send reminders",
        severity: "error",
      });
    }
  };

  const handleOpenCopyDialog = (invoice: ClientInvoiceData) => {
    setInvoiceToCopy(invoice);
    setNewInvoiceNumber(`COPY-${invoice.invoiceNumber || invoice.id}-${Date.now().toString().slice(-6)}`);
    setCopyDialogOpen(true);
  };

  const handleCloseCopyDialog = () => {
    setCopyDialogOpen(false);
    setInvoiceToCopy(null);
    setNewInvoiceNumber("");
  };

  const handleCopyInvoice = async () => {
    if (!invoiceToCopy) return;

    try {
      await copyInvoice({
        sourceInvoiceId: invoiceToCopy.id,
        clientId: clientId,
        newInvoiceNumber: newInvoiceNumber,
      });

      setSnackbar({
        open: true,
        message: "Invoice copied successfully",
        severity: "success",
      });
      handleCloseCopyDialog();
    } catch (error) {
      console.error("Error copying invoice:", error);
      setSnackbar({
        open: true,
        message: "Failed to copy invoice",
        severity: "error",
      });
    }
  };

  if (isLoadingInvoices) {
    return (
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Typography>Loading invoices...</Typography>
      </Box>
    );
  }

  if (isErrorInvoices) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">
          Error loading invoices. Please try again later.
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Card
        elevation={2}
        sx={{
          width: "100%",
          mb: 3,
          background: "linear-gradient(45deg, #2c3e50 30%, #34495e 90%)",
        }}
      >
        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <Box display="flex" alignItems="center">
              <AssignmentIcon sx={{ fontSize: 28, mr: 2 }} />
              <Typography variant="h6" component="h2">
                Invoices
              </Typography>
              <IconButton
                onClick={toggleVisibility}
                sx={{ color: "white", ml: 2 }}
                size="small"
              >
                {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenGenerateDialog}
              size="small"
            >
              Generate Invoice
            </Button>
          </Box>

          {/* Invoices List */}
          <Collapse in={Boolean(isVisible)}>
            <Box sx={{ mt: 2 }}>
              {clientInvoices.length === 0 ? (
                <Typography
                  color="white"
                  variant="body2"
                  sx={{ textAlign: "center", py: 2 }}
                >
                  No invoices found
                </Typography>
              ) : (
                <Grid container spacing={2}>
                  {clientInvoices.map((invoice) => (
                    <Grid item xs={12} md={6} key={invoice.id}>
                      <Card>
                        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography variant="h6">
                                Invoice #{invoice.invoiceNumber || invoice.id}
                              </Typography>
                              <Box>
                                {invoice.oldVersions &&
                                  invoice.oldVersions.length > 0 && (
                                    <Chip
                                      label={`${invoice.oldVersions.length} Revision${invoice.oldVersions.length > 1 ? "s" : ""}`}
                                      color="secondary"
                                      size="small"
                                      sx={{ ml: 1 }}
                                      onClick={() =>
                                        handleViewVersions(invoice)
                                      }
                                    />
                                  )}
                              </Box>
                            </Box>
                            <Stack
                              direction="row"
                              spacing={1}
                              flexWrap="wrap"
                              useFlexGap
                            >
                              <Chip
                                label={formatCurrency(
                                  invoice.totalDollarAmountDue
                                )}
                                color="primary"
                                size="small"
                              />
                              <Chip
                                label={invoice.status || "pending"}
                                color={
                                  (invoice.status === "paid"
                                    ? "success"
                                    : "warning") as any
                                }
                                size="small"
                              />
                              {invoice.dueDate && (
                                <Chip
                                  label={`Due: ${new Date(
                                    invoice.dueDate
                                  ).toLocaleDateString(undefined, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}`}
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                              {invoice.createdAt && (
                                <Chip
                                  label={`Created: ${new Date(
                                    invoice.createdAt
                                  ).toLocaleString(undefined, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}`}
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                            </Stack>
                            {invoice.oldVersions &&
                              invoice.oldVersions.length > 0 && (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  This invoice has been revised{" "}
                                  {invoice.oldVersions.length} time
                                  {invoice.oldVersions.length > 1 ? "s" : ""}
                                </Typography>
                              )}
                            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                              <Button
                                startIcon={<DescriptionIcon />}
                                variant="outlined"
                                size="small"
                                onClick={() => handleViewInvoice(invoice)}
                              >
                                View
                              </Button>
                              {invoice.oldVersions &&
                                invoice.oldVersions.length > 0 && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleViewVersions(invoice)}
                                  >
                                    Revisions
                                  </Button>
                                )}
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleEditClick(invoice)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => handleDeleteClick(invoice)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleOpenReminderDialog(invoice)}
                                title="Send reminder"
                              >
                                <NotificationsIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleOpenCopyDialog(invoice)}
                                title="Copy invoice"
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>

      {/* View Invoice Dialog */}
      {selectedInvoiceForView && pdfUrl && (
        <ViewFileDialog
          open={viewDialogOpen}
          onClose={handleCloseViewDialog}
          fileUrl={pdfUrl}
          fileName={`Invoice_${selectedInvoiceForView.invoiceNumber}.pdf`}
        />
      )}

      {/* Generate Invoice Dialog */}
      <GenerateInvoiceDialog
        open={isGenerateDialogOpen}
        onClose={handleCloseGenerateDialog}
        isEditMode={!!invoiceToEdit}
        editingInvoice={invoiceToEdit}
        onSubmit={handleSubmitInvoice}
        clientId={clientId}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete invoice #
            {invoiceToDelete?.invoiceNumber || invoiceToDelete?.id}? This action
            cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Versions Dialog */}
      <Dialog
        open={versionsDialogOpen}
        onClose={handleCloseVersionsDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Invoice Revision History - #
          {selectedInvoiceForVersions?.invoiceNumber}
        </DialogTitle>
        <DialogContent>
          {selectedInvoiceForVersions && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Current Version{" "}
                {selectedInvoiceForVersions.oldVersions &&
                selectedInvoiceForVersions.oldVersions.length > 0
                  ? `(Revision ${selectedInvoiceForVersions.oldVersions.length + 1})`
                  : "(Original)"}
              </Typography>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">
                    Invoice #{selectedInvoiceForVersions.invoiceNumber}
                  </Typography>
                  <Typography>
                    Created:{" "}
                    {selectedInvoiceForVersions.createdAt
                      ? new Date(
                          selectedInvoiceForVersions.createdAt
                        ).toLocaleString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                      : "Unknown date"}
                  </Typography>
                  <Typography>
                    Amount:{" "}
                    {formatCurrency(
                      selectedInvoiceForVersions.totalDollarAmountDue
                    )}
                  </Typography>
                  <Button
                    startIcon={<DescriptionIcon />}
                    variant="outlined"
                    size="small"
                    onClick={() =>
                      handleViewInvoice(selectedInvoiceForVersions)
                    }
                    sx={{ mt: 1 }}
                  >
                    View Current Version
                  </Button>
                </CardContent>
              </Card>

              <Typography variant="subtitle1" gutterBottom>
                Previous Versions
              </Typography>
              {!selectedInvoiceForVersions.oldVersions ||
              selectedInvoiceForVersions.oldVersions.length === 0 ? (
                <Typography>No previous versions found</Typography>
              ) : (
                // Display versions in reverse order (oldest to newest)
                [...selectedInvoiceForVersions.oldVersions]
                  .reverse()
                  .map((version, index) => {
                    // Calculate the version number based on the reversed index
                    const versionNumber =
                      selectedInvoiceForVersions.oldVersions!.length - index;

                    // Calculate the actual version index for viewing the PDF
                    const versionIndex =
                      selectedInvoiceForVersions.oldVersions!.length -
                      versionNumber;

                    return (
                      <Card key={index} sx={{ mb: 2 }}>
                        <CardContent>
                          <Typography variant="h6">
                            {" "}
                            {version.createdAt
                              ? new Date(version.createdAt).toLocaleString(
                                  undefined,
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )
                              : "Unknown date"}
                          </Typography>
                          <Typography>
                            Amount:{" "}
                            {formatCurrency(version.totalDollarAmountDue)}
                          </Typography>
                          <Button
                            startIcon={<DescriptionIcon />}
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              handleViewInvoice(
                                selectedInvoiceForVersions,
                                versionIndex
                              )
                            }
                            sx={{ mt: 1 }}
                          >
                            View This Version
                          </Button>
                        </CardContent>
                      </Card>
                    );
                  })
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVersionsDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Reminder Dialog */}
      <Dialog
        open={reminderDialogOpen}
        onClose={handleCloseReminderDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Send Invoice Reminder</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Select stakeholders to send a reminder for Invoice #
            {selectedInvoiceForReminder?.invoiceNumber || selectedInvoiceForReminder?.id}
          </Typography>
          <List>
            {clientStakeholders.map((stakeholder) => (
              <ListItem key={stakeholder.id} disablePadding>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStakeholders.includes(stakeholder.id)}
                      onChange={() => handleStakeholderToggle(stakeholder.id)}
                      disabled={isSendingReminders}
                    />
                  }
                  label={`${stakeholder.firstName} ${stakeholder.lastName} (${stakeholder.email})`}
                />
              </ListItem>
            ))}
          </List>
          {clientStakeholders.length === 0 && (
            <Typography color="text.secondary">
              No stakeholders found for this client.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReminderDialog} disabled={isSendingReminders}>Cancel</Button>
          <Button
            onClick={handleSendReminders}
            color="primary"
            variant="contained"
            disabled={selectedStakeholders.length === 0 || isSendingReminders}
          >
            {isSendingReminders ? "Sending..." : "Send Reminders"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Copy Invoice Dialog */}
      <Dialog
        open={copyDialogOpen}
        onClose={handleCloseCopyDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Copy Invoice</DialogTitle>
        <DialogContent>
          <Typography gutterBottom sx={{ mb: 2 }}>
            You are creating a copy of invoice #{invoiceToCopy?.invoiceNumber || invoiceToCopy?.id}.
            The new invoice will retain all the core details but will have a new ID and invoice number.
          </Typography>
          <TextField
            fullWidth
            label="New Invoice Number"
            value={newInvoiceNumber}
            onChange={(e) => setNewInvoiceNumber(e.target.value)}
            required
            sx={{ mt: 2 }}
            helperText="You can modify the invoice number for the copy"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCopyDialog} disabled={isCopyingInvoice}>Cancel</Button>
          <Button
            onClick={handleCopyInvoice}
            color="primary"
            variant="contained"
            disabled={isCopyingInvoice || !newInvoiceNumber.trim()}
          >
            {isCopyingInvoice ? "Copying..." : "Copy Invoice"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AssociatedInvoices;
