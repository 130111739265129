import { ConsultingServicesAgreement } from "@freetech/models/projects";
import { addDocument, updateDocument } from "core/firestore";
import { uploadFile } from "core/storage";

// Helper to remove undefined values from an object
const removeUndefinedValues = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.entries(obj)
    .filter(([_, value]) => value !== undefined)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );
};

export const createAndUploadCSA = async (
  clientId: string,
  projectId: string,
  csaData: Omit<ConsultingServicesAgreement, "id" | "fileUrl">,
  file: File
): Promise<ConsultingServicesAgreement> => {
  // Remove any undefined values from the CSA data
  const cleanedData = removeUndefinedValues(csaData) as Omit<
    ConsultingServicesAgreement,
    "id" | "fileUrl"
  >;

  const newCSARef = await addDocument(
    `clients/${clientId}/projects/${projectId}/consultingServicesAgreements`,
    cleanedData
  );

  const fileUrl = await uploadFile(
    `clients/${clientId}/projects/${projectId}/csas/${newCSARef.id}`,
    file
  );

  const updateWithFileUrl: ConsultingServicesAgreement = {
    id: newCSARef.id,
    ...cleanedData,
    fileUrl,
    clientId,
    projectId,
    status: "active",
  };

  await updateDocument(
    `clients/${clientId}/projects/${projectId}/consultingServicesAgreements/${newCSARef.id}`,
    updateWithFileUrl
  );

  return updateWithFileUrl;
};
