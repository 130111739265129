import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Fade,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuth } from "../../../hooks/auth/useAuth";
import { useNotifications } from "../../../hooks/notifications/useNotifications";
import { NotificationList } from "./components/NotificationList";
import NotificationSection from "./components/NotificationSection";
import {
  NotificationPreference,
  NotificationPreferences,
  AllNotificationType,
} from "@freetech/models/notifications";
import { notificationEnums } from "@freetech/models/notifications";

// Extend NotificationPreferences to allow string indexing
interface ExtendedNotificationPreferences extends NotificationPreferences {
  [key: string]: NotificationPreference;
}

export const NotificationCenter: React.FC = () => {
  const { userInfo } = useAuth();
  const {
    updateNotificationPreferences,
    notificationPreferences: fetchedPreferences,
    refetchNotificationPreferences,
  } = useNotifications(userInfo?.id || "");

  // State to control whether to show preferences or notifications
  const [showPreferences, setShowPreferences] = useState(false);

  // Determine user role
  const isStakeholder =
    userInfo?.role === "stakeholder" ||
    userInfo?.role === "requested-stakeholder" ||
    userInfo?.role === "babyStakeholder";
  const isAdmin = [
    "admin",
    "superAdmin",
    "executiveAssistant",
    "SuperUser",
  ].includes(userInfo?.role || "");
  const isFreelancer = !isStakeholder && !isAdmin;

  // Get all possible notification types to ensure we have defaults for all of them
  const allNotificationTypes: AllNotificationType[] = [
    ...Object.values(notificationEnums.StakeholderNotificationType),
    ...Object.values(notificationEnums.FreeloNotificationType),
    ...Object.values(notificationEnums.AdminNotificationType),
  ];

  // Get core notification types based on user role
  const getCoreNotificationTypes = (): AllNotificationType[] => {
    if (isStakeholder) {
      return Object.values(notificationEnums.StakeholderNotificationType);
    } else if (isAdmin) {
      // For admin users, show admin notifications instead of core notifications
      return Object.values(notificationEnums.AdminNotificationType);
    } else {
      // Freelancers don't have core notifications
      return [];
    }
  };

  // Get notification types to display based on user role
  const displayNotificationTypes: AllNotificationType[] = [
    ...getCoreNotificationTypes(),
    ...Object.values(notificationEnums.FreeloNotificationType),
  ];

  // Initialize preferences with default values for all notification types
  const getDefaultPreferences = (): ExtendedNotificationPreferences => {
    return Object.fromEntries(
      allNotificationTypes.map((type) => [type, { email: false, sms: false }])
    ) as ExtendedNotificationPreferences;
  };

  const [notificationPreferences, setNotificationPreferences] =
    useState<ExtendedNotificationPreferences>(getDefaultPreferences());
  const [hasChanges, setHasChanges] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Update preferences when fetched preferences change
  useEffect(() => {
    if (fetchedPreferences) {
      console.log("Fetched preferences:", fetchedPreferences);

      // Create a new preferences object with defaults for all types
      const defaultPrefs = getDefaultPreferences();

      // Carefully merge the fetched preferences, preserving existing values
      const mergedPreferences = { ...defaultPrefs };

      // Explicitly copy each preference from fetched to merged
      Object.keys(
        fetchedPreferences as ExtendedNotificationPreferences
      ).forEach((key) => {
        const typedPreferences =
          fetchedPreferences as ExtendedNotificationPreferences;
        if (typedPreferences[key]) {
          mergedPreferences[key] = {
            email: typedPreferences[key].email || false,
            // Always set SMS to false regardless of fetched value
            sms: false,
          };
        }
      });

      console.log("Merged preferences:", mergedPreferences);
      setNotificationPreferences(mergedPreferences);
      setIsLoading(false);
      setHasChanges(false);
    }
  }, [fetchedPreferences]);

  // Handle toggle for notification preferences
  const handleToggle = (type: string, channel: "email" | "sms") => {
    // Prevent toggling SMS notifications
    if (channel === "sms") return;

    setNotificationPreferences((prev) => {
      // Ensure we have a valid preference object for this type
      const currentPreference = prev[type] || { email: false, sms: false };

      // Create a new preference object with the toggled value
      const updatedPreference = {
        ...currentPreference,
        [channel]: !currentPreference[channel],
      };

      // Create a new preferences object to ensure React detects the change
      const newPreferences = {
        ...prev,
        [type]: updatedPreference,
      };

      console.log(`Toggled ${channel} for ${type}:`, updatedPreference);

      // Set hasChanges to true whenever a preference is toggled
      setHasChanges(true);

      return newPreferences;
    });
  };

  const handleSavePreferences = async () => {
    if (userInfo?.id && hasChanges) {
      try {
        // Create a copy of preferences with all SMS values set to false
        const preferencesToSave = { ...notificationPreferences };

        // Ensure all SMS preferences are set to false
        Object.keys(preferencesToSave).forEach((key) => {
          if (preferencesToSave[key]) {
            preferencesToSave[key] = {
              ...preferencesToSave[key],
              sms: false,
            };
          }
        });

        await updateNotificationPreferences.mutateAsync(
          preferencesToSave as NotificationPreferences
        );
        setShowSuccessAlert(true);
        setHasChanges(false);
      } catch (error) {
        console.error("Failed to save notification preferences:", error);
        setShowErrorAlert(true);
      }
    }
  };

  const handleCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const handleCloseErrorAlert = () => {
    setShowErrorAlert(false);
  };

  // Initialize with all sections expanded for better UX
  const [expandedSubsections, setExpandedSubsections] = useState<Set<string>>(
    new Set([
      "Core Notifications",
      "Freelo Notifications",
      "Admin Notifications",
    ])
  );

  // Create notification sections based on user role
  const notificationSections = [
    {
      title: isAdmin ? "Admin Notifications" : "Core Notifications",
      types: getCoreNotificationTypes(),
    },
    {
      title: "Freelo Notifications",
      types: Object.values(notificationEnums.FreeloNotificationType),
    },
  ];

  const handleSubsectionExpand = (subsection: string) => {
    setExpandedSubsections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(subsection)) {
        newSet.delete(subsection);
      } else {
        newSet.add(subsection);
      }
      return newSet;
    });
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: { xs: 2, md: 3 } }}>
      {/* Main View with Notifications and Settings Button */}
      <Fade in={!showPreferences} mountOnEnter unmountOnExit>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              Notification Center
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SettingsIcon />}
              onClick={() => setShowPreferences(true)}
              size="small"
              sx={{
                borderRadius: 2,
                textTransform: "none",
                fontWeight: 500,
              }}
            >
              Manage notification preferences
            </Button>
          </Box>

          <NotificationList />
        </Box>
      </Fade>

      {/* Preferences View */}
      <Fade in={showPreferences} mountOnEnter unmountOnExit>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 4,
            }}
          >
            <IconButton
              onClick={() => setShowPreferences(false)}
              sx={{ mr: 2 }}
              aria-label="Back to notifications"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              Notification Settings
            </Typography>
          </Box>

          <Paper
            elevation={2}
            sx={{ p: { xs: 2, md: 4 }, borderRadius: 2, mb: 4 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Notification Preferences
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  updateNotificationPreferences.isPending ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SaveIcon />
                  )
                }
                onClick={handleSavePreferences}
                disabled={
                  !hasChanges ||
                  updateNotificationPreferences.isPending ||
                  isLoading
                }
              >
                {updateNotificationPreferences.isPending
                  ? "Saving..."
                  : "Save Changes"}
              </Button>
            </Box>

            <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="text.secondary">
                Configure which notifications you want to receive via email.{" "}
                <strong>SMS notifications are coming soon.</strong>
              </Typography>
            </Box>

            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {notificationSections.map((section) => (
                  <NotificationSection
                    key={section.title}
                    title={section.title}
                    expandedSubsections={expandedSubsections}
                    notificationPreferences={notificationPreferences}
                    handleSubsectionExpand={handleSubsectionExpand}
                    notificationTypes={section.types}
                    onToggle={handleToggle}
                  />
                ))}
              </Box>
            )}
          </Paper>
        </Box>
      </Fade>

      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={6000}
        onClose={handleCloseSuccessAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccessAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Notification preferences saved successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorAlert}
        autoHideDuration={6000}
        onClose={handleCloseErrorAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          Failed to save notification preferences. Please try again.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotificationCenter;
