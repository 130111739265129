import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  doc,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { FreelancerPayment } from "@freetech/models/bank";
import { bankFunctions } from "core/functions/bankFunctions";
import { Freelancer } from "@freetech/models/user";
import { useAuth } from "hooks/auth/useAuth";
// Define the OldTaxFormEntry type based on the Freelancer.oldTaxForms type
type OldTaxFormEntry = NonNullable<Freelancer["oldTaxForms"]>[number];

const db = getFirestore();
const storage = getStorage();

export const useAdminPayroll = () => {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  const getTaxFormUrl = async (userId: string) => {
    try {
      const storageRef = ref(storage, `users/${userId}/taxform.pdf`);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error("Error getting W9 form URL:", error);
      throw error;
    }
  };

  const verifyTaxForm = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const userRef = doc(db, "users", userId);
      const now = new Date().toISOString();

      await updateDoc(userRef, {
        taxFormVerifiedByAdmin: true,
        taxFormVerifiedByAdminAt: now,
        updatedAt: now,
      });

      return { success: true };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  const markReadyForPayroll = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const userRef = doc(db, "users", userId);
      const now = new Date().toISOString();

      await updateDoc(userRef, {
        readyForPayroll: true,
        readyForPayrollAt: now,
        updatedAt: now,
      });

      return { success: true };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  const usePrenotificationStatus = (
    userId: string,
    enabled: boolean = true
  ) => {
    return useQuery({
      queryKey: ["prenotificationStatus", userId],
      queryFn: async () => {
        const response =
          await bankFunctions.shared.getAchPrenotificationStatus(userId);

        if (!response.ok) {
          throw new Error("Failed to get prenotification status");
        }

        return response.json();
      },
      enabled,
      refetchInterval: false, // Default to no refetching
    });
  };

  const resubmitPrenotification = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const response =
        await bankFunctions.admin.resubmitAchPrenotification(userId);

      if (!response.ok) {
        throw new Error("Failed to resubmit prenotification");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
      queryClient.invalidateQueries({ queryKey: ["prenotificationStatus"] });
    },
  });

  const makePayment = useMutation({
    mutationFn: async ({
      userId,
      freelancerPayment,
    }: {
      userId: string;
      freelancerPayment: Omit<FreelancerPayment, "id">;
    }) => {
      const response = await bankFunctions.admin.createFreelancerPayment(
        userId,
        freelancerPayment
      );

      if (response.status === 400) {
        const data = await response.json();
        if (data.code === "INSUFFICIENT_BALANCE") {
          throw new Error(
            "Insufficient balance in the account to process this payment. Please contact support."
          );
        }
      }

      if (!response.ok) {
        throw new Error("Failed to create payment");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
      queryClient.invalidateQueries({ queryKey: ["freelancerPayments"] });
    },
  });

  const createTestPayment = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const now = new Date().toISOString();
      const response =
        await bankFunctions.admin.createExternalAccountOutboundTestPayment(
          userId,
          100,
          "Test payment",
          now,
          "outbound"
        );

      if (!response.ok) {
        throw new Error("Failed to create test payment");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  const addExternalPayment = useMutation({
    mutationFn: async ({
      userId,
      amount,
      memo,
      paymentMethod,
      paymentDate,
      clientId,
      projectId,
      projectAssignmentId,
      csaId,
    }: {
      userId: string;
      amount: number;
      memo: string;
      paymentMethod:
        | "venmo"
        | "paypal"
        | "cashapp"
        | "zelle"
        | "ach"
        | "cash"
        | "check"
        | "wire";
      paymentDate: string;
      clientId?: string;
      projectId?: string;
      projectAssignmentId?: string;
      csaId?: string;
    }) => {
      const now = new Date().toISOString();
      if (!currentUser?.uid) {
        throw new Error("No authenticated user");
      }
      const payment: Omit<FreelancerPayment, "id"> = {
        userId,
        amount,
        memo,
        externalPaymentSource: paymentMethod,
        createdAt: now,
        updatedAt: now,
        status: "completed",
        paymentDate,
        direction: "outbound",
        approvedByAdmin: true,
        isExternalPayment: true,
        clientId: clientId || "",
        projectId,
        projectAssignmentId,
        csaId,
        submittedByAdminUserId: currentUser?.uid,
      };

      const paymentRef = await addDoc(
        collection(db, "users", userId, "payments"),
        payment
      );

      return { ...payment, id: paymentRef.id };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
      queryClient.invalidateQueries({ queryKey: ["freelancerPayments"] });
    },
  });

  const useFreelancerPayments = (userId: string) => {
    return useQuery({
      queryKey: ["freelancerPayments", userId],
      queryFn: async () => {
        const paymentsRef = collection(db, "users", userId, "payments");
        const snapshot = await getDocs(paymentsRef);

        const payments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as FreelancerPayment[];

        // Sort payments by date, most recent first
        return payments.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      },
      enabled: Boolean(userId),
    });
  };

  const useTestPaymentStatus = (userId: string, enabled: boolean = true) => {
    return useQuery<FreelancerPayment | null>({
      queryKey: ["testPaymentStatus", userId],
      queryFn: async () => {
        try {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);

          if (!docSnap.exists()) {
            throw new Error("User not found");
          }

          const freelancer = docSnap.data() as Freelancer;
          const testPaymentId =
            freelancer.increaseExternalAccountProfile?.testPaymentIncreaseId;

          if (!testPaymentId) {
            return null;
          }

          const testPaymentDocRef = doc(
            db,
            "users",
            userId,
            "payments",
            testPaymentId
          );
          const testPaymentDocSnap = await getDoc(testPaymentDocRef);

          if (!testPaymentDocSnap.exists()) {
            return null;
          }

          const payment = testPaymentDocSnap.data() as FreelancerPayment;
          console.log(payment);
          return payment;
        } catch (error) {
          console.error("Error fetching test payment status:", error);
          throw error;
        }
      },
      enabled,
      refetchInterval: 5000, // Refetch every 5 seconds while enabled
    });
  };

  const denyAndRequestNewTaxForm = useMutation({
    mutationFn: async ({
      userId,
      reason,
    }: {
      userId: string;
      reason: string;
    }) => {
      try {
        const userRef = doc(db, "users", userId);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
          throw new Error("User not found");
        }

        const userData = userDoc.data() as Freelancer;
        const now = new Date().toISOString();

        // Create an entry for oldTaxForms array
        const oldTaxFormEntry: OldTaxFormEntry = {
          taxFormType: userData.taxFormType || "w9",
          dateCompleted: userData.taxFormCompletedAt || now,
          deniedByAdmin: true,
          deniedByAdminAt: now,
          documentHash: userData.taxFormDocumentHash || "",
          documentEmbedUrl: userData.taxFormDocumentEmbedUrl || "",
          documentGeneratedAt: userData.taxFormGeneratedAt || "",
          documentCompletedAt: userData.taxFormCompletedAt || "",
          deniedByAdminReason: `Tax form denied on ${now}. Reason: ${reason}${userData.notes}`,
        };

        // Prepare the oldTaxForms array
        const oldTaxForms: OldTaxFormEntry[] = userData.oldTaxForms || [];
        oldTaxForms.push(oldTaxFormEntry);

        // Update the user document
        const updateData: Partial<Freelancer> = {
          // Clear current tax form data
          taxFormCompleted: false,
          taxFormDocumentHash: "",
          taxFormDocumentEmbedUrl: "",
          taxFormVerifiedByAdmin: false,
          taxFormVerifiedByAdminAt: "",

          // Set flags for new tax form request
          newTaxFormRequested: true,
          newTaxFormRequestedAt: now,
          newTaxFormRequestedByUserId: "admin", // Using "admin" as a placeholder
          taxFormNeedsUpdate: true,

          // Store the old tax form data
          oldTaxForms,

          // Reset payroll readiness
          readyForPayroll: false,

          // Add reason to notes

          // Update timestamp
          updatedAt: now,
        };

        await updateDoc(userRef, updateData);

        return { success: true };
      } catch (error) {
        console.error("Error denying tax form:", error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  return {
    getTaxFormUrl,
    verifyTaxForm,
    markReadyForPayroll,
    makePayment,
    usePrenotificationStatus,
    resubmitPrenotification,
    addExternalPayment,
    useFreelancerPayments,
    createTestPayment,
    useTestPaymentStatus,
    denyAndRequestNewTaxForm,
  };
};
