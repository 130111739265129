import {
  getFirestore,
  collection,
  doc,
  setDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { ClientInvoiceData } from "@freetech/models/bank";
import { createAndSavePDF } from "./createAndSavePDF";

export * from "./getInvoices";
export * from "./createInvoice";
export * from "./deleteInvoice";
export * from "./generateInvoiceNumber";
export { getInvoicePDFUrl } from "./getInvoicePDFUrl";
// export * from "./takePaymentForClientInvoice";
export { createAndSavePDF } from "./createAndSavePDF";

export const createInvoice = async (
  invoiceData: Omit<ClientInvoiceData, "id">
): Promise<ClientInvoiceData> => {
  try {
    const db = getFirestore();

    // Ensure we have a client ID
    if (!invoiceData.clientId) {
      throw new Error("Client ID is required for creating an invoice");
    }

    // Calculate total amount if not already set
    const totalDollarAmountDue =
      invoiceData.totalDollarAmountDue ||
      invoiceData.items.reduce(
        (sum: number, item: { quantity: number; rate: number }) =>
          sum + (item.quantity || 0) * (item.rate || 0),
        0
      );

    // Prepare invoice data
    const invoiceToSave: Omit<ClientInvoiceData, "id"> = {
      ...invoiceData,
      totalDollarAmountDue,
      status: "unpaid",
      createdAt: new Date().toISOString(),
    };

    // Log the invoice data being saved
    console.log("Creating invoice with data:", invoiceToSave);

    let invoiceId: string;

    // Create a new document
    const clientInvoicesRef = collection(
      db,
      "clients",
      invoiceData.clientId,
      "invoices"
    );

    // if (invoiceData.id) {
    //   // If ID is provided, use it (for testing or specific cases)
    //   invoiceId = invoiceData.id;
    //   await setDoc(doc(clientInvoicesRef, invoiceId), invoiceToSave);
    // } else {
    //   // Otherwise, let Firestore generate an ID
    //   const newInvoiceRef = await addDoc(clientInvoicesRef, invoiceToSave);
    //   invoiceId = newInvoiceRef.id;
    // }

    const newInvoiceRef = await addDoc(clientInvoicesRef, invoiceToSave);
    invoiceId = newInvoiceRef.id;

    // Generate PDF and save to storage
    const pdfUrl = await createAndSavePDF(
      invoiceToSave,
      invoiceData.clientId,
      invoiceId
    );

    // Update the invoice with the PDF URL
    const invoiceRef = doc(
      db,
      "clients",
      invoiceData.clientId,
      "invoices",
      invoiceId
    );
    await updateDoc(invoiceRef, { pdfUrl });

    // Return the complete invoice data
    const finalInvoice = {
      ...invoiceToSave,
      id: invoiceId,
      pdfUrl,
    };

    console.log("Created invoice:", finalInvoice);

    return finalInvoice;
  } catch (error) {
    console.error("Error creating invoice:", error);
    throw error;
  }
};
