import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Project } from "@freetech/models/projects";
import { Client } from "@freetech/models/projects";
import { UserInfoType } from "@freetech/models/user";
import {
  IBugReport,
  BugReportAttachment,
  ICreateBugReport,
} from "@freetech/models/bugReport";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CodeIcon from "@mui/icons-material/Code";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";

const DropzoneContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  borderStyle: "dashed",
  borderWidth: 2,
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.action.hover,
  "&:hover": {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  },
}));

const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "text/plain",
];

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const bugReportSchema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title is too long"),
  description: z
    .string()
    .min(1, "Description is required")
    .max(5000, "Description is too long"),
  stepsToReproduce: z
    .string()
    .min(1, "Steps to reproduce are required")
    .max(2000, "Steps to reproduce are too long"),
  expectedBehavior: z
    .string()
    .min(1, "Expected behavior is required")
    .max(1000, "Expected behavior is too long"),
  actualBehavior: z
    .string()
    .min(1, "Actual behavior is required")
    .max(1000, "Actual behavior is too long"),
  priority: z.enum(["low", "medium", "high", "critical"]),
  type: z.enum([
    "internal_user_experience",
    "end_user_experience",
    "performance",
    "security",
    "other",
  ]),
  browserInfo: z.string().optional(),
  targetDate: z.string().optional(),
});

export type BugReportFormData = z.infer<typeof bugReportSchema>;

interface BugReportDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ICreateBugReport, files: File[]) => Promise<void>;
  project: Project | undefined;
  client: Client | undefined;
  stakeholder: UserInfoType | undefined;
  isSubmitting: boolean;
  submitError?: string;
}

export const BugReportDialog: React.FC<BugReportDialogProps> = ({
  open,
  onClose,
  onSubmit,
  project,
  client,
  stakeholder,
  isSubmitting,
  submitError,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState<string>();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<BugReportFormData>({
    resolver: zodResolver(bugReportSchema),
    mode: "onChange",
    defaultValues: {
      title: "",
      description: "",
      priority: "medium",
      type: "internal_user_experience",
      stepsToReproduce: "",
      expectedBehavior: "",
      actualBehavior: "",
      browserInfo: "",
      targetDate: undefined,
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder:
          "Please provide a detailed description of the bug. Include any error messages or relevant context that would help us understand and reproduce the issue.",
      }),
    ],
    onUpdate: ({ editor }) => {
      setValue("description", editor.getHTML(), { shouldValidate: true });
    },
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none",
      },
    },
  });

  const handleClose = () => {
    reset();
    setSelectedFiles([]);
    setFileError(undefined);
    editor?.commands.clearContent();
    onClose();
  };

  const onDrop = (acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        setFileError(`File ${file.name} is too large. Maximum size is 10MB.`);
        return false;
      }
      return true;
    });
    setSelectedFiles((prev) => [...prev, ...validFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ALLOWED_FILE_TYPES.reduce(
      (acc, type) => ({ ...acc, [type]: [] }),
      {}
    ),
    maxSize: MAX_FILE_SIZE,
  });

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleFormSubmit = async (data: BugReportFormData) => {
    if (!project || !client || !stakeholder) return;

    const createBugReport: ICreateBugReport = {
      title: data.title,
      description: data.description,
      stepsToReproduce: data.stepsToReproduce,
      expectedBehavior: data.expectedBehavior,
      actualBehavior: data.actualBehavior,
      priority: data.priority,
      type: data.type,
      clientId: client.id,
      projectId: project.id,
      reportedById: stakeholder.id,
      browserInfo: data.browserInfo,
      status: "open", // Set initial status
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    await onSubmit(createBugReport, selectedFiles);
    resetFiles();
  };

  const resetFiles = () => {
    setSelectedFiles([]);
    setFileError(undefined);
  };

  const MenuBar = () => {
    if (!editor) return null;

    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          mb: 1,
          borderBottom: 1,
          borderColor: "divider",
          pb: 1,
        }}
      >
        <Tooltip title="Bold">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleBold().run()}
            color={editor.isActive("bold") ? "primary" : "default"}
          >
            <FormatBoldIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Italic">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            color={editor.isActive("italic") ? "primary" : "default"}
          >
            <FormatItalicIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Bullet List">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            color={editor.isActive("bulletList") ? "primary" : "default"}
          >
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Numbered List">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            color={editor.isActive("orderedList") ? "primary" : "default"}
          >
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Code">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            color={editor.isActive("codeBlock") ? "primary" : "default"}
          >
            <CodeIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: "min(1000px, 80vh)",
          width: "900px",
          m: 2,
        },
      }}
    >
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <DialogTitle sx={{ p: 2, flexShrink: 0, fontSize: "1.1rem" }}>
          Submit Bug Report
        </DialogTitle>
        <DialogContent
          sx={{
            p: "16px !important",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            overflow: "hidden",
          }}
        >
          {(submitError || fileError) && (
            <Alert severity="error" sx={{ flexShrink: 0 }}>
              {submitError || fileError}
            </Alert>
          )}

          <Box sx={{ flexShrink: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Project"
                  value={project?.name || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      bgcolor: "action.hover",
                      "&:hover": {
                        bgcolor: "action.hover",
                      },
                      "& .MuiInputBase-input": {
                        color: "text.secondary",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Client"
                  value={client?.companyName || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      bgcolor: "action.hover",
                      "&:hover": {
                        bgcolor: "action.hover",
                      },
                      "& .MuiInputBase-input": {
                        color: "text.secondary",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <TextField
            {...control.register("title")}
            autoFocus
            size="small"
            label="Bug Title"
            fullWidth
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ flexShrink: 0 }}
            placeholder="Enter a clear, concise title describing the bug"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
            }}
          >
            <MenuBar />
            <Box
              sx={{
                border: 1,
                borderColor: errors.description ? "error.main" : "divider",
                borderRadius: 1,
                p: 2,
                minHeight: "120px",
                maxHeight: "200px",
                overflow: "auto",
                "& .ProseMirror": {
                  minHeight: "100px",
                  width: "100%",
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                  "& p.is-editor-empty:first-child::before": {
                    content: "attr(data-placeholder)",
                    color: "text.disabled",
                    float: "left",
                    pointerEvents: "none",
                    height: 0,
                  },
                  "& p": {
                    margin: 0,
                    lineHeight: 1.6,
                  },
                },
              }}
            >
              <EditorContent editor={editor} />
            </Box>
            {errors.description && (
              <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                {errors.description.message}
              </Typography>
            )}
          </Box>

          <Controller
            name="stepsToReproduce"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={4}
                label="Steps to Reproduce"
                fullWidth
                size="small"
                error={!!errors.stepsToReproduce}
                helperText={errors.stepsToReproduce?.message}
                placeholder="1. Go to...&#10;2. Click on...&#10;3. Enter..."
              />
            )}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="expectedBehavior"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={3}
                    label="Expected Behavior"
                    fullWidth
                    size="small"
                    error={!!errors.expectedBehavior}
                    helperText={errors.expectedBehavior?.message}
                    placeholder="What should have happened?"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="actualBehavior"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={3}
                    label="Actual Behavior"
                    fullWidth
                    size="small"
                    error={!!errors.actualBehavior}
                    helperText={errors.actualBehavior?.message}
                    placeholder="What happened instead?"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ flexShrink: 0 }}>
            <Grid item xs={12} md={4}>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>Priority</InputLabel>
                    <Select {...field} label="Priority">
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="critical">Critical</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>Type</InputLabel>
                    <Select {...field} label="Type">
                      <MenuItem value="internal_user_experience">
                        Internal User Experience
                      </MenuItem>
                      <MenuItem value="end_user_experience">
                        End User Experience
                      </MenuItem>
                      <MenuItem value="performance">Performance</MenuItem>
                      <MenuItem value="security">Security</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="browserInfo"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Browser / Environment"
                    fullWidth
                    placeholder="e.g., Chrome 91.0, Windows 10"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box>
            <DropzoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              <CloudUploadIcon
                sx={{ fontSize: 40, color: "primary.main", mb: 1 }}
              />
              <Typography variant="h6" gutterBottom>
                {isDragActive ? "Drop files here" : "Drag & drop files here"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                or click to select files
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ display: "block", mt: 1 }}
              >
                Supported files: Images (JPEG, PNG, GIF), PDF, Text files
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Maximum file size: 10MB
              </Typography>
            </DropzoneContainer>

            {selectedFiles.length > 0 && (
              <Paper variant="outlined" sx={{ mt: 2, p: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Selected Files ({selectedFiles.length})
                </Typography>
                <List dense>
                  {selectedFiles.map((file, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        borderRadius: 1,
                        mb: 0.5,
                        bgcolor: "background.default",
                      }}
                    >
                      <ListItemText
                        primary={file.name}
                        secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveFile(index)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ p: 2, flexShrink: 0, borderTop: 1, borderColor: "divider" }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
