import { collectionGroup, query, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { getClientCompanyName } from "core/clients/utils/getClientCompanyName";
import { getProjectName } from "core/projects";
import { ProjectAssignment } from "@freetech/models/projects";
export const getProjectAssignments = async (): Promise<
  Record<string, ProjectAssignment[]>
> => {
  const projectAssignmentsQuery = query(
    collectionGroup(db, "projectAssignments")
  );
  const querySnapshot = await getDocs(projectAssignmentsQuery);
  const allProjectAssignments = await Promise.all(
    querySnapshot.docs
      .map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          }) as ProjectAssignment
      )
      .map(async (assignment) => {
        let clientName: string | undefined;
        let projectName: string | undefined;
        if (assignment.clientId) {
          clientName = await getClientCompanyName(assignment.clientId);
        }
        if (assignment.projectId && assignment.clientId) {
          projectName = await getProjectName(
            assignment.clientId,
            assignment.projectId
          );
        }
        return {
          ...assignment,
          clientName,
          projectName,
        };
      })
  );

  return allProjectAssignments.reduce(
    (acc, assignment) => {
      if (!acc[assignment.engineerId]) {
        acc[assignment.engineerId] = [];
      }
      acc[assignment.engineerId].push(assignment);
      return acc;
    },
    {} as Record<string, ProjectAssignment[]>
  );
};
