import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuth } from "hooks/auth/useAuth";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEversignSDK } from "hooks/eversign/useEversignSDK";
import { useFreelancerSignAgreements } from "hooks/freelancer/useFreelancerSignAgreements";
import { ProjAssToSignCard } from "components/ProjAssToSignCard";
import { IndEngToSignCard } from "components/IndEngToSignCard";
import { useNavigate, Link } from "react-router-dom";
import { ProjectAssignment } from "@freetech/models/projects";
import { AgreementToSign } from "core/freelancer/getAgreementsToSign";
import { IndependentEngineerAgreement } from "@freetech/models/user";

declare global {
  interface Window {
    eversign: any;
  }
}

export const SignAgreements: React.FC = () => {
  const { userInfo } = useAuth();
  const [selectedAgreement, setSelectedAgreement] =
    useState<AgreementToSign | null>(null);
  const [signingError, setSigningError] = useState<string | null>(null);
  const [showWarning, setShowWarning] = useState(false);
  const [showAdminSignatureDialog, setShowAdminSignatureDialog] =
    useState(false);
  const [showProjectSuccessDialog, setShowProjectSuccessDialog] =
    useState(false);
  const [showAgreementSuccessDialog, setShowAgreementSuccessDialog] =
    useState(false);
  const [isSigningInProgress, setIsSigningInProgress] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(false);

  const { isLoaded: eversignSDKLoaded, error: eversignSDKError } =
    useEversignSDK();

  const {
    agreementsToSign = [],
    isLoading,
    error,
    handleAgreementSigned,
  } = useFreelancerSignAgreements();

  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {}, [userInfo, eversignSDKLoaded]);

  useEffect(() => {
    if (eversignSDKError) {
      console.error("Eversign SDK failed to load:", eversignSDKError);
    }
  }, [eversignSDKLoaded, eversignSDKError]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (selectedAgreement) {
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [selectedAgreement]);

  const cleanupEversignIframe = useCallback(() => {
    if (containerRef.current) {
      const iframe = containerRef.current.querySelector("iframe");
      if (iframe) {
        iframe.remove();
      }
    }
  }, []);

  const handleCardClick = async (agreementToSign: AgreementToSign) => {
    if (
      agreementToSign.agreement.everSignDocumentStatus ===
      "awaiting_admin_signature"
    ) {
      setShowAdminSignatureDialog(true);
    } else {
      setIsIframeLoading(true);
      setSelectedAgreement(agreementToSign);

      try {
        await new Promise((resolve) => setTimeout(resolve, 0));

        if (containerRef.current && window.eversign?.open) {
          cleanupEversignIframe();

          window.eversign.open({
            url: agreementToSign.agreement.eversignUserDocumentEmbedUrl,
            containerID: containerRef.current.id,
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight,
            events: {
              loaded: () => {
                setIsIframeLoading(false);
              },
              signed: async () => {
                cleanupEversignIframe();
                setSelectedAgreement(null);
                setIsSigningInProgress(true);

                try {
                  await handleAgreementSigned(agreementToSign);
                  if (agreementToSign.type === "projectAssignment") {
                    setShowProjectSuccessDialog(true);
                  } else {
                    setShowAgreementSuccessDialog(true);
                  }
                } catch (error) {
                  console.error("Error during signing:", error);
                  setSigningError("Failed to process signed agreement");
                } finally {
                  setIsSigningInProgress(false);
                }
              },
              declined: () => {
                cleanupEversignIframe();
                setSelectedAgreement(null);
              },
              error: (error: any) => {
                cleanupEversignIframe();
                setIsIframeLoading(false);
                console.error("Eversign error:", error);
                if (error === "Document not ready to be signed.") {
                  setSigningError(
                    "This document is awaiting admin signature. Please try again later."
                  );
                } else {
                  setSigningError(`An error occurred: ${error}`);
                }
                setSelectedAgreement(null);
              },
            },
          });
        }
      } catch (error) {
        console.error("Error loading iframe:", error);
        setIsIframeLoading(false);
        setSigningError("Failed to load signing interface");
        setSelectedAgreement(null);
      }
    }
  };

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    if (selectedAgreement) {
      setShowWarning(true);
    }
  };

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleConfirmBack = () => {
    setShowWarning(false);
    setSelectedAgreement(null);
    handleNavigateBack();
  };

  const handleCloseAdminSignatureDialog = () => {
    setShowAdminSignatureDialog(false);
  };

  const handleCloseProjectSuccessDialog = () => {
    setShowProjectSuccessDialog(false);
    navigate("/project-assignments");
  };

  const handleCloseAgreementSuccessDialog = () => {
    setShowAgreementSuccessDialog(false);
    navigate("/freelancer-manage");
  };

  const handleNavigateBack = () => {
    navigate("/sign-agreements");
  };

  if (isLoading || !eversignSDKLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          {isLoading ? "Loading agreements..." : "Loading Eversign SDK..."}
        </Typography>
        {eversignSDKError && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Error: {eversignSDKError.message}
          </Typography>
        )}
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          {error.toString()}
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Refresh Page
        </Button>
      </Box>
    );
  }

  if (selectedAgreement) {
    const title =
      selectedAgreement.type === "projectAssignment"
        ? `Sign Agreement: ${(selectedAgreement.agreement as ProjectAssignment).projectName}`
        : "Sign Independent Engineer Agreement";

    return (
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{ zIndex: 1000 }}
          >
            Back
          </Button>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box
          ref={containerRef}
          id="eversign-container"
          sx={{
            flexGrow: 1,
            position: "relative",
            "& iframe": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            },
          }}
        />
        <Dialog
          open={showWarning}
          onClose={handleCloseWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Incomplete Agreement"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You haven't completed signing the agreement. Your progress will be
              lost if you leave this page. Are you sure you want to go back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWarning}>Stay on Page</Button>
            <Button onClick={handleConfirmBack} color="error">
              Leave Page
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Agreements to Sign
        </Typography>
        {agreementsToSign.length === 0 ? (
          <Typography>No agreements to sign at the moment.</Typography>
        ) : (
          <Grid container spacing={3}>
            {agreementsToSign.map((agreement) => (
              <Grid item xs={12} sm={6} md={4} key={agreement.id}>
                {agreement.type === "projectAssignment" ? (
                  <ProjAssToSignCard
                    assignment={agreement.agreement as ProjectAssignment}
                    onClick={() => handleCardClick(agreement)}
                  />
                ) : (
                  <IndEngToSignCard
                    agreement={
                      agreement.agreement as IndependentEngineerAgreement
                    }
                    onClick={() => handleCardClick(agreement)}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Dialog
        open={showAdminSignatureDialog}
        onClose={handleCloseAdminSignatureDialog}
        aria-labelledby="admin-signature-dialog-title"
        aria-describedby="admin-signature-dialog-description"
      >
        <DialogTitle id="admin-signature-dialog-title">
          {"Awaiting Admin Signature"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="admin-signature-dialog-description">
            This agreement is currently awaiting admin signature. You will be
            notified when it's ready for your signature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAdminSignatureDialog}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!signingError}
        onClose={() => setSigningError(null)}
        aria-labelledby="signing-error-dialog-title"
        aria-describedby="signing-error-dialog-description"
      >
        <DialogTitle id="signing-error-dialog-title">
          {"Signing Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="signing-error-dialog-description">
            {signingError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSigningError(null)}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showProjectSuccessDialog}
        onClose={handleCloseProjectSuccessDialog}
        aria-labelledby="project-success-dialog-title"
        aria-describedby="project-success-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="project-success-dialog-title">
          {"Agreement Successfully Signed!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="project-success-dialog-description">
            You can now track time for this project or view the signed agreement
            in Project Assignments.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, gap: 2 }}>
          <Button
            component={Link}
            to="/timesheet"
            variant="contained"
            color="primary"
            onClick={() => {
              setShowProjectSuccessDialog(false);
            }}
          >
            Go to Time Tracker
          </Button>
          <Button
            component={Link}
            to="/project-assignments"
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowProjectSuccessDialog(false);
            }}
          >
            View Project Assignments
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showAgreementSuccessDialog}
        onClose={handleCloseAgreementSuccessDialog}
        aria-labelledby="agreement-success-dialog-title"
        aria-describedby="agreement-success-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="agreement-success-dialog-title">
          {"Independent Engineer Agreement Signed!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="agreement-success-dialog-description">
            Your Independent Engineer Agreement has been successfully signed.
            You can view it in your profile.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            component={Link}
            to="/freelancer-manage"
            variant="contained"
            color="primary"
            onClick={() => {
              setShowAgreementSuccessDialog(false);
            }}
          >
            View in Profile
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isSigningInProgress}
        aria-labelledby="signing-progress-dialog-title"
        aria-describedby="signing-progress-dialog-description"
      >
        <DialogContent
          sx={{ display: "flex", alignItems: "center", gap: 2, p: 3 }}
        >
          <CircularProgress size={24} />
          <DialogContentText id="signing-progress-dialog-description">
            Processing signed agreement...
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isIframeLoading}
        aria-labelledby="iframe-loading-dialog-title"
        aria-describedby="iframe-loading-dialog-description"
      >
        <DialogContent
          sx={{ display: "flex", alignItems: "center", gap: 2, p: 3 }}
        >
          <CircularProgress size={24} />
          <DialogContentText id="iframe-loading-dialog-description">
            Loading signing interface...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
