import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Alert,
  Stack,
  AlertTitle,
  Button,
  Snackbar,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { PaymentProfileCard } from "./components/PaymentProfileCard";
import { ActiveProjectsCard } from "./components/ActiveProjectsCard";
import { PaymentHistory } from "./components/PaymentHistory";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { FreeTechLogoLoading } from "components/FreeTechLogoLoading";
import { UpdatePaymentProfileDialog } from "./components/UpdatePaymentProfileDialog";
import { useQueryClient } from "@tanstack/react-query";

export const StakeholderPayments: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const {
    paymentProfile,
    paymentProfileError,
    clientInvoices,
    clientInvoicesLoading,
  } = useStakeholderPayments();
  const {
    stakeholderProfile,
    clients,
    isLoadingStakeholderProfile: isLoadingStakeholder,
  } = useStakeholder(currentUser?.uid);
  const queryClient = useQueryClient();

  const [isCreateProfileDialogOpen, setIsCreateProfileDialogOpen] = useState(false);
  const [stripeSuccessMessage, setStripeSuccessMessage] = useState<string | null>(null);
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string | null>(null);

  // Handle Stripe checkout redirect
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');
    const canceled = queryParams.get('canceled');
    const invoiceId = queryParams.get('invoiceId');
    const newTab = queryParams.get('newTab');

    if (success === 'true' && invoiceId) {
      const invoice = clientInvoices?.find(inv => inv.id === invoiceId);
      
      if (invoice) {
        // Calculate the amount that was paid (may include 5% fee if paid by card)
        const amount = invoice.totalDollarAmountDue || 0;
        setStripeSuccessMessage(`Payment for invoice #${invoice.invoiceNumber || ''} was successful! Your invoice will be marked as paid shortly.`);
      } else {
        setStripeSuccessMessage(`Payment was successful! Your invoice will be marked as paid shortly.`);
      }
      
      // Clear URL parameters
      navigate('/stakeholder/payments', { replace: true });
      
      // If payment was initiated in a new tab, refresh the data to show updated payment status
      if (newTab === 'true') {
        queryClient.invalidateQueries({ queryKey: ['clientInvoices', currentUser?.uid] });
      }
    } else if (canceled === 'true') {
      setStripeErrorMessage('Payment was canceled. Your invoice remains unpaid.');
      
      // Clear URL parameters
      navigate('/stakeholder/payments', { replace: true });
    }
  }, [location.search, navigate, clientInvoices, currentUser?.uid, queryClient]);

  const handleSetupPayment = () => {
    navigate("/stakeholder/onboarding", { state: { startAtStep: 3 } });
  };

  const handleCloseSuccessMessage = () => {
    setStripeSuccessMessage(null);
  };

  const handleCloseErrorMessage = () => {
    setStripeErrorMessage(null);
  };

  if (isLoadingStakeholder || clientInvoicesLoading) {
    return <FreeTechLogoLoading />;
  }

  // Calculate payment overview metrics using clientInvoices
  const pendingInvoices =
    clientInvoices?.filter((invoice) => invoice.status === "unpaid") || [];
  const totalExpectedAmount = pendingInvoices.reduce(
    (total, invoice) => total + (invoice.totalDollarAmountDue || 0),
    0
  );

  const hasPaymentProfile = stakeholderProfile?.paymentProfileCompleted;

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Payment Overview Section - Left Side (70%) */}
        <Grid item xs={12} lg={8}>
          <Stack spacing={3}>
            {/* Payment Overview Card */}
            <Paper
              elevation={0}
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <AccountBalanceWalletIcon color="primary" />
                <Typography variant="h6">Payment Overview</Typography>
              </Box>

              <Box sx={{ p: 3 }}>
                <Stack spacing={2}>
                  {pendingInvoices.length > 0 ? (
                    <>
                      <Box sx={{ textAlign: "center", mb: 1 }}>
                        <Typography
                          variant="h4"
                          color="text.primary"
                          gutterBottom
                        >
                          ${totalExpectedAmount.toLocaleString()}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Total Outstanding Amount
                        </Typography>
                      </Box>

                      {!hasPaymentProfile ? (
                        <Alert severity="warning" icon={<PaymentIcon />}>
                          <AlertTitle>Payment Profile Required</AlertTitle>
                          You need to set up your payment profile before you can pay your pending invoices.
                          Please create your payment profile to proceed with payments.
                        </Alert>
                      ) : (
                        <>
                          <Alert severity="warning" icon={<ReceiptIcon />}>
                            <AlertTitle>Pending Invoices</AlertTitle>
                            You have {pendingInvoices.length} invoice
                            {pendingInvoices.length !== 1 ? "s" : ""} pending
                            payment
                          </Alert>

                          <Alert severity="info" icon={<PaymentIcon />}>
                            <AlertTitle>Payments Due </AlertTitle>
                            {pendingInvoices.map((invoice) => (
                              <Typography key={invoice.id} variant="body1">
                                Invoice #{invoice.invoiceNumber}:{" "}
                                {
                                  clients?.find(
                                    (client) => client.id === invoice.clientId
                                  )?.companyName
                                }{" "}
                                - ${invoice.totalDollarAmountDue?.toLocaleString()}
                              </Typography>
                            ))}
                          </Alert>
                        </>
                      )}
                    </>
                  ) : (
                    hasPaymentProfile ? (
                      <Alert severity="success">
                        <AlertTitle>All Set!</AlertTitle>
                        You have no pending invoices or expected payments at this time.
                      </Alert>
                    ) : (
                      <Alert severity="warning" icon={<PaymentIcon />}>
                        <AlertTitle>Payment Profile Required</AlertTitle>
                        You currently have no pending invoices, but you'll need to set up your payment profile to be ready for future payments. This is required to process any payments for FreeTech projects.
                      </Alert>
                    )
                  )}
                </Stack>
              </Box>
            </Paper>

            {/* Payment History */}
            <PaymentHistory />
          </Stack>
        </Grid>

        {/* Payment Profile Section - Right Side (30%) */}
        <Grid item xs={12} lg={4}>
          <Stack spacing={3}>
            {hasPaymentProfile ? (
              <PaymentProfileCard />
            ) : (
              <Paper
                elevation={0}
                sx={{
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 2,
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <AccountBalanceIcon 
                  color="primary" 
                  sx={{ fontSize: 48, mb: 2 }} 
                />
                <Typography
                  variant="h6"
                  color="text.primary"
                  gutterBottom
                >
                  Set Up Payment Profile
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  gutterBottom
                  sx={{ mb: 3 }}
                >
                  Let's set up your payment profile. Please contact admin@freetech.co with any questions.
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setIsCreateProfileDialogOpen(true)}
                  startIcon={<PaymentIcon />}
                  size="large"
                >
                  Create Payment Profile
                </Button>
              </Paper>
            )}

            {/* Active Projects Section - Now under payment profile */}
            <ActiveProjectsCard />
          </Stack>
        </Grid>
      </Grid>

      <UpdatePaymentProfileDialog
        open={isCreateProfileDialogOpen}
        onClose={() => setIsCreateProfileDialogOpen(false)}
        mode="create"
      />

      {/* Stripe success message */}
      <Snackbar
        open={!!stripeSuccessMessage}
        autoHideDuration={6000}
        onClose={handleCloseSuccessMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccessMessage} severity="success" sx={{ width: '100%' }}>
          {stripeSuccessMessage}
        </Alert>
      </Snackbar>

      {/* Stripe error message */}
      <Snackbar
        open={!!stripeErrorMessage}
        autoHideDuration={6000}
        onClose={handleCloseErrorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseErrorMessage} severity="error" sx={{ width: '100%' }}>
          {stripeErrorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
