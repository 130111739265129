import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useAdminStakeholders } from "hooks/admin/useAdminStakeholders";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { Stakeholder, Freelancer } from "@freetech/models/user";
import { Client } from "@freetech/models/projects";
import { useQueryClient } from "@tanstack/react-query";
import { use } from "motion/react-client";

interface AssociatedClientUsersProps {
  client: Client;
  clientId: string;
}

const AssociatedClientUsers: React.FC<AssociatedClientUsersProps> = ({
  client,
  clientId,
}) => {
  const [openStakeholderDialog, setOpenStakeholderDialog] = useState(false);
  const [openFreelancerDialog, setOpenFreelancerDialog] = useState(false);
  const [associatedStakeholders, setAssociatedStakeholders] = useState<
    Stakeholder[]
  >([]);
  const [associatedFreelancers, setAssociatedFreelancers] = useState<
    Freelancer[]
  >([]);
  const queryClient = useQueryClient();

  const {
    associateStakeholder,
    dissociateStakeholder,
    associateFreelancer,
    dissociateFreelancer,
  } = useAdminClient(clientId);

  const { data: stakeholders = [] } = useAdminStakeholders();
  const { freelancers = [] } = useAdminFreelancers();

  const availableStakeholders = stakeholders.filter(
    (s: Stakeholder) => !client.associatedStakeholderIds?.includes(s.id)
  );

  const availableFreelancers = freelancers.filter(
    (f: Freelancer) => !client.associatedFreelancerIds?.includes(f.id)
  );

  const handleAssociateStakeholder = async (stakeholderId: string) => {
    try {
      await associateStakeholder.mutateAsync(stakeholderId);
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      queryClient.invalidateQueries({ queryKey: ["adminStakeholders"] });
      setOpenStakeholderDialog(false);
    } catch (error) {
      console.error("Error associating stakeholder:", error);
    }
  };

  const handleDissociateStakeholder = async (stakeholderId: string) => {
    try {
      await dissociateStakeholder.mutateAsync(stakeholderId);
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      queryClient.invalidateQueries({ queryKey: ["adminStakeholders"] });
    } catch (error) {
      console.error("Error removing stakeholder:", error);
    }
  };

  const handleAssociateFreelancer = async (freelancerId: string) => {
    try {
      await associateFreelancer.mutateAsync(freelancerId);
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
      setOpenFreelancerDialog(false);
    } catch (error) {
      console.error("Error associating freelancer:", error);
    }
  };

  const handleDissociateFreelancer = async (freelancerId: string) => {
    try {
      await dissociateFreelancer.mutateAsync(freelancerId);
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    } catch (error) {
      console.error("Error removing freelancer:", error);
    }
  };

  useEffect(() => {
    setAssociatedStakeholders(
      stakeholders.filter((s: Stakeholder) =>
        client.associatedStakeholderIds?.includes(s.id)
      )
    );
    setAssociatedFreelancers(
      freelancers.filter((f: Freelancer) =>
        client.associatedFreelancerIds?.includes(f.id)
      )
    );
  }, [stakeholders, freelancers, client, client.associatedStakeholderIds, client.associatedFreelancerIds]);

  return (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      {/* Associated Stakeholders */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">Associated Stakeholders</Typography>
              <Button
                startIcon={<AddIcon />}
                onClick={() => setOpenStakeholderDialog(true)}
                disabled={availableStakeholders.length === 0}
              >
                Add Stakeholder
              </Button>
            </Box>
            <List>
              {associatedStakeholders.map((stakeholder: Stakeholder) => (
                <ListItem key={stakeholder.id}>
                  <ListItemText
                    primary={stakeholder.displayName || stakeholder.email}
                    secondary={stakeholder.email}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() =>
                        handleDissociateStakeholder(stakeholder.id)
                      }
                      disabled={dissociateStakeholder.isPending}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {associatedStakeholders.length === 0 && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ p: 2 }}
                >
                  No stakeholders associated
                </Typography>
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>

      {/* Associated Freelancers */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">Associated Freelancers</Typography>
              <Button
                startIcon={<AddIcon />}
                onClick={() => setOpenFreelancerDialog(true)}
                disabled={availableFreelancers.length === 0}
              >
                Add Freelancer
              </Button>
            </Box>
            <List>
              {associatedFreelancers.map((freelancer: Freelancer) => (
                <ListItem key={freelancer.id}>
                  <ListItemText
                    primary={freelancer.displayName || freelancer.email}
                    secondary={freelancer.email}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleDissociateFreelancer(freelancer.id)}
                      disabled={dissociateFreelancer.isPending}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {associatedFreelancers.length === 0 && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ p: 2 }}
                >
                  No freelancers associated
                </Typography>
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>

      {/* Add Stakeholder Dialog */}
      <Dialog
        open={openStakeholderDialog}
        onClose={() => setOpenStakeholderDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Stakeholder</DialogTitle>
        <DialogContent>
          <List>
            {availableStakeholders.map((stakeholder: Stakeholder) => (
              <ListItem
                key={stakeholder.id}
                onClick={() => handleAssociateStakeholder(stakeholder.id)}
                sx={{
                  cursor: "pointer",
                  "&:hover": { bgcolor: "action.hover" },
                }}
              >
                <ListItemText
                  primary={stakeholder.displayName || stakeholder.email}
                  secondary={stakeholder.email}
                />
              </ListItem>
            ))}
            {availableStakeholders.length === 0 && (
              <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
                No stakeholders available to add
              </Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStakeholderDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Freelancer Dialog */}
      <Dialog
        open={openFreelancerDialog}
        onClose={() => setOpenFreelancerDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Freelancer</DialogTitle>
        <DialogContent>
          <List>
            {availableFreelancers.map((freelancer: Freelancer) => (
              <ListItem
                key={freelancer.id}
                onClick={() => handleAssociateFreelancer(freelancer.id)}
                sx={{
                  cursor: "pointer",
                  "&:hover": { bgcolor: "action.hover" },
                }}
              >
                <ListItemText
                  primary={freelancer.displayName || freelancer.email}
                  secondary={freelancer.email}
                />
              </ListItem>
            ))}
            {availableFreelancers.length === 0 && (
              <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
                No freelancers available to add
              </Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFreelancerDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AssociatedClientUsers;
