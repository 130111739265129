import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Chip,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LinkIcon from "@mui/icons-material/Link";
import { IFeatureRequest, Freelancer } from "@freetech/models";
import { Card as FreeloCard } from "@freetech/models/freelo";
import { DateTime } from "luxon";
import ReadOnlyTipTap from "components/TipTap/ReadOnlyTipTap";
import EstimationFields from "components/EstimationFields/EstimationFields";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useAdminFeatureRequest } from "hooks/admin/useAdminFeatureRequest";
import { ConfirmDialog } from "./ConfirmDialog";

interface FeatureRequestCardProps {
  request: IFeatureRequest;
  linkedTickets: FreeloCard[];
  onCreateTicket: (request: IFeatureRequest) => void;
}

export const FeatureRequestCard: React.FC<FeatureRequestCardProps> = ({
  request,
  linkedTickets,
  onCreateTicket,
}) => {
  const { freelancers } = useAdminFreelancers();
  const { approveFeatureRequest, rejectFeatureRequest, updateFeatureRequest } =
    useAdminFeatureRequest(request.clientId);

  const [estimationValues, setEstimationValues] = useState({
    freetechEstimatedHours: request.freetechEstimatedHours,
    freetechEstimatedDollarAmount: request.freetechEstimatedDollarAmount,
    actualStartDate: request.actualStartDate,
    actualCompletionDate: request.actualCompletionDate,
  });
  const [selectedEngineerId, setSelectedEngineerId] = useState<string>(
    request.assignedToId || ""
  );

  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    type: "approve" | "in_progress" | "complete" | "cancel" | null;
    isLoading: boolean;
  }>({
    open: false,
    type: null,
    isLoading: false,
  });

  const handleEstimationUpdate = (field: string, value: any) => {
    const fieldMapping: Record<string, string> = {
      estimatedHours: "freetechEstimatedHours",
      estimatedDollarAmount: "freetechEstimatedDollarAmount",
      estimatedStartDate: "actualStartDate",
      estimatedCompletionDate: "actualCompletionDate",
    };

    setEstimationValues((prev) => ({
      ...prev,
      [fieldMapping[field] || field]: value,
    }));
  };

  const handleConfirmAction = async () => {
    setConfirmDialog((prev) => ({ ...prev, isLoading: true }));
    try {
      if (confirmDialog.type === "approve") {
        await approveFeatureRequest(
          {
            ...request,
            ...estimationValues,
          },
          selectedEngineerId
        );
      } else if (
        confirmDialog.type === "in_progress" ||
        confirmDialog.type === "complete"
      ) {
        await updateFeatureRequest.mutateAsync({
          id: request.id,
          data: {
            status:
              confirmDialog.type === "in_progress"
                ? "in_progress"
                : "completed",
          },
        });
      } else if (confirmDialog.type === "cancel") {
        await updateFeatureRequest.mutateAsync({
          id: request.id,
          data: {
            status: "cancelled",
          },
        });
      }
    } catch (error) {
      console.error("Error updating feature request:", error);
    } finally {
      setConfirmDialog({ open: false, type: null, isLoading: false });
    }
  };

  const handleReject = async () => {
    try {
      await rejectFeatureRequest(request);
    } catch (error) {
      console.error("Error rejecting feature request:", error);
    }
  };

  const requestDate = request.requestDate || request.createdAt;

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  mb: 1,
                }}
              >
                <Typography variant="h6">{request.title}</Typography>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    Requested on:{" "}
                    {requestDate
                      ? DateTime.fromISO(requestDate).toLocaleString(
                          DateTime.DATE_SHORT
                        )
                      : "Unknown"}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    Requested by: {request.requestedById || "Unknown"}
                  </Typography>
                </Box>
              </Box>

              <ReadOnlyTipTap content={request.description} />

              <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <Chip
                  label={request.priority}
                  color={
                    request.priority === "high"
                      ? "error"
                      : request.priority === "medium"
                        ? "warning"
                        : "info"
                  }
                  size="small"
                />
                <Chip
                  label={request.status}
                  color={
                    request.status === "approved"
                      ? "success"
                      : request.status === "rejected"
                        ? "error"
                        : "warning"
                  }
                  size="small"
                />
              </Stack>
            </Box>
          </Box>

          {request.status === "pending_approval" && (
            <>
              <Box sx={{ mt: 2, p: 2, bgcolor: "grey.50", borderRadius: 1 }}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Client Estimation Details
                </Typography>
                <Stack spacing={1}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Hours:
                    </Typography>
                    <Typography variant="body2">
                      {request.clientEstimatedHours
                        ? `${request.clientEstimatedHours} hours`
                        : "Not specified"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Budget:
                    </Typography>
                    <Typography variant="body2">
                      {request.clientDollarAmountBudget
                        ? `$${request.clientDollarAmountBudget.toLocaleString()}`
                        : "Not specified"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Desired Start:
                    </Typography>
                    <Typography variant="body2">
                      {request.desiredStartDate
                        ? DateTime.fromISO(
                            request.desiredStartDate
                          ).toLocaleString(DateTime.DATE_SHORT)
                        : "Not specified"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Desired Completion:
                    </Typography>
                    <Typography variant="body2">
                      {request.desiredCompletionDate
                        ? DateTime.fromISO(
                            request.desiredCompletionDate
                          ).toLocaleString(DateTime.DATE_SHORT)
                        : "Not specified"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <Box sx={{ mt: 2, p: 2, bgcolor: "primary.50", borderRadius: 1 }}>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  FreeTech Estimation Details
                </Typography>
                <EstimationFields
                  estimatedStartDate={estimationValues.actualStartDate}
                  estimatedCompletionDate={
                    estimationValues.actualCompletionDate
                  }
                  estimatedHours={estimationValues.freetechEstimatedHours}
                  estimatedDollarAmount={
                    estimationValues.freetechEstimatedDollarAmount
                  }
                  onUpdate={handleEstimationUpdate}
                  disabled={false}
                />
              </Box>

              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Assign Engineer
                </Typography>
                <FormControl fullWidth size="small">
                  <InputLabel>Select Engineer</InputLabel>
                  <Select
                    value={selectedEngineerId}
                    onChange={(e) => setSelectedEngineerId(e.target.value)}
                    label="Select Engineer"
                  >
                    {freelancers?.map((freelancer) => (
                      <MenuItem key={freelancer.id} value={freelancer.id}>
                        {`${freelancer.firstName} ${freelancer.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
                <Button variant="outlined" color="error" onClick={handleReject}>
                  Reject
                </Button>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() =>
                    setConfirmDialog({
                      open: true,
                      type: "cancel",
                      isLoading: false,
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setConfirmDialog({
                      open: true,
                      type: "approve",
                      isLoading: false,
                    })
                  }
                  disabled={
                    !selectedEngineerId ||
                    !estimationValues.freetechEstimatedHours ||
                    !estimationValues.freetechEstimatedDollarAmount
                  }
                >
                  Approve & Assign
                </Button>
              </Box>
            </>
          )}

          {(request.status === "approved" ||
            request.status === "in_progress") && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor:
                        request.status === "in_progress"
                          ? "warning.50"
                          : "success.50",
                      borderRadius: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={
                        request.status === "in_progress"
                          ? "warning.main"
                          : "success.main"
                      }
                      gutterBottom
                    >
                      FreeTech Estimation Details
                    </Typography>
                    <Stack spacing={1}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Hours:
                        </Typography>
                        <Typography variant="body2">
                          {request.freetechEstimatedHours} hours
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Cost:
                        </Typography>
                        <Typography variant="body2">
                          $
                          {request.freetechEstimatedDollarAmount?.toLocaleString()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Start:
                        </Typography>
                        <Typography variant="body2">
                          {request.actualStartDate
                            ? DateTime.fromISO(
                                request.actualStartDate
                              ).toLocaleString(DateTime.DATE_SHORT)
                            : "Not set"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Complete:
                        </Typography>
                        <Typography variant="body2">
                          {request.actualCompletionDate
                            ? DateTime.fromISO(
                                request.actualCompletionDate
                              ).toLocaleString(DateTime.DATE_SHORT)
                            : "Not set"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor:
                        request.status === "in_progress"
                          ? "warning.50"
                          : "primary.50",
                      borderRadius: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={
                        request.status === "in_progress"
                          ? "warning.main"
                          : "primary"
                      }
                      gutterBottom
                    >
                      Assigned Engineer
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                      <Box>
                        <Typography variant="body2">
                          {
                            freelancers?.find(
                              (f) => f.id === request.assignedToId
                            )?.firstName
                          }{" "}
                          {
                            freelancers?.find(
                              (f) => f.id === request.assignedToId
                            )?.lastName
                          }
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {freelancers?.find(
                            (f) => f.id === request.assignedToId
                          )?.role || "Engineer"}
                        </Typography>
                      </Box>
                    </Box>
                    {request.approvedByAdminUserId && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ display: "block" }}
                      >
                        Approved by {request.approvedByAdminUserId} on{" "}
                        {request.approvedByAdminDate
                          ? DateTime.fromISO(
                              request.approvedByAdminDate
                            ).toLocaleString(DateTime.DATE_SHORT)
                          : "Unknown"}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ p: 2, bgcolor: "grey.50", borderRadius: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        Freelo Tickets
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<AssignmentIcon />}
                        onClick={() => onCreateTicket(request)}
                      >
                        Create Ticket
                      </Button>
                    </Box>
                    {linkedTickets.length > 0 ? (
                      <Stack spacing={1}>
                        {linkedTickets.map((ticket) => (
                          <Box
                            key={ticket.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              p: 1,
                              bgcolor: "background.paper",
                              borderRadius: 1,
                              border: 1,
                              borderColor: "divider",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <LinkIcon color="action" fontSize="small" />
                              <Typography variant="body2">
                                {ticket.title}
                              </Typography>
                            </Box>
                            <Button
                              variant="text"
                              size="small"
                              onClick={() => {
                                window.open(
                                  `${window.location.origin}/freelo/${ticket.boardId}#${ticket.humanReadableId}`,
                                  "_blank"
                                );
                              }}
                            >
                              #{ticket.humanReadableId}
                            </Button>
                          </Box>
                        ))}
                      </Stack>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textAlign: "center", py: 1 }}
                      >
                        No Freelo tickets created yet
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() =>
                    setConfirmDialog({
                      open: true,
                      type: "cancel",
                      isLoading: false,
                    })
                  }
                >
                  Cancel
                </Button>
                {request.status === "approved" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      setConfirmDialog({
                        open: true,
                        type: "in_progress",
                        isLoading: false,
                      })
                    }
                  >
                    Mark as In Progress
                  </Button>
                )}
                {request.status === "in_progress" && (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      setConfirmDialog({
                        open: true,
                        type: "complete",
                        isLoading: false,
                      })
                    }
                  >
                    Mark as Complete
                  </Button>
                )}
              </Box>
            </>
          )}

          {request.status === "completed" && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "success.50",
                      borderRadius: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="success.main"
                      gutterBottom
                    >
                      FreeTech Estimation Details
                    </Typography>
                    <Stack spacing={1}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Hours:
                        </Typography>
                        <Typography variant="body2">
                          {request.freetechEstimatedHours} hours
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Cost:
                        </Typography>
                        <Typography variant="body2">
                          $
                          {request.freetechEstimatedDollarAmount?.toLocaleString()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Start:
                        </Typography>
                        <Typography variant="body2">
                          {request.actualStartDate
                            ? DateTime.fromISO(
                                request.actualStartDate
                              ).toLocaleString(DateTime.DATE_SHORT)
                            : "Not set"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Complete:
                        </Typography>
                        <Typography variant="body2">
                          {request.actualCompletionDate
                            ? DateTime.fromISO(
                                request.actualCompletionDate
                              ).toLocaleString(DateTime.DATE_SHORT)
                            : "Not set"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "success.50",
                      borderRadius: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="success.main"
                      gutterBottom
                    >
                      Project Timeline
                    </Typography>
                    <Stack spacing={1}>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Requested on
                        </Typography>
                        <Typography variant="body2">
                          {requestDate
                            ? DateTime.fromISO(requestDate).toLocaleString(
                                DateTime.DATE_FULL
                              )
                            : "Unknown"}
                        </Typography>
                      </Box>
                      {request.approvedByAdminUserId && (
                        <Box>
                          <Typography variant="caption" color="text.secondary">
                            Approved by
                          </Typography>
                          <Typography variant="body2">
                            {request.approvedByAdminUserId} on{" "}
                            {request.approvedByAdminDate
                              ? DateTime.fromISO(
                                  request.approvedByAdminDate
                                ).toLocaleString(DateTime.DATE_FULL)
                              : "Unknown"}
                          </Typography>
                        </Box>
                      )}
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Completed on
                        </Typography>
                        <Typography variant="body2">
                          {request.completedAt
                            ? DateTime.fromISO(
                                request.completedAt
                              ).toLocaleString(DateTime.DATE_FULL)
                            : DateTime.now().toLocaleString(DateTime.DATE_FULL)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "success.50",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="success.main"
                      gutterBottom
                    >
                      Assigned Engineer
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                      <Box>
                        <Typography variant="body2">
                          {
                            freelancers?.find(
                              (f) => f.id === request.assignedToId
                            )?.firstName
                          }{" "}
                          {
                            freelancers?.find(
                              (f) => f.id === request.assignedToId
                            )?.lastName
                          }
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {freelancers?.find(
                            (f) => f.id === request.assignedToId
                          )?.role || "Engineer"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "success.50",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="success.main"
                      gutterBottom
                    >
                      Client Details
                    </Typography>
                    <Stack spacing={1}>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Client Estimated Hours
                        </Typography>
                        <Typography variant="body2">
                          {request.clientEstimatedHours || "Not specified"}{" "}
                          hours
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Client Budget
                        </Typography>
                        <Typography variant="body2">
                          $
                          {request.clientDollarAmountBudget?.toLocaleString() ||
                            "Not specified"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Desired Start
                        </Typography>
                        <Typography variant="body2">
                          {request.desiredStartDate
                            ? DateTime.fromISO(
                                request.desiredStartDate
                              ).toLocaleString(DateTime.DATE_SHORT)
                            : "Not specified"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Desired Completion
                        </Typography>
                        <Typography variant="body2">
                          {request.desiredCompletionDate
                            ? DateTime.fromISO(
                                request.desiredCompletionDate
                              ).toLocaleString(DateTime.DATE_SHORT)
                            : "Not specified"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ p: 2, bgcolor: "success.50", borderRadius: 1 }}>
                    <Typography
                      variant="subtitle2"
                      color="success.main"
                      gutterBottom
                    >
                      Linked Freelo Tickets
                    </Typography>
                    {linkedTickets.length > 0 ? (
                      <Stack spacing={1}>
                        {linkedTickets.map((ticket) => (
                          <Box
                            key={ticket.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              p: 1,
                              bgcolor: "background.paper",
                              borderRadius: 1,
                              border: 1,
                              borderColor: "divider",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <LinkIcon color="action" fontSize="small" />
                              <Typography variant="body2">
                                {ticket.title}
                              </Typography>
                            </Box>
                            <Button
                              variant="text"
                              size="small"
                              onClick={() => {
                                window.open(
                                  `${window.location.origin}/freelo/${ticket.boardId}#${ticket.humanReadableId}`,
                                  "_blank"
                                );
                              }}
                            >
                              #{ticket.humanReadableId}
                            </Button>
                          </Box>
                        ))}
                      </Stack>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textAlign: "center", py: 1 }}
                      >
                        No Freelo tickets were created
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}

          {request.status === "rejected" && (
            <Box sx={{ mt: 2, p: 2, bgcolor: "error.50", borderRadius: 1 }}>
              <Typography variant="subtitle2" color="error" gutterBottom>
                Request Rejected
              </Typography>
              {request.rejectedByAdminUserId && (
                <Typography variant="body2" color="text.secondary">
                  Rejected by {request.rejectedByAdminUserId} on{" "}
                  {request.rejectedByAdminDate
                    ? DateTime.fromISO(
                        request.rejectedByAdminDate
                      ).toLocaleString(DateTime.DATE_FULL)
                    : "Unknown date"}
                </Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Card>

      <ConfirmDialog
        open={confirmDialog.open}
        onClose={() =>
          setConfirmDialog({ open: false, type: null, isLoading: false })
        }
        onConfirm={handleConfirmAction}
        title={
          confirmDialog.type === "approve"
            ? "Approve Feature Request"
            : confirmDialog.type === "in_progress"
              ? "Mark as In Progress"
              : confirmDialog.type === "complete"
                ? "Mark as Complete"
                : "Cancel Feature Request"
        }
        message={
          confirmDialog.type === "approve"
            ? `Are you sure you want to approve this feature request and assign it to ${
                freelancers?.find((f) => f.id === selectedEngineerId)?.firstName
              } ${
                freelancers?.find((f) => f.id === selectedEngineerId)?.lastName
              }?`
            : confirmDialog.type === "in_progress"
              ? "Are you sure you want to mark this feature request as in progress?"
              : confirmDialog.type === "complete"
                ? "Are you sure you want to mark this feature request as complete?"
                : "Are you sure you want to cancel this feature request? This action cannot be undone."
        }
        confirmText={
          confirmDialog.type === "approve"
            ? "Approve & Assign"
            : confirmDialog.type === "in_progress"
              ? "Mark as In Progress"
              : confirmDialog.type === "complete"
                ? "Mark as Complete"
                : "Cancel Request"
        }
        isLoading={confirmDialog.isLoading}
      />
    </>
  );
};
