import {
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { ReferralStatus } from "@freetech/models/referrals";

/**
 * Updates the status of a referral
 * @param referralId The ID of the referral to update
 * @param newStatus The new status to set
 * @param note Optional note about the status change
 * @returns Promise that resolves when the update is complete
 */
export const updateReferralStatus = async (
  referralId: string,
  newStatus: ReferralStatus,
  note?: string
): Promise<void> => {
  try {
    const referralRef = doc(db, "referrals", referralId);

    // Check if referral exists
    const referralDoc = await getDoc(referralRef);
    if (!referralDoc.exists()) {
      throw new Error(`Referral with ID ${referralId} not found`);
    }

    const now = new Date().toISOString();

    // Update the referral
    await updateDoc(referralRef, {
      status: newStatus,
      updatedAt: now,
      statusHistory: arrayUnion({
        status: newStatus,
        timestamp: now,
        note: note || `Status updated to ${newStatus}`,
      }),
    });
  } catch (error) {
    console.error("Error updating referral status:", error);
    throw error;
  }
};
