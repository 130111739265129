import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import ViewFileDialog from "components/ViewFileDialog";
import ProjectAssignmentsOverview from "./components/ProjectAssignmentsOverview";
import ProjectAssignmentCard from "../../../../components/Freelancer/ProjectAssignmentCard";
import ProjectAssignmentsHeader from "./components/ProjectAssignmentsHeader";
import { ProjectAssignment } from "@freetech/models/projects";
import { useFreelancerProjectAssignments } from "hooks/freelancer/useFreelancerProjectAssignments";
import { useFreelancerRoleClients } from "hooks/freelancer/useFreelancerRoleClients";

export const FreelancerProjAssExplorer: React.FC = () => {
  const [filteredAssignments, setFilteredAssignments] = useState<
    ProjectAssignment[]
  >([]);
  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState<string>("all");
  const [selectedStatus, setSelectedStatus] = useState<string>("all");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const { clients: freelancerClients, isLoading: isLoadingClients } =
    useFreelancerRoleClients();
  const {
    projectAssignments,
    isLoadingProjectAssignments,
    errorProjectAssignments,
    handleViewProjectAssignment,
  } = useFreelancerProjectAssignments();

  useEffect(() => {
    if (projectAssignments) {
      filterAssignments();
    }
  }, [selectedClient, selectedStatus, startDate, endDate, projectAssignments]);

  const filterAssignments = () => {
    let filtered = projectAssignments;

    if (!filtered) {
      return;
    }

    if (selectedClient !== "all") {
      filtered = filtered.filter((a) =>
        a.projectName?.startsWith(selectedClient)
      );
    }

    if (selectedStatus !== "all") {
      filtered = filtered.filter((a) => a.status === selectedStatus);
    }

    if (startDate) {
      filtered = filtered.filter(
        (a) => a.startDate && a.startDate >= startDate
      );
    }

    if (endDate) {
      filtered = filtered.filter((a) => a.endDate && a.endDate <= endDate);
    }

    setFilteredAssignments(filtered);
  };

  const handleViewFile = async (assignment: ProjectAssignment) => {
    const fileUrl = await handleViewProjectAssignment(
      assignment.clientId,
      assignment.id
    );
    console.log(fileUrl);
    if (fileUrl) {
      setSelectedFile({
        url: fileUrl,
        name: `${assignment.projectName} - Contract`,
      });
      setOpenFileDialog(true);
    }
  };

  const getStatusColor = (
    status: ProjectAssignment["status"]
  ):
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning" => {
    switch (status) {
      case "in_progress":
        return "success";
      case "complete":
        return "secondary";
      case "ready":
        return "warning";
      case "interviewing":
        return "info";
      default:
        return "default";
    }
  };

  if (isLoadingClients || isLoadingProjectAssignments) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (errorProjectAssignments) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography variant="h6">Error loading project assignments</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        {/* <ProjectAssignmentsOverview assignments={assignments} /> */}

        <ProjectAssignmentsHeader
          clients={freelancerClients}
          onClientChange={setSelectedClient}
          onStatusChange={setSelectedStatus}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />

        <Grid container spacing={3}>
          {filteredAssignments.map((assignment) => (
            <Grid item xs={12} sm={6} md={4} key={assignment.id}>
              <ProjectAssignmentCard
                assignment={assignment}
                onViewFile={handleViewFile}
                getStatusColor={getStatusColor}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedFile && (
        <ViewFileDialog
          open={openFileDialog}
          onClose={() => setOpenFileDialog(false)}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
        />
      )}
    </>
  );
};
