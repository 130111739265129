import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  Box, 
  Typography, 
  Autocomplete, 
  Chip, 
  Grid,
  Alert,
  Paper,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import { GroupOutlined as GroupIcon, Code as CodeIcon, MarkEmailRead as MarkEmailReadIcon } from '@mui/icons-material';
import { EmailTemplate, SendEmailRequest, ContactList } from 'types/admin/emailTemplate';
import { PublicUserInfoType } from '@freetech/models/user';
import { useAdminEmailManagement } from 'hooks/admin/useAdminEmailManagement';

// Helper function to extract variables from template content
const extractTemplateVariables = (template: EmailTemplate): string[] => {
  const variables = new Set<string>();
  
  // Helper to extract variables from a string using regex
  const extractFromString = (content: string | undefined) => {
    if (!content) return;
    
    // Match Handlebars variables {{variableName}} or {{{variableName}}}
    const matches = content.match(/{{2,3}([^{}]+)}{2,3}/g);
    if (matches) {
      matches.forEach(match => {
        // Clean up the variable name (remove {{, }}, whitespace)
        const varName = match.replace(/^{{2,3}|}{2,3}$/g, '').trim();
        // Skip helpers or partials (containing spaces or >)
        if (!varName.includes(' ') && !varName.includes('>')) {
          variables.add(varName);
        }
      });
    }
  };
  
  // Extract from all template fields that might contain variables
  extractFromString(template.subject);
  extractFromString(template.html);
  extractFromString(template.text);
  
  return Array.from(variables);
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`send-email-tabpanel-${index}`}
      aria-labelledby={`send-email-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface SendEmailDialogProps {
  open: boolean;
  onClose: () => void;
  template: EmailTemplate;
  contactLists: ContactList[];
  publicContacts: PublicUserInfoType[];
  onSend: (request: SendEmailRequest) => Promise<void>;
}

export const SendEmailDialog: React.FC<SendEmailDialogProps> = ({
  open,
  onClose,
  template,
  contactLists,
  publicContacts,
  onSend,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<PublicUserInfoType[]>([]);
  const [selectedLists, setSelectedLists] = useState<string[]>([]);
  const [scheduleEmail, setScheduleEmail] = useState(false);
  const [scheduledDate, setScheduledDate] = useState<DateTime>(DateTime.now());
  const [sending, setSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [variables, setVariables] = useState<Record<string, string>>({});
  const [detectedVariables, setDetectedVariables] = useState<string[]>([]);

  // Extract variables from template when it changes
  useEffect(() => {
    if (template) {
      console.log('Template in SendEmailDialog:', template);
      const vars = extractTemplateVariables(template);
      console.log('Detected variables:', vars);
      setDetectedVariables(vars);
      
      // Initialize variables object with empty values for all detected variables
      const initialVars: Record<string, string> = {};
      vars.forEach(varName => {
        initialVars[varName] = '';
      });
      setVariables(initialVars);
    }
  }, [template]);

  // Update selectedContacts when selectedUsers changes
  useEffect(() => {
    setSelectedContacts(selectedUsers.map(user => user.email));
  }, [selectedUsers]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleListToggle = (listId: string) => {
    setSelectedLists(prev => 
      prev.includes(listId) 
        ? prev.filter(id => id !== listId) 
        : [...prev, listId]
    );
  };

  const handleVariableChange = (varName: string, value: string) => {
    setVariables(prev => ({
      ...prev,
      [varName]: value
    }));
  };

  const handleSend = async () => {
    if (selectedContacts.length === 0 && selectedLists.length === 0) {
      setError('Please select at least one recipient');
      return;
    }

    setError(null);
    setSending(true);

    try {
      const scheduledTime = scheduleEmail && scheduledDate 
        ? scheduledDate.toISO() || undefined 
        : undefined;
        
      // Filter out empty variable values
      const nonEmptyVariables: Record<string, string> = {};
      Object.entries(variables).forEach(([key, value]) => {
        // Only include variables with actual values (not empty strings)
        if (value && value.trim()) {
          nonEmptyVariables[key] = value.trim();
        }
      });
      
      // Create request object with only valid fields
      const request: SendEmailRequest = {
        templateId: template.id || '',
        contacts: selectedContacts,
      };
      
      // Only add fields that have values
      if (selectedLists.length > 0) {
        request.contactListIds = selectedLists;
      }
      
      if (Object.keys(nonEmptyVariables).length > 0) {
        request.variables = nonEmptyVariables;
      }
      
      if (scheduledTime) {
        request.scheduledFor = scheduledTime;
      }
      
      await onSend(request);
      onClose();
    } catch (error) {
      setError(`Failed to send email: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setSending(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      aria-labelledby="send-email-dialog-title"
    >
      <DialogTitle id="send-email-dialog-title">
        Send Email: {template.nickname || template.name}
      </DialogTitle>

      <DialogContent dividers>
        {/* Tracking info */}
        <Alert 
          severity="info" 
          icon={<MarkEmailReadIcon />}
          sx={{ mb: 2 }}
        >
          This email will include tracking that lets you know when recipients have opened it. 
          You can view open status in the Mail Deliveries tab.
        </Alert>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            aria-label="send email tabs"
          >
            <Tab label="Recipients" icon={<GroupIcon />} />
            <Tab 
              label="Template Variables" 
              icon={<CodeIcon />} 
              disabled={detectedVariables.length === 0}
            />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Typography variant="subtitle1" gutterBottom>
            Select Recipients
          </Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>Individual Users</Typography>
              <Autocomplete
                multiple
                id="user-select"
                options={publicContacts}
                value={selectedUsers}
                onChange={(_, newValue) => setSelectedUsers(newValue)}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search users"
                    placeholder="Type to search"
                    fullWidth
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={`${option.firstName} ${option.lastName}`}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                }
                filterSelectedOptions
                openOnFocus
                sx={{ mb: 2 }}
              />
              {selectedUsers.length > 0 && (
                <Paper variant="outlined" sx={{ p: 1, maxHeight: 200, overflow: 'auto' }}>
                  <Typography variant="caption" color="text.secondary">
                    Selected users ({selectedUsers.length}):
                  </Typography>
                  <List dense>
                    {selectedUsers.map((user) => (
                      <ListItem key={user.email} sx={{ py: 0.5 }}>
                        <ListItemText 
                          primary={`${user.firstName} ${user.lastName}`} 
                          secondary={user.email} 
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Contact Lists</Typography>
              <List dense sx={{ height: 300, overflow: 'auto', bgcolor: 'background.paper' }}>
                {contactLists.length > 0 ? (
                  contactLists.map((list) => (
                    <ListItem key={list.id}>
                      <Checkbox
                        edge="start"
                        checked={selectedLists.includes(list.id || '')}
                        onChange={() => list.id && handleListToggle(list.id)}
                      />
                      <ListItemText 
                        primary={list.name} 
                        secondary={`${list.contacts.length} contacts`} 
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No contact lists available" />
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
          
          {(selectedContacts.length > 0 || selectedLists.length > 0) && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">
                Total recipients: {selectedContacts.length + (selectedLists.reduce((count, listId) => {
                  const list = contactLists.find(l => l.id === listId);
                  return count + (list?.contacts.length || 0);
                }, 0))}
              </Typography>
            </Box>
          )}
          
          <Box sx={{ mt: 3 }}>
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={scheduleEmail} 
                  onChange={(e) => setScheduleEmail(e.target.checked)} 
                />
              } 
              label="Schedule for later" 
            />
            
            {scheduleEmail && (
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  label="Schedule Date & Time"
                  value={scheduledDate}
                  onChange={(newValue) => newValue && setScheduledDate(newValue)}
                  disablePast
                  sx={{ mt: 2, width: 300 }}
                />
              </LocalizationProvider>
            )}
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Typography variant="subtitle1" gutterBottom>
            Template Variables
          </Typography>
          
          {detectedVariables.length > 0 ? (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                The following variables were detected in this template. Provide values for them:
              </Typography>
              
              {detectedVariables.map((varName) => (
                <Box key={varName} sx={{ mb: 2 }}>
                  <TextField
                    label={varName}
                    fullWidth
                    value={variables[varName] || ''}
                    onChange={(e) => handleVariableChange(varName, e.target.value)}
                    helperText={`This will replace {{${varName}}} in the template`}
                  />
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="body1">
              No variables detected in this template.
            </Typography>
          )}
        </TabPanel>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        {error && (
          <Alert severity="error" sx={{ mr: 'auto' }}>
            {error}
          </Alert>
        )}
        <Button onClick={onClose} disabled={sending}>
          Cancel
        </Button>
        <Button 
          onClick={handleSend} 
          variant="contained" 
          disabled={sending || (selectedContacts.length === 0 && selectedLists.length === 0)}
          startIcon={sending ? <CircularProgress size={20} /> : undefined}
        >
          {sending ? 'Sending...' : 'Send Email'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendEmailDialog; 