import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useTimesheet } from "hooks/timesheet/useTimesheet";
import { useAuth } from "hooks/auth/useAuth";
import { DateTime } from "luxon";
import TimeTrackerInput from "./components/TimeTrackerInput";
import { adjustEndTime } from "core/timesheet/utils";
import TimeTrackerGrid from "./components/TimeTrackerGrid";
import { useAdminClients } from "hooks/admin/useAdminClients";
import {
  groupEntriesByWeek,
  getUniqueClients,
  getFilteredProjects,
  getFilteredAssignments,
  getPaginatedEntries,
  getSortedEntries,
  getPaginationInfo,
} from "core/timesheet/utils/filterTimesheets";
import { Timesheet } from "@freetech/models/timesheet";
import { AdminTimeTrackerInput } from "./components/AdminTimeTrackerInput";
import AdminTimeTrackerGrid from "./components/AdminTimeTrackerGrid";

const TimeTracker: React.FC = () => {
  const [entries, setEntries] = useState<Timesheet[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const { userInfo } = useAuth();

  if (!userInfo?.id) {
    throw new Error("User must be logged in to use time tracker");
  }

  const {
    timesheetsQuery,
    clientsProjectsAndAssignmentsQuery,
    deleteTimesheet,
    updateTimesheet,
    createTimesheet,
    timesheet,
    startTimer,
    stopTimer,
    timerValue,
    isTimerActive,
    isSaving,
  } = useTimesheet(userInfo.id);

  const {
    data: clientsProjectsAndAssignments,
    isLoading: isClientsProjectsAndAssignmentsLoading,
    error: clientsProjectsAndAssignmentsError,
  } = clientsProjectsAndAssignmentsQuery;

  const {
    data: timesheets,
    isLoading: isTimesheetsLoading,
    refetch: refetchTimesheets,
  } = timesheetsQuery;

  const activeProjectAssignments =
    clientsProjectsAndAssignments?.projectAssignments || [];

  useEffect(() => {
    setEntries(timesheets || []);
  }, [timesheets]);

  const handleDeleteEntry = async (id: string) => {
    await deleteTimesheet.mutateAsync(id);
    refetchTimesheets();
  };

  const handleProcessRowUpdate = useCallback(
    async (newRow: Timesheet, oldRow: Timesheet) => {
      try {
        const date = DateTime.fromISO(newRow.date);
        const startTime = DateTime.fromISO(newRow.startTime);
        let endTime = DateTime.fromISO(newRow.endTime);

        if (!date.isValid || !startTime.isValid || !endTime.isValid) {
          console.error("Invalid date or time values:", {
            date,
            startTime,
            endTime,
          });
          throw new Error("Invalid date or time values");
        }

        if (newRow.duration !== oldRow.duration) {
          endTime = DateTime.fromISO(
            adjustEndTime(startTime.toISO() || "", newRow.duration)
          );
        }

        const updatedRow: Timesheet = {
          ...newRow,
          date: date.toISODate() || "",
          startTime: startTime.toISO() || "",
          endTime: endTime.toISO() || "",
          duration: newRow.duration,
          longDescription: newRow.longDescription || oldRow.longDescription,
          projectAssignmentId:
            newRow.projectAssignmentId || oldRow.projectAssignmentId || null,
          clientName:
            newRow.clientName || oldRow.clientName || "",
          projectName:
            newRow.projectName || oldRow.projectName || "",
        };

        setEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.id === updatedRow.id ? updatedRow : entry
          )
        );

        await updateTimesheet.mutateAsync({
          ...updatedRow,
          longDescription: updatedRow.longDescription,
        });

        await refetchTimesheets();

        return updatedRow;
      } catch (error) {
        console.error("Failed to update timesheet:", error);
        setEntries((prevEntries) =>
          prevEntries.map((entry) => (entry.id === newRow.id ? oldRow : entry))
        );
        throw error;
      }
    },
    [updateTimesheet, refetchTimesheets]
  );

  // const clients = useMemo(
  //   () => getUniqueClients(activeProjectAssignments),
  //   [activeProjectAssignments]
  // );

  // const filteredProjects = useMemo(
  //   () => getFilteredProjects(activeProjectAssignments, selectedClient),
  //   [activeProjectAssignments, selectedClient]
  // );

  // const filteredAssignments = useMemo(
  //   () =>
  //     getFilteredAssignments(
  //       activeProjectAssignments,
  //       selectedClient,
  //       selectedProject
  //     ),
  //   [activeProjectAssignments, selectedClient, selectedProject]
  // );

  const groupedEntriesByWeek = useMemo(
    () => groupEntriesByWeek(entries),
    [entries]
  );

  const paginatedEntries = useMemo(
    () => getPaginatedEntries(entries, currentPage, itemsPerPage),
    [entries, currentPage, itemsPerPage]
  );

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    const newItemsPerPage = event.target.value as number;
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    setEntries(getSortedEntries(entries, newItemsPerPage));
  };

  const { totalPages, totalItems, startItem, endItem } = useMemo(
    () => getPaginationInfo(entries, currentPage, itemsPerPage),
    [entries, currentPage, itemsPerPage]
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  if (isTimesheetsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (clientsProjectsAndAssignmentsError) {
    return (
      <Typography color="error">
        Error loading data. Please try again.
      </Typography>
    );
  }

  const renderTimeTrackerInput = () => {
    if (userInfo?.role === "admin" || userInfo?.role === "executiveAssistant") {
      return <AdminTimeTrackerInput />;
    }
    return (
      <TimeTrackerInput
        activeProjectAssignments={activeProjectAssignments}
        selectedClient={selectedClient}
        selectedProject={selectedProject}
        setSelectedClient={setSelectedClient}
        setSelectedProject={setSelectedProject}
        isClientsProjectsAndAssignmentsLoading={
          isClientsProjectsAndAssignmentsLoading
        }
        timesheet={timesheet}
        createTimesheet={createTimesheet}
        startTimer={startTimer}
        stopTimer={stopTimer}
        timerValue={timerValue}
        isTimerActive={isTimerActive}
        isSaving={isSaving}
        updateTimesheet={updateTimesheet}
        refetchTimesheets={refetchTimesheets}
      />
    );
  };

  return (
    <Box sx={{ height: "100%", width: "100%", p: 2 }}>
      {renderTimeTrackerInput()}
      {entries.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            backgroundColor: "background.paper",
            borderRadius: 1,
            mt: 2,
            gap: 1,
          }}
        >
          <Typography variant="h6" color="text.secondary">
            No timesheet entries found, start logging time to get started!
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ fontStyle: "italic" }}
          >
            If you're having any issues, please contact{" "}
            <span style={{ fontWeight: "bold" }}>admin@freetech.co</span>.
          </Typography>
        </Box>
      ) : (
        <>
          {Object.entries(groupedEntriesByWeek).map(
            ([weekKey, weekEntries]) => (
              <Box key={weekKey} sx={{ position: "relative", mb: 4 }}>
                {userInfo?.role === "admin" ? (
                  <AdminTimeTrackerGrid
                    weekEntries={weekEntries}
                    weekKey={weekKey}
                    paginatedEntries={paginatedEntries}
                    handleProcessRowUpdate={handleProcessRowUpdate}
                    activeProjectAssignments={activeProjectAssignments}
                    handleDeleteEntry={handleDeleteEntry}
                  />
                ) : (
                  <TimeTrackerGrid
                    weekEntries={weekEntries}
                    weekKey={weekKey}
                    paginatedEntries={paginatedEntries}
                    handleProcessRowUpdate={handleProcessRowUpdate}
                    activeProjectAssignments={activeProjectAssignments}
                    handleDeleteEntry={handleDeleteEntry}
                  />
                )}
              </Box>
            )
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
              <Typography variant="body2" sx={{ ml: 2 }}>
                {`${startItem}-${endItem} of ${totalItems} items`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ mr: 2 }}>
                Items per page:
              </Typography>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TimeTracker;
