import {
  Home as HomeIcon,
  School as SchoolIcon,
  MenuBook as MenuBookIcon,
  Assignment as AssignmentIcon,
  People as CommunityIcon,
  AccountCircle as ProfileIcon,
  ViewKanban as ViewKanbanIcon,
  VolunteerActivism as VolunteerActivismIcon,
  CreditCard as CreditCardIcon,
} from "@mui/icons-material";
import { Navigation } from "types/navigation";
import { FreeloHome } from "views/FreeloHome";
import { FreeloBoard } from "views/FreeloBoard";
import { StudentHome } from "views/Student/StudentHome";
import { StudentMentorship } from "views/Student/StudentMentorship";
import { StudentManageSubscription } from "views/Student/ManageSubscription";
import { Navigate } from "react-router-dom";

export const studentPages: Navigation = [
  {
    kind: "page",
    title: "Home",
    segment: "student",
    icon: <HomeIcon />,
    component: <StudentHome />,
  },
  {
    kind: "page",
    title: "Mentorship",
    segment: "student/mentorship",
    icon: <VolunteerActivismIcon />,
    component: <StudentMentorship />,
  },
  {
    kind: "page",
    title: "Freelo",
    segment: "freelo",
    icon: <ViewKanbanIcon />,
    component: <FreeloHome />,
    slugBabyPattern: ":boardId",
    slugBabyComponent: <FreeloBoard />,
  },
  // {
  //   kind: "page",
  //   title: "My Courses",
  //   segment: "student/courses",
  //   icon: <SchoolIcon />,
  //   component: <div>My Courses - Coming Soon</div>,
  // },
  // {
  //   kind: "page",
  //   title: "Learning Materials",
  //   segment: "student/materials",
  //   icon: <MenuBookIcon />,
  //   component: <div>Learning Materials - Coming Soon</div>,
  // },
  // {
  //   kind: "page",
  //   title: "Assignments",
  //   segment: "student/assignments",
  //   icon: <AssignmentIcon />,
  //   component: <div>Assignments - Coming Soon</div>,
  // },

  // {
  //   kind: "page",
  //   title: "Community",
  //   segment: "student/community",
  //   icon: <CommunityIcon />,
  //   component: <div>Community - Coming Soon</div>,
  // },
  // {
  //   kind: "page",
  //   title: "My Profile",
  //   segment: "student/profile",
  //   icon: <ProfileIcon />,
  //   component: <div>Student Profile - Coming Soon</div>,
  // },
];
