import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Button,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Alert,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';
import {
  Person as PersonIcon,
  Group as GroupIcon,
  Schedule as ScheduleIcon,
  Link as LinkIcon,
  Notifications as NotificationsIcon,
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  School as SchoolIcon,
  DateRange as DateRangeIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Chat as SlackIcon,
} from '@mui/icons-material';
import { useManageMyStudents } from 'hooks/mentor/useManageMyStudents';
import { DateTime } from 'luxon';
import { DAYS_OF_WEEK } from 'utils/constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mentor-tabpanel-${index}`}
      aria-labelledby={`mentor-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `mentor-tab-${index}`,
    'aria-controls': `mentor-tabpanel-${index}`,
  };
};

const ManageMyStudents: React.FC = () => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  
  // Dialog states
  const [updateCohortDialogOpen, setUpdateCohortDialogOpen] = useState(false);
  const [selectedCohortId, setSelectedCohortId] = useState<string>('');
  const [weeklyMeetingDay, setWeeklyMeetingDay] = useState<string>('');
  const [weeklyMeetingTime, setWeeklyMeetingTime] = useState<string>('');
  const [weeklyMeetingDuration, setWeeklyMeetingDuration] = useState<number>(60);
  const [weeklyMeetingLink, setWeeklyMeetingLink] = useState<string>('');
  const [slackChannel, setSlackChannel] = useState<string>('');
  const [freeloBoardId, setFreeloBoardId] = useState<string>('');
  
  const [assistanceDialogOpen, setAssistanceDialogOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState<string>('');
  const [assistanceResponse, setAssistanceResponse] = useState<string>('');
  const [resolveAssistance, setResolveAssistance] = useState<boolean>(true);
  
  const {
    mentorCohorts,
    managedStudents,
    freeloBoards,
    isLoading,
    studentsRequestingAssistance,
    updateCohortDetails,
    respondToAssistanceRequest,
    isUpdatingCohort,
    isRespondingToAssistance
  } = useManageMyStudents();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  const handleOpenUpdateCohortDialog = (cohortId: string) => {
    const cohort = mentorCohorts.find(c => c.id === cohortId);
    if (cohort) {
      setSelectedCohortId(cohortId);
      setWeeklyMeetingDay(cohort.weeklyMeetingDay || '');
      setWeeklyMeetingTime(cohort.weeklyMeetingTime || '');
      setWeeklyMeetingDuration(cohort.weeklyMeetingDuration || 60);
      setWeeklyMeetingLink(cohort.weeklyMeetingLink || '');
      setSlackChannel(cohort.slackChannel || '');
      setFreeloBoardId(cohort.freeloBoardId || '');
      setUpdateCohortDialogOpen(true);
    }
  };
  
  const handleCloseUpdateCohortDialog = () => {
    setUpdateCohortDialogOpen(false);
  };
  
  const handleUpdateCohort = async () => {
    try {
      await updateCohortDetails({
        cohortId: selectedCohortId,
        weeklyMeetingDay,
        weeklyMeetingTime,
        weeklyMeetingDuration,
        weeklyMeetingLink,
        slackChannel,
        freeloBoardId,
      });
      handleCloseUpdateCohortDialog();
    } catch (error) {
      console.error('Failed to update cohort:', error);
    }
  };
  
  const handleOpenAssistanceDialog = (studentId: string) => {
    const student = managedStudents.find(s => s.id === studentId);
    if (student) {
      setSelectedStudentId(studentId);
      setAssistanceResponse('');
      setResolveAssistance(true);
      setAssistanceDialogOpen(true);
    }
  };
  
  const handleCloseAssistanceDialog = () => {
    setAssistanceDialogOpen(false);
  };
  
  const handleRespondToAssistance = async () => {
    try {
      await respondToAssistanceRequest({
        studentId: selectedStudentId,
        response: assistanceResponse,
        resolved: resolveAssistance,
      });
      handleCloseAssistanceDialog();
    } catch (error) {
      console.error('Failed to respond to assistance request:', error);
    }
  };
  
  // Format time string for display
  const formatTime = (timeString?: string) => {
    if (!timeString) return 'Not set';
    try {
      const [hours, minutes] = timeString.split(':');
      return DateTime.now()
        .set({ hour: parseInt(hours, 10), minute: parseInt(minutes, 10) })
        .toFormat('h:mm a');
    } catch (e) {
      return timeString;
    }
  };
  
  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return 'Not set';
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED);
  };
  
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Manage My Students
      </Typography>
      
      {studentsRequestingAssistance.length > 0 && (
        <Alert 
          severity="info" 
          sx={{ mb: 3 }}
          action={
            <Button 
              color="inherit" 
              size="small" 
              onClick={() => setTabValue(2)}
            >
              View Requests
            </Button>
          }
        >
          {studentsRequestingAssistance.length} student{studentsRequestingAssistance.length > 1 ? 's' : ''} requesting assistance
        </Alert>
      )}
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="mentor tabs"
        >
          <Tab 
            label="My Cohorts" 
            icon={<GroupIcon />} 
            iconPosition="start" 
            {...a11yProps(0)} 
          />
          <Tab 
            label="My Students" 
            icon={<PersonIcon />} 
            iconPosition="start" 
            {...a11yProps(1)} 
          />
          <Tab 
            label={
              <Badge 
                color="error" 
                badgeContent={studentsRequestingAssistance.length} 
                showZero={false}
              >
                Assistance Requests
              </Badge>
            }
            icon={<NotificationsIcon />} 
            iconPosition="start" 
            {...a11yProps(2)} 
          />
        </Tabs>
      </Box>
      
      <TabPanel value={tabValue} index={0}>
        <Typography variant="h6" gutterBottom>
          Cohorts You Mentor
        </Typography>
        
        {mentorCohorts.length === 0 ? (
          <Alert severity="info">
            You are not currently mentoring any cohorts.
          </Alert>
        ) : (
          <Grid container spacing={3}>
            {mentorCohorts.map((cohort) => {
              const cohortStudents = managedStudents.filter(s => s.cohortId === cohort.id);
              
              return (
                <Grid item xs={12} md={6} lg={4} key={cohort.id}>
                  <Card>
                    <CardHeader
                      title={cohort.name}
                      subheader={`${cohortStudents.length} Students`}
                      action={
                        <IconButton 
                          aria-label="edit cohort" 
                          onClick={() => handleOpenUpdateCohortDialog(cohort.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <Box sx={{ mb: 2 }}>
                        {cohort.description && (
                          <Typography variant="body2" color="text.secondary" paragraph>
                            {cohort.description}
                          </Typography>
                        )}
                        
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <DateRangeIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
                              <Typography variant="body2">
                                {cohort.startDate
                                  ? `Started: ${formatDate(cohort.startDate)}`
                                  : 'Start date not set'}
                                {cohort.endDate && ` • Ends: ${formatDate(cohort.endDate)}`}
                              </Typography>
                            </Box>
                          </Grid>
                          
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <ScheduleIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
                              <Typography variant="body2">
                                {cohort.weeklyMeetingDay && cohort.weeklyMeetingTime
                                  ? `Weekly meeting: ${cohort.weeklyMeetingDay}s at ${formatTime(cohort.weeklyMeetingTime)}`
                                  : 'Weekly meeting not scheduled'}
                                {cohort.weeklyMeetingDuration && ` (${cohort.weeklyMeetingDuration} min)`}
                              </Typography>
                            </Box>
                          </Grid>
                          
                          {cohort.weeklyMeetingLink && (
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <LinkIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
                                <Typography variant="body2">
                                  <a 
                                    href={cohort.weeklyMeetingLink} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                  >
                                    Meeting Link
                                  </a>
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                          
                          {cohort.slackChannel && (
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <SlackIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
                                <Typography variant="body2">
                                  Slack: #{cohort.slackChannel}
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                          
                          {cohort.freeloBoardId && (
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <SchoolIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
                                <Typography variant="body2">
                                  Freelo board: {
                                    freeloBoards.find(b => b.id === cohort.freeloBoardId)?.name ||
                                    cohort.freeloBoardId
                                  }
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      
                      <Divider sx={{ mb: 2 }} />
                      
                      <Typography variant="subtitle2" gutterBottom>
                        Students:
                      </Typography>
                      
                      {cohortStudents.length === 0 ? (
                        <Typography variant="body2" color="text.secondary">
                          No students assigned to this cohort
                        </Typography>
                      ) : (
                        <List dense>
                          {cohortStudents.map((student) => (
                            <ListItem 
                              key={student.id}
                              secondaryAction={
                                student.pendingMentorAssistance ? (
                                  <Chip 
                                    color="error"
                                    size="small"
                                    label="Needs Help"
                                    onClick={() => handleOpenAssistanceDialog(student.id)}
                                  />
                                ) : null
                              }
                            >
                              <ListItemAvatar>
                                <Avatar 
                                  src={student.profilePicture} 
                                  alt={student.displayName}
                                >
                                  {student.firstName?.[0] || ''}{student.lastName?.[0] || ''}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText 
                                primary={student.displayName} 
                                secondary={student.email}
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                      
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={() => handleOpenUpdateCohortDialog(cohort.id)}
                      >
                        Update Cohort Details
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </TabPanel>
      
      <TabPanel value={tabValue} index={1}>
        <Typography variant="h6" gutterBottom>
          Students You Mentor
        </Typography>
        
        {managedStudents.length === 0 ? (
          <Alert severity="info">
            You are not currently mentoring any students.
          </Alert>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Cohort</TableCell>
                  <TableCell>Assistance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {managedStudents.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar 
                          src={student.profilePicture} 
                          alt={student.displayName}
                          sx={{ mr: 2, width: 32, height: 32 }}
                        >
                          {student.firstName?.[0] || ''}{student.lastName?.[0] || ''}
                        </Avatar>
                        <Typography variant="body2">
                          {student.displayName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>
                      <Chip
                        label={student.status || 'active'}
                        color={
                          student.status === 'active'
                            ? 'success'
                            : student.status === 'ready'
                            ? 'primary'
                            : 'default'
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {student.cohortName || 'Not assigned'}
                    </TableCell>
                    <TableCell>
                      {student.pendingMentorAssistance ? (
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          startIcon={<NotificationsIcon />}
                          onClick={() => handleOpenAssistanceDialog(student.id)}
                        >
                          Needs Help
                        </Button>
                      ) : (
                        <Chip
                          icon={<CheckCircleIcon />}
                          label="No requests"
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>
      
      <TabPanel value={tabValue} index={2}>
        <Typography variant="h6" gutterBottom>
          Assistance Requests
        </Typography>
        
        {studentsRequestingAssistance.length === 0 ? (
          <Alert severity="success" icon={<CheckCircleIcon />}>
            No pending assistance requests.
          </Alert>
        ) : (
          <Grid container spacing={3}>
            {studentsRequestingAssistance.map((student) => (
              <Grid item xs={12} md={6} key={student.id}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar 
                        src={student.profilePicture} 
                        alt={student.displayName}
                      >
                        {student.firstName?.[0] || ''}{student.lastName?.[0] || ''}
                      </Avatar>
                    }
                    title={student.displayName}
                    subheader={
                      <>
                        {student.email}
                        <br />
                        {student.cohortName ? `Cohort: ${student.cohortName}` : 'No cohort assigned'}
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                      Assistance Requested:
                    </Typography>
                    <Typography variant="body2" paragraph>
                      {student.mentorAssistanceRequestedAt && (
                        <Box component="span" sx={{ color: 'text.secondary', mr: 1 }}>
                          <HourglassEmptyIcon 
                            fontSize="small" 
                            sx={{ verticalAlign: 'middle', mr: 0.5 }} 
                          />
                          {formatDate(student.mentorAssistanceRequestedAt)}:
                        </Box>
                      )}
                      {student.mentorAssistanceMessage || 'No details provided'}
                    </Typography>
                    
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenAssistanceDialog(student.id)}
                    >
                      Respond to Request
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </TabPanel>
      
      {/* Update Cohort Dialog */}
      <Dialog
        open={updateCohortDialogOpen}
        onClose={handleCloseUpdateCohortDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Update Cohort Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Meeting Day</InputLabel>
                <Select
                  value={weeklyMeetingDay}
                  label="Meeting Day"
                  onChange={(e: SelectChangeEvent) => setWeeklyMeetingDay(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {DAYS_OF_WEEK.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Meeting Time"
                type="time"
                fullWidth
                value={weeklyMeetingTime}
                onChange={(e) => setWeeklyMeetingTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Duration (minutes)</InputLabel>
                <Select
                  value={weeklyMeetingDuration.toString()}
                  label="Duration (minutes)"
                  onChange={(e) => setWeeklyMeetingDuration(Number(e.target.value))}
                >
                  <MenuItem value="30">30 minutes</MenuItem>
                  <MenuItem value="45">45 minutes</MenuItem>
                  <MenuItem value="60">60 minutes</MenuItem>
                  <MenuItem value="90">90 minutes</MenuItem>
                  <MenuItem value="120">120 minutes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Meeting Link"
                fullWidth
                placeholder="https://zoom.us/..."
                value={weeklyMeetingLink}
                onChange={(e) => setWeeklyMeetingLink(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Slack Channel"
                fullWidth
                placeholder="cohort-123"
                value={slackChannel}
                onChange={(e) => setSlackChannel(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Freelo Board</InputLabel>
                <Select
                  value={freeloBoardId}
                  label="Freelo Board"
                  onChange={(e) => setFreeloBoardId(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {freeloBoards.map((board) => (
                    <MenuItem key={board.id} value={board.id}>
                      {board.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateCohortDialog}>
            Cancel
          </Button>
          <Button 
            onClick={handleUpdateCohort} 
            variant="contained"
            disabled={isUpdatingCohort}
          >
            {isUpdatingCohort ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Respond to Assistance Dialog */}
      <Dialog
        open={assistanceDialogOpen}
        onClose={handleCloseAssistanceDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Respond to Assistance Request</DialogTitle>
        <DialogContent>
          {selectedStudentId && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Request from {managedStudents.find(s => s.id === selectedStudentId)?.displayName}:
              </Typography>
              <Paper 
                variant="outlined" 
                sx={{ p: 2, mb: 3, bgcolor: 'background.default' }}
              >
                <Typography variant="body2">
                  {managedStudents.find(s => s.id === selectedStudentId)?.mentorAssistanceMessage || 'No details provided'}
                </Typography>
              </Paper>
              
              <TextField
                label="Your Response"
                multiline
                rows={4}
                fullWidth
                value={assistanceResponse}
                onChange={(e) => setAssistanceResponse(e.target.value)}
                placeholder="Provide guidance or set up a time to meet..."
                sx={{ mb: 2 }}
              />
              
              <FormControl component="fieldset">
                <Typography variant="subtitle2" gutterBottom>
                  Resolution:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant={resolveAssistance ? "contained" : "outlined"}
                      onClick={() => setResolveAssistance(true)}
                      startIcon={<CheckCircleIcon />}
                    >
                      Mark as Resolved
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={!resolveAssistance ? "contained" : "outlined"}
                      onClick={() => setResolveAssistance(false)}
                      color="secondary"
                    >
                      Keep Open
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssistanceDialog}>
            Cancel
          </Button>
          <Button 
            onClick={handleRespondToAssistance} 
            variant="contained"
            disabled={!assistanceResponse.trim() || isRespondingToAssistance}
          >
            {isRespondingToAssistance ? <CircularProgress size={24} /> : 'Send Response'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageMyStudents; 