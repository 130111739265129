import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Chip,
  Avatar,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import ArticleIcon from "@mui/icons-material/Article";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import { Freelancer } from "@freetech/models";
import { useAuth } from "hooks/auth/useAuth";
import { PaymentDialog } from "./components/PaymentDialog";

export const AdminPayroll = () => {
  const [filterStatus, setFilterStatus] = useState<"active" | "inactive">(
    "active"
  );
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState<Freelancer | null>(null);
  const { freelancers: contractors, isLoading } = useAdminFreelancers();
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  const handleContractorClick = (contractorId: string) => {
    navigate(`/${userInfo?.role}/payroll/${contractorId}`);
  };

  const handlePaymentClick = (e: React.MouseEvent, contractor: Freelancer) => {
    e.stopPropagation();
    setSelectedContractor(contractor);
    setPaymentDialogOpen(true);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
    setSelectedContractor(null);
  };

  const filteredContractors = contractors?.filter(
    (contractor: Freelancer) => contractor.status === filterStatus
  );

  const getVerificationStatus = (contractor: Freelancer) => {
    if (!contractor.taxFormCompleted) {
      return {
        w9Status: "missing",
        bankStatus: "missing",
        icon: <ErrorIcon color="error" />,
        text: "Missing Documents",
      };
    }

    if (!contractor.payrollProfileCompleted) {
      return {
        w9Status: "completed",
        bankStatus: "missing",
        icon: <PendingIcon color="warning" />,
        text: "Bank Info Required",
      };
    }

    // if (!contractor.increasePrenotificationVerified) {
    //   return {
    //     w9Status: "completed",
    //     bankStatus: "pending",
    //     icon: <PendingIcon color="warning" />,
    //     text: "Bank Verification Pending",
    //   };
    // }

    return {
      w9Status: "completed",
      bankStatus: "verified",
      icon: <CheckCircleIcon color="success" />,
      text: "Ready for Payment",
    };
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 3 }}>
        <TextField
          select
          label="Find a contractor"
          variant="outlined"
          size="small"
          sx={{ minWidth: 200 }}
        >
          {contractors?.map((contractor: Freelancer) => (
            <MenuItem
              key={contractor.id}
              value={contractor.id}
              onClick={() => handleContractorClick(contractor.id)}
            >
              {contractor.firstName} {contractor.lastName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          value={filterStatus}
          onChange={(e) =>
            setFilterStatus(e.target.value as "active" | "inactive")
          }
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </TextField>

        {/* <Box sx={{ display: "flex", gap: 2, ml: { xs: 0, md: "auto" } }}>
          <Button variant="outlined" color="primary">
            View 1099 filings
          </Button>
          <Button variant="outlined" color="primary">
            Prepare 1099s
          </Button>
          <Button variant="outlined" color="primary">
            Add a contractor
          </Button>
          <Button variant="contained" color="primary">
            Pay contractors
          </Button>
        </Box> */}
      </Box>

      {/* Contractors List */}
      {!isLoading &&
        filteredContractors?.map((contractor: Freelancer) => {
          const status = getVerificationStatus(contractor);
          return (
            <Box
              key={contractor.id}
              onClick={() => handleContractorClick(contractor.id)}
              sx={{
                bgcolor: "background.paper",
                borderRadius: 1,
                boxShadow: 1,
                p: 2,
                mb: 2,
                cursor: "pointer",
                "&:hover": {
                  boxShadow: 2,
                  bgcolor: "action.hover",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar
                    src={contractor.profilePicture}
                    alt={`${contractor.firstName} ${contractor.lastName}`}
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor: "primary.main",
                    }}
                  >
                    {!contractor.profilePicture && <PersonIcon />}
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1">
                      {contractor.firstName} {contractor.lastName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {contractor.email}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: contractor.disabled
                          ? "text.disabled"
                          : "success.main",
                      }}
                    >
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          bgcolor: contractor.disabled
                            ? "text.disabled"
                            : "success.main",
                          mr: 1,
                        }}
                      />
                      <Typography variant="body2">
                        {contractor.disabled ? "Inactive" : "Active"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Chip
                        icon={<ArticleIcon />}
                        label="W9"
                        color={
                          status.w9Status === "completed" ? "success" : "error"
                        }
                        size="small"
                        variant="outlined"
                      />
                      <Chip
                        icon={<AccountBalanceIcon />}
                        label="Bank"
                        color={
                          status.bankStatus === "verified"
                            ? "success"
                            : status.bankStatus === "pending"
                              ? "warning"
                              : "error"
                        }
                        size="small"
                        variant="outlined"
                      />
                      <Chip
                        icon={status.icon}
                        label={status.text}
                        color={
                          status.bankStatus === "verified"
                            ? "success"
                            : status.bankStatus === "pending"
                              ? "warning"
                              : "error"
                        }
                        size="small"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => handlePaymentClick(e, contractor)}
                    disabled={status.bankStatus !== "verified"}
                  >
                    Make Payment
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })}

      {/* Payment Dialog */}
      {selectedContractor && (
        <PaymentDialog
          open={paymentDialogOpen}
          onClose={handleClosePaymentDialog}
          contractor={selectedContractor}
          contractorId={selectedContractor.id}
        />
      )}
    </Box>
  );
};
