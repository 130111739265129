import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Chip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { useAdminBlogs } from "hooks/blog/useAdminBlogs";
import {
  BlogState,
  BlogListItem,
  BlogPostCreate,
} from "@freetech/models/blog";
import { BlogCategory } from "@freetech/models/blog";
import { useAuth } from "hooks/auth";
import { useProfilePicture } from "hooks/auth/useProfilePicture";

const BLOG_CATEGORIES = Object.values(BlogCategory);

const BlogStateChip = ({ state }: { state: BlogState }) => {
  const getChipProps = (state: BlogState) => {
    switch (state) {
      case "draft":
        return { color: "default" as const };
      case "pending_approval":
        return { color: "warning" as const };
      case "live":
        return { color: "success" as const };
      case "hidden":
        return { color: "error" as const };
    }
  };

  return (
    <Chip
      label={state.replace("_", " ").toUpperCase()}
      size="small"
      {...getChipProps(state)}
    />
  );
};

const CreateBlogDialog = ({
  open,
  onClose,
  onCreate,
}: {
  open: boolean;
  onClose: () => void;
  onCreate: (blog: BlogPostCreate) => Promise<void>;
}) => {
  const { currentUser } = useAuth();
  const { currentPhotoURL } = useProfilePicture();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: BlogCategory.Technology,
  });

  const handleSubmit = async () => {
    const now = new Date().toISOString();
    const newBlog: BlogPostCreate = {
      ...formData,
      date: now,
      author: {
        name: currentUser?.displayName || "Anonymous",
        avatar: currentPhotoURL || "",
      },
      readTime: "5 min",
      headerImage: "",
      content: "",
      slug: formData.title.toLowerCase().replace(/\s+/g, "-"),
      state: "draft" as BlogState,
      isPublished: false,
    };

    await onCreate(newBlog);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Blog Post</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Title"
            fullWidth
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
          <TextField
            select
            label="Category"
            fullWidth
            value={formData.category}
            onChange={(e) =>
              setFormData({
                ...formData,
                category: e.target.value as BlogCategory,
              })
            }
          >
            {BLOG_CATEGORIES.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            !formData.title || !formData.description || !formData.category
          }
        >
          Create Draft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BlogCard = ({ blog }: { blog: BlogListItem }) => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const handleEdit = () => {
    navigate(`/blog-writer/${blog.id}`);
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" noWrap sx={{ maxWidth: "70%" }}>
            {blog.title}
          </Typography>
          <BlogStateChip state={blog.state} />
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {blog.description}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          <Chip label={blog.category} size="small" />
          <Chip label={blog.readTime} size="small" />
          <Chip label={new Date(blog.date).toLocaleDateString()} size="small" />
        </Box>
      </CardContent>
      <CardActions>
        <IconButton size="small" title="Edit" onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton size="small" title="Preview">
          <VisibilityIcon />
        </IconButton>
        <IconButton size="small" title="Delete">
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

const BlogWriter = () => {
  const { blogs, isLoadingBlogs, createBlog } = useAdminBlogs();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const handleCreateBlog = async (blog: BlogPostCreate) => {
    try {
      await createBlog(blog);
    } catch (error) {
      console.error("Failed to create blog:", error);
      // TODO: Add error handling
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">Blog Writer</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Create Blog
        </Button>
      </Box>

      {isLoadingBlogs ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {blogs.map((blog) => (
            <Grid item xs={12} sm={6} md={4} key={blog.id}>
              <BlogCard blog={blog} />
            </Grid>
          ))}
        </Grid>
      )}

      <CreateBlogDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onCreate={handleCreateBlog}
      />
    </Box>
  );
};

export default BlogWriter;
