import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  useTheme,
  alpha,
  CircularProgress,
} from "@mui/material";
import { useStakeholderProjectPlan } from "hooks/stakeholder/useStakeholderProjectPlan";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import GroupIcon from "@mui/icons-material/Group";
import CodeIcon from "@mui/icons-material/Code";
import WarningIcon from "@mui/icons-material/Warning";
import SendIcon from "@mui/icons-material/Send";
import { motion } from "framer-motion";

interface ProjectPlanDisplayProps {
  clientId: string;
  projectIdeaId: string;
}

/**
 * Component for displaying the generated project plan
 */
export const ProjectPlanDisplay: React.FC<ProjectPlanDisplayProps> = ({
  clientId,
  projectIdeaId,
}) => {
  const theme = useTheme();
  const { projectPlan, isLoading, isSubmitting, handleSubmit } =
    useStakeholderProjectPlan({
      clientId,
      projectIdeaId,
    });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  if (isLoading || !projectPlan) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ overflow: "hidden" }}>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: 3,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            disabled={isSubmitting}
            onClick={handleSubmit}
            sx={{
              borderRadius: 8,
              textTransform: "none",
              px: 3,
              py: 1,
              boxShadow: theme.shadows[2],
            }}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1, color: "white" }} />
                Submitting...
              </>
            ) : (
              "Submit Project Plan"
            )}
          </Button>
        </Box>

        <Box sx={{ p: 3 }}>
          <motion.div variants={itemVariants}>
            <Card
              elevation={0}
              sx={{
                mb: 4,
                borderRadius: 2,
                boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
                >
                  Project Overview
                </Typography>
                <Typography variant="body1">{projectPlan.overview}</Typography>
              </CardContent>
            </Card>
          </motion.div>

          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              component={motion.div}
              variants={itemVariants}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: 2,
                  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  height: "100%",
                }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <ScheduleIcon color="primary" sx={{ mr: 1 }} />
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                      }}
                    >
                      Project Timeline
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />

                  {projectPlan.timeline.phases.map((phase, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {phase.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {phase.description}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Duration:</strong> {phase.duration}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="body2">
                          <strong>Deliverables:</strong>
                        </Typography>
                        <List dense>
                          {phase.deliverables.map((deliverable, i) => (
                            <ListItem key={i} sx={{ py: 0 }}>
                              <ListItemIcon sx={{ minWidth: 30 }}>
                                <CheckCircleIcon
                                  fontSize="small"
                                  color="success"
                                />
                              </ListItemIcon>
                              <ListItemText primary={deliverable} />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      {index < projectPlan.timeline.phases.length - 1 && (
                        <Divider sx={{ my: 2 }} />
                      )}
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              component={motion.div}
              variants={itemVariants}
            >
              <Card
                elevation={0}
                sx={{
                  mb: 3,
                  borderRadius: 2,
                  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <GroupIcon color="primary" sx={{ mr: 1 }} />
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                      }}
                    >
                      Team Composition
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />

                  {projectPlan.resources.teamComposition.map(
                    (member, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 }}
                        >
                          {member.role} ({member.count})
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 0.5 }}>
                          <strong>Responsibilities:</strong>
                        </Typography>
                        <List dense>
                          {member.responsibilities.map((resp, i) => (
                            <ListItem key={i} sx={{ py: 0 }}>
                              <ListItemIcon sx={{ minWidth: 30 }}>
                                <CheckCircleIcon
                                  fontSize="small"
                                  color="success"
                                />
                              </ListItemIcon>
                              <ListItemText primary={resp} />
                            </ListItem>
                          ))}
                        </List>
                        {index <
                          projectPlan.resources.teamComposition.length - 1 && (
                          <Divider sx={{ my: 1 }} />
                        )}
                      </Box>
                    )
                  )}
                </CardContent>
              </Card>

              <Card
                elevation={0}
                sx={{
                  mb: 3,
                  borderRadius: 2,
                  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <CodeIcon color="primary" sx={{ mr: 1 }} />
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                      }}
                    >
                      Technologies
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {projectPlan.resources.technologies.map((tech, index) => (
                      <Chip
                        key={index}
                        label={tech}
                        color="primary"
                        variant="outlined"
                        sx={{ borderRadius: 4 }}
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: 2,
                  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <WarningIcon color="warning" sx={{ mr: 1 }} />
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                      }}
                    >
                      Risk Assessment
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />

                  <List dense>
                    {projectPlan.risks.map((risk, index) => (
                      <ListItem key={index} alignItems="flex-start" sx={{ py: 1 }}>
                        <ListItemIcon sx={{ minWidth: 36, mt: 0.5 }}>
                          <WarningIcon color="warning" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={risk.description}
                          secondary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{ display: "block", mt: 0.5 }}
                              >
                                <strong>Mitigation:</strong> {risk.mitigation}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} component={motion.div} variants={itemVariants}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 2,
                  boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  background: alpha(theme.palette.primary.light, 0.1),
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
                  >
                    Estimated Cost
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary"
                    align="center"
                    sx={{ my: 2, fontWeight: 600 }}
                  >
                    ${projectPlan.resources.estimatedCost.min.toLocaleString()}{" "}
                    - $
                    {projectPlan.resources.estimatedCost.max.toLocaleString()}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    This estimate is based on the project scope, timeline, and
                    resource requirements.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </motion.div>
    </Box>
  );
}; 