import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { Timesheet } from "@freetech/models/timesheet";
import AddIcon from "@mui/icons-material/Add";
import { ProjectAssignment } from "@freetech/models/projects";
import { calculateDuration } from "core/timesheet/utils/utils";
import { Freelancer } from "@freetech/models/user";
import { useAdminTimesheet } from "hooks/admin/useAdminTimesheet";
import { useAuth } from "hooks/auth/useAuth";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useAdminClients } from "hooks/admin/useAdminClients";

interface TimeEntryFormProps {
  onSubmit: (timeEntry: Omit<Timesheet, "id">) => Promise<void>;
  projectAssignments?: ProjectAssignment[];
  freelancers?: Freelancer[];
  isAdmin?: boolean;
  userId?: string;
  defaultValues?: Partial<Timesheet>;
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
  editMode?: boolean;
  copyMode?: boolean;
  initialTimesheet?: Timesheet;
  onClose?: () => void;
}

export const AdminManualTimeEntryForm: React.FC<TimeEntryFormProps> = ({
  onSubmit,
  projectAssignments = [],
  freelancers = [],
  isAdmin = false,
  userId,
  defaultValues,
  buttonLabel = "Add Time Entry",
  buttonIcon = <AddIcon />,
  editMode = false,
  copyMode = false,
  initialTimesheet,
  onClose,
}) => {
  const [selectedUser, setSelectedUser] = useState<Freelancer | null>(null);
  const { addEntryForUser, updateTimesheet } = useAdminTimesheet();
  const { freelancers: adminFreelancers } = useAdminFreelancers();
  const { clients } = useAdminClients();
  const [open, setOpen] = useState(editMode || copyMode);
  const [date, setDate] = useState<DateTime>(DateTime.now());
  const [startTime, setStartTime] = useState<DateTime>(
    DateTime.now().startOf("hour")
  );
  const [endTime, setEndTime] = useState<DateTime>(
    DateTime.now().startOf("hour").plus({ hours: 1 })
  );
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [selectedAssignment, setSelectedAssignment] = useState<string>("");
  const [billable, setBillable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize form with timesheet data in edit or copy mode
  useEffect(() => {
    if ((editMode || copyMode) && initialTimesheet && adminFreelancers) {
      console.log("Initializing edit/copy mode with:", {
        initialTimesheet,
        adminFreelancers,
        clients,
        mode: copyMode ? "copy" : "edit"
      });

      // In copy mode, set the date to today
      setDate(copyMode ? DateTime.now() : DateTime.fromISO(initialTimesheet.date));
      setStartTime(DateTime.fromISO(initialTimesheet.startTime));
      setEndTime(DateTime.fromISO(initialTimesheet.endTime));
      setDescription(initialTimesheet.description || "");
      setLongDescription(initialTimesheet.longDescription || "");
      setBillable(initialTimesheet.billable);
      
      // Set user first if admin
      if (isAdmin && initialTimesheet.userId) {
        const user = adminFreelancers.find(f => f.id === initialTimesheet.userId);
        console.log("Found user:", user);
        if (user) {
          setSelectedUser(user);
          
          // Set client and project directly using IDs
          if (clients) {
            setSelectedClient(initialTimesheet.clientId);
            setSelectedProject(initialTimesheet.projectId);
            console.log("Setting client and project IDs:", {
              clientId: initialTimesheet.clientId,
              projectId: initialTimesheet.projectId
            });
          }
        }
      }
    }
  }, [editMode, copyMode, initialTimesheet, adminFreelancers, isAdmin, clients]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetForm();
    onClose?.();
  };

  const resetForm = () => {
    setDate(DateTime.now());
    setStartTime(DateTime.now().startOf("hour"));
    setEndTime(DateTime.now().startOf("hour").plus({ hours: 1 }));
    setDescription("");
    setLongDescription("");
    setSelectedUser(null);
    setSelectedClient("");
    setSelectedProject("");
    setSelectedAssignment("");
    setBillable(true);
  };

  // Get unique clients from all available clients
  const uniqueClients = useMemo(() => {
    console.log("Calculating unique clients");
    if (!clients) return [];
    
    return clients.map(client => ({
      id: client.id,
      name: client.companyName
    })).sort((a, b) => a.name.localeCompare(b.name));
  }, [clients]);

  // Get projects for selected client
  const availableProjects = useMemo(() => {
    console.log("Calculating available projects for client:", selectedClient);
    if (!selectedClient || !clients) return [];

    const client = clients.find(c => c.id === selectedClient);
    if (!client) return [];

    return (client.projects || [])
      .map(project => ({
        id: project.id,
        name: project.name
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [selectedClient, clients]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedUser && isAdmin) return;
    if (!selectedClient || !selectedProject) return;
    setIsSubmitting(true);

    try {
      const selectedProjectData = availableProjects.find(
        (p) => p.id === selectedProject
      );
      if (!selectedProjectData) {
        throw new Error("Selected project not found");
      }

      const timeEntry: Omit<Timesheet, "id"> = {
        date: date.toISODate() || "",
        startTime: startTime.toISO() || "",
        endTime: endTime.toISO() || "",
        duration: calculateDuration(
          startTime.toISO() || "",
          endTime.toISO() || ""
        ),
        description,
        longDescription,
        billable,
        submitted: false, // Always false for new entries and copies
        approved: false, // Always false for new entries and copies
        rejected: false, // Always false for new entries and copies
        userId: selectedUser?.id || userId || "",
        userEmail: selectedUser?.email || "",
        userName: selectedUser 
          ? `${selectedUser.firstName} ${selectedUser.lastName}`
          : "",
        projectAssignmentId: selectedAssignment || null,
        clientId: selectedClient,
        projectId: selectedProject,
        clientName: uniqueClients.find(c => c.id === selectedClient)?.name || "",
        projectName: availableProjects.find(p => p.id === selectedProject)?.name || "",
      };

      // In copy mode, always use addEntryForUser
      if (editMode && !copyMode && initialTimesheet) {
        await updateTimesheet.mutateAsync({
          id: initialTimesheet.id,
          ...timeEntry
        });
      } else {
        await addEntryForUser.mutateAsync(timeEntry);
      }

      handleClose();
    } catch (error) {
      console.error("Error submitting time entry:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!editMode && !copyMode && (
        <Tooltip title={buttonLabel}>
          <IconButton onClick={handleOpen} color="primary">
            {buttonIcon}
          </IconButton>
        </Tooltip>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {copyMode ? "Copy Time Entry" : editMode ? "Edit Time Entry" : "Add Time Entry"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={3}>
              {isAdmin && (
                <Autocomplete
                  options={freelancers}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  value={selectedUser}
                  onChange={(_, newValue) => {
                    console.log("Selected user:", newValue);
                    setSelectedUser(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select User" required />
                  )}
                />
              )}

              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => newValue && setDate(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
              />

              <Box sx={{ display: "flex", gap: 2 }}>
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newValue) => newValue && setStartTime(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
                <TimePicker
                  label="End Time"
                  value={endTime}
                  onChange={(newValue) => newValue && setEndTime(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Box>

              <FormControl fullWidth>
                <InputLabel>Client</InputLabel>
                <Select
                  value={selectedClient}
                  onChange={(e) => {
                    console.log("Selected client:", e.target.value);
                    setSelectedClient(e.target.value);
                    setSelectedProject("");
                    setSelectedAssignment("");
                  }}
                  label="Client"
                  required
                >
                  {uniqueClients.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth disabled={!selectedClient}>
                <InputLabel>Project</InputLabel>
                <Select
                  value={selectedProject}
                  onChange={(e) => {
                    console.log("Selected project:", e.target.value);
                    setSelectedProject(e.target.value);
                    setSelectedAssignment("");
                  }}
                  label="Project"
                  required
                >
                  {availableProjects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Short Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                required
              />

              <TextField
                label="Detailed Description"
                value={longDescription}
                onChange={(e) => setLongDescription(e.target.value)}
                multiline
                rows={4}
                fullWidth
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={billable}
                    onChange={(e) => setBillable(e.target.checked)}
                  />
                }
                label="Billable"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={
                isSubmitting ||
                (isAdmin && !selectedUser) ||
                !selectedClient ||
                !selectedProject
              }
            >
              {isSubmitting 
                ? (copyMode ? "Copying..." : editMode ? "Updating..." : "Adding...") 
                : (copyMode ? "Copy Entry" : editMode ? "Update Entry" : "Add Entry")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
