import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Divider,
  Alert,
  alpha,
} from "@mui/material";
import { DateTime } from "luxon";
import { formatDurationForEdit } from "core/timesheet/utils";
import TipTapEditor from "components/TipTap/TipTapEditor";
import { Timesheet } from "@freetech/models/timesheet";

interface LongDescriptionDialogProps {
  open: boolean;
  onClose: () => void;
  timesheet: Timesheet;
  onSave: (newLongDescription: string) => Promise<void>;
}
export const LongDescriptionDialog: React.FC<LongDescriptionDialogProps> = ({
  open,
  onClose,
  timesheet,
  onSave,
}) => {
  const [longDescription, setLongDescription] = useState(
    timesheet.longDescription || ""
  );
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);
    try {
      await onSave(longDescription);
    } catch (error) {
      console.error("Failed to save long description:", error);
      setError("Failed to save long description. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Long Description</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {/* Timesheet Details */}
        <Box sx={{ mb: 3, mt: 1 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Entry Details (Read-only)
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
            }}
          >
            <Typography variant="body2">
              <strong>Date:</strong>{" "}
              {DateTime.fromISO(timesheet.date).toFormat("dd LLL yyyy")}
            </Typography>
            <Typography variant="body2">
              <strong>Duration:</strong>{" "}
              {formatDurationForEdit(timesheet.duration)}
            </Typography>
            <Typography variant="body2">
              <strong>Description:</strong> {timesheet.description}
            </Typography>
            <Typography variant="body2">
              <strong>Status:</strong>{" "}
              {timesheet.approved
                ? "Approved"
                : timesheet.submitted
                  ? "Submitted"
                  : "Not Submitted"}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Markdown Editor */}
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Long Description
        </Typography>
        <Box
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            overflow: "hidden",
            "& .markdown-editor-toolbar": {
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              backgroundColor: (theme) =>
                alpha(theme.palette.background.default, 0.5),
            },
            "& .markdown-editor-content": {
              minHeight: 300,
              padding: 2,
            },
          }}
        >
          <TipTapEditor
            content={longDescription}
            onChange={setLongDescription}
            placeholder="Add additional details here..."
            minHeight={300}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={isSaving}
          startIcon={isSaving && <CircularProgress size={20} />}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
