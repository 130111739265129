import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Container,
  Divider,
  Paper,
  Button,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  StepIconProps,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useProjectIdeaPage } from "hooks/stakeholder/useProjectIdeaPage";
import { useNavigate, useParams } from "react-router-dom";
import { useStakeholderProjectPlan } from "hooks/stakeholder/useStakeholderProjectPlan";
import { ChatInterface } from "./components/ChatInterface";
import { ProjectPlanDisplay } from "./components/ProjectPlanDisplay";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatIcon from "@mui/icons-material/Chat";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EventNoteIcon from "@mui/icons-material/EventNote";

// Custom StepIcon component with glow effect for active steps
const CustomStepIcon: React.FC<StepIconProps & { icon: React.ReactNode }> = (props) => {
  const { active, completed, icon } = props;
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active 
          ? theme.palette.primary.main 
          : completed 
            ? theme.palette.success.main 
            : theme.palette.text.disabled,
        borderRadius: '50%',
        width: 36,
        height: 36,
        boxShadow: active 
          ? `0 0 10px ${theme.palette.primary.main}` 
          : 'none',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      {icon}
    </Box>
  );
};

/**
 * Component for the project idea chat interface
 * This page displays the chat interface with Jane for creating a project idea
 */
const ProjectIdeaChat: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { projectIdeaId: urlProjectIdeaId } = useParams<{ projectIdeaId: string }>();
  const [hasStartedChat, setHasStartedChat] = useState(false);

  const {
    isProcessing,
    generatedPlan,
    isGeneratingPlan,
    isError,
    selectedClient,
    currentStep,
    clients,
    clientsIsLoading,
    sendMessage,
    handleClientSelection,
    messages,
    handleGeneratePlan: generatePlan,
    projectIdeaId,
    conversationComplete,
    loadProjectIdea,
  } = useProjectIdeaPage();

  // Load the project idea based on the URL parameter
  useEffect(() => {
    if (urlProjectIdeaId && loadProjectIdea) {
      console.log("ProjectIdeaChat: Loading project idea with ID:", urlProjectIdeaId);
      loadProjectIdea(urlProjectIdeaId);
      setHasStartedChat(true);
    }
  }, [urlProjectIdeaId, loadProjectIdea]);

  // Only call the hook when we have both IDs
  const { projectPlan, isLoading: projectPlanIsLoading } =
    useStakeholderProjectPlan(
      selectedClient && projectIdeaId
        ? {
            clientId: selectedClient.id,
            projectIdeaId: projectIdeaId,
          }
        : { clientId: "", projectIdeaId: "" }
    );

  // If we don't have a URL projectIdeaId, redirect to the setup page
  useEffect(() => {
    if (!urlProjectIdeaId && !projectIdeaId) {
      console.log("ProjectIdeaChat: No projectIdeaId found, redirecting to setup page");
      navigate("/stakeholder/projects/new-idea");
    }
  }, [urlProjectIdeaId, projectIdeaId, navigate]);

  // Process steps
  const steps = [
    { label: 'Create Project', icon: <AssignmentIcon /> },
    { label: 'AI Conversation', icon: <ChatIcon /> },
    { label: 'Auto-Generate Plan', icon: <AutoAwesomeIcon /> },
    { label: 'Review & Finalize', icon: <EventNoteIcon /> }
  ];

  // Determine current step index for stepper
  const getActiveStep = () => {
    if (generatedPlan || projectPlan) {
      return 3; // Review & Finalize step
    } else if (conversationComplete) {
      return 2; // Ready to generate plan
    } else {
      return 1; // AI Conversation
    }
  };

  if (clientsIsLoading || projectPlanIsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!clients?.length) {
    navigate("/stakeholder/clients/new");
    return null;
  }

  // Show loading state when generating plan
  if (isGeneratingPlan) {
    return (
      <Container maxWidth="xl">
        <Box sx={{ mb: 3, mt: 2 }}>
          <Stepper activeStep={2} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={step.label} completed={index < 2}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <CustomStepIcon {...props} icon={step.icon} />
                  )}
                  sx={{
                    '& .MuiStepLabel-label': {
                      color: index === 2 ? theme.palette.primary.main : 'inherit',
                      fontWeight: index === 2 ? 'bold' : 'normal',
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 4 }}>
            Generating your project plan...
          </Typography>
        </Box>
      </Container>
    );
  }
  
  // Show plan display when we have a generated plan
  if (generatedPlan || projectPlan) {
    return (
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Box sx={{ mb: 3, mt: 2 }}>
          <Stepper activeStep={3} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={step.label} completed={index < 3}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <CustomStepIcon {...props} icon={step.icon} />
                  )}
                  sx={{
                    '& .MuiStepLabel-label': {
                      color: index === 3 ? theme.palette.primary.main : 'inherit',
                      fontWeight: index === 3 ? 'bold' : 'normal',
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
              Your Project Plan
            </Typography>

            {selectedClient && projectIdeaId && (
              <ProjectPlanDisplay
                clientId={selectedClient.id}
                projectIdeaId={projectIdeaId}
              />
            )}
          </Paper>
        </motion.div>
      </Container>
    );
  }

  const handleSendMessage = (message: string) => {
    sendMessage(message);
    setHasStartedChat(true);
  };

  const onGeneratePlan = () => {
    console.log("Generate Plan clicked", {
      clientId: selectedClient?.id,
      projectIdeaId,
      conversationComplete,
    });

    if (!projectIdeaId) {
      console.error("Cannot generate plan: Missing projectIdeaId");
      return;
    }

    if (!selectedClient?.id) {
      console.error("Cannot generate plan: Missing clientId");
      return;
    }

    console.log("Proceeding with generate plan:", {
      clientId: selectedClient.id,
      projectIdeaId,
    });

    generatePlan();
  };

  const activeStep = getActiveStep();

  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 3, mt: 2 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label} completed={index < activeStep}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon {...props} icon={step.icon} />
                )}
                sx={{
                  '& .MuiStepLabel-label': {
                    color: index === activeStep ? theme.palette.primary.main : 'inherit',
                    fontWeight: index === activeStep ? 'bold' : 'normal',
                  },
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            component={motion.div}
            layout
            transition={{ duration: 0.5 }}
          >
            <Box>
              <ChatInterface
                messages={messages}
                onSendMessage={handleSendMessage}
                isProcessing={isProcessing}
                onClientSelect={handleClientSelection}
                clients={clients || []}
                currentStep={currentStep}
                readyForPlan={conversationComplete}
                onGeneratePlan={onGeneratePlan}
              />
              {currentStep && !generatedPlan && conversationComplete && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 4 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={onGeneratePlan}
                      disabled={isGeneratingPlan}
                      sx={{
                        minWidth: 200,
                        py: 1.5,
                        px: 4,
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Generate Plan
                    </Button>
                  </Box>
                </motion.div>
              )}
            </Box>
          </Grid>
          {hasStartedChat && currentStep !== "complete" && (
            <Grid
              item
              xs={12}
              md={4}
              component={motion.div}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {isError && (
                <Alert severity="error" sx={{ mt: 4 }}>
                  An error occurred while generating the plan. Please try again.
                </Alert>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default ProjectIdeaChat; 