import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  IconButton,
  CircularProgress,
  Paper,
  Breadcrumbs,
  Link,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Alert,
  Snackbar,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { useAdminCareers } from "hooks/careers/useAdminCareers";
import {
  JobCategory,
  JobLocation,
  JobStatus,
  JobType,
  JobListing,
} from "@freetech/models/careers";
import { useConfirm } from "material-ui-confirm";
import { useAuth } from "hooks/auth/useAuth";
import { usePublicUserList } from "hooks/user/usePublicUserList";

const JOB_CATEGORIES = Object.values(JobCategory);
const JOB_TYPES = Object.values(JobType);
const JOB_LOCATIONS = Object.values(JobLocation);
const JOB_STATUSES = Object.values(JobStatus);

const JobPostEditor = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { jobs, isLoadingJobs, updateJob, deleteJob } = useAdminCareers();
  const { userInfo } = useAuth();
  const { data: publicUsers = [], isLoading: isLoadingUsers } = usePublicUserList();

  const [originalData, setOriginalData] = useState<JobListing | null>(null);
  const [formData, setFormData] = useState<JobListing | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [compensationType, setCompensationType] = useState<"salary" | "hourly">("salary");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);

  // Find the user who last updated the job
  const lastUpdatedByUser = useMemo(() => {
    if (!formData?.updatedBy || !publicUsers.length) return null;
    return publicUsers.find(user => user.id === formData.updatedBy);
  }, [formData?.updatedBy, publicUsers]);

  // Check if there are unsaved changes
  const hasUnsavedChanges = useMemo(() => {
    if (!originalData || !formData) return false;

    // Compare basic fields
    if (formData.title !== originalData.title) return true;
    if (formData.shortDescription !== originalData.shortDescription) return true;
    if (formData.description !== originalData.description) return true;
    if (formData.jobType !== originalData.jobType) return true;
    if (formData.location !== originalData.location) return true;
    if (formData.category !== originalData.category) return true;
    if (formData.status !== originalData.status) return true;

    // Compare salary
    if (formData.salary && originalData.salary) {
      if (formData.salary.min !== originalData.salary.min) return true;
      if (formData.salary.max !== originalData.salary.max) return true;
    } else if (formData.salary || originalData.salary) {
      return true;
    }

    // Compare hourly rate
    if (formData.hourlyRate && originalData.hourlyRate) {
      if (formData.hourlyRate.min !== originalData.hourlyRate.min) return true;
      if (formData.hourlyRate.max !== originalData.hourlyRate.max) return true;
    } else if (formData.hourlyRate || originalData.hourlyRate) {
      return true;
    }

    // Compare arrays
    if (formData.requirements.length !== originalData.requirements.length) return true;
    if (formData.responsibilities.length !== originalData.responsibilities.length) return true;

    for (let i = 0; i < formData.requirements.length; i++) {
      if (formData.requirements[i] !== originalData.requirements[i]) return true;
    }

    for (let i = 0; i < formData.responsibilities.length; i++) {
      if (formData.responsibilities[i] !== originalData.responsibilities[i]) return true;
    }

    return false;
  }, [originalData, formData]);

  useEffect(() => {
    if (!isLoadingJobs && jobs.length > 0 && jobId) {
      const job = jobs.find((j) => j.id === jobId);
      if (job) {
        setFormData(job);
        setOriginalData(job);
        
        // Set last saved time
        if (job.updatedAt) {
          setLastSaved(new Date(job.updatedAt));
        }
        
        // Determine compensation type based on which field is present
        if (job.hourlyRate) {
          setCompensationType("hourly");
        } else {
          setCompensationType("salary");
        }
      } else {
        setError("Job listing not found");
      }
    }
  }, [isLoadingJobs, jobs, jobId]);

  const handleChange = (field: keyof JobListing, value: any) => {
    if (formData) {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const handleArrayChange = (
    field: "requirements" | "responsibilities",
    index: number,
    value: string
  ) => {
    if (formData) {
      const newArray = [...formData[field]];
      newArray[index] = value;
      setFormData({
        ...formData,
        [field]: newArray,
      });
    }
  };

  const handleAddArrayItem = (
    field: "requirements" | "responsibilities"
  ) => {
    if (formData) {
      setFormData({
        ...formData,
        [field]: [...formData[field], ""],
      });
    }
  };

  const handleRemoveArrayItem = (
    field: "requirements" | "responsibilities",
    index: number
  ) => {
    if (formData) {
      const newArray = [...formData[field]];
      newArray.splice(index, 1);
      setFormData({
        ...formData,
        [field]: newArray,
      });
    }
  };

  const handleSalaryChange = (field: "min" | "max", value: any) => {
    if (formData) {
      setFormData({
        ...formData,
        salary: {
          ...formData.salary || { min: 0, max: 0 },
          [field]: Number(value),
        },
      });
    }
  };

  const handleHourlyRateChange = (field: "min" | "max", value: any) => {
    if (formData) {
      setFormData({
        ...formData,
        hourlyRate: {
          ...formData.hourlyRate || { min: 0, max: 0 },
          [field]: Number(value),
        },
      });
    }
  };

  const handleCompensationTypeChange = (type: "salary" | "hourly") => {
    setCompensationType(type);
    if (formData) {
      // When switching compensation type, ensure the other type is null/undefined
      if (type === "salary") {
        const { hourlyRate, ...rest } = formData;
        setFormData({
          ...rest,
          salary: formData.salary || { min: 0, max: 0 },
        });
      } else {
        const { salary, ...rest } = formData;
        setFormData({
          ...rest,
          hourlyRate: formData.hourlyRate || { min: 0, max: 0 },
        });
      }
    }
  };

  const handleSave = async () => {
    if (!formData || !userInfo) return;

    try {
      setIsSubmitting(true);
      setError(null);

      const now = new Date().toISOString();
      const updatedJob: JobListing = {
        ...formData,
        updatedAt: now,
        updatedBy: userInfo.id,
        requirements: formData.requirements.filter(Boolean),
        responsibilities: formData.responsibilities.filter(Boolean),
      };

      await updateJob(updatedJob);
      
      // Update original data to reflect saved changes
      setOriginalData(updatedJob);
      
      // Update last saved time
      setLastSaved(new Date(now));
      
      setSaveSuccess(true);
    } catch (err) {
      setError("Failed to save job listing");
      console.error("Failed to save job:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusChange = async (newStatus: JobStatus) => {
    if (!formData || !userInfo) return;

    try {
      setIsSubmitting(true);
      setError(null);

      const now = new Date().toISOString();
      const updatedJob: JobListing = {
        ...formData,
        status: newStatus,
        updatedAt: now,
        updatedBy: userInfo.id,
        // Set publishedAt if publishing
        ...(newStatus === JobStatus.Published && !formData.publishedAt && { publishedAt: now }),
        // Set closedAt if closing
        ...(newStatus === JobStatus.Closed && !formData.closedAt && { closedAt: now }),
      };

      await updateJob(updatedJob);
      
      // Update original data to reflect saved changes
      setOriginalData(updatedJob);
      
      // Update last saved time
      setLastSaved(new Date(now));
      
      setSaveSuccess(true);
    } catch (err) {
      setError("Failed to update job status");
      console.error("Failed to update job status:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    if (!formData) return;

    try {
      await confirm({
        title: "Delete Job Listing",
        description: `Are you sure you want to delete "${formData.title}"? This action cannot be undone.`,
        confirmationText: "Delete",
        confirmationButtonProps: { color: "error" },
      });

      setIsSubmitting(true);
      await deleteJob(formData.id);
      navigate("/admin/beta-features/job-poster");
    } catch (err) {
      if (err instanceof Error) {
        setError("Failed to delete job listing");
        console.error("Failed to delete job:", err);
      }
      // User canceled the deletion
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSaveSuccess(false);
  };

  if (isLoadingJobs || isLoadingUsers) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error && !formData) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/admin/beta-features/job-poster")}
          sx={{ mt: 2 }}
        >
          Back to Job Listings
        </Button>
      </Box>
    );
  }

  if (!formData) {
    return null;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Snackbar 
        open={saveSuccess} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Job listing saved successfully!
        </Alert>
      </Snackbar>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigate("/admin/beta-features/job-poster")}
                sx={{ cursor: "pointer" }}
              >
                Job Listings
              </Link>
              <Typography color="text.primary">Edit Job</Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ mt: 1 }}>
              Edit Job Listing
            </Typography>
          </Box>
          <Stack direction="row" spacing={2} alignItems="center">
            {lastSaved && !hasUnsavedChanges && (
              <Typography variant="body2" color="text.secondary">
                Last saved: {lastSaved.toLocaleString(undefined, {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true
                })}
              </Typography>
            )}
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/admin/beta-features/job-poster")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={isSubmitting}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
              disabled={isSubmitting || !hasUnsavedChanges}
            >
              {isSubmitting ? <CircularProgress size={24} /> : (hasUnsavedChanges ? "Save Changes" : "No Changes")}
            </Button>
          </Stack>
        </Box>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {/* Status Action Buttons */}
        <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
          <Typography variant="subtitle1" sx={{ alignSelf: "center" }}>
            Job Status: 
            <Chip 
              label={formData.status.charAt(0).toUpperCase() + formData.status.slice(1)} 
              color={
                formData.status === JobStatus.Published ? "success" :
                formData.status === JobStatus.Draft ? "default" :
                formData.status === JobStatus.Closed ? "error" : "warning"
              }
              sx={{ ml: 1 }}
            />
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {formData.status !== JobStatus.Published && (
            <Button
              variant="contained"
              color="success"
              onClick={() => handleStatusChange(JobStatus.Published)}
              disabled={isSubmitting}
            >
              Publish
            </Button>
          )}
          {formData.status !== JobStatus.Draft && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleStatusChange(JobStatus.Draft)}
              disabled={isSubmitting}
            >
              Hide
            </Button>
          )}
          {formData.status !== JobStatus.Closed && (
            <Button
              variant="contained"
              color="error"
              onClick={() => handleStatusChange(JobStatus.Closed)}
              disabled={isSubmitting}
            >
              Close
            </Button>
          )}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label="Job Title"
                fullWidth
                value={formData.title}
                onChange={(e) => handleChange("title", e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Status"
                fullWidth
                value={formData.status}
                onChange={(e) =>
                  handleChange("status", e.target.value as JobStatus)
                }
              >
                {JOB_STATUSES.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <TextField
            label="Short Description"
            fullWidth
            value={formData.shortDescription}
            onChange={(e) => handleChange("shortDescription", e.target.value)}
            required
            placeholder="A brief summary of the job position"
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Job Type"
                fullWidth
                value={formData.jobType}
                onChange={(e) =>
                  handleChange("jobType", e.target.value as JobType)
                }
                required
              >
                {JOB_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Location"
                fullWidth
                value={formData.location}
                onChange={(e) =>
                  handleChange("location", e.target.value as JobLocation)
                }
                required
              >
                {JOB_LOCATIONS.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="Category"
                fullWidth
                value={formData.category}
                onChange={(e) =>
                  handleChange("category", e.target.value as JobCategory)
                }
                required
              >
                {JOB_CATEGORIES.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <TextField
            label="Job Description"
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
            required
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">Compensation Type</FormLabel>
            <RadioGroup
              row
              value={compensationType}
              onChange={(e) => handleCompensationTypeChange(e.target.value as "salary" | "hourly")}
            >
              <FormControlLabel value="salary" control={<Radio />} label="Salary" />
              <FormControlLabel value="hourly" control={<Radio />} label="Hourly Rate" />
            </RadioGroup>
          </FormControl>

          {compensationType === "salary" ? (
            <>
              <Typography variant="subtitle1">Salary Range (Optional)</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Minimum"
                    type="number"
                    fullWidth
                    value={formData.salary?.min || 0}
                    onChange={(e) => handleSalaryChange("min", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Maximum"
                    type="number"
                    fullWidth
                    value={formData.salary?.max || 0}
                    onChange={(e) => handleSalaryChange("max", e.target.value)}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">Hourly Rate Range (Optional)</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Minimum"
                    type="number"
                    fullWidth
                    value={formData.hourlyRate?.min || 0}
                    onChange={(e) => handleHourlyRateChange("min", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Maximum"
                    type="number"
                    fullWidth
                    value={formData.hourlyRate?.max || 0}
                    onChange={(e) => handleHourlyRateChange("max", e.target.value)}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Divider sx={{ my: 1 }} />

          <Box>
            <Typography variant="subtitle1">Requirements</Typography>
            {formData.requirements.map((req, index) => (
              <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                <TextField
                  fullWidth
                  value={req}
                  onChange={(e) =>
                    handleArrayChange("requirements", index, e.target.value)
                  }
                  placeholder={`Requirement ${index + 1}`}
                />
                <IconButton
                  color="error"
                  onClick={() => handleRemoveArrayItem("requirements", index)}
                  disabled={formData.requirements.length <= 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddArrayItem("requirements")}
            >
              Add Requirement
            </Button>
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box>
            <Typography variant="subtitle1">Responsibilities</Typography>
            {formData.responsibilities.map((resp, index) => (
              <Box key={index} sx={{ display: "flex", gap: 1, mb: 2 }}>
                <TextField
                  fullWidth
                  value={resp}
                  onChange={(e) =>
                    handleArrayChange("responsibilities", index, e.target.value)
                  }
                  placeholder={`Responsibility ${index + 1}`}
                />
                <IconButton
                  color="error"
                  onClick={() =>
                    handleRemoveArrayItem("responsibilities", index)
                  }
                  disabled={formData.responsibilities.length <= 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddArrayItem("responsibilities")}
            >
              Add Responsibility
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Created: {new Date(formData.createdAt).toLocaleString()}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Last Updated: {new Date(formData.updatedAt).toLocaleString()}
            </Typography>
            {formData.updatedBy && (
              <Typography variant="subtitle2" color="text.secondary">
                Last Updated By: {lastUpdatedByUser ? 
                  `${lastUpdatedByUser.firstName} ${lastUpdatedByUser.lastName}` : 
                  formData.updatedBy}
              </Typography>
            )}
            {formData.publishedAt && (
              <Typography variant="subtitle2" color="text.secondary">
                Published: {new Date(formData.publishedAt).toLocaleString()}
              </Typography>
            )}
            {formData.closedAt && (
              <Typography variant="subtitle2" color="text.secondary">
                Closed: {new Date(formData.closedAt).toLocaleString()}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default JobPostEditor;
