import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Skeleton,
  useTheme,
  alpha,
} from "@mui/material";
import {
  AccountBalance as AccountBalanceIcon,
  People as PeopleIcon,
  School as SchoolIcon,
  Business as BusinessIcon,
  MonetizationOn as MonetizationOnIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import { useAdminFinancialHub } from "hooks/admin/useAdminFinancialHub";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useAdminClients } from "hooks/admin/useAdminClients";

const MetricCard = ({
  title,
  value,
  icon: Icon,
  loading,
  color = "primary",
  secondaryValue,
  secondaryLabel,
}: {
  title: string;
  value: string | number;
  icon: React.ElementType;
  loading?: boolean;
  color?: "primary" | "secondary" | "success" | "error" | "warning" | "info";
  secondaryValue?: string | number;
  secondaryLabel?: string;
}) => {
  const theme = useTheme();

  if (loading) {
    return (
      <Card sx={{ height: "100%" }}>
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{ mr: 1 }}
            />
            <Skeleton variant="text" width={120} />
          </Box>
          <Skeleton variant="text" width={80} height={40} />
          <Skeleton variant="text" width={140} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        height: "100%",
        backgroundColor: alpha(theme.palette[color].main, 0.05),
        "&:hover": {
          backgroundColor: alpha(theme.palette[color].main, 0.1),
        },
      }}
    >
      <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Icon
            sx={{
              mr: 1,
              color: `${color}.main`,
              fontSize: { xs: "1.25rem", sm: "1.5rem" },
            }}
          />
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{
              fontSize: { xs: "0.875rem", sm: "1rem" },
              lineHeight: 1.2,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="h4"
          component="div"
          sx={{
            color: `${color}.main`,
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" },
            lineHeight: 1.2,
            my: 1,
          }}
        >
          {value}
        </Typography>
        {secondaryValue && secondaryLabel && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mt: 1,
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
            }}
          >
            {secondaryLabel}: {secondaryValue}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const BusinessHealthMetrics: React.FC = () => {
  const {
    formatCurrency,
    accountBalances,
    stripeBalance,
    stripeSubscriptions,
    isLoading: isLoadingFinancial,
  } = useAdminFinancialHub();

  const { freelancers, isLoading: isLoadingFreelancers } =
    useAdminFreelancers();

  const { clients, isLoading: isLoadingClients } = useAdminClients();

  // Calculate metrics
  const totalBalance =
    (accountBalances?.primaryAccount?.currentBalance || 0) +
    (accountBalances?.accounts[1]?.currentBalance || 0) +
    (accountBalances?.accounts[2]?.currentBalance || 0) +
    (Array.isArray(stripeBalance?.available)
      ? stripeBalance?.available.find((b) => b.currency === "usd")?.amount || 0
      : 0) /
      100;

  const activeFreelancers =
    freelancers?.filter((f) => f.status === "active").length || 0;
  const totalFreelancers = freelancers?.length || 0;
  const activeClients =
    clients?.filter((c) => c.status === "active").length || 0;
  const totalClients = clients?.length || 0;
  const activeProjects =
    clients?.reduce(
      (count, client) =>
        count +
        (client.projects?.filter((p) => p.status === "active").length || 0),
      0
    ) || 0;
  const totalProjects =
    clients?.reduce(
      (count, client) => count + (client.projects?.length || 0),
      0
    ) || 0;
  const monthlyRecurringRevenue =
    stripeSubscriptions
      ?.filter((sub) => sub.status === "active")
      .reduce((total, sub) => {
        const itemTotal = Array.isArray(sub.items)
          ? sub.items.reduce((sum, item: any) => {
              return sum + (item.price?.unit_amount || 0);
            }, 0)
          : (sub.items as any)?.price?.unit_amount || 0;
        return total + itemTotal;
      }, 0) / 100 || 0;

  const isLoading =
    isLoadingFinancial || isLoadingFreelancers || isLoadingClients;

  return (
    <Box sx={{ width: "100%", p: { xs: 0, sm: 1 } }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          mb: 3,
          fontSize: { xs: "1.125rem", sm: "1.25rem" },
          px: { xs: 2, sm: 0 },
        }}
      >
        Business Health Overview
      </Typography>
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Total Balance"
            value={formatCurrency(totalBalance)}
            icon={AccountBalanceIcon}
            loading={isLoading}
            color="success"
            secondaryValue={formatCurrency(monthlyRecurringRevenue)}
            secondaryLabel="Monthly Recurring Revenue"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Active Freelancers"
            value={activeFreelancers}
            icon={PeopleIcon}
            loading={isLoading}
            color="primary"
            secondaryValue={totalFreelancers}
            secondaryLabel="Total Freelancers"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Active Clients"
            value={activeClients}
            icon={BusinessIcon}
            loading={isLoading}
            color="secondary"
            secondaryValue={totalClients}
            secondaryLabel="Total Clients"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Active Projects"
            value={activeProjects}
            icon={AssignmentIcon}
            loading={isLoading}
            color="info"
            secondaryValue={totalProjects}
            secondaryLabel="Total Projects"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Active Subscriptions"
            value={
              stripeSubscriptions?.filter((sub) => sub.status === "active")
                .length || 0
            }
            icon={MonetizationOnIcon}
            loading={isLoading}
            color="warning"
            secondaryValue={formatCurrency(monthlyRecurringRevenue)}
            secondaryLabel="Monthly Revenue"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Active Students"
            value={0}
            icon={SchoolIcon}
            loading={isLoading}
            color="error"
            secondaryValue={0}
            secondaryLabel="Total Students"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessHealthMetrics;
