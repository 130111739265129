import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc, 
  updateDoc, 
  addDoc,
  serverTimestamp,
  Timestamp 
} from 'firebase/firestore';
import { db } from 'config/firebase';
import { useAuth } from 'hooks/auth/useAuth';
import { usePublicUserList } from 'hooks/user/usePublicUserList';
import { Student, PublicUserInfoType } from '@freetech/models/user';

interface Cohort {
  id: string;
  name: string;
  description?: string;
  mentorId: string;
  createdAt: Timestamp;
  startDate?: string;
  endDate?: string;
  status: 'active' | 'inactive' | 'completed';
  studentIds: string[];
  weeklyMeetingDay?: string; // Monday, Tuesday, etc.
  weeklyMeetingTime?: string; // HH:MM format
  weeklyMeetingDuration?: number; // in minutes
  weeklyMeetingLink?: string; // Zoom/Meet link
  slackChannel?: string;
  freeloBoardId?: string;
}

interface ManagedStudent extends PublicUserInfoType {
  cohortId?: string;
  cohortName?: string;
  status?: 'active' | 'ready' | 'inactive';
  pendingMentorAssistance?: boolean;
  mentorAssistanceRequestedAt?: string;
  mentorAssistanceMessage?: string;
}

interface FreeloBoard {
  id: string;
  name: string;
}

interface UpdateCohortDetailsParams {
  cohortId: string;
  weeklyMeetingDay?: string;
  weeklyMeetingTime?: string;
  weeklyMeetingDuration?: number;
  weeklyMeetingLink?: string;
  slackChannel?: string;
  freeloBoardId?: string;
}

interface RespondToAssistanceRequestParams {
  studentId: string;
  response: string;
  resolved: boolean;
}

export const useManageMyStudents = () => {
  const { userInfo: user } = useAuth();
  const queryClient = useQueryClient();
  const { data: publicUserProfiles = [] } = usePublicUserList();

  // Get all cohorts where the current user is the mentor
  const { data: mentorCohorts = [], isLoading: isCohortsLoading } = useQuery({
    queryKey: ['mentor', 'cohorts', user?.id],
    queryFn: async (): Promise<Cohort[]> => {
      if (!user?.id) return [];

      const cohortQuery = query(
        collection(db, 'cohorts'),
        where('mentorId', '==', user.id)
      );

      const snapshot = await getDocs(cohortQuery);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Cohort));
    },
    enabled: !!user?.id && (user.role === 'admin' || user.role === 'freelancer')
  });

  // Get all students assigned to this mentor (either directly or via cohorts)
  const { data: managedStudents = [], isLoading: isStudentsLoading } = useQuery({
    queryKey: ['mentor', 'students', user?.id, mentorCohorts.length, publicUserProfiles.length],
    queryFn: async (): Promise<ManagedStudent[]> => {
      if (!user?.id) return [];

      // Get all students with this mentor assigned
      const directStudentsQuery = query(
        collection(db, 'users'),
        where('role', '==', 'student'),
        where('assignedMentorId', '==', user.id)
      );

      const directSnapshot = await getDocs(directStudentsQuery);
      const directStudents = directSnapshot.docs.map(doc => {
        const data = doc.data();
        // Find matching public profile
        const publicProfile = publicUserProfiles.find(p => p.id === doc.id);
        
        if (!publicProfile) {
          // If no matching public profile, create a minimal one from private data
          return {
            id: doc.id,
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            displayName: data.displayName || `${data.firstName} ${data.lastName}`,
            email: data.email || '',
            profilePicture: data.profilePicture,
            disabled: data.disabled || false,
            cohortId: data.cohortId,
            status: data.status,
            pendingMentorAssistance: data.pendingMentorAssistance,
            mentorAssistanceRequestedAt: data.mentorAssistanceRequestedAt,
            mentorAssistanceMessage: data.mentorAssistanceMessage
          } as ManagedStudent;
        }

        // Use public profile with additional mentor-specific fields
        return {
          ...publicProfile,
          cohortId: data.cohortId,
          status: data.status,
          pendingMentorAssistance: data.pendingMentorAssistance,
          mentorAssistanceRequestedAt: data.mentorAssistanceRequestedAt,
          mentorAssistanceMessage: data.mentorAssistanceMessage
        } as ManagedStudent;
      });

      // Add cohort name to each student
      const studentsWithCohortNames = directStudents.map(student => {
        if (student.cohortId) {
          const cohort = mentorCohorts.find(c => c.id === student.cohortId);
          if (cohort) {
            return {
              ...student,
              cohortName: cohort.name
            };
          }
        }
        return student;
      });

      return studentsWithCohortNames;
    },
    enabled: !!user?.id && (user.role === 'admin' || user.role === 'freelancer') && publicUserProfiles.length > 0
  });

  // Get all available Freelo boards - using Firestore collection instead of MCP
  const { data: freeloBoards = [], isLoading: isBoardsLoading } = useQuery({
    queryKey: ['mentor', 'freelo-boards'],
    queryFn: async (): Promise<FreeloBoard[]> => {
      try {
        // Fetch boards from Firestore 'freelo_boards' collection instead of direct MCP call
        const boardsQuery = query(collection(db, 'freelo_boards'));
        const snapshot = await getDocs(boardsQuery);
        
        return snapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name || doc.id,
        }));
      } catch (error) {
        console.error('Error fetching Freelo boards:', error);
        return [];
      }
    },
    enabled: !!user?.id && (user.role === 'admin' || user.role === 'freelancer')
  });

  // Update cohort details
  const updateCohortDetailsMutation = useMutation({
    mutationFn: async (params: UpdateCohortDetailsParams) => {
      const { cohortId, ...updateData } = params;
      await updateDoc(doc(db, 'cohorts', cohortId), {
        ...updateData,
        updatedAt: serverTimestamp(),
        updatedBy: user?.id
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mentor', 'cohorts'] });
    }
  });

  // Respond to a student assistance request
  const respondToAssistanceRequestMutation = useMutation({
    mutationFn: async (params: RespondToAssistanceRequestParams) => {
      const { studentId, response, resolved } = params;
      
      // Get the current user document
      const studentDoc = await getDoc(doc(db, 'users', studentId));
      if (!studentDoc.exists()) {
        throw new Error('Student not found');
      }
      
      // Update the assistance request with mentor's response
      await updateDoc(doc(db, 'users', studentId), {
        mentorAssistanceResponse: response,
        mentorAssistanceRespondedAt: new Date().toISOString(),
        mentorAssistanceRespondedBy: user?.id,
        pendingMentorAssistance: !resolved,
        mentorAssistanceResolved: resolved,
        mentorAssistanceResolvedAt: resolved ? new Date().toISOString() : null
      });
      
      // Add to assistance history
      await addDoc(collection(db, 'mentorAssistanceHistory'), {
        studentId,
        mentorId: user?.id,
        requestMessage: studentDoc.data().mentorAssistanceMessage,
        requestedAt: studentDoc.data().mentorAssistanceRequestedAt,
        responseMessage: response,
        respondedAt: new Date().toISOString(),
        resolved,
        resolvedAt: resolved ? new Date().toISOString() : null,
        createdAt: serverTimestamp()
      });
      
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mentor', 'students'] });
    }
  });

  return {
    mentorCohorts,
    managedStudents,
    freeloBoards,
    isCohortsLoading,
    isStudentsLoading,
    isBoardsLoading,
    isLoading: isCohortsLoading || isStudentsLoading || isBoardsLoading,
    updateCohortDetails: updateCohortDetailsMutation.mutateAsync,
    respondToAssistanceRequest: respondToAssistanceRequestMutation.mutateAsync,
    isUpdatingCohort: updateCohortDetailsMutation.isPending,
    isRespondingToAssistance: respondToAssistanceRequestMutation.isPending,
    // Derived data
    studentsRequestingAssistance: managedStudents.filter(s => s.pendingMentorAssistance),
    activeStudents: managedStudents.filter(s => s.status === 'active'),
    inactiveStudents: managedStudents.filter(s => s.status === 'inactive'),
    readyStudents: managedStudents.filter(s => s.status === 'ready'),
  };
}; 