import { useState, useEffect, useCallback } from "react";
import { initializeEversignSDK } from "core/eversign";

export const useEversignSDK = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const initSDK = useCallback(async () => {
    try {
      await initializeEversignSDK();
      setIsLoaded(true);
    } catch (err) {
      console.error("Error in initSDK:", err);
      setError(err as Error);
    }
  }, []);

  useEffect(() => {
    initSDK();

    return () => {
      const script = document.querySelector(
        'script[src="https://static.eversign.com/js/embedded-signing.js"]'
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [initSDK]);

  return {
    // SDK Status
    isLoaded,
    error,
    initSDK,
  };
};
