import React from "react";
import { Box, Typography, Tooltip, Chip } from "@mui/material";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { Timeline as TimelineIcon } from "@mui/icons-material";
import { IndependentEngineerAgreement, Freelancer } from "@freetech/models/user";

interface FreelancerStatusTimelineProps {
  freelancerProfile: Freelancer;
  independentEngineerAgreement: IndependentEngineerAgreement | null;
  projectAssignmentStatusCounts: {
    interviewing: number;
    ready: number;
    signing: number;
    in_progress: number;
    complete: number;
    cancelled: number;
  };
}

const TimelineStep = ({
  completed,
  label,
}: {
  completed: boolean;
  label: string;
}) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
    <CheckCircleIcon
      sx={{
        color: completed ? "#4caf50" : "grey.300",
        mr: 1.5,
        fontSize: "1.2rem",
      }}
    />
    <Typography
      sx={{
        color: completed ? "grey.900" : "grey.500",
        fontSize: "0.9rem",
        fontWeight: completed ? 500 : 400,
      }}
    >
      {label}
    </Typography>
  </Box>
);

export const FreelancerStatusTimeline: React.FC<
  FreelancerStatusTimelineProps
> = ({
  freelancerProfile,
  independentEngineerAgreement,
  projectAssignmentStatusCounts,
}) => {
  const hasAgreement = !!independentEngineerAgreement;
  const hasProjects = !!freelancerProfile?.projectAssignments?.length;
  const hasActiveProjects = projectAssignmentStatusCounts.in_progress > 0;
  const hasCompletedProjects = projectAssignmentStatusCounts.complete > 0;

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <TimelineIcon 
          sx={{ 
            color: 'primary.main',
            fontSize: '1.5rem',
            mr: 1.5 
          }} 
        />
        <Typography
          variant="h6"
          sx={{
            color: "grey.900",
            fontWeight: 600,
          }}
        >
          Freelancer Status
        </Typography>
      </Box>
      <TimelineStep completed={true} label="Profile Created" />
      <TimelineStep
        completed={hasAgreement}
        label="Independent Engineer Agreement Signed"
      />
      <TimelineStep
        completed={hasProjects}
        label="Project Assignment Received"
      />
      <TimelineStep
        completed={hasActiveProjects}
        label="Project Assignment Started"
      />
      <TimelineStep
        completed={hasCompletedProjects}
        label="Project Assignment Completed"
      />
    </Box>
  );
};
