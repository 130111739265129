import React, { useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import FreelancerPayrollOnboarding from "./components/FreelancerPayrollOnboarding";
import FreelancerPayrollHome from "./components/FreelancerPayrollHome";
import { Box, CircularProgress } from "@mui/material";

const FreelancerPayroll: React.FC = () => {
  const { currentUser } = useAuth();
  const {
    freelancerProfile: freelancer,
    isLoadingFreelancerProfile: isLoading,
  } = useFreelancerProfile();
  const [hasStartedSetup, setHasStartedSetup] = useState(false);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Show onboarding if payment profile is not completed and no new tax form is requested
  if (
    (!freelancer?.payrollProfileCompleted) &&
    !freelancer?.newTaxFormRequested
  ) {
    return (
      <FreelancerPayrollOnboarding
        onBeginSetup={() => setHasStartedSetup(true)}
      />
    );
  }

  // Show main payroll view for all other cases
  return <FreelancerPayrollHome />;
};

export default FreelancerPayroll;
