import {
  EversignDocumentBody,
  EversignDocumentRequestBody,
  Field,
} from "@freetech/models/eversign";
import { sendMailTemplate } from "core/mail";
import { Freelancer, IndependentEngineerAgreement } from "@freetech/models/user";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { getDefaultTemplateId } from "./getDefaultTemplateId";
import { portalFunctions } from "core/functions/portalFunctions";

const agreementFields: Field[] = [
  { identifier: "start_date", value: "" },
  { identifier: "end_date", value: "" },
];

export const sendUpdatedIndependentEngineerAgreement = async (
  freelancer: Freelancer,
  sandbox: boolean
): Promise<void> => {
  try {
    console.log("Starting sendUpdatedIndependentEngineerAgreement", {
      freelancerId: freelancer.id,
      freelancerEmail: freelancer.email,
      sandbox,
    });

    const templateId = await getDefaultTemplateId();

    if (!templateId) {
      throw new Error("No default template ID found");
    }

    const documentBody: EversignDocumentBody = {
      template_id: templateId,
      title: "Independent Engineer Agreement",
      message:
        "Please review and sign the updated Independent Engineer Agreement.",
      redirect: "https://portal.freetech.co/sign-agreements",
      redirect_decline: "https://portal.freetech.co/sign-agreements",
      signers: [
        {
          name: "Adam Siwiec",
          email: "adam@freetech.co",
          deliver_email: 0,
          role: "Adam Siwiec",
          order: 1,
          language: "en",
        },
        {
          name: `${freelancer.firstName} ${freelancer.lastName}`,
          email: freelancer.email,
          deliver_email: 0,
          role: "Contractor",
          order: 2,
          language: "en",
        },
      ],
      fields: agreementFields.map((field) => {
        if (field.identifier === "start_date") {
          return { ...field, value: new Date().toISOString().split("T")[0] };
        }
        if (field.identifier === "end_date") {
          return { ...field, value: new Date().toISOString().split("T")[0] };
        }
        return field;
      }),
      sandbox: sandbox ? 1 : 0,
      embedded_signing_enabled: 1,
      recipients: [],
    };

    const requestBody: EversignDocumentRequestBody = {
      created_by: "adam@freetech.co",
      document_data: documentBody,
      sandbox: sandbox ? 1 : 0,
    };

    const response = await portalFunctions.eversign.useEversignTemplate({
      documentData: requestBody,
      sandbox: sandbox ? 1 : 0,
    });

    const responseText = await response.text();

    if (!response.ok) {
      throw new Error(
        `Failed to send agreement to ${freelancer.email}. Status: ${response.status}, Response: ${responseText}`
      );
    }

    const eversignResponse = JSON.parse(responseText);

    // Store the agreement in the independentEngineerAgreements subcollection
    const agreementsCollectionRef = collection(
      db,
      "users",
      freelancer.id,
      "independentEngineerAgreements"
    );

    const agreementDoc: Omit<IndependentEngineerAgreement, "id"> = {
      userId: freelancer.id,
      eversignDocumentHash: eversignResponse?.document_hash || "",
      eversignUserDocumentEmbedUrl:
        eversignResponse?.signers[1]?.embedded_signing_url || "",
      eversignAdminDocumentEmbedUrl:
        eversignResponse?.signers[0]?.embedded_signing_url || "",
      everSignDocumentStatus: "awaiting_admin_signature",
      status: "signing",
      sentDate: new Date().toISOString(),
      sandbox,
    };

    // Remove any undefined values before adding to Firestore
    const cleanedAgreementDoc = Object.fromEntries(
      Object.entries(agreementDoc).filter(([_, value]) => value !== undefined)
    );

    // Add the agreement to the subcollection
    const agreementRef = await addDoc(
      agreementsCollectionRef,
      cleanedAgreementDoc
    );

    // Update the freelancer's current agreement reference
    const freelancerRef = doc(db, "users", freelancer.id);
    await updateDoc(freelancerRef, {
      currentIndependentEngineerAgreementId: agreementRef.id,
    });

    // Send email notification
    await sendMailTemplate(
      freelancer.email,
      "Updated Independent Engineer Agreement Ready for Review",
      "agreementNotification",
      {
        name: `${freelancer.firstName} ${freelancer.lastName}`,
        url: "https://portal.freetech.co/sign-agreements",
      }
    );

    console.log(
      "Successfully completed sendUpdatedIndependentEngineerAgreement"
    );
  } catch (error) {
    console.error("Error in sendUpdatedIndependentEngineerAgreement:", {
      error,
      stack: error instanceof Error ? error.stack : undefined,
      freelancerId: freelancer.id,
      freelancerEmail: freelancer.email,
    });
    throw error;
  }
};
