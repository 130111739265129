import React from "react";
import { Badge, keyframes } from "@mui/material";
import { AttachMoney as AttachMoneyIcon, ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";

// Define a keyframe animation for a pulsing effect
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const StakeholderInvoiceCount: React.FC = () => {
  const { clientInvoices } = useStakeholderPayments();
  const unpaidInvoicesCount = clientInvoices?.filter(invoice => invoice.status === "unpaid").length || 0;

  return (
    <Badge
      color="error"
      badgeContent={unpaidInvoicesCount}
      invisible={unpaidInvoicesCount === 0}
      sx={{
        "& .MuiBadge-badge": { right: -5 },
        animation: unpaidInvoicesCount > 0 ? `${pulse} 1.5s infinite` : "none",
      }}
    >
      <AttachMoneyIcon />
    </Badge>
  );
};
