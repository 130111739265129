import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Breadcrumbs,
  Link,
  Typography,
  LinkProps,
  Skeleton,
  Box,
} from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Navigation } from "types/navigation";
import {
  resolveBreadcrumbName,
  shouldSkipSegment,
} from "./breadcrumbResolvers";
import { motion, AnimatePresence } from "framer-motion";

const MotionBox = motion(Box);

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink} />;
}

// Function to find the breadcrumb name from navigation items
const findBreadcrumbName = (
  path: string,
  navigationItems: Navigation
): string => {
  const findInItems = (
    items: Navigation,
    searchPath: string
  ): string | null => {
    for (const item of items) {
      if ("segment" in item) {
        const itemPath = `/${item.segment}`;
        if (itemPath === searchPath) {
          return item.title;
        }
        if (item.children) {
          const foundInChildren = findInItems(item.children, searchPath);
          if (foundInChildren) return foundInChildren;
        }
      }
    }
    return null;
  };

  const name = findInItems(navigationItems, path);
  return name || path.split("/").pop() || "";
};

interface NavigationBreadcrumbsProps {
  navigationItems: Navigation;
  disabled?: boolean;
}

export const CoreBreadcrumbs: React.FC<NavigationBreadcrumbsProps> = ({
  navigationItems,
  disabled = false,
}) => {
  const location = useLocation();
  const [resolvedNames, setResolvedNames] = useState<{ [key: string]: string }>(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const resolveNames = async () => {
      setIsLoading(true);
      const newResolvedNames: { [key: string]: string } = {};

      // Build paths for each level
      let currentPath = "";
      for (const segment of pathnames) {
        currentPath += "/" + segment;
        if (!shouldSkipSegment(segment)) {
          const resolvedName = await resolveBreadcrumbName(currentPath);
          if (resolvedName) {
            newResolvedNames[currentPath] = resolvedName;
          }
        }
      }

      setResolvedNames(newResolvedNames);
      setIsLoading(false);
    };

    resolveNames();
  }, [location.pathname]);

  // Don't show breadcrumbs on these pages
  if (disabled) {
    return null;
  }

  const pathnames = location.pathname.split("/").filter((x) => x);
  const filteredPathnames = pathnames.filter(
    (segment) => !shouldSkipSegment(segment)
  );

  const isUserTypePath =
    filteredPathnames.length === 1 &&
    ["stakeholder", "freelancer", "superuser"].includes(
      filteredPathnames[0].toLowerCase()
    );

  const breadcrumbVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
  };

  const renderBreadcrumb = (value: string, index: number, isLast: boolean) => {
    const to = "/" + pathnames.slice(0, pathnames.indexOf(value) + 1).join("/");
    const resolvedName = resolvedNames[to];
    const breadcrumbName =
      resolvedName || findBreadcrumbName(to, navigationItems);

    if (isLoading && resolvedName === undefined) {
      return (
        <Box key={to} component="li">
          <Skeleton width={100} height={24} sx={{ display: "inline-block" }} />
        </Box>
      );
    }

    const content = (
      <LinkRouter
        underline={isLast ? "none" : "hover"}
        color={isLast ? "text.primary" : "inherit"}
        to={to}
        sx={{
          fontSize: 14,
          cursor: isLast ? "default" : "pointer",
          "&:hover": {
            textDecoration: isLast ? "none" : "underline",
          },
        }}
      >
        {breadcrumbName}
      </LinkRouter>
    );

    if (isLast) {
      return (
        <Box component="li" key={to}>
          <MotionBox
            initial="initial"
            animate="animate"
            exit="exit"
            variants={breadcrumbVariants}
            transition={{ duration: 0.2 }}
            sx={{ display: "inline-block" }}
          >
            {content}
          </MotionBox>
        </Box>
      );
    }

    return (
      <Box component="li" key={to} sx={{ display: "inline-block" }}>
        {content}
      </Box>
    );
  };

  const HomeLink = (
    <Box component="li" key="home" sx={{ display: "inline-block" }}>
      <LinkRouter
        to={`/${filteredPathnames[0]}`}
        color="inherit"
        sx={{
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <HomeRoundedIcon sx={{ mr: 0.5, fontSize: 20 }} />
        Home
      </LinkRouter>
    </Box>
  );

  const commonBreadcrumbsProps = {
    separator: <ChevronRightRoundedIcon sx={{ fontSize: 16 }} />,
    sx: {
      mb: 2,
      "& .MuiBreadcrumbs-ol": {
        gap: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "nowrap",
      },
      "& .MuiBreadcrumbs-li": {
        display: "flex",
        alignItems: "center",
        minHeight: 24,
      },
      "& .MuiBreadcrumbs-separator": {
        mx: 1,
      },
    },
  };

  if (isUserTypePath) {
    return <Breadcrumbs {...commonBreadcrumbsProps}>{HomeLink}</Breadcrumbs>;
  }

  return (
    <Breadcrumbs {...commonBreadcrumbsProps}>
      {HomeLink}
      {filteredPathnames
        .slice(1)
        .map((value, index) =>
          renderBreadcrumb(
            value,
            index,
            index === filteredPathnames.slice(1).length - 1
          )
        )}
    </Breadcrumbs>
  );
};
