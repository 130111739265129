import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Stack,
  Card,
  CardContent,
  Button,
  Divider,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import CodeIcon from "@mui/icons-material/Code";
import AddIcon from "@mui/icons-material/Add";
import { Board } from "@freetech/models/freelo";
import { PublicUserInfoType } from "@freetech/models/user";

interface FreeloAdminHeaderProps {
  boards: Board[];
  userInfo: PublicUserInfoType;
  users?: PublicUserInfoType[];
  onCreateBoard: () => void;
}

export const FreeloAdminHeader: React.FC<FreeloAdminHeaderProps> = ({
  boards,
  userInfo,
  users,
  onCreateBoard,
}) => {
  // Calculate some statistics for admins
  const getBoardStatistics = () => {
    let totalBoards = boards.length;
    let totalUsers = new Set<string>();
    let totalCards = 0;

    boards.forEach((board) => {
      // Count all users with access to any board
      board.readUserIds?.forEach((id) => totalUsers.add(id));
      board.writeUserIds?.forEach((id) => totalUsers.add(id));

      // Count all cards
      board.lists?.forEach((list) => {
        totalCards += list.cards?.length || 0;
      });
    });

    return {
      totalBoards,
      totalUsers: totalUsers.size,
      totalCards,
    };
  };

  const stats = getBoardStatistics();

  return (
    <Paper
      elevation={0}
      sx={{ p: 3, mb: 4, bgcolor: "background.default", borderRadius: 2 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Box>
            <Typography variant="h5" gutterBottom>
              Welcome back, {userInfo.firstName}!
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Manage your Freelo boards and monitor project progress across the organization.
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            onClick={onCreateBoard}
            sx={{ mt: 1 }}
          >
            Create Board
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <DashboardIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Board Statistics</Typography>
              </Box>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {stats.totalBoards}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Boards
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {stats.totalCards}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Cards
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <PeopleIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Team Overview</Typography>
              </Box>
              <Typography variant="h4" gutterBottom>
                {stats.totalUsers}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Users with Board Access
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <CodeIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">MCP Integration</Typography>
              </Box>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Freelo boards are integrated with the Model Context Protocol (MCP) to directly align software development with client requirements.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                MCP enables AI assistants to access and understand board data, improving development efficiency.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            <strong>Admin Tip:</strong> When creating new boards for projects, ensure they have descriptive names and 
            appropriate access permissions to maximize their effectiveness with the MCP protocol.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}; 