import { useCallback } from "react";
import { createInvoice, deleteInvoice, getInvoicePDFUrl } from "core/invoices";
import { ClientInvoiceData } from "@freetech/models/bank";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  query,
  getFirestore,
  collectionGroup,
  getDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { Client } from "@freetech/models/projects";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { portalFunctions } from "core/functions/portalFunctions";

// Extend the ClientInvoiceData type to include isClientSpecific
export interface ExtendedClientInvoiceData extends ClientInvoiceData {
  isClientSpecific?: boolean;
  // Remove any fields that don't exist in ClientInvoiceData
}

export const useAdminInvoicing = () => {
  const queryClient = useQueryClient();

  // Get all clients for reference
  const { data: clients = [] } = useQuery<Client[]>({
    queryKey: ["clients"],
    queryFn: async () => {
      try {
        const clientsRef = collection(db, "clients");
        const clientsSnapshot = await getDocs(clientsRef);
        return clientsSnapshot.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id }) as Client
        );
      } catch (error) {
        console.error("Error fetching clients:", error);
        return [];
      }
    },
    staleTime: 5 * 60 * 1000,
  });

  // Get all invoices
  const getInvoices = useCallback(async () => {
    try {
      let allInvoices: ExtendedClientInvoiceData[] = [];

      // Get all invoices from all clients
      const invoicesQuery = query(collectionGroup(db, "invoices"));
      const invoicesSnapshot = await getDocs(invoicesQuery);

      if (!invoicesSnapshot.empty) {
        allInvoices = invoicesSnapshot.docs.map((doc) => {
          const data = doc.data() as ExtendedClientInvoiceData;
          // Extract clientId from the path
          const path = doc.ref.path.split("/");
          const clientId = path[path.indexOf("clients") + 1];

          return {
            ...data,
            id: doc.id,
            clientId: clientId,
          };
        });

        // Try to add client names if possible
        if (clients.length > 0) {
          allInvoices = allInvoices.map((invoice) => {
            const client = clients.find((c) => c.id === invoice.clientId);
            return {
              ...invoice,
              clientName: client?.companyName || "",
            };
          });
        }
      }

      // Sort invoices by date (newest first)
      return allInvoices.sort((a, b) => {
        // If createdAt is available, use it
        if (a.createdAt && b.createdAt) {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        }
        // Otherwise fall back to invoice date
        return (
          new Date(b.invoiceDate).getTime() - new Date(a.invoiceDate).getTime()
        );
      });
    } catch (error) {
      console.error("Error fetching invoices:", error);
      return [];
    }
  }, [clients]);

  // Query for all invoices
  const {
    data: invoices = [],
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
  } = useQuery<ExtendedClientInvoiceData[], Error>({
    queryKey: ["invoices", clients],
    queryFn: getInvoices,
    staleTime: 5 * 60 * 1000,
  });

  // Function to get all versions of an invoice
  const getInvoiceVersions = useCallback(
    (originalInvoiceId: string) => {
      // Find the invoice by ID
      const invoice = invoices.find((inv) => inv.id === originalInvoiceId);

      // Return the oldVersions array or an empty array if not found
      return invoice?.oldVersions || [];
    },
    [invoices]
  );

  // Mutation for creating invoices
  const createInvoiceMutation = useMutation({
    mutationFn: (data: ExtendedClientInvoiceData) => createInvoice(data),
    onSuccess: () => {
      // Invalidate queries to refresh data
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Mutation for deleting invoices
  const deleteInvoiceMutation = useMutation({
    mutationFn: (params: { clientId: string; invoiceId: string }) =>
      deleteInvoice(params.clientId, params.invoiceId),
    onSuccess: () => {
      // Invalidate queries to refresh data
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Mutation for updating invoices
  const updateInvoiceMutation = useMutation({
    mutationFn: async (params: {
      invoiceId: string;
      clientId: string;
      data: Partial<ExtendedClientInvoiceData>;
    }) => {
      try {
        // Get the current invoice data
        const invoiceRef = doc(
          db,
          "clients",
          params.clientId,
          "invoices",
          params.invoiceId
        );

        // Get the current invoice data to save as a version
        const invoiceSnapshot = await getDoc(invoiceRef);
        if (!invoiceSnapshot.exists()) {
          throw new Error(`Invoice ${params.invoiceId} not found`);
        }

        const currentInvoiceData =
          invoiceSnapshot.data() as ExtendedClientInvoiceData;

        // Create a version timestamp
        const versionTimestamp = new Date().toISOString();

        // Create a version object from the current data (omitting id and oldVersions)
        const { id, oldVersions, ...versionData } = currentInvoiceData;

        // Calculate the version number (current number of versions)
        const versionNumber = currentInvoiceData.oldVersions?.length || 0;

        // Add the version timestamp and a formatted version
        const versionWithTimestamp = {
          ...versionData,
          versionTimestamp,
          createdAt: currentInvoiceData.createdAt || versionTimestamp, // Preserve the original creation date
          totalDollarAmountDue: currentInvoiceData.totalDollarAmountDue, // Preserve the original amount
          versionLabel: `Version 1 - ${new Date().toLocaleString()}`,
        };

        // First, save the current PDF as a versioned file if it exists
        if (currentInvoiceData.pdfUrl) {
          try {
            // Get the current PDF content
            const storage = getStorage();
            const response = await fetch(currentInvoiceData.pdfUrl);
            const pdfBlob = await response.blob();

            // Save it with a version suffix
            const versionedPath = `clients/${params.clientId}/invoices/${params.invoiceId}/version_${versionNumber + 1}.pdf`;
            const versionedRef = ref(storage, versionedPath);

            // Upload the current PDF as a versioned file
            await uploadBytes(versionedRef, pdfBlob, {
              contentType: "application/pdf",
              customMetadata: {
                versionTimestamp,
                versionNumber: versionNumber.toString(),
              },
            });

            console.log(
              `Saved current PDF as version ${versionNumber} at ${versionedPath}`
            );
          } catch (error) {
            console.error("Error saving current PDF as versioned file:", error);
            // Continue even if this fails
          }
        }

        // Create the updated invoice data
        const updatedInvoiceData = {
          ...params.data,
          // Add the current data as a version at the beginning of the array
          oldVersions: [
            versionWithTimestamp,
            ...(currentInvoiceData.oldVersions || []),
          ],
        };

        // Update the invoice document
        await updateDoc(invoiceRef, updatedInvoiceData);

        // Create a complete invoice object for PDF generation
        const completeInvoice = {
          ...currentInvoiceData,
          ...updatedInvoiceData,
          id: params.invoiceId,
          clientId: params.clientId,
        } as ExtendedClientInvoiceData;

        // Regenerate the PDF with the updated data
        console.log("Regenerating PDF with updated data:", completeInvoice);

        try {
          // Import the createAndSavePDF function
          const { createAndSavePDF } = await import("core/invoices");

          // Generate a new PDF - this will create a versioned PDF
          const pdfUrl = await createAndSavePDF(
            completeInvoice,
            completeInvoice.clientId,
            completeInvoice.id
          );

          // Update the invoice with the new PDF URL
          await updateDoc(invoiceRef, { pdfUrl });

          console.log("PDF regenerated successfully with URL:", pdfUrl);
        } catch (pdfError) {
          console.error("Error regenerating PDF:", pdfError);
          // Continue with the update even if PDF generation fails
        }

        return completeInvoice;
      } catch (error) {
        console.error("Error updating invoice:", error);
        throw error;
      }
    },
    onSuccess: () => {
      // Invalidate queries to refresh data
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Function to view an invoice PDF
  const viewInvoicePDF = useCallback(
    async (clientId: string, invoiceId: string, customPath?: string) => {
      try {
        console.log(
          `Attempting to view PDF for invoice ${invoiceId}${customPath ? ` at custom path: ${customPath}` : ""}`
        );

        if (customPath) {
          // If a custom path is provided, get the download URL directly
          const storage = getStorage();
          const pdfRef = ref(storage, customPath);
          try {
            return await getDownloadURL(pdfRef);
          } catch (error) {
            console.error(
              `Error getting PDF from custom path ${customPath}:`,
              error
            );
            throw error;
          }
        } else {
          // Otherwise use the standard function
          try {
            const url = await getInvoicePDFUrl(clientId, invoiceId);
            return url;
          } catch (error) {
            console.error(
              `Error getting PDF URL for invoice ${invoiceId}:`,
              error
            );
            throw error;
          }
        }
      } catch (error) {
        console.error("Error fetching PDF URL:", error);
        throw error;
      }
    },
    []
  );

  // Mutation for copying an invoice
  const copyInvoiceMutation = useMutation({
    mutationFn: async ({ 
      sourceInvoiceId, 
      clientId,
      newInvoiceNumber
    }: { 
      sourceInvoiceId: string; 
      clientId: string; 
      newInvoiceNumber?: string;
    }) => {
      try {
        // Get the source invoice data
        const invoiceRef = doc(
          db,
          "clients",
          clientId,
          "invoices",
          sourceInvoiceId
        );

        const invoiceSnapshot = await getDoc(invoiceRef);
        if (!invoiceSnapshot.exists()) {
          throw new Error(`Invoice ${sourceInvoiceId} not found`);
        }

        const sourceInvoiceData = invoiceSnapshot.data() as ExtendedClientInvoiceData;
        
        // Create a copy of the invoice data, excluding fields we don't want to copy
        const { 
          id, 
          oldVersions, 
          pdfUrl, 
          invoiceNumber, 
          status, 
          createdAt, 
          partialPaymentAmount,
          stakeholderPaymentIds,
          ...invoiceDataToCopy 
        } = sourceInvoiceData;
        
        // Set new invoice data
        const now = new Date();
        const newInvoiceData: Omit<ExtendedClientInvoiceData, 'id'> = {
          ...invoiceDataToCopy,
          invoiceNumber: newInvoiceNumber || `COPY-${invoiceNumber || sourceInvoiceId}-${now.getTime().toString().slice(-6)}`,
          invoiceDate: now.toISOString().split('T')[0], // Today's date
          createdAt: now.toISOString(),
          status: "unpaid",
          clientId,
        };

        // Create the new invoice
        const createdInvoice = await createInvoice(newInvoiceData);
        return createdInvoice;
      } catch (error) {
        console.error("Error copying invoice:", error);
        throw error;
      }
    },
    onSuccess: () => {
      // Invalidate queries to refresh data
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Mutation for sending invoice reminders
  const sendInvoiceRemindersMutation = useMutation({
    mutationFn: ({ clientId, invoiceId, stakeholderIds }: { clientId: string; invoiceId: string; stakeholderIds: string[] }) =>
      portalFunctions.admin.sendInvoiceReminders(clientId, invoiceId, stakeholderIds),
    onSuccess: () => {
      // Invalidate queries to refresh data
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
    },
  });

  return {
    // Queries
    invoices,
    isLoadingInvoices,
    isErrorInvoices,

    // Mutations
    createInvoice: createInvoiceMutation.mutate,
    isCreatingInvoice: createInvoiceMutation.isPending,
    createInvoiceError: createInvoiceMutation.error,

    deleteInvoice: deleteInvoiceMutation.mutate,
    isDeletingInvoice: deleteInvoiceMutation.isPending,
    deleteInvoiceError: deleteInvoiceMutation.error,

    updateInvoice: updateInvoiceMutation.mutate,
    isUpdatingInvoice: updateInvoiceMutation.isPending,
    updateInvoiceError: updateInvoiceMutation.error,
    
    copyInvoice: copyInvoiceMutation.mutate,
    isCopyingInvoice: copyInvoiceMutation.isPending,
    copyInvoiceError: copyInvoiceMutation.error,

    // Helper functions
    getInvoiceVersions,
    viewInvoicePDF,

    sendInvoiceReminders: sendInvoiceRemindersMutation.mutate,
    isSendingReminders: sendInvoiceRemindersMutation.isPending,
    sendInvoiceRemindersError: sendInvoiceRemindersMutation.error,
  };
};
