import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { storage } from "config/firebase";
import { ref, deleteObject } from "firebase/storage";
import { FeatureRequestAttachment } from "@freetech/models";

/**
 * Removes an attachment from a feature request and deletes it from storage
 * @param clientId The ID of the client
 * @param featureRequestId The ID of the feature request
 * @param attachmentId The ID of the attachment to remove
 */
export const removeFeatureRequestAttachment = async (
  clientId: string,
  featureRequestId: string,
  attachmentId: string
): Promise<void> => {
  try {
    const featureRequestRef = doc(db, "clients", clientId, "featureRequests", featureRequestId);

    // Get current attachments
    const featureRequestDoc = await getDoc(featureRequestRef);
    if (!featureRequestDoc.exists()) {
      throw new Error("Feature request not found");
    }
    
    const currentAttachments = featureRequestDoc.data()?.attachments || [];
    
    // Find the attachment to remove
    const attachmentToRemove = currentAttachments.find(
      (attachment: FeatureRequestAttachment) => attachment.id === attachmentId
    );
    
    if (!attachmentToRemove) {
      throw new Error("Attachment not found");
    }
    
    // Try to delete the file from storage
    try {
      // Extract the file path from the URL or use the attachment name
      const fileName = attachmentToRemove.name;
      const storageRef = ref(storage, `featureRequests/${clientId}/${featureRequestId}/${fileName}`);
      await deleteObject(storageRef);
    } catch (error) {
      console.error("Error deleting file from storage:", error);
      // Continue even if storage deletion fails
    }
    
    // Filter out the attachment from the list
    const updatedAttachments = currentAttachments.filter(
      (attachment: FeatureRequestAttachment) => attachment.id !== attachmentId
    );
    
    // Update the feature request document
    await updateDoc(featureRequestRef, {
      attachments: updatedAttachments,
      updatedAt: new Date().toISOString(),
    });
    
    console.log(`Attachment ${attachmentId} removed from feature request ${featureRequestId}`);
  } catch (error) {
    console.error("Error removing attachment:", error);
    throw error;
  }
}; 