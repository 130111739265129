import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  doc,
  getDocs,
  writeBatch,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { useEffect } from "react";
import {
  INotification,
  NotificationPreferences,
} from "@freetech/models/notifications";
import { portalFunctions } from "../../core/functions/portalFunctions";

export const useNotifications = (userId: string) => {
  const queryClient = useQueryClient();

  const { data: notifications = [] } = useQuery({
    queryKey: ["notifications", userId],
    queryFn: async () => {
      const q = query(
        collection(db, "notifications"),
        where("recipientId", "==", userId),
        orderBy("createdAt", "desc"),
        limit(10)
      );

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toLocaleString(),
      })) as INotification[];
    },
    staleTime: Infinity,
    enabled: !!userId,
  });

  const {
    data: notificationPreferences,
    refetch: refetchNotificationPreferences,
  } = useQuery({
    queryKey: ["notificationPreferences", userId],
    queryFn: async () => {
      if (!userId) {
        throw new Error(
          "User ID is required to fetch notification preferences"
        );
      }
      return portalFunctions.shared.getNotificationPreferences(userId);
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled: !!userId,
  });

  useEffect(() => {
    if (!userId) return;

    const q = query(
      collection(db, "notifications"),
      where("recipientId", "==", userId),
      orderBy("createdAt", "desc"),
      limit(10)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          queryClient.setQueryData(
            ["notifications", userId],
            (oldData: INotification[] = []) => {
              const newNotification = {
                id: change.doc.id,
                ...change.doc.data(),
                createdAt: change.doc.data().createdAt,
              } as INotification;

              const filteredOldData = oldData.filter(
                (n) => n.id !== newNotification.id
              );
              return [newNotification, ...filteredOldData].slice(0, 10);
            }
          );
        }

        if (change.type === "removed") {
          queryClient.setQueryData(
            ["notifications", userId],
            (oldData: INotification[] = []) =>
              oldData.filter((n) => n.id !== change.doc.id)
          );
        }
      });
    });

    return () => unsubscribe();
  }, [userId, queryClient]);

  const markAsRead = useMutation({
    mutationFn: async (notificationId: string) => {
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, { read: true });
    },
    onMutate: async (notificationId: string) => {
      await queryClient.cancelQueries({ queryKey: ["notifications", userId] });

      const previousNotifications = queryClient.getQueryData([
        "notifications",
        userId,
      ]);

      queryClient.setQueryData(
        ["notifications", userId],
        (old: INotification[] = []) =>
          old.map((notification) =>
            notification.id === notificationId
              ? { ...notification, read: true }
              : notification
          )
      );

      return { previousNotifications };
    },
    onError: (err, variables, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData(
          ["notifications", userId],
          context.previousNotifications
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications", userId] });
    },
  });

  const markAllAsRead = useMutation({
    mutationFn: async () => {
      const batch = writeBatch(db);
      notifications
        .filter((n) => !n.read)
        .forEach((n) => {
          const ref = doc(db, "notifications", n.id!);
          batch.update(ref, { read: true });
        });
      await batch.commit();
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["notifications", userId] });

      const previousNotifications = queryClient.getQueryData([
        "notifications",
        userId,
      ]);

      queryClient.setQueryData(
        ["notifications", userId],
        (old: INotification[] = []) =>
          old.map((notification) => ({ ...notification, read: true }))
      );

      return { previousNotifications };
    },
    onError: (err, variables, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData(
          ["notifications", userId],
          context.previousNotifications
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications", userId] });
    },
  });

  const updateNotificationPreferences = useMutation({
    mutationFn: async (preferences: NotificationPreferences) => {
      if (!userId) {
        throw new Error(
          "User ID is required to update notification preferences"
        );
      }

      // Use the portalFunctions to update notification preferences
      const result = await portalFunctions.shared.updateNotificationPreferences(
        userId,
        preferences
      );
      console.log("Preferences updated successfully:", result);
      return result;
    },
    onMutate: async (newPreferences: NotificationPreferences) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({
        queryKey: ["notificationPreferences", userId],
      });

      // Snapshot the previous value
      const previousPreferences = queryClient.getQueryData([
        "notificationPreferences",
        userId,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["notificationPreferences", userId],
        newPreferences
      );

      console.log("Optimistically updated preferences:", newPreferences);

      // Return a context object with the previous preferences
      return { previousPreferences };
    },
    onError: (err, newPreferences, context) => {
      console.error("Error updating preferences:", err);

      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousPreferences) {
        queryClient.setQueryData(
          ["notificationPreferences", userId],
          context.previousPreferences
        );
        console.log(
          "Rolled back to previous preferences:",
          context.previousPreferences
        );
      }
    },
    onSuccess: (data) => {
      console.log("Preferences updated successfully, received data:", data);
    },
    onSettled: () => {
      // Always refetch after error or success to make sure our local data is in sync with the server
      queryClient.invalidateQueries({
        queryKey: ["notificationPreferences", userId],
      });
    },
  });

  return {
    notifications,
    notificationPreferences,
    refetchNotificationPreferences,
    markAsRead,
    markAllAsRead,
    updateNotificationPreferences,
  };
};
