import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Divider,
  Chip,
  Avatar,
  Button,
  Tooltip,
} from "@mui/material";
import {
  Business,
  Email,
  Phone,
  Assignment,
  LocationOn,
  AccessTime,
  Person,
  Check,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Client } from "@freetech/models/projects";
import { useFreelancerProjectAssignments } from "hooks/freelancer";

// Extended client interface to handle additional properties
interface ExtendedClientProps {
  email?: string;
  phone?: string;
  location?: string;
  startDate?: string | Date;
  duration?: string;
}

interface ClientCardProps {
  client: Client & ExtendedClientProps;
}

const ClientCard: React.FC<ClientCardProps> = ({ client }) => {
  // Calculate duration if startDate is available but duration is not
  const getDuration = () => {
    if (client.duration) return client.duration;

    if (client.startDate) {
      const start = new Date(client.startDate);
      const now = new Date();
      const diffMonths =
        (now.getFullYear() - start.getFullYear()) * 12 +
        (now.getMonth() - start.getMonth());

      if (diffMonths < 1) return "Less than a month";
      if (diffMonths === 1) return "1 month";
      if (diffMonths < 12) return `${diffMonths} months`;

      const years = Math.floor(diffMonths / 12);
      const remainingMonths = diffMonths % 12;

      if (remainingMonths === 0) return `${years} year${years > 1 ? "s" : ""}`;
      return `${years} year${years > 1 ? "s" : ""} and ${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
    }

    return undefined;
  };

  const { projectAssignments } = useFreelancerProjectAssignments();

  const activeProjectAssignments =
    projectAssignments?.filter(
      (assignment) => assignment.status === "in_progress"
    ) || [];

  const completedProjectAssignments =
    projectAssignments?.filter(
      (assignment) => assignment.status === "complete"
    ) || [];

  const totalProjectAssignments = projectAssignments?.length || 0;

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        avatar={
          client.logoUrl ? (
            <Avatar src={client.logoUrl} alt={client.companyName} />
          ) : (
            <Avatar sx={{ bgcolor: "primary.main" }}>
              {client.companyName.charAt(0).toUpperCase()}
            </Avatar>
          )
        }
        title={
          <Typography variant="h6" component="div">
            {client.companyName}
          </Typography>
        }
        subheader={
          <>
            {activeProjectAssignments?.length > 0 && (
              <Chip
                icon={<Assignment fontSize="small" />}
                label={`${activeProjectAssignments?.length} active`}
                size="small"
                sx={{ mt: 1 }}
              />
            )}

            {completedProjectAssignments?.length > 0 && (
              <Chip
                icon={<Check fontSize="small" />}
                label={`${completedProjectAssignments?.length} completed`}
                size="small"
                sx={{ mt: 1 }}
              />
            )}
            {/* <Chip
              icon={<Check fontSize="small" />}
              label={`${totalProjectAssignments} total assignments`}
              size="small"
              sx={{ mt: 1 }}
            /> */}
          </>
        }
      />
      <Divider />
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ mb: 2 }}>
          {/* Contact Information */}
          {client.pointOfContactName && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Person
                fontSize="small"
                sx={{ mr: 1, color: "text.secondary" }}
              />
              <Typography variant="body2">
                {client.pointOfContactName}
              </Typography>
            </Box>
          )}

          {client.email && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Email fontSize="small" sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body2">{client.email}</Typography>
            </Box>
          )}

          {client.phone && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Phone fontSize="small" sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body2">{client.phone}</Typography>
            </Box>
          )}

          {/* Location Information */}
          {client.location && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <LocationOn
                fontSize="small"
                sx={{ mr: 1, color: "text.secondary" }}
              />
              <Typography variant="body2">{client.location}</Typography>
            </Box>
          )}

          {/* Duration with FreeTech */}
          {(client.startDate || client.duration) && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <AccessTime
                fontSize="small"
                sx={{ mr: 1, color: "text.secondary" }}
              />
              <Tooltip
                title={
                  client.startDate
                    ? `Since ${new Date(client.startDate).toLocaleDateString()}`
                    : ""
                }
              >
                <Typography variant="body2">
                  {getDuration() ? `Client for ${getDuration()}` : "New client"}
                </Typography>
              </Tooltip>
            </Box>
          )}

          {/* Projects Information */}
          {client.projects?.length !== undefined && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Assignment
                fontSize="small"
                sx={{ mr: 1, color: "text.secondary" }}
              />
              <Typography variant="body2">
                {client.projects?.length} Project
                {client.projects?.length !== 1 ? "s" : ""}
              </Typography>
            </Box>
          )}
        </Box>

        <Button
          component={Link}
          to={`/freelancer/my-clients/${client.id}`}
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
        >
          View Details
        </Button>
      </CardContent>
    </Card>
  );
};

export default ClientCard;
