import {
  INotification,
  CardNotificationData,
  AdminUserNotificationData,
  AdminClientNotificationData,
  AdminProjectNotificationData,
  AdminCSANotificationData,
  AdminInvoiceNotificationData,
  AdminPaymentNotificationData,
  AdminProjectAssignmentNotificationData,
  AdminTimesheetNotificationData,
  AllNotificationType,
} from "@freetech/models/notifications";
import { notificationEnums } from "@freetech/models/notifications";

/**,
  notificFreeloNotificationType, ationEnums.StakeholderNotificationType
 * Type guard to check i,
  AdminNotificationTypef a string is a valid notification type
 */
function isNotificationType(type: string): type is AllNotificationType {
  return (
    Object.values(notificationEnums.StakeholderNotificationType).includes(
      type as notificationEnums.StakeholderNotificationType
    ) ||
    Object.values(notificationEnums.FreeloNotificationType).includes(
      type as notificationEnums.FreeloNotificationType
    ) ||
    Object.values(notificationEnums.AdminNotificationType).includes(
      type as notificationEnums.AdminNotificationType
    )
  );
}

/**
 * Type guard to check if a string is a notificationEnums.StakeholderNotificationType
 */
function isStakeholderNotificationType(
  type: string
): type is notificationEnums.StakeholderNotificationType {
  return Object.values(notificationEnums.StakeholderNotificationType).includes(
    type as notificationEnums.StakeholderNotificationType
  );
}

/**
 * Type guard to check if a string is a notificationEnums.FreeloNotificationType
 */
function isFreeloNotificationType(
  type: string
): type is notificationEnums.FreeloNotificationType {
  return Object.values(notificationEnums.FreeloNotificationType).includes(
    type as notificationEnums.FreeloNotificationType
  );
}

/**
 * Type guard to check if a string is an notificationEnums.AdminNotificationType
 */
function isAdminNotificationType(
  type: string
): type is notificationEnums.AdminNotificationType {
  return Object.values(notificationEnums.AdminNotificationType).includes(
    type as notificationEnums.AdminNotificationType
  );
}

/**
 * Determines the destination URL when a notification is clicked
 * @param notification The notification object
 * @returns The URL to navigate to, or undefined if no navigation should occur
 */
export const handleNotificationClick = (
  notification: INotification
): string | undefined => {
  const { type, data } = notification;

  // If the notification has a link, use it directly
  if (data.link) {
    return data.link;
  }

  // Handle stakeholder notification types
  if (isStakeholderNotificationType(type)) {
    switch (type) {
      // Invoice-related notifications
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_RECIEVED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_PAID:
        // These should have links provided in the data
        return undefined;

      // Payment-related stakeholder notifications
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_CREATED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_COMPLETED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_FAILED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_REVERSED:
        // Navigate to payments page
        return "/payments";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_CSA_RECIEVED:
        // Navigate to CSA page
        return "/csa";
    }
  }

  // Handle freelo notification types
  if (isFreeloNotificationType(type)) {
    switch (type) {
      case notificationEnums.FreeloNotificationType.FREELO_CARD_CREATED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_UPDATED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_DELETED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_ASSIGNED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_COMMENT_MENTION:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_STATUS_CHANGED:
      case notificationEnums.FreeloNotificationType.FREELO_DEADLINE_APPROACHING:
        const cardData = data as CardNotificationData;
        if (cardData.boardId && cardData.humanReadableId) {
          return `/freelo/${cardData.boardId}#${cardData.humanReadableId}`;
        }
        break;

      case notificationEnums.FreeloNotificationType.FREELO_BOARD_UPDATED:
        const boardData = data as CardNotificationData;
        if (boardData.boardId) {
          return `/freelo/${boardData.boardId}`;
        }
        break;
    }
  }

  // Handle admin notification types
  if (isAdminNotificationType(type)) {
    switch (type) {
      // User-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_USER_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_USER_SIGNED_UP:
      case notificationEnums.AdminNotificationType
        .ADMIN_USER_ONBOARDING_COMPLETE:
        const userData = data as AdminUserNotificationData;
        if (userData.userId) {
          return `/admin/users/${userData.userId}`;
        }
        return "/admin/users";

      // Client-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_CLIENT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_CLIENT_UPDATED:
        const clientData = data as AdminClientNotificationData;
        if (clientData.clientId) {
          return `/admin/clients/${clientData.clientId}`;
        }
        return "/admin/clients";

      // Project-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_PROJECT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_PROJECT_UPDATED:
        const projectData = data as AdminProjectNotificationData;
        if (projectData.projectId) {
          return `/admin/projects/${projectData.projectId}`;
        }
        return "/admin/projects";

      // CSA-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_CSA_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_CSA_SIGNED_BY_ADMIN:
      case notificationEnums.AdminNotificationType
        .ADMIN_CSA_SIGNED_BY_STAKEHOLDER:
        const csaData = data as AdminCSANotificationData;
        if (csaData.csaId) {
          return `/admin/csa/${csaData.csaId}`;
        }
        return "/admin/csa";

      // Invoice-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_INVOICE_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_INVOICE_PAID:
        const invoiceData = data as AdminInvoiceNotificationData;
        if (invoiceData.invoiceId) {
          return `/admin/invoices/${invoiceData.invoiceId}`;
        }
        return "/admin/invoices";

      // Payment-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_PAYMENT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_PAYMENT_COMPLETED:
        const paymentData = data as AdminPaymentNotificationData;
        if (paymentData.paymentId) {
          return `/admin/payments/${paymentData.paymentId}`;
        }
        return "/admin/payments";

      // Project assignment-related admin notifications
      case notificationEnums.AdminNotificationType
        .ADMIN_PROJECT_ASSIGNMENT_CREATED:
      case notificationEnums.AdminNotificationType
        .ADMIN_PROJECT_ASSIGNMENT_SIGNED:
        const assignmentData = data as AdminProjectAssignmentNotificationData;
        if (assignmentData.assignmentId) {
          return `/admin/project-assignments/${assignmentData.assignmentId}`;
        }
        return "/admin/project-assignments";

      // Timesheet-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_SUBMITTED:
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_APPROVED:
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_REJECTED:
        const timesheetData = data as AdminTimesheetNotificationData;
        if (timesheetData.timesheetId) {
          return `/admin/timesheets/${timesheetData.timesheetId}`;
        }
        return "/admin/timesheets";
    }
  }

  // If we couldn't determine a destination, return undefined
  return undefined;
};
