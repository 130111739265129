import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Chip,
  OutlinedInput,
  SelectChangeEvent,
  CircularProgress,
  Typography
} from "@mui/material";
import { ReferralFormData, ReferralType } from "@freetech/models/referrals";

interface ReferralFormProps {
  onSubmit: (formData: ReferralFormData) => void;
  onCancel: () => void;
  isSubmitting: boolean;
}

// Industry options for clients
const INDUSTRY_OPTIONS = [
  "Technology",
  "Healthcare",
  "Finance",
  "Education",
  "Retail",
  "Manufacturing",
  "Media & Entertainment",
  "Real Estate",
  "Hospitality",
  "Transportation",
  "Energy",
  "Telecommunications",
  "Professional Services",
  "Non-profit",
  "Government",
  "Construction",
  "Agriculture",
  "Automotive",
  "Aerospace",
  "Pharmaceuticals"
];

// Company size options
const COMPANY_SIZE_OPTIONS = [
  "1-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501-1000 employees",
  "1001-5000 employees",
  "5001+ employees"
];

const ReferralForm: React.FC<ReferralFormProps> = ({ 
  onSubmit, 
  onCancel,
  isSubmitting
}) => {
  const [formData, setFormData] = useState<ReferralFormData>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    type: "previous_client",
    notes: "",
    companyName: "",
    companyWebsite: "",
    companySize: ""
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [industry, setIndustry] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleIndustryChange = (event: SelectChangeEvent<string>) => {
    setIndustry(event.target.value);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    // Required fields for all referral types
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
    
    // Validate phone if provided
    if (formData.phone && !/^\+?[\d\s()-]{10,15}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }
    
    // Client-specific validations
    if (!formData.companyName?.trim()) {
      newErrors.companyName = "Company name is required";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (validateForm()) {
      // Add industry to notes if selected
      const updatedFormData = {...formData};
      if (industry) {
        updatedFormData.notes = `Industry: ${industry}\n${formData.notes || ''}`;
      }
      onSubmit(updatedFormData);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        {/* Referral Type */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="referral-type-label">Referral Type</InputLabel>
            <Select
              labelId="referral-type-label"
              id="type"
              name="type"
              value={formData.type}
              label="Referral Type"
              onChange={handleSelectChange}
            >
              <MenuItem value="previous_client">Previous Client</MenuItem>
              <MenuItem value="warm_lead">Warm Lead</MenuItem>
              <MenuItem value="cold_lead">Cold Lead</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            <FormHelperText>
              {formData.type === "previous_client" 
                ? "A client you've worked with before" 
                : formData.type === "warm_lead" 
                  ? "Someone you know who might need our services" 
                  : formData.type === "cold_lead"
                    ? "A company you think could benefit from our services"
                    : "Any other potential client"}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Company Information */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="companyName"
            name="companyName"
            label="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            error={!!errors.companyName}
            helperText={errors.companyName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="companyWebsite"
            name="companyWebsite"
            label="Company Website"
            value={formData.companyWebsite}
            onChange={handleChange}
            error={!!errors.companyWebsite}
            helperText={errors.companyWebsite || "Optional"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="company-size-label">Company Size</InputLabel>
            <Select
              labelId="company-size-label"
              id="companySize"
              name="companySize"
              value={formData.companySize || ""}
              label="Company Size"
              onChange={handleSelectChange}
            >
              {COMPANY_SIZE_OPTIONS.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Optional</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="industry-label">Industry</InputLabel>
            <Select
              labelId="industry-label"
              id="industry"
              value={industry}
              label="Industry"
              onChange={handleIndustryChange}
            >
              {INDUSTRY_OPTIONS.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Optional</FormHelperText>
          </FormControl>
        </Grid>

        {/* Contact Person Information */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Contact Person
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            name="lastName"
            label="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="email"
            name="email"
            label="Email Address"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="phone"
            name="phone"
            label="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone || "Optional"}
          />
        </Grid>

        {/* Notes field */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="notes"
            name="notes"
            label="Additional Notes"
            multiline
            rows={4}
            value={formData.notes}
            onChange={handleChange}
            helperText="Any additional information about the client or their needs (optional)"
          />
        </Grid>

        {/* Form Actions */}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button 
              variant="outlined" 
              onClick={onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              {isSubmitting ? "Submitting..." : "Submit Client Referral"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferralForm; 