import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useFreelancerPayroll } from "hooks/freelancer/useFreelancerPayroll";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import { useAuth } from "hooks/auth/useAuth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedIcon from "@mui/icons-material/Verified";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewFileDialog from "components/ViewFileDialog";

interface FreelancerVerificationStepProps {
  onBack: () => void;
}

const FreelancerVerificationStep: React.FC<FreelancerVerificationStepProps> = ({
  onBack,
}) => {
  const { currentUser } = useAuth();
  const { freelancerProfile: freelancer } = useFreelancerProfile();
  const {
    paymentProfile,
    completeFreelancerPayrollOnboarding,
    getTaxFormStorageUrl,
  } = useFreelancerPayroll();

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [viewTaxFormDialogOpen, setViewTaxFormDialogOpen] = useState(false);
  const [taxFormFileUrl, setTaxFormFileUrl] = useState<string | null>(null);

  const handleVerify = async () => {
    try {
      setError(null);
      await completeFreelancerPayrollOnboarding();
      setSuccess("Payment and tax information has been verified successfully");
      // Wait for the freelancer profile to be refetched to get the updated verification status
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (!freelancer?.paymentAndTaxProfileVerifiedByUser) {
        setError("Verification status not updated. Please try again.");
        return;
      }
    } catch (err) {
      console.error("Error verifying payment and tax information:", err);
      setError(
        "Failed to verify payment and tax information. Please try again."
      );
    }
  };

  const handleViewTaxForm = async () => {
    try {
      setError(null);
      const url = await getTaxFormStorageUrl();
      setTaxFormFileUrl(url);
      setViewTaxFormDialogOpen(true);
    } catch (error) {
      console.error("Error loading tax form:", error);
      setError("Failed to load tax form. Please try again.");
    }
  };

  const handleCloseTaxFormDialog = () => {
    setViewTaxFormDialogOpen(false);
    setTaxFormFileUrl(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 3 }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
        >
          Back to Direct Deposit
        </Button>
      </Box>
      <Typography variant="h5" gutterBottom>
        Step 3: Verification
      </Typography>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <VerifiedIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Review Your Information</Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Tax Form Information
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2">
                {freelancer?.taxFormType === "w9" ? "Form W-9" : "Form W-8BEN"}{" "}
                has been completed and signed.
              </Typography>
              <Button
                startIcon={<VisibilityIcon />}
                onClick={handleViewTaxForm}
                variant="outlined"
                sx={{ mt: 2 }}
              >
                View Tax Form
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Direct Deposit Information
            </Typography>
            {paymentProfile && (
              <Box>
                <Typography variant="body2">
                  <strong>Account Holder:</strong>{" "}
                  {paymentProfile.accountHolderFullName}
                </Typography>
                <Typography variant="body2">
                  <strong>Account Type:</strong> {paymentProfile.funding}
                </Typography>
                <Typography variant="body2">
                  <strong>Financial Institution:</strong>{" "}
                  {
                    freelancer?.increaseExternalAccountProfile
                      ?.financialInstitution
                  }
                </Typography>
                <Typography variant="body2">
                  <strong>Account Number:</strong> ****
                  {paymentProfile.accountNumber
                    ? paymentProfile.accountNumber.slice(-4)
                    : "N/A"}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        {!freelancer?.paymentAndTaxProfileVerifiedByUser && (
          <Box sx={{ mt: 4 }}>
            <Alert severity="info" sx={{ mb: 3 }}>
              <AlertTitle>Please Review and Verify</AlertTitle>
              Please review your tax form and direct deposit information
              carefully. Following self-verification, an admin will review your
              information and notify you of approval.
            </Alert>
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerify}
              fullWidth
            >
              Verify Information
            </Button>
          </Box>
        )}

        {freelancer?.paymentAndTaxProfileVerifiedByUser && (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              alignItems: "center",
              color: "success.main",
            }}
          >
            <CheckCircleIcon sx={{ mr: 1 }} />
            <Typography>Information Verified</Typography>
          </Box>
        )}
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert
          severity="success"
          sx={{ mt: 2 }}
          onClose={() => setSuccess(null)}
        >
          {success}
        </Alert>
      )}

      <ViewFileDialog
        open={viewTaxFormDialogOpen}
        onClose={handleCloseTaxFormDialog}
        fileUrl={taxFormFileUrl || ""}
        fileName={`${freelancer?.taxFormType === "w9" ? "W9" : "W8-BEN"} Form.pdf`}
      />
    </Box>
  );
};

export default FreelancerVerificationStep;
