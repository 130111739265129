import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import { useFreelancerPayroll } from "hooks/freelancer/useFreelancerPayroll";
import { useAuth } from "hooks/auth/useAuth";
import { useEversignSDK } from "hooks/eversign/useEversignSDK";
import ViewFileDialog from "components/ViewFileDialog";

declare global {
  interface Window {
    eversign: any;
  }
}

interface FreelancerTaxProfileStepProps {
  onNext: () => void;
}

const FreelancerTaxProfileStep: React.FC<FreelancerTaxProfileStepProps> = ({
  onNext,
}) => {
  const { currentUser } = useAuth();
  const { freelancerProfile: freelancer } = useFreelancerProfile();
  const {
    generateFreelancerW9Form,
    generateFreelancerW8BenForm,
    saveOrUpdateTaxForm,
    getTaxFormStorageUrl,
  } = useFreelancerPayroll();
  const { isLoaded: eversignSDKLoaded } = useEversignSDK();

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [selectedTaxForm, setSelectedTaxForm] = useState<"w9" | "w8ben" | null>(
    freelancer?.taxFormType || null
  );
  const [isUsResident, setIsUsResident] = useState<boolean | null>(
    freelancer?.taxFormType ? freelancer.taxFormType === "w9" : null
  );
  const [isSigningInProgress, setIsSigningInProgress] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [taxFormSigningUrl, setTaxFormSigningUrl] = useState<string | null>(null);
  const [viewTaxFormDialogOpen, setViewTaxFormDialogOpen] = useState(false);
  const [taxFormFileUrl, setTaxFormFileUrl] = useState<string | null>(null);
  const [documentHash, setDocumentHash] = useState<string | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const cleanupEversignIframe = () => {
    if (containerRef.current) {
      const iframe = containerRef.current.querySelector("iframe");
      if (iframe) {
        iframe.remove();
      }
    }
  };

  const handleStartTaxForm = async () => {
    if (!selectedTaxForm) {
      setError("Please select a tax form type before proceeding");
      return;
    }

    setIsIframeLoading(true);
    setIsSigningInProgress(true);
    try {
      setError(null);
      const generateForm =
        selectedTaxForm === "w9" ? generateFreelancerW9Form : generateFreelancerW8BenForm;
      const response = await generateForm();

      if (!response.embedUrl || !response.documentHash) {
        throw new Error("Failed to generate tax form");
      }

      setTaxFormSigningUrl(response.embedUrl);
      setDocumentHash(response.documentHash);

      // Initialize Eversign iframe
      if (containerRef.current && window.eversign?.open) {
        cleanupEversignIframe();

        window.eversign.open({
          url: response.embedUrl,
          containerID: containerRef.current.id,
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
          events: {
            loaded: () => {
              setIsIframeLoading(false);
              // Keep isSigningInProgress true while the form is being filled out
            },
            signed: async () => {
              cleanupEversignIframe();
              setTaxFormSigningUrl(null);
              // Keep isSigningInProgress true while processing the signed form
              try {
                await saveOrUpdateTaxForm(response.documentHash, selectedTaxForm);
                setSuccess("Tax form completed successfully");
                onNext();
              } catch (error) {
                console.error("Error during signing:", error);
                setError("Failed to process signed tax form");
              } finally {
                setIsSigningInProgress(false);
              }
            },
            declined: () => {
              cleanupEversignIframe();
              setTaxFormSigningUrl(null);
              setIsSigningInProgress(false);
            },
            error: (error: any) => {
              cleanupEversignIframe();
              setIsIframeLoading(false);
              setIsSigningInProgress(false);
              console.error("Eversign error:", error);
              setError(`Failed to load tax form signing interface: ${error}`);
              setTaxFormSigningUrl(null);
            },
          },
        });
      }
    } catch (err) {
      console.error("Error starting tax form flow:", err);
      setError("Failed to start tax form signing process. Please try again.");
      setIsSigningInProgress(false);
    } finally {
      setIsIframeLoading(false);
      // Note: We don't reset isSigningInProgress here as it should remain true until the form is signed or declined
    }
  };

  const handleViewTaxForm = async () => {
    try {
      setError(null);
      const url = await getTaxFormStorageUrl();
      setTaxFormFileUrl(url);
      setViewTaxFormDialogOpen(true);
    } catch (error) {
      console.error("Error loading tax form:", error);
      setError("Failed to load tax form. Please try again.");
    }
  };

  const handleCloseTaxFormDialog = () => {
    setViewTaxFormDialogOpen(false);
    setTaxFormFileUrl(null);
  };

  const handleConfirmBack = () => {
    setShowWarning(false);
    cleanupEversignIframe();
    setTaxFormSigningUrl(null);
    setIsSigningInProgress(false);
    setIsIframeLoading(false);
  };

  if (!eversignSDKLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading Eversign SDK...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "90%",
          maxWidth: "1200px",
          zIndex: 1000,
          display: taxFormSigningUrl ? "flex" : "none",
          flexDirection: "column",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            startIcon={<CancelIcon />}
            onClick={() => setShowWarning(true)}
          >
            Cancel
          </Button>
          <Typography variant="h6">Complete Tax Form</Typography>
          <Box sx={{ width: 48 }} />
        </Box>
        <Box
          ref={containerRef}
          id="eversign-container"
          sx={{
            flexGrow: 1,
            position: "relative",
            "& iframe": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
            },
          }}
        />
      </Box>

      {/* Loading Dialog */}
      <Dialog
        open={isSigningInProgress && !taxFormSigningUrl}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
      >
        <DialogContent sx={{ minWidth: 300, textAlign: 'center', py: 4 }}>
          <CircularProgress size={60} sx={{ mb: 2 }} />
          <DialogContentText id="loading-dialog-description">
            {isIframeLoading ? "Preparing your tax form..." : "Processing your tax form..."}
          </DialogContentText>
          <DialogContentText variant="caption" sx={{ mt: 2, color: 'text.secondary' }}>
            This may take a few moments. Please do not close this window.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Step 1: Tax Form
        </Typography>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <ArticleIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Complete Your Tax Form</Typography>
          </Box>
          <Typography color="text.secondary" sx={{ mb: 3 }}>
            Complete and sign your tax form. This is required for tax reporting
            purposes. The form will be processed electronically through our secure
            system.
          </Typography>

          {!freelancer?.taxFormCompleted && (
            <Box sx={{ mb: 3 }}>
              <FormControl component="fieldset" sx={{ mb: 3, width: "100%" }}>
                <FormLabel component="legend">
                  Are you a U.S. resident for tax purposes?
                </FormLabel>
                <RadioGroup
                  value={
                    freelancer?.taxFormType === "w9"
                      ? "true"
                      : freelancer?.taxFormType === "w8ben"
                        ? "false"
                        : isUsResident === null
                          ? ""
                          : isUsResident.toString()
                  }
                  onChange={(e) => {
                    if (freelancer?.taxFormType) return; // Extra safety check
                    const value = e.target.value === "true";
                    setIsUsResident(value);
                    setSelectedTaxForm(value ? "w9" : "w8ben");
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes - Complete Form W-9"
                    disabled={!!freelancer?.taxFormType || isSigningInProgress}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No - Complete Form W-8BEN"
                    disabled={!!freelancer?.taxFormType || isSigningInProgress}
                  />
                </RadioGroup>
              </FormControl>

              {freelancer?.taxFormType && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  You have already selected{" "}
                  {freelancer.taxFormType === "w9" ? "Form W-9" : "Form W-8BEN"}.
                  If you need to change your tax form type, please contact an
                  admin.
                </Alert>
              )}

              {selectedTaxForm && !freelancer?.taxFormType && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {selectedTaxForm === "w9" ? (
                    <>
                      You will complete Form W-9 (Request for Taxpayer
                      Identification Number).
                    </>
                  ) : (
                    <>
                      You will complete Form W-8BEN (Certificate of Foreign Status
                      of Beneficial Owner).
                    </>
                  )}
                </Alert>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleStartTaxForm}
                disabled={!selectedTaxForm || isSigningInProgress}
                fullWidth
              >
                {isSigningInProgress ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size={24} sx={{ mr: 1 }} />
                    <Typography variant="button">
                      {isIframeLoading ? "Loading Form..." : "Processing..."}
                    </Typography>
                  </Box>
                ) : (
                  `Complete ${selectedTaxForm === "w9" ? "W-9" : "W-8BEN"} Form`
                )}
              </Button>
            </Box>
          )}

          {freelancer?.taxFormCompleted && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color: "success.main",
                }}
              >
                <CheckCircleIcon sx={{ mr: 1 }} />
                <Typography>
                  {freelancer.taxFormType === "w9" ? "W-9" : "W-8BEN"} Form
                  Completed
                </Typography>
              </Box>
              <Button
                startIcon={<VisibilityIcon />}
                onClick={handleViewTaxForm}
                variant="outlined"
              >
                View Tax Form
              </Button>
            </>
          )}
        </Paper>
        {freelancer?.taxFormCompleted && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={onNext}
            >
              Continue to Direct Deposit
            </Button>
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2 }} onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}
      </Box>

      <Dialog
        open={showWarning}
        onClose={() => setShowWarning(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel? Your progress will be lost and
            you'll need to start over.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowWarning(false)}
            startIcon={<CancelIcon />}
          >
            Stay on Page
          </Button>
          <Button
            onClick={handleConfirmBack}
            color="error"
            startIcon={<ArrowBackIcon />}
          >
            Cancel Signing
          </Button>
        </DialogActions>
      </Dialog>

      <ViewFileDialog
        open={viewTaxFormDialogOpen}
        onClose={handleCloseTaxFormDialog}
        fileUrl={taxFormFileUrl || ""}
        fileName={`${freelancer?.taxFormType === "w9" ? "W9" : "W8-BEN"} Form.pdf`}
      />
    </>
  );
};

export default FreelancerTaxProfileStep;
