import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
} from "@mui/material";
import { Project, ProjectStatus } from "@freetech/models/projects";

interface AddProjectDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (projectData: Omit<Project, "id">) => Promise<void>;
  clientId: string;
}

const AddProjectDialog: React.FC<AddProjectDialogProps> = ({
  open,
  onClose,
  onAdd,
  clientId,
}) => {
  const [projectData, setProjectData] = useState<Partial<Project>>({
    name: "",
    description: "",
    status: "idea" as ProjectStatus,
    startDate: null,
    endDate: null,
    clientId,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTextChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const { name, value } = e.target;
    setProjectData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleStatusChange = (e: SelectChangeEvent<ProjectStatus>) => {
    setProjectData((prev) => ({
      ...prev,
      status: e.target.value as ProjectStatus,
    }));
  };

  const resetForm = () => {
    setProjectData({
      name: "",
      description: "",
      status: "idea" as ProjectStatus,
      startDate: null,
      endDate: null,
      clientId,
    });
    setIsSubmitting(false);
  };

  const handleSubmit = () => {
    if (!projectData.name) return;
    
    setIsSubmitting(true);
    
    // Store the current project data to submit
    const projectToSubmit = { ...projectData } as Omit<Project, "id">;
    
    // Reset form and close dialog immediately for optimistic UI
    resetForm();
    onClose();
    
    // Submit the data in the background
    onAdd(projectToSubmit).catch((error) => {
      console.error("Error adding project:", error);
      // If there's an error, the optimistic update will be rolled back automatically
    });
  };

  // Reset form when dialog is closed
  const handleClose = () => {
    if (!isSubmitting) {
      resetForm();
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Project</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <TextField
            name="name"
            label="Project Name"
            value={projectData.name}
            onChange={handleTextChange}
            fullWidth
            required
          />
          <TextField
            name="description"
            label="Description"
            value={projectData.description}
            onChange={handleTextChange}
            fullWidth
            multiline
            rows={3}
          />
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={projectData.status}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value="idea">Idea</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="startDate"
            label="Start Date"
            type="date"
            value={projectData.startDate?.split("T")[0] || ""}
            onChange={handleTextChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="endDate"
            label="End Date"
            type="date"
            value={projectData.endDate?.split("T")[0] || ""}
            onChange={handleTextChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!projectData.name || isSubmitting}
        >
          Add Project
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectDialog; 