import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Link,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Business,
  Campaign as CampaignIcon,
  Handshake,
} from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

interface ClientOverviewHeaderProps {
  clientCount: number;
}

const ClientOverviewHeader: React.FC<ClientOverviewHeaderProps> = ({
  clientCount,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper
      elevation={2}
      sx={{
        p: { xs: 2, md: 3 },
        mb: 3,
        borderRadius: 2,
        background: `linear-gradient(to right, ${theme.palette.primary.main}15, ${theme.palette.background.paper})`,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            My Clients Dashboard
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to your client management portal. You are currently working
            with{" "}
            <Chip
              label={`${clientCount} client${clientCount !== 1 ? "s" : ""}`}
              color="primary"
              size="small"
              sx={{ fontWeight: "bold" }}
            />
            . Here you can view all your clients and their details, track
            projects, and manage your work.
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Browse through your client cards below to access detailed
            information, contact details, and project history.
          </Typography>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper
            elevation={4}
            sx={{
              bgcolor: "grey.800",
              borderRadius: 2,
              p: 2.5,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.2)",
              border: "1px solid rgba(255, 255, 255, 0.08)",
              backdropFilter: "blur(8px)",
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(225deg, rgba(13,71,161,0.1) 0%, rgba(0,0,0,0) 100%)",
                zIndex: 0,
              },
              "&:after": {
                content: '""',
                position: "absolute",
                top: -100,
                left: -100,
                right: -100,
                bottom: -100,
                background:
                  "radial-gradient(circle at center, rgba(25,118,210,0.12) 0%, rgba(0,0,0,0) 70%)",
                animation: "pulse 3s ease-in-out infinite",
              },
              "& > *": {
                position: "relative",
                zIndex: 1,
              },
              "@keyframes pulse": {
                "0%": {
                  opacity: 0.8,
                  transform: "scale(0.8)",
                },
                "50%": {
                  opacity: 1,
                  transform: "scale(1)",
                },
                "100%": {
                  opacity: 0.8,
                  transform: "scale(0.8)",
                },
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Handshake
                sx={{
                  color: "#ffffff",
                  fontSize: "1.75rem",
                  mr: 1.5,
                  opacity: 0.9,
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: "#ffffff",
                  fontWeight: 700,
                }}
              >
                Earn Additional Income
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "grey.300",
                mb: 2,
                lineHeight: 1.6,
              }}
            >
              Your professional connections could benefit from the services
              provided by FreeTech. When you introduce clients to FreeTech, you
              can earn up to 20% commission. 
            </Typography>
            <Typography
              sx={{
                color: "grey.400",
                mb: 2,
                fontSize: "0.9rem",
              }}
            >
              Many of our freelancers have found this to be a rewarding way to
              expand their professional impact while generating additional
              income.
            </Typography>
            <Link
              component={RouterLink}
              to="/freelancer/referral-program"
              sx={{
                background:
                  "linear-gradient(135deg, #1a1a1a 0%, rgba(0, 98, 255, 0.15) 50%, #1a1a1a 100%)",
                color: "#ffffff",
                textDecoration: "none",
                fontWeight: 700,
                fontSize: "1.1rem",
                display: "inline-flex",
                alignItems: "center",
                padding: "12px 24px",
                borderRadius: "8px",
                transition: "all 0.3s ease-in-out",
                border: "1px solid rgba(0, 98, 255, 0.2)",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                alignSelf: "flex-start",
                width: "fit-content",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #1a1a1a 0%, rgba(0, 98, 255, 0.25) 50%, #1a1a1a 100%)",
                  transform: "translateY(-1px)",
                  boxShadow: "0 6px 16px rgba(0, 98, 255, 0.15)",
                  borderColor: "rgba(0, 98, 255, 0.4)",
                },
              }}
            >
              Learn more →
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClientOverviewHeader;
