import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Alert,
  Tooltip,
  TablePagination,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { useAdminStudents } from 'hooks/admin/useAdminStudents';
import { DateTime } from 'luxon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';
import { Student, StudentStatus } from '@freetech/models/user';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`student-management-tabpanel-${index}`}
      aria-labelledby={`student-management-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `student-management-tab-${index}`,
    'aria-controls': `student-management-tabpanel-${index}`,
  };
};

const StudentManagement: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [studentPage, setStudentPage] = useState(0);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [cohortPage, setCohortPage] = useState(0);
  const [cohortsPerPage, setCohortsPerPage] = useState(10);
  
  // Create/Edit Cohort Modal
  const [cohortModalOpen, setCohortModalOpen] = useState(false);
  const [editingCohort, setEditingCohort] = useState<any>(null);
  const [cohortName, setCohortName] = useState('');
  const [cohortDescription, setCohortDescription] = useState('');
  const [selectedMentorId, setSelectedMentorId] = useState('');
  const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([]);
  const [cohortStartDate, setCohortStartDate] = useState('');
  const [cohortEndDate, setCohortEndDate] = useState('');
  const [cohortStatus, setCohortStatus] = useState<'active' | 'inactive' | 'completed'>('active');
  
  // Assign Mentor Modal
  const [mentorModalOpen, setMentorModalOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [assignMentorId, setAssignMentorId] = useState('');
  
  // Edit Student Status Modal
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusStudentId, setStatusStudentId] = useState('');
  const [newStatus, setNewStatus] = useState<StudentStatus>('active');
  
  // Delete Cohort Modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingCohortId, setDeletingCohortId] = useState('');
  
  const {
    students,
    potentialMentors,
    cohorts,
    isLoading,
    createCohort,
    updateCohort,
    deleteCohort,
    assignMentor,
    updateStudentStatus,
    isCreatingCohort,
    isUpdatingCohort,
    isDeletingCohort,
    isAssigningMentor,
    isUpdatingStudentStatus,
  } = useAdminStudents();
  
  // Reset form when dialog closes
  useEffect(() => {
    if (!cohortModalOpen) {
      setEditingCohort(null);
      setCohortName('');
      setCohortDescription('');
      setSelectedMentorId('');
      setSelectedStudentIds([]);
      setCohortStartDate('');
      setCohortEndDate('');
      setCohortStatus('active');
    }
  }, [cohortModalOpen]);
  
  // Prepare form for editing
  useEffect(() => {
    if (editingCohort) {
      setCohortName(editingCohort.name || '');
      setCohortDescription(editingCohort.description || '');
      setSelectedMentorId(editingCohort.mentorId || '');
      setSelectedStudentIds(editingCohort.studentIds || []);
      setCohortStartDate(editingCohort.startDate || '');
      setCohortEndDate(editingCohort.endDate || '');
      setCohortStatus(editingCohort.status || 'active');
    }
  }, [editingCohort]);
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  const handleStudentPageChange = (event: unknown, newPage: number) => {
    setStudentPage(newPage);
  };
  
  const handleStudentsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudentsPerPage(parseInt(event.target.value, 10));
    setStudentPage(0);
  };
  
  const handleCohortPageChange = (event: unknown, newPage: number) => {
    setCohortPage(newPage);
  };
  
  const handleCohortsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCohortsPerPage(parseInt(event.target.value, 10));
    setCohortPage(0);
  };
  
  const handleOpenCohortModal = (cohort?: any) => {
    if (cohort) {
      setEditingCohort(cohort);
    }
    setCohortModalOpen(true);
  };
  
  const handleCloseCohortModal = () => {
    setCohortModalOpen(false);
  };
  
  const handleOpenMentorModal = (studentId: string) => {
    setSelectedStudentId(studentId);
    // Pre-select current mentor if exists
    const student = students.find(s => s.id === studentId);
    setAssignMentorId(student?.assignedMentorId || '');
    setMentorModalOpen(true);
  };
  
  const handleCloseMentorModal = () => {
    setMentorModalOpen(false);
  };
  
  const handleOpenStatusModal = (studentId: string) => {
    setStatusStudentId(studentId);
    // Pre-select current status if exists
    const student = students.find(s => s.id === studentId);
    setNewStatus(student?.status || 'active');
    setStatusModalOpen(true);
  };
  
  const handleCloseStatusModal = () => {
    setStatusModalOpen(false);
  };
  
  const handleOpenDeleteModal = (cohortId: string) => {
    setDeletingCohortId(cohortId);
    setDeleteModalOpen(true);
  };
  
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  
  const handleSaveCohort = async () => {
    try {
      if (editingCohort) {
        await updateCohort({
          id: editingCohort.id,
          name: cohortName,
          description: cohortDescription,
          mentorId: selectedMentorId,
          studentIds: selectedStudentIds,
          startDate: cohortStartDate,
          endDate: cohortEndDate,
          status: cohortStatus,
        });
      } else {
        await createCohort({
          name: cohortName,
          description: cohortDescription,
          mentorId: selectedMentorId,
          studentIds: selectedStudentIds,
          startDate: cohortStartDate,
          endDate: cohortEndDate,
        });
      }
      handleCloseCohortModal();
    } catch (error) {
      console.error('Error saving cohort:', error);
    }
  };
  
  const handleDeleteCohort = async () => {
    try {
      await deleteCohort(deletingCohortId);
      handleCloseDeleteModal();
    } catch (error) {
      console.error('Error deleting cohort:', error);
    }
  };
  
  const handleAssignMentor = async () => {
    try {
      await assignMentor({
        studentId: selectedStudentId,
        mentorId: assignMentorId,
      });
      handleCloseMentorModal();
    } catch (error) {
      console.error('Error assigning mentor:', error);
    }
  };
  
  const handleUpdateStatus = async () => {
    try {
      await updateStudentStatus({
        studentId: statusStudentId,
        status: newStatus,
      });
      handleCloseStatusModal();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  
  // Filter students by search term
  const filteredStudents = students.filter(student => {
    const searchLower = searchTerm.toLowerCase();
    return (
      student.displayName?.toLowerCase().includes(searchLower) ||
      student.email?.toLowerCase().includes(searchLower) ||
      student.firstName?.toLowerCase().includes(searchLower) ||
      student.lastName?.toLowerCase().includes(searchLower)
    );
  });
  
  // Calculate paginated lists
  const paginatedStudents = filteredStudents.slice(
    studentPage * studentsPerPage,
    studentPage * studentsPerPage + studentsPerPage
  );
  
  const filteredCohorts = cohorts.filter(cohort => {
    const searchLower = searchTerm.toLowerCase();
    return (
      cohort.name?.toLowerCase().includes(searchLower) ||
      cohort.description?.toLowerCase().includes(searchLower)
    );
  });
  
  const paginatedCohorts = filteredCohorts.slice(
    cohortPage * cohortsPerPage,
    cohortPage * cohortsPerPage + cohortsPerPage
  );
  
  // Get mentor name from ID
  const getMentorName = (mentorId: string) => {
    const mentor = potentialMentors.find(m => m.id === mentorId);
    return mentor ? mentor.displayName : 'Unknown';
  };
  
  // Get student name from ID
  const getStudentName = (studentId: string) => {
    const student = students.find(s => s.id === studentId);
    return student ? student.displayName : 'Unknown';
  };
  
  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return 'Not set';
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED);
  };
  
  // Get status chip color
  const getStatusColor = (status?: StudentStatus) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'ready':
        return 'info';
      case 'inactive':
        return 'error';
      default:
        return 'default';
    }
  };
  
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom component="div">
        Student Management
      </Typography>
      
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="student management tabs">
            <Tab label="Students" {...a11yProps(0)} />
            <Tab label="Cohorts" {...a11yProps(1)} />
          </Tabs>
        </Box>
        
        <Box sx={{ mb: 3, mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: '300px' }}
          />
          
          {tabValue === 1 && (
            <Button
              variant="contained"
              startIcon={<GroupAddIcon />}
              onClick={() => handleOpenCohortModal()}
            >
              Create Cohort
            </Button>
          )}
        </Box>
        
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TabPanel value={tabValue} index={0}>
              {filteredStudents.length === 0 ? (
                <Alert severity="info">No students found</Alert>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Student</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Mentor</TableCell>
                          <TableCell>Cohort</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedStudents.map((student) => {
                          const mentor = potentialMentors.find(m => m.id === student.assignedMentorId);
                          const cohort = cohorts.find(c => c.id === student.cohortId);
                          
                          return (
                            <TableRow key={student.id}>
                              <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar
                                    src={student.profilePicture}
                                    sx={{ width: 32, height: 32, mr: 1 }}
                                  >
                                    {student.firstName?.[0]}{student.lastName?.[0]}
                                  </Avatar>
                                  {student.displayName}
                                </Box>
                              </TableCell>
                              <TableCell>{student.email}</TableCell>
                              <TableCell>
                                <Chip
                                  label={student.status || 'active'}
                                  color={getStatusColor(student.status) as any}
                                  size="small"
                                  onClick={() => handleOpenStatusModal(student.id)}
                                />
                              </TableCell>
                              <TableCell>
                                {mentor ? (
                                  <Chip
                                    label={mentor.displayName}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleOpenMentorModal(student.id)}
                                  />
                                ) : (
                                  <Button
                                    size="small"
                                    startIcon={<PersonAddIcon />}
                                    onClick={() => handleOpenMentorModal(student.id)}
                                  >
                                    Assign
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell>
                                {cohort ? (
                                  <Chip
                                    label={cohort.name}
                                    size="small"
                                    variant="outlined"
                                  />
                                ) : (
                                  <Typography variant="body2" color="text.secondary">
                                    Not assigned
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Assign Mentor">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleOpenMentorModal(student.id)}
                                  >
                                    <PersonAddIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Change Status">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleOpenStatusModal(student.id)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredStudents.length}
                    rowsPerPage={studentsPerPage}
                    page={studentPage}
                    onPageChange={handleStudentPageChange}
                    onRowsPerPageChange={handleStudentsPerPageChange}
                  />
                </>
              )}
            </TabPanel>
            
            <TabPanel value={tabValue} index={1}>
              {filteredCohorts.length === 0 ? (
                <Alert 
                  severity="info"
                  action={
                    <Button 
                      color="inherit" 
                      size="small"
                      onClick={() => handleOpenCohortModal()}
                    >
                      Create Cohort
                    </Button>
                  }
                >
                  No cohorts found
                </Alert>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Cohort Name</TableCell>
                          <TableCell>Mentor</TableCell>
                          <TableCell>Students</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedCohorts.map((cohort) => (
                          <TableRow key={cohort.id}>
                            <TableCell>
                              <Typography variant="body1">{cohort.name}</Typography>
                              {cohort.description && (
                                <Typography variant="caption" color="text.secondary">
                                  {cohort.description}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>{getMentorName(cohort.mentorId)}</TableCell>
                            <TableCell>
                              <Chip 
                                label={`${cohort.studentIds?.length || 0} Students`} 
                                size="small"
                              />
                            </TableCell>
                            <TableCell>
                              <Chip
                                label={cohort.status || 'active'}
                                color={
                                  cohort.status === 'active'
                                    ? 'success'
                                    : cohort.status === 'completed'
                                    ? 'info'
                                    : 'default'
                                }
                                size="small"
                              />
                            </TableCell>
                            <TableCell>{formatDate(cohort.startDate)}</TableCell>
                            <TableCell>{formatDate(cohort.endDate)}</TableCell>
                            <TableCell align="right">
                              <Tooltip title="Edit Cohort">
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpenCohortModal(cohort)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete Cohort">
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpenDeleteModal(cohort.id)}
                                  color="error"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredCohorts.length}
                    rowsPerPage={cohortsPerPage}
                    page={cohortPage}
                    onPageChange={handleCohortPageChange}
                    onRowsPerPageChange={handleCohortsPerPageChange}
                  />
                </>
              )}
            </TabPanel>
          </>
        )}
      </Box>
      
      {/* Create/Edit Cohort Dialog */}
      <Dialog
        open={cohortModalOpen}
        onClose={handleCloseCohortModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingCohort ? 'Edit Cohort' : 'Create New Cohort'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                label="Cohort Name"
                fullWidth
                required
                value={cohortName}
                onChange={(e) => setCohortName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                fullWidth
                multiline
                rows={2}
                value={cohortDescription}
                onChange={(e) => setCohortDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Mentor</InputLabel>
                <Select
                  value={selectedMentorId}
                  label="Mentor"
                  onChange={(e: SelectChangeEvent) => setSelectedMentorId(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    <em>Select a mentor</em>
                  </MenuItem>
                  {potentialMentors.map((mentor) => (
                    <MenuItem key={mentor.id} value={mentor.id}>
                      {mentor.displayName} ({mentor.role})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={cohortStatus}
                  label="Status"
                  onChange={(e: SelectChangeEvent) => setCohortStatus(e.target.value as any)}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                value={cohortStartDate}
                onChange={(e) => setCohortStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="End Date"
                type="date"
                fullWidth
                value={cohortEndDate}
                onChange={(e) => setCohortEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={students}
                getOptionLabel={(option) => option.displayName}
                value={students.filter(student => selectedStudentIds.includes(student.id))}
                onChange={(_, newValue) => {
                  setSelectedStudentIds(newValue.map(student => student.id));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Students"
                    placeholder="Select students"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={option.profilePicture}
                        sx={{ width: 24, height: 24, mr: 1 }}
                      >
                        {option.firstName?.[0]}{option.lastName?.[0]}
                      </Avatar>
                      {option.displayName}
                    </Box>
                  </li>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCohortModal}>Cancel</Button>
          <Button
            onClick={handleSaveCohort}
            variant="contained"
            disabled={!cohortName || !selectedMentorId || isCreatingCohort || isUpdatingCohort}
          >
            {isCreatingCohort || isUpdatingCohort ? (
              <CircularProgress size={24} />
            ) : editingCohort ? (
              'Update'
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Assign Mentor Dialog */}
      <Dialog
        open={mentorModalOpen}
        onClose={handleCloseMentorModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Assign Mentor</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Assign a mentor to {getStudentName(selectedStudentId)}
            </Typography>
            <FormControl fullWidth required sx={{ mt: 1 }}>
              <InputLabel>Mentor</InputLabel>
              <Select
                value={assignMentorId}
                label="Mentor"
                onChange={(e: SelectChangeEvent) => setAssignMentorId(e.target.value)}
              >
                <MenuItem value="" disabled>
                  <em>Select a mentor</em>
                </MenuItem>
                {potentialMentors.map((mentor) => (
                  <MenuItem key={mentor.id} value={mentor.id}>
                    {mentor.displayName} ({mentor.role})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMentorModal}>Cancel</Button>
          <Button
            onClick={handleAssignMentor}
            variant="contained"
            disabled={!assignMentorId || isAssigningMentor}
          >
            {isAssigningMentor ? <CircularProgress size={24} /> : 'Assign'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Update Status Dialog */}
      <Dialog
        open={statusModalOpen}
        onClose={handleCloseStatusModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Update Student Status</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Update status for {getStudentName(statusStudentId)}
            </Typography>
            <FormControl fullWidth required sx={{ mt: 1 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={newStatus}
                label="Status"
                onChange={(e: SelectChangeEvent) => setNewStatus(e.target.value as StudentStatus)}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="ready">Ready</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusModal}>Cancel</Button>
          <Button
            onClick={handleUpdateStatus}
            variant="contained"
            disabled={isUpdatingStudentStatus}
          >
            {isUpdatingStudentStatus ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete Cohort Dialog */}
      <Dialog
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete Cohort</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this cohort? This action cannot be undone.
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            All students in this cohort will be unassigned from it, but they will remain in the system.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button
            onClick={handleDeleteCohort}
            variant="contained"
            color="error"
            disabled={isDeletingCohort}
          >
            {isDeletingCohort ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StudentManagement; 