import { useQuery, useMutation } from "@tanstack/react-query";
import { getDoc, doc, DocumentSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { ProjectIdeaData, ProjectPlan } from "@freetech/models/projects";
import { portalFunctions } from "core/functions/portalFunctions";
import { useNavigate } from "react-router-dom";

interface UseStakeholderProjectPlanProps {
  clientId: string;
  projectIdeaId: string;
}

/**
 * Hook for managing project plan data and submission
 */
export const useStakeholderProjectPlan = ({
  clientId,
  projectIdeaId,
}: UseStakeholderProjectPlanProps) => {
  const navigate = useNavigate();

  // Fetch project plan
  const { data: projectPlan, isLoading } = useQuery({
    queryKey: ["project-plan", clientId, projectIdeaId],
    queryFn: async () => {
      try {
        const db = getFirestore();

        // First get the project idea document
        const projectIdeaDoc = await getDoc(
          doc(db, `clients/${clientId}/projectIdeas/${projectIdeaId}`)
        );

        if (!projectIdeaDoc.exists()) {
          throw new Error("Project idea not found");
        }

        const projectIdeaData = projectIdeaDoc.data() as ProjectIdeaData;

        // Check if we have a generated plan in the project idea
        if (projectIdeaData.aiGeneratedPlan) {
          return JSON.parse(projectIdeaData.aiGeneratedPlan) as ProjectPlan;
        }

        // If not, check for a separate project plan document
        if (projectIdeaData.projectPlanId) {
          const projectPlanDoc = await getDoc(
            doc(
              db,
              `clients/${clientId}/projectPlans/${projectIdeaData.projectPlanId}`
            )
          );

          if (!projectPlanDoc.exists()) {
            throw new Error("Project plan not found");
          }

          return {
            ...(projectPlanDoc.data() as ProjectPlan),
            id: projectPlanDoc.id,
          };
        }

        throw new Error("No project plan available");
      } catch (error) {
        console.error("Error fetching project plan:", error);
        throw error;
      }
    },
    enabled: Boolean(
      clientId && projectIdeaId && clientId !== "" && projectIdeaId !== ""
    ),
  });

  // Submit project plan mutation
  const submitMutation = useMutation({
    mutationFn: async (params: {
      clientId: string;
      projectIdeaId: string;
      projectPlanId: string;
    }) => {
      const { success, projectId } =
        await portalFunctions.stakeholder.submitStakeholderProjectIdea({
          clientId: params.clientId,
          projectIdeaId: params.projectIdeaId,
          projectPlanId: params.projectPlanId,
        });

      if (!success) {
        throw new Error("Failed to submit project idea");
      }

      return { success, projectId };
    },
    onSuccess: () => {
      navigate("/stakeholder/projects");
    },
  });

  const handleSubmit = () => {
    if (projectPlan && clientId && projectIdeaId) {
      submitMutation.mutate({
        clientId,
        projectIdeaId,
        projectPlanId: projectPlan.id,
      });
    }
  };

  return {
    projectPlan,
    isLoading,
    isSubmitting: submitMutation.isPending,
    handleSubmit,
  };
};
