import React, { useState, useMemo } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Collapse,
  Paper,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useLocalStorageBoolState } from "hooks/useLocalStorage";
import { Client } from "@freetech/models/projects";
import { useAdminTimesheet } from "hooks/admin/useAdminTimesheet";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import TimesheetExplorerGrid from "views/SuperAdmin/TimesheetManagement/TimesheetExplorer/components/TimesheetExplorerGrid";
import { Freelancer } from "@freetech/models/user";

interface AssociatedTimesheetsProps {
  client: Client;
  clientId: string;
}

export const AssociatedTimesheets: React.FC<AssociatedTimesheetsProps> = ({
  client,
  clientId,
}) => {
  const [isVisible, setIsVisible] = useLocalStorageBoolState(
    "isAssociatedTimesheetsVisible",
    false
  );
  const [startDate, setStartDate] = useState<DateTime>(
    DateTime.now().startOf("month")
  );
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.now().endOf("month")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEngineers, setSelectedEngineers] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedBillableStatus, setSelectedBillableStatus] = useState<
    boolean | null
  >(null);

  const { getAllTimesheets } = useAdminTimesheet();
  const { data: fetchedTimesheets = [], isLoading: loading } =
    getAllTimesheets();
  const { freelancers = [] } = useAdminFreelancers();

  // Filter timesheets for this client only
  const clientTimesheets = useMemo(() => {
    return fetchedTimesheets.filter(
      (timesheet) => timesheet.clientId === clientId
    );
  }, [fetchedTimesheets, clientId]);

  const selectedEngineerObjects = useMemo(() => {
    return freelancers.filter((f) => selectedEngineers.includes(f.email));
  }, [freelancers, selectedEngineers]);

  const selectedClients = useMemo(() => {
    return client.companyName ? [client.companyName] : [];
  }, [client.companyName]);

  const filteredTimesheets = useMemo(() => {
    return clientTimesheets.filter((timesheet) => {
      const timesheetDate = DateTime.fromISO(timesheet.date);

      const matchesDateRange =
        timesheetDate >= startDate.startOf("day") &&
        timesheetDate <= endDate.endOf("day");

      const matchesSearch =
        (timesheet.userName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.projectName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.description?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        );

      const matchesEngineer =
        selectedEngineers.length === 0 ||
        (timesheet.userEmail &&
          selectedEngineers.includes(timesheet.userEmail));

      const matchesProject =
        selectedProjects.length === 0 ||
        selectedProjects.includes(timesheet.projectId || "");

      const matchesBillable =
        selectedBillableStatus === null ||
        timesheet.billable === selectedBillableStatus;

      return (
        matchesDateRange &&
        matchesSearch &&
        matchesEngineer &&
        matchesProject &&
        matchesBillable
      );
    });
  }, [
    clientTimesheets,
    startDate,
    endDate,
    searchTerm,
    selectedEngineers,
    selectedProjects,
    selectedBillableStatus,
  ]);

  return (
    <Card
      elevation={2}
      sx={{
        width: "100%",
        mb: 3,
        background: "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
      }}
    >
      <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          <Box display="flex" alignItems="center">
            <ReceiptIcon sx={{ fontSize: 28, mr: 2 }} />
            <Typography variant="h6" component="h2">
              Timesheets
            </Typography>
            <IconButton
              onClick={() => setIsVisible(!isVisible)}
              sx={{ color: "white", ml: 2 }}
              size="small"
            >
              {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Box>
        </Box>

        <Collapse in={isVisible}>
          <Box sx={{ mt: 2 }}>
            {/* Filters Section */}
            <Paper
              elevation={3}
              sx={{
                p: 2,
                mb: 2,
                bgcolor: "background.paper",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                {/* Date Range Row */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                    gap: 1.5,
                  }}
                >
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => newValue && setStartDate(newValue)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => newValue && setEndDate(newValue)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  />
                </Box>

                {/* Filter Inputs */}
                <Autocomplete<Freelancer, true>
                  multiple
                  size="small"
                  options={freelancers}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  value={selectedEngineerObjects}
                  onChange={(_, newValue) => {
                    setSelectedEngineers(newValue.map((v) => v.email));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter by Engineer" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.email === value.email
                  }
                />

                <Autocomplete
                  multiple
                  size="small"
                  options={client.projects || []}
                  getOptionLabel={(option) => option.name}
                  value={
                    client.projects?.filter((p) =>
                      selectedProjects.includes(p.id)
                    ) || []
                  }
                  onChange={(_, newValue) => {
                    setSelectedProjects(newValue.map((v) => v.id));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Filter by Project" />
                  )}
                />

                <FormControl size="small">
                  <InputLabel>Billable Status</InputLabel>
                  <Select
                    value={
                      selectedBillableStatus === null
                        ? ""
                        : String(selectedBillableStatus)
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedBillableStatus(
                        value === "" ? null : value === "true"
                      );
                    }}
                    label="Billable Status"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="true">Billable</MenuItem>
                    <MenuItem value="false">Non-billable</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  fullWidth
                />
              </Box>
            </Paper>

            {/* Grid Section */}
            <Box sx={{ bgcolor: "background.paper", borderRadius: 1 }}>
              <TimesheetExplorerGrid
                timesheets={filteredTimesheets}
                loading={loading}
                startDate={startDate}
                endDate={endDate}
                selectedEngineers={selectedEngineers}
                selectedProjects={selectedProjects}
                selectedBillableStatus={selectedBillableStatus}
                selectedClients={selectedClients}
              />
            </Box>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};
