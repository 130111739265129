import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { BoardActivityEvent, BoardActivityEventType } from "@freetech/models/freelo";

export const logBoardActivity = async (
  boardId: string,
  type: BoardActivityEventType,
  userId: string,
  additionalInfo?: string
) => {
  try {
    const activityRef = collection(db, "boards", boardId, "activity");

    await addDoc(activityRef, {
      type,
      actor: userId,
      additionalInfo,
      dateTimeOccured: DateTime.now().toISO(),
    });
  } catch (error) {
    console.error("Error logging board activity:", error);
  }
}; 