import { useQuery } from "@tanstack/react-query";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { useStakeholderProjects } from "./useStakeholderProjects";
import { Timesheet } from "@freetech/models/timesheet";
import { useStakeholder } from "./useStakeholder";
import React from "react";
import { loadClientsNonAdmin } from "core/clients";
import { useAuth } from "hooks/auth/useAuth";

export const useStakeholderTimesheets = () => {
  const { userInfo } = useAuth();
  // Extract unique client IDs from projects
  const {
    data: timesheets,
    isLoading: isTimesheetsLoading,
    error: timesheetsError,
    isError: isTimesheetsError,
  } = useQuery({
    queryKey: ["stakeholder-timesheets"],
    queryFn: async () => {
      if (!userInfo) {
        throw new Error("No user info");
      }

      // First get the clients the stakeholder has access to
      const clients = await loadClientsNonAdmin(userInfo);

      // Then get timesheets one client at a time
      const allTimesheets: Timesheet[] = [];

      for (const client of clients) {
        const timesheetsRef = collection(db, "timesheets");
        const timesheetQuery = query(
          timesheetsRef,
          where("clientId", "==", client.id)
        );

        const querySnapshot = await getDocs(timesheetQuery);
        const clientTimesheets = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Timesheet[];

        console.log("clientTimesheets", clientTimesheets);

        allTimesheets.push(...clientTimesheets);
      }

      return allTimesheets.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    },
    enabled: !!userInfo,
  });

  // Combine loading states

  return {
    timesheets,
    isTimesheetsLoading: isTimesheetsLoading,
    timesheetsError: timesheetsError,
    isTimesheetsError,
  };
};
