import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  Alert,
  Button,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import useFreelancerReferrals from "hooks/freelancer/useFreelancerReferrals";
import { ReferralForm, ReferralList } from "./components";
import { ReferralFormData, ReferralStatus } from "@freetech/models/referrals";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`referral-tabpanel-${index}`}
      aria-labelledby={`referral-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const ReferralProgram: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const {
    referrals,
    isLoading,
    isError,
    error,
    submitReferral,
    isSubmitting,
    refetchReferrals,
  } = useFreelancerReferrals();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  const handleSubmitReferral = async (formData: ReferralFormData) => {
    try {
      await submitReferral(formData);
      setShowForm(false);
      refetchReferrals();
    } catch (error) {
      console.error("Error submitting referral:", error);
    }
  };

  const getFilteredReferrals = (status: string | null) => {
    if (!referrals) return [];
    if (!status || status === "all") return referrals;

    switch (status) {
      case "pending":
        return referrals.filter((ref) => ref.status === "pending");
      case "contacted":
        return referrals.filter((ref) => ref.status === "contacted");
      case "interviewing":
        return referrals.filter((ref) => ref.status === "interviewing");
      case "hired":
        return referrals.filter((ref) => ref.status === "hired");
      case "rejected":
        return referrals.filter((ref) => ref.status === "rejected");
      default:
        return referrals;
    }
  };

  const getEmptyMessage = (status: string | null) => {
    if (!status || status === "all") {
      return "You haven't submitted any client referrals yet.";
    }

    switch (status) {
      case "pending":
        return "You don't have any new client referrals.";
      case "contacted":
        return "You don't have any contacted client referrals.";
      case "interviewing":
        return "You don't have any client referrals in discussion.";
      case "hired":
        return "You don't have any converted client referrals yet.";
      case "rejected":
        return "You don't have any rejected client referrals.";
      default:
        return "No client referrals found.";
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">
          Error loading referrals:{" "}
          {error instanceof Error ? error.message : "Unknown error"}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, md: 3 } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4">Client Referral Program</Typography>
        {!showForm && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleShowForm}
          >
            Refer a Client
          </Button>
        )}
      </Box>

      {showForm ? (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Submit a New Client Referral
          </Typography>
          <ReferralForm
            onSubmit={handleSubmitReferral}
            onCancel={handleHideForm}
            isSubmitting={isSubmitting}
          />
        </Paper>
      ) : (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Client Referral Program Overview
          </Typography>
          <Typography variant="body1" paragraph>
            Refer potential clients to FreeTech and earn rewards when they
            become our clients! Help us grow our business network while earning
            bonuses for successful referrals.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                }}
              >
                <Typography variant="h4" color="primary">
                  {referrals.length}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Total Referrals
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                }}
              >
                <Typography variant="h4" color="warning.main">
                  {getFilteredReferrals("contacted").length +
                    getFilteredReferrals("interviewing").length}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  In Discussion
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                }}
              >
                <Typography variant="h4" color="success.main">
                  {getFilteredReferrals("hired").length}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Converted to Clients
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                }}
              >
                <Typography variant="h4" color="primary">
                  $
                  {getFilteredReferrals("hired").reduce(
                    (sum, r) => sum + (r.rewardAmount || 0),
                    0
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Total Rewards Earned
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      )}

      <Paper sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="referral tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={`All Referrals (${referrals.length})`} />
            <Tab label={`New (${getFilteredReferrals("pending").length})`} />
            <Tab
              label={`Contacted (${getFilteredReferrals("contacted").length})`}
            />
            <Tab
              label={`In Discussion (${getFilteredReferrals("interviewing").length})`}
            />
            <Tab
              label={`Converted (${getFilteredReferrals("hired").length})`}
            />
            <Tab
              label={`Not Interested (${getFilteredReferrals("rejected").length})`}
            />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <ReferralList
            referrals={referrals}
            emptyMessage={getEmptyMessage(null)}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <ReferralList
            referrals={getFilteredReferrals("pending")}
            emptyMessage={getEmptyMessage("pending")}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <ReferralList
            referrals={getFilteredReferrals("contacted")}
            emptyMessage={getEmptyMessage("contacted")}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <ReferralList
            referrals={getFilteredReferrals("interviewing")}
            emptyMessage={getEmptyMessage("interviewing")}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          <ReferralList
            referrals={getFilteredReferrals("hired")}
            emptyMessage={getEmptyMessage("hired")}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={5}>
          <ReferralList
            referrals={getFilteredReferrals("rejected")}
            emptyMessage={getEmptyMessage("rejected")}
          />
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default ReferralProgram;
