import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useStakeholderBugReport } from "hooks/stakeholder/useStakeholderBugReport";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";

interface BreadcrumbResolver {
  pattern: RegExp;
  resolve: (match: string) => Promise<string>;
  skipSegments?: string[];
}

export function breadcrumbResolvers(): BreadcrumbResolver[] {
  return [
    {
      // Matches project IDs in the URL
      pattern: /^\/stakeholder\/projects\/([^/]+)$/,
      resolve: async (projectId: string) => {
        try {
          const projectDoc = await getDoc(doc(db, "projects", projectId));
          if (projectDoc.exists()) {
            return projectDoc.data().name || "Project Details";
          }
        } catch (error) {
          console.error("Error resolving project name:", error);
        }
        return "Project Details";
      },
    },
    {
      // Matches bug report IDs in the URL
      pattern: /^\/stakeholder\/projects\/[^/]+\/bug-reports\/([^/]+)$/,
      resolve: async (bugReportId: string) => {
        try {
          const bugReportDoc = await getDoc(doc(db, "bugReports", bugReportId));
          if (bugReportDoc.exists()) {
            return `Bug Report #${bugReportId.slice(0, 8)}`;
          }
        } catch (error) {
          console.error("Error resolving bug report:", error);
        }
        return "Bug Report";
      },
      skipSegments: ["bug-reports"],
    },
    {
      // Matches feature request IDs in the URL
      pattern: /^\/stakeholder\/projects\/[^/]+\/feature-requests\/([^/]+)$/,
      resolve: async (featureRequestId: string) => {
        try {
          const projectId = window.location.pathname.split("/")[3];
          const featureRequestDoc = await getDoc(
            doc(
              db,
              "clients",
              "projects",
              projectId,
              "featureRequests",
              featureRequestId
            )
          );
          if (featureRequestDoc.exists()) {
            return `Feature Request #${featureRequestId.slice(0, 8)}`;
          }
        } catch (error) {
          console.error("Error resolving feature request:", error);
        }
        return "Feature Request";
      },
      skipSegments: ["feature-requests"],
    },
    {
      // Matches feature request IDs in the URL
      pattern: /^\/client\/management\/([^/]+)$/,
      resolve: async (clientId: string) => {
        try {
          const clientDoc = await getDoc(doc(db, "clients", clientId));
          if (clientDoc.exists()) {
            return `Client Management #${clientId.slice(0, 8)}`;
          }
        } catch (error) {
          console.error("Error resolving feature request:", error);
        }
        return "Client Management";
      },
      skipSegments: ["client-management"],
    },
  ];
}

export const resolveBreadcrumbName = async (
  path: string
): Promise<string | null> => {
  for (const resolver of breadcrumbResolvers()) {
    const match = path.match(resolver.pattern);
    if (match) {
      return resolver.resolve(match[1]);
    }
  }
  return null;
};

export const shouldSkipSegment = (segment: string): boolean => {
  return breadcrumbResolvers().some((resolver) =>
    resolver.skipSegments?.includes(segment)
  );
};
