import React, { useEffect } from "react";
import {
  Container,
  Box,
  Paper
} from "@mui/material";
import { authBackground, logoNoBackground } from "assets";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useMFA } from "hooks/auth/useMFA";
import { MainLoading } from "../MainLoading";

const AuthLayout: React.FC = () => {

  const { isLoading, currentUser, userInfo } = useAuth();
  const { resolver } = useMFA();
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <MainLoading />;
  }

  useEffect(() => {
    if (userInfo?.role) {
      navigate(`/${userInfo?.role}`);
    } 
     if (resolver && location.pathname !== "/login-mfa-code") {
      navigate("/login-mfa-code");
    } 
     if (location.pathname === "/" && !currentUser) {
      navigate("/login");
    } 
  }, [resolver, location.pathname, navigate, currentUser, userInfo]);

  // Use a wider container for signup page
  const isSignupPage = location.pathname === "/signup";
  
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundImage: `url(${authBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "15vh",
      }}
    >
      <Container component="main" maxWidth={isSignupPage ? "sm" : "xs"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={logoNoBackground}
            alt="FreeTech Logo"
            sx={{
              width: "200px",
              height: "auto",
              marginBottom: 4,
            }}
          />
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "white",
              width: "100%",
              borderRadius: 2,
              boxShadow: "0 4px 20px  black", //rgba(0,0,0,0.1)',
            }}
          >
            <Outlet />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthLayout;
