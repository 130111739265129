import { getDocument } from "core/firestore";
import { Project } from "@freetech/models/projects";

export const getProjectName = async (
  clientId: string,
  projectId: string
): Promise<Partial<string>> => {
  const project = await getDocument<Project>(
    `clients/${clientId}/projects/${projectId}`
  );
  return project?.name || "Unknown Project";
};
