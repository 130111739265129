  import { getDocuments } from "core/firestore";
import { sortObjectArrayByColumn } from "utils/sortObjectArrayByColumn";
import { collectionGroup, query, where, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { ProjectAssignment } from "@freetech/models/projects";

export const getEngineerAssignments = async (engineerId: string): Promise<ProjectAssignment[]> => {
  const projectAssignmentsQuery = query(
    collectionGroup(db, 'projectAssignments'),
    where("engineerId", "==", engineerId)
  );
  
  const querySnapshot = await getDocs(projectAssignmentsQuery);
  const projectAssignments = querySnapshot.docs.map(doc => ({
    ...doc.data(),
    id: doc.id
  } as ProjectAssignment));

  
  
  return sortObjectArrayByColumn(projectAssignments, 'startDate', 'desc');
};
