import React from "react";
import { Box, Typography, Chip, CircularProgress } from "@mui/material";
import { DateTime } from "luxon";
import { useFreelancerPayroll } from "hooks/freelancer/useFreelancerPayroll";
import { FreelancerPayment } from "@freetech/models/bank";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useFreelancerRoleClients } from "hooks/freelancer";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";

interface PaymentHistoryTableProps {
  totalCount: number;
  page: number;
  onPageChange: (event: unknown, newPage: number) => void;
  rowsPerPage: number;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PaymentHistoryTable: React.FC<PaymentHistoryTableProps> = ({
  totalCount,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}) => {
  const { payments, isPaymentsLoading } = useFreelancerPayroll();
  const { clients } = useFreelancerRoleClients();

  const { freelancerProfile } = useFreelancerProfile();

  const projects = clients.flatMap((client) => client.projects);
  const projectAssignments = clients.flatMap(
    (client) => client.projectAssignments
  );

  const { data: publicUserList } = usePublicUserList();

  const getStatusColor = (status: FreelancerPayment["status"]) => {
    switch (status) {
      case "completed":
        return "success";
      case "pending":
        return "warning";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  const formatDate = (dateString: string) => {
    return DateTime.fromISO(dateString).toFormat("MMM d, yyyy");
  };

  const columns: GridColDef<FreelancerPayment>[] = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 130,
      valueFormatter: (params: { value: string | null | undefined }) =>
        params?.value
          ? DateTime.fromISO(params.value).toLocaleString(DateTime.DATE_SHORT)
          : "",
    },
    {
      field: "isExternalPayment",
      headerName: "Type",
      width: 130,
      renderCell: (params: GridRenderCellParams<FreelancerPayment>) =>
        params.row.isExternalPayment ? "External" : "Increase",
    },
    {
      field: "externalPaymentSource",
      headerName: "Payment Method",
      width: 160,
      renderCell: (params: GridRenderCellParams<FreelancerPayment>) =>
        params?.value ? params.value.toUpperCase() : "ACH",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 130,
      renderCell: (params: GridRenderCellParams<FreelancerPayment>) =>
        `$${params.row.amount?.toFixed(2) ?? "0.00"}`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params: GridRenderCellParams<FreelancerPayment>) => {
        const status = params.value?.toString() || "Unknown";
        return (
          <Chip
            label={
              status.charAt(0).toUpperCase() + (status ? status.slice(1) : "")
            }
            color={getStatusColor(params.value as FreelancerPayment["status"])}
            size="small"
          />
        );
      },
    },
    {
      field: "memo",
      headerName: "Description",
      flex: 1,
      minWidth: 200,
    },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }: { row: FreelancerPayment }) => (
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Payment Details
        </Typography>
        {freelancerProfile?.increaseExternalAccountProfile
          ?.testPaymentIncreaseId === row.id ? (
          <Typography gutterBottom>
            This payment is a test payment to verify your direct deposit
            account.
          </Typography>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 2,
            }}
          >
            {row.clientId && (
              <Box>
                <Typography variant="caption" color="textSecondary">
                  Client
                </Typography>
                <Typography>
                  {
                    clients?.find((client) => client.id === row.clientId)
                      ?.companyName
                  }
                </Typography>
              </Box>
            )}
            {row.projectId && (
              <Box>
                <Typography variant="caption" color="textSecondary">
                  Projec
                </Typography>
                <Typography>
                  {
                    projects?.find((project) => project?.id === row.projectId)
                      ?.name
                  }
                </Typography>
              </Box>
            )}
            <Box>
              <Typography variant="caption" color="textSecondary">
                Project Assignment ID
              </Typography>
              <Typography>
                {projectAssignments?.find(
                  (assignment) => assignment?.id === row.projectAssignmentId
                )?.csaId || "N/A"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="textSecondary">
                Created At
              </Typography>
              <Typography>
                {DateTime.fromISO(row.createdAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="textSecondary">
                Updated At
              </Typography>
              <Typography>
                {DateTime.fromISO(row.updatedAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              </Typography>
            </Box>
            {row.submittedByAdminUserId && (
              <Box>
                <Typography variant="caption" color="textSecondary">
                  Submitted By
                </Typography>
                <Typography>
                  {
                    publicUserList?.find(
                      (user) => user.id === row.submittedByAdminUserId
                    )?.displayName
                  }
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    ),
    []
  );

  if (isPaymentsLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={4}
        sx={{
          height: "25vh",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Loading payment history...
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4, height: 600 }}>
      <Typography variant="h6" gutterBottom>
        Payment History
      </Typography>
      <DataGridPro
        rows={payments || []}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        disableRowSelectionOnClick
        loading={isPaymentsLoading}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 200}
        rowHeight={60}
      />
    </Box>
  );
};

export default PaymentHistoryTable;
