import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateBugReportAttachments, removeBugReportAttachment } from "core/bugReport";
import { useAuth } from "hooks/auth/useAuth";
import { useState, useCallback } from "react";
import {
  BugReportComment,
  IBugReport,
  ICreateBugReport,
  BugReportAttachment
} from "@freetech/models/bugReport";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "core/firestore";

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "text/plain",
];

interface UseStakeholderBugReportProps {
  projectId: string;
  clientId: string;
}

export const useStakeholderBugReport = ({
  clientId,
}: UseStakeholderBugReportProps) => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);

  // Get bug reports for the current project
  const { data: bugReports, isLoading: isLoadingReports } = useQuery({
    queryKey: ["bugReports"],
    queryFn: async () => {
      if (!clientId || !userInfo) {
        return [];
      }

      const q = query(
        collection(db, "bugReports"),
        where("clientId", "==", clientId)
      );

      const reports = await getDocs(q);

      return reports.docs.map((doc) => {
        const data = doc.data() as IBugReport;
        return {
          ...data,
          id: doc.id,
        };
      });
    },
    select: (data) => data,
    enabled: !!clientId && !!userInfo,
  });

  const createReport = useMutation<string, Error, ICreateBugReport>({
    mutationFn: async (data) => {
      const docRef = await addDoc(collection(db, "bugReports"), {
        ...data,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: "open",
      });

      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports"] });
    },
  });

  const updateAttachments = useMutation<
    void,
    Error,
    { bugReportId: string; files: File[] }
  >({
    mutationFn: ({ bugReportId, files }) =>
      updateBugReportAttachments(bugReportId, files),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports"] });
    },
  });

  // Add a mutation for removing attachments
  const removeAttachment = useMutation<
    void,
    Error,
    { bugReportId: string; attachmentId: string }
  >({
    mutationFn: ({ bugReportId, attachmentId }) =>
      removeBugReportAttachment(bugReportId, attachmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports"] });
      queryClient.invalidateQueries({ queryKey: ["bugReport", clientId] });
    },
  });

  // Add comment mutation
  const addComment = useMutation({
    mutationFn: async ({
      bugReportId,
      content,
    }: {
      bugReportId: string;
      content: string;
    }) => {
      if (!userInfo) throw new Error("User info not found");

      const newComment: BugReportComment = {
        id: crypto.randomUUID(),
        content,
        userId: userInfo.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      const docRef = doc(db, "bugReports", bugReportId);
      const bugReport = bugReports?.find((report) => report.id === bugReportId);

      if (!bugReport) throw new Error("Bug report not found");

      await updateDoc(docRef, {
        comments: [...(bugReport.comments || []), newComment],
        updatedAt: new Date().toISOString(),
      });

      return newComment;
    },
    onSuccess: (_, { bugReportId }) => {
      queryClient.invalidateQueries({ queryKey: ["bugReports"] });
      queryClient.invalidateQueries({ queryKey: ["bugReport", bugReportId] });
    },
  });

  const handleFileSelect = useCallback(
    (acceptedFiles: File[], rejectedFiles: any[]) => {
      // Handle rejected files
      if (rejectedFiles.length > 0) {
        const errorMessages = rejectedFiles
          .map(
            ({ file, errors }) =>
              `${file.name}: ${errors.map((e: any) => e.message).join(", ")}`
          )
          .join("; ");
        setUploadError(errorMessages);
        return;
      }

      // Validate accepted files
      const invalidFiles = acceptedFiles.filter(
        (file) =>
          !ALLOWED_FILE_TYPES.includes(file.type) || file.size > MAX_FILE_SIZE
      );

      if (invalidFiles.length > 0) {
        setUploadError(
          `Invalid files: ${invalidFiles.map((f) => f.name).join(", ")}. Files must be under 10MB and of type: ${ALLOWED_FILE_TYPES.join(", ")}`
        );
        return;
      }

      setUploadError(null);
      setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
    },
    []
  );

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    if (selectedFiles.length === 1) {
      setUploadError(null);
    }
  };

  const resetFiles = () => {
    setSelectedFiles([]);
    setUploadError(null);
  };

  const submitBugReport = async (data: ICreateBugReport, files?: File[]) => {
    try {
      // First create the bug report
      const bugReportId = await createReport.mutateAsync(data);
      console.log("bugReportId", bugReportId);
      console.log("files", files);

      // Then handle file uploads if any
      if (files && files.length > 0) {
        try {
          await updateAttachments.mutateAsync({
            bugReportId,
            files,
          });
        } catch (error) {
          console.error("Error updating attachments:", error);
        }
      }

      return bugReportId;
    } catch (error) {
      throw error;
    }
  };

  return {
    bugReports,
    isLoadingReports,
    submitBugReport,
    isSubmitting: createReport.isPending || updateAttachments.isPending,
    // File handling
    selectedFiles,
    uploadError,
    setUploadError,
    handleFileSelect,
    handleRemoveFile,
    resetFiles,
    // Comment handling
    addComment: addComment.mutateAsync,
    isAddingComment: addComment.isPending,
    // Attachment handling
    removeAttachment: removeAttachment.mutateAsync,
    isRemovingAttachment: removeAttachment.isPending,
  };
};
