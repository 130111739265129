import React from 'react'
import { Navigation, NavigationPageItem } from 'types/navigation'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useTheme, Theme } from '@mui/material/styles'

interface RenderOptions {
  open: boolean
  setOpen: (open: boolean) => void
  location: { pathname: string }
  openDropdowns: { [key: string]: boolean }
  handleNavigation: (path?: string) => void
  handleDropdownToggle: (segment: string) => void
  theme: Theme
}

const renderNavigationItem = (item: NavigationPageItem, index: number, options: RenderOptions) => {
  const { open, location, openDropdowns, handleNavigation, handleDropdownToggle, theme } = options
  const isCurrentPage = location.pathname === `/${item.segment}`
  const hasChildren = item.children && item.children.length > 0
  const isDropdownOpen = openDropdowns[item.segment || '']

  // Check if any child is selected
  const isChildSelected =
    hasChildren &&
    item.children?.some(child => {
      if (child.kind === 'header' || child.kind === 'divider') return false
      const pageChild = child as NavigationPageItem
      return location.pathname === `/${item.segment}/${pageChild.segment}`
    })

  // Parent should be highlighted if it's current page or if any child is selected
  const shouldHighlight = isCurrentPage || isChildSelected

  return (
    <React.Fragment key={item.segment || `item-${index}`}>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <Tooltip title={open ? '' : item.title || ''} placement='right' arrow>
          <ListItemButton
            onClick={() => {
              if (hasChildren) {
                if (!open) {
                  options.setOpen(true)
                  handleDropdownToggle(item.segment || '')
                } else {
                  handleDropdownToggle(item.segment || '')
                }
              } else {
                handleNavigation(item.segment)
              }
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              backgroundColor: shouldHighlight ? theme.palette.primary.main : 'transparent',
              borderLeft: shouldHighlight ? '3px solid white' : '3px solid transparent',
              '&:hover': {
                backgroundColor: shouldHighlight ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.04)',
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: theme => (shouldHighlight ? 'white' : ''),
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              sx={{
                opacity: open ? 1 : 0,
                color: shouldHighlight ? 'white' : 'inherit',
              }}
            />
            {hasChildren &&
              open &&
              (isDropdownOpen ? (
                <ExpandLess sx={{ color: theme => (shouldHighlight ? 'white' : theme.palette.text.primary) }} />
              ) : (
                <ExpandMore sx={{ color: theme => (shouldHighlight ? 'white' : theme.palette.text.primary) }} />
              ))}
          </ListItemButton>
        </Tooltip>
      </ListItem>
      {hasChildren && (
        <Collapse in={isDropdownOpen && open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {item.children?.map((child, childIndex) => {
              if (child.kind === 'header' || child.kind === 'divider') return null

              const isChildCurrentPage = location.pathname === `/${item.segment}/${child.segment}`

              return (
                <ListItem key={`${item.segment}-child-${childIndex}`} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() => handleNavigation(item.segment + '/' + child.segment)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      pl: 4,
                      backgroundColor:
                        location.pathname === `/${item.segment}/${child.segment}`
                          ? 'rgba(0, 0, 0, 0.3)'
                          : 'transparent',
                      '&:hover': {
                        backgroundColor:
                          location.pathname === `/${item.segment}/${child.segment}`
                            ? 'rgba(0, 0, 0, 0.3)'
                            : 'rgba(255, 255, 255, 0.02)',
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: theme => (location.pathname === `/${item.segment}/${child.segment}` ? 'white' : ''),
                      }}
                    >
                      {child.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={child.title}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: location.pathname === `/${item.segment}/${child.segment}` ? 'white' : 'inherit',
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )
}

export const mapPagesToDrawer = (pages: Navigation, options: RenderOptions) => {
  const { open } = options
  const theme = useTheme()
  const optionsWithTheme = { ...options, theme }

  return pages.map((item, index) => {
    if (item.kind === 'header') {
      return open ? (
        <React.Fragment key={`header-${index}`}>
          <Typography
            variant='caption'
            sx={{
              px: 3,
              pt: 1,
              pb: 0.75,
              display: 'block',
              color: 'text.disabled',
              fontSize: '0.6875rem',
              letterSpacing: '0.08em',
              fontWeight: 500,
              mt: index === 0 ? 0 : 1,
            }}
          >
            {item.title}
          </Typography>
          <Divider sx={{ opacity: 0.12 }} />
        </React.Fragment>
      ) : null
    }

    if (item.kind === 'divider') {
      return <Divider key={`divider-${item.key || index}`} sx={{ my: 0.5, opacity: 1 }} />
    }

    return renderNavigationItem(item as NavigationPageItem, index, optionsWithTheme)
  })
}
