enum AdminNotificationType {
  ADMIN_USER_CREATED = "admin_user_created",
  ADMIN_USER_SIGNED_UP = "admin_user_signed_up",
  ADMIN_USER_ONBOARDING_COMPLETE = "admin_user_onboarding_complete",

  ADMIN_CLIENT_CREATED = "admin_client_created",
  ADMIN_CLIENT_UPDATED = "admin_client_updated",

  ADMIN_PROJECT_CREATED = "admin_project_created",
  ADMIN_PROJECT_UPDATED = "admin_project_updated",

  ADMIN_CSA_CREATED = "admin_csa_created",
  ADMIN_CSA_SIGNED_BY_ADMIN = "admin_csa_signed_by_admin",
  ADMIN_CSA_SIGNED_BY_STAKEHOLDER = "admin_csa_signed_by_stakeholder",

  ADMIN_INVOICE_CREATED = "admin_invoice_created",
  ADMIN_INVOICE_PAID = "admin_invoice_paid",

  ADMIN_PROJECT_ASSIGNMENT_CREATED = "admin_project_assignment_created",
  ADMIN_PROJECT_ASSIGNMENT_SIGNED = "admin_project_assignment_signed",

  ADMIN_PAYMENT_CREATED = "admin_payment_created",
  ADMIN_PAYMENT_STATUS_CHANGED = "admin_payment_status_changed",
  ADMIN_PAYMENT_COMPLETED = "admin_payment_completed",

  ADMIN_TIMESHEET_SUBMITTED = "admin_timesheet_submitted",
  ADMIN_TIMESHEET_CREATED = "admin_timesheet_created",
  ADMIN_TIMESHEET_APPROVED = "admin_timesheet_approved",
  ADMIN_TIMESHEET_REJECTED = "admin_timesheet_rejected",
}

enum StakeholderNotificationType {
  STAKEHOLDER_INVOICE_RECIEVED = "stakeholder_invoice_recieved",
  STAKEHOLDER_INVOICE_PAID = "stakeholder_invoice_paid",
  STAKEHOLDER_CSA_RECIEVED = "stakeholder_csa_recieved",
  STAKEHOLDER_PAYMENT_CREATED = "stakeholder_payment_created",
  STAKEHOLDER_PAYMENT_COMPLETED = "stakeholder_payment_recieved",
  STAKEHOLDER_PAYMENT_FAILED = "stakeholder_payment_failed",
  STAKEHOLDER_PAYMENT_REVERSED = "stakeholder_payment_reversed",
}

enum FreelancerNotificationType {
  FREELANCER_PAYMENT_UPDATED = "freelancer_payment_updated",
  FREELANCER_PAYMENT_CREATED = "freelancer_payment_created",
  FREELANCER_PROJECT_ASSIGNMENT_CREATED = "freelancer_project_assignment_created",
  FREELANCER_PROJECT_ASSIGNMENT_COMPLETED = "freelancer_project_assignment_completed",
}

enum FreeloNotificationType {
  FREELO_CARD_CREATED = "freelo_card_created",
  FREELO_CARD_UPDATED = "freelo_card_updated",
  FREELO_CARD_DELETED = "freelo_card_deleted",
  FREELO_CARD_ASSIGNED = "freelo_card_assigned",
  FREELO_CARD_COMMENT_MENTION = "freelo_card_comment_mention",
  FREELO_CARD_STATUS_CHANGED = "freelo_card_status_changed",
  FREELO_BOARD_UPDATED = "freelo_board_updated",
  FREELO_DEADLINE_APPROACHING = "freelo_deadline_approaching",
}



export {
  StakeholderNotificationType,
  FreeloNotificationType,
  AdminNotificationType,
  FreelancerNotificationType,
};
