import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  onSnapshot,
  Timestamp,
  where,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc
} from "firebase/firestore";
import { db } from "config/firebase";
import { useState, useEffect } from "react";
import { useAuth } from 'hooks/auth/useAuth';

// Define the audit log entry type
export interface AuditLogEntry {
  id: string;
  documentId: string;
  timestamp: Timestamp;
  alphas?: Record<string, any>;
  deltas?: Record<string, any>;
  fullData?: Record<string, any>;
  modifiedBy: string;
  collection: string;
  type: 'update' | 'create' | 'delete';
  identifier: string;
  docId: string;
  reverted?: boolean;
  revertedAt?: Timestamp;
  revertedBy?: string;
}

// Keys for audit log queries
export const auditLogKeys = {
  all: ['auditLogs'] as const,
  list: () => [...auditLogKeys.all, 'list'] as const,
  filters: (filters: { search?: string, collection?: string, type?: string }) => 
    [...auditLogKeys.list(), { filters }] as const,
};

export const useAdminAuditLog = () => {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterCollection, setFilterCollection] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<'update' | 'create' | 'delete' | null>(null);
  const { userInfo, currentUser } = useAuth();

  // Main query function to fetch audit logs
  const fetchAuditLogs = async (): Promise<AuditLogEntry[]> => {
    const historyRef = collection(db, "document_history");
    
    let q;
    
    // Create different queries based on filter combinations
    if (filterCollection && filterType) {
      q = query(
        historyRef,
        where("collection", "==", filterCollection),
        where("type", "==", filterType),
        orderBy("timestamp", "desc"),
        limit(100)
      );
    } else if (filterCollection) {
      q = query(
        historyRef,
        where("collection", "==", filterCollection),
        orderBy("timestamp", "desc"),
        limit(100)
      );
    } else if (filterType) {
      q = query(
        historyRef,
        where("type", "==", filterType),
        orderBy("timestamp", "desc"),
        limit(100)
      );
    } else {
      q = query(
        historyRef,
        orderBy("timestamp", "desc"),
        limit(100)
      );
    }
    
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map((doc) => ({
      ...doc.data(),
      documentId: doc.data().docId,
      id: doc.id,
    } as AuditLogEntry));
  };

  // Fetch the most recent 100 audit log entries with filters
  const {
    data: auditLogs = [],
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: auditLogKeys.filters({ 
      search: searchTerm,
      collection: filterCollection || undefined, 
      type: filterType || undefined 
    }),
    queryFn: fetchAuditLogs,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Set up real-time listener to keep data up to date
  useEffect(() => {
    const historyRef = collection(db, "document_history");
    const q = query(
      historyRef,
      orderBy("timestamp", "desc"),
      limit(100)
    );
    
    const unsubscribe = onSnapshot(q, () => {
      queryClient.invalidateQueries({ queryKey: auditLogKeys.list() });
    });

    return () => unsubscribe();
  }, [queryClient]);

  // Mutation to revert a change
  const revertChangeMutation = useMutation({
    mutationFn: async (logEntry: AuditLogEntry) => {
      // Get the document reference based on the log entry
      const docRef = doc(db, logEntry.collection, logEntry.docId);
      
      // Check if the document exists
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error("Document no longer exists and cannot be reverted");
      }

      // Get user info for audit
      const userEmail = userInfo?.email || currentUser?.email || 'unknown_user';
      const userName = userInfo?.displayName || currentUser?.displayName || userEmail;
      
      // Different handling based on operation type
      switch (logEntry.type) {
        case 'update':
          // For updates, we need to apply the previous values
          if (!logEntry.deltas) {
            throw new Error("Cannot revert: missing previous value data");
          }
          
          // Get current data and merge with previous values
          const currentData = docSnap.data();
          
          // Simply restore document to its previous state without adding complex metadata
          // Copy modified_by from previous state or remove it if not present
          const previousData = {...logEntry.deltas};
          
          // Update the document with the previous state
          await updateDoc(docRef, previousData);
          
          // Mark this entry as reverted in the audit log
          await updateDoc(doc(db, "document_history", logEntry.id), {
            reverted: true,
            revertedAt: serverTimestamp(),
            revertedBy: userName
          });
          
          return { success: true, message: "Update successfully reverted" };
          
        case 'create':
          // For creations, we need to delete the document
          await deleteDoc(docRef);
          
          // Mark this entry as reverted
          await updateDoc(doc(db, "document_history", logEntry.id), {
            reverted: true,
            revertedAt: serverTimestamp(),
            revertedBy: userName
          });
          
          return { success: true, message: "Created document successfully deleted" };
          
        case 'delete':
          // For deletions, we need to recreate the document
          if (!logEntry.fullData && !logEntry.deltas) {
            throw new Error("Cannot revert: missing document data");
          }
          
          // Use fullData if available, otherwise fall back to deltas for compatibility
          const documentData = logEntry.fullData || logEntry.deltas;
          
          // Recreate the document with its original data, without adding extra metadata
          await setDoc(docRef, documentData);
          
          // Mark this entry as reverted
          await updateDoc(doc(db, "document_history", logEntry.id), {
            reverted: true,
            revertedAt: serverTimestamp(),
            revertedBy: userName
          });
          
          return { success: true, message: "Deleted document successfully restored" };
          
        default:
          throw new Error("Unsupported operation type");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: auditLogKeys.list() });
    }
  });

  // Mutation to undo a revert
  const undoRevertMutation = useMutation({
    mutationFn: async (logEntry: AuditLogEntry) => {
      if (!logEntry.reverted) {
        throw new Error("This entry has not been reverted and cannot be undone");
      }
      
      // Get the document reference based on the log entry
      const docRef = doc(db, logEntry.collection, logEntry.docId);
      
      // Check if the document exists
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists() && logEntry.type !== 'create') {
        throw new Error("Document no longer exists and cannot be restored");
      }

      // Get user info for audit
      const userEmail = userInfo?.email || currentUser?.email || 'unknown_user';
      const userName = userInfo?.displayName || currentUser?.displayName || userEmail;
      
      // Different handling based on operation type
      switch (logEntry.type) {
        case 'update':
          // For updates, we need to apply the new values (alphas) to undo the revert
          if (!logEntry.alphas) {
            throw new Error("Cannot undo revert: missing new value data");
          }
          
          // Simply restore the document to its post-update state
          const newData = {...logEntry.alphas};
          
          // Update the document with the post-update state
          await updateDoc(docRef, newData);
          
          // Mark this entry as no longer reverted
          await updateDoc(doc(db, "document_history", logEntry.id), {
            reverted: false,
            undoRevertedAt: serverTimestamp(),
            undoRevertedBy: userName
          });
          
          return { success: true, message: "Revert successfully undone" };
          
        case 'create':
          // For creations that were reverted (meaning the doc was deleted), we need to recreate it
          if (!logEntry.alphas && !logEntry.fullData) {
            throw new Error("Cannot undo revert: missing document data");
          }
          
          // Use fullData if available, otherwise alphas for compatibility
          const documentData = logEntry.fullData || logEntry.alphas;
          
          // Recreate the document with its original data
          await setDoc(docRef, documentData);
          
          // Mark this entry as no longer reverted
          await updateDoc(doc(db, "document_history", logEntry.id), {
            reverted: false,
            undoRevertedAt: serverTimestamp(),
            undoRevertedBy: userName
          });
          
          return { success: true, message: "Document successfully restored" };
          
        case 'delete':
          // For deletions that were reverted (doc was recreated), we need to delete it again
          await deleteDoc(docRef);
          
          // Mark this entry as no longer reverted
          await updateDoc(doc(db, "document_history", logEntry.id), {
            reverted: false,
            undoRevertedAt: serverTimestamp(),
            undoRevertedBy: userName
          });
          
          return { success: true, message: "Document successfully re-deleted" };
          
        default:
          throw new Error("Unsupported operation type");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: auditLogKeys.list() });
    }
  });

  // Filter logs based on search term (client-side filtering for search)
  const filteredLogs = auditLogs.filter((log) => {
    // If there's no search term, don't filter
    if (!searchTerm) return true;
    
    // Search in identifiers, collection, or document ID
    const searchLower = searchTerm.toLowerCase();
    return Boolean(
      (log.identifier && log.identifier.toLowerCase().includes(searchLower)) ||
      (log.collection && log.collection.toLowerCase().includes(searchLower)) ||
      (log.docId && log.docId.toLowerCase().includes(searchLower))
    );
  });

  // Get unique collections for the filter dropdown
  const uniqueCollections = [...new Set(auditLogs.map(log => log.collection))];

  return {
    auditLogs: filteredLogs,
    isLoading,
    isError,
    error,
    refetch,
    searchTerm,
    setSearchTerm,
    filterCollection,
    setFilterCollection,
    filterType,
    setFilterType,
    uniqueCollections,
    revertChange: revertChangeMutation.mutateAsync,
    undoRevert: undoRevertMutation.mutateAsync,
  };
};