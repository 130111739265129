import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button, Typography, Box } from "@mui/material";

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <Box
      role="alert"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      padding={2}
      textAlign="center"
    >
      <Typography variant="h4" color="error" gutterBottom>
        Something went wrong.
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        {error.message}
      </Typography>
      <Button variant="contained" color="primary" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </Box>
  );
}

const RootErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset any state or perform any action needed to recover from the error
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default RootErrorBoundary;
