import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "config/firebase";
import { Stakeholder, PrivateUserInfoType } from "@freetech/models/user";
import { stakeholderConverter } from  "@freetech/models/converters";

export const loadNestedStakeholders = async (): Promise<Stakeholder[]> => {
  try {
    // Get all users who are stakeholders
    const usersRef = collection(db, "users").withConverter(
      stakeholderConverter
    );
    const stakeholderQuery = query(
      usersRef,
      where("role", "==", "stakeholder")
    );
    const stakeholderSnapshot = await getDocs(stakeholderQuery);

    // Map the stakeholders and include their IDs
    const stakeholders = stakeholderSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return stakeholders;
  } catch (error) {
    console.error("Error loading stakeholders:", error);
    throw error;
  }
};
