import {
  INotification,
  CardNotificationData,
  ProjectAssignmentNotificationData,
  FreelancerPaymentNotificationData,
  AdminUserNotificationData,
  AdminClientNotificationData,
  AdminProjectNotificationData,
  AdminCSANotificationData,
  AdminInvoiceNotificationData,
  AdminPaymentNotificationData,
  AdminProjectAssignmentNotificationData,
  AdminTimesheetNotificationData,
  AllNotificationType,
} from "@freetech/models/notifications";
import { notificationEnums } from "@freetech/models/notifications";
import {
  Payment as PaymentIcon,
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  Comment as CommentIcon,
  Receipt as ReceiptIcon,
  Notifications as NotificationsIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  CreditCard as CreditCardIcon,
  Dashboard as DashboardIcon,
  ViewKanban as ViewKanbanIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  Work as WorkIcon,
  Gavel as GavelIcon,
  AccessTime as TimeIcon,
  Update as UpdateIcon,
  Flag as FlagIcon,
  AlarmOn as AlarmIcon,
} from "@mui/icons-material";

/**
 * Type guard to check if a string is a valid notification type
 */
function isNotificationType(type: string): type is AllNotificationType {
  return (
    Object.values(notificationEnums.StakeholderNotificationType).includes(
      type as notificationEnums.StakeholderNotificationType
    ) ||
    Object.values(notificationEnums.FreeloNotificationType).includes(
      type as notificationEnums.FreeloNotificationType
    ) ||
    Object.values(notificationEnums.AdminNotificationType).includes(
      type as notificationEnums.AdminNotificationType
    )
  );
}

/**
 * Type guard to check if a string is a notificationEnums.StakeholderNotificationType
 */
function isStakeholderNotificationType(
  type: string
): type is notificationEnums.StakeholderNotificationType {
  return Object.values(notificationEnums.StakeholderNotificationType).includes(
    type as notificationEnums.StakeholderNotificationType
  );
}

/**
 * Type guard to check if a string is a notificationEnums.FreeloNotificationType
 */
function isFreeloNotificationType(
  type: string
): type is notificationEnums.FreeloNotificationType {
  return Object.values(notificationEnums.FreeloNotificationType).includes(
    type as notificationEnums.FreeloNotificationType
  );
}

/**
 * Type guard to check if a string is an notificationEnums.AdminNotificationType
 */
function isAdminNotificationType(
  type: string
): type is notificationEnums.AdminNotificationType {
  return Object.values(notificationEnums.AdminNotificationType).includes(
    type as notificationEnums.AdminNotificationType
  );
}

/**
 * Gets the appropriate icon component for a notification type
 * @param type The notification type
 * @param severity Optional severity to override icon based on importance
 * @returns The icon component to use
 */
export const getIcon = (type: string, severity?: string) => {
  // If severity is provided, use severity-based icons
  if (severity) {
    switch (severity) {
      case "error":
        return ErrorIcon;
      case "warning":
        return WarningIcon;
      case "success":
        return SuccessIcon;
      case "info":
        return InfoIcon;
    }
  }

  // Handle notification types based on their specific type
  if (isStakeholderNotificationType(type)) {
    switch (type) {
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_RECIEVED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_PAID:
        return ReceiptIcon;

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_CREATED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_COMPLETED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_FAILED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_REVERSED:
        return PaymentIcon;

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_CSA_RECIEVED:
        return DescriptionIcon;
    }
  }

  if (isFreeloNotificationType(type)) {
    switch (type) {
      case notificationEnums.FreeloNotificationType.FREELO_CARD_CREATED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_UPDATED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_DELETED:
        return ViewKanbanIcon;

      case notificationEnums.FreeloNotificationType.FREELO_CARD_ASSIGNED:
        return AssignmentIcon;

      case notificationEnums.FreeloNotificationType.FREELO_CARD_COMMENT_MENTION:
        return CommentIcon;

      case notificationEnums.FreeloNotificationType.FREELO_CARD_STATUS_CHANGED:
        return UpdateIcon;

      case notificationEnums.FreeloNotificationType.FREELO_BOARD_UPDATED:
        return DashboardIcon;

      case notificationEnums.FreeloNotificationType.FREELO_DEADLINE_APPROACHING:
        return AlarmIcon;
    }
  }

  if (isAdminNotificationType(type)) {
    switch (type) {
      // User-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_USER_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_USER_SIGNED_UP:
      case notificationEnums.AdminNotificationType
        .ADMIN_USER_ONBOARDING_COMPLETE:
        return PersonIcon;

      // Client-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_CLIENT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_CLIENT_UPDATED:
        return BusinessIcon;

      // Project-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_PROJECT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_PROJECT_UPDATED:
        return WorkIcon;

      // CSA-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_CSA_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_CSA_SIGNED_BY_ADMIN:
      case notificationEnums.AdminNotificationType
        .ADMIN_CSA_SIGNED_BY_STAKEHOLDER:
        return GavelIcon;

      // Invoice-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_INVOICE_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_INVOICE_PAID:
        return ReceiptIcon;

      // Payment-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_PAYMENT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_PAYMENT_COMPLETED:
      case notificationEnums.AdminNotificationType
        .ADMIN_PROJECT_ASSIGNMENT_CREATED:
      case notificationEnums.AdminNotificationType
        .ADMIN_PROJECT_ASSIGNMENT_SIGNED:
        return PaymentIcon;

      // Timesheet-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_SUBMITTED:
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_APPROVED:
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_REJECTED:
        return TimeIcon;
    }
  }

  // Default icon
  return NotificationsIcon;
};

/**
 * Formats a notification message based on its type and data
 * @param notification The notification object
 * @returns Formatted message string
 */
export const getNotificationMessage = (notification: INotification): string => {
  const { type, data } = notification;

  // If the notification has a title, use it as the primary message
  if (data.title) {
    return data.title;
  }

  // Handle notification types based on their specific type
  if (isStakeholderNotificationType(type)) {
    switch (type) {
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_RECIEVED:
        return "You have received a new invoice";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_PAID:
        return "Your invoice has been paid";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_CSA_RECIEVED:
        return "You have received a new client service agreement";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_CREATED:
        return "A new payment has been created";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_COMPLETED:
        return "Your payment has been completed";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_FAILED:
        return "Your payment has failed";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_REVERSED:
        return "Your payment has been reversed";
    }
  }

  if (isFreeloNotificationType(type)) {
    switch (type) {
      case notificationEnums.FreeloNotificationType.FREELO_CARD_CREATED:
        return "A new card has been created";

      case notificationEnums.FreeloNotificationType.FREELO_CARD_UPDATED:
        return "A card has been updated";

      case notificationEnums.FreeloNotificationType.FREELO_CARD_DELETED:
        return "A card has been deleted";

      case notificationEnums.FreeloNotificationType.FREELO_CARD_ASSIGNED:
        return "You have been assigned to a card";

      case notificationEnums.FreeloNotificationType.FREELO_CARD_COMMENT_MENTION:
        const mentionData = data as CardNotificationData;
        return (
          mentionData.content || "You have been mentioned in a card comment"
        );

      case notificationEnums.FreeloNotificationType.FREELO_CARD_STATUS_CHANGED:
        const statusData = data as CardNotificationData;
        return `Card status changed to ${statusData.newStatus || "a new status"}`;

      case notificationEnums.FreeloNotificationType.FREELO_BOARD_UPDATED:
        return "A board you're following has been updated";

      case notificationEnums.FreeloNotificationType.FREELO_DEADLINE_APPROACHING:
        const deadlineData = data as CardNotificationData;
        const days = deadlineData.daysRemaining || 0;
        return days <= 0
          ? "A card deadline is today!"
          : days === 1
            ? "A card deadline is tomorrow!"
            : `A card deadline is approaching in ${days} days`;
    }
  }

  if (isAdminNotificationType(type)) {
    switch (type) {
      // User-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_USER_CREATED:
        const userCreatedData = data as AdminUserNotificationData;
        return `New user created: ${userCreatedData.email || "Unknown"}`;

      case notificationEnums.AdminNotificationType.ADMIN_USER_SIGNED_UP:
        const userSignedUpData = data as AdminUserNotificationData;
        return `User signed up: ${userSignedUpData.email || "Unknown"}`;

      case notificationEnums.AdminNotificationType
        .ADMIN_USER_ONBOARDING_COMPLETE:
        const userOnboardingData = data as AdminUserNotificationData;
        return `Freelancer completed onboarding: ${userOnboardingData.email || "Unknown"}`;

      // Client-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_CLIENT_CREATED:
        const clientCreatedData = data as AdminClientNotificationData;
        return `New client created: ${clientCreatedData.name || "Unknown"}`;

      case notificationEnums.AdminNotificationType.ADMIN_CLIENT_UPDATED:
        const clientUpdatedData = data as AdminClientNotificationData;
        return `Client updated: ${clientUpdatedData.name || "Unknown"}`;

      // Project-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_PROJECT_CREATED:
        const projectCreatedData = data as AdminProjectNotificationData;
        return `New project created: ${projectCreatedData.name || "Unknown"}`;

      case notificationEnums.AdminNotificationType.ADMIN_PROJECT_UPDATED:
        const projectUpdatedData = data as AdminProjectNotificationData;
        return `Project updated: ${projectUpdatedData.name || "Unknown"}`;

      // CSA-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_CSA_CREATED:
        const csaCreatedData = data as AdminCSANotificationData;
        return `New CSA created for client: ${csaCreatedData.clientName || "Unknown"}`;

      case notificationEnums.AdminNotificationType.ADMIN_CSA_SIGNED_BY_ADMIN:
        const csaSignedByAdminData = data as AdminCSANotificationData;
        return `CSA signed by admin for client: ${csaSignedByAdminData.clientName || "Unknown"}`;

      case notificationEnums.AdminNotificationType
        .ADMIN_CSA_SIGNED_BY_STAKEHOLDER:
        const csaSignedByStakeholderData = data as AdminCSANotificationData;
        return `CSA signed by stakeholder for client: ${csaSignedByStakeholderData.clientName || "Unknown"}`;

      // Invoice-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_INVOICE_CREATED:
        const invoiceCreatedData = data as AdminInvoiceNotificationData;
        return `New invoice created: ${invoiceCreatedData.invoiceNumber || "Unknown"}`;

      case notificationEnums.AdminNotificationType.ADMIN_INVOICE_PAID:
        const invoicePaidData = data as AdminInvoiceNotificationData;
        return `Invoice paid: ${invoicePaidData.invoiceNumber || "Unknown"}`;

      // Payment-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_PAYMENT_CREATED:
        const paymentCreatedData = data as AdminPaymentNotificationData;
        return `New payment created: ${paymentCreatedData.amount ? `$${paymentCreatedData.amount}` : "Unknown amount"}`;

      case notificationEnums.AdminNotificationType.ADMIN_PAYMENT_COMPLETED:
        const paymentCompletedData = data as AdminPaymentNotificationData;
        return `Payment completed: ${paymentCompletedData.amount ? `$${paymentCompletedData.amount}` : "Unknown amount"}`;

      case notificationEnums.AdminNotificationType
        .ADMIN_PROJECT_ASSIGNMENT_CREATED:
        const assignmentCreatedData =
          data as AdminProjectAssignmentNotificationData;
        return `New project assignment created for: ${assignmentCreatedData.freelancerName || "Unknown freelancer"}`;

      case notificationEnums.AdminNotificationType
        .ADMIN_PROJECT_ASSIGNMENT_SIGNED:
        const assignmentSignedData =
          data as AdminProjectAssignmentNotificationData;
        return `Project assignment signed by: ${assignmentSignedData.freelancerName || "Unknown freelancer"}`;

      // Timesheet-related admin notifications
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_CREATED:
        const timesheetCreatedData = data as AdminTimesheetNotificationData;
        return `New timesheet created by: ${timesheetCreatedData.freelancerName || "Unknown freelancer"}`;

      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_SUBMITTED:
        const timesheetSubmittedData = data as AdminTimesheetNotificationData;
        return `Timesheet submitted by: ${timesheetSubmittedData.freelancerName || "Unknown freelancer"}`;

      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_APPROVED:
        const timesheetApprovedData = data as AdminTimesheetNotificationData;
        return `Timesheet approved for: ${timesheetApprovedData.freelancerName || "Unknown freelancer"}`;

      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_REJECTED:
        const timesheetRejectedData = data as AdminTimesheetNotificationData;
        return `Timesheet rejected for: ${timesheetRejectedData.freelancerName || "Unknown freelancer"}`;
    }
  }

  // Default message
  return "New notification";
};

/**
 * Gets a detailed description for a notification
 * @param notification The notification object
 * @returns Detailed description or undefined if not available
 */
export const getNotificationDescription = (
  notification: INotification
): string | undefined => {
  const { data } = notification;

  // If the notification has a description, use it
  if (data.description) {
    return data.description;
  }

  // Otherwise return undefined
  return undefined;
};

/**
 * Type guard to check if a value is a valid severity
 */
function isValidSeverity(
  value: any
): value is "info" | "success" | "warning" | "error" {
  return ["info", "success", "warning", "error"].includes(value);
}

/**
 * Gets the severity level for a notification
 * @param notification The notification object
 * @returns Severity level ('info', 'success', 'warning', 'error')
 */
export const getNotificationSeverity = (
  notification: INotification
): "info" | "success" | "warning" | "error" => {
  const { type, data } = notification;

  // If the notification has a severity, use it
  if ("severity" in data && data.severity && isValidSeverity(data.severity)) {
    return data.severity;
  }

  // Handle notification types based on their specific type
  if (isStakeholderNotificationType(type)) {
    switch (type) {
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_FAILED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_REVERSED:
        return "error";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_CREATED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_RECIEVED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_CSA_RECIEVED:
        return "warning";

      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_PAYMENT_COMPLETED:
      case notificationEnums.StakeholderNotificationType
        .STAKEHOLDER_INVOICE_PAID:
        return "success";
    }
  }

  if (isFreeloNotificationType(type)) {
    switch (type) {
      case notificationEnums.FreeloNotificationType.FREELO_CARD_DELETED:
        return "error";

      case notificationEnums.FreeloNotificationType.FREELO_CARD_CREATED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_ASSIGNED:
        return "success";

      case notificationEnums.FreeloNotificationType.FREELO_CARD_UPDATED:
      case notificationEnums.FreeloNotificationType.FREELO_CARD_COMMENT_MENTION:
      case notificationEnums.FreeloNotificationType.FREELO_BOARD_UPDATED:
        return "info";

      case notificationEnums.FreeloNotificationType.FREELO_CARD_STATUS_CHANGED:
        return "success";

      case notificationEnums.FreeloNotificationType.FREELO_DEADLINE_APPROACHING:
        return "warning";
    }
  }

  if (isAdminNotificationType(type)) {
    switch (type) {
      // Error severity notifications
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_REJECTED:
        return "error";

      // Warning severity notifications
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_SUBMITTED:
        return "warning";

      // Success severity notifications
      case notificationEnums.AdminNotificationType.ADMIN_USER_SIGNED_UP:
      case notificationEnums.AdminNotificationType
        .ADMIN_USER_ONBOARDING_COMPLETE:
      case notificationEnums.AdminNotificationType.ADMIN_CLIENT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_PROJECT_CREATED:
      case notificationEnums.AdminNotificationType.ADMIN_CSA_SIGNED_BY_ADMIN:
      case notificationEnums.AdminNotificationType
        .ADMIN_CSA_SIGNED_BY_STAKEHOLDER:
      case notificationEnums.AdminNotificationType.ADMIN_INVOICE_PAID:
      case notificationEnums.AdminNotificationType.ADMIN_PAYMENT_COMPLETED:
      case notificationEnums.AdminNotificationType
        .ADMIN_PROJECT_ASSIGNMENT_SIGNED:
      case notificationEnums.AdminNotificationType.ADMIN_TIMESHEET_APPROVED:
        return "success";

      // Info severity for all other admin notifications
      default:
        return "info";
    }
  }

  // Default severity
  return "info";
};
