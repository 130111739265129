import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  IconButton,
  Tooltip,
  Button,
  Paper,
  Chip,
  Divider,
} from "@mui/material";
import { useNotifications } from "../../../../hooks/notifications/useNotifications";
import { useAuth } from "../../../../hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import {
  getIcon,
  getNotificationMessage,
  getNotificationDescription,
  getNotificationSeverity,
} from "../../../../core/notifications/getNotificationDetails";
import { handleNotificationClick } from "../../../../core/notifications/handleNotificationClick";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { DateTime } from "luxon";

export const NotificationList: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { notifications, markAsRead, markAllAsRead } = useNotifications(
    userInfo?.id || ""
  );

  // Sort notifications by date (newest first)
  const sortedNotifications = [...notifications].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB.getTime() - dateA.getTime();
  });

  const unreadCount = sortedNotifications.filter((n) => !n.read).length;

  // Group notifications by date
  const groupedNotifications = sortedNotifications.reduce(
    (groups, notification) => {
      const date = new Date(notification.createdAt);

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      const lastWeek = new Date(today);
      lastWeek.setDate(lastWeek.getDate() - 7);

      let groupKey: string;

      if (date >= today) {
        groupKey = "Today";
      } else if (date >= yesterday) {
        groupKey = "Yesterday";
      } else if (date >= lastWeek) {
        groupKey = "This Week";
      } else {
        groupKey = "Earlier";
      }

      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }

      groups[groupKey].push(notification);
      return groups;
    },
    {} as Record<string, typeof sortedNotifications>
  );

  // Get severity color for notification
  const getSeverityColor = (
    severity: "info" | "success" | "warning" | "error"
  ) => {
    switch (severity) {
      case "error":
        return "error.main";
      case "warning":
        return "warning.main";
      case "success":
        return "success.main";
      case "info":
      default:
        return "primary.main";
    }
  };

  // Format date for display
  const formatDate = (date: Date | string) => {
    const dateObj = date instanceof Date ? date : new Date(date);
    return DateTime.fromJSDate(dateObj).toLocaleString(DateTime.DATETIME_SHORT);
  };

  return (
    <Paper elevation={2} sx={{ p: 4, borderRadius: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Recent Notifications
          {unreadCount > 0 && (
            <Chip
              size="small"
              label={`${unreadCount} unread`}
              color="error"
              sx={{ ml: 2, height: 24 }}
            />
          )}
        </Typography>
        {unreadCount > 0 && (
          <Button
            startIcon={<DoneAllIcon />}
            onClick={() => markAllAsRead.mutate()}
            disabled={markAllAsRead.isPending}
            variant="outlined"
            size="small"
          >
            Mark all as read
          </Button>
        )}
      </Box>

      {Object.keys(groupedNotifications).length > 0 ? (
        Object.entries(groupedNotifications).map(
          ([dateGroup, groupNotifications]) => (
            <Box key={dateGroup} sx={{ mb: 4 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                  color: "text.secondary",
                  fontSize: "0.875rem",
                  textTransform: "uppercase",
                  letterSpacing: "0.05em",
                }}
              >
                {dateGroup}
              </Typography>
              <List sx={{ p: 0 }}>
                {groupNotifications.map((notification) => {
                  const severity = getNotificationSeverity(notification);
                  const description = getNotificationDescription(notification);
                  const message = getNotificationMessage(notification);
                  const destination = handleNotificationClick(notification);
                  const isClickable = !!destination;

                  return (
                    <ListItem
                      key={notification.id}
                      sx={{
                        padding: "16px",
                        cursor: isClickable ? "pointer" : "default",
                        backgroundColor: notification.read
                          ? "transparent"
                          : "action.hover",
                        borderRadius: 1,
                        mb: 1,
                        transition: "all 0.2s ease",
                        "&:hover": isClickable
                          ? {
                              backgroundColor: "action.selected",
                            }
                          : {},
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          left: 0,
                          top: 0,
                          bottom: 0,
                          width: "4px",
                          backgroundColor: notification.read
                            ? "transparent"
                            : getSeverityColor(severity),
                        },
                      }}
                      onClick={() => {
                        if (destination) {
                          navigate(destination);
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Avatar
                          sx={{
                            bgcolor: notification.read
                              ? "grey.200"
                              : `${getSeverityColor(severity)}20`,
                            color: notification.read
                              ? "grey.500"
                              : getSeverityColor(severity),
                            transition: "background-color 0.2s ease",
                          }}
                        >
                          {React.createElement(
                            getIcon(notification.type, severity) || "div"
                          )}
                        </Avatar>
                      </ListItemIcon>
                      <Box sx={{ flex: 1, ml: 1 }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: notification.read
                              ? "regular"
                              : "medium",
                            color: notification.read
                              ? "text.secondary"
                              : "text.primary",
                            mb: description ? 0.5 : 0,
                          }}
                        >
                          {message}
                        </Typography>
                        {description && (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 1 }}
                          >
                            {description}
                          </Typography>
                        )}
                        <Typography variant="caption" color="text.secondary">
                          {formatDate(notification.createdAt)}
                        </Typography>
                      </Box>
                      {!notification.read && (
                        <Tooltip title="Mark as read">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if (notification.id) {
                                markAsRead.mutate(notification.id);
                              }
                            }}
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )
        )
      ) : (
        <Typography
          sx={{
            padding: "32px",
            textAlign: "center",
            color: "text.secondary",
            bgcolor: "action.hover",
            borderRadius: 1,
          }}
        >
          No notifications
        </Typography>
      )}
    </Paper>
  );
};
