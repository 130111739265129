import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import {
  AuthRoute,
  SuperUserRoute,
  FreelancerRoute,
  ProtectedRoute,
  StakeholderRoute,
  ExecutiveAssistantRoute,
  StudentRoute,
} from "./routes";
import {
  stakeholderPages,
  superuserPages,
  getFreelancerPages,
  executiveAssistantPages,
  studentPages,
} from "router/pages";
import {
  AuthLayout,
  OnboardingLayout,
  CoreLayout,
  MainLoading,
} from "./layouts";

import {
  Login,
  SignUp,
  ForgotPassword,
  ResetPassword,
  NotFound,
  AccountDeactivated,
  SessionExpired,
} from "views/Auth";

import StandardErrorBoundary from "views/Shared/ErrorBoundaries/StandardErrorBoundary";
import { FreelancerOnboarding } from "views/Freelancer";
import { StakeholderOnboarding } from "views/Stakeholder";
import { AccountSettings } from "views/Shared/AccountSettings/AccountSettings";
import { LoginMFACode } from "views/Auth/LoginMFACode";
import { useAuth } from "hooks/auth";
import { NeedsResetPassword } from "../views/Shared/NeedsResetPassword/NeedsResetPassword";
import { mapPagesToReactRouter } from "router/mapPagesToReactRouter";
import { NotificationCenter } from "views/Shared/Notifications/NotificationCenter";
import { NewProjectSetup } from "views/Stakeholder/StakeholderProjectHub/NewProjectSetup";
import { ProjectIdeaChat } from "views/Stakeholder/StakeholderProjectHub/NewProjectSetup/ProjectIdeaChat";
import { StudentOnboarding } from "views/Student";
import { StudentManageSubscription } from "views/Student/ManageSubscription";

export const AppRoutes = () => {
  const { isLoading, userInfo } = useAuth();

  if (isLoading) {
    return <MainLoading />;
  }

  const AuthRoutes = () => {
    return (
      <>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />

        <Route
          path="/signup"
          element={
            <AuthRoute>
              <SignUp />
            </AuthRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />

        <Route
          path="/reset-password"
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        />

        <Route
          path="/login-mfa-code"
          element={
            <AuthRoute>
              <LoginMFACode />
            </AuthRoute>
          }
        />

        <Route path="/session-expired" element={<SessionExpired />} />
      </>
    );
  };

  const RedirectRoutes = () => {
    return (
      <>
        {/* Not Found - other pages redirect here */}
        <Route path="/not-found" element={<NotFound />} />

        {/* Redirect logic to /login or /role occurs in AuthLayout */}
        <Route path="/" />

        {/* Redirect * to NotFound to hide SuperUser Routes  */}
        <Route path="*" element={<Navigate to="/not-found" replace />} />

        <Route path="/account-deactivated" element={<AccountDeactivated />} />
      </>
    );
  };

  const FreelancerRoutes = () => {
    return (
      <>
        {mapPagesToReactRouter(getFreelancerPages(userInfo), FreelancerRoute)}
      </>
    );
  };

  const SuperUserRoutes = () => {
    return <>{mapPagesToReactRouter(superuserPages, SuperUserRoute)}</>;
  };

  const StakeholderRoutes = () => {
    return <>{mapPagesToReactRouter(stakeholderPages, StakeholderRoute)}</>;
  };

  const ExecutiveAssistantRoutes = () => {
    return (
      <>
        {mapPagesToReactRouter(
          executiveAssistantPages,
          ExecutiveAssistantRoute
        )}
      </>
    );
  };

  const StudentRoutes = () => {
    return <>{mapPagesToReactRouter(studentPages, StudentRoute)}</>;
  };

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          {AuthRoutes()}
          {RedirectRoutes()}
        </Route>

        {/* Onboarding Layout */}
        <Route element={<OnboardingLayout />}>
          <Route
            path="/freelancer-onboarding"
            element={<FreelancerOnboarding />}
          />
          <Route
            path="/stakeholder-onboarding"
            element={<StakeholderOnboarding />}
          />
          <Route
            path="/student-onboarding"
            element={<StudentOnboarding />}
          />

          <Route
            path="/needs-reset-password"
            element={<NeedsResetPassword />}
          />

          <Route
            path="/executiveAssistant-onboarding"
            element={<div>Executive Assistant Onboarding</div>}
          />

          <Route path="/student-onboarding" element={<StudentOnboarding />} />
        </Route>

        <Route element={<CoreLayout />}>
          <Route
            element={
              <StandardErrorBoundary>
                <Outlet />
              </StandardErrorBoundary>
            }
          >
            <Route
              path="/account-settings"
              element={
                <ProtectedRoute>
                  <AccountSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <NotificationCenter />
                </ProtectedRoute>
              }
            />
            {FreelancerRoutes()}
            {SuperUserRoutes()}
            {StakeholderRoutes()}
            {ExecutiveAssistantRoutes()}
            {StudentRoutes()}
            {/* Additional Stakeholder Routes */}
            <Route
              path="/stakeholder/projects/new-idea"
              element={
                <StakeholderRoute>
                  <NewProjectSetup />
                </StakeholderRoute>
              }
            />
            <Route
              path="/stakeholder/projects/new-idea/:projectIdeaId"
              element={
                <StakeholderRoute>
                  <ProjectIdeaChat />
                </StakeholderRoute>
              }
            />
            <Route
              path="/student/manage-subscription"
              element={
                <StudentRoute>
                  <StudentManageSubscription />
                </StudentRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
