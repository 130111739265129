import {
  Home as HomeIcon,
  Group as GroupIcon,
  Person2,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  People as PeopleIcon,
  AccountBalance as AccountBalanceIcon,
  MonetizationOn as MonetizationOnIcon,
  Work as ContractorIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  School as SchoolIcon,
  CastForEducation as CastForEducationIcon,
  RssFeed as RssFeedIcon,
  Gavel as GavelIcon,
  Draw as DrawIcon,
} from "@mui/icons-material";
import {
  UserManagement,
  ClientManagement,
  FreelancerManagement,
  TimeTracker,
  AgreementManagement,
  BlogWriter,
  CSAManagement,
  EmailManagement,
  StudentManagement,
  SuperAdminHome,
} from "views/SuperAdmin";
import FreelancerOverview from "views/SuperAdmin/FreelancerManagement/FreelancerOverview/FreelancerOverview";
import { Navigation } from "types/navigation";
import KnowledgeBase from "views/KnowledgeBase";
import { FreeloHome } from "views/FreeloHome";
import { FreeloBoard } from "views/FreeloBoard";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { AdminPayroll } from "views/SuperAdmin/AdminPayroll/AdminPayroll";
import { ContractorDetail } from "views/SuperAdmin/AdminPayroll/components/ContractorDetail";
import { TimesheetManagement } from "views/SuperAdmin/TimesheetManagement";
import ClientOverviewV2 from "views/SuperAdmin/ClientManagement/ClientOverviewV2/ClientOverviewV2";
import HubIcon from "@mui/icons-material/Hub";
import HailIcon from "@mui/icons-material/Hail";
import ScienceIcon from "@mui/icons-material/Science";
import { BlogPostEditor } from "views/SuperAdmin/MarketingCMS/BlogWriter/BlogPostEditor";
import {
  JobPoster,
  JobPostEditor,
} from "views/SuperAdmin/MarketingCMS/JobPoster";
import WorkIcon from "@mui/icons-material/Work";
import { MarketingCMS } from "views/SuperAdmin/MarketingCMS/MarketingCMS";
import SellIcon from "@mui/icons-material/Sell";
import AuditLog from "views/SuperAdmin/AuditLog/AuditLog";
import { ManageMyStudents } from "views/Shared";
import { AdminFinancialHub } from "views/SuperAdmin";

export const superuserPages: Navigation = [
  {
    kind: "page",
    title: "Home",
    segment: "admin",
    icon: <HomeIcon />,
    component: <SuperAdminHome />,
    // component: (
    //   <div>New Admin Dashboard coming soon - clearing clutter for now.</div>
    // ),
  },
  {
    segment: "admin/tracker",
    title: "Time Tracker",
    icon: <AccessTimeIcon />,
    component: <TimeTracker />,
  },
  {
    kind: "page",
    title: "Freelo",
    segment: "freelo",
    icon: <ViewKanbanIcon />,
    component: <FreeloHome />,
    slugBabyPattern: ":boardId",
    slugBabyComponent: <FreeloBoard />,
  },
  {
    segment: "my-students",
    title: "My Students",
    icon: <SchoolIcon />,
    component: <ManageMyStudents />,
  },
  { kind: "header", title: "Financial" },
  { kind: "divider" },
  {
    segment: "admin/financial-hub",
    title: "Financial Hub",
    icon: <AccountBalanceIcon />,
    component: <AdminFinancialHub />,
  },
  {
    segment: "admin/payroll",
    title: "Payroll",
    icon: <CurrencyExchangeIcon />,
    slugBabyPattern: ":contractorId",
    slugBabyComponent: <ContractorDetail />,
    component: <AdminPayroll />,
  },
  { kind: "header", title: "Operations" },
  { kind: "divider" },
  {
    segment: "admin/client-management",
    title: "Clients",
    icon: <HubIcon />,
    slugBabyPattern: ":clientId",
    slugBabyComponent: <ClientOverviewV2 />,
    component: <ClientManagement />,
  },
  {
    segment: "admin/freelancer-management",
    title: "Freelancers",
    icon: <HailIcon />,
    slugBabyPattern: ":freelancerId",
    slugBabyComponent: <FreelancerOverview />,
    component: <FreelancerManagement />,
  },
  {
    segment: "student-management",
    title: "Students",
    icon: <CastForEducationIcon />,
    component: <StudentManagement />,
  },
  // {
  //   segment: "admin/invoice-management",
  //   title: "Invoicing",
  //   icon: <MonetizationOnIcon />,
  //   component: <InvoiceManagement />,
  //   slugBabyPattern: ":invoiceId",
  //   slugBabyComponent: <EditInvoicePage />,
  // },
  {
    segment: "admin/agreement-management",
    title: "Assignment Manager",
    icon: <AssignmentIcon />,
    component: <AgreementManagement />,
  },
  {
    segment: "admin/timesheet-management",
    title: "Timesheet Manager",
    icon: <AccessTimeIcon />,
    component: <TimesheetManagement />,
  },
  // {
  //   segment: "admin/marketing-cms",
  //   title: "Marketing CMS",
  //   icon: <SellIcon />,
  //   component: <MarketingCMS />,
  // },
  {
    segment: "admin/csa-management",
    title: "CSA Manager",
    icon: <DrawIcon />,
    component: <CSAManagement />,
    slugBabyPattern: ":csaId",
    slugBabyComponent: <BlogPostEditor />,
  },
  { kind: "header", title: "Marketing" },
  { kind: "divider" },
  {
    segment: "blog-writer",
    title: "Blog Writer",
    icon: <RssFeedIcon />,
    component: <BlogWriter />,
    slugBabyPattern: ":blogId",
    slugBabyComponent: <BlogPostEditor />,
  },
  {
    segment: "job-poster",
    title: "Job Poster",
    icon: <WorkIcon />,
    component: <JobPoster />,
    slugBabyPattern: ":jobId",
    slugBabyComponent: <JobPostEditor />,
  },
  {
    segment: "email-management",
    title: "Email Management",
    icon: <EmailIcon />,
    component: <EmailManagement />,
  },
  { kind: "header", title: "Admin" },
  { kind: "divider" },

  {
    segment: "admin/user-management",
    title: "User Management",
    icon: <GroupIcon />,
    component: <UserManagement />,
  },
  {
    segment: "audit-log",
    title: "Audit Log",
    icon: <AssignmentIcon />,
    component: <AuditLog />,
  },

  //     {
  //       segment: "knowledge-base",
  //       title: "Knowledge Base",
  //       icon: <GroupIcon />,
  //       component: <KnowledgeBase />,
  //     },
  //   ],
  // },
];
