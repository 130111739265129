import { useState, useCallback, useEffect } from 'react';
import { BlogCategory } from '@freetech/models/blog';
import { portalFunctions } from 'core/functions/portalFunctions';

export interface BlogContentPrompt {
  command: string;        // The command used (e.g., "/intro", "/paragraph", "/conclusion")
  topic?: string;         // Optional topic for the content
  context?: string;       // Current content context (surrounding text)
  category?: BlogCategory; // Blog category
  tone?: string;          // Desired tone (professional, casual, etc.)
  keywords?: string[];    // Keywords to include
  length?: "short" | "medium" | "long"; // Desired length
}

interface UseBlogAIOptions {
  onChunk?: (chunk: string) => void;
  onComplete?: (fullContent: string) => void;
  onError?: (error: Error) => void;
}

export const useBlogAI = (options: UseBlogAIOptions = {}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [generatedContent, setGeneratedContent] = useState('');
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  // Clean up the abort controller on unmount
  useEffect(() => {
    return () => {
      if (abortController) {
        console.log("Cleaning up abort controller on unmount");
        abortController.abort();
      }
    };
  }, [abortController]);

  const generateContent = useCallback(async (prompt: BlogContentPrompt) => {
    console.log("generateContent called with prompt:", JSON.stringify(prompt));
    
    // Reset state
    setIsGenerating(true);
    setError(null);
    setGeneratedContent('');
    console.log("State reset: isGenerating=true, error=null, generatedContent=''");
    
    // Create a new abort controller for this request
    const controller = new AbortController();
    setAbortController(controller);
    console.log("Created new abort controller");
    
    try {
      console.log("Calling portalFunctions.ai.generateBlogContent");
      // Use the portalFunctions to generate blog content
      await portalFunctions.ai.generateBlogContent(
        prompt,
        {
          onChunk: (chunk) => {
            console.log("Received chunk:", chunk);
            setGeneratedContent(prev => {
              const newContent = prev + chunk;
              console.log("Updated generatedContent length:", newContent.length);
              return newContent;
            });
            options.onChunk?.(chunk);
          },
          onComplete: (fullContent) => {
            console.log("Generation complete, content length:", fullContent.length);
            setIsGenerating(false);
            setGeneratedContent(fullContent);
            options.onComplete?.(fullContent);
          },
          onError: (err) => {
            console.error("Error in generation:", err);
            setError(err);
            setIsGenerating(false);
            options.onError?.(err);
          },
          signal: controller.signal
        }
      );
      console.log("portalFunctions.ai.generateBlogContent completed");
    } catch (err) {
      console.error("Caught error in generateContent:", err);
      // Only set error if it's not an abort error
      if (err instanceof Error && err.name !== 'AbortError') {
        console.error("Setting error state:", err.message);
        setError(err);
        options.onError?.(err instanceof Error ? err : new Error(String(err)));
      } else {
        console.log("Ignoring AbortError");
      }
    } finally {
      console.log("Setting final states: isGenerating=false, abortController=null");
      setIsGenerating(false);
      setAbortController(null);
    }
  }, [options]);
  
  const cancelGeneration = useCallback(() => {
    if (abortController) {
      console.log("Cancelling generation");
      abortController.abort();
      setIsGenerating(false);
    } else {
      console.warn("Cannot cancel - no active abort controller");
    }
  }, [abortController]);
  
  return {
    generateContent,
    cancelGeneration,
    isGenerating,
    error,
    generatedContent
  };
}; 