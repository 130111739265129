import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Avatar,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import {
  AttachMoney as AttachMoneyIcon,
  AccountBalance as AccountBalanceIcon,
  Money as MoneyIcon,
  Percent as PercentIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import { useAdminFinancialHub } from "hooks/admin/useAdminFinancialHub";
import { FreeTechLogoLoading } from "components/FreeTechLogoLoading";

// Loading placeholders
const BalanceCardLoader = () => (
  <Card>
    <CardContent>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="text" width={120} />
      </Box>
      <Skeleton variant="text" width={80} height={40} />
      <Skeleton variant="text" width={140} />
    </CardContent>
  </Card>
);

const ProductCardLoader = () => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <Skeleton variant="rectangular" height={140} />
    <CardContent sx={{ flexGrow: 1 }}>
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="40%" sx={{ mt: 1 }} />
      <Skeleton variant="text" width="100%" sx={{ mt: 2 }} />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="90%" />
    </CardContent>
    <CardActions>
      <Skeleton variant="text" width={120} height={30} />
    </CardActions>
  </Card>
);

const TableLoader = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="rectangular" height={50} animation="wave" sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" height={400} animation="wave" />
  </Box>
);

/**
 * StripeTab component for the AdminFinancialHub
 * Provides a unified view of Stripe financial data including subscriptions, transactions, and products
 */
const StripeTab: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [transactionsToShow, setTransactionsToShow] = useState(25);
  
  const { 
    stripeProducts,
    stripeSubscriptions,
    stripeTransactions,
    stripeBalance,
    isLoadingStripeProducts,
    isLoadingStripeSubscriptions,
    isLoadingStripeTransactions,
    formatCurrency,
    error,
  } = useAdminFinancialHub();

  // Calculate subscription metrics
  const getSubscriptionMetrics = () => {
    if (!stripeSubscriptions) {
      return {
        activeSubscriptions: 0,
        totalSubscriptions: 0,
        monthlyRecurringRevenue: 0,
        annualRecurringRevenue: 0,
        averageRevenue: 0,
      };
    }

    // Count active subscriptions
    const activeSubscriptions = stripeSubscriptions.filter(sub => sub.status === 'active').length;
    
    // Calculate monthly recurring revenue
    const monthlyRecurringRevenue = stripeSubscriptions
      .filter(sub => sub.status === 'active')
      .reduce((total, sub) => {
        // Ensure sub.items is an array before using reduce
        const itemTotal = Array.isArray(sub.items) 
          ? sub.items.reduce((sum, item: any) => {
              return sum + (item.price?.unit_amount || 0);
            }, 0)
          : ((sub.items as any)?.price?.unit_amount || 0); // Handle case where items might be a single object
        return total + itemTotal;
      }, 0) / 100; // Convert from cents to dollars
    
    return {
      activeSubscriptions,
      totalSubscriptions: stripeSubscriptions.length,
      monthlyRecurringRevenue,
      annualRecurringRevenue: monthlyRecurringRevenue * 12,
      averageRevenue: activeSubscriptions > 0 
        ? monthlyRecurringRevenue / activeSubscriptions 
        : 0,
    };
  };

  // Helper function to get USD balance
  const getUsdBalance = (): number => {
    if (!stripeBalance || !stripeBalance.available) return 0;
    
    if (typeof stripeBalance.available === 'object' && 'usd' in stripeBalance.available) {
      return Number(stripeBalance.available.usd) || 0;
    }
    
    if (Array.isArray(stripeBalance.available)) {
      const usdBalance = stripeBalance.available.find(b => b.currency === "usd");
      return usdBalance ? Number(usdBalance.amount) || 0 : 0;
    }
    
    return 0;
  };

  // Helper function to get pending balance
  const getPendingBalance = (): number => {
    if (!stripeBalance || !stripeBalance.pending) return 0;
    
    if (typeof stripeBalance.pending === 'object' && 'usd' in stripeBalance.pending) {
      return Number(stripeBalance.pending.usd) || 0;
    }
    
    if (Array.isArray(stripeBalance.pending)) {
      const usdPending = stripeBalance.pending.find(b => b.currency === "usd");
      return usdPending ? Number(usdPending.amount) || 0 : 0;
    }
    
    return 0;
  };

  // Get subscription status color
  const getSubscriptionStatusColor = (status: string): string => {
    switch (status) {
      case "active":
        return "success";
      case "trialing":
        return "info";
      case "past_due":
        return "warning";
      case "canceled":
        return "error";
      default:
        return "default";
    }
  };

  // Get transaction type display name
  const getTransactionTypeDisplay = (type: string): string => {
    switch (type) {
      case "charge":
        return "Charge";
      case "payment":
        return "Payment";
      case "refund":
        return "Refund";
      case "payout":
        return "Payout";
      case "payment_refund":
        return "Payment Refund";
      case "stripe_fee":
        return "Stripe Fee";
      default:
        return type.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase());
    }
  };

  // Render transaction amount with color based on type
  const renderTransactionAmount = (amount: number, type: string) => {
    const isNegative = amount < 0;
    const displayAmount = isNegative ? -amount / 100 : amount / 100;
    
    let color = "text.primary";
    
    if (type === "charge" || type === "payment") {
      color = "success.main";
    } else if (type === "refund" || type === "payment_refund") {
      color = "error.main";
    } else if (type === "stripe_fee") {
      color = "warning.main";
    }

    return (
      <Typography variant="body2" sx={{ color, fontWeight: "medium" }}>
        {isNegative ? "-" : ""}
        {formatCurrency(displayAmount)}
      </Typography>
    );
  };

  // Function to load more transactions
  const handleLoadMoreTransactions = () => {
    setTransactionsToShow(prev => prev + 25);
  };

  // Check if any data is loading
  const isLoading = isLoadingStripeProducts || isLoadingStripeSubscriptions || isLoadingStripeTransactions;

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography color="error">
          Error loading Stripe data. Please try again later.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {error instanceof Error ? error.message : "Unknown error"}
        </Typography>
      </Box>
    );
  }

  const subscriptionMetrics = getSubscriptionMetrics();

  return (
    <Box>
      {/* Stripe Overview Section */}
      <Grid container spacing={3}>
        {/* Key Metrics */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Stripe Financial Overview</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              {isLoadingStripeTransactions ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <AccountBalanceIcon sx={{ mr: 1, color: "primary.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Available Balance
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div" sx={{ color: "success.main" }}>
                      {formatCurrency(getUsdBalance())}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Ready to be paid out
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {isLoadingStripeTransactions ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <MoneyIcon sx={{ mr: 1, color: "info.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Pending Balance
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div">
                      {formatCurrency(getPendingBalance())}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Will be available soon
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {isLoadingStripeSubscriptions ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PercentIcon sx={{ mr: 1, color: "warning.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Monthly Recurring Revenue
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div" sx={{ color: "primary.main" }}>
                      {formatCurrency(subscriptionMetrics.monthlyRecurringRevenue)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      From active subscriptions
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {isLoadingStripeSubscriptions ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <AttachMoneyIcon sx={{ mr: 1, color: "success.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Active Subscriptions
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div">
                      {subscriptionMetrics.activeSubscriptions}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Currently billing
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Product Grid */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>Products</Typography>
          {isLoadingStripeProducts ? (
            <Grid container spacing={3}>
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ProductCardLoader />
                </Grid>
              ))}
            </Grid>
          ) : stripeProducts && stripeProducts.length > 0 ? (
            <Grid container spacing={3}>
              {stripeProducts
                .filter(product => product.active)
                .slice(0, 6)
                .map((product) => (
                  <Grid item xs={12} sm={6} md={4} key={product.id}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                      {product.images && product.images[0] && (
                        <Box sx={{ height: 140, p: 2, bgcolor: 'background.default', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img 
                            src={product.images[0]} 
                            alt={product.name} 
                            style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} 
                          />
                        </Box>
                      )}
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                          <Typography variant="h6" component="div" gutterBottom>
                            {product.name}
                          </Typography>
                          {product.metadata?.category && (
                            <Chip 
                              size="small" 
                              label={product.metadata.category} 
                            />
                          )}
                        </Box>
                        
                        {product.default_price && (
                          <Typography variant="subtitle1" color="primary.main" gutterBottom>
                            {formatCurrency(
                              (typeof product.default_price === 'object' && (product.default_price as any).unit_amount 
                                ? (product.default_price as any).unit_amount 
                                : 0) / 100
                            )}
                            {typeof product.default_price === 'object' && (product.default_price as any).recurring && 
                              `/${(product.default_price as any).recurring.interval}`}
                          </Typography>
                        )}
                        
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                          {product.description?.substring(0, 100) || 'No description'}
                          {product.description && product.description.length > 100 ? '...' : ''}
                        </Typography>
                        
                        {product.metadata?.features && (
                          <>
                            <Typography variant="subtitle2" gutterBottom>
                              Features:
                            </Typography>
                            <Box component="ul" sx={{ pl: 2, mt: 0 }}>
                              {product.metadata.features.split(',').map((feature: string, index: number) => (
                                <Typography component="li" variant="body2" key={index}>
                                  {feature.trim()}
                                </Typography>
                              ))}
                            </Box>
                          </>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button 
                          size="small" 
                          endIcon={<OpenInNewIcon />}
                          component="a"
                          href={`https://dashboard.stripe.com/products/${product.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View in Stripe
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography color="text.secondary" sx={{ py: 3, textAlign: "center" }}>
                No active products
              </Typography>
            </Grid>
          )}
        </Grid>

        {/* Subscriptions */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>Active Subscriptions</Typography>
          {isLoadingStripeSubscriptions ? (
            <Grid container spacing={3}>
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <Skeleton variant="rectangular" height={160} />
                </Grid>
              ))}
            </Grid>
          ) : stripeSubscriptions && stripeSubscriptions.length > 0 ? (
            <Grid container spacing={3}>
              {stripeSubscriptions
                .filter(sub => sub.status === 'active')
                .slice(0, 6)
                .map((subscription) => (
                  <Grid item xs={12} md={6} lg={4} key={subscription.id}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {subscription.items[0]?.product?.name || 'Unknown Product'}
                          </Typography>
                          <Chip
                            label="Active"
                            size="small"
                            color="success"
                          />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            Customer: {subscription.customer.name || subscription.customer.email || 'Unknown'}
                          </Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            Next bill:
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2" color="text.secondary">
                            Amount:
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: 500, color: 'primary.main' }}>
                            {formatCurrency(
                              (Array.isArray(subscription.items)
                                ? subscription.items.reduce((sum, item: any) => sum + (item.price?.unit_amount || 0), 0)
                                : (subscription.items as any)?.price?.unit_amount || 0
                              ) / 100
                            )}/mo
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Button 
                          size="small" 
                          endIcon={<OpenInNewIcon />}
                          component="a"
                          href={`https://dashboard.stripe.com/subscriptions/${subscription.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View in Stripe
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography color="text.secondary" sx={{ py: 3, textAlign: "center" }}>
                No active subscriptions
              </Typography>
            </Grid>
          )}
        </Grid>

        {/* Transactions */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>Transactions</Typography>
          <Paper sx={{ p: 3 }}>
            {isLoadingStripeTransactions ? (
              <TableLoader />
            ) : stripeTransactions && stripeTransactions.length > 0 ? (
              <>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Fee</TableCell>
                        <TableCell align="right">Net</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stripeTransactions.slice(0, transactionsToShow).map((transaction) => (
                        <TableRow key={transaction.id} hover>
                          <TableCell>
                            {new Date(transaction.created * 1000).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {transaction.description || 'N/A'}
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={getTransactionTypeDisplay(transaction.type)}
                              size="small"
                              sx={{
                                backgroundColor: 
                                  transaction.type === "charge" || transaction.type === "payment" 
                                    ? "success.light" 
                                    : transaction.type === "refund" || transaction.type === "payment_refund"
                                      ? "error.light"
                                      : "default",
                                color: theme.palette.getContrastText(
                                  transaction.type === "charge" || transaction.type === "payment" 
                                    ? theme.palette.success.light 
                                    : transaction.type === "refund" || transaction.type === "payment_refund"
                                      ? theme.palette.error.light
                                      : theme.palette.grey[300]
                                )
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {renderTransactionAmount(transaction.amount, transaction.type)}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(Math.abs(transaction.fee || 0) / 100)}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(Math.abs(transaction.net || 0) / 100)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {stripeTransactions.length > transactionsToShow && (
                  <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button 
                      variant="outlined" 
                      onClick={handleLoadMoreTransactions}
                    >
                      Load More Transactions
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Typography color="text.secondary" sx={{ py: 3, textAlign: "center" }}>
                No transactions available
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StripeTab;