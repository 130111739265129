import React, { memo } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import FreeloTicket from './FreeloTicket';
import { Card } from "@freetech/models/freelo";
interface DraggableCardProps {
  card: Card;
  index: number;
  boardId: string;
  listId: string;
  onUpdate: (cardId: string, updates: Partial<Card>) => Promise<void>;
  onArchive: (cardId: string) => Promise<void>;
  onCopy: (card: Card) => Promise<void>;
  onTrash: (cardId: string, card: Card) => Promise<void>;
}

const DraggableCard = memo(({
  card,
  index,
  boardId,
  listId,
  onUpdate,
  onArchive,
  onCopy,
  onTrash,
}: DraggableCardProps) => {
  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            backgroundColor: snapshot.isDragging ? 'rgba(255, 165, 0, 0.1)' : undefined,
            borderColor: snapshot.isDragging ? 'orange' : undefined,
            boxShadow: snapshot.isDragging ? '0 0 10px rgba(255, 165, 0, 0.3)' : undefined,
          }}
        >
          <FreeloTicket
            card={card}
            boardId={boardId}
            listId={listId}
            onUpdate={onUpdate}
            onArchive={async () => {
              await onArchive(card.id);
            }}
            onCopy={() => onCopy(card)}
            onTrash={() => onTrash(card.id, card)}
          />
        </div>
      )}
    </Draggable>
  );
});

DraggableCard.displayName = 'DraggableCard';

export default DraggableCard; 