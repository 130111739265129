import React, { useMemo } from "react";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  IconButton,
  AvatarGroup,
  Avatar,
  Tooltip,
  Badge,
  InputAdornment,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import ArchiveIcon from "@mui/icons-material/Archive";
import ShareIcon from "@mui/icons-material/Share";
import CrownIcon from "@mui/icons-material/WorkspacePremium";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { PublicUserInfoType } from "@freetech/models/user";
import { Board, Card } from "@freetech/models/freelo";
import { BoardFilters } from "./FreeloBoardFilters";

interface FreeloBoardHeaderProps {
  board: Board;
  users?: PublicUserInfoType[];
  currentUser?: PublicUserInfoType;
  isAdmin: boolean;
  canShare: boolean;
  isArchiveView: boolean;
  isActivityView: boolean;
  editBoardTitle: string;
  isEditingBoardTitle: boolean;
  filters: BoardFilters;
  onEditBoardTitle: (title: string) => void;
  onBoardTitleSubmit: () => void;
  onSetIsEditingBoardTitle: (isEditing: boolean) => void;
  onViewChange: (view: "board" | "activity" | "archive") => void;
  onOpenShareDialog: () => void;
  onOpenSettings: () => void;
  onFiltersChange: (filters: BoardFilters) => void;
}

export const FreeloBoardHeader: React.FC<FreeloBoardHeaderProps> = ({
  board,
  users,
  currentUser,
  isAdmin,
  canShare,
  isArchiveView,
  isActivityView,
  editBoardTitle,
  isEditingBoardTitle,
  filters,
  onEditBoardTitle,
  onBoardTitleSubmit,
  onSetIsEditingBoardTitle,
  onViewChange,
  onOpenShareDialog,
  onOpenSettings,
  onFiltersChange,
}) => {
  const getBoardUsers = () => {
    if (!board) return [];

    const allUsers = new Set<string>();
    board.readUserIds?.forEach((id) => allUsers.add(id));
    board.writeUserIds?.forEach((id) => allUsers.add(id));
    if (board.createdBy) allUsers.add(board.createdBy);

    return Array.from(allUsers);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = {
      ...filters,
      searchText: event.target.value,
    };
    onFiltersChange(newFilters);
  };

  const handleClearSearch = () => {
    const newFilters = {
      ...filters,
      searchText: "",
    };
    onFiltersChange(newFilters);
  };

  const handleUserAvatarClick = (userId: string) => {
    const newMembers = filters.members.includes(userId)
      ? filters.members.filter((id) => id !== userId)
      : [...filters.members, userId];

    onFiltersChange({
      ...filters,
      members: newMembers,
    });
  };

  const isUserSelected = (userId: string) => {
    return filters.members.includes(userId);
  };

  // Count tickets per user
  const userTicketCounts = useMemo(() => {
    if (!board || !board.lists) return new Map<string, number>();

    const counts = new Map<string, number>();

    // Initialize counts for all board users
    getBoardUsers().forEach((userId) => {
      counts.set(userId, 0);
    });

    // Count tickets for each user
    board.lists.forEach((list) => {
      list.cards.forEach((card) => {
        // Count assignee
        if (card.assignee) {
          const currentCount = counts.get(card.assignee) || 0;
          counts.set(card.assignee, currentCount + 1);
        }

        // Count reporter
        if (card.reporter) {
          const currentCount = counts.get(card.reporter) || 0;
          counts.set(card.reporter, currentCount + 1);
        }

        // Count additional assignees
        if (card.additionalAssignees) {
          card.additionalAssignees.forEach((userId) => {
            const currentCount = counts.get(userId) || 0;
            counts.set(userId, currentCount + 1);
          });
        }
      });
    });

    return counts;
  }, [board]);

  // Sort users by ticket count
  const sortedUsers = useMemo(() => {
    const boardUsers = getBoardUsers();

    // Sort by ticket count (descending), with owner always first
    return boardUsers.sort((a, b) => {
      // Owner is always first
      if (a === board.createdBy) return -1;
      if (b === board.createdBy) return 1;

      // Then sort by ticket count
      const countA = userTicketCounts.get(a) || 0;
      const countB = userTicketCounts.get(b) || 0;

      return countB - countA;
    });
  }, [board, userTicketCounts]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        zIndex: 1,
        // px: { xs: 1, sm: 2 },
        // pl: {
        //   xs: 1,
        //   sm: (theme) => `calc(${theme.spacing(8)} + ${theme.spacing(1)})`,
        //   md: (theme) => `calc(${theme.spacing(30)} + ${theme.spacing(2)})`
        // },
        "&.sidebar-closed": {
          pl: {
            xs: 1,
            sm: (theme) => `calc(${theme.spacing(8)} + ${theme.spacing(1)})`,
            md: (theme) => `calc(${theme.spacing(8)} + ${theme.spacing(2)})`,
          },
        },
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
          mx: "auto",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.95)",
            backdropFilter: "blur(12px)",
            borderRadius: { xs: 1, sm: 1 },
            border: 1,
            borderColor: "divider",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            position: "relative",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "stretch", sm: "center" },
            justifyContent: "space-between",
            minHeight: { xs: "auto", sm: 56 },
            gap: { xs: 1, sm: 2 },
            p: 1,
            boxSizing: "border-box",
            mb: { xs: 1, sm: 2 },
          }}
        >
          {/* Board Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: 0,
              flex: { sm: "0 1 auto" },
              pl: { xs: 5, sm: 5 },
            }}
          >
            {isEditingBoardTitle ? (
              <TextField
                autoFocus
                value={editBoardTitle}
                onChange={(e) => onEditBoardTitle(e.target.value)}
                onBlur={onBoardTitleSubmit}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onBoardTitleSubmit();
                  }
                }}
                size="small"
                sx={{
                  flex: 1,
                  maxWidth: { xs: "100%", sm: 300 },
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "1rem", sm: "1.125rem" },
                    fontWeight: 500,
                    py: 0.5,
                    px: 1,
                  },
                }}
              />
            ) : (
              <Typography
                variant="h6"
                onClick={() => {
                  onSetIsEditingBoardTitle(true);
                  onEditBoardTitle(board.name);
                }}
                sx={{
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontSize: { xs: "1rem", sm: "1.125rem" },
                  fontWeight: 500,
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                {board.name}
              </Typography>
            )}
          </Box>

          {/* Controls Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flexWrap: { xs: "wrap", sm: "nowrap" },
              justifyContent: { xs: "space-between", sm: "flex-end" },
              flex: { sm: "1 1 auto" },
            }}
          >
            {/* Search Input */}
            <TextField
              placeholder="Search cards..."
              size="small"
              value={filters.searchText || ""}
              onChange={handleSearchChange}
              sx={{
                order: { xs: 1, sm: 1 },
                width: { xs: "100%", sm: 200 },
                "& .MuiInputBase-root": {
                  height: 32,
                  fontSize: "0.875rem",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: filters.searchText ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                      size="small"
                      sx={{ p: 0.5 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />

            <ButtonGroup
              variant="outlined"
              size="small"
              sx={{
                order: { xs: 1, sm: 2 },
                flexGrow: { xs: 1, sm: 0 },
                height: { xs: 40, sm: 32 },
              }}
            >
              <Button
                startIcon={<DashboardIcon />}
                color={
                  !isArchiveView && !isActivityView ? "primary" : "inherit"
                }
                variant={
                  !isArchiveView && !isActivityView ? "contained" : "outlined"
                }
                onClick={() => onViewChange("board")}
                sx={{
                  minWidth: 0,
                  px: { xs: 1.5, sm: 1.5 },
                  height: "100%",
                  "& .MuiSvgIcon-root": {
                    fontSize: { xs: "1.25rem", sm: "1rem" }
                  }
                }}
              >
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "inline" } }}
                >
                  Board
                </Box>
              </Button>
              <Button
                startIcon={<HistoryIcon />}
                color={isActivityView ? "primary" : "inherit"}
                variant={isActivityView ? "contained" : "outlined"}
                onClick={() => onViewChange("activity")}
                sx={{
                  minWidth: 0,
                  px: { xs: 1.5, sm: 1.5 },
                  height: "100%",
                  "& .MuiSvgIcon-root": {
                    fontSize: { xs: "1.25rem", sm: "1rem" }
                  }
                }}
              >
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "inline" } }}
                >
                  Activity
                </Box>
              </Button>
              <Button
                startIcon={<ArchiveIcon />}
                color={isArchiveView ? "primary" : "inherit"}
                variant={isArchiveView ? "contained" : "outlined"}
                onClick={() => onViewChange("archive")}
                sx={{
                  minWidth: 0,
                  px: { xs: 1.5, sm: 1.5 },
                  height: "100%",
                  "& .MuiSvgIcon-root": {
                    fontSize: { xs: "1.25rem", sm: "1rem" }
                  }
                }}
              >
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "inline" } }}
                >
                  Archive
                </Box>
              </Button>
            </ButtonGroup>

            <AvatarGroup
              max={6}
              sx={{
                order: { xs: 2, sm: 3 },
                
                "& .MuiAvatar-root": {
                  width: { xs: 36, sm: 28 },
                  height: { xs: 36, sm: 28 },
                  fontSize: { xs: "0.875rem", sm: "0.75rem" },
                  border: "2px solid white",
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.1)",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  },
                },
              }}
            >
              {sortedUsers.slice(0, 5).map((userId) => {
                const user = users?.find((u) => u.id === userId);
                if (!user) return null;

                const isOwner = userId === board.createdBy;
                const isSelected = isUserSelected(userId);
                const ticketCount = userTicketCounts.get(userId) || 0;

                return (
                  <Tooltip
                    key={user.id}
                    title={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography variant="body2">
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography variant="caption">
                          {`${ticketCount} ticket${ticketCount !== 1 ? "s" : ""}`}
                        </Typography>
                        {isOwner && (
                          <Typography
                            variant="caption"
                            sx={{ color: "warning.main" }}
                          >
                            (Owner)
                          </Typography>
                        )}
                        {isSelected && (
                          <Typography
                            variant="caption"
                            sx={{ color: "primary.main" }}
                          >
                            (Filtered)
                          </Typography>
                        )}
                      </Box>
                    }
                  >
                    <Box
                      sx={{ position: "relative" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUserAvatarClick(user.id);
                      }}
                    >
                      <Badge
                        badgeContent={ticketCount}
                        color={isSelected ? "primary" : "default"}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            fontSize: { xs: "0.7rem", sm: "0.6rem" },
                            height: { xs: 20, sm: 16 },
                            minWidth: { xs: 20, sm: 16 },
                            padding: "0 4px",
                            fontWeight: isSelected ? "bold" : "normal",
                            boxShadow: isSelected ? "0 0 0 2px white" : "none",
                          },
                        }}
                      >
                        <Avatar
                          src={user.profilePicture || undefined}
                          alt={`${user.firstName} ${user.lastName}`}
                          sx={
                            isOwner
                              ? {
                                  border: "2px solid",
                                  borderColor: "warning.main",
                                }
                              : isSelected
                                ? {
                                    border: "2px solid",
                                    borderColor: "primary.main",
                                    transform: "scale(1.1)",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                                  }
                                : undefined
                          }
                        >
                          {user.firstName?.[0] || ""}
                        </Avatar>
                      </Badge>
                      {isOwner && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -2,
                            right: -2,
                            width: { xs: 20, sm: 16 },
                            height: { xs: 20, sm: 16 },
                            borderRadius: "50%",
                            bgcolor: "warning.main",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid white",
                            zIndex: 1,
                          }}
                        >
                          <CrownIcon sx={{ fontSize: { xs: 14, sm: 12 }, color: "white" }} />
                        </Box>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
            </AvatarGroup>

            {/* Add a clear filters button if any filters are active */}
            {(filters.members.length > 0 ||
              filters.labels.length > 0 ||
              filters.searchText) && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() =>
                  onFiltersChange({ members: [], labels: [], searchText: "" })
                }
                sx={{
                  height: { xs: 40, sm: 32 },
                  order: { xs: 1, sm: 4 },
                  ml: { xs: 0, sm: 1 },
                  "& .MuiSvgIcon-root": {
                    fontSize: { xs: "1.25rem", sm: "1rem" }
                  }
                }}
                startIcon={<FilterAltOffIcon />}
              >
                Clear
              </Button>
            )}

            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                order: { xs: 3, sm: 5 },
                ml: { xs: 0, sm: 1 },
              }}
            >
              {canShare && (
                <>
                  <Button
                    startIcon={<ShareIcon />}
                    onClick={onOpenShareDialog}
                    variant="outlined"
                    size="small"
                    sx={{
                      minWidth: 0,
                      height: { xs: 40, sm: 32 },
                      px: { xs: 1.5, sm: 1 },
                      "& .MuiButton-startIcon": {
                        mr: { xs: 0, sm: 0.5 },
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: { xs: "1.25rem", sm: "1rem" }
                      }
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ display: { xs: "none", sm: "inline" } }}
                    >
                      Share
                    </Box>
                  </Button>
                  <IconButton
                    onClick={onOpenSettings}
                    size="small"
                    sx={{
                      color: "inherit",
                      width: { xs: 40, sm: 32 },
                      height: { xs: 40, sm: 32 },
                      "& .MuiSvgIcon-root": {
                        fontSize: { xs: "1.25rem", sm: "1rem" }
                      }
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
