import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { toast } from "react-hot-toast";

export const useCSATemplate = () => {
  const queryClient = useQueryClient();
  const TEMPLATE_PATH = "admin/csa_template.html";
  const ARCHIVE_PATH = "admin/csa_archive";

  // Fetch the current CSA template
  const {
    data: templateHtml,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["admin", "csa-template"],
    queryFn: async () => {
      try {
        const templateRef = ref(storage, TEMPLATE_PATH);
        const url = await getDownloadURL(templateRef);
        const response = await fetch(url);
        return await response.text();
      } catch (error) {
        console.error("Error fetching CSA template:", error);
        throw new Error("Failed to fetch CSA template");
      }
    },
  });

  // Update the CSA template and archive the old one
  const updateTemplate = useMutation({
    mutationFn: async (html: string) => {
      try {
        // First, archive the current template
        const currentTemplateRef = ref(storage, TEMPLATE_PATH);
        
        try {
          // Try to get the current template
          const currentUrl = await getDownloadURL(currentTemplateRef);
          const currentResponse = await fetch(currentUrl);
          const currentHtml = await currentResponse.text();
          
          // Archive it with timestamp
          const timestamp = Date.now();
          const archiveRef = ref(storage, `${ARCHIVE_PATH}/csa_template_${timestamp}.html`);
          const archiveBlob = new Blob([currentHtml], { type: "text/html" });
          await uploadBytes(archiveRef, archiveBlob);
        } catch (error) {
          // If there's no current template, just continue
          console.log("No existing template to archive or error archiving:", error);
        }
        
        // Upload the new template
        const blob = new Blob([html], { type: "text/html" });
        await uploadBytes(currentTemplateRef, blob);
        
        return html;
      } catch (error) {
        console.error("Error updating CSA template:", error);
        throw new Error("Failed to update CSA template");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "csa-template"] });
      toast.success("CSA template updated successfully");
    },
    onError: (error) => {
      toast.error(`Failed to update CSA template: ${error instanceof Error ? error.message : "Unknown error"}`);
    },
  });

  // Get archived templates
  const {
    data: archivedTemplates,
    isLoading: isLoadingArchives,
    refetch: refetchArchives,
  } = useQuery({
    queryKey: ["admin", "csa-template-archives"],
    queryFn: async () => {
      try {
        const archiveRef = ref(storage, ARCHIVE_PATH);
        const result = await listAll(archiveRef);
        
        const templates = await Promise.all(
          result.items.map(async (item) => {
            const url = await getDownloadURL(item);
            // Extract timestamp from filename
            const filename = item.name;
            const timestampMatch = filename.match(/csa_template_(\d+)\.html/);
            const timestamp = timestampMatch ? parseInt(timestampMatch[1]) : 0;
            
            return {
              name: filename,
              url,
              timestamp,
              date: new Date(timestamp).toLocaleString(),
            };
          })
        );
        
        // Sort by timestamp (newest first)
        return templates.sort((a, b) => b.timestamp - a.timestamp);
      } catch (error) {
        console.error("Error fetching archived templates:", error);
        return [];
      }
    },
  });

  return {
    templateHtml,
    isLoading,
    error,
    refetch,
    updateTemplate,
    archivedTemplates,
    isLoadingArchives,
    refetchArchives,
  };
}; 