import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography, Paper, useTheme, Container } from "@mui/material";
import { AdminProjectAssignmentExplorer } from "./AdminProjectAssignmentExplorer";
import { IndependentEngineerAgreements } from "./IndependentEngineerAgreements/IndependentEngineerAgreements";
import ProjectAssignmentGenerator from "./ProjectAssignmentGenerator/ProjectAssignmentGenerator";
import AdminSignAgreements from "./AdminSignAgreements/AdminSignAgreements";
import { useNavigate, useLocation } from "react-router-dom";

// Tab configuration with IDs for URL hash navigation
const TAB_CONFIG = [
  { id: "assignment-generator", label: "Assignment Generator" },
  { id: "sign-agreements", label: "Sign Agreements" },
  { id: "project-explorer", label: "Project Explorer" },
  { id: "engineer-agreements", label: "Engineer Agreements" },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`project-assignments-tabpanel-${index}`}
      aria-labelledby={`project-assignments-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `project-assignments-tab-${index}`,
    "aria-controls": `project-assignments-tabpanel-${index}`,
  };
}

export const AgreementManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);

  // Initialize tab based on URL hash
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    const tabIndex = TAB_CONFIG.findIndex(tab => tab.id === hash);
    if (tabIndex !== -1) {
      setCurrentTab(tabIndex);
    } else if (!location.hash && currentTab !== 0) {
      // If no hash is present, default to the first tab
      navigate(`#${TAB_CONFIG[0].id}`, { replace: true });
    }
  }, [location.hash, navigate]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    // Update URL hash when tab changes
    navigate(`#${TAB_CONFIG[newValue].id}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper 
        elevation={2}
        sx={{ 
          mb: 3,
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden'
        }}
      >
        <Box 
          sx={{ 
            borderBottom: 1, 
            borderColor: "divider",
            position: "relative", // Add position relative for stable positioning
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="project assignments tabs"
            variant="fullWidth" // Changed from scrollable to fullWidth for better stability
            scrollButtons={false}
            sx={{
              position: "relative", // Ensure stable positioning
              '& .MuiTabs-indicator': {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              },
              '& .MuiTabs-flexContainer': {
                justifyContent: 'center',
                display: 'flex',
              },
              '& .MuiTab-root': {
                fontWeight: 500,
                textTransform: 'none',
                padding: '12px 16px',
                transition: 'all 0.2s',
                flexGrow: 1,
                maxWidth: 'none',
                '&:hover': {
                  color: theme.palette.primary.main,
                  opacity: 1,
                },
                [theme.breakpoints.up('md')]: {
                  minWidth: 0, // Allow tabs to size based on content
                },
              },
              '& .Mui-selected': {
                fontWeight: 600,
                color: theme.palette.primary.main,
              },
            }}
          >
            {TAB_CONFIG.map((tab, index) => (
              <Tab 
                key={tab.id} 
                label={tab.label} 
                {...a11yProps(index)} 
                sx={{
                  flexShrink: 0,
                  flexGrow: 1,
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Paper>

      <TabPanel value={currentTab} index={0}>
        <ProjectAssignmentGenerator />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <AdminSignAgreements />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <AdminProjectAssignmentExplorer />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <IndependentEngineerAgreements />
      </TabPanel>
    </Box>
  );
};
