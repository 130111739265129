import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Container,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useFreelo } from "hooks/freelo/useFreelo";
import { useAuth } from "hooks/auth/useAuth";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { BlurredContent } from "components/BlurredContent";
import { 
  FreeloFreelancerHeader, 
  FreeloStakeholderHeader,
  FreeloStudentHeader,
  FreeloAdminHeader
} from "./components";

export const FreeloHome: React.FC = () => {
  const navigate = useNavigate();
  const [newBoardName, setNewBoardName] = useState("");
  const [openNewBoardDialog, setOpenNewBoardDialog] = useState(false);
  const { userInfo } = useAuth();

  const isStakeholder = userInfo?.role === "stakeholder";
  const isFreelancer = userInfo?.role === "freelancer";
  const isStudent = userInfo?.role === "student";
  const isAdmin = userInfo?.role === "admin" || (!isStakeholder && !isFreelancer && !isStudent);

  const { data: users } = usePublicUserList();

  const { boards, isLoading, error, createBoard } = useFreelo();

  const getAccessibleBoards = () => {
    if (!boards) return [];

    return boards.filter((board) => {
      if (userInfo?.role === "admin") return true;

      const hasReadAccess = board.readUserIds?.includes(userInfo?.id || "");
      const hasWriteAccess = board.writeUserIds?.includes(userInfo?.id || "");
      const isCreator = board.createdBy === userInfo?.id;

      return hasReadAccess || hasWriteAccess || isCreator;
    });
  };

  const getUserName = (userId: string) => {
    const user = users?.find((u) => u.id === userId);
    return user ? `${user.firstName} ${user.lastName}` : "Unknown User";
  };

  const handleCreateBoard = async () => {
    if (!newBoardName.trim()) return;

    try {
      const boardId = await createBoard.mutateAsync({
        name: newBoardName.trim(),
      });
      setNewBoardName("");
      setOpenNewBoardDialog(false);
      navigate(`/freelo/${boardId}`);
    } catch (error) {
      console.error("Error creating board:", error);
    }
  };

  const handleBoardClick = (boardId: string) => {
    navigate(`/freelo/${boardId}`);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">Error loading boards</Typography>
      </Box>
    );
  }

  const accessibleBoards = getAccessibleBoards();

  return (
    <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 2 } }}>
      {/* Role-specific Headers */}
      {isFreelancer && userInfo && (
        <FreeloFreelancerHeader
          boards={accessibleBoards}
          userInfo={userInfo}
          users={users}
        />
      )}
      {isStakeholder && userInfo && (
        <FreeloStakeholderHeader
          boards={accessibleBoards}
          userInfo={userInfo}
          users={users}
        />
      )}
      {isStudent && userInfo && (
        <FreeloStudentHeader
          boards={accessibleBoards}
          userInfo={userInfo}
          users={users}
        />
      )}
      {isAdmin && userInfo && (
        <FreeloAdminHeader
          boards={accessibleBoards}
          userInfo={userInfo}
          users={users}
          onCreateBoard={() => setOpenNewBoardDialog(true)}
        />
      )}

      <Box sx={{ px: { xs: 1, sm: 2 } }}>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {/* Create New Board Card */}
          {!isStakeholder && !isFreelancer && !isStudent && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  height: { xs: "160px", sm: "200px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "action.hover",
                  cursor: "pointer",
                  borderRadius: { xs: 2, sm: 3 },
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
                  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  },
                }}
                onClick={() => setOpenNewBoardDialog(true)}
              >
                <CardActionArea
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <AddIcon
                      sx={{ 
                        fontSize: { xs: 36, sm: 48 }, 
                        color: "text.secondary", 
                        mb: { xs: 1, sm: 2 } 
                      }}
                    />
                    <Typography 
                      variant="h6" 
                      color="text.secondary"
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.25rem" },
                        fontWeight: 600
                      }}
                    >
                      Create New Board
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}

          {/* Show message when freelancer, stakeholder, or student has no boards */}
          {(isFreelancer || isStakeholder || isStudent) && accessibleBoards.length === 0 && (
            <Grid item xs={12}>
              {isStudent ? (
                <BlurredContent title="Freelo Boards Access">
                  <Box 
                    sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      minHeight: '200px'
                    }}
                  >
                    <Paper 
                      sx={{ 
                        p: { xs: 3, sm: 4 }, 
                        textAlign: 'center',
                        backgroundColor: 'background.default',
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: { xs: 2, sm: 3 },
                        width: '100%',
                        maxWidth: '600px',
                        mx: 'auto'
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        gutterBottom
                        sx={{
                          fontSize: { xs: "1.1rem", sm: "1.25rem" },
                          fontWeight: 600
                        }}
                      >
                        No Freelo Boards Available
                      </Typography>
                      <Typography 
                        color="text.secondary" 
                        sx={{ 
                          mb: 2,
                          fontSize: { xs: "0.95rem", sm: "1rem" }
                        }}
                      >
                        You haven't been assigned to any Freelo boards yet. Please contact your mentor.
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{
                          fontSize: { xs: "0.875rem", sm: "0.95rem" }
                        }}
                      >
                        Please contact your instructor to be added to a Freelo board for learning purposes.
                      </Typography>
                    </Paper>
                  </Box>
                </BlurredContent>
              ) : (
                <Paper 
                  sx={{ 
                    p: { xs: 3, sm: 4 }, 
                    textAlign: 'center',
                    backgroundColor: 'background.default',
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: { xs: 2, sm: 3 }
                  }}
                >
                  <Typography 
                    variant="h6" 
                    gutterBottom
                    sx={{
                      fontSize: { xs: "1.1rem", sm: "1.25rem" },
                      fontWeight: 600
                    }}
                  >
                    No Freelo Boards Available
                  </Typography>
                  <Typography 
                    color="text.secondary" 
                    sx={{ 
                      mb: 2,
                      fontSize: { xs: "0.95rem", sm: "1rem" }
                    }}
                  >
                    You haven't been assigned to any Freelo boards yet.
                  </Typography>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontSize: { xs: "0.875rem", sm: "0.95rem" }
                    }}
                  >
                    Please contact admin@freetech.co or your project lead to be added to a Freelo board.
                  </Typography>
                </Paper>
              )}
            </Grid>
          )}

          {/* Board Cards */}
          {accessibleBoards.map((board) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={board.id}>
              {isStudent ? (
                <BlurredContent title="Freelo Board">
                  <Card
                    sx={{
                      height: { xs: "160px", sm: "200px" },
                      cursor: "pointer",
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: { xs: 2, sm: 3 },
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
                      transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                    onClick={() => handleBoardClick(board.id)}
                  >
                    {/* Background Image/Color */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: board.backgroundUrl 
                          ? `url(${board.backgroundUrl})` 
                          : 'none',
                        backgroundColor: board.backgroundUrl ? 'transparent' : '#ffffff',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        opacity: 1,
                        zIndex: 0,
                      }}
                    />
                    <CardActionArea sx={{ height: "100%", position: "relative", zIndex: 1 }}>
                      <CardContent sx={{ 
                        height: "100%", 
                        display: "flex", 
                        flexDirection: "column", 
                        justifyContent: "space-between",
                        p: { xs: 2, sm: 3 }
                      }}>
                        <Box
                          sx={{
                            backgroundColor: board.backgroundUrl ? 'rgba(255, 255, 255, 0.85)' : 'transparent',
                            padding: board.backgroundUrl ? { xs: 1, sm: 1.5 } : 0,
                            borderRadius: 1,
                            marginBottom: 1,
                          }}
                        >
                          <Typography 
                            variant="h6" 
                            gutterBottom
                            sx={{ 
                              fontWeight: "bold",
                              color: 'text.primary',
                              fontSize: { xs: "1rem", sm: "1.25rem" },
                              mb: 0.5
                            }}
                          >
                            {board.name}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: 'text.secondary',
                              fontSize: { xs: "0.75rem", sm: "0.875rem" }
                            }}
                          >
                            {board.lists?.length} lists
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: board.backgroundUrl ? 'rgba(255, 255, 255, 0.85)' : 'transparent',
                            padding: board.backgroundUrl ? { xs: 1, sm: 1.5 } : 0,
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              display: "block",
                              color: board.createdBy === userInfo?.id ? "success.main" : "info.main",
                              fontSize: { xs: "0.7rem", sm: "0.75rem" },
                              fontWeight: 500
                            }}
                          >
                            {board.createdBy === userInfo?.id
                              ? "Owner"
                              : "Learning Board"}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              display: "block",
                              color: "text.secondary",
                              mt: 0.5,
                              fontSize: { xs: "0.7rem", sm: "0.75rem" }
                            }}
                          >
                            Created by {getUserName(board.createdBy)}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </BlurredContent>
              ) : (
                <Card
                  sx={{
                    height: { xs: "160px", sm: "200px" },
                    cursor: "pointer",
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: { xs: 2, sm: 3 },
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
                    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  onClick={() => handleBoardClick(board.id)}
                >
                  {/* Background Image/Color */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundImage: board.backgroundUrl 
                        ? `url(${board.backgroundUrl})` 
                        : 'none',
                      backgroundColor: board.backgroundUrl ? 'transparent' : '#ffffff',
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      opacity: 1,
                      zIndex: 0,
                    }}
                  />
                  <CardActionArea sx={{ height: "100%", position: "relative", zIndex: 1 }}>
                    <CardContent sx={{ 
                      height: "100%", 
                      display: "flex", 
                      flexDirection: "column", 
                      justifyContent: "space-between",
                      p: { xs: 2, sm: 3 }
                    }}>
                      <Box
                        sx={{
                          backgroundColor: board.backgroundUrl ? 'rgba(255, 255, 255, 0.85)' : 'transparent',
                          padding: board.backgroundUrl ? { xs: 1, sm: 1.5 } : 0,
                          borderRadius: 1,
                          marginBottom: 1,
                        }}
                      >
                        <Typography 
                          variant="h6" 
                          gutterBottom
                          sx={{ 
                            fontWeight: "bold",
                            color: 'text.primary',
                            fontSize: { xs: "1rem", sm: "1.25rem" },
                            mb: 0.5
                          }}
                        >
                          {board.name}
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: 'text.secondary',
                            fontSize: { xs: "0.75rem", sm: "0.875rem" }
                          }}
                        >
                          {board.lists?.length} lists
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: board.backgroundUrl ? 'rgba(255, 255, 255, 0.85)' : 'transparent',
                          padding: board.backgroundUrl ? { xs: 1, sm: 1.5 } : 0,
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            display: "block",
                            color: board.createdBy === userInfo?.id ? "success.main" : "info.main",
                            fontSize: { xs: "0.7rem", sm: "0.75rem" },
                            fontWeight: 500
                          }}
                        >
                          {board.createdBy === userInfo?.id
                            ? "Owner"
                            : isStudent ? "Learning Board" : "Shared with you"}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            display: "block",
                            color: "text.secondary",
                            mt: 0.5,
                            fontSize: { xs: "0.7rem", sm: "0.75rem" }
                          }}
                        >
                          Created by {getUserName(board.createdBy)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* New Board Dialog */}
      <Dialog
        open={openNewBoardDialog}
        onClose={() => setOpenNewBoardDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: { xs: 2, sm: 3 },
            width: { xs: "90%", sm: "auto" },
            maxWidth: { xs: "90%", sm: 400 }
          }
        }}
      >
        <DialogTitle sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" }, fontWeight: 600 }}>
          Create New Board
        </DialogTitle>
        <DialogContent sx={{ pt: { xs: 1, sm: 2 } }}>
          <TextField
            autoFocus
            margin="dense"
            label="Board Name"
            fullWidth
            value={newBoardName}
            onChange={(e) => setNewBoardName(e.target.value)}
            InputProps={{
              sx: {
                fontSize: { xs: "0.95rem", sm: "1rem" }
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: { xs: "0.95rem", sm: "1rem" }
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, sm: 3 }, pb: { xs: 2, sm: 3 } }}>
          <Button 
            onClick={() => setOpenNewBoardDialog(false)}
            sx={{ 
              fontSize: { xs: "0.875rem", sm: "0.95rem" },
              px: { xs: 2, sm: 3 }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateBoard} 
            variant="contained"
            sx={{ 
              fontSize: { xs: "0.875rem", sm: "0.95rem" },
              px: { xs: 2, sm: 3 }
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
