import React, { useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LabelIcon from '@mui/icons-material/Label';
import TimerIcon from '@mui/icons-material/Timer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import GitHubIcon from '@mui/icons-material/GitHub';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';
import { Card, CardAttachment } from "@freetech/models/freelo";
import { useFreelo } from 'hooks/freelo/useFreelo';
import { usePublicUserList } from 'hooks/user/usePublicUserList';

interface CardSidebarProps {
  card: Card;
  boardId: string;
  showChecklists: boolean;
  showHillChart: boolean;
  showAttachments: boolean;
  isUploading: boolean;
  onMembersClick: () => void;
  onLabelsClick: (event: React.MouseEvent<HTMLElement>) => void;
  onDateClick: () => void;
  onChecklistToggle: () => void;
  onHillChartToggle: () => void;
  onArchiveClick: () => void;
  onGithubBranchClick: () => void;
  onAttachmentsToggle: () => void;
  onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderMemberChip: (userId: string, role: string) => React.ReactNode;
  AttachmentList: React.ComponentType<{
    attachments?: CardAttachment[];
    onDelete: (attachmentId: string) => void;
    onDeleteClick: (attachment: CardAttachment) => void;
  }>;
}

const CardSidebar: React.FC<CardSidebarProps> = ({
  card,
  boardId,
  showChecklists,
  showHillChart,
  showAttachments,
  isUploading,
  onMembersClick,
  onLabelsClick,
  onDateClick,
  onChecklistToggle,
  onHillChartToggle,
  onArchiveClick,
  onGithubBranchClick,
  onAttachmentsToggle,
  onFileUpload,
  renderMemberChip,
  AttachmentList,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [moveAnchorEl, setMoveAnchorEl] = useState<null | HTMLElement>(null);
  const [copyAnchorEl, setCopyAnchorEl] = useState<null | HTMLElement>(null);
  const { boards, moveCard, createCard, updateCard } = useFreelo();
  const { data: users } = usePublicUserList();
  const board = boards?.find(b => b.id === boardId);
  const currentList = board?.lists?.find(list => list.id === card.listId);
  const createdByUser = users?.find(user => user.id === card.createdBy);

  const handleMoveClick = (event: React.MouseEvent<HTMLElement>) => {
    setMoveAnchorEl(event.currentTarget);
  };

  const handleCopyClick = (event: React.MouseEvent<HTMLElement>) => {
    setCopyAnchorEl(event.currentTarget);
  };

  const handleMoveClose = () => {
    setMoveAnchorEl(null);
  };

  const handleCopyClose = () => {
    setCopyAnchorEl(null);
  };

  const handleMoveToList = async (destinationListId: string) => {
    if (!card.listId) return;
    
    try {
      await moveCard.mutateAsync({
        boardId,
        cardId: card.id,
        sourceListId: card.listId,
        destinationListId,
      });
      handleMoveClose();
    } catch (error) {
      console.error('Error moving card:', error);
    }
  };

  const handleCopyToList = async (destinationListId: string) => {
    try {
      const newCardId = await createCard.mutateAsync({
        boardId,
        listId: destinationListId,
        title: card.title,
        description: card.description,
        assignee: card.assignee,
        reporter: card.reporter,
        additionalAssignees: card.additionalAssignees,
        labels: card.labels,
        dueDate: card.dueDate,
        startDate: card.startDate,
      });

      // Wait for the board to be updated with the new card
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Get the updated board data
      const updatedBoard = boards?.find(b => b.id === boardId);
      const newCard = updatedBoard?.lists
        ?.find(list => list.id === destinationListId)
        ?.cards.find(c => c.id === newCardId);

      // Update the original card's humanReadableId to reference the new card
      if (newCard) {
        await updateCard.mutateAsync({
          boardId,
          cardId: card.id,
          updates: {
            humanReadableId: `${card.humanReadableId} -> ${newCard.humanReadableId}`,
          },
        });
      }
      
      handleCopyClose();
    } catch (error) {
      console.error('Error copying card:', error);
    }
  };

  return (
    <Box sx={{ width: 280 }}>
      {/* Metadata Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
          Card Information
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {/* Created By */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="caption" sx={{ color: "text.secondary", minWidth: 80 }}>
              Created by
            </Typography>
            {createdByUser ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar
                  src={createdByUser.profilePicture || undefined}
                  sx={{ width: 20, height: 20 }}
                >
                  {createdByUser.firstName?.[0]}
                </Avatar>
                <Typography variant="body2">
                  {createdByUser.firstName} {createdByUser.lastName}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">Unknown</Typography>
            )}
          </Box>

          {/* Created At */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="caption" sx={{ color: "text.secondary", minWidth: 80 }}>
              Created
            </Typography>
            <Typography variant="body2">
              {card.createdAt ? DateTime.fromISO(card.createdAt).toFormat('LLL dd, yyyy HH:mm') : 'Unknown'}
            </Typography>
          </Box>

          {/* Updated At */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="caption" sx={{ color: "text.secondary", minWidth: 80 }}>
              Updated
            </Typography>
            <Typography variant="body2">
              {card.updatedAt ? DateTime.fromISO(card.updatedAt).toFormat('LLL dd, yyyy HH:mm') : 'Unknown'}
            </Typography>
          </Box>

          {/* Current List */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="caption" sx={{ color: "text.secondary", minWidth: 80 }}>
              Current List
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {currentList?.color && (
                <Box
                  sx={{
                    width: 3,
                    height: 16,
                    borderRadius: 1,
                    bgcolor: currentList.color,
                  }}
                />
              )}
              <Typography variant="body2">
                {currentList?.name || 'Unknown'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Add to card
      </Typography>

      <Box>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
          Members
        </Typography>

        <Box sx={{ display: "flex", flexWrap: "wrap", mb: 1 }}>
          {card.assignee && renderMemberChip(card.assignee, "Assignee")}
          {card.reporter && renderMemberChip(card.reporter, "Reporter")}
          {card.additionalAssignees?.map((userId) =>
            renderMemberChip(userId, "Additional Assignee")
          )}
        </Box>

        <Button
          startIcon={<PersonAddIcon />}
          fullWidth
          sx={{ justifyContent: "flex-start", mb: 1 }}
          onClick={onMembersClick}
        >
          Manage Members
        </Button>
      </Box>

      <Button
        startIcon={<LabelIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.labels?.length ? "primary.main" : "inherit",
        }}
        onClick={onLabelsClick}
      >
        {card.labels?.length ? `Labels (${card.labels.length})` : "Labels"}
      </Button>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Actions
      </Typography>

      <Button
        startIcon={<TimerIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.dueDate ? "primary.main" : "inherit",
        }}
        onClick={onDateClick}
      >
        {card.dueDate ? (
          <>
            {card.startDate &&
              `${DateTime.fromISO(card.startDate).toFormat("MMM d")} - `}
            Due {DateTime.fromISO(card.dueDate).toFormat("MMM d, yyyy")}
          </>
        ) : (
          "Set Date Range"
        )}
      </Button>

      <Button
        startIcon={<CheckCircleIcon sx={{ color: "success.main" }} />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.checklists?.length ? "primary.main" : "inherit",
        }}
        onClick={onChecklistToggle}
      >
        {card.checklists?.length
          ? showChecklists
            ? "Hide Checklist"
            : "Show Checklist"
          : "Add Checklist"}
      </Button>

      <Button
        startIcon={<TimelineIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.hillProgress !== undefined ? "primary.main" : "inherit",
        }}
        onClick={onHillChartToggle}
      >
        {card.hillProgress !== undefined
          ? showHillChart
            ? "Hide Hill Chart"
            : "Show Hill Chart"
          : "Add Hill Chart"}
      </Button>

      <Button 
        startIcon={<DriveFileMoveIcon />}
        fullWidth 
        sx={{ justifyContent: "flex-start", mb: 1 }}
        onClick={handleMoveClick}
      >
        Move
      </Button>

      <Button 
        startIcon={<ContentCopyIcon />}
        fullWidth 
        sx={{ justifyContent: "flex-start", mb: 1 }}
        onClick={handleCopyClick}
      >
        Copy
      </Button>

      <Button
        fullWidth
        sx={{ justifyContent: "flex-start", mb: 1, color: "error.main" }}
        onClick={onArchiveClick}
      >
        Archive
      </Button>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Additional
      </Typography>

      <Button
        startIcon={<GitHubIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.githubBranch ? "primary.main" : "inherit",
        }}
        onClick={onGithubBranchClick}
      >
        {card.githubBranch ? `Branch: ${card.githubBranch}` : "Link GitHub Branch"}
      </Button>

      <Button
        startIcon={<AttachFileIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.attachments?.length ? "primary.main" : "inherit",
        }}
        onClick={onAttachmentsToggle}
      >
        ATTACHMENTS ({card.attachments?.length || 0}) {showAttachments ? "▼" : "▶"}
      </Button>

      {showAttachments && (
        <Box sx={{ pl: 4, mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="text.secondary">
                {card.attachments?.length || 0} attachments
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={() => fileInputRef.current?.click()}
              disabled={isUploading}
              sx={{
                minWidth: "unset",
                px: 2,
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  opacity: 0.8,
                },
              }}
            >
              ADD
            </Button>
          </Box>

          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileUpload}
            style={{ display: "none" }}
          />

          <AttachmentList
            attachments={card.attachments}
            onDelete={() => {}}
            onDeleteClick={() => {}}
          />
        </Box>
      )}

      {/* Move Card Menu */}
      <Menu
        anchorEl={moveAnchorEl}
        open={Boolean(moveAnchorEl)}
        onClose={handleMoveClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">Move card</Typography>
            <IconButton size="small" onClick={handleMoveClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {board?.lists?.map((list) => (
          <MenuItem
            key={list.id}
            onClick={() => handleMoveToList(list.id)}
            disabled={list.id === card.listId}
          >
            <ListItemText primary={list.name} />
          </MenuItem>
        ))}
      </Menu>

      {/* Copy Card Menu */}
      <Menu
        anchorEl={copyAnchorEl}
        open={Boolean(copyAnchorEl)}
        onClose={handleCopyClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1">Copy card</Typography>
            <IconButton size="small" onClick={handleCopyClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {board?.lists?.map((list) => (
          <MenuItem
            key={list.id}
            onClick={() => handleCopyToList(list.id)}
          >
            <ListItemText primary={list.name} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default CardSidebar; 