import { useMutation, useQueryClient } from "@tanstack/react-query";
import { doc, updateDoc, collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useAuth } from "hooks/auth/useAuth";
import { PrivateUserInfoType } from "@freetech/models/user";
import { Project } from "@freetech/models/projects";

interface OnboardingStep {
  step: number;
  data: any;
}

interface UpdateProjectParams {
  clientId: string;
  projectId: string;
  projectData: Partial<Project>;
}

export const useStakeholderOnboarding = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  const updateProfile = async ({ step, data }: OnboardingStep) => {
    if (!userInfo?.id) {
      throw new Error("No authenticated user");
    }

    const userRef = doc(db, "users", userInfo.id);

    const updates: Partial<PrivateUserInfoType> = {
      updatedAt: new Date().toISOString(),
    };

    // Add step-specific updates
    switch (step) {
      case 0: // Personal Information
        updates.firstName = data.firstName;
        updates.lastName = data.lastName;
        updates.phone = data.phone;
        updates.email = data.email;
        break;
      case 1: // Company Setup
        // No user profile updates needed - companies are stored in clients collection
        break;
      case 2: // Project Setup
        // No user profile updates needed - companies are stored in clients collection
        break;

      case 3: // Payment Setup
        // Payment info is stored separately for security
        break;
      case 4: // Project Setup - Final Step
        updates.needsOnboarding = false;
        updates.onboardingCompletedAt = new Date().toISOString();
        break;
    }

    await updateDoc(userRef, updates);
    return updates;
  };

  const completeStakeholderOnboarding = async () => {
    if (!userInfo?.id) {
      throw new Error("No authenticated user");
    }

    const stakeholderRef = doc(db, "users", userInfo.id);
    await updateDoc(stakeholderRef, {
      needsOnboarding: false,
      onboardingCompletedAt: new Date().toISOString(),
    });
  };

  const updateProject = async ({
    clientId,
    projectId,
    projectData,
  }: UpdateProjectParams) => {
    if (!userInfo?.id) {
      throw new Error("No authenticated user");
    }

    const projectRef = doc(db, `clients/${clientId}/projects/${projectId}`);
    await updateDoc(projectRef, {
      ...projectData,
      updatedAt: new Date().toISOString(),
    });
  };

  const profileMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["auth-user"] });
    },
  });

  const projectMutation = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stakeholder-clients"] });
    },
  });

  return {
    updateProfile: profileMutation.mutate,
    isUpdating: profileMutation.isPending,
    error: profileMutation.error,
    updateProject: projectMutation.mutate,
    isUpdatingProject: projectMutation.isPending,
    projectError: projectMutation.error,
    completeStakeholderOnboarding: completeStakeholderOnboarding,
  };
};
