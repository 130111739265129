
import { Timesheet } from "@freetech/models/timesheet";

interface UpdateTimeEntryParams {
  timesheet: Timesheet;
  newLongDescription?: string;
  submitted?: boolean;
}

export const updateTimeEntry = async (
  params: UpdateTimeEntryParams,
  updateFunction: (newRow: Timesheet, oldRow: Timesheet) => Promise<Timesheet>
) => {
  const { timesheet, newLongDescription, submitted } = params;
  
  try {
    const updatedRow = {
      ...timesheet,
      ...(newLongDescription !== undefined && { longDescription: newLongDescription }),
      ...(submitted !== undefined && { submitted }),
    };

    return await updateFunction(updatedRow, timesheet);
  } catch (error) {
    console.error("Failed to update timesheet:", error);
    throw error;
  }
}; 