import React, { useState, useMemo } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Autocomplete,
  Tabs,
  Tab,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useTimesheet } from "hooks/timesheet/useTimesheet";
import TimesheetExplorerGrid from "./components/TimesheetExplorerGrid";
import TimesheetStats from "./components/TimesheetStats";
import { TimesheetTrends } from "./components/TimesheetTrends";
import { TimesheetFilters } from "./components/TimesheetFilters";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";
import { useAuth } from "hooks/auth/useAuth";
import { Project } from "@freetech/models/projects";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`timesheet-tabpanel-${index}`}
      aria-labelledby={`timesheet-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export const FreelancerTimesheetExplorer: React.FC = () => {
  const [startDate, setStartDate] = useState<DateTime>(
    DateTime.now().startOf("month")
  );
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.now().endOf("month")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [tabValue, setTabValue] = useState(0);

  const { userInfo } = useAuth();

  const { getMyTimesheets } = useTimesheet(userInfo?.id ?? "");
  const { data: fetchedTimesheets = [], isLoading: loading } =
    getMyTimesheets();
  const { data: myProjects = [] } = useProjectsNonAdmin();

  const filteredTimesheets = useMemo(() => {
    return fetchedTimesheets.filter((timesheet) => {
      const timesheetDate = DateTime.fromISO(timesheet.date);

      const matchesDateRange =
        timesheetDate >= startDate.startOf("day") &&
        timesheetDate <= endDate.endOf("day");

      const matchesSearch =
        (timesheet.projectName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.clientName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.description?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        );

      const matchesProject =
        selectedProjects.length === 0 ||
        selectedProjects.includes(timesheet.projectId || "");

      return matchesDateRange && matchesSearch && matchesProject;
    });
  }, [fetchedTimesheets, startDate, endDate, searchTerm, selectedProjects]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        width: { xs: "100vw", md: "100%" },
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: { xs: 1, sm: 2 },
          flex: "1 1 auto",
          minHeight: 0,
          overflow: "auto",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {/* Filters Section */}
        <TimesheetFilters
          startDate={startDate}
          endDate={endDate}
          searchTerm={searchTerm}
          selectedProjects={selectedProjects}
          myProjects={myProjects}
          onStartDateChange={(date) => date && setStartDate(date)}
          onEndDateChange={(date) => date && setEndDate(date)}
          onSearchChange={setSearchTerm}
          onProjectsChange={setSelectedProjects}
        />

        {/* Summary Stats */}
        <TimesheetStats timesheets={filteredTimesheets} />

        {/* Tabs and Content */}
        <Paper elevation={3}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="timesheet views"
          >
            <Tab label="Grid View" />
            <Tab label="Trends" />
            <Tab label="Client Breakdown" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <TimesheetExplorerGrid
              timesheets={filteredTimesheets}
              loading={loading}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <TimesheetTrends timesheets={filteredTimesheets} />
          </TabPanel>
        </Paper>
      </Box>
    </Box>
  );
};
