import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useStakeholderProjectPlan } from "hooks/stakeholder/useStakeholderProjectPlan";
import { addDocument, getCollectionRef } from "core/firestore";
import {
  Project,
  ProjectPlan,
  ProjectIdeaData,
  ProjectIdeaMessage,
  ChatMessage,
  Client,
} from "@freetech/models/projects";
import {
  DocumentData,
  QueryDocumentSnapshot,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  getFirestore,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { portalFunctions } from "core/functions/portalFunctions";
import { v4 as uuidv4 } from "uuid";

/**
 * Hook for managing the project idea page
 */
export const useProjectIdeaPage = () => {
  const { userInfo } = useAuth();
  const { clients, clientsIsLoading } = useStakeholder(userInfo?.id);
  const abortControllerRef = useRef<AbortController | null>(null);
  const queryClient = useQueryClient();

  const [initialInput, setInitialInput] = useState("");
  const [showFullInterface, setShowFullInterface] = useState(false);
  const [generatedPlan, setGeneratedPlan] = useState<ProjectPlan | null>(null);
  const [currentProjectData, setCurrentProjectData] = useState<
    Partial<Project>
  >({});
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [projectIdeaId, setProjectIdeaId] = useState<string | null>(null);
  const [projectPlanId, setProjectPlanId] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<
    "objective" | "selectCompany" | "complete"
  >(() => {
    // If there's only one client, start with objective step
    if (clients?.length === 1) {
      setSelectedClient(clients[0]);
      return "objective";
    }
    return "selectCompany";
  });

  const [conversationComplete, setConversationComplete] = useState(false);

  // Fetch project plan if we have a client and project idea
  const { projectPlan, isLoading: projectPlanIsLoading } =
    useStakeholderProjectPlan({
      clientId: selectedClient?.id || "",
      projectIdeaId: projectIdeaId || "",
    });

  // Update generated plan when project plan is fetched
  useEffect(() => {
    if (projectPlan) {
      setGeneratedPlan(projectPlan);
      setCurrentStep("complete");
    }
  }, [projectPlan]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>(() => {
    // If there's only one client, show the welcome message for that client
    if (clients?.length === 1) {
      return [
        {
          id: uuidv4(),
          type: "ai",
          content: `

Hi, my name is Jane! I'll help you develop a comprehensive plan for **${clients[0].companyName}** that we'll submit to FreeTech for review.

Here's our process:

* 📋 Define your business objectives and requirements
* 💡 Understand your current challenges and desired outcomes
* 📊 Document your workflow and process needs
* 🎯 Identify success metrics and goals
* 🔄 Review and refine the requirements

Once complete, FreeTech's team will:
- Design the technical solution
- Determine the appropriate technology stack
- Plan resource allocation and timeline
- Create a detailed implementation strategy

### What business challenge would you like to address?
Please describe your current situation and what you're looking to improve.`,
          timestamp: Date.now(),
        },
      ];
    }
    // Otherwise, show the company selection message
    return [
      {
        id: uuidv4(),
        type: "ai",
        content: `## Welcome to FreeTech's Project Planner! 🚀

Before we begin, please select the company you'd like to create a project for.

I'll help you develop a comprehensive project plan that we'll submit to FreeTech for review.`,
        timestamp: Date.now(),
      },
    ];
  });

  // Fetch in-progress project ideas
  const { data: inProgressIdeas } = useQuery({
    queryKey: ["in-progress-project-ideas", userInfo?.id],
    queryFn: async () => {
      if (!userInfo?.id) return [];

      const ideas: ProjectIdeaData[] = [];

      // Fetch project ideas for each client
      if (clients) {
        for (const client of clients) {
          const projectIdeasRef = getCollectionRef(
            `clients/${client.id}/projectIdeas`
          );
          const projectIdeasQuery = query(projectIdeasRef);
          const projectIdeasSnapshot = await getDocs(projectIdeasQuery);
          const projectIdeas = projectIdeasSnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              }) as ProjectIdeaData
          );
          const inProgressIdeas = projectIdeas.filter(
            (idea) => idea.status === "in_progress"
          );
          ideas.push(...inProgressIdeas);
        }
      }

      // Sort by most recently updated
      return ideas.sort(
        (a, b) =>
          new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime()
      );
    },
    enabled: Boolean(userInfo?.id && clients),
  });

  // Project generation mutation
  const generatePlanMutation = useMutation({
    mutationFn: async () => {
      if (!selectedClient?.id || !projectIdeaId) {
        console.error("Missing required data for plan generation", {
          clientId: selectedClient?.id,
          projectIdeaId,
        });
        throw new Error("Missing required data for plan generation");
      }

      console.log("Generating plan with:", {
        clientId: selectedClient.id,
        projectIdeaId,
      });

      const plan = await portalFunctions.stakeholder.generateProjectPlan({
        clientId: selectedClient.id,
        projectIdeaId: projectIdeaId,
      });
      return plan;
    },
    onSuccess: ({ plan }) => {
      console.log("Plan generated successfully:", plan);
      setGeneratedPlan(plan);
      setCurrentStep("complete");
    },
    onError: (error) => {
      console.error("Error generating plan:", error);
      // Set an error message that can be displayed to the user
      setMessages((prev) => [
        ...prev,
        {
          id: uuidv4(),
          type: "ai",
          content:
            "There was an error generating the project plan. Please try again or contact support if the issue persists.",
          timestamp: Date.now(),
        },
      ]);
    },
  });

  const handleClientSelection = useCallback((client: Client) => {
    setSelectedClient(client);
    setCurrentStep("objective");
    const welcomeMessage: ChatMessage = {
      id: uuidv4(),
      type: "ai",
      content: `

Hi, my name is Jane! I'll help you develop a comprehensive plan for **${client.companyName}** that we'll submit to FreeTech for review.

Here's our process:

* 📋 Define your business objectives and requirements
* 💡 Understand your current challenges and desired outcomes
* 📊 Document your workflow and process needs
* 🎯 Identify success metrics and goals
* 🔄 Review and refine the requirements

Once complete, FreeTech's team will:
- Design the technical solution
- Determine the appropriate technology stack
- Plan resource allocation and timeline
- Create a detailed implementation strategy

### What business challenge would you like to address?
Please describe your current situation and what you're looking to improve.`,
      timestamp: Date.now(),
    };
    setMessages([welcomeMessage]);
  }, []);

  const sendMessage = useCallback(
    async (message: string, isInitializing = false) => {
      if (isProcessing || !selectedClient) return;

      setIsProcessing(true);

      try {
        // Only add the user message to the conversation if it's not an initializing call
        if (!isInitializing && message.trim()) {
          const userMessage: ChatMessage = {
            id: uuidv4(),
            type: "user",
            content: message,
            timestamp: Date.now(),
          };
          
          // Add user message to messages state
          setMessages((prev) => [...prev, userMessage]);
        }

        // Create a temporary AI message for streaming
        const tempAiMessage: ChatMessage = {
          id: uuidv4(),
          type: "ai",
          content: "",
          timestamp: Date.now(),
        };

        setMessages((prev) => [...prev, tempAiMessage]);

        // Cancel any existing request
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        // Create new abort controller
        abortControllerRef.current = new AbortController();

        // Convert messages to API format
        const apiMessages = messages.map((msg) => ({
          role: msg.type === "user" ? "user" : "assistant",
          content: msg.content,
        }));

        // Add the new user message
        apiMessages.push({
          role: "user",
          content: message,
        });

        // Call the API with streaming
        await portalFunctions.stakeholder.handleConversation(
          apiMessages,
          currentStep,
          selectedClient.id,
          projectIdeaId || undefined,
          {
            onChunk: (chunk) => {
              setMessages((prev) => {
                const updatedMessages = [...prev];
                const lastMessage = updatedMessages[updatedMessages.length - 1];
                if (lastMessage.type === "ai") {
                  lastMessage.content += chunk;
                }
                return updatedMessages;
              });
            },
            onComplete: async (fullContent) => {
              try {
                // Parse the response as JSON if it's a string
                let response;
                try {
                  response =
                    typeof fullContent === "string"
                      ? JSON.parse(fullContent)
                      : fullContent;
                } catch (parseError) {
                  console.error("Error parsing response:", parseError);
                  response = fullContent;
                }

                // Check if we received a projectIdeaId - multiple places to check
                let receivedProjectIdeaId = null;
                if (response && typeof response === "object") {
                  if (response.projectIdeaId) {
                    receivedProjectIdeaId = response.projectIdeaId;
                    console.log(
                      "Found projectIdeaId in root:",
                      receivedProjectIdeaId
                    );
                  } else if (
                    response.projectData &&
                    response.projectData.projectIdeaId
                  ) {
                    receivedProjectIdeaId = response.projectData.projectIdeaId;
                    console.log(
                      "Found projectIdeaId in projectData:",
                      receivedProjectIdeaId
                    );
                  }
                }

                // If we found a projectIdeaId, update our state
                if (receivedProjectIdeaId) {
                  console.log("Setting projectIdeaId:", receivedProjectIdeaId);
                  setProjectIdeaId(receivedProjectIdeaId);
                }

                // Check if response indicates ready for plan
                const isReadyForPlan =
                  (typeof response === "string" &&
                    response.includes("READY_FOR_PLAN")) ||
                  response.type === "complete" ||
                  response.isComplete;

                if (isReadyForPlan) {
                  setCurrentStep("complete");
                  setConversationComplete(true);

                  // Update the message content to indicate plan generation
                  setMessages((prev) => {
                    const updatedMessages = [...prev];
                    const lastMessage =
                      updatedMessages[updatedMessages.length - 1];
                    if (lastMessage.type === "ai") {
                      lastMessage.content = lastMessage.content.replace(
                        "READY_FOR_PLAN",
                        ""
                      );
                    }
                    return updatedMessages;
                  });

                  // Update project data
                  if (response.projectData) {
                    setCurrentProjectData((prev) => ({
                      ...prev,
                      ...response.projectData,
                    }));
                  }
                  return;
                }

                if (response.type === "collect_data") {
                  // Update project data as we collect it
                  setCurrentProjectData((prev) => ({
                    ...prev,
                    ...response.projectData,
                  }));

                  // Store the projectIdeaId if we receive one
                  if (response.projectIdeaId) {
                    setProjectIdeaId(response.projectIdeaId);
                  } else if (
                    response.projectData &&
                    response.projectData.projectIdeaId
                  ) {
                    setProjectIdeaId(response.projectData.projectIdeaId);
                  }
                }

                // Update the message content
                setMessages((prev) => {
                  const updatedMessages = [...prev];
                  const lastMessage =
                    updatedMessages[updatedMessages.length - 1];
                  if (lastMessage.type === "ai") {
                    lastMessage.content = response.message || response;
                  }
                  return updatedMessages;
                });
              } catch (error) {
                // If parsing fails, treat it as a regular message
                setMessages((prev) => {
                  const updatedMessages = [...prev];
                  const lastMessage =
                    updatedMessages[updatedMessages.length - 1];
                  if (lastMessage.type === "ai") {
                    lastMessage.content = fullContent;

                    // Check if the raw response indicates ready for plan
                    if (fullContent.includes("READY_FOR_PLAN")) {
                      setCurrentStep("complete");
                      setConversationComplete(true);
                      lastMessage.content = lastMessage.content.replace(
                        "READY_FOR_PLAN",
                        ""
                      );
                    }
                  }
                  return updatedMessages;
                });
              }
              setIsProcessing(false);
            },
            onError: (error: any) => {
              console.error("Error in conversation:", error);

              // Check if this is a resetConversation error
              if (error.resetConversation) {
                // Reset the conversation state
                resetConversation();
                return;
              }

              setMessages((prev) => {
                const updatedMessages = [...prev];
                const lastMessage = updatedMessages[updatedMessages.length - 1];
                if (lastMessage.type === "ai") {
                  lastMessage.content =
                    error.content ||
                    "I apologize, but I encountered an error. Please try again.";
                }
                return updatedMessages;
              });
              setIsProcessing(false);
            },
            signal: abortControllerRef.current.signal,
          }
        );
      } catch (error) {
        console.error("Error in sendMessage:", error);
        setIsProcessing(false);
      }
    },
    [messages, currentStep, selectedClient, isProcessing, projectIdeaId]
  );

  const resetConversation = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setMessages([
      {
        id: uuidv4(),
        type: "ai",
        content: `## Welcome to FreeTech's Project Planner! 🚀

Before we begin, please select the company you'd like to create a project for.

I'll help you develop a comprehensive project plan that we'll submit to FreeTech for review.`,
        timestamp: Date.now(),
      },
    ]);
    setSelectedClient(clients?.length === 1 ? clients[0] : null);
    setCurrentStep(clients?.length === 1 ? "objective" : "selectCompany");
    setGeneratedPlan(null);
    setProjectIdeaId(null);
  }, [clients]);

  // Handle continuing an in-progress idea
  const handleContinueIdea = useCallback(
    async (clientId: string, ideaId: string) => {
      console.log(
        "handleContinueIdea in callback",
        clientId,
        ideaId,
        inProgressIdeas
      );
      console.log("clients", clients);
      console.log("inProgressIdeas", inProgressIdeas);
      if (!clients || !inProgressIdeas) return;

      // Find and set the selected client
      const client = clients.find((c) => c.id === clientId);
      const idea = inProgressIdeas.find((i) => i.id === ideaId);
      console.log("idea after find", idea);

      if (!client || !idea) return;

      // Set the selected client and project idea ID
      setSelectedClient(client);
      setProjectIdeaId(ideaId);

      console.log("after setSelectedClient and setProjectIdeaId");
      // Update the current project data with client and idea info
      setCurrentProjectData((prev) => ({
        ...prev,
        clientId: client.id,
        clientName: client.companyName,
        projectDescription: idea.projectDescription,
      }));

      // If there are existing messages, set them directly
      if (idea.messages) {
        setMessages(
          idea.messages.map((msg: ProjectIdeaMessage) => ({
            id: uuidv4(),
            type: msg.role === "user" ? "user" : "ai",
            content: msg.content,
            timestamp: msg.timestamp,
          }))
        );
      }

      // Check if conversation was already complete
      if (idea.readyForPlan || (idea as any).conversationComplete) {
        console.log("Conversation was already ready for plan", {
          readyForPlan: idea.readyForPlan,
          conversationComplete: (idea as any).conversationComplete,
        });
        setConversationComplete(true);
        setCurrentStep("complete");
      } else {
        // Set initial step
        setCurrentStep("objective");
      }

      // Set the full interface to be shown
      setShowFullInterface(true);
    },
    [clients, inProgressIdeas]
  );

  // Handle plan generation
  const handleGeneratePlan = useCallback(() => {
    generatePlanMutation.mutate();
  }, [generatePlanMutation]);

  useEffect(() => {
    console.log("useEffect", {
      clients,
      inProgressIdeas,
    });
  }, [clients, handleContinueIdea]);

  // Project deletion mutation
  const deleteProjectIdeaMutation = useMutation({
    mutationFn: async ({ clientId, ideaId }: { clientId: string; ideaId: string }) => {
      if (!clientId || !ideaId) {
        throw new Error("Missing required data for deletion");
      }

      const db = getFirestore();
      const ideaRef = doc(db, `clients/${clientId}/projectIdeas/${ideaId}`);
      await deleteDoc(ideaRef);
      
      return { success: true };
    },
    onSuccess: () => {
      // Invalidate and refetch in-progress ideas
      queryClient.invalidateQueries({ queryKey: ["in-progress-project-ideas", userInfo?.id] });
    },
    onError: (error) => {
      console.error("Error deleting project idea:", error);
    },
  });

  // Function to handle project idea deletion
  const handleDeleteProjectIdea = useCallback(
    (clientId: string, ideaId: string) => {
      return deleteProjectIdeaMutation.mutateAsync({ clientId, ideaId });
    },
    [deleteProjectIdeaMutation]
  );

  // Function to load a project idea by ID
  const loadProjectIdea = useCallback(
    async (ideaId: string) => {
      try {
        if (!ideaId) return;

        // Find the matching project idea and client
        if (inProgressIdeas) {
          const idea = inProgressIdeas.find((i) => i.id === ideaId);
          
          if (idea) {
            // Set selected client
            const client = clients?.find((c) => c.id === idea.clientId);
            if (client) {
              setSelectedClient(client);
            }

            // Set project data
            setProjectIdeaId(ideaId);
            setCurrentProjectData((prev) => ({
              ...prev,
              clientId: idea.clientId,
              clientName: idea.clientName,
              projectDescription: idea.projectDescription,
            }));

            // Set messages if they exist
            if (idea.messages) {
              setMessages(
                idea.messages.map((msg: ProjectIdeaMessage) => ({
                  id: uuidv4(),
                  type: msg.role === "user" ? "user" : "ai",
                  content: msg.content,
                  timestamp: msg.timestamp,
                }))
              );
            }

            // Check if conversation was already complete
            if (idea.readyForPlan || (idea as any).conversationComplete) {
              setConversationComplete(true);
              setCurrentStep("complete");
            } else {
              // Set initial step
              setCurrentStep("objective");
            }

            return true;
          } else {
            // Try to fetch the project idea directly from Firestore
            const db = getFirestore();
            let projectRef;
            let client;

            // Search through each client's collection
            if (clients) {
              for (const c of clients) {
                projectRef = doc(db, `clients/${c.id}/projectIdeas/${ideaId}`);
                const docSnap = await getDoc(projectRef);
                
                if (docSnap.exists()) {
                  const ideaData = docSnap.data() as ProjectIdeaData;
                  
                  // Set selected client
                  client = c;
                  setSelectedClient(c);

                  // Set project data
                  setProjectIdeaId(ideaId);
                  setCurrentProjectData((prev) => ({
                    ...prev,
                    clientId: c.id,
                    clientName: c.companyName,
                    projectDescription: ideaData.projectDescription,
                  }));

                  // Set messages if they exist
                  if (ideaData.messages) {
                    setMessages(
                      ideaData.messages.map((msg: ProjectIdeaMessage) => ({
                        id: uuidv4(),
                        type: msg.role === "user" ? "user" : "ai",
                        content: msg.content,
                        timestamp: msg.timestamp,
                      }))
                    );
                  }

                  // Check if conversation was already complete
                  if (ideaData.readyForPlan || (ideaData as any).conversationComplete) {
                    setConversationComplete(true);
                    setCurrentStep("complete");
                  } else {
                    // Set initial step
                    setCurrentStep("objective");
                  }

                  return true;
                }
              }
            }
            
            console.error("Project idea not found");
            return false;
          }
        }
        
        return false;
      } catch (error) {
        console.error("Error loading project idea:", error);
        return false;
      }
    },
    [clients, inProgressIdeas, setMessages, setSelectedClient]
  );

  return {
    // State
    initialInput,
    setInitialInput,
    showFullInterface,
    setShowFullInterface,
    messages,
    setMessages,
    isProcessing,
    generatedPlan,
    projectPlan,
    projectPlanIsLoading,
    generatePlanMutation,
    isGeneratingPlan: generatePlanMutation.isPending,
    isError: generatePlanMutation.isError,
    selectedClient,
    currentStep,
    projectIdeaId,
    clients,
    clientsIsLoading,
    currentProjectData,
    setCurrentProjectData,
    inProgressIdeas,
    sendMessage,
    resetConversation,
    handleClientSelection,
    handleContinueIdea,
    handleGeneratePlan,
    handleDeleteProjectIdea,
    loadProjectIdea,
    isDeleting: deleteProjectIdeaMutation.isPending,
    conversationComplete,
    setConversationComplete,
  };
};
