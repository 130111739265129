import React, { memo, useMemo } from "react";
import { Box, Paper, Typography, Grid, Chip } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { useFreelancerHomeStatistics } from "hooks/freelancer/useFreelancerHomeStatistics";
import { useFreelancerProjectAssignments } from "hooks/freelancer/useFreelancerProjectAssignments";
import { DateTime } from "luxon";
import { motion } from "framer-motion";
import AnimatedNumber from 'components/AnimatedNumber';

interface TimeFilter {
  startDate: DateTime;
  endDate: DateTime;
  period?: string;
}

interface TimeTrackingChartsProps {
  timeFilter: TimeFilter;
}

// Add this type at the top with other interfaces
type SeriesValueFormatter = (value: number | null) => string;

// Add interface for project assignment
interface ProjectAssignment {
  startDate: string;
  endDate: string | null;
  status: 'in_progress' | 'complete' | string;
}

// Add this helper function to determine if we're viewing a monthly period
const isMonthlyView = (timeFilter: TimeFilter) => {
  return timeFilter.period?.includes('Month');
};

const TimeTrackingCharts: React.FC<TimeTrackingChartsProps> = memo(
  ({ timeFilter }) => {
    const { stats } = useFreelancerHomeStatistics(timeFilter.startDate.year);
    const { projectAssignments } = useFreelancerProjectAssignments();

    // Filter project assignments based on date range
    const filteredProjectAssignments = useMemo(() => {
      if (!projectAssignments) return { inProgress: 0, completed: 0 };
      
      return (projectAssignments as ProjectAssignment[]).reduce(
        (acc, assignment) => {
          if (!assignment.startDate) return acc;
          
          const startDate = DateTime.fromISO(assignment.startDate);
          const endDate = assignment.endDate 
            ? DateTime.fromISO(assignment.endDate) 
            : DateTime.now();
          
          // Check if assignment overlaps with selected time period
          if (startDate <= timeFilter.endDate && endDate >= timeFilter.startDate) {
            if (assignment.status === "in_progress") {
              acc.inProgress++;
            } else if (assignment.status === "complete") {
              acc.completed++;
            }
          }
          return acc;
        },
        { inProgress: 0, completed: 0 }
      );
    }, [projectAssignments, timeFilter]);

    // Filter data based on date range
    const filteredData = useMemo(() => {
      return stats.monthlyHours.filter(item => {
        const itemDate = DateTime.fromFormat(item.month, 'MMM').set({ 
          year: timeFilter.startDate.year 
        });
        return itemDate >= timeFilter.startDate && itemDate <= timeFilter.endDate;
      });
    }, [stats.monthlyHours, timeFilter]);

    // Calculate totals based on filtered data
    const totals = useMemo(() => {
      return filteredData.reduce((acc, item) => ({
        approvedHours: acc.approvedHours + item.approvedHours,
        totalHours: acc.totalHours + item.hours,
        approvedEarnings: (acc.approvedHours + item.approvedHours) * 100,
        potentialEarnings: (acc.totalHours + item.hours) * 100,
      }), {
        approvedHours: 0,
        totalHours: 0,
        approvedEarnings: 0,
        potentialEarnings: 0,
      });
    }, [filteredData]);

    // Update the monthlyData calculation in the component
    const chartData = useMemo(() => {
      if (isMonthlyView(timeFilter)) {
        // Daily view for month periods
        const days: string[] = [];
        let currentDate = timeFilter.startDate.startOf('day');
        const endDate = timeFilter.endDate.endOf('day');
        
        while (currentDate <= endDate) {
          days.push(currentDate.toFormat('dd'));
          currentDate = currentDate.plus({ days: 1 });
        }

        // Map the filtered data to days
        const dailyData = days.map(day => {
          const dayData = filteredData.find(item => {
            const itemDate = DateTime.fromFormat(item.month, 'MMM').set({ 
              year: timeFilter.startDate.year,
              day: parseInt(day)
            });
            return itemDate.hasSame(timeFilter.startDate, 'month') && 
                   itemDate.day === parseInt(day);
          }) || {
            hours: 0,
            approvedHours: 0
          };

          return {
            day,
            potentialEarnings: dayData.hours * 100,
            approvedEarnings: dayData.approvedHours * 100
          };
        });

        return {
          labels: days,
          potentialEarnings: dailyData.map(item => item.potentialEarnings),
          approvedEarnings: dailyData.map(item => item.approvedEarnings)
        };
      } else {
        // Monthly view for year/quarter periods
        const months: string[] = [];
        let currentDate = timeFilter.startDate.startOf('month');
        const endDate = timeFilter.endDate.endOf('month');
        
        while (currentDate <= endDate) {
          months.push(currentDate.toFormat('MMM'));
          currentDate = currentDate.plus({ months: 1 });
        }

        const monthlyHours = months.map(month => {
          const monthData = filteredData.find(item => item.month === month) || {
            hours: 0,
            approvedHours: 0
          };

          return {
            month,
            potentialEarnings: monthData.hours * 100,
            approvedEarnings: monthData.approvedHours * 100
          };
        });

        return {
          labels: months,
          potentialEarnings: monthlyHours.map(item => item.potentialEarnings),
          approvedEarnings: monthlyHours.map(item => item.approvedEarnings)
        };
      }
    }, [filteredData, timeFilter]);

    return (
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3, height: "100%" }}>
              <Typography variant="h6" gutterBottom sx={{ color: "grey.900", mb: 3 }}>
                Project Assignments
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: "center", mb: 2 }}>
                    <AnimatedNumber
                      variant="h3"
                      sx={{ color: "#1976d2", fontWeight: 700, mb: 1 }}
                      value={filteredProjectAssignments.inProgress}
                    />
                    <Chip 
                      label="In Progress" 
                      sx={{ 
                        bgcolor: "rgba(25, 118, 210, 0.1)",
                        color: "#1976d2",
                        fontWeight: 600 
                      }} 
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: "center", mb: 2 }}>
                    <AnimatedNumber
                      variant="h3"
                      sx={{ color: "#2e7d32", fontWeight: 700, mb: 1 }}
                      value={filteredProjectAssignments.completed}
                    />
                    <Chip 
                      label="Completed" 
                      sx={{ 
                        bgcolor: "rgba(46, 125, 50, 0.1)",
                        color: "#2e7d32",
                        fontWeight: 600 
                      }} 
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: "grey.900", mb: 3 }}>
                Period Overview
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: 'center' }}>
                    <AnimatedNumber
                      variant="h4"
                      sx={{ color: "#2e7d32", fontWeight: 700 }}
                      value={totals.approvedHours}
                      format={(value) => value.toFixed(1)}
                    />
                    <Typography variant="body2" sx={{ color: "grey.600" }}>
                      Approved Hours
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: 'center' }}>
                    <AnimatedNumber
                      variant="h4"
                      sx={{ color: "grey.900", fontWeight: 700 }}
                      value={totals.totalHours}
                      format={(value) => value.toFixed(1)}
                    />
                    <Typography variant="body2" sx={{ color: "grey.600" }}>
                      Logged Hours
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: 'center' }}>
                    <AnimatedNumber
                      variant="h4"
                      sx={{ color: "#2e7d32", fontWeight: 700 }}
                      value={totals.approvedEarnings}
                      format={(value) => `$${value.toLocaleString()}`}
                    />
                    <Typography variant="body2" sx={{ color: "grey.600" }}>
                      Approved Earnings
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ textAlign: 'center' }}>
                    <AnimatedNumber
                      variant="h4"
                      sx={{ color: "#ed6c02", fontWeight: 700 }}
                      value={totals.potentialEarnings}
                      format={(value) => `$${value.toLocaleString()}`}
                    />
                    <Typography variant="body2" sx={{ color: "grey.600" }}>
                      Potential Earnings
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: "grey.900", mb: 3 }}>
                Monthly Distribution
              </Typography>
              <motion.div
                key={`chart-${timeFilter.startDate.toISO()}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Box sx={{ width: '100%', height: 300 }}>
                  <LineChart
                    height={300}
                    series={[
                      {
                        data: chartData.approvedEarnings,
                        area: true,
                        label: "Approved Earnings",
                        color: "#2e7d32",
                        showMark: isMonthlyView(timeFilter),
                        valueFormatter: (value: number | null) => 
                          value !== null ? `$${value}` : '',
                      },
                      {
                        data: chartData.potentialEarnings,
                        area: true,
                        label: "Potential Earnings",
                        color: "#ed6c02",
                        showMark: isMonthlyView(timeFilter),
                        valueFormatter: (value: number | null) => 
                          value !== null ? `$${value}` : '',
                      }
                    ]}
                    xAxis={[
                      {
                        data: chartData.labels,
                        scaleType: "point",
                      }
                    ]}
                    sx={{
                      '.MuiLineElement-root': {
                        strokeWidth: 2,
                      },
                      '.MuiAreaElement-root': {
                        fillOpacity: 0.1,
                      }
                    }}
                    slotProps={{
                      legend: {
                        hidden: false,
                        position: { vertical: 'top', horizontal: 'right' }
                      }
                    }}
                  />
                </Box>
              </motion.div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

TimeTrackingCharts.displayName = "TimeTrackingCharts";

export default TimeTrackingCharts;
