import React, { useEffect, useState } from 'react';
import { 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button, 
  CircularProgress, 
  Box, 
  Divider,
  Chip,
  useTheme,
  Stack,
  Fade,
  Zoom,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  LocalOffer as LocalOfferIcon,
  Star as StarIcon
} from '@mui/icons-material';
import { StudentProduct } from 'types/student';
import { useNavigate } from 'react-router-dom';
import { useStudentManageSubscription } from 'hooks/student/useStudentManageSubscription';
import { getStudentProducts } from 'core/functions/stripeFunctions';

/**
 * Component that displays available subscription products for students
 */
const SubscriptionProducts: React.FC = () => {
  const theme = useTheme();
  const [products, setProducts] = useState<StudentProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const navigate = useNavigate();
  
  // Use the subscription management hook
  const { createSubscription, isCreating } = useStudentManageSubscription();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const productsData = await getStudentProducts();
        setProducts(productsData);
        setError(null);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Failed to load subscription options. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSubscribe = async (priceId: string) => {
    setSelectedPlanId(priceId);
    try {
      const result = await createSubscription(priceId);
      
      // Redirect to Stripe checkout
      if (result.checkoutUrl) {
        window.location.href = result.checkoutUrl;
      }
    } catch (err) {
      console.error('Error creating subscription:', err);
      setError('Failed to create subscription. Please try again later.');
      setSelectedPlanId(null);
    }
  };

  const formatPrice = (price: { unit_amount: number, recurring: { interval: string } | null }) => {
    if (!price || !price.unit_amount) return '$0.00';
    const amount = price.unit_amount / 100;
    const interval = price.recurring?.interval || 'month';
    return `$${amount.toFixed(2)}/${interval === 'month' ? 'mo' : 'year'}`;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
        <Fade in={true} timeout={800}>
          <CircularProgress size={60} thickness={4} />
        </Fade>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" p={4} sx={{ maxWidth: 600, mx: 'auto' }}>
        <Typography color="error" variant="h6" gutterBottom>
          Subscription Options Unavailable
        </Typography>
        <Typography color="text.secondary" variant="body1" paragraph>
          {error}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => window.location.reload()}
          sx={{ mt: 2 }}
        >
          Try Again
        </Button>
      </Box>
    );
  }

  // Feature list for student subscription
  const commonFeatures = [
    'Full access to all student resources',
    'Project mentorship and reviews',
    'Career planning resources',
    'Learning path recommendations',
    'Industry networking opportunities',
  ];

  return (
    <Box mt={2} mb={6}>
      <Fade in={true} timeout={800}>
        <Typography 
          variant="h5" 
          gutterBottom 
          textAlign="center" 
          mb={5}
          sx={{
            fontWeight: 'bold',
            position: 'relative',
            display: 'inline-block',
            left: '50%',
            transform: 'translateX(-50%)',
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: '25%',
              width: '50%',
              height: 4,
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2
            }
          }}
        >
          Choose Your FreeTech Plan
        </Typography>
      </Fade>
      
      <Grid 
        container 
        spacing={4} 
        justifyContent="center" 
        sx={{ mt: 2 }}
      >
        {products.map((product) => (
          // Map through all prices of each product
          product.prices.map((price, index) => {
            const isAnnual = price.recurring?.interval === 'year';
            const isPopular = isAnnual; // Assuming annual plan is always the popular one
            const isLoading = isCreating && selectedPlanId === price.id;
            
            return (
              <Grid 
                item 
                xs={12} 
                md={6} 
                lg={5} 
                key={`${product.id}-${price.id}`}
                sx={{ 
                  display: 'flex',
                  transitionDelay: `${index * 100}ms`
                }}
              >
                <Zoom in={true} timeout={500 + (index * 100)}>
                  <Card 
                    elevation={isPopular ? 8 : 3} 
                    sx={{ 
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      border: isPopular ? `2px solid ${theme.palette.primary.main}` : 'none',
                      borderRadius: 3,
                      overflow: 'hidden',
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: 12
                      }
                    }}
                  >
                    {isPopular && (
                      <Box 
                        sx={{
                          position: 'absolute',
                          top: 16,
                          right: -32,
                          transform: 'rotate(45deg)',
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          py: 0.5,
                          width: 150,
                          textAlign: 'center',
                          zIndex: 1,
                          boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          BEST VALUE
                        </Typography>
                      </Box>
                    )}
                    
                    <Box 
                      sx={{ 
                        backgroundColor: isPopular ? 'primary.main' : 'grey.50',
                        color: isPopular ? 'primary.contrastText' : 'text.primary',
                        p: 3,
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                        {isPopular ? 
                          <StarIcon sx={{ mr: 1, fontSize: 28 }} /> : 
                          <LocalOfferIcon sx={{ mr: 1, fontSize: 28 }} />
                        }
                        <Typography 
                          variant="h5" 
                          component="h2" 
                          fontWeight="bold"
                        >
                          {product.name} {isAnnual ? 'Annual' : 'Monthly'}
                        </Typography>
                      </Box>
                      
                      <Typography 
                        variant="body2" 
                        color={isPopular ? 'primary.contrastText' : 'text.secondary'}
                        sx={{ opacity: 0.9, mb: 2, fontSize: '0.95rem' }}
                      >
                        {isAnnual ? 'Save 17% with annual billing' : 'Flexible monthly billing'}
                      </Typography>
                      
                      <Typography 
                        variant="h3" 
                        sx={{ 
                          fontWeight: 'bold',
                          mb: 0.5
                        }}
                      >
                        ${(price.unit_amount / 100).toFixed(2)}
                      </Typography>
                      
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          mb: 1,
                          color: isPopular ? 'primary.contrastText' : 'text.secondary',
                          opacity: 0.9
                        }}
                      >
                        per {price.recurring?.interval || 'month'}
                      </Typography>
                    </Box>
                    
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2, fontWeight: 'medium' }}>
                        INCLUDES:
                      </Typography>
                      
                      <List disablePadding>
                        {commonFeatures.map((feature, idx) => (
                          <ListItem key={idx} disablePadding disableGutters sx={{ mb: 1.5 }}>
                            <ListItemIcon sx={{ minWidth: 34 }}>
                              <CheckCircleIcon color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText 
                              primary={feature} 
                              primaryTypographyProps={{ 
                                variant: 'body2',
                                fontWeight: 'medium'
                              }} 
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                    
                    <Box p={3} pt={0}>
                      <Button 
                        fullWidth 
                        variant={isPopular ? "contained" : "outlined"} 
                        color="primary"
                        size="large"
                        onClick={() => handleSubscribe(price.id)}
                        disabled={isCreating}
                        sx={{ 
                          py: 1.5,
                          fontSize: '1rem',
                          borderRadius: 2,
                          boxShadow: isPopular ? 4 : 0
                        }}
                      >
                        {isLoading ? 
                          <CircularProgress size={24} /> : 
                          `Subscribe ${isAnnual ? 'Annually' : 'Monthly'}`
                        }
                      </Button>
                      
                      {isAnnual && (
                        <Typography 
                          variant="caption" 
                          color="text.secondary" 
                          sx={{ 
                            display: 'block',
                            textAlign: 'center', 
                            mt: 1.5
                          }}
                        >
                          * Annual subscription billed as a single payment of ${(price.unit_amount / 100).toFixed(2)}
                        </Typography>
                      )}
                    </Box>
                  </Card>
                </Zoom>
              </Grid>
            );
          })
        ))}
      </Grid>
      
      <Fade in={true} timeout={1000}>
        <Paper 
          elevation={1} 
          sx={{ 
            maxWidth: '600px', 
            mx: 'auto', 
            mt: 5, 
            p: 2.5, 
            borderRadius: 2,
            backgroundColor: theme.palette.grey[50],
            border: `1px solid ${theme.palette.grey[200]}`
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <LocalOfferIcon color="primary" sx={{ fontSize: 32 }} />
            <Box>
              <Typography variant="body2" color="text.secondary">
                All plans come with a 14-day money-back guarantee. Use coupon code <b>STUDENT25</b> for 25% off your first payment.
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Fade>
    </Box>
  );
};

export default SubscriptionProducts; 