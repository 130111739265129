import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TextField,
  InputAdornment,
  Skeleton,
  useTheme,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AccessTime as AccessTimeIcon,
  MonetizationOn as MonetizationOnIcon,
  Assignment as AssignmentIcon,
  OpenInNew as OpenInNewIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@mui/icons-material';
import { useAdminTimesheet } from 'hooks/admin/useAdminTimesheet';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

const TimesheetTab: React.FC = () => {
  const theme = useTheme();
  const { getAllTimesheets } = useAdminTimesheet();
  const { data: timesheets, isLoading } = getAllTimesheets();
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateFilter, setDateFilter] = useState<'all' | 'thisWeek' | 'thisMonth'>('all');

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Handle date filter change
  const handleDateFilterChange = (filter: 'all' | 'thisWeek' | 'thisMonth') => {
    setDateFilter(filter);
    setPage(0);
  };

  // Filter timesheets based on search query and date filter
  const filteredTimesheets = timesheets?.filter((timesheet) => {
    // Date filtering
    if (dateFilter === 'thisWeek') {
      const startOfWeek = DateTime.now().startOf('week');
      const timesheetDate = timesheet.date ? DateTime.fromISO(timesheet.date) : null;
      if (!timesheetDate || timesheetDate < startOfWeek) {
        return false;
      }
    } else if (dateFilter === 'thisMonth') {
      const startOfMonth = DateTime.now().startOf('month');
      const timesheetDate = timesheet.date ? DateTime.fromISO(timesheet.date) : null;
      if (!timesheetDate || timesheetDate < startOfMonth) {
        return false;
      }
    }

    // Search query filtering
    const searchText = searchQuery.toLowerCase();
    const userName = timesheet.userName?.toLowerCase() || '';
    const userEmail = timesheet.userEmail?.toLowerCase() || '';
    const projectName = timesheet.projectName?.toLowerCase() || '';
    const clientName = timesheet.clientName?.toLowerCase() || '';
    
    return (
      userName.includes(searchText) ||
      userEmail.includes(searchText) ||
      projectName.includes(searchText) ||
      clientName.includes(searchText)
    );
  }) || [];

  // Calculate displayed timesheets based on pagination
  const displayedTimesheets = filteredTimesheets
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Calculate timesheet statistics
  const totalHours = timesheets?.reduce((sum, ts) => sum + (ts.duration || 0), 0) || 0;
  const billableHours = timesheets?.reduce(
    (sum, ts) => sum + (ts.billable ? (ts.duration || 0) : 0), 
    0
  ) || 0;
  const nonBillableHours = totalHours - billableHours;
  
  const pendingTimesheets = timesheets?.filter(ts => ts.submitted && !ts.approved && !ts.rejected).length || 0;
  const approvedTimesheets = timesheets?.filter(ts => ts.approved).length || 0;
  const rejectedTimesheets = timesheets?.filter(ts => ts.rejected).length || 0;

  // Loading placeholders
  const StatCardLoader = () => (
    <Card>
      <CardContent>
        <Skeleton variant="text" width={120} height={28} />
        <Skeleton variant="text" width={80} height={40} sx={{ my: 1 }} />
        <Skeleton variant="text" width={100} />
      </CardContent>
    </Card>
  );

  const TableLoader = () => (
    <Box>
      <Skeleton variant="rectangular" height={52} sx={{ mb: 1 }} />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={53} sx={{ mb: 1 }} />
      ))}
    </Box>
  );

  // Format date
  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';
    return DateTime.fromISO(dateString).toFormat('LLL dd, yyyy');
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Timesheet Stats */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Timesheet Overview</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Total Hours
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1 }}>
                      {totalHours.toFixed(1)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      All recorded hours
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Billable Hours
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.success.main }}>
                      {billableHours.toFixed(1)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {billableHours > 0 && totalHours > 0 
                        ? `${((billableHours / totalHours) * 100).toFixed(1)}% of total hours`
                        : 'No billable hours'}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Pending Approval
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.warning.main }}>
                      {pendingTimesheets}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Awaiting review
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Approved Timesheets
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.info.main }}>
                      {approvedTimesheets}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Ready for billing
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Timesheet Search and Filters */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap', gap: 2 }}>
            <Typography variant="h6">Timesheet Entries</Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                variant="outlined"
                placeholder="Search timesheets..."
                size="small"
                value={searchQuery}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: { xs: '100%', sm: 220 } }}
              />
              
              <Box>
                <Button 
                  variant={dateFilter === 'all' ? "contained" : "outlined"} 
                  size="small"
                  onClick={() => handleDateFilterChange('all')}
                  sx={{ mx: 0.5 }}
                >
                  All
                </Button>
                <Button 
                  variant={dateFilter === 'thisWeek' ? "contained" : "outlined"} 
                  size="small"
                  onClick={() => handleDateFilterChange('thisWeek')}
                  sx={{ mx: 0.5 }}
                >
                  This Week
                </Button>
                <Button 
                  variant={dateFilter === 'thisMonth' ? "contained" : "outlined"} 
                  size="small"
                  onClick={() => handleDateFilterChange('thisMonth')}
                  sx={{ mx: 0.5 }}
                >
                  This Month
                </Button>
              </Box>
            </Box>
          </Box>
          
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {isLoading ? (
              <Box sx={{ p: 2 }}>
                <TableLoader />
              </Box>
            ) : (
              <>
                <TableContainer sx={{ maxHeight: 500 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Freelancer</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell align="right">Hours</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedTimesheets.map((timesheet) => (
                        <TableRow hover key={timesheet.id}>
                          <TableCell>{formatDate(timesheet.date)}</TableCell>
                          <TableCell>{timesheet.userName || 'Unknown'}</TableCell>
                          <TableCell>{timesheet.projectName || 'N/A'}</TableCell>
                          <TableCell>{timesheet.clientName || 'N/A'}</TableCell>
                          <TableCell align="right">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                              <Typography variant="body2">
                                {timesheet.duration?.toFixed(1) || '0.0'}
                              </Typography>
                              {timesheet.billable && (
                                <Tooltip title="Billable">
                                  <MonetizationOnIcon 
                                    fontSize="small" 
                                    color="success" 
                                    sx={{ ml: 1 }} 
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            {timesheet.approved ? (
                              <Chip
                                icon={<CheckCircleIcon fontSize="small" />}
                                label="Approved"
                                size="small"
                                color="success"
                              />
                            ) : timesheet.rejected ? (
                              <Chip
                                icon={<CancelIcon fontSize="small" />}
                                label="Rejected"
                                size="small"
                                color="error"
                              />
                            ) : timesheet.submitted ? (
                              <Chip
                                icon={<HourglassEmptyIcon fontSize="small" />}
                                label="Pending"
                                size="small"
                                color="warning"
                              />
                            ) : (
                              <Chip
                                icon={<AccessTimeIcon fontSize="small" />}
                                label="Draft"
                                size="small"
                                color="default"
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="View Details">
                              <IconButton
                                component={Link}
                                to={`/admin/timesheet-management`}
                                size="small"
                                color="primary"
                              >
                                <OpenInNewIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                      {displayedTimesheets.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No timesheet entries found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={filteredTimesheets.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimesheetTab; 