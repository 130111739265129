import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { Card } from "@freetech/models/freelo";
  
export const updateCard = async (
  boardId: string,
  cardId: string,
  updates: Partial<Card>
): Promise<Card> => {
  const cardRef = doc(db, "boards", boardId, "cards", cardId);
  
  const sanitizedUpdates = Object.entries(updates).reduce((acc, [key, value]) => {
    acc[key] = value === undefined ? null : value;
    return acc;
  }, {} as Record<string, any>);

  await updateDoc(cardRef, {
    ...sanitizedUpdates,
    updatedAt: DateTime.now().toISO(),
  });

  const updatedCardDoc = await getDoc(cardRef);
  return { id: cardId, ...updatedCardDoc.data() } as Card;
}; 