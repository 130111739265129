import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
  useTheme,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Placeholder from "@tiptap/extension-placeholder";
import { toast } from "react-hot-toast";
import { useCSATemplate } from "hooks/admin/useCSATemplate";
import {
  Save as SaveIcon,
  History as HistoryIcon,
  RestoreFromTrash as RestoreIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

// Define template fields
const TEMPLATE_FIELDS = [
  { name: "Client Name", value: "{{CLIENT_NAME}}" },
  { name: "Project Name", value: "{{PROJECT_NAME}}" },
  { name: "Effective Date", value: "{{EFFECTIVE_DATE}}" },
  { name: "End Date", value: "{{END_DATE}}" },
  { name: "Monthly Budget", value: "{{MONTHLY_BUDGET}}" },
  { name: "Current Date", value: "{{CURRENT_DATE}}" },
  { name: "Legal Owner Name", value: "{{LEGAL_OWNER_NAME}}" },
  { name: "Legal Owner Email", value: "{{LEGAL_OWNER_EMAIL}}" },
];

// Define signature placeholders
const SIGNATURE_PLACEHOLDERS = [
  { 
    name: "Admin Signature", 
    value: "<div id=\"ADMIN_SIGNER\" class=\"signature-line\" style=\"border-bottom: 1px solid #000; height: 50px; margin-bottom: 10px; background-color: #f0f8ff;\"></div>" 
  },
  { 
    name: "Client Signature", 
    value: "<div id=\"CLIENT_SIGNER\" class=\"signature-line\" style=\"border-bottom: 1px solid #000; height: 50px; margin-bottom: 10px; background-color: #fff0f0;\"></div>" 
  },
];

// Add custom styles to the HTML container
const addCustomStyles = (container: HTMLElement) => {
  // Add global styles
  const styleElement = document.createElement("style");
  styleElement.textContent = `
    body {
      font-family: 'Times New Roman', Times, serif;
      font-size: 12pt;
      line-height: 1.5;
      margin: 1in;
    }
    h1, h2, h3, h4, h5, h6 {
      font-family: 'Times New Roman', Times, serif;
      margin-top: 12pt;
      margin-bottom: 6pt;
    }
    p {
      margin-top: 0;
      margin-bottom: 6pt;
    }
    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 12pt;
    }
    th, td {
      border: 1px solid #000;
      padding: 6pt;
      text-align: left;
    }
    ol, ul {
      margin-top: 0;
      margin-bottom: 6pt;
      padding-left: 20pt;
    }
    li {
      margin-bottom: 3pt;
    }
    .signature-section {
      margin-top: 36pt;
      page-break-inside: avoid;
    }
    .signature-row {
      display: flex;
      justify-content: space-between;
      margin-top: 24pt;
    }
    .signature-column {
      width: 45%;
    }
    .signature-line {
      border-bottom: 1px solid #000;
      height: 50px;
      margin-bottom: 10px;
    }
    .signature-label {
      margin-bottom: 6pt;
    }
    .template-field {
      background-color: #FFFF00;
      padding: 2px 4px;
      border-radius: 2px;
      border: 1px dashed #FF9800;
      font-weight: bold;
      color: #000;
    }
  `;
  container.prepend(styleElement);
  
  // Ensure headers are properly centered
  container.querySelectorAll('p[align="center"]').forEach(el => {
    (el as HTMLElement).style.textAlign = "center";
  });
  
  // Ensure tables have proper styling
  container.querySelectorAll('table').forEach(table => {
    (table as HTMLTableElement).style.borderCollapse = "collapse";
    (table as HTMLTableElement).style.width = "100%";
    (table as HTMLTableElement).style.marginBottom = "12pt";
    
    table.querySelectorAll('th, td').forEach(cell => {
      (cell as HTMLTableCellElement).style.border = "1px solid #000";
      (cell as HTMLTableCellElement).style.padding = "6pt";
      (cell as HTMLTableCellElement).style.textAlign = "left";
    });
  });
  
  // Style the signature section
  const signatureSection = container.querySelector('.signature-section');
  if (signatureSection) {
    signatureSection.classList.add('signature-section');
  }
};

// Format the template for PDF generation
const formatTemplateForPdf = (html: string): string => {
  // Create a temporary container to manipulate the HTML
  const tempContainer = document.createElement("div");
  tempContainer.innerHTML = html;

  // Apply custom styles
  addCustomStyles(tempContainer);
  
  // Fix lettered list items
  fixLetteredListItems(tempContainer);
  
  // Note: We no longer automatically add signature placeholders
  // Users will add them manually using the signature placeholder buttons

  return tempContainer.innerHTML;
};

// Fix specific lettered list items in the document
const fixLetteredListItems = (container: HTMLElement) => {
  // Find all paragraphs that start with a single letter followed by a period
  const paragraphs = container.querySelectorAll("p.MsoNormal");

  paragraphs.forEach((paragraph) => {
    const text = paragraph.textContent || "";
    // Check if the paragraph starts with a single letter followed by a period
    if (/^[A-Z]\.$/.test(text.trim())) {
      // Add a class to style it as a lettered list item
      paragraph.classList.add("lettered-list-item");
      // Add inline styling
      (paragraph as HTMLElement).style.fontWeight = "bold";
      (paragraph as HTMLElement).style.marginLeft = "20px";
      (paragraph as HTMLElement).style.position = "relative";
    }
  });
};

const CSACreator: React.FC = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showArchiveDialog, setShowArchiveDialog] = useState(false);
  const [selectedArchive, setSelectedArchive] = useState<any>(null);
  const [archivePreviewHtml, setArchivePreviewHtml] = useState<string | null>(
    null
  );
  const [showArchivePreview, setShowArchivePreview] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedArchiveIndex, setSelectedArchiveIndex] = useState<
    number | null
  >(null);

  // Use the CSA template hook
  const {
    templateHtml,
    isLoading: isLoadingTemplate,
    updateTemplate,
    archivedTemplates,
    isLoadingArchives,
    refetchArchives,
  } = useCSATemplate();

  // Initialize TipTap editor
  const editor = useEditor({
    extensions: [
      StarterKit,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading") {
            return "What is the title?";
          }
          return "Start typing to edit the CSA template...";
        },
      }),
    ],
    content: `
      <h1>Consulting Services Agreement</h1>
      <p>Loading template...</p>
    `,
    onUpdate: ({ editor }) => {
      setHasUnsavedChanges(true);
    },
  });

  // Load the template content
  useEffect(() => {
    if (editor && templateHtml && !templateLoaded) {
      try {
        // Format the template for better rendering
        const formattedHtml = formatTemplateForPdf(templateHtml);
        editor.commands.setContent(formattedHtml);
        setTemplateLoaded(true);
        setHasUnsavedChanges(false);
      } catch (error) {
        console.error("Error loading template:", error);
        toast.error("Failed to load CSA template");
      }
    }
  }, [editor, templateHtml, templateLoaded]);

  // Add custom CSS to highlight Jinja placeholders in the editor
  useEffect(() => {
    if (!editor) return;

    // Add a style element to the document head
    const style = document.createElement("style");
    style.textContent = `
      .csa-editor [data-text*="{{"] {
        background-color: #FFFF00;
        padding: 2px 4px;
        border-radius: 2px;
        border: 1px dashed #FF9800;
        font-weight: bold;
        color: #000;
      }
      
      /* Table styling for the editor */
      .ProseMirror table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 1em 0;
        overflow: hidden;
      }
      
      .ProseMirror td, .ProseMirror th {
        border: 1px solid #ddd;
        padding: 8px;
        position: relative;
        vertical-align: top;
      }
      
      .ProseMirror th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: center;
      }
      
      .ProseMirror tr:nth-child(even) {
        background-color: #f9f9f9;
      }
      
      .ProseMirror tr:hover {
        background-color: #f5f5f5;
      }
      
      /* Add some spacing to the editor content */
      .ProseMirror {
        padding: 16px;
        min-height: 600px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
      
      /* Improve list styling */
      .ProseMirror ol, .ProseMirror ul {
        padding-left: 30px;
        margin: 16px 0;
      }
      
      .ProseMirror li {
        margin-bottom: 8px;
      }
    `;
    document.head.appendChild(style);

    return () => {
      // Clean up the style element when the component unmounts
      document.head.removeChild(style);
    };
  }, [editor]);

  // Handle save template
  const handleSaveTemplate = () => {
    setShowConfirmDialog(true);
  };

  // Confirm save template
  const confirmSaveTemplate = async () => {
    if (!editor) return;

    setLoading(true);
    setShowConfirmDialog(false);

    try {
      // Get the HTML content from the editor
      const html = editor.getHTML();

      // Create a temporary container to apply additional fixes
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = html;

      // Apply additional fixes to lettered list items
      fixLetteredListItems(tempContainer);

      // Get the updated HTML
      const finalHtml = tempContainer.innerHTML;

      // Update the template in Firebase Storage
      await updateTemplate.mutateAsync(finalHtml);

      setHasUnsavedChanges(false);
      toast.success("CSA template updated successfully");
    } catch (error) {
      console.error("Error updating CSA template:", error);
      toast.error("Failed to update CSA template");
    } finally {
      setLoading(false);
    }
  };

  // Insert a template field at the current cursor position
  const insertTemplateField = (field: { name: string; value: string }) => {
    if (!editor) return;
    
    // Check if this is a signature placeholder
    const isSignaturePlaceholder = field.value.includes('id="ADMIN_SIGNER"') || 
                                  field.value.includes('id="CLIENT_SIGNER"');
    
    if (isSignaturePlaceholder) {
      // For signature placeholders, insert directly at cursor position
      // This allows users to position them exactly where they want
      editor.chain().focus().insertContent(`${field.value}`).run();
      
      // Show a toast with guidance
      toast.success(`${field.name} added. The signature will appear exactly at this position.`);
    } else {
      // For regular template fields, insert the placeholder value
      editor.chain().focus().insertContent(`<span class="template-field">${field.value}</span>`).run();
    }
  };

  // Handle opening the archive dialog
  const handleOpenArchiveDialog = () => {
    refetchArchives();
    setShowArchiveDialog(true);
  };

  // Handle closing the archive dialog
  const handleCloseArchiveDialog = () => {
    setShowArchiveDialog(false);
    setSelectedArchive(null);
    setArchivePreviewHtml(null);
  };

  // Handle opening the archive menu
  const handleOpenArchiveMenu = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedArchiveIndex(index);
  };

  // Handle closing the archive menu
  const handleCloseArchiveMenu = () => {
    setAnchorEl(null);
    setSelectedArchiveIndex(null);
  };

  // Handle previewing an archived template
  const handlePreviewArchive = async (archive: any) => {
    try {
      const response = await fetch(archive.url);
      const html = await response.text();
      setArchivePreviewHtml(html);
      setShowArchivePreview(true);
      handleCloseArchiveMenu();
    } catch (error) {
      console.error("Error fetching archive:", error);
      toast.error("Failed to load archived template");
    }
  };

  // Handle restoring an archived template
  const handleRestoreArchive = async () => {
    if (!selectedArchiveIndex && selectedArchiveIndex !== 0) return;

    const archive = archivedTemplates?.[selectedArchiveIndex];
    if (!archive) return;

    try {
      setLoading(true);

      // Fetch the archived template
      const response = await fetch(archive.url);
      const html = await response.text();

      // Update the editor content
      if (editor) {
        editor.commands.setContent(html);
        setHasUnsavedChanges(true);
        toast.success("Archived template loaded successfully");
      }

      handleCloseArchiveMenu();
      handleCloseArchiveDialog();
    } catch (error) {
      console.error("Error restoring archive:", error);
      toast.error("Failed to restore archived template");
    } finally {
      setLoading(false);
    }
  };

  // Handle closing the archive preview
  const handleCloseArchivePreview = () => {
    setShowArchivePreview(false);
    setArchivePreviewHtml(null);
  };

  // Menu bar component with template field buttons
  const MenuBar = () => {
    return (
      <Box sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleSaveTemplate}
            startIcon={<SaveIcon />}
          >
            Save Template
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpenArchiveDialog}
            startIcon={<HistoryIcon />}
          >
            Template Archive
          </Button>
        </Box>
        
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Insert Template Fields:
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {TEMPLATE_FIELDS.map((field) => (
              <Button
                key={field.value}
                variant="outlined"
                size="small"
                onClick={() => insertTemplateField(field)}
              >
                {field.name}
              </Button>
            ))}
          </Box>
        </Box>
        
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle2" gutterBottom>
            Insert Signature Placeholders:
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {SIGNATURE_PLACEHOLDERS.map((field) => (
              <Button
                key={field.name}
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => insertTemplateField(field)}
              >
                {field.name}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>

      <Paper
        elevation={2}
        sx={{
          p: 3,
          mb: 4,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Tooltip
          title="The fields with {   
            { and } 
            } are template placeholders that will be automatically replaced
            with client information when generating CSAs. Use the buttons above
            the editor to insert template fields. Do not modify the format of
            these fields."
        >
          <Alert severity="warning" sx={{ mb: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Important: Template Fields
            </Typography>
            <Typography variant="body2"></Typography>
          </Alert>
        </Tooltip>
        {hasUnsavedChanges && (
          <Alert severity="info" sx={{ mt: 2 }}>
            You have unsaved changes to the template.
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit CSA Template
          </Typography>
          <Box>
            <Button
              variant="outlined"
              startIcon={<HistoryIcon />}
              onClick={handleOpenArchiveDialog}
              sx={{ mr: 2 }}
            >
              View Archives
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveTemplate}
              disabled={loading || !hasUnsavedChanges}
            >
              Save Template
            </Button>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          This template will be used as the base for all CSAs generated in the
          system. Make sure to include all necessary placeholders for client
          information.
        </Typography>
        <Divider sx={{ mb: 2 }} />

        {isLoadingTemplate ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <MenuBar />
            <EditorContent editor={editor} />
          </>
        )}
      </Paper>

      {/* Confirm Save Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this template? This will update the
            master template used for all CSAs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button
            onClick={confirmSaveTemplate}
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Archive Dialog */}
      <Dialog
        open={showArchiveDialog}
        onClose={handleCloseArchiveDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Archived Templates</DialogTitle>
        <DialogContent>
          {isLoadingArchives ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
              <CircularProgress />
            </Box>
          ) : archivedTemplates && archivedTemplates.length > 0 ? (
            <List>
              {archivedTemplates.map((archive, index) => (
                <ListItem key={archive.name} divider>
                  <ListItemText
                    primary={`Template Version ${index + 1}`}
                    secondary={`Saved on: ${archive.date}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={(e) => handleOpenArchiveMenu(e, index)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1" align="center" sx={{ p: 2 }}>
              No archived templates found.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseArchiveDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Archive Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseArchiveMenu}
      >
        <MenuItem
          onClick={() => {
            if (selectedArchiveIndex !== null && archivedTemplates) {
              handlePreviewArchive(archivedTemplates[selectedArchiveIndex]);
            }
          }}
        >
          <VisibilityIcon fontSize="small" sx={{ mr: 1 }} />
          Preview
        </MenuItem>
        <MenuItem onClick={handleRestoreArchive}>
          <RestoreIcon fontSize="small" sx={{ mr: 1 }} />
          Restore
        </MenuItem>
      </Menu>

      {/* Archive Preview Dialog */}
      <Dialog
        open={showArchivePreview}
        onClose={handleCloseArchivePreview}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Template Preview</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, p: 2, border: "1px solid #ddd", borderRadius: 1 }}>
            <div
              dangerouslySetInnerHTML={{ __html: archivePreviewHtml || "" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseArchivePreview}>Close</Button>
          <Button
            color="primary"
            onClick={() => {
              if (editor && archivePreviewHtml) {
                editor.commands.setContent(archivePreviewHtml);
                setHasUnsavedChanges(true);
                handleCloseArchivePreview();
                handleCloseArchiveDialog();
                toast.success("Archived template loaded into editor");
              }
            }}
          >
            Load in Editor
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CSACreator;
