export * from "./firebase";
export * from "./freetechPaymentsPublicKey";
export * as constants from "./constants";

export const isLocal = window.location.hostname.includes("localhost");

export const muiXLicenseKey = process.env.VITE_MUI_X_LICENSE_KEY;

export const socketUrl = isLocal
  ? "http://localhost:4069"
  : // ? "https://freetech-socket-stg-667347021524.us-central1.run.app"
    process.env.VITE_SOCKET_URL;

// export const socketUrl = process.env.VITE_SOCKET_URL;
