import React, { useState } from "react";
import {
  Box,
  Button,
  Badge,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Avatar,
  useTheme,
  useMediaQuery,
  Chip,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { Payment, Assignment, Description } from "@mui/icons-material";
import { useNotifications } from "../../../../hooks/notifications/useNotifications";
import { useAuth } from "hooks/auth/useAuth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getIcon,
  getNotificationMessage,
  getNotificationDescription,
  getNotificationSeverity,
} from "core/notifications/getNotificationDetails";
import { handleNotificationClick } from "core/notifications/handleNotificationClick";
import { DateTime } from "luxon";

const NotificationAccordion: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { notifications, markAsRead, markAllAsRead } = useNotifications(
    userInfo?.id || ""
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Sort notifications by date (newest first)
  const sortedNotifications = [...notifications].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB.getTime() - dateA.getTime();
  });

  const unreadNotifications = sortedNotifications.filter((n) => !n.read);

  const handleMarkAsRead = (
    event: React.MouseEvent,
    notificationId?: string
  ) => {
    event.stopPropagation();
    if (notificationId) {
      markAsRead.mutate(notificationId);
    } else {
      console.error("No notification ID provided");
    }
  };

  // Get severity color for notification
  const getSeverityColor = (
    severity: "info" | "success" | "warning" | "error"
  ) => {
    switch (severity) {
      case "error":
        return theme.palette.error.main;
      case "warning":
        return theme.palette.warning.main;
      case "success":
        return theme.palette.success.main;
      case "info":
      default:
        return theme.palette.primary.main;
    }
  };

  // Format date for display
  const formatDate = (date: Date | string) => {
    const dateObj = date instanceof Date ? date : new Date(date);
    const now = new Date();
    const diffMs = now.getTime() - dateObj.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      // Today - show time
      return DateTime.fromJSDate(dateObj).toLocaleString(DateTime.TIME_SIMPLE);
    } else if (diffDays === 1) {
      // Yesterday
      return "Yesterday";
    } else if (diffDays < 7) {
      // Within a week - show day name
      return DateTime.fromJSDate(dateObj).toLocaleString({ weekday: "short" });
    } else {
      // Older - show date
      return DateTime.fromJSDate(dateObj).toLocaleString(DateTime.DATE_SHORT);
    }
  };

  return (
    <Box>
      <Button
        onClick={handleClick}
        sx={{
          padding: 0,
          minWidth: "auto",
          marginRight: { xs: 0.5, sm: 1 },
        }}
      >
        <Badge
          badgeContent={unreadNotifications.length}
          color="error"
          overlap="circular"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <svg width="0" height="0">
              <linearGradient
                id="mailIconGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
              >
                <stop offset="20%" stopColor="#0062ff" />
                <stop offset="30%" stopColor="#00a6ff" />
                <stop offset="100%" stopColor="#e6f3ff" />
              </linearGradient>
            </svg>
            <MailIcon
              sx={{
                fill: "url(#mailIconGradient)",
                fontSize: 24,
              }}
            />
          </Box>
        </Badge>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            width: { xs: "100vw", sm: "420px" },
            maxWidth: { xs: "100vw", sm: "420px" },
            maxHeight: { xs: "calc(100vh - 64px)", sm: "calc(100vh - 100px)" },
            overflowY: "auto",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        slotProps={{
          paper: {
            sx: {
              position: { xs: "fixed", sm: "absolute" },
              top: { xs: "64px", sm: "auto" },
              left: { xs: "0", sm: "unset" },
              right: { xs: "0", sm: "unset" },
              transform: {
                xs: "none !important",
                sm: "translate(-32px, 0) !important",
              },
              borderRadius: { xs: 0, sm: 1 },
              boxShadow: (theme) => theme.shadows[8],
            },
          },
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "background.paper",
            zIndex: 1,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              px: { xs: 1.5, sm: 2 },
              py: { xs: 1, sm: 1.5 },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Notifications
              {unreadNotifications.length > 0 && (
                <Chip
                  size="small"
                  label={unreadNotifications.length}
                  color="error"
                  sx={{ ml: 1, height: 20, fontSize: "0.75rem" }}
                />
              )}
            </Typography>
            <Button
              size="small"
              onClick={() => markAllAsRead.mutate()}
              disabled={
                markAllAsRead.isPending || unreadNotifications.length === 0
              }
              sx={{ color: "primary.main" }}
            >
              Mark all as read
            </Button>
          </Box>
        </Box>
        <List sx={{ py: 0 }}>
          {unreadNotifications.length > 0 ? (
            unreadNotifications.map((notification) => {
              const severity = getNotificationSeverity(notification);
              const description = getNotificationDescription(notification);
              const message = getNotificationMessage(notification);
              const destination = handleNotificationClick(notification);
              const isClickable = !!destination;

              return (
                <ListItem
                  key={notification.id}
                  component="button"
                  onClick={() => {
                    if (destination) {
                      navigate(destination);
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    p: { xs: 1.5, sm: 2 },
                    textAlign: "left",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    cursor: isClickable ? "pointer" : "default",
                    "&:hover": isClickable
                      ? {
                          bgcolor: "action.hover",
                        }
                      : {},
                    position: "relative",
                    overflow: "hidden",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: "4px",
                      backgroundColor: getSeverityColor(severity),
                    },
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <ListItemIcon sx={{ minWidth: { xs: 32, sm: 40 } }}>
                      <Avatar
                        sx={{
                          bgcolor: `${getSeverityColor(severity)}20`,
                          color: getSeverityColor(severity),
                          width: { xs: 32, sm: 36 },
                          height: { xs: 32, sm: 36 },
                        }}
                      >
                        {React.createElement(
                          getIcon(notification.type, severity) || "div",
                          {
                            sx: {
                              fontSize: { xs: 18, sm: 20 },
                            },
                          }
                        )}
                      </Avatar>
                    </ListItemIcon>
                    <Box sx={{ flex: 1, overflow: "hidden" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          color: "text.primary",
                          fontSize: { xs: "0.8125rem", sm: "0.875rem" },
                          mb: 0.5,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {message}
                      </Typography>
                      {description && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "text.secondary",
                            display: "block",
                            mb: 0.5,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {description}
                        </Typography>
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          fontSize: { xs: "0.75rem", sm: "0.75rem" },
                        }}
                      >
                        {formatDate(notification.createdAt)}
                      </Typography>
                    </Box>
                    <Button
                      onClick={(e) => handleMarkAsRead(e, notification.id)}
                      sx={{
                        minWidth: "auto",
                        p: { xs: 0.25, sm: 0.5 },
                        ml: 1,
                        color: "text.secondary",
                        "&:hover": {
                          bgcolor: "action.hover",
                        },
                      }}
                    >
                      <VisibilityIcon sx={{ fontSize: { xs: 18, sm: 20 } }} />
                    </Button>
                  </Box>
                </ListItem>
              );
            })
          ) : (
            <ListItem sx={{ py: { xs: 3, sm: 4 } }}>
              <ListItemText
                sx={{ textAlign: "center" }}
                primary={
                  <Typography color="text.secondary">
                    No notifications
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "background.paper",
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/notifications");
            }}
            sx={{
              justifyContent: "center",
              py: { xs: 1, sm: 1.5 },
              fontWeight: 500,
              color: "primary.main",
              "&:hover": {
                bgcolor: "primary.light",
                color: "white",
              },
            }}
          >
            See All
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default NotificationAccordion;
