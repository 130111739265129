import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createBlog,
  updateBlog,
  deleteBlog,
  getBlog,
  getBlogs,
  getBlogVersions,
  createBlogVersion,
  deleteBlogVersion,
} from "core/blog/blog";
import {
  BlogPost,
  BlogPostCreate,
  BlogPostUpdate,
  BlogListItem,
  BlogVersion,
  BlogCategory,
} from "@freetech/models/blog";
import { useAuth } from "hooks/auth";

const BLOGS_QUERY_KEY = "blogs";
const VERSIONS_QUERY_KEY = "versions";

export const useAdminBlogs = (blogId?: string) => {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  const { data: blogs = [], isLoading: isLoadingBlogs } = useQuery<
    BlogListItem[]
  >({
    queryKey: [BLOGS_QUERY_KEY],
    queryFn: () => getBlogs(false),
  });

  const { data: publishedBlogs = [], isLoading: isLoadingPublished } = useQuery<
    BlogListItem[]
  >({
    queryKey: [BLOGS_QUERY_KEY, "published"],
    queryFn: () => getBlogs(true),
  });

  const { data: selectedBlog, isLoading: isLoadingBlog } = useQuery<BlogPost>({
    queryKey: [BLOGS_QUERY_KEY, "selected", blogId],
    queryFn: () => getBlog(blogId!),
    enabled: !!blogId,
  });

  const { data: blogVersions = [], isLoading: isLoadingVersions } = useQuery<BlogVersion[]>({
    queryKey: [BLOGS_QUERY_KEY, blogId, VERSIONS_QUERY_KEY],
    queryFn: () => getBlogVersions(blogId!),
    enabled: !!blogId,
  });

  const { mutateAsync: createBlogMutation } = useMutation({
    mutationFn: (blog: BlogPostCreate) => {
      if (!currentUser?.uid) throw new Error("User not authenticated");
      return createBlog(blog, currentUser.uid);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BLOGS_QUERY_KEY] });
    },
  });

  const { mutateAsync: createVersionMutation } = useMutation({
    mutationFn: async (blog: BlogPost) => {
      if (!currentUser?.uid) throw new Error("User not authenticated");
      
      // Get previous version for diff calculation
      const previousBlog = blogVersions[0] ? await getBlog(blogVersions[0].blogId) : null;
      
      return createBlogVersion(
        blog,
        previousBlog,
        currentUser.uid,
        currentUser.displayName || 'Unknown User'
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ 
        queryKey: [BLOGS_QUERY_KEY, blogId, VERSIONS_QUERY_KEY] 
      });
    },
  });

  const { mutateAsync: updateBlogMutation } = useMutation({
    mutationFn: async (blog: BlogPostUpdate) => {
      if (!currentUser?.uid) throw new Error("User not authenticated");
      
      return updateBlog(
        blog,
        currentUser.uid,
        currentUser.displayName || 'Unknown User'
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [BLOGS_QUERY_KEY] });
      queryClient.invalidateQueries({ 
        queryKey: [BLOGS_QUERY_KEY, "selected", variables.id] 
      });
    },
  });

  const { mutateAsync: deleteBlogMutation } = useMutation({
    mutationFn: (id: string) => deleteBlog(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BLOGS_QUERY_KEY] });
    },
  });

  const { mutateAsync: restoreVersionMutation } = useMutation({
    mutationFn: async (version: BlogVersion) => {
      if (!currentUser?.uid) throw new Error("User not authenticated");
      
      const updateData: BlogPostUpdate = {
        id: version.blogId,
        content: version.content,
        title: version.title,
        description: version.description,
        category: version.category,
        headerImage: version.headerImage,
        lastModified: new Date().toISOString(),
      };

      return updateBlog(
        updateData,
        currentUser.uid,
        currentUser.displayName || 'Unknown User'
      );
    },
    onSuccess: (_, version) => {
      queryClient.invalidateQueries({ queryKey: [BLOGS_QUERY_KEY] });
      queryClient.invalidateQueries({ 
        queryKey: [BLOGS_QUERY_KEY, "selected", version.blogId] 
      });
    },
  });

  const { mutateAsync: deleteVersionMutation } = useMutation({
    mutationFn: async (versionId: string) => {
      console.log('Deleting version with ID:', versionId);
      console.log('Blog ID:', blogId);
      if (!blogId) throw new Error("Blog ID is required");
      const result = await deleteBlogVersion(blogId, versionId);
      console.log('Version deleted successfully');
      return result;
    },
    onSuccess: () => {
      console.log('Invalidating queries after version deletion');
      queryClient.invalidateQueries({ 
        queryKey: [BLOGS_QUERY_KEY, blogId, VERSIONS_QUERY_KEY] 
      });
    },
    onError: (error) => {
      console.error('Error in deleteVersion mutation:', error);
    }
  });

  return {
    // Queries
    blogs,
    publishedBlogs,
    selectedBlog,
    isLoadingBlogs,
    isLoadingPublished,
    isLoadingBlog,
    blogVersions,
    isLoadingVersions,

    // Mutations
    createBlog: createBlogMutation,
    updateBlog: updateBlogMutation,
    deleteBlog: deleteBlogMutation,
    restoreVersion: restoreVersionMutation,
    deleteVersion: deleteVersionMutation,
  };
};
