import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import { IFeatureRequest } from "@freetech/models";
import { useFreelo } from "hooks/freelo/useFreelo";
import { useAdminFeatureRequest } from "hooks/admin/useAdminFeatureRequest";

interface CreateTicketDialogProps {
  open: boolean;
  onClose: () => void;
  featureRequest: IFeatureRequest;
  boardId: string;
  listId: string;
  onSuccess: () => void;
  onError: (error: string) => void;
}

export const CreateTicketDialog: React.FC<CreateTicketDialogProps> = ({
  open,
  onClose,
  featureRequest,
  boardId,
  listId,
  onSuccess,
  onError,
}) => {
  const [title, setTitle] = useState(featureRequest.title);
  const [description, setDescription] = useState(
    `Feature Request: ${featureRequest.description}\n\nPriority: ${
      featureRequest.priority
    }\nRequested by: ${featureRequest.requestedById || "Unknown"}`
  );
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);

  const { createCard } = useFreelo();
  const { updateFeatureRequest } = useAdminFeatureRequest(featureRequest.clientId);

  const handleSubmit = async () => {
    if (!title.trim()) return;
    setIsCreatingTicket(true);

    try {
      const cardId = await createCard.mutateAsync({
        boardId,
        listId,
        title,
        description,
        assignee: featureRequest.assignedToId,
        featureRequestId: [featureRequest.id],
        labels: [
          {
            id: "feature-request",
            name: "Feature Request",
            color: "magenta",
          },
        ],
        reporter: featureRequest.requestedById,
      });

      // Update the feature request with the new ticket ID
      await updateFeatureRequest.mutateAsync({
        id: featureRequest.id,
        data: {
          freeloTicketIds: [...(featureRequest.freeloTicketIds || []), cardId],
        },
      });

      onSuccess();
      onClose();
    } catch (error) {
      onError("Failed to create Freelo ticket. Please try again.");
      console.error("Error creating Freelo ticket:", error);
    } finally {
      setIsCreatingTicket(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create Freelo Ticket</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isCreatingTicket || !title.trim()}
        >
          {isCreatingTicket ? <CircularProgress size={24} /> : "Create Ticket"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 