import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Chip,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IBugReport, Freelancer } from "@freetech/models";
import { Card as FreeloCard } from "@freetech/models/freelo";
import { DateTime } from "luxon";
import ReadOnlyTipTap from "components/TipTap/ReadOnlyTipTap";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useAdminBugReport } from "hooks/admin/useAdminBugReport";
import { ConfirmDialog } from "./ConfirmDialog";

interface BugReportCardProps {
  report: IBugReport;
  linkedTickets: FreeloCard[];
  onCreateTicket: (report: IBugReport) => void;
}

interface LinkedTicketsProps {
  tickets: FreeloCard[];
  onCreateTicket: (report: IBugReport) => void;
  report: IBugReport;
  variant?: "success" | "default";
}

const LinkedTickets: React.FC<LinkedTicketsProps> = ({ tickets, onCreateTicket, report, variant = "default" }) => (
  <Box sx={{ p: 2, bgcolor: variant === "success" ? "success.50" : "grey.50", borderRadius: 1 }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
      }}
    >
      <Typography variant="subtitle2" color={variant === "success" ? "success.main" : "text.secondary"}>
        {variant === "success" ? "Linked Freelo Tickets" : "Freelo Tickets"}
      </Typography>
      {variant !== "success" && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AssignmentIcon />}
          onClick={() => onCreateTicket(report)}
        >
          Create Ticket
        </Button>
      )}
    </Box>
    {tickets.length > 0 ? (
      <Stack spacing={1}>
        {tickets.map((ticket) => (
          <Box
            key={ticket.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
              border: 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <LinkIcon color="action" fontSize="small" />
              <Typography variant="body2">
                {ticket.title}
              </Typography>
            </Box>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                window.open(
                  `${window.location.origin}/freelo/${ticket.boardId}#${ticket.humanReadableId}`,
                  "_blank"
                );
              }}
            >
              #{ticket.humanReadableId}
            </Button>
          </Box>
        ))}
      </Stack>
    ) : (
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ textAlign: "center", py: 1 }}
      >
        {variant === "success" ? "No Freelo tickets were created" : "No Freelo tickets created yet"}
      </Typography>
    )}
  </Box>
);

export const BugReportCard: React.FC<BugReportCardProps> = ({
  report,
  linkedTickets,
  onCreateTicket,
}) => {
  console.log('report in card', report);
  const { freelancers } = useAdminFreelancers();
  const { approveBugReport, rejectBugReport, updateBugReport } =
    useAdminBugReport(report.clientId);

  const [selectedEngineerId, setSelectedEngineerId] = useState<string>(
    report.assignedToId || ""
  );

  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    type: "approve" | "in_progress" | "resolved" | "cancel" | null;
    isLoading: boolean;
  }>({
    open: false,
    type: null,
    isLoading: false,
  });

  const handleConfirmAction = async () => {
    setConfirmDialog((prev) => ({ ...prev, isLoading: true }));
    try {
      if (confirmDialog.type === "approve") {
        await approveBugReport(report, selectedEngineerId);
      } else if (
        confirmDialog.type === "in_progress" ||
        confirmDialog.type === "resolved" ||
        confirmDialog.type === "cancel"
      ) {
        await updateBugReport.mutateAsync({
          id: report.id,
          data: {
            status:
              confirmDialog.type === "in_progress"
                ? "in_progress"
                : confirmDialog.type === "resolved"
                  ? "resolved"
                  : "closed",
          },
        });
      }
    } catch (error) {
      console.error("Error updating bug report:", error);
    } finally {
      setConfirmDialog({ open: false, type: null, isLoading: false });
    }
  };

  const handleReject = async () => {
    try {
      await rejectBugReport(report);
    } catch (error) {
      console.error("Error rejecting bug report:", error);
    }
  };

  const requestDate = report.createdAt;

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  mb: 1,
                }}
              >
                <Typography variant="h6">{report.title}</Typography>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    Reported on:{" "}
                    {report.createdAt.toLocaleString() || "Unknown"}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    Reported by: {report.reportedById || "Unknown"}
                  </Typography>
                </Box>
              </Box>

              <ReadOnlyTipTap content={report.description} />

              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Steps to Reproduce
                </Typography>
                <ReadOnlyTipTap content={report.stepsToReproduce} />
              </Box>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Expected Behavior
                    </Typography>
                    <ReadOnlyTipTap content={report.expectedBehavior} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Actual Behavior
                    </Typography>
                    <ReadOnlyTipTap content={report.actualBehavior} />
                  </Box>
                </Grid>
              </Grid>

              {report.attachments && report.attachments.length > 0 && (
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Attachments
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                    {report.attachments.map((attachment, index) => (
                      <Chip
                        key={index}
                        icon={<AttachFileIcon />}
                        label={attachment.name}
                        onClick={() => window.open(attachment.url, "_blank")}
                        onDelete={() => window.open(attachment.url, "_blank")}
                        deleteIcon={<DownloadIcon />}
                        sx={{ m: 0.5 }}
                      />
                    ))}
                  </Stack>
                </Box>
              )}

              <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <Chip
                  label={report.priority}
                  color={
                    report.priority === "critical"
                      ? "error"
                      : report.priority === "high"
                        ? "warning"
                        : "info"
                  }
                  size="small"
                />
                <Chip label={report.type} color="default" size="small" />
                <Chip
                  label={report.status}
                  color={
                    report.status === "resolved"
                      ? "success"
                      : report.status === "closed"
                        ? "error"
                        : report.status === "in_progress"
                          ? "warning"
                          : "default"
                  }
                  size="small"
                />
              </Stack>
            </Box>
          </Box>

          {report.status === "open" && (
            <>
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Assign Engineer
                </Typography>
                <FormControl fullWidth size="small">
                  <InputLabel>Select Engineer</InputLabel>
                  <Select
                    value={selectedEngineerId}
                    onChange={(e) => setSelectedEngineerId(e.target.value)}
                    label="Select Engineer"
                  >
                    {freelancers?.map((freelancer) => (
                      <MenuItem key={freelancer.id} value={freelancer.id}>
                        {`${freelancer.firstName} ${freelancer.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mt: 2 }}>
                <LinkedTickets
                  tickets={linkedTickets}
                  onCreateTicket={onCreateTicket}
                  report={report}
                />
              </Box>

              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
                <Button variant="outlined" color="error" onClick={handleReject}>
                  Close
                </Button>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() =>
                    setConfirmDialog({
                      open: true,
                      type: "cancel",
                      isLoading: false,
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setConfirmDialog({
                      open: true,
                      type: "approve",
                      isLoading: false,
                    })
                  }
                  disabled={!selectedEngineerId}
                >
                  Start Work & Assign
                </Button>
              </Box>
            </>
          )}

          {report.status === "in_progress" && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "warning.50",
                      borderRadius: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="warning.main"
                      gutterBottom
                    >
                      Assigned Engineer
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                      <Box>
                        <Typography variant="body2">
                          {
                            freelancers?.find(
                              (f) => f.id === report.assignedToId
                            )?.firstName
                          }{" "}
                          {
                            freelancers?.find(
                              (f) => f.id === report.assignedToId
                            )?.lastName
                          }
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {freelancers?.find(
                            (f) => f.id === report.assignedToId
                          )?.role || "Engineer"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <LinkedTickets
                    tickets={linkedTickets}
                    onCreateTicket={onCreateTicket}
                    report={report}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() =>
                    setConfirmDialog({
                      open: true,
                      type: "cancel",
                      isLoading: false,
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() =>
                    setConfirmDialog({
                      open: true,
                      type: "resolved",
                      isLoading: false,
                    })
                  }
                >
                  Mark as Resolved
                </Button>
              </Box>
            </>
          )}

          {report.status === "resolved" && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "success.50",
                      borderRadius: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="success.main"
                      gutterBottom
                    >
                      Resolution Details
                    </Typography>
                    <Stack spacing={1}>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Resolved on
                        </Typography>
                        <Typography variant="body2">
                          {/* {report.resolvedAt
                            ? DateTime.fromISO(report.resolvedAt).toLocaleString(
                                DateTime.DATE_FULL
                              )
                            : DateTime.now().toLocaleString(DateTime.DATE_FULL)} */}
                          Yes
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Time to Resolution
                        </Typography>
                        <Typography variant="body2">
                          {/* {report.createdAt && report.resolvedAt
                            ? `${Math.ceil(
                                DateTime.fromISO(report.).diff(
                                  DateTime.fromISO(report.createdAt),
                                  "days"
                                ).days
                              )} days`
                            : "Unknown"} */}
                            Yes
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "success.50",
                      borderRadius: 1,
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="success.main"
                      gutterBottom
                    >
                      Assigned Engineer
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                      <Box>
                        <Typography variant="body2">
                          {
                            freelancers?.find(
                              (f) => f.id === report.assignedToId
                            )?.firstName
                          }{" "}
                          {
                            freelancers?.find(
                              (f) => f.id === report.assignedToId
                            )?.lastName
                          }
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {freelancers?.find(
                            (f) => f.id === report.assignedToId
                          )?.role || "Engineer"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <LinkedTickets
                    tickets={linkedTickets}
                    onCreateTicket={onCreateTicket}
                    report={report}
                    variant="success"
                  />
                </Grid>
              </Grid>
            </>
          )}

          {report.status === "closed" && (
            <Box sx={{ mt: 2, p: 2, bgcolor: "error.50", borderRadius: 1 }}>
              <Typography variant="subtitle2" color="error" gutterBottom>
                Bug Report Closed
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Closed on{" "}
                {report.closedAt
                  ? DateTime.fromISO(report.closedAt).toLocaleString(
                      DateTime.DATE_FULL
                    )
                  : "Unknown date"}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <ConfirmDialog
        open={confirmDialog.open}
        onClose={() =>
          setConfirmDialog({ open: false, type: null, isLoading: false })
        }
        onConfirm={handleConfirmAction}
        title={
          confirmDialog.type === "approve"
            ? "Start Work on Bug Report"
            : confirmDialog.type === "in_progress"
              ? "Mark as In Progress"
              : confirmDialog.type === "resolved"
                ? "Mark as Resolved"
                : "Cancel Bug Report"
        }
        message={
          confirmDialog.type === "approve"
            ? `Are you sure you want to start work on this bug report and assign it to ${
                freelancers?.find((f) => f.id === selectedEngineerId)?.firstName
              } ${
                freelancers?.find((f) => f.id === selectedEngineerId)?.lastName
              }?`            : confirmDialog.type === "in_progress"
              ? "Are you sure you want to mark this bug report as in progress?"
              : confirmDialog.type === "resolved"
                ? "Are you sure you want to mark this bug report as resolved?"
                : "Are you sure you want to cancel this bug report? This action cannot be undone."
        }
        confirmText={
          confirmDialog.type === "approve"
            ? "Start Work & Assign"
            : confirmDialog.type === "in_progress"
              ? "Mark as In Progress"
              : confirmDialog.type === "resolved"
                ? "Mark as Resolved"
                : "Cancel Report"
        }
        isLoading={confirmDialog.isLoading}
      />
    </>
  );
};

