import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { adminController } from "controllers/adminController";
import { Stakeholder } from "@freetech/models/user";

export const useAdminStakeholders = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["adminStakeholders"],
    queryFn: () => adminController.stakeholders.loadNestedStakeholders(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });


  

  const updateStakeholderMutation = useMutation({
    mutationFn: ({
      stakeholderId,
      updates,
    }: {
      stakeholderId: string;
      updates: Partial<Stakeholder>;
    }) =>
      adminController.stakeholders.updateStakeholder(stakeholderId, updates),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminStakeholders"] });
    },
  });

  return {
    data: query.data as Stakeholder[],
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,

    // Stakeholder mutations
    updateStakeholder: updateStakeholderMutation.mutate,
    isUpdatingStakeholder: updateStakeholderMutation.isPending,
    updateStakeholderError: updateStakeholderMutation.error,
  };
};
