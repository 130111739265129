import React, { useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAuth } from "hooks/auth/useAuth";
import {
  ICreateBugReport,
  BugReportPriority,
  BugReportType,
  Stakeholder,
} from "@freetech/models";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useQuery } from "@tanstack/react-query";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
];

const bugReportSchema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title is too long"),
  description: z
    .string()
    .min(1, "Description is required")
    .max(5000, "Description is too long"),
  stepsToReproduce: z.string().min(1, "Steps to reproduce are required"),
  expectedBehavior: z.string().min(1, "Expected behavior is required"),
  actualBehavior: z.string().min(1, "Actual behavior is required"),
  priority: z.enum(["low", "medium", "high", "critical"] as const),
  type: z.enum([
    "internal_user_experience",
    "end_user_experience",
    "performance",
    "security",
    "other",
  ] as const),
  browserInfo: z.string().optional(),
  reportedById: z.string().min(1, "Stakeholder is required"),
});

type BugReportFormData = z.infer<typeof bugReportSchema>;

interface CreateBugReportDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ICreateBugReport, files: File[]) => Promise<void>;
  clientId: string;
  projectId: string;
  isSubmitting?: boolean;
}

export const CreateBugReportDialog: React.FC<CreateBugReportDialogProps> = ({
  open,
  onClose,
  onSubmit,
  clientId,
  projectId,
  isSubmitting = false,
}) => {
  const { userInfo } = useAuth();
  const [files, setFiles] = React.useState<File[]>([]);
  const { getStakeholdersForClientId } = useAdminClient(clientId);
  const { data: stakeholders = [], isLoading: isLoadingStakeholders } =
    useQuery({
      queryKey: ["stakeholders", clientId],
      queryFn: () => getStakeholdersForClientId.mutateAsync(clientId),
      enabled: !!clientId,
    });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<BugReportFormData>({
    resolver: zodResolver(bugReportSchema),
    defaultValues: {
      title: "",
      description: "",
      stepsToReproduce: "",
      expectedBehavior: "",
      actualBehavior: "",
      priority: "medium",
      type: "internal_user_experience",
      browserInfo: "",
      reportedById: "",
    },
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(
      (file) =>
        file.size <= MAX_FILE_SIZE && ALLOWED_FILE_TYPES.includes(file.type)
    );
    setFiles((prev) => [...prev, ...validFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png", ".gif"],
      "application/pdf": [".pdf"],
    },
    maxSize: MAX_FILE_SIZE,
  });

  const handleClose = () => {
    reset();
    setFiles([]);
    onClose();
  };

  const handleFormSubmit = async (data: BugReportFormData) => {
    const bugReport: ICreateBugReport = {
      ...data,
      clientId,
      projectId,
      status: "open",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    await onSubmit(bugReport, files);
    handleClose();
  };

  const removeFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Bug Report</DialogTitle>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="reportedById"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.reportedById}>
                    <InputLabel>Reported By</InputLabel>
                    <Select
                      {...field}
                      label="Reported By"
                      disabled={isLoadingStakeholders}
                    >
                      {stakeholders.map((stakeholder) => (
                        <MenuItem key={stakeholder.id} value={stakeholder.id}>
                          {stakeholder.firstName} {stakeholder.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Title"
                    fullWidth
                    error={!!errors.title}
                    helperText={errors.title?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.description}
                    helperText={errors.description?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="stepsToReproduce"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Steps to Reproduce"
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.stepsToReproduce}
                    helperText={errors.stepsToReproduce?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="expectedBehavior"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Expected Behavior"
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.expectedBehavior}
                    helperText={errors.expectedBehavior?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="actualBehavior"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Actual Behavior"
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.actualBehavior}
                    helperText={errors.actualBehavior?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.priority}>
                    <InputLabel>Priority</InputLabel>
                    <Select {...field} label="Priority">
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="critical">Critical</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.type}>
                    <InputLabel>Type</InputLabel>
                    <Select {...field} label="Type">
                      <MenuItem value="internal_user_experience">
                        Internal User Experience
                      </MenuItem>
                      <MenuItem value="end_user_experience">
                        End User Experience
                      </MenuItem>
                      <MenuItem value="performance">Performance</MenuItem>
                      <MenuItem value="security">Security</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="browserInfo"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Browser Information"
                    fullWidth
                    placeholder="e.g., Chrome 91.0.4472.124 on Windows 10"
                    error={!!errors.browserInfo}
                    helperText={errors.browserInfo?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: 1,
                  p: 3,
                  textAlign: "center",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "primary.main",
                  },
                }}
              >
                <input {...getInputProps()} />
                <CloudUploadIcon
                  sx={{ fontSize: 48, color: "text.secondary" }}
                />
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Drag and drop files here, or click to select files
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Supported formats: JPEG, PNG, GIF, PDF (max 10MB)
                </Typography>
              </Box>
            </Grid>

            {files.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Attached Files:
                </Typography>
                {files.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 1,
                      mb: 1,
                      bgcolor: "background.paper",
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2">{file.name}</Typography>
                    <Button
                      size="small"
                      color="error"
                      onClick={() => removeFile(index)}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || isLoadingStakeholders}
          >
            Create Bug Report
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
