import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Avatar,
  AvatarGroup,
  Stack,
  Tooltip,
  Link,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import TimelineIcon from "@mui/icons-material/Timeline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CodeIcon from "@mui/icons-material/Code";
import { Board } from "@freetech/models/freelo";
import { PublicUserInfoType } from "@freetech/models/user";

interface FreeloStakeholderHeaderProps {
  boards: Board[];
  userInfo: PublicUserInfoType;
  users?: PublicUserInfoType[];
}

export const FreeloStakeholderHeader: React.FC<
  FreeloStakeholderHeaderProps
> = ({ boards, userInfo, users }) => {
  const getBoardStatistics = () => {
    let totalFreelancers = new Set<string>();
    let totalTasks = 0;
    let completedTasks = 0;

    boards.forEach((board) => {
      // Get all freelancers who have read or write access
      board.readUserIds?.forEach((id) => totalFreelancers.add(id));
      board.writeUserIds?.forEach((id) => totalFreelancers.add(id));

      board.lists?.forEach((list) => {
        list.cards?.forEach((card) => {
          totalTasks++;
          if (list.name === "Done" || list.name === "Completed") {
            completedTasks++;
          }
        });
      });
    });

    return {
      freelancerCount: totalFreelancers.size,
      totalTasks,
      completedTasks,
    };
  };

  const getActiveFreelancers = () => {
    const freelancers = new Set<string>();

    boards.forEach((board) => {
      board.lists?.forEach((list) => {
        list.cards?.forEach((card) => {
          if (card.assignee && list.name !== "Done") {
            freelancers.add(card.assignee);
          }
        });
      });
    });

    return Array.from(freelancers)
      .map((id) => users?.find((user) => user.id === id))
      .filter((user): user is PublicUserInfoType => !!user);
  };

  const stats = getBoardStatistics();
  
  const activeFreelancers = getActiveFreelancers();

  return (
    <Paper
      elevation={0}
      sx={{ p: 3, mb: 4, bgcolor: "background.default", borderRadius: 2 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Welcome to Freelo!
          </Typography>
          <Box sx={{ maxWidth: "800px" }}>
            <Typography 
              variant="body1" 
              color="text.secondary" 
              paragraph 
              sx={{ 
                lineHeight: 1.6,
                mb: 2
              }}
            >
              Freelo is our personalized Kanban project management tool. We use this product at 
              FreeTech to track our work and communicate with our clients on deliverables. Please reach out to your Project Lead for more information.
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              <Link
                href="https://www.atlassian.com/agile/kanban"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 0.5,
                  color: "primary.main",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Learn more about Kanban and how it differs from Scrum and Agile
                <OpenInNewIcon sx={{ fontSize: 16 }} />
              </Link>
            </Typography>

            <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <CodeIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="subtitle1" fontWeight={600}>MCP Integration</Typography>
                </Box>
                <Typography variant="body2" sx={{ mb: 1.5, lineHeight: 1.6 }}>
                  FreeTech leverages the Model Context Protocol (MCP) to align software development directly with your requirements in Freelo.
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
                  This technology enables our AI assistants to access and understand your project boards, allowing our developers to build software that precisely meets your specifications without information being lost in translation.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TimelineIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Project Progress</Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ p: 2, flex: 1 }}>
              <Typography variant="h4" gutterBottom>
                {stats.totalTasks}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Tasks
              </Typography>
            </Paper>
            <Paper sx={{ p: 2, flex: 1 }}>
              <Typography variant="h4" color="success.main" gutterBottom>
                {stats.completedTasks}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Tasks Completed
              </Typography>
            </Paper>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <GroupIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Team Overview</Typography>
          </Box>
          <Paper sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {stats.freelancerCount}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Active Freelancers
                </Typography>
              </Box>
              <AvatarGroup
                max={5}
                sx={{ "& .MuiAvatar-root": { width: 40, height: 40 } }}
              >
                {activeFreelancers.map((freelancer) => (
                  <Tooltip
                    key={freelancer.id}
                    title={`${freelancer.firstName} ${freelancer.lastName}`}
                  >
                    <Avatar
                      alt={`${freelancer.firstName} ${freelancer.lastName}`}
                      src={freelancer.profilePicture || undefined}
                    >
                      {freelancer.firstName?.[0]}
                    </Avatar>
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};
