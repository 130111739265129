import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Project } from "@freetech/models/projects";
import { Client } from "@freetech/models/projects";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CodeIcon from "@mui/icons-material/Code";
import { FeatureRequestPriority } from "@freetech/models/bugReport";
import { useStakeholderFeatureRequest } from "hooks/stakeholder/useStakeholderFeatureRequest";
import { useAuth } from "hooks/auth/useAuth";

const schema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title is too long"),
  description: z
    .string()
    .min(1, "Description is required")
    .max(5000, "Description is too long"),
  priority: z.enum(["low", "medium", "high"] as const),
  desiredStartDate: z.string({
    required_error: "Start date is required",
  }),
  desiredCompletionDate: z.string({
    required_error: "Completion date is required",
  }),
  clientEstimatedHours: z.number({
    required_error: "Desired hours is required",
    invalid_type_error: "Please enter a valid number",
  }).min(1, "Hours must be greater than 0"),
  clientDollarAmountBudget: z.number({
    required_error: "Desired budget is required",
    invalid_type_error: "Please enter a valid number",
  }).min(1, "Budget must be greater than 0"),
}).refine((data) => {
  const startDate = new Date(data.desiredStartDate);
  const completionDate = new Date(data.desiredCompletionDate);
  return completionDate >= startDate;
}, {
  message: "Completion date must be after start date",
  path: ["desiredCompletionDate"],
});

type FeatureRequestFormData = z.infer<typeof schema>;

interface FeatureRequestDialogProps {
  open: boolean;
  onClose: () => void;
  project: Project | undefined;
  client: Client | undefined;
}

export const FeatureRequestDialog: React.FC<FeatureRequestDialogProps> = ({
  open,
  onClose,
  project,
  client,
}) => {
  const { userInfo } = useAuth();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { createFeatureRequest, isSubmitting } = useStakeholderFeatureRequest({
    clientId: client?.id || "",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: "",
      description: "",
      priority: "medium" as const,
      desiredStartDate: "",
      desiredCompletionDate: "",
      clientEstimatedHours: undefined,
      clientDollarAmountBudget: undefined,
    },
    mode: "onChange",
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder:
          "Please provide a detailed description of the feature you're requesting. Include any specific requirements, use cases, or examples that would help us understand your needs better.",
      }),
    ],
    onUpdate: ({ editor }) => {
      setValue("description", editor.getHTML(), { shouldValidate: true });
    },
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none",
      },
    },
  });

  const handleClose = () => {
    reset();
    setSubmitError(null);
    editor?.commands.clearContent();
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!project?.id || !client?.id || !userInfo?.id) {
      setSubmitError("Missing required project or client information");
      return;
    }

    try {
      setSubmitError(null);
      const featureRequestData = {
        title: data.title,
        description: data.description,
        priority: data.priority,
        desiredStartDate: data.desiredStartDate,
        desiredCompletionDate: data.desiredCompletionDate,
        clientEstimatedHours: data.clientEstimatedHours,
        clientDollarAmountBudget: data.clientDollarAmountBudget,
        clientId: client.id,
        projectId: project.id,
        requestedById: userInfo.id,
        requestDate: new Date().toISOString(),
        status: "pending_approval" as const,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      await createFeatureRequest.mutateAsync(featureRequestData);
      handleClose();
    } catch (error) {
      console.error("Error creating feature request:", error);
      setSubmitError(
        error instanceof Error 
          ? error.message 
          : "Failed to create feature request. Please try again later."
      );
    }
  });

  const MenuBar = () => {
    if (!editor) return null;

    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          mb: 1,
          borderBottom: 1,
          borderColor: "divider",
          pb: 1,
        }}
      >
        <Tooltip title="Bold">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleBold().run()}
            color={editor.isActive("bold") ? "primary" : "default"}
          >
            <FormatBoldIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Italic">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            color={editor.isActive("italic") ? "primary" : "default"}
          >
            <FormatItalicIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Bullet List">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            color={editor.isActive("bulletList") ? "primary" : "default"}
          >
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Numbered List">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            color={editor.isActive("orderedList") ? "primary" : "default"}
          >
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Code">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            color={editor.isActive("codeBlock") ? "primary" : "default"}
          >
            <CodeIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Create Feature Request</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ pb: 2 }}>
          {submitError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Project"
                value={project?.name || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    bgcolor: "action.hover",
                    "&:hover": {
                      bgcolor: "action.hover",
                    },
                    "& .MuiInputBase-input": {
                      color: "text.secondary",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Client"
                value={client?.companyName || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    bgcolor: "action.hover",
                    "&:hover": {
                      bgcolor: "action.hover",
                    },
                    "& .MuiInputBase-input": {
                      color: "text.secondary",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <TextField
            {...control.register("title")}
            autoFocus
            size="small"
            label="Title"
            fullWidth
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ mb: 2 }}
            placeholder="Enter a clear, concise title for your feature request"
          />

          <Box sx={{ mb: 2 }}>
            <MenuBar />
            <Box
              sx={{
                border: 1,
                borderColor: errors.description ? "error.main" : "divider",
                borderRadius: 1,
                p: 2,
                minHeight: "120px",
                maxHeight: "200px",
                overflow: "auto",
                "& .ProseMirror": {
                  minHeight: "100px",
                  width: "100%",
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                  "& p.is-editor-empty:first-child::before": {
                    content: "attr(data-placeholder)",
                    color: "text.disabled",
                    float: "left",
                    pointerEvents: "none",
                    height: 0,
                  },
                  "& p": {
                    margin: 0,
                    lineHeight: 1.6,
                  },
                },
              }}
            >
              <EditorContent editor={editor} />
            </Box>
            {errors.description && (
              <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                {errors.description.message}
              </Typography>
            )}
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>Priority</InputLabel>
                    <Select {...field} label="Priority">
                      <MenuItem value="low">Low Priority</MenuItem>
                      <MenuItem value="medium">Medium Priority</MenuItem>
                      <MenuItem value="high">High Priority</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="clientEstimatedHours"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    value={value || ""}
                    onChange={(e) => onChange(Number(e.target.value) || undefined)}
                    type="number"
                    label="Desired Hours"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.clientEstimatedHours}
                    helperText={errors.clientEstimatedHours?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="clientDollarAmountBudget"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    value={value || ""}
                    onChange={(e) => onChange(Number(e.target.value) || undefined)}
                    type="number"
                    label="Desired Budget ($)"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.clientDollarAmountBudget}
                    helperText={errors.clientDollarAmountBudget?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="desiredStartDate"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value || undefined)}
                    type="date"
                    label="Desired Start Date"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.desiredStartDate}
                    helperText={errors.desiredStartDate?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="desiredCompletionDate"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value || undefined)}
                    type="date"
                    label="Desired Completion Date"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.desiredCompletionDate}
                    helperText={errors.desiredCompletionDate?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={createFeatureRequest.isPending}
          >
            {createFeatureRequest.isPending ? (
              <CircularProgress size={24} />
            ) : (
              "Submit Request"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
