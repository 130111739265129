import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Chip,
  Dialog,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Freelancer } from "@freetech/models/user";
import { useAdminPayroll } from "hooks/admin/useAdminPayroll";
import { DateTime } from "luxon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import ArticleIcon from "@mui/icons-material/Article";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplayIcon from "@mui/icons-material/Replay";
import ViewFileDialog from "components/ViewFileDialog";

interface ContractorDetailsProps {
  contractor: Freelancer;
}

export const ContractorDetails: React.FC<ContractorDetailsProps> = ({
  contractor,
}) => {
  const {
    getTaxFormUrl,
    verifyTaxForm,
    markReadyForPayroll,
    createTestPayment,
    useTestPaymentStatus,
    denyAndRequestNewTaxForm,
  } = useAdminPayroll();
  const [viewTaxFormDialogOpen, setViewTaxFormDialogOpen] = useState(false);
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);
  const [denyDialogOpen, setDenyDialogOpen] = useState(false);
  const [taxFormUrl, setTaxFormUrl] = useState<string | null>(null);
  const [isLoadingTaxForm, setIsLoadingTaxForm] = useState(false);
  const [denyReason, setDenyReason] = useState("");
  const [denyReasonError, setDenyReasonError] = useState(false);
  const [isTestPaymentOverdue, setIsTestPaymentOverdue] = useState(false);

  // Only fetch test payment status if not ready for payroll and has a test payment
  const { data: testPaymentStatus } = useTestPaymentStatus(
    contractor.id,
    !contractor.readyForPayroll &&
      Boolean(contractor.increaseExternalAccountProfile?.testPaymentIncreaseId)
  );

  // Check if test payment is pending for more than 3 days
  useEffect(() => {
    if (contractor.increaseExternalAccountProfile?.testPaymentCreatedAt) {
      const testPaymentDate = DateTime.fromISO(
        contractor.increaseExternalAccountProfile.testPaymentCreatedAt
      );
      const currentDate = DateTime.now();
      const diffInDays = currentDate.diff(testPaymentDate, ["days"]).days;
      
      setIsTestPaymentOverdue(diffInDays >= 3);
    } else {
      setIsTestPaymentOverdue(false);
    }
  }, [contractor.increaseExternalAccountProfile?.testPaymentCreatedAt]);

  const handleViewTaxForm = async () => {
    setIsLoadingTaxForm(true);
    try {
      const url = await getTaxFormUrl(contractor.id);
      setTaxFormUrl(url);
      setViewTaxFormDialogOpen(true);
    } catch (error) {
      console.error("Error loading tax form:", error);
    } finally {
      setIsLoadingTaxForm(false);
    }
  };

  const handleOpenVerifyDialog = async () => {
    setIsLoadingTaxForm(true);
    try {
      const url = await getTaxFormUrl(contractor.id);
      setTaxFormUrl(url);
      setVerifyDialogOpen(true);
    } catch (error) {
      console.error("Error loading tax form:", error);
    } finally {
      setIsLoadingTaxForm(false);
    }
  };

  const handleOpenDenyDialog = async () => {
    setIsLoadingTaxForm(true);
    try {
      const url = await getTaxFormUrl(contractor.id);
      setTaxFormUrl(url);
      setDenyDialogOpen(true);
    } catch (error) {
      console.error("Error loading tax form:", error);
    } finally {
      setIsLoadingTaxForm(false);
    }
  };

  const handleVerifyTaxForm = async () => {
    try {
      await verifyTaxForm.mutateAsync({ userId: contractor.id });
      setVerifyDialogOpen(false);
    } catch (error) {
      console.error("Error verifying tax form:", error);
    }
  };

  const handleDenyTaxForm = async () => {
    if (!denyReason.trim()) {
      setDenyReasonError(true);
      return;
    }

    try {
      await denyAndRequestNewTaxForm.mutateAsync({
        userId: contractor.id,
        reason: denyReason.trim(),
      });
      setDenyDialogOpen(false);
      setDenyReason("");
    } catch (error) {
      console.error("Error denying tax form:", error);
    }
  };

  const handleMarkReadyForPayroll = async () => {
    try {
      await markReadyForPayroll.mutateAsync({ userId: contractor.id });
    } catch (error) {
      console.error("Error marking ready for payroll:", error);
    }
  };

  const handleCreateTestPayment = async () => {
    try {
      await createTestPayment.mutateAsync({ userId: contractor.id });
    } catch (error) {
      console.error("Error creating test payment:", error);
    }
  };

  const handleRetryBankVerification = async () => {
    try {
      await createTestPayment.mutateAsync({ userId: contractor.id });
    } catch (error) {
      console.error("Error retrying bank verification:", error);
    }
  };

  const formatDate = (date: string | undefined) => {
    return date
      ? DateTime.fromISO(date).toFormat("MMM d, yyyy h:mm a")
      : "Not set";
  };

  console.log(testPaymentStatus);

  useEffect(() => {
    console.log(createTestPayment.isPending);
  }, [createTestPayment.isPending]);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        {/* Tax Form Information */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <ArticleIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Tax Form Information</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Status
                </Typography>
                <Chip
                  icon={
                    contractor.taxFormCompleted ? (
                      <CheckCircleIcon />
                    ) : (
                      <ErrorIcon />
                    )
                  }
                  label={
                    contractor.taxFormCompleted ? "Completed" : "Not Completed"
                  }
                  color={contractor.taxFormCompleted ? "success" : "error"}
                  sx={{ mt: 1 }}
                />
                {contractor.newTaxFormRequested && (
                  <Chip
                    icon={<PendingIcon />}
                    label="New Tax Form Requested"
                    color="warning"
                    sx={{ mt: 1, ml: 1 }}
                  />
                )}
              </Box>
              {contractor.taxFormCompleted && (
                <>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Completed At
                    </Typography>
                    <Typography>
                      {formatDate(contractor.taxFormCompletedAt)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Updated
                    </Typography>
                    <Typography>
                      {formatDate(contractor.taxFormUpdatedAt)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Admin Verification
                    </Typography>
                    <Chip
                      icon={
                        contractor.taxFormVerifiedByAdmin ? (
                          <CheckCircleIcon />
                        ) : (
                          <PendingIcon />
                        )
                      }
                      label={
                        contractor.taxFormVerifiedByAdmin
                          ? "Verified"
                          : "Needs Verification"
                      }
                      color={
                        contractor.taxFormVerifiedByAdmin
                          ? "success"
                          : "warning"
                      }
                      sx={{ mt: 1 }}
                    />
                    {contractor.taxFormVerifiedByAdminAt && (
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ mt: 1 }}
                      >
                        Verified on{" "}
                        {formatDate(contractor.taxFormVerifiedByAdminAt)}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Button
                      startIcon={<VisibilityIcon />}
                      onClick={handleViewTaxForm}
                      variant="outlined"
                      disabled={isLoadingTaxForm}
                    >
                      View Tax Form
                    </Button>
                    {!contractor.taxFormVerifiedByAdmin ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={handleOpenVerifyDialog}
                          disabled={verifyTaxForm.isPending || isLoadingTaxForm}
                          color="success"
                        >
                          {verifyTaxForm.isPending ? (
                            <>
                              <CircularProgress size={20} sx={{ mr: 1 }} />
                              Verifying...
                            </>
                          ) : (
                            "Verify Tax Form"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleOpenDenyDialog}
                          disabled={
                            denyAndRequestNewTaxForm.isPending ||
                            isLoadingTaxForm
                          }
                          color="error"
                        >
                          {denyAndRequestNewTaxForm.isPending ? (
                            <>
                              <CircularProgress size={20} sx={{ mr: 1 }} />
                              Denying...
                            </>
                          ) : (
                            "Deny & Request New"
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleOpenDenyDialog}
                        disabled={
                          denyAndRequestNewTaxForm.isPending || isLoadingTaxForm
                        }
                        color="error"
                      >
                        {denyAndRequestNewTaxForm.isPending ? (
                          <>
                            <CircularProgress size={20} sx={{ mr: 1 }} />
                            Denying...
                          </>
                        ) : (
                          "Deny & Request New"
                        )}
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid>

        {/* Bank Account Information */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <AccountBalanceIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Bank Account Information</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Profile Status
                </Typography>
                <Chip
                  icon={
                    contractor.payrollProfileCompleted ? (
                      <CheckCircleIcon />
                    ) : (
                      <ErrorIcon />
                    )
                  }
                  label={
                    contractor.payrollProfileCompleted
                      ? "Completed"
                      : "Not Set Up"
                  }
                  color={
                    contractor.payrollProfileCompleted ? "success" : "error"
                  }
                  sx={{ mt: 1 }}
                />
              </Box>
              {contractor.payrollProfileCompleted && (
                <>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Completed At
                    </Typography>
                    <Typography>
                      {formatDate(contractor.payrollProfileCompletedAt)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Updated
                    </Typography>
                    <Typography>
                      {formatDate(contractor.payrollProfileUpdatedAt)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Bank Verification
                    </Typography>
                    {contractor.readyForPayroll ? (
                      <Chip
                        icon={<CheckCircleIcon />}
                        label="Verified"
                        color="success"
                        sx={{ mt: 1 }}
                      />
                    ) : (
                      <>
                        <Chip
                          icon={
                            testPaymentStatus?.status === "completed" ? (
                              <CheckCircleIcon />
                            ) : testPaymentStatus?.status === "failed" ? (
                              <ErrorIcon />
                            ) : testPaymentStatus?.status === "pending" ? (
                              <PendingIcon />
                            ) : (
                              <PendingIcon />
                            )
                          }
                          label={
                            testPaymentStatus?.status === "completed"
                              ? "Verified"
                              : testPaymentStatus?.status === "failed"
                                ? "Failed"
                                : testPaymentStatus?.status === "pending"
                                  ? "Pending"
                                  : "Pending"
                          }
                          color={
                            testPaymentStatus?.status === "completed"
                              ? "success"
                              : testPaymentStatus?.status === "failed"
                                ? "error"
                                : testPaymentStatus?.status === "pending"
                                  ? "warning"
                                  : "warning"
                          }
                          sx={{ mt: 1 }}
                        />
                        {contractor.increaseExternalAccountProfile
                          ?.testPaymentCreatedAt && (
                          <Typography
                            variant="caption"
                            display="block"
                            sx={{ mt: 1 }}
                          >
                            Test payment initiated on{" "}
                            {formatDate(
                              contractor.increaseExternalAccountProfile
                                ?.testPaymentCreatedAt
                            )}
                          </Typography>
                        )}
                        {isTestPaymentOverdue && testPaymentStatus?.status !== "completed" && (
                          <Typography
                            variant="caption"
                            color="error"
                            display="block"
                            sx={{ mt: 1 }}
                          >
                            Test payment initiated more than 3 days ago
                          </Typography>
                        )}
                        {!contractor.increaseExternalAccountProfile
                          ?.testPaymentIncreaseId && (
                          <Box sx={{ mt: 2 }}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleCreateTestPayment}
                              disabled={createTestPayment.isPending}
                            >
                              {createTestPayment.isPending ? (
                                <>
                                  <CircularProgress size={20} sx={{ mr: 1 }} />
                                  Creating Test Payment...
                                </>
                              ) : (
                                "Create Test Payment"
                              )}
                            </Button>
                          </Box>
                        )}
                        {contractor.increaseExternalAccountProfile
                          ?.testPaymentIncreaseId && (
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="caption" display="block">
                              Test Payment Id:{" "}
                              {
                                contractor.increaseExternalAccountProfile
                                  ?.testPaymentIncreaseId
                              }
                            </Typography>
                            <Typography variant="caption" display="block">
                              Test Payment Status:{" "}
                              {testPaymentStatus?.status ||
                                contractor.increaseExternalAccountProfile
                                  ?.testPaymentStatus}
                            </Typography>
                            {isTestPaymentOverdue && testPaymentStatus?.status !== "completed" && (
                              <Box sx={{ mt: 2 }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<ReplayIcon />}
                                  onClick={handleRetryBankVerification}
                                  disabled={createTestPayment.isPending}
                                >
                                  {createTestPayment.isPending ? (
                                    <>
                                      <CircularProgress size={20} sx={{ mr: 1 }} />
                                      Retrying...
                                    </>
                                  ) : (
                                    "Retry Bank Verification"
                                  )}
                                </Button>
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid>

        {/* Ready for Payroll Status */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <CheckCircleIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Ready for Payroll Status</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    User Verification
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Chip
                      icon={
                        contractor.paymentAndTaxProfileVerifiedByUser ? (
                          <CheckCircleIcon />
                        ) : (
                          <PendingIcon />
                        )
                      }
                      label={
                        contractor.paymentAndTaxProfileVerifiedByUser
                          ? "Verified by User"
                          : "Not Verified"
                      }
                      color={
                        contractor.paymentAndTaxProfileVerifiedByUser
                          ? "success"
                          : "warning"
                      }
                    />
                    {contractor.paymentAndTaxProfileVerifiedByUserAt && (
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ mt: 1 }}
                      >
                        Verified on{" "}
                        {formatDate(
                          contractor.paymentAndTaxProfileVerifiedByUserAt
                        )}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Ready for Payroll
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Chip
                      icon={
                        contractor.readyForPayroll ? (
                          <CheckCircleIcon />
                        ) : (
                          <PendingIcon />
                        )
                      }
                      label={contractor.readyForPayroll ? "Ready" : "Not Ready"}
                      color={contractor.readyForPayroll ? "success" : "warning"}
                    />
                    {contractor.readyForPayrollAt && (
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ mt: 1 }}
                      >
                        Ready since {formatDate(contractor.readyForPayrollAt)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {!contractor.readyForPayroll &&
              contractor.paymentAndTaxProfileVerifiedByUser &&
              contractor.taxFormVerifiedByAdmin &&
              contractor.payrollProfileCompleted &&
              testPaymentStatus?.status === "completed" && (
                <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleMarkReadyForPayroll}
                    disabled={markReadyForPayroll.isPending}
                  >
                    {markReadyForPayroll.isPending ? (
                      <>
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        Marking as Ready...
                      </>
                    ) : (
                      "Mark Ready for Payroll"
                    )}
                  </Button>
                </Box>
              )}
          </Paper>
        </Grid>
      </Grid>

      <ViewFileDialog
        open={viewTaxFormDialogOpen}
        onClose={() => {
          setViewTaxFormDialogOpen(false);
          setTaxFormUrl(null);
        }}
        fileUrl={taxFormUrl || ""}
        fileName="Tax Form.pdf"
        isLoading={isLoadingTaxForm}
      />

      <Dialog
        open={verifyDialogOpen}
        onClose={() => setVerifyDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Verify Tax Form</DialogTitle>
        <DialogContent>
          <Typography paragraph sx={{ mb: 3 }}>
            Please review the tax form below carefully before verifying it.
          </Typography>
          <Box sx={{ height: "70vh", width: "100%", mb: 2 }}>
            {taxFormUrl && (
              <iframe
                src={taxFormUrl}
                style={{ width: "100%", height: "100%", border: "none" }}
                title="Tax Form Preview"
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVerifyDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleVerifyTaxForm}
            variant="contained"
            disabled={verifyTaxForm.isPending}
          >
            {verifyTaxForm.isPending ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Verifying...
              </>
            ) : (
              "Confirm Verification"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={denyDialogOpen}
        onClose={() => {
          setDenyDialogOpen(false);
          setDenyReason("");
          setDenyReasonError(false);
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Deny Tax Form & Request New</DialogTitle>
        <DialogContent>
          <Typography paragraph sx={{ mb: 3 }}>
            Please review the tax form below and provide a reason for denial.
            The freelancer will be notified and asked to submit a new tax form.
          </Typography>
          <Box sx={{ height: "50vh", width: "100%", mb: 3 }}>
            {taxFormUrl && (
              <iframe
                src={taxFormUrl}
                style={{ width: "100%", height: "100%", border: "none" }}
                title="Tax Form Preview"
              />
            )}
          </Box>
          <TextField
            label="Reason for Denial"
            multiline
            rows={4}
            fullWidth
            value={denyReason}
            onChange={(e) => {
              setDenyReason(e.target.value);
              if (e.target.value.trim()) {
                setDenyReasonError(false);
              }
            }}
            error={denyReasonError}
            helperText={
              denyReasonError ? "Please provide a reason for denial" : ""
            }
            placeholder="Please explain why this tax form is being denied and what the freelancer needs to correct."
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDenyDialogOpen(false);
              setDenyReason("");
              setDenyReasonError(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDenyTaxForm}
            variant="contained"
            color="error"
            disabled={denyAndRequestNewTaxForm.isPending}
          >
            {denyAndRequestNewTaxForm.isPending ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Denying & Requesting New...
              </>
            ) : (
              "Deny & Request New Tax Form"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
