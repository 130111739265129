import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Alert,
  Chip,
  Stack,
  InputAdornment,
  IconButton,
  Collapse,
  useTheme
} from "@mui/material";
import { useAuth } from "hooks/auth";
import { useNavigate } from "react-router-dom";
import { useStudentManageSubscription } from "hooks/student/useStudentManageSubscription";
import { useStudentOnboarding } from "hooks/student/useStudentOnboarding";
import { getStudentProducts } from "core/functions/stripeFunctions";
import { StudentProduct } from "types/student";
import { CheckCircleOutline as CheckCircleIcon } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const steps = ["Personal Information", "Educational Background", "Membership"];

export const StudentOnboarding: React.FC = () => {
  const theme = useTheme();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  
  // Form state
  const [phoneNumber, setPhoneNumber] = useState<string>(userInfo?.phone || "");
  const [address, setAddress] = useState<string>("");
  const [school, setSchool] = useState<string>("");
  const [degree, setDegree] = useState<string>("");
  const [graduationYear, setGraduationYear] = useState<string>("");
  const [skills, setSkills] = useState<string>("");
  
  // Subscription state
  const [products, setProducts] = useState<StudentProduct[]>([]);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponValid, setCouponValid] = useState<boolean | null>(null);
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
  const [selectedPriceId, setSelectedPriceId] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  
  // Get subscription management hook
  const { 
    subscription,
    isLoading: isSubscriptionLoading,
    createSubscription,
    isCreating
  } = useStudentManageSubscription();

  // Get onboarding hook
  const {
    updateProfile,
    isUpdating,
    completeStudentOnboarding
  } = useStudentOnboarding();

  // Check if user already has an active subscription
  const hasActiveSubscription = subscription?.status === 'active';

  // Fetch products when the membership step is active
  useEffect(() => {
    if (activeStep === 2) {
      fetchProducts();
    }
  }, [activeStep]);

  // Fetch subscription products
  const fetchProducts = async () => {
    try {
      setLoadingProducts(true);
      const productsData = await getStudentProducts();
      setProducts(productsData);
      setError(null);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to load subscription options. Please try again later.');
    } finally {
      setLoadingProducts(false);
    }
  };

  // Validate coupon code
  const validateCoupon = async () => {
    // This is a placeholder for actual coupon validation
    // In a real implementation, this would call an API endpoint
    try {
      const expectedCoupon = "STUDENT2024"; // This would come from the config
      
      // Simple validation - checking if the coupon matches the expected value
      if (couponCode.trim().toUpperCase() === expectedCoupon) {
        setCouponValid(true);
        setCouponMessage("Coupon applied successfully! You'll get 10% off your subscription.");
      } else {
        setCouponValid(false);
        setCouponMessage("Invalid coupon code. Please try again.");
      }
    } catch (error) {
      setCouponValid(false);
      setCouponMessage("Error validating coupon. Please try again.");
    }
  };

  const handleNext = () => {
    // Save the current step data before proceeding
    saveCurrentStepData();
    
    if (activeStep === steps.length - 1) {
      // This shouldn't be reachable since we have custom buttons for the last step
      navigate("/student");
      return;
    }
    
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Save the current step data to the database
  const saveCurrentStepData = () => {
    try {
      switch (activeStep) {
        case 0: // Personal Information
          updateProfile({
            step: 0,
            data: {
              phoneNumber,
              address
            }
          });
          break;
        case 1: // Educational Background
          updateProfile({
            step: 1,
            data: {
              school,
              degree,
              graduationYear,
              skills
            }
          });
          break;
        // Case 2 is handled by the final button actions
      }
    } catch (err) {
      console.error('Error saving student data:', err);
      setError('Failed to save your information. Please try again.');
    }
  };

  const handleSubscribe = async () => {
    if (!selectedPriceId) {
      setError("Please select a subscription plan");
      return;
    }

    try {
      // First, save all profile information and mark onboarding as complete
      updateProfile({
        step: 2,
        data: {} // No additional data needed for this step
      });
      
      // Add coupon code to subscription if valid
      const couponParam = couponValid ? couponCode.trim().toUpperCase() : undefined;
      
      // Then create the subscription
      const result = await createSubscription(selectedPriceId, couponParam);
      
      // Redirect to Stripe checkout
      if (result.checkoutUrl) {
        window.location.href = result.checkoutUrl;
      }
    } catch (err) {
      console.error('Error creating subscription:', err);
      setError('Failed to create subscription. Please try again later.');
    }
  };

  const handleSkipSubscription = async () => {
    try {
      // Save all profile information and mark onboarding as complete
      await completeStudentOnboarding();
      
      // Navigate to student dashboard
      navigate("/student");
    } catch (err) {
      console.error('Error completing onboarding:', err);
      setError('Failed to complete onboarding. Please try again.');
    }
  };

  const formatPrice = (price: { unit_amount: number, recurring: { interval: string } | null }) => {
    if (!price || !price.unit_amount) return '$0.00';
    const amount = price.unit_amount / 100;
    const interval = price.recurring?.interval || 'month';
    return `$${amount.toFixed(2)}/${interval === 'month' ? 'mo' : 'year'}`;
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                variant="outlined"
                multiline
                rows={3}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="School"
                variant="outlined"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Degree"
                variant="outlined"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Graduation Year"
                variant="outlined"
                value={graduationYear}
                onChange={(e) => setGraduationYear(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Skills"
                variant="outlined"
                multiline
                rows={3}
                placeholder="List your technical skills, separated by commas"
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
              />
            </Grid>
          </Grid>
        );
      case 2:
        if (isSubscriptionLoading || loadingProducts) {
          return (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          );
        }
        
        if (hasActiveSubscription) {
          return (
            <Alert severity="success" sx={{ mb: 3 }}>
              You already have an active subscription! Click Next to continue.
            </Alert>
          );
        }
        
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Select a Membership Plan
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                Choose a plan that fits your needs. You can change your subscription at any time.
              </Typography>
            </Grid>
            
            {products.map((product) => {
              const price = product.prices[0]; // Get the first price
              return (
                <Grid item xs={12} md={6} key={product.id}>
                  <Card 
                    variant="outlined" 
                    sx={{ 
                      height: '100%',
                      border: selectedPriceId === price.id ? 
                        `2px solid ${theme.palette.primary.main}` : undefined,
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      '&:hover': {
                        boxShadow: 2
                      }
                    }}
                    onClick={() => setSelectedPriceId(price.id)}
                  >
                    <CardContent>
                      <Stack 
                        direction="row" 
                        justifyContent="space-between" 
                        alignItems="center"
                        mb={2}
                      >
                        <Typography variant="h6">
                          {product.name}
                        </Typography>
                        {selectedPriceId === price.id && (
                          <CheckCircleIcon color="primary" />
                        )}
                      </Stack>
                      
                      <Typography variant="h5" color="primary" gutterBottom>
                        {formatPrice(price)}
                      </Typography>
                      
                      <Divider sx={{ my: 2 }} />
                      
                      <Typography variant="body2" paragraph>
                        {product.description || "No description available"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
            
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Have a coupon code?
                </Typography>
                <Stack direction="row" spacing={1}>
                  <TextField
                    size="small"
                    placeholder="Enter coupon code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalOfferIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button 
                    variant="outlined" 
                    onClick={validateCoupon}
                    disabled={!couponCode.trim()}
                  >
                    Apply
                  </Button>
                </Stack>
                
                <Collapse in={couponValid !== null}>
                  <Alert 
                    severity={couponValid ? "success" : "error"}
                    sx={{ mt: 1 }}
                    icon={couponValid ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
                  >
                    {couponMessage}
                  </Alert>
                </Collapse>
              </Box>
            </Grid>
            
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 1000, mx: 'auto' }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom align="center">
          Student Onboarding
        </Typography>
        
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        {renderStepContent(activeStep)}
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
          >
            Back
          </Button>
          
          <Box>
            {activeStep === steps.length - 1 ? (
              <>
                <Button
                  variant="outlined"
                  onClick={handleSkipSubscription}
                  sx={{ mr: 1 }}
                  disabled={isUpdating}
                >
                  Skip for Now
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubscribe}
                  disabled={isUpdating || isCreating || !selectedPriceId}
                >
                  {isCreating ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Subscribe"
                  )}
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={isUpdating}
              >
                {isUpdating ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Next"
                )}
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}; 