import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from 'config/firebase';
import { useAuth } from 'hooks/auth/useAuth';
import { Student } from '@freetech/models';
import { usePublicUserList } from 'hooks/user/usePublicUserList';
import { PublicUserInfoType } from '@freetech/models/user';

interface MentorInfo {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  profilePicture?: string;
}

interface CohortMember {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  profilePicture?: string;
}

interface CohortInfo {
  id: string;
  name: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  members: CohortMember[];
}

export const useStudentMentorship = () => {
  const { userInfo: user } = useAuth();
  const queryClient = useQueryClient();
  const { data: publicUserProfiles = [], isLoading: isPublicUsersLoading } = usePublicUserList();
  
  // Fetch current student's profile and cohortId/mentorId info
  const { data: studentData, isLoading: isStudentDataLoading } = useQuery({
    queryKey: ['student', 'profile', user?.id],
    queryFn: async (): Promise<Student | null> => {
      if (!user?.id || user.role !== 'student') {
        return null;
      }

      // Fetch student data
      const studentDoc = await getDoc(doc(db, 'users', user.id));
      if (!studentDoc.exists()) {
        return null;
      }

      return { ...studentDoc.data(), id: studentDoc.id } as Student;
    },
    enabled: !!user?.id && user.role === 'student',
  });
  
  // Fetch mentor info from public profiles
  const { data: mentorInfo, isLoading: isMentorLoading } = useQuery({
    queryKey: ['student', 'mentor', studentData?.assignedMentorId, publicUserProfiles.length],
    queryFn: (): MentorInfo | null => {
      if (!studentData?.assignedMentorId) {
        return null;
      }
      
      const mentorProfile = publicUserProfiles.find(
        profile => profile.id === studentData.assignedMentorId
      );
      
      if (!mentorProfile) {
        return null;
      }
      
      return {
        id: mentorProfile.id,
        firstName: mentorProfile.firstName,
        lastName: mentorProfile.lastName,
        displayName: mentorProfile.displayName,
        email: mentorProfile.email,
        profilePicture: mentorProfile.profilePicture,
      };
    },
    enabled: !!studentData?.assignedMentorId && publicUserProfiles.length > 0,
  });

  // Fetch cohort information
  const { data: cohortInfo, isLoading: isCohortLoading } = useQuery({
    queryKey: ['student', 'cohort', studentData?.cohortId, publicUserProfiles.length],
    queryFn: async (): Promise<CohortInfo | null> => {
      try {
        if (!studentData?.cohortId) {
          return null;
        }

        // Fetch cohort data
        const cohortDoc = await getDoc(doc(db, 'cohorts', studentData.cohortId));
        
        if (!cohortDoc.exists()) {
          return null;
        }

        const cohortData = cohortDoc.data();
        
        // Get cohort member information from public profiles rather than querying Firestore
        // Find all student profiles with matching cohortId from studentIds in the cohort
        const cohortMembers: CohortMember[] = [];
        
        // If the cohort has studentIds array, use it to filter public profiles
        if (cohortData.studentIds && Array.isArray(cohortData.studentIds)) {
          cohortData.studentIds.forEach(studentId => {
            const memberProfile = publicUserProfiles.find(profile => profile.id === studentId);
            if (memberProfile) {
              cohortMembers.push({
                id: memberProfile.id,
                firstName: memberProfile.firstName || '',
                lastName: memberProfile.lastName || '',
                displayName: memberProfile.displayName,
                email: memberProfile.email,
                profilePicture: memberProfile.profilePicture,
              });
            }
          });
        }
        
        return {
          id: cohortDoc.id,
          name: cohortData.name || `Cohort ${cohortDoc.id}`,
          description: cohortData.description,
          startDate: cohortData.startDate,
          endDate: cohortData.endDate,
          members: cohortMembers,
        };
      } catch (error) {
        console.error("Error fetching cohort information:", error);
        return null;
      }
    },
    enabled: !!studentData?.cohortId && publicUserProfiles.length > 0,
  });

  // Request mentor assistance mutation
  const requestMentorAssistanceMutation = useMutation({
    mutationFn: async (message: string) => {
      if (!user?.id || !mentorInfo?.id) {
        throw new Error('User or mentor information not available');
      }
      
      // Create assistance request in the database
      await updateDoc(doc(db, 'users', user.id), {
        pendingMentorAssistance: true,
        mentorAssistanceRequestedAt: new Date().toISOString(),
        mentorAssistanceMessage: message,
      });
      
      // TODO: Send notification to mentor (this would be integrated with your notification system)
      
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['student', 'mentor', user?.id] });
    },
  });

  return {
    mentorInfo,
    cohortInfo,
    isMentorLoading: isMentorLoading || isStudentDataLoading || isPublicUsersLoading,
    isCohortLoading: isCohortLoading || isStudentDataLoading || isPublicUsersLoading,
    isLoading: isMentorLoading || isCohortLoading || isStudentDataLoading || isPublicUsersLoading,
    requestMentorAssistance: requestMentorAssistanceMutation.mutateAsync,
    isRequestingAssistance: requestMentorAssistanceMutation.isPending,
  };
}; 