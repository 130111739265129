import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Alert,
  List,
  ListItem,
  AlertTitle,
} from "@mui/material";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import { useAuth } from "hooks/auth/useAuth";
import FreelancerTaxProfileStep from "./FreelancerTaxProfileStep";
import FreelancerDirectDepositStep from "./FreelancerDirectDepositStep";
import FreelancerVerificationStep from "./FreelancerVerificationStep";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VerifiedIcon from "@mui/icons-material/Verified";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LockIcon from "@mui/icons-material/Lock";

interface FreelancerPayrollOnboardingProps {
  onBeginSetup: () => void;
}

const FreelancerPayrollOnboarding: React.FC<
  FreelancerPayrollOnboardingProps
> = ({ onBeginSetup }) => {
  const { currentUser } = useAuth();
  const { freelancerProfile: freelancer } = useFreelancerProfile();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [hasStarted, setHasStarted] = useState(false);

  const handleNext = () => {
    setActiveStep((prev) => Math.min(prev + 1, 2));
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    // Determine initial step based on completion status
    if (!freelancer?.taxFormCompleted) {
      setActiveStep(0);
    } else if (!freelancer?.payrollProfileCompleted) {
      setActiveStep(1);
    } else {
      setActiveStep(2);
    }
  }, [freelancer]);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <FreelancerTaxProfileStep onNext={handleNext} />;

      case 1:
        return (
          <FreelancerDirectDepositStep
            onNext={handleNext}
            onBack={handleBack}
          />
        );

      case 2:
        return <FreelancerVerificationStep onBack={handleBack} />;

      default:
        return null;
    }
  };

  if (!hasStarted) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: "100%",
            maxWidth: "1400px",
            background: "linear-gradient(to bottom right, #ffffff, #f8f9fa)",
            p: { xs: 3, sm: 4 },
          }}
        >
          <Stack spacing={4} alignItems="center">
            <Box sx={{ width: "100%", maxWidth: 800, textAlign: "center" }}>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  background: "linear-gradient(45deg, #1976d2, #42a5f5)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
                }}
              >
                Set Up Your Payroll & Tax Profile
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.1rem", md: "1.25rem" },
                }}
              >
                Complete your tax forms and set up direct deposit payments
              </Typography>
              <Typography
                variant="body1"
                align="center"
                color="text.secondary"
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                }}
              >
                We'll guide you through completing your tax documentation (W-9
                or W-8BEN) and setting up secure direct deposit payments for
                your work.
              </Typography>
            </Box>

            <Grid container spacing={3} sx={{ maxWidth: 800, mx: "auto" }}>
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={1}
                  sx={{
                    p: 3,
                    border: "1px solid",
                    borderColor: "divider",
                    height: "100%",
                  }}
                >
                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AccountBalanceIcon
                        sx={{
                          mr: 1.5,
                          color: "primary.main",
                          fontSize: 28,
                        }}
                      />
                      <Typography variant="h6">Required Information</Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      For tax forms and direct deposit setup:
                    </Typography>
                    <List sx={{ pl: 1 }}>
                      {[
                        "Tax identification number (SSN/ITIN)",
                        "Bank routing number",
                        "Account number",
                        "Account holder name",
                        "Account type (checking/savings)",
                      ].map((item, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            py: 0.5,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CheckCircleIcon
                            sx={{
                              mr: 1,
                              fontSize: 20,
                              color: "success.main",
                            }}
                          />
                          <Typography>{item}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  elevation={1}
                  sx={{
                    p: 3,
                    border: "1px solid",
                    borderColor: "divider",
                    height: "100%",
                  }}
                >
                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <VerifiedIcon
                        sx={{
                          mr: 1.5,
                          color: "primary.main",
                          fontSize: 28,
                        }}
                      />
                      <Typography variant="h6">Security & Privacy</Typography>
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                      Your tax and banking information is protected with:
                    </Typography>
                    <List sx={{ pl: 1 }}>
                      {[
                        "Bank-level encryption",
                        "Secure data storage",
                        "Strict access controls",
                        "Regular security audits",
                      ].map((item, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            py: 0.5,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <LockIcon
                            sx={{
                              mr: 1,
                              fontSize: 20,
                              color: "success.main",
                            }}
                          />
                          <Typography>{item}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: "100%",
                maxWidth: 500,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Alert
                severity="info"
                sx={{
                  mb: 2,
                  width: "100%",
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
              >
                <AlertTitle>Setup Time: ~10 minutes</AlertTitle>
                Please have your tax and banking information ready. You'll need
                to complete both tax forms and direct deposit setup to receive
                payments.
              </Alert>

              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setHasStarted(true);
                    onBeginSetup();
                  }}
                  sx={{
                    mt: 2,
                    px: 4,
                    py: 1.5,
                    fontSize: "1.1rem",
                    boxShadow: 2,
                    "&:hover": {
                      boxShadow: 4,
                    },
                  }}
                  endIcon={<ArrowForwardIcon />}
                >
                  {freelancer?.taxFormCompleted ||
                  freelancer?.payrollProfileCompleted
                    ? "Continue Payroll Setup"
                    : "Begin Payroll Setup"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", mb: 4 }}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Tax Form</StepLabel>
          </Step>
          <Step>
            <StepLabel>Direct Deposit</StepLabel>
          </Step>
          <Step>
            <StepLabel>Verification</StepLabel>
          </Step>
        </Stepper>
      </Box>

      {renderStepContent()}
    </Box>
  );
};

export default FreelancerPayrollOnboarding;
