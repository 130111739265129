import { useEffect, useRef, useCallback, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useAuth } from "hooks/auth/useAuth";
import {
  WebSocketEvents,
  UserStatus,
  UserPing,
  OnlineUsers,
} from "@freetech/models";
import { useLocation } from "react-router-dom";
import { socketUrl } from "config";
import { getAuthToken } from "core/auth/getAuthToken";

export const useWebSocket = () => {
  const socketRef = useRef<Socket<WebSocketEvents>>();
  const { currentUser, userInfo } = useAuth();
  const location = useLocation();
  const [onlineUsers, setOnlineUsers] = useState<OnlineUsers>({});
  const [isConnecting, setIsConnecting] = useState(false);

  const connect = useCallback(async () => {
    if (!currentUser) return;
    if (!userInfo?.role) return;
    if (socketRef.current?.connected) return;
    if (isConnecting) return;

    console.log("socketUrl", socketUrl);

    try {
      setIsConnecting(true);
      const token = await getAuthToken();

      console.log("isConnecting", isConnecting);

      socketRef.current = io(socketUrl, {
        auth: { token },
        withCredentials: true,
        transports: ["websocket"],
        autoConnect: false,
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 20000,
      });

      console.log("socketRef.current", socketRef.current);

      const setupSocketEvents = () => {
        if (!socketRef.current) return;

        socketRef.current.on("connect", () => {
          setIsConnecting(false);
          if (currentUser.uid) {
            socketRef.current?.emit("register", currentUser.uid);
            setOnlineUsers((prev) => ({
              ...prev,
              [currentUser.uid]: {
                ...prev[currentUser.uid],
                online: true,
                lastPing: Date.now(),
              },
            }));
          }
        });

        socketRef.current.on("connect_error", () => {
          setIsConnecting(false);
        });

        socketRef.current.on("userStatus", ({ userId, status }: UserStatus) => {
          setOnlineUsers((prev) => ({
            ...prev,
            [userId]: {
              ...prev[userId],
              online: status === "online",
              lastPing:
                status === "online" ? Date.now() : prev[userId]?.lastPing,
            },
          }));
        });

        socketRef.current.on("initialUsers", (userIds: string[]) => {
          const timestamp = Date.now();
          setOnlineUsers((prev) => {
            const initialStatuses = userIds.reduce(
              (acc, userId) => ({
                ...acc,
                [userId]: {
                  ...prev[userId],
                  online: true,
                  lastPing: timestamp,
                },
              }),
              {} as OnlineUsers
            );
            return { ...prev, ...initialStatuses };
          });
        });

        socketRef.current.on("userPing", (pingData: UserPing) => {
          const { userId, timestamp, userRole, currentPage } = pingData;
          console.log("Received ping:", pingData);

          setOnlineUsers((prev) => ({
            ...prev,
            [userId]: {
              ...prev[userId],
              online: true,
              lastPing: timestamp,
              userRole: userRole || prev[userId]?.userRole,
              currentPage: currentPage || prev[userId]?.currentPage,
            },
          }));
        });

        socketRef.current.on("disconnect", () => {
          if (currentUser.uid) {
            setOnlineUsers((prev) => ({
              ...prev,
              [currentUser.uid]: {
                ...prev[currentUser.uid],
                online: false,
                lastPing: Date.now(),
              },
            }));
          }
          setIsConnecting(false);
        });
      };

      setupSocketEvents();
      socketRef.current.connect();
    } catch (error) {
      setIsConnecting(false);
    }
  }, [currentUser, userInfo?.role]);

  useEffect(() => {
    connect();
    return () => {
      if (socketRef.current) {
        socketRef.current.removeAllListeners();
        socketRef.current.disconnect();
      }
    };
  }, [connect]);

  const sendUserPing = useCallback(() => {
    if (!currentUser?.uid || !socketRef.current?.connected || !userInfo?.role)
      return;

    const ping: UserPing = {
      userId: currentUser.uid,
      timestamp: Date.now(),
      userRole: userInfo.role,
      currentPage: location.pathname,
    };
    console.log("Sending ping:", ping);

    socketRef.current.emit("userPing", ping);
  }, [currentUser?.uid, userInfo?.role, location.pathname]);

  // Send ping on location change
  useEffect(() => {
    if (socketRef.current?.connected) {
      sendUserPing();
    }
  }, [location.pathname, sendUserPing]);

  // Send ping every 30 seconds
  useEffect(() => {
    if (!socketRef.current?.connected) return;

    const interval = setInterval(sendUserPing, 30000);
    return () => clearInterval(interval);
  }, [sendUserPing]);

  return {
    sendUserPing,
    onlineUsers,
  };
};
