import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Paper,
  useTheme,
} from '@mui/material';
import { TimesheetExplorer } from './TimesheetExplorer/TimesheetExplorer';
import { TimesheetApprovals } from './TimesheetApprovals/TimesheetApprovals';
import { useNavigate, useLocation } from "react-router-dom";

// Tab configuration with IDs for URL hash navigation
const TAB_CONFIG = [
  { id: "timesheet-explorer", label: "Timesheet Explorer" },
  { id: "timesheet-approvals", label: "Timesheet Approvals" },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`timesheet-management-tabpanel-${index}`}
      aria-labelledby={`timesheet-management-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `timesheet-management-tab-${index}`,
    'aria-controls': `timesheet-management-tabpanel-${index}`,
  };
}

export const TimesheetManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);

  // Initialize tab based on URL hash
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    const tabIndex = TAB_CONFIG.findIndex(tab => tab.id === hash);
    if (tabIndex !== -1) {
      setCurrentTab(tabIndex);
    } else if (!location.hash && currentTab !== 0) {
      // If no hash is present, default to the first tab
      navigate(`#${TAB_CONFIG[0].id}`, { replace: true });
    }
  }, [location.hash, navigate]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    // Update URL hash when tab changes
    navigate(`#${TAB_CONFIG[newValue].id}`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        elevation={2}
        sx={{ 
          mb: 3,
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden'
        }}
      >
        <Box 
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            position: "relative",
          }}
        >
          <Tabs 
            value={currentTab} 
            onChange={handleTabChange}
            aria-label="timesheet management tabs"
            variant="standard"
            scrollButtons={false}
            TabIndicatorProps={{
              style: {
                transition: 'none', // Remove transition for immediate movement
              },
            }}
            sx={{
              position: "static",
              '& .MuiTabs-indicator': {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                transition: 'none', // Remove transition for immediate movement
              },
              '& .MuiTabs-flexContainer': {
                justifyContent: 'center',
                display: 'flex',
              },
              '& .MuiTab-root': {
                fontWeight: 500,
                textTransform: 'none',
                padding: '12px 16px',
                transition: 'color 0.2s', // Only transition the color, not position
                flexGrow: 1,
                maxWidth: 'none',
                '&:hover': {
                  color: theme.palette.primary.main,
                  opacity: 1,
                },
                [theme.breakpoints.up('md')]: {
                  minWidth: 0, // Allow tabs to size based on content
                },
              },
              '& .Mui-selected': {
                fontWeight: 600,
                color: theme.palette.primary.main,
              },
            }}
          >
            {TAB_CONFIG.map((tab, index) => (
              <Tab 
                key={tab.id} 
                label={tab.label} 
                {...a11yProps(index)} 
                sx={{
                  flexShrink: 0,
                  flexGrow: 1,
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Paper>

      <TabPanel value={currentTab} index={0}>
        <TimesheetExplorer />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <TimesheetApprovals />
      </TabPanel>
    </Box>
  );
}; 