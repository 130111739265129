import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Skeleton,
} from '@mui/material';
import {
  AttachMoney as AttachMoneyIcon,
  AccountBalance as AccountBalanceIcon,
  Money as MoneyIcon,
  Percent as PercentIcon,
  OpenInNew as OpenInNewIcon,
  CreditCard as CreditCardIcon
} from '@mui/icons-material';
import { useAdminFinancialHub } from 'hooks/admin/useAdminFinancialHub';
import SwipeableViews from 'react-swipeable-views';
import { DateTime } from 'luxon';

// TabPanel component for finance tabs
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`finance-tabpanel-${index}`}
      aria-labelledby={`finance-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

// Loading placeholder card
const BalanceCardLoader = () => (
  <Card>
    <CardContent>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="text" width={120} />
      </Box>
      <Skeleton variant="text" width={80} height={40} />
      <Skeleton variant="text" width={140} />
    </CardContent>
  </Card>
);

// Transaction table loading placeholder
const TableLoader = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="rectangular" height={50} animation="wave" sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" height={400} animation="wave" />
  </Box>
);

const FinancialTab: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [financeTabValue, setFinanceTabValue] = useState(0);
  const [timeRange, setTimeRange] = useState<"7d" | "30d" | "90d" | "1y">("30d");
  const [transactionsToShow, setTransactionsToShow] = useState(25);
  
  const { 
    formatCurrency,
    financialSummary,
    combinedTransactions,
    stripeProducts,
    stripeSubscriptions,
    stripeTransactions,
    accountBalances,
    generateChartData,
    isLoadingStripeProducts,
    isLoadingStripeSubscriptions,
    isLoadingStripeTransactions,
    isLoadingIncreaseTransactions,
    isLoadingAccountBalances,
    isLoading,
    error
  } = useAdminFinancialHub();

  // Create a local function for combined transactions since it's not in the hook
  const getCombinedTransactions = () => {
    return combinedTransactions;
  };

  // Create a local function for financial summary calculation
  const calculateFinancialSummary = () => {
    return financialSummary;
  };

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setFinanceTabValue(newValue);
  };

  // Handle swipe change
  const handleSwipeChangeIndex = (index: number) => {
    setFinanceTabValue(index);
  };

  // Handle time range change
  const handleTimeRangeChange = (range: "7d" | "30d" | "90d" | "1y") => {
    setTimeRange(range);
  };

  // Function to load more transactions
  const handleLoadMoreTransactions = () => {
    setTransactionsToShow(prev => prev + 25);
  };

  // Function to get transaction type display name
  const getTransactionTypeDisplay = (type: string): string => {
    switch (type) {
      case "charge":
        return "Charge";
      case "payment":
        return "Payment";
      case "refund":
        return "Refund";
      case "payout":
        return "Payout";
      case "payment_refund":
        return "Payment Refund";
      case "stripe_fee":
        return "Stripe Fee";
      default:
        return type.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase());
    }
  };

  // Render transaction amount with color based on type
  const renderTransactionAmount = (amount: number, type: string) => {
    const isNegative = amount < 0;
    const displayAmount = isNegative ? -amount : amount;
    
    let color = "text.primary";
    
    if (type === "charge" || type === "payment" || type === "inbound") {
      color = "success.main";
    } else if (type === "refund" || type === "payment_refund" || type === "outbound") {
      color = "error.main";
    } else if (type === "stripe_fee") {
      color = "warning.main";
    }

    return (
      <Typography variant="body2" sx={{ color, fontWeight: "medium" }}>
        {isNegative ? "-" : ""}
        {formatCurrency(displayAmount)}
      </Typography>
    );
  };

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography color="error">
          Error loading financial data. Please try again later.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {error instanceof Error ? error.message : "Unknown error"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Financial Overview Section */}
      <Grid container spacing={3}>
        {/* Key Metrics */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Financial Overview</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <AccountBalanceIcon sx={{ mr: 1, color: "primary.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Net Balance
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div" sx={{ color: "success.main" }}>
                      {formatCurrency(financialSummary.netBalance)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Total inbound minus outbound
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <MoneyIcon sx={{ mr: 1, color: "success.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Total Inbound
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div" sx={{ color: "success.main" }}>
                      {formatCurrency(financialSummary.totalInbound)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      All incoming transactions
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <MoneyIcon sx={{ mr: 1, color: "error.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Total Outbound
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div" sx={{ color: "error.main" }}>
                      {formatCurrency(financialSummary.totalOutbound)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      All outgoing transactions
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <BalanceCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PercentIcon sx={{ mr: 1, color: "warning.main" }} />
                      <Typography variant="subtitle2" color="text.secondary">
                        Pending Transactions
                      </Typography>
                    </Box>
                    <Typography variant="h4" component="div">
                      {formatCurrency(financialSummary.totalPending)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Not yet settled
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Tabs for Different Financial Views */}
        <Grid item xs={12}>
          <Paper sx={{ width: '100%', marginTop: 3 }}>
            <Tabs
              value={financeTabValue}
              onChange={handleTabChange}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile ? "auto" : false}
              aria-label="financial tabs"
            >
              <Tab icon={<AttachMoneyIcon />} label="Transactions" />
              <Tab icon={<CreditCardIcon />} label="Stripe" />
              <Tab icon={<AccountBalanceIcon />} label="Accounts" />
            </Tabs>

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={financeTabValue}
              onChangeIndex={handleSwipeChangeIndex}
              style={{ backgroundColor: theme.palette.background.paper }}
            >
              {/* Transactions Tab */}
              <TabPanel value={financeTabValue} index={0}>
                <Box>
                  <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Recent Transactions</Typography>
                    <Box>
                      <Button 
                        variant={timeRange === "7d" ? "contained" : "outlined"} 
                        size="small"
                        onClick={() => handleTimeRangeChange("7d")}
                        sx={{ mx: 0.5 }}
                      >
                        7 Days
                      </Button>
                      <Button 
                        variant={timeRange === "30d" ? "contained" : "outlined"} 
                        size="small"
                        onClick={() => handleTimeRangeChange("30d")}
                        sx={{ mx: 0.5 }}
                      >
                        30 Days
                      </Button>
                      <Button 
                        variant={timeRange === "90d" ? "contained" : "outlined"} 
                        size="small"
                        onClick={() => handleTimeRangeChange("90d")}
                        sx={{ mx: 0.5 }}
                      >
                        90 Days
                      </Button>
                      <Button 
                        variant={timeRange === "1y" ? "contained" : "outlined"} 
                        size="small"
                        onClick={() => handleTimeRangeChange("1y")}
                        sx={{ mx: 0.5 }}
                      >
                        1 Year
                      </Button>
                    </Box>
                  </Box>
                  
                  {isLoading ? (
                    <TableLoader />
                  ) : getCombinedTransactions() && getCombinedTransactions().length > 0 ? (
                    <>
                      <TableContainer sx={{ maxHeight: 500 }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Source</TableCell>
                              <TableCell align="right">Amount</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {getCombinedTransactions().slice(0, transactionsToShow).map((transaction: any) => (
                              <TableRow key={transaction.id} hover>
                                <TableCell>
                                  {transaction.date 
                                    ? DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_MED) 
                                    : 'N/A'}
                                </TableCell>
                                <TableCell>
                                  {transaction.description || 'N/A'}
                                </TableCell>
                                <TableCell>
                                  {transaction.type 
                                    ? getTransactionTypeDisplay(transaction.type) 
                                    : transaction.direction === 'inbound' ? 'Incoming' : 'Outgoing'}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    label={transaction.source === 'stripe' ? 'Stripe' : 'Bank'}
                                    size="small"
                                    color={transaction.source === 'stripe' ? 'info' : 'primary'}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  {renderTransactionAmount(
                                    transaction.amount, 
                                    transaction.type || transaction.direction
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    label={transaction.status}
                                    size="small"
                                    color={
                                      transaction.status === 'complete' || transaction.status === 'succeeded' 
                                        ? 'success' 
                                        : transaction.status === 'pending' 
                                          ? 'warning' 
                                          : 'default'
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {getCombinedTransactions().length > transactionsToShow && (
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                          <Button 
                            variant="outlined" 
                            onClick={handleLoadMoreTransactions}
                          >
                            Load More Transactions
                          </Button>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Typography color="text.secondary" sx={{ py: 3, textAlign: "center" }}>
                      No transactions available
                    </Typography>
                  )}
                </Box>
              </TabPanel>

              {/* Stripe Tab */}
              <TabPanel value={financeTabValue} index={1}>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Stripe Overview
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Active Subscriptions
                          </Typography>
                          <Typography variant="h4" color="primary.main">
                            {stripeSubscriptions?.filter(sub => sub.status === 'active').length || 0}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Active Products
                          </Typography>
                          <Typography variant="h4" color="primary.main">
                            {stripeProducts?.filter(product => product.active).length || 0}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Stripe Revenue
                          </Typography>
                          <Typography variant="h4" sx={{ color: "success.main" }}>
                            {formatCurrency(financialSummary.stripeInbound)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Card>
                        <CardContent>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Stripe Expenses
                          </Typography>
                          <Typography variant="h4" sx={{ color: "error.main" }}>
                            {formatCurrency(financialSummary.stripeOutbound)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  
                  <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    Active Subscriptions
                  </Typography>
                  
                  {isLoadingStripeSubscriptions ? (
                    <Grid container spacing={2}>
                      {[1, 2, 3, 4].map(i => (
                        <Grid item xs={12} md={6} lg={3} key={i}>
                          <Skeleton variant="rectangular" height={140} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : stripeSubscriptions && stripeSubscriptions.filter(sub => sub.status === 'active').length > 0 ? (
                    <Grid container spacing={2}>
                      {stripeSubscriptions
                        .filter(sub => sub.status === 'active')
                        .slice(0, 4)
                        .map(subscription => (
                          <Grid item xs={12} md={6} lg={3} key={subscription.id}>
                            <Card>
                              <CardContent>
                                <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                                  {subscription.items[0]?.product?.name || 'Unknown Product'}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                  {subscription.customer.name || subscription.customer.email || 'Unknown'}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography variant="body2" color="text.secondary">
                                    Amount:
                                  </Typography>
                                  <Typography variant="body2" fontWeight="medium" color="primary.main">
                                    {formatCurrency(
                                      (Array.isArray(subscription.items)
                                        ? subscription.items.reduce((sum, item: any) => sum + (item.price?.unit_amount || 0), 0)
                                        : ((subscription.items as any)?.price?.unit_amount || 0)
                                      ) / 100
                                    )}/mo
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography variant="body2" color="text.secondary">
                                    Next bill:
                                  </Typography>
                                  <Typography variant="body2" fontWeight="medium">
                                    {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    <Typography color="text.secondary" sx={{ py: 2, textAlign: "center" }}>
                      No active subscriptions
                    </Typography>
                  )}
                  
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      endIcon={<OpenInNewIcon />}
                      component="a"
                      href="https://dashboard.stripe.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Stripe Dashboard
                    </Button>
                  </Box>
                </Box>
              </TabPanel>

              {/* Bank Accounts Tab */}
              <TabPanel value={financeTabValue} index={2}>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Bank Accounts Overview
                  </Typography>
                  
                  {isLoadingAccountBalances ? (
                    <Grid container spacing={2}>
                      {[1, 2, 3].map(i => (
                        <Grid item xs={12} md={4} key={i}>
                          <Skeleton variant="rectangular" height={180} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : accountBalances && accountBalances.accounts ? (
                    <Grid container spacing={2}>
                      {accountBalances.accounts.map(account => (
                        <Grid item xs={12} md={4} key={account.id}>
                          <Card key={account.id} sx={{ height: '100%' }}>
                            <CardContent>
                              <Typography variant="subtitle1" fontWeight="medium">
                                {account.name || 'Account'}
                              </Typography>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Typography variant="body2" color="text.secondary">
                                  Available:
                                </Typography>
                                <Typography variant="body1" fontWeight="medium" color="success.main">
                                  {formatCurrency(account.availableBalance || 0)}
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary">
                                  Current:
                                </Typography>
                                <Typography variant="body1" fontWeight="medium" color="warning.main">
                                  {formatCurrency(account.currentBalance || 0)}
                                </Typography>
                              </Box>
                              <Divider sx={{ my: 1 }} />
                              <Typography variant="caption" color="text.secondary">
                                {account.status?.toUpperCase()} • {account.currency?.toUpperCase()}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography color="text.secondary" sx={{ py: 2, textAlign: "center" }}>
                      No bank accounts found
                    </Typography>
                  )}
                  
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      endIcon={<OpenInNewIcon />}
                      component="a"
                      href="https://dashboard.increase.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Increase Dashboard
                    </Button>
                  </Box>
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialTab; 