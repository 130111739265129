import React, { useState, useMemo } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
  SelectChangeEvent,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAdminFinancialHub } from "hooks/admin/useAdminFinancialHub";
import RecentTransactionsTable from "./RecentTransactionsTable";
import { DateTime } from "luxon";

// Loading placeholder for section
const TableLoader = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="rectangular" height={50} animation="wave" sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" height={400} animation="wave" />
  </Box>
);

const AllTransactionsTab: React.FC = () => {
  const { 
    combinedTransactions,
    timeRange, 
    setTimeRange, 
    isLoadingIncreaseTransactions,
    isLoadingStripeTransactions,
    formatCurrency
  } = useAdminFinancialHub();
  
  // Filter states
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [directionFilter, setDirectionFilter] = useState<string>("all");
  const [routeFilter, setRouteFilter] = useState<string>("all");
  const [sourceFilter, setSourceFilter] = useState<string>("all");
  const [showFilters, setShowFilters] = useState(false);

  // Handle filter changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value);
  };

  const handleDirectionFilterChange = (event: SelectChangeEvent<string>) => {
    setDirectionFilter(event.target.value);
  };

  const handleRouteFilterChange = (event: SelectChangeEvent<string>) => {
    setRouteFilter(event.target.value);
  };

  const handleSourceFilterChange = (event: SelectChangeEvent<string>) => {
    setSourceFilter(event.target.value);
  };

  const handleTimeRangeChange = (
    event: React.MouseEvent<HTMLElement>,
    newTimeRange: "7d" | "30d" | "90d" | "1y" | null
  ) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const clearFilters = () => {
    setSearchTerm("");
    setStatusFilter("all");
    setDirectionFilter("all");
    setRouteFilter("all");
    setSourceFilter("all");
  };

  // Get unique routes for the filter dropdown
  const uniqueRoutes = useMemo(() => {
    if (!combinedTransactions) return [];
    
    const routes = new Set<string>();
    
    combinedTransactions.forEach(transaction => {
      if (transaction.route) {
        routes.add(transaction.route);
      }
    });
    
    return Array.from(routes).sort();
  }, [combinedTransactions]);

  // Check for loading state
  const isLoading = isLoadingIncreaseTransactions || isLoadingStripeTransactions;

  // Apply filters to transactions
  const filteredTransactions = useMemo(() => {
    if (!combinedTransactions) return [];
    
    return combinedTransactions.filter(transaction => {
      // Search term filter (search in description or ID)
      const matchesSearch = 
        searchTerm === "" || 
        transaction.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction.id.toLowerCase().includes(searchTerm.toLowerCase());
      
      // Status filter
      const matchesStatus = 
        statusFilter === "all" || 
        transaction.status === statusFilter;
      
      // Direction filter
      const matchesDirection = 
        directionFilter === "all" || 
        transaction.direction === directionFilter;
      
      // Route filter
      const matchesRoute = 
        routeFilter === "all" || 
        transaction.route === routeFilter;
        
      // Source filter
      const matchesSource = 
        sourceFilter === "all" || 
        transaction.source === sourceFilter;
      
      return matchesSearch && matchesStatus && matchesDirection && matchesRoute && matchesSource;
    });
  }, [combinedTransactions, searchTerm, statusFilter, directionFilter, routeFilter, sourceFilter]);

  // Calculate summary statistics
  const summaryStats = useMemo(() => {
    if (!filteredTransactions.length) {
      return {
        total: 0,
        inbound: 0,
        outbound: 0,
        pending: 0,
        complete: 0,
        failed: 0,
        stripe: 0,
        increase: 0
      };
    }
    
    return filteredTransactions.reduce((stats, transaction) => {
      // Count by status
      if (transaction.status === "pending") stats.pending++;
      if (transaction.status === "complete") stats.complete++;
      if (transaction.status === "failed") stats.failed++;
      
      // Count by source
      if (transaction.source === "stripe") stats.stripe++;
      if (transaction.source === "increase") stats.increase++;
      
      // Count by direction and sum amounts
      if (transaction.direction === "inbound") {
        stats.inbound += transaction.amount;
      } else {
        stats.outbound += transaction.amount;
      }
      
      stats.total++;
      return stats;
    }, {
      total: 0,
      inbound: 0,
      outbound: 0,
      pending: 0,
      complete: 0,
      failed: 0,
      stripe: 0,
      increase: 0
    });
  }, [filteredTransactions]);

  return (
    <Box>
      {/* Time Range Filter and Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Typography variant="h5">All Transactions</Typography>
        <ToggleButtonGroup
          value={timeRange}
          exclusive
          onChange={handleTimeRangeChange}
          size="small"
        >
          <ToggleButton value="7d">7d</ToggleButton>
          <ToggleButton value="30d">30d</ToggleButton>
          <ToggleButton value="90d">90d</ToggleButton>
          <ToggleButton value="1y">1y</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Search and Filter Bar */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <TextField
            label="Search transactions"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ flexGrow: 1, mr: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search by description or ID"
          />
          <Button 
            variant={showFilters ? "contained" : "outlined"} 
            onClick={toggleFilters}
            startIcon={<FilterListIcon />}
          >
            {showFilters ? "Hide Filters" : "Show Filters"}
          </Button>
        </Box>

        {showFilters && (
          <>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={statusFilter}
                    label="Status"
                    onChange={handleStatusFilterChange}
                  >
                    <MenuItem value="all">All Statuses</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="complete">Complete</MenuItem>
                    <MenuItem value="failed">Failed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Direction</InputLabel>
                  <Select
                    value={directionFilter}
                    label="Direction"
                    onChange={handleDirectionFilterChange}
                  >
                    <MenuItem value="all">All Directions</MenuItem>
                    <MenuItem value="inbound">Inbound</MenuItem>
                    <MenuItem value="outbound">Outbound</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Route</InputLabel>
                  <Select
                    value={routeFilter}
                    label="Route"
                    onChange={handleRouteFilterChange}
                  >
                    <MenuItem value="all">All Routes</MenuItem>
                    {uniqueRoutes.map(route => (
                      <MenuItem key={route} value={route}>
                        {route}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Source</InputLabel>
                  <Select
                    value={sourceFilter}
                    label="Source"
                    onChange={handleSourceFilterChange}
                  >
                    <MenuItem value="all">All Sources</MenuItem>
                    <MenuItem value="increase">Bank (Increase)</MenuItem>
                    <MenuItem value="stripe">Stripe</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={clearFilters} size="small">
                Clear Filters
              </Button>
            </Box>
          </>
        )}
      </Paper>

      {/* Summary Stats */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="body2" color="text.secondary">
              Total Transactions
            </Typography>
            <Typography variant="h6">
              {isLoading ? <Skeleton width={50} /> : summaryStats.total}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="body2" color="text.secondary">
              Inbound
            </Typography>
            <Typography variant="h6" color="success.main">
              {isLoading ? <Skeleton width={80} /> : formatCurrency(summaryStats.inbound)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="body2" color="text.secondary">
              Outbound
            </Typography>
            <Typography variant="h6" color="error.main">
              {isLoading ? <Skeleton width={80} /> : formatCurrency(summaryStats.outbound)}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="body2" color="text.secondary">
              Pending
            </Typography>
            <Typography variant="h6">
              {isLoading ? <Skeleton width={50} /> : (
                <Chip 
                  size="small" 
                  label={summaryStats.pending} 
                  color="warning" 
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="body2" color="text.secondary">
              Bank
            </Typography>
            <Typography variant="h6">
              {isLoading ? <Skeleton width={50} /> : summaryStats.increase}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="body2" color="text.secondary">
              Stripe
            </Typography>
            <Typography variant="h6">
              {isLoading ? <Skeleton width={50} /> : summaryStats.stripe}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Transactions Table */}
      <Paper sx={{ p: 0 }}>
        {isLoading ? (
          <TableLoader />
        ) : filteredTransactions.length > 0 ? (
          <RecentTransactionsTable 
            transactions={filteredTransactions} 
            maxHeight={600} 
          />
        ) : (
          <Box sx={{ p: 4, textAlign: "center" }}>
            <Typography color="text.secondary">
              No transactions match the current filters
            </Typography>
            <Button onClick={clearFilters} sx={{ mt: 2 }}>
              Clear All Filters
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default AllTransactionsTab; 