import React, { useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Typography,
  Avatar,
  Tooltip,
} from "@mui/material";
import { DateTime } from "luxon";
import { useManageUsers } from "hooks/admin/useManageUsers";
import { CombinedUserData } from "@freetech/models/user";
import StripeIcon from "@mui/icons-material/Payment";
import BankIcon from "@mui/icons-material/AccountBalance";

// Combined transaction interface that works with both Increase and Stripe
interface CombinedTransaction {
  id: string;
  date: string;
  amount: number;
  description: string | null;
  status: string;
  direction: "inbound" | "outbound";
  source: "increase" | "stripe";
  type?: string;
  category?: string;
  route?: string;
  metadata?: Record<string, any>;
  [key: string]: any; // Allow any additional properties
}

// Extended transaction interface with user data
interface TransactionWithUser extends CombinedTransaction {
  user?: CombinedUserData;
  client?: CombinedUserData;
}

interface RecentTransactionsTableProps {
  transactions: CombinedTransaction[];
  maxHeight?: number;
}

const RecentTransactionsTable: React.FC<RecentTransactionsTableProps> = ({
  transactions,
  maxHeight = 400,
}) => {
  // Get users data
  const { authUsers, privateUsers, authUsersLoading } = useManageUsers();

  // Sort and combine users and transactions data
  const transactionsWithUsers = useMemo(() => {
    if (!transactions || !authUsers) return [] as TransactionWithUser[];

    // First map transactions to add user data
    const mappedTransactions = transactions.map((transaction) => {
      // Check for related user in metadata - we get userId from metadata only since it's
      // not guaranteed to be on the transaction object directly
      const userId = transaction.metadata?.userId;
      const clientId = transaction.metadata?.clientId;
      
      // Find associated user - using id instead of uid
      const associatedUser = userId ? authUsers.find(user => user.id === userId) : undefined;
      const associatedClient = clientId ? authUsers.find(user => user.id === clientId) : undefined;
      
      return {
        ...transaction,
        user: associatedUser,
        client: associatedClient
      } as TransactionWithUser;
    });
    
    // Then sort by date (newest first)
    return mappedTransactions.sort((a, b) => {
      const dateA = a.date ? DateTime.fromISO(a.date) : DateTime.fromMillis(0);
      const dateB = b.date ? DateTime.fromISO(b.date) : DateTime.fromMillis(0);
      return dateB.toMillis() - dateA.toMillis();
    });
  }, [transactions, authUsers]);

  // Helper function to format date
  const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) return "-";
    try {
      return DateTime.fromISO(dateString).toFormat("LLL dd, yyyy");
    } catch (e) {
      return dateString || "-";
    }
  };

  // Helper function to format currency
  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
    }).format(amount);
  };

  // Helper to get status chip
  const getStatusChip = (status: string) => {
    let color: "success" | "error" | "warning" | "default" = "default";
    
    switch (status) {
      case "complete":
        color = "success";
        break;
      case "pending":
        color = "warning";
        break;
      case "failed":
        color = "error";
        break;
      default:
        color = "default";
    }
    
    return (
      <Chip
        label={status}
        size="small"
        color={color}
        sx={{ textTransform: "capitalize" }}
      />
    );
  };

  // Helper to get direction chip
  const getDirectionChip = (direction: string) => {
    const isInbound = direction === "inbound";
    
    return (
      <Chip
        label={direction}
        size="small"
        color={isInbound ? "success" : "error"}
        sx={{ textTransform: "capitalize" }}
      />
    );
  };

  // Helper to get source icon
  const getSourceIcon = (source: string) => {
    switch (source) {
      case "stripe":
        return <StripeIcon fontSize="small" />;
      case "increase":
      default:
        return <BankIcon fontSize="small" />;
    }
  };

  // Helper to render user information
  const renderUser = (user?: CombinedUserData) => {
    if (!user) return null;
    
    // Access properties safely
    const displayName = user.displayName || '';
    const email = user.email || '';
    // Use profilePicture which is correctly defined in the CombinedUserData interface
    const profilePicture = user.profilePicture || '';
    
    return (
      <Tooltip title={`${displayName || 'Unknown'} (${email || 'No email'})`}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar 
            src={profilePicture} 
            alt={displayName} 
            sx={{ width: 24, height: 24, mr: 1, fontSize: '0.75rem' }}
          >
            {displayName ? displayName[0] : '?'}
          </Avatar>
          <Typography variant="body2" noWrap sx={{ maxWidth: 100 }}>
            {displayName || email || 'Unknown user'}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  // Helper to render description based on transaction source
  const renderDescription = (transaction: TransactionWithUser) => {
    if (transaction.source === "stripe") {
      return (
        <>
          <Typography
            variant="body2"
            sx={{
              maxWidth: 250,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textDecoration: transaction.status === "failed" ? "line-through" : "none",
              color: transaction.status === "failed" ? "text.disabled" : "text.primary",
            }}
          >
            {transaction.description || `Stripe ${transaction.type || "Payment"}`}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            STRIPE • {transaction.type || "payment"}
          </Typography>
        </>
      );
    }
    
    // Default for Increase transactions
    return (
      <>
        <Typography
          variant="body2"
          sx={{
            maxWidth: 250,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textDecoration: transaction.status === "failed" ? "line-through" : "none",
            color: transaction.status === "failed" ? "text.disabled" : "text.primary",
          }}
        >
          {transaction.description || 
           `${transaction.route?.toUpperCase() || "BANK"} Transaction ${transaction.id.substring(0, 8)}`}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {transaction.route?.toUpperCase() || "BANK"} • {transaction.category || "transaction"}
        </Typography>
      </>
    );
  };

  if (authUsersLoading) {
    return (
      <Box sx={{ textAlign: "center", p: 3 }}>
        <Typography variant="body1" color="text.secondary">
          Loading transaction users...
        </Typography>
      </Box>
    );
  }

  if (!transactions || transactions.length === 0) {
    return (
      <Box sx={{ textAlign: "center", p: 3 }}>
        <Typography variant="body1" color="text.secondary">
          No transactions found
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight, overflow: "auto" }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>User/Client</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionsWithUsers.map((transaction: TransactionWithUser) => (
            <TableRow
              key={transaction.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: transaction.status === "failed" ? "rgba(0, 0, 0, 0.04)" : "inherit",
              }}
            >
              <TableCell>
                {formatDate(transaction.date)}
              </TableCell>
              <TableCell>
                {renderDescription(transaction)}
              </TableCell>
              <TableCell>
                <Tooltip title={transaction.source === "stripe" ? "Stripe" : "Bank"}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {getSourceIcon(transaction.source)}
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell>
                {transaction.relatedEntityType === "freelancer" 
                  ? (
                    <Box>
                      <Typography variant="caption" color="text.secondary">User:</Typography>
                      {renderUser(transaction.user)}
                      {transaction.client && (
                        <>
                          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                            Client:
                          </Typography>
                          {renderUser(transaction.client)}
                        </>
                      )}
                    </Box>
                  ) 
                  : renderUser(transaction.user)
                }
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      transaction.direction === "inbound"
                        ? "success.main"
                        : "error.main",
                    fontWeight: "bold",
                  }}
                >
                  {transaction.direction === "inbound" ? "+" : "-"}
                  {formatCurrency(transaction.amount)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {getStatusChip(transaction.status)}
              </TableCell>
              <TableCell align="center">
                {getDirectionChip(transaction.direction)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecentTransactionsTable; 