import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { Client } from "../projects";

export const clientConverter: FirestoreDataConverter<Client> = {
  toFirestore(client: WithFieldValue<Client>): DocumentData {
    // Create a base object with required fields
    const firestoreData: DocumentData = {
      companyName: client.companyName,
      location: client.location,
      size: client.size,
      status: client.status,
      introductionDate: client.introductionDate,
      createdByUserId: client.createdByUserId,
      legalOwnerFirebaseId: client.legalOwnerFirebaseId || "",
      companyPhysicalAddress: client.companyPhysicalAddress || {
        street: "",
        city: "",
        state: "",
        zip: "",
      },
    };

    // Add optional fields only if they are defined
    if (client.pointOfContactName)
      firestoreData.pointOfContactName = client.pointOfContactName;
    if (client.email) firestoreData.email = client.email;
    if (client.phone) firestoreData.phone = client.phone;
    if (client.associatedFreelancerIds)
      firestoreData.associatedFreelancerIds = client.associatedFreelancerIds;
    if (client.associatedStakeholderIds)
      firestoreData.associatedStakeholderIds = client.associatedStakeholderIds;

    return firestoreData;
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Client {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      createdByUserId: data?.createdByUserId || "",
      legalOwnerFirebaseId: data?.legalOwnerFirebaseId || "",
      companyName: data?.companyName || "",
      logoUrl: data?.logoUrl || "",
      pointOfContactName: data?.pointOfContactName || "",
      email: data?.email || "",
      phone: data?.phone || "",
      location: data?.location || "",
      size: data?.size || "",
      status: data?.status || "opportunity",
      introductionDate: data?.introductionDate || new Date().toISOString(),
      associatedFreelancerIds: data?.associatedFreelancerIds || [],
      associatedStakeholderIds: data?.associatedStakeholderIds || [],
      companyPhysicalAddress: data?.companyPhysicalAddress || {
        street: "",
        city: "",
        state: "",
        zip: "",
      },
    };
  },
};
