import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Avatar,
  Stack,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CodeIcon from "@mui/icons-material/Code";
import { Board } from "@freetech/models/freelo";
import { PublicUserInfoType } from "@freetech/models/user";

interface FreeloFreelancerHeaderProps {
  boards: Board[];
  userInfo: PublicUserInfoType;
  users?: PublicUserInfoType[];
}

export const FreeloFreelancerHeader: React.FC<FreeloFreelancerHeaderProps> = ({
  boards,
  userInfo,
  users,
}) => {
  const getAssignedTasks = () => {
    let totalTasks = 0;
    let completedTasks = 0;

    boards.forEach((board) => {
      board.lists?.forEach((list) => {
        list.cards?.forEach((card) => {
          if (card.assignee === userInfo.id) {
            totalTasks++;
            if (list.name === "Done") {
              completedTasks++;
            }
          }
        });
      });
    });

    return { totalTasks, completedTasks };
  };

  const { totalTasks, completedTasks } = getAssignedTasks();

  return (
    <Paper
      elevation={0}
      sx={{ p: 3, mb: 4, bgcolor: "background.default", borderRadius: 2 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Welcome back, {userInfo.firstName}!
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Track your assigned tasks and collaborate with your team using
            Freelo boards.
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <AssignmentIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Your Tasks</Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ p: 2, flex: 1 }}>
              <Typography variant="h4" gutterBottom>
                {totalTasks}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Assigned Tasks
              </Typography>
            </Paper>
            <Paper sx={{ p: 2, flex: 1 }}>
              <Typography variant="h4" color="success.main" gutterBottom>
                {completedTasks}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Completed Tasks
              </Typography>
            </Paper>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <DashboardIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Your Boards</Typography>
          </Box>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              {
                boards.filter(
                  (board) =>
                    board.readUserIds?.includes(userInfo.id) ||
                    board.writeUserIds?.includes(userInfo.id)
                ).length
              }
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Boards You Have Access To
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <CodeIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">MCP Integration</Typography>
          </Box>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
                FreeTech uses the Model Context Protocol (MCP) to align software development with client requirements. Your Freelo cards directly inform AI-assisted coding.
              </Typography>
              <Divider sx={{ my: 1.5 }} />
              <Typography variant="body2" color="text.secondary">
                Keep your cards detailed and up-to-date to help our AI tools produce better code.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};
