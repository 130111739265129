import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Chip,
  Skeleton,
  Tabs,
  Tab,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BugReportIcon from "@mui/icons-material/BugReport";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { IFeatureRequest } from "@freetech/models";
import { IBugReport, BugReportAttachment } from "@freetech/models/bugReport";
import ViewFileDialog from "components/ViewFileDialog";
import { useBugReport, ALLOWED_FILE_TYPES } from "hooks/bugReport/useBugReport";
import { useNavigate } from "react-router-dom";
import { RequestCard } from "./RequestCard";

interface ProjectOverviewFeaturesAndBugsProps {
  featureRequests: IFeatureRequest[] | undefined;
  bugReports: IBugReport[] | undefined;
  isFeatureRequestsLoading: boolean;
  isBugReportsLoading: boolean;
  projectId: string;
  onOpenFeatureRequestDialog: () => void;
  onOpenBugReportDialog: () => void;
}

export const ProjectOverviewFeaturesAndBugs: React.FC<ProjectOverviewFeaturesAndBugsProps> = ({
  featureRequests,
  bugReports,
  isFeatureRequestsLoading,
  isBugReportsLoading,
  projectId,
  onOpenFeatureRequestDialog,
  onOpenBugReportDialog,
}) => {
  const { updateAttachments } = useBugReport();
  const [activeTab, setActiveTab] = useState<"features" | "bugs">("features");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([]);
  const [visibleItems, setVisibleItems] = useState(5);
  const [selectedBugStatuses, setSelectedBugStatuses] = useState<string[]>([]);
  const [selectedBugPriorities, setSelectedBugPriorities] = useState<string[]>([]);
  const [visibleBugItems, setVisibleBugItems] = useState(5);
  const [selectedFile, setSelectedFile] = useState<BugReportAttachment | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const FEATURE_REQUEST_STATUSES = [
    { value: "pending_approval", label: "Pending Approval" },
    { value: "approved", label: "Approved" },
    { value: "in_progress", label: "In Progress" },
    { value: "completed", label: "Completed" },
    { value: "rejected", label: "Rejected" },
    { value: "cancelled", label: "Cancelled" },
  ] as const;

  const FEATURE_REQUEST_PRIORITIES = [
    { value: "low", label: "Low Priority" },
    { value: "medium", label: "Medium Priority" },
    { value: "high", label: "High Priority" },
  ] as const;

  const BUG_REPORT_STATUSES = [
    { value: "open", label: "Open" },
    { value: "in_progress", label: "In Progress" },
    { value: "resolved", label: "Resolved" },
    { value: "closed", label: "Closed" },
  ] as const;

  const BUG_REPORT_PRIORITIES = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
    { value: "critical", label: "Critical" },
  ] as const;

  const getFilteredFeatureRequests = (requests: IFeatureRequest[]) => {
    return requests.filter((request) => {
      const matchesStatus =
        selectedStatuses.length === 0 ||
        selectedStatuses.includes(request.status);
      const matchesPriority =
        selectedPriorities.length === 0 ||
        (request.priority && selectedPriorities.includes(request.priority));
      return matchesStatus && matchesPriority;
    });
  };

  const getFilteredBugReports = (reports: IBugReport[]) => {
    return reports.filter((report) => {
      const matchesStatus =
        selectedBugStatuses.length === 0 ||
        selectedBugStatuses.includes(report.status);
      const matchesPriority =
        selectedBugPriorities.length === 0 ||
        selectedBugPriorities.includes(report.priority);
      return matchesStatus && matchesPriority;
    });
  };

  const handleFileUpload = async (bugReportId: string, files: File[]) => {
    try {
      await updateAttachments.mutateAsync({
        bugReportId,
        files,
      });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const renderFeatureRequests = () => {
    if (isFeatureRequestsLoading) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {[1, 2].map((i) => (
            <Skeleton key={i} variant="rectangular" height={100} />
          ))}
        </Box>
      );
    }

    const projectFeatureRequests = featureRequests?.filter(
      (fr) => fr.projectId === projectId
    );

    if (!projectFeatureRequests?.length) {
      return (
        <Typography color="text.secondary" sx={{ p: 2 }}>
          No feature requests yet
        </Typography>
      );
    }

    const filteredRequests = getFilteredFeatureRequests(projectFeatureRequests);
    const visibleRequests = filteredRequests.slice(0, visibleItems);
    const hasMore = filteredRequests.length > visibleItems;

    return (
      <Box>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Filter by Status
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {FEATURE_REQUEST_STATUSES.map(({ value, label }) => (
                  <Chip
                    key={value}
                    label={label}
                    onClick={() => {
                      setSelectedStatuses((prev) =>
                        prev.includes(value)
                          ? prev.filter((s) => s !== value)
                          : [...prev, value]
                      );
                      setVisibleItems(5);
                    }}
                    color={selectedStatuses.includes(value) ? "primary" : "default"}
                    variant={selectedStatuses.includes(value) ? "filled" : "outlined"}
                    sx={{
                      "&:hover": {
                        bgcolor: selectedStatuses.includes(value)
                          ? "primary.dark"
                          : "action.hover",
                      },
                    }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Filter by Priority
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {FEATURE_REQUEST_PRIORITIES.map(({ value, label }) => (
                  <Chip
                    key={value}
                    label={label}
                    onClick={() => {
                      setSelectedPriorities((prev) =>
                        prev.includes(value)
                          ? prev.filter((p) => p !== value)
                          : [...prev, value]
                      );
                      setVisibleItems(5);
                    }}
                    color={selectedPriorities.includes(value) ? "primary" : "default"}
                    variant={selectedPriorities.includes(value) ? "filled" : "outlined"}
                    sx={{
                      "&:hover": {
                        bgcolor: selectedPriorities.includes(value)
                          ? "primary.dark"
                          : "action.hover",
                      },
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>

          {(selectedStatuses.length > 0 || selectedPriorities.length > 0) && (
            <Button
              size="small"
              onClick={() => {
                setSelectedStatuses([]);
                setSelectedPriorities([]);
                setVisibleItems(5);
              }}
              sx={{ mt: 2 }}
            >
              Clear Filters
            </Button>
          )}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Grid container spacing={2}>
            {visibleRequests.map((featureRequest) => (
              <Grid item xs={12} md={6} key={featureRequest.id}>
                <RequestCard
                  item={featureRequest}
                  type="feature"
                  projectId={projectId}
                  onNavigate={navigate}
                  onFileView={setSelectedFile}
                />
              </Grid>
            ))}
          </Grid>

          {hasMore && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                onClick={() => setVisibleItems((prev) => prev + 5)}
                variant="outlined"
                size="small"
              >
                Load More
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderBugReports = () => {
    if (isBugReportsLoading) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {[1, 2].map((i) => (
            <Skeleton key={i} variant="rectangular" height={100} />
          ))}
        </Box>
      );
    }

    const projectBugReports = bugReports?.filter(
      (br) => br.projectId === projectId
    );

    if (!projectBugReports?.length) {
      return (
        <Typography color="text.secondary" sx={{ p: 2 }}>
          No bug reports yet
        </Typography>
      );
    }

    const filteredReports = getFilteredBugReports(projectBugReports);
    const visibleReports = filteredReports.slice(0, visibleBugItems);
    const hasMore = filteredReports.length > visibleBugItems;

    return (
      <Box>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Filter by Status
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {BUG_REPORT_STATUSES.map(({ value, label }) => (
                  <Chip
                    key={value}
                    label={label}
                    onClick={() => {
                      setSelectedBugStatuses((prev) =>
                        prev.includes(value)
                          ? prev.filter((s) => s !== value)
                          : [...prev, value]
                      );
                      setVisibleBugItems(5);
                    }}
                    color={selectedBugStatuses.includes(value) ? "primary" : "default"}
                    variant={selectedBugStatuses.includes(value) ? "filled" : "outlined"}
                    sx={{
                      "&:hover": {
                        bgcolor: selectedBugStatuses.includes(value)
                          ? "primary.dark"
                          : "action.hover",
                      },
                    }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Filter by Priority
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {BUG_REPORT_PRIORITIES.map(({ value, label }) => (
                  <Chip
                    key={value}
                    label={label}
                    onClick={() => {
                      setSelectedBugPriorities((prev) =>
                        prev.includes(value)
                          ? prev.filter((p) => p !== value)
                          : [...prev, value]
                      );
                      setVisibleBugItems(5);
                    }}
                    color={selectedBugPriorities.includes(value) ? "primary" : "default"}
                    variant={selectedBugPriorities.includes(value) ? "filled" : "outlined"}
                    sx={{
                      "&:hover": {
                        bgcolor: selectedBugPriorities.includes(value)
                          ? "primary.dark"
                          : "action.hover",
                      },
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>

          {(selectedBugStatuses.length > 0 || selectedBugPriorities.length > 0) && (
            <Button
              size="small"
              onClick={() => {
                setSelectedBugStatuses([]);
                setSelectedBugPriorities([]);
                setVisibleBugItems(5);
              }}
              sx={{ mt: 2 }}
            >
              Clear Filters
            </Button>
          )}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Grid container spacing={2}>
            {visibleReports.map((bugReport) => (
              <Grid item xs={12} md={6} key={bugReport.id}>
                <RequestCard
                  item={bugReport}
                  type="bug"
                  projectId={projectId}
                  onNavigate={navigate}
                  onFileView={setSelectedFile}
                  onFileUpload={(files) => handleFileUpload(bugReport.id, files)}
                  allowedFileTypes={ALLOWED_FILE_TYPES}
                />
              </Grid>
            ))}
          </Grid>

          {hasMore && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                onClick={() => setVisibleBugItems((prev) => prev + 5)}
                variant="outlined"
                size="small"
              >
                Load More
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Paper 
      elevation={2} 
      sx={{ 
        p: { xs: 2, sm: 3 },
        borderRadius: { xs: 1.5, sm: 2 }
      }}
    >
      <Box 
        sx={{ 
          display: "flex", 
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between", 
          alignItems: { xs: "flex-start", sm: "center" },
          mb: { xs: 2, sm: 3 },
          gap: { xs: 2, sm: 0 }
        }}
      >
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{ 
            fontWeight: 600,
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
            mb: { xs: 0, sm: 1 }
          }}
        >
          Features & Bug Reports
        </Typography>
        <Box 
          sx={{ 
            display: "flex", 
            gap: 1,
            width: { xs: "100%", sm: "auto" }
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<LightbulbIcon />}
            onClick={onOpenFeatureRequestDialog}
            sx={{ 
              flex: { xs: 1, sm: "auto" },
              fontSize: { xs: "0.875rem", sm: "0.95rem" }
            }}
          >
            New Feature
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<BugReportIcon />}
            onClick={onOpenBugReportDialog}
            sx={{ 
              flex: { xs: 1, sm: "auto" },
              fontSize: { xs: "0.875rem", sm: "0.95rem" }
            }}
          >
            Report Bug
          </Button>
        </Box>
      </Box>

      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{ 
          borderBottom: 1, 
          borderColor: "divider",
          mb: { xs: 2, sm: 3 }
        }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab 
          label="Feature Requests" 
          value="features" 
          sx={{ 
            textTransform: "none",
            fontSize: { xs: "0.875rem", sm: "0.95rem" },
            fontWeight: 500
          }}
        />
        <Tab 
          label="Bug Reports" 
          value="bugs" 
          sx={{ 
            textTransform: "none",
            fontSize: { xs: "0.875rem", sm: "0.95rem" },
            fontWeight: 500
          }}
        />
      </Tabs>

      {activeTab === "features" ? renderFeatureRequests() : renderBugReports()}

      {selectedFile && (
        <ViewFileDialog
          open={!!selectedFile}
          onClose={() => setSelectedFile(null)}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
          isLoading={false}
        />
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Paper>
  );
};
