import { functionsBaseUrl } from "config/functionsBaseUrl";
import { getHeaders } from "../getHeaders";
import { StudentProduct, StudentSubscription } from "types/student";
import {
  StripeProductsResponse,
  SubscriptionsResponse,
  BalanceTransactionsResponse,
} from "@freetech/models/stripe";

// Base URL for Stripe API endpoints
const STRIPE_API_BASE = "/api/stripe";

export const stripeFunctions = {
  stakeholder: {
    /**
     * Creates a Stripe checkout session for an invoice payment
     *
     * @param invoiceId The ID of the invoice to pay
     * @param clientId The ID of the client associated with the invoice
     * @param amount The amount to charge in dollars
     * @param invoiceNumber The invoice number for display purposes
     * @param successUrl The URL to redirect to after successful payment
     * @param cancelUrl The URL to redirect to if payment is cancelled
     * @returns The checkout URL, session ID, and fee information
     */
    createCheckoutSession: async (
      invoiceId: string,
      clientId: string,
      amount: number,
      invoiceNumber: string,
      successUrl: string,
      cancelUrl: string
    ): Promise<{
      checkoutUrl: string;
      sessionId: string;
      originalAmount: number;
      processingFee: number;
      totalAmount: number;
    }> => {
      const url = `${functionsBaseUrl}/stripe/stakeholder/create-checkout-session`;
      const method = "POST";

      const response = await fetch(url, {
        method,
        headers: await getHeaders(),
        body: JSON.stringify({
          invoiceId,
          clientId,
          amount,
          invoiceNumber,
          successUrl,
          cancelUrl,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const data = await response.json();
      return data;
    },
  },
  student: {
    /**
     * Creates a Stripe subscription checkout session for a student
     *
     * @param priceId The ID of the Stripe price to subscribe to
     * @param successUrl The URL to redirect to after successful subscription
     * @param cancelUrl The URL to redirect to if subscription is cancelled
     * @param couponCode Optional coupon code to apply to the subscription
     * @returns The checkout URL and session ID
     */
    createSubscription: async (
      priceId: string,
      successUrl: string,
      cancelUrl: string,
      couponCode?: string
    ): Promise<{
      checkoutUrl: string;
      sessionId: string;
    }> => {
      const url = `${functionsBaseUrl}/stripe/student/create-subscription`;
      const method = "POST";

      const response = await fetch(url, {
        method,
        headers: await getHeaders(),
        body: JSON.stringify({
          priceId,
          successUrl,
          cancelUrl,
          couponCode
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create subscription");
      }

      const data = await response.json();
      return data;
    },

    /**
     * Gets the current subscription information for the student
     *
     * @returns The subscription information, or null if no subscription exists
     */
    getSubscription: async (): Promise<{
      hasSubscription: boolean;
      subscription: {
        id: string;
        studentId: string;
        planName: string;
        amount: number;
        status: string;
        currentPeriodStart: string;
        currentPeriodEnd: string;
        canceledAt?: string;
        cancelAtPeriodEnd: boolean;
        stripeSubscriptionId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    }> => {
      const url = `${functionsBaseUrl}/stripe/student/subscription`;
      const method = "GET";

      const response = await fetch(url, {
        method,
        headers: await getHeaders(),
      });

      if (!response.ok) {
        throw new Error("Failed to get subscription information");
      }

      const data = await response.json();
      return data;
    },

    /**
     * Cancels the student's current subscription
     *
     * @param cancelImmediately Whether to cancel immediately (true) or at the end of the billing period (false)
     * @returns Success status and message
     */
    cancelSubscription: async (
      cancelImmediately: boolean = false
    ): Promise<{
      success: boolean;
      message: string;
    }> => {
      const url = `${functionsBaseUrl}/stripe/student/cancel-subscription`;
      const method = "DELETE";

      const response = await fetch(url, {
        method,
        headers: await getHeaders(),
        body: JSON.stringify({
          cancelImmediately,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }

      const data = await response.json();
      return data;
    },

    /**
     * Reactivates a previously canceled subscription
     * 
     * @returns Success status and message
     */
    reactivateSubscription: async (): Promise<{
      success: boolean;
      message: string;
      subscription?: StudentSubscription;
    }> => {
      const url = `${functionsBaseUrl}/stripe/student/reactivate-subscription`;
      const method = "POST";

      const response = await fetch(url, {
        method,
        headers: await getHeaders(),
      });

      if (!response.ok) {
        throw new Error("Failed to reactivate subscription");
      }

      const data = await response.json();
      return data;
    },

    /**
     * Creates a Stripe Customer Portal session for managing payment methods and subscription
     *
     * @param returnUrl The URL to return to after the customer finishes in the portal
     * @returns The URL to the customer portal
     */
    createCustomerPortalSession: async (
      returnUrl: string = `${window.location.origin}/student/manage-subscription`
    ): Promise<{
      success: boolean;
      url: string;
    }> => {
      const url = `${functionsBaseUrl}/stripe/student/customer-portal`;
      const method = "POST";

      const response = await fetch(url, {
        method,
        headers: await getHeaders(),
        body: JSON.stringify({
          returnUrl,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create customer portal session");
      }

      const data = await response.json();
      return data;
    },
  },
  admin: {
    /**
     * Get all active Stripe products with pricing
     * @returns Promise containing Stripe products data
     */
    getProducts: async (): Promise<StripeProductsResponse> => {
      const response = await fetch(`${functionsBaseUrl}/stripe/admin/products`, {
        method: "GET",
        headers: await getHeaders(),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to fetch Stripe products");
      }
      
      return await response.json();
    },
    
    /**
     * Get Stripe subscriptions with detailed information
     * @param options Optional parameters for filtering subscriptions
     * @returns Promise containing subscription data
     */
    getSubscriptions: async (options?: {
      limit?: number;
      status?: "active" | "past_due" | "unpaid" | "canceled" | "incomplete" | "incomplete_expired" | "trialing" | "all";
    }): Promise<SubscriptionsResponse> => {
      // Build query parameters
      const queryParams = new URLSearchParams();
      
      if (options?.limit) {
        queryParams.append("limit", options.limit.toString());
      }
      
      if (options?.status) {
        queryParams.append("status", options.status);
      }
      
      const queryString = queryParams.toString();
      const url = `${functionsBaseUrl}/stripe/admin/subscriptions${queryString ? `?${queryString}` : ""}`;
      
      const response = await fetch(url, {
        method: "GET",
        headers: await getHeaders(),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to fetch Stripe subscriptions");
      }
      
      return await response.json();
    },
    
    /**
     * Get Stripe balance transactions with detailed information
     * @param options Optional parameters for filtering transactions
     * @returns Promise containing balance transaction data
     */
    getBalanceTransactions: async (options?: {
      limit?: number;
      starting_after?: string;
      ending_before?: string;
      type?: string;
      time_range?: "7d" | "30d" | "90d" | "1y";
    }): Promise<BalanceTransactionsResponse> => {
      // Build query parameters
      const queryParams = new URLSearchParams();
      
      if (options?.limit) {
        queryParams.append("limit", options.limit.toString());
      }
      
      if (options?.starting_after) {
        queryParams.append("starting_after", options.starting_after);
      }
      
      if (options?.ending_before) {
        queryParams.append("ending_before", options.ending_before);
      }
      
      if (options?.type) {
        queryParams.append("type", options.type);
      }
      
      if (options?.time_range) {
        queryParams.append("time_range", options.time_range);
      }
      
      const queryString = queryParams.toString();
      const url = `${functionsBaseUrl}/stripe/admin/balance-transactions${queryString ? `?${queryString}` : ""}`;
      
      const response = await fetch(url, {
        method: "GET",
        headers: await getHeaders(),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to fetch Stripe balance transactions");
      }
      
      return await response.json();
    }
  },
};

/**
 * Create a subscription for a student
 * @param priceId The Stripe price ID
 * @param couponCode Optional coupon code to apply to the subscription
 * @returns The checkout URL and session ID
 */
export const createStudentSubscription = async (priceId: string, couponCode?: string) => {
  try {
    return await stripeFunctions.student.createSubscription(
      priceId,
      `${window.location.origin}/student/dashboard?subscription=success`,
      `${window.location.origin}/student/manage-subscription?subscription=cancelled`,
      couponCode
    );
  } catch (error) {
    console.error("Error creating subscription:", error);
    throw error;
  }
};

/**
 * Get the current subscription for a student
 * @returns The subscription information
 */
export const getStudentSubscription =
  async (): Promise<StudentSubscription | null> => {
    try {
      // First use the function through stripeFunctions.student.getSubscription
      // to avoid CORS issues
      const data = await stripeFunctions.student.getSubscription();

      // Extract just the subscription object
      if (data && "hasSubscription" in data && "subscription" in data) {
        return data.hasSubscription
          ? (data.subscription as StudentSubscription)
          : null;
      }

      return null;
    } catch (error) {
      console.error("Error getting subscription:", error);
      return null;
    }
  };

/**
 * Cancel a student's subscription
 * @param atPeriodEnd Whether to cancel at the end of the billing period
 * @returns The updated subscription
 */
export const cancelStudentSubscription = async (
  atPeriodEnd: boolean = true
): Promise<{ success: boolean; message: string }> => {
  try {
    return await stripeFunctions.student.cancelSubscription(!atPeriodEnd);
  } catch (error) {
    console.error("Error canceling subscription:", error);
    throw error;
  }
};

/**
 * Reactivate a previously canceled subscription
 * @returns The reactivation status and updated subscription if successful
 */
export const reactivateStudentSubscription = async (): Promise<{
  success: boolean;
  message: string;
  subscription?: StudentSubscription;
}> => {
  try {
    return await stripeFunctions.student.reactivateSubscription();
  } catch (error) {
    console.error("Error reactivating subscription:", error);
    throw error;
  }
};

/**
 * Get all available student subscription products
 * @returns The list of available products
 */
export const getStudentProducts = async (): Promise<StudentProduct[]> => {
  // The products endpoint doesn't require authentication
  const url = `${functionsBaseUrl}/stripe/student/products`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get student products: ${response.status} ${response.statusText}`
    );
  }

  const data = await response.json();
  return data;
};

/**
 * Creates a Stripe Customer Portal session for a student
 * @param returnUrl Optional URL to return to after the portal session
 * @returns The URL to the customer portal
 */
export const createStudentCustomerPortalSession = async (
  returnUrl?: string
): Promise<string> => {
  try {
    const result = await stripeFunctions.student.createCustomerPortalSession(returnUrl);
    return result.url;
  } catch (error) {
    console.error("Error creating customer portal session:", error);
    throw error;
  }
};
