import { useState, useCallback, useEffect } from 'react';
import { AIMessage } from 'types/ai';
import { useAuth } from 'hooks/auth/useAuth';
import { getFirestore, collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import { portalFunctions } from 'core/functions/portalFunctions';

const db = getFirestore();

export const useAIChat = () => {
  const [messages, setMessages] = useState<AIMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useAuth();

  // Listen to chat messages
  useEffect(() => {
    if (!userInfo) return;

    const chatQuery = query(
      collection(db, 'generate'),
      where('userId', '==', userInfo.id),
      orderBy('createTime', 'desc')
    );

    const unsubscribe = onSnapshot(chatQuery, (snapshot) => {
      const newMessages: AIMessage[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        // Add user's prompt as a message using originalPrompt
        newMessages.push({
          id: doc.id + '-prompt',
          content: data.originalPrompt || data.prompt,
          timestamp: data.createTime?.toMillis() || Date.now(),
          isAI: false,
          userName: userInfo.displayName || userInfo.email || 'User'
        });

        // Add AI's response as a message if it exists
        if (data.response) {
          newMessages.push({
            id: doc.id + '-response',
            content: data.response,
            timestamp: (data.createTime?.toMillis() || Date.now()) + 1,
            isAI: true,
            userName: 'Jane'
          });
        }
      });

      // Sort messages by timestamp
      setMessages(newMessages.sort((a, b) => a.timestamp - b.timestamp));
    });

    return () => unsubscribe();
  }, [userInfo]);

  const sendMessage = useCallback(async (content: string) => {
    if (!userInfo) return;

    // Optimistically add user's message
    const optimisticId = `temp-${Date.now()}`;
    const optimisticMessage: AIMessage = {
      id: optimisticId,
      content,
      timestamp: Date.now(),
      isAI: false,
      userName: userInfo.displayName || userInfo.email || 'User'
    };

    setMessages(prev => [...prev, optimisticMessage]);
    setIsLoading(true);

    try {
      await portalFunctions.ai.sendMessage(content);
      // The message will be updated through the Firebase listener
    } catch (error) {
      console.error('Error sending message:', error);
      // Remove the optimistic message if there was an error
      setMessages(prev => prev.filter(msg => msg.id !== optimisticId));
    } finally {
      setIsLoading(false);
    }
  }, [userInfo]);

  return {
    messages,
    sendMessage,
    isLoading,
  };
};