import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import {
  Dashboard as DashboardIcon,
  AttachMoney as AttachMoneyIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  AccessTime as AccessTimeIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import { useAuth } from "hooks/auth/useAuth";

// Import Tab Components
import OverviewTab from "./components/OverviewTab";
import FinancialTab from "./components/FinancialTab";
import FreelancersTab from "./components/FreelancersTab";
import ClientsTab from "./components/ClientsTab";
import TimesheetTab from "./components/TimesheetTab";
import ProjectsTab from "./components/ProjectsTab";

// TabPanel component for dashboard tabs
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      style={{
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: { xs: 2, md: 3 },
            width: "100%",
            maxWidth: "100%",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const SuperAdminHome: React.FC = () => {
  const { userInfo, isLoading } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [tabValue, setTabValue] = useState(0);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSwipeChangeIndex = (index: number) => {
    setTabValue(index);
  };

  return (
    <Box
      sx={{
        p: { xs: 1, sm: 1.5, md: 3 },
        width: "100%",
        maxWidth: "100vw",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          mb: { xs: 1, md: 3 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "flex-end" },
          gap: { xs: 1, md: 0 },
          width: "100%",
        }}
      >
        <Typography variant={isMobile ? "h5" : "h4"} sx={{ mb: { md: 0 } }}>
          FreeTech Dashboard
        </Typography>
      </Box>

      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons={isMobile ? "auto" : false}
          aria-label="dashboard tabs"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            "& .MuiTab-root": {
              minHeight: "64px",
            },
          }}
        >
          <Tab icon={<DashboardIcon />} label="Overview" />
          <Tab icon={<AttachMoneyIcon />} label="Financial" />
          <Tab icon={<PeopleIcon />} label="Freelancers" />
          <Tab icon={<BusinessIcon />} label="Clients" />
          <Tab icon={<AccessTimeIcon />} label="Timesheets" />
          <Tab icon={<AssignmentIcon />} label="Projects" />
        </Tabs>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={tabValue}
          onChangeIndex={handleSwipeChangeIndex}
        >
          <TabPanel value={tabValue} index={0}>
            <OverviewTab />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <FinancialTab />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <FreelancersTab />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <ClientsTab />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <TimesheetTab />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <ProjectsTab />
          </TabPanel>
        </SwipeableViews>
      </Paper>
    </Box>
  );
};

export default SuperAdminHome;
