import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Chip,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Person,
  Business,
  SupervisorAccount,
  Email,
  Phone,
  AccessTime,
  AttachMoney,
  Info,
  Language,
  Work,
} from "@mui/icons-material";
import {
  Referral,
  ReferralStatus,
  ReferrerType,
  ReferralType,
} from "@freetech/models";
import { DateTime } from "luxon";

interface ReferralListProps {
  referrals: Referral[];
  emptyMessage: string;
}

const ReferralList: React.FC<ReferralListProps> = ({
  referrals,
  emptyMessage,
}) => {
  // Helper function to get icon based on referral type
  const getReferralTypeIcon = (type: ReferralType) => {
    switch (type) {
      case "previous_client":
        return <Business />;
      case "cold_lead":
        return <Work />;
      case "warm_lead":
        return <Business />;
      case "other":
        return <Language />;
      default:
        return <Business />;
    }
  };

  // Helper function to get status chip color
  const getStatusColor = (status: ReferralStatus) => {
    switch (status) {
      case "pending":
        return "default";
      case "contacted":
        return "info";
      case "interviewing":
        return "warning";
      case "hired":
        return "success";
      case "rejected":
        return "error";
      default:
        return "default";
    }
  };

  // Helper function to get status display text
  const getStatusDisplayText = (status: ReferralStatus) => {
    switch (status) {
      case "pending":
        return "New";
      case "contacted":
        return "Contacted";
      case "interviewing":
        return "In Discussion";
      case "hired":
        return "Converted";
      case "rejected":
        return "Not Interested";
      default:
        // Handle the case where status might be outside the defined enum
        const statusStr = String(status);
        return statusStr.charAt(0).toUpperCase() + statusStr.slice(1).replace(/_/g, " ");
    }
  };

  // Helper function to format date using Luxon
  const formatDate = (timestamp: string) => {
    if (!timestamp) return "N/A";
    try {
      const date = DateTime.fromISO(timestamp);
      return date.toRelative();
    } catch (error) {
      return "Invalid date";
    }
  };

  // Extract industry from notes if available
  const extractIndustry = (notes?: string): string | null => {
    if (!notes) return null;
    const industryMatch = notes.match(/Industry: ([^\n]+)/);
    return industryMatch ? industryMatch[1] : null;
  };

  if (referrals.length === 0) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="body1" color="text.secondary">
          {emptyMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <List sx={{ width: "100%" }}>
      {referrals.map((referral, index) => {
        const industry = extractIndustry(referral.notes);
        
        return (
          <React.Fragment key={referral.id}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                mb: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
              }}
            >
              <Grid container spacing={2}>
                {/* Company Information */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          bgcolor:
                            referral.type === "previous_client"
                              ? "primary.main"
                              : referral.type === "warm_lead"
                                ? "secondary.main"
                                : "info.main",
                        }}
                      >
                        {getReferralTypeIcon(referral.type)}
                      </Avatar>
                    </ListItemAvatar>
                    <Box>
                      <Typography variant="subtitle1">
                        {referral.companyName || "Unnamed Company"}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                        <Chip
                          label={
                            referral.type.charAt(0).toUpperCase() +
                            referral.type.slice(1).replace(/_/g, " ")
                          }
                          size="small"
                          sx={{ mr: 1 }}
                        />
                        <Chip
                          label={getStatusDisplayText(referral.status)}
                          size="small"
                          color={getStatusColor(referral.status)}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ pl: 7 }}>
                    {referral.companyWebsite && (
                      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                        <Language
                          fontSize="small"
                          sx={{ mr: 1, color: "text.secondary" }}
                        />
                        <Typography variant="body2">
                          {referral.companyWebsite}
                        </Typography>
                      </Box>
                    )}
                    {referral.companySize && (
                      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                        <Business
                          fontSize="small"
                          sx={{ mr: 1, color: "text.secondary" }}
                        />
                        <Typography variant="body2">
                          {referral.companySize}
                        </Typography>
                      </Box>
                    )}
                    {industry && (
                      <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                        <Work
                          fontSize="small"
                          sx={{ mr: 1, color: "text.secondary" }}
                        />
                        <Typography variant="body2">
                          {industry}
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AccessTime
                        fontSize="small"
                        sx={{ mr: 1, color: "text.secondary" }}
                      />
                      <Typography variant="body2">
                        Referred {formatDate(referral.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Contact Person Information */}
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" gutterBottom>
                    Contact Person
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Person
                      fontSize="small"
                      sx={{ mr: 1, color: "text.secondary" }}
                    />
                    <Typography variant="body2">
                      {referral.firstName} {referral.lastName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                    <Email
                      fontSize="small"
                      sx={{ mr: 1, color: "text.secondary" }}
                    />
                    <Typography variant="body2">{referral.email}</Typography>
                  </Box>
                  {referral.phone && (
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
                    >
                      <Phone
                        fontSize="small"
                        sx={{ mr: 1, color: "text.secondary" }}
                      />
                      <Typography variant="body2">{referral.phone}</Typography>
                    </Box>
                  )}
                </Grid>

                {/* Reward Information */}
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="subtitle2" gutterBottom>
                      Reward Status
                    </Typography>
                    <Tooltip title="View details">
                      <IconButton size="small">
                        <Info fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <AttachMoney
                      fontSize="small"
                      sx={{ mr: 1, color: "text.secondary" }}
                    />
                    <Typography variant="body2">
                      {referral.rewardEligible
                        ? referral.status === "hired"
                          ? referral.rewardPaid
                            ? `Reward of $${referral.rewardAmount || 0} paid`
                            : `Eligible for $${referral.rewardAmount || 0} reward`
                          : "Eligible for reward if converted to client"
                        : "Not eligible for reward"}
                    </Typography>
                  </Box>

                  {referral.notes && !industry && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Notes
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {referral.notes}
                      </Typography>
                    </Box>
                  )}
                  
                  {referral.notes && industry && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Notes
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {referral.notes.replace(/Industry: [^\n]+\n?/, '')}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Paper>
            {index < referrals.length - 1 && <Divider sx={{ my: 2 }} />}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default ReferralList;
