import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  FormHelperText,
} from "@mui/material";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Project,
  ProjectStatus,
  ConsultingServicesAgreement,
} from "@freetech/models/projects";

const editProjectSchema = z.object({
  name: z.string().min(1, "Project name is required"),
  description: z.string(),
  status: z.enum([
    "idea",
    "active",
    "completed",
    "on_hold",
    "cancelled",
  ] as const),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
  activeCSAId: z.string().nullable(),
});

type EditProjectFormData = z.infer<typeof editProjectSchema>;

interface EditProjectDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: Partial<Project>) => Promise<void>;
  project: Project;
  csas: ConsultingServicesAgreement[];
}

const EditProjectDialog: React.FC<EditProjectDialogProps> = ({
  open,
  onClose,
  onSave,
  project,
  csas,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<EditProjectFormData>({
    resolver: zodResolver(editProjectSchema),
    defaultValues: {
      name: project.name,
      description: project.description,
      status: project.status,
      startDate: project.startDate,
      endDate: project.endDate,
      activeCSAId: project.activeCSAId || null,
    },
  });

  const onSubmit = async (data: EditProjectFormData) => {
    try {
      await onSave({
        ...data,
        activeCSAId: data.activeCSAId || undefined,
      });
      onClose();
    } catch (error) {
      console.error("Error saving project:", error);
    }
  };

  React.useEffect(() => {
    if (open) {
      reset({
        name: project.name,
        description: project.description,
        status: project.status,
        startDate: project.startDate,
        endDate: project.endDate,
        activeCSAId: project.activeCSAId || null,
      });
    }
  }, [open, project, reset]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Project</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 1 }}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Project Name"
                  fullWidth
                  required
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  fullWidth
                  multiline
                  rows={3}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />

            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors.status}>
                  <InputLabel>Status</InputLabel>
                  <Select {...field} label="Status">
                    <MenuItem value="idea">Idea</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="on_hold">On Hold</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                  </Select>
                  {errors.status && (
                    <FormHelperText>{errors.status.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Start Date"
                  type="date"
                  fullWidth
                  value={field.value?.split("T")[0] || ""}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.startDate}
                  helperText={errors.startDate?.message}
                />
              )}
            />

            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="End Date"
                  type="date"
                  fullWidth
                  value={field.value?.split("T")[0] || ""}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                />
              )}
            />

            <Controller
              name="activeCSAId"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Active CSA</InputLabel>
                  <Select
                    {...field}
                    label="Active CSA"
                    value={field.value || ""}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {csas.map((csa) => (
                      <MenuItem key={csa.id} value={csa.id}>
                        CSA {csa.name || csa.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProjectDialog;
