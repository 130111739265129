import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "config/firebase";
import { getClientsProjectsAndAssignmentsForFreelancer } from "core/timesheet";
import { loadClientsForStakeholder } from "core/stakeholder/loadClientsForStakeholder";
import { PrivateUserInfoType } from "@freetech/models/user";
import { Project } from "@freetech/models/projects";

export const loadProjectsNonAdmin = async (
  userInfo?: PrivateUserInfoType
): Promise<Project[]> => {
  try {
    // If user is not admin, only fetch projects they have access to

    let projects: Project[] = [];
    if (userInfo?.role === "freelancer") {
      const {
        clients,
        projects: localProjects,
        projectAssignments,
      } = await getClientsProjectsAndAssignmentsForFreelancer(userInfo);
      projects = localProjects;
    } else if (userInfo?.role === "stakeholder") {
      const clients = await loadClientsForStakeholder(userInfo);
      return clients.flatMap((client) => client.projects || []);
    } else if (userInfo?.role === "admin") {
      const projectsRef = collectionGroup(db, "projects");
      const querySnapshot = await getDocs(projectsRef);
      projects = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Project[];
    }

    return projects;
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};
