import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Skeleton,
  useTheme,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useAdminFinancialHub } from 'hooks/admin/useAdminFinancialHub';
import { useAdminFreelancers } from 'hooks/admin/useAdminFreelancers';
import { useAdminClients } from 'hooks/admin/useAdminClients';
import { useAdminTimesheet } from 'hooks/admin/useAdminTimesheet';
import TimeTrackingCharts from './TimeTrackingCharts';
import BusinessHealthMetrics from './BusinessHealthMetrics';

const OverviewTab: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { formatCurrency, accountBalances, isLoading: isLoadingFinancial } = useAdminFinancialHub();
  const { freelancers, isLoading: isLoadingFreelancers } = useAdminFreelancers();
  const { clients, isLoading: isLoadingClients } = useAdminClients();
  const { getAllTimesheets } = useAdminTimesheet();
  const { data: timesheets, isLoading: isLoadingTimesheets } = getAllTimesheets();

  // Calculate key metrics
  const activeFreelancers = freelancers?.filter(f => f.status === 'active').length || 0;
  const totalFreelancers = freelancers?.length || 0;

  const activeClients = clients?.filter(c => c.status === 'active').length || 0;
  const totalClients = clients?.length || 0;

  const totalProjects = clients?.reduce(
    (count, client) => count + (client.projects?.length || 0),
    0
  ) || 0;

  const totalHours = timesheets?.reduce((sum, ts) => sum + (ts.duration || 0), 0) || 0;
  
  // Format time from total minutes
  const formatTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours}h ${remainingMinutes}m`;
  };

  // Loading placeholders
  const StatCardLoader = () => (
    <Card>
      <CardContent>
        <Skeleton variant="text" width={120} height={28} />
        <Skeleton variant="text" width={80} height={40} sx={{ my: 1 }} />
        <Skeleton variant="text" width={100} />
      </CardContent>
    </Card>
  );

  const SectionLoader = () => (
    <>
      <Skeleton variant="text" width={200} height={32} sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        {[...Array(4)].map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatCardLoader />
          </Grid>
        ))}
      </Grid>
    </>
  );

  return (
    <Box sx={{ 
      maxWidth: 1200, 
      margin: '0 auto',
      p: { xs: 1, sm: 2, md: 3 }
    }}>
      <Paper 
        sx={{ 
          p: { xs: 1, sm: 2, md: 3 },
          mb: { xs: 2, sm: 3 },
          borderRadius: { xs: 1, sm: 2 },
          boxShadow: { xs: 1, sm: 3 }
        }}
      >
        <BusinessHealthMetrics />
      </Paper>
      
      <Paper 
        sx={{ 
          p: { xs: 1, sm: 2, md: 3 },
          borderRadius: { xs: 1, sm: 2 },
          boxShadow: { xs: 1, sm: 3 }
        }}
      >
        <TimeTrackingCharts />
      </Paper>
    </Box>
  );
};

export default OverviewTab; 