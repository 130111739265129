import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navigation, NavigationPageItem } from "types/navigation";
import { useAuth } from "hooks/auth/useAuth";
import {
  superuserPages,
  getFreelancerPages,
  stakeholderPages,
  executiveAssistantPages,
  studentPages,
} from "router/pages";

export const useNavigation = () => {
  const [navigationItems, setNavigationItems] = useState<Navigation>([]);
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  const findNavItem = (
    items: Navigation,
    path: string
  ): NavigationPageItem | null => {
    for (const item of items) {
      if ("segment" in item && item.segment === path) {
        return item as NavigationPageItem;
      }
      if ("children" in item && item.children) {
        const found = findNavItem(item.children, path);
        if (found) return found;
      }
      // Check for slugBabyPattern and slugGrandBabyPattern
      if ("slugBabyPattern" in item && "segment" in item && item.segment) {
        const baseSegment = item.segment;
        const pathParts = path.split("/");
        const segmentParts = baseSegment.split("/");

        if (pathParts.length >= segmentParts.length) {
          const isMatch = segmentParts.every(
            (part, index) => part === pathParts[index]
          );
          if (isMatch) {
            return item as NavigationPageItem;
          }
        }
      }
    }
    return null;
  };

  const handleNavigation = (path?: string) => {
    if (!path) {
      throw new Error("Something weird happened.. path is undefined");
    }
    navigate(`/${path}`);
  };

  useEffect(() => {
    const getNavigationItems = (): Navigation => {
      switch (userInfo?.role) {
        case "admin":
          return superuserPages;
        case "freelancer":
          return getFreelancerPages(userInfo);
        case "stakeholder":
          return stakeholderPages;
        case "executiveAssistant":
          return executiveAssistantPages;
        case "student":
          return studentPages;
        default:
          return [];
      }
    };
    setNavigationItems(getNavigationItems());
  }, [userInfo?.role]);

  return {
    findNavItem,
    handleNavigation,
    navigationItems,
  };
};
