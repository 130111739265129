import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Chip,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { DateTime } from "luxon";

export const RecentPayments: React.FC = () => {
  const { currentUser } = useAuth();
  const { clients } = useStakeholder(currentUser?.uid);
  const { stakeholderPayments, stakeholderPaymentsLoading, clientInvoices } =
    useStakeholderPayments();

  if (stakeholderPaymentsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Sort payments by payment date (newest first)
  const sortedPayments = stakeholderPayments?.length
    ? [...stakeholderPayments].sort((a, b) => {
        const dateA = new Date(a.paymentDate);
        const dateB = new Date(b.paymentDate);
        return dateB.getTime() - dateA.getTime();
      })
    : [];

  return (
    <List sx={{ p: 0 }}>
      {sortedPayments.length > 0 ? (
        sortedPayments.map((payment, index) => {
          const client = clients?.find((c) => c.id === payment.clientId);
          // Find the corresponding invoice to get the invoice date
          const invoice = clientInvoices?.find(inv => inv.id === payment.invoiceId);
          
          return (
            <React.Fragment key={payment.id}>
              <ListItem sx={{ px: 3, py: 2 }}>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Typography variant="subtitle1">
                        ${payment.amount.toLocaleString()}
                      </Typography>
                      {payment.status === "completed" ? (
                        <Chip label="PAID" color="success" size="small" />
                      ) : (
                        <Chip label="PENDING" color="warning" size="small" />
                      )}
                    </Box>
                  }
                  secondary={
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        {client?.companyName || payment.clientId || "Client"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Invoice #{payment.invoiceId}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Payment Date: {DateTime.fromISO(payment.paymentDate).toLocaleString(DateTime.DATE_MED)}
                        {invoice?.invoiceDate && (
                          <> • Invoice Date: {DateTime.fromISO(invoice.invoiceDate).toLocaleString(DateTime.DATE_MED)}</>
                        )}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              {index < sortedPayments.length - 1 && <Divider />}
            </React.Fragment>
          );
        })
      ) : (
        <ListItem sx={{ px: 3, py: 2 }}>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                No payment history
              </Typography>
            }
          />
        </ListItem>
      )}
    </List>
  );
};
