import React, { useState } from "react";
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridValueGetter,
  GridValueFormatter,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import {
  Box,
  Paper,
  useTheme,
  alpha,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import {
  calculateDuration,
  formatDurationForEdit,
  parseDuration,
} from "core/timesheet/utils";
import { Timesheet } from "@freetech/models/timesheet";
import {
  useFreelancerRoleClients,
  useFreelancerProjectAssignments,
} from "hooks/freelancer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ProjectAssignment } from "@freetech/models/projects";
import ViewFileDialog from "components/ViewFileDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
interface TimesheetExplorerGridProps {
  timesheets: Timesheet[];
  loading: boolean;
}

const TimesheetExplorerGrid: React.FC<TimesheetExplorerGridProps> = ({
  timesheets,
  loading,
}) => {
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [openFileDialog, setOpenFileDialog] = useState(false);

  const { clients } = useFreelancerRoleClients();
  const { projectAssignments, handleViewProjectAssignment } =
    useFreelancerProjectAssignments();

  const handleViewFile = async (assignment: ProjectAssignment) => {
    const fileUrl = await handleViewProjectAssignment(
      assignment.clientId,
      assignment.id
    );
    console.log(fileUrl);
    if (fileUrl) {
      setSelectedFile({
        url: fileUrl,
        name: `${assignment.projectName} - Contract`,
      });
      setOpenFileDialog(true);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
      minWidth: 150,
      valueGetter: (value, row, column, apiRef) => {
        const client = clients.find((client) => client.id === row.clientId);
        const project = client?.projects?.find(
          (project) => project.id === row.projectId
        );
        return project ? project.name : "";
      },
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      minWidth: 150,
      valueGetter: (value, row, column, apiRef) => {
        const client = clients.find((client) => client.id === row.clientId);
        return client ? client.companyName : "";
      },
    },
    {
      field: "projectAssignmentId",
      headerName: "PA",
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => {
        const projectAssignment = projectAssignments?.find(
          (assignment) => assignment.id === params.row.projectAssignmentId
        );
        return projectAssignment ? (
          <IconButton onClick={() => handleViewFile(projectAssignment)}>
            <VisibilityIcon />
          </IconButton>
        ) : (
          ""
        );
      },
    },
    {
      field: "billable",
      headerName: "Billable",
      flex: 0.5,
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => (
        <Tooltip title={params.value ? "Billable" : "Non-billable"}>
          <IconButton
            sx={{
              color: params.value
                ? theme.palette.success.main
                : theme.palette.text.secondary,
              pointerEvents: "none",
            }}
          >
            {params.value ? <AttachMoneyIcon /> : <MoneyOffIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "approved",
      headerName: "Approved",
      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => (
        <Tooltip title={params.value ? "Billable" : "Non-billable"}>
          <IconButton
            sx={{
              color: params.value
                ? theme.palette.success.main
                : theme.palette.text.secondary,
              pointerEvents: "none",
            }}
          >
            {params.value ? <CheckCircleIcon /> : <CancelIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1,
      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return DateTime.fromISO(row.startTime).toFormat("HH:mm");
      },
    },
    {
      field: "endTime",
      headerName: "End Time",
      flex: 1,
      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return DateTime.fromISO(row.endTime).toFormat("HH:mm");
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        const durationInSeconds = calculateDuration(row.startTime, row.endTime);
        return formatDurationForEdit(durationInSeconds);
      },
      valueSetter: (value, row, column, apiRef) => {
        if (!value) return;
        const newDurationInSeconds = parseDuration(value);

        return { ...row, duration: newDurationInSeconds };
      },
    },
    {
      field: "rate",
      headerName: "Rate",
      flex: 1,
      minWidth: 100,
      valueGetter: (value, row: Timesheet, column, apiRef) => {
        const projectAssignment = projectAssignments?.find(
          (assignment) => assignment.id === row.projectAssignmentId
        );
        return projectAssignment ? `$${projectAssignment.billableRate}/hr` : "";
      },
    },
    {
      field: "earnings",
      headerName: "Earnings",
      flex: 1,
      minWidth: 100,
      cellClassName: (params) => {
        if (params.row.approved) {
          return "approved-earnings";
        } else if (params.row.billable) {
          return "pending-earnings";
        }
        return "";
      },
      renderCell: (params) => {
        const value = params.value;
        const tooltipText = params.row.approved
          ? `Approved! You will be paid in accordance with the coresponding project assignment.`
          : params.row.billable
            ? "Pending approval - Final earnings are contingent upon company approval in accordance with the coresponding project assignment."
            : "Non-billable time entry";

        return (
          <Tooltip title={tooltipText}>
            <Box sx={{ width: "100%" }}>{value}</Box>
          </Tooltip>
        );
      },
      valueGetter: (value, row: Timesheet, column, apiRef) => {
        if (!row) return 0;
        if (!row.projectAssignmentId) return "$0.00";
        if (!row.billable) return "$0.00";
        const minutes = row.duration / 60;
        const projectAssignment = projectAssignments?.find(
          (assignment) => assignment.id === row.projectAssignmentId
        );
        const minuteRate = projectAssignment?.billableRate
          ? projectAssignment.billableRate / 60
          : 0;

        const earnings = minutes * minuteRate;
        return `$${earnings.toFixed(2)}`;
      },
    },
    {
      field: "description",
      headerName: "Short Description",
      flex: 2,
      minWidth: 200,
      valueGetter: (value, row: Timesheet, column, apiRef) => {
        return row.description ?? "";
      },
    },
  ];

  return (
    <Paper elevation={3}>
      <ViewFileDialog
        open={openFileDialog}
        onClose={() => setOpenFileDialog(false)}
        fileUrl={selectedFile?.url || ""}
        fileName={selectedFile?.name || ""}
      />
      <DataGridPro<Timesheet>
        rows={timesheets}
        columns={columns}
        loading={loading}
        autoHeight
        pagination
        density="comfortable"
        disableColumnFilter
        disableColumnResize
        disableColumnReorder
        disableColumnMenu
        disableColumnSelector
        disableChildrenSorting
        disableMultipleColumnsSorting
        disableDensitySelector
        disableRowSelectionOnClick
        columnHeaderHeight={40}
        rowHeight={50}
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [],
            },
          },
          sorting: {
            sortModel: [{ field: "date", sort: "desc" }],
          },
        }}
        sx={{
          border: "none",
          "& .MuiDataGrid-cell": {
            padding: "8px",
            borderBottom: "none",
            color: theme.palette.text.primary,
          },
          "& .approved-earnings": {
            backgroundColor: alpha(theme.palette.success.main, 0.1),
            "&:hover": {
              backgroundColor: alpha(theme.palette.success.main, 0.2),
            },
          },
          "& .pending-earnings": {
            backgroundColor: alpha(theme.palette.warning.main, 0.1),
            "&:hover": {
              backgroundColor: alpha(theme.palette.warning.main, 0.2),
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "0 !important",
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(odd)": {
              backgroundColor: alpha(theme.palette.background.default, 0.5),
            },
            "&:nth-of-type(even)": {
              backgroundColor: alpha(theme.palette.background.paper, 0.5),
            },
            "&:hover": {
              backgroundColor: alpha(theme.palette.action.hover, 0.1),
            },
          },
          "& .MuiDataGrid-columnHeader": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cellEditable": {
            "& .MuiInputBase-input": {
              color: theme.palette.text.primary,
            },
          },
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
            outline: `2px solid ${theme.palette.primary.main}`,
            outlineOffset: "-2px",
          },
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            {
              outline: "none",
            },
        }}
      />
    </Paper>
  );
};

export default TimesheetExplorerGrid;
