import { Box, Button, Typography } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Freelancer } from "@freetech/models/user";
import React from "react";
import { useAdminPayroll } from "hooks/admin/useAdminPayroll";
import { DateTime } from "luxon";
import { FreelancerPayment } from "@freetech/models/bank";

interface Props {
  contractor: Freelancer;
}

export const ContractorPayments = ({ contractor }: Props) => {
  const { useFreelancerPayments } = useAdminPayroll();
  const { data: payments, isLoading } = useFreelancerPayments(contractor.id);

  const columns: GridColDef<FreelancerPayment>[] = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 130,
      valueFormatter: (params) => {
        if (!params) return "";
        const { value } = params;
        return value
          ? DateTime.fromISO(value as string).toLocaleString(
              DateTime.DATE_SHORT
            )
          : "";
      },
    },
    {
      field: "isExternalPayment",
      headerName: "Type",
      width: 130,
      renderCell: (params: GridRenderCellParams<FreelancerPayment>) =>
        params.row.isExternalPayment ? "External" : "Increase",
    },
    {
      field: "externalPaymentSource",
      headerName: "Payment Method",
      width: 160,
      renderCell: (params: GridRenderCellParams<FreelancerPayment>) =>
        params.row.externalPaymentSource
          ? params.row.externalPaymentSource
          : "ACH",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 130,
      renderCell: (params: GridRenderCellParams<FreelancerPayment>) =>
        params.row?.amount ? `$${params.row.amount.toFixed(2)}` : "$0.00",
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      valueFormatter: (params) => {
        if (!params) return "";
        const { value } = params;
        return value ? (value as string).toUpperCase() : "";
      },
    },
    {
      field: "memo",
      headerName: "Description",
      flex: 1,
      minWidth: 200,
    },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }: { row: FreelancerPayment }) => (
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Payment Details
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
          }}
        >
          {row.clientId && (
            <Box>
              <Typography variant="caption" color="textSecondary">
                Client ID
              </Typography>
              <Typography>{row.clientId}</Typography>
            </Box>
          )}
          {row.projectId && (
            <Box>
              <Typography variant="caption" color="textSecondary">
                Project ID
              </Typography>
              <Typography>{row.projectId}</Typography>
            </Box>
          )}
          {row.projectAssignmentId && (
            <Box>
              <Typography variant="caption" color="textSecondary">
                Assignment ID
              </Typography>
              <Typography>{row.projectAssignmentId}</Typography>
            </Box>
          )}
          {row.csaId && (
            <Box>
              <Typography variant="caption" color="textSecondary">
                CSA ID
              </Typography>
              <Typography>{row.csaId}</Typography>
            </Box>
          )}
          <Box>
            <Typography variant="caption" color="textSecondary">
              Created At
            </Typography>
            <Typography>
              {row.createdAt
                ? DateTime.fromISO(row.createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )
                : ""}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="textSecondary">
              Updated At
            </Typography>
            <Typography>
              {row.updatedAt
                ? DateTime.fromISO(row.updatedAt).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )
                : ""}
            </Typography>
          </Box>
          {row.submittedByAdminUserId && (
            <Box>
              <Typography variant="caption" color="textSecondary">
                Submitted By
              </Typography>
              <Typography>{row.submittedByAdminUserId}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    ),
    []
  );

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <Button variant="outlined">Date: All</Button>
        <Button variant="outlined">Type: All</Button>
        <Button variant="outlined">Payment method: All</Button>
      </Box>

      <Box sx={{ height: 800, width: "100%" }}>
        <DataGridPro
          rows={payments || []}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[20, 50, 100]}
          disableRowSelectionOnClick
          loading={isLoading}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 200}
          rowHeight={60}
        />
      </Box>
    </Box>
  );
};
