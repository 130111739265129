import {
  collectionGroup,
  query,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { useQuery, useMutation } from "@tanstack/react-query";
import { ProjectAssignment } from "@freetech/models/projects";
import { IndependentEngineerAgreement, Freelancer } from "@freetech/models/user";
import { EversignTemplate } from "@freetech/models/eversign";
import { sendUpdatedIndependentEngineerAgreement } from "core/admin/freelancers/sendUpdatedIndependentEngineerAgreement";
import { updateProjectAssignment } from "core/projectAssignments";
import {
  updateSignedAgreement,
  cancelIndependentEngineerAgreement,
} from "core/independentEngineerAgreements";
import { useHyperDxAction } from "hooks/logging/useHyperDxAction";
import { portalFunctions } from "core/functions/portalFunctions";
import { useState } from "react";

const { logError } = useHyperDxAction();

export type AdminAgreementToSign = {
  type: "projectAssignment" | "independentEngineerAgreement";
  agreement: ProjectAssignment | IndependentEngineerAgreement;
  id: string;
};

export const useAdminSignAgreements = () => {
  // Query for fetching agreements that need signing

  const [agreements, setAgreements] = useState<AdminAgreementToSign[]>([]);

  const agreementsQuery = useQuery({
    queryKey: ["adminAgreementsToSign"],
    queryFn: async () => {
      const agreements = await getAllAgreementsToSign();
      setAgreements(agreements);
      return agreements;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes of stale time
    refetchOnWindowFocus: true, // Disable refetching when window is focused
    refetchOnMount: true, // Prevents refetch on mount if data is fresh
  });

  // Query for fetching Eversign templates
  const templatesQuery = useQuery<EversignTemplate[]>({
    queryKey: ["eversignTemplates"],
    queryFn: async () => {
      const response = await portalFunctions.eversign.fetchEversignTemplates();
      const data = await response;
      return data;
    },
  });

  // Mutation for sending updated agreements
  const sendAgreementsMutation = useMutation({
    mutationFn: async (params: {
      freelancers: Freelancer[];
      sandbox?: boolean;
    }) => {
      const results = await Promise.allSettled(
        params.freelancers.map((freelancer) =>
          sendUpdatedIndependentEngineerAgreement(freelancer, !!params.sandbox)
        )
      );

      // Check for any failures
      const failures = results.filter(
        (result): result is PromiseRejectedResult =>
          result.status === "rejected"
      );

      if (failures.length > 0) {
        throw new Error(
          `Failed to send agreements to some freelancers: ${failures
            .map((f) => f.reason)
            .join(", ")}`
        );
      }

      return results;
    },
  });

  // Mutation for updating project assignment status
  const updateProjectAssignmentMutation = useMutation({
    mutationFn: async ({
      clientId,
      assignmentId,
      updates,
    }: {
      clientId: string;
      assignmentId: string;
      updates: Partial<ProjectAssignment>;
    }) => {
      return updateProjectAssignment(clientId, assignmentId, updates);
    },
  });

  // Mutation for updating signed independent engineer agreement
  const updateSignedAgreementMutation = useMutation({
    mutationFn: async (params: {
      userId: string;
      agreementId: string;
      engineerEmail: string;
      engineerName: string;
      engineerFirstName: string;
    }) => {
      return updateSignedAgreement(params);
    },
  });

  // Mutation for cancelling project assignment
  const cancelProjectAssignmentMutation = useMutation({
    mutationFn: async ({
      clientId,
      assignmentId,
      documentHash,
    }: {
      clientId: string;
      assignmentId: string;
      documentHash: string;
    }) => {
      return cancelProjectAssignmentInSigningStatus(
        clientId,
        assignmentId,
        documentHash
      );
    },
  });

  // Mutation for cancelling independent engineer agreement
  const cancelIndependentEngineerAgreementMutation = useMutation({
    mutationFn: async ({
      userId,
      agreementId,
      documentHash,
    }: {
      userId: string;
      agreementId: string;
      documentHash: string;
    }) => {
      return cancelIndependentEngineerAgreement(
        userId,
        agreementId,
        documentHash
      );
    },
  });

  return {
    // Agreements to sign
    data: agreementsQuery.data,
    isLoading: agreementsQuery.isLoading,
    isError: agreementsQuery.isError,
    error: agreementsQuery.error,

    

    // Templates
    templates: templatesQuery.data,
    isLoadingTemplates: templatesQuery.isLoading,
    templatesError: templatesQuery.error,

    // Send agreements
    sendAgreements: (freelancers: Freelancer[], sandbox?: boolean) =>
      sendAgreementsMutation.mutate({ freelancers, sandbox }),
    isSending: sendAgreementsMutation.isPending,
    sendError: sendAgreementsMutation.error,

    // Update project assignment
    updateProjectAssignment: (
      clientId: string,
      assignmentId: string,
      updates: Partial<ProjectAssignment>
    ) =>
      updateProjectAssignmentMutation.mutate({
        clientId,
        assignmentId,
        updates,
      }),
    isUpdatingProjectAssignment: updateProjectAssignmentMutation.isPending,
    updateProjectAssignmentError: updateProjectAssignmentMutation.error,

    // Update signed agreement
    updateSignedAgreement: (params: {
      userId: string;
      agreementId: string;
      engineerEmail: string;
      engineerName: string;
      engineerFirstName: string;
    }) => updateSignedAgreementMutation.mutate(params),
    isUpdatingSignedAgreement: updateSignedAgreementMutation.isPending,
    updateSignedAgreementError: updateSignedAgreementMutation.error,

    // Cancel project assignment
    cancelProjectAssignment: (
      clientId: string,
      assignmentId: string,
      documentHash: string
    ) =>
      cancelProjectAssignmentMutation.mutate({
        clientId,
        assignmentId,
        documentHash,
      }),
    isCancellingProjectAssignment: cancelProjectAssignmentMutation.isPending,
    cancelProjectAssignmentError: cancelProjectAssignmentMutation.error,

    // Cancel independent engineer agreement
    cancelIndependentEngineerAgreement: (
      userId: string,
      agreementId: string,
      documentHash: string
    ) =>
      cancelIndependentEngineerAgreementMutation.mutate({
        userId,
        agreementId,
        documentHash,
      }),
    isCancellingIndependentEngineerAgreement:
      cancelIndependentEngineerAgreementMutation.isPending,
    cancelIndependentEngineerAgreementError:
      cancelIndependentEngineerAgreementMutation.error,
  };
};

export const getAllAgreementsToSign = async (): Promise<
  AdminAgreementToSign[]
> => {
  try {
    // Fetch project assignments
    const projectAssignmentsQuery = query(
      collectionGroup(db, "projectAssignments")
    );
    const projectAssignmentsSnapshot = await getDocs(projectAssignmentsQuery);
    const projectAssignments = projectAssignmentsSnapshot.docs
      .map((doc) => {
        const data = doc.data();

        return {
          type: "projectAssignment",
          agreement: {
            ...data,
            id: doc.id,
          } as ProjectAssignment,
          id: doc.id,
        } as AdminAgreementToSign;
      })
      .filter(
        (assignment) =>
          assignment.agreement.everSignDocumentStatus ===
            "awaiting_admin_signature" ||
          assignment.agreement.everSignDocumentStatus ===
            "awaiting_engineer_signature"
      );

    // Fetch independent engineer agreements
    const indEngAgreementsQuery = query(
      collectionGroup(db, "independentEngineerAgreements")
    );
    const indEngAgreementsSnapshot = await getDocs(indEngAgreementsQuery);
    const indEngAgreements = indEngAgreementsSnapshot.docs
      .map((doc) => {
        const data = doc.data();
        console.log("Independent Engineer Agreement Data:", {
          id: doc.id,
          data,
        });
        return {
          type: "independentEngineerAgreement",
          agreement: {
            ...data,
            id: doc.id,
          } as IndependentEngineerAgreement,
          id: doc.id,
        } as AdminAgreementToSign;
      })
      .filter(
        (agreement) =>
          agreement.agreement.everSignDocumentStatus ===
            "awaiting_admin_signature" ||
          agreement.agreement.everSignDocumentStatus ===
            "awaiting_engineer_signature"
      );

    // Combine and return all agreements
    const allAgreements = [...projectAssignments, ...indEngAgreements];

    // if (allAgreements.length === 0) {

    // }

    return allAgreements;
  } catch (error) {
    console.error("Error in getAllAgreementsToSign:", error);
    throw error;
  }
};

export const cancelProjectAssignmentInSigningStatus = async (
  clientId: string,
  assignmentId: string,
  documentHash: string
) => {
  const projectAssignmentRef = doc(
    db,
    "clients",
    clientId,
    "projectAssignments",
    assignmentId
  );
  const projectAssignmentSnap = await getDoc(projectAssignmentRef);
  const projectAssignmentData = projectAssignmentSnap.data();

  if (!projectAssignmentData) {
    throw new Error("Project assignment not found");
  }

  await updateDoc(projectAssignmentRef, {
    everSignDocumentStatus: "cancelled",
    status: "cancelled",
  });

  const cancelDocumentResponse =
    await portalFunctions.eversign.cancelDocument(documentHash);

  const cancelDocumentData = await cancelDocumentResponse.json();

  if (!cancelDocumentData.success) {
    logError(new Error("Failed to cancel document on the eversign side"), {
      clientId,
      assignmentId,
      documentHash,
    });
    throw new Error("Failed to cancel document on the eversign side");
  }

  return true;
};

export { cancelIndependentEngineerAgreement };
