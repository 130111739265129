import React, { useState, useCallback } from "react";
import {
  Box,
  Paper,
  Typography,
  Chip,
  Grid,
  Divider,
  Container,
  CircularProgress,
  Alert,
  Button,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { IFeatureRequest, FeatureRequestAttachment } from "@freetech/models";
import LinkIcon from "@mui/icons-material/Link";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReadOnlyTipTap from "components/TipTap/ReadOnlyTipTap";
import { useStakeholderFeatureRequest } from "hooks/stakeholder/useStakeholderFeatureRequest";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useFreelo } from "hooks/freelo/useFreelo";
import { Card as FreeloCard } from "@freetech/models/freelo";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { StakeholderReportOrRequestComments } from "../components/StakeholderReportOrRequestComments";
import { useAuth } from "hooks/auth/useAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useFreeloBoard } from "hooks/freelo/useFreeloBoard";
import { useFreeloTickets } from "hooks/freelo/useFreeloTickets";
import RichTextEditor from "components/TipTap/RichTextEditor";
import { useDropzone } from "react-dropzone";
import { DateTime } from "luxon";

// Constants for file upload
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const StakeholderFeatureRequest: React.FC = () => {
  const { projectId, featureRequestId } = useParams();
  const navigate = useNavigate();
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  
  // Attachment state
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState<string | null>(null);
  const [selectedAttachment, setSelectedAttachment] = useState<FeatureRequestAttachment | null>(null);
  
  // Delete confirmation dialog state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState<string | null>(null);
  
  // Get project data
  const { projects, projectsIsLoading, projectsError } = useStakeholder();
  const project = projects?.find((p) => p?.id === projectId);

  // Get feature request data
  const { 
    featureRequests, 
    isLoadingRequests, 
    addComment, 
    isAddingComment,
    updateFeatureRequest,
    uploadAttachment,
    isUploadingAttachment,
    removeAttachment,
    isRemovingAttachment: isRemovingAttachmentMutation
  } = useStakeholderFeatureRequest({
    clientId: project?.clientId || "",
  });
  
  const featureRequest = featureRequests?.find(
    (fr) => fr.id === featureRequestId
  );

  const { data: users } = usePublicUserList();

  // Get linked Freelo tickets
  const { data: tickets, isLoading: isLoadingTickets } = useFreeloTickets(
    project?.freeloBoardId || "",
    featureRequest?.freeloTicketIds
  );

  // Centralized loading state
  const isLoading = projectsIsLoading || isLoadingRequests || isLoadingTickets;

  const { userInfo } = useAuth();

  // Add Freelo hooks
  const { data: board } = useFreeloBoard(featureRequest?.projectId || "");

  // File upload handlers
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    
    // Check file size
    const oversizedFiles = acceptedFiles.filter(file => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      setUploadError(`Some files exceed the maximum size of 10MB: ${oversizedFiles.map(f => f.name).join(', ')}`);
      return;
    }
    
    setUploadFiles(prev => [...prev, ...acceptedFiles]);
    setUploadError(null);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ALLOWED_FILE_TYPES.reduce(
      (acc, type) => ({ ...acc, [type]: [] }),
      {}
    ),
    maxSize: MAX_FILE_SIZE,
  });

  const handleRemoveUploadFile = (index: number) => {
    setUploadFiles((prev) => prev.filter((_, i) => i !== index));
    if (uploadFiles.length === 1) {
      setUploadError(null);
    }
  };

  const handleUploadAttachments = async () => {
    if (!featureRequestId || uploadFiles.length === 0) return;
    
    try {
      setIsUploading(true);
      await uploadAttachment({
        featureRequestId,
        files: uploadFiles
      });
      setUploadFiles([]);
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading attachments:", error);
      setUploadError("Failed to upload attachments. Please try again.");
      setIsUploading(false);
    }
  };

  const handleRemoveAttachment = async (event: React.MouseEvent, attachmentId: string) => {
    event.stopPropagation(); // Prevent opening the attachment
    
    // Open the confirmation dialog instead of using window.confirm
    setAttachmentToDelete(attachmentId);
    setDeleteDialogOpen(true);
  };
  
  const confirmDeleteAttachment = async () => {
    if (!featureRequestId || !attachmentToDelete) return;
    
    try {
      setIsRemovingAttachment(attachmentToDelete);
      
      await removeAttachment({ 
        featureRequestId, 
        attachmentId: attachmentToDelete 
      });
      
      setIsRemovingAttachment(null);
    } catch (error) {
      // Error occurred
      setIsRemovingAttachment(null);
      console.error("Error removing attachment:", error);
    } finally {
      // Close the dialog and reset the attachment to delete
      setDeleteDialogOpen(false);
      setAttachmentToDelete(null);
    }
  };
  
  const cancelDeleteAttachment = () => {
    setDeleteDialogOpen(false);
    setAttachmentToDelete(null);
  };

  const handleAttachmentClick = (attachment: FeatureRequestAttachment) => {
    setSelectedAttachment(attachment);
  };

  const handleCloseDialog = () => {
    setSelectedAttachment(null);
  };

  // Early return for loading state with centered spinner
  if (isLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '50vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Handle error states
  if (projectsError) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          Error loading project: {projectsError.message}
        </Alert>
      </Container>
    );
  }

  // Handle not found states
  if (!project) {
    return (
      <Container maxWidth="lg">
        <Alert severity="warning" sx={{ mt: 2 }}>
          Project not found
        </Alert>
      </Container>
    );
  }

  if (!featureRequest) {
    return (
      <Container maxWidth="lg">
        <Alert severity="warning" sx={{ mt: 2 }}>
          Feature request not found
        </Alert>
      </Container>
    );
  }

  const getRequestedByUser = (id: string) => {
    const user = users?.find((u) => u.id === id);
    return user ? `${user.firstName} ${user.lastName}` : id;
  };

  const handleAddComment = async (content: string) => {
    if (!featureRequestId) return;
    await addComment({ featureRequestId, content });
  };

  const handleUpdateDescription = async (newDescription: string) => {
    if (!featureRequestId) return;
    
    try {
      await updateFeatureRequest.mutateAsync({
        id: featureRequestId,
        data: {
          description: newDescription,
          updatedAt: new Date().toISOString()
        }
      });
      
      setIsEditingDescription(false);
    } catch (error) {
      console.error("Error updating feature request description:", error);
    }
  };

  const handleStartEditing = () => {
    setEditorContent(featureRequest.description);
    setIsEditingDescription(true);
  };

  return (
    <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 } }}>

      {/* Header */}
      <Box sx={{ mb: { xs: 2, sm: 3 } }}>
        <Box sx={{ 
          display: "flex", 
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" }, 
          gap: { xs: 1, sm: 2 }, 
          mb: { xs: 1.5, sm: 2 } 
        }}>
          <Typography 
            variant="h4"
            sx={{ 
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" },
              fontWeight: 700,
              letterSpacing: "-0.01em",
            }}
          >
            {featureRequest.title}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: { xs: 1.5, sm: 2 } }}>
          <Chip
            label={featureRequest.priority}
            color={
              featureRequest.priority === "high"
                ? "error"
                : featureRequest.priority === "medium"
                  ? "warning"
                  : "default"
            }
            sx={{ 
              fontWeight: "bold",
              height: { xs: 28, sm: 32 },
              fontSize: { xs: "0.75rem", sm: "0.875rem" }
            }}
          />
          <Chip
            label={featureRequest.status}
            color={
              featureRequest.status === "approved"
                ? "success"
                : featureRequest.status === "pending_approval"
                  ? "warning"
                  : featureRequest.status === "rejected"
                    ? "error"
                    : "default"
            }
            sx={{ 
              height: { xs: 28, sm: 32 },
              fontSize: { xs: "0.75rem", sm: "0.875rem" }
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12} md={8} sx={{ order: { xs: 2, md: 1 } }}>
          {/* Main Content */}
          <Paper 
            elevation={1}
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 }
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{ 
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                  fontWeight: 600,
                  mb: { xs: 1.5, sm: 2 },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                Description
                <IconButton 
                  size="small" 
                  color="primary"
                  onClick={handleStartEditing}
                  disabled={isAddingComment || updateFeatureRequest.isPending}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Typography>

              {isEditingDescription ? (
                <Box sx={{ mt: 2 }}>
                  <RichTextEditor
                    content={editorContent}
                    onChange={setEditorContent}
                    placeholder="Edit description..."
                    minHeight={150}
                    includeHeadings={true}
                    includeCodeBlock={true}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                    <Button 
                      variant="outlined" 
                      color="error" 
                      size="small" 
                      startIcon={<CloseIcon />}
                      onClick={() => setIsEditingDescription(false)}
                    >
                      Cancel
                    </Button>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      size="small" 
                      startIcon={<SaveIcon />}
                      onClick={() => handleUpdateDescription(editorContent)}
                      disabled={updateFeatureRequest.isPending}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ 
                  mb: { xs: 2, sm: 3 },
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  lineHeight: 1.6
                }}>
                  <ReadOnlyTipTap content={featureRequest.description} />
                </Box>
              )}
            </Box>
          </Paper>

          {/* Comments Section - In Left Column */}
          <Paper 
            elevation={1}
            sx={{ 
              p: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 }
            }}
          >
            <StakeholderReportOrRequestComments
              comments={featureRequest.comments || []}
              onAddComment={handleAddComment}
              isSubmitting={isAddingComment}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} sx={{ order: { xs: 1, md: 2 }, mb: { xs: 3, md: 0 } }}>
          {/* Sidebar */}
          
          <Paper 
            elevation={1}
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 }
            }}
          >
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ 
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
                fontWeight: 600,
                mb: { xs: 1.5, sm: 2 }
              }}
            >
              Details
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 1.5, sm: 2 } }}>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Requested By
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {getRequestedByUser(featureRequest.requestedById)}
                </Typography>
              </Box>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Assigned To
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {featureRequest.assignedToId 
                    ? getRequestedByUser(featureRequest.assignedToId)
                    : "No user assigned yet"}
                </Typography>
              </Box>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Created At
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {featureRequest.createdAt 
                    ? new Date(featureRequest.createdAt).toLocaleDateString() 
                    : "N/A"}
                </Typography>
              </Box>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Last Updated
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {featureRequest.updatedAt 
                    ? new Date(featureRequest.updatedAt).toLocaleDateString() 
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Paper>

          {/* Client Estimates Card */}
          <Paper 
            elevation={1}
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 },
              borderLeft: 4,
              borderColor: 'info.main'
            }}
          >
            <Box
              sx={{ 
                display: "flex", 
                alignItems: "center", 
                gap: 1, 
                mb: { xs: 1.5, sm: 2 } 
              }}
            >
              <MonetizationOnIcon color="info" />
              <Typography 
                variant="h6"
                sx={{ 
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                  fontWeight: 600
                }}
              >
                Client Estimates
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 1.5, sm: 2 } }}>
              {featureRequest.clientDollarAmountBudget && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <MonetizationOnIcon color="action" sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }} />
                  <Box>
                    <Typography 
                      variant="subtitle2"
                      sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                    >
                      Desired Budget
                    </Typography>
                    <Typography 
                      sx={{ 
                        fontWeight: 600,
                        fontSize: { xs: "0.95rem", sm: "1.1rem" },
                        color: "primary.main"
                      }}
                    >
                      ${featureRequest.clientDollarAmountBudget.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              )}
              {featureRequest.clientEstimatedHours && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <AccessTimeIcon color="action" sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }} />
                  <Box>
                    <Typography 
                      variant="subtitle2"
                      sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                    >
                      Estimated Hours
                    </Typography>
                    <Typography 
                      sx={{ 
                        fontWeight: 600,
                        fontSize: { xs: "0.95rem", sm: "1.1rem" }
                      }}
                    >
                      {featureRequest.clientEstimatedHours} hours
                    </Typography>
                  </Box>
                </Box>
              )}
              {/* Desired Timeline */}
              {(featureRequest.desiredStartDate || featureRequest.desiredCompletionDate) && (
                <Box>
                  <Typography 
                    variant="subtitle2"
                    sx={{ 
                      fontSize: { xs: "0.8rem", sm: "0.9rem" },
                      mb: 0.5
                    }}
                  >
                    Desired Timeline
                  </Typography>
                  <Box sx={{ 
                    display: "flex", 
                    flexWrap: "wrap",
                    gap: { xs: 2, sm: 2 } 
                  }}>
                    {featureRequest.desiredStartDate && (
                      <Box>
                        <Typography 
                          variant="caption" 
                          color="text.secondary"
                          sx={{ fontSize: { xs: "0.7rem", sm: "0.75rem" } }}
                        >
                          Start Date
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}>
                          {new Date(featureRequest.desiredStartDate).toLocaleDateString()}
                        </Typography>
                      </Box>
                    )}
                    {featureRequest.desiredCompletionDate && (
                      <Box>
                        <Typography 
                          variant="caption" 
                          color="text.secondary"
                          sx={{ fontSize: { xs: "0.7rem", sm: "0.75rem" } }}
                        >
                          Completion Date
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem" } }}>
                          {new Date(featureRequest.desiredCompletionDate).toLocaleDateString()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>

          {/* Admin Approval Details */}
          {featureRequest.approvedByAdminUserId && (
            <Paper 
              elevation={1}
              sx={{ 
                p: { xs: 2, sm: 3 }, 
                mb: { xs: 2, sm: 3 },
                borderRadius: { xs: 1.5, sm: 2 },
                borderLeft: 4,
                borderColor: "success.main"
              }}
            >
              <Box
                sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  gap: 1, 
                  mb: { xs: 1.5, sm: 2 } 
                }}
              >
                <CheckCircleIcon color="success" />
                <Typography 
                  variant="h6"
                  sx={{ 
                    fontSize: { xs: "1.1rem", sm: "1.25rem" },
                    fontWeight: 600
                  }}
                >
                  FreeTech Approval
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 1.5, sm: 2 } }}>
                {featureRequest.freetechEstimatedHours && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <AccessTimeIcon color="action" sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }} />
                    <Box>
                      <Typography 
                        variant="subtitle2"
                        sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                      >
                        Estimated Hours
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                        {featureRequest.freetechEstimatedHours} hours
                      </Typography>
                    </Box>
                  </Box>
                )}
                {featureRequest.freetechEstimatedDollarAmount && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <MonetizationOnIcon color="action" sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }} />
                    <Box>
                      <Typography 
                        variant="subtitle2"
                        sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem" } }}
                      >
                        Budget
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                        ${featureRequest.freetechEstimatedDollarAmount.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box>
                  <Typography 
                    variant="subtitle2" 
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Approved By
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                    {getRequestedByUser(featureRequest.approvedByAdminUserId)}
                  </Typography>
                </Box>
                <Box>
                  <Typography 
                    variant="subtitle2" 
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Approved On
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                    {new Date(
                      featureRequest.approvedByAdminDate || ""
                    ).toLocaleDateString()}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          )}

          {/* Rejection Details */}
          {featureRequest.rejectedByAdminUserId && (
            <Paper 
              elevation={1}
              sx={{ 
                p: { xs: 2, sm: 3 }, 
                mb: { xs: 2, sm: 3 },
                borderRadius: { xs: 1.5, sm: 2 },
                borderLeft: 4,
                borderColor: "error.main"
              }}
            >
              <Box
                sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  gap: 1, 
                  mb: { xs: 1.5, sm: 2 } 
                }}
              >
                <Typography 
                  variant="h6" 
                  color="error"
                  sx={{ 
                    fontSize: { xs: "1.1rem", sm: "1.25rem" },
                    fontWeight: 600
                  }}
                >
                  Rejection Details
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 1.5, sm: 2 } }}>
                <Box>
                  <Typography 
                    variant="subtitle2" 
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Rejected By
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                    {getRequestedByUser(featureRequest.rejectedByAdminUserId)}
                  </Typography>
                </Box>
                <Box>
                  <Typography 
                    variant="subtitle2" 
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Rejected On
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                    {new Date(
                      featureRequest.rejectedByAdminDate || ""
                    ).toLocaleDateString()}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          )}

          {/* Linked Freelo Tickets */}
          <Paper 
            elevation={1}
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 }
            }}
          >
            <Box sx={{ 
              display: "flex", 
              alignItems: "center", 
              gap: 1, 
              mb: { xs: 1.5, sm: 2 } 
            }}>
              <LinkIcon color="primary" sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }} />
              <Typography 
                variant="h6"
                sx={{ 
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                  fontWeight: 600
                }}
              >
                Freelo Tickets
              </Typography>
            </Box>
            {tickets && tickets.length > 0 ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 1, sm: 2 } }}>
                {tickets.map((ticket: FreeloCard) => (
                  <Paper
                    key={ticket.id}
                    variant="outlined"
                    sx={{
                      p: { xs: 1.5, sm: 2 },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: { xs: 1, sm: 1.5 },
                      "&:hover": {
                        bgcolor: "action.hover",
                        transform: "translateX(4px)",
                        cursor: "pointer",
                      },
                      transition: "all 0.2s ease-in-out",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(
                          `${window.location.origin}/freelo/${ticket.boardId}#${ticket.humanReadableId}`,
                          "_blank"
                        );
                      }}
                    >
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: "medium",
                          fontSize: { xs: "0.9rem", sm: "1rem" }
                        }}
                      >
                        #{ticket.humanReadableId}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontSize: { xs: "0.75rem", sm: "0.8rem" } }}
                      >
                        Assigned to{" "}
                        {getRequestedByUser(ticket.assignee)}
                      </Typography>
                    </Box>
                    <Chip
                      label={ticket.progress === 100 ? "Done" : "In Progress"}
                      color={ticket.progress === 100 ? "success" : "info"}
                      size="small"
                      sx={{ 
                        height: { xs: 24, sm: 24 },
                        fontSize: { xs: "0.7rem", sm: "0.75rem" }
                      }}
                    />
                  </Paper>
                ))}
              </Box>
            ) : (
              <Typography 
                color="text.secondary"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
                No tickets created yet
              </Typography>
            )}
          </Paper>

          {/* Project Information */}
          {project && (
            <Paper 
              elevation={1}
              sx={{ 
                p: { xs: 2, sm: 3 },
                mb: { xs: 2, sm: 3 },
                borderRadius: { xs: 1.5, sm: 2 }
              }}
            >
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{ 
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                  fontWeight: 600,
                  mb: { xs: 1.5, sm: 2 }
                }}
              >
                Project Information
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 1.5, sm: 2 } }}>
                <Box>
                  <Typography 
                    variant="subtitle2" 
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Project Name
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                    {project.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography 
                    variant="subtitle2" 
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Project Status
                  </Typography>
                  <Chip
                    label={project.status}
                    size="small"
                    color={
                      project.status === "active"
                        ? "success"
                        : project.status === "on_hold"
                          ? "warning"
                          : "default"
                    }
                    sx={{ 
                      height: { xs: 24, sm: 24 },
                      fontSize: { xs: "0.7rem", sm: "0.75rem" },
                      textTransform: "capitalize"
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          )}
          
          {/* Attachments Section - Now at the end */}
          <Paper 
            elevation={1}
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              borderRadius: { xs: 1.5, sm: 2 }
            }}
          >
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ 
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
                fontWeight: 600,
                mb: { xs: 1.5, sm: 2 }
              }}
            >
              Attachments
            </Typography>
            
            {/* File upload dropzone */}
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed',
                borderColor: isDragActive ? 'primary.main' : 'divider',
                borderRadius: 1,
                p: 2,
                mb: 2,
                textAlign: 'center',
                cursor: 'pointer',
                bgcolor: isDragActive ? 'action.hover' : 'background.paper',
                transition: 'all 0.2s ease',
              }}
            >
              <input {...getInputProps()} />
              <Box sx={{ p: 2 }}>
                <CloudUploadIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="body1" gutterBottom>
                  {isDragActive ? 'Drop files here' : 'Drag & drop files here'}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  or click to select files
                </Typography>
              </Box>
            </Box>

            {uploadError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {uploadError}
              </Alert>
            )}

            {uploadFiles.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Files to upload:
                </Typography>
                {uploadFiles.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 1,
                      mb: 1,
                      bgcolor: 'background.default',
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" noWrap sx={{ maxWidth: '70%' }}>
                      {file.name}
                    </Typography>
                    <Button
                      size="small"
                      color="error"
                      onClick={() => handleRemoveUploadFile(index)}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  onClick={handleUploadAttachments}
                  disabled={isUploading || uploadFiles.length === 0}
                  sx={{ mt: 1 }}
                >
                  {isUploading ? 'Uploading...' : 'Upload Files'}
                </Button>
              </Box>
            )}

            {/* Existing attachments */}
            {featureRequest.attachments && featureRequest.attachments.length > 0 ? (
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                {featureRequest.attachments.map((attachment, index) => {
                  // Determine if it's an image based on file extension
                  const fileExt = attachment.name.split('.').pop()?.toLowerCase();
                  const isImage = fileExt && ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(fileExt);
                  
                  return (
                    <Grid item xs={6} key={index}>
                      <Card 
                        sx={{ 
                          cursor: "pointer",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: { xs: 1, sm: 1.5 },
                          transition: "transform 0.2s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.02)",
                            boxShadow: 3
                          },
                          position: "relative"
                        }}
                        onClick={() => handleAttachmentClick(attachment)}
                      >
                        {/* Delete button */}
                        <IconButton
                          size="small"
                          color="error"
                          sx={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            bgcolor: "rgba(255, 255, 255, 0.8)",
                            "&:hover": {
                              bgcolor: "rgba(255, 255, 255, 0.9)",
                            },
                            zIndex: 1
                          }}
                          onClick={(e) => handleRemoveAttachment(e, attachment.id)}
                          disabled={isRemovingAttachment === attachment.id || isRemovingAttachmentMutation}
                        >
                          {isRemovingAttachment === attachment.id ? (
                            <CircularProgress size={20} color="error" />
                          ) : (
                            <DeleteIcon fontSize="small" />
                          )}
                        </IconButton>
                        
                        <CardMedia
                          component="img"
                          height="100"
                          image={
                            isImage
                              ? attachment.url
                              : "/images/file-icon.png"
                          }
                          alt={attachment.name}
                          sx={{ 
                            objectFit: isImage ? "cover" : "contain",
                            p: isImage ? 0 : 2,
                            bgcolor: isImage ? "transparent" : "grey.100"
                          }}
                        />
                        <CardContent sx={{ p: { xs: 1, sm: 1.5 }, flexGrow: 1 }}>
                          <Typography 
                            variant="body2" 
                            noWrap 
                            title={attachment.name}
                            sx={{ fontSize: { xs: "0.7rem", sm: "0.75rem" } }}
                          >
                            {attachment.name}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            color="text.secondary"
                            sx={{ fontSize: { xs: "0.6rem", sm: "0.65rem" } }}
                          >
                            {DateTime.fromISO(attachment.uploadedAt).toRelative()}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography color="text.secondary" variant="body2">
                No attachments yet
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* File Viewer Dialog */}
      <Dialog
        open={!!selectedAttachment}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        {selectedAttachment && (
          <>
            <DialogTitle>
              {selectedAttachment.name}
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {selectedAttachment.url.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                <img 
                  src={selectedAttachment.url} 
                  alt={selectedAttachment.name} 
                  style={{ maxWidth: '100%', maxHeight: '70vh', display: 'block', margin: '0 auto' }} 
                />
              ) : (
                <Box sx={{ textAlign: 'center', py: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    This file cannot be previewed directly.
                  </Typography>
                  <Button 
                    variant="contained" 
                    color="primary"
                    href={selectedAttachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download File
                  </Button>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
              <Button 
                color="primary" 
                href={selectedAttachment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open in New Tab
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDeleteAttachment}
        aria-labelledby="delete-attachment-dialog-title"
        aria-describedby="delete-attachment-dialog-description"
      >
        <DialogTitle id="delete-attachment-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography id="delete-attachment-dialog-description">
            Are you sure you want to remove this attachment? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteAttachment} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={confirmDeleteAttachment} 
            color="error" 
            variant="contained"
            disabled={isRemovingAttachment !== null}
            startIcon={isRemovingAttachment ? <CircularProgress size={16} color="inherit" /> : <DeleteIcon />}
          >
            {isRemovingAttachment ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
