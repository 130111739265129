/* eslint-disable */

// TODO: Remove eslint-disable and chanlde user info type in set state

import {
  getAuth,
  signInWithEmailAndPassword,
  MultiFactorError,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "@firebase/auth";
import React from "react";
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { FirebaseError } from "firebase/app";
import { privateUserConverter } from"@freetech/models/converters";
import { LoginCredentials } from "types/user/user-props";
// import { detectIncognitoMode } from "./detectIncognitoMode";

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const handleLoginWithEmail = async (
  { email, password, rememberMe }: LoginCredentials,
  setUserInfo: React.Dispatch<any>
): Promise<MultiFactorError | FirebaseError | void> => {
  const auth = getAuth();
  const db = getFirestore();
  let lastError: any;

  // Check for incognito mode
  // const isIncognito = await detectIncognitoMode();
  // if (isIncognito) {
  //   throw new FirebaseError(
  //     "auth/browser-not-supported",
  //     "FreeTech does not support incognito mode or private browsing."
  //   );
  // }

  try {
    // Set persistence based on rememberMe
    await setPersistence(
      auth,
      rememberMe ? browserLocalPersistence : browserSessionPersistence
    );

    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const userDoc = await getDoc(
      doc(db, "users", user.uid).withConverter(privateUserConverter)
    );
    if (!userDoc.exists()) {
      throw new Error("User data not found");
    }

    const userData = userDoc.data();
    setUserInfo(userData);

    return;
  } catch (error: any) {
    lastError = error;
    console.error(`Email login attempt failed:`, {
      error,
      code: error?.code,
      message: error?.message,
      name: error?.name,
    });

    if (
      error?.code === "auth/multi-factor-auth-required" ||
      error?.code === "auth/invalid-credential" ||
      error?.code === "auth/browser-not-supported" ||
      (error?.message &&
        (error.message.includes("ReCAPTCHA") ||
          error.message.includes("AppCheck")))
    ) {
      throw error;
    }

    // Only retry if it's the specific deadline exceeded error
    if (
      error?.code !== "auth/internal-error" ||
      !error?.message?.includes("Cloud function deadline exceeded")
    ) {
      throw error;
    }
  }

  throw lastError;
};
