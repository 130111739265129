import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import {
  createReferral,
  getUserReferrals,
  updateReferralStatus,
} from "core/referrals";
import {
  Referral,
  ReferralFormData,
  ReferralStatus,
} from "@freetech/models/referrals";

export const useFreelancerReferrals = () => {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);

  // Get all referrals made by the current freelancer
  const {
    data: referrals = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["freelancerReferrals", currentUser?.uid],
    queryFn: () => {
      try {
        return getUserReferrals(currentUser?.uid || "");
      } catch (err) {
        const error = err as Error;
        console.error("Error fetching referrals:", error);
        setError(error);
        throw error;
      }
    },
    enabled: !!currentUser?.uid,
  });

  // Create a new referral
  const { mutateAsync: submitReferral, isPending: isSubmitting } = useMutation({
    mutationFn: async (formData: ReferralFormData) => {
      if (!currentUser?.uid || !currentUser.displayName || !currentUser.email) {
        throw new Error("User information is missing");
      }
      console.log("formData", formData);

      return createReferral(
        formData,
        currentUser.uid,
        "freelancer",
        currentUser.displayName,
        currentUser.email
      );
    },
    onSuccess: () => {
      // Invalidate the referrals query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ["freelancerReferrals", currentUser?.uid],
      });
    },
    onError: (err: Error) => {
      console.error("Error submitting referral:", err);
      setError(err);
    },
  });

  // Update a referral's status
  const { mutateAsync: updateStatus, isPending: isUpdating } = useMutation({
    mutationFn: async ({
      referralId,
      status,
      note,
    }: {
      referralId: string;
      status: ReferralStatus;
      note?: string;
    }) => {
      return updateReferralStatus(referralId, status, note);
    },
    onSuccess: () => {
      // Invalidate the referrals query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ["freelancerReferrals", currentUser?.uid],
      });
    },
    onError: (err: Error) => {
      console.error("Error updating referral status:", err);
      setError(err);
    },
  });

  return {
    referrals,
    isLoading,
    isError,
    error,
    submitReferral,
    isSubmitting,
    updateStatus,
    isUpdating,
    refetchReferrals: refetch,
  };
};

export default useFreelancerReferrals;
