import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { Stakeholder } from "@freetech/models/user";

export const updateStakeholder = async (
  stakeholderId: string,
  updates: Partial<Stakeholder>
): Promise<void> => {
  try {
    const stakeholderRef = doc(db, "users", stakeholderId);
    await updateDoc(stakeholderRef, {
      ...updates,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error updating stakeholder:", error);
    throw error;
  }
}; 