import { functionsBaseUrl } from "config/functionsBaseUrl";
import { getHeaders } from "../getHeaders";
import { 
  IncreaseExternalAccountDetails, 
  FreelancerPayment, 
  AccountBalancesResponse, 
  TransactionSummary, 
  RecentTransactionsResponse 
} from "@freetech/models/bank";
import { encryptPayload } from "../security/encryptPayload";

export const bankFunctions = {
  admin: {
    createExternalAccountOutboundTestPayment: async (
      userId: string,
      amount: number,
      memo: string,
      paymentDate: string,
      direction: "outbound" | "inbound"
    ) => {
      const url = `${functionsBaseUrl}/bank/admin/createExternalAccountOutboundTestPayment`;
      const method = "POST";

      const headers = await getHeaders();
      const response = await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify({
          userId: userId,
          amount: amount,
          memo: memo,
          paymentDate: paymentDate,
          direction: direction,
        }),
      });
      const data = await response.json();
      return data;
    },
    createFreelancerPayment: async (
      userId: string,
      freelancerPayment: Omit<FreelancerPayment, "id">
    ) => {
      const url = `${functionsBaseUrl}/bank/admin/createFreelancerPayment`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          freelancerPayment: freelancerPayment,
        }),
      });
      return response;
    },
    resubmitAchPrenotification: async (userId: string) => {
      const url = `${functionsBaseUrl}/bank/admin/resubmitAchPrenotification`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
        }),
      });
      const data = await response.json();
      return data;
    },
    getAccountBalances: async (): Promise<AccountBalancesResponse> => {
      const url = `${functionsBaseUrl}/bank/admin/getAccountBalances`;
      const method = "GET";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
      });
      
      if (!response.ok) {
        throw new Error(`Failed to get account balances: ${response.statusText}`);
      }
      
      return await response.json();
    },
    getTransactionSummary: async (timeRange: "7d" | "30d" | "90d" | "1y" = "30d"): Promise<TransactionSummary> => {
      const url = `${functionsBaseUrl}/bank/admin/getTransactionSummary?timeRange=${timeRange}`;
      const method = "GET";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
      });
      
      if (!response.ok) {
        throw new Error(`Failed to get transaction summary: ${response.statusText}`);
      }
      
      return await response.json();
    },
    getRecentTransactions: async (
      params: {
        limit?: number;
        timeRange?: "7d" | "30d" | "90d" | "1y";
        transactionId?: string;
      } = {}
    ): Promise<RecentTransactionsResponse> => {
      const queryParams = new URLSearchParams();
      if (params.limit) queryParams.append("limit", params.limit.toString());
      if (params.timeRange) queryParams.append("timeRange", params.timeRange);
      if (params.transactionId) queryParams.append("transactionId", params.transactionId);
      
      const queryString = queryParams.toString();
      const url = `${functionsBaseUrl}/bank/admin/getRecentTransactions${queryString ? `?${queryString}` : ""}`;
      const method = "GET";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
      });
      
      if (!response.ok) {
        throw new Error(`Failed to get recent transactions: ${response.statusText}`);
      }
      
      return await response.json();
    },
  },

  freelancer: {
    createFreelancerPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname: string;
      }
    ): Promise<{ success: boolean; error?: string }> => {
      const url = `${functionsBaseUrl}/bank/freelancer/createMyFreelancerPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });

      const data = await response.json();
      return data;
    },
    getMyFreelancerPaymentProfile:
      async (): Promise<IncreaseExternalAccountDetails> => {
        const url = `${functionsBaseUrl}/bank/freelancer/getMyFreelancerPaymentProfile`;
        const method = "GET";

        const response = await fetch(url, {
          method: method,
          headers: await getHeaders(),
        });
        const data = await response.json();
        return data;
      },

    updateFreelancerPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname?: string;
      }
    ) => {
      const url = `${functionsBaseUrl}/bank/freelancer/updateMyFreelancerPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });
      const data = await response.json();
      return data;
    },
  },

  stakeholder: {
    createStakeholderPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname: string;
      }
    ): Promise<void> => {
      const url = `${functionsBaseUrl}/bank/stakeholder/createMyStakeHolderPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to create stakeholder payment profile");
      }
      return;
    },
    getMyStakeholderPaymentProfile:
      async (): Promise<IncreaseExternalAccountDetails> => {
        const url = `${functionsBaseUrl}/bank/stakeholder/getMyStakeHolderPaymentProfile`;
        const method = "GET";

        const response = await fetch(url, {
          method: method,
          headers: await getHeaders(),
        });

        if (!response.ok) {
          throw new Error("Failed to get stakeholder payment profile");
        }

        const data = await response.json();
        return data;
      },
    payMyStakeholderInvoice: async (
      clientId: string,
      invoiceId: string,
      amount?: number
    ): Promise<void> => {
      const url = `${functionsBaseUrl}/bank/stakeholder/payMyStakeholderInvoice`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          clientId: clientId,
          invoiceId: invoiceId,
          amount: amount
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to pay my stakeholder invoice");
      }

      return;
    },
    updateStakeholderPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname?: string;
      }
    ): Promise<void> => {
      const url = `${functionsBaseUrl}/bank/stakeholder/updateMyStakeHolderPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });
      if (!response.ok) {
        console.log("response", response);
        throw new Error(
          `Failed to update stakeholder payment profile: ${await response.json().toString()}`
        );
      }
      return;
    },
  },
  shared: {
    getAchPrenotificationStatus: async (userId: string) => {
      const url = `${functionsBaseUrl}/bank/freelancer/getAchPrenotificationStatus`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
        }),
      });
      const data = await response.json();
      return data;
    },
  },
};
