import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Collapse,
  Chip,
  Stack,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Tooltip,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BugReportIcon from "@mui/icons-material/BugReport";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LinkIcon from "@mui/icons-material/Link";
import { useLocalStorageBoolState } from "hooks/useLocalStorage";
import { Client } from "@freetech/models/projects";
import { Freelancer, IBugReport, ICreateBugReport } from "@freetech/models";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useAdminBugReport } from "hooks/admin/useAdminBugReport";
import { useFreelo } from "hooks/freelo/useFreelo";
import { Card as FreeloCard } from "@freetech/models/freelo";
import ReadOnlyTipTap from 'components/TipTap/ReadOnlyTipTap';
import { BugReportCard, CreateTicketDialog, CreateBugReportDialog } from "./components";
import { useStakeholderBugReport } from "hooks/stakeholder/useStakeholderBugReport";
import { useFreeloBoard } from "hooks/freelo/useFreeloBoard";
interface AssociatedBugReportsProps {
  client: Client;
  clientId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`bug-report-tabpanel-${index}`}
      aria-labelledby={`bug-report-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

interface ApprovalDialogProps {
  open: boolean;
  onClose: () => void;
  onApprove: (engineerId: string) => void;
  freelancers: Freelancer[] | undefined;
  isLoading: boolean;
  bugReport: IBugReport;
}

const ApprovalDialog: React.FC<ApprovalDialogProps> = ({
  open,
  onClose,
  onApprove,
  freelancers,
  isLoading,
  bugReport,
}) => {
  const [selectedEngineerId, setSelectedEngineerId] = useState<string>("");

  const handleApprove = () => {
    onApprove(selectedEngineerId);
    setSelectedEngineerId("");
  };

  const handleClose = () => {
    setSelectedEngineerId("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign Engineer to Bug Report</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Bug Report: {bugReport.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {bugReport.description}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <List sx={{ width: "100%" }}>
            {freelancers?.map((freelancer) => (
              <ListItemButton
                key={freelancer.id}
                selected={selectedEngineerId === freelancer.id}
                onClick={() => setSelectedEngineerId(freelancer.id)}
                sx={{
                  borderRadius: 1,
                  mb: 1,
                  "&.Mui-selected": {
                    backgroundColor: "primary.light",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${freelancer.firstName} ${freelancer.lastName}`}
                  secondary={freelancer.role || "Engineer"}
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleApprove}
          color="primary"
          variant="contained"
          disabled={!selectedEngineerId}
        >
          Assign & Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ExtendedCard extends FreeloCard {
  bugReportId?: string[];
}

const AssociatedBugReports: React.FC<AssociatedBugReportsProps> = ({
  client,
  clientId,
}) => {
  const [isVisible, setIsVisible] = useLocalStorageBoolState(
    "isAssociatedBugReportsVisible",
    false
  );
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [approvalDialog, setApprovalDialog] = useState<{
    open: boolean;
    bugReport: IBugReport | null;
  }>({
    open: false,
    bugReport: null,
  });
  const [createTicketDialog, setCreateTicketDialog] = useState<{
    open: boolean;
    bugReport: IBugReport | null;
  }>({
    open: false,
    bugReport: null,
  });
  const [createDialog, setCreateDialog] = useState(false);

  const { freelancers, isLoading: isLoadingFreelancers } = useAdminFreelancers();
  const {
    bugReports,
    isLoading: isLoadingReports,
    approveBugReport,
    rejectBugReport,
  } = useAdminBugReport(clientId);
  const project = client.projects?.[0];
  const { data: board } = useFreeloBoard(project?.freeloBoardId || "");

  const { submitBugReport, isSubmitting: isCreating } = useStakeholderBugReport({
    clientId,
    projectId: project?.id || "",
  });

  const handleOpenApprovalDialog = (bugReport: IBugReport) => {
    setApprovalDialog({
      open: true,
      bugReport,
    });
  };

  const handleCloseApprovalDialog = () => {
    setApprovalDialog({
      open: false,
      bugReport: null,
    });
  };

  const handleApproveBugReport = async (engineerId: string) => {
    if (!approvalDialog.bugReport) return;

    try {
      await approveBugReport(approvalDialog.bugReport, engineerId);
      handleCloseApprovalDialog();
    } catch (error) {
      setError("Failed to update bug report. Please try again.");
      console.error("Error updating bug report:", error);
    }
  };

  const handleRejectBugReport = async (bugReport: IBugReport) => {
    try {
      await rejectBugReport(bugReport);
    } catch (error) {
      setError("Failed to reject bug report. Please try again.");
      console.error("Error rejecting bug report:", error);
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getPendingReportsCount = () => {
    return bugReports?.filter((br) => br.status === "open").length || 0;
  };

  const getInProgressReportsCount = () => {
    return bugReports?.filter((br) => br.status === "in_progress").length || 0;
  };

  const handleOpenCreateTicketDialog = (report: IBugReport) => {
    setCreateTicketDialog({
      open: true,
      bugReport: report,
    });
  };

  const handleCloseCreateTicketDialog = () => {
    setCreateTicketDialog({
      open: false,
      bugReport: null,
    });
  };

  const getLinkedTickets = (report: IBugReport) => {
    if (!board?.lists || !report.freeloTicketIds) return [];
    return board.lists
      .flatMap((list) => list.cards || [])
      .filter((card) => report.freeloTicketIds?.includes(card.id));
  };

  const handleCreateBugReport = async (data: ICreateBugReport, files: File[]) => {
    try {
      await submitBugReport(data, files);
      setError("Bug report created successfully!");
      setCreateDialog(false);
    } catch (error) {
      setError("Failed to create bug report. Please try again.");
      console.error("Error creating bug report:", error);
    }
  };

  return (
    <>
      <Card
        elevation={2}
        sx={{
          width: "100%",
          mb: 3,
          background: "linear-gradient(45deg, #c62828 30%, #d32f2f 90%)",
        }}
      >
        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <Box display="flex" alignItems="center">
              <BugReportIcon sx={{ fontSize: 28, mr: 2 }} />
              <Typography variant="h6" component="h2">
                Bug Reports
                {getPendingReportsCount() > 0 && (
                  <Chip
                    label={getPendingReportsCount()}
                    color="error"
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Typography>
              <IconButton
                onClick={() => setIsVisible(!isVisible)}
                sx={{ color: "white", ml: 2 }}
                size="small"
              >
                {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => setCreateDialog(true)}
            >
              Create Bug Report
            </Button>
          </Box>

          <Collapse in={isVisible}>
            <Box sx={{ mt: 2, bgcolor: "background.paper", borderRadius: 1 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="bug report tabs"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Open
                      {getPendingReportsCount() > 0 && (
                        <Chip
                          label={getPendingReportsCount()}
                          color="error"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      In Progress
                      {getInProgressReportsCount() > 0 && (
                        <Chip
                          label={getInProgressReportsCount()}
                          color="success"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                />
                <Tab label="Resolved" />
                <Tab label="Closed" />
              </Tabs>

              {isLoadingReports ? (
                <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <TabPanel value={tabValue} index={0}>
                    {bugReports
                      ?.filter((br) => br.status === "open")
                      .map((report) => (
                        <BugReportCard
                          key={report.id}
                          report={report}
                          linkedTickets={getLinkedTickets(report)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    {bugReports
                      ?.filter((br) => br.status === "in_progress")
                      .map((report) => (
                        <BugReportCard
                          key={report.id}
                          report={report}
                          linkedTickets={getLinkedTickets(report)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    {bugReports
                      ?.filter((br) => br.status === "resolved")
                      .map((report) => (
                        <BugReportCard
                          key={report.id}
                          report={report}
                          linkedTickets={getLinkedTickets(report)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    {bugReports
                      ?.filter((br) => br.status === "closed")
                      .map((report) => (
                        <BugReportCard
                          key={report.id}
                          report={report}
                          linkedTickets={getLinkedTickets(report)}
                          onCreateTicket={handleOpenCreateTicketDialog}
                        />
                      ))}
                  </TabPanel>
                </>
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>

      {approvalDialog.bugReport && (
        <ApprovalDialog
          open={approvalDialog.open}
          onClose={handleCloseApprovalDialog}
          onApprove={handleApproveBugReport}
          freelancers={freelancers}
          isLoading={isLoadingFreelancers}
          bugReport={approvalDialog.bugReport}
        />
      )}

      {createTicketDialog.bugReport && (
        <CreateTicketDialog
          open={createTicketDialog.open}
          onClose={handleCloseCreateTicketDialog}
          bugReport={createTicketDialog.bugReport}
          boardId={project?.freeloBoardId || ""}
          listId={board?.lists?.[0]?.id || ""}
          onSuccess={() => setError("Freelo ticket created successfully!")}
          onError={setError}
        />
      )}

      <CreateBugReportDialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
        onSubmit={handleCreateBugReport}
        clientId={clientId}
        projectId={project?.id || ""}
        isSubmitting={isCreating}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </>
  );
};

export default AssociatedBugReports; 