import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import { Typography, Box, CircularProgress, Paper, Grid, IconButton } from "@mui/material";
import { motion } from "framer-motion"; 
import { useTheme } from "@mui/material/styles";
import { Edit as EditIcon, Save as SaveIcon } from "@mui/icons-material";

import ProfileInfo from "./components/ProfileInfo";
import DocumentsSection from "./components/DocumentsSection";
import AgreementSection from "./components/AgreementSection";
import FreelancerReadOnlyInfo from "./components/FreelancerReadOnlyInfo";
import { useAuth } from "hooks/auth/useAuth";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";

const MotionBox = motion(Box);
const MotionPaper = motion(Paper);

// Add these form styles
const formSectionStyles = {
  bgcolor: "white",
  borderRadius: 2,
  p: 3,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
  border: "1px solid",
  borderColor: "grey.200",
  "& .MuiTextField-root, & .MuiOutlinedInput-root": {
    bgcolor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey.300",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey.400",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary.main",
    }
  },
  "& .MuiInputLabel-root": {
    color: "grey.700",
  },
  "& .MuiInputBase-input": {
    color: "grey.900",
  },
  "& .MuiTypography-root": {
    color: "grey.900",
  },
  "& .MuiTypography-secondary": {
    color: "grey.600",
  }
};

export const FreelancerProfile: React.FC = () => {
  const { userInfo } = useAuth();
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);

  if (!userInfo?.id) {
    return <Typography>No user data found</Typography>;
  }

  const { 
    freelancerProfile, 
    isLoadingFreelancerProfile, 
    updateFreelancerProfile, 
    freelancerDocuments,
    uploadFreelancerDocument
  } = useFreelancerProfile();

  if (isLoadingFreelancerProfile) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!freelancerProfile) {
    return <Typography>No freelancer data found</Typography>;
  }

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={theme.customComponents.container.main}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <MotionPaper
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            elevation={2}
            sx={theme.customComponents.card.dark}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 2 
            }}>
              <Typography variant="h5">Profile Information</Typography>
              <IconButton 
                sx={{ 
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </Box>
            <ProfileInfo
              freelancer={freelancerProfile}
              onUpdate={updateFreelancerProfile}
              userInfo={userInfo}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </MotionPaper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <MotionPaper
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            elevation={2}
            sx={theme.customComponents.card.dark}
          >
            <AgreementSection freelancerId={freelancerProfile.id} />
          </MotionPaper>
        </Grid>

        <Grid item xs={12}>
          <Box sx={formSectionStyles}>
            <FreelancerReadOnlyInfo freelancer={freelancerProfile} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={formSectionStyles}>
            <DocumentsSection 
              documents={freelancerDocuments || []} 
              onUploadDocument={uploadFreelancerDocument}
            />
          </Box>
        </Grid>
      </Grid>
    </MotionBox>
  );
};