import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Divider,
  Tooltip
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StripeIcon from '@mui/icons-material/Payment';
import BankIcon from '@mui/icons-material/AccountBalance';

interface TransactionSummaryProps {
  timeRange: "7d" | "30d" | "90d" | "1y";
  setTimeRange: (timeRange: "7d" | "30d" | "90d" | "1y") => void;
  loading?: boolean;
  summary: {
    totalInbound: number;
    totalOutbound: number;
    netBalance: number;
    pendingInbound: number;
    pendingOutbound: number;
    totalPending: number;
    stripeInbound: number;
    stripeOutbound: number;
    increaseInbound: number;
    increaseOutbound: number;
  };
  formatCurrency: (amount: number | undefined) => string;
}

const TransactionSummary: React.FC<TransactionSummaryProps> = ({
  timeRange,
  setTimeRange,
  loading,
  summary,
  formatCurrency
}) => {
  const handleTimeRangeChange = (
    event: React.MouseEvent<HTMLElement>,
    newTimeRange: "7d" | "30d" | "90d" | "1y" | null
  ) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <CircularProgress />
      </Box>
    );
  }

  // Main summary items to display
  const mainSummaryItems = [
    {
      label: "Total Inbound",
      value: formatCurrency(summary.totalInbound),
      icon: <ArrowUpwardIcon color="success" />,
      color: "success.main"
    },
    {
      label: "Total Outbound",
      value: formatCurrency(summary.totalOutbound),
      icon: <ArrowDownwardIcon color="error" />,
      color: "error.main"
    },
    {
      label: "Net Balance",
      value: formatCurrency(summary.netBalance),
      icon: <AccountBalanceIcon color="primary" />,
      color: "primary.main"
    },
    {
      label: "Pending",
      value: formatCurrency(summary.totalPending),
      icon: <AccessTimeIcon color="warning" />,
      color: "warning.main"
    }
  ];

  // Source breakdown items
  const sourceBreakdownItems = [
    {
      label: "Stripe Inbound",
      value: formatCurrency(summary.stripeInbound),
      icon: <StripeIcon />,
      color: "success.main"
    },
    {
      label: "Stripe Outbound",
      value: formatCurrency(summary.stripeOutbound),
      icon: <StripeIcon />,
      color: "error.main"
    },
    {
      label: "Bank Inbound",
      value: formatCurrency(summary.increaseInbound),
      icon: <BankIcon />,
      color: "success.main"
    },
    {
      label: "Bank Outbound",
      value: formatCurrency(summary.increaseOutbound),
      icon: <BankIcon />,
      color: "error.main"
    }
  ];

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Typography variant="subtitle1" color="text.secondary">
          Transaction Summary
        </Typography>
        <ToggleButtonGroup
          value={timeRange}
          exclusive
          onChange={handleTimeRangeChange}
          size="small"
        >
          <ToggleButton value="7d">7d</ToggleButton>
          <ToggleButton value="30d">30d</ToggleButton>
          <ToggleButton value="90d">90d</ToggleButton>
          <ToggleButton value="1y">1y</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Main summary */}
      <Grid container spacing={2}>
        {mainSummaryItems.map((item, index) => (
          <Grid item xs={6} key={index}>
            <Paper elevation={0} sx={{ p: 2, height: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                {item.icon}
                <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                  {item.label}
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ fontWeight: "bold", color: item.color }}>
                {item.value}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Source Breakdown */}
      <Box sx={{ mt: 3, mb: 1 }}>
        <Divider>
          <Typography variant="caption" color="text.secondary">
            Source Breakdown
          </Typography>
        </Divider>
      </Box>
      
      <Grid container spacing={2}>
        {sourceBreakdownItems.map((item, index) => (
          <Grid item xs={6} sm={3} key={`source-${index}`}>
            <Tooltip title={item.label}>
              <Paper elevation={0} sx={{ p: 1, height: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                  {item.icon}
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 0.5, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {item.label}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ color: item.color, fontWeight: "medium" }}>
                  {item.value}
                </Typography>
              </Paper>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TransactionSummary; 