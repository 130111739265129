import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { portalFunctions } from "core/functions/portalFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { toast } from "react-toastify";

interface GenerateCSADocumentParams {
  csaId: string;
  clientId: string;
  projectId: string;
  stakeholderId?: string;
}

interface ProcessSignedCSAParams {
  documentHash: string;
  clientId: string;
  csaId: string;
}

export const useAdminSignCSAs = () => {
  const { userInfo } = useAuth();

  const generateCSADocument = useMutation({
    mutationFn: async (params: GenerateCSADocumentParams) => {
      if (!userInfo) {
        throw new Error("User not authenticated");
      }

      // First, ensure the CSA has the projectId set
      // We'll update the CSA document in the CSAExplorer component

      const response = await portalFunctions.eversign.generateCSADocument({
        csaId: params.csaId,
        clientId: params.clientId,
        adminUserId: userInfo.id,
        projectId: params.projectId,
        stakeholderId: params.stakeholderId,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to generate CSA document");
      }

      return response.json();
    },
    onSuccess: () => {
      toast.success("CSA document generated successfully");
    },
    onError: (error: Error) => {
      toast.error(`Error generating CSA document: ${error.message}`);
    },
  });

  const processSignedCSA = useMutation({
    mutationFn: async (params: ProcessSignedCSAParams) => {
      const response = await portalFunctions.eversign.processSignedCSA({
        documentHash: params.documentHash,
        clientId: params.clientId,
        csaId: params.csaId,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to process signed CSA");
      }

      return response.json();
    },
    onSuccess: () => {
      toast.success("Signed CSA processed successfully");
    },
    onError: (error: Error) => {
      toast.error(`Error processing signed CSA: ${error.message}`);
    },
  });

  const cancelCSADocument = useMutation({
    mutationFn: async (documentHash: string) => {
      const response =
        await portalFunctions.eversign.cancelDocument(documentHash);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to cancel CSA document");
      }

      return response.json();
    },
    onSuccess: () => {
      toast.success("CSA document canceled successfully");
    },
    onError: (error: Error) => {
      toast.error(`Error canceling CSA document: ${error.message}`);
    },
  });

  return {
    generateCSADocument,
    processSignedCSA,
    cancelCSADocument,
  };
};

export default useAdminSignCSAs;
