import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "config/firebase";
import { timesheetConverter } from "@freetech/models/converters";
import { Timesheet } from "@freetech/models/timesheet";

export const bulkRevokeTimesheetApprovals = async (ids: string[]) => {
  const batch = writeBatch(db);

  ids.forEach((id) => {
    const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
    const updateData: Partial<Timesheet> = {
      approved: false,
      approvedAt: null,
      approvedBy: null,
      approvedByEmail: null,
    };
    batch.update(timesheetRef, updateData);
  });

  await batch.commit();
}; 