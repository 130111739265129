import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  collectionGroup,
} from "firebase/firestore";
import { db } from "core/firestore";
import { IFeatureRequest } from "@freetech/models";
import { useAuth } from "hooks/auth/useAuth";
import { Card } from "@freetech/models/freelo";

export const useAdminFeatureRequest = (clientId: string) => {
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();

  const {
    data: featureRequests,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["featureRequests", clientId],
    queryFn: async () => {
      const q = query(
        collection(db, "clients", clientId, "featureRequests"),
        where("clientId", "==", clientId)
      );
      const requests = await getDocs(q);
      return requests.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as IFeatureRequest[];
    },
    enabled: !!clientId,
  });

  const updateFeatureRequest = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: string;
      data: Partial<IFeatureRequest>;
    }) => {
      const docRef = doc(db, "clients", clientId, "featureRequests", id);
      await updateDoc(docRef, {
        ...data,
        updatedAt: new Date().toISOString(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["featureRequests", clientId],
      });
    },
  });

  const approveFeatureRequest = async (
    featureRequest: IFeatureRequest,
    engineerId: string
  ) => {
    if (!engineerId) {
      throw new Error("Engineer ID is required for approval");
    }

    if (!userInfo?.id) {
      throw new Error("Admin user must be logged in to approve");
    }

    return updateFeatureRequest.mutateAsync({
      id: featureRequest.id,
      data: {
        status: "approved",
        assignedToId: engineerId,
        freetechEstimatedHours: featureRequest.freetechEstimatedHours,
        freetechEstimatedDollarAmount:
          featureRequest.freetechEstimatedDollarAmount,
        actualStartDate: featureRequest.actualStartDate,
        actualCompletionDate: featureRequest.actualCompletionDate,
        approvedByAdminUserId: userInfo.id,
        approvedByAdminDate: new Date().toISOString(),
      },
    });
  };

  const rejectFeatureRequest = async (featureRequest: IFeatureRequest) => {
    if (!userInfo?.id) {
      throw new Error("Admin user must be logged in to reject");
    }

    return updateFeatureRequest.mutateAsync({
      id: featureRequest.id,
      data: {
        status: "rejected",
        rejectedByAdminUserId: userInfo.id,
        rejectedByAdminDate: new Date().toISOString(),
      },
    });
  };

  return {
    featureRequests,
    isLoading,
    error,
    approveFeatureRequest,
    rejectFeatureRequest,
    updateFeatureRequest,
  };
};
