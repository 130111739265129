import { clientConverter } from"@freetech/models/converters";
import { projectConverter } from"@freetech/models/converters";
import {
  collection,
  query,
  getDocs,
  getFirestore,
  where,
} from "firebase/firestore";
import {
  Client,
  ConsultingServicesAgreement,
} from "@freetech/models/projects";
import { IFeatureRequest } from "@freetech/models";
import { Project } from "@freetech/models/projects";
import { PrivateUserInfoType, UserInfoType } from "@freetech/models/user";
import { adminController } from "controllers/adminController";
import { consultingServicesAgreementConverter } from "@freetech/models/converters";

const db = getFirestore();

export const loadClientsNonAdmin = async (
  user: UserInfoType
): Promise<Client[]> => {

  if (user.role === "admin") {
    return adminController.clients.loadNestedClients();
  }

  const q = query(
    collection(db, "clients").withConverter(clientConverter),
    where(
      user.role === "freelancer"
        ? "associatedFreelancerIds"
        : "associatedStakeholderIds",
      "array-contains",
      user.id
    )
  );
  const querySnapshot = await getDocs(q);
  const clientsData: Client[] = [];

  for (const doc of querySnapshot.docs) {
    const clientData = { ...doc.data(), id: doc.id };
    const projectsSnapshot = await getDocs(
      collection(doc.ref, "projects").withConverter(projectConverter)
    );
    const projectsData: Project[] = projectsSnapshot.docs.map((projectDoc) => ({
      ...projectDoc.data(),
      id: projectDoc.id,
    }));
    clientData.projects = projectsData;

    if (user.role === "stakeholder") {
      const consultingServicesAgreementsSnapshot = await getDocs(
        collection(doc.ref, "consultingServicesAgreements").withConverter(
          consultingServicesAgreementConverter
        )
      );
      const consultingServicesAgreementsData: ConsultingServicesAgreement[] =
        consultingServicesAgreementsSnapshot.docs.map((csaDoc) => ({
          ...csaDoc.data(),
          id: csaDoc.id,
        }));

      const featureRequestsSnapshot = await getDocs(
        collection(doc.ref, "featureRequests")
      );
      const featureRequestsData: IFeatureRequest[] =
        featureRequestsSnapshot.docs.map((featureRequestDoc) => ({
          ...featureRequestDoc.data(),
          id: featureRequestDoc.id,
        })) as IFeatureRequest[];

      console.log(
        "consultingServicesAgreementsData",
        consultingServicesAgreementsData
      );
      clientData.consultingServicesAgreements =
        consultingServicesAgreementsData;
      clientData.featureRequests = featureRequestsData;
    }
    clientsData.push({
      ...clientData,
      id: doc.id,
    });
  }

  // return clientsData.filter(client =>
  //   client.projectAssignments?.some(assignment =>
  //     assignment.engineerId === user.id
  //   )
  // );
  return clientsData;
};
