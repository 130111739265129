import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  useTheme,
} from '@mui/material';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useAdminTimesheet } from 'hooks/admin/useAdminTimesheet';
import { useAdminClients } from 'hooks/admin/useAdminClients';
import { useAdminFreelancers } from 'hooks/admin/useAdminFreelancers';
import { DateTime } from 'luxon';

/**
 * TimeTrackingCharts component that displays time tracking data visualizations
 */
const TimeTrackingCharts: React.FC = () => {
  const theme = useTheme();
  const { getAllTimesheets } = useAdminTimesheet();
  const { data: timesheets, isLoading: isLoadingTimesheets } = getAllTimesheets();
  const { clients, isLoading: isLoadingClients } = useAdminClients();
  const { freelancers, isLoading: isLoadingFreelancers } = useAdminFreelancers();
  
  // Period selection state
  const [period, setPeriod] = useState<'week' | 'month' | 'quarter' | 'year'>('month');
  const [selectedClient, setSelectedClient] = useState<string>('all');
  const [selectedFreelancer, setSelectedFreelancer] = useState<string>('all');
  
  const handlePeriodChange = (
    event: React.MouseEvent<HTMLElement>,
    newPeriod: 'week' | 'month' | 'quarter' | 'year' | null,
  ) => {
    if (newPeriod !== null) {
      setPeriod(newPeriod);
    }
  };
  
  const handleClientChange = (event: SelectChangeEvent) => {
    setSelectedClient(event.target.value);
  };
  
  const handleFreelancerChange = (event: SelectChangeEvent) => {
    setSelectedFreelancer(event.target.value);
  };
  
  // Get date range based on selected period
  const getDateRange = () => {
    const now = DateTime.now();
    
    switch (period) {
      case 'week':
        return {
          start: now.startOf('week'),
          end: now.endOf('week'),
        };
      case 'month':
        return {
          start: now.startOf('month'),
          end: now.endOf('month'),
        };
      case 'quarter':
        return {
          start: now.startOf('quarter'),
          end: now.endOf('quarter'),
        };
      case 'year':
        return {
          start: now.startOf('year'),
          end: now.endOf('year'),
        };
      default:
        return {
          start: now.startOf('month'),
          end: now.endOf('month'),
        };
    }
  };
  
  // Filter timesheets based on selected period, client, and freelancer
  const filteredTimesheets = useMemo(() => {
    if (!timesheets) return [];
    
    const { start, end } = getDateRange();
    
    return timesheets.filter(timesheet => {
      const timesheetDate = DateTime.fromISO(timesheet.date);
      const matchesDate = timesheetDate >= start && timesheetDate <= end;
      const matchesClient = selectedClient === 'all' || timesheet.clientId === selectedClient;
      const matchesFreelancer = selectedFreelancer === 'all' || timesheet.userId === selectedFreelancer;
      
      return matchesDate && matchesClient && matchesFreelancer;
    });
  }, [timesheets, period, selectedClient, selectedFreelancer]);
  
  // Format duration from seconds to hours and minutes
  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };
  
  // Format hours for chart axis (converts seconds to hours with 1 decimal)
  const formatChartHours = (seconds: number) => {
    return `${(seconds / 3600).toFixed(1)}h`;
  };
  
  // Calculate time tracked by client
  const timeByClient = useMemo(() => {
    if (!filteredTimesheets || !clients) return [];
    
    const clientSecondsMap: { [clientId: string]: number } = {};
    
    // Sum up seconds for each client
    filteredTimesheets.forEach(timesheet => {
      const { clientId, duration } = timesheet;
      clientSecondsMap[clientId] = (clientSecondsMap[clientId] || 0) + (duration || 0);
    });
    
    // Convert to array with client names
    return Object.entries(clientSecondsMap).map(([clientId, seconds]) => {
      const client = clients.find(c => c.id === clientId);
      return {
        name: client?.companyName || 'Unknown Client',
        id: clientId,
        seconds: seconds,
        value: Math.round(seconds / 3600 * 10) / 10, // Convert to hours with one decimal for sorting
      };
    }).sort((a, b) => b.value - a.value); // Sort by hours (descending)
  }, [filteredTimesheets, clients]);
  
  // Calculate time tracked by freelancer
  const timeByFreelancer = useMemo(() => {
    if (!filteredTimesheets || !freelancers) return [];
    
    const freelancerSecondsMap: { [freelancerId: string]: number } = {};
    
    // Sum up seconds for each freelancer
    filteredTimesheets.forEach(timesheet => {
      const { userId, duration } = timesheet;
      freelancerSecondsMap[userId] = (freelancerSecondsMap[userId] || 0) + (duration || 0);
    });
    
    // Convert to array with freelancer names
    return Object.entries(freelancerSecondsMap).map(([userId, seconds]) => {
      const freelancer = freelancers.find(f => f.id === userId);
      return {
        name: freelancer ? `${freelancer.firstName} ${freelancer.lastName}` : 'Unknown Freelancer',
        id: userId,
        seconds: seconds,
        value: Math.round(seconds / 3600 * 10) / 10, // Convert to hours with one decimal for sorting
      };
    }).sort((a, b) => b.value - a.value); // Sort by hours (descending)
  }, [filteredTimesheets, freelancers]);
  
  // Calculate time tracked by day/week/month for chart
  const timeSeriesData = useMemo(() => {
    if (!filteredTimesheets) return [];
    
    const { start, end } = getDateRange();
    const durationByDate: { [date: string]: { date: string, hours: number, billableHours: number, seconds: number, billableSeconds: number } } = {};
    
    // Initialize all dates in the range
    let current = start;
    const formatString = period === 'year' ? 'MMM' : 'LLL dd';
    
    while (current <= end) {
      const dateKey = current.toFormat(formatString);
      durationByDate[dateKey] = { 
        date: dateKey, 
        hours: 0, 
        billableHours: 0,
        seconds: 0,
        billableSeconds: 0
      };
      
      if (period === 'year') {
        current = current.plus({ months: 1 });
      } else if (period === 'quarter') {
        current = current.plus({ weeks: 1 });
      } else {
        current = current.plus({ days: 1 });
      }
    }
    
    // Fill in the data from timesheets
    filteredTimesheets.forEach(timesheet => {
      const timesheetDate = DateTime.fromISO(timesheet.date);
      const dateKey = timesheetDate.toFormat(formatString);
      
      if (durationByDate[dateKey]) {
        const durationInHours = (timesheet.duration || 0) / 3600;
        const durationInSeconds = timesheet.duration || 0;
        
        durationByDate[dateKey].hours += durationInHours;
        durationByDate[dateKey].seconds += durationInSeconds;
        
        if (timesheet.billable) {
          durationByDate[dateKey].billableHours += durationInHours;
          durationByDate[dateKey].billableSeconds += durationInSeconds;
        }
      }
    });
    
    return Object.values(durationByDate);
  }, [filteredTimesheets, period]);
  
  // Calculate billable vs non-billable time
  const billableTime = useMemo(() => {
    if (!filteredTimesheets) return { billable: 0, nonBillable: 0 };
    
    const billableSeconds = filteredTimesheets.reduce(
      (sum, timesheet) => timesheet.billable ? sum + (timesheet.duration || 0) : sum, 
      0
    );
    
    const totalSeconds = filteredTimesheets.reduce(
      (sum, timesheet) => sum + (timesheet.duration || 0),
      0
    );
    
    return {
      billable: billableSeconds,
      nonBillable: totalSeconds - billableSeconds,
    };
  }, [filteredTimesheets]);
  
  // COLORS
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    '#8884d8',
    '#83a6ed',
    '#8dd1e1',
    '#82ca9d',
    '#a4de6c',
  ];
  
  // Format time from minutes with hours and minutes format
  const formatTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours}h ${remainingMinutes}m`;
  };
  
  const isLoading = isLoadingTimesheets || isLoadingClients || isLoadingFreelancers;
  
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box>
      {/* Filter Controls */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <ToggleButtonGroup
              value={period}
              exclusive
              onChange={handlePeriodChange}
              aria-label="time period"
              fullWidth
              size="small"
            >
              <ToggleButton value="week">Week</ToggleButton>
              <ToggleButton value="month">Month</ToggleButton>
              <ToggleButton value="quarter">Quarter</ToggleButton>
              <ToggleButton value="year">Year</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Client</InputLabel>
              <Select
                value={selectedClient}
                label="Client"
                onChange={handleClientChange}
              >
                <MenuItem value="all">All Clients</MenuItem>
                {clients?.map(client => (
                  <MenuItem key={client.id} value={client.id}>{client.companyName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Freelancer</InputLabel>
              <Select
                value={selectedFreelancer}
                label="Freelancer"
                onChange={handleFreelancerChange}
              >
                <MenuItem value="all">All Freelancers</MenuItem>
                {freelancers?.map(freelancer => (
                  <MenuItem key={freelancer.id} value={freelancer.id}>
                    {freelancer.firstName} {freelancer.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        {/* Time Series Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Time Tracked Over {period === 'week' ? 'Week' : period === 'month' ? 'Month' : period === 'quarter' ? 'Quarter' : 'Year'}
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={timeSeriesData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis tickFormatter={formatChartHours} />
                  <Tooltip 
                    formatter={(value: number, name, props) => {
                      // Use different properties based on the dataKey
                      if (name === "Total Hours") {
                        return [formatDuration(props.payload?.seconds || 0), name];
                      } else if (name === "Billable Hours") {
                        return [formatDuration(props.payload?.billableSeconds || 0), name];
                      }
                      return [formatDuration(Math.round(value * 3600)), name];
                    }}
                    labelFormatter={(label) => `Date: ${label}`}
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="hours"
                    name="Total Hours"
                    stroke={theme.palette.primary.main}
                    activeDot={{ r: 8 }}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="billableHours"
                    name="Billable Hours"
                    stroke={theme.palette.success.main}
                    activeDot={{ r: 6 }}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Time by Client */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Hours by Client
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={timeByClient.slice(0, 10)} // Show top 10 clients
                  layout="vertical"
                  margin={{ top: 5, right: 30, left: 80, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={formatChartHours} />
                  <YAxis 
                    dataKey="name" 
                    type="category" 
                    width={70}
                    tick={{ fontSize: 12 }}
                  />
                  <Tooltip formatter={(value: number, name, props) => {
                    const seconds = props.payload?.seconds || 0;
                    return [formatDuration(seconds), 'Time Tracked'];
                  }} />
                  <Bar dataKey="value" name="Hours" fill={theme.palette.primary.main} />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Time by Freelancer */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Hours by Freelancer
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={timeByFreelancer.slice(0, 10)} // Show top 10 freelancers
                  layout="vertical"
                  margin={{ top: 5, right: 30, left: 80, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={formatChartHours} />
                  <YAxis 
                    dataKey="name" 
                    type="category"
                    width={70}
                    tick={{ fontSize: 12 }}
                  />
                  <Tooltip formatter={(value: number, name, props) => {
                    const seconds = props.payload?.seconds || 0;
                    return [formatDuration(seconds), 'Time Tracked'];
                  }} />
                  <Bar dataKey="value" name="Hours" fill={theme.palette.secondary.main} />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Billable vs Non-Billable Time */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Billable vs Non-Billable Hours
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={[
                          { name: 'Billable', value: billableTime.billable },
                          { name: 'Non-Billable', value: billableTime.nonBillable },
                        ]}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={5}
                        dataKey="value"
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                      >
                        <Cell fill={theme.palette.success.main} />
                        <Cell fill={theme.palette.grey[400]} />
                      </Pie>
                      <Tooltip formatter={(value: number) => [formatDuration(Math.round(value * 3600)), '']} />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h5" gutterBottom textAlign="center">
                    Billable Hours Summary
                  </Typography>
                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Total Hours
                      </Typography>
                      <Typography variant="h4" color="text.primary">
                        {formatDuration(billableTime.billable + billableTime.nonBillable)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Billable Ratio
                      </Typography>
                      <Typography variant="h4" color="success.main">
                        {billableTime.billable + billableTime.nonBillable > 0
                          ? `${((billableTime.billable / (billableTime.billable + billableTime.nonBillable)) * 100).toFixed(0)}%`
                          : '0%'
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Billable Hours
                      </Typography>
                      <Typography variant="h5" color="success.main">
                        {formatDuration(billableTime.billable)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Non-Billable Hours
                      </Typography>
                      <Typography variant="h5" color="text.secondary">
                        {formatDuration(billableTime.nonBillable)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimeTrackingCharts; 