import {
  collection,
  collectionGroup,
  getDocs,
  query,
} from "firebase/firestore";
import { db, storage } from "config/firebase";
import { clientConverter } from"@freetech/models/converters";
import { projectConverter } from"@freetech/models/converters";
import { consultingServicesAgreementConverter } from "@freetech/models/converters";
import { invoiceConverter } from  "@freetech/models/converters";
import {
  Client,
  ConsultingServicesAgreement,
  Project,
} from "@freetech/models/projects";
import { ClientInvoiceData } from "@freetech/models/bank";
import { getDownloadURL, ref } from "firebase/storage";
import { updateDocument } from "core/firestore";
import { IFeatureRequest } from "@freetech/models";

const loadCollectionGroupData = async <T extends { id?: string }>(
  collectionName: string,
  converter?: any
): Promise<T[]> => {
  const collectionRef = collectionGroup(db, collectionName).withConverter(
    converter
  );
  const snapshot = await getDocs(collectionRef);
  return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as T[];
};

// Helper function to download and save CSA file URL

// Helper function to filter client-specific data
const filterClientData = <T extends { clientId: string }>(
  data: T[],
  clientId: string
): T[] => {
  return data.filter((item) => item.clientId === clientId);
};

export const loadNestedClients = async (): Promise<Client[]> => {
  // Load base client data
  const clientsQuery = query(
    collection(db, "clients").withConverter(clientConverter)
  );
  const clientsSnapshot = await getDocs(clientsQuery);
  const clients: Client[] = clientsSnapshot.docs.map((doc) => doc.data());

  // Load related data in parallel
  const [projects, csaList, invoices, featureRequests] = await Promise.all([
    loadCollectionGroupData<Project>("projects", projectConverter),
    loadCollectionGroupData<ConsultingServicesAgreement>(
      "consultingServicesAgreements",
      consultingServicesAgreementConverter
    ),
    loadCollectionGroupData<ClientInvoiceData>("invoices", invoiceConverter),
    loadCollectionGroupData<IFeatureRequest>("featureRequests"),
  ]);

  // Process CSAs to ensure file URLs are available
  const processedCSAs = await processCSAList(csaList);

  // Combine all data into client objects
  return clients.map((client: Client) => ({
    ...client,
    projects: filterClientData(projects, client.id),
    consultingServicesAgreements: filterClientData(processedCSAs, client.id),
    invoices: filterClientData(invoices, client.id),
    featureRequests: filterClientData(featureRequests, client.id),
  }));
};

// Helper function to process CSA list and handle file URLs
const processCSAList = async (
  csaList: ConsultingServicesAgreement[]
): Promise<ConsultingServicesAgreement[]> => {
  const downloadAndSaveCSA = async (
    csa: ConsultingServicesAgreement
  ): Promise<string> => {
    const storageRef = ref(
      storage,
      `clients/${csa.clientId}/consultingServicesAgreements/${csa.id}`
    );
    const downloadUrl = await getDownloadURL(storageRef);

    await updateDocument(
      `clients/${csa.clientId}/consultingServicesAgreements/${csa.id}`,
      { fileUrl: downloadUrl }
    );

    console.log("fileUrl updated for ", csa.id);
    return downloadUrl;
  };
  return Promise.all(
    csaList.map(async (csa) => {
      try {
        // Only download if fileUrl is undefined (not empty string or null)
        const downloadUrl =
          csa.fileUrl === undefined
            ? await downloadAndSaveCSA(csa)
            : csa.fileUrl;

        return { ...csa, fileUrl: downloadUrl };
      } catch (error) {
        console.error("Error getting fileUrl for CSA", error);
        return csa;
      }
    })
  );
};
