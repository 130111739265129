import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { useAdminFinancialHub } from "hooks/admin/useAdminFinancialHub";
import { FreeTechLogoLoading } from "components/FreeTechLogoLoading";
import AccountBalanceWidget from "./components/AccountBalanceWidget";
import TransactionSummary from "./components/TransactionSummary";
import RecentTransactionsTable from "./components/RecentTransactionsTable";
import PaymentsCalendar from "./components/PaymentsCalendar";
import FinancialMetricsChart from "./components/FinancialMetricsChart";
import AllTransactionsTab from "./components/AllTransactionsTab";
import StripeTab from "./components/StripeTab";
import { DateTime } from "luxon";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`financial-tabpanel-${index}`}
      aria-labelledby={`financial-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

// Loading placeholder for section
const SectionLoader = ({ height = 200 }: { height?: number }) => (
  <Box sx={{ p: 3, height }}>
    <Skeleton variant="rectangular" height="100%" animation="wave" />
  </Box>
);

// Account balance widget loading placeholder
const AccountBalanceWidgetLoader = () => (
  <Grid container spacing={3}>
    {[1, 2, 3].map((index) => (
      <Grid item xs={12} md={4} key={index}>
        <Skeleton variant="rectangular" height={140} animation="wave" />
      </Grid>
    ))}
  </Grid>
);

const AdminFinancialHub: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [tabValue, setTabValue] = useState(0);
  
  const { 
    accountBalances,
    combinedTransactions,
    financialSummary,
    timeRange,
    setTimeRange,
    isLoading,
    isLoadingAccountBalances,
    isLoadingIncreaseTransactions,
    isLoadingStripeTransactions,
    isLoadingStripeProducts,
    isLoadingStripeSubscriptions,
    error,
    refetch,
    formatCurrency,
    generateChartData,
    getUpcomingPaymentEvents,
  } = useAdminFinancialHub();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Handle critical errors - only show error state if everything fails
  if (error && isLoading) {
    return (
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography color="error">
          Error loading financial data. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mt: 3, mb: 4 }}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 600, mb: 2 }}>
          Financial Management Hub
        </Typography>
      </Box>

      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="financial hub tabs"
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons={isMobile ? "auto" : undefined}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Dashboard" />
          <Tab label="Transactions" />
          <Tab label="Stripe" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={3}>
            {/* Account Balance Cards */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>Account Balances</Typography>
              {isLoadingAccountBalances ? (
                <AccountBalanceWidgetLoader />
              ) : (
                <Grid container spacing={3}>
                  {accountBalances?.accounts && accountBalances.accounts.length > 0 ? (
                    // Display a widget for each account
                    accountBalances.accounts.map((account) => (
                      <Grid item xs={12} md={4} key={account.id}>
                        <AccountBalanceWidget
                          title={account.name}
                          amount={account.currentBalance}
                          availableAmount={account.availableBalance}
                          source="increase"
                        />
                      </Grid>
                    ))
                  ) : (
                    // Fallback when no accounts are available
                    <Grid item xs={12} md={4}>
                      <AccountBalanceWidget
                        title="No Accounts Found"
                        amount={0}
                        availableAmount={0}
                        source="increase"
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>

            {/* Transaction Summary */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>Transaction Summary</Typography>
              <Paper sx={{ p: 3, height: "100%" }}>
                <TransactionSummary 
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                  loading={isLoadingIncreaseTransactions || isLoadingStripeTransactions}
                  summary={financialSummary}
                  formatCurrency={formatCurrency}
                />
              </Paper>
            </Grid>

            {/* Payments Calendar */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>Upcoming Payments</Typography>
              <Paper sx={{ p: 3, height: "100%" }}>
                {isLoadingIncreaseTransactions || isLoadingStripeTransactions ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 300 }}>
                    <CircularProgress size={40} />
                  </Box>
                ) : (
                  <PaymentsCalendar 
                    events={getUpcomingPaymentEvents()}
                  />
                )}
              </Paper>
            </Grid>

            {/* Financial Metrics Chart */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>Financial Metrics</Typography>
              <Paper sx={{ p: 3 }}>
                {isLoadingIncreaseTransactions || isLoadingStripeTransactions ? (
                  <SectionLoader height={300} />
                ) : (
                  <FinancialMetricsChart 
                    data={generateChartData(timeRange)}
                    timeRange={timeRange}
                    setTimeRange={setTimeRange}
                  />
                )}
              </Paper>
            </Grid>

            {/* Recent Transactions */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>Recent Transactions</Typography>
              <Paper sx={{ p: 3 }}>
                {isLoadingIncreaseTransactions || isLoadingStripeTransactions ? (
                  <SectionLoader height={400} />
                ) : combinedTransactions && combinedTransactions.length > 0 ? (
                  <RecentTransactionsTable 
                    transactions={combinedTransactions} 
                  />
                ) : (
                  <Typography color="text.secondary" sx={{ py: 3, textAlign: "center" }}>
                    No recent transactions
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <AllTransactionsTab />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <StripeTab />
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default AdminFinancialHub; 
