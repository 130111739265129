import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { Project } from "../projects";
export const projectConverter: FirestoreDataConverter<Project> = {
  toFirestore(project: WithFieldValue<Project>): DocumentData {
    const { id, ...data } = project;
    return data;
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Project {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      clientId: data?.clientId,
      name: data?.name,
      description: data?.description,
      status: data?.status,
      startDate: data?.startDate,
      endDate: data?.endDate,
      ideaDate: data?.ideaDate,
      createdByUserId: data?.createdByUserId,
      budget: data?.budget,
      duration: data?.duration,
      // associatedStakeholderIds: data?.associatedStakeholderIds || [],
      // associatedFreelancerIds: data?.associatedFreelancerIds || [],
      projectType: data?.projectType,
      activeCSAId: data?.activeCSAId,
      freeloBoardId: data?.freeloBoardId,
      expectedStartDate: data?.expectedStartDate,
      expectedDuration: data?.expectedDuration,
      techStack: data?.techStack,
      teamSize: data?.teamSize,
      additionalNotes: data?.additionalNotes,
      companyContext: data?.companyContext,
      projectDetails: data?.projectDetails,
      projectTypeDetails: data?.projectTypeDetails,
      projectPlanId: data?.projectPlanId,
      projectIdeaId: data?.projectIdeaId,
    };
  },
};
