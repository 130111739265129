import React, { useState, useMemo } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Stack,
  Divider,
  Button,
  ButtonGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useAdminTimesheet } from "hooks/admin/useAdminTimesheet";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";
import { AdminManualTimeEntryForm } from "components/AdminManualTimeEntryForm";
import { AdminTimesheetGridPro } from "components/AdminTimesheetGridPro";
import { Timesheet } from "@freetech/models/timesheet";

type SubmissionStatus = "all" | "submitted" | "unsubmitted";

export const TimesheetExplorer: React.FC = () => {
  const [startDate, setStartDate] = useState<DateTime>(
    DateTime.now().startOf("month")
  );
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.now().endOf("month")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedEngineers, setSelectedEngineers] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedBillableStatus, setSelectedBillableStatus] = useState<
    boolean | null
  >(null);
  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>("all");

  const {
    getAllTimesheets,
    addEntryForUser,
    updateTimesheet,
    deleteTimesheet,
    approveTimesheet,
    rejectTimesheet,
  } = useAdminTimesheet();
  const { data: fetchedTimesheets = [], isLoading } = getAllTimesheets();
  const { freelancers = [], previousMonthAssignments = [] } =
    useAdminFreelancers();
  const { data: allProjects = [] } = useProjectsNonAdmin();

  const uniqueClients = useMemo(() => {
    const clients = new Set(
      fetchedTimesheets.map((t) => t.clientName).filter(Boolean)
    );
    return Array.from(clients).sort();
  }, [fetchedTimesheets]);

  const filteredTimesheets = useMemo(() => {
    return fetchedTimesheets.filter((timesheet) => {
      const timesheetDate = DateTime.fromISO(timesheet.date);

      const matchesDateRange =
        timesheetDate >= startDate.startOf("day") &&
        timesheetDate <= endDate.endOf("day");

      const matchesSearch =
        (timesheet.userName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.projectName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.clientName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.description?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        );

      const matchesClient =
        selectedClients.length === 0 ||
        (timesheet.clientName &&
          selectedClients.includes(timesheet.clientName));

      const matchesEngineer =
        selectedEngineers.length === 0 ||
        (timesheet.userEmail &&
          selectedEngineers.includes(timesheet.userEmail));

      const matchesProject =
        selectedProjects.length === 0 ||
        selectedProjects.includes(timesheet.projectId || "");

      const matchesBillable =
        selectedBillableStatus === null ||
        timesheet.billable === selectedBillableStatus;

      const matchesSubmissionStatus =
        submissionStatus === "all" ||
        (submissionStatus === "submitted" && timesheet.submitted) ||
        (submissionStatus === "unsubmitted" && !timesheet.submitted);

      return (
        matchesDateRange &&
        matchesSearch &&
        matchesClient &&
        matchesEngineer &&
        matchesProject &&
        matchesBillable &&
        matchesSubmissionStatus
      );
    });
  }, [
    fetchedTimesheets,
    startDate,
    endDate,
    searchTerm,
    selectedClients,
    selectedEngineers,
    selectedProjects,
    selectedBillableStatus,
    submissionStatus,
  ]);

  const handleEdit = async (timesheet: Timesheet) => {
    // TODO: Implement edit functionality
    console.log("Edit timesheet:", timesheet);
  };

  const handleDelete = async (timesheet: Timesheet) => {
    try {
      await deleteTimesheet.mutateAsync(timesheet.id);
    } catch (error) {
      console.error("Error deleting timesheet:", error);
    }
  };

  const handleApprove = async (timesheet: Timesheet) => {
    try {
      await approveTimesheet.mutateAsync({ id: timesheet.id, approverId: "" });
    } catch (error) {
      console.error("Error approving timesheet:", error);
    }
  };

  const handleReject = async (timesheet: Timesheet) => {
    try {
      await rejectTimesheet.mutateAsync({
        id: timesheet.id,
        rejectorId: "",
        reason: "",
      });
    } catch (error) {
      console.error("Error rejecting timesheet:", error);
    }
  };

  const handleExport = () => {
    // TODO: Implement export functionality
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        width: { xs: "100vw", md: "100%" },
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: { xs: 1, sm: 2 },
          flex: "1 1 auto",
          minHeight: 0,
          overflow: "auto",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {/* Filters Section */}
        <Paper elevation={3} sx={{ p: 3 }}>
          <Stack spacing={3}>
            {/* Header */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <FilterListIcon color="primary" />
                <Typography variant="h6">Filters</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <AdminManualTimeEntryForm
                  onSubmit={addEntryForUser.mutateAsync}
                  projectAssignments={previousMonthAssignments}
                  freelancers={freelancers}
                  isAdmin={true}
                  buttonLabel="Add Manual Time Entry"
                  buttonIcon={<AddCircleIcon />}
                />
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={handleExport}
                >
                  Export
                </Button>
              </Stack>
            </Stack>

            <Divider />

            {/* Date Range */}
            <Box>
              <Stack spacing={2}>
                <ButtonGroup variant="outlined" size="small">
                  <Button onClick={() => {
                    setStartDate(DateTime.now().startOf('month'));
                    setEndDate(DateTime.now().endOf('month'));
                  }}>This Month</Button>
                  <Button onClick={() => {
                    setStartDate(DateTime.now().minus({ months: 1 }).startOf('month'));
                    setEndDate(DateTime.now().minus({ months: 1 }).endOf('month'));
                  }}>Last Month</Button>
                  <Button onClick={() => {
                    setStartDate(DateTime.now().startOf('year'));
                    setEndDate(DateTime.now());
                  }}>Year to Date</Button>
                </ButtonGroup>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                    gap: 2,
                  }}
                >
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => newValue && setStartDate(newValue)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => newValue && setEndDate(newValue)}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  />
                </Box>
              </Stack>
            </Box>

            {/* Status Filters */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 2,
              }}
            >
              <FormControl size="small">
                <InputLabel>Submission Status</InputLabel>
                <Select
                  value={submissionStatus}
                  onChange={(e) =>
                    setSubmissionStatus(e.target.value as SubmissionStatus)
                  }
                  label="Submission Status"
                >
                  <MenuItem value="all">All Entries</MenuItem>
                  <MenuItem value="submitted">Submitted</MenuItem>
                  <MenuItem value="unsubmitted">Unsubmitted</MenuItem>
                </Select>
              </FormControl>

              <FormControl size="small">
                <InputLabel>Billable Status</InputLabel>
                <Select
                  value={
                    selectedBillableStatus === null
                      ? ""
                      : String(selectedBillableStatus)
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedBillableStatus(
                      value === "" ? null : value === "true"
                    );
                  }}
                  label="Billable Status"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="true">Billable</MenuItem>
                  <MenuItem value="false">Non-billable</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* Entity Filters */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr 1fr" },
                gap: 2,
              }}
            >
              <Autocomplete
                multiple
                size="small"
                options={freelancers}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                value={freelancers.filter((f) =>
                  selectedEngineers.includes(f.email)
                )}
                onChange={(_, newValue) => {
                  setSelectedEngineers(newValue.map((v) => v.email));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Engineer" />
                )}
              />

              <Autocomplete
                multiple
                size="small"
                options={uniqueClients}
                value={selectedClients}
                onChange={(_, newValue) => {
                  setSelectedClients(
                    newValue.filter(
                      (value): value is string => value !== undefined
                    )
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Client" />
                )}
              />

              <Autocomplete
                multiple
                size="small"
                options={allProjects}
                getOptionLabel={(option) => option.name}
                value={allProjects.filter((p) =>
                  selectedProjects.includes(p.id)
                )}
                onChange={(_, newValue) => {
                  setSelectedProjects(newValue.map((v) => v.id));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Project" />
                )}
              />
            </Box>

            {/* Search */}
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              placeholder="Search by engineer, project, client, or description..."
            />
          </Stack>
        </Paper>

        {/* Grid Section */}
        <Box
          sx={{
            flex: "1 1 auto",
            minHeight: 0,
            overflow: "auto",
            width: { xs: "90vw", md: "100%" },
          }}
        >
          <AdminTimesheetGridPro
            timesheets={filteredTimesheets}
            startDate={startDate}
            endDate={endDate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onApprove={handleApprove}
            onReject={handleReject}
            showActions={true}
            isAdmin={true}
            projectAssignments={previousMonthAssignments}
            freelancers={freelancers}
          />
        </Box>
      </Box>
    </Box>
  );
};
