import { useQuery } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import { db } from "config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { EnrichedCSA } from "hooks/admin/useAdminCSAs";
import { ConsultingServicesAgreement } from "@freetech/models/projects";
import { getDownloadURL } from "firebase/storage";
import { getStorage } from "firebase/storage";
import { ref } from "firebase/storage";

export const useStakeholderCSAs = () => {
  const { userInfo } = useAuth();

  const fetchCSAs = async (): Promise<EnrichedCSA[]> => {
    if (!userInfo) {
      return [];
    }

    // Get all clients where the stakeholder is associated
    const clientsQuery = query(
      collection(db, "clients"),
      where("associatedStakeholderIds", "array-contains", userInfo.id)
    );
    const clientsSnapshot = await getDocs(clientsQuery);

    const csas: EnrichedCSA[] = [];

    // For each client, get their CSAs
    for (const clientDoc of clientsSnapshot.docs) {
      const clientId = clientDoc.id;
      const clientName = clientDoc.data().companyName;

      const csasQuery = collection(
        db,
        `clients/${clientId}/consultingServicesAgreements`
      );
      const csasSnapshot = await getDocs(csasQuery);

      for (const csaDoc of csasSnapshot.docs) {
        const csaData = csaDoc.data() as ConsultingServicesAgreement;

        // Only include CSAs where this stakeholder is the assigned stakeholder
        // or CSAs that are active/completed (for reference)
        if (
          csaData.stakeholderId === userInfo.id ||
          csaData.status === "active" ||
          csaData.status === "completed" ||
          csaData.status === "client_signing"
        ) {
          // Get the project name
          let projectName = "";
          if (csaData.projectId) {
            const projectDocRef = doc(
              db,
              "clients",
              clientId,
              "projects",
              csaData.projectId
            );
            const projectDoc = await getDoc(projectDocRef);
            if (projectDoc.exists()) {
              projectName = projectDoc.data()?.name || "";
            }
          }

          csas.push({
            ...csaData,
            id: csaDoc.id,
            clientName,
            projectName,
          });
        }
      }
    }

    return csas;
  };

  const getViewableCSAURL = async (
    clientId: string,
    csaId: string
  ): Promise<string | null> => {
    const csaDocRef = doc(
      db,
      "clients",
      clientId,
      "consultingServicesAgreements",
      csaId
    );
    console.log(csaDocRef);
    const csaDoc = await getDoc(csaDocRef);
    if (!csaDoc.exists()) {
      return null;
    }

    const csaData = csaDoc.data() as ConsultingServicesAgreement;
    if (!csaData.finalStoragePath) {
      return null;
    }

    const storage = getStorage();
    let url;
    try {
      const fileRef = ref(
        storage,
        `clients/${clientId}/consultingServicesAgreements/${csaId}_completed.pdf`
      );

      url = await getDownloadURL(fileRef);
      return url;
    } catch (error) {
      // legacy support for manual uploads csas
      const fileRef = ref(
        storage,
        `clients/${clientId}/consultingServicesAgreements/${csaId}`
      );

      url = await getDownloadURL(fileRef);
    }
    return url;
  };

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["stakeholderCSAs", userInfo?.id],
    queryFn: fetchCSAs,
    enabled: !!userInfo,
  });

  return {
    csas: data || [],
    isLoading,
    error,
    refetch,
    getViewableCSAURL,
  };
};

export default useStakeholderCSAs;
