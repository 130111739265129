import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Link,
  Alert,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import { UserDocument } from "@freetech/models/user";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import ViewFileDialog from "components/ViewFileDialog";

interface AgreementSectionProps {
  freelancerId: string;
}

const AgreementSection: React.FC<AgreementSectionProps> = ({
  freelancerId,
}) => {
  const {
    independentEngineerAgreement,
    isLoadingIndependentEngineerAgreement,
    freelancerProfile,
  } = useFreelancerProfile();
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const theme = useTheme();

  const handleViewAgreement = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsViewDialogOpen(true);
    setIsFileLoading(true);
  };

  const handleCloseDialog = () => {
    setIsViewDialogOpen(false);
  };

  const handleFileLoad = () => {
    setIsFileLoading(false);
  };

  if (isLoadingIndependentEngineerAgreement) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Engineer Status {freelancerProfile?.status || "Not set"}
      </Typography>

      <Card sx={theme.customComponents.card.dark}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Independent Engineer Agreement
          </Typography>

          {independentEngineerAgreement ? (
            <Box>
              <Typography variant="body1" gutterBottom>
                Current Agreement:
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <Link
                  href={independentEngineerAgreement.firebaseStorageUrl}
                  onClick={handleViewAgreement}
                >
                  Independent Engineer Agreement
                </Link>
                <Typography variant="body2" color="textSecondary">
                  Signed on{" "}
                  {DateTime.fromISO(
                    independentEngineerAgreement.sentDate
                  ).toFormat("LLL dd, yyyy")}
                </Typography>
                {independentEngineerAgreement.status === "signing" && (
                  <Typography variant="body2" color="warning.main">
                    (Pending Signatures)
                  </Typography>
                )}
              </Box>
            </Box>
          ) : (
            <Alert severity="info">
              No agreement on file. Please contact admin@freetech.co.
            </Alert>
          )}
        </CardContent>
      </Card>

      {independentEngineerAgreement && (
        <ViewFileDialog
          open={isViewDialogOpen}
          onClose={handleCloseDialog}
          fileUrl={independentEngineerAgreement?.firebaseStorageUrl || ""}
          fileName="Independent Engineer Agreement"
          isLoading={isFileLoading}
          onLoad={handleFileLoad}
        />
      )}
    </>
  );
};

export default AgreementSection;
