import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  IconButton,
  Card,
  CardContent,
  Divider,
  DialogContentText,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStakeholderSignCSAs } from "hooks/stakeholder/useStakeholderSignCSAs";
import { useStakeholderCSAs } from "hooks/stakeholder/useStakeholderCSAs";
import { EnrichedCSA } from "hooks/admin/useAdminCSAs";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoIcon from "@mui/icons-material/Info";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DateTime } from "luxon";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useEversignSDK } from "hooks/eversign/useEversignSDK";
import { useNavigate, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ViewFileDialog from "components/ViewFileDialog";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PersonIcon from "@mui/icons-material/Person";

// Function to check if a CSA needs signing by the stakeholder
const needsSigningCheck = (csa: EnrichedCSA): boolean => {
  // Use a type assertion to handle the string comparison
  const documentStatus = csa.everSignDocumentStatus as unknown as string;
  return (
    csa.status === "client_signing" &&
    documentStatus === "awaiting_signature" &&
    !!csa.eversignUserDocumentEmbedUrl
  );
};

// Function to check if a CSA can be viewed as a document
const canViewCSADocument = (csa: EnrichedCSA): boolean => {
  return (
    csa.status !== "draft" &&
    csa.status !== "admin_signing" &&
    csa.status !== "client_signing" &&
    !!csa.finalStoragePath
  );
};

// Add Window interface for eversign
declare global {
  interface Window {
    eversign: any;
  }
}

const StakeholderContracts: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { csas, isLoading, refetch, getViewableCSAURL } = useStakeholderCSAs();
  const { processSignedCSA, cancelCSADocument } = useStakeholderSignCSAs();
  const { data: publicUsers, isLoading: isLoadingUsers } = usePublicUserList();

  // State for detail dialog
  const [showDetailDialog, setShowDetailDialog] = useState(false);
  const [selectedCSA, setSelectedCSA] = useState<EnrichedCSA | null>(null);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  // State for iframe loading and error
  const [isIframeLoading, setIsIframeLoading] = useState(false);

  // State for signing
  const [signingError, setSigningError] = useState<string | null>(null);
  const [isProcessingSignature, setIsProcessingSignature] = useState(false);
  const [showSigningSuccessDialog, setShowSigningSuccessDialog] = useState(false);

  // State for document viewing
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [documentError, setDocumentError] = useState<string | null>(null);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);

  // Ref for eversign container
  const eversignContainerRef = useRef<HTMLDivElement>(null);

  // Use the eversign SDK hook
  const { isLoaded: eversignSDKLoaded, error: eversignSDKError } =
    useEversignSDK();

  // Sort CSAs by priority: needs signing -> active -> completed -> others
  const sortedCSAs = useMemo(() => {
    if (!csas) return [];

    // Filter CSAs to only include those with eversign details
    const filteredCSAs = csas.filter(
      (csa) => csa.eversignDocumentHash || csa.finalStoragePath
    );

    return [...filteredCSAs].sort((a, b) => {
      // First priority: Contracts needing signing
      const aRequiresSignature = needsSigningCheck(a);
      const bRequiresSignature = needsSigningCheck(b);

      if (aRequiresSignature && !bRequiresSignature) return -1;
      if (!aRequiresSignature && bRequiresSignature) return 1;

      // Second priority: Active contracts
      if (a.status === "active" && b.status !== "active") return -1;
      if (a.status !== "active" && b.status === "active") return 1;

      // Third priority: Completed contracts
      if (a.status === "completed" && b.status !== "completed") return -1;
      if (a.status !== "completed" && b.status === "completed") return 1;

      // If same priority, sort by effective date (newest first)
      if (a.effectiveDate && b.effectiveDate) {
        return (
          new Date(b.effectiveDate).getTime() -
          new Date(a.effectiveDate).getTime()
        );
      }

      return 0;
    });
  }, [csas]);

  // Count contracts that need signing
  const contractsToSign = sortedCSAs?.filter(needsSigningCheck)?.length || 0;

  // Handle URL hash for direct linking to CSA details
  useEffect(() => {
    if (sortedCSAs && sortedCSAs.length > 0) {
      const hash = location.hash;
      if (hash && hash.startsWith("#csa-")) {
        const csaId = hash.replace("#csa-", "");
        const csa = sortedCSAs.find((c) => c.id === csaId);
        if (csa) {
          handleViewCSA(csa);
        }
      }
    }
  }, [sortedCSAs, location.hash]);

  // Function to clean up eversign iframe
  const cleanupEversignIframe = useCallback(() => {
    try {
      // First remove any iframe elements
      if (eversignContainerRef.current) {
        const iframe = eversignContainerRef.current.querySelector("iframe");
        if (iframe) {
          iframe.remove();
        }
      }

      // Then close the eversign session if it exists
      if (window.eversign && window.eversign.close) {
        console.log("Closing eversign session");
        window.eversign.close();
      }
    } catch (err) {
      console.error("Error closing eversign:", err);
    }
  }, []);

  // Initialize eversign iframe
  const initializeEversignIframe = useCallback(() => {
    if (
      !selectedCSA ||
      !needsSigningCheck(selectedCSA) ||
      !eversignSDKLoaded ||
      !eversignContainerRef.current
    ) {
      return;
    }

    // Check if iframe already exists and is loaded
    const existingIframe = eversignContainerRef.current.querySelector("iframe");
    if (existingIframe && !isIframeLoading) {
      console.log("Eversign iframe already exists, skipping initialization");
      return;
    }

    try {
      setIsIframeLoading(true);
      setSigningError(null);

      console.log(
        "Preparing to initialize eversign with URL:",
        selectedCSA.eversignUserDocumentEmbedUrl
      );

      if (!selectedCSA.eversignUserDocumentEmbedUrl) {
        throw new Error("Missing eversign embed URL");
      }

      // Clean up any existing eversign iframe
      cleanupEversignIframe();

      // Add a small delay to ensure DOM is ready (like in SignAgreements.tsx)
      setTimeout(() => {
        try {
          // Initialize eversign SDK
          window.eversign.open({
            url: selectedCSA.eversignUserDocumentEmbedUrl,
            containerID:
              eversignContainerRef.current?.id || "eversign-container",
            width: eversignContainerRef.current?.offsetWidth || 800,
            height: eversignContainerRef.current?.offsetHeight || 600,
            events: {
              loaded: () => {
                console.log("Eversign interface loaded");
                setIsIframeLoading(false);
              },
              signed: async () => {
                console.log("Document signed!");
                cleanupEversignIframe();

                try {
                  // Close the detail dialog and show processing dialog
                  setShowDetailDialog(false);
                  setIsProcessingSignature(true);

                  // Clear URL hash since we're closing the dialog
                  navigate("", { replace: true });

                  await processSignedCSA.mutateAsync({
                    documentHash: selectedCSA.eversignDocumentHash || "",
                    clientId: selectedCSA.clientId,
                    csaId: selectedCSA.id,
                  });

                  // Refresh data
                  await refetch();

                  // Show success dialog instead of reopening the detail dialog
                  setIsProcessingSignature(false);
                  setShowSigningSuccessDialog(true);
                } catch (error) {
                  console.error("Error processing signed document:", error);
                  setSigningError(
                    "Error processing signed document. Please try again."
                  );
                  setIsProcessingSignature(false);
                }
              },
              declined: () => {
                console.log("Document declined");
                cleanupEversignIframe();

                cancelCSADocument
                  .mutateAsync(selectedCSA.eversignDocumentHash || "")
                  .catch((error: Error) => {
                    console.error("Error canceling document:", error);
                  });
              },
              error: (error: unknown) => {
                console.error("Eversign error:", error);
                const errorMessage =
                  error instanceof Error ? error.message : "Unknown error";
                setSigningError(`Error with signing process: ${errorMessage}`);
                setIsIframeLoading(false);
              },
            },
          });
        } catch (err) {
          console.error("Error in setTimeout callback:", err);
          const errorMessage =
            err instanceof Error ? err.message : "Unknown error";
          setSigningError(`Failed to initialize signing: ${errorMessage}`);
          setIsIframeLoading(false);
        }
      }, 0);
    } catch (error) {
      console.error("Error initializing eversign:", error);
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";
      setSigningError(`Failed to initialize signing: ${errorMessage}`);
      setIsIframeLoading(false);
    }
  }, [
    selectedCSA,
    eversignSDKLoaded,
    cleanupEversignIframe,
    processSignedCSA,
    cancelCSADocument,
  ]);

  // Fetch document URL
  const fetchDocumentUrl = async () => {
    if (!selectedCSA) return;

    setIsDocumentLoading(true);
    setDocumentError(null);

    try {
      const url = await getViewableCSAURL(selectedCSA.clientId, selectedCSA.id);
      setDocumentUrl(url);
    } catch (error) {
      console.error("Error fetching document URL:", error);
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";
      setDocumentError(`Failed to load document: ${errorMessage}`);
      toast.error(`Failed to load document: ${errorMessage}`);
    } finally {
      setIsDocumentLoading(false);
    }
  };

  // Handle view CSA
  const handleViewCSA = (csa: EnrichedCSA) => {
    setSelectedCSA(csa);

    // Only show the dialog if the contract needs signing
    if (needsSigningCheck(csa)) {
      setShowDetailDialog(true);

      // Update URL hash for direct linking
      navigate(`#csa-${csa.id}`, { replace: true });

      // Reset document state
      setDocumentUrl(null);
      setDocumentError(null);

      // Initialize the iframe for signing
      if (eversignSDKLoaded) {
        // We need to wait for the dialog to open and the DOM to be ready
        setTimeout(() => {
          initializeEversignIframe();
        }, 100);
      }
    } else {
      // If the contract doesn't need signing, just expand the row
      setExpandedRow(csa.id);
    }
  };

  // Handle closing the detail dialog
  const handleCloseDialog = () => {
    setShowDetailDialog(false);
    // Clear URL hash when closing dialog
    navigate("", { replace: true });
  };

  // Handle closing the success dialog
  const handleCloseSuccessDialog = () => {
    setShowSigningSuccessDialog(false);
  };

  // Effect to handle SDK errors
  useEffect(() => {
    if (eversignSDKError) {
      console.error("Eversign SDK failed to load:", eversignSDKError);
      setSigningError(
        "Failed to load signing interface. Please try again later."
      );
    }
  }, [eversignSDKError]);

  // Effect to handle container resizing
  useEffect(() => {
    if (!eversignContainerRef.current) return;

    // Create a resize observer to handle container size changes
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const iframe = entry.target.querySelector("iframe");
        if (iframe) {
          iframe.style.width = `${entry.contentRect.width}px`;
          iframe.style.height = `${entry.contentRect.height}px`;
        }
      }
    });

    resizeObserver.observe(eversignContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Effect to clean up when dialog closes
  useEffect(() => {
    if (!showDetailDialog) {
      cleanupEversignIframe();
      // Refresh the CSA list when the dialog is closed
      refetch();
    }

    return () => {
      cleanupEversignIframe();
    };
  }, [showDetailDialog, refetch, cleanupEversignIframe]);

  // Effect to initialize iframe when dialog opens with signing tab selected
  useEffect(() => {
    if (
      showDetailDialog &&
      selectedCSA &&
      needsSigningCheck(selectedCSA) &&
      eversignSDKLoaded
    ) {
      // Small delay to ensure DOM is ready
      const timer = setTimeout(() => {
        initializeEversignIframe();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [
    showDetailDialog,
    selectedCSA,
    eversignSDKLoaded,
    initializeEversignIframe,
  ]);

  // Effect to load document when dialog opens with a viewable document
  useEffect(() => {
    if (showDetailDialog && selectedCSA && canViewCSADocument(selectedCSA)) {
      // Pre-fetch the document URL when the dialog opens if the CSA has a viewable document
      fetchDocumentUrl();
    }
  }, [showDetailDialog, selectedCSA]);

  // Format date
  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return "N/A";
    return DateTime.fromISO(dateString).toFormat("MMM d, yyyy");
  };

  // Get status chip color
  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "success";
      case "completed":
        return "info";
      case "cancelled":
        return "error";
      case "admin_signing":
        return "warning";
      case "client_signing":
        return "warning";
      default:
        return "default";
    }
  };

  // Get creator name from user ID
  const getCreatorName = useCallback(
    (creatorId?: string) => {
      if (!creatorId || !publicUsers) return "Unknown";
      const creator = publicUsers.find((user) => user.id === creatorId);
      return creator ? `${creator.firstName} ${creator.lastName}` : "Unknown";
    },
    [publicUsers]
  );

  // Get stakeholder name from ID
  const getStakeholderName = useCallback(
    (stakeholderId?: string) => {
      if (!stakeholderId || !publicUsers) return "Unknown";
      const stakeholder = publicUsers.find((user) => user.id === stakeholderId);
      return stakeholder ? `${stakeholder.firstName} ${stakeholder.lastName}` : "Unknown";
    },
    [publicUsers]
  );

  // Render welcome card
  const renderWelcomeCard = () => {
    return (
      <Card sx={{ mb: 4 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.grey[800],
            p: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <AssignmentIcon sx={{ color: theme.palette.common.white, mr: 2 }} />
          <Typography
            variant="subtitle1"
            color="common.white"
            fontWeight="medium"
          >
            Welcome to the Contract Center. Here, you can view and sign your contracts related to FreeTech services.
          </Typography>
        </Box>
        <CardContent>
          {contractsToSign > 0 && (
            <Alert 
              severity="warning" 
              icon={<BorderColorIcon />}
              sx={{ mb: 2 }}
            >
              <Typography variant="subtitle2">
                You have {contractsToSign} contract{contractsToSign > 1 ? "s" : ""} waiting for your signature
              </Typography>
            </Alert>
          )}
          
          <Typography variant="body1" sx={{ mb: 2 }}>
            This is where you'll sign and manage contracts for approved project
            ideas. Once a project is approved, a Consulting Services Agreement
            (CSA) will be created and sent to you for review and signature.
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <InfoIcon sx={{ color: "info.main", mr: 1, mt: 0.5 }} />
            <Typography variant="body2" color="text.secondary">
              If you entered a contract with FreeTech outside of the portal, you
              can find it in the Project Hub. Only contracts created and managed
              through the portal are displayed here.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  // Render CSA table
  const renderCSATable = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (!sortedCSAs || sortedCSAs.length === 0) {
      return (
        <Box sx={{ p: 3 }}>
          <Typography variant="body1">No contracts found.</Typography>
        </Box>
      );
    }

    const toggleRowExpand = (csaId: string) => {
      setExpandedRow(expandedRow === csaId ? null : csaId);
    };

    const handleViewDocument = async (
      csa: EnrichedCSA,
      e: React.MouseEvent
    ) => {
      e.stopPropagation();
      setSelectedCSA(csa);

      setIsDocumentLoading(true);
      setDocumentError(null);

      try {
        const url = await getViewableCSAURL(csa.clientId, csa.id);
        setDocumentUrl(url);
        if (url) {
          setShowDocumentDialog(true);
        }
      } catch (error) {
        console.error("Error fetching document URL:", error);
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error";
        setDocumentError(`Failed to load document: ${errorMessage}`);
        toast.error(`Failed to load document: ${errorMessage}`);
      } finally {
        setIsDocumentLoading(false);
      }
    };

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Contract Name</TableCell>
              <TableCell>Effective Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCSAs.map((csa) => (
              <React.Fragment key={csa.id}>
                <TableRow
                  hover
                  onClick={() => toggleRowExpand(csa.id)}
                  sx={{
                    cursor: "pointer",
                    // Highlight rows that need signing
                    ...(needsSigningCheck(csa) && {
                      backgroundColor: `${theme.palette.warning.light}20`,
                      "&:hover": {
                        backgroundColor: `${theme.palette.warning.light}40`,
                      },
                    }),
                  }}
                >
                  <TableCell>{csa.clientName || "N/A"}</TableCell>
                  <TableCell>{csa.projectName || "N/A"}</TableCell>
                  <TableCell>{csa.name}</TableCell>
                  <TableCell>{formatDate(csa.effectiveDate)}</TableCell>
                  <TableCell>
                    <Chip
                      label={
                        csa.status === "client_signing"
                          ? "Awaiting Your Signature"
                          : csa.status
                      }
                      color={getStatusColor(csa.status) as any}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {canViewCSADocument(csa) && (
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<VisibilityIcon />}
                        onClick={(e) => handleViewDocument(csa, e)}
                      >
                        View Document
                      </Button>
                    )}
                    {needsSigningCheck(csa) && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ ml: 1 }}
                        startIcon={<BorderColorIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewCSA(csa);
                        }}
                      >
                        Sign
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
                {expandedRow === csa.id && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ py: 0 }}>
                      <Box sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                          Contract Information
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: 2,
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle2">Project</Typography>
                            <Typography variant="body1">
                              {csa.projectName || "N/A"}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">Status</Typography>
                            <Chip
                              label={
                                csa.status === "client_signing"
                                  ? "Awaiting Your Signature"
                                  : csa.status
                              }
                              color={getStatusColor(csa.status) as any}
                              size="small"
                            />
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              Effective Date
                            </Typography>
                            <Typography variant="body1">
                              {formatDate(csa.effectiveDate)}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              End Date
                            </Typography>
                            <Typography variant="body1">
                              {formatDate(csa.endDate)}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              Contract Type
                            </Typography>
                            <Typography variant="body1">
                              {csa.csaType === "fixed_price"
                                ? "Fixed Price"
                                : "Monthly Retainer"}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">Budget</Typography>
                            <Typography variant="body1">
                              {csa.csaType === "fixed_price"
                                ? csa.fixedPriceBudget
                                  ? `$${csa.fixedPriceBudget.toLocaleString()}`
                                  : "N/A"
                                : csa.monthlyBudget
                                  ? `$${csa.monthlyBudget.toLocaleString()}/month`
                                  : "N/A"}
                            </Typography>
                          </Box>
                          {csa.documentSigningCompletedAt && (
                            <Box>
                              <Typography variant="subtitle2">
                                Signing Completed
                              </Typography>
                              <Typography variant="body1">
                                {formatDate(csa.documentSigningCompletedAt)}
                              </Typography>
                            </Box>
                          )}
                          <Box>
                            <Typography variant="subtitle2">
                              Created By
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PersonIcon
                                sx={{
                                  fontSize: 16,
                                  mr: 0.5,
                                  color: "text.secondary",
                                }}
                              />
                              <Typography variant="body1">
                                {isLoadingUsers
                                  ? "Loading..."
                                  : getCreatorName(csa.createdBy)}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="subtitle2">
                              Client Signer
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <PersonIcon
                                sx={{
                                  fontSize: 16,
                                  mr: 0.5,
                                  color: "text.secondary",
                                }}
                              />
                              <Typography variant="body1">
                                {isLoadingUsers
                                  ? "Loading..."
                                  : getStakeholderName(csa.stakeholderId)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        {csa.scopeOfWork && csa.scopeOfWork.length > 0 && (
                          <Box sx={{ mt: 3 }}>
                            <Typography variant="h6" gutterBottom>
                              Scope of Work
                            </Typography>
                            <List
                              dense
                              sx={{
                                bgcolor: "background.paper",
                                borderRadius: 1,
                                border: "1px solid",
                                borderColor: "divider",
                              }}
                            >
                              {csa.scopeOfWork.map((item, index) => (
                                <ListItem key={index}>
                                  <ListItemIcon sx={{ minWidth: 36 }}>
                                    <CheckCircleOutlineIcon
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={item} />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        )}

                        {csa.billableRates && csa.billableRates.length > 0 && (
                          <Box sx={{ mt: 3 }}>
                            <Typography variant="h6" gutterBottom>
                              Billable Rates
                            </Typography>
                            <TableContainer
                              component={Paper}
                              variant="outlined"
                              sx={{ mt: 1 }}
                            >
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Rate ($/hour)</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {csa.billableRates.map((rate, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {rate.role || "N/A"}
                                      </TableCell>
                                      <TableCell>
                                        ${rate.rate?.toLocaleString() || "N/A"}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        )}

                        {needsSigningCheck(csa) && (
                          <Box sx={{ mt: 3 }}>
                            <Alert severity="warning" sx={{ mb: 2 }}>
                              <Typography variant="subtitle1">
                                This contract requires your signature
                              </Typography>
                              <Typography variant="body2">
                                Please click the "Sign Contract" button to
                                review and sign this document.
                              </Typography>
                            </Alert>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<BorderColorIcon />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleViewCSA(csa);
                              }}
                            >
                              Sign Contract
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Render CSA detail dialog
  const renderDetailDialog = () => {
    if (!selectedCSA) return null;

    const needsSigning = needsSigningCheck(selectedCSA);

    return (
      <Dialog
        open={showDetailDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: "80vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Sign Contract: {selectedCSA.name}
          </Typography>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          {needsSigning && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Sign Contract
              </Typography>
              <Alert severity="info" sx={{ mb: 2 }}>
                Please review the contract carefully before signing. By signing
                this document, you are entering into a legally binding
                agreement.
              </Alert>

              {!selectedCSA.eversignUserDocumentEmbedUrl && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Unable to load signing interface. The document URL is missing.
                </Alert>
              )}

              <Box
                ref={eversignContainerRef}
                id="eversign-container"
                sx={{
                  flexGrow: 1,
                  border: "1px solid #ddd",
                  minHeight: "500px",
                  position: "relative",
                  "& iframe": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                {isIframeLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      zIndex: 10,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                {signingError && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      p: 3,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "white",
                      zIndex: 5,
                    }}
                  >
                    <Alert severity="error" sx={{ mb: 2, width: "100%" }}>
                      {signingError}
                    </Alert>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      If the signing interface doesn't appear below, you can try
                      opening it in a new tab.
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setSigningError(null);
                          initializeEversignIframe();
                        }}
                      >
                        Try Again
                      </Button>
                      {selectedCSA?.eversignUserDocumentEmbedUrl && (
                        <Button
                          variant="outlined"
                          onClick={() =>
                            window.open(
                              selectedCSA.eversignUserDocumentEmbedUrl,
                              "_blank"
                            )
                          }
                        >
                          Open in New Tab
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Render signing success dialog
  const renderSigningSuccessDialog = () => {
    return (
      <Dialog
        open={showSigningSuccessDialog}
        onClose={handleCloseSuccessDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: theme.palette.success.light, color: theme.palette.success.contrastText }}>
          Contract Signed Successfully
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
            <Typography variant="h6" align="center" gutterBottom>
              Thank you for signing the contract!
            </Typography>
            <Typography variant="body1" align="center">
              Your signed contract has been processed and is now available in the Contract Center. You can view the completed document at any time.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseSuccessDialog} 
            variant="contained" 
            color="primary"
            fullWidth
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {renderWelcomeCard()}
      {renderCSATable()}
      {renderDetailDialog()}
      {renderSigningSuccessDialog()}

      {/* Document Viewer Dialog */}
      {documentUrl && (
        <ViewFileDialog
          open={showDocumentDialog}
          onClose={() => setShowDocumentDialog(false)}
          fileUrl={documentUrl}
          fileName={
            selectedCSA?.name ? `${selectedCSA.name}.pdf` : "Contract.pdf"
          }
          isLoading={isDocumentLoading}
        />
      )}

      {/* Processing Dialog */}
      <Dialog
        open={isProcessingSignature}
        aria-labelledby="processing-dialog-title"
      >
        <DialogContent
          sx={{ display: "flex", alignItems: "center", gap: 2, p: 3 }}
        >
          <CircularProgress size={24} />
          <DialogContentText id="processing-dialog-description">
            Processing signed contract...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StakeholderContracts;
