// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { AppCheck } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VITE_FIREBASE_API_KEY,
  authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VITE_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig ? firebaseConfig : {});

// let appCheck: AppCheck | null = null;

// try {
//   if (process.env.NODE_ENV === "development") {
//     // @ts-ignore
//     self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
//     console.info(
//       "App Check debug mode enabled. Make sure to add the debug token to Firebase Console."
//     );
//   }

//   if (!process.env.VITE_RECAPTCHA_SITE_KEY) {
//     console.error("Missing VITE_RECAPTCHA_SITE_KEY environment variable");
//     throw new Error("Missing VITE_RECAPTCHA_SITE_KEY environment variable");
//   }

//   appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(process.env.VITE_RECAPTCHA_SITE_KEY),
//     isTokenAutoRefreshEnabled: true,
//   });

//   console.info("App Check initialized successfully");

//   // Log debug token if in development
//   if (process.env.NODE_ENV === "development") {
//     // @ts-ignore
//     const debugToken = self.FIREBASE_APPCHECK_DEBUG_TOKEN;
//     if (typeof debugToken === "string") {
//       console.info(`App Check debug token: ${debugToken}`);
//       console.info(
//         "Please add this debug token to your Firebase Console under App Check settings."
//       );
//     }
//   }
// } catch (error) {
//   console.error("Failed to initialize App Check:", error);
//   // Initialize without App Check in case of failure
//   appCheck = null;
// }

const auth = getAuth(app);
const db = getFirestore();
const storage = getStorage(app);

// export { auth, app, firebaseConfig, db, storage, appCheck };
export { auth, app, firebaseConfig, db, storage };
