import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Paper,
  Typography,
  Chip,
  Grid,
  IconButton,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { IBugReport, BugReportAttachment } from "@freetech/models/bugReport";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "config/firebase";
import { bugReportConverter } from "@freetech/models/converters";
import { useNavigation } from "hooks/useNavigation";
import { Project } from "@freetech/models/projects";
import { useAuth } from "hooks/auth/useAuth";
import { StakeholderReportOrRequestComments } from "../components/StakeholderReportOrRequestComments";
import { useFreelo } from "hooks/freelo/useFreelo";
import { Card as FreeloCard } from "@freetech/models/freelo";
import LinkIcon from "@mui/icons-material/Link";
import { useStakeholderBugReport } from "hooks/stakeholder/useStakeholderBugReport";
import { PrivateUserInfoType } from "@freetech/models/user";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { useFreeloBoard } from "hooks/freelo/useFreeloBoard";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import ReadOnlyTipTap from "components/TipTap/ReadOnlyTipTap";
import RichTextEditor from "components/TipTap/RichTextEditor";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { updateBugReportAttachments } from "core/bugReport";
import DeleteIcon from "@mui/icons-material/Delete";

// Editable TipTap component
const EditableTipTap: React.FC<{
  content: string;
  onSave: (content: string) => void;
  onCancel: () => void;
}> = ({ content, onSave, onCancel }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content,
    editable: true,
  });

  if (!editor) return null;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          border: 1,
          borderColor: "primary.main",
          borderRadius: 1,
          p: 2,
          mb: 2,
          minHeight: "100px",
        }}
      >
        <EditorContent editor={editor} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
        <Button
          variant="outlined"
          color="error"
          size="small"
          startIcon={<CloseIcon />}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SaveIcon />}
          onClick={() => onSave(editor.getHTML())}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

// Define an extended attachment type to handle contentType
interface ExtendedBugReportAttachment extends BugReportAttachment {
  contentType?: string;
  filename?: string;
}

// Constants for file upload
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "application/pdf",
  "text/plain",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const StakeholderBugReport: React.FC = () => {
  const { projectId, bugReportId } = useParams();
  const navigate = useNavigate();
  const { handleNavigation } = useNavigation();
  const queryClient = useQueryClient();
  const [selectedAttachment, setSelectedAttachment] =
    useState<ExtendedBugReportAttachment | null>(null);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingExpectedBehavior, setIsEditingExpectedBehavior] =
    useState(false);
  const [isEditingActualBehavior, setIsEditingActualBehavior] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [expectedBehaviorContent, setExpectedBehaviorContent] = useState("");
  const [actualBehaviorContent, setActualBehaviorContent] = useState("");
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState<
    string | null
  >(null);

  // Get project data using useStakeholder hook
  const { projects, projectsIsLoading } = useStakeholder();
  const project = projects?.find((p) => p?.id === projectId);

  // Get bug report data
  const { data: bugReport, isLoading } = useQuery({
    queryKey: ["bugReport", bugReportId],
    queryFn: async () => {
      if (!bugReportId) throw new Error("Bug report ID is required");

      const docRef = doc(db, "bugReports", bugReportId).withConverter(
        bugReportConverter
      );
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error("Bug report not found");
      }

      return {
        ...docSnap.data(),
        id: docSnap.id,
      };
    },
    enabled: !!bugReportId,
  });

  const {
    addComment,
    isAddingComment,
    removeAttachment,
    isRemovingAttachment: isRemovingAttachmentMutation,
  } = useStakeholderBugReport({
    clientId: bugReport?.clientId || "",
    projectId: bugReport?.projectId || "",
  });

  // Create a mutation for updating the bug report description
  const updateBugReportMutation = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: string;
      data: Partial<IBugReport>;
    }) => {
      const docRef = doc(db, "bugReports", id);
      await updateDoc(docRef, data);
      return data; // Return the updated data
    },
    onSuccess: (data) => {
      // Update local state immediately for optimistic updates
      if (data.description) {
        setEditorContent(data.description);
      }
      if (data.expectedBehavior) {
        setExpectedBehaviorContent(data.expectedBehavior);
      }
      if (data.actualBehavior) {
        setActualBehaviorContent(data.actualBehavior);
      }

      // Also invalidate the query to ensure data consistency
      queryClient.invalidateQueries({ queryKey: ["bugReport", bugReportId] });
    },
  });

  // Create a mutation for uploading attachments
  const uploadAttachmentsMutation = useMutation({
    mutationFn: async ({ id, files }: { id: string; files: File[] }) => {
      await updateBugReportAttachments(id, files);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReport", bugReportId] });
      setUploadFiles([]);
      setUploadError(null);
    },
  });

  // Add Freelo hooks
  const { data: board } = useFreeloBoard(bugReport?.projectId || "");
  const linkedTickets = useMemo(() => {
    const ticketIds = bugReport?.freeloTicketIds;
    if (!board || !ticketIds || !Array.isArray(ticketIds)) return [];
    return (
      board.lists?.flatMap((list) =>
        list.cards.filter((card) => ticketIds.includes(card.id))
      ) || []
    );
  }, [board, bugReport?.freeloTicketIds]);

  const { data: users } = usePublicUserList();

  const getRequestedByUser = (id: string) => {
    const user = users?.find((u) => u.id === id);
    return user ? `${user.firstName} ${user.lastName}` : "Unknown User";
  };

  useEffect(() => {
    if (bugReport && project) {
      navigate(
        `/stakeholder/projects/${projectId}/bug-reports/${bugReport.id}`
      );
    }
  }, [bugReport, project, projectId, navigate]);

  // Update editor content when bugReport changes
  useEffect(() => {
    if (bugReport) {
      setEditorContent(bugReport.description || "");
      setExpectedBehaviorContent(bugReport.expectedBehavior || "");
      setActualBehaviorContent(bugReport.actualBehavior || "");
    }
  }, [bugReport]);

  const handleAddComment = async (content: string) => {
    if (!bugReportId) return;
    await addComment({ bugReportId, content });
  };

  const handleStartEditing = () => {
    setEditorContent(bugReport?.description || "");
    setIsEditingDescription(true);
  };

  const handleStartEditingExpectedBehavior = () => {
    setExpectedBehaviorContent(bugReport?.expectedBehavior || "");
    setIsEditingExpectedBehavior(true);
  };

  const handleStartEditingActualBehavior = () => {
    setActualBehaviorContent(bugReport?.actualBehavior || "");
    setIsEditingActualBehavior(true);
  };

  const handleUpdateDescription = async (newDescription: string) => {
    if (!bugReportId) return;

    try {
      await updateBugReportMutation.mutateAsync({
        id: bugReportId,
        data: {
          description: newDescription,
          updatedAt: new Date().toISOString(),
        },
      });

      // Update the local state immediately for optimistic UI update
      if (bugReport) {
        bugReport.description = newDescription;
      }

      setIsEditingDescription(false);
    } catch (error) {
      console.error("Error updating bug report description:", error);
    }
  };

  const handleUpdateExpectedBehavior = async (newExpectedBehavior: string) => {
    if (!bugReportId) return;

    try {
      await updateBugReportMutation.mutateAsync({
        id: bugReportId,
        data: {
          expectedBehavior: newExpectedBehavior,
          updatedAt: new Date().toISOString(),
        },
      });

      // Update the local state immediately for optimistic UI update
      if (bugReport) {
        bugReport.expectedBehavior = newExpectedBehavior;
      }

      setIsEditingExpectedBehavior(false);
    } catch (error) {
      console.error("Error updating expected behavior:", error);
    }
  };

  const handleUpdateActualBehavior = async (newActualBehavior: string) => {
    if (!bugReportId) return;

    try {
      await updateBugReportMutation.mutateAsync({
        id: bugReportId,
        data: {
          actualBehavior: newActualBehavior,
          updatedAt: new Date().toISOString(),
        },
      });

      // Update the local state immediately for optimistic UI update
      if (bugReport) {
        bugReport.actualBehavior = newActualBehavior;
      }

      setIsEditingActualBehavior(false);
    } catch (error) {
      console.error("Error updating actual behavior:", error);
    }
  };

  const handleAttachmentClick = (attachment: BugReportAttachment) => {
    // Create an extended attachment with contentType based on file extension
    const fileExt = attachment.name.split(".").pop()?.toLowerCase();
    const isImage =
      fileExt && ["jpg", "jpeg", "png", "gif", "webp", "svg"].includes(fileExt);

    setSelectedAttachment({
      ...attachment,
      contentType: isImage ? "image/" + fileExt : "application/octet-stream",
      filename: attachment.name,
    });
  };

  const handleCloseDialog = () => {
    setSelectedAttachment(null);
  };

  // Handle file upload
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any[]) => {
    // Handle rejected files
    if (rejectedFiles.length > 0) {
      const errorMessages = rejectedFiles
        .map(
          ({ file, errors }) =>
            `${file.name}: ${errors.map((e: any) => e.message).join(", ")}`
        )
        .join("; ");
      setUploadError(errorMessages);
      return;
    }

    // Validate accepted files
    const invalidFiles = acceptedFiles.filter(
      (file) =>
        !ALLOWED_FILE_TYPES.includes(file.type) || file.size > MAX_FILE_SIZE
    );

    if (invalidFiles.length > 0) {
      setUploadError(
        `Invalid files: ${invalidFiles.map((f) => f.name).join(", ")}. Files must be under 10MB and of supported types.`
      );
      return;
    }

    setUploadError(null);
    setUploadFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ALLOWED_FILE_TYPES.reduce(
      (acc, type) => ({ ...acc, [type]: [] }),
      {}
    ),
    maxSize: MAX_FILE_SIZE,
  });

  const handleRemoveUploadFile = (index: number) => {
    setUploadFiles((prev) => prev.filter((_, i) => i !== index));
    if (uploadFiles.length === 1) {
      setUploadError(null);
    }
  };

  const handleUploadAttachments = async () => {
    if (!bugReportId || uploadFiles.length === 0) return;

    try {
      setIsUploading(true);
      await uploadAttachmentsMutation.mutateAsync({
        id: bugReportId,
        files: uploadFiles,
      });
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading attachments:", error);
      setUploadError("Failed to upload attachments. Please try again.");
      setIsUploading(false);
    }
  };

  const handleRemoveAttachment = async (
    event: React.MouseEvent,
    attachmentId: string
  ) => {
    event.stopPropagation(); // Prevent opening the attachment

    if (!bugReportId) return;

    try {
      // Use basic browser confirm dialog instead
      const confirmDelete = window.confirm(
        "Are you sure you want to remove this attachment? This action cannot be undone."
      );

      if (!confirmDelete) {
        return; // User cancelled
      }

      setIsRemovingAttachment(attachmentId);

      await removeAttachment({
        bugReportId,
        attachmentId,
      });

      setIsRemovingAttachment(null);
    } catch (error) {
      // Error occurred
      setIsRemovingAttachment(null);
      console.error("Error removing attachment:", error);
    }
  };

  if (isLoading || projectsIsLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (!bugReport) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Bug report not found</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 } }}>
      {/* Header */}
      <Box sx={{ mb: { xs: 2, sm: 3 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            gap: { xs: 1, sm: 2 },
            mb: { xs: 1.5, sm: 2 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" },
              fontWeight: 700,
              letterSpacing: "-0.01em",
            }}
          >
            {bugReport.title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: { xs: 1.5, sm: 2 },
          }}
        >
          <Chip
            label={bugReport.priority}
            color={
              bugReport.priority === "high" || bugReport.priority === "critical"
                ? "error"
                : bugReport.priority === "medium"
                  ? "warning"
                  : "default"
            }
            sx={{
              fontWeight: "bold",
              height: { xs: 28, sm: 32 },
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
            }}
          />
          <Chip
            label={bugReport.status}
            color={
              bugReport.status === "resolved"
                ? "success"
                : bugReport.status === "in_progress"
                  ? "info"
                  : bugReport.status === "open"
                    ? "warning"
                    : "default"
            }
            sx={{
              height: { xs: 28, sm: 32 },
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12} md={8} sx={{ order: { xs: 2, md: 1 } }}>
          {/* Main Content */}
          <Paper
            elevation={1}
            sx={{
              p: { xs: 2, sm: 3 },
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 },
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                  fontWeight: 600,
                  mb: { xs: 1.5, sm: 2 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Description
                <IconButton
                  size="small"
                  color="primary"
                  onClick={handleStartEditing}
                  disabled={
                    isAddingComment || updateBugReportMutation.isPending
                  }
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Typography>

              {isEditingDescription ? (
                <Box sx={{ mt: 2 }}>
                  <RichTextEditor
                    content={editorContent}
                    onChange={setEditorContent}
                    placeholder="Edit description..."
                    minHeight={150}
                    includeHeadings={true}
                    includeCodeBlock={true}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      startIcon={<CloseIcon />}
                      onClick={() => setIsEditingDescription(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={() => handleUpdateDescription(editorContent)}
                      disabled={updateBugReportMutation.isPending}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    mb: { xs: 3, sm: 4 },
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                    lineHeight: 1.6,
                  }}
                >
                  <ReadOnlyTipTap
                    content={editorContent || bugReport.description}
                  />
                </Box>
              )}
            </Box>

            <Grid container spacing={{ xs: 2, sm: 3 }}>
              {/* Expected Behavior */}
              <Grid item xs={12} sm={6}>
                <Box sx={{ position: "relative" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "1.1rem", sm: "1.25rem" },
                      fontWeight: 600,
                      mb: { xs: 1, sm: 1.5 },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Expected Behavior
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={handleStartEditingExpectedBehavior}
                      disabled={
                        isAddingComment || updateBugReportMutation.isPending
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Typography>

                  {isEditingExpectedBehavior ? (
                    <Box sx={{ mt: 2 }}>
                      <RichTextEditor
                        content={expectedBehaviorContent}
                        onChange={setExpectedBehaviorContent}
                        placeholder="Edit expected behavior..."
                        minHeight={120}
                        includeHeadings={false}
                        includeCodeBlock={true}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 1,
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          startIcon={<CloseIcon />}
                          onClick={() => setIsEditingExpectedBehavior(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          onClick={() =>
                            handleUpdateExpectedBehavior(
                              expectedBehaviorContent
                            )
                          }
                          disabled={updateBugReportMutation.isPending}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                        lineHeight: 1.6,
                      }}
                    >
                      {expectedBehaviorContent || bugReport.expectedBehavior ? (
                        <ReadOnlyTipTap
                          content={
                            expectedBehaviorContent ||
                            bugReport.expectedBehavior
                          }
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontStyle: "italic" }}
                        >
                          No expected behavior specified
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Actual Behavior */}
              <Grid item xs={12} sm={6}>
                <Box sx={{ position: "relative" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "1.1rem", sm: "1.25rem" },
                      fontWeight: 600,
                      mb: { xs: 1, sm: 1.5 },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Actual Behavior
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={handleStartEditingActualBehavior}
                      disabled={
                        isAddingComment || updateBugReportMutation.isPending
                      }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Typography>

                  {isEditingActualBehavior ? (
                    <Box sx={{ mt: 2 }}>
                      <RichTextEditor
                        content={actualBehaviorContent}
                        onChange={setActualBehaviorContent}
                        placeholder="Edit actual behavior..."
                        minHeight={120}
                        includeHeadings={false}
                        includeCodeBlock={true}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 1,
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          startIcon={<CloseIcon />}
                          onClick={() => setIsEditingActualBehavior(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          onClick={() =>
                            handleUpdateActualBehavior(actualBehaviorContent)
                          }
                          disabled={updateBugReportMutation.isPending}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                        lineHeight: 1.6,
                      }}
                    >
                      {actualBehaviorContent || bugReport.actualBehavior ? (
                        <ReadOnlyTipTap
                          content={
                            actualBehaviorContent || bugReport.actualBehavior
                          }
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontStyle: "italic" }}
                        >
                          No actual behavior specified
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Environment */}
              {bugReport.browserInfo && (
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "1.1rem", sm: "1.25rem" },
                      fontWeight: 600,
                      mb: { xs: 1, sm: 1.5 },
                    }}
                  >
                    Environment
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "0.875rem", sm: "1rem" },
                      lineHeight: 1.6,
                    }}
                  >
                    {bugReport.browserInfo}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>

          {/* Comments Section */}
          <Paper
            elevation={1}
            sx={{
              p: { xs: 2, sm: 3 },
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 },
            }}
          >
            <StakeholderReportOrRequestComments
              comments={bugReport.comments || []}
              onAddComment={handleAddComment}
              isSubmitting={isAddingComment}
            />
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{ order: { xs: 1, md: 2 }, mb: { xs: 3, md: 0 } }}
        >
          {/* Details Sidebar */}
          <Paper
            elevation={1}
            sx={{
              p: { xs: 2, sm: 3 },
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
                fontWeight: 600,
                mb: { xs: 1.5, sm: 2 },
              }}
            >
              Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: 1.5, sm: 2 },
              }}
            >
              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Reported By
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {getRequestedByUser(bugReport.reportedById)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Assigned To
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {bugReport.assignedToId
                    ? getRequestedByUser(bugReport.assignedToId)
                    : "No user assigned yet"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Created At
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {bugReport.createdAt
                    ? new Date(bugReport.createdAt).toLocaleDateString()
                    : "N/A"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                >
                  Last Updated
                </Typography>
                <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                  {bugReport.updatedAt
                    ? new Date(bugReport.updatedAt).toLocaleDateString()
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Paper>

          {/* Linked Freelo Tickets */}
          <Paper
            elevation={1}
            sx={{
              p: { xs: 2, sm: 3 },
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: { xs: 1.5, sm: 2 },
              }}
            >
              <LinkIcon
                color="primary"
                sx={{ fontSize: { xs: "1.1rem", sm: "1.25rem" } }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                  fontWeight: 600,
                }}
              >
                Freelo Tickets
              </Typography>
            </Box>
            {linkedTickets && linkedTickets.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 1, sm: 2 },
                }}
              >
                {linkedTickets.map((ticket: FreeloCard) => (
                  <Paper
                    key={ticket.id}
                    variant="outlined"
                    sx={{
                      p: { xs: 1.5, sm: 2 },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: { xs: 1, sm: 1.5 },
                      "&:hover": {
                        bgcolor: "action.hover",
                        transform: "translateX(4px)",
                        cursor: "pointer",
                      },
                      transition: "all 0.2s ease-in-out",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(
                          `${window.location.origin}/freelo/${ticket.boardId}#${ticket.humanReadableId}`,
                          "_blank"
                        );
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "medium",
                          fontSize: { xs: "0.9rem", sm: "1rem" },
                        }}
                      >
                        #{ticket.humanReadableId}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: { xs: "0.75rem", sm: "0.8rem" } }}
                      >
                        Assigned to {getRequestedByUser(ticket.assignee)}
                      </Typography>
                    </Box>
                    <Chip
                      label={ticket.progress === 100 ? "Done" : "In Progress"}
                      color={ticket.progress === 100 ? "success" : "info"}
                      size="small"
                      sx={{
                        height: { xs: 24, sm: 24 },
                        fontSize: { xs: "0.7rem", sm: "0.75rem" },
                      }}
                    />
                  </Paper>
                ))}
              </Box>
            ) : (
              <Typography
                color="text.secondary"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
                No tickets created yet
              </Typography>
            )}
          </Paper>

          {/* Project Information */}
          {project && (
            <Paper
              elevation={1}
              sx={{
                p: { xs: 2, sm: 3 },
                mb: { xs: 2, sm: 3 },
                borderRadius: { xs: 1.5, sm: 2 },
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: { xs: "1.1rem", sm: "1.25rem" },
                  fontWeight: 600,
                  mb: { xs: 1.5, sm: 2 },
                }}
              >
                Project Information
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 1.5, sm: 2 },
                }}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Project Name
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                    {project.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    Project Status
                  </Typography>
                  <Chip
                    label={project.status}
                    size="small"
                    color={
                      project.status === "active"
                        ? "success"
                        : project.status === "on_hold"
                          ? "warning"
                          : "default"
                    }
                    sx={{
                      height: { xs: 24, sm: 24 },
                      fontSize: { xs: "0.7rem", sm: "0.75rem" },
                      textTransform: "capitalize",
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          )}

          {/* Attachments Card */}
          <Paper
            elevation={1}
            sx={{
              p: { xs: 2, sm: 3 },
              mb: { xs: 2, sm: 3 },
              borderRadius: { xs: 1.5, sm: 2 },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
                fontWeight: 600,
                mb: { xs: 1.5, sm: 2 },
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <AttachFileIcon fontSize="small" />
              Attachments
            </Typography>

            {/* Upload new attachments */}
            <Box sx={{ mb: 3 }}>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed",
                  borderColor: isDragActive ? "primary.main" : "divider",
                  borderRadius: 1,
                  p: 2,
                  textAlign: "center",
                  cursor: "pointer",
                  bgcolor: isDragActive ? "action.hover" : "background.paper",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    borderColor: "primary.main",
                    bgcolor: "action.hover",
                  },
                  mb: 2,
                }}
              >
                <input {...getInputProps()} />
                <CloudUploadIcon
                  sx={{ fontSize: 40, color: "primary.main", mb: 1 }}
                />
                <Typography variant="body1" gutterBottom>
                  {isDragActive ? "Drop files here" : "Drag & drop files here"}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  or click to select files
                </Typography>
              </Box>

              {uploadError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {uploadError}
                </Alert>
              )}

              {uploadFiles.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Files to upload:
                  </Typography>
                  {uploadFiles.map((file, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 1,
                        mb: 1,
                        bgcolor: "background.default",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{ maxWidth: "70%" }}
                      >
                        {file.name}
                      </Typography>
                      <Button
                        size="small"
                        color="error"
                        onClick={() => handleRemoveUploadFile(index)}
                      >
                        Remove
                      </Button>
                    </Box>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                    onClick={handleUploadAttachments}
                    disabled={isUploading || uploadFiles.length === 0}
                    sx={{ mt: 1 }}
                  >
                    {isUploading ? "Uploading..." : "Upload Files"}
                  </Button>
                </Box>
              )}
            </Box>

            {/* Existing attachments */}
            {bugReport.attachments && bugReport.attachments.length > 0 ? (
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                {bugReport.attachments.map(
                  (attachment: BugReportAttachment, index: number) => {
                    // Determine if it's an image based on file extension
                    const fileExt = attachment.name
                      .split(".")
                      .pop()
                      ?.toLowerCase();
                    const isImage =
                      fileExt &&
                      ["jpg", "jpeg", "png", "gif", "webp", "svg"].includes(
                        fileExt
                      );

                    return (
                      <Grid item xs={6} key={index}>
                        <Card
                          sx={{
                            cursor: "pointer",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: { xs: 1, sm: 1.5 },
                            transition: "transform 0.2s ease-in-out",
                            "&:hover": {
                              transform: "scale(1.02)",
                              boxShadow: 3,
                            },
                            position: "relative",
                          }}
                          onClick={() => handleAttachmentClick(attachment)}
                        >
                          {/* Delete button */}
                          <IconButton
                            size="small"
                            color="error"
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              bgcolor: "rgba(255, 255, 255, 0.8)",
                              "&:hover": {
                                bgcolor: "rgba(255, 255, 255, 0.9)",
                              },
                              zIndex: 1,
                            }}
                            onClick={(e) =>
                              handleRemoveAttachment(e, attachment.id)
                            }
                            disabled={
                              isRemovingAttachment === attachment.id ||
                              isRemovingAttachmentMutation
                            }
                          >
                            {isRemovingAttachment === attachment.id ? (
                              <CircularProgress size={20} color="error" />
                            ) : (
                              <DeleteIcon fontSize="small" />
                            )}
                          </IconButton>

                          <CardMedia
                            component="img"
                            height="100"
                            image={
                              isImage ? attachment.url : "/images/file-icon.png"
                            }
                            alt={attachment.name}
                            sx={{
                              objectFit: isImage ? "cover" : "contain",
                              p: isImage ? 0 : 2,
                              bgcolor: isImage ? "transparent" : "grey.100",
                            }}
                          />
                          <CardContent
                            sx={{ p: { xs: 1, sm: 1.5 }, flexGrow: 1 }}
                          >
                            <Typography
                              variant="body2"
                              noWrap
                              title={attachment.name}
                              sx={{ fontSize: { xs: "0.7rem", sm: "0.75rem" } }}
                            >
                              {attachment.name}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            ) : (
              <Typography color="text.secondary" variant="body2">
                No attachments yet
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* File Viewer Dialog */}
      <Dialog
        open={!!selectedAttachment}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: { xs: 2, sm: 3 },
            maxHeight: "90vh",
          },
        }}
      >
        {selectedAttachment && (
          <>
            <DialogTitle
              sx={{
                pb: 1,
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
                fontWeight: 600,
              }}
            >
              {selectedAttachment.filename || selectedAttachment.name}
            </DialogTitle>
            <DialogContent dividers sx={{ p: { xs: 2, sm: 3 } }}>
              {selectedAttachment.contentType?.includes("image") ? (
                <Box
                  component="img"
                  src={selectedAttachment.url}
                  alt={selectedAttachment.filename || selectedAttachment.name}
                  sx={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "70vh",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Box sx={{ textAlign: "center", py: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    This file cannot be previewed.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    href={selectedAttachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mt: 2 }}
                  >
                    Download File
                  </Button>
                </Box>
              )}
            </DialogContent>
            <DialogActions
              sx={{ px: { xs: 2, sm: 3 }, py: { xs: 1.5, sm: 2 } }}
            >
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                href={selectedAttachment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
};
