import { useCallback, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "config/firebase";
import {
  JobListing,
  JobListingCreate,
  JobStatus,
} from "@freetech/models/careers";

const COLLECTION_NAME = "careerListings";

// Helper function to remove undefined fields from an object
const removeUndefinedFields = (obj: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  });
  return result;
};

export const useAdminCareers = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);

  // Convert Firestore document to JobListing
  const convertFromFirestore = useCallback((doc: any): JobListing => {
    const data = doc.data() as JobListing;
    return {
      ...data,
      id: doc.id,
    };
  }, []);

  // Fetch all job listings
  const { data: jobs = [], isLoading: isLoadingJobs } = useQuery({
    queryKey: ["admin", "careers"],
    queryFn: async () => {
      try {
        const q = query(
          collection(db, COLLECTION_NAME),
          orderBy("updatedAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(convertFromFirestore);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        return [];
      }
    },
  });

  // Create a new job listing
  const createJob = useMutation({
    mutationFn: async (job: JobListingCreate) => {
      try {
        const now = new Date().toISOString();
        const docRef = doc(collection(db, COLLECTION_NAME));
        
        // Prepare job data with ISO string dates
        const jobData = {
          ...job,
          id: docRef.id,
          createdAt: job.createdAt || now,
          updatedAt: job.updatedAt || now,
          publishedAt: job.status === JobStatus.Published ? (job.publishedAt || now) : null,
          closedAt: job.status === JobStatus.Closed ? (job.closedAt || now) : null,
        };
        
        // Remove any undefined fields before saving to Firestore
        await setDoc(docRef, removeUndefinedFields(jobData));
        return jobData as JobListing;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "careers"] });
    },
  });

  // Update an existing job listing
  const updateJob = useMutation({
    mutationFn: async (job: JobListing) => {
      try {
        const docRef = doc(db, COLLECTION_NAME, job.id);
        const now = new Date().toISOString();
        
        // Prepare update data with ISO string dates
        const updateData = {
          ...job,
          updatedAt: now,
          // Handle publishedAt and closedAt based on status
          publishedAt: job.status === JobStatus.Published 
            ? (job.publishedAt || now) 
            : (job.publishedAt || null),
          closedAt: job.status === JobStatus.Closed 
            ? (job.closedAt || now) 
            : (job.closedAt || null),
        };
        
        // Remove any undefined fields before updating Firestore
        await updateDoc(docRef, removeUndefinedFields(updateData));
        return updateData as JobListing;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "careers"] });
    },
  });

  // Delete a job listing
  const deleteJob = useMutation({
    mutationFn: async (jobId: string) => {
      try {
        const docRef = doc(db, COLLECTION_NAME, jobId);
        await deleteDoc(docRef);
        return jobId;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "careers"] });
    },
  });

  // Update job status
  const updateJobStatus = useMutation({
    mutationFn: async ({
      jobId,
      status,
    }: {
      jobId: string;
      status: JobStatus;
    }) => {
      try {
        const docRef = doc(db, COLLECTION_NAME, jobId);
        const now = new Date().toISOString();
        const job = jobs.find(j => j.id === jobId);
        
        if (!job) {
          throw new Error(`Job with ID ${jobId} not found`);
        }
        
        const updateData: Record<string, any> = {
          status,
          updatedAt: now,
        };

        // Set publishedAt if publishing for the first time
        if (status === JobStatus.Published && !job.publishedAt) {
          updateData.publishedAt = now;
        }

        // Set closedAt if closing for the first time
        if (status === JobStatus.Closed && !job.closedAt) {
          updateData.closedAt = now;
        }

        // Remove any undefined fields before updating Firestore
        await updateDoc(docRef, removeUndefinedFields(updateData));
        return { jobId, status };
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "careers"] });
    },
  });

  return {
    jobs,
    isLoadingJobs,
    error,
    createJob: createJob.mutateAsync,
    updateJob: updateJob.mutateAsync,
    deleteJob: deleteJob.mutateAsync,
    updateJobStatus: updateJobStatus.mutateAsync,
  };
};
