import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { Referral, ReferralFormData, ReferrerType } from "@freetech/models/referrals";

/**
 * Creates a new referral in Firestore
 * @param referralData The referral form data
 * @param userId The ID of the user making the referral
 * @param userType The type of user making the referral (freelancer, admin, stakeholder)
 * @param userName The name of the user making the referral
 * @param userEmail The email of the user making the referral
 * @returns The ID of the newly created referral
 */
export const createReferral = async (
  referralData: ReferralFormData,
  userId: string,
  userType: ReferrerType,
  userName: string,
  userEmail: string
): Promise<string> => {
  try {
    const referralCollection = collection(db, "referrals");
    
    const now = new Date().toISOString();
    
    const newReferral = {
      ...referralData,
      referrerId: userId,
      referrerType: userType,
      referrerName: userName,
      referrerEmail: userEmail,
      status: "pending",
      createdAt: now,
      updatedAt: now,
      statusHistory: [
        {
          status: "pending",
          timestamp: now,
          note: "Referral created"
        }
      ],
      rewardEligible: true,
      rewardPaid: false
    };
    
    const docRef = await addDoc(referralCollection, newReferral);
    return docRef.id;
  } catch (error) {
    console.error("Error creating referral:", error);
    throw error;
  }
}; 