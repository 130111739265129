import { getDocuments } from "core/firestore";
import { ClientStatistics } from "../../../types/admin";
import { Project } from "@freetech/models/projects";
import { ProjectAssignment } from "@freetech/models/projects";
import { StakeholderPayment } from "@freetech/models/bank";

export const getClientOverviewStatistics = async (clientId: string): Promise<ClientStatistics> => {
  try {
    // Get all projects for the client
    const projects = await getDocuments<Project>(`clients/${clientId}/projects`);

    // Get all project assignments for the client
    const projectAssignments = await getDocuments<ProjectAssignment>(`clients/${clientId}/projectAssignments`);

    // Get all payments for the client
    const payments = await getDocuments<StakeholderPayment>(`clients/${clientId}/payments`);

    // Calculate statistics
    const totalProjects = projects.length;
    const activeProjects = projects.filter(project => project.status === 'active').length;
    const completedProjects = projects.filter(project => project.status === 'completed').length;

    const allEngineers = new Set(projectAssignments.map(assignment => assignment.engineerId));
    const totalEngineers = allEngineers.size;

    const activeEngineers = new Set(projectAssignments
      .filter(assignment => assignment.status === 'in_progress')
      .map(assignment => assignment.engineerId)
    ).size;

    const totalUtilizedHours = projectAssignments.reduce((total, assignment) => 
      total + (assignment.utilizedHours || 0), 0);

    const totalEngineerExpenses = projectAssignments.reduce((total, assignment) => 
      total + (assignment.amountPaid || 0), 0);

    const totalRevenue = payments.reduce((total, payment) => 
      total + (parseFloat(payment.amount.toString()) || 0), 0);

    return {
      totalProjects: {
        label: "Total Projects",
        value: totalProjects
      },
      activeProjects: {
        label: "Active Projects",
        value: activeProjects
      },
      totalEngineers: {
        label: "Total Engineers",
        value: totalEngineers
      },
      activeEngineers: {
        label: "Active Engineers",
        value: activeEngineers
      },
      completedProjects: {
        label: "Completed Projects",
        value: completedProjects
      },
      totalUtilizedHours: {
        label: "Total Utilized Hours",
        value: totalUtilizedHours
      },
      totalEngineerExpenses: {
        label: "Engineer Expenses",
        value: totalEngineerExpenses
      },
      totalRevenue: {
        label: "Total Revenue",
        value: totalRevenue
      }
    };
  } catch (error) {
    console.error("Error fetching client statistics:", error);
    throw error;
  }
}; 