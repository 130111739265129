import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Snackbar,
  Alert,
  useTheme,
  alpha,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  CircularProgress,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import AddIcon from "@mui/icons-material/Add";
import { FilterList as FilterListIcon, Search as SearchIcon } from "@mui/icons-material";
import { Client, ICreateClient } from "@freetech/models/projects";
import { useAuth } from "hooks/auth/useAuth";

type ClientStatus = "opportunity" | "active" | "inactive";

const ClientManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  // Hooks for managing clients, stakeholders, and freelancers
  const {
    clients,
    isLoading: isLoadingClients,
    isError: isErrorClients,
    addClient,
    updateClient,
    deleteClient,
  } = useAdminClients();

  let stakeholders = [];
  let isLoadingStakeholders = false;

  const { freelancers, isLoading: isLoadingFreelancers } =
    useAdminFreelancers();

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [formData, setFormData] = useState<ICreateClient>({
    companyName: "",
    pointOfContactName: "",
    email: "",
    phone: "",
    location: "",
    size: "",
    status: "opportunity",
    introductionDate: new Date().toISOString(),
    createdByUserId: "",
    companyPhysicalAddress: {
      street: "",
      city: "",
      state: "",
      zip: "",
    },
    associatedFreelancerIds: [],
    associatedStakeholderIds: [],
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [statusFilter, setStatusFilter] = useState<ClientStatus | "all">("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);

  // Filter clients based on search query and status filter
  useEffect(() => {
    if (!clients) return;
    
    let filtered = [...clients];

    // Apply status filter first
    if (statusFilter !== "all") {
      filtered = filtered.filter(
        (client) => client.status === statusFilter
      );
    }

    // Then apply search filter
    if (searchQuery.trim() !== "") {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (client) =>
          (client.companyName &&
            client.companyName.toLowerCase().includes(lowercasedQuery)) ||
          (client.pointOfContactName &&
            client.pointOfContactName.toLowerCase().includes(lowercasedQuery)) ||
          (client.email &&
            client.email.toLowerCase().includes(lowercasedQuery)) ||
          (client.location &&
            client.location.toLowerCase().includes(lowercasedQuery))
      );
    }

    setFilteredClients(filtered);
  }, [searchQuery, statusFilter, clients]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStatusChange = (
    e: SelectChangeEvent<"opportunity" | "active" | "inactive">
  ) => {
    setFormData({
      ...formData,
      status: e.target.value as "opportunity" | "active" | "inactive",
    });
  };

  const handleStatusFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: ClientStatus | "all"
  ) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = async () => {
    if (
      !formData.companyName?.trim() ||
      !formData.pointOfContactName?.trim() ||
      !formData.email?.trim() ||
      !formData.phone?.trim() ||
      !formData.location?.trim() ||
      !formData.size?.trim() ||
      !formData.status
    ) {
      setSnackbar({
        open: true,
        message: "Please fill all required fields",
        severity: "error",
      });
      return;
    }

    try {
      if (editMode && selectedClient) {
        await updateClient.mutateAsync({
          id: selectedClient.id!,
          client: formData,
        });
        setSnackbar({
          open: true,
          message: "Client updated successfully",
          severity: "success",
        });
      } else {
        await addClient.mutateAsync(formData);
        setSnackbar({
          open: true,
          message: "Client added successfully",
          severity: "success",
        });
      }
      setOpen(false);
      resetForm();
    } catch (error) {
      console.error("Error saving client: ", error);
      setSnackbar({
        open: true,
        message: "Error saving client",
        severity: "error",
      });
    }
  };

  const handleOpenDialog = (client?: Client) => {
    if (client) {
      setEditMode(true);
      setSelectedClient(client);
      setFormData(client);
    } else {
      setEditMode(false);
      resetForm();
    }
    setOpen(true);
  };

  const resetForm = () => {
    setFormData({
      companyName: "",
      pointOfContactName: "",
      email: "",
      phone: "",
      location: "",
      size: "",
      status: "opportunity",
      introductionDate: new Date().toISOString(),
      createdByUserId: "",
      companyPhysicalAddress: {
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      associatedFreelancerIds: [],
      associatedStakeholderIds: [],
    });
    setSelectedClient(null);
  };

  const handleManageClient = (client: Client) => {
    navigate(`/${userInfo?.role}/client-management/${client.id}`);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "success";
      case "opportunity":
        return "warning";
      case "inactive":
        return "error";
      default:
        return "default";
    }
  };

  if (isLoadingClients || isLoadingStakeholders || isLoadingFreelancers) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isErrorClients) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">
          Error loading clients. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: "24px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {/* Left: Status Filter */}
        <Box
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <FilterListIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2" fontWeight="medium">
              Filter by Status:
            </Typography>
          </Box>
          <ToggleButtonGroup
            value={statusFilter}
            exclusive
            onChange={handleStatusFilterChange}
            aria-label="client status filter"
            size="small"
          >
            <ToggleButton value="all" aria-label="all clients">
              All
            </ToggleButton>
            <ToggleButton
              value="active"
              aria-label="active clients"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: alpha(theme.palette.success.main, 0.1),
                  color: theme.palette.success.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.success.main, 0.2),
                  },
                },
              }}
            >
              Active
            </ToggleButton>
            <ToggleButton
              value="opportunity"
              aria-label="opportunity clients"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: alpha(theme.palette.warning.main, 0.1),
                  color: theme.palette.warning.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.warning.main, 0.2),
                  },
                },
              }}
            >
              Opportunity
            </ToggleButton>
            <ToggleButton
              value="inactive"
              aria-label="inactive clients"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: alpha(theme.palette.error.main, 0.1),
                  color: theme.palette.error.main,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.error.main, 0.2),
                  },
                },
              }}
            >
              Inactive
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Right: Search Field and Add Button */}
        <Box sx={{ flex: "0 0 auto", display: "flex", gap: 2 }}>
          {/* <TextField
            placeholder="Search clients..."
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ minWidth: 250 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
            startIcon={<AddIcon />}
          >
            Add Client
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {filteredClients.map((client) => (
          <Grid item xs={12} sm={6} md={4} key={client.id}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "all 0.3s",
                cursor: "pointer",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: theme.shadows[10],
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                },
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
              }}
              onClick={() => handleManageClient(client)}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Avatar
                      src={client.logoUrl}
                      alt={client.companyName}
                      sx={{
                        width: 50,
                        height: 50,
                        bgcolor: alpha(theme.palette.primary.main, 0.7),
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        border: "1px solid",
                        borderColor: alpha(theme.palette.primary.main, 0.2),
                      }}
                    >
                      {client.companyName?.charAt(0).toUpperCase() || "C"}
                    </Avatar>
                    <Typography variant="h6" component="div" color="primary">
                      {client.companyName}
                    </Typography>
                  </Box>
                  <Chip
                    label={client.status}
                    color={getStatusColor(client.status)}
                    size="small"
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  <strong>Contact:</strong> {client.pointOfContactName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Email:</strong> {client.email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Phone:</strong> {client.phone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Location:</strong> {client.location}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Size:</strong> {client.size}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {filteredClients.length === 0 && (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography variant="h6" color="text.secondary">
            No clients found matching your search.
          </Typography>
        </Box>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{editMode ? "Edit Client" : "Add New Client"}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
            <TextField
              autoFocus
              name="companyName"
              label="Company Name"
              fullWidth
              value={formData.companyName}
              onChange={handleInputChange}
            />
            <TextField
              name="pointOfContactName"
              label="Point of Contact Name"
              fullWidth
              value={formData.pointOfContactName}
              onChange={handleInputChange}
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
            />
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              value={formData.phone}
              onChange={handleInputChange}
            />
            <TextField
              name="location"
              label="Location"
              fullWidth
              value={formData.location}
              onChange={handleInputChange}
            />
            <TextField
              name="size"
              label="Size"
              fullWidth
              value={formData.size}
              onChange={handleInputChange}
            />
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={formData.status as "opportunity" | "active" | "inactive"}
                onChange={handleStatusChange}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="opportunity">Opportunity</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={addClient.isPending || updateClient.isPending}
          >
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ClientManagement;
