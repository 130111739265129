import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import { IBugReport } from "@freetech/models";
import { useFreelo } from "hooks/freelo/useFreelo";
import { useAdminBugReport } from "hooks/admin/useAdminBugReport";

interface CreateTicketDialogProps {
  open: boolean;
  onClose: () => void;
  bugReport: IBugReport;
  boardId: string;
  listId: string;
  onSuccess: () => void;
  onError: (error: string) => void;
}

export const CreateTicketDialog: React.FC<CreateTicketDialogProps> = ({
  open,
  onClose,
  bugReport,
  boardId,
  listId,
  onSuccess,
  onError,
}) => {
  const [title, setTitle] = useState(bugReport.title);
  const [description, setDescription] = useState(
    `Bug Report: ${bugReport.description}\n\nPriority: ${
      bugReport.priority
    }\nType: ${bugReport.type}\nReported by: ${bugReport.reportedById || "Unknown"}`
  );
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);

  const { createCard } = useFreelo();
  const { updateBugReport } = useAdminBugReport(bugReport.clientId);

  const handleSubmit = async () => {
    if (!title.trim()) return;
    setIsCreatingTicket(true);

    try {
      const cardId = await createCard.mutateAsync({
        boardId,
        listId,
        title,
        description,
        assignee: bugReport.assignedToId,
        bugReportId: [bugReport.id],
        labels: [
          {
            id: "bug-report",
            name: "Bug Report",
            color: "red",
          },
        ],
        reporter: bugReport.reportedById,
      });

      // Update the bug report with the new ticket ID
      await updateBugReport.mutateAsync({
        id: bugReport.id,
        data: {
          freeloTicketIds: [...(bugReport.freeloTicketIds || []), cardId],
        },
      });

      onSuccess();
      onClose();
    } catch (error) {
      onError("Failed to create Freelo ticket. Please try again.");
      console.error("Error creating Freelo ticket:", error);
    } finally {
      setIsCreatingTicket(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create Freelo Ticket</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isCreatingTicket || !title.trim()}
        >
          {isCreatingTicket ? <CircularProgress size={24} /> : "Create Ticket"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 