export enum JobType {
  FullTime = "Full-Time",
  PartTime = "Part-Time",
  Contract = "Contract",
  Internship = "Internship",
}

export enum JobLocation {
  Remote = "Remote",
  Hybrid = "Hybrid",
  OnSite = "On-Site",
}

export enum JobCategory {
  Engineering = "Engineering",
  Design = "Design",
  Marketing = "Marketing",
  Sales = "Sales",
  CustomerSupport = "Customer Support",
  ProductManagement = "Product Management",
  Operations = "Operations",
  Finance = "Finance",
  HR = "Human Resources",
  Other = "Other",
}

export enum JobStatus {
  Draft = "draft",
  Published = "published",
  Closed = "closed",
  Hidden = "hidden",
}

export interface JobListingCreate {
  title: string;
  shortDescription: string;
  description: string;
  requirements: string[];
  responsibilities: string[];
  jobType: JobType;
  location: JobLocation;
  category: JobCategory;
  status: JobStatus;
  salary?: {
    min: number;
    max: number;
  };
  hourlyRate?: {
    min: number;
    max: number;
  };
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  publishedAt?: string;
  closedAt?: string;
}

export interface JobListing extends JobListingCreate {
  id: string;
}
