import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { db, storage } from "config/firebase";
import { DateTime } from "luxon";
import { Card, CardAttachment } from "@freetech/models/freelo";
export const deleteAttachment = async (params: { boardId: string; cardId: string; attachmentId: string }): Promise<void> => {
  const { boardId, cardId, attachmentId } = params;
  const cardRef = doc(db, "boards", boardId, "cards", cardId);
  const cardDoc = await getDoc(cardRef);
  const card = cardDoc.data() as Card;

  const attachment = card.attachments?.find((a) => a.id === attachmentId);
  if (!attachment) throw new Error("Attachment not found");

  try {
    const storageRef = ref(storage, `boards/${boardId}/cards/${cardId}/${attachment.fileName}`);
    await deleteObject(storageRef);
  } catch (error) {
    console.error("Error deleting file from storage:", error);
  }

  await updateDoc(cardRef, {
    attachments: card.attachments?.filter((a) => a.id !== attachmentId) || [],
    updatedAt: DateTime.now().toISO(),
  });
}; 