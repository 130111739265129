import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
  IconButton,
  Tooltip,
  ButtonBase,
} from "@mui/material";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useAdminStakeholders } from "hooks/admin/useAdminStakeholders";
import { Stakeholder } from "@freetech/models/user";
import { Client } from "@freetech/models/projects";
import { useQueryClient } from "@tanstack/react-query";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";

interface ClientLegalOwnerProps {
  client: Client;
  clientId: string;
}

const ClientLegalOwner: React.FC<ClientLegalOwnerProps> = ({
  client,
  clientId,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const queryClient = useQueryClient();

  const { setLegalOwner } = useAdminClient(clientId);
  const { data: stakeholders = [], isLoading: isLoadingStakeholders } = useAdminStakeholders();

  // Find the current legal owner
  const currentOwner = stakeholders.find(
    (s: Stakeholder) => s.id === client.legalOwnerFirebaseId
  );

  const handleSetLegalOwner = async (stakeholderId: string) => {
    try {
      await setLegalOwner.mutate(stakeholderId);
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error setting legal owner:", error);
    }
  };

  // Handle removing the legal owner
  const handleRemoveLegalOwner = async () => {
    try {
      await setLegalOwner.mutate("");
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error removing legal owner:", error);
    }
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography variant="h6">Legal Owner</Typography>
          <Tooltip title="Edit Legal Owner">
            <IconButton 
              color="primary" 
              onClick={() => setOpenDialog(true)}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
        
        {currentOwner ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ mr: 2 }}>
              {currentOwner.displayName?.charAt(0) || <PersonIcon />}
            </Avatar>
            <Box>
              <Typography variant="body1">
                {currentOwner.displayName || "Unknown User"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {currentOwner.email}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              startIcon={<PersonIcon />}
              onClick={() => setOpenDialog(true)}
            >
              Assign Legal Owner
            </Button>
          </Box>
        )}
      </CardContent>

      {/* Dialog for selecting legal owner */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select Legal Owner</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            The legal owner will have full access to this client's data and will be the primary contact for legal matters.
          </Typography>
          
          {isLoadingStakeholders ? (
            <Typography>Loading stakeholders...</Typography>
          ) : stakeholders.length === 0 ? (
            <Typography>No stakeholders available</Typography>
          ) : (
            <List sx={{ width: "100%" }}>
              {stakeholders.map((stakeholder: Stakeholder) => (
                <ButtonBase
                  key={stakeholder.id}
                  onClick={() => handleSetLegalOwner(stakeholder.id)}
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    borderRadius: 1,
                    mb: 1,
                    backgroundColor: stakeholder.id === client.legalOwnerFirebaseId ? "primary.light" : "transparent",
                    "&:hover": {
                      backgroundColor: stakeholder.id === client.legalOwnerFirebaseId ? "primary.light" : "action.hover",
                    },
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        {stakeholder.displayName?.charAt(0) || <PersonIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={stakeholder.displayName || "Unknown User"}
                      secondary={stakeholder.email}
                    />
                    {stakeholder.id === client.legalOwnerFirebaseId && (
                      <Chip label="Current Owner" color="primary" size="small" />
                    )}
                  </ListItem>
                </ButtonBase>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          {client.legalOwnerFirebaseId && (
            <Button 
              onClick={handleRemoveLegalOwner} 
              color="error"
            >
              Remove Owner
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ClientLegalOwner; 