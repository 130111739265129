import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Chip,
  Button,
  IconButton,
  Tooltip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TurnPageIcon from "@mui/icons-material/AutoStories";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IFeatureRequest,
  IBugReport,
  BugReportAttachment,
} from "@freetech/models";
import { Card as FreeloCard } from "@freetech/models/freelo";
import ReadOnlyTipTap from "components/TipTap/ReadOnlyTipTap";
import { useFreelo } from "hooks/freelo/useFreelo";
import { useMemo } from "react";
import { useFreeloTickets } from "hooks/freelo/useFreeloTickets";
import { useFreeloBoard } from "hooks/freelo/useFreeloBoard";

// Styled components for the flip card
const FlipCard = styled(motion.div)({
  position: "relative",
  width: "100%",
  height: "100%",
  transformStyle: "preserve-3d",
  perspective: 1000,
});

const CardSide = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
}));

const CardFront = styled(CardSide)({
  transform: "rotateY(0deg)",
});

// Commented out CardBack as part of disabling flip functionality
// const CardBack = styled(CardSide)({
//   transform: "rotateY(180deg)",
// });

interface RequestCardProps {
  item: IFeatureRequest | IBugReport;
  type: "feature" | "bug";
  projectId: string;
  onNavigate: (path: string) => void;
  onFileView?: (file: BugReportAttachment) => void;
  onFileUpload?: (files: File[]) => void;
  onDeleteAttachment?: (attachmentId: string) => Promise<void>;
  isRemovingAttachment?: boolean;
  allowedFileTypes?: string[];
}

export const RequestCard: React.FC<RequestCardProps> = ({
  item,
  type,
  projectId,
  onNavigate,
  onFileView,
  onFileUpload,
  onDeleteAttachment,
  isRemovingAttachment = false,
  allowedFileTypes,
}) => {
  // Commented out flip state as part of disabling flip functionality
  // const [isFlipped, setIsFlipped] = React.useState(false);

  const { data: board } = useFreeloBoard(projectId);
  const { data: linkedTickets, isLoading: isLoadingTickets } = useFreeloTickets(
    projectId,
    item.freeloTicketIds
  );

  // Commented out handleFlip as part of disabling flip functionality
  // const handleFlip = (e: React.MouseEvent) => {
  //   e.stopPropagation();
  //   setIsFlipped(!isFlipped);
  // };

  // Delete confirmation dialog state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState<BugReportAttachment | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation();
    const path =
      type === "feature"
        ? `/stakeholder/projects/${projectId}/feature-requests/${item.id}`
        : `/stakeholder/projects/${projectId}/bug-reports/${item.id}`;
    onNavigate(path);
  };

  const handleDeleteClick = (e: React.MouseEvent, attachment: BugReportAttachment) => {
    e.stopPropagation();
    setAttachmentToDelete(attachment);
    setDeleteDialogOpen(true);
  };
  
  const confirmDeleteAttachment = async () => {
    if (!attachmentToDelete || !onDeleteAttachment) return;
    
    try {
      setIsDeleting(true);
      await onDeleteAttachment(attachmentToDelete.id);
    } catch (error) {
      console.error("Error deleting attachment:", error);
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
      setAttachmentToDelete(null);
    }
  };
  
  const cancelDeleteAttachment = () => {
    setDeleteDialogOpen(false);
    setAttachmentToDelete(null);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
      case "resolved":
        return "success";
      case "pending_approval":
      case "open":
        return "warning";
      case "in_progress":
        return "info";
      case "rejected":
      case "closed":
        return "error";
      default:
        return "default";
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case "critical":
      case "high":
        return "error";
      case "medium":
        return "warning";
      case "low":
        return "info";
      default:
        return "default";
    }
  };

  const getBorderColor = (theme: any) => {
    if (type === "feature") {
      return item.status === "approved"
        ? theme.palette.success.main
        : theme.palette.grey[300];
    }
    const bugReport = item as IBugReport;
    switch (bugReport.priority) {
      case "critical":
        return theme.palette.error.main;
      case "high":
        return theme.palette.warning.main;
      case "medium":
        return theme.palette.info.main;
      default:
        return theme.palette.grey[300];
    }
  };

  const hasAttachments =
    "attachments" in item &&
    Array.isArray(item.attachments) &&
    item.attachments.length > 0;
  const attachments =
    hasAttachments && "attachments" in item && item.attachments
      ? item.attachments
      : [];
  const attachmentsCount = attachments.length;

  const getFormattedDate = () => {
    if (type === "feature") {
      const featureRequest = item as IFeatureRequest;
      return featureRequest.requestDate
        ? new Date(featureRequest.requestDate).toLocaleDateString()
        : "N/A";
    } else {
      const bugReport = item as IBugReport;
      return bugReport.createdAt
        ? new Date(bugReport.createdAt).toLocaleDateString()
        : "N/A";
    }
  };

  return (
    <>
      <Box sx={{ height: { xs: 320, sm: 300 }, perspective: 1000 }}>
        <FlipCard
          // Removed flip animation
          // animate={{ rotateY: isFlipped ? 180 : 0 }}
          transition={{ duration: 0.6 }}
          onClick={handleNavigate}
          style={{ transformStyle: "preserve-3d" }}
        >
          <CardFront
            elevation={2}
            sx={{
              borderLeft: 3,
              borderColor: getBorderColor,
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
              borderRadius: { xs: 1.5, sm: 2 },
              p: { xs: 1.5, sm: 2 },
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: (theme) => theme.shadows[4],
              },
              position: "relative",
            }}
          >
            {/* Header Section */}
            <Box sx={{ mb: 1.5 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "1rem", sm: "1.25rem" },
                      lineHeight: 1.3,
                    }}
                  >
                    {item.title}
                  </Typography>
                  {linkedTickets && linkedTickets.length > 0 && (
                    <Tooltip
                      title={`${linkedTickets.length} Freelo ticket${linkedTickets.length !== 1 ? "s" : ""} linked`}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AssignmentIcon color="primary" fontSize="small" />
                        <Typography
                          variant="caption"
                          color="primary.main"
                          sx={{ ml: 0.5 }}
                        >
                          {linkedTickets.length}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                
                {/* Display attachment count without flip functionality */}
                {hasAttachments && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <AttachFileIcon
                      fontSize="small"
                      color="action"
                    />
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ fontWeight: "medium", fontSize: { xs: "0.7rem", sm: "0.75rem" } }}
                    >
                      {attachmentsCount} file{attachmentsCount !== 1 ? "s" : ""}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.75,
                  mb: 1,
                }}
              >
                <Chip
                  label={item.status}
                  color={getStatusColor(item.status)}
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    height: { xs: 24, sm: 24 },
                    fontSize: { xs: "0.7rem", sm: "0.75rem" },
                  }}
                />
                {item.priority && (
                  <Chip
                    label={`${item.priority} priority`}
                    color={getPriorityColor(item.priority)}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      height: { xs: 24, sm: 24 },
                      fontSize: { xs: "0.7rem", sm: "0.75rem" },
                    }}
                  />
                )}
                <Chip
                  label={getFormattedDate()}
                  variant="outlined"
                  size="small"
                  sx={{
                    height: { xs: 24, sm: 24 },
                    fontSize: { xs: "0.7rem", sm: "0.75rem" },
                  }}
                />
              </Box>
            </Box>

            {/* Description Section */}
            <Box
              sx={{
                flex: 1,
                overflow: "hidden",
                mb: 1.5,
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: "vertical",
                  fontSize: { xs: "0.8rem", sm: "0.875rem" },
                  lineHeight: 1.5,
                }}
              >
                {item.description}
              </Typography>
            </Box>

            {/* Footer Section */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: "auto",
              }}
            >
              <Button
                endIcon={<ArrowForwardIcon />}
                size="small"
                onClick={handleNavigate}
                sx={{
                  fontSize: { xs: "0.75rem", sm: "0.8rem" },
                  textTransform: "none",
                }}
              >
                View Details
              </Button>
            </Box>
          </CardFront>
        </FlipCard>
      </Box>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDeleteAttachment}
        aria-labelledby="delete-attachment-dialog-title"
        aria-describedby="delete-attachment-dialog-description"
      >
        <DialogTitle id="delete-attachment-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography id="delete-attachment-dialog-description">
            Are you sure you want to remove this attachment? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteAttachment} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={confirmDeleteAttachment} 
            color="error" 
            variant="contained"
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={16} color="inherit" /> : <DeleteIcon />}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
