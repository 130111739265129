import React, { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Tooltip,
  FormHelperText,
  IconButton,
  Box,
  TextField,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Checkbox,
  FormControlLabel,
  Chip,
} from "@mui/material";
import { useAdminInvoicing } from "hooks/admin/useAdminInvoicing";
import { ClientInvoiceData } from "@freetech/models/bank";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { ExtendedClientInvoiceData } from "hooks/admin/useAdminInvoicing";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSessionStorageFormState } from "hooks/useSessionStorage";
import { ConsultingServicesAgreement, BillableRate } from "@freetech/models/projects";

interface GenerateInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  isEditMode?: boolean;
  editingInvoice?: ClientInvoiceData | null;
  onSubmit?: (invoiceData: ExtendedClientInvoiceData) => Promise<void>;
  clientId?: string;
}

// Initial state for a new invoice
const initialInvoiceData: ExtendedClientInvoiceData = {
  invoiceNumber: "",
  invoiceDate: new Date().toISOString().split("T")[0],
  dueDate: "",
  billTo: { name: "", company: "" },
  shipTo: { name: "", company: "" },
  items: [{ description: "", quantity: 1, rate: 0 }],
  terms: "Net 30",
  discount: 0,
  id: "",
  projectId: "",
  csaId: "",
  clientId: "",
  clientName: "",
  projectName: "",
  csaName: "",
  totalDollarAmountDue: 0,
  isClientSpecific: false,
  cardPaymentsAllowed: false,
  cardPaymentFeeWaived: false,
};

const GenerateInvoiceDialog: React.FC<GenerateInvoiceDialogProps> = ({
  open,
  onClose,
  isEditMode = false,
  editingInvoice = null,
  onSubmit,
  clientId = "",
}) => {
  // Use session storage for form persistence
  const [storedInvoiceData, setStoredInvoiceData, clearStoredInvoiceData] = 
    useSessionStorageFormState<ExtendedClientInvoiceData>('invoice-form-data', initialInvoiceData);
  
  // Local state for the invoice form
  const [invoiceData, setInvoiceData] = useState<ExtendedClientInvoiceData>(
    // Initialize from session storage if available
    storedInvoiceData
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [viewingCSA, setViewingCSA] = useState(false);
  const [csaFileUrl, setCsaFileUrl] = useState<string | null>(null);
  const [selectedCSA, setSelectedCSA] = useState<ConsultingServicesAgreement | null>(null);

  // Get clients and projects data
  const { clients } = useAdminClients();
  const { client } = useAdminClient(clientId);
  const {
    invoices,
    isLoadingInvoices,
    createInvoice,
    isCreatingInvoice
  } = useAdminInvoicing();

  const projects = client?.projects || [];
  const csas = client?.consultingServicesAgreements || [];

  // Combine loading states
  const isLoading = isLoadingInvoices || isCreatingInvoice || isSubmitting;

  // Reset form when dialog opens/closes
  useEffect(() => {
    if (open) {
      if (isEditMode && editingInvoice) {
        // Create a deep copy to avoid reference issues
        const invoiceCopy = JSON.parse(JSON.stringify(editingInvoice)) as ExtendedClientInvoiceData;
        
        // Ensure client ID is set
        if (!invoiceCopy.clientId && clientId) {
          invoiceCopy.clientId = clientId;
        }
        
        // Set isClientSpecific flag
        invoiceCopy.isClientSpecific = true;
        
        // Set the invoice data
        setInvoiceData(invoiceCopy);
        setStoredInvoiceData(invoiceCopy);
        console.log("Setting invoice data for editing:", invoiceCopy);
      } else {
        // Reset to initial state for new invoice
        resetForm();
        
        // Set client ID for new invoice
        if (clientId) {
          const newData = {
            ...initialInvoiceData,
            clientId: clientId,
            isClientSpecific: true
          };
          setInvoiceData(newData);
          setStoredInvoiceData(newData);
        }
      }
    }
  }, [open, isEditMode, editingInvoice, clientId]);

  // Update billing info when client changes
  useEffect(() => {
    if (invoiceData.clientId && !isEditMode) {
      const selectedClient = clients?.find(
        (client) => client.id === invoiceData.clientId
      );
      if (selectedClient) {
        const updatedData = {
          ...invoiceData,
          billTo: {
            name: selectedClient.pointOfContactName || "",
            company: selectedClient.companyName
          }
        };
        setInvoiceData(updatedData);
        setStoredInvoiceData(updatedData);
      }
    }
  }, [invoiceData.clientId, clients, isEditMode]);

  // Update billing info when client object changes
  useEffect(() => {
    if (client && clientId) {
      const updatedData = {
        ...invoiceData,
        billTo: {
          name: client.pointOfContactName || "",
          company: client.companyName || ""
        }
      };
      setInvoiceData(updatedData);
      setStoredInvoiceData(updatedData);
    }
  }, [client, clientId]);

  // Form reset function
  const resetForm = () => {
    setInvoiceData(initialInvoiceData);
    clearStoredInvoiceData();
  };

  // Form submission handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setIsSubmitting(true);
      
      // Calculate total amount
      const subtotal = invoiceData.items.reduce(
        (sum, item) => sum + (item.quantity || 0) * (item.rate || 0),
        0
      );
      
      // Apply discount
      const discountAmount = (subtotal * (invoiceData.discount || 0)) / 100;
      const totalDollarAmountDue = subtotal - discountAmount;
      
      // Create complete invoice data
      const completeInvoiceData: ExtendedClientInvoiceData = {
        ...invoiceData,
        totalDollarAmountDue,
        clientId: clientId || invoiceData.clientId,
        // Include client name if available
        clientName: client?.companyName || invoiceData.clientName || "",
      };
      
      if (onSubmit) {
        // Use the custom submit handler if provided
        await onSubmit(completeInvoiceData);
      } else {
        // Use the default behavior
        await createInvoice(completeInvoiceData);
      }
      
      // Clear session storage after successful submission
      clearStoredInvoiceData();
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error creating/updating invoice:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Form field change handlers
  const handleChange = (name: string, value: string | number | boolean) => {
    const updatedData = {
      ...invoiceData,
      [name]: value,
    };
    setInvoiceData(updatedData);
    setStoredInvoiceData(updatedData);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  const handleAddressChange = (
    type: "billTo" | "shipTo", 
    field: "name" | "company", 
    value: string
  ) => {
    const updatedData = {
      ...invoiceData,
      [type]: { ...invoiceData[type], [field]: value },
    };
    setInvoiceData(updatedData);
    setStoredInvoiceData(updatedData);
  };

  const handleItemChange = (
    index: number,
    field: keyof ExtendedClientInvoiceData["items"][0],
    value: string | number
  ) => {
    const updatedData = {
      ...invoiceData,
      items: invoiceData.items.map((item, i) =>
        i === index
          ? {
              ...item,
              [field]: field === "description" ? value : Number(value),
            }
          : item
      ),
    };
    setInvoiceData(updatedData);
    setStoredInvoiceData(updatedData);
  };

  const handleAddItem = () => {
    const updatedData = {
      ...invoiceData,
      items: [...invoiceData.items, { description: "", quantity: 1, rate: 0 }],
    };
    setInvoiceData(updatedData);
    setStoredInvoiceData(updatedData);
  };

  const handleSelectChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    
    let updatedData;
    if (name === "clientId") {
      updatedData = { ...invoiceData, [name]: value, projectId: "", csaId: "" };
    } else if (name === "projectId") {
      updatedData = { ...invoiceData, [name]: value, csaId: "" };
    } else {
      updatedData = { ...invoiceData, [name]: value };
    }
    
    setInvoiceData(updatedData);
    setStoredInvoiceData(updatedData);
  };

  // Calculate total amount
  const totalAmount = invoiceData.items.reduce(
    (sum, item) => sum + (item.quantity || 0) * (item.rate || 0),
    0
  );

  // Calculate discount amount
  const discountAmount = (totalAmount * (invoiceData.discount || 0)) / 100;
  
  // Calculate final total after discount
  const finalTotal = totalAmount - discountAmount;

  // Use memoized filtered CSAs based on the selected project
  const projectCsas = useMemo(() => {
    if (!invoiceData.projectId || !client?.consultingServicesAgreements) {
      return [];
    }
    
    return client.consultingServicesAgreements.filter(
      csa => csa.projectId === invoiceData.projectId && csa.status === "active"
    );
  }, [client?.consultingServicesAgreements, invoiceData.projectId]);
    
  // Check if there are any CSAs available for the selected project
  const hasAvailableCsas = projectCsas.length > 0;
  
  // Message to display when no CSAs are available
  const noCSAsMessage = invoiceData.projectId 
    ? "No active Consulting Services Agreements found for this project. A CSA is required to generate an invoice."
    : "Please select a project first to see available Consulting Services Agreements.";

  // Check if form is valid
  const isFormValid = Boolean(
    invoiceData.clientId &&
    invoiceData.projectId &&
    invoiceData.csaId &&
    invoiceData.invoiceNumber &&
    invoiceData.invoiceDate &&
    invoiceData.dueDate &&
    invoiceData.items.length > 0 &&
    invoiceData.items.every(item => 
      item.description && 
      item.description.trim() !== '' && 
      item.quantity > 0 && 
      item.rate >= 0
    )
  );

  // Debug validation issues
  useEffect(() => {
    console.log("Validation state:", {
      hasClientId: Boolean(invoiceData.clientId),
      hasProjectId: Boolean(invoiceData.projectId),
      hasCsaId: Boolean(invoiceData.csaId),
      hasInvoiceNumber: Boolean(invoiceData.invoiceNumber),
      hasInvoiceDate: Boolean(invoiceData.invoiceDate),
      hasDueDate: Boolean(invoiceData.dueDate),
      hasItems: invoiceData.items.length > 0,
      itemsValid: invoiceData.items.every(item => 
        item.description && 
        item.description.trim() !== '' && 
        item.quantity > 0 && 
        item.rate >= 0
      ),
      isFormValid
    });
  }, [invoiceData, isFormValid]);

  // Handle viewing CSA
  const handleViewCSA = () => {
    if (!invoiceData.csaId) return;
    
    // Find the CSA in the project-specific list
    const selectedCSADoc = projectCsas.find(csa => csa.id === invoiceData.csaId);
    if (selectedCSADoc && selectedCSADoc.fileUrl) {
      setCsaFileUrl(selectedCSADoc.fileUrl);
      setSelectedCSA(selectedCSADoc);
      setViewingCSA(true);
    } else {
      // Handle case where CSA doesn't have a file URL
      alert("No viewable document found for this CSA.");
    }
  };

  // Handle back to invoice
  const handleBackToInvoice = () => {
    setViewingCSA(false);
    setCsaFileUrl(null);
    setSelectedCSA(null);
  };

  // Get the selected CSA details
  const currentCSA = invoiceData.csaId 
    ? projectCsas.find(csa => csa.id === invoiceData.csaId) 
    : null;

  // Budget comparison logic
  const getBudgetStatus = () => {
    if (!currentCSA || !currentCSA.monthlyBudget) return null;
    
    if (finalTotal > currentCSA.monthlyBudget) {
      return { status: 'over', color: 'error.main', message: 'Over budget' };
    } else if (finalTotal === currentCSA.monthlyBudget) {
      return { status: 'at', color: 'warning.main', message: 'At budget' };
    } else {
      const percentOfBudget = (finalTotal / currentCSA.monthlyBudget) * 100;
      if (percentOfBudget >= 90) {
        return { status: 'near', color: 'warning.main', message: 'Near budget' };
      } else {
        return { status: 'under', color: 'success.main', message: 'Under budget' };
      }
    }
  };

  const budgetStatus = getBudgetStatus();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle sx={{ paddingRight: viewingCSA ? '60px' : '24px' }}>
        {viewingCSA 
          ? `Viewing CSA: ${currentCSA?.name || ""}` 
          : isEditMode 
            ? "Edit Invoice" 
            : "Generate New Invoice"
        }
      </DialogTitle>
      <DialogContent dividers>
        {viewingCSA && csaFileUrl ? (
          <Box sx={{ width: '100%', height: '70vh', position: 'relative' }}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={handleBackToInvoice}
              sx={{  left: 0, top: 0}}
              variant="outlined"
              color="primary"
            >
              Back to Invoice
            </Button>
            <Box sx={{ width: '100%', height: '100%', mt: 6 }}>
              <iframe 
                src={csaFileUrl} 
                width="100%" 
                height="100%" 
                style={{ border: 'none' }}
                title="CSA Document"
              />
            </Box>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Invoice Details
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {clientId && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Client</InputLabel>
                    <Select
                      name="clientId"
                      value={invoiceData.clientId || clientId}
                      onChange={handleSelectChange}
                      disabled={isEditMode || !!clientId}
                    >
                      {clients?.map((client) => (
                        <MenuItem
                          key={client.id || clientId}
                          value={client.id || clientId}
                        >
                          {client.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                    {(isEditMode || !!clientId) && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        {isEditMode
                          ? "Client cannot be changed when editing"
                          : "Client is pre-selected from the current view"}
                      </Typography>
                    )}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Project</InputLabel>
                  <Select
                    name="projectId"
                    value={invoiceData.projectId}
                    onChange={handleSelectChange}
                    disabled={(!invoiceData.clientId && !clientId) || isEditMode}
                  >
                    {projects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Tooltip 
                    title={!hasAvailableCsas ? noCSAsMessage : ""}
                    placement="top"
                    arrow
                  >
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Consulting Services Agreement</InputLabel>
                      <Select
                        name="csaId"
                        value={invoiceData.csaId}
                        onChange={handleSelectChange}
                        disabled={!invoiceData.projectId || isEditMode || !hasAvailableCsas || isLoading}
                      >
                        {isLoading ? (
                          <MenuItem disabled>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <CircularProgress size={20} sx={{ mr: 1 }} />
                              Loading CSAs...
                            </Box>
                          </MenuItem>
                        ) : (
                          projectCsas.map((csa) => (
                            <MenuItem key={csa.id} value={csa.id}>
                              {csa.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      {!isLoading && !hasAvailableCsas && invoiceData.projectId && (
                        <FormHelperText error>
                          No active CSAs available for this project. A CSA is required to generate an invoice.
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Tooltip>
                  {invoiceData.csaId && (
                    <Tooltip title="View CSA Document">
                      <IconButton 
                        onClick={handleViewCSA}
                        sx={{ ml: 1, mb: 1 }}
                        color="primary"
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Invoice Number"
                  name="invoiceNumber"
                  value={invoiceData.invoiceNumber}
                  onChange={handleInputChange}
                  required
                  disabled={isEditMode}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Invoice Date"
                  name="invoiceDate"
                  type="date"
                  value={invoiceData.invoiceDate}
                  onChange={handleInputChange}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Due Date"
                  name="dueDate"
                  type="date"
                  value={invoiceData.dueDate}
                  onChange={handleInputChange}
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Bill To Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Contact Name"
                  value={invoiceData.billTo?.name || client?.pointOfContactName || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={!client}
                  sx={{ bgcolor: "action.hover" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company"
                  value={invoiceData.billTo?.company || client?.companyName || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={!client}
                  sx={{ bgcolor: "action.hover" }}
                />
              </Grid>
              {invoiceData.clientId && (
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    Client ID: {invoiceData.clientId}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Items
            </Typography>

            {/* Display billable rates from selected CSA */}
            {currentCSA && currentCSA.billableRates && currentCSA.billableRates.length > 0 && (
              <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
                <Typography variant="subtitle2" gutterBottom>
                  CSA Billable Rates Reference:
                </Typography>
                <Grid container spacing={2}>
                  {currentCSA.billableRates.map((rate: BillableRate, index: number) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {rate.role || 'Role'}:
                        </Typography>
                        <Typography variant="body2">
                          ${rate.rate}/hr
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {invoiceData.items.map((item, index) => (
              <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Description"
                    value={item.description}
                    onChange={(e) =>
                      handleItemChange(index, "description", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      handleItemChange(index, "quantity", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Rate"
                    type="number"
                    value={item.rate}
                    onChange={(e) =>
                      handleItemChange(index, "rate", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Amount"
                    type="number"
                    value={(item.quantity || 0) * (item.rate || 0)}
                    InputProps={{
                      readOnly: true,
                      startAdornment: <span>$</span>,
                    }}
                    sx={{ bgcolor: "action.hover" }}
                  />
                </Grid>
              </Grid>
            ))}
            <Button
              variant="outlined"
              onClick={handleAddItem}
              sx={{ mt: 1, mb: 3 }}
            >
              Add Item
            </Button>

            {/* Card Payment Options */}
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Payment Options
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={invoiceData.cardPaymentsAllowed || false}
                        onChange={(e) => 
                          handleChange("cardPaymentsAllowed", e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Allow Card Payments"
                  />
                </Grid>
                
                {invoiceData.cardPaymentsAllowed && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={invoiceData.cardPaymentFeeWaived || false}
                          onChange={(e) => 
                            handleChange("cardPaymentFeeWaived", e.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Waive Card Payment Processing Fee"
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between", alignItems: "flex-start", gap: 2 }}>
              {/* Monthly Budget Display - Now on the left */}
              {currentCSA && currentCSA.monthlyBudget ? (
                <Box 
                  sx={{ 
                    p: 1.5, 
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '45%',
                    height: 'fit-content'
                  }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Monthly Budget
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      ${currentCSA.monthlyBudget.toFixed(2)}
                    </Typography>
                    {budgetStatus && (
                      <Chip 
                        label={budgetStatus.message} 
                        size="small" 
                        sx={{ 
                          bgcolor: budgetStatus.color,
                          color: 'white',
                          fontWeight: 'medium'
                        }}
                      />
                    )}
                  </Box>
                  {budgetStatus && budgetStatus.status !== 'over' && (
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                      Remaining: ${(currentCSA.monthlyBudget - finalTotal).toFixed(2)}
                    </Typography>
                  )}
                  {budgetStatus && budgetStatus.status === 'over' && (
                    <Typography variant="caption" color="error.main" sx={{ mt: 0.5, fontWeight: 'medium' }}>
                      Over by: ${(finalTotal - currentCSA.monthlyBudget).toFixed(2)}
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box sx={{ width: '45%' }} />
              )}
              
              {/* Calculator-style summary - Still on the right */}
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'flex-end',
                p: 2,
                borderRadius: 1,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                width: '45%'
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                  <Typography variant="body1">Subtotal:</Typography>
                  <Typography variant="body1">${totalAmount.toFixed(2)}</Typography>
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ mr: 1 }}>Discount:</Typography>
                    <TextField
                      size="small"
                      variant="standard"
                      type="number"
                      value={invoiceData.discount || 0}
                      onChange={(e) => handleChange("discount", parseFloat(e.target.value) || 0)}
                      InputProps={{
                        inputProps: { 
                          min: 0, 
                          max: 100,
                          step: 0.1,
                          style: { 
                            width: '40px', 
                            textAlign: 'right'
                          }
                        },
                        endAdornment: <Typography variant="body2">%</Typography>,
                        disableUnderline: false
                      }}
                    />
                  </Box>
                  <Typography variant="body1" color="error.main">-${discountAmount.toFixed(2)}</Typography>
                </Box>
                
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  width: '100%', 
                  pt: 1,
                  mt: 1,
                  borderTop: '1px solid',
                  borderColor: 'divider'
                }}>
                  <Typography variant="h6">Total:</Typography>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="h6">${finalTotal.toFixed(2)}</Typography>
                    {finalTotal <= 0 }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {!viewingCSA && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isLoading || !isFormValid}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : isEditMode ? (
              "Update Invoice"
            ) : (
              "Generate Invoice"
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GenerateInvoiceDialog;