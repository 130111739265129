export const ADVANCED_TEMPLATE = `import * as React from "react";
import {
  Body,
  Button,
  Column,
  Container,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Tailwind,
  Text,
} from "@react-email/components";

// Advanced template showcasing many React Email components
export const EmailTemplate = () => (
  <Html>
    <Head />
    <Preview>{"{{subject}} | FreeTech Platform Updates"}</Preview>
    <Tailwind>
      <Body className="bg-gray-100 font-sans">
        <Container className="mx-auto pt-10 pb-10">
          {/* Header with Logo */}
          <Section className="bg-white rounded-t-lg p-8 text-center border-b border-gray-200">
            <Img
              src="https://firebasestorage.googleapis.com/v0/b/freetech-production.appspot.com/o/public%2Flogo-no-background.png?alt=media&token=14917ef1-04eb-4370-9b07-110de636adc9"
              alt="FreeTech Logo"
              className="mx-auto"
              width="180"
            />
          </Section>
          
          {/* Hero Section */}
          <Section className="bg-blue-600 text-center px-8 py-12">
            <Heading className="text-white text-3xl font-bold m-0">
              {"Hello, {{name}}!"}
            </Heading>
            <Text className="text-white text-lg">
              {"{{greeting}}"}
            </Text>
          </Section>
          
          {/* Main Content */}
          <Section className="bg-white px-8 py-10">
            <Text className="text-gray-700 text-base leading-6">
              {"{{mainMessage}}"}
            </Text>
            
            {/* Project Updates Grid */}
            <Heading className="text-xl font-bold text-gray-800 mt-8 mb-4">
              {"{{#if showProjectUpdates}}Your Project Updates{{/if}}"}
            </Heading>
            <Row className="mb-8 {{#unless showProjectUpdates}}hidden{{/unless}}">
              <Column className="pr-4">
                <Section className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                  <Heading className="text-base font-bold text-gray-800 m-0">
                    {"Project: {{project1.name}}"}
                  </Heading>
                  <Text className="text-sm text-gray-600 m-0 mt-2">
                    {"Status: {{project1.status}}"}
                  </Text>
                  <Text className="text-sm text-gray-600 m-0">
                    {"Due: {{project1.dueDate}}"}
                  </Text>
                  <Button 
                    className="bg-blue-500 text-white text-sm px-4 py-2 mt-4 rounded-md no-underline text-center block"
                    href="{{project1.url}}"
                  >
                    {"View Project"}
                  </Button>
                </Section>
              </Column>
              <Column className="pl-4">
                <Section className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                  <Heading className="text-base font-bold text-gray-800 m-0">
                    {"Project: {{project2.name}}"}
                  </Heading>
                  <Text className="text-sm text-gray-600 m-0 mt-2">
                    {"Status: {{project2.status}}"}
                  </Text>
                  <Text className="text-sm text-gray-600 m-0">
                    {"Due: {{project2.dueDate}}"}
                  </Text>
                  <Button 
                    className="bg-blue-500 text-white text-sm px-4 py-2 mt-4 rounded-md no-underline text-center block"
                    href="{{project2.url}}"
                  >
                    {"View Project"}
                  </Button>
                </Section>
              </Column>
            </Row>
            
            {/* Task List */}
            <Heading className="text-xl font-bold text-gray-800 mt-8 mb-4">
              {"{{#if tasks}}Your Tasks{{/if}}"}
            </Heading>
            <Section className="bg-gray-50 p-6 rounded-lg {{#unless tasks}}hidden{{/unless}}">
              <table className="w-full" cellPadding="0" cellSpacing="0" role="presentation">
                <tr className="bg-gray-200">
                  <th className="text-left py-2 px-4 text-sm font-medium text-gray-700">Task</th>
                  <th className="text-left py-2 px-4 text-sm font-medium text-gray-700">Priority</th>
                  <th className="text-left py-2 px-4 text-sm font-medium text-gray-700">Due Date</th>
                </tr>
                <tr>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[0].name}}"}</td>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[0].priority}}"}</td>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[0].dueDate}}"}</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[1].name}}"}</td>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[1].priority}}"}</td>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[1].dueDate}}"}</td>
                </tr>
                <tr>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[2].name}}"}</td>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[2].priority}}"}</td>
                  <td className="py-3 px-4 text-sm text-gray-600">{"{{tasks.[2].dueDate}}"}</td>
                </tr>
              </table>
              <Button 
                className="bg-blue-500 text-white text-sm px-4 py-2 mt-6 rounded-md no-underline text-center mx-auto block"
                href="{{viewAllTasksUrl}}"
              >
                {"View All Tasks"}
              </Button>
            </Section>
            
            {/* Call to Action */}
            <Section className="text-center my-10">
              <Button 
                className="bg-blue-600 text-white px-6 py-3 rounded-md font-medium no-underline text-base"
                href="{{primaryActionUrl}}"
              >
                {"{{primaryActionText}}"}
              </Button>
            </Section>
            
            {/* Announcement - Conditional */}
            <Section className="bg-yellow-50 p-6 border-l-4 border-yellow-400 rounded my-8 {{#unless announcement}}hidden{{/unless}}">
              <Heading className="text-lg font-bold text-yellow-800 m-0">
                {"Important Announcement"}
              </Heading>
              <Text className="text-yellow-800 m-0 mt-2">
                {"{{announcement}}"}
              </Text>
            </Section>
            
            <Hr className="border-t border-gray-300 my-6" />
            
            {/* Resources Section */}
            <Heading className="text-lg font-bold text-gray-800 mb-4">
              {"Helpful Resources"}
            </Heading>
            <Section>
              <Row>
                <Column className="pr-2">
                  <Link 
                    href="{{resourceLinks.documentation}}"
                    className="text-blue-600 block mb-3 text-sm"
                  >
                    {"Documentation"}
                  </Link>
                  <Link 
                    href="{{resourceLinks.tutorials}}"
                    className="text-blue-600 block mb-3 text-sm"
                  >
                    {"Tutorials"}
                  </Link>
                </Column>
                <Column className="pl-2">
                  <Link 
                    href="{{resourceLinks.support}}"
                    className="text-blue-600 block mb-3 text-sm"
                  >
                    {"Support"}
                  </Link>
                  <Link 
                    href="{{resourceLinks.settings}}"
                    className="text-blue-600 block mb-3 text-sm"
                  >
                    {"Account Settings"}
                  </Link>
                </Column>
              </Row>
            </Section>
          </Section>
          
          {/* Footer */}
          <Section className="bg-gray-200 px-8 py-6 text-center rounded-b-lg">
            <Text className="text-gray-600 text-xs">
              {"© {{year}} FreeTech. All rights reserved."}
            </Text>
            <Text className="text-gray-600 text-xs">
              {"You're receiving this email because you have an account on the FreeTech platform."}
            </Text>
            <Text className="text-gray-600 text-xs mt-4">
              <Link href="{{unsubscribeUrl}}" className="text-blue-600">
                {"Manage email preferences"}
              </Link>
              {" | "}
              <Link href="{{privacyUrl}}" className="text-blue-600">
                {"Privacy Policy"}
              </Link>
            </Text>
          </Section>
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

/* Available Handlebars variables for this template:
 * {{subject}} - Email subject
 * {{name}} - Recipient's name
 * {{greeting}} - Personalized greeting message
 * {{mainMessage}} - Main content of the email
 * {{showProjectUpdates}} - Boolean to show/hide project updates section
 * {{project1}} - Object with project details: name, status, dueDate, url
 * {{project2}} - Object with project details: name, status, dueDate, url
 * {{tasks}} - Array of task objects with properties: name, priority, dueDate
 * {{viewAllTasksUrl}} - URL to view all tasks
 * {{primaryActionText}} - Text for the main call-to-action button
 * {{primaryActionUrl}} - URL for the main call-to-action button
 * {{announcement}} - Optional announcement text (section hidden if not provided)
 * {{resourceLinks}} - Object with URLs: documentation, tutorials, support, settings
 * {{year}} - Current year for copyright
 * {{unsubscribeUrl}} - URL to manage email preferences
 * {{privacyUrl}} - URL to privacy policy
 */

export default EmailTemplate;`; 