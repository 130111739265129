import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ClientInvoiceData } from "types/bank";
import { getDownloadURL, ref, listAll } from "firebase/storage";
import { storage } from "core/storage";

interface ViewInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: ClientInvoiceData | null;
}

const ViewInvoiceDialog: React.FC<ViewInvoiceDialogProps> = ({
  open,
  onClose,
  invoice,
}) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      if (!invoice || !open) return;
      
      setLoading(true);
      setError(null);
      
      try {
        // Check if the invoice already has a pdfUrl field
        if (invoice.pdfUrl) {
          setPdfUrl(invoice.pdfUrl);
        } else {
          // Fallback to checking for current.pdf or original.pdf
          const basePath = `clients/${invoice.clientId}/invoices/${invoice.id}`;
          
          try {
            // First try to get current.pdf
            const currentUrl = await getDownloadURL(ref(storage, `${basePath}/current.pdf`));
            setPdfUrl(currentUrl);
          } catch (currentErr) {
            // If current.pdf doesn't exist, try original.pdf
            try {
              const originalUrl = await getDownloadURL(ref(storage, `${basePath}/original.pdf`));
              setPdfUrl(originalUrl);
            } catch (originalErr) {
              // If neither exists, throw an error
              throw new Error("Could not find invoice PDF");
            }
          }
        }
      } catch (err) {
        console.error("Error getting invoice PDF:", err);
        setError("Failed to load invoice PDF. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPdfUrl();
  }, [invoice, open]);

  const handleClose = () => {
    onClose();
    // Reset state when dialog closes
    setPdfUrl(null);
    setError(null);
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          height: "90vh",
          maxHeight: "none",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <Typography variant="h6">
          {invoice ? `Invoice #${invoice.invoiceNumber}` : "View Invoice"}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, flexGrow: 1, overflow: "hidden", position: "relative" }}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.paper",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              p: 3,
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          </Box>
        )}
        {pdfUrl && !error && (
          <iframe
            src={pdfUrl}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title={invoice ? `Invoice #${invoice.invoiceNumber}` : "Invoice"}
            onLoad={handleIframeLoad}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewInvoiceDialog; 