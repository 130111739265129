export const NOTIFICATION_TEMPLATE = `import * as React from "react";
import {
  Body,
  Container,
  Head,
  Heading,
  Html,
  Preview,
  Section,
  Text,
  Img,
  Link,
} from "@react-email/components";

// Simple notification template for alerts and time-sensitive messages
export const EmailTemplate = () => (
  <Html>
    <Head />
    <Preview>{"{{alertType}} - FreeTech Alert"}</Preview>
    <Body style={{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      backgroundColor: "#f5f5f5",
      margin: "0",
      padding: "0",
    }}>
      <Container style={{
        maxWidth: "500px",
        margin: "0 auto",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        marginTop: "40px",
        marginBottom: "40px",
      }}>
        {/* Header with Alert Type */}
        <Section style={{
          padding: "20px",
          textAlign: "center",
          backgroundColor: "{{#if urgent}}#f44336{{else}}#ff9800{{/if}}",
          color: "#ffffff",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}>
          <Img
            src="https://firebasestorage.googleapis.com/v0/b/freetech-production.appspot.com/o/public%2Flogo-no-background.png?alt=media&token=14917ef1-04eb-4370-9b07-110de636adc9"
            alt="FreeTech Logo"
            style={{
              maxWidth: "150px",
              height: "auto",
              margin: "0 auto 10px",
            }}
          />
          <Heading style={{
            fontSize: "20px",
            fontWeight: "600",
            margin: "0",
          }}>
            {"{{alertType}} {{#if urgent}}(URGENT){{/if}}"}
          </Heading>
        </Section>
        
        {/* Alert Content */}
        <Section style={{ padding: "24px" }}>
          <Text style={{
            fontSize: "16px",
            color: "#333333",
            lineHeight: "1.6",
            marginBottom: "20px",
          }}>
            {"Hello {{recipientName}},"}
          </Text>
          
          <Text style={{
            fontSize: "16px",
            color: "#333333",
            lineHeight: "1.6",
            marginBottom: "16px",
          }}>
            {"{{alertMessage}}"}
          </Text>
          
          {/* Details Section */}
          <Section style={{
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            padding: "16px",
            marginBottom: "24px",
          }}>
            <Text style={{
              fontSize: "14px",
              color: "#333333",
              fontWeight: "500",
              margin: "0 0 8px 0",
            }}>
              {"Alert Details:"}
            </Text>
            <table style={{ width: "100%", fontSize: "14px" }}>
              <tr>
                <td style={{ padding: "4px 0", color: "#666666" }}>{"Time:"}</td>
                <td style={{ padding: "4px 0" }}>{"{{timestamp}}"}</td>
              </tr>
              <tr>
                <td style={{ padding: "4px 0", color: "#666666" }}>{"Category:"}</td>
                <td style={{ padding: "4px 0" }}>{"{{category}}"}</td>
              </tr>
              <tr>
                <td style={{ padding: "4px 0", color: "#666666" }}>{"Status:"}</td>
                <td style={{ padding: "4px 0" }}>{"{{status}}"}</td>
              </tr>
              <tr>
                <td style={{ padding: "4px 0", color: "#666666" }}>{"Reference ID:"}</td>
                <td style={{ padding: "4px 0" }}>{"{{referenceId}}"}</td>
              </tr>
            </table>
          </Section>
          
          {/* Action Link */}
          <Section style={{
            textAlign: "center",
            padding: "8px 0 16px",
          }}>
            <Link
              href="{{actionUrl}}"
              style={{
                backgroundColor: "{{#if urgent}}#f44336{{else}}#ff9800{{/if}}",
                color: "#ffffff",
                padding: "10px 20px",
                borderRadius: "4px",
                textDecoration: "none",
                fontWeight: "500",
                display: "inline-block",
              }}
            >
              {"{{actionText}}"}
            </Link>
          </Section>
          
          {/* Footer */}
          <Text style={{
            fontSize: "14px",
            color: "#666666",
            borderTop: "1px solid #eeeeee",
            paddingTop: "16px",
            marginTop: "16px",
          }}>
            {"This is an automated notification. If you require assistance, please contact the FreeTech support team."}
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

/* Available Handlebars variables for this template:
 * {{alertType}} - Type of alert (e.g., "System Alert", "Payment Notification")
 * {{urgent}} - Boolean flag to indicate urgent alerts (changes color scheme)
 * {{recipientName}} - Name of the recipient
 * {{alertMessage}} - Main alert message
 * {{timestamp}} - Time when the alert was generated
 * {{category}} - Category of the alert
 * {{status}} - Current status
 * {{referenceId}} - Reference ID for tracking
 * {{actionUrl}} - URL for the call-to-action link
 * {{actionText}} - Text for the call-to-action link
 */

export default EmailTemplate;`; 