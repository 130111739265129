import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Divider,
  Grid,
  CircularProgress,
  Alert,
  Stack,
  Tooltip,
  AlertTitle,
  Link,
  TextField,
  FormControlLabel,
  Switch,
  InputAdornment,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { CreditCard as CreditCardIcon } from "@mui/icons-material";
import { AccountBalance as AccountBalanceIcon } from "@mui/icons-material";
import { Info as InfoIcon } from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import { logoNoBackground as logo } from "assets";
import { useAuth } from "hooks/auth/useAuth";
import { ClientInvoiceData } from "types/bank";
import { Stakeholder } from "@freetech/models/user";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";

// Card payment processing fee (5%)
const CARD_PAYMENT_FEE_PERCENTAGE = 0.05;

interface PayInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: ClientInvoiceData;
  accountId: string;
}

const PayInvoiceDialog: React.FC<PayInvoiceDialogProps> = ({
  open,
  onClose,
  invoice,
  accountId,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<"bank" | "card">("bank");
  const [checkoutUrl, setCheckoutUrl] = useState<string>("");
  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [partialAmount, setPartialAmount] = useState<string>("");
  const [partialAmountError, setPartialAmountError] = useState<string>("");

  const { userInfo } = useAuth();
  const { clients } = useStakeholder(userInfo?.id);
  const client = clients?.find((c) => c.id === invoice.clientId);
  const { paymentProfile, payMyStakeholderInvoice, createStripeCheckoutSession } = useStakeholderPayments();

  const externalAccountId = (userInfo as Stakeholder)
    ?.increaseExternalAccountProfile?.externalAccountId;

  // Calculate the maximum amount that can be paid
  const maxPayableAmount = useMemo(() => {
    const totalAmount = invoice.totalDollarAmountDue || 0;
    const partialPaymentAmount = invoice.partialPaymentAmount || 0;
    return Math.max(0, totalAmount - partialPaymentAmount);
  }, [invoice.totalDollarAmountDue, invoice.partialPaymentAmount]);

  // Determine if this is a partial payment already
  const isAlreadyPartial = useMemo(() => {
    return invoice.status === "partial";
  }, [invoice.status]);

  // Calculate card payment fee and total
  const cardPaymentDetails = useMemo(() => {
    const amount = isPartialPayment && partialAmount 
      ? parseFloat(partialAmount)
      : maxPayableAmount;
      
    const feeWaived = invoice.cardPaymentFeeWaived === true;
    const processingFee = feeWaived ? 0 : amount * CARD_PAYMENT_FEE_PERCENTAGE;
    const totalAmount = amount + processingFee;
    
    return {
      originalAmount: amount,
      processingFee,
      totalAmount,
      feePercentage: CARD_PAYMENT_FEE_PERCENTAGE * 100,
      feeWaived
    };
  }, [invoice.cardPaymentFeeWaived, maxPayableAmount, isPartialPayment, partialAmount]);

  const handlePartialAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPartialAmount(value);
    
    // Validate the partial amount
    if (value === "") {
      setPartialAmountError("Amount is required");
    } else {
      const numValue = parseFloat(value);
      if (isNaN(numValue)) {
        setPartialAmountError("Please enter a valid number");
      } else if (numValue <= 0) {
        setPartialAmountError("Amount must be greater than zero");
      } else if (numValue > maxPayableAmount) {
        setPartialAmountError(`Amount cannot exceed $${maxPayableAmount.toLocaleString()}`);
      } else {
        setPartialAmountError("");
      }
    }
  };

  const handleBankPayment = async () => {
    if (!externalAccountId) {
      setError(
        "No external account found. Please set up your payment profile first."
      );
      return;
    }

    // Validate partial payment amount if applicable
    if (isPartialPayment) {
      if (!partialAmount || partialAmountError) {
        setError("Please enter a valid partial payment amount");
        return;
      }
    }

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      if (!client || !userInfo?.id) {
        setError("An unexpected error occurred...");
        return;
      }

      // Determine the payment amount
      const paymentAmount = isPartialPayment ? parseFloat(partialAmount) : undefined;

      await payMyStakeholderInvoice(client.id, invoice.id, paymentAmount);
      setSuccess(true);
      // Close dialog after 2 seconds of showing success
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error processing payment:", error);
      setError("An error occurred while processing the payment");
    } finally {
      setLoading(false);
    }
  };

  const handleCardPayment = async () => {
    // Validate partial payment amount if applicable
    if (isPartialPayment) {
      if (!partialAmount || partialAmountError) {
        setError("Please enter a valid partial payment amount");
        return;
      }
      
      // Check for Stripe's minimum payment threshold ($0.50)
      const amountValue = parseFloat(partialAmount);
      if (amountValue < 0.50) {
        setError("Stripe requires a minimum payment of $0.50. Please use bank transfer for smaller amounts or increase your payment.");
        return;
      }
    } else if (maxPayableAmount < 0.50) {
      // If the total remaining amount is below the threshold
      setError("Stripe requires a minimum payment of $0.50. Please use bank transfer for this payment instead.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      if (!client || !userInfo?.id) {
        setError("An unexpected error occurred...");
        return;
      }

      // Create success and cancel URLs with a parameter indicating it was opened in a new tab
      const successUrl = `${window.location.origin}/stakeholder/payments?success=true&invoiceId=${invoice.id}&newTab=true`;
      const cancelUrl = `${window.location.origin}/stakeholder/payments?canceled=true&newTab=true`;

      // Determine the payment amount
      const paymentAmount = isPartialPayment ? parseFloat(partialAmount) : undefined;

      const { checkoutUrl } = await createStripeCheckoutSession(
        invoice,
        successUrl,
        cancelUrl,
        paymentAmount
      );
      
      // Save checkout URL for fallback button
      setCheckoutUrl(checkoutUrl);

      // Open Stripe checkout in a new tab
      window.open(checkoutUrl, '_blank');
      
      // Show success message
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      console.error("Error creating Stripe checkout session:", error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An error occurred while setting up the payment");
      }
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (!loading) {
      setSuccess(false);
      setError(null);
      setIsPartialPayment(false);
      setPartialAmount("");
      setPartialAmountError("");
      onClose();
    }
  };

  const currentPaymentAmount = useMemo(() => {
    if (isPartialPayment && partialAmount && !partialAmountError) {
      return parseFloat(partialAmount);
    }
    return maxPayableAmount;
  }, [isPartialPayment, partialAmount, partialAmountError, maxPayableAmount]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Pay Invoice</Typography>
          <IconButton onClick={handleClose} disabled={loading}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <AnimatePresence mode="wait">
          {success ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "2rem",
              }}
            >
              <CheckCircleIcon
                sx={{
                  fontSize: 80,
                  color: "success.main",
                  mb: 2,
                }}
              />
              <Typography variant="h5" align="center" gutterBottom>
                {paymentMethod === "bank" ? "Payment Successful!" : "Payment Initiated!"}
              </Typography>
              <Typography variant="body1" align="center" color="text.secondary">
                {paymentMethod === "bank" ? (
                  `Your payment of $${currentPaymentAmount.toLocaleString()} has been processed.`
                ) : (
                  <>
                    Your secure payment link has been opened in a new tab.<br />
                    If a new tab did not open, please click the button below.
                  </>
                )}
              </Typography>
              
              {paymentMethod === "card" && checkoutUrl && (
                <Box mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<OpenInNewIcon />}
                    href={checkoutUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pay Invoice Now
                  </Button>
                </Box>
              )}
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box mb={3}>
                    <img
                      src={logo}
                      alt="FreeTech Logo"
                      style={{ maxWidth: "150px", height: "auto" }}
                    />
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    PAYMENT AMOUNT
                  </Typography>
                  
                  {/* Partial payment toggle */}
                  {maxPayableAmount > 0 && (
                    <Box mb={2}>
                      <FormControlLabel
                        control={
                          <Switch 
                            checked={isPartialPayment}
                            onChange={(e) => setIsPartialPayment(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Make a partial payment
                            <Tooltip title="You can pay a portion of the total amount now and the remainder later.">
                              <InfoIcon fontSize="small" color="action" sx={{ ml: 0.5, verticalAlign: 'middle' }} />
                            </Tooltip>
                          </Typography>
                        }
                      />
                    </Box>
                  )}
                  
                  {/* Warning for small amounts with card payment */}
                  {paymentMethod === "card" && maxPayableAmount < 0.50 && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      <AlertTitle>Card Payment Not Available</AlertTitle>
                      The remaining amount (${maxPayableAmount.toFixed(2)}) is below Stripe's minimum charge of $0.50.
                      Please use bank transfer to pay this invoice.
                    </Alert>
                  )}
                  
                  {/* Payment amount display */}
                  {isPartialPayment ? (
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        label="Payment Amount"
                        value={partialAmount}
                        onChange={handlePartialAmountChange}
                        error={!!partialAmountError}
                        helperText={partialAmountError || `Maximum: $${maxPayableAmount.toLocaleString()}`}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        variant="outlined"
                        type="number"
                        inputProps={{ 
                          step: "0.01",
                          min: "0.01",
                          max: maxPayableAmount
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography variant="h4" color="primary" gutterBottom>
                      ${paymentMethod === "bank" 
                        ? maxPayableAmount.toLocaleString() 
                        : cardPaymentDetails.totalAmount.toLocaleString()}
                    </Typography>
                  )}
                  
                  {isAlreadyPartial && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                      <AlertTitle>Partial Payment</AlertTitle>
                      This invoice has already been partially paid. 
                      Remaining amount: ${maxPayableAmount.toLocaleString()}
                    </Alert>
                  )}
                  
                  {paymentMethod === "card" && (
                    <Box mb={2}>
                      {!cardPaymentDetails.feeWaived && (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="body2" color="text.secondary">
                            Original amount: ${isPartialPayment && partialAmount && !partialAmountError 
                              ? parseFloat(partialAmount).toLocaleString()
                              : maxPayableAmount.toLocaleString()}
                          </Typography>
                          <Tooltip title={`A ${cardPaymentDetails.feePercentage}% processing fee is applied to all card payments to cover transaction costs.`}>
                            <InfoIcon fontSize="small" color="action" />
                          </Tooltip>
                        </Stack>
                      )}
                      {!cardPaymentDetails.feeWaived && (
                        <Typography variant="body2" color="text.secondary">
                          Processing fee: ${cardPaymentDetails.processingFee.toLocaleString()}
                        </Typography>
                      )}
                    </Box>
                  )}
                  
                  <Divider sx={{ my: 2 }} />
                  
                  <Typography variant="body2" gutterBottom>
                    <strong>Invoice #{invoice.invoiceNumber}</strong>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Client:</strong> {client?.companyName || "Unknown Client"}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Due Date:</strong>{" "}
                    {new Date(invoice.dueDate).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Total Invoice Amount:</strong> ${invoice.totalDollarAmountDue?.toLocaleString()}
                  </Typography>
                  
                  {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                      {error}
                    </Alert>
                  )}
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    SELECT PAYMENT METHOD
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant={paymentMethod === "bank" ? "contained" : "outlined"}
                        fullWidth
                        onClick={() => setPaymentMethod("bank")}
                        startIcon={<AccountBalanceIcon />}
                        sx={{
                          p: 2,
                          textTransform: "none",
                          height: "100%",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: paymentMethod === "bank" ? "primary.main" : "transparent",
                          transition: "all 0.2s ease",
                          "&:hover": {
                            backgroundColor: paymentMethod === "bank" ? "primary.dark" : "action.hover",
                          },
                        }}
                      >
                        <Box sx={{ alignSelf: "flex-start", mb: 1 }}>
                          <AccountBalanceIcon fontSize="large" />
                        </Box>
                        <Typography
                          variant="subtitle1"
                          align="left"
                          sx={{ fontWeight: "bold", mb: 0.5 }}
                        >
                          Bank Account
                        </Typography>
                        <Typography
                          variant="body2"
                          align="left"
                          color={paymentMethod === "bank" ? "primary.contrastText" : "text.secondary"}
                        >
                          No processing fee
                        </Typography>
                      </Button>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Tooltip 
                        title={maxPayableAmount < 0.50 ? 
                          "Card payments require a minimum charge of $0.50. Please use bank transfer for this payment." : 
                          ""}
                        placement="top"
                      >
                        <span>
                          <Button
                            variant={paymentMethod === "card" ? "contained" : "outlined"}
                            fullWidth
                            onClick={() => setPaymentMethod("card")}
                            startIcon={<CreditCardIcon />}
                            disabled={maxPayableAmount < 0.50}
                            sx={{
                              p: 2,
                              textTransform: "none",
                              height: "100%",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: paymentMethod === "card" ? "primary.main" : "transparent",
                              transition: "all 0.2s ease",
                              "&:hover": {
                                backgroundColor: paymentMethod === "card" ? "primary.dark" : "action.hover",
                              },
                            }}
                          >
                            <Box sx={{ alignSelf: "flex-start", mb: 1 }}>
                              <CreditCardIcon fontSize="large" />
                            </Box>
                            <Typography
                              variant="subtitle1"
                              align="left"
                              sx={{ fontWeight: "bold", mb: 0.5 }}
                            >
                              Credit Card
                            </Typography>
                            <Typography
                              variant="body2"
                              align="left"
                              color={paymentMethod === "card" ? "primary.contrastText" : "text.secondary"}
                            >
                              {cardPaymentDetails.feeWaived 
                                ? "No processing fee" 
                                : `${cardPaymentDetails.feePercentage}% processing fee`}
                            </Typography>
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  
                  <Box mt={4}>
                    <Alert severity="info">
                      <AlertTitle>Payment Information</AlertTitle>
                      {paymentMethod === "bank" ? (
                        <>
                          <Typography variant="body2" paragraph>
                            Paying with your bank account is free and secure. Funds will be transferred directly from your registered bank account.
                          </Typography>
                          
                          {externalAccountId ? (
                            <Typography variant="body2">
                              Your payment profile is set up and ready for bank transfers.
                            </Typography>
                          ) : (
                            <Typography variant="body2" color="error">
                              You need to set up your payment profile before making a bank payment.
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography variant="body2" paragraph>
                            Pay securely with your credit or debit card. You'll be redirected to our secure payment processor.
                          </Typography>
                          
                          {!cardPaymentDetails.feeWaived && (
                            <Typography variant="body2" color="warning.main">
                              A {cardPaymentDetails.feePercentage}% processing fee will be added to cover transaction costs.
                            </Typography>
                          )}
                        </>
                      )}
                    </Alert>
                  </Box>
                </Grid>
              </Grid>
            </motion.div>
          )}
        </AnimatePresence>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        {!success && (
          <>
            <Button onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
            {paymentMethod === "bank" ? (
              <Button
                variant="contained"
                onClick={handleBankPayment}
                disabled={loading || (isPartialPayment && (!!partialAmountError || !partialAmount))}
                startIcon={loading && <CircularProgress size={16} color="inherit" />}
              >
                {loading ? "Processing..." : "Pay with Bank Account"}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleCardPayment}
                disabled={loading || (isPartialPayment && (!!partialAmountError || !partialAmount))}
                startIcon={loading && <CircularProgress size={16} color="inherit" />}
              >
                {loading ? "Processing..." : "Pay with Card"}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PayInvoiceDialog;
