import { Extension } from '@tiptap/core';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';

export const AICommandsPluginKey = new PluginKey('ai-commands');

export interface AICommand {
  title: string;
  command: string;
  description: string;
  icon?: string;
}

export const defaultAICommands: AICommand[] = [
  {
    title: 'Introduction',
    command: '/intro',
    description: 'Generate an introduction paragraph',
    icon: '📝',
  },
  {
    title: 'Paragraph',
    command: '/paragraph',
    description: 'Generate a new paragraph',
    icon: '📄',
  },
  {
    title: 'Conclusion',
    command: '/conclusion',
    description: 'Generate a conclusion paragraph',
    icon: '🏁',
  },
  {
    title: 'Expand',
    command: '/expand',
    description: 'Expand the selected text with more details',
    icon: '🔍',
  },
  {
    title: 'Rewrite',
    command: '/rewrite',
    description: 'Rewrite the selected text to improve clarity',
    icon: '✏️',
  },
  {
    title: 'Outline',
    command: '/outline',
    description: 'Generate an outline for your blog post',
    icon: '📋',
  },
];

export interface AICommandsOptions {
  suggestion: Partial<SuggestionOptions>;
}

export const AICommands = Extension.create<AICommandsOptions>({
  name: 'ai-commands',

  addOptions() {
    console.log('AICommands addOptions called');
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }) => {
          console.log('AICommands command called with props:', props);
          // Delete the slash command input
          editor.chain().focus().deleteRange(range).run();
          
          // Open the AI dialog with the selected command
          if (props) {
            console.log('Command props:', props);
            handleCommandSelect(props);
          }
        },
        items: ({ query }) => {
          console.log('AICommands items called with query:', query);
          const filteredItems = defaultAICommands
            .filter(item => item.command.toLowerCase().startsWith('/' + query.toLowerCase()) || 
                           item.command.toLowerCase().startsWith(query.toLowerCase()))
            .slice(0, 10);
          console.log('Filtered items:', filteredItems);
          return filteredItems;
        },
      },
    };
  },

  addProseMirrorPlugins() {
    console.log('Adding AICommands ProseMirror plugins');
    const suggestionPlugin = Suggestion({
      editor: this.editor,
      ...this.options.suggestion,
      pluginKey: AICommandsPluginKey,
    });
    console.log('Created suggestion plugin:', suggestionPlugin);
    return [suggestionPlugin];
  },
});

// Helper function to handle command selection
function handleCommandSelect(command: AICommand) {
  console.log('handleCommandSelect called with command:', command);
  // This will be overridden by the editor configuration
} 