import { useMutation, useQueryClient } from "@tanstack/react-query";
import { portalFunctions } from "core/functions/portalFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { toast } from "react-toastify";
import { EnrichedCSA } from "hooks/admin/useAdminCSAs";

interface ProcessSignedCSAParams {
  documentHash: string;
  clientId: string;
  csaId: string;
}

export const useStakeholderSignCSAs = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  const processSignedCSA = useMutation({
    mutationFn: async (params: ProcessSignedCSAParams) => {
      console.log("Calling processStakeholderSignedCSA with params:", params);
      
      if (!params.documentHash || !params.clientId || !params.csaId) {
        console.error("Missing required parameters for processStakeholderSignedCSA:", params);
        throw new Error("Missing required parameters");
      }
      
      try {
        const response = await portalFunctions.eversign.processStakeholderSignedCSA({
          documentHash: params.documentHash,
          clientId: params.clientId,
          csaId: params.csaId,
        });

        console.log("processStakeholderSignedCSA response status:", response.status);
        
        if (!response.ok) {
          const errorData = await response.json();
          console.error("processStakeholderSignedCSA error:", errorData);
          throw new Error(errorData.message || "Failed to process signed CSA");
        }

        const responseData = await response.json();
        console.log("processStakeholderSignedCSA response data:", responseData);
        
        // Invalidate queries to refresh the CSA list
        queryClient.invalidateQueries({ queryKey: ["stakeholderCSAs", userInfo?.id] });
        
        return responseData;
      } catch (error) {
        console.error("Error in processStakeholderSignedCSA:", error);
        throw error;
      }
    },
    onSuccess: (data) => {
      console.log("processStakeholderSignedCSA success:", data);
      toast.success("Contract signed and processed successfully");
    },
    onError: (error: Error) => {
      console.error("processStakeholderSignedCSA error in onError:", error);
      toast.error(`Error processing signed contract: ${error.message}`);
    },
  });

  const cancelCSADocument = useMutation({
    mutationFn: async (documentHash: string) => {
      const response =
        await portalFunctions.eversign.cancelDocument(documentHash);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to cancel CSA document");
      }

      return response.json();
    },
    onSuccess: () => {
      toast.success("CSA document canceled successfully");
      // Invalidate queries to refresh the CSA list
      queryClient.invalidateQueries({ queryKey: ["stakeholderCSAs", userInfo?.id] });
    },
    onError: (error: Error) => {
      toast.error(`Error canceling CSA document: ${error.message}`);
    },
  });

  return {
    processSignedCSA,
    cancelCSADocument,
  };
};

export default useStakeholderSignCSAs; 