import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface MinimumTimeDialogProps {
  open: boolean;
  onClose: () => void;
  duration: string;
}
export const MinimumTimeDialog: React.FC<MinimumTimeDialogProps> = ({
  open,
  onClose,
  duration,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: "error.main",
        }}
      >
        <WarningAmberIcon color="error" />
        Invalid Time Duration
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          The time entry duration ({duration}) is less than one minute.
        </Typography>
        <Typography variant="body2">
          Please ensure that time entries are at least one minute long.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
