import { DateTime } from "luxon";
import { ProjectAssignment } from "@freetech/models/projects";

export const filterActiveProjectAssignments = (
  assignments: ProjectAssignment[]
): ProjectAssignment[] => {
  const now = DateTime.now();
  const bufferDays = 7;

  return assignments.filter((assignment) => {
    if (assignment.status !== "in_progress") {
      return false;
    }

    if (!assignment.startDate || !assignment.endDate) {
      return false;
    }

    const startDate = DateTime.fromISO(assignment.startDate);
    const endDate = DateTime.fromISO(assignment.endDate);

    if (!startDate.isValid || !endDate.isValid) {
      return false;
    }

    const isWithinStartBuffer =
      now >= startDate.minus({ days: bufferDays }) &&
      now <= startDate.plus({ days: bufferDays });
    const isWithinEndBuffer =
      now >= endDate.minus({ days: bufferDays }) &&
      now <= endDate.plus({ days: bufferDays });
    const isActive = now >= startDate && now <= endDate;

    return isActive || isWithinStartBuffer || isWithinEndBuffer;
  });
};
