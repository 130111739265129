import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Link,
  Stack,
  Chip,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import CodeIcon from "@mui/icons-material/Code";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Board } from "@freetech/models/freelo";
import { PublicUserInfoType } from "@freetech/models/user";
import { BlurredContent } from "components/BlurredContent";

interface FreeloStudentHeaderProps {
  boards: Board[];
  userInfo: PublicUserInfoType;
  users?: PublicUserInfoType[];
}

export const FreeloStudentHeader: React.FC<FreeloStudentHeaderProps> = ({
  userInfo,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{ p: 3, mb: 4, bgcolor: "background.default", borderRadius: 2 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Welcome to Freelo, {userInfo.firstName}!
          </Typography>
          <BlurredContent title="Freelo Learning Resources">
            <Box sx={{ maxWidth: "900px" }}>
              <Typography 
                variant="body1"
                paragraph
                sx={{ lineHeight: 1.6, mb: 2 }}
              >
                As a student, you have the opportunity to observe and learn how professional software development teams track work and communicate with clients. Freelo is our customized Kanban project management tool that plays a crucial role in the development workflow.
              </Typography>
              
              <Divider sx={{ my: 3 }} />
              
              <Typography 
                variant="h6" 
                color="primary" 
                gutterBottom
                sx={{ 
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: 600 
                }}
              >
                <IntegrationInstructionsIcon /> The MCP (Model Context Protocol) & Freelo Integration
              </Typography>
              
              <Typography 
                variant="body1" 
                paragraph
                sx={{ lineHeight: 1.6, mb: 3 }}
              >
                FreeTech developers use the <strong>Model Context Protocol (MCP)</strong> to directly align software with client requirements. Freelo boards are a critical part of this process:
              </Typography>
              
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" sx={{ height: '100%' }}>
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                        <CodeIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="subtitle1" fontWeight={600}>How MCP Works</Typography>
                      </Box>
                      <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
                        MCP is a protocol that allows AI assistants to understand the context of a project by accessing Freelo board data. This enables AI to write code that precisely meets client requirements without developers having to translate requirements manually.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" sx={{ height: '100%' }}>
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                        <LightbulbIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="subtitle1" fontWeight={600}>What You'll Learn</Typography>
                      </Box>
                      <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
                        By studying our Freelo boards, you'll understand how requirements are structured for AI consumption, how tasks are organized across development stages, and how this modern approach differs from traditional development workflows.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              
              <Typography variant="body1" paragraph>
                <strong>As a student, you'll have read-only access to specific boards</strong> that showcase this integration. This gives you a unique opportunity to see how professional development teams are leveraging AI and structured workflows to build software more efficiently.
              </Typography>
              
              <Divider sx={{ my: 3 }} />
              
              <Typography variant="h6" gutterBottom sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <SchoolIcon color="primary" />
                <span>Learning Resources</span>
              </Typography>
              
              <Stack spacing={1.5} sx={{ mt: 2 }}>
                <Link
                  href="https://www.atlassian.com/agile/kanban"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 0.5,
                    color: "primary.main",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Learn more about Kanban and how it differs from Scrum and other Agile methodologies
                  <OpenInNewIcon sx={{ fontSize: 16 }} />
                </Link>
                
                <Link
                  href="https://github.com/sooperset/mcp-atlassian"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 0.5,
                    color: "primary.main",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  See examples of MCP implementation with task management systems
                  <OpenInNewIcon sx={{ fontSize: 16 }} />
                </Link>
              </Stack>
              
              <Box sx={{ mt: 3, p: 2, bgcolor: "info.lighter", borderRadius: 2 }}>
                <Typography variant="body2" sx={{ color: "info.main", fontWeight: 500 }}>
                  Boards will be assigned to you as part of your learning modules. Your instructor will guide you through the practical applications and how to interpret the data you see.
                </Typography>
              </Box>
            </Box>
          </BlurredContent>
        </Grid>
      </Grid>
    </Paper>
  );
}; 