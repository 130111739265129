import React, { useState, KeyboardEvent } from 'react';
import { 
  Box, 
  TextField, 
  IconButton, 
  CircularProgress,
  InputAdornment,
  useTheme
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { motion } from 'framer-motion';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  isProcessing: boolean;
  disabled?: boolean;
  placeholder?: string;
}

/**
 * Component for the chat input in the AI conversation
 */
export const ChatInput: React.FC<ChatInputProps> = ({ 
  onSendMessage, 
  isProcessing,
  disabled = false,
  placeholder = 'Type your message...'
}) => {
  const [message, setMessage] = useState('');
  const theme = useTheme();
  
  const handleSend = () => {
    if (message.trim() && !isProcessing && !disabled) {
      onSendMessage(message);
      setMessage('');
    }
  };
  
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };
  
  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={isProcessing || disabled}
        multiline
        maxRows={4}
        sx={{ 
          '& .MuiOutlinedInput-root': {
            borderRadius: 30,
            pr: 1,
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)',
            '&.Mui-focused': {
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.1)',
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <IconButton
                  onClick={handleSend}
                  disabled={!message.trim() || isProcessing || disabled}
                  color="primary"
                  aria-label="send message"
                  sx={{ 
                    bgcolor: message.trim() ? theme.palette.primary.main : 'transparent',
                    color: message.trim() ? 'white' : theme.palette.text.disabled,
                    '&:hover': {
                      bgcolor: message.trim() ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.04)',
                    },
                    '&.Mui-disabled': {
                      bgcolor: 'transparent',
                      color: theme.palette.text.disabled,
                    }
                  }}
                >
                  {isProcessing ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <SendIcon />
                  )}
                </IconButton>
              </motion.div>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}; 