import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  serverTimestamp,
  addDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "config/firebase";
import { useAuth } from "hooks/auth";
import { 
  AssistanceRequest,
  MentorInfo,
  CohortInfo,
  CohortMember
} from "@freetech/models/student";
import { Board, Card } from "@freetech/models/freelo";

// Collection names
const COHORTS_COLLECTION = "cohorts";
const USERS_COLLECTION = "users";
const BOARDS_COLLECTION = "boards";
const STUDENT_ASSISTANCE_REQUESTS_COLLECTION = "studentAssistanceRequests";

export const useStudent = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  const userId = userInfo?.id;

  // Get student's cohort information
  const { data: cohortInfo, isLoading: isLoadingCohort } = useQuery({
    queryKey: ["studentCohort", userId],
    queryFn: async (): Promise<CohortInfo | null> => {
      if (!userId) return null;

      // Find cohorts where student is a member
      const cohortsQuery = query(
        collection(db, COHORTS_COLLECTION),
        where("studentIds", "array-contains", userId),
        where("status", "==", "active")
      );

      const cohortsSnapshot = await getDocs(cohortsQuery);
      
      if (cohortsSnapshot.empty) {
        return null;
      }

      // Get the first active cohort (most students will only be in one)
      const cohortDoc = cohortsSnapshot.docs[0];
      
      return {
        ...(cohortDoc.data() as CohortInfo),
        id: cohortDoc.id,
      };
    },
    enabled: !!userId,
  });

  // Get the mentor information
  const { data: mentorInfo, isLoading: isLoadingMentor } = useQuery({
    queryKey: ["studentMentor", cohortInfo?.mentorId],
    queryFn: async (): Promise<MentorInfo | null> => {
      if (!cohortInfo?.mentorId) return null;

      const mentorDoc = await getDoc(doc(db, USERS_COLLECTION, cohortInfo.mentorId));
      
      if (!mentorDoc.exists()) {
        return null;
      }

      return {
        ...(mentorDoc.data() as MentorInfo),
        id: mentorDoc.id,
      };
    },
    enabled: !!cohortInfo?.mentorId,
  });

  // Get cohort members
  const { data: cohortMembers = [], isLoading: isLoadingCohortMembers } =
    useQuery({
      queryKey: ["cohortMembers", cohortInfo?.id],
      queryFn: async (): Promise<CohortMember[]> => {
        if (!cohortInfo?.id || !cohortInfo.studentIds || cohortInfo.studentIds.length === 0) 
          return [];

        // Get all the student details from their IDs
        const memberPromises = cohortInfo.studentIds
          .filter(studentId => studentId !== userId) // Exclude current user
          .map(async (studentId) => {
            const userDoc = await getDoc(doc(db, USERS_COLLECTION, studentId));
            if (userDoc.exists()) {
              return {
                ...(userDoc.data() as CohortMember),
                id: userDoc.id,
              };
            }
            return null;
          });

        const members = await Promise.all(memberPromises);
        
        // Filter out nulls
        return members.filter(member => member !== null) as CohortMember[];
      },
      enabled: !!cohortInfo?.id && Array.isArray(cohortInfo.studentIds) && cohortInfo.studentIds.length > 0,
    });

  // Get Freelo board information
  const { data: freeloBoard, isLoading: isLoadingFreeloBard } = useQuery({
    queryKey: ["freeloBoard", cohortInfo?.freeloBoardId],
    queryFn: async (): Promise<Board | null> => {
      if (!cohortInfo?.freeloBoardId) return null;

      const boardDoc = await getDoc(
        doc(db, BOARDS_COLLECTION, cohortInfo.freeloBoardId)
      );
      
      if (!boardDoc.exists()) {
        return null;
      }

      return {
        ...(boardDoc.data() as Board),
        id: boardDoc.id,
      };
    },
    enabled: !!cohortInfo?.freeloBoardId,
  });

  // Get recent tasks for the student from the Freelo board
  const { data: recentTasks = [], isLoading: isLoadingRecentTasks } = useQuery({
    queryKey: ["recentTasks", userId, freeloBoard?.id],
    queryFn: async (): Promise<Card[]> => {
      if (!userId || !freeloBoard?.id) return [];

      // Access cards as a subcollection of boards
      const cardsQuery = query(
        collection(db, `${BOARDS_COLLECTION}/${freeloBoard.id}/cards`),
        where("additionalAssignees", "array-contains", userId)
      );

      const cardsSnapshot = await getDocs(cardsQuery);
      
      if (cardsSnapshot.empty) {
        return [];
      }

      return cardsSnapshot.docs.map((doc) => ({
        ...(doc.data() as Card),
        id: doc.id
      }));
    },
    enabled: !!userId && !!freeloBoard?.id,
  });

  // Get pending assistance request
  const {
    data: pendingAssistanceRequest,
    isLoading: isLoadingAssistanceRequest,
  } = useQuery({
    queryKey: ["assistanceRequest", userId],
    queryFn: async (): Promise<AssistanceRequest | null> => {
      if (!userId || !cohortInfo?.mentorId) return null;

      // Look for pending assistance requests in the studentAssistanceRequests collection
      const assistanceQuery = query(
        collection(db, STUDENT_ASSISTANCE_REQUESTS_COLLECTION),
        where("studentId", "==", userId),
        where("resolved", "==", false),
        orderBy("requestedAt", "desc"),
        limit(1)
      );

      const assistanceSnapshot = await getDocs(assistanceQuery);

      if (assistanceSnapshot.empty) {
        return null;
      }

      const requestDoc = assistanceSnapshot.docs[0];
      const requestData = requestDoc.data() as AssistanceRequest;

      return {
        ...requestData,
        id: requestDoc.id,
        requestedAt: requestData.requestedAt || new Date().toISOString(),
      };
    },
    enabled: !!userId && !!cohortInfo?.mentorId,
  });

  // Mutation to request assistance from mentor
  const requestAssistanceMutation = useMutation({
    mutationFn: async (message: string) => {
      if (!userId || !cohortInfo?.mentorId) {
        throw new Error("User is not authenticated or mentor not assigned");
      }

      // Create new assistance request in the studentAssistanceRequests collection
      const assistanceRequest: Omit<AssistanceRequest, 'id'> = {
        studentId: userId,
        mentorId: cohortInfo.mentorId,
        cohortId: cohortInfo.id,
        message,
        requestedAt: new Date().toISOString(),
        resolved: false
      };

      const docRef = await addDoc(
        collection(db, STUDENT_ASSISTANCE_REQUESTS_COLLECTION),
        assistanceRequest
      );

      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["assistanceRequest", userId],
      });
    },
  });

  return {
    cohortInfo,
    mentorInfo,
    cohortMembers,
    freeloBoard,
    recentTasks,
    pendingAssistanceRequest,
    isRequestingAssistance: requestAssistanceMutation.isPending,
    requestMentorAssistance: requestAssistanceMutation.mutateAsync,
    isLoading:
      isLoadingCohort ||
      isLoadingMentor ||
      isLoadingCohortMembers ||
      isLoadingFreeloBard ||
      isLoadingRecentTasks ||
      isLoadingAssistanceRequest,
  };
};
