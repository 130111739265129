import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { FreelancerProjAssExplorer } from "./FreelancerProjAssExplorer/FreelancerProjAssExplorer";
import { FreelancerTimesheetExplorer } from "./FreelancerTimesheetExplorer/FreelancerTimesheetExplorer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`freelancer-tabpanel-${index}`}
      aria-labelledby={`freelancer-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const FreelancerProjectExplorer: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="freelancer project explorer tabs"
        >
          <Tab label="Timesheets" />
          <Tab label="Project Assignments" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FreelancerTimesheetExplorer />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FreelancerProjAssExplorer />
      </TabPanel>
    </Box>
  );
};
