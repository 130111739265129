import React, { useState, useCallback, memo, useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { Box, Paper, TextField, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useFreelo } from "hooks/freelo/useFreelo";
import FreeloList from "./components/FreeloList";
import { Board, List, Card } from "@freetech/models/freelo";
interface DraggableCardAreaProps {
  board: Board;
  boardId: string;
  onListMenuOpen: (
    event: React.MouseEvent<HTMLElement>,
    listId: string
  ) => void;
}

interface LocalColumn {
  id: string;
  cards: Card[];
  cardOrder: string[];
  name: string;
  color?: string;
}

interface LocalState {
  columns: { [key: string]: LocalColumn };
  columnOrder: string[];
}

const createInitialState = (board: Board): LocalState => {
  const columns: { [key: string]: LocalColumn } = {};

  board.lists?.forEach((list) => {
    const nonArchivedCards = list.cards.filter((card) => !card.archived);
    const cardOrder = list.cardOrder || nonArchivedCards.map((card) => card.id);

    columns[list.id] = {
      id: list.id,
      cards: nonArchivedCards,
      cardOrder,
      name: list.name,
      color: list.color,
    };
  });

  return {
    columns,
    columnOrder: board.listOrder || board.lists?.map((list) => list.id) || [],
  };
};

// Memoize the AddList component
const AddList = memo(
  ({
    isAddingList,
    newListName,
    setIsAddingList,
    setNewListName,
    createList,
    boardId,
    onAddList,
  }: {
    isAddingList: boolean;
    newListName: string;
    setIsAddingList: (value: boolean) => void;
    setNewListName: (value: string) => void;
    createList: any;
    boardId: string;
    onAddList: (name: string) => void;
  }) => {
    const handleAddList = async () => {
      if (!newListName.trim()) return;
      
      onAddList(newListName);
      
      setNewListName("");
      setIsAddingList(false);
      
      await createList.mutateAsync({
        boardId,
        name: newListName,
      });
    };

    return (
      <Paper
        sx={{
          width: 280,
          height: "fit-content",
          flexShrink: 0,
          bgcolor: isAddingList ? "grey.50" : "rgba(255, 255, 255, 0.6)",
          p: 1,
        }}
      >
        {isAddingList ? (
          <Box>
            <TextField
              autoFocus
              fullWidth
              placeholder="Enter list name..."
              size="small"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleAddList();
                }
              }}
              sx={{
                mb: 1,
                "& .MuiOutlinedInput-root": {
                  bgcolor: "white",
                },
              }}
            />
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                size="small"
                onClick={handleAddList}
              >
                Add list
              </Button>
              <IconButton
                size="small"
                onClick={() => {
                  setIsAddingList(false);
                  setNewListName("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Button
            startIcon={<AddIcon />}
            fullWidth
            sx={{
              justifyContent: "flex-start",
              color: "text.secondary",
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.4)",
              },
            }}
            onClick={() => setIsAddingList(true)}
          >
            Add another list
          </Button>
        )}
      </Paper>
    );
  }
);

AddList.displayName = "AddList";

export const DraggableCardArea = memo(
  ({ board, boardId, onListMenuOpen }: DraggableCardAreaProps) => {
    const [state, setState] = useState<LocalState>(createInitialState(board));
    const [isAddingList, setIsAddingList] = useState(false);
    const [newListName, setNewListName] = useState("");
    const { moveCard, moveList, addList } = useFreelo();

    const handleAddList = useCallback((name: string) => {
      const tempId = `temp-${Date.now()}`;
      
      setState((prevState) => {
        const newColumn: LocalColumn = {
          id: tempId,
          cards: [],
          cardOrder: [],
          name: name,
        };

        return {
          columns: {
            ...prevState.columns,
            [tempId]: newColumn,
          },
          columnOrder: [...prevState.columnOrder, tempId],
        };
      });
    }, []);

    useEffect(() => {
      const newState = createInitialState(board);
      setState((prevState) => {
        const currentColumns = Object.entries(prevState.columns).filter(
          ([id]) => !id.startsWith('temp-')
        );
        
        return {
          ...newState,
          columnOrder: newState.columnOrder,
          columns: {
            ...Object.fromEntries(currentColumns),
            ...newState.columns,
          },
        };
      });
    }, [board]);

    const onDragEnd = useCallback(
      (result: DropResult) => {
        const { destination, source, draggableId, type } = result;

        if (!destination) return;
        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }

        if (type === "column") {
          // Handle column reordering
          const newColumnOrder = Array.from(state.columnOrder);
          newColumnOrder.splice(source.index, 1);
          newColumnOrder.splice(destination.index, 0, draggableId);

          // Update local state first
          setState((prevState) => ({
            ...prevState,
            columnOrder: newColumnOrder,
          }));

          // Then trigger the mutation
          console.log("Moving column:", {
            boardId,
            columnId: draggableId,
            destinationIndex: destination.index,
          });

          moveList.mutate({
            boardId,
            listId: draggableId,
            destinationIndex: destination.index,
          });

          return; // Exit early for column moves
        }

        // Rest of your card moving logic...
        setState((prevState) => {
          const sourceColumn = prevState.columns[source.droppableId];
          const destColumn = prevState.columns[destination.droppableId];

          if (source.droppableId === destination.droppableId) {
            // Moving within the same column
            const newCardOrder = Array.from(sourceColumn.cardOrder);
            newCardOrder.splice(source.index, 1);
            newCardOrder.splice(destination.index, 0, draggableId);

            const newColumn = {
              ...sourceColumn,
              cardOrder: newCardOrder,
            };

            moveCard.mutate({
              boardId,
              cardId: draggableId,
              sourceListId: source.droppableId,
              destinationListId: destination.droppableId,
              destinationIndex: destination.index,
            });

            return {
              ...prevState,
              columns: {
                ...prevState.columns,
                [newColumn.id]: newColumn,
              },
            };
          }

          // Moving between columns
          const sourceCardOrder = Array.from(sourceColumn.cardOrder);
          const destCardOrder = Array.from(destColumn.cardOrder);
          const movedCard = sourceColumn.cards.find(
            (card) => card.id === draggableId
          )!;

          sourceCardOrder.splice(source.index, 1);
          destCardOrder.splice(destination.index, 0, draggableId);

          moveCard.mutate({
            boardId,
            cardId: draggableId,
            sourceListId: source.droppableId,
            destinationListId: destination.droppableId,
            destinationIndex: destination.index,
          });

          return {
            ...prevState,
            columns: {
              ...prevState.columns,
              [sourceColumn.id]: {
                ...sourceColumn,
                cardOrder: sourceCardOrder,
                cards: sourceColumn.cards.filter(
                  (card) => card.id !== draggableId
                ),
              },
              [destColumn.id]: {
                ...destColumn,
                cardOrder: destCardOrder,
                cards: [
                  ...destColumn.cards.slice(0, destination.index),
                  { ...movedCard, listId: destination.droppableId },
                  ...destColumn.cards.slice(destination.index),
                ],
              },
            },
          };
        });
      },
      [boardId, moveCard, moveList, state.columnOrder]
    );

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="board" direction="horizontal" type="column">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                display: "flex",
                overflowX: "auto",
                overflowY: "hidden",
                gap: 2,
                height: "calc(100vh - 160px)", // Adjust this value to match your header height
                alignItems: "flex-start",
                pb: 1,
                pr: 2,
                "& > *": { // Make all direct children (lists) have fixed height
                  maxHeight: "calc(100vh - 160px)", // Match parent height
                  display: "flex",
                  flexDirection: "column",
                },
                "&::-webkit-scrollbar": {
                  height: 8,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: 4,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  borderRadius: 4,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                  },
                },
              }}
            >
              {state.columnOrder.map((columnId, index) => {
                const column = state.columns[columnId];
                if (!column) return null;

                return (
                  <FreeloList
                    key={columnId}
                    list={{
                      ...column,
                      order: index,
                    }}
                    cards={column.cards}
                    cardOrder={column.cardOrder}
                    index={index}
                    boardId={boardId}
                    onListMenuOpen={onListMenuOpen}
                  />
                );
              })}
              {provided.placeholder}
              <AddList
                isAddingList={isAddingList}
                newListName={newListName}
                setIsAddingList={setIsAddingList}
                setNewListName={setNewListName}
                createList={addList}
                boardId={boardId}
                onAddList={handleAddList}
              />
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

DraggableCardArea.displayName = "DraggableCardArea";
