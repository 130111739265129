import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  Link,
  useTheme,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authTextFieldStyle } from "core/theme";
import NotFound from "views/Auth/NotFound";
import { verifyPasswordRequirements } from "core/auth/utils/verifyPasswordRequirements";
import { useAuth } from "hooks/auth/useAuth";
import { FirebaseError } from "@firebase/app";
import { portalFunctions } from "core/functions/portalFunctions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const [resetSuccess, setResetSuccess] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(
    null
  );
  const [showResendButton, setShowResendButton] = useState(false);
  const [resendEmail, setResendEmail] = useState("");
  const [tokenStatus, setTokenStatus] = useState<
    "validating" | "valid" | "invalid" | "expired"
  >("validating");
  const theme = useTheme();
  const [userEmail, setUserEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    body: "",
    button: "",
  });

  const {
    handleVerifyPasswordResetCode,
    handleConfirmPasswordReset,
    handleSignInWithEmailLink,
    handleResendVerificationEmail,
  } = useAuth();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("oobCode");
  const mode = searchParams.get("mode");

  const handleResendVerification = async () => {
    if (!resendEmail) {
      setVerificationError("Please enter your email address.");
      return;
    }

    setIsLoading(true);
    try {
      const success = await handleResendVerificationEmail(resendEmail);
      setDialogContent({
        title: "Verification Email Sent",
        body: `If an account exists for ${resendEmail}, you should see verification instructions in your inbox.`,
        button: "Back to Login",
      });
      setOpenDialog(true);
    } catch (error: any) {
      console.error("Error sending verification email:", error);
      if (error.code === "auth/too-many-requests") {
        setDialogContent({
          title: "Rate Limit Exceeded",
          body: "You have already requested a verification email within the last minute. Please try again later.",
          button: "Ok",
        });
      } else {
        setDialogContent({
          title: "Error",
          body: "An unexpected error occurred. Please try again.",
          button: "Try Again",
        });
      }
      setOpenDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (dialogContent.button === "Back to Login") {
      navigate("/login", {
        state: {
          message: "Please check your inbox for the verification email.",
        },
      });
    }
  };

  const decodeTokenData = async () => {
    if (!token) {
      setTokenStatus("invalid");
      setShowResendButton(true);
      return;
    }

    if (mode === "signIn") {
      try {
        const signInSuccess = await handleSignInWithEmailLink(token);
        if (signInSuccess) {
          setTokenStatus("valid");
          setResetSuccess(true);
          setTimeout(() => {
            // Don't navigate immediately, let the auth state change propagate
            // The OnboardingLayout or CoreLayout will handle the navigation
          }, 3000);
        } else {
          setTokenStatus("invalid");
          setShowResendButton(true);
          setVerificationError(
            "Failed to sign in. Please request a new verification email."
          );
        }
      } catch (error) {
        console.error("[ResetPassword] Error during sign in:", error);
        setTokenStatus("invalid");
        setShowResendButton(true);
        if (
          error instanceof FirebaseError &&
          error.code === "auth/invalid-action-code"
        ) {
          setVerificationError(
            "This sign in link has expired or is invalid. Please request a new verification email."
          );
        } else {
          setVerificationError(
            "Failed to sign in. Please request a new verification email."
          );
        }
      }
      return;
    }

    if (mode === "resetPassword") {
      try {
        const userEmail = await handleVerifyPasswordResetCode(token);
        if (typeof userEmail === "string") {
          setTokenStatus("valid");
          setUserEmail(userEmail);
        } else {
          setTokenStatus("invalid");
          setShowResendButton(true);
        }
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (error.code === "auth/invalid-action-code") {
            setTokenStatus("expired");
          } else {
            setTokenStatus("invalid");
            setShowResendButton(true);
          }
        }
      }
    } else {
      setTokenStatus("invalid");
      setShowResendButton(true);
    }
  };

  useEffect(() => {
    decodeTokenData();
  }, [token]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const password = formData.get("password") as string;
    const passwordConfirmation = formData.get("passwordConfirmation") as string;

    if (!verifyPasswordRequirements(password)) {
      setVerificationError(
        "Your password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number."
      );
      return;
    }

    if (password !== passwordConfirmation) {
      setVerificationError("You must confirm your password.");
      return;
    }

    if (!token) {
      setVerificationError("Please try sending another password reset email.");
      return;
    }

    const response = await handleConfirmPasswordReset(token, password);

    if (response) {
      setVerificationError(null);
      setResetSuccess(true);
      setTimeout(() => {
        navigate("/login", {
          state: {
            message:
              "Your password was reset successfully. We will now redirect you to the login page.",
          },
        });
      }, 3000);
    } else {
      setVerificationError("Please try sending another password reset email.");
    }
  };

  if (tokenStatus === "validating") {
    return (
      <Box sx={{ margin: "20px", textAlign: "center" }}>
        <Typography variant="h4" sx={{ marginBottom: 3, color: "black" }}>
          Verifying your email...
        </Typography>
      </Box>
    );
  }

  if (tokenStatus === "invalid") {
    return (
      <Box sx={{ margin: "20px", textAlign: "center" }}>
        <Typography variant="h4" sx={{ marginBottom: 3, color: "black" }}>
          Invalid or expired link
        </Typography>
        {showResendButton && (
          <Box sx={{ maxWidth: "400px", margin: "0 auto" }}>
            <Typography
              sx={{
                color: "black",
                marginBottom: 2,
                fontSize: "1rem",
              }}
            >
              Please enter your email to resend a verification link:
            </Typography>
            <TextField
              fullWidth
              name="resendEmail"
              label="Email Address"
              type="email"
              value={resendEmail}
              onChange={(e) => setResendEmail(e.target.value)}
              sx={{
                ...authTextFieldStyle,
                marginBottom: 2,
                "& .MuiInputBase-input": {
                  color: "black",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.7)",
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleResendVerification}
              disabled={isLoading}
              sx={{
                mt: 1,
                mb: 3,
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.8)",
                },
              }}
            >
              Resend Verification Email
            </Button>
            <Dialog open={isLoading}>
              <DialogTitle>Sending verification email...</DialogTitle>
              <Box sx={{ margin: 2, textAlign: "center" }}>
                <CircularProgress />
              </Box>
            </Dialog>

            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>{dialogContent.title}</DialogTitle>
              <DialogContent>
                <Typography>{dialogContent.body}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  {dialogContent.button}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Link href="/login" variant="body2" sx={{ color: "black" }}>
              Back to Login
            </Link>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box
      id="reset-password-container"
      component="form"
      onSubmit={handleSubmit}
      noValidate
    >
      <Box sx={{ marginTop: "20px", textAlign: "center" }}>
        {userEmail && !resetSuccess && (
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "20px",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              maxWidth: "400px",
              margin: "0 auto 20px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.7)",
                fontSize: "0.9rem",
                marginBottom: "4px",
              }}
            >
              Resetting password for account:
            </Typography>
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.9)",
                fontWeight: 500,
                fontSize: "1.1rem",
                wordBreak: "break-all",
              }}
            >
              {userEmail}
            </Typography>
          </Box>
        )}
        {!resetSuccess && (
          <Typography sx={{ color: theme.palette.primary.contrastText }}>
            Please enter and confirm your new password.
          </Typography>
        )}
      </Box>

      {resetSuccess ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "200px",
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: 60,
              color: "success.main",
              mb: 2,
            }}
          />
          <Typography variant="h5" sx={{ color: "success.main", mb: 1 }}>
            Password Reset Success!
          </Typography>
        </Box>
      ) : (
        <>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={{
              ...authTextFieldStyle,
              "& .MuiInputBase-input": {
                color: theme.palette.text.primary,
              },
              "& .MuiInputLabel-root": {
                color: theme.palette.text.secondary,
              },
              "& .MuiFormHelperText-root": {
                color: theme.palette.text.secondary,
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label="Confirm Password"
            type="password"
            id="passwordConfirmation"
            autoComplete="new-password"
            sx={{
              ...authTextFieldStyle,
              "& .MuiInputBase-input": {
                color: theme.palette.text.primary,
              },
              "& .MuiInputLabel-root": {
                color: theme.palette.text.secondary,
              },
              "& .MuiFormHelperText-root": {
                color: theme.palette.text.secondary,
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 3,
              bgcolor: "black",
              color: "white",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.8)",
              },
            }}
          >
            Reset Password
          </Button>
        </>
      )}
    </Box>
  );
};

export default ResetPassword;
