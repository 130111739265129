import React from "react";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { alpha, useTheme } from "@mui/material/styles";

interface ChartData {
  date: string;
  inbound: number;
  outbound: number;
  balance: number;
  net: number;
}

interface FinancialMetricsChartProps {
  data: ChartData[];
  timeRange: "7d" | "30d" | "90d" | "1y";
  setTimeRange: (timeRange: "7d" | "30d" | "90d" | "1y") => void;
}

const FinancialMetricsChart: React.FC<FinancialMetricsChartProps> = ({
  data,
  timeRange,
  setTimeRange
}) => {
  const theme = useTheme();
  
  const handleTimeRangeChange = (
    event: React.MouseEvent<HTMLElement>,
    newTimeRange: "7d" | "30d" | "90d" | "1y" | null
  ) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
    }
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      maximumFractionDigits: 1
    }).format(value);
  };

  if (!data || data.length === 0) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 400 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Typography variant="h6">Cash Flow Overview</Typography>
        <ToggleButtonGroup
          value={timeRange}
          exclusive
          onChange={handleTimeRangeChange}
          size="small"
        >
          <ToggleButton value="7d">7d</ToggleButton>
          <ToggleButton value="30d">30d</ToggleButton>
          <ToggleButton value="90d">90d</ToggleButton>
          <ToggleButton value="1y">1y</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke={alpha(theme.palette.text.secondary, 0.2)} />
          <XAxis 
            dataKey="date" 
            tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
          />
          <YAxis 
            tickFormatter={formatCurrency} 
            tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
          />
          <Tooltip 
            formatter={(value: number) => [formatCurrency(value), ""]}
            contentStyle={{ 
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 4,
              boxShadow: theme.shadows[4]
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="balance"
            name="Account Balance"
            stroke={theme.palette.primary.main}
            activeDot={{ r: 8 }}
            dot={{ r: 0 }}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="inbound"
            name="Money In"
            stroke={theme.palette.success.main}
            dot={{ r: 0 }}
            strokeWidth={1.5}
          />
          <Line
            type="monotone"
            dataKey="outbound"
            name="Money Out"
            stroke={theme.palette.error.main}
            dot={{ r: 0 }}
            strokeWidth={1.5}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default FinancialMetricsChart; 