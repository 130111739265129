import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { IndependentEngineerAgreement } from "@freetech/models/user";
import { sendMailTemplate } from "core/mail";
import { PASignedByAdminEngineerUpdateProps } from "core/mail/mailTemplates/paSignedByAdminEngineerUpdate";

export interface UpdateSignedAgreementParams {
  userId: string;
  agreementId: string;
  engineerEmail: string;
  engineerName: string;
  engineerFirstName: string;
}

export const updateSignedAgreement = async ({
  userId,
  agreementId,
  engineerEmail,
  engineerName,
  engineerFirstName,
}: UpdateSignedAgreementParams): Promise<boolean> => {
  const agreementRef = doc(
    db,
    "users",
    userId,
    "independentEngineerAgreements",
    agreementId
  );

  const updates: Partial<IndependentEngineerAgreement> = {
    everSignDocumentStatus: "awaiting_engineer_signature",
    status: "signing",
  };

  await updateDoc(agreementRef, updates);

  // Send email notification
  await sendMailTemplate(
    engineerEmail,
    `${engineerFirstName}, your agreement has been signed by the admin. Please review and sign the document.`,
    "paSignedByAdminEngineerUpdate",
    {
      engineerName,
      projectName: "Independent Engineer Agreement",
      url: "https://portal.freetech.co/sign-agreement",
    } as PASignedByAdminEngineerUpdateProps
  );

  return true;
}; 