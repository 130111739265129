import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { 
  DocumentReference, 
  DocumentData, 
  collection, 
  onSnapshot, 
  Unsubscribe 
} from "firebase/firestore";
import { adminController } from "controllers/adminController";
import { Client, ICreateClient } from "@freetech/models/projects";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { db } from "core/firestore";

export const useAdminClients = () => {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  // Load all clients (GET)
  const {
    data: clients,
    isLoading,
    isError,
  }: UseQueryResult<Client[], Error> = useQuery<Client[], Error>({
    queryKey: ["clients"],
    queryFn: () => adminController.clients.loadNestedClients(),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // Set up snapshot listener for clients
  useEffect(() => {
    let unsubscribe: Unsubscribe;
    
    const setupClientsListener = async () => {
      const clientsRef = collection(db, 'clients');
      
      unsubscribe = onSnapshot(clientsRef, async () => {
        // When clients collection changes, invalidate the clients query
        // This will trigger a refetch of the clients data
        queryClient.invalidateQueries({ queryKey: ["clients"] });
      });
    };
    
    setupClientsListener();
    
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [queryClient]);

  // Add new client (POST)
  const addClientMutation = useMutation<
    DocumentReference<DocumentData>,
    Error,
    ICreateClient
  >({
    mutationFn: (c: ICreateClient) => adminController.clients.createClient(c, currentUser),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Update a client (PUT)
  const updateClientMutation = useMutation<
    void,
    Error,
    { id: string; client: Partial<Client> }
  >({
    mutationFn: ({ id, client }) =>
      adminController.clients.updateClient(client),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Delete a client (DELETE)
  const deleteClientMutation = useMutation<void, Error, string>({
    mutationFn: (clientId: string) =>
      adminController.clients.deleteClient(clientId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  return {
    clients,
    isLoading,
    isError,
    addClient: addClientMutation,
    updateClient: updateClientMutation,
    deleteClient: deleteClientMutation,
  };
};
