import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Board, Card, List } from "@freetech/models/freelo";
import {
  doc,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "config/firebase";
import { useEffect } from "react";

export const useFreeloBoard = (boardId: string) => {
  const queryClient = useQueryClient();

  const query = useQuery<Board, Error>({
    queryKey: ["board", boardId],
    queryFn: async (): Promise<Board> => {
      const boardRef = doc(db, "boards", boardId);
      const listsRef = collection(db, "boards", boardId, "lists");
      const cardsRef = collection(db, "boards", boardId, "cards");

      // Initial fetch
      const [boardDoc, listsSnapshot, cardsSnapshot] = await Promise.all([
        getDoc(boardRef),
        getDocs(listsRef),
        getDocs(cardsRef),
      ]);

      if (!boardDoc.exists()) {
        throw new Error("Board not found");
      }

      const board = boardDoc.data() as Board;
      const lists = listsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as List[];
      const cards = cardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        boardId: boardId,
        ...doc.data(),
      })) as Card[];

      const listsWithCards = lists.map((list) => ({
        ...list,
        cards: cards.filter((card) => card.listId === list.id),
      }));

      return {
        ...board,
        id: boardId,
        lists: listsWithCards,
      };
    },
    enabled: !!boardId,
    staleTime: Infinity,
    gcTime: 1000 * 60 * 30,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Set up real-time listeners
  useEffect(() => {
    if (!boardId) return;

    const boardRef = doc(db, "boards", boardId);
    const listsRef = collection(db, "boards", boardId, "lists");
    const cardsRef = collection(db, "boards", boardId, "cards");

    const unsubBoard = onSnapshot(boardRef, (snapshot) => {
      if (!snapshot.exists()) return;
      const currentBoard = queryClient.getQueryData<Board>(["board", boardId]);
      if (!currentBoard) return;

      const updatedBoard = {
        ...currentBoard,
        ...snapshot.data(),
      };
      queryClient.setQueryData(["board", boardId], updatedBoard);
    });

    const unsubLists = onSnapshot(listsRef, (snapshot) => {
      const currentBoard = queryClient.getQueryData<Board>(["board", boardId]);
      if (!currentBoard) return;

      const updatedLists = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as List[];

      const updatedBoard = {
        ...currentBoard,
        lists: updatedLists.map((list) => ({
          ...list,
          cards: currentBoard.lists?.find((l) => l.id === list.id)?.cards || [],
        })),
      };
      queryClient.setQueryData(["board", boardId], updatedBoard);
    });

    const unsubCards = onSnapshot(cardsRef, (snapshot) => {
      const currentBoard = queryClient.getQueryData<Board>(["board", boardId]);
      if (!currentBoard) return;

      const updatedCards = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Card[];

      const updatedBoard = {
        ...currentBoard,
        lists:
          currentBoard.lists?.map((list) => ({
            ...list,
            cards: updatedCards.filter((card) => card.listId === list.id),
          })) || [],
      };
      queryClient.setQueryData(["board", boardId], updatedBoard);
    });

    // Cleanup listeners on unmount
    return () => {
      unsubBoard();
      unsubLists();
      unsubCards();
    };
  }, [boardId]);

  return query;
};
