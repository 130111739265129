import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  IconButton,
  Collapse,
  Grid,
  Chip,
  Stack,
  Snackbar,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tab,
  Tabs,
  Badge,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Assignment as AssignmentIcon,
  Dashboard as DashboardIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  CheckCircle as CheckCircleIcon,
  Description as DescriptionIcon,
  ChatBubbleOutline as ChatIcon,
  Psychology as BrainIcon,
  Upload as UploadIcon,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";
import { useLocalStorageBoolState } from "hooks/useLocalStorage";
import { 
  Client, 
  ConsultingServicesAgreement, 
  Project,
  ProjectPlan, 
  ProjectIdeaData, 
  CSAType,
  CSAStatus,
  BillableRate
} from "@freetech/models/projects";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useFreelo } from "hooks/freelo/useFreelo";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "core/firestore";
import AddProjectDialog from "./AddProjectDialog";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "config/firebase";
import ViewFileDialog from "components/ViewFileDialog";
import EditProjectDialog from "./EditProjectDialog";

interface AssociatedProjectsProps {
  client: Client;
  clientId: string;
}

const STATUS_COLORS = {
  active: "#2ecc71",
  completed: "#95a5a6",
  idea: "#3498db",
  default: "#95a5a6",
} as const;

const AssociatedProjects: React.FC<AssociatedProjectsProps> = ({
  client,
  clientId,
}) => {
  const [isVisible, setIsVisible] = useLocalStorageBoolState(
    "isAssociatedProjectsVisible",
    false
  );
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loadingCSA, setLoadingCSA] = useState<string | null>(null);
  const [selectedCSA, setSelectedCSA] = useState<ConsultingServicesAgreement | null>(null);
  const [viewCSADialogOpen, setViewCSADialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [assignBoardDialogOpen, setAssignBoardDialogOpen] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState("");
  const [selectedProjectForBoard, setSelectedProjectForBoard] = useState<Project | null>(null);
  const [uploadCSADialogOpen, setUploadCSADialogOpen] = useState(false);
  const [selectedProjectForCSA, setSelectedProjectForCSA] = useState<Project | null>(null);
  const [csaFormData, setCSAFormData] = useState({
    name: "",
    effectiveDate: "",
    endDate: "",
    csaType: "monthly" as CSAType,
    monthlyBudget: "",
    fixedPriceBudget: "",
    billableRates: [{ role: "", rate: "" }],
    scopeOfWork: "",
  });
  const [csaFile, setCSAFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [csaTabValue, setCSATabValue] = useState(0);
  
  const {
    client: clientData,
    addProject,
    deleteProject,
    updateProject,
    assignProjectToBoard,
    projectPlans,
    isLoadingProjectPlans,
    isErrorProjectPlans,
    deleteProjectPlan,
    isDeletingProjectPlan,
    projectIdeas,
    isLoadingProjectIdeas,
    isErrorProjectIdeas,
    deleteProjectIdea,
    isDeletingProjectIdea,
    uploadCSA,
    isUploadingCSA,
  } = useAdminClient(clientId);
  
  const { boards, isLoading: isLoadingBoards } = useFreelo();

  const projects = clientData?.projects || [];

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleAddProject = async (projectData: Omit<Project, "id">) => {
    try {
      return await addProject(projectData);
    } catch (error) {
      console.error("Error adding project:", error);
      setError("Failed to add project. Please try again.");
      throw error;
    }
  };

  const handleDeleteProject = async (projectId: string) => {
    try {
      await deleteProject.mutateAsync(projectId);
    } catch (error) {
      console.error("Error deleting project:", error);
      setError("Failed to delete project. Please try again.");
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleCSATabChange = (_: React.SyntheticEvent, newValue: number) => {
    setCSATabValue(newValue);
  };

  const handleCSAClick = async (csa: ConsultingServicesAgreement) => {
    try {
      setLoadingCSA(csa.id);
      setSelectedCSA(csa);
      
      // Always open the dialog - will show appropriate tab based on file availability
      setViewCSADialogOpen(true);
      
      // Default to details tab
      setCSATabValue(csa.fileUrl ? 1 : 0);
    } catch (error) {
      console.error("Error opening CSA file:", error);
      setError("Failed to open CSA file. Please try again.");
    } finally {
      setLoadingCSA(null);
    }
  };

  const handleCloseViewCSADialog = () => {
    setViewCSADialogOpen(false);
    setSelectedCSA(null);
  };

  const handleEditProject = async (projectData: Partial<Project>) => {
    if (!selectedProject) return;
    
    try {
      // Create optimistic update
      const updatedProject = {
        ...selectedProject,
        ...projectData,
      };

      // Update the local state immediately
      const updatedProjects = projects.map((p) =>
        p.id === selectedProject.id ? updatedProject : p
      );

      // Update client data optimistically
      if (clientData) {
        clientData.projects = updatedProjects;
      }

      await updateProject.mutateAsync({
        ...selectedProject,
        ...projectData
        
      });

      setEditDialogOpen(false);
      setSelectedProject(null);
    } catch (error) {
      console.error("Error updating project:", error);
      setError("Failed to update project. Please try again.");
    }
  };

  const handleOpenEditDialog = (project: Project) => {
    setSelectedProject(project);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedProject(null);
  };

  const handleOpenAssignBoardDialog = (project: Project) => {
    setSelectedProjectForBoard(project);
    setAssignBoardDialogOpen(true);
  };

  const handleAssignToBoard = async () => {
    if (!selectedProjectForBoard || !selectedBoardId) return;

    try {
      await assignProjectToBoard.mutateAsync({
        boardId: selectedBoardId,
        projectId: selectedProjectForBoard.id,
        clientId,
      });
      setAssignBoardDialogOpen(false);
      setSelectedBoardId("");
      setSelectedProjectForBoard(null);
    } catch (error) {
      console.error("Error assigning project to board:", error);
      setError("Failed to assign project to board. Please try again.");
    }
  };

  const getBoardName = (boardId: string) => {
    return boards?.find(board => board.id === boardId)?.name || boardId;
  };

  const renderCSAInfo = (project: Project) => {
    const csas =
      clientData?.consultingServicesAgreements?.filter(
        (csa) => csa.projectId === project.id
      ) || [];

    if (csas.length === 0) {
      return (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, fontStyle: "italic" }}
        >
          No CSAs attached
        </Typography>
      );
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Divider sx={{ my: 1 }} />
        <Typography
          variant="subtitle2"
          color="text.secondary"
          gutterBottom
          sx={{ display: "flex", alignItems: "center" }}
        >
          <DescriptionIcon sx={{ fontSize: 16, mr: 0.5 }} />
          Client Service Agreements
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {csas.map((csa) => {
            const isActive = project.activeCSAId === csa.id;
            return (
              <Chip
                key={csa.id}
                label={
                  loadingCSA === csa.id ? (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                      <CircularProgress size={12} color="primary" />
                      <span>Loading...</span>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {isActive && <CheckCircleIcon sx={{ fontSize: 16 }} />}
                      {`CSA ${csa.name || csa.id}`}
                    </Box>
                  )
                }
                size="small"
                color={isActive ? "success" : "default"}
                variant={isActive ? "filled" : "outlined"}
                onClick={() => handleCSAClick(csa)}
                disabled={loadingCSA === csa.id}
                sx={{
                  cursor: loadingCSA === csa.id ? "wait" : "pointer",
                  minWidth: loadingCSA === csa.id ? "100px" : "auto",
                  "& .MuiChip-label": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            );
          })}
        </Stack>
      </Box>
    );
  };

  const renderBoardInfo = (project: Project) => {
    const assignedBoard = boards?.find(board => board.projectId === project.id);
    if (!assignedBoard) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DashboardIcon sx={{ fontSize: 16, mr: 0.5 }} />
            Assigned Board: {assignedBoard.name}
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedProjectForBoard(project);
              setSelectedBoardId(assignedBoard.id);
              setAssignBoardDialogOpen(true);
            }}
            title="Edit Board Assignment"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  // Project Plans visibility
  const [projectPlansVisible, setProjectPlansVisible] = useState(true);
  const toggleProjectPlansVisibility = () => {
    setProjectPlansVisible(!projectPlansVisible);
  };

  // Project Ideas visibility
  const [projectIdeasVisible, setProjectIdeasVisible] = useState(true);
  const toggleProjectIdeasVisibility = () => {
    setProjectIdeasVisible(!projectIdeasVisible);
  };

  // Handle delete project plan
  const handleDeleteProjectPlan = (planId: string) => {
    if (window.confirm("Are you sure you want to delete this project plan?")) {
      deleteProjectPlan(planId);
    }
  };

  // Handle delete project idea
  const handleDeleteProjectIdea = (ideaId: string) => {
    if (window.confirm("Are you sure you want to delete this project idea?")) {
      deleteProjectIdea(ideaId);
    }
  };

  const handleOpenCSADialog = (project: Project) => {
    setSelectedProjectForCSA(project);
    setUploadCSADialogOpen(true);
  };

  const handleCSAInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCSAFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCSATypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCSAFormData((prev) => ({
      ...prev,
      csaType: e.target.value as CSAType,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCSAFile(e.target.files[0]);
    }
  };
  
  const handleAddBillableRate = () => {
    setCSAFormData((prev) => ({
      ...prev,
      billableRates: [...prev.billableRates, { role: "", rate: "" }],
    }));
  };

  const handleBillableRateChange = (index: number, field: string, value: string) => {
    setCSAFormData((prev) => {
      const updatedRates = [...prev.billableRates];
      updatedRates[index] = {
        ...updatedRates[index],
        [field]: value,
      };
      return {
        ...prev,
        billableRates: updatedRates,
      };
    });
  };

  const handleRemoveBillableRate = (index: number) => {
    setCSAFormData((prev) => {
      const updatedRates = [...prev.billableRates];
      updatedRates.splice(index, 1);
      return {
        ...prev,
        billableRates: updatedRates.length ? updatedRates : [{ role: "", rate: "" }],
      };
    });
  };

  const handleUploadCSA = async () => {
    if (!selectedProjectForCSA || !csaFile) return;
    
    try {
      // Create CSA data object with properly handled fields
      const csaData: any = {
        clientId,
        projectId: selectedProjectForCSA.id,
        name: csaFormData.name || `CSA for ${selectedProjectForCSA.name}`,
        effectiveDate: csaFormData.effectiveDate,
        endDate: csaFormData.endDate || null,
        csaType: csaFormData.csaType,
        status: "draft" as CSAStatus,
        createdAt: new Date().toISOString(),
        billableRates: csaFormData.billableRates
          .filter(rate => rate.role && rate.rate)
          .map(rate => ({
            role: rate.role,
            rate: parseFloat(rate.rate),
          })),
        scopeOfWork: csaFormData.scopeOfWork.split('\n').filter(item => item.trim() !== ''),
      };
      
      // Add budget fields based on CSA type, only if they have values
      if (csaFormData.csaType === "monthly" && csaFormData.monthlyBudget) {
        csaData.monthlyBudget = parseFloat(csaFormData.monthlyBudget);
      }
      
      if (csaFormData.csaType === "fixed_price" && csaFormData.fixedPriceBudget) {
        csaData.fixedPriceBudget = parseFloat(csaFormData.fixedPriceBudget);
      }
      
      // Upload the CSA
      await uploadCSA({
        projectId: selectedProjectForCSA.id,
        csaData,
        file: csaFile,
      });
      
      handleCloseViewCSADialog();
    } catch (error) {
      console.error("Error uploading CSA:", error);
      setError("Failed to upload CSA. Please try again.");
    }
  };

  const renderCSADetails = () => {
    if (!selectedCSA) return null;
    
    return (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom fontWeight="bold">
              CSA Name
            </Typography>
            <Typography variant="body1" gutterBottom>
              {selectedCSA.name || "Unnamed CSA"}
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom fontWeight="bold">
              Status
            </Typography>
            <Chip 
              label={selectedCSA.status}
              size="small"
              color={
                selectedCSA.status === "active" ? "success" :
                selectedCSA.status === "draft" ? "default" :
                "primary"
              }
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom fontWeight="bold">
              CSA Type
            </Typography>
            <Typography variant="body1" gutterBottom>
              {selectedCSA.csaType === "monthly" 
                ? "Monthly Budget" 
                : selectedCSA.csaType === "fixed_price" 
                  ? "Fixed Price" 
                  : selectedCSA.csaType}
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom fontWeight="bold">
              Effective Date
            </Typography>
            <Typography variant="body1" gutterBottom>
              {selectedCSA.effectiveDate 
                ? new Date(selectedCSA.effectiveDate).toLocaleDateString() 
                : "Not specified"}
            </Typography>
          </Grid>
          
          {selectedCSA.endDate && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                End Date
              </Typography>
              <Typography variant="body1" gutterBottom>
                {new Date(selectedCSA.endDate).toLocaleDateString()}
              </Typography>
            </Grid>
          )}
          
          {selectedCSA.csaType === "monthly" && selectedCSA.monthlyBudget && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                Monthly Budget
              </Typography>
              <Typography variant="body1" gutterBottom>
                ${selectedCSA.monthlyBudget.toLocaleString()}
              </Typography>
            </Grid>
          )}
          
          {selectedCSA.csaType === "fixed_price" && selectedCSA.fixedPriceBudget && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                Fixed Budget
              </Typography>
              <Typography variant="body1" gutterBottom>
                ${selectedCSA.fixedPriceBudget.toLocaleString()}
              </Typography>
            </Grid>
          )}
          
          {selectedCSA.billableRates && selectedCSA.billableRates.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                Billable Rates
              </Typography>
              <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Role</TableCell>
                      <TableCell align="right">Hourly Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedCSA.billableRates.map((rate, index) => (
                      <TableRow key={index}>
                        <TableCell>{rate.role}</TableCell>
                        <TableCell align="right">${rate.rate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          
          {selectedCSA.scopeOfWork && selectedCSA.scopeOfWork.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                Scope of Work
              </Typography>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <ul style={{ margin: 0, paddingLeft: 20 }}>
                  {selectedCSA.scopeOfWork.map((item, index) => (
                    <li key={index}>
                      <Typography variant="body2">{item}</Typography>
                    </li>
                  ))}
                </ul>
              </Paper>
            </Grid>
          )}
          
          {selectedCSA.createdAt && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Created: {new Date(selectedCSA.createdAt).toLocaleString()}
              </Typography>
            </Grid>
          )}
          
          {selectedCSA.updatedAt && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Last Updated: {new Date(selectedCSA.updatedAt).toLocaleString()}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };
  
  const renderCSAFilePreview = () => {
    if (!selectedCSA || !selectedCSA.fileUrl) {
      return (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 400,
            p: 3,
            bgcolor: 'background.paper'
          }}
        >
          <Typography variant="body1" color="text.secondary">
            No file available for this CSA
          </Typography>
        </Box>
      );
    }
    
    return (
      <Box 
        sx={{ 
          width: '100%', 
          height: 600,
          bgcolor: 'background.paper'
        }}
      >
        <iframe
          src={selectedCSA.fileUrl}
          style={{ 
            width: '100%', 
            height: '100%', 
            border: 'none' 
          }}
          title={`CSA ${selectedCSA.name || selectedCSA.id}`}
          onLoad={() => setLoadingCSA(null)}
        />
      </Box>
    );
  };

  return (
    <>
      <Card
        elevation={2}
        sx={{
          width: "100%",
          mb: 3,
          background: "linear-gradient(45deg, #1a237e 30%, #283593 90%)",
        }}
      >
        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <Box display="flex" alignItems="center">
              <AssignmentIcon sx={{ fontSize: 28, mr: 2 }} />
              <Typography variant="h6" component="h2">
                Projects
              </Typography>
              <IconButton
                onClick={toggleVisibility}
                sx={{ color: "white", ml: 2 }}
                size="small"
              >
                {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Box>

            <Button
              onClick={() => setOpenAddDialog(true)}
              startIcon={<AddIcon />}
              variant="contained"
              size="small"
              sx={{
                bgcolor: "rgba(255, 255, 255, 0.1)",
                color: "white",
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              Add Project
            </Button>
          </Box>

          {/* Projects List */}
          <Collapse in={Boolean(isVisible)}>
            <Box sx={{ mt: 2 }}>
              {projects.length === 0 ? (
                <Typography
                  color="white"
                  variant="body2"
                  sx={{ textAlign: "center", py: 2 }}
                >
                  No projects found
                </Typography>
              ) : (
                <Grid container spacing={2}>
                  {projects.map((project) => (
                    <Grid item xs={12} key={project.id}>
                      <Card>
                        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h6" gutterBottom>
                                {project.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 1 }}
                              >
                                {project.description}
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={1}
                                flexWrap="wrap"
                                useFlexGap
                                sx={{ mb: 1 }}
                              >
                                <Chip
                                  label={project.status}
                                  size="small"
                                  sx={{
                                    bgcolor:
                                      STATUS_COLORS[
                                        project.status as keyof typeof STATUS_COLORS
                                      ] || STATUS_COLORS.default,
                                    color: "white",
                                  }}
                                />
                                {project.startDate && (
                                  <Chip
                                    label={`Started: ${new Date(project.startDate).toLocaleDateString()}`}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                                {project.endDate && (
                                  <Chip
                                    label={`Ended: ${new Date(project.endDate).toLocaleDateString()}`}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              </Stack>
                              {renderCSAInfo(project)}
                              {renderBoardInfo(project)}
                            </Box>
                            <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
                              {!boards?.some(board => board.projectId === project.id) && (
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpenAssignBoardDialog(project)}
                                  title="Assign to Board"
                                >
                                  <DashboardIcon />
                                </IconButton>
                              )}
                              <IconButton
                                size="small"
                                onClick={() => handleOpenCSADialog(project)}
                                title="Upload CSA"
                              >
                                <AttachFileIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => handleOpenEditDialog(project)}
                                title="Edit Project"
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteProject(project.id)}
                                disabled={deleteProject.isPending || false}
                                title="Delete Project"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>

      {/* Project Plans Card */}
      <Card
        elevation={2}
        sx={{
          width: "100%",
          mb: 3,
          background: "linear-gradient(45deg, #4a148c 30%, #6a1b9a 90%)",
        }}
      >
        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <Box display="flex" alignItems="center">
              <DescriptionIcon sx={{ fontSize: 28, mr: 2 }} />
              <Typography variant="h6" component="h2">
                Project Plans
              </Typography>
              <IconButton
                onClick={toggleProjectPlansVisibility}
                sx={{ color: "white", ml: 2 }}
                size="small"
              >
                {projectPlansVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Box>
          </Box>

          {/* Plans List */}
          <Collapse in={Boolean(projectPlansVisible)}>
            <Box sx={{ mt: 2 }}>
              {isLoadingProjectPlans ? (
                <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
                  <CircularProgress color="inherit" />
                </Box>
              ) : projectPlans && projectPlans.length > 0 ? (
                <Grid container spacing={2}>
                  {projectPlans.map((plan) => (
                    <Grid item xs={12} key={plan.id}>
                      <Card>
                        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h6" gutterBottom>
                                Project Plan {plan.projectId ? `for ${plan.projectId}` : ""}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 1 }}
                              >
                                {plan.overview}
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={1}
                                flexWrap="wrap"
                                useFlexGap
                                sx={{ mb: 1 }}
                              >
                                <Chip
                                  label={plan.status}
                                  size="small"
                                  sx={{
                                    bgcolor: 
                                      plan.status === "approved" ? "#4caf50" :
                                      plan.status === "rejected" ? "#f44336" :
                                      plan.status === "in_stakeholder_review" ? "#ff9800" :
                                      "#2196f3",
                                    color: "white",
                                  }}
                                />
                                <Chip
                                  label={`Phases: ${plan.timeline.phases.length}`}
                                  size="small"
                                  variant="outlined"
                                />
                                <Chip
                                  label={`Budget: $${plan.resources.estimatedCost.min}-${plan.resources.estimatedCost.max}`}
                                  size="small"
                                  variant="outlined"
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteProjectPlan(plan.id)}
                                disabled={isDeletingProjectPlan}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography
                  color="white"
                  variant="body2"
                  sx={{ textAlign: "center", py: 2 }}
                >
                  No project plans found
                </Typography>
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>

      {/* Project Ideas Card */}
      <Card
        elevation={2}
        sx={{
          width: "100%",
          mb: 3,
          background: "linear-gradient(45deg, #00695c 30%, #00897b 90%)",
        }}
      >
        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <Box display="flex" alignItems="center">
              <BrainIcon sx={{ fontSize: 28, mr: 2 }} />
              <Typography variant="h6" component="h2">
                Project Ideas
              </Typography>
              <IconButton
                onClick={toggleProjectIdeasVisibility}
                sx={{ color: "white", ml: 2 }}
                size="small"
              >
                {projectIdeasVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Box>
          </Box>

          {/* Ideas List */}
          <Collapse in={Boolean(projectIdeasVisible)}>
            <Box sx={{ mt: 2 }}>
              {isLoadingProjectIdeas ? (
                <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
                  <CircularProgress color="inherit" />
                </Box>
              ) : projectIdeas && projectIdeas.length > 0 ? (
                <Grid container spacing={2}>
                  {projectIdeas.map((idea) => (
                    <Grid item xs={12} key={idea.id}>
                      <Card>
                        <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="h6" gutterBottom>
                                {idea.name || "Unnamed Project Idea"}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 1 }}
                              >
                                {idea.projectDescription || idea.description || "No description provided"}
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={1}
                                flexWrap="wrap"
                                useFlexGap
                                sx={{ mb: 1 }}
                              >
                                <Chip
                                  label={idea.status}
                                  size="small"
                                  sx={{
                                    bgcolor: 
                                      idea.status === "approved" ? "#4caf50" :
                                      idea.status === "rejected" ? "#f44336" :
                                      idea.status === "in_progress" ? "#ff9800" :
                                      idea.status === "awaiting_admin_approval" ? "#2196f3" :
                                      idea.status === "awaiting_stakeholder_approval" ? "#9c27b0" :
                                      "#607d8b",
                                    color: "white",
                                  }}
                                />
                                {idea.projectType && (
                                  <Chip
                                    label={`Type: ${idea.projectType}`}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                                {idea.budget && (
                                  <Chip
                                    label={`Budget: $${idea.budget}`}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                                {idea.timeline && (
                                  <Chip
                                    label={`Timeline: ${idea.timeline}`}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                                {idea.messages && (
                                  <Chip
                                    label={`Messages: ${idea.messages.length}`}
                                    size="small"
                                    variant="outlined"
                                    icon={<ChatIcon sx={{ fontSize: 16 }} />}
                                  />
                                )}
                                {idea.readyForPlan && (
                                  <Chip
                                    label="Ready for Plan"
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                  />
                                )}
                              </Stack>
                              
                              {idea.projectPlanId && (
                                <Box sx={{ mt: 1 }}>
                                  <Divider sx={{ my: 1 }} />
                                  <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                    sx={{ display: "flex", alignItems: "center" }}
                                  >
                                    <DescriptionIcon sx={{ fontSize: 16, mr: 0.5 }} />
                                    Has associated project plan: {idea.projectPlanId}
                                  </Typography>
                                </Box>
                              )}
                              
                              {idea.createdAt && (
                                <Typography variant="caption" color="text.secondary" display="block">
                                  Created: {new Date(idea.createdAt).toLocaleDateString()}
                                </Typography>
                              )}
                              {idea.updatedAt && idea.updatedAt !== idea.createdAt && (
                                <Typography variant="caption" color="text.secondary" display="block">
                                  Last updated: {new Date(idea.updatedAt).toLocaleDateString()}
                                </Typography>
                              )}
                            </Box>
                            <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteProjectIdea(idea.id as string)}
                                disabled={isDeletingProjectIdea}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography
                  color="white"
                  variant="body2"
                  sx={{ textAlign: "center", py: 2 }}
                >
                  No project ideas found
                </Typography>
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>

      <AddProjectDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onAdd={handleAddProject}
        clientId={clientId}
      />

      {selectedProject && (
        <EditProjectDialog
          open={editDialogOpen}
          onClose={handleCloseEditDialog}
          onSave={handleEditProject}
          project={selectedProject}
          csas={clientData?.consultingServicesAgreements?.filter(
            (csa) => csa.projectId === selectedProject.id
          ) || []}
        />
      )}

      {/* CSA Details/Preview Dialog */}
      <Dialog
        open={viewCSADialogOpen}
        onClose={handleCloseViewCSADialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          CSA {selectedCSA?.name || selectedCSA?.id || ""}
        </DialogTitle>
        <DialogContent>
          {loadingCSA ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs 
                  value={csaTabValue} 
                  onChange={handleCSATabChange} 
                  aria-label="CSA tabs"
                  variant="fullWidth"
                >
                  <Tab label="CSA Details" />
                  <Tab label="Document Preview" disabled={!selectedCSA?.fileUrl} />
                </Tabs>
              </Box>
              <Box sx={{ p: 0 }}>
                {csaTabValue === 0 ? renderCSADetails() : renderCSAFilePreview()}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewCSADialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Assign to Board Dialog */}
      <Dialog
        open={assignBoardDialogOpen}
        onClose={() => {
          setAssignBoardDialogOpen(false);
          setSelectedBoardId("");
          setSelectedProjectForBoard(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedBoardId && boards?.some(board => board.projectId === selectedProjectForBoard?.id)
            ? "Edit Board Assignment"
            : "Assign Project to Board"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="board-select-label">Select Board</InputLabel>
              <Select
                labelId="board-select-label"
                value={selectedBoardId}
                onChange={(e) => setSelectedBoardId(e.target.value)}
                label="Select Board"
                disabled={isLoadingBoards}
              >
                {isLoadingBoards ? (
                  <MenuItem disabled>Loading boards...</MenuItem>
                ) : boards && boards.length > 0 ? (
                  boards.map((board) => (
                    <MenuItem key={board.id} value={board.id}>
                      {board.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No boards available</MenuItem>
                )}
              </Select>
              <FormHelperText>
                {isLoadingBoards 
                  ? "Loading available boards..." 
                  : "Select a board to assign this project to"}
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAssignBoardDialogOpen(false);
              setSelectedBoardId("");
              setSelectedProjectForBoard(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAssignToBoard}
            variant="contained"
            disabled={!selectedBoardId || assignProjectToBoard.isPending || isLoadingBoards}
          >
            {assignProjectToBoard.isPending ? "Assigning..." : "Assign"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload CSA Dialog */}
      <Dialog
        open={uploadCSADialogOpen}
        onClose={handleCloseViewCSADialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Upload CSA for {selectedProjectForCSA?.name || "Project"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="CSA Name"
                  name="name"
                  value={csaFormData.name}
                  onChange={handleCSAInputChange}
                  placeholder="e.g., Q1 2023 Services Agreement"
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Effective Date"
                  name="effectiveDate"
                  type="date"
                  value={csaFormData.effectiveDate}
                  onChange={handleCSAInputChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="End Date (Optional)"
                  name="endDate"
                  type="date"
                  value={csaFormData.endDate}
                  onChange={handleCSAInputChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  CSA Type
                </Typography>
                <RadioGroup
                  row
                  name="csaType"
                  value={csaFormData.csaType}
                  onChange={handleCSATypeChange}
                >
                  <FormControlLabel
                    value="monthly"
                    control={<Radio />}
                    label="Monthly Budget"
                  />
                  <FormControlLabel
                    value="fixed_price"
                    control={<Radio />}
                    label="Fixed Price"
                  />
                </RadioGroup>
              </Grid>
              
              {csaFormData.csaType === "monthly" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Monthly Budget"
                    name="monthlyBudget"
                    type="number"
                    value={csaFormData.monthlyBudget}
                    onChange={handleCSAInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              
              {csaFormData.csaType === "fixed_price" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Fixed Price Budget"
                    name="fixedPriceBudget"
                    type="number"
                    value={csaFormData.fixedPriceBudget}
                    onChange={handleCSAInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Billable Rates
                </Typography>
                {csaFormData.billableRates.map((rate, index) => (
                  <Box
                    key={index}
                    sx={{ 
                      display: "flex", 
                      gap: 2, 
                      mb: 2,
                      alignItems: "center" 
                    }}
                  >
                    <TextField
                      label="Role"
                      value={rate.role}
                      onChange={(e) => 
                        handleBillableRateChange(index, "role", e.target.value)
                      }
                      sx={{ flex: 2 }}
                    />
                    <TextField
                      label="Hourly Rate"
                      type="number"
                      value={rate.rate}
                      onChange={(e) => 
                        handleBillableRateChange(index, "rate", e.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      sx={{ flex: 1 }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveBillableRate(index)}
                      disabled={csaFormData.billableRates.length === 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAddBillableRate}
                  size="small"
                >
                  Add Rate
                </Button>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Scope of Work"
                  name="scopeOfWork"
                  value={csaFormData.scopeOfWork}
                  onChange={handleCSAInputChange}
                  multiline
                  rows={4}
                  placeholder="Enter each work item on a new line"
                  helperText="Enter each scope item on a new line. These will be formatted as a list in the CSA."
                />
              </Grid>
              
              <Grid item xs={12}>
                <Box sx={{ 
                  border: '1px dashed',
                  borderColor: 'divider',
                  p: 3,
                  textAlign: 'center',
                  borderRadius: 1,
                  mb: 2
                }}>
                  <input
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    id="csa-file-upload"
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  <label htmlFor="csa-file-upload">
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<UploadIcon />}
                    >
                      Select CSA Document
                    </Button>
                  </label>
                  <Box sx={{ mt: 2 }}>
                    {csaFile ? (
                      <Typography variant="body2">
                        Selected file: {csaFile.name}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No file selected (PDF recommended)
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewCSADialog}>
            Cancel
          </Button>
          <Button
            onClick={handleUploadCSA}
            variant="contained"
            disabled={!csaFile || isUploadingCSA || !csaFormData.effectiveDate}
          >
            {isUploadingCSA ? <CircularProgress size={24} /> : "Upload CSA"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={error}
      />
    </>
  );
};

export default AssociatedProjects;
