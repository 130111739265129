import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "config";
import { Board } from "@freetech/models/freelo";

export const generateHumanReadableCardId = async (
  boardId: string,
  boards?: Board[]
) => {
  let board: Board | undefined;
  if (boards) {
    board = boards?.find((b) => b.id === boardId);
  } else {
    const boardRef = doc(db, "boards", boardId);
    const boardDoc = await getDoc(boardRef);
    board = boardDoc.data() as Board;
  }

  if (!board) {
    throw new Error("Board not found");
  }

  // Get all cards directly from the board's cards collection
  const cardsRef = collection(db, "boards", boardId, "cards");
  const cardsSnapshot = await getDocs(cardsRef);
  const totalCardCount = cardsSnapshot.size;

  const cardCountString = (totalCardCount + 1).toString().padStart(2, "0");

  return `${board?.name
    .split(" ")
    .slice(0, 2)
    .map((n) => n[0])
    .join("")}-${cardCountString}`;
};
