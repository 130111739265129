import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  useTheme,
  Typography,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { authTextFieldStyle } from "core/theme";
import { errorMessages } from "core/errorMessages";
import { FcGoogle } from "react-icons/fc";
import { AuthErrorDialog } from "components";
import { useMFA } from "hooks/auth/useMFA";
import { MultiFactorError } from "@firebase/auth";
import { FirebaseError } from "firebase/app";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginWithEmail, loginWithGoogle, handleVerifyActionCode } = useAuth();
  const { handleMFAError } = useMFA();
  const [openDialog, setOpenDialog] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastAttemptedAction, setLastAttemptedAction] =
    useState<() => Promise<void>>();
  const [dialogContent, setDialogContent] = useState({
    title: "",
    errorMessage: "",
    showResetPassword: false,
    showResendVerification: false,
    email: "",
    customButton: undefined as
      | { text: string; onClick: () => void }
      | undefined,
    hideDefaultButton: false,
  });

  // Success message state
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const googleError = searchParams.get("googleError");

  // Check for success message in location state
  useEffect(() => {
    if (location.state && location.state.successMessage) {
      setSuccessMessage(location.state.successMessage);
      setOpenSuccessSnackbar(true);

      // Clear the state after showing the message
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const credentials = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
      rememberMe,
    };

    setIsLoading(true);
    const loginAction = async () => {
      await loginWithEmail(credentials);
    };

    setLastAttemptedAction(() => loginAction);
    try {
      await loginAction();
    } catch (error: any) {
      handleLoginError(error as MultiFactorError | FirebaseError);
    } finally {
      setIsLoading(false);
    }
  };

  const openLoginWithGooglePopup = async () => {
    // Clear any previous errors
    setSearchParams((params) => {
      params.delete("googleError");
      return params;
    });

    setIsLoading(true);
    const googleLoginAction = async () => {
      await loginWithGoogle();
    };

    setLastAttemptedAction(() => googleLoginAction);
    try {
      await googleLoginAction();
    } catch (error: any) {
      handleLoginError(error as MultiFactorError | FirebaseError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setLastAttemptedAction(undefined);
  };

  const handleCloseSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };

  const handleRetry = async () => {
    setOpenDialog(false);
  };

  const handleLoginError = (error: MultiFactorError | FirebaseError) => {
    // Check for AppCheck/ReCAPTCHA errors first
    if (
      error.code === "auth/browser-not-supported" ||
      (error.message &&
        (error.message.includes("ReCAPTCHA") ||
          error.message.includes("AppCheck")))
    ) {
      setDialogContent({
        title: "Browser Mode Not Supported",
        errorMessage:
          "FreeTech does not support incognito mode or private browsing. Please use a regular browser window.",
        showResetPassword: false,
        showResendVerification: false,
        email: "",
        customButton: undefined,
        hideDefaultButton: false,
      });
      setOpenDialog(true);
      return;
    }

    if (error.code === "auth/network-request-failed") {
      setDialogContent({
        title: "Network Error",
        errorMessage: `We're having trouble verifying your browser's security check. This
              might be due to your browser's privacy settings or extensions.
              FreeTech does not support incognito mode or private browsing.`,
        showResetPassword: false,
        showResendVerification: false,
        email: "",
        customButton: {
          text: "Try Again",
          onClick: handleRetry,
        },
        hideDefaultButton: true,
      });
      setOpenDialog(true);
      return;
    }

    if (error.code === "auth/multi-factor-auth-required") {
      const mfaResolver = handleMFAError(error as MultiFactorError);
      if (mfaResolver) {
        navigate("/login-mfa-code");
        return;
      }
    }

    if (error.code === "auth/invalid-credential") {
      setDialogContent({
        title: "Login Error",
        errorMessage: "Incorrect email or password. Please try again.",
        showResetPassword: false,
        showResendVerification: false,
        email: "",
        customButton: undefined,
        hideDefaultButton: false,
      });
      setOpenDialog(true);
      return;
    }

    if (error.code === "auth/invalid-email") {
      setDialogContent({
        title: "Login Error",
        errorMessage: "Invalid email address. Please try again.",
        showResetPassword: false,
        showResendVerification: false,
        email: "",
        customButton: undefined,
        hideDefaultButton: false,
      });
      setOpenDialog(true);
      return;
    } else {
      setDialogContent({
        title: "Login Error",
        errorMessage: "Incorrect email or password. Please try again.",
        showResetPassword: false,
        showResendVerification: false,
        email: "",
        customButton: undefined,
        hideDefaultButton: false,
      });
      setOpenDialog(true);
    }
  };

  const handleNavigateForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleNavigateSignUp = () => {
    navigate("/signup");
  };

  return (
    <>
      <AuthErrorDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogContent.title}
        errorMessage={dialogContent.errorMessage}
        showResetPassword={dialogContent.showResetPassword}
        showResendVerification={dialogContent.showResendVerification}
        resetToken={resetToken}
        email={dialogContent.email}
        customButton={dialogContent.customButton}
        hideDefaultButton={dialogContent.hideDefaultButton}
      />

      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: "100%", mt: 4 }}
      >
        <TextField
          required
          fullWidth
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          disabled={isLoading}
          sx={{
            ...authTextFieldStyle,
            mb: 3,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
              "&.Mui-focused": {
                color: "black",
              },
            },
          }}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin="normal"
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          disabled={isLoading}
          sx={{
            ...authTextFieldStyle,
            mb: 2,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
              "&.Mui-focused": {
                color: "black",
              },
            },
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              disabled={isLoading}
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "black",
                },
              }}
            />
          }
          label="Remember me"
          sx={{ mb: 2 }}
        />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={isLoading}
          sx={{
            mt: 2,
            mb: 3,
            bgcolor: "black",
            color: "white",
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.8)",
            },
            position: "relative",
            minHeight: "42px",
            "& .MuiButton-startIcon": {
              marginRight: isLoading ? 0 : 1,
            },
          }}
        >
          <Box
            sx={{
              visibility: isLoading ? "hidden" : "visible",
              display: "flex",
              alignItems: "center",
            }}
          >
            Sign In
          </Box>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>

        <Button
          fullWidth
          variant="contained"
          disabled={isLoading}
          sx={{
            backgroundColor: "#4285F4",
            color: "white",
            "&:hover": {
              backgroundColor: "#357ae8",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
            mb: 1,
            position: "relative",
            minHeight: "42px",
            "& .MuiButton-startIcon": {
              marginRight: isLoading ? 0 : 1,
              visibility: isLoading ? "hidden" : "visible",
            },
          }}
          startIcon={<FcGoogle />}
          onClick={() => openLoginWithGooglePopup()}
        >
          <Box
            sx={{
              visibility: isLoading ? "hidden" : "visible",
              display: "flex",
              alignItems: "center",
            }}
          >
            Login with Google
          </Box>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>

        {googleError && (
          <Typography
            color="error"
            variant="body2"
            sx={{
              mt: 2,
              mb: 1,
              textAlign: "center",
            }}
          >
            {googleError}
          </Typography>
        )}

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs>
            <Link
              onClick={handleNavigateForgotPassword}
              variant="body2"
              sx={{
                color: "black",
                cursor: isLoading ? "default" : "pointer",
                pointerEvents: isLoading ? "none" : "auto",
              }}
            >
              {"Forgot password?"}
            </Link>
          </Grid>
          <Grid item>
            <Link
              onClick={handleNavigateSignUp}
              variant="body2"
              sx={{
                color: "black",
                cursor: isLoading ? "default" : "pointer",
                pointerEvents: isLoading ? "none" : "auto",
              }}
            >
              {"New here? Sign up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
