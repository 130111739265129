import React, { useState, ChangeEvent, FormEvent, useRef, useEffect } from "react";
import { Box, Paper, Typography, Button, Grid, Tooltip, Alert, AlertTitle, Dialog, DialogTitle, DialogContent, DialogActions, RadioGroup, Radio, FormControlLabel, FormControl, FormLabel, Skeleton, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArticleIcon from "@mui/icons-material/Article";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningIcon from "@mui/icons-material/Warning";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useFreelancerPayroll } from "hooks/freelancer/useFreelancerPayroll";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import { useAuth } from "hooks/auth/useAuth";
import ViewFileDialog from "components/ViewFileDialog";
import PaymentHistoryTable from "./PaymentHistoryTable";
import { DateTime } from "luxon";
import FreelancerPayrollForm from "./FreelancerPayrollForm";
import { IncreaseExternalAccountDetails } from "@freetech/models/bank";
import { PayrollFormData } from "./FreelancerPayrollForm";
import { useEversignSDK } from "hooks/eversign/useEversignSDK";

declare global {
  interface Window {
    eversign: any;
  }
}

const FreelancerPayrollHome: React.FC = () => {
  const { currentUser } = useAuth();
  const { freelancerProfile: freelancer } = useFreelancerProfile();
  const {
    paymentProfile,
    paymentProfileError,
    updateMyFreelancerPayrollProfile,
    getTaxFormStorageUrl,
    startNewTaxForm,
    saveOrUpdateTaxForm,
    isPaymentsLoading,
  } = useFreelancerPayroll();
  const { isLoaded: eversignSDKLoaded } = useEversignSDK();

  const [viewTaxFormDialogOpen, setViewTaxFormDialogOpen] = useState(false);
  const [taxFormFileUrl, setTaxFormFileUrl] = useState<string | null>(null);
  const [isLoadingTaxForm, setIsLoadingTaxForm] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState<
    Partial<IncreaseExternalAccountDetails>
  >({});
  const [showTaxFormDialog, setShowTaxFormDialog] = useState(false);
  const [selectedTaxForm, setSelectedTaxForm] = useState<"w9" | "w8ben" | null>(null);
  const [isGeneratingTaxForm, setIsGeneratingTaxForm] = useState(false);
  const [showTaxFormSigningDialog, setShowTaxFormSigningDialog] = useState(false);
  const [taxFormSigningError, setTaxFormSigningError] = useState<string | null>(null);
  
  const containerRef = useRef<HTMLDivElement>(null);

  // Add a state to track if the dialog content is ready
  const [isDialogReady, setIsDialogReady] = useState(false);

  const handleViewTaxForm = async () => {
    setIsLoadingTaxForm(true);
    try {
      const url = await getTaxFormStorageUrl();
      setTaxFormFileUrl(url);
      setViewTaxFormDialogOpen(true);
    } catch (error) {
      console.error("Error loading tax form:", error);
      setError("Failed to load tax form. Please try again.");
    } finally {
      setIsLoadingTaxForm(false);
    }
  };

  const handleCloseTaxFormDialog = () => {
    setViewTaxFormDialogOpen(false);
    setTaxFormFileUrl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdate = async (data: PayrollFormData) => {
    try {
      setError(null);
      const response = await updateMyFreelancerPayrollProfile({
        ...data,
        nickname: `${data.accountHolderFullName}'s ${data.funding} account`,
      });

      if (!response.success) {
        setError(response.error || "Failed to update payment profile");
        return;
      }

      setSuccess("Payment profile updated successfully");
      setIsUpdating(false);
    } catch (err) {
      setError("Failed to update payment profile. Please try again.");
      console.error(err);
    }
  };

  const handleStartNewTaxForm = async () => {
    if (!selectedTaxForm) {
      setError("Please select a tax form type");
      return;
    }

    setIsGeneratingTaxForm(true);
    try {
      await startNewTaxForm(selectedTaxForm);
      setSuccess(`New ${selectedTaxForm.toUpperCase()} form has been generated. Please complete it.`);
      setShowTaxFormDialog(false);
      // Redirect to the tax form signing page or refresh the page
      window.location.reload();
    } catch (err) {
      console.error("Error starting new tax form:", err);
      setError("Failed to generate new tax form. Please try again.");
    } finally {
      setIsGeneratingTaxForm(false);
    }
  };

  const handleResumeTaxForm = () => {
    if (!eversignSDKLoaded || !freelancer?.taxFormDocumentEmbedUrl) {
      return;
    }
    
    setShowTaxFormSigningDialog(true);
    // Reset the dialog ready state
    setIsDialogReady(false);
  };

  const handleTaxFormSigned = async () => {
    if (!freelancer?.taxFormDocumentHash || !freelancer?.taxFormType) {
      setTaxFormSigningError("Missing tax form information");
      return;
    }

    try {
      await saveOrUpdateTaxForm(
        freelancer.taxFormDocumentHash,
        freelancer.taxFormType
      );
      setShowTaxFormSigningDialog(false);
      window.location.reload(); // Reload to show updated state
    } catch (error) {
      console.error("Error during signing:", error);
      setTaxFormSigningError("Failed to process signed tax form");
    }
  };

  // Add a function to handle dialog open complete
  const handleDialogEntered = () => {
    // Set a small timeout to ensure the dialog is fully rendered
    setTimeout(() => {
      setIsDialogReady(true);
    }, 300);
  };

  // Update the useEffect to use isDialogReady
  useEffect(() => {
    if (
      showTaxFormSigningDialog && 
      isDialogReady &&
      eversignSDKLoaded && 
      containerRef.current && 
      freelancer?.taxFormDocumentEmbedUrl &&
      window.eversign?.open
    ) {
      // Clean up any existing iframe first
      const existingIframe = containerRef.current.querySelector("iframe");
      if (existingIframe) {
        existingIframe.remove();
      }

      console.log("Initializing eversign iframe with URL:", freelancer.taxFormDocumentEmbedUrl);
      console.log("Container ID:", containerRef.current.id);
      console.log("Container dimensions:", containerRef.current.offsetWidth, "x", containerRef.current.offsetHeight);

      // Initialize Eversign iframe to resume signing
      window.eversign.open({
        url: freelancer.taxFormDocumentEmbedUrl,
        containerID: containerRef.current.id,
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
        events: {
          loaded: () => {
            console.log("Eversign iframe loaded successfully");
          },
          signed: async () => {
            console.log("Tax form signed, processing...");
            await handleTaxFormSigned();
          },
          declined: () => {
            console.log("Tax form signing declined");
            setShowTaxFormSigningDialog(false);
          },
          error: (error: any) => {
            console.error("Eversign error:", error);
            setTaxFormSigningError(`Failed to load tax form signing interface: ${error}`);
          },
        },
      });
    }
  }, [showTaxFormSigningDialog, isDialogReady, eversignSDKLoaded, freelancer?.taxFormDocumentEmbedUrl]);

  const renderPayrollStatusCard = () => {
    if (!freelancer) return null;

    let statusInfo = {
      icon: <VerifiedIcon sx={{ mr: 1.5, color: "warning.main", mt: 0.5 }} />,
      title: "Your payment and tax information is awaiting admin approval",
      subtitle: freelancer?.paymentAndTaxProfileVerifiedByUserAt
        ? `Submitted on ${DateTime.fromISO(freelancer.paymentAndTaxProfileVerifiedByUserAt).toFormat("MMM d, yyyy")}`
        : "",
      bgColor: "rgb(255, 244, 229)",
      borderColor: "warning.light",
      textColor: "warning",
    };

    if (freelancer.readyForPayroll) {
      statusInfo = {
        icon: (
          <CheckCircleIcon sx={{ mr: 1.5, color: "success.main", mt: 0.5 }} />
        ),
        title:
          "Your payment and tax information has been approved and verified",
        subtitle: freelancer.readyForPayrollAt
          ? `Approved on ${DateTime.fromISO(freelancer.readyForPayrollAt).toFormat("MMM d, yyyy")}`
          : "",
        bgColor: "rgb(237, 247, 237)",
        borderColor: "success.light",
        textColor: "success",
      };
    } else if (
      freelancer.taxFormVerifiedByAdmin &&
      freelancer.increaseExternalAccountProfile?.testPaymentStatus
    ) {
      switch (freelancer.increaseExternalAccountProfile?.testPaymentStatus) {
        case "pending":
          statusInfo = {
            icon: (
              <PendingIcon sx={{ mr: 1.5, color: "warning.main", mt: 0.5 }} />
            ),
            title: "Bank Account Verification in Progress",
            subtitle:
              "We've initiated a small test deposit to verify your bank account",
            bgColor: "rgb(255, 244, 229)",
            borderColor: "warning.light",
            textColor: "warning",
          };
          break;
        case "completed":
          statusInfo = {
            icon: (
              <CheckCircleIcon
                sx={{ mr: 1.5, color: "success.main", mt: 0.5 }}
              />
            ),
            title: "Bank Account Verified",
            subtitle: "Your bank account has been successfully verified",
            bgColor: "rgb(237, 247, 237)",
            borderColor: "success.light",
            textColor: "success",
          };
          break;
        case "failed":
          statusInfo = {
            icon: <ErrorIcon sx={{ mr: 1.5, color: "error.main", mt: 0.5 }} />,
            title: "Bank Account Verification Failed",
            subtitle:
              "There was an issue verifying your bank account. Please contact support.",
            bgColor: "rgb(253, 237, 237)",
            borderColor: "error.light",
            textColor: "error",
          };
          break;
      }
    }

    return (
      <Paper
        elevation={0}
        sx={{
          p: 3,
          bgcolor: statusInfo.bgColor,
          border: 1,
          borderColor: statusInfo.borderColor,
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          {statusInfo.icon}
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: `${statusInfo.textColor}.dark`,
                fontWeight: 500,
                mb: 0.5,
              }}
            >
              {statusInfo.title}
            </Typography>
            {statusInfo.subtitle && (
              <Typography
                variant="body2"
                sx={{ color: `${statusInfo.textColor}.main` }}
              >
                {statusInfo.subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    );
  };

  // Function to render skeleton loading state for direct deposit info
  const renderDirectDepositSkeleton = () => (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
          Account Holder:
        </Typography>
        <Skeleton width={150} animation="wave" />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
          Account Type:
        </Typography>
        <Skeleton width={100} animation="wave" />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
          Financial Institution:
        </Typography>
        <Skeleton width={180} animation="wave" />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
          Routing Number:
        </Typography>
        <Skeleton width={120} animation="wave" />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
          Account Number:
        </Typography>
        <Skeleton width={100} animation="wave" />
      </Box>
    </Box>
  );

  if (isUpdating) {
    return (
      <FreelancerPayrollForm
        formData={formData}
        onSubmit={handleUpdate}
        error={error}
        success={success}
        isUpdate={true}
        onBack={() => {
          setIsUpdating(false);
          setError(null);
          setSuccess(null);
        }}
      />
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Payment & Tax Information
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <ArticleIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">
                {freelancer?.taxFormType === "w9"
                  ? "W9 Tax Form"
                  : "W8-BEN Tax Form"}
              </Typography>
            </Box>
            
            {freelancer?.newTaxFormRequested && (
              <Alert 
                severity="warning" 
                sx={{ mb: 2 }}
                icon={<WarningIcon />}
              >
                <AlertTitle>New Tax Form Requested</AlertTitle>
                An administrator has requested that you complete a new tax form. Please sign a new form to continue receiving payments.
                <Box sx={{ mt: 2 }}>
                  <Button 
                    variant="contained" 
                    color="warning"
                    onClick={() => setShowTaxFormDialog(true)}
                  >
                    Sign New Form
                  </Button>
                </Box>
              </Alert>
            )}
            
            {/* Show Resume Tax Form button if form is not completed but has embed URL */}
            {!freelancer?.taxFormCompleted && 
             freelancer?.taxFormDocumentEmbedUrl && 
             freelancer?.taxFormDocumentHash && (
              <Alert 
                severity="info" 
                sx={{ mb: 2 }}
              >
                <AlertTitle>Tax Form In Progress</AlertTitle>
                You have a tax form that needs to be completed. Please resume the signing process.
                <Box sx={{ mt: 2 }}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    startIcon={<PlayArrowIcon />}
                    onClick={handleResumeTaxForm}
                    disabled={!eversignSDKLoaded}
                  >
                    Resume Tax Form
                  </Button>
                </Box>
              </Alert>
            )}
            
            <Box sx={{ mb: 3 }}>
              {freelancer?.newTaxFormRequested ? (
                <>
                  <Box sx={{ p: 2, bgcolor: 'grey.100', borderRadius: 1, mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Previous Tax Form (To Be Replaced)
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <CheckCircleIcon
                        sx={{ mr: 1, color: "success.main", fontSize: 20 }}
                      />
                      <Typography variant="body2">
                        Submitted and verified by you on{" "}
                        {DateTime.fromISO(
                          freelancer?.taxFormCompletedAt || ""
                        ).toFormat("MMM d, yyyy")}
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      {freelancer?.taxFormVerifiedByAdmin ? (
                        <>
                          <CheckCircleIcon
                            sx={{ mr: 1, color: "success.main", fontSize: 20 }}
                          />
                          <Typography variant="body2">
                            Verified by admin on{" "}
                            {DateTime.fromISO(
                              freelancer.taxFormVerifiedByAdminAt || ""
                            ).toFormat("MMM d, yyyy")}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <PendingIcon
                            sx={{ mr: 1, color: "warning.main", fontSize: 20 }}
                          />
                          <Typography variant="body2" color="warning.main">
                            Pending admin verification
                          </Typography>
                        </>
                      )}
                    </Box>
                    
                    <Button
                      startIcon={<VisibilityIcon />}
                      onClick={handleViewTaxForm}
                      variant="outlined"
                      size="small"
                    >
                      View Tax Form
                    </Button>
                  </Box>
                </>
              ) : freelancer?.taxFormCompleted ? (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CheckCircleIcon
                      sx={{ mr: 1, color: "success.main", fontSize: 20 }}
                    />
                    <Typography variant="body2">
                      Submitted and verified by you on{" "}
                      {DateTime.fromISO(
                        freelancer?.taxFormCompletedAt || ""
                      ).toFormat("MMM d, yyyy")}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    {freelancer?.taxFormVerifiedByAdmin ? (
                      <>
                        <CheckCircleIcon
                          sx={{ mr: 1, color: "success.main", fontSize: 20 }}
                        />
                        <Typography variant="body2">
                          Verified by admin on{" "}
                          {DateTime.fromISO(
                            freelancer.taxFormVerifiedByAdminAt || ""
                          ).toFormat("MMM d, yyyy")}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <PendingIcon
                          sx={{ mr: 1, color: "warning.main", fontSize: 20 }}
                        />
                        <Typography variant="body2" color="warning.main">
                          Pending admin verification
                        </Typography>
                      </>
                    )}
                  </Box>
                  
                  <Button
                    startIcon={<VisibilityIcon />}
                    onClick={handleViewTaxForm}
                    variant="outlined"
                    size="small"
                  >
                    View Tax Form
                  </Button>
                </>
              ) : null}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccountBalanceIcon sx={{ mr: 1 }} color="primary" />
                <Typography variant="h6">Direct Deposit Information</Typography>
              </Box>
              <Tooltip 
                title={!freelancer?.directDepositAccountNeedsUpdate ? "Please contact an admin to update your direct deposit information" : ""}
                arrow
              >
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!freelancer?.directDepositAccountNeedsUpdate}
                    onClick={() => {
                      if (paymentProfile) {
                        setFormData({
                          accountHolderFullName:
                            paymentProfile.accountHolderFullName,
                          accountHolderType: paymentProfile.accountHolderType,
                          funding: paymentProfile.funding,
                          routingNumber: "",
                          accountNumber: "",
                        });
                        setIsUpdating(true);
                      }
                    }}
                  >
                    Update Payment Account
                  </Button>
                </span>
              </Tooltip>
            </Box>
            
            {isPaymentsLoading ? (
              renderDirectDepositSkeleton()
            ) : paymentProfile ? (
              <>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Account Holder:</strong>{" "}
                    {paymentProfile.accountHolderFullName}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Account Type:</strong> {paymentProfile.funding}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Financial Institution:</strong>{" "}
                    {
                      freelancer?.increaseExternalAccountProfile
                        ?.financialInstitution
                    }
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Routing Number:</strong>{" "}
                    {paymentProfile.routingNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Account Number:</strong> ****
                    {paymentProfile.accountNumber
                      ? paymentProfile.accountNumber.slice(-4)
                      : "****"}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Last updated:{" "}
                  {DateTime.fromISO(
                    freelancer?.payrollProfileUpdatedAt || ""
                  ).toFormat("MMM d, yyyy 'at' h:mm a")}
                </Typography>
              </>
            ) : (
              <Typography color="text.secondary">
                No payment profile found. Please complete the onboarding process.
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {renderPayrollStatusCard()}
        </Grid>
      </Grid>

      <PaymentHistoryTable
        totalCount={0}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      <ViewFileDialog
        open={viewTaxFormDialogOpen}
        onClose={handleCloseTaxFormDialog}
        fileUrl={taxFormFileUrl || ""}
        fileName={`${freelancer?.taxFormType === "w9" ? "W9" : "W8-BEN"} Form.pdf`}
        isLoading={isLoadingTaxForm}
      />

      {/* Tax Form Selection Dialog */}
      <Dialog open={showTaxFormDialog} onClose={() => setShowTaxFormDialog(false)}>
        <DialogTitle>Select Tax Form Type</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <FormLabel component="legend">
              Are you a U.S. resident for tax purposes?
            </FormLabel>
            <RadioGroup
              value={selectedTaxForm}
              onChange={(e) => setSelectedTaxForm(e.target.value as "w9" | "w8ben")}
            >
              <FormControlLabel
                value="w9"
                control={<Radio />}
                label="Yes - Complete Form W-9"
              />
              <FormControlLabel
                value="w8ben"
                control={<Radio />}
                label="No - Complete Form W-8BEN"
              />
            </RadioGroup>
          </FormControl>
          
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTaxFormDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleStartNewTaxForm} 
            variant="contained" 
            color="primary"
            disabled={!selectedTaxForm || isGeneratingTaxForm}
          >
            {isGeneratingTaxForm ? "Generating..." : "Generate Form"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Tax Form Signing Dialog */}
      <Dialog 
        open={showTaxFormSigningDialog} 
        onClose={() => setShowTaxFormSigningDialog(false)}
        maxWidth="lg"
        fullWidth
        TransitionProps={{
          onEntered: handleDialogEntered
        }}
      >
        <DialogTitle>
          Complete Your Tax Form
          <Typography variant="subtitle2" color="text.secondary">
            Please review and sign your tax form
          </Typography>
        </DialogTitle>
        <DialogContent>
          {taxFormSigningError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {taxFormSigningError}
            </Alert>
          )}
          <Box
            ref={containerRef}
            id="eversign-container"
            sx={{
              width: "100%",
              height: "70vh",
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              position: "relative",
              overflow: "hidden", // Add this to prevent scrollbars
              "& iframe": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTaxFormSigningDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FreelancerPayrollHome;
