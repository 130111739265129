import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Autocomplete,
} from '@mui/material';
import { useBlogAI, BlogContentPrompt } from 'hooks/blog/useBlogAI';
import { AICommand } from './AICommandsExtension';
import { BlogCategory } from '@freetech/models/blog';

interface AIGenerationDialogProps {
  open: boolean;
  onClose: () => void;
  onInsert: (content: string) => void;
  command: AICommand | null;
  context?: string;
  category?: BlogCategory;
}

export const AIGenerationDialog: React.FC<AIGenerationDialogProps> = ({
  open,
  onClose,
  onInsert,
  command,
  context,
  category,
}) => {
  const [topic, setTopic] = useState('');
  const [tone, setTone] = useState('professional');
  const [length, setLength] = useState<'short' | 'medium' | 'long'>('medium');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [keywordInput, setKeywordInput] = useState('');
  
  const { generateContent, cancelGeneration, isGenerating, error, generatedContent } = useBlogAI();
  
  // Reset form when dialog opens with new command
  useEffect(() => {
    if (open && command) {
      setTopic('');
      setTone('professional');
      setLength('medium');
      setKeywords([]);
      setKeywordInput('');
    }
  }, [open, command]);
  
  const handleGenerate = async () => {
    if (!command) return;
    
    const prompt: BlogContentPrompt = {
      command: command.command,
      topic: topic || undefined,
      context: context || undefined,
      category: category,
      tone: tone || undefined,
      keywords: keywords.length > 0 ? keywords : undefined,
      length: length,
    };
    
    await generateContent(prompt);
  };
  
  const handleInsert = () => {
    onInsert(generatedContent);
    onClose();
  };
  
  const handleAddKeyword = () => {
    if (keywordInput && !keywords.includes(keywordInput)) {
      setKeywords([...keywords, keywordInput]);
      setKeywordInput('');
    }
  };
  
  const handleKeywordDelete = (keywordToDelete: string) => {
    setKeywords(keywords.filter(keyword => keyword !== keywordToDelete));
  };
  
  return (
    <Dialog 
      open={open} 
      onClose={isGenerating ? undefined : onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {command?.icon} {command?.title}
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {command?.description}
          </Typography>
        </Box>
        
        <Stack spacing={3} sx={{ mb: 3 }}>
          <TextField
            label="Topic"
            fullWidth
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            placeholder="What should this content be about?"
            disabled={isGenerating}
          />
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Tone</InputLabel>
              <Select
                value={tone}
                label="Tone"
                onChange={(e) => setTone(e.target.value)}
                disabled={isGenerating}
              >
                <MenuItem value="professional">Professional</MenuItem>
                <MenuItem value="casual">Casual</MenuItem>
                <MenuItem value="technical">Technical</MenuItem>
                <MenuItem value="enthusiastic">Enthusiastic</MenuItem>
                <MenuItem value="educational">Educational</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl fullWidth>
              <InputLabel>Length</InputLabel>
              <Select
                value={length}
                label="Length"
                onChange={(e) => setLength(e.target.value as 'short' | 'medium' | 'long')}
                disabled={isGenerating}
              >
                <MenuItem value="short">Short (1-2 paragraphs)</MenuItem>
                <MenuItem value="medium">Medium (2-3 paragraphs)</MenuItem>
                <MenuItem value="long">Long (4-5 paragraphs)</MenuItem>
              </Select>
            </FormControl>
          </Box>
          
          <Box>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <TextField
                label="Keywords"
                fullWidth
                value={keywordInput}
                onChange={(e) => setKeywordInput(e.target.value)}
                placeholder="Add keywords to include"
                disabled={isGenerating}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddKeyword();
                  }
                }}
              />
              <Button 
                variant="outlined" 
                onClick={handleAddKeyword}
                disabled={!keywordInput || isGenerating}
              >
                Add
              </Button>
            </Box>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {keywords.map((keyword) => (
                <Chip
                  key={keyword}
                  label={keyword}
                  onDelete={() => handleKeywordDelete(keyword)}
                  disabled={isGenerating}
                />
              ))}
            </Box>
          </Box>
        </Stack>
        
        {error && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            Error: {error.message}
          </Typography>
        )}
        
        {isGenerating && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            <Typography variant="body2">Generating content...</Typography>
          </Box>
        )}
        
        {generatedContent && (
          <Box sx={{ 
            p: 2, 
            border: '1px solid', 
            borderColor: 'divider', 
            borderRadius: 1,
            bgcolor: 'background.paper',
            maxHeight: '300px',
            overflow: 'auto'
          }}>
            <Typography variant="body1" whiteSpace="pre-wrap">
              {generatedContent}
            </Typography>
          </Box>
        )}
      </DialogContent>
      
      <DialogActions>
        {isGenerating ? (
          <Button onClick={cancelGeneration} color="error">
            Cancel Generation
          </Button>
        ) : (
          <>
            <Button onClick={onClose} disabled={isGenerating}>
              Cancel
            </Button>
            {!generatedContent ? (
              <Button 
                onClick={handleGenerate} 
                variant="contained" 
                disabled={isGenerating}
              >
                Generate
              </Button>
            ) : (
              <>
                <Button 
                  onClick={handleGenerate} 
                  variant="outlined"
                >
                  Regenerate
                </Button>
                <Button 
                  onClick={handleInsert} 
                  variant="contained" 
                  color="success"
                >
                  Insert Content
                </Button>
              </>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}; 