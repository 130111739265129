import React, { useCallback } from 'react';
import { Editor } from '@tiptap/react';
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import {
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatStrikethrough as FormatStrikethroughIcon,
  Code as CodeIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  CheckBox as CheckBoxIcon,
  FormatQuote as FormatQuoteIcon,
  HorizontalRule as HorizontalRuleIcon,
  Link as LinkIcon,
  Image as ImageIcon,
  Title as TitleIcon,
} from '@mui/icons-material';
import { supportedLanguages } from '../extensions';

interface MenuBarProps {
  editor: Editor | null;
  includeHeadings?: boolean;
  includeImage?: boolean;
  onImageUpload?: (file: File) => Promise<string>;
}

const MenuBar: React.FC<MenuBarProps> = ({
  editor,
  includeHeadings = false,
  includeImage = false,
  onImageUpload,
}) => {
  if (!editor) {
    return null;
  }

  const setLink = useCallback(() => {
    if (!editor) return;

    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().unsetLink().run();
      return;
    }

    editor.chain().focus().setLink({ href: url }).run();
  }, [editor]);

  const handleCodeBlock = useCallback(() => {
    if (!editor) return;

    // If already in a code block, exit it
    if (editor.isActive('codeBlock')) {
      editor.chain().focus().toggleCodeBlock().run();
      return;
    }

    // Provide language options with TypeScript as default
    const language = window.prompt(
      `Enter language (${supportedLanguages.join(', ')}):`,
      'typescript'
    );

    if (!language) return;

    // Validate the language
    const validLanguage = supportedLanguages.includes(language.toLowerCase())
      ? language.toLowerCase()
      : 'typescript';

    // Clear any existing formatting before applying code block
    editor.chain()
      .focus()
      .clearNodes()
      .toggleCodeBlock({ language: validLanguage })
      .run();
  }, [editor]);

  const handleImageUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!editor || !onImageUpload) return;

      const file = event.target.files?.[0];
      if (!file) return;

      try {
        const url = await onImageUpload(file);
        editor.chain().focus().setImage({ src: url }).run();
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    },
    [editor, onImageUpload]
  );

  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      sx={{
        p: 1,
        flexWrap: 'wrap',
        gap: 0.5,
      }}
    >
      {includeHeadings && (
        <>
          <Tooltip title="Heading 1">
            <IconButton
              size="small"
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              color={editor.isActive('heading', { level: 1 }) ? 'primary' : 'default'}
            >
              <TitleIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Heading 2">
            <IconButton
              size="small"
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              color={editor.isActive('heading', { level: 2 }) ? 'primary' : 'default'}
            >
              <Box component="span" sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                H2
              </Box>
            </IconButton>
          </Tooltip>
          <Tooltip title="Heading 3">
            <IconButton
              size="small"
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              color={editor.isActive('heading', { level: 3 }) ? 'primary' : 'default'}
            >
              <Box component="span" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                H3
              </Box>
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem />
        </>
      )}

      <Tooltip title="Bold">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBold().run()}
          color={editor.isActive('bold') ? 'primary' : 'default'}
        >
          <FormatBoldIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Italic">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          color={editor.isActive('italic') ? 'primary' : 'default'}
        >
          <FormatItalicIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Strike">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          color={editor.isActive('strike') ? 'primary' : 'default'}
        >
          <FormatStrikethroughIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Code">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleCode().run()}
          color={editor.isActive('code') ? 'primary' : 'default'}
        >
          <CodeIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title="Bullet List">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          color={editor.isActive('bulletList') ? 'primary' : 'default'}
        >
          <FormatListBulletedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Numbered List">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          color={editor.isActive('orderedList') ? 'primary' : 'default'}
        >
          <FormatListNumberedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Task List">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleTaskList().run()}
          color={editor.isActive('taskList') ? 'primary' : 'default'}
        >
          <CheckBoxIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title="Blockquote">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          color={editor.isActive('blockquote') ? 'primary' : 'default'}
        >
          <FormatQuoteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Horizontal Rule">
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <HorizontalRuleIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Link">
        <IconButton
          size="small"
          onClick={setLink}
          color={editor.isActive('link') ? 'primary' : 'default'}
        >
          <LinkIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Code Block">
        <IconButton
          size="small"
          onClick={handleCodeBlock}
          color={editor.isActive('codeBlock') ? 'primary' : 'default'}
        >
          <Box component="span" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
            {'</>'}
          </Box>
        </IconButton>
      </Tooltip>
      {includeImage && onImageUpload && (
        <Tooltip title="Image">
          <IconButton size="small" component="label">
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
            <ImageIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default MenuBar; 