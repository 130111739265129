import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';

interface EstimationFieldsProps {
  estimatedStartDate: string | undefined;
  estimatedCompletionDate: string | undefined;
  estimatedHours: number | undefined;
  estimatedDollarAmount: number | undefined;
  onUpdate: (field: string, value: any) => void;
  disabled?: boolean;
}

const EstimationFields: React.FC<EstimationFieldsProps> = ({
  estimatedStartDate,
  estimatedCompletionDate,
  estimatedHours,
  estimatedDollarAmount,
  onUpdate,
  disabled = false,
}) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        Estimation Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Estimated Start Date"
            value={estimatedStartDate ? DateTime.fromISO(estimatedStartDate) : null}
            onChange={(newValue) => {
              onUpdate('estimatedStartDate', newValue?.toISO() || null);
            }}
            disabled={disabled}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Estimated Completion Date"
            value={estimatedCompletionDate ? DateTime.fromISO(estimatedCompletionDate) : null}
            onChange={(newValue) => {
              onUpdate('estimatedCompletionDate', newValue?.toISO() || null);
            }}
            disabled={disabled}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Estimated Hours"
            type="number"
            value={estimatedHours || ''}
            onChange={(e) => onUpdate('estimatedHours', Number(e.target.value))}
            disabled={disabled}
            size="small"
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Estimated Cost ($)"
            type="number"
            value={estimatedDollarAmount || ''}
            onChange={(e) => onUpdate('estimatedDollarAmount', Number(e.target.value))}
            disabled={disabled}
            size="small"
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EstimationFields; 