import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "config/firebase";
import { IndependentEngineerAgreement } from "@freetech/models/user";

export const getIndependentEngineerAgreements = async (freelancerId: string) => {
  const agreementsRef = collection(
    db,
    "users",
    freelancerId,
    "independentEngineerAgreements"
  );

  const agreementsQuery = query(
    agreementsRef,
    where("status", "==", "signing"),
    where("everSignDocumentStatus", "in", [
      "awaiting_engineer_signature",
      "awaiting_admin_signature",
    ])
  );

  const snapshot = await getDocs(agreementsQuery);
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as IndependentEngineerAgreement[];
}; 