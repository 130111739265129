import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Grid,
  Divider,
  Stepper,
  Step,
  StepLabel,
  InputAdornment,
  Tooltip,
  Card,
  CardContent,
  Icon,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { Client, PROJECT_TYPES } from "@freetech/models/projects";
import { portalFunctions } from "core/functions/portalFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { InProgressIdeas } from "./components/InProgressIdeas";
import { useProjectIdeaPage } from "hooks/stakeholder/useProjectIdeaPage";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatIcon from "@mui/icons-material/Chat";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EventNoteIcon from "@mui/icons-material/EventNote";

/**
 * Component for the initial project setup page
 * This page allows stakeholders to select a client, enter a project name,
 * and select a project category before starting the AI-assisted conversation
 */
const NewProjectSetup: React.FC = () => {
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const { clients, clientsIsLoading } = useStakeholder(userInfo?.id);
  const {
    inProgressIdeas,
    handleDeleteProjectIdea: deleteProjectIdea,
    isDeleting,
  } = useProjectIdeaPage();

  // Basic project information
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [projectType, setProjectType] = useState<string>("");
  const [projectDescription, setProjectDescription] = useState<string>("");

  // Additional project details
  const [budget, setBudget] = useState<string>("");
  const [timeline, setTimeline] = useState<string>("");
  const [expectedStartDate, setExpectedStartDate] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeletingIdea, setIsDeletingIdea] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<{
    client?: string;
    projectName?: string;
    projectType?: string;
    projectDescription?: string;
    budget?: string;
    timeline?: string;
    expectedStartDate?: string;
  }>({});

  // If there's only one client, auto-select it
  useEffect(() => {
    if (clients?.length === 1) {
      setSelectedClient(clients[0].id);
    }
  }, [clients]);

  // Handle continuing an in-progress idea
  const handleContinueIdea = (ideaId: string) => {
    navigate(`/stakeholder/projects/new-idea/${ideaId}`);
  };

  // Handle deleting an in-progress idea
  const handleDeleteIdea = async (clientId: string, ideaId: string) => {
    setIsDeletingIdea(true);
    try {
      await deleteProjectIdea(clientId, ideaId);
    } catch (error) {
      console.error("Error deleting project idea:", error);
      alert("Failed to delete project idea. Please try again.");
    } finally {
      setIsDeletingIdea(false);
    }
  };

  if (clientsIsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Validate the form
  const validateForm = () => {
    const errors: {
      client?: string;
      projectName?: string;
      projectType?: string;
      projectDescription?: string;
      budget?: string;
      timeline?: string;
      expectedStartDate?: string;
    } = {};

    if (!selectedClient) {
      errors.client = "Please select a client";
    }

    if (!projectName.trim()) {
      errors.projectName = "Please enter a project name";
    }

    if (!projectType) {
      errors.projectType = "Please select a project category";
    }

    if (!projectDescription.trim()) {
      errors.projectDescription = "Please enter a brief description";
    }

    if (!budget) {
      errors.budget = "Please select a budget range";
    }

    if (!timeline.trim()) {
      errors.timeline = "Please enter timeline expectations";
    }

    if (!expectedStartDate) {
      errors.expectedStartDate = "Please select an expected start date";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Budget options
  const BUDGET_OPTIONS = [
    { value: "less_than_10k", label: "Less than $10,000", numericValue: 10000 },
    { value: "10k_to_25k", label: "$10,000 - $25,000", numericValue: 25000 },
    { value: "25k_to_100k", label: "$25,000 - $100,000", numericValue: 100000 },
    { value: "more_than_100k", label: "$100,000+", numericValue: 150000 },
  ];

  // Get numeric budget value from selected option
  const getBudgetNumericValue = (budgetValue: string): number | undefined => {
    if (!budgetValue) return undefined;
    const option = BUDGET_OPTIONS.find(opt => opt.value === budgetValue);
    return option?.numericValue;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Find selected client details
      const client = clients?.find((c) => c.id === selectedClient);

      if (!client) {
        throw new Error("Selected client not found");
      }

      // Initialize the project idea with the form data
      const response = await portalFunctions.stakeholder.initializeProjectIdea({
        clientId: selectedClient,
        projectName: projectName,
        projectType: projectType,
        projectDescription: projectDescription,
        budget: getBudgetNumericValue(budget),
        timeline: timeline,
        expectedStartDate: expectedStartDate,
      });

      // Check if we have a projectIdeaId
      if (response.success && response.projectIdeaId) {
        // Navigate to the chat interface with the projectIdeaId
        navigate(`/stakeholder/projects/new-idea/${response.projectIdeaId}`);
      } else {
        throw new Error(
          "Failed to initialize project idea: No project ID returned"
        );
      }
    } catch (error) {
      console.error("Error starting project:", error);
      alert("An error occurred while starting the project. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Process steps
  const steps = [
    { label: "Create Project", icon: <AssignmentIcon /> },
    { label: "AI Conversation", icon: <ChatIcon /> },
    { label: "Auto-Generate Plan", icon: <AutoAwesomeIcon /> },
    { label: "Review & Finalize", icon: <EventNoteIcon /> },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 5 }}>
        {/* Display in-progress ideas */}
        {inProgressIdeas && inProgressIdeas.length > 0 && (
          <Box sx={{ mb: 5 }}>
            <InProgressIdeas
              ideas={inProgressIdeas}
              onContinueIdea={handleContinueIdea}
              onDeleteIdea={handleDeleteIdea}
              isDeleting={isDeletingIdea}
            />
            <Divider sx={{ my: 4 }} />
          </Box>
        )}

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2, mb: 4 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ mb: 2 }}>
              Start New Project Idea
            </Typography>

            <Typography
              variant="body1"
              align="center"
              sx={{ mb: 4, color: "text.secondary" }}
            >
              Let's collect some initial information about your project. After
              submitting, you'll be guided through an AI-assisted conversation
              to refine your requirements.
            </Typography>

            {/* Project Creation Process Stepper */}
            <Stepper activeStep={0} alternativeLabel sx={{ mb: 5 }}>
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel
                    StepIconComponent={() => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {step.icon}
                      </Box>
                    )}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* Project Creation Process Cards */}
            <Grid container spacing={3} sx={{ mb: 5 }}>
              <Grid item xs={12} md={6}>
                <Card elevation={1} sx={{ height: "100%" }}>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <ChatIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">AI-Assisted Process</Typography>
                    </Box>
                    <Typography variant="body2">
                      After submitting this form, our AI assistant Jane will
                      guide you through a conversation to understand your
                      project requirements in detail. This helps us create a
                      comprehensive project plan tailored to your needs.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card elevation={1} sx={{ height: "100%" }}>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <AutoAwesomeIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">
                        Smart Project Planning
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      Based on your inputs, our AI will automatically generate a
                      detailed project plan including timeline estimates,
                      resource requirements, and technical considerations.
                      You'll have the opportunity to review and refine this
                      plan.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Divider sx={{ mb: 4 }} />

            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    General Project Information
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth required error={!!formErrors.client}>
                    <InputLabel id="client-select-label">Client</InputLabel>
                    <Select
                      labelId="client-select-label"
                      id="client-select"
                      value={selectedClient}
                      label="Client"
                      onChange={(e) => setSelectedClient(e.target.value)}
                      disabled={isSubmitting || clients?.length === 1}
                    >
                      {clients?.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.client && (
                      <FormHelperText>{formErrors.client}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Project Name"
                    variant="outlined"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    error={!!formErrors.projectName}
                    helperText={formErrors.projectName}
                    disabled={isSubmitting}
                    placeholder="Enter a descriptive name for your project"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth required error={!!formErrors.projectType}>
                    <InputLabel id="project-type-label">
                      Project Category
                    </InputLabel>
                    <Select
                      labelId="project-type-label"
                      id="project-type"
                      value={projectType}
                      label="Project Category"
                      onChange={(e) => setProjectType(e.target.value)}
                      disabled={isSubmitting}
                    >
                      {PROJECT_TYPES.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.projectType && (
                      <FormHelperText>{formErrors.projectType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Brief Description"
                    variant="outlined"
                    value={projectDescription}
                    onChange={(e) => setProjectDescription(e.target.value)}
                    error={!!formErrors.projectDescription}
                    helperText={formErrors.projectDescription}
                    disabled={isSubmitting}
                    placeholder="Briefly describe what you want to achieve with this project"
                    multiline
                    rows={3}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required error={!!formErrors.budget}>
                    <InputLabel id="budget-select-label">Budget Range</InputLabel>
                    <Select
                      labelId="budget-select-label"
                      id="budget-select"
                      value={budget}
                      label="Budget Range"
                      onChange={(e) => setBudget(e.target.value)}
                      disabled={isSubmitting}
                    >
                      <MenuItem value="">
                        <em>Select a budget range</em>
                      </MenuItem>
                      {BUDGET_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.budget && (
                      <FormHelperText>{formErrors.budget}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    label="Expected Start Date"
                    variant="outlined"
                    type="date"
                    value={expectedStartDate}
                    onChange={(e) => setExpectedStartDate(e.target.value)}
                    disabled={isSubmitting}
                    error={!!formErrors.expectedStartDate}
                    helperText={formErrors.expectedStartDate}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Timeline Expectations"
                    variant="outlined"
                    value={timeline}
                    onChange={(e) => setTimeline(e.target.value)}
                    disabled={isSubmitting}
                    error={!!formErrors.timeline}
                    helperText={formErrors.timeline || "e.g., 3 months, Q4 2023, etc."}
                    placeholder="e.g., 3 months, Q4 2023, etc."
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={isSubmitting}
                      sx={{
                        minWidth: 240,
                        py: 1.5,
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </motion.div>
      </Box>
    </Container>
  );
};

export default NewProjectSetup;
