import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { ClientInvoiceData } from "../bank";

export const invoiceConverter: FirestoreDataConverter<ClientInvoiceData> = {
  toFirestore(invoice: WithFieldValue<ClientInvoiceData>): DocumentData {
    const { id, ...data } = invoice;
    return data as DocumentData;
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): ClientInvoiceData {
    const data = snapshot.data(options);
    if (!data) {
      throw new Error("Invoice data is undefined");
    }
    return {
      id: snapshot.id,
      clientId: data.clientId,
      projectId: data.projectId,
      status: data.status,
      dueDate: data.dueDate,
      csaId: data.csaId,
      invoiceNumber: data.invoiceNumber,
      invoiceDate: data.invoiceDate,
      billTo: data.billTo,
      shipTo: data.shipTo,
      items: data.items,
      terms: data.terms,
      discount: data.discount,
      totalDollarAmountDue: data.totalDollarAmountDue,
      pdfUrl: data.pdfUrl,
      stakeholderPaymentIds: data.stakeholderPaymentIds,
      cardPaymentsAllowed: data.cardPaymentsAllowed,
      cardPaymentFeeWaived: data.cardPaymentFeeWaived,
      partialPaymentAmount: data.partialPaymentAmount,
    };
  },
};
