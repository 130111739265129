import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { db, storage } from "config/firebase";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";
import {
  ConsultingServicesAgreement,
  BillableRate,
  CSAStatus,
  CSAType,
} from "@freetech/models/projects";
import { useAuth } from "hooks/auth/useAuth";

// Extended CSA type with additional fields for UI display
export type EnrichedCSA = ConsultingServicesAgreement & {
  clientName?: string;
  projectName?: string;
  createdAt?: any;
  createdBy?: string;
  updatedAt?: any;
  updatedBy?: string;
};

// Type for creating a new CSA
export interface CreateCSARequest {
  clientId: string;
  projectId: string;
  name: string;
  effectiveDate: string;
  endDate?: string | null;
  csaType: CSAType;
  status: CSAStatus;
  monthlyBudget?: number;
  fixedPriceBudget?: number;
  billableRates: BillableRate[];
  file?: File | null;
  scopeOfWork?: string | string[];
  draftStoragePath?: string;
  finalStoragePath?: string;
  stakeholderId?: string;
  stakeholderName?: string;
  stakeholderEmail?: string;
}

// Type for updating an existing CSA
export interface UpdateCSARequest {
  id: string;
  name?: string;
  effectiveDate?: string;
  endDate?: string | null;
  csaType?: CSAType;
  status?: CSAStatus;
  monthlyBudget?: number;
  fixedPriceBudget?: number;
  billableRates?: BillableRate[];
  file?: File | null;
  draftStoragePath?: string;
  finalStoragePath?: string;
  needsApproval?: boolean;
}

const fetchProjectName = async (clientId: string, projectId: string): Promise<string> => {
  try {
    const projectDoc = await getDoc(doc(db, "clients", clientId, "projects", projectId));
    return projectDoc.exists() ? projectDoc.data().name : "Unknown Project";
  } catch (error) {
    return "Unknown Project";
  }
};

export const useAdminCSAs = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();

  // Fetch all CSAs with client and project details
  const {
    data: csas,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["admin", "csas"],
    queryFn: async () => {
      // Get all clients first
      const clientsQuery = query(collection(db, "clients"));
      const clientsSnapshot = await getDocs(clientsQuery);
      
      // For each client, get their CSAs
      const allCSAPromises: Promise<EnrichedCSA[]>[] = clientsSnapshot.docs.map(async (clientDoc) => {
        const clientId = clientDoc.id;
        const clientName = clientDoc.data().companyName || "Unknown Client";
        
        // Get CSAs for this client
        const csasQuery = query(collection(db, `clients/${clientId}/consultingServicesAgreements`));
        const csasSnapshot = await getDocs(csasQuery);
        
        // Process each CSA
        const clientCSAs = csasSnapshot.docs.map(async (csaDoc) => {
          const csaData = csaDoc.data() as ConsultingServicesAgreement;
          const projectName = await fetchProjectName(csaData.clientId, csaData.projectId);
          
          return {
            ...csaData,
            id: csaDoc.id,
            clientId,
            clientName,
            projectName,
          } as EnrichedCSA;
        });
        
        return Promise.all(clientCSAs);
      });
      
      // Flatten the array of arrays into a single array of CSAs
      const allCSAsArrays = await Promise.all(allCSAPromises);
      const allCSAs = allCSAsArrays.flat();
      
      return allCSAs;
    },
    enabled: !!userInfo,
  });

  // Create a new CSA
  const createCSA = useMutation({
    mutationFn: async (data: CreateCSARequest) => {
      const { file, ...csaData } = data;
      
      // First, create the CSA document in Firestore under the client
      const csaRef = await addDoc(
        collection(db, `clients/${csaData.clientId}/consultingServicesAgreements`),
        {
          ...csaData,
          status: "draft", // Set initial status to draft
          needsApproval: true, // Set needsApproval to true by default
          createdAt: Timestamp.now(),
          createdBy: userInfo?.id,
        }
      );
      
      const csaId = csaRef.id;
      let fileUrl = undefined;
      let draftStoragePath = undefined;

      // Upload file to drafts folder if provided
      if (file) {
        const timestamp = Date.now();
        draftStoragePath = `clients/${csaData.clientId}/consultingServicesAgreements/drafts/${csaId}_${timestamp}.pdf`;
        const storageRef = ref(storage, draftStoragePath);
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
        
        // Update the CSA document with the file URL
        await updateDoc(csaRef, {
          fileUrl,
          draftStoragePath,
        });
      }

      return { 
        id: csaId, 
        ...csaData, 
        status: "draft", // Ensure the returned object has the draft status
        needsApproval: true,
        fileUrl,
        draftStoragePath
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "csas"] });
      toast.success("Consulting Services Agreement created successfully");
    },
    onError: (error) => {
      toast.error(
        `Failed to create CSA: ${error instanceof Error ? error.message : "Unknown error"}`
      );
    },
  });

  // Update an existing CSA
  const updateCSA = useMutation({
    mutationFn: async (data: UpdateCSARequest) => {
      const { id, file, ...updateData } = data;
      let fileUrl = undefined;
      let draftStoragePath = undefined;
      let finalStoragePath = undefined;

      // Get the current CSA data - first we need to find which client it belongs to
      // We'll search through all clients
      const clientsQuery = query(collection(db, "clients"));
      const clientsSnapshot = await getDocs(clientsQuery);
      
      let csaRef = null;
      let csaDoc = null;
      let clientId = null;
      
      // Search for the CSA in each client's collection
      for (const clientDoc of clientsSnapshot.docs) {
        const tempClientId = clientDoc.id;
        const tempCsaRef = doc(db, `clients/${tempClientId}/consultingServicesAgreements`, id);
        const tempCsaDoc = await getDoc(tempCsaRef);
        
        if (tempCsaDoc.exists()) {
          csaRef = tempCsaRef;
          csaDoc = tempCsaDoc;
          clientId = tempClientId;
          break;
        }
      }
      
      if (!csaDoc || !csaRef || !clientId) {
        throw new Error("CSA not found");
      }
      
      const csaData = csaDoc.data() as ConsultingServicesAgreement;

      // Upload new file if provided
      if (file) {
        const timestamp = Date.now();
        
        // Determine if this is a draft or final version based on status
        if (updateData.status === "draft" || updateData.status === "active" || !updateData.status) {
          // This is a draft version
          draftStoragePath = `clients/${clientId}/consultingServicesAgreements/drafts/${id}_${timestamp}.pdf`;
          const storageRef = ref(storage, draftStoragePath);
          await uploadBytes(storageRef, file);
          fileUrl = await getDownloadURL(storageRef);
        } else if (updateData.status === "completed") {
          // This is a final version
          finalStoragePath = `clients/${clientId}/consultingServicesAgreements/${id}.pdf`;
          const storageRef = ref(storage, finalStoragePath);
          await uploadBytes(storageRef, file);
          fileUrl = await getDownloadURL(storageRef);
        }
      }

      // Update CSA document
      await updateDoc(csaRef, {
        ...updateData,
        ...(fileUrl && { fileUrl }),
        ...(draftStoragePath && { draftStoragePath }),
        ...(finalStoragePath && { finalStoragePath }),
        updatedAt: Timestamp.now(),
        updatedBy: userInfo?.id,
      });

      return { 
        id, 
        ...updateData, 
        ...(fileUrl && { fileUrl }),
        ...(draftStoragePath && { draftStoragePath }),
        ...(finalStoragePath && { finalStoragePath })
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "csas"] });
      toast.success("Consulting Services Agreement updated successfully");
    },
    onError: (error) => {
      toast.error(
        `Failed to update CSA: ${error instanceof Error ? error.message : "Unknown error"}`
      );
    },
  });

  // Delete a CSA
  const deleteCSA = useMutation({
    mutationFn: async (id: string) => {
      // First, find which client the CSA belongs to
      const clientsQuery = query(collection(db, "clients"));
      const clientsSnapshot = await getDocs(clientsQuery);
      
      let csaRef = null;
      let clientId = null;
      
      // Search for the CSA in each client's collection
      for (const clientDoc of clientsSnapshot.docs) {
        const tempClientId = clientDoc.id;
        const tempCsaRef = doc(db, `clients/${tempClientId}/consultingServicesAgreements`, id);
        const tempCsaDoc = await getDoc(tempCsaRef);
        
        if (tempCsaDoc.exists()) {
          csaRef = tempCsaRef;
          clientId = tempClientId;
          break;
        }
      }
      
      if (!csaRef || !clientId) {
        throw new Error("CSA not found");
      }
      
      await deleteDoc(csaRef);
      return id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin", "csas"] });
      toast.success("Consulting Services Agreement deleted successfully");
    },
    onError: (error) => {
      toast.error(
        `Failed to delete CSA: ${error instanceof Error ? error.message : "Unknown error"}`
      );
    },
  });

  // Get CSAs for a specific client
  const getClientCSAs = async (clientId: string): Promise<EnrichedCSA[]> => {
    const csaQuery = query(
      collection(db, `clients/${clientId}/consultingServicesAgreements`)
    );

    const csaSnapshot = await getDocs(csaQuery);
    const clientDoc = await getDoc(doc(db, "clients", clientId));
    const clientName = clientDoc.exists() ? clientDoc.data().companyName : "Unknown Client";

    const csaPromises = csaSnapshot.docs.map(async (csaDoc) => {
      const csaData = csaDoc.data() as ConsultingServicesAgreement;

      // Get project name
      let projectName;
      try {
        const projectDoc = await getDoc(doc(db, "projects", csaData.projectId));
        projectName = projectDoc.exists()
          ? projectDoc.data().name
          : "Unknown Project";
      } catch (error) {
        projectName = "Unknown Project";
      }

      return {
        ...csaData,
        id: csaDoc.id,
        clientId,
        clientName,
        projectName,
      } as EnrichedCSA;
    });

    return Promise.all(csaPromises);
  };

  // Get CSAs for a specific project
  const getProjectCSAs = async (projectId: string): Promise<EnrichedCSA[]> => {
    // We need to search through all clients to find CSAs for this project
    const clientsQuery = query(collection(db, "clients"));
    const clientsSnapshot = await getDocs(clientsQuery);
    
    const allCSAPromises: Promise<EnrichedCSA[]>[] = clientsSnapshot.docs.map(async (clientDoc) => {
      const clientId = clientDoc.id;
      const clientName = clientDoc.data().companyName || "Unknown Client";
      
      // Query CSAs for this client that match the project ID
      const csasQuery = query(
        collection(db, `clients/${clientId}/consultingServicesAgreements`),
        where("projectId", "==", projectId)
      );
      
      const csasSnapshot = await getDocs(csasQuery);
      
      // Process each matching CSA
      const projectCSAs = csasSnapshot.docs.map((csaDoc) => {
        const csaData = csaDoc.data() as ConsultingServicesAgreement;
        
        return {
          ...csaData,
          id: csaDoc.id,
          clientId,
          clientName,
        } as EnrichedCSA;
      });
      
      return projectCSAs;
    });
    
    // Flatten the array of arrays into a single array of CSAs
    const allCSAsArrays = await Promise.all(allCSAPromises);
    const projectCSAs = allCSAsArrays.flat();
    
    return projectCSAs;
  };

  // Generate a CSA PDF from the template
  const generateCSAPdf = useMutation({
    mutationFn: async (data: {
      clientName: string;
      projectName?: string;
      effectiveDate: string;
      endDate?: string;
      monthlyBudget: string;
      scopeOfWork: string[];
      billableRates: BillableRate[];
      stakeholderName?: string;
      stakeholderEmail?: string;
    }): Promise<Blob> => {
      try {
        // Get the template HTML
        const templateRef = ref(storage, "admin/csa_template.html");
        const templateUrl = await getDownloadURL(templateRef);
        const templateResponse = await fetch(templateUrl);
        let htmlTemplate = await templateResponse.text();
        
        // Create a mapping of template fields to their values
        const fieldMappings: Record<string, string> = {
          "{{CLIENT_NAME}}": data.clientName || "",
          "{{PROJECT_NAME}}": data.projectName || "",
          "{{EFFECTIVE_DATE}}": data.effectiveDate || "",
          "{{END_DATE}}": data.endDate || "",
          "{{MONTHLY_BUDGET}}": data.monthlyBudget || "",
          "{{CURRENT_DATE}}": new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
          "{{LEGAL_OWNER_NAME}}": data.stakeholderName || "",
          "{{LEGAL_OWNER_EMAIL}}": data.stakeholderEmail || ""
        };
        
        // Replace template fields with their values
        Object.entries(fieldMappings).forEach(([placeholder, value]) => {
          // Replace both the styled span version and the raw text version
          const spanRegex = new RegExp(`<span[^>]*class="template-field"[^>]*>${placeholder}<\/span>`, 'g');
          htmlTemplate = htmlTemplate.replace(spanRegex, value);
          
          // Also replace the raw placeholder text
          htmlTemplate = htmlTemplate.replace(new RegExp(placeholder, 'g'), value);
        });
        
        // Also handle legacy placeholders for backward compatibility
        htmlTemplate = htmlTemplate.replace(
          /<b>________________________________<\/b>/,
          `<b>${data.clientName}</b>`
        );
        
        htmlTemplate = htmlTemplate.replace(
          /_______________________/,
          data.effectiveDate
        );
        
        htmlTemplate = htmlTemplate.replace(
          /<b><i>_______ <\/i><\/b>/,
          `<b><i>${data.monthlyBudget} </i></b>`
        );
        
        htmlTemplate = htmlTemplate.replace(
          /\$________/,
          `$${data.monthlyBudget}`
        );
        
        // Handle scope of work - special case
        if (data.scopeOfWork && data.scopeOfWork.length > 0) {
          // Create properly formatted list items
          const formattedItems = data.scopeOfWork
            .filter(item => item.trim() !== '')
            .map((item: string) => 
              `<li class="MsoNormal" style="margin-top:0in;margin-bottom:10.0pt;line-height:200%;display:list-item;"><span lang="EN" style="font-family:&quot;Times New Roman&quot;,serif">${item.trim()}</span></li>`
            ).join('\n');
          
          // Find the scope of work placeholder list
          const scopePlaceholderRegex = /<ol[^>]*>[\s\S]*?<li[^>]*>[\s\S]*?&nbsp;[\s\S]*?<\/li>[\s\S]*?<\/ol>/;
          const scopePlaceholder = htmlTemplate.match(scopePlaceholderRegex);
          
          if (scopePlaceholder) {
            // Replace the placeholder with our formatted items
            const listStart = '<ol style="margin-top:0in;margin-bottom:0in" start=1 type=1>';
            const listEnd = '</ol>';
            htmlTemplate = htmlTemplate.replace(
              scopePlaceholderRegex,
              `${listStart}\n${formattedItems}\n${listEnd}`
            );
          } else {
            // Fallback: try to find a simpler placeholder
            htmlTemplate = htmlTemplate.replace(
              /<li class="MsoNormal"[^>]*><span[^>]*>&nbsp;<\/span><\/li>/,
              formattedItems
            );
          }
          
          // Also replace any individual scope item placeholders
          // We'll only do this for the first few items to avoid excessive replacements
          data.scopeOfWork.slice(0, 5).forEach((item, index) => {
            const scopeItemPlaceholder = `{{SCOPE_ITEM_${index + 1}}}`;
            const spanRegex = new RegExp(`<span[^>]*class="template-field"[^>]*>${scopeItemPlaceholder}<\/span>`, 'g');
            htmlTemplate = htmlTemplate.replace(spanRegex, item.trim());
            htmlTemplate = htmlTemplate.replace(new RegExp(scopeItemPlaceholder, 'g'), item.trim());
          });
          
          // Replace generic scope item placeholder with the first item if it exists
          if (data.scopeOfWork.length > 0) {
            const genericScopeRegex = new RegExp(`<span[^>]*class="template-field"[^>]*>{{SCOPE_ITEM}}<\/span>`, 'g');
            htmlTemplate = htmlTemplate.replace(genericScopeRegex, data.scopeOfWork[0].trim());
            htmlTemplate = htmlTemplate.replace(/{{SCOPE_ITEM}}/g, data.scopeOfWork[0].trim());
          }
        }
        
        // Handle billable rates table - special case
        if (data.billableRates && data.billableRates.length > 0) {
          // Find the table that contains the billable rates
          const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/;
          const tableMatch = htmlTemplate.match(tableRegex);
          
          if (tableMatch) {
            const originalTable = tableMatch[0];
            
            // Extract the table header row
            const headerRowRegex = /<tr[^>]*>[\s\S]*?<td[^>]*>[\s\S]*?Contractor[\s\S]*?<\/td>[\s\S]*?<\/tr>/;
            const headerRowMatch = originalTable.match(headerRowRegex);
            
            if (headerRowMatch) {
              const headerRow = headerRowMatch[0];
              
              // Create new rows for each billable rate
              const newRows = data.billableRates.map(rate => {
                return `
                <tr>
                  <td width=182 valign=top style="width:136.5pt;border:solid black 1.0pt;border-top:none;padding:0in 5.75pt 0in 5.75pt">
                    <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:0in">
                      <span lang="EN" style="font-family:&quot;Times New Roman&quot;,serif">Contractor</span>
                    </p>
                  </td>
                  <td width=236 valign=top style="width:177.0pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0in 5.75pt 0in 5.75pt">
                    <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:0in">
                      <span lang="EN" style="font-family:&quot;Times New Roman&quot;,serif">${rate.role}</span>
                    </p>
                  </td>
                  <td width=207 valign=top style="width:155.05pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0in 5.75pt 0in 5.75pt">
                    <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:0in">
                      <span lang="EN" style="font-family:&quot;Times New Roman&quot;,serif">$${rate.rate}/hr</span>
                    </p>
                  </td>
                </tr>`;
              }).join('');
              
              // Create a new table with the header and new rows
              const tableStartTagMatch = originalTable.match(/<table[^>]*>/);
              const tableStartTag = tableStartTagMatch ? tableStartTagMatch[0] : '<table>';
              const tableEndTag = '</table>';
              
              const newTable = `${tableStartTag}${headerRow}${newRows}${tableEndTag}`;
              
              // Replace the original table with the new table
              htmlTemplate = htmlTemplate.replace(tableRegex, newTable);
            }
          }
        }
        
        // Add legal owner information to the notices section
        // Replace client attention placeholder with legal owner name
        htmlTemplate = htmlTemplate.replace(
          /Attention:\s*________________________________/,
          `Attention: ${data.stakeholderName || ""}`
        );
        
        // Replace client email placeholder with legal owner email
        const emailPlaceholderRegex = /<p class=MsoNormal style='margin-bottom:10\.0pt;line-height:200%'><span lang=EN\s*style='font-family:"Times New Roman",serif'>________________________________<\/span><\/p>/;
        const emailMatch = htmlTemplate.match(emailPlaceholderRegex);
        
        if (emailMatch && data.stakeholderEmail) {
          htmlTemplate = htmlTemplate.replace(
            emailPlaceholderRegex,
            `<p class=MsoNormal style='margin-bottom:10.0pt;line-height:200%'><span lang=EN style='font-family:"Times New Roman",serif'>${data.stakeholderEmail}</span></p>`
          );
        }
        
        // Convert HTML to PDF
        const blob = await htmlToPdf(htmlTemplate);
        return blob;
      } catch (error) {
        console.error("Error generating CSA PDF:", error);
        throw new Error("Failed to generate CSA PDF");
      }
    }
  });

  // Helper function to convert HTML to PDF
  const htmlToPdf = async (html: string): Promise<Blob> => {
    try {
      // Create a temporary container to render the HTML
      const container = document.createElement('div');
      container.innerHTML = html;
      container.style.position = 'absolute';
      container.style.left = '-9999px';
      container.style.top = '-9999px';
      container.style.width = '800px'; // Set a fixed width for better rendering
      
      // Add custom styles for better PDF formatting
      const style = document.createElement('style');
      style.textContent = `
        body {
          font-family: "Times New Roman", Times, serif;
          margin: 0;
          padding: 0;
          line-height: 1.5;
        }
        .WordSection1 {
          width: 100%;
          max-width: 800px;
          margin: 0 auto;
          padding: 20px 40px;
        }
        h1, h2, h3, p.MsoNormal[align=center] {
          text-align: center;
        }
        h1 {
          font-size: 18pt;
          font-weight: bold;
          margin-bottom: 20px;
        }
        h2, b {
          font-weight: bold;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 15px 0;
        }
        table, th, td {
          border: 1px solid black;
        }
        th {
          background-color: #f2f2f2;
          font-weight: bold;
          text-align: center;
          padding: 8px;
        }
        td {
          padding: 8px;
        }
        ol, ul {
          margin-left: 20px;
          padding-left: 20px;
        }
        li {
          margin-bottom: 8px;
        }
        ol[type="A"] {
          list-style-type: upper-alpha;
        }
        ol[type="1"] {
          list-style-type: decimal;
        }
        ol[type="a"] {
          list-style-type: lower-alpha;
        }
      `;
      container.appendChild(style);
      
      document.body.appendChild(container);
      
      // Use jspdf-html2canvas to convert HTML to PDF
      const jspdfHtml2canvas = await import('jspdf-html2canvas');
      
      const pdfDoc = await jspdfHtml2canvas.default(container, {
        jsPDF: {
          format: 'a4',
          orientation: 'portrait',
          unit: 'mm',
        },
        html2canvas: {
          scale: 2, // Higher scale for better quality
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
        imageType: 'image/jpeg',
        output: 'jspdf', // Return jsPDF instance instead of blob
        margin: {
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        },
      });
      
      // Clean up
      document.body.removeChild(container);
      
      // Convert jsPDF to blob
      const pdfBlob = pdfDoc.output('blob');
      return pdfBlob;
    } catch (error) {
      console.error('Error converting HTML to PDF:', error);
      throw new Error('Failed to convert HTML to PDF');
    }
  };

  return {
    csas,
    isLoading,
    error,
    refetch,
    createCSA,
    updateCSA,
    deleteCSA,
    getClientCSAs,
    getProjectCSAs,
    generateCSAPdf,
  };
};

// Export the hook as the default export
export default useAdminCSAs;
