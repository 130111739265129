import { useMutation, useQueryClient } from "@tanstack/react-query";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useAuth } from "hooks/auth/useAuth";
import { PrivateUserInfoType, Student } from "@freetech/models/user";

interface OnboardingStep {
  step: number;
  data: any;
}

/**
 * Hook for handling student onboarding process
 * Provides functionality to update student profile and mark onboarding as complete
 */
export const useStudentOnboarding = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  /**
   * Updates student profile with step-specific data
   */
  const updateProfile = async ({ step, data }: OnboardingStep) => {
    if (!userInfo?.id) {
      throw new Error("No authenticated user");
    }

    const userRef = doc(db, "users", userInfo.id);

    const updates: Partial<Student> = {
      updatedAt: new Date().toISOString(),
    };

    // Add step-specific updates
    switch (step) {
      case 0: // Personal Information
        updates.phone = data.phoneNumber;
        if (data.address) {
          updates.address = {
            formattedAddress: data.address,
            street: data.address,
            city: "",
            state: "",
            zipCode: "",
            country: ""
          };
        }
        break;
      case 1: // Educational Background
        updates.education = [{
          school: data.school,
          degree: data.degree,
          graduationYear: data.graduationYear,
          skills: data.skills
        }];
        break;
      case 2: // Membership - Final Step
        updates.needsOnboarding = false;
        updates.onboardingCompletedAt = new Date().toISOString();
        break;
    }

    await updateDoc(userRef, updates);
    return updates;
  };

  /**
   * Marks student onboarding as complete in one operation
   */
  const completeStudentOnboarding = async () => {
    if (!userInfo?.id) {
      throw new Error("No authenticated user");
    }

    const studentRef = doc(db, "users", userInfo.id);
    await updateDoc(studentRef, {
      needsOnboarding: false,
      onboardingCompletedAt: new Date().toISOString(),
    });
  };

  const profileMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["auth-user"] });
    },
  });

  return {
    updateProfile: profileMutation.mutate,
    isUpdating: profileMutation.isPending,
    error: profileMutation.error,
    completeStudentOnboarding,
  };
}; 