import { Timesheet } from "@freetech/models/timesheet";
import { calculateDuration } from ".";

interface CreateTimeEntryParams {
  entry: Partial<Timesheet>;
  userId: string;
  selectedClient?: string;
  selectedProject?: string;
}

export const prepareTimeEntryData = ({
  entry,
  userId,
  selectedClient,
  selectedProject,
}: CreateTimeEntryParams): Omit<Timesheet, "id"> => {
  const duration = calculateDuration(entry.startTime!, entry.endTime!);

  if (!duration) {
    throw new Error("Unable to calculate duration");
  }

  const preparedEntry = {
    ...entry,
    userId,
    duration,
    billable: entry.billable || false,
    submitted: false,
    description: entry.description || "",
    startTime: entry.startTime!,
    endTime: entry.endTime!,
    clientId: selectedClient || undefined,
    projectId: selectedProject || undefined,
  } as Omit<Timesheet, "id">;

  if (entry.projectAssignmentId) {
    preparedEntry.projectAssignmentId = entry.projectAssignmentId;
  }

  return preparedEntry;
};
