import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStakeholderProjects } from "hooks/stakeholder/useStakeholderProjects";
import { useBugReport } from "hooks/bugReport/useBugReport";
import { ProjectOverviewHeader } from "./components/ProjectOverviewHeader";
import { ProjectOverviewFeaturesAndBugs } from "./components/ProjectOverviewFeaturesAndBugs";
import { FeatureRequestDialog } from "./components/FeatureRequestDialog";
import { BugReportDialog } from "./components/BugReportDialog";
import { IBugReport } from "@freetech/models/bugReport";
import { useAuth } from "hooks/auth/useAuth";
import { MainLoading } from "router/layouts/MainLoading";
import { FreeTechLogoLoading } from "components/FreeTechLogoLoading";
import { useMutation } from "@tanstack/react-query";
import { BugReportFormData } from "./components/BugReportDialog";
import { useStakeholderBugReport } from "hooks/stakeholder/useStakeholderBugReport";

// Zod schema for feature request form
const featureRequestSchema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title is too long"),
  description: z
    .string()
    .min(1, "Description is required")
    .max(1000, "Description is too long"),
  priority: z.enum(["low", "medium", "high"]),
  estimatedStartDate: z.string().optional(),
  estimatedCompletionDate: z.string().optional(),
});

type FeatureRequestFormData = z.infer<typeof featureRequestSchema>;

export const StakeholderProjectOverview = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isFeatureRequestDialogOpen, setIsFeatureRequestDialogOpen] =
    useState(false);
  const [isBugReportDialogOpen, setIsBugReportDialogOpen] = useState(false);
  const [submitBugReportError, setSubmitBugReportError] = useState<string>();

  const {
    projects,
    freelancers,
    isFreelancersLoading,
    isProjectsLoading,
    projectsError,
    clients,
    isClientsLoading,
    clientsError,
    submitFeatureRequest,
    isSubmittingFeatureRequest,
    submitFeatureRequestError,
    featureRequests,
    isFeatureRequestsLoading,
  } = useStakeholderProjects();

  const { userInfo } = useAuth();
  const project = projects?.find((p) => p.id === projectId);
  const client = clients?.find((c) => c.id === project?.clientId);

  const {
    bugReports,
    isLoadingReports: isBugReportsLoading,
    submitBugReport,
    isSubmitting: isSubmittingBugReport,
  } = useStakeholderBugReport({
    projectId: projectId || "",
    clientId: project?.clientId || "",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FeatureRequestFormData>({
    resolver: zodResolver(featureRequestSchema),
    mode: "onChange",
    defaultValues: {
      title: "",
      description: "",
      priority: "medium",
      estimatedStartDate: undefined,
      estimatedCompletionDate: undefined,
    },
  });

  const handleOpenFeatureRequestDialog = () => {
    setIsFeatureRequestDialogOpen(true);
  };

  const handleCloseFeatureRequestDialog = () => {
    setIsFeatureRequestDialogOpen(false);
    reset();
  };

  const handleOpenBugReportDialog = () => {
    setIsBugReportDialogOpen(true);
  };

  const handleCloseBugReportDialog = () => {
    setIsBugReportDialogOpen(false);
    setSubmitBugReportError(undefined);
  };

  const handleSubmitBugReport = async (
    data: BugReportFormData,
    files: File[]
  ) => {
    if (!project || !client || !userInfo) return;

    try {
      await submitBugReport(
        {
          title: data.title,
          description: data.description,
          priority: data.priority,
          type: data.type,
          status: "open",
          clientId: client.id,
          projectId: project.id,
          reportedById: userInfo.id,
          stepsToReproduce: data.stepsToReproduce,
          expectedBehavior: data.expectedBehavior,
          actualBehavior: data.actualBehavior,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        files
      );
      handleCloseBugReportDialog();
    } catch (error) {
      setSubmitBugReportError((error as Error).message);
    }
  };

  const renderContent = () => {
    if (isProjectsLoading) {
      return <FreeTechLogoLoading />;
    }
    if (projectsError || (!isProjectsLoading && !project)) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Paper
            elevation={3}
            sx={{ p: 4, textAlign: "center", maxWidth: 400 }}
          >
            <ErrorOutline color="error" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Error
            </Typography>
            <Typography color="error">
              {projectsError?.message || "Project not found"}
            </Typography>
          </Paper>
        </Box>
      );
    }

    return (
      <Box>
        {project && (
          <ProjectOverviewHeader
            project={project}
            client={client}
            freelancers={freelancers}
            isLoading={isProjectsLoading}
            isFreelancersLoading={isFreelancersLoading}
          />
        )}
        <ProjectOverviewFeaturesAndBugs
          featureRequests={featureRequests}
          bugReports={bugReports}
          isFeatureRequestsLoading={isFeatureRequestsLoading}
          isBugReportsLoading={isBugReportsLoading}
          projectId={projectId || ""}
          onOpenFeatureRequestDialog={handleOpenFeatureRequestDialog}
          onOpenBugReportDialog={handleOpenBugReportDialog}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ 
      p: { xs: 2, sm: 3 },
      maxWidth: "100%",
      overflowX: "hidden"
    }}>
      {renderContent()}
      <FeatureRequestDialog
        open={isFeatureRequestDialogOpen}
        onClose={handleCloseFeatureRequestDialog}
        project={project}
        client={client}
      />
      <BugReportDialog
        open={isBugReportDialogOpen}
        onClose={handleCloseBugReportDialog}
        project={project}
        client={client}
        stakeholder={userInfo}
        onSubmit={handleSubmitBugReport}
        isSubmitting={isSubmittingBugReport}
        submitError={submitBugReportError}
      />
    </Box>
  );
};
