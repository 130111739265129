import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { Box, Paper, Divider } from '@mui/material';
import { getDefaultExtensions, getMentionExtension } from './extensions';
import MenuBar from './components/MenuBar';
import hljs from 'highlight.js/lib/core';
import './styles/editor.css';
import { Extension, Node, Mark } from '@tiptap/core';

export interface RichTextEditorProps {
  content: string;
  onChange: (content: string) => void;
  placeholder?: string;
  minHeight?: number;
  readOnly?: boolean;
  includeHeadings?: boolean;
  includeCodeBlock?: boolean;
  includeImage?: boolean;
  mentionSuggestions?: Array<{ id: string; label: string }>;
  onMentionSelect?: (id: string) => void;
  onImageUpload?: (file: File) => Promise<string>;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  content,
  onChange,
  placeholder = 'Write something...',
  minHeight = 200,
  readOnly = false,
  includeHeadings = false,
  includeCodeBlock = true,
  includeImage = false,
  mentionSuggestions = [],
  onMentionSelect,
  onImageUpload,
}) => {
  // Build extensions array
  const extensions: (Extension | Node | Mark)[] = [...getDefaultExtensions({
    placeholder,
    includeCodeBlock,
  })];

  // Add mention extension if mentionSuggestions are provided
  if (mentionSuggestions.length > 0) {
    extensions.push(getMentionExtension(mentionSuggestions, onMentionSelect));
  }

  const editor = useEditor({
    extensions,
    content,
    editable: !readOnly,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: 'tiptap-editor',
      },
    },
  });

  // Add this effect to manually highlight code blocks if includeCodeBlock is true
  useEffect(() => {
    if (editor && includeCodeBlock) {
      const highlightCodeBlocks = () => {
        // Find all code blocks in the editor
        const codeBlocks = document.querySelectorAll('pre code');
        
        // Apply highlight.js to each code block
        codeBlocks.forEach((block) => {
          // Make sure the language class is set based on the data-language attribute
          const pre = block.closest('pre');
          const language = pre?.getAttribute('data-language');
          
          if (language) {
            // Remove any existing language classes
            block.className = block.className.replace(/language-\w+/g, '');
            
            // Add the appropriate language class
            block.classList.add(`language-${language}`);
            
            // Special handling for TSX/JSX content
            if (language === 'typescript' && block.textContent?.includes('JSX')) {
              block.classList.add('language-tsx');
            }
          }
          
          // Apply highlighting
          hljs.highlightElement(block as HTMLElement);
        });
      };

      // Run once on mount
      setTimeout(highlightCodeBlocks, 100);

      // Add event listener for content changes
      editor.on('update', () => {
        setTimeout(highlightCodeBlocks, 100);
      });
      
      return () => {
        editor.off('update');
      };
    }
  }, [editor, includeCodeBlock]);

  if (!editor) {
    return null;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {!readOnly && (
        <>
          <MenuBar 
            editor={editor} 
            includeHeadings={includeHeadings}
            includeImage={includeImage}
            onImageUpload={onImageUpload}
          />
          <Divider />
        </>
      )}
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          '& .ProseMirror': {
            minHeight,
            outline: 'none',
            p: 2,
            '& > * + *': {
              marginTop: 1,
            },
            '& p.is-editor-empty:first-child::before': {
              content: 'attr(data-placeholder)',
              color: 'text.disabled',
              float: 'left',
              height: 0,
              pointerEvents: 'none',
            },
          },
        }}
      >
        <EditorContent editor={editor} />
      </Box>
    </Paper>
  );
};

export default RichTextEditor; 