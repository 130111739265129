import React, { useState, useCallback, useRef } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Snackbar,
  Alert,
  Dialog,
  Box,
  useTheme,
  useMediaQuery,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddComment,
  NotificationImportant,
  Block,
  Message,
  Error as ErrorIcon,
  CheckCircle,
  DeleteForever,
} from "@mui/icons-material";
import { PageTitle } from "components/PageTitle";
import { useManageUsers } from "hooks/admin";
import { CreateUser } from "./components/CreateUser";
import {
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridRowModel,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { CombinedUserData } from "@freetech/models/user";
import { portalFunctions } from "core/functions";

const booleanOptions = ["true", "false"];
const roles = ["freelancer", "stakeholder", "babyStakeholder", "admin", "executiveAssistant", "student"];

export const UserManagement = () => {
  const theme = useTheme();
  const {
    authUsers,
    authUsersLoading,
    authUsersError,
    updateUserMutation,
    disableUserMutation,
    enableUserMutation,
    deleteUserMutation,
  } = useManageUsers();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CombinedUserData | null>(
    null
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<CombinedUserData | null>(
    null
  );
  const notesRef = useRef("");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleOpen = useCallback((user: CombinedUserData) => {
    setSelectedUser((prevState) => ({ ...prevState, ...user }));
    notesRef.current = user.notes || "";
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDisableUser = useCallback(
    async (userId: string) => {
      try {
        await disableUserMutation.mutate(userId);
        setSnackbar({
          open: true,
          message: "User disabled successfully",
          severity: "success",
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to disable user",
          severity: "error",
        });
      }
    },
    [disableUserMutation]
  );

  const handleEnableUser = useCallback(
    async (userId: string) => {
      try {
        await enableUserMutation.mutate(userId);
        setSnackbar({
          open: true,
          message: "User enabled successfully",
          severity: "success",
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to enable user",
          severity: "error",
        });
      }
    },
    [enableUserMutation]
  );

  const handleSaveNotes = useCallback(async () => {
    if (selectedUser) {
      const userUpdate: CombinedUserData = {
        ...selectedUser,
        notes: notesRef.current,
      };
      await updateUserMutation.mutate(userUpdate);
    }
    handleClose();
  }, [selectedUser, handleClose]);

  const handleChangeNotes = (event: { target: { value: string } }) => {
    notesRef.current = event.target.value;
  };

  const handleResendVerificationEmail = (row: CombinedUserData) => async () => {
    try {
      await portalFunctions.admin.resendVerificationEmail(row.id);
    } catch (error) {
      console.error("Error resending verification email:", error);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleProcessRowUpdate = async (
    newRow: CombinedUserData,
    oldRow: CombinedUserData
  ) => {
    try {
      await updateUserMutation.mutate(newRow);
      setSnackbar({
        open: true,
        message: "User updated successfully",
        severity: "success",
      });
      return newRow;
    } catch (error) {
      console.error("Error updating user:", error);
      setSnackbar({
        open: true,
        message:
          error instanceof Error ? error.message : "Failed to update user",
        severity: "error",
      });
      return oldRow;
    }
  };

  const handleDeleteUser = async (user: CombinedUserData) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!userToDelete) return;

    try {
      await deleteUserMutation.mutate(userToDelete.id);
      setSnackbar({
        open: true,
        message: "User deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      setSnackbar({
        open: true,
        message:
          error instanceof Error ? error.message : "Failed to delete user",
        severity: "error",
      });
    } finally {
      setDeleteDialogOpen(false);
      setUserToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const columns: GridColDef<CombinedUserData>[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    { field: "lastName", headerName: "Last Name", width: 150, editable: true },
    {
      field: "role",
      headerName: "User Type",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: roles,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      renderCell: (params: GridRenderCellParams<CombinedUserData>) => {
        const user = params.row;
        if (user.error) {
          return (
            <Tooltip title={user.error}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ErrorIcon color="error" />
                {user.email}
              </Box>
            </Tooltip>
          );
        }
        return user.email;
      },
    },
    { field: "phone", headerName: "Phone", width: 150, editable: true },
    {
      field: "lastLoginAt",
      headerName: "Last Login",
      width: 150,
      editable: false,
      valueGetter: (value: Date, row) => {
        if (!value) return "Never";
        console.log(value);
        const date = new Date(value);
        return date.toLocaleString();
      },
    },
    {
      field: "disabled",
      headerName: "Status",
      width: 130,
      valueGetter: (value, row) => {
        if (!row) return "";
        if (row.disabled) return "Disabled";
        return "Active";
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (params: GridRowParams<CombinedUserData>) => [
        params.row.disabled ? (
          <GridActionsCellItem
            key={`enable-${params.id}`}
            icon={<CheckCircle color="success" />}
            label="Enable User"
            onClick={() => handleEnableUser(params.id.toString())}
            showInMenu
          />
        ) : (
          <GridActionsCellItem
            key={`disable-${params.id}`}
            icon={<Block />}
            label="Disable User"
            onClick={() => handleDisableUser(params.id.toString())}
            disabled={params.row.disabled || false}
            showInMenu
          />
        ),
        <GridActionsCellItem
          key={`delete-${params.id}`}
          icon={<DeleteForever color="error" />}
          label="Delete User"
          onClick={() => handleDeleteUser(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: "auth.emailVerified",
      headerName: "Email Verified",
      width: 130,
      valueGetter: (value, row) => {
        if (!row?.auth) return "false";
        return row.auth.emailVerified ? "true" : "false";
      },
    },
    {
      field: "Resend Email",
      type: "actions",
      headerName: "Resend Verification",
      width: 150,
      getActions: (params: GridRowParams<CombinedUserData>) => [
        <GridActionsCellItem
          key={params.id}
          icon={<NotificationImportant />}
          label="Send Reminder Email"
          onClick={handleResendVerificationEmail(params.row)}
          disabled={params.row.auth?.emailVerified === true}
        />,
      ],
    },
    {
      field: "add_notes",
      type: "actions",
      headerName: "Notes",
      width: 80,
      getActions: (params: GridRowParams<CombinedUserData>) => [
        <GridActionsCellItem
          key={params.id}
          icon={<AddComment />}
          label="Add Notes"
          onClick={() => handleOpen(params.row)}
        />,
      ],
    },
  ];

  if (authUsersLoading) {
    return <div>Loading...</div>;
  }

  if (authUsersError) {
    return <div>Error loading users</div>;
  }

  return (
    <>
      <PageTitle
        title="User Management"
        subtitle="This page allows you to create, update, or delete portal users. "
        bulletPoints={[
          "Double-click the row to start editing a user, then click the save icon that appears on the right side.",
          "Use the 'Remind' icon allows you to resend a verification email to a user.",
          "Share user notes with the team by clicking the 'Add Notes' icon.",
          "Disable users using the block icon in the Actions column.",
        ]}
      />

      <CreateUser />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent sx={{ width: "550px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="notes"
            label="Notes"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={10}
            defaultValue={notesRef.current}
            onChange={handleChangeNotes}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveNotes}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the user {userToDelete?.email}? This
            action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Box 
        sx={{ 
          height: 800,
          width: '100%',
          overflow: 'hidden',
          '& .MuiDataGrid-root': {
            border: 'none',
            maxWidth: '100%',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.background.paper,
            borderBottom: `2px solid ${theme.palette.divider}`,
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background.paper,
          },
          '& .MuiDataGrid-main': {
            overflow: 'auto',
          }
        }}
      >
        <DataGridPro<CombinedUserData>
          rows={authUsers || []}
          columns={columns}
          loading={authUsersLoading}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.error("Error updating row:", error);
          }}
          editMode="row"
          rowHeight={40}
          pagination
          paginationModel={{ page: 0, pageSize: 25 }}
          pageSizeOptions={[10, 25, 50]}
          autoHeight={false}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          sx={{
            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                height: 8
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[400],
                borderRadius: 4
              }
            }
          }}
        />
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};
