import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Stack,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useTheme,
  alpha,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridRowModel,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { Timesheet } from "@freetech/models/timesheet";
import { ProjectAssignment } from "@freetech/models/projects";
import { Freelancer } from "@freetech/models/user";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WarningIcon from "@mui/icons-material/Warning";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { calculateDuration } from "core/timesheet/utils/utils";
import { AdminManualTimeEntryForm } from "./AdminManualTimeEntryForm";
import { useAuth } from "hooks/auth/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { useAdminTimesheet } from "hooks/admin/useAdminTimesheet";

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration);
  const minutes = Math.round((duration - hours) * 60);
  return `${hours}h ${minutes}m`;
};

interface AdminTimesheetGridProProps {
  timesheets: Timesheet[];
  startDate: DateTime;
  endDate: DateTime;
  onEdit?: (timesheet: Timesheet) => void;
  onDelete?: (timesheet: Timesheet) => void;
  onApprove?: (timesheet: Timesheet) => void;
  onReject?: (timesheet: Timesheet) => void;
  showActions?: boolean;
  isAdmin?: boolean;
  projectAssignments?: ProjectAssignment[];
  freelancers?: Freelancer[];
}

export const AdminTimesheetGridPro: React.FC<AdminTimesheetGridProProps> = ({
  timesheets,
  startDate,
  endDate,
  onEdit,
  onDelete,
  onApprove,
  onReject,
  showActions = true,
  isAdmin = false,
  projectAssignments = [],
  freelancers = [],
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const { deleteTimesheet, copyTimesheet, addEntryForUser, updateTimesheet } = useAdminTimesheet();
  const [editingTimesheet, setEditingTimesheet] = useState<Timesheet | null>(
    null
  );
  const [deletingTimesheet, setDeletingTimesheet] = useState<Timesheet | null>(
    null
  );
  const [copyingTimesheet, setCopyingTimesheet] = useState<Timesheet | null>(null);

  const handleEdit = (timesheet: Timesheet) => {
    setEditingTimesheet(timesheet);
  };

  const handleEditSubmit = async (updatedTimesheet: Omit<Timesheet, "id">) => {
    if (onEdit && editingTimesheet) {
      await onEdit({
        ...updatedTimesheet,
        id: editingTimesheet.id,
      });
      setEditingTimesheet(null);
    }
  };

  const handleDeleteClick = (timesheet: Timesheet) => {
    setDeletingTimesheet(timesheet);
  };

  const handleDeleteConfirm = async () => {
    if (deletingTimesheet) {
      try {
        await deleteTimesheet.mutateAsync(deletingTimesheet.id);
        if (onDelete) {
          onDelete(deletingTimesheet);
        }
      } catch (error) {
        console.error("Error deleting timesheet:", error);
      }
    }
    setDeletingTimesheet(null);
  };

  const handleCopy = (timesheet: Timesheet) => {
    setCopyingTimesheet(timesheet);
  };

  const handleSubmitForUser = async (id: string) => {
    await updateTimesheet.mutateAsync({ id, submitted: true });
  };

  const columns: GridColDef<Timesheet>[] = [
    {
      field: "date",
      headerName: "Date",
      flex: 0.8,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<Timesheet>) => {
        const date = params.row.date;
        if (!date || date === "Invalid DateTime") {
          return "Invalid DateTime";
        }
        try {
          return DateTime.fromISO(date).toFormat("LLL dd");
        } catch (e) {
          return "Invalid DateTime";
        }
      },
    },
    {
      field: "userId",
      headerName: "Engineer",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Timesheet>) => (
        <>{params.row.userName || "Unknown"}</>
      ),
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 0.8,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<Timesheet>) => (
        <>{formatDuration(params.row.duration / 3600)}</>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.2,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<Timesheet>) => (
        <Stack direction="row" spacing={1}>
          {params.row.billable && (
            <Chip
              label="Billable"
              size="small"
              color="primary"
              variant="outlined"
              icon={<AttachMoneyIcon />}
            />
          )}
          {params.row.submitted && (
            <Chip
              label="Submitted"
              size="small"
              color="info"
              variant="outlined"
            />
          )}
          {params.row.approved && (
            <Chip
              label="Approved"
              size="small"
              color="success"
              variant="outlined"
            />
          )}
          {params.row.rejected && !params.row.approved && (
            <Chip
              label="Rejected"
              size="small"
              color="error"
              variant="outlined"
            />
          )}
          {!params.row.submitted && (
            <Chip
              label="Unsubmitted"
              color="warning"
              size="small"
              variant="outlined"
            />
          )}
        </Stack>
      ),
    },
    ...(showActions
      ? [
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            minWidth: 200,
            renderCell: (params: GridRenderCellParams<Timesheet>) => (
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                {!params.row.submitted && (
                  <Tooltip title="Submit">
                    <IconButton
                      size="small"
                      onClick={() => handleSubmitForUser(params.row.id)}
                    >
                      <NorthEastIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {isAdmin &&
                  (params.row.submitted || params.row.rejected) &&
                  !params.row.approved && (
                    <>
                      <Tooltip title="Approve">
                        <IconButton
                          size="small"
                          color="success"
                          onClick={() => onApprove?.(params.row)}
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      </Tooltip>
                      {!params.row.rejected && (
                        <Tooltip title="Reject">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => onReject?.(params.row)}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                {!params.row.approved && (
                  <>
                    <Tooltip title="Copy">
                      <IconButton
                        size="small"
                        onClick={() => handleCopy(params.row)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        onClick={() => handleEdit(params.row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleDeleteClick(params.row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            ),
          },
        ]
      : []),
  ];

  return (
    <Box sx={{ width: "100%" }}>
      {editingTimesheet && (
        <Dialog 
          open={true} 
          onClose={() => setEditingTimesheet(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Edit Time Entry</DialogTitle>
          <DialogContent>
            <AdminManualTimeEntryForm
              editMode
              initialTimesheet={editingTimesheet}
              onSubmit={handleEditSubmit}
              onClose={() => setEditingTimesheet(null)}
              isAdmin={isAdmin}
              projectAssignments={projectAssignments}
              freelancers={freelancers}
            />
          </DialogContent>
        </Dialog>
      )}

      {copyingTimesheet && (
        <AdminManualTimeEntryForm
          copyMode
          initialTimesheet={copyingTimesheet}
          onSubmit={addEntryForUser.mutateAsync}
          onClose={() => setCopyingTimesheet(null)}
          isAdmin={isAdmin}
          projectAssignments={projectAssignments}
          freelancers={freelancers}
        />
      )}

      <Dialog
        open={!!deletingTimesheet}
        onClose={() => setDeletingTimesheet(null)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle
          id="delete-dialog-title"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <WarningIcon color="warning" />
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            This is a destructive action that cannot be undone. Are you sure you
            want to delete this time entry?
          </DialogContentText>
          {deletingTimesheet && (
            <Box sx={{ mt: 2, p: 2, bgcolor: "grey.100", borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Time Entry Details:
              </Typography>
              <Typography variant="body2">
                Date:{" "}
                {DateTime.fromISO(deletingTimesheet.date).toFormat(
                  "LLL dd, yyyy"
                )}
              </Typography>
              <Typography variant="body2">
                Project: {deletingTimesheet.projectName}
              </Typography>
              <Typography variant="body2">
                Client: {deletingTimesheet.clientName}
              </Typography>
              <Typography variant="body2">
                Duration: {formatDuration(deletingTimesheet.duration)}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletingTimesheet(null)}>Cancel</Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={2} sx={{ mb: 2, overflow: "hidden" }}>
        <DataGridPro<Timesheet>
          rows={timesheets}
          columns={columns}
          autoHeight
          getRowId={(row: any) => {
            if ('id' in row) return row.id;
            if ('userId' in row && 'date' in row && 'startTime' in row && 'endTime' in row) {
              return `${row.userId}-${row.date}-${row.startTime}-${row.endTime}`;
            }
            return Math.random().toString(); // Fallback unique ID
          }}
          disableColumnFilter
          disableColumnResize
          disableColumnReorder
          disableColumnMenu
          disableColumnSelector
          disableChildrenSorting
          disableMultipleColumnsSorting
          disableDensitySelector
          disableRowSelectionOnClick
          columnHeaderHeight={40}
          rowHeight={50}
          slots={{
            toolbar: GridToolbar,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "date", sort: "desc" }],
            },
          }}
          sx={{
            border: "none",
            "& .MuiDataGrid-cell": {
              padding: "8px",
              borderBottom: "none",
              color: theme.palette.text.primary,
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: `1px solid ${theme.palette.divider}`,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.text.primary,
            },
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important",
            },
            "& .MuiDataGrid-row": {
              "&:nth-of-type(odd)": {
                backgroundColor: alpha(theme.palette.background.default, 0.5),
              },
              "&:nth-of-type(even)": {
                backgroundColor: alpha(theme.palette.background.paper, 0.5),
              },
              "&:hover": {
                backgroundColor: alpha(theme.palette.action.hover, 0.1),
              },
              "&.approved": {
                backgroundColor: alpha(theme.palette.success.light, 0.1),
              },
              "&.rejected": {
                backgroundColor: alpha(theme.palette.error.light, 0.1),
              },
            },
            "& .MuiDataGrid-columnHeader": {
              fontWeight: "bold",
            },
          }}
          getRowClassName={(params) => {
            if (params.row.approved) return "approved";
            if (params.row.rejected) return "rejected";
            return "";
          }}
        />
      </Paper>
    </Box>
  );
};
