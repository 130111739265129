import React from 'react';
import { motion, useSpring, useMotionValue, useTransform } from 'framer-motion';
import { Typography, TypographyProps } from '@mui/material';

interface AnimatedNumberProps extends Omit<TypographyProps, 'children'> {
  value: number;
  format?: (value: number) => string;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ value, format = (v) => v.toString(), ...props }) => {
  const motionValue = useMotionValue(0);
  const springValue = useSpring(motionValue, {
    stiffness: 200,
    damping: 30,
    restDelta: 0.5,
    restSpeed: 0.5
  });

  React.useEffect(() => {
    motionValue.set(value);
  }, [value, motionValue]);

  return (
    <Typography {...props}>
      <motion.span>
        {useTransform(springValue, (latest) => format(Math.round(latest)))}
      </motion.span>
    </Typography>
  );
};

export default AnimatedNumber; 