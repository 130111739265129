import {
  Home as HomeIcon,
  Group as GroupIcon,
  Person2,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  People as PeopleIcon,
  AccountBalance as AccountBalanceIcon,
  MonetizationOn as MonetizationOnIcon,
  Work as ContractorIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  UserManagement,
  ClientManagement,
  FreelancerManagement,
  SuperAdminHome,
  TimeTracker,
  AgreementManagement,
  BlogWriter,
} from "views/SuperAdmin";
import { ExecutiveAssistantHome } from "views/ExecutiveAssistant";
import FreelancerOverview from "views/SuperAdmin/FreelancerManagement/FreelancerOverview/FreelancerOverview";
import { Navigation } from "types/navigation";
import { FreeloHome } from "views/FreeloHome";
import { FreeloBoard } from "views/FreeloBoard";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { TimesheetManagement } from "views/SuperAdmin/TimesheetManagement";
import ClientOverviewV2 from "views/SuperAdmin/ClientManagement/ClientOverviewV2/ClientOverviewV2";
import HubIcon from "@mui/icons-material/Hub";
import HailIcon from "@mui/icons-material/Hail";
import ScienceIcon from "@mui/icons-material/Science";
import { BlogPostEditor } from "views/SuperAdmin/MarketingCMS/BlogWriter/BlogPostEditor";
import { JobPoster, JobPostEditor } from "views/SuperAdmin/MarketingCMS/JobPoster";
import WorkIcon from "@mui/icons-material/Work";

const roleName = "executiveAssistant";

export const executiveAssistantPages: Navigation = [
  {
    kind: "page",
    title: "Home",
    segment: roleName,
    icon: <HomeIcon />,
    component: <ExecutiveAssistantHome />,
  },
  {
    segment: `${roleName}/tracker`,
    title: "Time Tracker",
    icon: <AccessTimeIcon />,
    component: <TimeTracker />,
  },
  {
    kind: "page",
    title: "Freelo",
    segment: `${roleName}/freelo`,
    icon: <ViewKanbanIcon />,
    component: <FreeloHome />,
    slugBabyPattern: ":boardId",
    slugBabyComponent: <FreeloBoard />,
  },
  { kind: "header", title: "Operations" },
  { kind: "divider" },
  {
    segment: `${roleName}/client-management`,
    title: "Clients",
    icon: <HubIcon />,
    slugBabyPattern: ":clientId",
    slugBabyComponent: <ClientOverviewV2 />,
    component: <ClientManagement />,
  },
  {
    segment: `${roleName}/freelancer-management`,
    title: "Freelancers",
    icon: <HailIcon />,
    slugBabyPattern: `:freelancerId`,
    slugBabyComponent: <FreelancerOverview />,
    component: <FreelancerManagement />,
  },
  {
    segment: `${roleName}/timesheet-management`,
    title: "Timesheets",
    icon: <AccessTimeIcon />,
    component: <TimesheetManagement />,
  },
  { kind: "header", title: "Beta Features" },
  { kind: "divider" },
  {
    segment: `${roleName}/beta-features`,
    title: "Beta Features",
    icon: <ScienceIcon />,
    children: [
      {
        segment: `${roleName}/blog-writer`,
        title: "Blog Writer",
        icon: <EditIcon />,
        component: <BlogWriter />,
        slugBabyPattern: ":blogId",
        slugBabyComponent: <BlogPostEditor />,
      },
      {
        segment: `${roleName}/job-poster`,
        title: "Job Poster",
        icon: <WorkIcon />,
        component: <JobPoster />,
        slugBabyPattern: ":jobId",
        slugBabyComponent: <JobPostEditor />,
      },
    ],
  },
];
