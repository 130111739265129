import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Badge,
  Divider,
} from "@mui/material";
import { useAdminClient } from "hooks/admin/useAdminClient";
import ClientDetails from "./ClientDetails/ClientDetails";
import AssociatedProjects from "./AssociatedProjects/AssociatedProjects";
import AssociatedInvoices from "./AssociatedInvoices/AssociatedInvoices";
import AssociatedClientUsers from "./AssociatedClientUsers/AssociatedClientUsers";
import AssociatedFeatureRequests from "./AssociatedFeatureRequests/AssociatedFeatureRequests";
import AssociatedBugReports from "./AssociatedBugReports/AssociatedBugReports";
import { AssociatedTimesheets } from "./AssociatedTimesheets";
import ClientLegalOwner from "./ClientLegalOwner/ClientLegalOwner";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import BugReportIcon from "@mui/icons-material/BugReport";
import ReceiptIcon from "@mui/icons-material/Receipt";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Tab names for URL hash
const TAB_HASHES = [
  "overview",
  "feature-requests",
  "bug-reports",
  "invoices",
  "users",
  "timesheets",
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`client-overview-tabpanel-${index}`}
      aria-labelledby={`client-overview-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const ClientOverviewV2 = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  // Get initial tab from URL hash or default to 0
  const getInitialTabFromHash = () => {
    const hash = location.hash.replace("#", "");
    const tabIndex = TAB_HASHES.indexOf(hash);
    return tabIndex >= 0 ? tabIndex : 0;
  };

  const [tabValue, setTabValue] = useState(getInitialTabFromHash());

  const { client, isLoading: isLoadingClient } = useAdminClient(clientId!);

  // Update tab when URL hash changes
  useEffect(() => {
    const handleHashChange = () => {
      const newTabValue = getInitialTabFromHash();
      setTabValue(newTabValue);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    // Update URL hash when tab changes
    navigate(`#${TAB_HASHES[newValue]}`, { replace: true });
  };

  if (isLoadingClient || !client) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ width: "100%" }}>
      {clientId && (
        <>
          <Paper sx={{ mb: 3 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="client overview tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                icon={<HomeIcon />}
                iconPosition="start"
                label="Overview"
                id="client-overview-tab-0"
                aria-controls="client-overview-tabpanel-0"
              />
              <Tab
                icon={<LightbulbIcon />}
                iconPosition="start"
                label="Feature Requests"
                id="client-overview-tab-1"
                aria-controls="client-overview-tabpanel-1"
              />
              <Tab
                icon={<BugReportIcon />}
                iconPosition="start"
                label="Bug Reports"
                id="client-overview-tab-2"
                aria-controls="client-overview-tabpanel-2"
              />
              <Tab
                icon={<ReceiptIcon />}
                iconPosition="start"
                label="Invoices"
                id="client-overview-tab-3"
                aria-controls="client-overview-tabpanel-3"
              />
              <Tab
                icon={<GroupIcon />}
                iconPosition="start"
                label="Users"
                id="client-overview-tab-4"
                aria-controls="client-overview-tabpanel-4"
              />
              <Tab
                icon={<AccessTimeIcon />}
                iconPosition="start"
                label="Timesheets"
                id="client-overview-tab-5"
                aria-controls="client-overview-tabpanel-5"
              />
            </Tabs>
          </Paper>

          <TabPanel value={tabValue} index={0}>
            <ClientDetails client={client} clientId={clientId} />
            <ClientLegalOwner client={client} clientId={clientId} />
            <AssociatedProjects
              client={client}
              clientId={clientId}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <AssociatedFeatureRequests client={client} clientId={clientId} />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <AssociatedBugReports client={client} clientId={clientId} />
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <AssociatedInvoices client={client} clientId={clientId} />
          </TabPanel>

          <TabPanel value={tabValue} index={4}>
            <AssociatedClientUsers client={client} clientId={clientId} />
          </TabPanel>

          <TabPanel value={tabValue} index={5}>
            <AssociatedTimesheets client={client} clientId={clientId} />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default ClientOverviewV2;
