import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { StakeholderPayment } from "../bank/bank";

export const stakeholderPaymentConverter: FirestoreDataConverter<StakeholderPayment> =
  {
    toFirestore(payment: WithFieldValue<StakeholderPayment>): DocumentData {
      const data = {
        id: payment.id,
        userId: payment.userId,
        amount: payment.amount,
        status: payment.status,
        paymentDate: payment.paymentDate,
        createdAt: payment.createdAt,
        clientId: payment.clientId,
        updatedAt: payment.updatedAt,
        direction: payment.direction,
        increaseExternalAccountId: payment.increaseExternalAccountId,
        externalPaymentSource: payment.externalPaymentSource,
        expectedSettlementDate: payment.expectedSettlementDate,
        isExternalPayment: payment.isExternalPayment,
        memo: payment.memo,
        projectId: payment.projectId,
        csaId: payment.csaId,
        invoiceId: payment.invoiceId,
      };
      return data;
    },
    fromFirestore(
      snapshot: DocumentSnapshot,
      options: SnapshotOptions
    ): StakeholderPayment {
      const data = snapshot.data(options);

      let amount = data?.amount;
      if (data?.increaseExternalAccountId) {
        amount = Math.abs(Number((data?.amount / 100).toFixed(2)));
      } else if (data?.stripePaymentIntentId) {
        amount = Number(data?.amount);
      } else {
        amount = 0
      }
      return {
        id: snapshot.id,
        userId: data?.userId,
        amount: amount,
        status: data?.status,
        paymentDate: data?.paymentDate,
        createdAt: data?.createdAt,
        clientId: data?.clientId,
        updatedAt: data?.updatedAt,
        direction: data?.direction,
        increaseExternalAccountId: data?.increaseExternalAccountId,
        stripePaymentIntentId: data?.stripePaymentIntentId,
        externalPaymentSource: data?.externalPaymentSource,
        expectedSettlementDate: data?.expectedSettlementDate,
        isExternalPayment: data?.isExternalPayment,
        memo: data?.memo,
        projectId: data?.projectId,
        csaId: data?.csaId,
        invoiceId: data?.invoiceId,
        
      };
    },
  };
