import React, { ReactNode } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';
import { useStudentManageSubscription } from 'hooks/student/useStudentManageSubscription';

interface BlurredContentProps {
  children: ReactNode;
  title?: string;
  isExempt?: boolean; // For pages like ManageSubscription that shouldn't be blurred
}

/**
 * A component that blurs content when a student's subscription is not active.
 * Provides a prompt to activate the subscription.
 */
export const BlurredContent: React.FC<BlurredContentProps> = ({ 
  children, 
  title,
  isExempt = false 
}) => {
  const { subscription, isLoading } = useStudentManageSubscription();
  const hasActiveSubscription = subscription?.status === 'active';
  
  // If loading, exempt from blur check, or has active subscription, show content normally
  if (isLoading || isExempt || hasActiveSubscription) {
    return <>{children}</>;
  }
  
  // Blur content for inactive subscription
  return (
    <Box 
      sx={{ 
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {/* Blurred content */}
      <Box 
        sx={{
          filter: 'blur(4px)',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {children}
      </Box>
      
      {/* Overlay with call to action */}
      <Box 
        sx={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 2,
          borderRadius: 1,
          padding: 3,
          textAlign: 'center'
        }}
      >
        <LockIcon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
        
        {title && (
          <Typography variant="h6" color="primary.main" gutterBottom>
            {title}
          </Typography>
        )}
        
        <Typography variant="body1" gutterBottom sx={{ maxWidth: 400, mb: 3 }}>
          This content requires an active subscription. Please activate your subscription to access all features.
        </Typography>
        
        <Button 
          variant="contained" 
          color="primary"
          component={Link}
          href="/student/manage-subscription"
          startIcon={<LockIcon />}
        >
          Activate Subscription
        </Button>
      </Box>
    </Box>
  );
}; 