import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Avatar,
  Chip,
  Tabs,
  Tab,
  CircularProgress,
  Stack,
  Skeleton,
  useTheme,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholderProjects } from "hooks/stakeholder/useStakeholderProjects";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useStakeholderTimesheets } from "hooks/stakeholder/useStakeholderTimesheets";
import { motion } from "framer-motion";
import { PieChart } from "@mui/x-charts/PieChart";
import { Project, ProjectStatus } from "@freetech/models/projects";
import {
  Assignment as AssignmentIcon,
  Receipt as ReceiptIcon,
  Description as DescriptionIcon,
  AccessTime as AccessTimeIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  AttachMoney as MoneyIcon,
  Lightbulb,
  InfoOutlined,
} from "@mui/icons-material";
import { FreeTechLogoLoading } from "components/FreeTechLogoLoading";
import { styled } from "@mui/material/styles";
import { SxProps } from "@mui/material/styles";
import { LineChart } from "@mui/x-charts/LineChart";
import { DateTime } from "luxon";
import { ViewKanban } from "@mui/icons-material";
import { StakeholderInvoiceCount } from "components/Icons/StakeholderInvoiceCount";
// Add type for value formatter
type SeriesValueFormatter = (value: number | null) => string;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface MonthlyHours {
  current: number;
  previous: number;
  percentageChange: number;
}

interface ProjectHours {
  name: string;
  hours: number;
  previousHours: number;
  percentageChange: number;
}

const MotionPaper = motion(Paper);
const MotionBox = motion(Box);

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  fontSize: "1rem",
  minWidth: 120,
  padding: "12px 24px",
  color: theme.palette.grey[600],
  "&.Mui-selected": {
    color: theme.palette.grey[900],
  },
  "&:hover": {
    color: theme.palette.grey[800],
    opacity: 1,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    minWidth: 100,
    padding: "10px 16px",
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.grey[900],
    height: 2,
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(2),
  },
}));

// Style definitions with proper typing
const darkContainerStyles: SxProps<Theme> = {
  background: "linear-gradient(145deg, #3a3a3a 0%, #2d2d2d 100%)",
  border: "1px solid",
  borderColor: "grey.800",
  boxShadow: "0px 12px 32px rgba(0, 0, 0, 0.18)",
  borderRadius: 3,
} as const;

const whiteCardStyles: SxProps<Theme> = {
  bgcolor: "#ffffff",
  borderRadius: 3,
  border: "1px solid",
  borderColor: "grey.200",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0px 12px 28px rgba(0, 0, 0, 0.12)",
  },
} as const;

const staticWhiteCardStyles: SxProps<Theme> = {
  bgcolor: "#ffffff",
  borderRadius: 3,
  border: "1px solid",
  borderColor: "grey.200",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
} as const;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

// Update the HoursComparisonSection component
const HoursComparisonSection = ({
  title,
  currentHours,
  previousHours,
}: {
  title: string;
  currentHours: number;
  previousHours: number;
}) => {
  const cardStyles: SxProps<Theme> = {
    bgcolor: "#ffffff",
    borderRadius: 3,
    border: "1px solid",
    borderColor: "grey.200",
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: { xs: 2, sm: 3 },
        height: "100%",
        ...cardStyles,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography
          variant="h6"
          sx={{
            color: "grey.900",
            fontWeight: 600,
            mb: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          {title}
        </Typography>
        <Tooltip
          title="Current month utilization may be out of date. Freelancers are required to submit hours each Sunday by 5 pm ET"
          arrow
          placement="top"
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                maxWidth: 300,
                fontSize: "0.875rem",
                py: 1,
                px: 1.5,
                bgcolor: "grey.800",
              },
            },
          }}
        >
          <Box
            component="span"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              color: "grey.500",
              mb: 2,
              cursor: "help",
            }}
          >
            <InfoOutlined sx={{ fontSize: 18 }} />
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-end", mb: 2 }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: "grey.900",
            lineHeight: 1,
            fontSize: { xs: "2rem", sm: "3rem" },
          }}
        >
          {currentHours}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "grey.600",
            ml: 1,
            mb: 0.5,
          }}
        >
          hours
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          color: "grey.600",
          fontWeight: 500,
        }}
      >
        Last Month: {previousHours} hours
      </Typography>
    </Paper>
  );
};

// Update the WeeklyUtilizationChart component
const WeeklyUtilizationChart = ({
  timesheets,
  projects,
  isLoading,
}: {
  timesheets: any[];
  projects: Project[];
  isLoading: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chartHeight = isMobile ? 220 : 280;

  const weeklyData = React.useMemo(() => {
    if (!timesheets || !projects)
      return { xAxis: [], series: [], projectNames: {} };

    const now = DateTime.now();
    const twoMonthsAgo = now.minus({ months: 2 });
    const weeks: DateTime[] = [];

    // Generate array of week start dates for the last 2 months
    let currentWeek = twoMonthsAgo.startOf("week");
    while (currentWeek <= now) {
      weeks.push(currentWeek);
      currentWeek = currentWeek.plus({ weeks: 1 });
    }

    // Initialize project data structure
    const projectData: {
      [key: string]: {
        name: string;
        abbreviation: string;
        hours: number[];
      };
    } = {};

    projects.forEach((project) => {
      // Create abbreviation from first letter of each word, max 3 letters
      const abbreviation = project.name
        .split(" ")
        .map((word) => word[0])
        .join("")
        .slice(0, 3)
        .toUpperCase();

      projectData[project.id] = {
        name: project.name,
        abbreviation,
        hours: new Array(weeks.length).fill(0),
      };
    });

    // Calculate hours for each project per week
    timesheets.forEach((timesheet) => {
      const timesheetDate = DateTime.fromISO(timesheet.date);
      const projectId = timesheet.projectId;

      if (!projectData[projectId]) return;

      weeks.forEach((weekStart, index) => {
        const weekEnd = weekStart.plus({ weeks: 1 });
        if (timesheetDate >= weekStart && timesheetDate < weekEnd) {
          projectData[projectId].hours[index] +=
            (timesheet.duration || 0) / 3600;
        }
      });
    });

    // Format data for chart
    const series = Object.entries(projectData)
      .filter(([_, data]) => data.hours.some((h) => h > 0)) // Only include projects with hours
      .map(([projectId, data], index) => ({
        data: data.hours.map((h) => Number(h.toFixed(1))),
        label: data.abbreviation,
        color: theme.palette.primary.main,
        valueFormatter: (value: number | null) =>
          value !== null ? `${value} hours` : "",
        type: "line" as const,
        showMark: false,
        projectName: data.name, // Add full project name
      }));

    return {
      xAxis: weeks.map((date) => date.toFormat("MMM d")),
      series,
    };
  }, [timesheets, projects, theme.palette.primary.main]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress sx={{ color: "grey.300" }} />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
          flexWrap: "wrap",
          gap: { xs: 1, sm: 0 },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "grey.900",
            fontWeight: 600,
            pr: 1,
            fontSize: { xs: "1rem", sm: "1.25rem" },
            mb: { xs: 1, sm: 0 },
          }}
        >
          Weekly Utilization
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {weeklyData.series.map((series) => (
            <Tooltip
              key={series.label}
              title={series.projectName}
              arrow
              placement="top"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.75,
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    bgcolor: theme.palette.primary.main,
                    opacity: 0.8,
                  }}
                />
                <Chip
                  label={series.label}
                  size="small"
                  sx={{
                    height: { xs: 28, sm: 24 },
                    bgcolor: "rgba(25, 118, 210, 0.08)",
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(25, 118, 210, 0.12)",
                    },
                  }}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Box>
      <Box sx={{ 
        width: "102%", 
        height: chartHeight, 
        ml: -1, 
        mt: -1,
        overflow: "hidden" 
      }}>
        <LineChart
          series={weeklyData.series}
          xAxis={[
            {
              data: weeklyData.xAxis,
              scaleType: "point",
            },
          ]}
          sx={{
            ".MuiLineElement-root": {
              strokeWidth: 2,
            },
            ".MuiAreaElement-root": {
              fillOpacity: 0.1,
            },
          }}
          height={weeklyData.xAxis.length > 0 ? chartHeight : 200}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </Box>
    </>
  );
};

const StakeholderHome = (): JSX.Element => {
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const {
    projects,
    isProjectsLoading,
    freelancers,
    isFreelancersLoading,
    stakeholders,
    isStakeholdersLoading,
  } = useStakeholderProjects();
  const { clientInvoices, clientInvoicesLoading } = useStakeholderPayments();

  const {
    timesheets,
    isTimesheetsLoading,
    timesheetsError,
    isTimesheetsError,
  } = useStakeholderTimesheets();

  const theme = useTheme();

  // Update the monthly hours calculation to include previous month
  const monthlyHours = React.useMemo((): MonthlyHours => {
    if (!timesheets) return { current: 0, previous: 0, percentageChange: 0 };

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Get previous month and year
    const previousDate = new Date(
      currentDate.setMonth(currentDate.getMonth() - 1)
    );
    const previousMonth = previousDate.getMonth();
    const previousYear = previousDate.getFullYear();

    const calculateMonthHours = (month: number, year: number) => {
      return timesheets.reduce((total, timesheet) => {
        const timesheetDate = new Date(timesheet.date);
        if (
          timesheetDate.getMonth() === month &&
          timesheetDate.getFullYear() === year
        ) {
          return total + (timesheet.duration || 0) / 3600;
        }
        return total;
      }, 0);
    };

    const currentHours = Number(
      calculateMonthHours(currentMonth, currentYear).toFixed(1)
    );
    const previousHours = Number(
      calculateMonthHours(previousMonth, previousYear).toFixed(1)
    );

    const percentageChange =
      previousHours === 0
        ? 100
        : Number(
            (((currentHours - previousHours) / previousHours) * 100).toFixed(1)
          );

    return {
      current: currentHours,
      previous: previousHours,
      percentageChange,
    };
  }, [timesheets]);

  // Calculate monthly hours per project
  const monthlyHoursPerProject = React.useMemo(() => {
    if (!timesheets || !projects) return {};

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Get previous month and year
    const previousDate = new Date(
      currentDate.setMonth(currentDate.getMonth() - 1)
    );
    const previousMonth = previousDate.getMonth();
    const previousYear = previousDate.getFullYear();

    const calculateProjectHours = (month: number, year: number) => {
      return timesheets.reduce(
        (acc, timesheet) => {
          const timesheetDate = new Date(timesheet.date);
          if (
            timesheetDate.getMonth() === month &&
            timesheetDate.getFullYear() === year &&
            timesheet.duration > 0
          ) {
            const projectId = timesheet.projectId;
            if (!acc[projectId]) {
              acc[projectId] = 0;
            }
            acc[projectId] += (timesheet.duration || 0) / 3600;
          }
          return acc;
        },
        {} as { [key: string]: number }
      );
    };

    const currentHours = calculateProjectHours(currentMonth, currentYear);
    const previousHours = calculateProjectHours(previousMonth, previousYear);

    const projectHours: { [key: string]: ProjectHours } = {};

    // Combine current and previous hours
    Object.keys({ ...currentHours, ...previousHours }).forEach((projectId) => {
      const current = Number((currentHours[projectId] || 0).toFixed(1));
      const previous = Number((previousHours[projectId] || 0).toFixed(1));

      if (current > 0 || previous > 0) {
        const percentageChange =
          previous === 0
            ? 100
            : Number((((current - previous) / previous) * 100).toFixed(1));

        const project = projects.find((p) => p.id === projectId);
        projectHours[projectId] = {
          name: project?.name || "Unknown Project",
          hours: current,
          previousHours: previous,
          percentageChange,
        };
      }
    });

    return projectHours;
  }, [timesheets, projects]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const activeProjects =
    projects?.filter((p: Project) => p.status === "active")?.length || 0;
  const completedProjects =
    projects?.filter((p: Project) => p.status === "completed")?.length || 0;

  const projectStatusData = [
    {
      id: 0,
      value:
        projects?.filter((p: Project) => p.status === "active")?.length || 0,
      label: "Active",
      color: "#1976d2",
    },
    {
      id: 1,
      value: projects?.filter((p: Project) => p.status === "idea")?.length || 0,
      label: "Idea",
      color: "#ed6c02",
    },
    {
      id: 2,
      value:
        projects?.filter((p: Project) => p.status === "completed")?.length || 0,
      label: "Completed",
      color: "#2e7d32",
    },
    {
      id: 3,
      value:
        projects?.filter((p: Project) => p.status === "on_hold")?.length || 0,
      label: "On Hold",
      color: "#f57c00",
    },
    {
      id: 4,
      value:
        projects?.filter((p: Project) => p.status === "cancelled")?.length || 0,
      label: "Cancelled",
      color: "#d32f2f",
    },
  ].filter((item) => item.value > 0);

  const monthlyHourlyBudget = projects?.reduce((total, project) => {
    const activeCSA = project?.budget;
    console.log(project);
    return total + (Number(project?.budget) || 0);
  }, 0);

  const outstandingInvoices =
    clientInvoices?.filter(
      (invoice) => invoice.status === "unpaid" || invoice.status === "partial"
    )?.length || 0;

  // Update the renderStatCard function to handle monthly hours comparison
  const renderStatCard = (
    icon: React.ReactNode,
    value: number | undefined | MonthlyHours,
    label: string,
    isLoading: boolean,
    options?: { warning?: boolean; isHours?: boolean }
  ) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        textAlign: "center",
        ...staticWhiteCardStyles,
      }}
    >
      <Box
        sx={{
          color: "grey.900",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 48,
          width: 48,
          borderRadius: "50%",
          bgcolor: "grey.100",
          margin: "0 auto 16px",
        }}
      >
        {icon}
      </Box>
      {isLoading ? (
        <Skeleton variant="text" width="100%" height={60} />
      ) : options?.isHours &&
        typeof value === "object" &&
        "current" in value ? (
        <>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              mb: 0.5,
              color: "grey.900",
            }}
          >
            {value.current}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              mb: 1,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "grey.600",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              Last Month: {value.previous}
            </Typography>
            {value.percentageChange !== 0 && (
              <Chip
                size="small"
                label={`${value.percentageChange > 0 ? "+" : ""}${value.percentageChange}%`}
                color={value.percentageChange > 0 ? "success" : "error"}
                sx={{ height: 20 }}
              />
            )}
          </Box>
        </>
      ) : (
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            mb: 1,
            color: "grey.900",
          }}
        >
          {typeof value === "number" ? value : 0}
        </Typography>
      )}
      <Typography variant="body2" sx={{ color: "grey.600", fontWeight: 500 }}>
        {label}
      </Typography>
    </Paper>
  );

  if (isTimesheetsLoading) {
    return <FreeTechLogoLoading />;
  }

  if (timesheetsError) {
    console.error(timesheetsError);
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">Error loading timesheets</Typography>
      </Box>
    );
  }

  console.log("timesheets", timesheets);

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        p: { xs: 1, sm: 2, md: 3 },
        bgcolor: "grey.100",
      }}
    >
      <Paper
        elevation={2}
        sx={{
          p: { xs: 2, sm: 3, md: 4 },
          mt: { xs: 1, sm: 2 },
          borderRadius: { xs: 2, sm: 3 },
          bgcolor: "grey.50",
          border: "1px solid",
          borderColor: "grey.200",
        }}
      >
        <Box sx={{ mb: { xs: 3, sm: 4 } }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              mb: { xs: 1, sm: 2 },
              color: "grey.900",
              letterSpacing: "-0.01em",
              fontSize: { xs: "1.75rem", sm: "2.5rem", md: "3rem" },
            }}
          >
            Welcome, {userInfo?.firstName} {userInfo?.lastName}!
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "grey.600",
              fontSize: { xs: "0.95rem", sm: "1.1rem" },
              lineHeight: 1.6,
              maxWidth: "800px",
              fontWeight: 400,
            }}
          >
            FreeTech looks forward to delivering the highest quality software,
            data, and AI solutions to your organization. Utilize our FreeTech
            Portal to check your budget utilization, submit bug reports or
            feature requests, and use our suite of project management tools to
            collaborate with your freelance team.
          </Typography>
        </Box>

        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="stakeholder home tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <StyledTab label="Overview" />
          <StyledTab label="Activity" />
        </StyledTabs>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
            <Grid item xs={12} md={4}>
              <MotionPaper
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
                elevation={2}
                sx={{
                  p: { xs: 2, sm: 3 },
                  height: "100%",
                  ...darkContainerStyles,
                }}
              >
                <Box display="flex" alignItems="center" mb={3}>
                  <Avatar
                    src={userInfo?.profilePicture}
                    alt={`${userInfo?.firstName} ${userInfo?.lastName}`}
                    sx={{
                      width: { xs: 60, sm: 80 },
                      height: { xs: 60, sm: 80 },
                      mr: 2,
                      border: "4px solid",
                      borderColor: "grey.800",
                    }}
                  />
                  <Box>
                    <Typography
                      variant="h5"
                      color="#ffffff"
                      sx={{ 
                        fontWeight: 600,
                        fontSize: { xs: "1.25rem", sm: "1.5rem" }
                      }}
                    >
                      {userInfo?.firstName} {userInfo?.lastName}
                    </Typography>
                    <Typography 
                      color="grey.400"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                        wordBreak: "break-word"
                      }}
                    >
                      {userInfo?.email}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  color="#ffffff"
                  sx={{ 
                    fontWeight: 600,
                    fontSize: { xs: "1.1rem", sm: "1.25rem" }
                  }}
                >
                  Quick Actions
                </Typography>
                <Stack spacing={{ xs: 1.5, sm: 2 }}>
                  {[
                    {
                      icon: <AssignmentIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />,
                      label: "Project Hub",
                      path: "/stakeholder/projects",
                    },
                    {
                      icon: <Lightbulb sx={{ fontSize: { xs: 20, sm: 24 } }} />,
                      label: "New Project Idea",
                      path: "/stakeholder/projects/new-idea",
                    },
                    {
                      icon: <ViewKanban sx={{ fontSize: { xs: 20, sm: 24 } }} />,
                      label: "Freelo",
                      path: "/freelo",
                    },
                    {
                      icon: (
                        <StakeholderInvoiceCount />
                      ),
                      label: "Pay Your Bill",
                      path: "/stakeholder/bill-pay",
                    },
                  ].map((action, index) => (
                    <Card
                      key={index}
                      sx={{
                        ...whiteCardStyles,
                        borderRadius: 2,
                      }}
                    >
                      <CardActionArea
                        onClick={() => handleNavigate(action.path)}
                        sx={{
                          "&:hover": {
                            bgcolor: "grey.50",
                          },
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            p: { xs: 1.5, sm: 2 },
                          }}
                        >
                          <Box
                            sx={{
                              mr: 2,
                              color: "grey.900",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bgcolor: "grey.100",
                              borderRadius: "50%",
                              width: { xs: 36, sm: 40 },
                              height: { xs: 36, sm: 40 },
                            }}
                          >
                            {action.icon}
                          </Box>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "0.875rem", sm: "0.95rem" },
                              color: "grey.900",
                            }}
                          >
                            {action.label}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))}
                </Stack>
              </MotionPaper>
            </Grid>

            <Grid item xs={12} md={8}>
              <MotionPaper
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
                elevation={2}
                sx={{
                  p: { xs: 2, sm: 3, md: 4 },
                  height: "100%",
                  ...darkContainerStyles,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "flex-start", sm: "center" },
                    justifyContent: "space-between",
                    mb: { xs: 2, sm: 3 },
                    gap: { xs: 1, sm: 0 },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#ffffff",
                      fontWeight: 600,
                      fontSize: { xs: "1.1rem", sm: "1.25rem" },
                    }}
                  >
                    Project Overview
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Chip
                      icon={<GroupIcon sx={{ fontSize: { xs: 16, sm: 18 } }} />}
                      label={`${freelancers?.length || 0} Freelancers`}
                      size="small"
                      sx={{
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        color: "#ffffff",
                        "& .MuiChip-icon": { color: "#ffffff" },
                        height: { xs: 28, sm: 24 },
                      }}
                    />
                    <Chip
                      icon={<BusinessIcon sx={{ fontSize: { xs: 16, sm: 18 } }} />}
                      label={`${stakeholders?.length || 0} Stakeholders`}
                      size="small"
                      sx={{
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        color: "#ffffff",
                        "& .MuiChip-icon": { color: "#ffffff" },
                        height: { xs: 28, sm: 24 },
                      }}
                    />
                  </Box>
                </Box>

                <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ mb: { xs: 3, sm: 4 } }}>
                  <Grid item xs={12} md={6}>
                    <HoursComparisonSection
                      title="Total Hours This Month"
                      currentHours={monthlyHours.current}
                      previousHours={monthlyHours.previous}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: { xs: 2, sm: 3 },
                        height: "100%",
                        ...staticWhiteCardStyles,
                      }}
                    >
                      <WeeklyUtilizationChart
                        timesheets={timesheets || []}
                        projects={projects || []}
                        isLoading={isTimesheetsLoading}
                      />
                    </Paper>
                  </Grid>
                </Grid>

                <Box sx={{ mt: { xs: 3, sm: 4 } }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: "#ffffff",
                      fontWeight: 600,
                      mb: { xs: 2, sm: 3 },
                      fontSize: { xs: "1.1rem", sm: "1.25rem" },
                    }}
                  >
                    Hours by Project
                  </Typography>
                  <Box sx={{ width: "100%" }}>
                    {isTimesheetsLoading ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                      >
                        <CircularProgress sx={{ color: "grey.300" }} />
                      </Box>
                    ) : Object.keys(monthlyHoursPerProject).length > 0 ? (
                      <Grid container spacing={{ xs: 2, sm: 3 }}>
                        {Object.entries(monthlyHoursPerProject).map(
                          ([projectId, data]) => (
                            <Grid item xs={12} sm={6} md={4} key={projectId}>
                              <Paper
                                elevation={0}
                                sx={{
                                  p: { xs: 2, sm: 3 },
                                  ...staticWhiteCardStyles,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 2,
                                  }}
                                >
                                  <AccessTimeIcon
                                    sx={{ 
                                      color: "grey.500", 
                                      mr: 1,
                                      fontSize: { xs: 20, sm: 24 }
                                    }}
                                  />
                                  <Tooltip
                                    title={data.name}
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          fontSize: "0.875rem",
                                          py: 0.75,
                                          px: 1.5,
                                        },
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        color: "grey.900",
                                        fontWeight: 600,
                                        flex: 1,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        cursor: "default",
                                        fontSize: { xs: "0.9rem", sm: "1rem" },
                                      }}
                                    >
                                      {data.name}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    mb: 2,
                                  }}
                                >
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      fontWeight: 700,
                                      color: "grey.900",
                                      lineHeight: 1,
                                      fontSize: { xs: "1.75rem", sm: "2.125rem" },
                                    }}
                                  >
                                    {data.hours}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "grey.600",
                                      ml: 1,
                                      mb: 0.5,
                                    }}
                                  >
                                    hours
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "grey.600",
                                    fontWeight: 500,
                                    fontSize: { xs: "0.75rem", sm: "0.875rem" },
                                  }}
                                >
                                  Last Month: {data.previousHours} hours
                                </Typography>
                              </Paper>
                            </Grid>
                          )
                        )}
                      </Grid>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                        sx={{
                          ...staticWhiteCardStyles,
                          border: "1px dashed",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "grey.600",
                            fontWeight: 500,
                          }}
                        >
                          No hours logged this month
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </MotionPaper>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <MotionPaper
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            elevation={2}
            sx={{
              p: { xs: 2, sm: 3 },
              borderRadius: { xs: 2, sm: 3 },
              bgcolor: "#ffffff",
              border: "1px solid",
              borderColor: "grey.200",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: "grey.900",
                fontWeight: 600,
                mb: 2,
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
              }}
            >
              Recent Activity
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "grey.600",
                fontWeight: 400,
                fontStyle: "italic",
                fontSize: { xs: "0.875rem", sm: "1rem" },
              }}
            >
              Coming soon: View recent activity on your projects, updates to
              thekanban board, feature requests, bug reports, and more.
            </Typography>
          </MotionPaper>
        </TabPanel>
      </Paper>
    </MotionBox>
  );
};

export default StakeholderHome;
