import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Chip,
  Paper,
  Button,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import { IncreaseExternalAccountProfile } from "@freetech/models";
import { IncreaseExternalAccountDetails } from "@freetech/models";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { UpdatePaymentProfileDialog } from "./UpdatePaymentProfileDialog";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useAuth } from "hooks/auth/useAuth";

export const PaymentProfileCard: React.FC = () => {
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [showRoutingNumber, setShowRoutingNumber] = useState(false);

  const { currentUser } = useAuth();
  const { stakeholderProfile, isLoadingStakeholderProfile } = useStakeholder(
    currentUser?.uid
  );
  const {
    paymentProfile,
    stakeholderPaymentsLoading,
    stakeholderPaymentsError,
  } = useStakeholderPayments();

  const formatAccountNumber = (accountNumber?: string) => {
    if (!accountNumber) return "••••";
    return showAccountNumber
      ? accountNumber
      : `•••• ${accountNumber.slice(-4)}`;
  };

  const formatRoutingNumber = (routingNumber?: string) => {
    if (!routingNumber) return "••••";
    return showRoutingNumber
      ? routingNumber
      : `•••• ${routingNumber.slice(-4)}`;
  };

  if (isLoadingStakeholderProfile || stakeholderPaymentsLoading || !stakeholderProfile || !paymentProfile) {
    return (
      <Paper
        elevation={0}
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
        }}
      >
        <CircularProgress />
      </Paper>
    );
  }

  if (stakeholderPaymentsError && !isLoadingStakeholderProfile) {
    return (
      <Paper
        elevation={0}
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
        }}
      >
        <Alert severity="error" sx={{ mb: 3 }}>
          Error loading payment profile: {stakeholderPaymentsError?.message}
        </Alert>
      </Paper>
    );
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{ border: 1, borderColor: "divider", borderRadius: 2 }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AccountBalanceIcon color="primary" />
            <Typography variant="h6">Payment Profile</Typography>
          </Box>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => setIsUpdateDialogOpen(true)}
            size="small"
          >
            Update Profile
          </Button>
        </Box>

        <Box sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Account Holder
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {
                  stakeholderProfile.increaseExternalAccountProfile
                    ?.accountHolderFullName
                }
              </Typography>
              <Chip
                label={
                  stakeholderProfile.increaseExternalAccountProfile
                    ?.accountHolderType === "individual"
                    ? "Individual Account"
                    : "Business Account"
                }
                size="small"
                color="primary"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Financial Institution
              </Typography>
              <Typography variant="body1">
                {stakeholderProfile.increaseExternalAccountProfile
                  ?.financialInstitution || "Unknown"}
              </Typography>
              <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                {stakeholderProfile.increaseExternalAccountProfile
                  ?.achTransfersSupported && (
                  <Chip
                    label="ACH"
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                )}
                {stakeholderProfile.increaseExternalAccountProfile
                  ?.wireTransfersSupported && (
                  <Chip
                    label="Wire"
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                )}
                {stakeholderProfile.increaseExternalAccountProfile
                  ?.realTimePaymentsSupported && (
                  <Chip
                    label="RTP"
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Account Number
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => setShowAccountNumber(!showAccountNumber)}
                  sx={{ mb: 0.5 }}
                >
                  {showAccountNumber ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </Box>
              <Typography variant="body1" sx={{ fontFamily: "monospace" }}>
                {formatAccountNumber(paymentProfile.accountNumber)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Routing Number
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => setShowRoutingNumber(!showRoutingNumber)}
                  sx={{ mb: 0.5 }}
                >
                  {showRoutingNumber ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </Box>
              <Typography variant="body1" sx={{ fontFamily: "monospace" }}>
                {formatRoutingNumber(paymentProfile.routingNumber)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Account Nickname
              </Typography>
              <Typography variant="body1">
                {
                  stakeholderProfile.increaseExternalAccountProfile
                    ?.externalAccountNickname
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                  borderRadius: 2,
                  mt: 1,
                }}
              >
                <Typography variant="body2">
                  This account supports both ACH and wire transfers. Your
                  payment information is securely stored.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <UpdatePaymentProfileDialog
        open={isUpdateDialogOpen}
        onClose={() => setIsUpdateDialogOpen(false)}
        currentProfile={{
          ...paymentProfile,
          ...stakeholderProfile.increaseExternalAccountProfile,
        }}
      />
    </>
  );
};
