import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "config/firebase";
import { timesheetConverter } from "@freetech/models/converters";
import { getClientCompanyName } from "core/clients/utils";
import { getProjectName } from "core/projects";
import { DateTime } from "luxon";
import { getPublicUserProfiles } from "core/users/getPublicUserProfile";
import { User } from "firebase/auth";
import { Timesheet } from "@freetech/models/timesheet";
import { PublicUserInfoType } from "@freetech/models/user";  

export const fetchTimesheets = async (
  filters: Partial<Timesheet> = {},
  users?: PublicUserInfoType[]
): Promise<Timesheet[]> => {
  const timesheetsRef = collection(db, "timesheets").withConverter(
    timesheetConverter
  );
  let q = query(timesheetsRef);

  const publicUserProfiles = await getPublicUserProfiles();

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      q = query(q, where(key, "==", value));
    }
  });

  const querySnapshot = await getDocs(q);

  const fetchedTimesheets: Timesheet[] = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const timesheet = doc.data() as Timesheet;
      let clientName: string | undefined;
      let projectName: string | undefined;

      if (timesheet.clientId) {
        clientName = await getClientCompanyName(timesheet.clientId);
      }
      if (timesheet.clientId && timesheet.projectId) {
        projectName = await getProjectName(
          timesheet.clientId,
          timesheet.projectId
        );
      }

      const userProfile = users?.find((user) => user.id === timesheet.userId);
      const publicProfile = publicUserProfiles?.find(
        (profile) => profile.id === timesheet.userId
      );

      const mergedProfile = {
        ...publicProfile,
        ...userProfile,
      };

      let approvedByEmail;
      let rejectedByEmail;
      if (timesheet.approvedBy) {
        approvedByEmail =
          users?.find((user) => user.id === timesheet.approvedBy)?.email ||
          publicUserProfiles?.find(
            (profile) => profile.id === timesheet.approvedBy
          )?.email;
      }
      if (timesheet.rejectedBy) {
        rejectedByEmail =
          users?.find((user) => user.id === timesheet.rejectedBy)?.email ||
          publicUserProfiles?.find(
            (profile) => profile.id === timesheet.rejectedBy
          )?.email;
      }

      return {
        ...timesheet,
        clientName,
        projectName,
        userEmail: mergedProfile?.email,
        userName: mergedProfile
          ? `${mergedProfile.firstName} ${mergedProfile.lastName}`
          : undefined,
        approvedByEmail,
        rejectedByEmail,
      };
    })
  );

  return sortTimesheets(fetchedTimesheets);
};

const sortTimesheets = (timesheets: Timesheet[]): Timesheet[] => {
  return timesheets.sort((a, b) => {
    const dateA = DateTime.fromISO(a.date);
    const dateB = DateTime.fromISO(b.date);
    if (dateA.equals(dateB)) {
      const startTimeA = DateTime.fromISO(a.startTime);
      const startTimeB = DateTime.fromISO(b.startTime);
      return startTimeB.toMillis() - startTimeA.toMillis();
    }
    return dateB.toMillis() - dateA.toMillis();
  });
};
