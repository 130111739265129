import { useState } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { db, storage } from "config/firebase";
import { useAuth } from "./useAuth";
import { updateDoc } from "firebase/firestore";
import { doc } from "firebase/firestore";

export const useProfilePicture = () => {
  const { currentUser } = useAuth();
  const [isUploading, setIsUploading] = useState(false);

  const uploadProfilePicture = async (file: File): Promise<string> => {
    if (!currentUser) throw new Error("No authenticated user");

    try {
      setIsUploading(true);

      // Delete old profile picture if it exists
      if (currentUser.photoURL?.includes("profile-pictures")) {
        try {
          const oldImageRef = ref(storage, currentUser.photoURL);
          await deleteObject(oldImageRef);
        } catch (error) {
          console.error("Failed to delete old profile picture:", error);
        }
      }

      // Upload new profile picture
      const storageRef = ref(
        storage,
        `public/profile-pictures/${currentUser.uid}/${file.name}`
      );
      const snapshot = await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      // Update user profile
      await updateProfile(currentUser, {
        photoURL: downloadUrl,
      });

      // Update user profile picture in Firestore
      await updateDoc(doc(db, "users", currentUser.uid), {
        profilePicture: downloadUrl,
      });

      return downloadUrl;
    } catch (error) {
      console.error("Failed to upload profile picture:", error);
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  const removeProfilePicture = async () => {
    if (!currentUser) throw new Error("No authenticated user");

    try {
      setIsUploading(true);

      // Delete profile picture from storage if it exists
      if (currentUser.photoURL?.includes("profile-pictures")) {
        const imageRef = ref(storage, currentUser.photoURL);
        await deleteObject(imageRef);
      }

      // Update user profile
      await updateProfile(currentUser, {
        photoURL: "",
      });
    } catch (error) {
      console.error("Failed to remove profile picture:", error);
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  return {
    uploadProfilePicture,
    removeProfilePicture,
    isUploading,
    currentPhotoURL: currentUser?.photoURL || "",
  };
};
