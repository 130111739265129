import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Avatar,
  IconButton,
  TablePagination,
  TextField,
  InputAdornment,
  Skeleton,
  useTheme,
  Divider,
} from '@mui/material';
import {
  Search as SearchIcon,
  Business as BusinessIcon,
  OpenInNew as OpenInNewIcon,
  MonetizationOn as MonetizationOnIcon,
  Assignment as AssignmentIcon,
} from '@mui/icons-material';
import { useAdminClients } from 'hooks/admin/useAdminClients';
import { Link } from 'react-router-dom';
import { Client } from '@freetech/models/projects';

// Define extended client interface with UI-specific properties
interface ExtendedClient extends Client {
  // These fields are added for UI purposes only
  contactPersonName?: string;
  contactPersonEmail?: string;
  industry?: string;
  totalBudget?: number;
}

const ClientsTab: React.FC = () => {
  const theme = useTheme();
  const { clients: apiClients, isLoading } = useAdminClients();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  // Transform API clients to extended clients with UI fields
  const clients: ExtendedClient[] = apiClients?.map(client => ({
    ...client,
    contactPersonName: client.pointOfContactName,
    contactPersonEmail: client.email,
    industry: 'Technology', // Default value or could come from another source
    totalBudget: client.projects?.reduce((sum, project) => {
      const budget = typeof project.budget === 'number' ? project.budget : 0;
      return sum + budget;
    }, 0) || 0
  })) || [];

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Filter clients based on search query
  const filteredClients = clients?.filter((client) => {
    const searchText = searchQuery.toLowerCase();
    const name = client.companyName?.toLowerCase() || '';
    const contactName = client.contactPersonName?.toLowerCase() || '';
    const contactEmail = client.contactPersonEmail?.toLowerCase() || '';
    
    return (
      name.includes(searchText) ||
      contactName.includes(searchText) ||
      contactEmail.includes(searchText)
    );
  }) || [];

  // Calculate displayed clients based on pagination
  const displayedClients = filteredClients
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Get counts for different client statuses
  const activeClients = clients?.filter(c => c.status === 'active').length || 0;
  const inactiveClients = clients?.filter(c => c.status === 'inactive').length || 0;
  const opportunityClients = clients?.filter(c => c.status === 'opportunity').length || 0;

  // Calculate total project count
  const totalProjects = clients?.reduce(
    (count, client) => count + (client.projects?.length || 0),
    0
  ) || 0;

  // Calculate clients by industry
  const getClientsByIndustry = () => {
    const industryMap: Record<string, number> = {};
    
    clients?.forEach(client => {
      if (client.industry) {
        industryMap[client.industry] = (industryMap[client.industry] || 0) + 1;
      }
    });
    
    return Object.entries(industryMap)
      .sort((a, b) => b[1] - a[1]);
  };

  const clientsByIndustry = getClientsByIndustry();

  // Get top clients by project count
  const getTopClientsByProjects = () => {
    return [...(clients || [])]
      .sort((a, b) => (b.projects?.length || 0) - (a.projects?.length || 0))
      .slice(0, 5);
  };

  const topClientsByProjects = getTopClientsByProjects();

  // Format currency
  const formatCurrency = (amount: number | undefined): string => {
    if (amount === undefined) return '$0';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Loading placeholders
  const StatCardLoader = () => (
    <Card>
      <CardContent>
        <Skeleton variant="text" width={120} height={28} />
        <Skeleton variant="text" width={80} height={40} sx={{ my: 1 }} />
        <Skeleton variant="text" width={100} />
      </CardContent>
    </Card>
  );

  const TableLoader = () => (
    <Box>
      <Skeleton variant="rectangular" height={52} sx={{ mb: 1 }} />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={53} sx={{ mb: 1 }} />
      ))}
    </Box>
  );

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Client Stats */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>Client Overview</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Total Clients
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1 }}>
                      {clients?.length || 0}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Registered in the system
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Active Clients
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.success.main }}>
                      {activeClients}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      With ongoing projects
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Total Projects
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.primary.main }}>
                      {totalProjects}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Across all clients
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              {isLoading ? (
                <StatCardLoader />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                      Avg. Projects per Client
                    </Typography>
                    <Typography variant="h4" sx={{ my: 1, color: theme.palette.info.main }}>
                      {clients && clients.length > 0 
                        ? (totalProjects / clients.length).toFixed(1) 
                        : '0'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Client engagement metric
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Clients by Industry */}
        <Grid item xs={12} md={5}>
          <Typography variant="h6" sx={{ mb: 2 }}>Clients by Industry</Typography>
          {isLoading ? (
            <Skeleton variant="rectangular" height={300} />
          ) : (
            <Paper sx={{ p: 2, height: '100%' }}>
              <TableContainer sx={{ maxHeight: 300 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Industry</TableCell>
                      <TableCell align="right">Clients</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientsByIndustry.map(([industry, count]) => (
                      <TableRow key={industry} hover>
                        <TableCell>{industry}</TableCell>
                        <TableCell align="right">{count}</TableCell>
                      </TableRow>
                    ))}
                    {clientsByIndustry.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          No industry data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Grid>

        {/* Top Clients by Projects */}
        <Grid item xs={12} md={7}>
          <Typography variant="h6" sx={{ mb: 2 }}>Top Clients by Projects</Typography>
          {isLoading ? (
            <Skeleton variant="rectangular" height={300} />
          ) : (
            <Paper sx={{ p: 2, height: '100%' }}>
              {topClientsByProjects.length > 0 ? (
                topClientsByProjects.map((client, index) => (
                  <Box key={client.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', py: 1.5 }}>
                      <Avatar
                        sx={{ 
                          bgcolor: theme.palette.primary.main, 
                          width: 40, 
                          height: 40,
                          mr: 2 
                        }}
                      >
                        <BusinessIcon />
                      </Avatar>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2">{client.companyName}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                          <AssignmentIcon fontSize="small" sx={{ color: 'text.secondary', mr: 0.5 }} />
                          <Typography variant="body2" color="text.secondary">
                            {client.projects?.length || 0} projects
                          </Typography>
                          {client.totalBudget && (
                            <>
                              <MonetizationOnIcon fontSize="small" sx={{ color: 'text.secondary', ml: 2, mr: 0.5 }} />
                              <Typography variant="body2" color="text.secondary">
                                {formatCurrency(client.totalBudget)}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                      <IconButton
                        component={Link}
                        to={`/admin/client-management/${client.id}`}
                        size="small"
                        color="primary"
                      >
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    {index < topClientsByProjects.length - 1 && <Divider />}
                  </Box>
                ))
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                  <Typography color="text.secondary">No client data available</Typography>
                </Box>
              )}
            </Paper>
          )}
        </Grid>

        {/* Client Search */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Client Search</Typography>
            <TextField
              variant="outlined"
              placeholder="Search clients..."
              size="small"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              sx={{ width: { xs: '100%', sm: 250 } }}
            />
          </Box>
          
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {isLoading ? (
              <Box sx={{ p: 2 }}>
                <TableLoader />
              </Box>
            ) : (
              <>
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Company</TableCell>
                        <TableCell>Contact Person</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Industry</TableCell>
                        <TableCell>Projects</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedClients.map((client) => (
                        <TableRow hover key={client.id}>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar 
                                sx={{ mr: 2, width: 32, height: 32, bgcolor: theme.palette.primary.main }}
                              >
                                <BusinessIcon fontSize="small" />
                              </Avatar>
                              <Typography variant="body2">
                                {client.companyName || 'Unknown Company'}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{client.contactPersonName || '-'}</TableCell>
                          <TableCell>{client.contactPersonEmail || '-'}</TableCell>
                          <TableCell>{client.industry || '-'}</TableCell>
                          <TableCell>{client.projects?.length || 0}</TableCell>
                          <TableCell>
                            <Chip
                              label={client.status || 'unknown'}
                              size="small"
                              color={
                                client.status === 'active' 
                                  ? 'success' 
                                  : client.status === 'inactive'
                                    ? 'error'
                                    : 'warning'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              component={Link}
                              to={`/admin/client-management/${client.id}`}
                              size="small"
                              color="primary"
                            >
                              <OpenInNewIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {displayedClients.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No clients found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredClients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientsTab; 