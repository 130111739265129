import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { adminController } from "controllers/adminController";
import { UploadIndependentEngineerAgreementParams } from "types/admin";
import {
  UserDocument,
  Freelancer,
  IndependentEngineerAgreement,
} from "@freetech/models/user";
import { portalFunctions } from "core/functions/portalFunctions";
import { GenerateProjectAssignmentTemplateRequestBody } from "@freetech/models/eversign";
import { getPreviousMonthAssignments } from "core/admin/projectAssignments/getPreviousMonthAssignments";
import { db } from "core/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const useAdminFreelancers = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["adminFreelancers"],
    queryFn: () => adminController.freelancers.loadNestedFreelancers(),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const deleteProjectAssignmentMutation = useMutation({
    mutationFn: adminController.freelancers.deleteProjectAssignment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  const updateProjectAssignmentMutation = useMutation({
    mutationFn: adminController.freelancers.updateProjectAssignment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
    onError: (error: Error) => {
      console.error("Failed to update project assignment:", error);
    },
  });

  const uploadDocumentMutation = useMutation({
    mutationFn: adminController.freelancers.uploadDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  const updateDocumentMutation = useMutation({
    mutationFn: adminController.freelancers.updateDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  const deleteDocumentMutation = useMutation({
    mutationFn: adminController.freelancers.deleteDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  // Independent Engineer Agreement mutations
  const uploadAgreementMutation = useMutation<
    UserDocument,
    Error,
    UploadIndependentEngineerAgreementParams
  >({
    mutationFn: adminController.freelancers.uploadIndependentEngineerAgreement,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
      queryClient.invalidateQueries({ queryKey: ["freelancerAgreement"] });
    },
  });

  const deleteAgreementMutation = useMutation<void, Error, string>({
    mutationFn: adminController.freelancers.deleteIndependentEngineerAgreement,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
      queryClient.invalidateQueries({ queryKey: ["freelancerAgreement"] });
    },
  });

  const getAgreementMutation = useMutation<
    IndependentEngineerAgreement | null,
    Error,
    string
  >({
    mutationFn: adminController.freelancers.getIndependentEngineerAgreement,
  });

  const generateProjectAssignmentMutation = useMutation<
    Response,
    Error,
    GenerateProjectAssignmentTemplateRequestBody
  >({
    mutationFn: async (input: GenerateProjectAssignmentTemplateRequestBody) => {
      const response =
        await portalFunctions.eversign.generateProjectAssignmentTemplate(input);
      return response;
    },
  });

  const previousMonthAssignments = useQuery({
    queryKey: ["previousMonthAssignments"],
    queryFn: async () => {
      const data = await getPreviousMonthAssignments(query?.data || []);
      return data;
    },
  });

  const updateFreelancerStatusMutation = useMutation<
    void,
    Error,
    { freelancerId: string; status: string }
  >({
    mutationFn: async ({ freelancerId, status }) => {
      console.log("Updating freelancer status:", freelancerId, status);
      try {
        const response = await updateDoc(doc(db, "users", freelancerId), {
          status: status,
        });
        return response;
      } catch (error) {
        console.error("Failed to update freelancer status:", error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  const updateFreelancerMutation = useMutation({
    mutationFn: async (params: {
      freelancerId: string;
      updates: Partial<Freelancer>;
    }) => {
      const { freelancerId, updates } = params;
      const userRef = doc(db, "users", freelancerId);
      await updateDoc(userRef, updates);
      return { freelancerId, updates };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
    onError: (error: Error) => {
      console.error("Failed to update freelancer:", error);
    },
  });

  const uploadFreelancerProfilePictureMutation = useMutation({
    mutationFn: async (params: { freelancerId: string; file: File }) => {
      const { freelancerId, file } = params;

      // Upload file to Firebase Storage
      const storage = getStorage();
      const storageRef = ref(storage, `users/${freelancerId}/profilePicture`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Update user document with new profile picture URL
      const userRef = doc(db, "users", freelancerId);
      await updateDoc(userRef, { profilePicture: downloadURL });

      return downloadURL;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
    onError: (error: Error) => {
      console.error("Failed to upload freelancer profile picture:", error);
    },
  });

  return {
    freelancers: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,

    // Return mutation objects with mutate and mutateAsync
    deleteProjectAssignment: deleteProjectAssignmentMutation,
    uploadDocument: uploadDocumentMutation,
    updateDocument: updateDocumentMutation,
    deleteDocument: deleteDocumentMutation,
    updateProjectAssignment: updateProjectAssignmentMutation,

    // Independent Engineer Agreement mutations
    uploadAgreement: uploadAgreementMutation,
    deleteAgreement: deleteAgreementMutation,
    getAgreement: getAgreementMutation,

    // Loading states
    isDeletingAssignment: deleteProjectAssignmentMutation.isPending,
    isUploadingDocument: uploadDocumentMutation.isPending,
    isUpdatingDocument: updateDocumentMutation.isPending,
    isDeletingDocument: deleteDocumentMutation.isPending,
    isUpdatingProjectAssignment: updateProjectAssignmentMutation.isPending,
    isUploadingAgreement: uploadAgreementMutation.isPending,
    isDeletingAgreement: deleteAgreementMutation.isPending,
    isLoadingAgreement: getAgreementMutation.isPending,

    // Error states
    deleteAssignmentError: deleteProjectAssignmentMutation.error,
    uploadDocumentError: uploadDocumentMutation.error,
    updateDocumentError: updateDocumentMutation.error,
    deleteDocumentError: deleteDocumentMutation.error,
    updateProjectAssignmentError: updateProjectAssignmentMutation.error,
    uploadAgreementError: uploadAgreementMutation.error,
    deleteAgreementError: deleteAgreementMutation.error,
    getAgreementError: getAgreementMutation.error,

    generateProjectAssignment: generateProjectAssignmentMutation,
    isGeneratingProjectAssignment: generateProjectAssignmentMutation.isPending,
    generateProjectAssignmentError: generateProjectAssignmentMutation.error,

    previousMonthAssignments: previousMonthAssignments.data,
    isLoadingPreviousMonthAssignments: previousMonthAssignments.isLoading,
    previousMonthAssignmentsError: previousMonthAssignments.error,

    updateFreelancerStatus: updateFreelancerStatusMutation,
    isUpdatingFreelancerStatus: updateFreelancerStatusMutation.isPending,
    updateFreelancerStatusError: updateFreelancerStatusMutation.error,

    updateFreelancer: updateFreelancerMutation,
    uploadFreelancerProfilePicture: uploadFreelancerProfilePictureMutation,
  };
};
