import {
  Home as HomeIcon,
  Person2,
  AccessTime as AccessTimeIcon,
  BorderColor as BorderColorIcon,
  ManageSearch,
  AttachMoney as MoneyIcon,
} from "@mui/icons-material";
import {
  Home as FreelancerHome,
  FreelancerProfile,
  SignAgreements,
} from "views/Freelancer";
import { TimeTracker } from "views/SuperAdmin";
import { Navigation } from "types/navigation";
import { FreeloHome } from "views/FreeloHome";
import FreeloBoard from "views/FreeloBoard/FreeloBoard";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import SavingsIcon from "@mui/icons-material/Savings";
import FreelancerPayroll from "views/Freelancer/FreelancerPayroll/FreelancerPayroll";
import { FreelancerProjectExplorer } from "views/Freelancer/FreelancerProjectExplorer";
import MyClients from "views/Freelancer/MyClients";
import FreelancerClient from "views/Freelancer/FreelancerClient";
import ReferralProgram from "views/Freelancer/ReferralProgram/ReferralProgram";
import { useAuth } from "hooks/auth/useAuth";
import BlogWriter from "views/SuperAdmin/MarketingCMS/BlogWriter/BlogWriter";
import { PrivateUserInfoType } from "@freetech/models";
import { BlogPostEditor } from "views/SuperAdmin/MarketingCMS/BlogWriter/BlogPostEditor";
const isStg = window.location.hostname.includes("stg");
const isLocal = window.location.hostname.includes("localhost");

// export const betaFeatures: Navigation = [
//   { kind: "header", title: "Beta Features" },
//   { kind: "divider" },
// ];

export const getFreelancerPages = (
  userInfo?: PrivateUserInfoType
): Navigation => {
  return [
    { kind: "header", title: "General" },
    { kind: "divider" },
    {
      segment: "freelancer",
      title: "Home",
      icon: <HomeIcon />,
      component: <FreelancerHome />,
    },
    {
      segment: "freelancer/profile",
      title: "Profile",
      icon: <Person2 />,
      component: <FreelancerProfile />,
    },
    { kind: "header", title: "Projects" },
    { kind: "divider" },
    {
      segment: "freelancer/my-clients",
      title: "My Clients",
      icon: <Person2 />,
      component: <MyClients />,
      slugBabyPattern: ":clientId",
      slugBabyComponent: <FreelancerClient />,
    },
    {
      kind: "page",
      title: "Freelo",
      segment: "freelo",
      icon: <ViewKanbanIcon />,
      component: <FreeloHome />,
      slugBabyPattern: ":boardId",
      slugBabyComponent: <FreeloBoard />,
    },
    {
      segment: "freelancer/timesheet",
      title: "Time Tracker",
      icon: <AccessTimeIcon />,
      component: <TimeTracker />,
    },
    {
      segment: "freelancer/project-explorer",
      title: "Explorer",
      icon: <ManageSearch />,
      component: <FreelancerProjectExplorer />,
    },
    {
      segment: "freelancer/referral-program",
      title: "Referral Program",
      icon: <SavingsIcon />,
      component: <ReferralProgram />,
    },
    { kind: "header", title: "Finance & Legal" },
    { kind: "divider" },
    {
      segment: "freelancer/freelancer-payroll",
      title: "Payroll",
      icon: <MoneyIcon />,
      component: <FreelancerPayroll />,
    },
    {
      segment: "freelancer/sign-agreements",
      title: "Sign Agreements",
      icon: <BorderColorIcon />,
      component: <SignAgreements />,
    },
    { kind: "header", title: "Special Access" },
    { kind: "divider" },
    ...(userInfo?.email === "jack@freetech.co" ||
    userInfo?.email === "adam1.siwiec@gmail.com"
      ? [
          {
            segment: "blog-writer",
            title: "Blog Writer",
            icon: <BorderColorIcon />,
            component: <BlogWriter />,
            slugBabyPattern: ":blogId",
            slugBabyComponent: <BlogPostEditor />,
          },
        ]
      : []),
  ];
};
