import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { DateTime } from "luxon";
import { bankFunctions } from "core/functions/bankFunctions";
import { stripeFunctions } from "core/functions/stripeFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { useAdminInvoicing } from "./useAdminInvoicing";
import { 
  FinancialOverview, 
  TransactionSummary, 
  EnhancedTransaction,
  AccountBalancesResponse
} from "@freetech/models/bank";
import {
  StripeBalanceTransaction,
  StripeProduct,
  StripeSubscription
} from "@freetech/models/stripe";

// Define interface for combined transaction data
interface CombinedTransaction {
  id: string;
  date: string;
  amount: number;
  description: string | null;
  status: string;
  direction: "inbound" | "outbound";
  source: "increase" | "stripe";
  type?: string;
  category?: string;
  route?: string;
  metadata?: Record<string, any>;
  [key: string]: any; // Allow any additional properties
}

// Define interface for financial summary
interface FinancialSummary {
  totalInbound: number;
  totalOutbound: number;
  netBalance: number;
  pendingInbound: number;
  pendingOutbound: number;
  totalPending: number;
  stripeInbound: number;
  stripeOutbound: number;
  increaseInbound: number;
  increaseOutbound: number;
}

export const useAdminFinancialHub = () => {
  const { currentUser, userInfo } = useAuth();
  const adminInvoicing = useAdminInvoicing();
  
  // State for time range and tab management
  const [timeRange, setTimeRange] = useState<"7d" | "30d" | "90d" | "1y">("30d");

  // Get account balances from Increase
  const {
    data: accountBalances,
    isLoading: isLoadingAccountBalances,
    error: accountBalancesError,
    refetch: refetchAccountBalances,
  } = useQuery({
    queryKey: ["adminAccountBalances", currentUser?.uid],
    queryFn: async () => {
      const result = await bankFunctions.admin.getAccountBalances();
      return result as AccountBalancesResponse;
    },
    enabled: !!currentUser?.uid && userInfo?.role === "admin",
  });

  // Get transactions from Increase
  const {
    data: increaseTransactionsResponse,
    isLoading: isLoadingIncreaseTransactions,
    error: increaseTransactionsError,
    refetch: refetchIncreaseTransactions,
  } = useQuery({
    queryKey: ["adminIncreaseTransactions", currentUser?.uid, timeRange],
    queryFn: async () => {
      return await bankFunctions.admin.getRecentTransactions({
        timeRange,
        limit: 100,
      });
    },
    enabled: !!currentUser?.uid && userInfo?.role === "admin",
  });

  // Get Stripe products
  const {
    data: stripeProductsData,
    isLoading: isLoadingStripeProducts,
    error: stripeProductsError,
  } = useQuery({
    queryKey: ["adminStripeProducts", currentUser?.uid],
    queryFn: async () => {
      return await stripeFunctions.admin.getProducts();
    },
    enabled: !!currentUser?.uid && userInfo?.role === "admin",
  });

  // Get Stripe subscriptions
  const {
    data: stripeSubscriptionsData,
    isLoading: isLoadingStripeSubscriptions,
    error: stripeSubscriptionsError,
  } = useQuery({
    queryKey: ["adminStripeSubscriptions", currentUser?.uid, "active"],
    queryFn: async () => {
      return await stripeFunctions.admin.getSubscriptions({
        status: "active",
      });
    },
    enabled: !!currentUser?.uid && userInfo?.role === "admin",
  });

  // Get Stripe transactions
  const {
    data: stripeTransactionsData,
    isLoading: isLoadingStripeTransactions,
    error: stripeTransactionsError,
    refetch: refetchStripeTransactions,
  } = useQuery({
    queryKey: ["adminStripeTransactions", currentUser?.uid, timeRange],
    queryFn: async () => {
      return await stripeFunctions.admin.getBalanceTransactions({
        time_range: timeRange,
      });
    },
    enabled: !!currentUser?.uid && userInfo?.role === "admin",
  });

  // Format currency helper
  const formatCurrency = (amount: number | undefined): string => {
    if (amount === undefined) return "$0";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Helper to get combined transactions from both sources
  const getCombinedTransactions = (): CombinedTransaction[] => {
    const increaseTransactions: CombinedTransaction[] = 
      increaseTransactionsResponse?.transactions?.map(tx => ({
        ...tx,
        source: "increase" as const
      })) || [];
    
    // Get invoice payment transactions from Stripe
    const stripeTransactions: CombinedTransaction[] = 
      stripeTransactionsData?.transactions
        ?.filter(tx => tx.type === "charge" || tx.type === "payment")
        ?.map(tx => ({
          id: tx.id,
          date: DateTime.fromSeconds(tx.created).toISO(),
          amount: Math.abs(tx.amount / 100), // Convert from cents to dollars
          description: tx.description,
          status: tx.status || "complete",
          direction: tx.amount > 0 ? "inbound" : "outbound",
          source: "stripe" as const,
          type: tx.type,
        })) || [];
    
    // Combine and sort by date (newest first)
    return [...increaseTransactions, ...stripeTransactions]
      .sort((a, b) => {
        const dateA = a.date ? DateTime.fromISO(a.date) : DateTime.fromMillis(0);
        const dateB = b.date ? DateTime.fromISO(b.date) : DateTime.fromMillis(0);
        return dateB.toMillis() - dateA.toMillis();
      });
  };

  // Calculate financial summary based on transactions
  const calculateFinancialSummary = (): FinancialSummary => {
    // Get all transactions
    const allTransactions = getCombinedTransactions();
    
    // Initialize summary object
    const summary: FinancialSummary = {
      totalInbound: 0,
      totalOutbound: 0,
      netBalance: 0,
      pendingInbound: 0,
      pendingOutbound: 0,
      totalPending: 0,
      stripeInbound: 0,
      stripeOutbound: 0,
      increaseInbound: 0,
      increaseOutbound: 0
    };
    
    // Process each transaction
    allTransactions.forEach(tx => {
      const amount = tx.amount || 0;
      
      // Handle by direction
      if (tx.direction === "inbound") {
        summary.totalInbound += amount;
        
        if (tx.source === "increase") {
          summary.increaseInbound += amount;
          if (tx.status === "pending") {
            summary.pendingInbound += amount;
          }
        } else if (tx.source === "stripe") {
          summary.stripeInbound += amount;
        }
      } else {
        summary.totalOutbound += amount;
        
        if (tx.source === "increase") {
          summary.increaseOutbound += amount;
          if (tx.status === "pending") {
            summary.pendingOutbound += amount;
          }
        } else if (tx.source === "stripe") {
          summary.stripeOutbound += amount;
        }
      }
    });
    
    // Calculate derived values
    summary.netBalance = summary.totalInbound - summary.totalOutbound;
    summary.totalPending = summary.pendingInbound + summary.pendingOutbound;
    
    return summary;
  };

  // Generate chart data based on transactions
  const generateChartData = (timeRange: "7d" | "30d" | "90d" | "1y") => {
    const transactions = getCombinedTransactions();
    
    if (!transactions || transactions.length === 0) {
      return [];
    }
    
    // Group transactions by date
    const groupedByDate = new Map<string, { inbound: number, outbound: number, balance: number }>();
    
    // Calculate date interval
    let interval: number;
    switch (timeRange) {
      case "7d":
        interval = 1; // daily for 7 days
        break;
      case "30d":
        interval = 3; // every 3 days for 30 days
        break;
      case "90d":
        interval = 7; // weekly for 90 days
        break;
      case "1y":
        interval = 30; // monthly for 1 year
        break;
      default:
        interval = 3;
    }
    
    // Group transactions into date buckets
    transactions.forEach(transaction => {
      const txDate = DateTime.fromISO(transaction.date).startOf('day');
      const dateKey = txDate.toFormat("yyyy-MM-dd");
      
      if (!groupedByDate.has(dateKey)) {
        groupedByDate.set(dateKey, { inbound: 0, outbound: 0, balance: 0 });
      }
      
      const entry = groupedByDate.get(dateKey)!;
      if (transaction.direction === "inbound") {
        entry.inbound += transaction.amount;
      } else {
        entry.outbound += transaction.amount;
      }
    });
    
    // Generate a date series for the chart
    const now = DateTime.now().startOf('day');
    const startDate = now.minus({ days: getTimeRangeDays(timeRange) });
    let currentDate = startDate;
    const chartData = [];
    
    // Use account balance from API as starting balance if available
    let balance = accountBalances?.primaryAccount?.currentBalance || 0;
    
    while (currentDate <= now) {
      // Only add data points at our interval
      if (currentDate.diff(startDate, 'days').days % interval === 0) {
        const dateKey = currentDate.toFormat("yyyy-MM-dd");
        const dateData = groupedByDate.get(dateKey) || { inbound: 0, outbound: 0, balance: 0 };
        
        // Update balance based on transaction data
        balance += dateData.inbound - dateData.outbound;
        
        chartData.push({
          date: currentDate.toFormat("LLL dd"),
          inbound: dateData.inbound,
          outbound: dateData.outbound,
          balance,
          net: dateData.inbound - dateData.outbound,
        });
      }
      
      currentDate = currentDate.plus({ days: 1 });
    }
    
    return chartData;
  };

  // Generate upcoming payment events
  const getUpcomingPaymentEvents = () => {
    const transactions = getCombinedTransactions();
    
    if (!transactions || transactions.length === 0) {
      return [];
    }
    
    // Explicitly type events to match what PaymentsCalendar expects
    return transactions
      .filter(tx => 
        // Include pending transactions and recent completed transactions
        tx.status === "pending" || 
        (tx.date && DateTime.fromISO(tx.date) > DateTime.now().minus({ days: 7 }))
      )
      .map(tx => ({
        date: DateTime.fromISO(tx.date),
        amount: tx.amount,
        description: tx.description || `${tx.source.toUpperCase()} Transaction`,
        type: tx.direction === "inbound" ? "incoming" as const : "outgoing" as const,
        status: tx.status === "pending" ? "pending" as const :
               tx.status === "failed" ? "failed" as const : "complete" as const,
        source: tx.source
      }));
  };
  
  // Helper to get number of days for a time range
  const getTimeRangeDays = (range: "7d" | "30d" | "90d" | "1y"): number => {
    switch (range) {
      case "7d": return 7;
      case "30d": return 30;
      case "90d": return 90;
      case "1y": return 365;
      default: return 30;
    }
  };

  // Return all financial data and functions
  return {
    // Data
    accountBalances,
    combinedTransactions: getCombinedTransactions(),
    increaseTransactions: increaseTransactionsResponse?.transactions || [],
    stripeTransactions: stripeTransactionsData?.transactions || [],
    stripeProducts: stripeProductsData?.products || [],
    stripeSubscriptions: stripeSubscriptionsData?.subscriptions || [],
    stripeBalance: stripeTransactionsData?.balance,
    
    // Financial summary and calculations
    financialSummary: calculateFinancialSummary(),
    
    // State management
    timeRange,
    setTimeRange,
    
    // Loading states
    isLoading: 
      isLoadingAccountBalances || 
      isLoadingIncreaseTransactions || 
      isLoadingStripeProducts ||
      isLoadingStripeSubscriptions ||
      isLoadingStripeTransactions ||
      adminInvoicing.isLoadingInvoices,
    isLoadingAccountBalances,
    isLoadingIncreaseTransactions,
    isLoadingStripeProducts,
    isLoadingStripeSubscriptions,
    isLoadingStripeTransactions,
    isLoadingInvoices: adminInvoicing.isLoadingInvoices,
    
    // Error states
    error: 
      accountBalancesError || 
      increaseTransactionsError || 
      stripeProductsError ||
      stripeSubscriptionsError ||
      stripeTransactionsError,
    
    // Utility functions
    formatCurrency,
    generateChartData,
    getUpcomingPaymentEvents,
    
    // Refetch functions
    refetch: () => {
      refetchAccountBalances();
      refetchIncreaseTransactions();
      refetchStripeTransactions();
    }
  };
}; 