import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import { formatDateStringToPretty } from 'utils/formatDateStringToPretty';
import { IndependentEngineerAgreement } from "@freetech/models/user";
import { usePublicUserList } from "hooks/user/usePublicUserList";

interface IndEngToSignCardProps {
  agreement: IndependentEngineerAgreement;
  onClick: (agreement: IndependentEngineerAgreement) => void;
  onCancel?: (agreement: IndependentEngineerAgreement) => void;
  isAdminView?: boolean;
}

export const IndEngToSignCard: React.FC<IndEngToSignCardProps> = ({ 
  agreement, 
  onClick, 
  onCancel, 
  isAdminView = false 
}) => {
  const { data: users } = usePublicUserList();


  
  const engineerName = React.useMemo(() => {
    if (!users || !agreement.userId) return "Unknown Engineer";
    const user = users.find(u => u.id === agreement.userId);
    return user ? `${user.firstName} ${user.lastName}` : "Unknown Engineer";
  }, [users, agreement.userId]);

  const StatusChip = () => {
    if (isAdminView) {
      if (agreement.everSignDocumentStatus === 'awaiting_admin_signature') {
        return (
          <Chip
            icon={<CheckCircleIcon />}
            label="Ready to Sign"
            color="success"
            size="small"
          />
        );
      } else if (agreement.everSignDocumentStatus === 'awaiting_engineer_signature') {
        return (
          <Chip
            icon={<WarningIcon />}
            label="Awaiting Engineer Signature"
            color="warning"
            size="small"
          />
        );
      }
    } else {
      if (agreement.everSignDocumentStatus === 'awaiting_admin_signature') {
        return (
          <Chip
            icon={<WarningIcon />}
            label="Awaiting Admin Signature"
            color="warning"
            size="small"
          />
        );
      } else if (agreement.everSignDocumentStatus === 'awaiting_engineer_signature') {
        return (
          <Chip
            icon={<CheckCircleIcon />}
            label="Ready to Sign"
            color="success"
            size="small"
          />
        );
      }
    }
    return null;
  };

  return (
    <Card 
      sx={{ 
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: 6
        },
        position: 'relative',
      }} 
      onClick={() => onClick(agreement)}
    >
      {isAdminView && onCancel && (
        <Button
          color="error"
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
            minWidth: 'auto',
            p: '4px 8px',
            backgroundColor: 'background.paper',
            '&:hover': {
              backgroundColor: 'error.light',
              color: 'white',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            onCancel(agreement);
          }}
        >
          Cancel
        </Button>
      )}
      <CardContent sx={{ flexGrow: 1, pb: 1 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Independent Engineer Agreement
        </Typography>
        {isAdminView && (
          <Box display="flex" alignItems="center" mb={1}>
            <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">{engineerName}</Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" mb={1}>
          <DescriptionIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2">Agreement ID: {agreement.id}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">
              Sent: {new Date(agreement.sentDate).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })}
            </Typography>
          </Box>
          <StatusChip />
        </Box>
        {agreement.sandbox && (
          <Box mt={1}>
            <Chip
              label="Sandbox"
              color="info"
              size="small"
              variant="outlined"
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}; 