import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { timesheetConverter } from "@freetech/models/converters";
import { Timesheet } from "@freetech/models/timesheet";
export const updateTimesheet = async ({
  id,
  ...updateData
}: Partial<Timesheet> & { id: string }) => {
  const timesheetRef = doc(db, "timesheets", id).withConverter(
    timesheetConverter
  );
  await updateDoc(timesheetRef, updateData);
};
