import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Snackbar,
  InputAdornment,
  IconButton,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { authTextFieldStyle } from "core/theme";
import { portalFunctions } from "core/functions/portalFunctions";
import { AuthErrorDialog } from "components";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import CodeIcon from "@mui/icons-material/Code";

type SignUpType = 'client' | 'freelancer' | 'student';

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [signupType, setSignupType] = useState<SignUpType>('client');
  const [dialogContent, setDialogContent] = useState({
    title: "",
    errorMessage: "",
    showResetPassword: false,
    showResendVerification: false,
    email: "",
    customButton: undefined as
      | { text: string; onClick: () => void }
      | undefined,
    hideDefaultButton: false,
  });

  // Check URL hash for signup type
  useEffect(() => {
    if (location.hash === "#student") {
      setSignupType('student');
    } else if (location.hash === "#freelancer") {
      setSignupType('freelancer');
    } else if (location.hash === "#client") {
      setSignupType('client');
    }
  }, [location.hash]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    const confirmPassword = formData.get("confirmPassword") as string;

    // Set company name based on sign up type
    let companyName = "Individual User";
    if (signupType === 'student') {
      companyName = "Student";
    } else if (signupType === 'freelancer') {
      companyName = "Freelancer";
    }

    const requestData = {
      firstName: formData.get("firstName") as string,
      lastName: formData.get("lastName") as string,
      email,
      password,
      phone: (formData.get("phone") as string) || undefined,
      companyName,
      companyWebsite: undefined,
      message: undefined,
      isStudent: signupType === 'student',
      isFreelancer: signupType === 'freelancer',
    };

    try {
      // Validate required fields
      if (
        !requestData.firstName ||
        !requestData.lastName ||
        !requestData.email
      ) {
        setDialogContent({
          title: "Missing Information",
          errorMessage:
            "Please fill in all required fields: First Name, Last Name, and Email.",
          showResetPassword: false,
          showResendVerification: false,
          email: "",
          customButton: undefined,
          hideDefaultButton: false,
        });
        setOpenDialog(true);
        setLoading(false);
        return;
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(requestData.email)) {
        setDialogContent({
          title: "Invalid Email",
          errorMessage: "Please enter a valid email address.",
          showResetPassword: false,
          showResendVerification: false,
          email: "",
          customButton: undefined,
          hideDefaultButton: false,
        });
        setOpenDialog(true);
        setLoading(false);
        return;
      }

      // Validate password
      if (!password) {
        setDialogContent({
          title: "Missing Password",
          errorMessage: "Please enter a password.",
          showResetPassword: false,
          showResendVerification: false,
          email: "",
          customButton: undefined,
          hideDefaultButton: false,
        });
        setOpenDialog(true);
        setLoading(false);
        return;
      }

      // Validate password length
      if (password.length < 8) {
        setDialogContent({
          title: "Password Too Short",
          errorMessage: "Password must be at least 8 characters long.",
          showResetPassword: false,
          showResendVerification: false,
          email: "",
          customButton: undefined,
          hideDefaultButton: false,
        });
        setOpenDialog(true);
        setLoading(false);
        return;
      }

      // Validate password match
      if (password !== confirmPassword) {
        setDialogContent({
          title: "Passwords Don't Match",
          errorMessage:
            "The passwords you entered don't match. Please try again.",
          showResetPassword: false,
          showResendVerification: false,
          email: "",
          customButton: undefined,
          hideDefaultButton: false,
        });
        setOpenDialog(true);
        setLoading(false);
        return;
      }

      // Use the appropriate signup function based on the account type
      switch (signupType) {
        case 'student':
          await portalFunctions.public.signUpStudent(requestData);
          break;
        case 'freelancer':
          await portalFunctions.public.signUpFreelancer(requestData);
          break;
        default:
          await portalFunctions.public.signUpStakeholder(requestData);
          break;
      }
      
      // Navigate to login page with success message
      navigate("/login", {
        state: {
          successMessage: getSuccessMessage()
        }
      });
      
    } catch (err: any) {
      console.error(`Error creating ${signupType} account:`, err);

      if (err.message.includes("A user with this email already exists")) {
        setDialogContent({
          title: "Account Already Exists",
          errorMessage:
            "A user with this email already exists. Please use a different email or contact support if you need assistance.",
          showResetPassword: false,
          showResendVerification: false,
          email: "",
          customButton: {
            text: "Go to Login",
            onClick: () => navigate("/login"),
          },
          hideDefaultButton: false,
        });
      } else {
        setDialogContent({
          title: "Sign Up Failed",
          errorMessage:
            err.message ||
            "An error occurred while creating your account. Please try again later.",
          showResetPassword: false,
          showResendVerification: false,
          email: "",
          customButton: undefined,
          hideDefaultButton: false,
        });
      }

      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const getSuccessMessage = () => {
    switch (signupType) {
      case 'client':
        return "Your account has been created successfully! You can now log in with your email and password.";
      case 'freelancer':
        return "Your freelancer account has been created successfully! After logging in, you can complete your profile to be considered for projects that match your skills.";
      case 'student':
        return "Your student account has been created successfully! You can now log in to access the student mentorship program.";
      default:
        return "Your account has been created successfully! You can now log in with your email and password.";
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as SignUpType;
    setSignupType(value);
    // Update URL hash without reloading the page
    navigate(`/signup#${value}`, { replace: true });
  };

  const getTypeDescription = () => {
    switch (signupType) {
      case 'client':
        return "Ready to bring your project to life? Create your account and start working with top tech talent today.";
      case 'freelancer':
        return "Sign up as a freelancer, complete your profile, and get matched with projects that suit your skills and expertise.";
      case 'student':
        return "Join our mentorship program and start your journey in tech. Create your student account today.";
      default:
        return "Create your account to access our services.";
    }
  };

  return (
    <>
      <AuthErrorDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogContent.title}
        errorMessage={dialogContent.errorMessage}
        showResetPassword={dialogContent.showResetPassword}
        showResendVerification={dialogContent.showResendVerification}
        email={dialogContent.email}
        customButton={dialogContent.customButton}
        hideDefaultButton={dialogContent.hideDefaultButton}
      />

      <Box>
        <Typography
          component="h1"
          variant="h5"
          sx={{ mb: 3, textAlign: "center", mt: 2 }}
        >
          Sign Up
        </Typography>

        {/* Account Type Selection */}
        <Paper elevation={0} sx={{ mb: 3, p: 2, backgroundColor: '#f8f9fa' }}>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <RadioGroup
              row
              name="account-type"
              value={signupType}
              onChange={handleRadioChange}
              sx={{ 
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <FormControlLabel
                value="client"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <BusinessIcon sx={{ mr: 0.5, fontSize: 20 }} />
                    Client
                  </Box>
                }
              />
              <FormControlLabel
                value="freelancer"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CodeIcon sx={{ mr: 0.5, fontSize: 20 }} />
                    Freelancer
                  </Box>
                }
              />
              <FormControlLabel
                value="student"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SchoolIcon sx={{ mr: 0.5, fontSize: 20 }} />
                    Student
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1, textAlign: 'center' }}>
            {getTypeDescription()}
          </Typography>
        </Paper>

        <Box
          component="form"
          ref={formRef}
          onSubmit={handleSubmit}
          noValidate
          sx={{ width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                sx={{
                  ...authTextFieldStyle,
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.6)",
                    "&.Mui-focused": {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                sx={{
                  ...authTextFieldStyle,
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.6)",
                    "&.Mui-focused": {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                sx={{
                  ...authTextFieldStyle,
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.6)",
                    "&.Mui-focused": {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="tel"
                sx={{
                  ...authTextFieldStyle,
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.6)",
                    "&.Mui-focused": {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ...authTextFieldStyle,
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.6)",
                    "&.Mui-focused": {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ...authTextFieldStyle,
                  "& .MuiInputLabel-root": {
                    color: "rgba(0, 0, 0, 0.6)",
                    "&.Mui-focused": {
                      color: "black",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: "black",
              color: "white",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.8)",
              },
              height: 48,
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              `Sign Up${signupType !== 'client' ? ` as ${signupType.charAt(0).toUpperCase() + signupType.slice(1)}` : ''}`
            )}
          </Button>

          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item>
              <Link
                onClick={handleNavigateToLogin}
                variant="body2"
                sx={{ color: "black", cursor: "pointer" }}
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
