import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { Timesheet } from "@freetech/models/timesheet";
import { generateProjectAssignmentDisplayName } from "core/timesheet/utils/generateProjectAssignmentDisplayName";
import { DateTime } from "luxon";
import { Client } from "@freetech/models/projects";
import { Project } from "@freetech/models/projects";
import { ProjectAssignment } from "@freetech/models/projects";
import { timesheetConverter } from "@freetech/models/converters";
import { getDocs, query, where } from "firebase/firestore";

interface ClientsProjectsAndAssignments {
  clients: Client[];
  projects: Project[];
  projectAssignments: ProjectAssignment[];
}

interface GetTimesheetsParams {
  userId: string;
  clientsProjectsAndAssignments?: ClientsProjectsAndAssignments;
}

export const getTimesheets = async ({
  userId,
  clientsProjectsAndAssignments,
}: GetTimesheetsParams): Promise<Timesheet[]> => {
  const timesheetsRef = collection(db, "timesheets").withConverter(
    timesheetConverter
  );

  const q = query(timesheetsRef, where("userId", "==", userId));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  }

  const { clients = [], projects = [], projectAssignments = [] } =
    clientsProjectsAndAssignments || {};

  const timesheets = querySnapshot.docs.map((doc) => {
    const timesheet = doc.data() as Timesheet;
    let clientName = undefined;
    let projectName = undefined;

    if (timesheet.clientId) {
      clientName = clients.find(
        (c) => c.id === timesheet.clientId
      )?.companyName;
    }
    if (timesheet.clientId && timesheet.projectId) {
      projectName = projects.find((p) => p.id === timesheet.projectId)?.name;
    }
    if (timesheet.projectAssignmentId) {
      const assignment = projectAssignments.find(
        (pa) => pa.id === timesheet.projectAssignmentId
      );
      if (assignment) {
        timesheet.projectAssignmentName =
          generateProjectAssignmentDisplayName(assignment);
      }
    }

    return {
      ...timesheet,
      clientName,
      projectName,
    };
  });

  return timesheets.sort((a, b) => {
    const dateA = DateTime.fromISO(a.date);
    const dateB = DateTime.fromISO(b.date);
    if (dateA.equals(dateB)) {
      const startTimeA = DateTime.fromISO(a.startTime);
      const startTimeB = DateTime.fromISO(b.startTime);
      return startTimeB.toMillis() - startTimeA.toMillis();
    }
    return dateB.toMillis() - dateA.toMillis();
  });
};
