import { useQuery } from "@tanstack/react-query";
import { getBoard } from "core/freelo/board";

export const useFreeloTickets = (
  boardId: string,
  ticketIds: string[] | undefined
) => {
  return useQuery({
    queryKey: ["tickets", boardId, ticketIds],
    queryFn: async () => {
      if (!ticketIds?.length) return [];
      const board = await getBoard(boardId);
      if (!board) return [];
      return (
        board.lists?.flatMap(
          (list) =>
            list.cards?.filter((card) => ticketIds.includes(card.id)) || []
        ) || []
      );
    },
    enabled: !!boardId && !!ticketIds?.length,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};
