import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { ICreateFeatureRequest, FeatureRequestPriority, Stakeholder } from "@freetech/models";
import { useAuth } from "hooks/auth/useAuth";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useQuery } from "@tanstack/react-query";

const featureRequestSchema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title is too long"),
  description: z.string().min(1, "Description is required"),
  priority: z.enum(["low", "medium", "high"] as const),
  desiredStartDate: z.string().optional(),
  desiredCompletionDate: z.string().optional(),
  clientEstimatedHours: z.number().min(0).optional(),
  clientDollarAmountBudget: z.number().min(0).optional(),
  requestedById: z.string().min(1, "Stakeholder is required"),
});

type FeatureRequestFormData = z.infer<typeof featureRequestSchema>;

interface CreateFeatureRequestDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ICreateFeatureRequest) => Promise<void>;
  clientId: string;
  projectId: string;
  isSubmitting?: boolean;
}

export const CreateFeatureRequestDialog: React.FC<CreateFeatureRequestDialogProps> = ({
  open,
  onClose,
  onSubmit,
  clientId,
  projectId,
  isSubmitting = false,
}) => {
  const { userInfo } = useAuth();
  const { getStakeholdersForClientId } = useAdminClient(clientId);
  const { data: stakeholders = [], isLoading: isLoadingStakeholders } = useQuery({
    queryKey: ["stakeholders", clientId],
    queryFn: () => getStakeholdersForClientId.mutateAsync(clientId),
    enabled: !!clientId,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FeatureRequestFormData>({
    resolver: zodResolver(featureRequestSchema),
    defaultValues: {
      title: "",
      description: "",
      priority: "medium",
      clientEstimatedHours: undefined,
      clientDollarAmountBudget: undefined,
      requestedById: "",
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = async (data: FeatureRequestFormData) => {
    const featureRequest: ICreateFeatureRequest = {
      ...data,
      clientId,
      projectId,
      status: "pending_approval",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    await onSubmit(featureRequest);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Feature Request</DialogTitle>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="requestedById"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.requestedById}>
                    <InputLabel>Requested By</InputLabel>
                    <Select
                      {...field}
                      label="Requested By"
                      disabled={isLoadingStakeholders}
                    >
                      {stakeholders.map((stakeholder) => (
                        <MenuItem key={stakeholder.id} value={stakeholder.id}>
                          {stakeholder.firstName} {stakeholder.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Title"
                    fullWidth
                    error={!!errors.title}
                    helperText={errors.title?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.description}
                    helperText={errors.description?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.priority}>
                    <InputLabel>Priority</InputLabel>
                    <Select {...field} label="Priority">
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="desiredStartDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Desired Start Date"
                    value={field.value ? DateTime.fromISO(field.value) : null}
                    onChange={(newValue) => {
                      field.onChange(newValue?.toISO() || "");
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.desiredStartDate,
                        helperText: errors.desiredStartDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="desiredCompletionDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Desired Completion Date"
                    value={field.value ? DateTime.fromISO(field.value) : null}
                    onChange={(newValue) => {
                      field.onChange(newValue?.toISO() || "");
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.desiredCompletionDate,
                        helperText: errors.desiredCompletionDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="clientEstimatedHours"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => field.onChange(e.target.value === "" ? undefined : Number(e.target.value))}
                    label="Client Estimated Hours"
                    type="number"
                    fullWidth
                    error={!!errors.clientEstimatedHours}
                    helperText={errors.clientEstimatedHours?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="clientDollarAmountBudget"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => field.onChange(e.target.value === "" ? undefined : Number(e.target.value))}
                    label="Client Budget ($)"
                    type="number"
                    fullWidth
                    error={!!errors.clientDollarAmountBudget}
                    helperText={errors.clientDollarAmountBudget?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || isLoadingStakeholders}
          >
            Create Feature Request
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}; 